import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { CohortService } from 'src/app/fur/cohort.service';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';

import { NavbarComponent } from 'src/app/components/navbar/navbar.component';

@Component({
  selector: 'app-cohort-monthly-plant',
  templateUrl: './cohort-monthly-plant.component.html',
  styleUrls: ['./cohort-monthly-plant.component.css']
})
export class CohortMonthlyPlantComponent implements OnInit {
  reportName ='';
  activeverticalTab = '';
  filterapplied: boolean;
  urlpath: string;
  activeTab: any;
  furType: string;
  finalpostObj: {};
  regions: any;
  dropdownSettings = {};
  teamdropdownSettings = {};
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};  

  public selectedRegions= [];
  public selectedTeams= [];

  dtOptionsenable2: boolean;
  dtOptionsenable3: boolean;
  dtOptionsenable4: boolean;
  plantclicked: boolean;
  cohortBoolean: boolean;
  smeclicked: boolean;
  supplyclicked: boolean;
  clicked1: boolean;
  cohortclicked: boolean;
  teamclicked: boolean;
  plantCondition = false;
  tableCondition = false;
  finalDataObject = { table: {}, excel: [] };
  userId_val:any;
  @Input('cohortWise') cohortWise = 0;
  finalCohort: any;
  TeamRegionsListObj:{};
  teams:any;
  constructor(private navbarTitle: NavbarComponent,private service: AppService, private cohortservice: CohortService,private userId: AppService ) {
 
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
}
  async ngOnInit(): Promise<any>  {    
    this.cohortWise = 0;
    this.getdefaultRegionsList();
    this.multiSelectDropdown();
    this.selectedRegions = JSON.parse(localStorage.getItem('regions'));
    this.selectedTeams = this.service.selectedTeamsList;
    this.cohortSales(this.cohortWise,this.selectedRegions,this.selectedTeams)
   
  }
  ngOnChanges(changes: SimpleChanges) {
    this.multiSelectDropdown();
    this.cohortSales(this.cohortWise,this.selectedRegions,this.selectedTeams);
  }
 async getdefaultRegionsList(){
  this.service.getTeamRegion(environment.baseURL_FREQ+UrlConfig.accessMapping,{username:  this.userId_val}).subscribe(
    (response) => {
      this.TeamRegionsListObj = response["data"][0];
      this.TeamRegionsListObj = this.service.TeamRegionsListObj;
      this.selectedRegions= this.service.selectedRegionsList;
      this.regions = this.selectedRegions;
      this.getCurrentRegionsTeams(this.selectedRegions)
      console.log(this.regions,this.selectedTeams,this.selectedRegions);
     

    });
   
  }

  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  getCurrentRegionsTeams(selectedRegionsList){
      this.teams = [];
      this.selectedTeams = [];
      for(let j=0;j<selectedRegionsList.length;j++){
        this.getKey(selectedRegionsList[j]);
      }
  } 
  getKey(key) {
      this.TeamRegionsListObj[key].forEach((team,index)=>{
        this.selectedTeams.push(team);
        this.teams.push(team);
      });
  }
  cohortSales(paramcohort: any,selectedRegionsList,sleectedTeamsList) {
    paramcohort = this.cohortWise;
    this.filterapplied = true;
    this.furType = "cohort";
    this.plantCondition = true;
    this.dtOptions = {};
    this.finalpostObj = {
        'region': this.selectedRegions,
        'username':this.userId_val,
        "team": this.selectedTeams
    }
    switch (paramcohort) {
      case 0: {
        this.urlpath = 'monthly_cohort_plantwise';
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        break;
      }
      case 1: {
        this.urlpath = 'monthly_cohort_revenue';
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        break;
      }
      case 2: {
        this.urlpath = 'quarterly_cohort_plantwise';
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        break;
      }
      case 3: {
        this.urlpath = 'quarterly_cohort_revenue';
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        break;
      }
    }
  }
  displayTable(urlpath, finalpostObj, furType) {
    this.furType = furType;
    this.dtOptionsenable = false;
    this.dtOptionsenable2 = false;
    this.dtOptionsenable3 = false;
    this.dtOptionsenable4 = false;
      this.cohortservice.saveInfo(urlpath, finalpostObj).subscribe((response) => {

        if(response['data1']['data']!=undefined && response['status']=='SUCCESS'){
          let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
          this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
            console.log(reportdata);
          })
        }

            this.finalCohort = this.cohortservice.postCohort(response);
              this.dtOptionsenable = true;
              this.dtOptionsenable2 = true;
              this.dtOptions = this.finalCohort[0].table;
              this.dtOptions2 = this.finalCohort[1].table;
    })
  }

  onRegionSelect(item: string) {
   this.getCurrentRegionsTeams(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getCurrentRegionsTeams(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
      this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
      this.selectedRegions.forEach(sid => {this.onRegionSelect(sid);});
  }
  onRegionsDeSelectAll(items: any){
    this.selectedTeams= [];
    this.teams = [];
  }
//teams
  onTeamSelect(item: string) {}
  onTeamsAll(items: any) {}
  onTeamDeSelect(deselectedTID: any): void {}
  onTeamsDeSelectAll(items: any){
      this.selectedTeams = items;
  }
}
