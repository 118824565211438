
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { InvoiceDownloadService } from 'src/app/services/invoice-download.service';
import { AppService } from 'src/app/services/app.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bulk-invoice-download',
  templateUrl: './bulk-invoice-download.component.html',
  styleUrls: ['./bulk-invoice-download.component.css']
})
export class BulkInvoiceDownloadComponent implements OnInit {
  url = environment.baseURL_FREQ+'get_vmi_wh';
  dateFormat = 'y-MM-dd';
  dateLocale = 'en-IN';
  excelData = [];
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  start_date: any;
  maxDate: Date = new Date();
  requestbody = {};
  private toDate = new Date();
  private fromDate = new Date();
  private currentDate = new Date();
  dropdownValue: "";
  WarehouseValue = {};
  dtOptions: DataTables.Settings = {};
  final = {};
  selected = 1;
  userId_val: any;
  tableDisplay: boolean;
  days_to_consider = 10;;
  reportName = "";
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  public selectedL1 = [];
  public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  dropdownSettings = {};
  TeamRegionsListObj: any = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  statusBPdropdownSettings = {};
  pod_flagdropdownSettings = {};
  filterData: any = {};
  RkeyValue: any;
  isOpen = false;
  invoices = false;
  pod = false;
  csvReport = false;
  mergeInPDF = false;
  cpoNumber = '';
  invoiceNumber = '';
  message = '';
  bulkFilters = { }

//     "data": {

//         "status": [

//             "0-SHIPMENT_CREATED",

//             "1-ASN_CREATED",

//             "3-SHIPPED",

//             "4-DELIVERED",

//             "8-RETURN_TO_ORIGIN",

//             "10-CANCELLED",

//             "40-PAYMENT_ELIGIBLE",

//             "45-DELIVERED_POD",

//             "50-PAYMENT_RECEIVED",

//             "60-RETURNED_PARTIAL",

//             "70-RETURNED_FULL",

//             "110-WAREHOUSE_RETURN",

//             "150-150"

//         ],

//         "pod_flag": [

//             "0-Unchecked",

//             "2-Approved",

//             "1-Flagged"

//         ]

//     },

//     "dateval": " ",

//     "message": "Query ran successfully",

//     "status": "SUCCESS"

// }
statusBP = []

selectedStatusBP = []
 
selectedPODFlag= []
PODFlag = []

  constructor(private navbarTitle: NavbarComponent, private invoiceDownloadService: InvoiceDownloadService, private commonService: CommonService,
    private userId: AppService,
    private Service: SelfServeService,) {
    this.getFiltersData();

    this.multiSelectDropdown();
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
    this.getSelectedFilters(this.selectedRegions);
  
    // this.initialDate();


    // this.requestbody = { "username": "kalyani.kocherla@moglix.com", "date": "2021-02-16", "days_to_consider": 10, "input_warehouse_id": this.selected }

    // this.getDataByFilter();
  }
  getBulkFilters(){
    let api = 'https://analytics-web.moglix.com/freq_report/pod_mapping'
    this.userId.saveInfo(api,{'username':this.userId_val}).subscribe(resp => {
      
    this.statusBP = resp["data"]["status"];
    this.PODFlag = resp["data"]["pod_flag"];
    })
    

  }
  async getNoDays(numberofDays) {
    this.days_to_consider = parseInt(numberofDays);
    this.requestbody['days_to_consider'] = this.days_to_consider;
  }
  ngOnInit() {
    // this.initialDate();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getSelectedFilters(this.selectedRegions);
    
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }

  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    // this.selectedTeams.push(item);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }

  getDateRange(value: Date): void {
    if(value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 32) {
        this.commonService.showToast('please select a dates within range of 31 days', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      } 
    }
  }

  bulkPod() {
    if(this.mergeInPDF) {
      this.invoices = this.pod = false;
    }
  }

  bulkPodInActive() {
    if(this.invoices || this.pod) {
      this.mergeInPDF = false;
    }
  }


  generatePayload() {
    const payload = {
      "cpo_number": this.cpoNumber || '',
      "invoice_number": this.invoiceNumber || '',
      "regions": this.selectedRegions,
      "teams": this.selectedTeams,
      "customers": this.selectedCustomers,
      "plants": this.selectedPlants,
      "invoices": this.invoices,
      "POD": this.pod,
      "csv_report": this.csvReport,
      "merge_inv_pod": this.mergeInPDF,
      "status": this.selectedStatusBP,
      "pod_flag": this.selectedPODFlag,
      "username": this.userId_val
  }
  if(this.dateRange) {
    payload["start_date"] = this.getDateFormat(this.dateRange[0]),
    payload["end_date"] = this.getDateFormat(this.dateRange[1])
  } else {
    payload["start_date"] = '',
    payload["end_date"] = ''
  }
  const api = environment.baseURL_FREQ+'merge_invoice_pod';
  this.invoiceDownloadService.sendBulkInvoice(api, payload).subscribe(resp => {
    let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
    this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
      console.log(reportdata);
    })
    this.commonService.showToast(resp['message'], 1);
    this.message = resp['message'];
  })
  }

  onTeamDeSelect(deselectedTID: any): void {

    if (this.selectedTeams != null) {
      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {
      this.selectedTeams = [];
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }

  resetFilters() {
    this.selectedRegions = [];
    this.onRegionsDeSelectAll(null);
    this.dateRange = null;
    this.invoiceNumber = '';
    this.cpoNumber = '';
    this.invoices = this.pod = this.csvReport = this.mergeInPDF = false;
    this.message = '';
  }

  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  
  //Customers
  onCustomerSelect(item: string) {
    this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onCustomersAll(items: any) {
    this.selectedCustomers = items;
    this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onCustomerDeSelect(deselectedTCID: any): void {
    if (this.selectedCustomers != null) {
      this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {

      this.selectedCustomers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }

  onCustomersDeSelectAll(items: any) {

    this.selectedL3 = [];
    this.selectedCustomers = [];

    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Plants
  onPlantSelect(item: string) {
    this.selectedL4.push(item);
    this.selectedPlants.push(item);

  }
  onPlantsAll(items: any) {
    this.selectedPlants = items;
  }
  onPlantDeSelect(deselectedTPID: any): void { }
  onPlantsDeSelectAll(items: any) {
    this.selectedL4 = [];
    this.selectedPlants = [];

    // this.plants = [];
  }

  onStatusBPSelect(item: string) {
    this.selectedStatusBP.push(item)
  }
  onStatusBPAll(items: any) {
    this.selectedStatusBP = items;
  }
  onStatusBPDeSelect(deselectedTPID: any): void { }
  onStatusBPDeSelectAll(items: any) {
    this.selectedStatusBP = [];
  }

  onPODFlagSelect(item: string) {
    this.selectedPODFlag.push(item)
  }
  onPODFlagAll(items: any) {
    this.selectedPODFlag = items;
  }
  onPODFlagDeSelect(deselectedTPID: any): void { }
  onPODFlagDeSelectAll(items: any) {
    this.selectedPODFlag = [];
  }
  CustomerUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {

    this.selectedPlants = [];
    this.selectedL4 = [];
    this.plants = [];
    Pkeys = [];

    for (let r = 0; r < this.regions.length; r++) {
      for (let rr = 0; rr < Rkeys.length; rr++) {
        if (this.regions[r] === Rkeys[rr]) {
          this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {
            let currentTeamName = Object.keys(team)[0];
            for (let t = 0; t < team[currentTeamName].length; t++) {
              for (let tt = 0; tt < Tkeys.length; tt++) {
                if (currentTeamName == Tkeys[tt]) {
                  let customer = Object.keys(team[currentTeamName][t])[0];
                  for (let cc = 0; cc < team[currentTeamName][t][customer].length; cc++) {
                    this.plants.push(team[currentTeamName][t][customer][cc]);

                    for (let p = 0; p < CKeys.length; p++) {

                      if (CKeys[p] == customer) {
                        for (let pp = 0; pp < team[currentTeamName][t][customer].length; pp++) {
                          this.selectedL4.push(team[currentTeamName][t][customer][pp]);
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        }
      }

    }

    if (this.selectedL4.length > 0) {
      this.selectedPlants = this.selectedL4.filter((v, i, a) => a.indexOf(v) === i);

    }

  }


  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {

    let teamsList = [];
    let s2 = [];
    let s3 = [];
    this.selectedCustomers = [];
    let s4 = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.customers = [];
    this.plants = [];
    for (let r = 0; r < this.regions.length; r++) {
      for (let rr = 0; rr < Rkeys.length; rr++) {
        if (this.regions[r] === Rkeys[rr]) {
          this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {

            let currentTeamName = Object.keys(this.TeamRegionsListObj[this.regions[r]][index])[0];

            for (let t = 0; t < team[currentTeamName].length; t++) {

              for (let tt = 0; tt < Tkeys.length; tt++) {
                if (currentTeamName === Tkeys[tt]) {
                  this.selectedCustomers.push(Object.keys(team[currentTeamName][t])[0])
                  this.customers.push(Object.keys(team[currentTeamName][t])[0])
                  let cName = Object.keys(team[currentTeamName][t])[0]
                  for (let p = 0; p < team[currentTeamName][t][cName].length; p++) {
                    this.plants.push(team[currentTeamName][t][cName][p])
                    this.selectedPlants.push(team[currentTeamName][t][cName][p])
                  }
                }
              }
            }
          });
        }
      }
    }
  }

  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }

  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }

  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }

  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }

  getFiltersData() {
    this.invoiceDownloadService.getFiltersItemTrackingES(this.userId.userDetails.email).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
      this.getBulkFilters();
    });
  }

  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

  this.statusBPdropdownSettings = {
    singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
  };
  this.pod_flagdropdownSettings = {
    singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
  };
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let dateVal: string = [year, month, day].join('-');
    return dateVal;
  }
  initialDate() {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.currentDate = new Date();
    this.currentDate.setDate(this.fromDate.getDate() - 1);
    this.fromDate.setDate(this.fromDate.getDate() - 1);
    this.toDate.setDate(this.toDate.getDate() - 1);
    this.start_date = formatDate(this.fromDate.setDate(this.fromDate.getDate()), this.dateFormat, this.dateLocale);
  }

  selectWarehouse(evt) {
    this.getNightlyType(evt.target.value);
  }

  getNightlyType(country: any) {
    for (let element in this.WarehouseValue) {
      console.log(this.WarehouseValue[element]);
      element === country
        ? this.selected = parseInt(country)
        : this.WarehouseValue[element];
    }
    console.log(this.WarehouseValue);
    console.log(this.selected);
  }


  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.excelData, 'VMI Projection');
  }
}
