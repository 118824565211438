import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { RegionService } from 'src/app/fur/region.service';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { environment } from 'src/environments/environment';
const moment = extendMoment(Moment);

@Component({
  selector: 'app-regional-osod',
  templateUrl: './regional-osod.component.html',
  styleUrls: ['./regional-osod.component.css']
})
export class RegionalOSODComponent implements OnInit {
  reportName="";

  selectedDate: any;
  datePickerenable: any;
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public datePickerConfigForSupply: any = {};
  public bsValue: any = new Date();

  activeverticalTab = '';
  filterapplied: boolean;
  urlpath: string;
  activeTab: any;
  furType: string;
  finalpostObj: {};
  regions: any;
  dropdownSettings = {};
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  selectedRegions: any;
  dtOptionsenable2: boolean;
  plantclicked: boolean;
  cohortBoolean: boolean;
  smeclicked: boolean;
  supplyclicked: boolean;
  clicked1: boolean;
  cohortclicked: boolean;
  teamclicked: boolean;
  plantCondition = false;
  tableCondition = false;
  finalDataObject = { table: {}, excel: [] };
  userId_val :any;
  @Input('regionWise') regionWise = 1;

  constructor(private navbarTitle: NavbarComponent,private service: AppService, private regionservice: RegionService,private userId: AppService ) { 
    
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }

  ngOnInit() {
    this.regionWise = 1;
    this.selectedRegions = this.service.getUserDetails(['regions']);
    this.getDataByFilter();
    this.multiSelectDropdown();
    this.finalpostObj = this.service.userDetails.regions;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.multiSelectDropdown();
    this.getDataByFilter();
    this.bsValue = new Date();
    this.regionSales(this.regionWise);
  }



  getDataByFilter(date = this.bsValue) {
    this.selectedDate = moment(date).format('YYYY-MM');
    if (this.regionWise === 0)
      this.regionSales(this.regionWise);
    if (this.regionWise === 1)
      this.regionSales(this.regionWise);
  }



  multiSelectDropdown() {

    this.regions = this.service.getUserDetails(['regions']);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  regionSales(paramregionWise: any) {
    paramregionWise=this.regionWise;
    switch (paramregionWise) {
      case 0: {
        this.urlpath = 'region_wise_sales';
        this.datePickerenable = true;
        break;
      }
      // case 1: {
      //   this.urlpath = '/region_wise_collection';
      //   this.datePickerenable = true;
      //   break;
      // }
      case 1: {
        this.urlpath = 'region_wise_osod';
        this.datePickerenable = false;
        break;
      }
    }
    this.selectedDate = this.bsValue;
    this.filterapplied = true;
    //this.activeTab = activeTab;
    this.furType = "region";
    this.plantCondition = true;
    this.selectedDate = moment(this.bsValue).format('YYYY-MM');
    this.dtOptions = {};
    
    this.finalpostObj = {
      'region': this.regions,
      'date': this.selectedDate,
      'username':this.userId_val
    }
    this.displayTable(this.urlpath, this.finalpostObj, this.furType);
    //this.bsValue=new Date();
  }
  displayTable(urlpath, finalpostObj, furType) {
    this.furType = furType;
    this.tableCondition = false;

    this.regionservice.saveInfo(urlpath, finalpostObj).subscribe(
      (response) => {

        if(response['data']['data1']!=undefined && response['status']=='SUCCESS'){
          let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
          this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
            console.log(reportdata);
          })
        }
        this.finalDataObject = this.regionservice.post(response);

        if (this.finalDataObject) {
          this.dtOptions = this.finalDataObject.table;
          if (this.dtOptions != null) {
            this.tableCondition = true;
          }
        }
        console.log(this.dtOptions)
      }
    )
  }
}
