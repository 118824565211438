import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CustomerService } from 'src/app/fur/customer.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { environment } from 'src/environments/environment';
import { SelfServeService } from 'src/app/services/self-serve.service';
const moment = extendMoment(Moment);
@Component({
  selector: 'app-customer-sales',
  templateUrl: './customer-sales.component.html',
  styleUrls: ['./customer-sales.component.css']
})
export class CustomerSalesComponent implements OnInit {
  reportName = "";
  selectedDate: any;
  datePickerenable: any;
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public datePickerConfigForSupply: any = {};
  public bsValue: any = new Date();

  activeverticalTab = '';
  filterapplied: boolean;
  urlpath: string;
  activeTab: any;
  furType: string;
  finalpostObj: {  };
  regions: any;
  dropdownSettings = {};
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  selectedRegions: any;
  dtOptionsenable2: boolean;
  plantclicked: boolean;
  cohortBoolean: boolean;
  smeclicked: boolean;
  supplyclicked: boolean;
  clicked1: boolean;
  cohortclicked: boolean;
  teamclicked: boolean;
  plantCondition = false;
  tableCondition = false;
  finalDataObject = { table: {}, excel: [] };
  userId_val :any;
  @Input('customerWise') customerWise = 0;
  datamessageDate = ""
  constructor(private navbarTitle: NavbarComponent, private service: AppService,
     private customerservice: CustomerService,private userId: AppService,private Service: SelfServeService  ) { 
    this.userId_val =  this.userId.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
  }

  ngOnInit() {
    this.customerWise=0;
    this.selectedRegions = this.service.getUserDetails(['regions']);
    this.getDataByFilter();
    this.multiSelectDropdown();
    this.finalpostObj = {'date':"2019-08",'region':this.service.userDetails.regions}
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.regions = this.service.getUserDetails(['regions']);
    this.multiSelectDropdown();
    this.bsValue=new Date();


    this.customerSales(this.customerWise);

  }



  getDataByFilter(date = this.bsValue) {

    console.log(this.selectedRegions);
    this.selectedDate = moment(date).format('YYYY-MM');

    if (this.customerWise === 0)
      this.customerSales(this.customerWise);
    if (this.customerWise === 1)
    this.customerSales(this.customerWise);
    if (this.customerWise === 2)
    this.customerSales(this.customerWise);



  }

  Customer(activeTab) {
    this.activeverticalTab = activeTab;
    this.teamclicked = false;
    this.clicked1 = false;
    this.cohortclicked = false;
    this.supplyclicked = false;
    this.smeclicked = false;
    this.cohortBoolean = false;
    this.plantclicked = true;
    this.tableCondition = false;
    this.dtOptionsenable = false;
    this.dtOptionsenable2 = false;


  }
  multiSelectDropdown() {
    this.regions = this.service.getUserDetails(['regions']);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  customerSales(paramcustomerWise:any) {
    paramcustomerWise=this.customerWise;
    switch (paramcustomerWise) {
      case 0: {
        this.urlpath = 'customer_wise_sales';
        this.datePickerenable=true;

        break;
      }
      case 1: {
        this.urlpath = 'customer_wise_osod';
        this.datePickerenable=false;

        break;
      }
    }
    this.selectedDate = this.bsValue;


    this.filterapplied = true;
    //this.activeTab = activeTab;
    this.furType = "region";
    this.plantCondition = true;
    this.selectedDate = moment(this.bsValue).format('YYYY-MM');

    this.dtOptions = {};
    this.finalpostObj = {
      'date': this.selectedDate,
      'region': this.regions,
      'username':this.userId_val
     

    }
    this.displayTable(this.urlpath, this.finalpostObj, this.furType);
    //this.bsValue=new Date();
  }
  displayTable(urlpath, finalpostObj, furType) {
    this.furType = furType;
    this.tableCondition = false;


    this.customerservice.saveInfo(urlpath, finalpostObj).subscribe(
      (response) => {
     
          this.datamessageDate = response['message'];
    if(response['data']['data1']!=undefined && response['status']=='SUCCESS'){
      let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
      this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
        console.log(reportdata);
      })
    }
        this.finalDataObject = this.customerservice.post(response);

        if (this.finalDataObject) {
          this.dtOptions = this.finalDataObject.table;
          if (this.dtOptions != null) {
            this.tableCondition = true;
          }
        }
        console.log(this.dtOptions)
      }
    )


  }

  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.finalDataObject.excel, 'Enterprise');
   
  }
}
