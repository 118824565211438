import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';
const fur_baseURL: string = environment.baseURL_FREQ; 

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval="";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
//  BASEURL: string = 'https://analytics-web.moglilabs.com/freq_report';

  constructor(private http:HttpClient) { }
  saveInfo(urlpath,data){
        
    return this.http.post(fur_baseURL+urlpath,data)
  } 
  post(finalpostObj) {
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
        this.timestampval=finalpostObj['dateval'];
      this.headers = finalpostObj['data']['headers'];
      this.finalresult = finalpostObj['data']['data1'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
  }
  
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
        console.log(rowData);
        
         if(col == 0){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }
        if(col == 1){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }
        }
        
      });
    }
    this.dtOptions = {
       destroy: true,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,      
      ordering:false,
      fixedHeader: true,
      fixedColumns: {
        leftColumns: 2
            
      },
     scrollY: '70vh',
      //pageLength:0,
     paging:false,
      //info: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">' ,
      language:{
         info: this.timestampval,
        
     "lengthMenu": "",
     //"zeroRecords": "",
     
     "infoEmpty": "",
     "infoFiltered": ""
      }
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
}
