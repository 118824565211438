  import { Injectable } from '@angular/core';
  import { HttpClient } from '@angular/common/http';
  @Injectable({
    providedIn: 'root'
  })
  export class TeamWiseSalesService {

    headers: any[];
    finalresult: any[];
    dtOptions: {};
    responseList: any[];
    responseListExcel = [];
    public finalDataObject = { table: {}, excel: [] };
    dtOptionsenable = false;
    timestampval = "";
    LOGINURL: string = '/request';
    readonly pivottablefiltersinput = '/request';
    constructor(private http: HttpClient) { }
    saveInfo(urlpath, data) {

      return this.http.post(urlpath, data)
    }

    postReturns(finalpostObj) {
      console.log(finalpostObj)
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
      this.headers = finalpostObj['returnsCols'];
      this.finalresult = finalpostObj['returnsRows'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
    }
    postOSOD(finalpostObj) {
      console.log(finalpostObj)
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
      this.headers = finalpostObj['osodCols'];
      this.finalresult = finalpostObj['osodRows'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
    }
    post(finalpostObj) {
      console.log(finalpostObj)
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
      this.headers = finalpostObj['invoiceCols'];
      this.finalresult = finalpostObj['invoiceRows'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
    }
    postMgmt(finalpostObj) {
      console.log(finalpostObj)
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
      this.headers = finalpostObj['salesCols'];
      this.finalresult = finalpostObj['salesRows'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
    }

    postRaw(finalpostObj) {
      console.log(finalpostObj)
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.dtOptionsenable = false;
      this.headers = finalpostObj['final_data_Cols'];
      this.finalresult = finalpostObj['final_data_Rows'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);
      if (this.finalDataObject)
        return this.finalDataObject
    }
    sortedResponseList(finalresult) {
      for (var i in finalresult) {
        var obj = finalresult[i];
        let orderObject = {};
        let orderobjectwithoutcomma = {}
        for (var j in this.headers) {
          for (const key of Object.keys(obj)) {
            if (this.headers[j] == key) {
              orderObject[key] = obj[key].toLocaleString();
              
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
            }
          }
        }
        this.responseList.push(orderobjectwithoutcomma);
        this.responseListExcel.push(orderobjectwithoutcomma);

      }
      if (this.responseList){
        this.dtOptions = this.tabledata(this.responseList);
      }
        
      if (this.responseList && this.responseListExcel)
        this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
      return this.finalDataObject;
    }
    tabledata(tableData) {
      let headerData = [];
      //alert(this.headers);
      for (let item of this.headers) {
        headerData.push({ title: item, data: item , "createdCell": function (td, cellData, rowData, row, col) {
          if(col == 0){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }

        if(col == 1){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }

        if(col == 2){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }
        }
      });
      }
      this.dtOptions = {
        responsive: true,
        autoWidth: false,
        scrollX: true,
        scrollCollapse: true,
          fixedColumns:{
        leftColumns:2
      },
        scrollY: '70vh',
        //pageLength: 10,
        paging: false,
        data: tableData,
        columns: headerData,  
        dom: '<"top"i><"bottom"flp>rt<"clear">',
        
        columnDefs: [
          {
            width: "300",
            targets: '_all',
            render: function (data, type, row, meta) {
              
              let title = meta.col;
              let GMpercent = (row["Attributes"] == "GM%") ? true : false;
              let Trendingpercent = (row["Attributes"] == "% Trending") ? true : false;
              // let Over30Days = (row["Attributes"] == "Overdue > 30 Days (Lakhs)") ? true : false;
              let AchievedLakhs = (row["Attributes"] == "Achieved (Lakhs)") ? true : false;
              let NetSalesExcludingTaxLakhs = (row["Attributes"] == "Net Sales Excluding Tax (Lakhs)") ? true : false;
              let ReturnSalesLakhs = (row["Attributes"] == "Return Sales (Lakhs)") ? true : false;
              let COGSexcludingTaxLakhs = (row["Attributes"] == "COGS excluding Tax (Lakhs)"? true :false)
              let OverdueLakhs = (row["Attributes"] == "Overdue (Lakhs)"? true :false)
              let SaleswithLaks = (row["Attributes"] == "Sales with Tax (Lakhs)"?true:false)
              //let Overdue30DaysLakhs = (row["Attributes"] == "Overdue > 30 Days (Lakhs)"? true :false)
            // data = data.toString().replace(/\"/g, "");
              //data = (data.length > 40)?'<a  title="' + data + '" >'+data.substring(0, 40)+'...'+'</a>':'<span class>'+data+'</span>';
            
          
            // if (GMpercent || Trendingpercent || Over30Days || AchievedLakhs || NetSalesExcludingTaxLakhs || ReturnSalesLakhs || COGSexcludingTaxLakhs || Overdue30DaysLakhs || OverdueLakhs)
              //  return '<b id="cellvalue">' + data + '</b>';
              //else               
              // return '<span id="cellvalue">' + data + '</span>';
                  let textvalue;
                  if ( SaleswithLaks || AchievedLakhs || ReturnSalesLakhs ||  OverdueLakhs) {
                  //  if (Over30Days || AchievedLakhs || ReturnSalesLakhs  || OverdueLakhs) {

                if (data == "0") {

                  return textvalue = data;
                }

                if (title == 0 || title == 1) {
                  textvalue = '<b id="cellvalue" style="color:blue;pointer-events: none;">' + data + '</b>';
                  return textvalue;
                }
                if (title != 0 || title != 1) {
                  if (data != "0") {
                    textvalue = '<b id="cellvalue" style="color:blue;cursor:pointer;"><u>' + data + '</u></b>'
                    return textvalue;
                  }
                  if(data == "0") {
                    return textvalue=data;
                  }
                }
              }
              if (GMpercent || Trendingpercent || NetSalesExcludingTaxLakhs)
                return '<b id="cellvalue">' + data + '</b>';
              if (!(Trendingpercent || NetSalesExcludingTaxLakhs || GMpercent ||  AchievedLakhs || OverdueLakhs))
              //if (!(Trendingpercent || NetSalesExcludingTaxLakhs || GMpercent || Over30Days || AchievedLakhs || Overdue30DaysLakhs || OverdueLakhs))
              
              return '<span id="cellvalue">' + data + '</span>';
            }

          }
        ],

      

        language: {
          info: this.timestampval,

          "lengthMenu": "",
          //"zeroRecords": "",

          "infoEmpty": "",
          "infoFiltered": ""
        }
      };
      if (this.dtOptions) {
        this.dtOptionsenable = true;
      }
      return this.dtOptions;
    }
  }
