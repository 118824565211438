import { Component, OnInit } from '@angular/core';
import { AdvancePOSupplyService } from 'src/app/services/advance-posupply.service';
import { AppService } from 'src/app/services/app.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { SMEMgmtScoreCardService } from 'src/app/services/sme-mgmt-score-cardservice.service';
import { environment } from 'src/environments/environment';
import { Report101Service } from 'src/app/services/report101.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-plant-master',
  templateUrl: './plant-master.component.html',
  styleUrls: ['./plant-master.component.css']
})
export class PlantMasterComponent implements OnInit {
  userId_val: any;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  reportlabel = '';
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  viewDetailsTable: boolean;

  DateCalender: any;
  req_start_date: string;
  req_end_date: string;
  mngmt_data: any;
  requestBody = {};
  teamfinalpostObj: {};
  tableCondition: boolean;
  visualTable: boolean;
  TeamRegionsListObj: any = {};
  regions: any = [];
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  teams: any[];
  selectedL3: any[];
  customers: any[];
  selectedL4: any[];
  plants: any[];
  RkeyValue: any;
  filterData: any = {};

  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  categoriesdropdownSettings = {};

  Categories: any;
  category: any;
  selectedCategories: any[];
  exceldata: any;
  catL1list: any;
  selectedL1=[];
  openFilters: boolean;
  payload: any;
  regionsList: any;
  userId: any;
  
  
  constructor(private appService: AppService,
                private toaster: ToastrService,    
    private smemgmtScoreCardservice: SMEMgmtScoreCardService,
    private advancePOsupply: AdvancePOSupplyService,
    private selfService: SelfServeService,
              private reportseivce:Report101Service) { }

  ngOnInit() {

    this.multiSelectDropdown();
    this.getRegionsList()
  }
  multiSelectDropdown() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }

  getRegionsList() {
    this.payload = {}
    this.appService.saveInfo('https://analytics-web.moglix.com/freq_report/dimsysRegion',{"list":[]}).subscribe(resp => {
      this.regionsList = resp['regionList']
      this.selectedRegions = this.regionsList;
      this.getDataByFilter()
    })
  }
  onRegionSelect(item: string) {

  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regionsList;

  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedRegions = [];
  }
  generatePayload() {
    const payload = {
      "regions": this.selectedRegions
    }
    console.log(payload)

    return payload;
  }
 getDataByFilter() {
    const payload = this.generatePayload();

    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};

    this.tableCondition = false;
    this.visualTable = false;

    this.appService.saveInfo('https://analytics-web.moglix.com/freq_report/dimSYSPlantMapping', payload).subscribe(resp => {
      // if (resp['data']['result'] != undefined && resp['status'] == 'SUCCESS') {
      //   let reportRequest = { 'username': this.userId_val, 'report_name': this.reportlabel };
      //   this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
      //     console.log(reportdata);
      //   })
      // }

      this.mngmt_data = this.reportseivce.postplantMapping(resp['data']);
      if (this.mngmt_data) {
        this.toaster.show("Please wait to the view report")
        this.dtOptions = this.mngmt_data.table;
        this.exceldata = this.mngmt_data.excel;
        console.log(this.exceldata)
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
          this.openFilters = false;
        }
      }
    })
  }


  exportAsXLSX(): void {
    this.selfService.exportAsExcelFile(this.exceldata, 'Plant-Mapping');
   
  }
}
