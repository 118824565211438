import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { NamService } from 'src/app/services/nam.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
   selector: 'app-nam',
   templateUrl: './nam.component.html',
   styleUrls: ['./nam.component.css']
})
export class NamComponent implements OnInit {
   url = 'https://mdm.moglix.com/search/customerRecord';
   searchValue = '';
   searchText$: Subject<string> = new Subject();
   requestbody = {};
   dropdownValue: "";
   dtOptions: DataTables.Settings = {};
   final = {};
   selected = 1;
   userId_val: any;
   tableDisplay: boolean;
   reportName = "";
   filterData: any = {};
   RkeyValue: any;
   isOpen = false;
   userInput = '';
   currentDate: any;
   currentMonth: any;
   dimUserTargetList = [];
   userTargetsArray = [];
   constructor(private navbarTitle: NavbarComponent, private namService: NamService, private commonService: CommonService,
      private userId: AppService,
      private Service: SelfServeService, private appService: AppService) {
      this.reportName = this.navbarTitle.reportlabel;
      this.userId_val = this.userId.userDetails.email;
   }
   ngOnInit() {
      const date = new Date();
      this.currentDate = date.getDate();
      let month = (date.getMonth() + 1).toString();
      const year = date.getFullYear();
      if (month <= '3') {
         this.userTargetsArray = [`${year}-Q1`];
      } else if (month > '3' && month <= '6') {
         this.userTargetsArray = [`${year}-Q2`];
      } else if (month > '6' && month <= '9') {
         this.userTargetsArray = [`${year}-Q3`];
      } else if (month > '9' && month <= '12') {
         this.userTargetsArray = [`${year}-Q4`];
      }
      this.userTargetsArray.push('National_Account_Manager');
      month = month.length == 1 ? '0' + month : month;
      this.currentMonth = month;
      this.userInput = `${year}-${month}`;
      this.searchDebouncer();
      this.getDataByFilter();
   }
   searchDebouncer() {
      this.searchText$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((term: any) => {
          if(term.length > 1 || term.length == 0) {
            this.getDataByFilter(term);
          }
        });
    }

   updateSearch(event) {
      this.searchText$.next(event.target.value);
   }

   updateTargets() {
      const url = 'https://mdm.moglix.com/insertMDMCustomerTarget';
      this.dimUserTargetList = this.dimUserTargetList.filter(function( obj ) {
         return obj.nationalAccountManager != '' && obj.nationalAccountManager != '0';
       });
       if(this.dimUserTargetList.length == 0) {
          this.commonService.showToast('Please enter Account Manager', 2);
         return;
       }
      this.namService.updateTargets(url, { mdmCustomerTargetsList: this.dimUserTargetList }).subscribe((resp) => {
         this.commonService.showToast(resp['message'], 1);
         this.dimUserTargetList = [];
      }, error => {
         this.commonService.showToast(error.error['message'], 3);
      })
   }

   validateEmail(email) { 
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(re.test(email)){
          //Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
          if(email.indexOf("@moglix.com", email.length - "@moglix.com".length) !== -1){
            return true;
          } else {
            this.commonService.showToast('Please enter valid moglix Email id', 2);
            return false;
          }
      } else {
         this.commonService.showToast('Please enter valid moglix Email id', 2);
         return false;
      }
  }


   getDataByFilter(searchString = '') {
      const requestBody = {
         "username": this.appService.userDetails.email,
         "search": searchString,
         "timeFrame": this.userInput,
         "ymType":"Quarter",
         "kpiType":"Targets"
      }
      this.namService.saveInfo(this.url, requestBody).subscribe((data) => {
         if (data['result'] != undefined && data['headers'] != undefined && data['result'].length) {
            let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
            this.namService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
            })
         } else {
            this.commonService.showToast("No Customer(s) found!!", 2);
            return false;
         }
         if (data['result'] != undefined && data['headers'] != undefined) {
            for (let l = 0; l < data['result'].length; l++) {
               for (let h = 0; h < data['headers'].length; h++) {

                  if (data['headers'][h] == 'Name') {
                     if (data['result'][l][data['headers'][h]].length > 40) {
                        data['result'][l][data['headers'][h]] = '<span title="' + data['result'][l][data['headers'][h]] + '">' + data['result'][l][data['headers'][h]].substring(0, 20) + "..." + '</span>'
                     }
                  }
               }
            }

            this.final = this.namService.post(data)
            if (this.final['table']) {
               this.dtOptions = this.final['table'];
               if (this.dtOptions != null) {
                  this.tableDisplay = true;
               }
            }
         }
         else {
            this.commonService.showToast(data['message'], 2);
         }
      });
   }

   change(row, column) {
      let validEmail = false;
      if(column.title == 'National_Account_Manager') {
         validEmail = this.validateEmail(row["National_Account_Manager"]);
         if(!validEmail) {
            row['National_Account_Manager'] = '';
         }
      }
      if(column.title == this.userTargetsArray[0]) {
         console.log(+row[this.userTargetsArray[0]])
         if(+row[this.userTargetsArray[0]] < 0) {
            this.commonService.showToast('Please enter a positive value', 2)
            row[this.userTargetsArray[0]] = 0;
            return;
         }
      }
      if(validEmail || column.title !== 'National_Account_Manager') {
         if (this.dimUserTargetList.length) {
            const i = this.dimUserTargetList.findIndex(each => (each.customerName == row['Customer_Name']));
            if (i == -1) {
               this.dimUserTargetList.push(this.createObject(row, column));
            } else {
               this.dimUserTargetList[i] = this.createObject(row, column);
            }
         } else {
            this.dimUserTargetList.push(this.createObject(row, column));
         }
      }
      console.log(this.dimUserTargetList);
   }

   createObject(row, column) {
      // "target": "210",
      // "ym": "2022-11",
      return {
         "customerId": row['Customer_Id'] || null,
         "customerName": row['Customer_Name'] || null,
         "nationalAccountManager": row['National_Account_Manager'] || null,
         "timeFrame": this.userTargetsArray[0] || null,
         "target": '' + Number(row[this.userTargetsArray[0]]) || null,
         "ymType":"Quarter",
         "kpiType": "Targets"
      }
   }
}
//   const api = 'https://analytics-web.moglilabs.com/freq_report/merge_invoice_pod';
  // this.namService.getTargetViews(api, payload).subscribe(resp => {
  //   this.commonService.showToast(resp['message'], 1);
  //   this.message = resp['message'];
  // })

    // this.namService.saveInfo(this.url, this.requestbody).subscribe((data) => {