import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';
import { text } from '@angular/core/src/render3';


@Injectable({
  providedIn: 'root'
})

export class Buscorecardtop20Service {

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';

  constructor(private http: HttpClient) { }
  saveInfo(urlpath, data) {

    return this.http.post(urlpath, data)
  }
  post(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers']
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postMgmt(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }

  posttop20(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseListtop20(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }




  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            //orderObject[key] = obj[key].toLocaleString();

            orderObject[key] = obj[key];
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
            
        if ( item.includes('Q') ) {
          $(td).css({'background-color':'#fff7df'})
        }
      } });
    }
    this.dtOptions = {

      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
    
  
      scrollY: '62vh',
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',

      columnDefs: [
        {
          width: "300",
          targets: '_all',
          render: function (data, type, row) {
            let GMpercent = (row["Param(in Lakhs)"] == "Total GMV") ? true : false;

            let dt = data.toString().replace(/\"/g, "");

            data = (dt == GMpercent) ? '<b id="cellvalue">' + dt + '</b>' : dt;

            if (data == "Total GMV" || data == "GM(%)" || data == "CM1(%)" || data == "CM2(%)")
              return '<b id="cellvalue" style="color:red;">' + data + '</b>';

            if(data == "Logistics Cost(%)"|| data == "Runner Cost(%)"|| data == "TOD Provisioning(%)"
            || data == "Marketing Cost"|| data == "Payroll & Infra(%)"|| data == "OD>30 Days"
            || data == "Inventory"|| data == "Inventory - Fresh"|| data == "Inventory - Return"
            || data == "AR-AP"|| data == "HC")
              return '<i id="cellvalue" style="color:blue;">' + data + '</i>';
            if(data != "Total GMV" || data != "GM(%)" || data != "CM1(%)" || data != "CM2(%)" 
            || data != "Logistics Cost(%)"|| data != "Runner Cost(%)"|| data != "TOD Provisioning(%)"
            || data != "Marketing Cost"|| data != "Payroll & Infra(%)"|| data != "OD>30 Days"
            || data != "Inventory"|| data != "Inventory - Fresh"|| data != "Inventory - Return"
            || data != "AR-AP"|| data != "HC")
              return '<span>' + data + '</span>';

          }

        }
      ],
      language: {
        info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
      
        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }


  sortedResponseListtop20(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            //orderObject[key] = obj[key].toLocaleString();

            orderObject[key] = obj[key];
            orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledatatop20(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledatatop20(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
            
        if ( item.includes('Q') ) {
          $(td).css({'background-color':'#fff7df'})
        }
      } });
    }
    this.dtOptions = {

      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
  
    
      scrollY: '62vh',
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',

      language: {
        info: this.timestampval,

        search: '<i style="font-size:12px;">Achieved/Target is <b style="color:red">0-85%</b>, <b style="color:#e59a0f">86%-95%</b>, <b style="color:green">>95%</b></i>',
        searchPlaceholder: 'Search',
      
        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
}
