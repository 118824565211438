  import { isNumber } from 'highcharts/highcharts.src';
  import { FormGroup, FormControl, Validators } from '@angular/forms';
  import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
  
  import * as Moment from 'moment';
  import { extendMoment } from 'moment-range';
  //import { formatDate } from 'ngx-bootstrap/chronos/format';
  import { formatDate } from '@angular/common';
  import { AppService } from 'src/app/services/app.service';
  import { SelfServeService } from 'src/app/services/self-serve.service';
  import { CommonService } from 'src/app/shared/common.service';
import { SupplyService } from 'src/app/fur/supply.service';
  
  const moment = extendMoment(Moment);
  const dateFormat = 'y-MM-dd';
  const dateLocale = 'en-IN';
  @Component({
    selector: 'app-unused-suppliers',
    templateUrl: './unused-suppliers.component.html',
    styleUrls: ['./unused-suppliers.component.css']
  })
  export class UnusedSuppliersComponent implements OnInit {
    
    cellval:any;
    //used variables
    finalDataObject = { table: {}, excel: [] };
    finalDataObject2 = { table: {}, excel: [] };
    userId_val:any;
    selectedlevel1_val : number; 
    urlpathlevel2 :any;
    level2: {};
    dtOptionsenable: boolean;
    dtOptionsenable2: boolean;
    dtOptions: DataTables.Settings = {};
    dtOptions2: DataTables.Settings = {};
    urlpath: string;
    responseListExcel2=[];
    public selectedRowValue: string;
    public selectedColumnValue: string;
    @Input('supplyWise') supplyWise = 0;
    private fromDate = new Date();
    private toDate = new Date();
    criteriaForm = new FormGroup({
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required)
    })
  
    constructor(private commonService:CommonService,
      private service:SelfServeService,private supplyservice: SupplyService,private userId: AppService ) { 
      this.userId_val =  this.userId.userDetails.email;
    }
  
    ngOnInit() { 
      this.initialDate();
      this.supplyWise=0;
      this.supplySales(this.supplyWise)
    
    }
    ngOnChanges(changes: SimpleChanges) {
      this.initialDate();
      this.supplySales(this.supplyWise);
    }
    supplySales(paramsupply: any) {
      let requestObj = this.criteriaForm.value;
      requestObj['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
      requestObj['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
      requestObj['username'] = this.userId_val;
      
      //requestObj['length'] = 'week';
      
      switch (paramsupply) {
        case 0: {
          this.urlpath = 'po_pending';
          this.selectedlevel1_val = 0;
          break;
        }
  
      }
      this.displayTable(this.urlpath, requestObj);
    }
  
    setRowColumnvalues = (cell:Number, r: string, c: string) => {
      let requestObjlevel2 = this.criteriaForm.value;
      this.cellval= cell
  
      
      
      //this.displayTable2(urlpathlevel2, requestObjlevel2);
      switch (this.selectedlevel1_val) {
  
        case 2: {
          this.urlpathlevel2 = 'auto_po_lvl2';
          requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
          requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
          requestObjlevel2['status'] = [c];
          requestObjlevel2['region'] = [r];
          requestObjlevel2['username'] = this.userId_val;
          this.displayTable2(this.urlpathlevel2, requestObjlevel2);
          break;
        }
        case 3: {
          this.urlpathlevel2 = 'po_cancellation_detailed';
          requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
          requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
          requestObjlevel2['type'] = c;
          requestObjlevel2['region'] = r;
          requestObjlevel2['username'] = this.userId_val;
          this.displayTable2(this.urlpathlevel2, requestObjlevel2);
          break;
        }
        case 4: {
          this.urlpathlevel2 = 'track_client_po_items_lvl2';
          requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
          requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
          requestObjlevel2['status'] = [c];
          requestObjlevel2['region'] = [r];
          requestObjlevel2['username'] = this.userId_val;
          this.displayTable2(this.urlpathlevel2, requestObjlevel2);
          break;
        }
        case 5: {
          this.urlpathlevel2 = 'track_client_invoice_lvl2';
          requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
          requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
          requestObjlevel2['days'] = [c];
          requestObjlevel2['region'] = [r];
          requestObjlevel2['username'] = this.userId_val;
          this.displayTable2(this.urlpathlevel2, requestObjlevel2);
          break;
        }
      }
    }
    testClick(event) {
      var self = this;
      $('#example tbody').on('click', 'td', function () {
        let table = $('#example').DataTable();
        let idx = table.cell(this).index().column;
        let rowIdx = table.cell(this).index().row;
        let title = table.column(idx).header();
        this.selectedRowValue = table.cell(rowIdx, 0).data();
        this.selectedColumnValue = $(title).html();
        let cell = table.cell( this );
  
        if(this.selectedColumnValue != "#POs" && this.selectedColumnValue != "#Suppliers")
        self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue);
  
      });
    }
  
    displayTable(urlpath, requestObj) {
      this.dtOptionsenable2 = false;
      this.dtOptionsenable = false;
      if(this.urlpath != "track_client_invoice_lvl1"){
        this.supplyservice.saveInfo(urlpath, requestObj).subscribe(
          (response) => {
            this.finalDataObject = this.supplyservice.post(response);
            this.dtOptions = this.finalDataObject.table;
            console.log("tabledata",  this.finalDataObject.table)
            this.dtOptionsenable = true;
          
          }
        )
      }
      if(this.urlpath == "track_client_invoice_lvl1"){
        this.supplyservice.getInfo(urlpath).subscribe(
          (response) => {
            this.finalDataObject = this.supplyservice.post(response);
            this.dtOptions = this.finalDataObject.table;
            console.log("tabledata", this.finalDataObject)
            this.dtOptionsenable = true;
          }
        )
      }
    }
    displayTable2(urlpath, requestObj) {
      if(this.cellval !=0){
        this.dtOptionsenable = false;
        this.supplyservice.saveInfo(urlpath, requestObj).subscribe(
          (response) => {
            this.finalDataObject2 = this.supplyservice.post(response);
            this.dtOptions2 = this.finalDataObject2.table;
            this.dtOptionsenable2 = true;
          }
        )
      }
      else{
        this.commonService.showToast("No Details exist for this !!",2);
      }
    }
  
    initialDate() {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.fromDate.setDate(this.fromDate.getDate() - 8);
      this.toDate.setDate(this.toDate.getDate() - 1);
      this.start_date.setValue(this.fromDate);
      this.end_date.setValue(this.toDate);
    }
    backButton() {
      this.dtOptionsenable = !this.dtOptionsenable;
      this.dtOptionsenable2 = !this.dtOptionsenable2;
    }
    //getters
    get start_date() { return this.criteriaForm.get('start_date') };
    get end_date() { return this.criteriaForm.get('end_date') };
  
    exportAsXLSX(): void {
      this.service.exportAsExcelFile(this.responseListExcel2, 'ClientPO');
      
    }
  }
  