import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from '../../services/app.service';
import * as Moment from 'moment';

import { extendMoment } from 'moment-range';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import * as Highcharts from 'highcharts';
import { CommonService } from '../../shared/common.service';
import { MgmtScoreCardService } from 'src/app/services/mgmt-score-cardservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelfServeService } from 'src/app/services/self-serve.service';
//import { MgmtComponent } from 'src/app/mgmt-score-card/mgmt/mgmt.component';
import { NavbarComponent } from '../navbar/navbar.component';

import { MgmtService } from '../../services/mgmt.service';
import { TeamWiseSalesService } from 'src/app/services/team-wise-sales.service';
import * as moment from 'moment';

const analytics_baseURL = environment.analytics_baseURL;

@Component({
  selector: 'app-enterprise-team-wise',
  templateUrl: './enterprise-team-wise.component.html',
  styleUrls: ['./enterprise-team-wise.component.css']
})
export class EnterpriseTeamWiseComponent implements OnInit {
  reportName = '';

  currentdateValue: any;
  currentDate = new Date();

  dtOptionspickup: DataTables.Settings = {};
  detailedReportStatus: boolean;
  dateval_mgmt_scorecard: string;
  dateval_mgmt_osod: string;
  sccorecard_mgmt_month: any;
  osod_mgmt_month: any;
  dtOptionsenable0: boolean;
  dtOptionsenable_1: boolean;
  finalDataObjectpickup = { table: {}, excel: [] };
  detailedfinalDataObjectpickup = { table: {}, excel: [] };

  finalDataObject = { table: {}, excel: [] };

  finalDataObject2 = { table: {}, excel: [] };

  excelData2 = [];
  excelData4 = [];
  excelData5 = [];
  excelData6 = [];
  finalDataObject3 = { table: {}, excel: [] };
  finalDataObject4 = { table: {}, excel: [] };
  finalDataObject5 = { table: {}, excel: [] };
  finalDataObject6 = { table: {}, excel: [] };

  excelData3 = [];
  public selectedRowValue_TypeVal: string;
  public selectedRowValue_Team_TypeVal: string;
  public selectedColumnValue: string;
  public visualTable: any;
  public bsValue: any = new Date();

  public discountData: any = [];
  public sparkLineList: any = {};
  public mapData: any = {};
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public minDate: any = new Date('2019-04-01');
  public maxDate: any = new Date();
  public deliveryList: any = [];
  public mapChartOptions: any = {};
  public baseURL_freq: any;
  tableCondition: boolean;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  dtOptions5: DataTables.Settings = {};
  dtOptions6: DataTables.Settings = {};
  regions = [];
  mngmt_data: any;
  selectedRegions = [];
  dropdownSettings = {};
  popUp: boolean;
  selectedRowValue: string;
  selectedColumnValues: any;
  userComment: string;
  commentForm: FormGroup;
  commentFormGroup: FormGroup;
  modalValue = 'inactive';
  userCommentList = [];
  selected = 2;
  realtime: any;
  enterpriseMenuList = [
    { 'title': "Enterprise Dashboard", id: 0 },
    { 'title': "Summary  - Regions", id: 5 },
    { 'title': "Region-wise Enterprise", id: 1 },
    { 'title': "Team-wise Enterprise", id: 2 }
  ]

  userId_val: any;


  // Tabular Region,Team,Plant
  regionDropdownSettings = {};
  teamDropdownSettings = {};
  plantDropdownSettings = {};
  // Tabular Selected Items
  public selectedRegionsList = [];
  public selectedTeamsList = [];
  public selectedPlantsList = [];
  // default List of Items
  regionsList: any;
  teamsList: any;
  plantsList: any;
  // obj list
  TeamRegionsListObj: any;
  PlantRegionsListObj: any;
  //Final object to get the data
  regionfinalpostObj: {};
  teamfinalpostObj: {};
  plantfinalpostObj: {};
  achieved: boolean;

  returnSales: boolean;
  COG: boolean;
  overdue: boolean;
  requestBody = {};
  url: string;
  urlpath: string;
  filterType: number;
  detailedTable: boolean;
  detailedTableOSOD: boolean;
  L1Title: string;
  L2Title: string;

  viewDetailsTable = '';
  detailedResponse: Object;
  detailedResponseOSOD: Object;
  detailedResponseReturns: Object;
  detailedResponsesummary: Object;
  invoice: boolean;
  sales: boolean;
  message: string;
  summary: boolean;
  osod: boolean;
  detailedTableReturns: boolean;
  returns: boolean;
  intervalValue: number;
  selectedTeam: string;
  selctedMonth: string;
  summaryselectedTeamValue: string;
  summaryselctedMonthValue: string;
  datamessageDate =""

  constructor(private navbarTitle: NavbarComponent, private mgmtService: MgmtService, private router: Router, private Service: SelfServeService,
    private teamwisesales: TeamWiseSalesService,

    private userId: AppService, private _fb: FormBuilder, private appService: AppService, private commonService: CommonService, private mgmtScoreCardservice: MgmtScoreCardService,) {
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
    this.getCommentForm();
    this.getdefaultRegionsList();

  }

  ngOnInit() {

    this.L1Title = localStorage.getItem("L1Title");
    this.L2Title = localStorage.getItem("L2Title");
    console.log(this.L1Title);
    //this.selectedRegions = this.appService.getUserDetails(['regions']);	
    this.TeamRegionsListObj = this.appService.TeamRegionsListObj;
    this.selectedRegionsList = this.appService.selectedRegionsList;
    this.regionsList = this.appService.regionsList;
    this.getCurrentRegionsTeams(this.selectedRegionsList);
    this.multiSelectDropdown();
    this.commentFormGroup = new FormGroup({
      userComment: new FormControl('')
    });
    this.getVisual(this.selected);
  }
  
  getReportName(){
    
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
  this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
  }
  getdefaultRegionsList() {
    this.appService.getTeamRegion(environment.baseURL_FREQ + UrlConfig.accessMapping, { username: this.userId_val }).subscribe(
      (response) => {


        this.TeamRegionsListObj = response["data"][0];
        this.selectedRegionsList = Object.keys(response['data'][0]);
        this.regionsList = Object.keys(response['data'][0]);
        this.getCurrentRegionsTeams(this.selectedRegionsList);
      });
  }
  getCurrentRegionsTeams(selectedRegionsList) {
    this.teamsList = [];
    this.selectedTeamsList = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getKey(selectedRegionsList[j]);
    }
  }
  getKey(key) {
    this.TeamRegionsListObj[key].forEach((team, index) => {
      this.selectedTeamsList.push(team);
      this.teamsList.push(team);
    });
  }
  multiSelectDropdown() {
    this.regions = this.appService.getUserDetails(['regions']);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.regionDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.plantDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  onSubmit({ value, valid }: { value: UserComment, valid: boolean }) {
    console.log(value.userComment, valid);
    this.userCommentList.push(value.userComment);
  }
  getCommentForm() {
    this.commentForm = this._fb.group({
      userComment: new FormControl('')
    });
  }
  saveComments() {
    this.commentForm.value.userComment;
    console.log(this.userComment);
  }
  close() {
    this.modalValue = '';
    this.modalValue = 'inactive';
    this.popUp = false;

  }

  currentactiveTab = 1;
  select(e_menu_id) {
    this.selected = e_menu_id;
  };
  isActive(e_menu_id) {
    return this.selected === e_menu_id;
  };

  getVisual(e_menu_id) {
    this.getdefaultRegionsList();
    this.selected = e_menu_id;
    this.currentactiveTab = e_menu_id;
    this.filterType = 1;
    switch (e_menu_id) {
      case 0: {
        break;
      }
      case 1: {
        this.selected = 1;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }
      case 2: {
        this.selected = 2;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }
      case 3: {
        this.selected = 3;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }

      case 5: {
        this.selected = 5;
        this.PendencyTables(0);
        break;
      }
    }
  }




  getvisualTable() {
    this.detailedTable = false;
    this.filterType = 1;
    this.visualTable = false;
    this.tableCondition = false;
    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};
    this.teamfinalpostObj = { 'team': this.selectedTeamsList, "username": this.userId_val };

    this.baseURL_freq = environment.baseURL_FREQ;
    switch (this.selected) {

      case 1: {
        this.url = 'sale_summary';
        this.finalData(this.url, this.regionfinalpostObj);
        break;
      }
      case 2: {
        this.url = 'sale_summary_teamwise';
        this.finalData(this.url, this.teamfinalpostObj);
        break;
      }
      case 3: {
        this.url = 'sale_summary_plantwise';
        this.finalData(this.url, this.plantfinalpostObj);
        break;
      }
    }

  }
  finalData(urlpath, finalObj) {
    this.requestBody = finalObj;
    this.appService.sendPostRequest(environment.baseURL_FREQ+'sale_summary_teamwise', this.requestBody).subscribe(res => {
      
      this.datamessageDate = res['message'];
      if (res['data']['data1'] != undefined && res['status'] == 'SUCCESS') {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
      }
      this.mngmt_data = this.mgmtScoreCardservice.post(res);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
        }
      }
    });
  }

  commentMgmt() {
    //console.log("form sun=bmit");
    console.log(this.commentForm)
  }




  setRowColumnvalues = (idx, cell: string, r: string, c: string) => {

    this.currentdateValue = moment(this.currentDate).format('YYYY-MM-DD');
    console.log(this.currentdateValue)
    // curr_month = this.currentdateValue.getMonth();
    // urr_year = this.currentdateValue.getFullYear()
    // console.log(curr_month)
    // console.log(curr_year)





    //     var selectedyear = moment(c).format('YYYY-MM')
    //     var selectedmonth=moment(c).format('YYYY-MM')
    // console.log(selectedyear)
    // console.log(selectedmonth)
    
    switch (idx) {
      case 3:
        this.intervalValue = 1
        break;
      case 4:
        this.intervalValue = 2
        break;
      case 5:
        this.intervalValue = 3
        break;
      case 6:
        this.intervalValue = 4
        break;

      // default:
      //   this.intervalValue = 1
      //   break;
    }

    let requestObj = { region: r, YM: c, intervalue: this.intervalValue }

    let teamObj = { team: r, YM: c,intervalue: this.intervalValue }
    this.summaryselectedTeamValue = r;
    this.summaryselctedMonthValue = c;
    if (this.selected == 1 && cell == "Sales with Tax (Lakhs)") {
      this.urlpath = "revenue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
      this.reportName = "Area-wise Region Sales Achieved"
      this.getReportName();

    }
    if (this.selected == 1 && cell == "Return Sales (Lakhs)") {
      this.urlpath = "returns_detailed_region"
      this.displayTable(this.urlpath, requestObj);
      this.reportName = "Area-wise Region Sales Returns"
      this.getReportName();
    }

    if (this.selected == 1 && cell == "COGS excluding Tax (Lakhs)") {
      this.urlpath = "cogs_detailed_region"
      this.displayTable(this.urlpath, requestObj);
      
      this.reportName = "Area-wise Region Sales COGs"
      this.getReportName();

    }
    if (this.selected == 1 && cell == "Overdue (Lakhs)") {
      this.urlpath = "overdue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
      
      this.reportName = "Area-wise Region Sales Overdue"
      this.getReportName();
    }
    // if(this.selected == 1 && cell =="Overdue > 30 Days (Lakhs)"){
    //   this.urlpath = "overdue_gr30days_detailed_region"
    //   this.displayTable(this.urlpath, requestObj);
    // }


    if (this.selected == 2 && cell == "Sales with Tax (Lakhs)") {
      this.urlpath = environment.baseURL_FREQ+"team_summary"
      this.displayTable(this.urlpath, teamObj);
      this.achieved = true;
      this.reportName = "Area-wise Team Sales Summary"
      this.getReportName();
    }
    if (this.selected == 2 && cell == "Return Sales (Lakhs)") {
      this.urlpath = environment.baseURL_FREQ+"returns_detailed_team"
      //this.returnDetailedTable(this.urlpath, teamObj);
      this.returnSales = true;
      this.displayTableReturns(this.urlpath, teamObj)
      this.reportName = "Area-wise Team Sales Returns"
      this.getReportName();
    }

    if (this.selected == 2 && cell == "COGS excluding Tax (Lakhs)") {
      this.urlpath = environment.baseURL_FREQ+"cogs_detailed_team";
      this.displayTable(this.urlpath, teamObj);
      this.COG = true;
      this.reportName = "Area-wise Team Sales COGS"
      this.getReportName();
    }
    if (this.selected == 2 && cell == "Overdue (Lakhs)") {
      this.urlpath = environment.baseURL_FREQ+"overdue_detailed_team"
      this.displayTableOSOD(this.urlpath, teamObj);
      this.overdue = true;
      this.reportName = "Area-wise Team Sales Overdue"
      this.getReportName();
    }
    // if(this.selected == 2 && cell =="Overdue > 30 Days (Lakhs)"){
    //   this.urlpath = "overdue_gr30days_detailed_team"
    //   this.displayTable(this.urlpath, teamObj);
    // }
  }
  backButton() {
    this.detailedTable = false;
    this.detailedTableOSOD = false;
    this.detailedTableReturns = false;
    this.osod = false;
    this.summary = false;
    this.returns = false;

    this.filterType = 1;
    this.tableCondition = !this.tableCondition;
    this.dtOptionsenable = !this.dtOptionsenable;
    //this.detailedTable = !this.detailedTable;
    this.dtOptions2={}
    this.dtOptions3={}
    this.dtOptions4={}
    this.dtOptions5={}
    this.dtOptions6={}
    this.summaryselectedTeamValue = ""
    this.summaryselctedMonthValue = ""

  }
  displayTable(path: String, requestObj: any) {
this.realtime = this.currentdateValue+" "+this.currentDate.getHours()+":"+this.currentDate.getMinutes()
    
    this.tableCondition = false


    this.teamwisesales.saveInfo(path, requestObj).subscribe(
      (response) => {
        
        this.detailedResponse = response;
        this.getachievedSummary();
      }
    )



  }
  displayTableOSOD(path: String, requestObj: any) {
this.realtime = this.currentdateValue+" "+this.currentDate.getHours()+":"+this.currentDate.getMinutes()
    
    this.tableCondition = false

    this.teamwisesales.saveInfo(path, requestObj).subscribe(
      (response) => {
        this.detailedResponseOSOD = response;
        this.getachievedOSOD();
      }
    )
  }
  displayTableReturns(path: String, requestObj: any) {
    this.realtime = this.currentdateValue+" "+this.currentDate.getHours()+":"+this.currentDate.getMinutes()
    
    this.tableCondition = false
    this.teamwisesales.saveInfo(path, requestObj).subscribe(
      (response) => {
        this.detailedResponseReturns = response;
        this.getReturns();
      }
    )
  }

  getReturns() {
    if (this.detailedResponseReturns['message'] != 'No Data') {
      this.dtOptions6 = {}
      this.finalDataObject6 = this.teamwisesales.postReturns(this.detailedResponseReturns);

      this.dtOptions6 = this.finalDataObject6.table;
      this.excelData6 = this.finalDataObject6.excel;

      this.detailedTableOSOD = false;
      this.detailedTable = false;

      this.detailedTableReturns = true;
      this.osod = false;
      this.sales = false;
      this.invoice = false;
      this.summary = false;
      this.returns = true;
    }
    else {
      this.message = "No Data"
    }

  }
  getachievedOSOD() {
    if (this.detailedResponseOSOD['message'] != 'No Data') {
      this.dtOptions5 = {}
      this.finalDataObject5 = this.teamwisesales.postOSOD(this.detailedResponseOSOD);



      this.dtOptions5 = this.finalDataObject5.table;
      this.excelData5 = this.finalDataObject5.excel;
      console.log(this.dtOptions5)


      this.detailedTableOSOD = true;
      this.detailedTableReturns = false;
      this.detailedTable = false;
      this.osod = true;
      this.sales = false;
      this.invoice = false;
      this.summary = false;
      this.returns = false;
    }
    else {
      this.message = "No Data"
    }

  }
  getachievedSummary() {
    if (this.detailedResponse['message'] != 'No Data') {
    
      this.dtOptions4 = {};
      this.finalDataObject4 = this.teamwisesales.postRaw(this.detailedResponse);

      this.dtOptions4 = this.finalDataObject4.table;
      this.excelData4 = this.finalDataObject4.excel;

      this.osod = false;
      this.sales = false;
      this.invoice = false;
      this.summary = true;
      this.returns = false;
      this.detailedTable = true;
      this.viewDetailsTable = 'achievedSummary';
    }
    else {
      this.message = "No Data"
    }

  }
  getachievedInvoice() {
    if (this.detailedResponse['message'] != 'No Data') {
      this.dtOptions2 = {};
      
      this.finalDataObject2 = this.teamwisesales.post(this.detailedResponse);

      this.dtOptions2 = this.finalDataObject2.table;
      this.excelData2 = this.finalDataObject2.excel;
      this.viewDetailsTable = 'achievedInvoice';

      this.detailedTable = true;
      this.sales = false;
      this.invoice = true;
      this.summary = false;
    }
    else {
      this.message = "No Data"
    }

  }
  getachievedSales() {
    this.dtOptions3 = {};
    if (this.detailedResponse['message'] != 'No Data') {
      
      this.finalDataObject3 = this.teamwisesales.postMgmt(this.detailedResponse);

      this.dtOptions3 = this.finalDataObject3.table;
      this.excelData3 = this.finalDataObject3.excel;
      this.viewDetailsTable = 'achievedSales';
      this.detailedTable = true;
      this.sales = true;
      this.invoice = false;
      this.summary = false;
    }
    else {
      this.message = "No Data"
    }
  }

  calldetailsData(event) {
    var self = this;
    $('#example tbody').on('click', 'td', function () {
      let table = $('#example').DataTable();
      let idx = table.cell(this).index().column;
      let rowIdx = table.cell(this).index().row;
      let title = table.column(idx).header();
      this.selectedRowValue = table.cell(rowIdx, 0).data();
      this.selectedRowValue_TypeVal = table.cell(rowIdx, 1).data();
      this.selectedRowValue_Team_TypeVal = table.cell(rowIdx, 2).data();
      this.selectedRowValue_Team = table.cell(rowIdx, 1).data();
      this.selectedColumnValue = $(title).html();
      console.log(idx)
      let cell = table.cell(this);
      if (this.selectedRowValue_TypeVal == "Sales with Tax (Lakhs)") {

        alert(this.selectedRowValue_Team_TypeVal)
        self.setRowColumnvalues(idx, this.selectedRowValue_TypeVal, this.selectedRowValue, this.selectedColumnValue);
        
      }

      if (this.selectedRowValue_TypeVal == "Return Sales (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_TypeVal, this.selectedRowValue, this.selectedColumnValue);
      }
      if (this.selectedRowValue_TypeVal == "COGS excluding Tax (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_TypeVal, this.selectedRowValue, this.selectedColumnValue);
      }
      if (this.selectedRowValue_TypeVal == "Overdue (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_TypeVal, this.selectedRowValue, this.selectedColumnValue);
      }
      // if(this.selectedRowValue_TypeVal == "Overdue > 30 Days (Lakhs)" ){
      //   self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      // }


      if (this.selectedRowValue_Team_TypeVal == "Sales with Tax (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_Team_TypeVal, this.selectedRowValue_Team, this.selectedColumnValue);

      }
      if (this.selectedRowValue_Team_TypeVal == "Return Sales (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_Team_TypeVal, this.selectedRowValue_Team, this.selectedColumnValue);

      }

      if (this.selectedRowValue_Team_TypeVal == "COGS excluding Tax (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_Team_TypeVal, this.selectedRowValue_Team, this.selectedColumnValue);

      }
      if (this.selectedRowValue_Team_TypeVal == "Overdue (Lakhs)") {
        self.setRowColumnvalues(idx, this.selectedRowValue_Team_TypeVal, this.selectedRowValue_Team, this.selectedColumnValue);

      }
      // if(this.selectedRowValue_Team_TypeVal == "Overdue > 30 Days (Lakhs)" ){
      //   self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      // }
    });
  }

  onRegionSelect(item: string) {
    this.getCurrentRegionsTeams(this.selectedRegionsList);
  }
  onRegionsAll(items: any) {
    this.selectedRegionsList = this.regionsList;
    this.getCurrentRegionsTeams(this.selectedRegionsList);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegionsList = this.selectedRegionsList.filter(s => s != deselectedSID);
    this.selectedRegionsList.forEach(sid => { this.onRegionSelect(sid); });
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeamsList = [];
    this.teamsList = [];
  }
  //teams
  onTeamSelect(item: string) { }
  onTeamsAll(items: any) { }
  onTeamDeSelect(deselectedTID: any): void { }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeamsList = items;
  }

  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.excelData2, 'Enterprise');
    this.viewDetailsTable = 'back'
    this.backButton();
  }



  PendencyTables(val) {
    this.dtOptionsenable0 = false;
    this.dtOptionsenable_1 = false;

    switch (val) {
      case 0: {
        this.getPendencyTables(analytics_baseURL + "overall_mgm_score/get_mgm_score", -1);
        this.getPendencyTables(analytics_baseURL + "overall_mgm_score/get_os_od_overall", 0);
        break;
      }

    }
  }





  getPendencyTables(urlpath, val: any) {

    if (val == -1) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
          this.dateval_mgmt_scorecard = response["dateval"];
          this.sccorecard_mgmt_month = response["scorecard_date"];

          this.mgmtService.setmgmt_scorecard_Month(this.sccorecard_mgmt_month);
          this.finalDataObjectpickup = this.mgmtService.postpickup(response, val);
          console.log(this.finalDataObjectpickup);
          this.dtOptionsenable0 = true;
          if (this.finalDataObjectpickup.table) {
            this.dtOptionspickup = this.finalDataObjectpickup.table;
          }
        }
      )
    }

    if (val == 0) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
          this.dateval_mgmt_osod = response["dateval"];
          this.osod_mgmt_month = response["scorecard_date"];
          this.mgmtService.setmgmt_osod_Month(this.osod_mgmt_month);
          this.finalDataObject = this.mgmtService.post(response, val);
          console.log(this.finalDataObject);

          this.dtOptionsenable_1 = true;
          if (this.finalDataObject.table) {
            this.dtOptions = this.finalDataObject.table;
          }
        }
      )
    }

  }
}
export interface UserComment {
  userComment: string;
}
