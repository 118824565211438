
  import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
  import { NgxSpinnerService } from 'ngx-spinner';
  import { FormsModule, ReactiveFormsModule } from '@angular/forms';
  import * as FileSaver from 'file-saver';
  import * as XLSX from 'xlsx';
  import { ToastrService } from 'ngx-toastr';
  import { SelfServeService } from 'src/app/services/self-serve.service';
  import { AppService } from 'src/app/services/app.service';
  import { oneLine } from '@angular-devkit/core/src/utils/literals';
  import { SCESupplyService } from 'src/app/services/sce-supply.service';
  import { v4 as uuid } from 'uuid';
  import { HttpClient } from '@angular/common/http';
  import { environment } from 'src/environments/environment';
  import { Report101Service } from 'src/app/services/report101.service';
  import { NavbarComponent } from '../navbar/navbar.component';
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  
  import { IDropdownSettings } from 'ng-multiselect-dropdown';
  import { CommonService } from 'src/app/shared/common.service';
  import { CustomerService } from 'src/app/fur/customer.service';
  import { MgmtService } from 'src/app/services/mgmt.service';
  @Component({
    selector: 'app-advance-po-supply',
    templateUrl: './advance-po-supply.component.html',
    styleUrls: ['./advance-po-supply.component.css']
  })
  export class AdvancePOSupplyComponent implements OnInit {
    @ViewChild('multiSelect') multiSelect: IDropdownSettings;
    filterChanged = true;
    dropdownList = [
      1, 2, 3, 4, 5, 6
    ];
    displayedheader = {
      "id":"ID",
      "customer_name":"Customer Name",
      "s3_link":"s3_link",
      "inserted_at":"Files Uploaded At",
      "filesize":"#Items",
      "filename":"File Name",
      "upload_status":"Upload Status",
      "download_link":"download_link"
  }
    selectedItems = [];
    dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      addNewItemOnFilter: true,
      selectAddedItem: true
    };
  
  
    uploadeddtOptions: DataTables.Settings = {};
    finalDataObject = { table: {}, excel: [] };
  
  
    date = new Date().toString();
    EX1 = [];
    EX2 = [];
    EX3 = [];
    EX4 = [];
    EX5 = [];
    EX6 = [];
    EX7 = [];
    EX8 = [];
    EX9 = [];
    EX10 = [];
    EX11 = [];
    EX12 = [];
    EX13 = [];
    EX14 = [];
    EX15 = [];
    EX16 = [];
    EX17 = [];
    EX18 = [];
    EX19 = [];
  
    selectedEX1 = "";
    selectedEX2 = "";
    selectedEX3 = "";
    selectedEX4 = "";
    selectedEX5 = "";
    selectedEX6 = "";
    selectedEX7 = "";
    selectedEX8 = "";
    selectedEX9 = "";
    selectedEX10 = "";
    selectedEX11 = "";
    selectedEX12 = "";
    selectedEX13 = "";
    selectedEX14 = "";
    selectedEX15 = "";
    selectedEX16 = "";
    selectedEX17 = "";
    selectedEX18 = "";
  
  
    dsEX1 = {}
    dsEX2 = {}
    dsEX3 = {}
    dsEX4 = {}
    dsEX5 = {}
    dsEX6 = {}
    dsEX7 = {}
    dsEX8 = {}
    dsEX9 = {}
    dsEX10 = {}
    dsEX11 = {}
    dsEX12 = {}
    dsEX13 = {}
    dsEX14 = {}
    dsEX15 = {}
    dsEX16 = {}
    dsEX17 = {}
    dsEX18 = {}
    dsEX19 = {}
    smeFinanceObj: { table: {}; excel: any[]; };
    smeFinanceObj2: { table: {}; excel: any[]; };
    dtOptions2 = {};
    dtOptions = {};
  
    tableDisplay: boolean;
    mandatoryFileds = false;
    previewJsonData: any;
    formDropDownList = false;
    overAllSelectedOptionsList = [];
  
    selectedOption: any;
    excelHeaders = [];
    excelHeaders1 = [];
    excelHeaders2 = [];
    excelHeaders3 = [];
    excelHeaders4 = [];
    excelHeaders5 = [];
    excelHeaders6 = [];
    excelHeaders7 = [];
    excelHeaders8 = [];
    excelHeaders9 = [];
    excelHeaders10 = [];
    excelHeaders11 = [];
    excelHeaders12 = [];
    excelHeaders13 = [];
    excelHeaders14 = [];
    excelHeaders15 = [];
    excelHeaders16 = [];
    excelHeaders17 = [];
    excelHeaders18 = [];
    excelHeaders19 = [];
  
    chunkDatatoSlice: any;
    chunks: any[];
    databytes: any[];
    fileName: any;
    col_mapping: {};
    ExcelDataTable = {}
    ExcelDataTable2 = {}
    ExcelDataTablewithNewCoulums: { data: []; headers: []; };
  
    actualExceldata = [];
    previewrows: unknown[];
    successRate = null;
    customerName = null;
    customerFlag: boolean;
  
    TeamRegionsListObj: any = {};
    payload = {}
    requestBody = {};
    regionsList: any;
    mngmt_data: any;
    public selectedRegions = [];
    public selectedTeams = [];
  
    exceldata: any;
    regions: any = [];
    teams: any = [];
  
    RkeyValue: any;
    selectedL3: any[];
    selectedL4: any[];
    selectedPlants: any[];
    plants: any[];
    myDataList: any;
    userId_val: any;
    customers: any = [];
    customersFlag = true;
    searchText: string;
    singleVendorurl = "https://serviceability-analysis.moglix.com/service/unspsc?code=11161505&query=32 inch led tv"
  
    
    customerdropdownSettings = {}
    public selectedCustomers = [];
    uploadedListAPI = 'https://analysis.moglix.com/lpp_serviceablity/vendor_status'
    reportlabel: any;
    uploadFileList: any;
    fileListFlag = false;
    // willDownload = false;
    customHeaders = ["ID",
  
      "Customer Name",
  
      "S3 Link",
  
      "Updated At",
  
      "Uploaded by",
  
      "Upload Status"]
      
      
      suppliers = [];
      showTable: boolean = false;


      code: string = '';
      query: string = '';
      single_page = false;
      bulk_page = false;
    constructor(private cdr:ChangeDetectorRef,private mgmt: MgmtService, private commonService: CommonService, private toaster: ToastrService, private Service: SelfServeService, private appService: AppService,
      private spinner: NgxSpinnerService, private reportseivce: Report101Service,
      private sceSupply: SCESupplyService,
      private navbarTitle: NavbarComponent,private http: HttpClient) {
  
  
      console.log(this.dtOptions)
      this.userId_val = this.appService.userDetails.email;
      this.reportlabel = this.navbarTitle.reportlabel;
      this.getCustomerList();
      const firstMenu = document.getElementById("first-menu");
      const secondMenu = document.getElementById("second-menu");
        this.uploadedlistFile();
    
  
    }
    fetchData(reportTabName) {
      if (reportTabName == 'Single Vendor Search') {
        this.single_page = true;
        this.bulk_page = false;
  
        
      } else if (reportTabName == 'Bulk Vendor Search') {
        this.single_page = false;
        this.bulk_page = true;
        
      } 
    }
    submitRequest() {
      this.spinner.show();
      const apiUrl = `https://serviceability-analysis.moglix.com/service/unspsc?code=${this.code}&query=${this.query}`;
      this.http.get(apiUrl).subscribe((response: any) => {
        this.suppliers = response["Suppliers Details"]
        console.log(this.suppliers)
        this.showTable = this.suppliers.length > 0; // Check if suppliers array has data
        this.spinner.hide();
      }, error => {
        console.error('Error fetching data:', error);
      });
    }
   
    uploadedlistFile(){
      this.appService.getReport(this.uploadedListAPI, { "username": this.userId_val }).subscribe(data => {
        //this.appService.getReport(this.uploadedListAPI, { "username": this.userId_val }).subscribe(data => {
        this.uploadFileList = data;
        if (data['status_code'] == 204) {
          this.fileListFlag = true
          this.formDropDownList = false;
        }
        else {
          if (this.uploadFileList != null)
            this.fileListFlag = true
            this.formDropDownList = false;
  
        }
      })
  
    }
    onAddItem(item: any) {
      // Add the new item to the options array
      const newItem = item
      this.dropdownList.push(newItem);
  
      // Add the new item to the selected items array
      this.selectedItems.push(newItem);
    }
  
    ngOnChanges(changes: SimpleChanges) {
  
      this.selectedEX1 = (this.selectedEX1).length != 0 ? this.selectedEX1[0] : "";
      this.selectedEX2 = (this.selectedEX2).length != 0 ? this.selectedEX2[0] : "";
     
      this.EX1 = this.EX1;
      this.EX2 = this.EX2;
      
      this.getColumn_mapping();
  
      this.onItemDeSelect
      this.setSelectedOption
      this.multiSelectDropdown();
  
  
    }
    onItemDeSelect(item: any): void {
  
      if (item != "Not in excel headers") {
  
  
        this.EX1.push(item)
        this.EX2.push(item)
        
        this.multiSelectDropdown()
        this.setSelectedOption("", "")
      }
      else {
        this.multiSelectDropdown()
      }
      this.multiSelectDropdown()
      //this.selectedEX.forEach(sid => { this.onCustomerDeSelect(sid); });
  
    }
  
    ngOnInit() {
  
      this.multiSelectDropdown();
  
    }
    multiSelectDropdown() {
  
      this.customerdropdownSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        //enableCheckAll: true,
        // selectAllText: 'Select All',
        // unSelectAllText: 'Unselect all',
        allowSearchFilter: true,
        limitSelection: -1,
        clearSearchFilter: true,
        maxHeight: 197,
        itemsShowLimit: 1,
        searchPlaceholderText: 'Search or Create',
        noDataAvailablePlaceholderText: 'noDataAvailablePlaceholderText',
        closeDropDownOnSelection: false,
        showSelectedItemsAtTop: false,
        defaultOpen: false
  
      };
  
  
      this.dsEX1 = {
        data: this.EX1,
        singleSelection: true,
        allowSearchFilter: true,
        itemsShowLimit: 1
      };
      this.dsEX2 = {
        data: this.EX2,
        singleSelection: true,
  
        allowSearchFilter: true,
        itemsShowLimit: 1
      };
    }
  
  
    getCustomerList() {
      this.payload = {}
      this.appService.getData('https://analytics-web.moglix.com/freq_report/dimsysCustomers').subscribe(resp => {
  
        this.customers = resp['customers']
        this.customersFlag = true;
  
  
      })
    }
  
    onFocusOutEvent(event: any) {
  
  
      this.customerName = event.target.value;
      this.customerFlag = true;
  
  
  
    }
  
    readExcelHeadersTo18Inputs(callFrom) {
      // duplicat the excelHeaders
  
      if (callFrom == "resetOptions") {
  
  
        this.EX1 = this.excelHeaders;
        this.EX2 = this.excelHeaders;
        this.tableDisplay = false;
  
  
        this.selectedEX1 = null;
        this.selectedEX2 = null;
  
  
  
  
  
  
  
  
        this.smeFinanceObj = { table: {}, excel: [] };
        this.smeFinanceObj2 = { table: {}, excel: [] };
        this.dtOptions2 = {};
        this.dtOptions = {};
        this.ExcelDataTable = {};
  
      }
      if (callFrom == "fileuploadList") {
        // this.toaster.show("Successfully Uploaded")
  
        this.EX1 = this.excelHeaders;
        this.EX2 = this.excelHeaders;
        this.formDropDownList = true;
  
  
  
      }
  
    }
    getExcelHeaderFilter(excelHeadersList, event) {
      // console.log(excelHeadersList.filter(
      //   (option) => option !== event
      // ))
      return excelHeadersList.filter(
        (option) => option !== event
      );
    }
  
  
    setSelectedOption(event, selectedEX1) {
      // console.log(selectedEX1)
      if (event != "Not in excel headers") {
        this.EX1 = this.getExcelHeaderFilter(this.EX1, event)
        this.EX2 = this.getExcelHeaderFilter(this.EX2, event)
        // this.multiSelectDropdown();
      }
      if (event == "") {
        this.multiSelectDropdown()
      }
  
    }
    getFilterText() {
      alert("")
    }
    getColumn_mapping() {
      // console.log(this.selectedEX1)
      this.col_mapping = {
        "code": this.selectedEX1.length != 0 ? this.selectedEX1[0] : "",
        "query": this.selectedEX2.length != 0 ? this.selectedEX2[0] : ""
          };
      return this.col_mapping;
    }
    chunked(data: any[], chunkSize: number): any[][] {
      const actualchunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        actualchunks.push(data.slice(i, i + chunkSize));
      }
      return actualchunks;
    }
    getChunkInitial4FileUpload() {
  
      //to Breakhe data into chunks
  
      this.chunks = this.chunked(this.actualExceldata, 5000);
      // Get the total number of chunks
  
      // Iterate over chunks and send data to backend
      return this.chunks
    }
  
  
    async inputToBackend() {
      let list: string[] = [];
      let myuuid = uuid();
      this.successRate = 0;
      await this.chunks.forEach((chunk, i) => {
        // "customerName": this.selectedCustomers[0],
        //  console.log(this.col_mapping)
        let requestBody = {
          "total_chunks": this.chunks.length, "col_mapping": this.col_mapping,
          "currentChunk": i, "file": this.chunks[i], "uuid": myuuid, 
          "filename": this.fileName,
          "username": this.userId_val
        }
        console.log(requestBody)
        
        //uncomment
        this.appService.saveInfo('https://analysis.moglix.com/lpp_serviceablity/vendor_upload', {
          "total_chunks": this.chunks.length, "col_mapping": this.col_mapping,
          "currentChunk": i, "file": this.chunks[i], "uuid": myuuid, 
          "filename": this.fileName,
          "username": this.userId_val
        }).subscribe(res => {
          if (res["message"].includes('Data inserted to DB')) {
  
            this.commonService.showToast('Data inserted to DB', 1)
            this.onReload()
            this.uploadedlistFile();
            
            return res;
          }
          if (res["message"].includes('headers mapping not correct'))
            this.toaster.show("Fill required Field", res["message"])
          if (res["message"].includes("saved"))
            this.successRate = this.successRate + 1;
  
          // if (this.chunks.length == this.successRate)
          //   this.toaster.show("File Successfuly Uploaded in DB")
  
        })
  
      });
  
  
    }
  
    onSubmitMapping(event) {
  
      // if (this.selectedCustomers.length != 0) {
        this.col_mapping = this.getColumn_mapping();
        this.getChunkInitial4FileUpload()
        this.inputToBackend();
      // }
      // else {
      //   alert("Please enter the Customer Name / Client Name")
      // }
    }
  
  
  
    public onFilterChange(item: any) {
      this.filterChanged = true;
      this.searchText = item;
    }
  
  
  
    async uploadFile(event) {
      this.fileListFlag = false;
      this.spinner.show();
  
      const file = event.target.files[0];
      this.fileName = file.name;
  
      // Read the file as a binary string
      const fileReader = new FileReader();
  
      fileReader.onload = (e) => {
  
        this.commonService.showToast('Uploaded the file successfully', 1)
        // this.toaster.show("Successfully Uploaded")
        const binaryString = fileReader.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
  
  
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
  
  
        this.actualExceldata = XLSX.utils.sheet_to_json(sheet, { defval: "", raw: false, dateNF: 'd"/"m"/"yyyy', blankrows: false });
  
        // let rexcelHeaders = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];
  
        this.excelHeaders = Object.keys(this.actualExceldata[0])
        this.spinner.hide();
  
        this.previewJsonData = workbook.SheetNames.reduce((initial, name) => {
          const sheet = workbook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet,
            {
              blankrows: false,
              raw: false,
              dateNF: 'd"/"m"/"yyyy',
              defval: ""
            });
          return initial;
        }, {});
  
        if (this.excelHeaders.length > 0) {
          this.excelHeaders.push("Not in excel headers")
          this.readExcelHeadersTo18Inputs("fileuploadList")
          this.formDropDownList = true;
        }
      }
  
      fileReader.readAsBinaryString(file);
    }
  
    onDeleteExcelFile() {
      this.toaster.show("Deleting File")
  
      this.readExcelHeadersTo18Inputs("resetOptions")
  
      this.getPreview()
  
      this.chunks = null
      this.previewJsonData = null
      this.col_mapping = null
      this.fileName = null
      this.formDropDownList = false;
  
    }
    onDeleteOptions() {
      this.tableDisplay = false;
  
      this.selectedEX1 = "";
      this.selectedEX2 = "";
      this.selectedEX3 = "";
      this.selectedEX4 = "";
      this.selectedEX5 = "";
      this.selectedEX6 = "";
      this.selectedEX7 = "";
      this.selectedEX8 = "";
      this.selectedEX9 = "";
      this.selectedEX10 = "";
      this.selectedEX11 = "";
      this.selectedEX12 = "";
      this.selectedEX13 = "";
      this.selectedEX14 = "";
      this.selectedEX15 = "";
      this.selectedEX16 = "";
      this.selectedEX17 = "";
      this.selectedEX18 = "";
  
  
  
      this.readExcelHeadersTo18Inputs("resetOptions")
  
      this.getColumn_mapping()
  
  
  
      this.dtOptions = [];
      this.ExcelDataTable = {};
      this.smeFinanceObj = { table: {}, excel: [] };
    }
  
    previewChunked(data: any[], chunkSize: number): any[][] {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      return chunks;
    }
  
    // previewChunked(data, chunkSize: number): any[][] {
    //   const chunks = [];
    //   for (let i = 0; i < data.length; i += chunkSize) {
    //     chunks.push(data.slice(i, i + chunkSize));
    //     break;
    //   }
    //   return chunks;
    // }
    mapTheColoumns(newColumnsmapped, previewjsonData, getStaticKeys) {
     
      previewjsonData.forEach(function (pobject, indx) {
        newColumnsmapped.forEach(function (col) {
          if (col.shouldRename) {
  
            pobject[col.newK] = (pobject[col.oldK] !== "undefined" || pobject[col.oldK] !== "") ? pobject[col.oldK] : ""
            // delete pobject[col.oldK];
  
          }
          else {
            pobject[col.newK] = (pobject[col.oldK] == "undefined" || pobject[col.oldK] == "") ? pobject[col.oldK] : ""
          }
        });
      });
  
  
      return previewjsonData
  
    }
    renameColumns(previewCHunkJsonData) {
  
  
      let getStaticKeys = Object.keys(this.getColumn_mapping())
  
  
      let newColumnsmapped = [];
      for (let s = 0; s <= getStaticKeys.length - 1; s++) {
        let obj = {}
        if (this.col_mapping[getStaticKeys[s]] !== "Not in excel headers" && this.col_mapping[getStaticKeys[s]] !== "") {
          obj = {
            "newK": getStaticKeys[s], "oldK": this.col_mapping[getStaticKeys[s]],
            "shouldRename": (this.col_mapping[getStaticKeys[s]] !== "Not in excel headers" || this.col_mapping[getStaticKeys[s]] !== "") ? true : false
          }
          newColumnsmapped.push(obj);
        }
        else {
          if (this.col_mapping[getStaticKeys[s]] == "Not in excel headers" || this.col_mapping[getStaticKeys[s]] == "" || this.col_mapping[getStaticKeys[s]] == "undefined")
            obj = {
              "newK": getStaticKeys[s], "oldK": this.col_mapping[getStaticKeys[s]],
              "shouldRename": false
            }
          newColumnsmapped.push(obj);
        }
  
      }
      let jsonDataWithnewColumns = this.mapTheColoumns(newColumnsmapped, previewCHunkJsonData, getStaticKeys)
  
  
      return jsonDataWithnewColumns
    }
  
  
    getPreview() {
      if (this.selectedCustomers.length != 0) {
  
        this.tableDisplay = false;
  
        this.dtOptions = [];
        this.ExcelDataTable = {};
        this.smeFinanceObj = { table: {}, excel: [] };
        this.col_mapping = this.getColumn_mapping();
  
        let previCHunk = [];
  
        previCHunk = this.previewChunked(this.previewJsonData["Sheet1"], 6);
        //previCHunk = this.previewJsonData["Sheet1"]
  
        let renameJsonDataColumns = this.renameColumns(previCHunk[0]);
        renameJsonDataColumns.forEach(obj => {
          if (obj.hasOwnProperty('client_name')) {
            obj['client_name'] = this.selectedCustomers[0];
          }
          
        });
        this.validateData(renameJsonDataColumns);
        console.log(renameJsonDataColumns)
        let getStaticKeys = Object.keys(this.col_mapping)
        this.ExcelDataTable = { "data": renameJsonDataColumns, "headers": getStaticKeys }
        this.smeFinanceObj = this.sceSupply.postLPP(this.ExcelDataTable);
        this.cdr.detectChanges()
        if (this.smeFinanceObj['table']) {
          this.dtOptions = this.smeFinanceObj['table'];
          if (this.dtOptions != null)
            this.tableDisplay = true;
  
      // this.cdr.detectChanges()
        }
      }
      else {
        alert("Please enter the Customer Name / Client Name")
      }
    }
  
  isCellInvalid(value: any, columnName: string): boolean {
    if (columnName === 'quantity') {
      return typeof value !== 'number';
    } else if (columnName === 'transfer_price') {
      return typeof value !== 'number';
    } else if (columnName === 'txn_date') {
      // Assuming you want to check if it's a valid date
      return !/^\d{4}-\d{2}-\d{2}$/.test(value);
    } else {
      // Handle other columns if needed
      return false;
    }
  }
    onCustomerSelect(item: string) {
      this.customerName = item;
      //this.selectedCustomers.push(item)
    }
    onCustomerDeSelect(deselectedSID: any): void {
  
      this.selectedCustomers.forEach(sid => { this.onCustomerDeSelect(sid); });
      this.customerName = null
    }
    onReload() {
  
  
      this.EX1 = [];
      this.EX2 = [];
      this.EX3 = [];
      this.EX4 = [];
      this.EX5 = [];
      this.EX6 = [];
      this.EX7 = [];
      this.EX8 = [];
      this.EX9 = [];
      this.EX10 = [];
      this.EX11 = [];
      this.EX12 = [];
      this.EX13 = [];
      this.EX14 = [];
      this.EX15 = [];
      this.EX16 = [];
      this.EX17 = [];
      this.EX18 = [];
      this.EX19 = [];
  
      this.selectedEX1 = "";
      this.selectedEX2 = "";
      this.selectedEX3 = "";
      this.selectedEX4 = "";
      this.selectedEX5 = "";
      this.selectedEX6 = "";
      this.selectedEX7 = "";
      this.selectedEX8 = "";
      this.selectedEX9 = "";
      this.selectedEX10 = "";
      this.selectedEX11 = "";
      this.selectedEX12 = "";
      this.selectedEX13 = "";
      this.selectedEX14 = "";
      this.selectedEX15 = "";
      this.selectedEX16 = "";
      this.selectedEX17 = "";
      this.selectedEX18 = "";
  
  
      this.dsEX1 = {}
      this.dsEX2 = {}
      this.dsEX3 = {}
      this.dsEX4 = {}
      this.dsEX5 = {}
      this.dsEX6 = {}
      this.dsEX7 = {}
      this.dsEX8 = {}
      this.dsEX9 = {}
      this.dsEX10 = {}
      this.dsEX11 = {}
      this.dsEX12 = {}
      this.dsEX13 = {}
      this.dsEX14 = {}
      this.dsEX15 = {}
      this.dsEX16 = {}
      this.dsEX17 = {}
      this.dsEX18 = {}
      this.dsEX19 = {}
      // this.smeFinanceObj= {};
      // this.smeFinanceObj2= {};
      this.dtOptions2 = {};
      this.dtOptions = {};
  
      this.tableDisplay = false;
      this.mandatoryFileds = false;
      this.previewJsonData = {};
      this.formDropDownList = false;
      this.overAllSelectedOptionsList = [];
  
      this.excelHeaders = [];
      this.excelHeaders1 = [];
      this.excelHeaders2 = [];
      this.excelHeaders3 = [];
      this.excelHeaders4 = [];
      this.excelHeaders5 = [];
      this.excelHeaders6 = [];
      this.excelHeaders7 = [];
      this.excelHeaders8 = [];
      this.excelHeaders9 = [];
      this.excelHeaders10 = [];
      this.excelHeaders11 = [];
      this.excelHeaders12 = [];
      this.excelHeaders13 = [];
      this.excelHeaders14 = [];
      this.excelHeaders15 = [];
      this.excelHeaders16 = [];
      this.excelHeaders17 = [];
      this.excelHeaders18 = [];
      this.excelHeaders19 = [];
  
      this.chunks = [];
      this.fileName = "";
      this.col_mapping = {};
      this.ExcelDataTable = {}
      this.ExcelDataTable2 = {}
  
      this.actualExceldata = [];
      this.previewrows = [];
      this.successRate = null;
      this.customerName = '';
      this.customerFlag = false;
  
      this.TeamRegionsListObj = {};
      this.payload = {}
      this.requestBody = {};
      this.regionsList = []
      this.mngmt_data = []
      this.selectedRegions = [];
      this.selectedTeams = [];
  
      this.exceldata = [];
      this.regions = [];
      this.teams = [];
  
  
      this.customersFlag = false;
  
      this.customerdropdownSettings = {}
      this.selectedCustomers = [];
      this.reportlabel = "";
      this.filterChanged = false;
      this.searchText = null;
      this.uploadFileList = {}
      // this.fileListFlag = false;
      let uploadedListAPI = 'https://analysis.moglix.com/lpp_serviceablity/vendor_status'
        this.multiSelectDropdown();
      this.getCustomerList()
      this.appService.getReport(this.uploadedListAPI, { "username": this.userId_val }).subscribe(data => {
        this.uploadFileList = data;
        if (data['status_code'] == 204) {
          this.fileListFlag = true
        }
        else {
          if (this.uploadFileList != null)
            this.fileListFlag = true
  
        }
      })
  
    }
  
    confirmSelection() {
      if (this.filterChanged && this.searchText != "") {
  
        // User confirmed selection, so read the selected items
        this.selectedCustomers = [];
        this.selectedCustomers.push(this.searchText);
        
        this.customers.push(this.selectedCustomers)
        // Display confirmation dialog and ask the user to confirm their selection
        // if (confirm('Are you sure you want to select these items?')) {
  
        //   // Do something with the selected items
        // } 
        // else {
        //   // User cancelled selection, so reset the selected items
        //   this.selectedCustomers;
        //   console.log("single")
        //   console.log(this.selectedCustomers)
        // }
      } else {
        // Filter hasn't changed, so no confirmation needed
        const selectedItems = this.selectedItems;
        this.searchText = '';
        // Do something with the selected items
      }
      this.searchText = ''
    }
    getSnakeCase(str: string): string {
      return str.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase());
    }
    downloadFile(id: number) {
      //alert(`Download file with ID: ${id}`);
      let url = "https://analytics-web.moglix.com/freq_report/download_desc?meta_data_id=" + id
      this.appService.getDownloadLink(id).subscribe(
        (response) => {
  
  
          // For example, initiate the download
          this.s3downloadFile(response, 'downloaded-file.csv');
        },
        (error) => {
          // Handle errors
          console.error(error);
        }
      );
    }
  
  
    private s3downloadFile(content: string, fileName: string) {
      // Convert the content to a Blob
      const blob = new Blob([content], { type: 'text/csv' });
  
      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
  
      // Set the download attribute with the desired file name
      link.download = fileName;
  
      // Append the link to the document
      document.body.appendChild(link);
  
      // Trigger a click on the link to initiate the download
      link.click();
  
      // Remove the link from the document
      document.body.removeChild(link);
    }
    ngAfterViewInit() {
      // this.smeFinanceObj
      this.cdr.detectChanges()
      
    }
    isNumber(value: any): boolean {
      return !isNaN(Number(value));
    }
  
    isDate(value: any): boolean {
      const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/; // Assuming date format is MM/DD/YY
      return dateRegex.test(value);
    }
  
    validateData(data: any[]): boolean {
      for (const entry of data) {
        if (!this.isNumber(entry.transfer_price) || !this.isNumber(entry.quantity) || !this.isDate(entry.txn_date)) {
          return false;
        }
      }
      return true;
    }
  
    
  }