import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  @Input() stages = [];
  @Input() reportsData = [];

  @Input() analyze_format;
  constructor() {
  }

  ngOnInit() {
  }
}
