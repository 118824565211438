import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-po-cancellation',
  templateUrl: './po-cancellation.component.html',
  styleUrls: ['./po-cancellation.component.css']
})
export class PoCancellationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
