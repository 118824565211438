import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';

import {
  GoogleLoginProvider
} from 'angularx-social-login';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { AppService } from '../../services/app.service';
import { CommonService } from 'src/app/shared/common.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-s3report',
  templateUrl: './s3report.component.html',
  styleUrls: ['./s3report.component.css']
})
export class S3reportComponent implements OnInit {
  setsessionURL = environment.baseURL_FREQ;
  private user: SocialUser;
  selectedTeamsList: any[];
  activatedRoute: any;
  constructor(private socialAuthService: AuthService,
    private appService: AppService, private routeAct: ActivatedRoute,
    private commonService: CommonService) {
    debugger
    console.log(routeAct)


        }
      
  

  ngOnInit() {
    
  }
}