import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { RegionTargetsService } from 'src/app/services/region-targets.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
   selector: 'app-region-target',
   templateUrl: './region-target.component.html',
   styleUrls: ['./region-target.component.css']
})
export class RegionTargetComponent implements OnInit {
   url = 'https://mdm.moglix.com/search/MDMRegionTarget';
   searchValue = '';
   // searchText$: Subject<string> = new Subject();
   requestbody = {};
   dropdownValue: "";
   dtOptions: DataTables.Settings = {};
   final = {};
   selected = 1;
   userId_val: any;
   tableDisplay: boolean;
   reportName = "";
   filterData: any = {};
   RkeyValue: any;
   isOpen = false;
   userInput = '';
   currentDate: any;
   currentMonth: any;
   dimUserTargetList = [];
   userTargetsArray = [];
   userTargetsArrayCopy = [];
   ymType = "Current Quarter";
   currentQuarter = '';
   secondaryHeaders: any[];
   tertiaryHeaders: any;
   nonEditableFields = [];
   constructor(private navbarTitle: NavbarComponent, private regionTargetService: RegionTargetsService, private commonService: CommonService,
      private userId: AppService,
      private Service: SelfServeService, private appService: AppService) {
      this.reportName = this.navbarTitle.reportlabel;
      this.userId_val = this.userId.userDetails.email;
   }
   ngOnInit() {
      this.updateUserTargets();
   }

   updateUserTargets() {
   const date = new Date();
      this.currentDate = date.getDate();
      let month = (date.getMonth() + 1).toString();
      const year = date.getFullYear();
      const yearCopy = date.getFullYear().toString().slice(-2);
 
      let monthValueInt =0;
      if (this.ymType == 'Next Quarter') {
         month = (date.getMonth() + 4).toString();
         monthValueInt = Number(month)
      } else {
         month = (date.getMonth() + 1).toString();
        
      monthValueInt = Number(month)
      }
      if (monthValueInt <= 3) {
         
         this.userTargetsArray = [`${year}-Q1`, `${year}-01`, `${year}-02`, `${year}-03`];
         this.userTargetsArrayCopy = [`Q1CY${yearCopy}`, `Jan'${yearCopy}`, `Feb'${yearCopy}`, `Mar'${yearCopy}`];
      } else if (monthValueInt > 3 && monthValueInt <= 6) {
         
         this.userTargetsArray = [`${year}-Q2`, `${year}-04`, `${year}-05`, `${year}-06`];
         this.userTargetsArrayCopy = [`Q2CY${yearCopy}`, `Apr'${yearCopy}`, `May'${yearCopy}`, `Jun'${yearCopy}`];
      } else if (monthValueInt > 6 && monthValueInt <= 9) {
         
         this.userTargetsArray = [`${year}-Q3`, `${year}-07`, `${year}-08`, `${year}-09`];
         this.userTargetsArrayCopy = [`Q3CY${yearCopy}`, `Jul'${yearCopy}`, `Aug'${yearCopy}`, `Sep'${yearCopy}`];
      } else if (monthValueInt > 9 && monthValueInt <=12) {
        
         this.userTargetsArray = [`${year}-Q4`, `${year}-10`, `${year}-11`, `${year}-12`];
         this.userTargetsArrayCopy = [`Q4CY${yearCopy}`, `Oct'${yearCopy}`, `Nov'${yearCopy}`, `Dec'${yearCopy}`];
      }
      else if (monthValueInt > 12 && monthValueInt <=15) {
        
         this.userTargetsArray = [`${year+1}-Q1`, `${year+1}-01`, `${year}-02`, `${year+1}-03`];
         this.userTargetsArrayCopy = [`Q1CY${year+1}`, `Jan'${year+1}`, `Feb'${year+1}`, `Mar'${year+1}`];
      }
      if (this.ymType == 'Next Quarter' ) {
         if(monthValueInt<=9){
            month = (date.getMonth() + 1).toString();
            this.currentQuarter = this.userTargetsArray[0];
         }
         
         else{
            console.log(month)
            console.log(monthValueInt)
            month = (date.getMonth() + 1).toString();
            this.currentQuarter = this.userTargetsArray[0];
         }

      } 
      else {
         this.currentQuarter = this.userTargetsArray[0];
      }
      month = month.length == 1 ? '0' + month : month;
      this.currentMonth = month;
      if(monthValueInt>12){
         this.userInput = `${year+1}-${'01'}`;
      }
      else{
         this.userInput = `${year}-${month}`;
      }
      
      console.log(this.userInput)
      this.createSecondaryHeaders();
      this.getDataByFilter();
   }

   createSecondaryHeaders() {
      this.secondaryHeaders = ['Vertical', 'Region'];
      this.tertiaryHeaders = ['Vertical', 'Region'];
      const year = new Date().getFullYear();
      this.userTargetsArray.forEach((each, index) => {
         if ((+(each.slice(-2)) == +(this.currentMonth))) {
            this.nonEditableFields.push(`${each} GMV Forecast`, `${each} GM Forecast`);
         }
         if ((+(each.slice(-2)) < +(this.currentMonth))) {
            this.tertiaryHeaders.push(`${each} GMV Target`, `${each} GMV Actual`, `${each} GM Target`, `${each} GM Forecast`)
            this.secondaryHeaders.push('Target', 'Actual', 'Target', 'Forecast')
         } else {
            this.tertiaryHeaders.push(`${each} GMV Target`, `${each} GMV Forecast`, `${each} GM Target`, `${each} GM Forecast`)
            this.secondaryHeaders.push('Target', 'Forecast', 'Target', 'Forecast')
         }
      })
   }

   updateTargets() {
      const url = 'https://mdm.moglix.com/insertRegionTarget';
      this.regionTargetService.updateTargets(url, { targetList: this.dimUserTargetList }).subscribe((resp) => {
         this.commonService.showToast(resp['message'], 1);
         this.getDataByFilter();
         this.dimUserTargetList = [];
      }, error => {
         this.commonService.showToast(error.error['message'], 3);
      })
   }


   getDataByFilter() {
      let regions = [];
      if (!this.searchValue.length) {
         regions = JSON.parse(localStorage.getItem('regions'));
      } else {
         regions = this.searchValue.split(',') || [];
      }
      const requestBody = {
         "regionList": regions,

         "timeFrame": this.currentQuarter,
         "whichQuarter": this.ymType,
         "kpiType": "GMV Target','GM Target', 'GMV Forecast', 'GM Forecast', 'GMV Actual",
         "pageNumber": "1",
         "pageSize": "8000",
         'username': this.userId_val
      }
      this.regionTargetService.updateTargets(this.url, requestBody).subscribe((data) => {
         if (data['result'] != undefined && data['status'] == "Success" && data['headers'] != undefined) {
            let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
            this.regionTargetService.updateTargets(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
            })
         }
         if (data['result'] != undefined && data['headers'] != undefined) {
            for (let l = 0; l < data['result'].length; l++) {
               for (let h = 0; h < data['headers'].length; h++) {

                  if (data['headers'][h] == 'Name') {
                     if (data['result'][l][data['headers'][h]].length > 40) {
                        data['result'][l][data['headers'][h]] = '<span title="' + data['result'][l][data['headers'][h]] + '">' + data['result'][l][data['headers'][h]].substring(0, 20) + "..." + '</span>'
                     }
                  }
               }
            }
            data['result'].sort((a, b) => {
               let fa = a.Region.toLowerCase(),
                   fb = b.Region.toLowerCase();
           
               if (fa < fb) {
                   return -1;
               }
               if (fa > fb) {
                   return 1;
               }
               return 0;
           });
            this.dtOptions.data = data['result'];
            this.tableDisplay = true;
         }
         else {
            this.commonService.showToast(data['message'], 2);
         }
      });
   }

   change(row, column) {
      if (this.dimUserTargetList.length) {
         const i = this.dimUserTargetList.findIndex(each => 
            ((each.region == row['Region']) && (each['timeFrame'] == column.slice(0,7)) && (each['kpiType'] == (column.slice(8))))
            );
         if (i == -1) {
            this.dimUserTargetList.push(this.createObject(row, column));
         } else {
            this.dimUserTargetList[i] = this.createObject(row, column);
         }
      } else {
         this.dimUserTargetList.push(this.createObject(row, column));
      }
      console.log(this.dimUserTargetList);
   }

   createObject(row, column) {
      return {
         "vertical": row['Vertical'] || null,
         "region": row['Region'] || null,
         "timeFrame": column.slice(0,7) || null,
         "target": '' + row[column] || null,
         "ymType": column.slice(0,7).includes('Q') ? 'Quarter' : 'Month' || null,
         "kpiType": column.slice(8) || null
      }
   }
}
