import { Router } from '@angular/router';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from '../../services/app.service';
import * as Moment from 'moment';

import { HttpHeaders } from '@angular/common/http';
import { extendMoment } from 'moment-range';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import * as Highcharts from 'highcharts';
import { CommonService } from '../../shared/common.service';
import { MgmtScoreCardService } from 'src/app/services/mgmt-score-cardservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelfServeService } from 'src/app/services/self-serve.service';
//import { MgmtComponent } from 'src/app/mgmt-score-card/mgmt/mgmt.component';
import { NavbarComponent } from '../navbar/navbar.component';

import { MgmtService } from '../../services/mgmt.service';
import { TeamWiseSalesService } from 'src/app/services/team-wise-sales.service';
import * as moment from 'moment';
import { Report101Service } from 'src/app/services/report101.service';
import { PodTrackerService } from 'src/app/services/pod-tracker.service';
import { HttpService } from 'src/app/http.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-report101',
  templateUrl: './report101.component.html',
  styleUrls: ['./report101.component.css']
})
export class Report101Component implements OnInit {

  userId_val: any;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  reportName = '';
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  viewDetailsTable: boolean;

  DateCalender: any;
  req_start_date: string;
  req_end_date: string;
  mngmt_data: any;
  requestBody = {};
  teamfinalpostObj: {};
  tableCondition: boolean;
  visualTable: boolean;
  TeamRegionsListObj: any = {};
  regions: any = [];
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  teams: any[];
  selectedL3: any[];
  customers: any[];
  selectedL4: any[];
  plants: any[];
  RkeyValue: any;
  filterData: any = {};

  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  categoriesdropdownSettings ={};
   
  Categories :any;
  category: any;
  selectedCategories: any[];

  constructor(private commonService: CommonService,
    private navbarTitle: NavbarComponent,
    private userId: AppService,
    private service: SelfServeService,
    private httpService: HttpService,
    private report101Service: Report101Service){
    
      
      this.userId_val = this.userId.userDetails.email;
      this.reportName = this.navbarTitle.reportlabel;

    this.multiSelectDropdown();
      this.getFiltersData();
    this.getSelectedFilters(this.selectedRegions);
    //this.getCategoriesFilter(this.selectedCategories)
  }
  ngOnInit() {
    debugger
    this.userId.getData(environment.baseURL_FREQ+'getCategories').subscribe((data) => {
      debugger
      this.Categories = data['result']
      console.log(this.Categories)
  })

  } 
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.categoriesdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
   
  }
  getFiltersData() {
    this.report101Service.getFiltersItemTrackingES(this.userId_val).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  getDateRange(date = this.dateRange) {
    this.report101Service.getDateItemTrackingES().subscribe(dateRes => {
      // this.dateRanges = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
      this.req_start_date = this.getDateFormat(this.dateRanges.start_date);
      this.req_end_date = this.getDateFormat(this.dateRanges.end_date);

    });
  }
  generateReports() {

    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};

    this.tableCondition = false;
    this.visualTable = false;
    const payload = this.generatePayload();
    const url = environment.baseURL_FREQ+'report101';
    this.report101Service.saveInfo(url, payload).subscribe(resp => {
      if (resp['report101Rows'] != undefined && resp['status'] == 200) {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
      }
      this.mngmt_data = this.report101Service.post(resp);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
        }
      }
    })
  }
  generatePayload() {
    const payload = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "regions": this.selectedRegions,
      "teams": this.selectedTeams,
      "customers": this.selectedCustomers,
      "plants": this.selectedPlants,
      "username": this.userId_val,
      "categories": this.selectedCategories
    }
    console.log(payload)
    return payload;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getDateRange(this.dateRange);
    this.getSelectedFilters(this.selectedRegions);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    this.getCategoriesFilter(this.selectedCategories);
  }

  getCategoriesFilter(cat){
    if(cat.length()>1)
        this.selectedCategories = cat
    else
        this.selectedCategories.push(cat)
  }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }
  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }
  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }
  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }
  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {
    let teamsList = [];
    let s2 = [];
    let s3 = [];

    let s4 = [];
    this.selectedCustomers = [];
    this.customers = [];
    for (let r = 0; r < Rkeys.length; r++) {

      let y: any = [];
      let regionName = Rkeys[r]
      this.TeamRegionsListObj[regionName].forEach((team, index) => {
        teamsList.push(Object.keys(team));
        for (let st = 0; st < this.selectedTeams.length; st++) {
          if (this.selectedTeams[st] == teamsList[index]) {
            s2.push(teamsList[index]);
            for (let t = 0; t < team[teamsList[index]].length; t++) {
              for (let c = 0; c < Object.keys(team[teamsList[index]][t]).length; c++) {
                s3.push(Object.keys(team[teamsList[index]][t])[c]);
                this.customers = s3;
                this.selectedCustomers = s3;
                y = Object.values(team[teamsList[index]][t])[c];
                for (let u = 0; u < y.length; u++) {
                  s4.push(y[u]);
                }
                this.selectedPlants = s4;
                this.plants = s4;
              }
            }
          }
        }
      });
    }
    // this.PlantsUpdate(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  PlantsUpdate(Rkeys, Tkeys, CKeys, Pkeys) {
    let s5 = [];
    for (let r = 0; r < Rkeys.length; r++) {
      for (let t = 0; t < Tkeys.length; t++) {
        for (let c = 0; c < CKeys.length; c++) {
          for (let p = 0; p < this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]].length; p++) {

            s5.push(this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]][p]);
            this.selectedPlants = s5
            this.plants = s5
            this.selectedL4 = s5
            console.log(this.plants);
          }
        }
      }
    }

  }

  onCategorySelect(item: string) {
    this.getCategoriesFilter(item);
  }
  onCategoriesAll(items: any) {
    this.selectedCategories = items;
    this.getCategoriesFilter(items);
  }
  onCategoriesDeSelect(deselectedSID: any): void {
    this.selectedCategories = this.selectedCategories.filter(s => s != deselectedSID);
    this.selectedCategories.forEach(sid => { this.onCategorySelect(sid); });
    this.getCategoriesFilter(this.selectedCategories);
  }

  onCategoriesDeSelectAll(items: any) {
    this.selectedCategories = [];
    

  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    this.TeamUpdatedOptions(this.selectedRegions, item, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onTeamDeSelect(deselectedTID: any): void {

    if (this.selectedTeams != null) {

      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = items;
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Customers
  oncustomerSelect(item: string) { this.TeamUpdatedOptions(this.selectedRegions, this.selectedPlants, this.selectedCustomers, this.selectedPlants); }
  oncustomersAll(items: any) {
    this.TeamUpdatedOptions(this.selectedRegions, items, this.selectedCustomers, this.selectedPlants);
  }
  oncustomerDeSelect(deselectedTCID: any): void { this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants); }
  oncustomersDeSelectAll(items: any) {
    this.selectedCustomers = items;
    this.selectedL3 = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  refresh() {
    this.selectedCategories = [];
    this.selectedRegions = [];
    this.selectedTeams = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.regions = [];
    this.customers = [];
    this.plants = [];
    this.teams = [];
    this.dateRange = null;
    this.getFiltersData();

  }
  //Plants
  onplantSelect(item: string) { }
  onplantsAll(items: any) { }
  onplantDeSelect(deselectedTPID: any): void { }
  onplantsDeSelectAll(items: any) {
  this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
}
