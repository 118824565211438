import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SCESupplyService } from 'src/app/services/sce-supply.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-sce-supply',
  templateUrl: './sce-supply.component.html',
  styleUrls: ['./sce-supply.component.css']
})
export class SCESupplyComponent implements OnInit {
  userId_val: any;
//data:any;
  smeFinanceObj: { table: {}; excel: any[]; };
  tableDisplay: boolean;
  dtOptions: DataTables.Settings = {};
  reportName: any;
  constructor(private sceSupply:SCESupplyService, private appService: AppService, private navbarTitle: NavbarComponent) { }

  ngOnInit() {
    this.userId_val = this.appService.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
    this.fetchData();
  }

    fetchData() {
        const userName = this.appService.userDetails.email;
        this.sceSupply.getTableData(userName).subscribe((resp: any) => {
            this.smeFinanceObj = this.sceSupply.post(resp);
            if (this.smeFinanceObj['table']) {
                let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
                this.appService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
                  console.log(reportdata);
                })
                this.dtOptions = this.smeFinanceObj['table'];
                this.tableDisplay = true;
            }
        });
    }

}
