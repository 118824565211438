import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { PlantService } from 'src/app/services/plant.service';
import { CustomerService } from 'src/app/fur/customer.service';

import { NavbarComponent } from '../navbar/navbar.component';
import { environment } from 'src/environments/environment';
const moment = extendMoment(Moment);

@Component({
  selector: 'app-customer-osod',
  templateUrl: './customer-osod.component.html',
  styleUrls: ['./customer-osod.component.css']
})
export class CustomerOSODComponent implements OnInit {
  reportName='';
  @Input('OSODWise') OSODWise = 0;
  datePickerenable: any;
  urlpath: string;
  selectedDate: any;
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public datePickerConfigForSupply: any = {};
  public bsValue: any = new Date();

  filterapplied: boolean;
  activeTab: any;
  furType: string;
  finalpostObj: {};
  regions: any;
  dropdownSettings = {};
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  selectedRegions: any;
  dtOptionsenable2: boolean;
  plantclicked: boolean;
  cohortBoolean: boolean;
  smeclicked: boolean;
  supplyclicked: boolean;
  clicked1: boolean;
  cohortclicked: boolean;
  teamclicked: boolean;
  plantCondition = false;
  tableCondition = false;
  finalDataObject = { table: {}, excel: [] };
  userId_val: any;
  constructor(private navbarTitle: NavbarComponent,private customerservice: CustomerService, private service: AppService, private osodservice: PlantService, private userId: AppService) {
    //public region:RegionComponent,public team:TeamComponent,public customer:CustomerComponent,public plants:PlantComponent
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }

  ngOnInit() {
    this.OSODWise = 2;
    this.selectedRegions = this.service.getUserDetails(['regions']);
    this.getDataByFilter();
    this.multiSelectDropdown();
    this.finalpostObj = this.service.userDetails.regions;
    this.selectedSalesType(this.OSODWise);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.multiSelectDropdown();
    this.getDataByFilter();
    this.bsValue = new Date();
  }


  getDataByFilter(date = this.bsValue) {
    this.selectedDate = moment(date).format('YYYY-MM');
    if (this.OSODWise === 0)
      this.selectedSalesType(this.OSODWise);
    if (this.OSODWise === 1)
      this.selectedSalesType(this.OSODWise);
    if (this.OSODWise === 2)
      this.selectedSalesType(this.OSODWise);
    if (this.OSODWise === 3)
      this.selectedSalesType(this.OSODWise);
  }

  multiSelectDropdown() {

    this.regions = this.service.getUserDetails(['regions']);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  selectedSalesType(salesType) {

    salesType = this.OSODWise;
    switch (salesType) {
      case 0: {
        this.urlpath = 'region_wise_osod';
        this.datePickerenable = true;
        this.selectedDate = this.bsValue;
        this.filterapplied = true;
        this.furType = "region";
        this.plantCondition = true;
        this.selectedDate = moment(this.bsValue).format('YYYY-MM');
        this.dtOptions = {};

        this.finalpostObj = {
          'region': this.regions,
          'date': this.selectedDate,
          'username': this.userId_val
        }
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        //  this.region.regionSales(0);
        break;
      }

      case 1: {
        this.urlpath = 'team_wise_osod';
        this.filterapplied = true;
        //this.activeTab = activeTab;
        this.furType = "region";
        this.plantCondition = true;
        this.dtOptions = {};
        this.finalpostObj = {
          'region': this.regions,
          'date': this.selectedDate,
          'username': this.userId_val


        }
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        // this.team.teamSales(0);
        break;
      }

      case 2: {

        this.urlpath = 'customer_wise_osod';
        this.filterapplied = true;
        //this.activeTab = activeTab;
        this.furType = "region";
        this.plantCondition = true;
        this.dtOptions = {};
        this.finalpostObj = {
          'region': this.regions,
          'date': this.selectedDate,
          'username': this.userId_val


        }
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        // this.team.teamSales(0);
        break;

      }

      case 3: {
        this.urlpath = 'plant_wise_osod';
        this.filterapplied = true;
        //this.activeTab = activeTab;
        this.furType = "region";
        this.plantCondition = true;
        this.dtOptions = {};
        this.finalpostObj = {
          'region': this.regions,
          'date': this.selectedDate,
          'username': this.userId_val


        }
        this.displayTable(this.urlpath, this.finalpostObj, this.furType);
        // this.team.teamSales(0);
        break;

      }
    }
  }


  displayTable(urlpath, finalpostObj, furType) {
    this.furType = furType;
    this.tableCondition = false;

    this.osodservice.saveInfo(urlpath, finalpostObj).subscribe(
      (response) => {

        if(response['data']['data1']!=undefined && response['status']=='SUCCESS'){
          let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
          this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
            console.log(reportdata);
          })
        }
        this.finalDataObject = this.osodservice.post(response);

        if (this.finalDataObject) {
          this.dtOptions = this.finalDataObject.table;
          if (this.dtOptions != null) {
            this.tableCondition = true;
          }
        }
        console.log(this.dtOptions)
      }
    )
  }
}
