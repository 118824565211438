import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { timeStamp } from 'console';
import { each } from 'jquery';
import { AppService } from 'src/app/services/app.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { companyPlantsList } from './mock';

@Component({
  selector: 'dashboard-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {
  @Output() onFiltersChange = new EventEmitter();
  showPlantDropdown = false;
  showCountryDropdown = false;
  showTeamsDropdown = false;
  companyAll = true;
  teamsAll = true;
  companyPlantsList = [];
  regionTeamsList = [];
  isOpen = false;
  dateRange: Date[];
  start_date: any;
  end_date = new Date;
  selectedPlants: any = [];
  selectedTeams: any;
  maxDate: Date = new Date();
  selectedCountry: any;
  bsValue: Date;
  selectedDateRange: any;
  changedCountry = 'India';
  companyPlantsListCopy: any;
  regionTeamsListCopy: any;
  selectedSearch = 'plants';
  changedSearchBy = 'plants';
  showSearchDropdown = false;
  searchText: any;
  plantsText = 'All';
  teamsText = '0 Selected';
  constructor(private dashBoardService: DashboardService, private appService: AppService) { 
    const currentDate = new Date();
    this.bsValue = new Date(currentDate.setDate(currentDate.getDate() - 30));
    this.dateRange = [this.bsValue, new Date()]
   }

  ngOnInit() {
    // this.companyPlantsList = companyPlantsList;
    this.getClientPlantsData();
    this.getTeamsData();
    this.countrySelect('India');
    this.onFiltersChange.emit({
      country: this.changedCountry,
      searchBy: this.changedSearchBy
    })
  }

  filterPlants(variable, value) {
    let data;
    if (variable == 'companyPlantsList') {
      data = this.companyPlantsListCopy;
    } else {
      data = this.regionTeamsListCopy
    }
    const list = [];
    if (data && data.length) {
      data.forEach(vertical => {
        const verticalName = vertical['searchWord'].toLowerCase();
        if (verticalName.includes(this.searchText.toLowerCase())) {
          if(!this.objExists(list, verticalName)) {
            list.push(vertical);
          }
        }
      });
      if (variable == 'companyPlantsList') {
        this.companyPlantsList = list;
      } else {
        this.regionTeamsList = list;
      }
    }
  }

  objExists(list, value) {
    return list.some(function(el) {
      return el.name === value;
    }); 
  }

  updateFields() {
    if(this.changedSearchBy == 'plants') {
      this.teamsText = '0 Selected';
      this.plantsText = this.companyAll ? 'All' : `${this.selectedPlants.length} Selected`;
    } else {
      this.teamsText = this.teamsAll ? 'All' : `${this.selectedTeams.length} Selected`;
      this.plantsText = '0 Selected'
    }
  }

  getTeamsData() {
    const email = this.appService.userDetails.email;
    const payload = {
      username: email
    }
    this.dashBoardService.getTeamsData(payload).subscribe((resp: any) => {
      const verticals = Object.keys(resp.data);
      const list = [];
      if(verticals && verticals.length) {
        verticals.forEach(each => {
          const regions = [];
          let searchText = '';
          if(resp && resp.data && resp.data[each] && resp.data[each].length) {
            resp['data'][each].forEach(region => {
              const plants: any = Object.values(region)[0] || [];
              plants.forEach(plant => {
              searchText += plant['teamName'] + Object.keys(region)[0];
              plant['plantName'] = plant['teamName'],
              plant['palntId'] = plant['teamId'],
              plant['isGrandChildSelected'] = true;
          })
              regions.push({
              regionName: Object.keys(region)[0],
              plants: plants,
              isChildSelected: true
          })
          })
            // if()
          }
          list.push({
            veticalName: each,
            searchWord: searchText + each,
            isParentSelected: true,
            regions: regions,
          })
        })
        this.regionTeamsList = list;
        this.regionTeamsListCopy = list;
        this.onTeamsSubmit(false);
      }
    })
  }

  getClientPlantsData() {
    const email = this.appService.userDetails.email;
    const payload = {
      username: email
    }
    this.dashBoardService.getPlantsData(payload).subscribe((resp: any) => {
      const companies = Object.keys(resp.data);
      const list = [];
      if(companies && companies.length) {
        companies.forEach(each => {
          const regions = [];
          let searchText = '';
          if(resp && resp.data && resp.data[each] && resp.data[each].length) {
            resp.data[each].forEach(element => {
              searchText += element.plantName;
              regions.push({
                regionName: element.plantName,
                isChildSelected: true,
                regionId: element.plantId,
                plants: []
              })
            });
          }
          list.push({
            veticalName: each,
            searchWord: searchText += each,
            isParentSelected: true,
            regions: regions,
          })
        })
        this.companyPlantsList = list;
        this.companyPlantsListCopy = JSON.parse(JSON.stringify(this.companyPlantsList))
        this.onPlantsSubmit(false);
      }
    });
  }

  hideAllFilters() {
    this.showPlantDropdown = false;
    this.showCountryDropdown = false;
    this.showTeamsDropdown = false;
    this.showSearchDropdown = false;
  }

  SelectAllCompanies(type, value, isAllCheck = false) {
    if(type == 'plants') {
      this.companyPlantsList.forEach((each, index) => {
        each.isParentSelected = value;
        this.parentSelection('', index, value, 'companyPlantsList');
      })
    } else if(type == 'regions') {
      this.regionTeamsList.forEach((each, index) => {
        each.isParentSelected = value;
        this.parentSelection('', index, value, 'regionTeamsList');
      })
    }
  }

  onTeamsSubmit(isOnload = false) {
    if(this.regionTeamsList && this.regionTeamsList.length) {
      this.selectedTeams = [];
      this.regionTeamsList.forEach(element => {
        if(element && element['regions'] && element['regions'].length) {
          element['regions'].forEach(region => {
              region['plants'].forEach(plant => {
                if(plant['isGrandChildSelected']) {
                  this.selectedTeams.push(plant['teamId']);
                }
              });
          });
        }
      });
      this.hideAllFilters();
      if(this.teamsAll) {
        this.teamsText = 'All'
      } else {
        this.teamsText = `${this.selectedTeams.length} Selected`
      }
      this.teamsText = this.changedSearchBy != 'teams' ? '0 Selected' : this.teamsText;
      if(!isOnload) {
        this.onFiltersChange.emit({
          filter: 'teams',
          teams: this.selectedTeams,
          isAllTeams: this.teamsAll
        })
      }
    }
  }

  onPlantsSubmit(isOnload = false) {
    if(this.companyPlantsList && this.companyPlantsList.length) {
      this.selectedPlants = [];
        this.companyPlantsList.forEach(element => {
          if(element && element['regions'] && element['regions'].length) {
            element['regions'].forEach(region => {
              if(region['isChildSelected']) {
                this.selectedPlants.push(region['regionId']);
              }
            });
          }
        });
      this.hideAllFilters();
      if(this.companyAll) {
        this.plantsText = 'All'
      } else {
        this.plantsText = `${this.selectedPlants.length} Selected`
      }
      if(!isOnload) {
        this.onFiltersChange.emit({
          filter: 'plants',
          plants: this.selectedPlants,
          isAllPlants: this.companyAll
        })
      }
    }
  }

  parentSelection(type, index, value, dataVariable ,isAllCheck = false){
      if(!isAllCheck) {
        this[dataVariable][index].regions.forEach((each, i) => {
          each.isChildSelected = value;
          this.childSelection('', index, i, value, dataVariable)
        });
      }
      const isAllSelected = this[dataVariable].every(each => each.isParentSelected == true);
      if(isAllSelected) {
        if(dataVariable == 'companyPlantsList') {
          this.companyAll = true;
        } else {
          this.teamsAll = true;
        }
      } else {
        if(dataVariable == 'companyPlantsList') {
          this.companyAll = false;
        } else {
          this.teamsAll = false;
        }
      }
  }

  childSelection(type, index, childIndex, value, dataVariable, isAllCheck = false) {
    if(!isAllCheck) {
      this[dataVariable][index].regions[childIndex].plants.forEach(each => {
        each.isGrandChildSelected = value;
      });
    }
    const isAllSelected = this[dataVariable][index].regions.every(each => each.isChildSelected == true);
    if(isAllSelected) {
      this[dataVariable][index].isParentSelected = true;
      this.parentSelection('', index, value, dataVariable,true)
    } else {
      this[dataVariable][index].isParentSelected = false;
      if(dataVariable == 'companyPlantsList') {
        this.companyAll = false;
      } else {
        this.teamsAll = false;
      }
    }
  }

  countrySelect(value, event?) {
    this.selectedCountry = value;
  }

  changeSearchBy(value, event) {
    this.selectedSearch = value;
  }

  grandChildSelection(type, index, childIndex, grandChildIndex,value, dataVariable) {
    const isAllSelected = this[dataVariable][index].regions[childIndex].plants.every(each => each.isGrandChildSelected == true);
    if(isAllSelected) {
      this[dataVariable][index].regions[childIndex].isChildSelected = true;
      this.childSelection('', index, childIndex, value, dataVariable,true);
    } else {
      this[dataVariable][index].regions[childIndex].isChildSelected = false;
      this[dataVariable][index].isParentSelected = false;
      if(dataVariable == 'companyPlantsList') {
        this.companyAll = false;
      } else {
        this.teamsAll = false;
      }     
    }
  }

  async getDateRange(value) {
    if(!value) {
      const currentDate = new Date();
      const start = new Date(currentDate.setDate(currentDate.getDate() - 30));
      value = [start, new Date()]
    }
    this.onFiltersChange.emit({
      filter: 'date',
      start_date: this.getDateFormat(value[0]),
      end_date: this.getDateFormat(value[1])
    });
  }
  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let dateVal: string = [year, month, day].join('-');
    return dateVal;
  }

}
