import { Chart } from 'angular-highcharts';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getLocaleDateFormat } from '@angular/common';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SMEMgmtScoreCardService } from 'src/app/services/sme-mgmt-score-cardservice.service';
import { ChartDataService } from 'src/app/services/chart-data.service';
import { UrlConfig } from 'src/app/helpers/urlConfig';
import { NavbarComponent } from '../navbar/navbar.component';
const moment = extendMoment(Moment);

@Component({
  selector: 'app-sme-inside-sales',
  templateUrl: './sme-inside-sales.component.html',
  styleUrls: ['./sme-inside-sales.component.css']
})
export class SmeInsideSalesComponent implements OnInit {
  reportName ='';
  smeMenuList = [
    { 'title': "SME Scorecard", id: 0 },
    { 'title': "SME Inside Sales", id: 1 },
    { 'title': "SME Self Service", id: 2 }
  ]
  public bsValue: any = new Date();
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public minDate: any = new Date('2019-04-01');
  public maxDate: any = new Date();
  month: any;
  year: any;

  selectedTransaction = 'All';
  selectedBusiness = 'All'
  business_dropdown = [
    { id: 0, businessNumber: "All" },
    { id: 1, businessNumber: "Self-Serve" },
    { id: 2, businessNumber: "Inside Sales" }
  ]
  transaction_dropdown = [
    { id: 0, transactionNumber: "All" },
    { id: 1, transactionNumber: "Retail" },
    { id: 2, transactionNumber: "Tax" }
  ]

  currentactiveTab: any;
  mngmt_data: any;
  dtOptions: DataTables.Settings = {};
  dtOptionsenable: boolean;
  tableCondition: boolean;
  public visualTable: any;
  public urlpath: any;
  userId_val: any;
  requestObj: any;
   selected = 1;
  //tabular
  constructor(private navbarTitle: NavbarComponent,private spinner: NgxSpinnerService,
    private router: Router,
    private appService: AppService, private smemgmtScoreCardservice: SMEMgmtScoreCardService,
    private userId: AppService) {
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }


  ngOnInit() {
    //this.save(new Date(),this.selectedTransaction,this.selectedBusiness);


    this.userId_val = this.userId.userDetails.email;
    this.getVisual(this.selected);
    this.urlpath = environment.baseURL_FREQ+UrlConfig.sme_inside_sales;
    this.getfinalData(this.urlpath);

  }


  ngOnChanges(changes: SimpleChanges) {
    this.getVisual(this.selected);
  }



  isActive(e_menu_id) {
    return this.selected === e_menu_id;
  };


  getVisual(e_menu_id) {
    this.selected = e_menu_id;
    this.currentactiveTab = e_menu_id;

    switch (e_menu_id) {
      case 0: {
        this.selected = 0;
        this.urlpath = environment.baseURL_FREQ+UrlConfig.sme_summary;
        this.getfinalData(this.urlpath);
        break;
      }
      case 1: {
        this.selected = 1;
        this.urlpath = environment.baseURL_FREQ+UrlConfig.sme_inside_sales;
        this.getfinalData(this.urlpath);
        break;
      }
      case 2: {
        this.selected = 2;
        this.urlpath = environment.baseURL_FREQ+UrlConfig.sme_self_serve;
        this.getfinalData(this.urlpath);
        break;
      }
    }
  }

  getfinalData(url) {

    this.dtOptions = {};
    this.dtOptionsenable = false;

    this.tableCondition = false;
    this.visualTable = false;
    this.requestObj = { 'username': this.userId_val }
    this.appService.sendPostRequest(url, this.requestObj).subscribe(res => {

      if(res['data']['result']!=undefined && res['status']=='SUCCESS'){
        let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
        this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
          console.log(reportdata);
        })
      }
      this.mngmt_data = this.smemgmtScoreCardservice.post(res.data);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
        }
      }
    });
  }

}
