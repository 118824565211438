import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-item-es-na',
  templateUrl: './reports-item-es-na.component.html',
  styleUrls: ['./reports-item-es-na.component.css']
})
export class ReportsItemEsNAComponent implements OnInit {
  @Input() stages = [];
  @Input() reportsData = [];


  constructor() { }
  
  ngOnInit() {
  }

}
