
import { ChartsComponent } from './components/charts/charts.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ChartModule } from 'angular-highcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import bulletChart from 'highcharts/modules/bullet.src';
import * as Highcharts from 'highcharts';

bulletChart(Highcharts);

import {NgSelectModule, NgOption} from '@ng-select/ng-select';


import { AppComponent } from './app.component';
import { GraphsComponent } from './components/graphs/graphs.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgSparklineComponent } from './components/ng-sparkline/ng-sparkline.component';
import { LoginComponent } from './components/login/login.component';
import { AppService } from './services/app.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InterceptorsService } from './interceptor';
import { AuthGuard } from './helpers/auth.guard';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  GoogleLoginProvider,
  AuthServiceConfig, AuthService
} from 'angularx-social-login';
import { SocialLoginModule } from 'angularx-social-login';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ReportsHomePageComponent } from './reports-home-page/reports-home-page.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import {ToastrModule} from 'ngx-toastr';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {CommonService} from './shared/common.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { HomeComponent } from './home/home.component';
import { MGMTScoreComponent } from './components/mgmt-score/mgmt-score.component';
import { EnterpriseRegionWiseComponent } from './components/enterprise-region-wise/enterprise-region-wise.component';

import { EnterpriseTeamWiseComponent } from './components/enterprise-team-wise/enterprise-team-wise.component';
import { CohortQuarterRevenueComponent } from './components/cohort-quarter-revenue/cohort-quarter-revenue.component';
import { CohortQuarterPlantComponent } from './components/cohort-quarter-plant/cohort-quarter-plant.component';
import { CohortMonthlyRevenueComponent } from './components/cohort-monthly-revenue/cohort-monthly-revenue.component';
import { CohortMonthlyPlantComponent } from './components/cohort-monthly-plant/cohort-monthly-plant.component';
import { RegionalSalesComponent } from './components/regional-sales/regional-sales.component';
import { TeamSalesComponent } from './components/team-sales/team-sales.component';
import { CustomerSalesComponent } from './components/customer-sales/customer-sales.component';
import { PlantSalesComponent } from './components/plant-sales/plant-sales.component';
import { RegionalOSODComponent } from './components/regional-osod/regional-osod.component';
import { TeamOSODComponent } from './components/team-osod/team-osod.component';
import { CustomerOSODComponent } from './components/customer-osod/customer-osod.component';
import { PlantOSODComponent } from './components/plant-osod/plant-osod.component';
import { ItemTrackingOTIFComponent } from './components/item-tracking-otif/item-tracking-otif.component';
import { ItemTrackingDayWiseComponent } from './components/item-tracking-day-wise/item-tracking-day-wise.component';
import { ItemTrackingListWiseComponent } from './components/item-tracking-list-wise/item-tracking-list-wise.component';
import { OpenClientInvoiceComponent } from './components/open-client-invoice/open-client-invoice.component';
import { OpenClientPOComponent } from './components/open-client-po/open-client-po.component';
import { OpenSupplierPOComponent } from './components/open-supplier-po/open-supplier-po.component';
import { PendingInboundComponent } from './components/pending-inbound/pending-inbound.component';
import { PendingPODComponent } from './components/pending-pod/pending-pod.component';
import { PendingShippingComponent } from './components/pending-shipping/pending-shipping.component';

import { WarehouseSLAComponent } from './warehouse-sla/warehouse-sla.component';
import { PoCancellationComponent } from './po-cancellation/po-cancellation.component';
import { PickupFailureComponent } from './pickup-failure/pickup-failure.component';
import { PoCancellationDetailedComponent } from './components/po-cancellation-detailed/po-cancellation-detailed.component';
import { AutoPODetailedComponent } from './components/auto-po-detailed/auto-po-detailed.component';
import { UnusedSuppliersComponent } from './components/unused-suppliers/unused-suppliers.component';
import { DeliveryPendencyComponent } from './components/delivery-pendency/delivery-pendency.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SmeDashboardsComponent } from './components/sme-dashboards/sme-dashboards.component';
import { SmeInsideSalesComponent } from './components/sme-inside-sales/sme-inside-sales.component';
import { SmeSelfServiceComponent } from './components/sme-self-service/sme-self-service.component';
import { ItemTrackingEsComponent } from './components/item-tracking-es/item-tracking-es.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsItemEsNAComponent } from './components/reports-item-es-na/reports-item-es-na.component';
import { SelfServeComponent } from './components/self-serve/self-serve.component';
import { ItemTrackingOTIFDetailedComponent } from './components/item-tracking-otif-detailed/item-tracking-otif-detailed.component';
import { CustomerExcellenceComponent } from './components/customer-excellence/customer-excellence.component';
import { AdvancePOFinanceComponent } from './components/advance-po-finance/advance-po-finance.component';
import { CsComponent } from './components/cs/cs.component';
import { BuDataInputComponent } from './components/bu-data-input/bu-data-input.component';
import { RegionScorecardBUComponent } from './components/region-scorecard-bu/region-scorecard-bu.component';
import { ZohoDashboardComponent } from './components/zoho-dashboard/zoho-dashboard.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CsStrategyComponent } from './components/cs-strategy/cs-strategy.component';
import { Butop20Component } from './components/butop20/butop20.component';
import { BridgeReportComponent } from './components/bridge-report/bridge-report.component';
import { BUReportsScorecardsComponent } from './components/bu-reports-scorecards/bu-reports-scorecards.component';
import { WHReportComponent } from './components/wh-report/wh-report.component';

import { SMEFinanceComponent } from './components/sme-finance/sme-finance.component';
import { SCESupplyComponent } from './components/sce-supply/sce-supply.component';
import { BulkInvoiceDownloadComponent } from './components/bulk-invoice-download/bulk-invoice-download.component';
import { DashboardsComponent } from './components/dashboards/dashboards.component';
import { InsightsComponent } from './components/insights/insights.component';
import { FiltersComponent } from './components/filters/filters.component';
import { ReportGeneratorComponent } from './components/report-generator/report-generator.component';
import { NamComponent } from './components/nam/nam.component';
import { PodTrackerComponent } from './components/pod-tracker/pod-tracker.component';
import { RegionTargetComponent } from './components/region-target/region-target.component';
import { Report101Component } from './components/report101/report101.component';
import { S3reportComponent } from './components/s3report/s3report.component';
import { RfqDashboardsComponent } from './components/rfq-dashboards/rfq-dashboards.component';
import { RfqDashboardsL1Component } from './components/rfq-dashboards-l1/rfq-dashboards-l1.component';
import { RfqDashboardsL3Component } from './components/rfq-dashboards-l3/rfq-dashboards-l3.component';
import { RfqDashboardsL2Component } from './components/rfq-dashboards-l2/rfq-dashboards-l2.component';
import { SupplyL3Component } from './components/supply-l3/supply-l3.component';
import { SupplyL2Component } from './components/supply-l2/supply-l2.component';
import { SupplyL1Component } from './components/supply-l1/supply-l1.component';
import { SupplyL0Component } from './components/supply-l0/supply-l0.component';
import { CreditDaysComponent } from './components/credit-days/credit-days.component';
import { CreditDaysL1Component } from './components/credit-days-l1/credit-days-l1.component';
import { CreditDaysL2Component } from './components/credit-days-l2/credit-days-l2.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { PickupL3Component } from './components/pickup-adherance-l3/pickup-l3.component';
import { PickupL2Component } from './components/pickup-adherance-l2/pickup-l2.component';
import { PickupL1Component } from './components/pickup-adherance-l1/pickup-l1.component';
import { PickupL0Component } from './components/pickup-adherance-lo/pickup-l0.component';
import { RegionsalesIDComponent } from './components/regionsales-id/regionsales-id.component';
import { CustomersalesIDComponent } from './components/customersales-id/customersales-id.component';
import { AdvancePOSupplyComponent } from './components/advance-po-supply/advance-po-supply.component';
import { KAMCategoryComponent } from './components/kam-category/kam-category.component';
import { PlantMasterComponent } from './components/plant-master/plant-master.component';
import { PlantMasterSummaryComponent } from './components/plant-master-summary/plant-master-summary.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { VendorComponent } from './components/vendor/vendor.component';



const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.clientID)
  }
]);

export function provideConfig() {
  return config;
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.microClientID, //application client id
      // redirectUri: 'http://localhost:4200',//'https://buyersqanew.moglix.com',
      authority: `https://login.microsoftonline.com/${environment.microTenantID}` //added tenant id
    }
  });
}
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: '', component: NavbarComponent,
       
    children: [
      { path: 'LandingPage', component: LandingPageComponent},
     // { path: '', redirectTo: 'graphs', pathMatch: 'full' },
     // { path: 'home', component: ReportsHomePageComponent},
      { path: 'graphs', component: ChartsComponent },
      { path: 'mgmtScore', component: MGMTScoreComponent },
      {path:'enterpriseRegion',component:EnterpriseRegionWiseComponent},
      {path:'enterpriseTeam',component:EnterpriseTeamWiseComponent},

      { path: 'smeDashboards', component: SmeDashboardsComponent },
      {path:'smeInsideSales',component:SmeInsideSalesComponent},
      {path:'smeSelfService',component:SmeSelfServiceComponent},




      {path:'cohortQuarterRevenue',component:CohortQuarterRevenueComponent},
      {path:'cohortQuarterPlant',component:CohortQuarterPlantComponent},
      {path:'cohortMonthlyRevenue',component:CohortMonthlyRevenueComponent},
      {path:'cohortMonthlyPlant',component: CohortMonthlyPlantComponent},
      {path:'regionalSales',component: RegionalSalesComponent},
      {path:'teamSales',component: TeamSalesComponent},
      {path:'customerSales',component: CustomerSalesComponent},
      {path:'plantSales',component: PlantSalesComponent},
      {path:'regionalOSOD',component: RegionalOSODComponent},
      {path:'teamOSOD',component: TeamOSODComponent},
      {path:'customerOSOD',component: CustomerOSODComponent},
      {path:'plantOSOD',component: PlantOSODComponent},
      {path:'deliveryPendency',component: DeliveryPendencyComponent},
      {path:'itemTrackingOTIF',component: ItemTrackingOTIFComponent},
      {path:'itemTrackingDayWise',component: ItemTrackingDayWiseComponent},
      {path:'itemTrackingListWise',component: ItemTrackingListWiseComponent},
      {path:'openClientInvoice',component: OpenClientInvoiceComponent},
      {path:'openClientPO',component: OpenClientPOComponent},
      {path:'openSupplierPO',component: OpenSupplierPOComponent},
      {path:'pendingInbound',component: PendingInboundComponent},
      {path:'pendingPOD',component: PendingPODComponent},
      {path:'pendingShipping',component:PendingShippingComponent},
      {path:'autoPODetailed',component:AutoPODetailedComponent},
      {path:'pickupFailure',component:PickupFailureComponent},
      {path:'poCancellationDetailed',component:PoCancellationDetailedComponent},
      {path:'warehouseSLA',component:WarehouseSLAComponent},
      {path:'unusedSuppliers',component:UnusedSuppliersComponent},
      { path: 'self-serve', component: SelfServeComponent},
      { path: 'graphCE', component: CustomerExcellenceComponent},
     
      { path:'budataInput', component:BuDataInputComponent},
      { path: 'regionBU', component: RegionScorecardBUComponent },
     // { path: 'mgmtSC', loadChildren:'./mgmt-score-card/mgmt-score-card.module#MgmtScoreCardModule'},
      //{ path: 'sme-charts', loadChildren: './sme/sme.module#SmeModule' },
      { path: 'self-serve', loadChildren: './self-serve/self-serve.module#SelfServeModule'},
      { path: 'furSR', loadChildren: './fur/fur.module#FurModule'  },
      { path: 'furDD', loadChildren: './fur/fur.module#FurModule'  },
      { path: 'furSC', loadChildren: './fur/fur.module#FurModule'  },
      // { path: 'supply-side', loadChildren: './supply-side/supply-side.module#SupplySideModule' },  
      { path: 'csbreach', component: CsComponent },
      { path: 'advance_po', component: AdvancePOFinanceComponent },
      {path:'zoho_dashboard',component: ZohoDashboardComponent},
      { path: 'online_pendency', component: CsStrategyComponent },


      // { path: 'butop20', component: Butop20Component},
      // { path: 'bridgeBU', component: BridgeReportComponent},
      // {path: 'buReports', component:BUReportsScorecardsComponent},
      
      {path:'inventory',component:InventoryComponent},

      {path:'cpnmsnMapping',component:FileUploadComponent},
      {path:'WHreorderMSN',component:WHReportComponent},
      {path:'smeFinance',component:SMEFinanceComponent},
      {path:'SCEsupply',component:SCESupplyComponent},
      {path: 'bulkpod', component: BulkInvoiceDownloadComponent},
      { path: 'dashboard', component: DashboardsComponent },
      { path: 'insights', component: InsightsComponent },
      {path: 'targetview', component: ReportGeneratorComponent},
      {path: 'customers', component: NamComponent},
      {path: 'pod_tracker', component: PodTrackerComponent},
      {path: 'regionTargets', component: RegionTargetComponent},
      { path: 'sales360', component: Report101Component },
      { path: 's3Report/:folder/:fileName', component: S3reportComponent },
      { path: 's3Report/:folder/:subFolder/:fileName', component: S3reportComponent },      
      {path: 'rfq_dashboard', component: RfqDashboardsComponent},
      {path: 'rfq_dashboard_l1', component: RfqDashboardsL1Component},
      {path: 'rfq_dashboard_l2', component: RfqDashboardsL2Component},
      {path: 'rfq_dashboard_l3', component: RfqDashboardsL3Component},
      {path: 'sales_dashboard', component: SupplyL0Component},
      {path: 'sales_dashboard_l1', component: SupplyL1Component},
      {path: 'sales_dashboard_l2', component: SupplyL2Component},
      {path: 'sales_dashboard_l3', component: SupplyL3Component},
      {path: 'credit_days', component: CreditDaysComponent},
      {path: 'credit_days_l1', component: CreditDaysL1Component},
      {path: 'credit_days_l2', component: CreditDaysL2Component},
      {path: 'pick_adherance', component: PickupL0Component},
      {path: 'pick_adherance_l1', component: PickupL1Component},
      {path: 'pick_adherance_l2', component: PickupL2Component},
      {path: 'pick_adherance_l3', component: PickupL3Component},
      {path: 'regionalIndrectSales', component: RegionsalesIDComponent},
      {path: 'customerIndrectSales', component: CustomersalesIDComponent},
      {path: 'kam_category_level', component: KAMCategoryComponent},
      {path: 'advance_PO_Supply', component: AdvancePOSupplyComponent},
      {path: 'vendor_deatils', component: VendorComponent},
      {path:'plantMaster',component:PlantMasterComponent},
      {path:'plantMasterSummary',component:PlantMasterSummaryComponent},

      {path:'lpp_upload',component :CustomerExcellenceComponent},
      
      {path:'product-search',component:ProductSearchComponent}

      //     { path: 'supply-side', loadChildren: './supply-side/supply-side.module#SupplySideModule' }
    ],
     canActivate: [AuthGuard]
  }

];


@NgModule({
  declarations: [
    AppComponent,
    GraphsComponent,
    NavbarComponent,
    ChartsComponent,
    NgSparklineComponent,
    LoginComponent,
    TopNavBarComponent,
    MGMTScoreComponent,
    EnterpriseRegionWiseComponent,
    EnterpriseTeamWiseComponent,
    CohortQuarterRevenueComponent,
    CohortQuarterPlantComponent,
    CohortMonthlyRevenueComponent,
    CohortMonthlyPlantComponent,
    RegionalSalesComponent,
    TeamSalesComponent,
    CustomerSalesComponent,
    PlantSalesComponent,
    RegionalOSODComponent,
    TeamOSODComponent,
    CustomerOSODComponent,
    PlantOSODComponent,
    ItemTrackingOTIFComponent,
    ItemTrackingDayWiseComponent,
    ItemTrackingListWiseComponent,
    OpenClientInvoiceComponent,
    OpenClientPOComponent,
    OpenSupplierPOComponent,
    PendingInboundComponent,
    PendingPODComponent,
    PendingShippingComponent,
    WarehouseSLAComponent,
    PoCancellationComponent,
    PickupFailureComponent,
    UnusedSuppliersComponent,
    AutoPODetailedComponent,
    PoCancellationDetailedComponent,
    DeliveryPendencyComponent,
    SmeDashboardsComponent,
    SmeInsideSalesComponent,
    SmeSelfServiceComponent,
    ItemTrackingEsComponent,
    SelfServeComponent,
    ReportsComponent,    ReportsItemEsNAComponent, ItemTrackingOTIFDetailedComponent, CustomerExcellenceComponent,
    AdvancePOFinanceComponent, CsComponent, BuDataInputComponent, RegionScorecardBUComponent, ZohoDashboardComponent,
    InventoryComponent, FileUploadComponent, CsStrategyComponent, 
    Butop20Component,
    BridgeReportComponent,
    WHReportComponent,
    BUReportsScorecardsComponent,
    SMEFinanceComponent,
    SCESupplyComponent,
    BulkInvoiceDownloadComponent,
    DashboardsComponent,
    FiltersComponent,
    InsightsComponent,
    ReportGeneratorComponent,
    NamComponent,
    PodTrackerComponent,
    RegionTargetComponent,
    Report101Component,
    S3reportComponent,
    RfqDashboardsComponent,
    RfqDashboardsL1Component,
    RfqDashboardsL2Component,
    RfqDashboardsL3Component,
    SupplyL0Component,
    SupplyL1Component,
    SupplyL2Component,
    SupplyL3Component,
    CreditDaysComponent,
    CreditDaysL1Component,
    CreditDaysL2Component,
    LandingPageComponent,
    PickupL0Component,
    PickupL1Component,
    PickupL2Component,
    PickupL3Component,
    RegionsalesIDComponent,
    CustomersalesIDComponent,
    AdvancePOSupplyComponent,
    KAMCategoryComponent,
    PlantMasterComponent,
    PlantMasterSummaryComponent,
    ProductSearchComponent
  ],
  imports: [
    
    BrowserModule,DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    NgxPaginationModule,
    ChartModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    NgxSpinnerModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    DataTablesModule,
        NgSelectModule,
    NgMultiSelectDropDownModule,
    ToastrModule.forRoot({
   timeOut: 3000,
   positionClass: 'toast-top-right',
   preventDuplicates: true,
 }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [AppService, CommonService, InterceptorsService, 
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorsService, multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    AuthService,
  
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
