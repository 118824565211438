import { Component, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from 'src/app/services/app.service';
import { RfqService } from 'src/app/services/rfq.service';
import { SCESupplyService } from 'src/app/services/sce-supply.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-rfq-dashboards-l1',
  templateUrl: './rfq-dashboards-l1.component.html',
  styleUrls: ['./rfq-dashboards-l1.component.css']
})
export class RfqDashboardsL1Component implements OnInit, OnChanges {
  chart: Chart;
 
  dateRanges = { start_date: new Date(), end_date: new Date() };
  DateCalender: any;
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  // public selectedL1 = [];
  // public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  dateRange: any;
  userId_val: any;
  splineChart: any;
  quotePendancyChart: any;
  RkeyValue: any;
  filterData: any = {};
  highValue = 'all';
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  tableDisplay: boolean;
  tableDisplay1: boolean;
  tableDisplay2: boolean;
  tableDisplay4: boolean;
  reportName: any;
  quoteData: any;
  GMData: any;
  bsValue: Date;
  status: any = 0;
  catL1list = [];
  catL2list = [];
  selectedL1 = [];
  selectedL2 = [];
  cat1dropdownSettings = {};
  cat2dropdownSettings = {};
  dropDownSelect = false;

  constructor(private commonService: CommonService, private navbarTitle: NavbarComponent,private sceService: SCESupplyService,private service: SelfServeService, private rfqService: RfqService, private appService: AppService) {
    this.userId_val = this.appService.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
    const currentDate = new Date();
    this.bsValue = new Date(currentDate.setDate(currentDate.getDate() - 30));
    this.dateRange = [this.bsValue, new Date()]
    this.getFiltersData();
    this.multiSelectDropdown();
    this.fetchCat1list();
    this.getSelectedFilters(this.selectedRegions);
   }

  ngOnInit() {
  }
  ngOnChanges() {
    this.getSelectedFilters(this.selectedRegions);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  fetchCat1list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l1_cat_list';
    this.rfqService.getCall(url).subscribe(filtersRes => {
      this.catL1list = filtersRes["L1_Category"];
    });
  }
  fetchCat2list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l2_cat_list';
    const ids = [];
    this.selectedL1.forEach(each => {
      ids.push(each.id);
    })
    this.rfqService.postCall(url, {id: ids}).subscribe(filtersRes => {
      this.catL2list = filtersRes["L2_Category"];
    });
  }

  fetchData1() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l0/pendency_table_region_category';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if (resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
      }
      const smeFinanceObj = this.sceService.post(resp);
      if (smeFinanceObj['table']) {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.appService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
        this.dtOptions = smeFinanceObj['table'];
        this.tableDisplay = true;
      }
    });
  }

  fetchData2() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l0/pendency_table_region';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if (resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
      }
      const smeFinanceObj = this.sceService.post(resp);
      if (smeFinanceObj['table']) {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.appService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
        this.dtOptions1 = smeFinanceObj['table'];
        this.tableDisplay1 = true;
      }
    });
  }

  fetchData3() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l1/pendency_table_region_customer';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if (resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
      }
      const smeFinanceObj = this.sceService.post(resp);
      if (smeFinanceObj['table']) {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.appService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
        this.dtOptions2 = smeFinanceObj['table'];
        this.tableDisplay2 = true;
      }
    });
  }

  fetchData4() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l2/pendency_sourcer';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if (resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
      }
      const smeFinanceObj = this.sceService.post(resp);
      if (smeFinanceObj['table']) {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
        this.appService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
        })
        this.dtOptions4 = smeFinanceObj['table'];
        this.tableDisplay4 = true;
      }
    });
  }

  calculateDateRange(value: Date): void {
    if(value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 185) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      } 
    }
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.cat1dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      idField: 'id',
      textField: 'category_name'
    };
    this.cat2dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }

  getFiltersData() {
    this.rfqService.getFiltersItemTrackingES(this.userId_val).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
      this.selectedRegions = this.regions;
      this.generateReports();
    });
  }

  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }
  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }
  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    this.TeamUpdatedOptions(this.selectedRegions, item, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onTeamDeSelect(deselectedTID: any): void {

    if (this.selectedTeams != null) {

      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = items;
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Customers
  oncustomerSelect(item: string) { this.TeamUpdatedOptions(this.selectedRegions, this.selectedPlants, this.selectedCustomers, this.selectedPlants); }
  oncustomersAll(items: any) {
    this.TeamUpdatedOptions(this.selectedRegions, items, this.selectedCustomers, this.selectedPlants);
  }
  oncustomerDeSelect(deselectedTCID: any): void { this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants); }
  oncustomersDeSelectAll(items: any) {
    this.selectedCustomers = items;
    this.selectedL3 = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Plants
  onplantSelect(item: string) { }
  onplantsAll(items: any) { }
  onplantDeSelect(deselectedTPID: any): void { }
  onplantsDeSelectAll(items: any) {
  this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }



  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {
    let teamsList = [];
    let s2 = [];
    let s3 = [];

    let s4 = [];
    this.selectedCustomers = [];
    this.customers = [];
    for (let r = 0; r < Rkeys.length; r++) {

      let y: any = [];
      let regionName = Rkeys[r]
      this.TeamRegionsListObj[regionName].forEach((team, index) => {
        teamsList.push(Object.keys(team));
        for (let st = 0; st < this.selectedTeams.length; st++) {
          if (this.selectedTeams[st] == teamsList[index]) {
            s2.push(teamsList[index]);
            for (let t = 0; t < team[teamsList[index]].length; t++) {
              for (let c = 0; c < Object.keys(team[teamsList[index]][t]).length; c++) {
                s3.push(Object.keys(team[teamsList[index]][t])[c]);
                this.customers = s3;
                this.selectedCustomers = s3;
                y = Object.values(team[teamsList[index]][t])[c];
                for (let u = 0; u < y.length; u++) {
                  s4.push(y[u]);
                }
                this.selectedPlants = s4;
                this.plants = s4;
              }
            }
          }
        }
      });
    }
    // this.PlantsUpdate(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  PlantsUpdate(Rkeys, Tkeys, CKeys, Pkeys) {
    let s5 = [];
    for (let r = 0; r < Rkeys.length; r++) {
      for (let t = 0; t < Tkeys.length; t++) {
        for (let c = 0; c < CKeys.length; c++) {
          for (let p = 0; p < this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]].length; p++) {

            s5.push(this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]][p]);
            this.selectedPlants = s5
            this.plants = s5
            this.selectedL4 = s5
            console.log(this.plants);
          }
        }
      }
    }

  }
  getDateFormat(date, isLast = false) {
    date = new Date(date);
    if (!date) return;
    if(isLast) {
      date.setDate(date.getDate() + 1);
    }
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }

  generateReports() {
    const payload = this.generatePayload();
    if(this.dateRange && this.dateRange.length) {
      this.generateQuotePendancyGraph(payload);
      this.generateWinLossGraph(payload);
      this.fetchData1();
      this.fetchData2();
      this.fetchData3();
      this.fetchData4();
    } else {
      this.commonService.showToast('Please select a date range', 3);
    }
  }


  generateQuotePendancyGraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l1/tat_dherence__by_value';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      this.generateQuotePendancyChart(resp);
    })
  }

  downloadExcel() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l012/rfq/data_download';
    const payload = this.generatePayload();
    if(payload['start_date'] == '' || payload['end_date'] == '') {
      this.commonService.showToast('Please select a date range', 3);
      return;
    }
      this.rfqService.postCall(url, payload).subscribe(resp => {
      const excelData = this.sceService.post(resp['data']['raw_data'])
      return this.service.exportAsExcelFile(excelData['excel'], 'RFQ-l1-Data');
    })
  }

  generateWinLossGraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard//l1/win_loss';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      this.generateWinLoassgraph(resp);
    })
  }

  generatePayload() {
    const payload = {
      "start_date": this.dateRange && this.dateRange.length ? this.getDateFormat(this.dateRange[0]) : '',
      "end_date": this.dateRange && this.dateRange.length ? this.getDateFormat(this.dateRange[1], true) : '',
      "regions": this.selectedRegions,
      // "teams": this.selectedTeams,
      // "customers": this.selectedCustomers,
      // "plants": this.selectedPlants,
      "pendency_status_code": Number(this.status) || 0,
      
      "username": this.userId_val,
      "high_value": this.highValue == 'all' ? 2 : this.highValue == '10l' ? 0 : 1,
    }
    if(this.selectedL2.length) {
      payload['category2'] = this.selectedL2;
      payload['category1'] = [];
    } else if(this.selectedL1.length) {
      const categoryNames = [];
      this.selectedL1.forEach(each => {
        categoryNames.push(each.category_name);
      });
      payload['category1'] = categoryNames;
      payload['category2'] = [];
    } else {
      payload['category1'] = [];
      payload['category2'] = [];
    }
    return payload;
  }
  pendencystatus(pendencystatus: any) {
    throw new Error('Method not implemented.');
  }

  exportAsXLSX1(): void {
    this.service.exportAsExcelFile(this.GMData, 'GMData');
  }

  generateValuesForGraph(data) {
    const values = [];
    const categories = [];
    if (data && data.length) {
      data.forEach(element => {
        if(element['Y'] > 100) {
          values.push((+element['Y'] / 100000));
        } else {
          values.push(element['Y'] || 0);
        }
        categories.push(element['X']);
      });
    }
    return {
      values: values,
      categories: categories
    };
  }

  generateWinLoassgraph(data) {
    const graphData = this.generateValuesForGraph(data['data']['win_loss_percentage']);
    const options: any = {
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'Reasons for RFQ Loss'
      },
      xAxis: {
        categories: graphData.categories,
      },
      yAxis: {
        labels: {
          format: '{value} L',
          style: {}
        },
      },
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{	
        name: 'Total (INR Lakhs)',
        data: graphData.values,
        color: 'blue'
      }]
    }
    this.splineChart = new Chart(options);
    const excelData = this.sceService.post(data && data.data.win_loss_percentage_raw_data);
    this.GMData = excelData['excel'];
  }

  generateQuotePendancyChart(data?) {
    const response = this.generateValuesForGraph(data['data']['total_quoted_value']);
    const winRate = this.generateValuesForGraph(data['data']['win_rate_percent']);
    const tat = this.generateValuesForGraph(data['data']['rfq_tat_%']);
    const perRegion = this.generateValuesForGraph(data['data']['response_%']);
    const options: any = {
      chart: {
        type: 'line',
        height: 300
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: winRate.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {}
        },
        labels: {
          format: '{value}%',
          style: {}
        },
        min: 0
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value} L',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{
        name: 'Sum of Quoted Value (INR Lakhs)',
        data: response.values,
        yAxis: 1,
        type: 'column',
        color: 'yellow'
      },
      {
        name: 'Sum of Win Rate(%)',
        color: 'blue',
        yAxis: 0,
        data: winRate.values,
      },
      {
        name: 'Sum of TAT Adherence(%)',
        color: 'orange',
        yAxis: 0,
        data: tat.values,
      },
      {
        name: 'Sum of Response(%)',
        color: 'grey',
        yAxis: 0,
        data: perRegion.values,
      }
    ]
    }
    this.quotePendancyChart = new Chart(options);
    this.quoteData = data.data;
  }
  dropdownclose(value) {
    if(this.dropDownSelect) {
      this.fetchCat2list();
      this.dropDownSelect = false;
    }
  }
}


