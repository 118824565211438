import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { CsService } from 'src/app/services/cs.service';

import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-cs-strategy',
  templateUrl: './cs-strategy.component.html',
  styleUrls: ['./cs-strategy.component.css']
})
export class CsStrategyComponent implements OnInit {

  reportName='';
  responsedata = {};

  table1_data=[];
  table1_headers=[];

  table2_data=[];
  table2_headers=[];

  table3_data=[];
  table3_headers=[];

  table4_data=[];
  table4_headers=[];

  table5_data=[];
  table5_headers=[];

  table6_data=[];
  table6_headers=[];

  table7_data=[];
  table7_headers=[];

  table8_data=[];
  table8_headers=[];
  userId_val='';

  constructor(private navbarTitle: NavbarComponent,private csService: CsService,private userId: AppService) {
   
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }
  getResponseData() {
    this.csService.getCSdata(environment.baseURL_FREQ+"online_pendency",{'username':this.userId_val}).subscribe(res => {
    this.responsedata = res;

    if(res['status']=='SUCCESS'){
      let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
      this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
        console.log(reportdata);
      })
    }


    this.table1_data = this.responsedata['data']['Summary']['rows'];
    this.table1_headers = this.responsedata['data']['Summary']['headers'];

    this.table2_data = this.responsedata['data']['Regional_Shipped']['rows'];
    this.table2_headers = this.responsedata['data']['Regional_Shipped']['headers'];


    this.table3_data = this.responsedata['data']['Order_Type']['rows'];
    this.table3_headers = this.responsedata['data']['Order_Type']['headers'];

    this.table4_data = this.responsedata['data']['Total']['rows'];
    this.table4_headers = this.responsedata['data']['Total']['headers'];


    this.table5_data = this.responsedata['data']['Prepaid']['rows'];
    this.table5_headers = this.responsedata['data']['Prepaid']['headers'];


    this.table6_data = this.responsedata['data']['GST']['rows'];
    this.table6_headers = this.responsedata['data']['GST']['headers'];

    this.table7_data = this.responsedata['data']['Category']['rows'];
    this.table7_headers = this.responsedata['data']['Category']['headers'];

  

  })
  }
  ngOnInit() {
this.getResponseData();
  }

}
