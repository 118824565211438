import { Router } from '@angular/router';
import { Component, OnInit, SimpleChanges,ChangeDetectorRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from '../../services/app.service';
import * as Moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { extendMoment } from 'moment-range';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import * as Highcharts from 'highcharts';
import { CommonService } from '../../shared/common.service';
import { MgmtScoreCardService } from 'src/app/services/mgmt-score-cardservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelfServeService } from 'src/app/services/self-serve.service';
//import { MgmtComponent } from 'src/app/mgmt-score-card/mgmt/mgmt.component';
import { NavbarComponent } from '../navbar/navbar.component';

import { MgmtService } from '../../services/mgmt.service';
import { TeamWiseSalesService } from 'src/app/services/team-wise-sales.service';
import * as moment from 'moment';
import { PodTrackerService } from 'src/app/services/pod-tracker.service';
import { HttpService } from 'src/app/http.service';
import { runInThisContext } from 'vm';
import { AdvancePOSupplyService } from 'src/app/services/advance-posupply.service';
import { SMEMgmtScoreCardService } from 'src/app/services/sme-mgmt-score-cardservice.service';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
@Component({
  selector: 'app-kam-category',
  templateUrl: './kam-category.component.html',
  styleUrls: ['./kam-category.component.css']
})
export class KAMCategoryComponent implements OnInit {

  userId_val: any;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  reportlabel = '';
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  viewDetailsTable: boolean;

  DateCalender: any;
  req_start_date: string;
  req_end_date: string;
  mngmt_data: any;
  requestBody = {};
  teamfinalpostObj: {};
  tableCondition: boolean;
  visualTable: boolean;
  TeamRegionsListObj: any = {};
  regions: any = [];
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  teams: any[];
  selectedL3: any[];
  customers: any[];
  selectedL4: any[];
  plants: any[];
  RkeyValue: any;
  filterData: any = {};

  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  categoriesdropdownSettings = {};

  Categories: any;
  category: any;
  selectedCategories: any[];
  exceldata: any;
  catL1list: any;
  selectedL1=[];
  openFilters: boolean;
  constructor(private commonService: CommonService,
    private navbarTitle: NavbarComponent,
    private userId: AppService,
    private service: SelfServeService,
    public itESservice: ItemTrackingESService,
    private httpService: HttpService, private smemgmtScoreCardservice: SMEMgmtScoreCardService,
    private advancePOsupply: AdvancePOSupplyService) {


    this.userId_val = this.userId.userDetails.email;
    this.reportlabel = this.navbarTitle.reportlabel;
  }
  ngOnInit() {
    this.multiSelectDropdown();
    const url = environment.baseURL_FREQ + 'um_access_mapping';
    //  this.itESservice.sendPostRequest(url, { username: this.userId_val }).subscribe(filtersRes => {

    //         this.regions = Object.keys(filtersRes["data"]);

    //  })
    //     this.getSelectedFilters(this.selectedRegions);
    this.fetchCat1list()
    
 
    // this.userId.getData(environment.baseURL_FREQ+'getCategories').subscribe((data) => {
    //     debugger
    //     this.Categories = data['result']
    //     console.log(this.Categories)

    // })



  }
  generatePayload() {
    const payload = {
      "username": this.userId_val,
      "Categories_L1": this.selectedL1,
      "grouping_col": 'Category_1'
    }
    console.log(payload)

    return payload;
  }
  fetchCat1list() {
    const url = 'https://analytics-web.moglix.com/freq_report/getCategories';
    this.userId.getData(environment.baseURL_FREQ + 'getCategories').subscribe((filtersRes) => {
      this.catL1list = filtersRes['result'];
      this.selectedL1.push(this.catL1list[0])
    this.selectedL1.push(this.catL1list[1])
    this.generatePayload();
    this.generateReports();
    });

  

  }
  ngOnChanges(changes: SimpleChanges) {
    this.fetchCat1list()
}
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  generateReports() {

    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};

    this.tableCondition = false;
    this.visualTable = false;
    const payload = this.generatePayload();
    const url = environment.baseURL_FREQ+'kam_category_level';
    //const url = 'http://10.8.1.69:5026/freq_report/kam_category_level'
    //this.advancePOsupply.saveInfo(url, payload).subscribe(resp => {
    this.advancePOsupply.saveInfo(url, payload).subscribe(res => {


      if (res['data']['result'] != undefined && res['status'] == 'SUCCESS') {
        let reportRequest = { 'username': this.userId_val, 'report_name': this.reportlabel };
        this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          console.log(reportdata);
        })
      }
      this.mngmt_data = this.smemgmtScoreCardservice.post(res['data']);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
          this.openFilters = false;
        }
      }
    });
  }





  refresh() {

    this.dateRange = null;

  }

}
