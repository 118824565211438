import {Component, Input, OnInit} from '@angular/core';
import {PinCodes} from '../../helpers/pinCodes';

import * as Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
HC_map(Highcharts);
require('../../indiamap')(Highcharts);




@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent implements OnInit {

  @Input('mapData') mapData: any = {};

  title = 'app';
  updateFromInput = false;
  Highcharts = Highcharts;
  chartConstructor = 'mapChart';
  chartCallback;
  public deliveryList: any = [];
  public chartOptions: any = {}


  constructor() { }

  ngOnInit() {
    this.getMapData();
  }

  getMapData() {
    this.deliveryList = [];
    let pushObj: any = {};
    for (const i in this.mapData.PincodeSales) {
      if (PinCodes.pins[i]) {
        pushObj = PinCodes.pins[i] || {};
        pushObj.invoice = this.mapData.PincodeSales[i];
       this.deliveryList.push(pushObj);
      }
    }
    this.initialiseMapOptions();
  }

  initialiseMapOptions() {

   this.chartOptions = {
      chart: {
        map: 'countries/in/custom/in-all-disputed'
      },

      title: {
        text: ''
      },


      mapNavigation: {
        enabled: true
      },

      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.name}</b> <br> Invoice:  <b>{point.invoice} Lakhs (INR)</b>'
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: [{

        name: 'Basemap',
        borderColor: '#FFFFFF',
        nullColor: '#EDEDED',
        showInLegend: false,
      }, {
        name: 'Separators',
        type: 'mapline',
        // nullColor: '#707070',
        showInLegend: false,
        enableMouseTracking: true,

      }, {
        // Specify points using lat/lon
        type: 'mappoint',
        name: '',
        color: '#EA5E7E',
        data: this.deliveryList,
        dataLabels: {
          enabled: false
        }
      }]
    };

  }
}
