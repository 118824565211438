import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
// import { AuthenticationResult } from '@azure/msal-browser';
// import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

import {
  GoogleLoginProvider
} from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { AppService } from '../../services/app.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  setsessionURL=environment.baseURL_FREQ;
  private user: SocialUser;
  selectedTeamsList: any[];
  constructor(private _msalService: MsalService,
    private appService: AppService,
    private commonService:CommonService) {
  }

  ngOnInit() {
    localStorage.clear();
    if(this.appService.userDetails['email']){
      this.appService.getdefaultRegionsList();
    }
  }

 

  microsoftSignIn(){
    this._msalService.loginPopup().subscribe((userData: AuthenticationResult) => {
        if(userData['accessToken']) {
          userData['authToken'] = userData['accessToken'];
          userData['email'] = userData.idTokenClaims['preferred_username'];
          this.socialSignIn(userData);
        }
      });
  }
  
    socialSignIn(userData) {
          if (userData != null) {
            this.user = userData;
            const url = environment.analyticsapi_baseURL_highcharts + UrlConfig.loginUrl;
            this.appService.login(url, this.user).subscribe((res: any) => { 
                 this.appService.getdefaultRegionsList();
            });
            this.appService.getSessionData(this.setsessionURL+'set_session_data',this.user.email).subscribe(res=>{
              console.log(res['data']);
              this.appService.setMenuList(res['data']);
              
            })
          }
    }
}
