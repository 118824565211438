import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RfqService {

  constructor(private http: HttpClient) { }
  
  getFiltersItemTrackingES(userId_val) {
    return this.http.post(environment.baseURL_FREQ+"broad_mapping",{"username":userId_val});
  }

  postCall(url, payload) {
    return this.http.post(url, payload);
  }

  getCall(url) {
    return this.http.get(url);
  }

}
