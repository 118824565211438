  
    import { elementStyleProp } from '@angular/core/src/render3';
    import { FormGroup, FormControl, Validators } from '@angular/forms';
    import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
    
    import * as Moment from 'moment';
    import { extendMoment } from 'moment-range';
    //import { formatDate } from 'ngx-bootstrap/chronos/format';
    import { formatDate } from '@angular/common';
    import { AppService } from 'src/app/services/app.service';
    import { SelfServeService } from 'src/app/services/self-serve.service';
    import { CommonService } from 'src/app/shared/common.service';
  import { OrderTrackingService } from 'src/app/fur/order-tracking.service';
    
    const moment = extendMoment(Moment);
    const dateFormat = 'y-MM-dd';
    const dateLocale = 'en-IN';
    
  
    @Component({
      selector: 'app-open-supplier-po',
      templateUrl: './open-supplier-po.component.html',
      styleUrls: ['./open-supplier-po.component.css']
    })
    export class OpenSupplierPOComponent implements OnInit {
          //used variables
      finalDataObject = { table: {}, excel: [] };
      finalDataObject2 = { table: {}, excel: [] };
      methodValue:any;
      selectedlevel1_val: number;
      urlpathlevel2: any;
      level2: {};
      dtOptionsenable: boolean;
      dtOptionsenable2: boolean;
      dtOptions: DataTables.Settings = {};
      dtOptions2: DataTables.Settings = {};
      urlpath: string;
      public selectedRowValue: string;
      public selectedColumnValue: string;
      userId_val:any;
      responseListExcel=[];
      responseListExcel2=[];
      @Input('orderTrackingWise') orderTracking = 0;
      private fromDate = new Date();
      private toDate = new Date();
      criteriaForm = new FormGroup({
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl('', Validators.required)
      })
    
      constructor(private commonService:CommonService,
        private orderTrackingservice: OrderTrackingService,private userId: AppService,private service:SelfServeService ) { 
        this.userId_val =  this.userId.userDetails.email;
      }
    
      ngOnInit() {
        this.initialDate();
        this.orderTracking = 0;
        this.supplySales(this.orderTracking);
       }
      ngOnChanges(changes: SimpleChanges) {
        this.initialDate();
        this.supplySales(this.orderTracking);
      }
      supplySales(paramsupply: any) {
        let requestObj = this.criteriaForm.value;
        requestObj['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
        requestObj['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
        requestObj['username'] = this.userId_val;
        
        switch (paramsupply) {
          case 0: {
            this.urlpath = 'track_supp_po_items_lvl1';
            this.selectedlevel1_val = 0;
            this.methodValue = "post";
            break;
          }
          case 1: {
            this.urlpath = 'track_client_invoice_lvl1';
            this.selectedlevel1_val = 2;
            this.methodValue = "get";
            break;
          }
    
          case 2: {
            this.urlpath = 'track_client_po_item_aging_lvl1';
            this.selectedlevel1_val = 3;
            this.methodValue = "get";
            break;
          }
          
        }
        this.displayTable(this.urlpath, requestObj);
      }
    
      setRowColumnvalues = (cell:number,r: string, c: string) => {
        let requestObjlevel2 = this.criteriaForm.value;
    
        switch (this.selectedlevel1_val) {
    
    
          case 0: {
            this.urlpathlevel2 = 'track_supp_po_items_lvl2';
            requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
            requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
            requestObjlevel2['status'] = [c];
            requestObjlevel2['warehouse'] = [r];
            requestObjlevel2['username'] = this.userId_val;
            this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
            break;
          }
          case 1: {
            this.urlpathlevel2 = 'track_client_po_items_lvl2';
            requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
            requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
            requestObjlevel2['status'] = [c];
            requestObjlevel2['region'] = [r];
            requestObjlevel2['username'] = this.userId_val;
            this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
            break;
          }
          case 2: {
            this.urlpathlevel2 = 'track_client_invoice_lvl2';
            requestObjlevel2['start_date'] = formatDate(this.start_date.value, dateFormat, dateLocale);
            requestObjlevel2['end_date'] = formatDate(this.end_date.value, dateFormat, dateLocale);
            requestObjlevel2['days'] = [c];
            requestObjlevel2['region'] = [r];
            requestObjlevel2['username'] = this.userId_val;
            this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
            break;
          }
          case 3: {
            this.urlpathlevel2 = 'track_client_po_item_aging_lvl2';
            requestObjlevel2['days'] = [c];
            requestObjlevel2['region'] = [r];
            requestObjlevel2['username'] = this.userId_val;
            this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
            break;
          }
        }
     
    
      }
      testClick(event) {
        var self = this;
        $('#example tbody').on('click', 'td', function () {
          let table = $('#example').DataTable();
          let idx = table.cell(this).index().column;
          let rowIdx = table.cell(this).index().row;
          let title = table.column(idx).header();
          this.selectedRowValue = table.cell(rowIdx, 0).data();
          this.selectedColumnValue = $(title).html();
          let cell = table.cell( this );
    
          self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue);
          // else{
          //   alert("No Details exist for this !!")
          // }
        });
      }
    
      displayTable(urlpath, requestObj) {
    
        this.dtOptionsenable = false;
        this.dtOptionsenable2 = false;
        
        if (this.methodValue == "post") {
      
          this.orderTrackingservice.saveInfo(urlpath, requestObj).subscribe(
            (response) => {
              this.finalDataObject = this.orderTrackingservice.post(response);
              this.dtOptions = this.finalDataObject.table;
              
              this.dtOptionsenable = true;
              this.dtOptionsenable2 = false;
    
            }
          )
        }
        if (this.methodValue == "get") {
    
          this.orderTrackingservice.saveInfo(urlpath,requestObj).subscribe(
            (response) => {
              this.finalDataObject = this.orderTrackingservice.post(response);
              this.dtOptions = this.finalDataObject.table;
              console.log("tabledata", this.finalDataObject)
              this.dtOptionsenable = true;
              this.dtOptionsenable2 = false;
              console.log(this.dtOptions.columns);
            }
          )
        }
     
    
        
        
      }
    
      displayTable2(cell,urlpath, requestObj) {
    
        if(cell!=0){
          this.orderTrackingservice.saveInfo(urlpath, requestObj).subscribe(
            (response) => {
              if(response['message'] != "No Data")
              {
                this.dtOptionsenable = false;
              this.finalDataObject2 = this.orderTrackingservice.post(response);
              this.dtOptions2 = this.finalDataObject2.table;
              this.responseListExcel2 = this.finalDataObject2.excel;
              this.dtOptionsenable2 = true;
              }
              // else{
              //   alert("No Details exist for this !!")
              // }
            }
          )
        }
        else{
          this.commonService.showToast("No Details exist for this !!",2);
        }
    
      }
    
      initialDate() {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.fromDate.setDate(this.fromDate.getDate() - 8);
        this.toDate.setDate(this.toDate.getDate() - 1);
        this.start_date.setValue(this.fromDate);
        this.end_date.setValue(this.toDate);
      }
      backButton() {
        this.dtOptionsenable = !this.dtOptionsenable;
        this.dtOptionsenable2 = !this.dtOptionsenable2;
      }
      //getters
      get start_date() { return this.criteriaForm.get('start_date') };
      get end_date() { return this.criteriaForm.get('end_date') };
    
      exportAsXLSX(): void {
        this.service.exportAsExcelFile(this.responseListExcel2, 'ClientPO');
      }
    }