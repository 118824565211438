
import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import { BuUploadService } from 'src/app/services/bu-upload.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/shared/common.service';
type AOA = any[][];
@Component({
  selector: 'app-bu-data-input',
  templateUrl: './bu-data-input.component.html',
  styleUrls: ['./bu-data-input.component.css']
})

export class BuDataInputComponent implements OnInit {
  message = '';
  previewDataListSrcArr = [];
  dateStatus: boolean;
  headers: string[] = ["Row_id", "created_for", "Year Month", "Region", "Actual/Estimated", "Fixed Fleet Cost", "Adhoc Fleet Cost", "Regional LSP Cost", "National LSP Cost", "Happay Cost", "Logistics Cost", "Invoice-Fleet", "Invoice-Regional LSP", "Invoice-National LSP", "Invoice-SCM", "PPE", "Non PPE", "Inventory Fresh", "Inventory Return", "Inventory JIT>7 days", "Inventory>30 Days", "Inventory Rest", "Inventory at Warehouse", "Overall>30 days", "JIT>3 Days", "VMI>15 Days", "AR", "AP", "OD>30 Days", "Payroll", "Infra", "Runner Cost", "Below Managers", "Mgr & above", "TOD Provisioning 0.6 per", "TOD", "Marketing Cost", "Exports", "Transaction per from Global MSN++", "TP reduction per on Global MSN", "New Supplier Development (Effort)", "Suppliers on 60/45 days credit (By Value)", "Supplier 1st Pick-up Date Compliance per", "Supplier not accepting return Failure within 1-week (By Value)", "Inventory Ageing By Value>30D", "Ebay Cost", "Monet Cost"]
  isValidHeader: boolean;
  arrayBuffer: any;
  filename: string = null;
  dtOptions: DataTables.Settings = {};
  userId_val: any;
  visualTable: boolean;
  uploadeddFileData = [];
  previewDataList = [];
  finalDataObjectpickup = { table: {}, excel: [] };
  isValidUsers: boolean;
  isUploadProcess: boolean;
  
  constructor(private spinner: NgxSpinnerService, 
    private appService: AppService, private userId: AppService,
     private buUploadService: BuUploadService, private commonService: CommonService) {
    this.userId_val = this.userId.userDetails.email;
  }

  ngOnInit() {
  }

  onFileChange(ev: any) {

    this.spinner.show();
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.filename = ev.target.files[0];
    if (file != null) {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for (let i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet: XLSX.WorkSheet = workbook.Sheets[first_sheet_name];
        if (worksheet["!ref"] == 'A1:K1') {
          alert('Enter the data')
        }
        else {
          console.log(worksheet);
          this.validateUploadedExcel(worksheet, ev);
        }
      }
    }
  }
  validateUploadedExcel(worksheet, ev) {
    this.previewDataListSrcArr = XLSX.utils.sheet_to_json(worksheet, { defval: '', raw: false });
    this.previewDataList = XLSX.utils.sheet_to_json(worksheet, { defval: 0, raw: false });

    console.log(this.previewDataList);

    let srcArr: string[] = Object.keys(this.previewDataListSrcArr[0]);
    console.log(srcArr);

    this.isValidHeader = this.validateHeaders(srcArr);
    if (this.isValidHeader) {
      this.formatUsersData(ev);
      if (this.isValidUsers) {
        this.isUploadProcess = true;

        this.previewData(this.previewDataList);

        this.spinner.hide();
      }
      else {


        this.deleteFile("#fileInput");
        this.spinner.hide();

      }
    }
    else {
      this.spinner.hide();

    }
  }

  setError(message) {
    this.isValidUsers = false;
    this.message = message;
  }
  formatUsersData(ev) {
    this.isValidUsers = true;
    for (let headIndex = 0; headIndex < this.headers.length; headIndex++) {
      for (let userIndex = 0; userIndex < this.previewDataListSrcArr.length; userIndex++) {
        let value: number | string | any = this.previewDataListSrcArr[userIndex][this.headers[headIndex]];
        let row = userIndex + 2;
        if (headIndex == 0 || headIndex == 1 || headIndex == 2 || headIndex == 3) {
          if (value == null) {
            alert('Enter Value of row' + row + this.headers[headIndex] + " should not be empty")
          }
          if (value.includes("/")) {
            this.commonService.showToast('Date format should be YYYY-MM-DD', 3);
            this.isValidUsers = false;
            this.spinner.hide();

            this.deleteFile("#fileInput");
            break;
          }


        }
        else {

        }


      }

    }
    return this.isValidUsers;
  }

  dateValidations(value) {
    if (value.indexOf('/') > -1) {
      this.dateStatus = true;
      let res = value.split("/");
      let day = res[0];
      let month = res[1];
      let year = res[2];
      month = Number(month);
      day = Number(day);
      year = Number(year);
      if (month < 1 || month > 12) { // check month range
        this.dateStatus = false;
      }
      if (day < 1 || day > 31) {
        this.dateStatus = false;
      }
      if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
        this.dateStatus = false;
      }
      if (month == 2) { // check for february 29th
        let isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
        if (day > 29 || (day === 29 && !isleap)) {
          this.dateStatus = false;
        }
      }
    }
    else {
      this.dateStatus = false;
    }
    return this.dateStatus;
  }
  validateHeaders(srcArr: string[]): boolean {
    console.log(srcArr, this.headers);
    this.isValidHeader = true;

    if (!(srcArr.length === this.headers.length)) {
      //this.setError('Number of column not matching');
      alert('Number of column not matching');
      this.isValidHeader = false;
    } else {
      for (let i = 0; i < srcArr.length; i++) {
        if (!(srcArr[i] === this.headers[i])) {
          //this.setError('Invalid header name: ' + srcArr[i]);
          alert('Invalid header name: ' + srcArr[i]);
          this.isValidHeader = false;

          this.spinner.hide();
          this.deleteFile("#fileInput");
          break;
        }
      }
    }
    return this.isValidHeader;
  }
  previewData(data) {
    this.visualTable = false;
    this.dtOptions = {};
    this.finalDataObjectpickup = this.buUploadService.post(data);
    this.dtOptions = this.finalDataObjectpickup.table;
    this.visualTable = true;
  }
  uploadData() {
    if (this.visualTable) {

      let requestBody = { "email": this.userId_val, data: this.previewDataList };
      this.appService.getReport(environment.baseURL_FREQ+UrlConfig.buUpload, requestBody).subscribe(res => {
        if (res['status_code'] == "201") {
          console.log(res);

          this.commonService.showToast("Successfully Data Uploaded", 1);
        }
        else {
          this.commonService.showToast(res['message'], 3);
        }

      })

    }
  }
  deleteFile(element) {
    this.previewDataList = [];
    this.previewDataListSrcArr = [];
    this.visualTable = false;
    element.value = "";
  }
}