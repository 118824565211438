import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from 'src/app/services/app.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {
  barChart: any;
  cogsBarChart: any;
  otifChart: any;
  revenueBarChart: any;
  rateContractChart: any;
  gmChart: Chart;
  overAllSummaryData: any;
  revenueData: any;
  cogsData: any;
  otifData: any = {
    "OTIF": 0,
    "Without OTIF": 0
  };
  rateContractData: any;
  financialData: any;
  gmData: any;
  customerVisit: any;
  customerVisitData: any;
  reportType = ["Overall Summary", "Revenue", "COGS >15 days credit", "OTIF Delivery Report", "Rate Contract %", "Financial", "GM %", "Customer Visit"];
  userName: any;
  selectedPalnts: any = [];
  selectedTeams = [];
  dateRange = [new Date(), new Date()]
  startDate: any;
  endDate: any;
  selectedCountry: any;
  searchBy = 'plants';
  reportName: any;
  isAllPlants = true;
  isAllTeams = false;
  constructor(private dashBoardService: DashboardService, private appService: AppService, private navbarTitle: NavbarComponent) { }

  ngOnInit() {
    this.userName = this.appService.getUserDetails(['userName']);
    this.reportName = this.navbarTitle.reportlabel;
    const email = this.appService.userDetails.email;
    let reportRequest = {'username':email,'report_name':this.reportName};
    this.appService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
      console.log(reportdata);
    })
  }

  getAllReportsData() {
    const reports = [
      // {
      //   reportType: 'Rate Contract %',
      //   dataType: 'rateContactData'
      // }, 
      {
        reportType: 'Overall Summary',
        dataType: 'overAllSummaryData'
      },
      {
        reportType: 'Revenue',
        dataType: 'revenueData'
      },
      {
        reportType: 'COGS >15 days credit',
        dataType: 'cogsData'
      },
      {
        reportType: 'OTIF Delivery Report',
        dataType: 'otifData'
      },
      {
        reportType: 'Financial',
        dataType: 'financialData'
      }, {
        reportType: 'GM %',
        dataType: 'gmData'
      }, {
        reportType: 'Customer Visit',
        dataType: 'customerVisitData'
      }
    ];
    reports.forEach((each) => {
      this.getData('Monthly', each['reportType'], 'Display', each['dataType']);
    })
    setTimeout(() => {
      this.getData('Monthly', 'Rate Contract %', 'Display', 'rateContactData');
    }, 500)
  }

  changeData(event, reportType, dataVariable) {
    this.getData(event.target.value, reportType, 'Display', dataVariable);
  }

  filterChange(data) {
    if (data['plants']) {
      this.selectedPalnts = data['plants'];
      if(data['isAllPlants'] || data['isAllPlants'] == false) {
        this.isAllPlants = data['isAllPlants']
      }
    }
    if (data['teams']) {
      this.selectedTeams = data['teams'];
      if(data['isAllTeams'] || data['isAllTeams'] == false) {
        this.isAllTeams = data['isAllTeams']
      }
    }
    if (data['filter'] == 'date') {
      this.startDate = data['start_date'];
      this.endDate = data['end_date'];
    }
    if (data['country']) {
      this.selectedCountry = data['country'];
    }
    if (data['searchBy']) {
      this.searchBy = data['searchBy']
    }
    this.getAllReportsData();
  }

  generatePayload(view, reportType, apiType) {
    if (this.searchBy == 'plants') {
      this.selectedTeams = [];
      this.isAllTeams = false;
    } else if (this.searchBy == 'teams') {
      this.selectedPalnts = [];
      this.isAllPlants = false;
    }
    const payload = {
      "Country": this.selectedCountry,
      "Plant_ids": this.selectedPalnts,
      "Team_ids": this.selectedTeams,
      "From_date": this.startDate,
      "All_plants": this.isAllPlants,
      "All_teams": this.isAllTeams,
      "To_date": this.endDate,
      "View": view,
      "Report_type": reportType,
      "API_type": apiType
    }
    return payload;
  }

  getData(view, reportType, apiType, data) {
    if (this.selectedPalnts.length == 0 && this.searchBy == 'plants') {
      return;
    }
    const payload = this.generatePayload(view, reportType, apiType);
    this.dashBoardService.getReportData(payload).subscribe((resp: any) => {
      this[data] = resp['Data'];
      this.handleResponse(reportType, resp['Data'])
    })
  }

  handleResponse(reportType, data?) {
    switch (reportType) {
      case 'Revenue':
        this.generateBarChart('revenue');
        break;
      case 'COGS >15 days credit':
        this.generateBarChart('cogs');
        break;
      case 'OTIF Delivery Report':
        this.generateDonutChart();
        break;
      case 'GM %':
        this.generateRateContractChart('gm');
        break;
      case 'Customer Visit':
        this.generateRevenueBarChart();
        break;
      case 'Rate Contract %':
        this.generateRateContractChartforContract(data);
        break;
    }
  }

  generateValuesForGraph(data) {
    const categories = []
    const values = [];
    if (data && data.length) {
      data.forEach(element => {
        categories.push(element['X']);
        values.push(element['Y']);
      });
    }
    return {
      'categories': categories,
      'values': values
    }
  }

  generateBarChart(type) {
    if (type == 'revenue') {
      var contractData: any = {};
      var revenueData = this.revenueData;
      contractData = this.generateValuesForGraph(this.revenueData);
    } else if (type == 'cogs') {
      var graphData: any = {};
      var cogsData = this.cogsData;
      graphData = this.generateValuesForGraph(this.cogsData);
    }
    // data = [{"Week":"20-09-2021 to 22-09-2021","X":"W1","Y":21},{"Week":"13-09-2021 to 19-09-2021","X":"W2","Y":18}]
    const options: any = {
      chart: {
        type: 'column',
        height: 160
      },
      colors: ['#4889F3', '#47D7AC', '#77CB35'],
      title: {
        text: '',
      },
      xAxis: {
        crosshair: true,
        height: 100
      },
      yAxis: {
        min: 0,
        height: 100,
        title: {
          text: ''
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} Cr</b></td></tr>',
        footerFormat: '</table>',
        formatter: function () {
          let tooltipMarkup;
          if (type == 'revenue') {
            const headerObj = revenueData.filter(each => each['X'] == this.points[0].x)
            var header = headerObj[0].Month ? headerObj[0].Month : headerObj[0].Week;
            tooltipMarkup = `<span style="font-size:10px"><b>${header}<b/></span><br/><b>Revenue: ${this.points[0].y} Cr`;
          } else if (type == 'cogs') {
            const headerObj = cogsData.filter(each => each['X'] == this.points[0].x)
            var header = headerObj[0].Month ? headerObj[0].Month : headerObj[0].Week;
            tooltipMarkup = `<span style="font-size:10px"><b>${header}<b/></span><br/><b>COGS Credit: ${this.points[0].y} Cr`;
          }
          return tooltipMarkup;
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: -0.1,
          borderWidth: 0,
          colorByPoint: true
        }
      },
      series: [{
        showInLegend: false,
        name: 'Revenue',
        color: '#77CB35'
      }]
    }
    if (type == 'revenue') {
      options.xAxis['categories'] = contractData.categories;
      options.series[0]['data'] = contractData.values;
      this.barChart = new Chart(options)
    } else if (type == 'cogs') {
      options.xAxis['categories'] = graphData.categories;
      options.series[0]['data'] = graphData.values;
      this.cogsBarChart = new Chart(options)
    }
  }

  generateRevenueBarChart() {
    const graphData = this.generateValuesForGraph(this.customerVisitData);
    const data = this.customerVisitData;
    const options: any = {
      chart: {
        type: 'column',
        height: 160
      },
      colors: ['#F873BB'],
      title: {
        text: '',
      },
      xAxis: {
        categories: graphData.categories,
        crosshair: true,
        height: 100
      },
      yAxis: {
        min: 0,
        height: 100,
        title: {
          text: ''
        }
      },
      tooltip: {
        formatter: function () {
          let tooltipMarkup;
          const headerObj = data.filter(each => each['X'] == this.points[0].x)
          var header = headerObj[0].Month ? headerObj[0].Month : headerObj[0].Week;
          tooltipMarkup = `<span style="font-size:10px"><b>${header}<b/></span><br/><b>Customer Visits: ${this.points[0].y} `;
          return tooltipMarkup;
        },
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: -0.1,
          borderWidth: 0,
          colorByPoint: true
        }
      },
      series: [{
        showInLegend: false,
        name: 'Customer Visits',
        data: graphData.values,
        color: '#F873BB'
      }]
    }
    this.revenueBarChart = new Chart(options)
  }

  generateDonutChart() {
    const options: any = {
      chart: {
        plotBorderWidth: null,
        plotShadow: false,
        height: 160
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      legends: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 100,
        y: 20
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '120%',
          innerSize: '20%',
          slicedOffset: 4
        }
      },
      series: [{
        type: 'pie',
        name: '',
        data: [
          {
            name: this.otifData['OTIF'] && this.otifData['OTIF'].toString().concat('%'),
            y: this.otifData['OTIF'],
            color: '#90EE7D',
            showInLegend: true
          },
          {
            name: this.otifData['Without OTIF'] && this.otifData['Without OTIF'].toString().concat('%'),
            y: this.otifData['Without OTIF'],
            sliced: true,
            selected: true,
            color: '#8085E9',
            showInLegend: true
          },
        ]
      }]
    }
    this.otifChart = new Chart(options)
  }

  generateRateContractChartforContract(value) {
    const graphData = this.generateValuesForGraph(value);
    const data = value;
    const options: any = {
      chart: {
        type: 'area',
        height: 140
      },
      title: {
        text: ''
      },
      subtitle: {
        style: {
          position: 'absolute',
          right: '0px',
          bottom: '10px'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: -150,
        y: 100,
        floating: true,
        borderWidth: 1,

        backgroundColor:
          '#FFFFFF'
      },
      xAxis: {
        categories: graphData.categories
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          let tooltipMarkup;
          const headerObj = data.filter(each => each['X'] == this.points[0].x)
          var header = headerObj[0].Month ? headerObj[0].Month : headerObj[0].Week;
          tooltipMarkup = `<span style="font-size:10px"><b>${header}<b/></span><br/><b>Rate Contract: ${this.points[0].y} %`;
          return tooltipMarkup;
        },
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5
        },
        line: {
          marker: undefined
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Rate Contract',
          data: graphData.values,
          color: '#2B61F4',
          marker: {
            enabled: false
          },
        }
      ]
    };
    this.rateContractChart = new Chart(options);
  }

  generateRateContractChart(type) {
    let graphData: any = {};
    graphData = this.generateValuesForGraph(this.gmData)
    var data = this.gmData;
    const options: any = {
      chart: {
        type: 'area',
        height: 140
      },
      title: {
        text: ''
      },
      subtitle: {
        style: {
          position: 'absolute',
          right: '0px',
          bottom: '10px'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: -150,
        y: 100,
        floating: true,
        borderWidth: 1,

        backgroundColor:
          '#FFFFFF'
      },
      xAxis: {
        categories: graphData.categories
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          let tooltipMarkup;
          const headerObj = data.filter(each => each['X'] == this.points[0].x)
          var header = headerObj[0].Month ? headerObj[0].Month : headerObj[0].Week;
          tooltipMarkup = `<span style="font-size:10px"><b>${header}<b/></span><br/><b>GM: ${this.points[0].y} %`;
          return tooltipMarkup;
        }
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5
        },
        line: {
          marker: undefined
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Rate Contract',
          data: graphData.values,
          color: '#2B61F4',
          marker: {
            enabled: false
          },
        }
      ]
    };
    this.gmChart = new Chart(options)
  }

}
