import {Component, OnInit, Input} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as Moment from 'moment';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);

@Component({
  selector: 'app-ng-sparkline',
  templateUrl: './ng-sparkline.component.html',
  styleUrls: ['./ng-sparkline.component.scss']
})
export class NgSparklineComponent implements OnInit {

  @Input('sparkLineData') sparkLineData: any = {};

  public sparkLineGraph: any = [];
  private monthsList: any = [];

  constructor() {
  }

  ngOnInit() {
    this.updateSparkChart();
  }

  updateSparkChart() {
    const reports: any = [];
    this.sparkLineGraph = [];
    this.sparkLineData.xAxis.forEach(month => {
      this.monthsList.push(moment(month.slice(5), 'MM').format('MMM'));
    });
    for (const dat of this.sparkLineData.yAxis1) {
      let suffix = 'Lakhs (INR)';
      if(dat.teamName == 'Team UAE') {
        suffix = 'AED (UAE)';
      }
      const sparkOptions: any = {
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [4, 0, 4, 0],
          width: 120,
          height: 40,
          style: {
            overflow: 'visible'
          },
          skipClone: true
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.monthsList,
          lineColor: '#fff',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: []
        },
        legend: {
          enabled: false
        },
        tooltip: {
          hideDelay: 0,
          outside: true,
          shared: true,
          headerFormat: '<b>{point.x}</b><br>',
          pointFormat: '<b>{point.y} '+ suffix +' </b>'
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          }
        },
        series: [{
          data: dat.invoiceGraph,
          lineWidth: 2,
          lineColor: '#6343D2',
          marker: {
            lineWidth: 2,
            lineColor: '#9161FA',
            fillColor: '#9161FA'
          },
          fillColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, '#C8B4F0'],
              [1, '#F1ECFB']
            ]
          },
        }]
      };
      const sparkOptions1: any = {
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [4, 0, 4, 0],
          width: 120,
          height: 40,
          style: {
            overflow: 'visible'
          },
          skipClone: true
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.monthsList,
          lineColor: '#fff',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: []
        },
        legend: {
          enabled: false
        },
        tooltip: {
          hideDelay: 0,
          outside: true,
          shared: true,
          headerFormat: '<b>{point.x}</b><br>',
          pointFormat: '<b>{point.y}</b>'
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          }
        },
        series: [{
          data: dat.plantGraph,
          lineWidth: 2,
          lineColor: '#6343D2',
          marker: {
            lineWidth: 2,
            lineColor: '#9161FA',
            fillColor: '#9161FA'
          },
          fillColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, '#C8B4F0'],
              [1, '#F1ECFB']
            ]
          },
        }]
      };
      const sparkOptions2: any = {
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [4, 0, 4, 0],
          width: 120,
          height: 40,
          style: {
            overflow: 'visible'
          },
          skipClone: true
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.monthsList,
          lineColor: '#fff',
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: []
        },
        legend: {
          enabled: false
        },
        tooltip: {
          hideDelay: 0,
          outside: true,
          shared: true,
          headerFormat: '<b>{point.x}</b><br>',
          pointFormat: '<b>{point.y} (%)</b>'
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          }
        },
        series: [{
          data: dat.gmPercentageGraph,
          lineWidth: 2,
          lineColor: '#6343D2',
          marker: {
            lineWidth: 2,
            lineColor: '#9161FA',
            fillColor: '#9161FA'
          },
          fillColor: {
            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
            stops: [
              [0, '#C8B4F0'],
              [1, '#F1ECFB']
            ]
          },
        }]
      };
      this.sparkLineGraph.push({
        invoiceGraph: new Chart(sparkOptions),
        plantGraph: new Chart(sparkOptions1),
        gmPercentageGraph: new Chart(sparkOptions2),
        teamName: dat.teamName,
        invoiceCount: dat.invoiceCount,
        gmValue: dat.gmValue,
        plantsCount: dat.plantsCount
      });
    }
  }
}
