import { elementStyleProp } from '@angular/core/src/render3';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
//import { formatDate } from 'ngx-bootstrap/chronos/format';
import { formatDate } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { SelfServeService } from 'src/app/services/self-serve.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/common.service';
import { PendencyTrackerService } from 'src/app/fur/pendency-tracker.service';


const baseURL_freq: string = environment.baseURL_FREQ;
const moment = extendMoment(Moment);
const dateFormat = 'y-MM-dd';
const dateLocale = 'en-IN';

@Component({
  selector: 'app-pending-inbound',
  templateUrl: './pending-inbound.component.html',
  styleUrls: ['./pending-inbound.component.css']
})
export class PendingInboundComponent implements OnInit {
  finalDataObject = { table: {}, excel: [] };
  finalDataObjectpickup = { table: {}, excel: [] };
  detailedfinalDataObjectpickup = { table: {}, excel: [] };
  finalDataObject1 = { table: {}, excel: [] };
  finalDataObject2 = { table: {}, excel: [] };
  finalDataObject3 = { table: {}, excel: [] };
  finalDataObject4 = { table: {}, excel: [] };
  
  methodValue: any;
  selectedlevel1_val: number;
  urlpathlevel2: any;
  level2: {};
  dtOptionsenable0: boolean;
  dtOptionsenable_1: boolean;
  dtOptionsenable1: boolean;
  dtOptionsenable2: boolean;
  dtOptionsenable3: boolean;
  dtOptionsenable4: boolean;
  dtOptions: DataTables.Settings = {};

  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  detailedReportdtOptions:DataTables.Settings = {};
  responseListExcel2=[];
  
  detailedReportfinalDataObject2 = [];
  
  detailedReportresponseListExcel2=[];
  
  urlpath: string;
  requestObj = {};
  public selectedRowValue: string;
  public selectedColumnValue: string;
  userId_val: any;
  @Input('pendencyTrackerWise') pendencyTracker = 0;
  private fromDate = new Date();
  private toDate = new Date();
  criteriaForm = new FormGroup({
    start_date: new FormControl('', Validators.required),
    end_date: new FormControl('', Validators.required)
  })
  dtOptionspickup: DataTables.Settings = {};
  detailedReportStatus: boolean;
  constructor(private commonService:CommonService,
    private service:SelfServeService,private pendencyTrackerService: PendencyTrackerService, private userId: AppService, private spinner: NgxSpinnerService, ) {
    this.userId_val = this.userId.userDetails.email;
    this.requestObj = { "username": this.userId_val };
    
  }
  ngOnInit() {
    this.pendencyTracker = 4;
    this.supplySales(this.pendencyTracker);
   }
  ngOnChanges(changes: SimpleChanges) {
    this.supplySales(this.pendencyTracker);
  }
  supplySales(paramsupply: any) {

    switch (paramsupply) {
      case 0: {
        this.selectedlevel1_val = 0;
        
        this.PendencyTables(this.requestObj, this.selectedlevel1_val);
        break;
      }
      case 1: {

        this.selectedlevel1_val = 1;
        this.PendencyTables(this.requestObj, this.selectedlevel1_val);
        break;
      }
      case 2: {
        this.selectedlevel1_val = 2;
        this.PendencyTables(this.requestObj, this.selectedlevel1_val);
        break;
      }
      case 3: {
        this.selectedlevel1_val = 3;
        this.PendencyTables(this.requestObj, this.selectedlevel1_val);
        break;
      }
      case 4: {
        this.selectedlevel1_val = 4;
        this.PendencyTables(this.requestObj, this.selectedlevel1_val);
        break;
      }
    }

  }
  PendencyTables(requestObj, selectedlevel1_val) {
    this.dtOptionsenable0=   false;
    this.dtOptionsenable_1= false;
    this.dtOptionsenable1=  false;
    this.dtOptionsenable2=  false;
    this.dtOptionsenable3=  false;
    this.dtOptionsenable4=  false;
    this.detailedReportStatus =false;

    let val1 = 1
    let val2 = 2
    let val3 = 3
    let val4 = 4
    switch (this.selectedlevel1_val){
      case 0: {
        this.getPendencyTables( environment.baseURL_FREQ+"service/delivery", requestObj, selectedlevel1_val);
        this.getPendencyTables( environment.baseURL_FREQ+"service/pickup", requestObj, -1);
        break;
      }
      case 1: {
        this.getPendencyTables( environment.baseURL_FREQ+"pendency/delivery", requestObj, val1);
         break;
      }
      case 2:{
        this.getPendencyTables( environment.baseURL_FREQ+"pendency/pod", requestObj, val2);
        break;
      }
      case 3:{
        this.getPendencyTables( environment.baseURL_FREQ+"pendency/shipping", requestObj, val3);
       break;
      }
      case 4:{
        this.getPendencyTables( environment.baseURL_FREQ+"pendency/inbound", requestObj, val4);
        break;
      }
    }
  }
  setRowColumnvalues = (cell:number,r: string, c: string,region:string) => {
    let requestObjlevel2 = {};
    switch (this.selectedlevel1_val) {
      case 1: {
        this.urlpathlevel2 =  environment.baseURL_FREQ+'freq_report/pendency/delivery_detail';
        requestObjlevel2['username'] = this.userId_val;
        requestObjlevel2['warehouse'] = r;
        requestObjlevel2['region'] =   region;
        requestObjlevel2['type'] =   c;
        this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
        break;
      }
      case 2: {
        this.urlpathlevel2 =  environment.baseURL_FREQ+'freq_report/pendency/pod_detail';
        requestObjlevel2['username'] = this.userId_val;
        requestObjlevel2['warehouse'] = r;
        requestObjlevel2['region'] =   region;
        requestObjlevel2['type'] =   c;
        this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
        break;
      }
      case 3: {
        this.urlpathlevel2 =  environment.baseURL_FREQ+'freq_report/pendency/shipping_detail';
        requestObjlevel2['username'] = this.userId_val;
        requestObjlevel2['warehouse'] = r;
        requestObjlevel2['region'] =   region;
        requestObjlevel2['type'] =   c;
        this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
        break;
      }
      case 4: {
        this.urlpathlevel2 =  environment.baseURL_FREQ+'freq_report/pendency/inbound_detail';
        requestObjlevel2['username'] = this.userId_val;
        requestObjlevel2['warehouse'] = r;
        requestObjlevel2['region'] =   region;
        requestObjlevel2['type'] =   c;
        this.displayTable2(cell,this.urlpathlevel2, requestObjlevel2);
        break;
      }
    }
  }
  backButton() {
    this.detailedReportStatus = !this.detailedReportStatus; 
    this.dtOptionsenable1 =!this.dtOptionsenable1;
    this.dtOptionsenable2 =!this.dtOptionsenable2;
    this.dtOptionsenable3 =!this.dtOptionsenable3;
    this.dtOptionsenable4 =!this.dtOptionsenable4;
  }
  exportAsXLSX(): void {
    if(this.selectedlevel1_val == 1)
    this.service.exportAsExcelFile(this.detailedReportresponseListExcel2, 'Pendency Delivery');
    if(this.selectedlevel1_val == 2)
    this.service.exportAsExcelFile(this.detailedReportresponseListExcel2, 'Pendency POD');
    if(this.selectedlevel1_val == 3)
    this.service.exportAsExcelFile(this.detailedReportresponseListExcel2, 'Pendency Shipping');
    if(this.selectedlevel1_val == 4)
    this.service.exportAsExcelFile(this.detailedReportresponseListExcel2, 'Pendency Inbound');
    
  }
  displayTable2(cell,urlpath, requestObj) {
    if(cell!=0){
      this.detailedReportStatus = false; 
      this.dtOptionsenable1 =!this.dtOptionsenable1;
      this.dtOptionsenable2 =!this.dtOptionsenable2;
      this.dtOptionsenable3 =!this.dtOptionsenable3;
      this.dtOptionsenable4 =!this.dtOptionsenable4;
      this.detailedReportresponseListExcel2 = [];
      
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          if(response['message'] != "No Data")
          {
          this.detailedfinalDataObjectpickup = this.pendencyTrackerService.postDetailed(response,1);
          this.detailedReportdtOptions = this.detailedfinalDataObjectpickup.table;
          this.detailedReportresponseListExcel2 = this.detailedfinalDataObjectpickup.excel;
          this.detailedReportStatus = true;  
          }
        }
      )
    }
    else{
      this.commonService.showToast("No Details exist for this !!",2);
    }
  }

  detailedReport(event) {
    var self = this;
    if(this.selectedlevel1_val == 1){
      $('#example1 tbody').on('click', 'td', function () {
        let table = $('#example1').DataTable();
        let idx = table.cell(this).index().column;
       
        let rowIdx = table.cell(this).index().row;
        let title = table.column(idx).header();
        this.selectedRowValue = table.cell(rowIdx, 0).data();
      //  this.selectedColumnValue = $(title).html();
        let cell = table.cell( this );
        this.regionXYZ = table.cell(rowIdx, 1).data();
    
          if(idx == 1 || idx == 2){
            this.selectedColumnValue = "National LSP"
            self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
        
          }
          if(idx == 3 || idx == 4)
         {
          this.selectedColumnValue = "Non-Dropship"
          self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
      
         }
         if(idx == 5 || idx == 6)
         {
          this.selectedColumnValue = "Dropship"
          self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
      
         }
       
      });
    }
    if(this.selectedlevel1_val == 2){
      $('#example1 tbody').on('click', 'td', function () {
        let table = $('#example1').DataTable();
        let idx = table.cell(this).index().column;
       
        let rowIdx = table.cell(this).index().row;
        let title = table.column(idx).header();
        this.selectedRowValue = table.cell(rowIdx, 0).data();
      //  this.selectedColumnValue = $(title).html();
        let cell = table.cell( this );
        this.regionXYZ = table.cell(rowIdx, 1).data();
    
          if(idx == 1 || idx == 2){
            this.selectedColumnValue = "All"
            self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
          }
       
       
      });
    }
    if(this.selectedlevel1_val == 3){
      $('#example1 tbody').on('click', 'td', function () {
        let table = $('#example1').DataTable();
        let idx = table.cell(this).index().column;
       
        let rowIdx = table.cell(this).index().row;
        let title = table.column(idx).header();
        this.selectedRowValue = table.cell(rowIdx, 0).data();
      //  this.selectedColumnValue = $(title).html();
        let cell = table.cell( this );
        this.regionXYZ = table.cell(rowIdx, 1).data();
    
          if(idx == 1 || idx == 2){
          this.selectedColumnValue = "Non-Dropship"
            self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
          }
          if(idx == 3 || idx == 4){
            this.selectedColumnValue = "Dropship"
            self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
          }  
       
      });
    }

    if(this.selectedlevel1_val == 4){
      $('#example1 tbody').on('click', 'td', function () {
        let table = $('#example1').DataTable();
        let idx = table.cell(this).index().column;
       
        let rowIdx = table.cell(this).index().row;
        let title = table.column(idx).header();
        this.selectedRowValue = table.cell(rowIdx, 0).data();
      //  this.selectedColumnValue = $(title).html();
        let cell = table.cell( this );
        this.regionXYZ = table.cell(rowIdx, 1).data();
    
          if(idx == 1 || idx == 2){
            this.selectedColumnValue = "All"
            self.setRowColumnvalues(cell.data(),this.selectedRowValue, this.selectedColumnValue,this.regionXYZ);
          }
       
       
      });
    }
  }

  getPendencyTables(urlpath, requestObj, val:any) {
    if (val == -1) {
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          this.finalDataObjectpickup = this.pendencyTrackerService.postpickup(response, val);
          console.log(this.finalDataObjectpickup);
          this.dtOptionsenable0= true;
          if (this.finalDataObjectpickup.table) {
            this.dtOptionspickup = this.finalDataObjectpickup.table;
          }
        }
      )
    }
    if (val == 0) {
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          this.finalDataObject = this.pendencyTrackerService.post(response, val);
          console.log(this.finalDataObject);

          this.dtOptionsenable_1=   true;
          if (this.finalDataObject.table) {
            this.dtOptions = this.finalDataObject.table;
          }
        }
      )
    }

    if (val == 1) {
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          this.finalDataObject1 = this.pendencyTrackerService.post1(response, val);
        
          this.dtOptionsenable1 = true;
          this.dtOptionsenable2 = false;
          this.dtOptionsenable3 = false;
          this.dtOptionsenable4 = false;
          if (this.finalDataObject1.table) {
            this.dtOptions1 = this.finalDataObject1.table;
          }
        }
      )
    }
    if (val == 2) {
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          this.finalDataObject2 = this.pendencyTrackerService.post2(response, val);
       
          this.dtOptionsenable1 = false;
          this.dtOptionsenable2 = true;
          this.dtOptionsenable3 = false;
          this.dtOptionsenable4 = false;
          
          if (this.finalDataObject2.table) {
            this.dtOptions2 = this.finalDataObject2.table;
          }
        }
      )
    }
    if (val == 3) {

      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {
          this.finalDataObject3 = this.pendencyTrackerService.post3(response, val);
          console.log(this.finalDataObject3);
          this.dtOptionsenable1 = false;
          this.dtOptionsenable2 = false;
          this.dtOptionsenable3 = true;
          this.dtOptionsenable4 = false;
          
          if (this.finalDataObject3.table) {
            this.dtOptions3 = this.finalDataObject3.table;
          }
        }
      )
    }
    if (val == 4) {
      this.pendencyTrackerService.saveInfo(urlpath, requestObj).subscribe(
        (response) => {

          this.finalDataObject4 = this.pendencyTrackerService.post4(response, val);
          console.log(this.finalDataObject4);
          this.dtOptionsenable1 = false;
          this.dtOptionsenable2 = false;
          this.dtOptionsenable3 = false;
          this.dtOptionsenable4 = true;
          
          if (this.finalDataObject4.table) {
            this.dtOptions4 = this.finalDataObject4.table;
          }
        }
      )
    }


  }
}