import { Component, OnInit, Input, OnChanges, NgZone, SimpleChanges } from '@angular/core';
import { HttpService } from './../../http.service';
import { keys } from 'highcharts';
//import { ItemTrackingESService } from '../item-tracking-es.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AppConstants } from './../../shared/app.constants';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-item-tracking-es',
  templateUrl: './item-tracking-es.component.html',
  styleUrls: ['./item-tracking-es.component.css']
})
export class ItemTrackingEsComponent implements OnInit {

  submitted: boolean;
  checked:boolean;
  analyzeLabel:String = 'Analyzable Format';
  analyze_format:boolean =true;
// enterprise variables
stages = [];
reportsData = [];
config: any = {};
previousQuery = {};
requestDetails ={};

  @Input('itemTrackerESWise') itemTrackerESWise = false;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  detaileddrillDownData:any= {};
  DateCalender: any;
  detailed = false;
  filterData: any = {};
  requestbody: any;
  columnsHeader = [];
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  ddTable ={};
  public selectedL1 = [];
  public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  detailedTable = [];
  tabledata: any = {};
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];

  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  otif: string;
  tabledata_rows: any;
  rowheaders: string[];
  lengthTable: number;
  tabledataObj: any;
  sortedtabledata = [];

  sortedtabledataDD = [];
  dateRange: any;
  RkeyValue: any;
  req_start_date: string;
  req_end_date: string;




  userId_val:any;

  constructor(private commonService:CommonService,
    private userId: AppService,
    private httpService: HttpService,
     public itESservice: ItemTrackingESService,
    private ngZone: NgZone,
     private toaster: ToastrService) {
    this.getFiltersData();
    this.multiSelectDropdown();
    //date Range default
    this.getDefaultDateRange();
    this.userId_val = this.userId.userDetails.email;

    this.getSelectedFilters(this.selectedRegions);

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 10
    };
  }
  getDefaultDateRange() {
    this.itESservice.getDateItemTrackingES().subscribe(dateRes => {
      this.DateCalender = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
    });
  }
  getFiltersData() {
    this.itESservice.getFiltersItemTrackingES(this.userId.userDetails.emai).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  refresh() {
    this.columnsHeader = [];
    this.requestbody = {};
    this.otif = null;
    this.selectedRegions = [];
    this.selectedTeams = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.regions = [];
    this.customers = [];
    this.plants = [];
    this.teams = [];
    this.getDefaultDateRange();
     this.dateRange = null;
    this.getFiltersData();

  }
  getTableDataFromApi() {
    this.sortedtabledata = [];
    this.tabledata = [];
    this.tabledataObj = [];

    this.requestbody = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "region": this.selectedRegions,
      "team": this.selectedTeams,
      "customer_name": this.selectedCustomers,
      "plant_name": this.selectedPlants
    }

    this.itESservice.sendPostRequest( 'https://analysis.moglix.com/'+'item_tracking/get_dash_aggregate_data', this.requestbody).subscribe((data) => {
      this.tabledataObj = data;
      this.columnsHeader = this.tabledataObj['data']['headers'];
      this.tabledata = this.tabledataObj['data']['aggregate_data'];
      this.sortedTable(this.tabledata);
      this.otif = this.tabledataObj['data']['delivered_percentage'];
    })
  }
  sortedTable(tabledata) {
    for (var i in this.tabledata) {
      var obj = this.tabledata[i];
      let orderObject = {};
      for (var j in this.columnsHeader) {
        for (const key of Object.keys(obj)) {
          if (this.columnsHeader[j] == key) {
            orderObject[key] = obj[key];
          }
        }
      }
      this.sortedtabledata.push(orderObject);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getDateRange(this.dateRange);
    this.getSelectedFilters(this.selectedRegions);
    this.TeamUpdatedOptions(this.selectedRegions,this.selectedTeams,this.selectedCustomers,this.selectedPlants);
    this.displayItemTracking();
  }
  displayItemTracking(){
    this.getStages();
  }
  async getStages() {
    const url = 'assets/stages.json';
    try {
      this.stages = await this.httpService.sendGetRequest(url);
    } catch (e) {
      console.log(e);
    }
  }
  getDateRange(date = this.dateRange) {
    this.itESservice.getDateItemTrackingES().subscribe(dateRes => {
     // this.dateRanges = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
      this.req_start_date = this.getDateFormat(this.dateRanges.start_date);
      this.req_end_date = this.getDateFormat(this.dateRanges.end_date);

    });
  }
  generateReports(){
    if (this.dateRange === null)
    {
      this.commonService.showToast("Please Select Date Range",2);
    }
    if(this.dateRange!=null && this.selectedRegions!=null && this.selectedPlants!=null && this.selectedCustomers
      !=null && this.selectedPlants!=null)
    this.getTableDataFromApi();
  }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }
  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }
  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }
  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }
  ngOnInit() {
    this.displayItemTracking();
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  formJsonForReports(data) {
    const tableData = {};
    this.ngZone.runOutsideAngular(() => {
      Object.values(data).forEach((object: any) => {
        object.stage_data.forEach((items: any) => {
          items.forEach(item => {
            tableData[item.item_ref] = tableData[item.item_ref] || { itemRef: item.item_ref };
            tableData[item.item_ref].stageDetails = object.item_ref_property || {};
            tableData[item.item_ref][item.sub_stage_code] = tableData[item.item_ref][item.sub_stage_code] || [];
            if(this.analyze_format){
              tableData[item.item_ref][item.sub_stage_code].push({ stageTime: item.stage_time,stageCode: item.sub_stage_code });
              }
             else{  
               
               tableData[item.item_ref][item.sub_stage_code].push({ stageTime: item.stage_time, quantity: item.quantity, stageCode: item.sub_stage_code });
             
            }




            
          });
        });
      });
    });
    this.reportsData = Object.values(tableData);
  }
  pageChange(pageNumber: number) {
    
    this.config.currentPage = pageNumber;
   this.getDrillDownData(false,false);
  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    this.TeamUpdatedOptions(this.selectedRegions, item, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onTeamDeSelect(deselectedTID: any): void {
 
    if(this.selectedTeams!=null){

      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else{
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = items;
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Customers
  oncustomerSelect(item: string) { this.TeamUpdatedOptions(this.selectedRegions, this.selectedPlants, this.selectedCustomers, this.selectedPlants); }
  oncustomersAll(items: any) {
    this.TeamUpdatedOptions(this.selectedRegions, items, this.selectedCustomers, this.selectedPlants);
  }
  oncustomerDeSelect(deselectedTCID: any): void {this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants); }
  oncustomersDeSelectAll(items: any) {
    this.selectedCustomers = items;
    this.selectedL3 = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Plants
  onplantSelect(item: string) {  }
  onplantsAll(items: any) {  }
  onplantDeSelect(deselectedTPID: any): void {  }
  onplantsDeSelectAll(items: any) {    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];}



  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {
    let teamsList = [];
    let s2 = [];
    let s3 = [];

    let s4 = [];
    this.selectedCustomers = [];
    this.customers = [];
    for (let r = 0; r < Rkeys.length; r++) {

      let y:any = [];
      let regionName = Rkeys[r]
      this.TeamRegionsListObj[regionName].forEach((team, index) => {
        teamsList.push(Object.keys(team));
        for (let st = 0; st < this.selectedTeams.length; st++) {
          if (this.selectedTeams[st] == teamsList[index]) {
            s2.push(teamsList[index]);
            for (let t = 0; t < team[teamsList[index]].length; t++) {
              for (let c = 0; c < Object.keys(team[teamsList[index]][t]).length; c++) {
                s3.push(Object.keys(team[teamsList[index]][t])[c]);
                this.customers = s3;
                this.selectedCustomers = s3;
                y=Object.values(team[teamsList[index]][t])[c];
                for(let u=0;u<y.length;u++){
                  s4.push(y[u]);
                }
                this.selectedPlants = s4;
                this.plants = s4;
              }
            }
          }
        }
      });
    }
   // this.PlantsUpdate(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  PlantsUpdate(Rkeys, Tkeys, CKeys, Pkeys) {
    let s5 = [];
    for (let r = 0; r < Rkeys.length; r++) {
      for (let t = 0; t < Tkeys.length; t++) {
        for (let c = 0; c < CKeys.length; c++) {
          for(let p=0;p<this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]].length;p++){
        
            s5.push(this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]][p]);
            this.selectedPlants =s5
            this.plants = s5
            this.selectedL4 = s5 
            console.log(this.plants);
          }
        }
      }
    }

  }
  getCellDetails(e,rowHeader,columnHeader,cellValue){
    console.log(cellValue);
    
    this.config.currentPage = 1;
   this.requestDetails = {
     "day_range": rowHeader,
     "stage_name":  columnHeader,
     "input_page_number" :1,
     "page_number":1
    }
     this.getDrillDownData();
   
    
  }
  async getDrillDownData(isPageChange = false, isGenerateReport = false){
    this.requestDetails['analyze_format'] = this.analyze_format;
    this.itESservice.getESDrillDown('https://analysis.moglix.com/'+"item_tracking/get_aggregate_data_details",this.requestDetails).subscribe(drilldownData=>{
      this.detaileddrillDownData = drilldownData;
      if(this.detaileddrillDownData!=null)
      {
        this.detailed = true;
        // this.getDrillDownData(false,false);
      }
  });
      const url = 'https://analysis.moglix.com/' + 'item_tracking/get_aggregate_data_details';
      let body: any = {};
     
      if (isPageChange) {
        body = this.previousQuery;
      } else {
        body = this.requestDetails;
        this.previousQuery = body;
      }

    body.page_number = this.config.currentPage;

      try {
        const { data, total_pages, status, message } = await this.httpService.sendPostRequest(url, body);
        if (status && status.toLowerCase() === 'success') {
          this.formJsonForReports(data);
          this.config.totalItems = Number(total_pages) * 10;
        } else {
          this.toaster.error(message, 'Error');
          this.reportsData = [];
        }
      } catch (err) {
        console.log('ERROR from API', err);
        this.reportsData = [];
      }
  }
  backButton() {
    this.detailed = false;
    this.analyze_format = true;
    this.submitted =false;
    this.checked= true;
    this.reportsData = [];
  }
  async onExport() {
    const url = 'https://analysis.moglix.com/' + 'item_tracking/export_aggregate_data_details';

    const body = this.requestDetails;
    const headers = {
      responseType: "blob",
      headers: new HttpHeaders().append("responseType", "text/csv; charset=utf-8")
    }
    try {
      const res: any = await this.httpService.sendPostRequest(url, body, headers);
      const xlData: any[] = [];
      res.data.forEach((element, i) => {
        xlData[i] = {};
        xlData[i]['Item'] = element['Item'];
        xlData[i]['Delivery Due Date'] = element['Delivery Due Date'];
        xlData[i]['CPN'] = element['CPN'];
        xlData[i]['Region'] = element['Region'];
        xlData[i]['Team Name'] = element['Team Name'];
        xlData[i]['Plant Name'] = element['Plant Name'];
        xlData[i]['Customer Name'] = element['Customer Name'];
        xlData[i]['Current Stage'] = element['Current Stage'];
        this.stages.forEach((stage: any) => {
          xlData[i][stage.value] = element[stage.value];
        });
      });
      this.exportAsExcelFile(xlData, 'test');
    } catch (e) {
      console.log('ERROR from API', e);
    }
    // window.saveAs
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    this.ngZone.runOutsideAngular(() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '_';
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const name = 'item_tracking_' + date + time + '.xls'
      XLSX.writeFile(workbook, name, { bookType: 'xls', type: 'buffer' });
    });
  }
  analyseStatus(){
    this.analyze_format = !this.analyze_format;
    this.checked = !this.checked;
    if(this.analyze_format){
 
      
      this.getDrillDownData(false,false);
      this.submitted = false;
    }
    else{
      this.displayItemTracking();
      this.getDrillDownData(false,false);
      this.submitted = true;
    }
  }
}



