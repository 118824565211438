import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';
import { text } from '@angular/core/src/render3';




@Injectable({
  providedIn: 'root'
})

export class WHReportService {

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';

  constructor(private http: HttpClient) { }
  saveInfo(urlpath, data) {

    return this.http.post(urlpath, data)
  }
  post(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers']
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postMgmt(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            //orderObject[key] = obj[key].toLocaleString();

            orderObject[key] = obj[key];
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
        if(col == 0){
         $(td).css({'background-color':'#e7ebef','z-index': 500})
       }

       if(col == 1){
         $(td).css({'background-color':'#e7ebef','az-index': 500})
       }
        

       if(col == 2){
         $(td).css({'background-color':'#e7ebef','az-index': 500})
       }
        

       if(col == 3){
         $(td).css({'background-color':'#e7ebef','az-index': 500})
       }

      }
    });
    }
    this.dtOptions = {
      destroy: true,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,      
      ordering:false,
      fixedHeader: true,
      fixedColumns: {
        leftColumns: 4
            
      },
      scrollY: '62vh',
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',

      columnDefs: [
        {
          width: "300",
          targets: '_all',
          //render: function (data, type, row) {
            //             return   data ;

          //}
            render: function ( data, type, row ) {
             return data.length > 20 ?'<span title="'+data+'">'+data.substr( 0, 20 ) +"…"+'</span>':data;


        }
        }
      ],
      language: {
        info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
      
        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
}
