import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';
import { BridgeReportService } from 'src/app/services/bridge-report.service';
import { Buscorecardtop20Service } from 'src/app/services/buscorecardtop20.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';


@Component({
  selector: 'app-bridge-report',
  templateUrl: './bridge-report.component.html',
  styleUrls: ['./bridge-report.component.css']
})
export class BridgeReportComponent implements OnInit {
  userId_val:any;
  requestbody = {};
  dtOptions: DataTables.Settings = {};
  final ={};
  navbarbu =[];
  newArrayG = [];
  currentactiveTab: any;
  public bsValue: any = new Date();
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public date = new Date();
  minDate: Date;
  currentMonth = this.date.getMonth();
  currentDate = this.date.getDate();
  currentYear = this.date.getFullYear();
  maxDate: Date;
  tableDisplay = false;
  selectedDate: any;
  selectedCurrentDate:any;
  previousDate:any;
  selected='/bridgeBU';
  headers: any;
  constructor(private router: Router,private navbar:NavbarComponent,
    private bridgeService:BridgeReportService,
    private userId: AppService) { 
      //this.navbarbu = this.navbar['navbarBU'][0];
      this.navbarbu = [
        {"link": "/regionBU", "show": true, "label": "Region Scorecard"},
        {"link":"/butop20","show":true, "label":"Top 20 Customers"},
        {"link":"/bridgeBU", "show":true,"label":"Bridge"}]
    this.userId_val =  this.userId.userDetails.email;

  }

  ngOnInit() {
    this.minDate= new Date(this.currentYear, this.currentMonth-5, this.currentDate);
    this.maxDate = new Date();
    console.log(this.navbarbu);

    let date = new Date();
    let months = [],
    monthNames = [ "January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    for(var i = 0; i < 12; i++) {
        months.push({value: monthNames[date.getMonth()] + ' ' + date.getFullYear(), id: {month: date.getMonth(), year: date.getFullYear()} });
        date.setMonth(date.getMonth() - 1);
    }
    this.selectedDate = moment(this.bsValue).format('YYYY-MM');

    this.getDataByFilter();
    
  }
  isActive(e_menu_link,idx) {
    return this.selected === e_menu_link;
  }
  getVisual(e_menu_link){
    this.selected = e_menu_link; 
    this.currentactiveTab = e_menu_link;
       switch (e_menu_link) {
      case '/regionBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);

        break;
      }
      case '/bridgeBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);
        
        break;
      }
      case '/butop20': {
        this.selected=e_menu_link;
        this.getDataByFilter();
        this.router.navigate([this.selected]);
        
        break;
      }
    }
  }
  getDataByFilter() {
    let monthNames =["Jan","Feb","Mar","Apr",
    "May","Jun","Jul","Aug",
    "Sep", "Oct","Nov","Dec"];
    this.dtOptions = {};
    this.tableDisplay = false;
    this.selectedDate = moment(this.bsValue).format('YYYY-MM');
    let selectDateVal = this.selectedDate;
    if(this.bsValue.getMonth()!=0)
  { this.selectedCurrentDate = monthNames[this.bsValue.getMonth()]+"'"+this.bsValue.getFullYear().toString().slice(2); 
    this.previousDate = monthNames[this.bsValue.getMonth()-1]+"'"+this.bsValue.getFullYear().toString().slice(2);}
    else{
      this.selectedCurrentDate = monthNames[this.bsValue.getMonth()]+"'"+this.bsValue.getFullYear().toString().slice(2);  
      this.previousDate = monthNames[11]+"'"+(this.bsValue.getFullYear()-1).toString().slice(2);  
    }
     console.log(this.selectedCurrentDate)
     console.log(this.previousDate)
      this.requestbody ={'YM':this.selectedDate,'username':this.userId_val};
    this.dtOptions = {};
    this.tableDisplay = false;
      this.bridgeService.saveInfo(environment.baseURL_FREQ+'bridge_report', this.requestbody).subscribe((data) => {
       
        let arrayG = data['data']['result'];
        this.headers= data['data']['headers'];
let k=0;
        for (let l = 0; l < arrayG.length; l++) {

          let temprow = {};
          if (arrayG[l]['Customer Name'] == this.selectedCurrentDate ) {
  
  
            for (let h = 0; h < this.headers.length; h++) {
              temprow[this.headers[h]] = '<b  style="color:black!important;">'+arrayG[l][this.headers[h]]+'</b>'
            }

            this.newArrayG[k++] = temprow;        
          }
          else if (arrayG[l]['Customer Name'] == this.previousDate ) { 
            for (let h = 0; h < this.headers.length; h++) {
              temprow[this.headers[h]] = '<b  style="color:black!important;">'+arrayG[l][this.headers[h]]+'</b>'
            }
            this.newArrayG[k++] = temprow;        
          }
          else { 
            for (let h = 0; h < this.headers.length; h++) {
              if(arrayG[l][this.headers[h]]>=0)
              temprow[this.headers[h]] = arrayG[l][this.headers[h]]

              else
              {
                              
              if(this.headers[h] =='Region' || this.headers[h]=='Customer Name')
              temprow[this.headers[h]] = arrayG[l][this.headers[h]]
              
              else if(this.headers[h] !='Region' || this.headers[h]!='Customer Name')
              temprow[this.headers[h]] = '<span style="color:red;">'+arrayG[l][this.headers[h]]+'</span>'
              else
              temprow[this.headers[h]] = arrayG[l][this.headers[h]]
              }
            }

            this.newArrayG[k++] = temprow;        
          }
        
        }

        data['data']['result'] = this.newArrayG;

      this.final =this.bridgeService.post(data)
      if (this.final['table']) {
        
        this.userId.saveInfo(environment.user_tracking_api,{'username':this.userId_val,'report_name':'BU Bridge Report'}).subscribe(reportdata=>{
          console.log(reportdata);
        })
        this.dtOptions = this.final['table'];
        if (this.dtOptions != null) { let arrayG = data['data']['result'];
      
      this.headers= data['data']['headers'];
          this.tableDisplay = true;
         
        }

      }
    })
    }
}
