 
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { NgModule } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { LocationStrategy } from '@angular/common';

import { environment } from '../../../environments/environment';
@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
   reportlabel: any;
   furstatus = 2;
   selected = 0;
   userPicture = "";
   mainHeader = "";
   L1Title = "";
   L2Title = ""
   L3Title = "";
   seletcedMainHeader = "";
   menu = [];
   flag = 0;
   userName: string;
   seletcgraphTypedMainHeader: any;
   menuListArray = [];
   display: boolean;
   userId_val: any;
   userId
   sessionURL = environment.baseURL_FREQ;
   menuDisplay: boolean;
   MainTitle=true;
   displayPage: boolean;
   constructor(private router: Router, private appService: AppService, private location: LocationStrategy) {
      
      history.pushState(null, null, window.location.href);
      // check if back or forward button is pressed.
      this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
          this.getMenuBarList()
      });


      this.userId_val = this.appService.userDetails.email;
      if (this.userId_val) {
         this.appService.getdefaultRegionsList();
      }

   }
   ngOnInit() {
      this.userPicture = this.appService.getUserDetails(['photoUrl']);
      this.userName = this.appService.getUserDetails(['userName']);
      this.getMenuBarList();
   }
   ngOnChanges(changes: SimpleChanges) {
      this.seletcgraphTypedMainHeader = this.mainHeader;
   }
   logout() {
      this.appService.logout();
   }
   menuToggle(event) {
      this.menuDisplay = !this.menuDisplay;
   }
   close() {
      this.menuDisplay = false;
   }

   getMenuBarList() {
      this.appService.getMenuDataAPI(environment.baseURL_FREQ + 'get_session_data', this.userId_val).subscribe(res => {
         this.menu = res['data'];
//          this.menu = [
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/LandingPage",
//                         "show":true,
//                         "label":"Landing Page"
//                      }
//                   ],
//                   "label":"Landing Page",
//                   "show":true
//                }
//             },
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/graphs",
//                         "show":true,
//                         "label":"Org Level Scorecard"
//                      },

//                      {
//                         "link":"/mgmtScore",
//                         "show":true,
//                         "label":"Moglix Scorecard"
//                      },
//                      {
//                         "show":true,
//                         "label":"BU Scorecard",
//                         "children":[
// //                            {
// //                               "label":"Upload",
// //                               "show":true,
// //                               "link":"/budataInput"
// //                            },
//                            {
//                               "label":"BU Reports",
//                               "show":true,
//                               "link":"/buReports"
//                            }
//                         ]
//                      },

//                      {
//                         "show":true,
//                         "label":"Area-Wise",
//                         "children":[
//                            {
//                               "label":"Region-wise Table",
//                               "show":true,
//                               "link":"/enterpriseRegion"
//                            },
//                            {
//                               "label":"Team-wise Table",
//                               "show":true,
//                               "link":"/enterpriseTeam"
//                            }
//                         ]
//                      },
//                      {
//                         "show":true,
//                         "label":"SME Scorecard",
//                         "children":[
//                            {
//                               "label":"SME Dashboard",
//                               "show":true,
//                               "link":"/smeDashboards"
//                            },
//                            {
//                               "label":"Self Service",
//                               "show":true,
//                               "link":"/smeSelfService"
//                            }
//                         ]
//                      }
//                   ],
//                   "label":"Executive Scorecard",
//                   "show":true
//                }
//             },

//             {
//                "children":{
//                   "children":[
                     
//                      {
//                         "show":true,
//                         "label":"Indirect Packaging",
//                         "children":[
//                            {
//                               "label":"Region Indirect Packaging",
//                               "show":true,
//                               "link":"/regionalIndrectSales"
//                            },
//                            {
//                               "label":"Customer Indirect Packaging",
//                               "show":true,
//                               "link":"/customerIndrectSales"
//                            }
//                         ]
//                      },
//                      {
//                         "show":true,
//                         "label":"Area-Wise",
//                         "children":[
//                            {
//                               "label":"Regional Sales",
//                               "show":true,
//                               "link":"/regionalSales"
//                            },
//                            {
//                               "label":"Team Sales",
//                               "show":true,
//                               "link":"/teamSales"
//                            },
//                            {
//                               "label":"Customer Sales",
//                               "show":true,
//                               "link":"/customerSales"
//                            },
//                            {
//                               "label":"Plant Sales",
//                               "show":true,
//                               "link":"/plantSales"
//                            }
//                         ]
//                      },
//                      {
//                         "link":"/sales360",
//                         "show":true,
//                         "label":"Sales 360"
//                      }
//                   ],
//                   "label":"Sales",
//                   "show":true
//                }
//             },
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/inventory",
//                         "show":true,
//                         "label":"Inventory Dashboard"
//                      },
//                      {
//                         "link":"/SCEsupply",
//                         "show":true,
//                         "label":"SCE 2.0"
//                      },
//                      {
//                         "show":true,
//                         "label":"RFQ Dashboards",
//                         "children":[
//                            {
//                               "label":"RFQ Dashboards - L0",
//                               "show":true,
//                               "link":"/rfq_dashboard"
//                            },
//                            {
//                               "label":"RFQ Dashboards - L1",
//                               "show":true,
//                               "link":"/rfq_dashboard_l1"
//                            },
//                            {
//                               "label":"RFQ Dashboards - L2",
//                               "show":true,
//                               "link":"/rfq_dashboard_l2"
//                            },
//                            {
//                               "label":"RFQ Dashboards - L3",
//                               "show":true,
//                               "link":"/rfq_dashboard_l3"
//                            }
//                         ]
//                      },
//                      {
//                         "show":true,
//                         "label":"Sales",
//                         "children":[
//                            {
//                               "label":"Sales Dashboard - L0",
//                               "show":true,
//                               "link":"/sales_dashboard"
//                            },
//                            {
//                               "label":"Sales Dashboard - L1",
//                               "show":true,
//                               "link":"/sales_dashboard_l1"
//                            },
//                            {
//                               "label":"Sales Dashboard - L2",
//                               "show":true,
//                               "link":"/sales_dashboard_l2"
//                            },
//                            {
//                               "label":"Sales Dashboard - L3",
//                               "show":true,
//                               "link":"/sales_dashboard_l3"
//                            }
//                         ]
//                      },
//                      {
//                         "show":true,
//                         "label":"AP Credit Days",
//                         "children":[
//                            {
//                               "label":"AP Credit Days - L0",
//                               "show":true,
//                               "link":"/credit_days"
//                            },
//                            {
//                               "label":"AP Credit Days - L1",
//                               "show":true,
//                               "link":"/credit_days_l1"
//                            },
//                            {
//                               "label":"AP Credit Days - L2",
//                               "show":true,
//                               "link":"/credit_days_l2"
//                            }
//                         ]
//                      },
//                      {
//                         "show":true,
//                         "label":"Pickup Adherance",
//                         "children":[
//                            {
//                               "label":"Pickup Adherance - L0",
//                               "show":true,
//                               "link":"/pick_adherance"
//                            },
//                            {
//                               "label":"Pickup Adherance - L1",
//                               "show":true,
//                               "link":"/pick_adherance_l1"
//                            },
//                            {
//                               "label":"Pickup Adherance - L2",
//                               "show":true,
//                               "link":"/pick_adherance_l2"
//                            },
//                            {
//                               "label":"Pickup Adherance - L3",
//                               "show":true,
//                               "link":"/pick_adherance_l3"
//                            }
//                         ]
//                      }
//                   ],
//                   "label":"Supply",
//                   "show":true
//                }
//             },
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/insights",
//                         "show":true,
//                         "label":"KAM Dashboard"
//                      }
//                   ],
//                   "label":"KAM",
//                   "show":true
//                }
//             },

//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/csbreach",
//                         "show":true,
//                         "label":"Breach Report"
//                      },
//                      {
//                         "link":"/pod_tracker",
//                         "show":true,
//                         "label":"POD Tracker"
//                      },
//                      {
//                         "link":"/online_pendency",
//                         "show":true,
//                         "label":"Online Pendency"
//                      },
//                      {
//                         "show":true,
//                         "label":"Item Tracking",
//                         "children":[
//                            {
//                               "label":"OTIF Item Tracking",
//                               "show":true,
//                               "link":"/itemTrackingOTIF"
//                            }
//                         ]
//                      }
// //                     ,
// //                      {
// //                         "show":true,
// //                         "label":"Pendency",
// //                         "children":[
// //                            {
// //                               "label":"Delivery Pendency",
// //                               "show":true,
// //                               "link":"/deliveryPendency"
// //                            },
// //                            {
// //                               "label":"Pending Inbound",
// //                               "show":true,
// //                               "link":"/pendingInbound"
// //                            },
// //                            {
// //                               "label":"Pending POD",
// //                               "show":true,
// //                               "link":"/pendingPOD"
// //                            },
// //                            {
// //                               "label":"Pending Shipping",
// //                               "show":true,
// //                               "link":"/pendingShipping"
// //                            }
// //                         ]
// //                      }
//                   ],
//                   "label":"CS",
//                   "show":true
//                }
//             },
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/smeFinance",
//                         "show":true,
//                         "label":"SME Finance"
//                      },
//                      {
//                         "link":"/regionalOSOD",
//                         "show":true,
//                         "label":"Regional OS/OD"
//                      },
//                      {
//                         "link":"/teamOSOD",
//                         "show":true,
//                         "label":"Team OS/OD"
//                      },
//                      {
//                         "link":"/customerOSOD",
//                         "show":true,
//                         "label":"Customer OS/OD"
//                      },
//                      {
//                         "link":"/plantOSOD",
//                         "show":true,
//                         "label":"Plant OS/OD"
//                      },
//                      {
//                         "link":"/advance_po",
//                         "show":true,
//                         "label":"Advance PO"
//                      }
//                   ],
//                   "label":"Finance",
//                   "show":true
//                }
//             },    
           

            
     
            
//             {
//                "children":{
//                   "children":[
//                      {
//                         "link":"/regionTargets",
//                         "show":true,
//                         "label":"Region Targets"
//                      },
//                      {
//                         "link":"/targetview",
//                         "show":true,
//                         "label":"Team Targets"
//                      },
//                      {
//                         "link":"/customers",
//                         "show":true,
//                         "label":"Customer Targets"
//                      }
//                   ],
//                   "label":"MDM",
//                   "show":true
//                }
               
               
               
//             },

//             {
//                "children":{
//                   "children":[
//                      {
//                         "show":true,
//                         "label":"SME",
//                         "children":[
//                            {
//                               "label":"VMI",
//                               "show":true,
//                               "link":"/WHreorderMSN"
//                            }
//                         ]
//                      },
//                      {
//                         "link":"/cpnmsnMapping",
//                         "show":true,
//                         "label":"CPN MSN Mapping"
//                      },
//                      {
//                         "link":"/bulkpod",
//                         "show":true,
//                         "label":"Bulk Invoice - POD"
//                      }
//                   ],
//                   "label":"Tool",
//                   "show":true
//                }
//             }
//          ]
         this.getFirstChild(this.menu)
      });
      //this.displayPage = false;
  

   }

   getFirstChild(menuList) {
      this.flag = 0;

      this.traverseFirstChild(menuList[0]['children']);
      this.L1Title = menuList[0]['children']['label']
   }
   traverseFirstChild(children) {
      this.displayPage= false;
      if (children['children']) {
         this.flag = this.flag + 1;
         if (this.flag == 1) {
               this.L2Title = children['children'][0]['label'];
            this.L3Title=null

         }
         this.traverseFirstChild(children['children'][0]);
      }
      if (children['link']) {
         this.L3Title = children['label'];
         this.reportlabel = children['label'];
         const value = sessionStorage.getItem('loadTime') || '';
         if(value == '') {
            this.MainTitle = false;
            this.displayPage= true;
            this.router.navigate([children['link']]);
            this.close()
         }
         this.L3Title=null;
         this.MainTitle = true;
         this.display = true;
      }
   }
   getGraphTitleL1L2(L1, L2, L2Link) {
      this.displayPage= false;
      this.L1Title = "";
      this.L2Title = "";
      this.L3Title = "";

      this.L1Title = this.menu[L1].children.label;
      this.L2Title = this.menu[L1].children.children[L2].label;

      this.appService.setL1L2(this.L1Title, this.L2Title, L1, L2);
      this.reportlabel = this.L2Title;
      this.MainTitle = false;
      this.displayPage= true;
      this.router.navigate([L2Link]);
      this.L3Title = null
      this.close();
   }

   getGraphTitleL1L2L3(L1, L2, L3Label, L3Link, L3) {
      this.displayPage= false;
      this.L1Title = "";
      this.L2Title = "";
      this.L3Title = "";
      this.L1Title = this.menu[L1].children.label;
      this.L2Title = this.menu[L1].children.children[L2].label;
      this.L3Title = L3Label;
      this.reportlabel = this.L3Title;
      this.appService.setL1L2L3(this.L1Title, this.L2Title, this.L3Title, L1, L2, L3);
      this.MainTitle = false;
      this.displayPage= true;
      this.router.navigate([L3Link]);
      
      this.close();

   }
   reloadPage(){
      window.location.reload();
      // this.displayPage = false;
      // this.display = false;
      // this.MainTitle = false;
      // this.router.navigate(['/LandingPage']);
   }
   

}
