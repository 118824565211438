import { Component, OnChanges, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { RfqService } from 'src/app/services/rfq.service';
import { SCESupplyService } from 'src/app/services/sce-supply.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-credit-days-l2',
  templateUrl: './credit-days-l2.component.html',
  styleUrls: ['./credit-days-l2.component.css']
})
export class CreditDaysL2Component implements OnInit {
  dateRanges = { start_date: new Date(), end_date: new Date() };
  DateCalender: any;
  regions: any = [];
  public selectedRegions = [];
  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  dateRange: any;
  userId_val: any;
  filterData: any = {};
  dtOptions: DataTables.Settings = {};
  tableDisplay: boolean;
  reportName: any;

  constructor(private commonService: CommonService,private service: SelfServeService, private rfqService: RfqService, private sceService: SCESupplyService,  private appService: AppService, private navbarTitle: NavbarComponent) {
    this.userId_val = this.appService.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
    this.regions = JSON.parse(localStorage.getItem('regions'));
    this.multiSelectDropdown();
   }
  
   fetchData1() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l2/ap_credit_day_supplier';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if(resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
        }
        const smeFinanceObj = this.sceService.post(resp);
        if (smeFinanceObj['table']) {
            let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
            this.appService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
              console.log(reportdata);
            })
            this.dtOptions = smeFinanceObj['table'];
            this.tableDisplay = true;
        }
    });
}

  ngOnInit() {
  }

  calculateDateRange(value: Date): void {
    if(value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 185) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      } 
    }
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }

  getDateFormat(date, isLast = false) {
    date = new Date(date);
    if (!date) return;
    if(isLast) {
      date.setDate(date.getDate() + 1);
    }
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }

  generateReports() {
    const payload = this.generatePayload();
    this.fetchData1();
  }

  generatePayload() {
    const payload = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1], true),
      "regions": this.selectedRegions
    }
    return payload;
  }

}
