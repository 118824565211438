import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';
import { InsightsService } from 'src/app/services/insights.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
    selector: 'app-insights',
    templateUrl: './insights.component.html',
    styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit {

    currentdateValue: any;
    currentDate = new Date();
    grouping_col_value = 'Team';
    grouping_col = 'Team';
    selectedTab = 'rfq';
    selectedBtn = 'Sales'
    buttons = ['Sales', 'Procurement', 'Collections', 'Payables', 'SCM', 'Adoptions', 'Visit'];
    userId_val: any;
    //data:any;
    smeFinanceObj: { table: {}; excel: any[]; };
    tableDisplay: boolean;
    dtOptions: DataTables.Settings = {};
    reportName: any;
    public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
    public datePickerConfigForSupply: any = {};
    public bsValue: any = new Date();
    maxDate = new Date();
    dtOptions2: DataTables.Settings = {};
    dtOptions3: DataTables.Settings = {};
    dtOptions4: DataTables.Settings = {};
    openFilters: boolean = false;
    public selectedRegions = [];
    public selectedTeams = [];
    public selectedCustomers = [];
    TeamRegionsListObj: any = {};
    dropdownSettings = {};
    teamdropdownSettings = {};
    customerdropdownSettings = {};
    plantdropdownSettings = {};
    filterData: any = {};
    regions: any = [];
    teams: any = [];
    customers: any = [];
    RkeyValue: any;
    catL1list = [];
    catL2list = [];
    selectedL1 = [];
    selectedL2 = [];
    dropDownSelect = false;
    sourcers: any = [];
    public selectedSourcers = [];
    sourcerdropdownSettings = {};
    cat1dropdownSettings = {};
    cat2dropdownSettings = {};
    selectedGrouping_Col: string;
    searchVal: any;
    excelDtOption: boolean;
    excelTable: boolean;
    dtoptionexcel1: {};
    excelData1: any;
    excelData2: any[];
    dtoptionexcel2: {};
    rfq_detailedRequestBody: {};
    order_detailedRequestBody: {};
    excelData_order: any;
    excelDtOption_order: any;
    excelData_business: any;
    excelDtOption_business: any;
    business_detailedRequestBody: {}
    selectedL3: any[];
    selectedL4: any[];
    selectedPlants: any[];
    plants: any[];

    constructor(private appService: AppService, private Service: SelfServeService, private navbarTitle: NavbarComponent, private insightsService: InsightsService, public itESservice: ItemTrackingESService, private cdr: ChangeDetectorRef) {
        this.fetchSourcersList();
        this.fetchCat1list();
        

        this.getFiltersDataDefault();


        //this.getFiltersData();
        this.multiSelectDropdown();

        this.getGrouping_Col();



    }

    getGrouping_Col() {

        this.selectedGrouping_Col = this.grouping_col_value


    }
    fetchSourcersList() {
        const url = 'https://analytics-web.moglix.com/freq_report/sourcers_POC';
        this.insightsService.getData(url).subscribe(filtersRes => {

            this.sourcers = filtersRes["sourcer_list"];
            this.selectedSourcers = this.sourcers;
        });
    }

    fetchCat1list() {
        const url = 'https://analytics-web.moglix.com/freq_report/getCategories';
        this.insightsService.getData(url).subscribe(filtersRes => {
            this.catL1list = filtersRes['result'];
            this.selectedL1 = this.catL1list;
        });


    }


    ngOnInit() {
        this.userId_val = this.appService.userDetails.email;
        this.reportName = this.navbarTitle.reportlabel;
    }

    ngOnChanges(changes: SimpleChanges) {
        //this.fetchData()
        this.getFiltersDataDefault()
        this.getSelectedFilters(this.selectedRegions);
        this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
        // this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers);
    }

    getSelectedFilters(selectedRegionsList) {
        this.teams = [];
        this.selectedTeams = [];
        this.selectedL3 = [];
        this.customers = [];
        this.selectedCustomers = [];
        this.selectedL4 = [];
        this.plants = [];
        this.selectedPlants = [];
        for (let j = 0; j < selectedRegionsList.length; j++) {
            this.getTeamsKey(selectedRegionsList[j]);
        }
    }
    getTeamsKey(Rkey) {

        this.RkeyValue = Rkey;
        this.TeamRegionsListObj[Rkey].forEach((team, index) => {

            let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
            let Tkey = team[currentTeamName];
            for (let t = 0; t < Tkey.length; t++) {
                let Ckey = Object.keys(Tkey[t]);
                this.getCustomersKey(Ckey, Tkey[t]);
            }
            this.selectedTeams.push(currentTeamName);
            this.teams.push(currentTeamName);
        });
    }
    getCustomersKey(Ckey, Tkey) {
        Ckey.forEach((customer, cindex) => {
            this.selectedL3.push(customer);
        });
        for (let t = 0; t < Ckey.length; t++) {
            this.getPlantsKey(Ckey, Tkey);
        }
        this.selectedCustomers = this.selectedL3;
        this.customers = this.selectedL3;
    }
    getPlantsKey(Ckey, Tkey) {
        for (let p = 0; p < Tkey[Ckey].length; p++) {
            this.selectedL4.push(Tkey[Ckey][p]);
        }
        this.selectedPlants = this.selectedL4;
        this.plants = this.selectedL4;
    }

    multiSelectDropdown() {
        this.dropdownSettings = {
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true,
            itemsShowLimit: 1
        };
        this.teamdropdownSettings = {
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true,
            itemsShowLimit: 1
        };
        this.customerdropdownSettings = {
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true,
            itemsShowLimit: 1
        };

        this.plantdropdownSettings = {
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true,
            itemsShowLimit: 1
        };
        this.sourcerdropdownSettings = {
            singleSelection: false,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true,
            itemsShowLimit: 1
        };

    }
    getFiltersData() {
        // this.TeamRegionsListObj = [];
        // this.selectedRegions = [];
        // this.filterData =[];
        // this.regions =[];
        //const url = 'https://analytics-web.moglix.com/freq_report/region_mapping';
        const url = environment.baseURL_FREQ+'um_access_mapping';
        this.itESservice.sendPostRequest(url, { username: this.userId_val }).subscribe(filtersRes => {
            this.filterData = filtersRes["data"];
            this.TeamRegionsListObj = filtersRes["data"];
            this.regions = Object.keys(filtersRes["data"]);
            this.selectedRegions = this.regions;
            this.getSelectedFilters(this.selectedRegions);
            setTimeout(() => {
                this.fetchData();
            }, 700);
        });
    }
    getFiltersDataDefault() {
         const url = environment.baseURL_FREQ+'um_access_mapping';
        this.itESservice.sendPostRequest(url, { username: this.userId_val }).subscribe(filtersRes => {
          
            let filteredItems=[];
            this.filterData = filtersRes["data"];
            this.TeamRegionsListObj = filtersRes["data"];
            this.regions = Object.keys(filtersRes["data"]);

            let defaultTeamFilter = [];
            let filterObj2 = ['All'];
            let filterfordefault = [];
            let arr = this.regions.filter(function(item){
                console.log(item)
                if(item != "All"){
                    filterfordefault.push(item) 
                }
              });
              console.log(filterfordefault)
            if(this.regions.length>=2){
            defaultTeamFilter.push(filterfordefault[0])
            defaultTeamFilter.push(filterfordefault[1])
              this.selectedRegions = defaultTeamFilter
            }
            if(this.regions.length==1){
              defaultTeamFilter.push(this.regions[0])
              this.selectedRegions = defaultTeamFilter
            }

            
            this.getSelectedFilters(this.selectedRegions);
            setTimeout(() => {
                this.fetchData();
            }, 700);

        });
    }

    TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {

        let teamsList = [];
        let s2 = [];
        let s3 = [];
        this.selectedCustomers = [];
        let s4 = [];
        this.selectedCustomers = [];
        this.selectedPlants = [];
        this.customers = [];
        this.plants = [];
        for (let r = 0; r < this.regions.length; r++) {
            for (let rr = 0; rr < Rkeys.length; rr++) {
                if (this.regions[r] === Rkeys[rr]) {
                    this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {
                        let currentTeamName = Object.keys(this.TeamRegionsListObj[this.regions[r]][index])[0];

                        for (let t = 0; t < team[currentTeamName].length; t++) {

                            for (let tt = 0; tt < Tkeys.length; tt++) {
                                if (currentTeamName === Tkeys[tt]) {
                                    this.selectedCustomers.push(Object.keys(team[currentTeamName][t])[0])
                                    this.customers.push(Object.keys(team[currentTeamName][t])[0])
                                    let cName = Object.keys(team[currentTeamName][t])[0]
                                    for (let p = 0; p < team[currentTeamName][t][cName].length; p++) {
                                        this.plants.push(team[currentTeamName][t][cName][p])
                                        this.selectedPlants.push(team[currentTeamName][t][cName][p])
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }
    }
    CustomerUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {

        this.selectedPlants = [];
        this.selectedL4 = [];
        this.plants = [];
        Pkeys = [];

        for (let r = 0; r < this.regions.length; r++) {
            for (let rr = 0; rr < Rkeys.length; rr++) {
                if (this.regions[r] === Rkeys[rr]) {
                    this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {
                        let currentTeamName = Object.keys(team)[0];
                        for (let t = 0; t < team[currentTeamName].length; t++) {
                            for (let tt = 0; tt < Tkeys.length; tt++) {
                                if (currentTeamName == Tkeys[tt]) {
                                    let customer = Object.keys(team[currentTeamName][t])[0];
                                    for (let cc = 0; cc < team[currentTeamName][t][customer].length; cc++) {
                                        this.plants.push(team[currentTeamName][t][customer][cc]);

                                        for (let p = 0; p < CKeys.length; p++) {

                                            if (CKeys[p] == customer) {
                                                for (let pp = 0; pp < team[currentTeamName][t][customer].length; pp++) {
                                                    this.selectedL4.push(team[currentTeamName][t][customer][pp]);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }

        }

        if (this.selectedL4.length > 0) {
            this.selectedPlants = this.selectedL4.filter((v, i, a) => a.indexOf(v) === i);

        }

    }


    onRegionSelect(item: string) {
        this.getSelectedFilters(this.selectedRegions);
    }
    onRegionsAll(items: any) {
        this.selectedRegions = this.regions;
        this.getSelectedFilters(this.selectedRegions);
    }
    onRegionDeSelect(deselectedSID: any): void {
        this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
        this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
        this.getSelectedFilters(this.selectedRegions);
    }
    onRegionsDeSelectAll(items: any) {
        this.selectedTeams = [];
        this.teams = [];
        this.selectedCustomers = [];
        this.customers = [];
        this.selectedL3 = [];
        this.selectedPlants = [];
        this.plants = [];
        this.selectedL4 = [];

    }
    //teams
    onTeamSelect(item: string) {
        // this.selectedTeams.push(item);
        this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

    }
    onTeamsAll(items: any) {
        this.selectedTeams = items
        this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

    }
    onTeamDeSelect(deselectedTID: any): void {

        if (this.selectedTeams != null) {
            this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
        }
        else {
            this.selectedTeams = [];
            this.selectedL3 = [];
            this.selectedCustomers = [];
            this.customers = [];
            this.selectedL4 = [];
            this.selectedPlants = [];
            this.plants = [];
        }
    }
    onTeamsDeSelectAll(items: any) {
        this.selectedTeams = [];
        this.selectedL3 = [];
        this.selectedCustomers = [];
        this.customers = [];
        this.selectedL4 = [];
        this.selectedPlants = [];
        this.plants = [];
    }
    //Customers
    onCustomerSelect(item: string) {
        this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    onCustomersAll(items: any) {
        this.selectedCustomers = items;
        this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    onCustomerDeSelect(deselectedTCID: any): void {
        if (this.selectedCustomers != null) {
            this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
        }
        else {

            this.selectedCustomers = [];
            this.selectedL4 = [];
            this.selectedPlants = [];
            this.plants = [];
        }
    }
    onCustomersDeSelectAll(items: any) {

        this.selectedL3 = [];
        this.selectedCustomers = [];

        this.selectedL4 = [];
        this.selectedPlants = [];
        this.plants = [];
    }
    //Plants
    onPlantSelect(item: string) {
        this.selectedL4.push(item);
        this.selectedPlants.push(item);

    }
    onPlantsAll(items: any) {
        this.selectedPlants = items;
    }
    onPlantDeSelect(deselectedTPID: any): void { }
    onPlantsDeSelectAll(items: any) {
        this.selectedL4 = [];
        this.selectedPlants = [];

        // this.plants = [];
    }


    fetchData() {
        let payload = {};
        let self = this;
        payload = {
            "username": this.userId_val,
            "teams": this.selectedTeams,
            "customers": this.selectedCustomers,
            "plants": this.selectedPlants,
            "sourcers": this.selectedSourcers,
            "categories": this.selectedL1,
            "grouping_col": this.grouping_col
        }


        this.tableDisplay = false;
        let url = '';
        let options = '';
        let report_name = 'KAM-'
        if (this.selectedTab == 'rfq') {
            options = 'dtOptions';
            url = 'https://analytics-web.moglix.com/freq_report/kam_rfq_tracking';

            report_name += 'RfQ View Dashboard';

        } else if (this.selectedTab == 'order') {

            options = 'dtOptions3';
            url = 'https://analytics-web.moglix.com/freq_report/kam_order'
            report_name += 'Order Fulfillment Dashboard';

        } else if (this.selectedTab == 'business') {
            options = 'dtOptions2';
            url = 'https://analytics-web.moglix.com/freq_report/kam_business'
            report_name += 'Business Performance Dashboard';


        } else if (this.selectedTab == 'category') {
            options = 'dtOptions4';
            url = 'https://analytics-web.moglix.com/freq_report/kam_category'

            report_name += 'Category Level View Dashboard';

        }
        this.insightsService.postData(url, payload).subscribe((resp: any) => {

            if (this.selectedTab == 'business' || this.selectedTab == 'order' || this.selectedTab == 'category') {
                resp['data']['data'] = resp['data']['result'];
            }
            this.smeFinanceObj = this.insightsService.post(resp, this.selectedTab);
            if (this.smeFinanceObj['table']) {
                let reportRequest = { 'username': this.userId_val, 'report_name': report_name };
                this.appService.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
                })
                if (this.selectedTab == 'rfq') {
                    //let parentthHeader = '<thead><tr style="background-color:#6F6F6F;color:white;"><th colspan="2" style="position:fixed;width:635px;height:18px;"></th><th colspan="5"></th><th colspan="4">MTD</th><th colspan="2">Last 7 Days</th><th colspan="3">Last Month</th><th colspan="3">Last Quarter</th><th colspan="5"></th></tr></thead>';
                    //parentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + parentthHeader + '</tr>'
                    // this.dtOptions["headerCallback"] = function (thead, data, display) {
                    //     $(thead).before(parentthHeader)

                    // }

                    this.tableDisplay = true;
                    this.openFilters = false;
                    this.dtOptions = this.smeFinanceObj['table'];
                }
                else if (this.selectedTab == 'order') {
                    let oparentthHeader = '<thead><tr style="background-color:#6F6F6F;color:white;"><th colspan="2" style="position:fixed;width:635px;height:18px;"></th><th colspan="5"></th><th colspan="4">MTD</th><th colspan="2">Last 7 Days</th><th colspan="3">Last Month</th><th colspan="3">Last Quarter</th><th colspan="5"></th></tr></thead>';
                    oparentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + oparentthHeader + '</tr>'

                    this.dtOptions2["headerCallback"] = function (thead, data, start, end, display) {
                        $(thead).before(oparentthHeader)

                    }
                    this.dtOptions2 = this.smeFinanceObj['table'];
                    this.tableDisplay = true;
                    this.openFilters = false;
                }
                else if (this.selectedTab == 'business') {
                    let parentthHeader = '<thead><tr style="background-color:#6F6F6F;color:white;"><th colspan="2" style="position:fixed;width:635px;height:18px;"></th><th colspan="5"></th><th colspan="4">MTD</th><th colspan="2">Last 7 Days</th><th colspan="3">Last Month</th><th colspan="3">Last Quarter</th><th colspan="5"></th></tr></thead>';
                    parentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + parentthHeader + '</tr>'
                    this.dtOptions3["headerCallback"] = function (thead, data, display) {
                        $(thead).before(parentthHeader)
                    }
                    this.dtOptions3 = this.smeFinanceObj['table'];
                    this.tableDisplay = true;
                    this.openFilters = false;
                }
                else if (this.selectedTab == 'category') {
                    let parentthHeader = '<thead><tr style="background-color:#6F6F6F;color:white;"><th colspan="2"></th><th colspan="2">MTD</th><th colspan="2">Last 7 Days</th><th colspan="2">Last Month</th><th colspan="2">Last Quarter</th></tr></thead>';
                    parentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + parentthHeader + '</tr>'
                    this.dtOptions4["headerCallback"] = function (thead, data, display) {
                        $(thead).before(parentthHeader)
                    }
                    this.dtOptions4 = this.smeFinanceObj['table'];
                    this.tableDisplay = true;
                    this.openFilters = false;
                }

                sessionStorage.removeItem('timer');
            }
        })
    }

    //data:any, type:any,full:any,
    detailedReport = (filtertype_name: any, filtertype: any, filter_stage: any, grouping_col_name: any, row: string, col: string) => {

        this.excelDtOption = false;
        this.excelTable = false;
        this.dtoptionexcel1 = {};
        this.excelData1 = [];
        let filter_stage_type = '';


        if (this.selectedTab == 'rfq') {

            if (filtertype_name == 'Total') {
                filtertype = 'Total';
            }

            if (filtertype_name != 'Total') {
                filtertype = 'Sourcer';
            }
            if (col.includes("CurrMonth")) {
                filter_stage = 'MTD';
            }
            if (col.includes("LastMonth")) {
                filter_stage = 'LastMonth';
            }
            if (col.includes("SixMonth")) {
                filter_stage = 'Last 6 months';
            }

            if (col.includes("Quoted")) {
                filter_stage_type = 'Quoted';
            }
            if (col.includes("Convered")) {
                filter_stage_type = 'Converted';
            }


            let urldetailed = environment.baseURL_FREQ+"kam_rfq_detailed";
            //let urldetailed  ='http://10.8.1.69:5026/freq_report/kam_rfq_detailed';



            this.rfq_detailedRequestBody = {
                "filter_stage": filter_stage,
                "filter_stage_type": filter_stage_type,
                "grouping_col_name": grouping_col_name,
                "filtertype": filtertype,
                "filtertype_name": filtertype_name,
                "username": this.userId_val,
                "teams": this.selectedTeams,
                "customers": this.selectedCustomers,
                "plants": this.selectedPlants,
                "sourcers": this.selectedSourcers,
                "categories": this.selectedL1,
                "grouping_col": this.grouping_col
            };
            this.insightsService.postData(urldetailed, this.rfq_detailedRequestBody).subscribe((resp: any) => {
                this.excelData1 = this.insightsService.postDetail(resp, this.selectedTab);
                this.excelDtOption = this.excelData1.table;
                this.excelData2 = this.excelData1.excel;
                return this.Service.exportAsExcelFile(this.excelData2, 'rfq');
            })
        }


        if (this.selectedTab == 'order') {
            let urldetailed = '';

            if (col.includes("OTIF Final CDD MTD%")) {
                filter_stage = 'MTD';
                urldetailed = environment.baseURL_FREQ+"kam_order_otif";

            }
            if (col.includes("OTIF Final CDD Last week%")) {
                filter_stage = 'Last week';
                urldetailed = environment.baseURL_FREQ+"kam_order_otif";
            }
            if (col.includes("OTIF Final CDD Last Month%")) {
                filter_stage = 'Last Month';
                urldetailed = environment.baseURL_FREQ+"kam_order_otif";
            }

            if (col.includes("CDD1 Crossed Line items") || col.includes('CDD1 Crossed Value')) {
                filter_stage = 'Last Month';
                urldetailed = environment.baseURL_FREQ+"kam_order_cdd_missed";
            }

            if (col.includes("CPO-SPO Pending")) {
                filter_stage = 'Last Month';
                urldetailed = environment.baseURL_FREQ+"kam_order_cpo_to_spo_detailed";
            }

            if (col.includes('POD pending')) {

                urldetailed = environment.baseURL_FREQ+"kam_business_pod_pendancy_detailed";

            }
            this.order_detailedRequestBody = {
                "filter_stage": filter_stage,
                "grouping_col_name": grouping_col_name,
                "filtertype": filtertype,
                "filtertype_name": filtertype_name,
                "username": this.userId_val,
                "teams": this.selectedTeams,
                "customers": this.selectedCustomers,
                "plants": this.selectedPlants,
                "sourcers": this.selectedSourcers,
                "categories": this.selectedL1,
                "grouping_col": this.grouping_col
            };

            this.insightsService.postData(urldetailed, this.order_detailedRequestBody).subscribe((resp: any) => {
                this.excelData_order = this.insightsService.postDetail(resp, this.selectedTab);
                this.excelDtOption_order = this.excelData_order.table;
                this.excelData_order = this.excelData_order.excel;
                return this.Service.exportAsExcelFile(this.excelData_order, 'order');
            })
        }

        if (this.selectedTab == 'business') {
            let urldetailed = '';

            this.currentdateValue = moment(this.currentDate).format('YYYY-MM');

            if (col.includes("PO in hand")) {

                urldetailed = environment.baseURL_FREQ+"kam_business_po_in_hand_detailed";
                this.business_detailedRequestBody = {
                    "filter_stage": filter_stage,
                    "grouping_col_name": grouping_col_name,
                    // "filtertype": filtertype,
                    // "filtertype_name": filtertype_name,
                    "username": this.userId_val,
                    "teams": this.selectedTeams,
                    "customers": this.selectedCustomers,
                    "plants": this.selectedPlants,
                    "sourcers": this.selectedSourcers,
                    "categories": this.selectedL1,
                    "grouping_col": this.grouping_col
                };

                this.insightsService.postData(urldetailed, this.business_detailedRequestBody).subscribe((resp: any) => {
                    this.excelData_business = this.insightsService.postDetail(resp, this.selectedTab);
                    this.excelDtOption_business = this.excelData_business.table;
                    this.excelData_business = this.excelData_business.excel;
                    return this.Service.exportAsExcelFile(this.excelData_business, 'BusinessPOinHand');
                })
            }
            if (col.includes('MTD GMV')) {
                this.business_detailedRequestBody = {}

                let urldetailed = environment.baseURL_FREQ+"team_summary";
                this.business_detailedRequestBody = { team: grouping_col_name, YM: this.currentdateValue, intervalue: 1 }
                this.insightsService.postData(urldetailed, this.business_detailedRequestBody).subscribe((resp: any) => {
                    this.excelData_business = this.insightsService.postDetail(resp, this.selectedTab);
                    this.excelDtOption_business = this.excelData_business.table;
                    this.excelData_business = this.excelData_business.excel;

                    return this.Service.exportAsExcelFile(this.excelData_business, 'BusinessMTDInvoiced');

                })
            }


            if (col.includes('MTD GM%')) {
                this.business_detailedRequestBody = {}
                let urldetailed = environment.baseURL_FREQ+"team_summary";
                this.business_detailedRequestBody = { team: grouping_col_name, YM: this.currentdateValue, intervalue: 1 }
                this.insightsService.postData(urldetailed, this.business_detailedRequestBody).subscribe((resp: any) => {
                    this.excelData_business = this.insightsService.postDetail(resp, this.selectedTab);
                    this.excelDtOption_business = this.excelData_business.table;
                    this.excelData_business = this.excelData_business.excel;
                    return this.Service.exportAsExcelFile(this.excelData_business, 'BusinessMTDGM');
                })
            }
            if (col.includes('GMV')) {
                this.business_detailedRequestBody = {}
                let urldetailed = environment.baseURL_FREQ+"kam_business_gmv_detailed";
                this.business_detailedRequestBody = {
                    "filter_stage": filter_stage,
                    "grouping_col_name": grouping_col_name,
                    // "filtertype": filtertype,
                    // "filtertype_name": filtertype_name,
                    "username": this.userId_val,
                    "teams": this.selectedTeams,
                    "customers": this.selectedCustomers,
                    "plants": this.selectedPlants,
                    "sourcers": this.selectedSourcers,
                    "categories": this.selectedL1,
                    "grouping_col": this.grouping_col
                }; this.insightsService.postData(urldetailed, this.business_detailedRequestBody).subscribe((resp: any) => {
                    this.excelData_business = this.insightsService.postDetail(resp, this.selectedTab);
                    this.excelDtOption_business = this.excelData_business.table;
                    this.excelData_business = this.excelData_business.excel;
                    return this.Service.exportAsExcelFile(this.excelData_business, 'BusinessMTDGM');
                })
            }
            if (col.includes('Returns')) {
                this.business_detailedRequestBody = {}

                let urldetailed = environment.baseURL_FREQ+"kam_business_returns_detailed";
                this.business_detailedRequestBody = {
                    "filter_stage": filter_stage,
                    "grouping_col_name": grouping_col_name,
                    // "filtertype": filtertype,
                    // "filtertype_name": filtertype_name,
                    "username": this.userId_val,
                    "teams": this.selectedTeams,
                    "customers": this.selectedCustomers,
                    "plants": this.selectedPlants,
                    "sourcers": this.selectedSourcers,
                    "categories": this.selectedL1,
                    "grouping_col": this.grouping_col
                };
                this.insightsService.postData(urldetailed, this.business_detailedRequestBody).subscribe((resp: any) => {
                    this.excelData_business = this.insightsService.postDetail(resp, this.selectedTab);
                    this.excelDtOption_business = this.excelData_business.table;
                    this.excelData_business = this.excelData_business.excel;
                    return this.Service.exportAsExcelFile(this.excelData_business, 'BusinessMTDReturns');
                })
            }


        }

        return true;

    }




    excelDownloadcell(event) {

        event.preventDefault();
        var self = this;
        let expressionVal = event.target.className;

        $('#example > tbody > tr').off("click").on('click', 'td:(#fa fa-download)', function (e) {
            // e.preventDefault();
            let table = $('#example').DataTable();
            let idx = table.cell(this).index().column;
            let rowIdx = table.cell(this).index().row;
            let title = table.column(idx).header();
            let selectedRowValue = table.cell(rowIdx, 0).data();
            let filtertype_name = table.cell(rowIdx, 1).data();

            let filter_stage = table.column(idx).header();

            let selectedColumnValue = $(title).html();
            let grouping_col_name = selectedRowValue;
            let cell = table.cell(this);

            let FirstColumntitle = table.column(0).header();
            let filtertype = $(FirstColumntitle).html();
            
            let FirstColumntitleValue = $(FirstColumntitle).html();
              if(idx != 0 && idx != 1 && idx != 4 && idx != 9 && idx != 11 && idx != 12 && idx != 16 && idx != 21 && idx != 23 && idx != 24 && idx != 26){
                self.detailedReport(filtertype_name, filtertype, filter_stage, grouping_col_name, selectedRowValue, selectedColumnValue);
            }
            
        });
    }
    excelDownloadcellorder(event) {

        event.preventDefault();
        var self = this;
        let expressionVal = event.target.className;


        $('#example1 > tbody > tr').off("click").on('click', 'td:(#fa fa-download)', function (e) {
            // e.preventDefault();
            let table = $('#example1').DataTable();
            let idx = table.cell(this).index().column;
            let rowIdx = table.cell(this).index().row;
            let title = table.column(idx).header();
            let selectedRowValue = table.cell(rowIdx, 0).data();
            let filtertype_name = table.cell(rowIdx, 1).data();

            let filter_stage = table.column(idx).header();

            let selectedColumnValue = $(title).html();
            let grouping_col_name = selectedRowValue;
            let cell = table.cell(this);

            let FirstColumntitle = table.column(0).header();
            let filtertype = $(FirstColumntitle).html();

            let FirstColumntitleValue = $(FirstColumntitle).html();
            if(idx  != 0){
            self.detailedReport(filtertype_name, filtertype, filter_stage, grouping_col_name, selectedRowValue, selectedColumnValue);
            }
        });
    }
    excelDownloadcellBusiness(event) {

        event.preventDefault();
        var self = this;
        let expressionVal = event.target.className;


        $('#exampleBusiness > tbody > tr').off("click").on('click', 'td:(#fa fa-download)', function (e) {

            let table = $('#exampleBusiness').DataTable();
            let idx = table.cell(this).index().column;
            let rowIdx = table.cell(this).index().row;
            let title = table.column(idx).header();
            let selectedRowValue = table.cell(rowIdx, 0).data();
            let filtertype_name = table.cell(rowIdx, 1).data();

            let filter_stage_Type = table.column(idx).header();
            let filter_stage = ''
            if (filter_stage_Type.innerText.includes('MTD')) {
                filter_stage = 'MTD'
            }

            if (filter_stage_Type.innerText.includes('Last Month')) {
                filter_stage = 'Last Month'
            }

            if (filter_stage_Type.innerText.includes('Last week')) {
                filter_stage = 'Last week'
            }

            if (filter_stage_Type.innerText.includes('Last Quarter')) {
                filter_stage = 'Last Quarter'
            }

            let selectedColumnValue = $(title).html();
            let grouping_col_name = selectedRowValue;
            let cell = table.cell(this);

            let FirstColumntitle = table.column(0).header();
            let filtertype = $(FirstColumntitle).html();

            let FirstColumntitleValue = $(FirstColumntitle).html();
            if(idx  != 0){
            self.detailedReport(filtertype_name, filtertype, filter_stage, grouping_col_name, selectedRowValue, selectedColumnValue);
            }
        });
    }
}
