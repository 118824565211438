export const environment = {

  // apiBaseURL: 'https://analytics-web.moglix.com/',
  // baseURL_FREQ: "https://analytics-web.moglix.com/freq_report/",
  // //Enterprise Scorecard 
  // enterprise_baseURL: 'https://analyticsapi.moglix.com/highcharts',
  // sme_baseURL: 'https://analyticsapi.moglix.com/highcharts',
  // analytics_baseURL: 'https://analytics.moglix.com/',
  // analyticsapi_baseURL_highcharts: 'https://analyticsapi.moglix.com/highcharts',
  // pendencyTracker_baseURL: 'https://analytics.moglix.com/entreprise_cs/',
  // analyticsmoglilabs:'https://analytics.moglilabs.com/',
  



  apiBaseURL: 'https://analytics-web.moglix.com/',
  baseURL_FREQ: "https://analytics-web.moglix.com/freq_report/",
  baseURL_ItemTracking: "https://analytics-web.moglix.com/item_tracking/",
  analyticsapi_baseURL_highcharts:'https://analyticsapi.moglix.com/highcharts',
  analytics_baseURL: 'https://analytics.moglix.com/',
  analytics_baseURL_web_labs: 'https://analytics-web.moglix.com',
  analysis_baseurl:'https://analysis.moglix.com/',
  //user_tracking_api:'https://analytics-web.moglix.com/user_tracking/add',
  user_tracking_api : 'https://analytics-web.moglix.com/freq_report/user_tracking/add',
  clientID: '839829923004-kabr8gskohmtqakg4i2hr574undc8l9m.apps.googleusercontent.com',
  microClientID : '829f23a7-49b2-4e23-9169-27f200bbcd88',
  microTenantID : '421e9584-87ff-424f-95b8-1bf46b70db99'

};