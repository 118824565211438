import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-tracking-otif-detailed',
  templateUrl: './item-tracking-otif-detailed.component.html',
  styleUrls: ['./item-tracking-otif-detailed.component.css']
})
export class ItemTrackingOTIFDetailedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
