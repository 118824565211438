import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { all } from 'q';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const fur_baseURL: string = environment.baseURL_FREQ; 

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  tableCondition = false;
  timestampval="";


  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  //BASEURL: string = 'https://analytics-web.moglilabs.com/freq_report';

  constructor(private http:HttpClient) { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName +  EXCEL_EXTENSION);
  }
  saveInfo(urlpath,data){
        
    return this.http.post(fur_baseURL+urlpath,data)
  }
  getInfo(urlpath){
    return this.http.get(fur_baseURL+urlpath)
  }

  post(finalpostObj) {


     
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.tableCondition = false;
        this.timestampval=finalpostObj['dateval'];
      this.headers = finalpostObj['data']['headers'];
      this.finalresult = finalpostObj['data']['data1'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);

      if (this.finalDataObject)
        return this.finalDataObject
    

  }
  get(finalpostObj) {


     
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.tableCondition = false;
      this.timestampval=finalpostObj['dateval'];
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['data1'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);

    if (this.finalDataObject)
      return this.finalDataObject
  

}
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key]
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject).toLocaleString;
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }
    this.dtOptions = {
      responsive: true,
      autoWidth:false,
      scrollX: true,
      scrollCollapse:true,
      scrollY: '100vh',
      //pageLength:0,
     paging:false,
      //info: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">' ,
      ColumnDefs: [
        {filter: true,targets:[0,1,2]}
      ],
      language:{
         
        
     "lengthMenu": "",
     //"zeroRecords": "",
     
     "infoEmpty": "",
     "infoFiltered": ""
      }
    };
    if (this.dtOptions) {
      this.tableCondition = true;
    }
    return this.dtOptions;
  }
  exportAsXLSXs(){
  this.exportAsExcelFile(this.responseListExcel, 'sample');
}
}
