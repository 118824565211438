import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { HttpService } from 'src/app/http.service';
import { AppService } from 'src/app/services/app.service';
import { Buscorecardtop20Service } from 'src/app/services/buscorecardtop20.service';
import { CommonService } from 'src/app/shared/common.service';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-bu-reports-scorecards',
  templateUrl: './bu-reports-scorecards.component.html',
  styleUrls: ['./bu-reports-scorecards.component.css']
})
export class BUReportsScorecardsComponent implements OnInit {
  navbarbu = [];
  constructor(private router: Router, private navbar: NavbarComponent, private commonService: CommonService,
    private buService: Buscorecardtop20Service,
    private userId: AppService,
    private httpService: HttpService,
    public itESservice: ItemTrackingESService,
    private ngZone: NgZone,
    private toaster: ToastrService) {
    //this.navbarbu = this.navbar['navbarBU'][0];
    this.navbarbu = []
    this.navbarbu = [
      {"link": "/regionBU", "show": true, "label": "Region Scorecard"},
      {"link":"/butop20","show":true, "label":"Top 20 Customers"},
      {"link":"/bridgeBU", "show":true,"label":"Bridge"}]
    console.log(this.navbarbu);
    this.getVisual();
    }
  ngOnInit() {
  }
  getVisual() {

    for(let y=0;y<this.navbarbu.length;y++){
      if(this.navbarbu[y]['show']==true){
        this.router.navigate([this.navbarbu[y]['link']]);
        break;
      }
    }
    
  
  }
}
