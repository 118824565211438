import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';
import { Buscorecardtop20Service } from 'src/app/services/buscorecardtop20.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-butop20',
  templateUrl: './butop20.component.html',
  styleUrls: ['./butop20.component.css']
})
export class Butop20Component implements OnInit {
  userId_val:any;
  requestbody = {};
  dtOptions: DataTables.Settings = {};
  final ={};
  navbarbu =[];
  selected='/butop20';
  currentactiveTab: any;
  
  
  public bsValue: any = new Date();
  public date = new Date();
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  
  minDate: Date;
  currentMonth = this.date.getMonth();
  currentDate = this.date.getDate();
  currentYear = this.date.getFullYear();
  maxDate: Date;
  
  tableDisplay = false;
  selectedDate: any;
  constructor(private router: Router,private navbar:NavbarComponent,private butop20service:Buscorecardtop20Service,private userId: AppService) { 
    this.userId_val =  this.userId.userDetails.email;
    //this.navbarbu = this.navbar['navbarBU'][0];
    this.navbarbu = [
      {"link": "/regionBU", "show": true, "label": "Region Scorecard"},
      {"link":"/butop20","show":true, "label":"Top 20 Customers"},
      {"link":"/bridgeBU", "show":true,"label":"Bridge"}]
    console.log(this.navbarbu);
  }

  ngOnInit() {
     this.minDate= new Date(this.currentYear, this.currentMonth-5, this.currentDate);
    this.maxDate = new Date();
    console.log(this.navbarbu);

    let date = new Date();
    let months = [],
    monthNames = [ "January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    for(var i = 0; i < 12; i++) {
        months.push({value: monthNames[date.getMonth()] + ' ' + date.getFullYear(), id: {month: date.getMonth(), year: date.getFullYear()} });
        date.setMonth(date.getMonth() - 1);
    }
    this.selectedDate = moment(this.bsValue).format('YYYY-MM');
  console.log(this.selectedDate)
    this.getDataByFilter();

  }
  isActive(e_menu_link,idx) {
    return this.selected === e_menu_link;
  }
  getVisual(e_menu_link){
    this.selected = e_menu_link; 
    this.currentactiveTab = e_menu_link;
       switch (e_menu_link) {
      case '/regionBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);

        this.getvisualTable(this.selected);
        break;
      }
      case '/bridgeBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);
        this.getvisualTable(this.selected);
        break;
      }
      case '/butop20': {
        this.selected=e_menu_link;
        this.getDataByFilter();
        this.router.navigate([this.selected]);
        this.getvisualTable(this.selected);
        break;
      }
    }
  }
  getvisualTable(menu){
      
  }
  getDataByFilter() {
      this.dtOptions = {};
      this.tableDisplay = false;
      this.selectedDate = moment(this.bsValue).format('YYYY-MM');
      
      this.requestbody ={'YM':this.selectedDate,'username':this.userId_val};
      console.log(this.requestbody);
      this.butop20service.saveInfo(environment.baseURL_FREQ+'top_customers', this.requestbody).subscribe((data) => {
        
        let k=0
        let headerCheck = data['data']['headers'];
        let arrayCPcolor =[];
        let arrayCP = data['data']['result'];
        for (let l = 0; l < arrayCP.length; l++) {
          let temprow = {};
     

          for(let h=0;h<headerCheck.length;h++){
            let titleText = arrayCP[l][headerCheck[h]];
            if(headerCheck[h] == 'GMV (Curr)' && headerCheck[h+1] == 'GMV (Prev)'){
              if(arrayCP[l][headerCheck[h]] > arrayCP[l][headerCheck[h+1]]){
                  temprow[headerCheck[h]] = '<b style="color:Green">'+arrayCP[l][headerCheck[h]]+'</b>'
              }
              if((arrayCP[l][headerCheck[h]] < arrayCP[l][headerCheck[h+1]])){
                temprow[headerCheck[h]] = '<b style="color:Red">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == arrayCP[l][headerCheck[h+1]]){
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] != '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:green">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]!='-')
              {
                temprow[headerCheck[h]] = '<b style="color:red">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }

            
            }
            if(headerCheck[h] == 'Net Sales (Curr)' && headerCheck[h+1] == 'Net Sales (Prev)'){
              if(arrayCP[l][headerCheck[h]] > arrayCP[l][headerCheck[h+1]]){
                  temprow[headerCheck[h]] = '<b style="color:Green">'+arrayCP[l][headerCheck[h]]+'</b>'     
               
              }
              if((arrayCP[l][headerCheck[h]] < arrayCP[l][headerCheck[h+1]])){
                temprow[headerCheck[h]] = '<b style="color:Red">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == arrayCP[l][headerCheck[h+1]]){
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] != '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:green">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]!='-')
              {
                temprow[headerCheck[h]] = '<b style="color:red">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }

            
            }
            if(headerCheck[h] == 'GM (Curr)' && headerCheck[h+1] == 'GM (Prev)'){
              if(arrayCP[l][headerCheck[h]] > arrayCP[l][headerCheck[h+1]]){
                  temprow[headerCheck[h]] = '<b style="color:Green">'+arrayCP[l][headerCheck[h]]+'</b>'     
               
              }
              if((arrayCP[l][headerCheck[h]] < arrayCP[l][headerCheck[h+1]])){
                temprow[headerCheck[h]] = '<b style="color:Red">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == arrayCP[l][headerCheck[h+1]]){
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] != '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:green">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]!='-')
              {
                temprow[headerCheck[h]] = '<b style="color:red">'+arrayCP[l][headerCheck[h]]+'</b>'   

              }

            
            }
            if(headerCheck[h] == '%GM (Curr)' && headerCheck[h+1] == '%GM (Prev)'){
              if(arrayCP[l][headerCheck[h]] > arrayCP[l][headerCheck[h+1]]){
                  temprow[headerCheck[h]] = '<b style="color:Green">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'     
               
              }
              if((arrayCP[l][headerCheck[h]] < arrayCP[l][headerCheck[h+1]])){
                temprow[headerCheck[h]] = '<b style="color:Red">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == arrayCP[l][headerCheck[h+1]]){
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'   
              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:black">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'   

              }
              if(arrayCP[l][headerCheck[h]] != '-' && arrayCP[l][headerCheck[h+1]]=='-')
              {
                temprow[headerCheck[h]] = '<b style="color:green">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'
              }
              if(arrayCP[l][headerCheck[h]] == '-' && arrayCP[l][headerCheck[h+1]]!='-')
              {
                temprow[headerCheck[h]] = '<b style="color:red">'+arrayCP[l][headerCheck[h]]+'%'+'</b>'   

              }
                      
            }
            if(headerCheck[h] == 'Region' || headerCheck[h] == 'Customer Name' ||
             headerCheck[h] == 'GM (Prev)' || headerCheck[h] == 'Net Sales (Prev)'
             || headerCheck[h] == 'GMV (Prev)' ){
               if(arrayCP[l][headerCheck[h]].length>12)
                temprow[headerCheck[h]] = '<span data-toggle="tooltip" title="'+titleText+'">'+arrayCP[l][headerCheck[h]].substring(0, 10)+'...'+'</span>'
                else{
                  temprow[headerCheck[h]] = arrayCP[l][headerCheck[h]];
                }
          }
            if(headerCheck[h] == '%GM (Prev)')
              temprow[headerCheck[h]] = arrayCP[l][headerCheck[h]]+'%';       
            }
          arrayCPcolor[k++] = temprow;
          
         
  
        }
        data['data']['result'] = arrayCPcolor;
        this.final =this.butop20service.posttop20(data)
    
      if (this.final['table']) {
this.userId.saveInfo(environment.user_tracking_api,{'username':this.userId_val,'report_name':'BU Top 20 Customers'}).subscribe(reportdata=>{
          console.log(reportdata);
        })

        this.dtOptions = this.final['table'];
        if (this.dtOptions != null) {
          this.tableDisplay = true;
         
        }
      }
 })
    }
}
