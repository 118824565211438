import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getReportData(payload) {
    return this.http.post('https://analysis.moglix.com/apm/dashboard', payload);
  }

  getPlantsData(payload) {
    return this.http.post(environment.baseURL_FREQ+'client_plant_mapping', payload);
  }

  getTeamsData(payload) {
    return this.http.post(environment.baseURL_FREQ+'vertical_region_team_mapping', payload);
  }
}
