import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  sendPostRequest(url: string, body: any, headers: any = {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, body, { headers }).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  sendGetRequest(url: string, headers: any = {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, { headers }).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
}
