import { environment } from './../../environments/environment';
import { Options } from 'highcharts';
import { Injectable, OnChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfig } from '../helpers/urlConfig';



const fur_baseURL: string = environment.baseURL_FREQ;

@Injectable({
  providedIn: 'root'
})
export class MgmtService {

  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };

  timestampval = "";
  timestampval2 = "";
  timestampval3 = "";
  timestampval4 = "";
  headers2: any[];
  headers3: any[];
  headers4: any[];
  detailedheader: any[];

  finalresult2: any[];
  finalresult3: any[];
  finalresult4: any[];
  detailedfinalresult: any[];

  dtOptions2: {};
  dtOptions3: {};
  dtOptions4: {};
  detaileddtOptions: {};

  responseList2: any[];
  responseList3: any[];
  responseList4: any[];
  detailedresponseList: any[];

  responseListExcel2 = [];
  responseListExcel3 = [];
  responseListExcel4 = [];
  detailedresponseListExcel = [];

  public finalDataObject2 = { table: {}, excel: [] };
  public finalDataObject3 = { table: {}, excel: [] };
  public finalDataObject4 = { table: {}, excel: [] };
  public detailedfinalDataObject = { table: {}, excel: [] };

  headers1: any[];
  finalresult1: any[];

  detailedheaders: any = [];
  dtOptions1: {};
  responseList1: any[];
  responseListExcel1: any[];
  finalDataObject1: any;
  headerspickup: any[];
  finalresultpickup: any[];
  dtOptionspickup: {};
  responseListpickup: any[];
  responseListExcelpickup: any[];
  finalDataObjectpickup: any;
  val = 0;
  val1: any;
  val2; any;
  val3: any;
  val4: any;
  valpickup: any;
  service_mgscorecard_month: string;
  service_mg_osod: string;
  constructor(private http: HttpClient) { }
  saveInfo(urlpath) {
    return this.http.get(urlpath)
  }

  setmgmt_scorecard_Month(mgscorecard_month){
    this.service_mgscorecard_month = mgscorecard_month;
 
  }
  setmgmt_osod_Month(mg_osod){
    this.service_mg_osod = mg_osod;
  } 
  getTeamRegion(teamRegionURL, TeamRegionList) {
    return this.http.post(environment.baseURL_FREQ+UrlConfig.accessMapping, TeamRegionList);
  }

  postpickup(finalpostObj, val) {
    if (val == -1) {

      this.valpickup = val;
      this.headerspickup = [];
      this.finalresultpickup = [];
      this.dtOptionspickup = {};
      this.responseListpickup = [];
      this.responseListExcelpickup = [];
      this.headerspickup = finalpostObj['data']['headers'];
      this.finalresultpickup = finalpostObj['data']['result'];
      this.finalDataObjectpickup = this.sortedResponseListpickup(this.finalresultpickup);

    }

    return this.finalDataObjectpickup;
  }
  post(finalpostObj, val) {
    if (val == 0) {

      this.val = val;
      this.headers = [];
      this.finalresult = [];
      this.dtOptions = {};
      this.responseList = [];
      this.responseListExcel = [];
      this.headers = finalpostObj['data']['headers'];
      this.finalresult = finalpostObj['data']['result'];
      this.finalDataObject = this.sortedResponseList(this.finalresult);

    }


    return this.finalDataObject;
  }
  postDetailed(finalpostObj, val) {
    if (val == 1) {
      this.val = 1;
      this.detailedheader = [];
      this.detailedfinalresult = [];
      this.detaileddtOptions = {};
      this.detailedresponseList = [];
      this.detailedresponseListExcel = [];
      this.detailedheaders = finalpostObj['data']['headers'];

      console.log(this.detailedheaders);
      this.detailedfinalresult = finalpostObj['data']['data'];
     
      if (this.detailedfinalresult != null)
        this.detailedfinalDataObject = this.sortedResponseListdetailed(this.detailedfinalresult);
    }
    return this.detailedfinalDataObject;
  }

  post1(finalpostObj, val) {
    if (val == 1) {
      this.val1 = val;
      this.headers1 = [];
      this.finalresult1 = [];
      this.dtOptions1 = {};
      this.responseList1 = [];
      this.responseListExcel1 = [];
      this.headers1 = finalpostObj['data']['headers'];
      this.finalresult1 = finalpostObj['data']['result'];
      this.finalDataObject = this.sortedResponseList1(this.finalresult1);
    }
    return this.finalDataObject1;
  }


  post2(finalpostObj, val) {

    if (val == 2) {
      this.val2 = val;
      this.headers2 = [];
      this.finalresult2 = [];
      this.dtOptions2 = {};
      this.responseList2 = [];
      this.responseListExcel2 = [];
      this.headers2 = finalpostObj['data']['headers'];
      this.finalresult2 = finalpostObj['data']['result'];
      this.finalDataObject2 = this.sortedResponseList2(this.finalresult2);
    }
    return this.finalDataObject2;
  }

  post3(finalpostObj, val) {

    if (val == 3) {
      this.val3 = val;
      this.headers3 = [];
      this.finalresult3 = [];
      this.dtOptions3 = {};
      this.responseList3 = [];
      this.responseListExcel3 = [];
      this.headers3 = finalpostObj['data']['headers'];
      this.finalresult3 = finalpostObj['data']['result'];
      this.finalDataObject3 = this.sortedResponseList3(this.finalresult3);
    }


    return this.finalDataObject3;
  }


  post4(finalpostObj, val) {
    if (val == 4) {
      this.val4 = val;
      this.headers4 = [];
      this.finalresult4 = [];
      this.dtOptions4 = {};
      this.responseList4 = [];
      this.responseListExcel4 = [];
      this.headers4 = finalpostObj['data']['headers'];
      this.finalresult4 = finalpostObj['data']['result'];
      this.finalDataObject4 = this.sortedResponseList4(this.finalresult4);
    }
    return this.finalDataObject4;
  }
  sortedResponseListpickup(finalresultpickup) {

    for (var i in finalresultpickup) {
      let objpickup = finalresultpickup[i];
      let orderObjectpickup = {};
      let orderobjectwithoutcommapickup = {}
      for (var hpickup in this.headerspickup) {
        for (const keypickup of Object.keys(objpickup)) {
          if (this.headerspickup[hpickup] == keypickup) {

            orderObjectpickup[keypickup] = objpickup[keypickup].toLocaleString();
            if (orderObjectpickup[keypickup].replace(/\s/g, "") == "") {

              orderObjectpickup[keypickup] = objpickup[keypickup].toLocaleString();
            }
            else {
              if (isNaN(orderObjectpickup[keypickup]))
                orderObjectpickup[keypickup] = objpickup[keypickup].toLocaleString();
              else {
                orderObjectpickup[keypickup] = objpickup[keypickup].toLocaleString();
              }
            }
            orderobjectwithoutcommapickup[keypickup] = objpickup[keypickup];
          }
        }
      }
      this.responseListpickup.push(orderObjectpickup);
      this.responseListExcelpickup.push(orderobjectwithoutcommapickup);

    }
    if (this.responseListpickup)
      this.dtOptionspickup = this.tabledatapickup(this.responseListpickup);
    if (this.responseListpickup && this.responseListExcelpickup)
      this.finalDataObjectpickup = { table: this.dtOptionspickup, excel: this.responseListExcelpickup };
    return this.finalDataObjectpickup;
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }


  sortedResponseList1(finalresult1) {

    for (var i in finalresult1) {
      let obj1 = finalresult1[i];
      let orderObject1 = {};
      let orderobjectwithoutcomma1 = {}
      for (var h1 in this.headers1) {
        for (const key1 of Object.keys(obj1)) {
          if (this.headers1[h1] == key1) {

            orderObject1[key1] = obj1[key1].toLocaleString();
            if (orderObject1[key1].replace(/\s/g, "") == "") {

              orderObject1[key1] = obj1[key1].toLocaleString();
            }
            else {
              if (isNaN(orderObject1[key1]))
                orderObject1[key1] = obj1[key1].toLocaleString();
              else {
                orderObject1[key1] = obj1[key1].toLocaleString();
              }
            }
            orderobjectwithoutcomma1[key1] = obj1[key1];
          }
        }
      }
      this.responseList1.push(orderObject1);
      this.responseListExcel1.push(orderobjectwithoutcomma1);

    }
    if (this.responseList1)
      this.dtOptions1 = this.tabledata1(this.responseList1);
    if (this.responseList1 && this.responseListExcel1)
      this.finalDataObject1 = { table: this.dtOptions1, excel: this.responseListExcel1 };
 
    return this.finalDataObject1;
  }

  sortedResponseList2(finalresult2) {

    for (var i in finalresult2) {
      let obj2 = finalresult2[i];
      let orderObject2 = {};
      let orderobjectwithoutcomma2 = {}
      for (var h2 in this.headers2) {
        for (const key2 of Object.keys(obj2)) {
          if (this.headers2[h2] == key2) {

            orderObject2[key2] = obj2[key2].toLocaleString();
            if (orderObject2[key2].replace(/\s/g, "") == "") {

              orderObject2[key2] = obj2[key2].toLocaleString();
            }
            else {
              if (isNaN(orderObject2[key2]))
                orderObject2[key2] = obj2[key2].toLocaleString();
              else {
                orderObject2[key2] = obj2[key2].toLocaleString();
              }
            }
            orderobjectwithoutcomma2[key2] = obj2[key2];
          }
        }
      }
      this.responseList2.push(orderObject2);
      this.responseListExcel2.push(orderobjectwithoutcomma2);

    }
    if (this.responseList2)
      this.dtOptions2 = this.tabledata2(this.responseList2);
    if (this.responseList2 && this.responseListExcel2)
      this.finalDataObject2 = { table: this.dtOptions2, excel: this.responseListExcel2 };
    return this.finalDataObject2;
  }
  sortedResponseList3(finalresult3) {

    for (var i in finalresult3) {
      let obj3 = finalresult3[i];
      let orderObject3 = {};
      let orderobjectwithoutcomma3 = {}
      for (var h3 in this.headers3) {
        for (const key3 of Object.keys(obj3)) {
          if (this.headers3[h3] == key3) {

            orderObject3[key3] = obj3[key3].toLocaleString();
            if (orderObject3[key3].replace(/\s/g, "") == "") {

              orderObject3[key3] = obj3[key3].toLocaleString();
            }
            else {
              if (isNaN(orderObject3[key3]))
                orderObject3[key3] = obj3[key3].toLocaleString();
              else {
                orderObject3[key3] = obj3[key3].toLocaleString();
              }
            }
            orderobjectwithoutcomma3[key3] = obj3[key3];
          }
        }
      }
      this.responseList3.push(orderObject3);
      this.responseListExcel3.push(orderobjectwithoutcomma3);

    }
    if (this.responseList3)
      this.dtOptions3 = this.tabledata3(this.responseList3);
    if (this.responseList3 && this.responseListExcel3)
      this.finalDataObject3 = { table: this.dtOptions3, excel: this.responseListExcel3 };
    return this.finalDataObject3;
  }

  sortedResponseListdetailed(finalresultdetailed) {

    for (var i in finalresultdetailed) {
      let finalresultdetailedObj = finalresultdetailed[i];
      let detailedorderObject = {};
      let detailedorderobjectwithoutcomma = {}
      for (var detailedheaderVal in this.detailedheaders) {
        for (const detailedheaderKey of Object.keys(finalresultdetailedObj)) {
          if (this.detailedheaders[detailedheaderVal] == detailedheaderKey) {

            detailedorderObject[detailedheaderKey] = finalresultdetailedObj[detailedheaderKey].toLocaleString();
  
            if (detailedorderObject[detailedheaderKey]) {
              detailedorderObject[detailedheaderKey] = finalresultdetailedObj[detailedheaderKey].toLocaleString();
            }
            else {
              if ((detailedorderObject[detailedheaderKey]) != null)
                detailedorderObject[detailedheaderKey] = finalresultdetailedObj[detailedheaderKey].toLocaleString();
              else {
                detailedorderObject[detailedheaderKey] = finalresultdetailedObj[detailedheaderKey].toLocaleString();
              }
            }
            detailedorderobjectwithoutcomma[detailedheaderKey] = finalresultdetailedObj[detailedheaderKey];
          }
        }
      }
      console.log(detailedorderObject);
      this.detailedresponseList.push(detailedorderObject);
      this.detailedresponseListExcel.push(detailedorderobjectwithoutcomma);

    }
    if (this.detailedresponseList)
      this.detaileddtOptions = this.tabledatadetailed(this.detailedresponseList);
    if (this.detailedresponseList && this.detailedresponseListExcel)
      this.detailedfinalDataObject = { table: this.detaileddtOptions, excel: this.detailedresponseListExcel };

    console.log(this.detailedfinalDataObject)
    return this.detailedfinalDataObject;
  }



  sortedResponseList4(finalresult4) {

    for (var four in finalresult4) {
      let obj4 = finalresult4[four];
      let orderObject4 = {};
      let orderobjectwithoutcomma4 = {}
      for (var h4 in this.headers4) {
        for (const key4 of Object.keys(obj4)) {
          if (this.headers4[h4] == key4) {

            orderObject4[key4] = obj4[key4].toLocaleString();
            if (orderObject4[key4].replace(/\s/g, "") == "") {

              orderObject4[key4] = obj4[key4].toLocaleString();
            }
            else {
              if (isNaN(orderObject4[key4]))
                orderObject4[key4] = obj4[key4].toLocaleString();
              else {
                orderObject4[key4] = obj4[key4].toLocaleString();
              }
            }
            orderobjectwithoutcomma4[key4] = obj4[key4];
          }
        }
      }
      this.responseList4.push(orderObject4);
      this.responseListExcel4.push(orderobjectwithoutcomma4);

    }
    if (this.responseList4)
      this.dtOptions4 = this.tabledata4(this.responseList4);
    if (this.responseList4 && this.responseListExcel4)
      this.finalDataObject4 = { table: this.dtOptions4, excel: this.responseListExcel4 };
    return this.finalDataObject4;
  }
  tabledatapickup(tableDatapickup) {
    let headerDataPU = [];
    for (let item of this.headerspickup) {
      headerDataPU.push({ title: item, data: item });
    }


    this.dtOptionspickup = {
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollY: '75vh',
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      data: tableDatapickup,
      columns: headerDataPU,
      searching: true,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all',
      render: function (data, type, row) {
        let Total = (row["Region"] == "Total") ? true : false;
        data = data.toString().replace(/\"/g, "");
        data = (data.length > 40)?'<a  title="' + data + '" >'+data.substring(0, 40)+'...'+'</a>':'<span class>'+data+'</span>';
       
     
        if (Total)
          return '<b id="cellvalue">' + data + '</b>';
        else               
          return '<span id="cellvalue">' + data + '</span>';
        
      }
}],

      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      }
    };
   if( this.valpickup == -1){
    this.dtOptionspickup["headerCallback"] = function (thead, data, start, end, display) {
      let g= this.service_mgscorecard_month;
      const timer = sessionStorage.getItem('scoreCardTimer') || '0';
      if (timer == '0') {
      sessionStorage.setItem('scoreCardTimer', '1');
      $(thead).before('<tr class="throw0" style="background-color:#333333cc;color:white;font-size:16px;" ><th colspan="9">Moglix Scorecard<span style="font-size:13px;"></span></th></tr>')
      }
   }

   }
      


    return this.dtOptionspickup;
  }
  tabledata(tableData) {
    let headerData = [];
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }

    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      scrollX: false,
      scrollY: '70vh',
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      data: tableData,
      columns: headerData,
      searching: true,
      ordering: false,

      columnDefs: [
        { width: 200, targets: '_all',
        render: function (data, type, row) {
          let Total = (row["Region"] == "Total") ? true : false;
          data = data.toString().replace(/\"/g, "");
          data = (data.length > 40)?'<a  title="' + data + '" >'+data.substring(0, 40)+'...'+'</a>':'<span class>'+data+'</span>';
         
       
          if (Total)
            return '<b id="cellvalue">' + data + '</b>';
          else               
            return '<span id="cellvalue">' + data + '</span>';
          
        } }
      ],
      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      }
    };

    if (this.val == 0) {
      this.dtOptions["headerCallback"] = function (thead, data, start, end, display) {
        const timer = sessionStorage.getItem('osodTimer') || '0';
        if (timer == '0') {
        sessionStorage.setItem('osodTimer', '1');
        // <tr class="throw2" style="background-color:#333333cc;color:white;" ><th colspan=""></th><th colspan="1">Items</th><th colspan="1">#Items Delivered</th><th colspan="1">% Delivered</th><th colspan="1">#Items Business Issue</th><th colspan="1">% Business</th><th colspan="1">#Items Ops Issue</th><th colspan="1">% OPS</th></tr>
        $(thead).before('<tr class="throw0" style="background-color:#333333cc;color:white;font-size:16px;padding:8px;" ><th colspan="8">Moglix Scorecard OS OD<span style="font-size:13px;"></span></th></tr>')
        }
      }
    }
    return this.dtOptions;
  }


  tabledata1(tableData1) {
    let headerData_PendDeliveryCustom = ["Warehouse", "Regions", "# Packets", "Value", "# Packets", "Value", "# Packets", "Value", "# Packets"];
    let headerData1 = [];
    let hc = 0;
    for (let item1 of this.headers1) {
      headerData1.push({ title: headerData_PendDeliveryCustom[hc], data: item1 });
      hc++
    }

    this.dtOptions1 = {
      responsive: true,
      autoWidth: false,
      scrollX: false,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      data: tableData1,
      columns: headerData1,
      searching: false,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all' }],

      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      }
    };
    if (this.val1 == 1) {
      this.dtOptions1["headerCallback"] = function (thead, data, start, end, display) {
        $(thead).before('<tr style="background-color:#333333cc;color:white;" ><th colspan="8">Delivery > 7 Days</th></tr><tr  style="background-color:#333333cc;color:white;" ><th colspan="2"></th><th colspan="2">National LSP</th><th colspan="2">Non-Dropship</th><th colspan="2">Dropship</th></tr>')
      }
    }
    return this.dtOptions1;
  }



  tabledata2(tableData2) {

    let headerData_PendPODCustom = ["Warehouse", "Regions", "# Packets", "Value", "# Packets", "Value", "# Packets", "Value", "# Packets"];
    let headerData2 = [];
    let hc = 0;
    for (let item1 of this.headers2) {
      headerData2.push({ title: headerData_PendPODCustom[hc], data: item1 });
      hc++
    }

    // for (let item2 of this.headers2) {
    //   headerData2.push({ title: item2, data: item2 });
    // }

    this.dtOptions2 = {
      responsive: true,
      autoWidth: false,
      scrollX: false,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      data: tableData2,
      columns: headerData2,
      searching: false,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all' }],

      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      }
    };

    if (this.val2 == 2) {
      this.dtOptions2["headerCallback"] = function (thead, data, start, end, display) {

        $(thead).before('<tr  style="background-color:#333333cc;color:white;" ><th colspan="4">POD > 2 Days</th></tr><tr  style="background-color:#333333cc;color:white;" ><th colspan="2"></th><th colspan="2">All</th></tr>')
      }
    }


    return this.dtOptions2;
  }


  detailedtabledata(detailedtabledata) {
    let headerData_deatiled = ["Warehouse", "Regions", "# Packets", "Value", "# Packets", "Value", "# Packets", "Value", "# Packets"];
    let detailedheaderData = [];
    let dh = 0;
    for (let detail of this.detailedheaders) {
      detailedheaderData.push({ title: headerData_deatiled[dh], data: detail });
      dh++
    }


    this.detaileddtOptions = {
      responsive: true,
      autoWidth: true,
      scrollX: true,
      scrollY: true,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      searching: false,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all' }], language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      },
      data: detailedtabledata,
      columns: detailedheaderData


    };

    if (this.val1 == 1) {
      this.dtOptions3["headerCallback"] = function (thead, data, start, end, display) {
        $(thead).before('<tr  style="background-color:#333333cc;color:white;" ><th colspan="6">Shipping > 2 Days</th></tr><tr  style="background-color:#333333cc;color:white;" ><th colspan="2"></th><th colspan="2">Non-Dropship</th><th colspan="2">Dropship</th></tr>')
      }
    }
    return this.detaileddtOptions;
  }

  tabledata3(tableData3) {
    let headerData_PendShippingCustom = ["Warehouse", "Regions", "# Packets", "Value", "# Packets", "Value", "# Packets", "Value", "# Packets"];
    let headerData3 = [];
    let hc = 0;
    for (let item1 of this.headers3) {
      headerData3.push({ title: headerData_PendShippingCustom[hc], data: item1 });
      hc++
    }


    this.dtOptions3 = {
      responsive: true,
      autoWidth: false,
      scrollX: false,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      searching: false,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all' }], language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      },
      data: tableData3,
      columns: headerData3


    };

    if (this.val3 == 3) {
      this.dtOptions3["headerCallback"] = function (thead, data, start, end, display) {
        $(thead).before('<tr  style="background-color:#333333cc;color:white;" ><th colspan="6">Shipping > 2 Days</th></tr><tr  style="background-color:#333333cc;color:white;" ><th colspan="2"></th><th colspan="2">Non-Dropship</th><th colspan="2">Dropship</th></tr>')
      }
    }
    return this.dtOptions3;
  }


  tabledatadetailed(tabledatadetailed) {
    let detailedheaderData = [];
    //alert(this.headers);
    for (let item of this.detailedheaders) {
      detailedheaderData.push({ title: item, data: item });
    }
    console.log(this.detailedtabledata);
    this.detailedtabledata = tabledatadetailed;
    this.detaileddtOptions = {
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollY: true,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      searching: false,
      ordering: false,
      columnDefs: [{
        width: 300, targets: '_all',
        render: function (data, type, row) {
          let textValue = (data.length > 30) ? '<a  title="' + data + '" >' + data.substring(0, 30) + '...' + '</a>' : '<span class>' + data + '</span>';
          return textValue;
        }
      }],
      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      },
      data: this.detailedtabledata,
      columns: detailedheaderData
    };
    return this.detaileddtOptions;
  }


  tabledata4(tableData4) {
    let headerData4 = [];
    for (let item4 of this.headers4) {
      headerData4.push({ title: item4, data: item4 });
    }

    this.dtOptions4 = {
      responsive: true,
      autoWidth: false,
      scrollX: false,
      scrollCollapse: true,
      pageLength: 10,
      paging: false,
      data: tableData4,
      columns: headerData4,
      searching: false,
      ordering: false,
      columnDefs: [{ width: 300, targets: '_all' }],

      language: {
        info: '',
        "lengthMenu": "",
        "infoEmpty": "",
      }

    };


    if (this.val4 == 4) {
      this.dtOptions4["headerCallback"] = function (thead, data, start, end, display) {
        $(thead).before('<tr  style="background-color:#333333cc;color:white;" ><th colspan="4">Inbound > 2 Days</th></tr><tr  style="background-color:#333333cc;color:white;" ><th colspan="2"></th><th colspan="2">All</th></tr>')
      }

      return this.dtOptions4;
    }
  }

}
