import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class TopLevelService{
  headers2:any[];
  searchVal = 1;
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  headerData_PendPODCustom: any;

  constructor(private http: HttpClient) { }

  getData(url){
    return this.http.get(url);

  }


  postMgmt(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedTopLevel(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }

  sortedTopLevel(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledatatopLevel(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }




  tabledatatopLevel(tableData) {
    let headerData2 = [];
    let hc = 0;
    for (let item1 of this.headers) {
      
      headerData2.push({name: this.headers[hc], title: (hc<=2)?this.headers[hc]:this.headers[hc].slice(8), data:this.headers[hc], "createdCell": function (td, cellData, rowData, row, col) {
            
        if ( item1.includes('Q') ) {
          $(td).css({'background-color':'#fff2cc','font-weight':600})
        }
           
        // if ( item.includes('4') ) {
        //   $(td).css({'background-color':'lightgray'})
        // }
         if(col == 0){
          $(td).css({'background-color': '#e7ebef','z-index': 500,'position':'sticky'})
        }

        if(col == 1){
          $(td).css({'background-color': '#e7ebef','z-index': 500,'position':'sticky'})
        }

        if(col == 2){
          $(td).css({'background-color': '#e7ebef','z-index': 500,'position':'sticky'})
        }
      }
        
      });


      hc++
    }
  
    this.dtOptions = {
      destroy: true,
      pageLength: 10,
      searching: true,
      ordering: false,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      fixedHeader: true,
      scrollY: '56vh',
      paging: false,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      columnDefs: [
        {
          width: "300",
          targets: '_all'
        }
      ],

      data: tableData,
      columns: headerData2,

      language: {
        info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
        "lengthMenu": "",
        "infoEmpty": "",
        "infoFiltered": ""
      },

      
      fixedColumns: {
        
        leftColumns: 3
      
      },
    
    
    
      rowGroup:true    
    };
    
   
    if (this.dtOptions) { 
      this.dtOptionsenable = true
    }
    return this.dtOptions;
    
  }

  postData(url,body){
    return this.http.post(url,body)
  }
  tabledata(tableData) {
    let headerData = [];
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }



    
    this.dtOptions = {
      pageLength: 10,
      searching: true,
      ordering: false,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {
        
        leftColumns: 3
      
      },
      scrollY: '55vh',
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      columnDefs: [
        {
          width: "300",
          targets: '_all',
          render: function (data, type, row, meta,full) {
            let GMpercent = (row["Param(in Lakhs)"] == "Total GMV") ? true : false;
            let dt = data.toString().replace(/\"/g, "");
            data = (dt == GMpercent) ? '<b id="cellvalue">' + dt + '</b>' : dt;
            if (data == "Total GMV" || data == "GM & (%)" || data == "CM1 & (%)" || data == "CM2 & (%)")
              return '<b id="cellvalue" style="color:red;">' + data + '</b>';

            if(data == "Logistics Cost & (%)"|| data == "Runner Cost & (%)"|| data == "TOD Provisioning & (%)"
            || data == "Marketing Cost"|| data == "Payroll & Infra & (%)"|| data == "OD>30 Days"
            || data == "Inventory"|| data == "Inventory - Fresh"|| data == "Inventory - Return"
            || data == "AR-AP"|| data == "HC")
              return '<i id="cellvalue" style="color:blue;">' + data + '</i>';
            if(data != "Total GMV" || data != "GM & (%)" || data != "CM1 & (%)" || data != "CM2 & (%)" 
            || data != "Logistics Cost & (%)"|| data != "Runner Cost & (%)"|| data != "TOD Provisioning & (%)"
            || data != "Marketing Cost"|| data != "Payroll & Infra & (%)"|| data != "OD>30 Days"
            || data != "Inventory"|| data != "Inventory - Fresh"|| data != "Inventory - Return"
            || data != "AR-AP"|| data != "HC")
              if(data!= '-'){
                let v = meta.col;
                if(headerData[v]['title'].includes("Q"))
                // return '<b class="active" style="color:#FC6A03">'+data+'</b>';
                return '<b class="active" style="color:black;">'+data+'</b>';
                else
                return data;
              }
              else{

                  return    '<span  style="color:black!important;">' + '-' + '</span>';

              }
          }
        }
      ],
      language: {
        info: this.timestampval,

        search: '<i style="font-size:12px;">Achieved/Target is <b style="color:red">0-85%</b>, <b style="color:#e59a0f">86%-95%</b>, <b style="color:green">>95%</b></i>',
        searchPlaceholder: 'Search',
      
        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
}
