 
import { Component, OnInit, Input, OnChanges, NgZone, SimpleChanges } from '@angular/core';
import { HttpService } from './../../http.service';
import { keys } from 'highcharts';
import { RowPositionUtils } from 'ag-grid-community';
//import { ItemTrackingESService } from '../item-tracking-es.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AppConstants } from './../../shared/app.constants';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';
import { CsService } from 'src/app/services/cs.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { ObjectUnsubscribedError } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';

@Component({
  selector: 'app-cs',
  templateUrl: './cs.component.html',
  styleUrls: ['./cs.component.css']
})
export class CsComponent implements OnInit {
  reportName='';
  emailRequestBody = {};
  FirstColumntitleValue: any;
  headers = [];
  emailAlert: boolean;
  detailedRequestBody: {};
  dtOptionsenable2: boolean;
  tableCondition2: boolean;
  summary_data: any;
  dtOptions: DataTables.Settings = {};
  dtOptionsdetailed: DataTables.Settings = {};
  selctedStage = [];
  levels = ["Region", "Team", "Customer", "Plant"];
  level: any;
  submitted: boolean;
  checked: boolean;
  analyzeLabel: String = 'Analyzable Format';
  analyze_format: boolean = true;
  // enterprise variables
  stages = [];
  reportsData = [];
  config: any = {};
  previousQuery = {};
  requestDetails = {};

  @Input('itemTrackerESWise') itemTrackerESWise = false;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  detaileddrillDownData: any = {};
  DateCalender: any;
  detailed = false;
  filterData: any = {};
  requestbody: any;
  columnsHeader = [];
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  ddTable = {};
  public selectedL1 = [];
  public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  detailedTable = [];
  tabledata: any = {};
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  dtoption2: any;
  dtoption3: any;
  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  otif: string;
  tabledata_rows: any;
  rowheaders: string[];
  lengthTable: number;
  tabledataObj: any;
  sortedtabledata = [];
  excelData2 = [];
  excelData3 = [];
  sortedtabledataDD = [];
  dateRange: any;
  RkeyValue: any;
  req_start_date: string;
  req_end_date: string;
  tableCondition: boolean;
  dtOptionsenable: boolean;
  userId_val: any;
  days: Number = 0;
  constructor(private navbarTitle: NavbarComponent,private commonService: CommonService, private csService: CsService, private Service: SelfServeService,
    private userId: AppService,
    private httpService: HttpService,
    public itESservice: ItemTrackingESService,
    private ngZone: NgZone,
    private toaster: ToastrService) {
    this.getFiltersData();
    this.multiSelectDropdown();
    //date Range default
    // this.getDefaultDateRange();
   
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  
    this.getSelectedFilters(this.selectedRegions);

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 10
    };
  }
  async getDateRange(d = false, f = false) {
    this.requestbody["start_date"] = this.getDateFormat(this.dateRange[0]),
      this.requestbody["end_date"] = this.getDateFormat(this.dateRange[1])
  }
  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let dateVal: string = [year, month, day].join('-');
    return dateVal;
  }
  // getDefaultDateRange() {
  //   this.itESservice.getDateItemTrackingES().subscribe(dateRes => {
  //     this.DateCalender = dateRes;
  //     this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
  //     this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
  //   });
  // }
  getFiltersData() {
    this.itESservice.getFiltersItemTrackingES(this.userId.userDetails.email).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }

  // getDateFormat(date) {
  //   if (!date) return;
  //   date = new Date(date);
  //   return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  // }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  refresh() {
    this.columnsHeader = [];
    this.requestbody = {};
    this.otif = null;
    this.selectedRegions = [];
    this.selectedTeams = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.regions = [];
    this.customers = [];
    this.plants = [];
    this.teams = [];
    // this.getDefaultDateRange();
    this.dateRange = null;
    this.getFiltersData();

  }
  getTableDataFromApi() {
    this.dtOptionsenable = false;
    this.tableCondition = false;
    this.dtOptionsenable2 = false;
    this.tableCondition2 = false;
    this.requestbody = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "regions": this.selectedRegions,
      "teams": this.selectedTeams,
      "customers": this.selectedCustomers,
      "plants": this.selectedPlants,
      "days": this.days,
      "filter_stage": this.stages,
      "username": this.userId_val,
      "filter_level": "Region"
    }
    let summary = environment.baseURL_FREQ+"cs_scorecard";
    

    this.csService.getCSdata(summary, this.requestbody).subscribe((data) => {



      if(data['data']['result']!=undefined && data['status']=='SUCCESS'){
        let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
        this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
          console.log(reportdata);
        })
      }

      this.summary_data = {};
      this.dtOptions = {};
      this.headers = [];
      this.tableCondition = false;

      this.dtOptionsenable = false;

      this.summary_data = this.csService.post(data['data']);

      this.headers = data['data']['headers'];

      this.tabledata = data['data']['result'];
      if (this.summary_data) {
        this.dtOptions = this.summary_data.table;
        
        this.tableCondition = true;

        this.dtOptionsenable = true;
      }
    })
  }
  detailedReport = (row: string, col: string, filter_level: string) => {

    this.dtOptionsenable2 = false;
    this.tableCondition2 = false;
    this.dtoption2 = {};
    this.dtoption3 = {};
    this.excelData2 = [];
    this.excelData3 = [];
    let urldetailed = environment.baseURL_FREQ+"cs_scorecard_detailed";
    this.detailedRequestBody = {
      "filter_stage": col,
      "days": this.days,
      "regions": [],

      "teams": [],
      "customers": [],
      "plants": [],
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "username": this.userId_val
    };

    if (filter_level == 'Region') {
      this.detailedRequestBody['regions']=this.selectedRegions;
      this.detailedRequestBody['filtertype'] = filter_level;
      this.detailedRequestBody['filtertype_name'] = row;
      
      this.csService.getCSdata(urldetailed, this.detailedRequestBody).subscribe(res => {
                let fileName = col + "_" + this.days;

        if (res['data']['SPO-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtOptionsdetailed = this.dtoption2.table;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile(this.excelData2, fileName);
        }
        if (res['data']['Customer_Packet-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          return this.Service.exportAsExcelFile(this.excelData3, fileName);
        }
        if (this.excelData2 && this.excelData3 && col == 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile2(this.excelData2, this.excelData3, fileName);
        }

      });

    }
    if (filter_level == 'Team') {
      
      this.detailedRequestBody['filtertype'] = filter_level;
      this.detailedRequestBody['filtertype_name'] = row;

      this.detailedRequestBody['regions']=this.selectedRegions;
      this.detailedRequestBody['teams']=this.selectedTeams;
      this.csService.getCSdata(urldetailed, this.detailedRequestBody).subscribe(res => {
        
        let fileName = col + "_" + this.days;
        if (res['data']['SPO-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtOptionsdetailed = this.dtoption2.table;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile(this.excelData2, fileName);
        }
        if (res['data']['Customer_Packet-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          return this.Service.exportAsExcelFile(this.excelData3, fileName);
        }
        if (this.excelData2 && this.excelData3 && col == 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile2(this.excelData2, this.excelData3, fileName);
        }
      });

    }
    if (filter_level == 'Customer Name') {
      
      this.detailedRequestBody['filtertype'] = filter_level;
      this.detailedRequestBody['filtertype_name'] = row;
      this.detailedRequestBody['regions']=this.selectedRegions;
      this.detailedRequestBody['teams']=this.selectedTeams;
      this.detailedRequestBody['customers']=this.selectedCustomers;
      this.csService.getCSdata(urldetailed, this.detailedRequestBody).subscribe(res => {
        
        let fileName = col + "_" + this.days;
        if (res['data']['SPO-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtOptionsdetailed = this.dtoption2.table;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile(this.excelData2, fileName);
        }
        if (res['data']['Customer_Packet-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          return this.Service.exportAsExcelFile(this.excelData3, fileName);
        }
        if (this.excelData2 && this.excelData3 && col == 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile2(this.excelData2, this.excelData3, fileName);
        }
      });

    }
    if (filter_level == 'Plant Name') {

      this.detailedRequestBody['filtertype'] = filter_level;
      this.detailedRequestBody['filtertype_name'] = row;
      
      this.detailedRequestBody['regions']=this.selectedRegions;
      this.detailedRequestBody['teams']=this.selectedTeams;
      this.detailedRequestBody['customers']=this.selectedCustomers;
      this.detailedRequestBody['plants']=this.selectedPlants;

      this.csService.getCSdata(urldetailed, this.detailedRequestBody).subscribe(res => {
        
        let fileName = col + "_" + this.days;
        if (res['data']['SPO-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtOptionsdetailed = this.dtoption2.table;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile(this.excelData2, fileName);
        }
        if (res['data']['Customer_Packet-side'].hasOwnProperty('headers') && col != 'Total') {
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          return this.Service.exportAsExcelFile(this.excelData3, fileName);
        }
        if (this.excelData2 && this.excelData3 && col == 'Total') {
          this.dtoption2 = this.csService.post(res['data']['SPO-side']);
          this.dtoption3 = this.csService.post(res['data']['Customer_Packet-side']);
          this.excelData3 = this.dtoption3.excel;
          this.excelData2 = this.dtoption2.excel;
          return this.Service.exportAsExcelFile2(this.excelData2, this.excelData3, fileName);
        }
      });

    }
    return true;
  }

  excelDownloadcell(event) {

    event.preventDefault();
    var self = this;
    let expressionVal = event.target.className;

    
    $('#example > tbody > tr').off("click").on('click', 'td:(.fa fa-download)', function (e) {
      // e.preventDefault();
       let table = $('#example').DataTable();
       let idx = table.cell(this).index().column;
       let rowIdx = table.cell(this).index().row;
       let title = table.column(idx).header();
       let selectedRowValue = table.cell(rowIdx, 0).data();
       let selectedRowValue_TypeVal = table.cell(rowIdx, idx).data();
       let selectedRowValue_Team_TypeVal = table.cell(rowIdx, idx).data();
       let selectedRowValue_Team = table.cell(rowIdx, idx).data();
       let selectedColumnValue = $(title).html();
       let cell = table.cell(this);

       let FirstColumntitle = table.column(0).header();
       let FirstColumntitleValue = $(FirstColumntitle).html();
      // if (selectedRowValue_TypeVal != null)
         self.detailedReport(selectedRowValue, selectedColumnValue, FirstColumntitleValue);
     });
  }
  email(row, col, type) {
    
    this.emailRequestBody = {
      "filter_stage": col,
      "days": this.days,
      "regions": [],

      "teams": [],
      "customers": [],
      "plants": [],
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "username": this.userId_val
    };
    if (type == 'Region') {
      this.emailRequestBody['regions'].push(row);
      let urldetailed = environment.baseURL_FREQ+"cs_scorecard_detailed";
      this.csService.getCSdata(urldetailed, this.emailRequestBody).subscribe(res => {
        
        return res;
      });

    }


    if (type == 'Team') {
      this.emailRequestBody['teams'].push(row);
      let urldetailed = environment.baseURL_FREQ+"cs_scorecard_detailed";
      this.csService.getCSdata(urldetailed, this.emailRequestBody).subscribe(res => {
        
        return res;
      });

    }
    if (type == 'Customer Name') {
      this.emailRequestBody['customers'].push(row);
      let urldetailed = environment.baseURL_FREQ+"cs_scorecard_detailed";
      this.csService.getCSdata(urldetailed, this.emailRequestBody).subscribe(res => {
        
        return res;
      });

    }
    if (type == 'Plant Name') {
      this.emailRequestBody['plants'].push(row);
      let urldetailed = environment.baseURL_FREQ+"cs_scorecard_detailed";
      this.csService.getCSdata(urldetailed, this.emailRequestBody).subscribe(res => {
        
        return res;
      });

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getDateRange(this.dateRange);
    this.getSelectedFilters(this.selectedRegions);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }

  async getStages() {
    this.csService.getCSdata(environment.baseURL_FREQ+"cs_scorecard_stages", { "username": this.userId_val }).subscribe(res => {
      this.stages = res['data'];

    })
  }
  async getNoDays(event) {
      if (event.key == "-") {
      event.preventDefault();
      return false;
    }
    this.days = parseInt(event.target.value);
    this.requestDetails['days'] = this.days;
  }
  generateReports() {
    if (this.dateRange === null) {
      this.commonService.showToast("Please Select Date Range", 2);
    }
    if (this.dateRange != null && this.selectedRegions != null && this.selectedPlants != null && this.selectedCustomers
      != null && this.selectedPlants != null)
      this.getTableDataFromApi();
  }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }
  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }
  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }
  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }
  ngOnInit() {
    this.getStages();
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    // this.selectedTeams.push(item);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

  }
  onTeamDeSelect(deselectedTID: any): void {

    if (this.selectedTeams != null) {
      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {
      this.selectedTeams = [];
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Customers
  onCustomerSelect(item: string) {
    this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onCustomersAll(items: any) {
    this.selectedCustomers = items;
    this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onCustomerDeSelect(deselectedTCID: any): void {
    if (this.selectedCustomers != null) {
      this.CustomerUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {

      this.selectedCustomers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onCustomersDeSelectAll(items: any) {

    this.selectedL3 = [];
    this.selectedCustomers = [];

    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Plants
  onPlantSelect(item: string) { 
    this.selectedL4.push(item);
    this.selectedPlants.push(item);
    
  }
  onPlantsAll(items: any) { 
    this.selectedPlants = items;
  }
  onPlantDeSelect(deselectedTPID: any): void { }
  onPlantsDeSelectAll(items: any) {
    this.selectedL4 = [];
    this.selectedPlants = [];

   // this.plants = [];
  }


  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {

    let teamsList = [];
    let s2 = [];
    let s3 = [];
    this.selectedCustomers = [];
    let s4 = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.customers = [];
    this.plants = [];
    for (let r = 0; r < this.regions.length; r++) {
      for (let rr = 0; rr < Rkeys.length; rr++) {
        if (this.regions[r] === Rkeys[rr]) {
          this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {
            let currentTeamName = Object.keys(this.TeamRegionsListObj[this.regions[r]][index])[0];

            for (let t = 0; t < team[currentTeamName].length; t++) {

              for (let tt = 0; tt < Tkeys.length; tt++) {
                if (currentTeamName === Tkeys[tt]) {
                  this.selectedCustomers.push(Object.keys(team[currentTeamName][t])[0])
                  this.customers.push(Object.keys(team[currentTeamName][t])[0])
                  let cName = Object.keys(team[currentTeamName][t])[0]
                  for (let p = 0; p < team[currentTeamName][t][cName].length; p++) {
                    this.plants.push(team[currentTeamName][t][cName][p])
                    this.selectedPlants.push(team[currentTeamName][t][cName][p])
                  }
                }
              }
            }
          });
        }
      }
    }
  }

  CustomerUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {
    
    this.selectedPlants = [];
    this.selectedL4=[];
    this.plants = [];
    Pkeys = [];

    for(let r = 0; r < this.regions.length; r++){
      for (let rr = 0; rr < Rkeys.length; rr++) {
        if (this.regions[r] === Rkeys[rr]) {
        this.TeamRegionsListObj[this.regions[r]].forEach((team, index) => {
          let currentTeamName = Object.keys(team)[0];
            for(let t=0;t<team[currentTeamName].length;t++){
                  for(let tt=0;tt<Tkeys.length;tt++){
                    if(currentTeamName==Tkeys[tt])
                    {
                      let customer = Object.keys(team[currentTeamName][t])[0];
                      for(let cc=0;cc<team[currentTeamName][t][customer].length;cc++){
                        this.plants.push(team[currentTeamName][t][customer][cc]); 
                         
                        for(let p=0;p<CKeys.length;p++){
                           
                          if(CKeys[p] == customer)
                         { 
                           for(let pp=0;pp<team[currentTeamName][t][customer].length;pp++){
                            this.selectedL4.push(team[currentTeamName][t][customer][pp]); 
                           }
                          }
                        }
                     }
                    }
                  }
            }
        });
      }
    }
      
    }

if(this.selectedL4.length>0){
  this.selectedPlants = this.selectedL4.filter((v, i, a) => a.indexOf(v) === i);

}

  }

  backButton() {
    this.tableCondition = !this.tableCondition;
    this.dtOptionsenable = !this.dtOptionsenable;
    this.tableCondition2 = !this.tableCondition2;
    this.dtOptionsenable2 = !this.dtOptionsenable2;
    this.detailed = false;
    this.submitted = false;
    this.checked = true;
    this.reportsData = [];

  }

}
