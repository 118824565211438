import { Component, OnInit, Input, OnChanges, NgZone, SimpleChanges } from '@angular/core';
import { HttpService } from './../../http.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';
import { UrlConfig } from 'src/app/helpers/urlConfig';
import { Router } from '@angular/router';
import { NavbarComponent } from '../navbar/navbar.component';
import { BuScorecardService } from 'src/app/services/bu-scorecard.service';
import { TopLevelService } from 'src/app/services/top-level.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { debug } from 'console';

@Component({
  selector: 'app-region-scorecard-bu',
  templateUrl: './region-scorecard-bu.component.html',
  styleUrls: ['./region-scorecard-bu.component.css']
})
export class RegionScorecardBUComponent implements OnInit {
  reportName = '';
  data = [];
  detaileddata = [];
  dtOptions: DataTables.Settings = {};
  dtOptionsSummary: DataTables.Settings = {};
  dtOptionsTopLevel: DataTables.Settings = {};

  dtOptionsMoglixSummary: DataTables.Settings = {};
  submitted: boolean;
  checked: boolean;
  analyzeLabel: String = 'Analyzable Format';
  analyze_format: boolean = true;
  stages = [];
  reportsData = [];
  config: any = {};
  previousQuery = {};
  requestDetails = {};
  flagSearch: boolean;
  toplevelArray = [];
  @Input('itemTrackerESWise') itemTrackerESWise = false;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  detaileddrillDownData: any = {};
  DateCalender: any;
  detailed = false;
  filterData: any = {};
  requestbody: any;
  columnsHeader = [];
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  ddTable = {};
  public selectedL1 = [];
  public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  detailedTable = [];
  tabledata: any = {};
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];
  newArrayG = [];
  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  otif: string;
  tabledata_rows: any;

  lengthTable: number;
  tabledataObj: any;
  sortedtabledata = [];

  sortedtabledataDD = [];
  dateRange: any;
  RkeyValue: any;
  req_start_date: string;
  req_end_date: string;
  headers = [];
  obj = {}

  moglixSummaryobj: { table: {}; excel: any[]; };
  tableDisplay: boolean = false;
  dropdownSettingsparamsList = {};

  selectedParamsList = [];

  navbarbu = [];

  userId_val: any;
  selected = '/regionBU';
  currentactiveTab: any;
  tableDisplaySummary: boolean;
  summaryobj: { table: {}; excel: any[]; };
  topLevelobj: { table: {}; excel: any[]; };
  viewDetailsTable: any;
  tableDisplaytopLevel: boolean;
  searchVal: any;
  excelData: any;
  enterpriseType = [];
  enterpriseTypeVal = 'All';
  requestTopLevel: { username: any; enterprise_type: any; };
  moglixSummaryArrayNew: any;
  tableDisplaymoglixSummary: boolean;
  moglixSummary: any;
  dropshipValue = 'Both'

  constructor(private navbarTitle: NavbarComponent, private Service: SelfServeService, private toplevelService: TopLevelService, private router: Router, private navbar: NavbarComponent, private commonService: CommonService,
    private buService: BuScorecardService,
    private userId: AppService,
    private httpService: HttpService,
    public itESservice: ItemTrackingESService,
    private ngZone: NgZone,
    private toaster: ToastrService) {
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
    ///    this.navbarbu = this.navbar['navbarBU'][0];
    this.navbarbu = [
      { "link": "/regionBU", "show": true, "label": "Region Scorecard" },
      { "link": "/butop20", "show": true, "label": "Top 20 Customers" },
      { "link": "/bridgeBU", "show": true, "label": "Bridge" }]
    console.log(this.navbarbu);
    this.buService.getData(environment.baseURL_FREQ+'enterprise_type').subscribe(enterprise => {
      this.enterpriseType = enterprise['data'];
    });
    console.log(this.enterpriseType)
    // this.getTableDataFromApi();
    this.getMoglixSummary();
  }



  ngOnInit() {


  }
  selectedenterpriseType(evt) {

    this.enterpriseTypeVal = evt.target.value;
  }

  getDefaultDateRange() {
    this.itESservice.getDateItemTrackingES().subscribe(dateRes => {
      this.DateCalender = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
    });
  }
  getFiltersData() {
    this.itESservice.getFiltersItemTrackingES(this.userId.userDetails.emai).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  isActive(e_menu_link, idx) {
    return this.selected === e_menu_link;
  }
  getVisual(e_menu_link) {
    this.selected = e_menu_link;
    this.currentactiveTab = e_menu_link;
    switch (e_menu_link) {
      case '/regionBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);

        this.getvisualTable(this.selected);
        break;
      }
      case '/bridgeBU': {
        this.selected = e_menu_link;
        this.router.navigate([this.selected]);
        this.getvisualTable(this.selected);
        break;
      }
      case '/butop20': {
        this.selected = e_menu_link;

        this.router.navigate([this.selected]);

        break;
      }
    }
  }

  getvisualTable(menu) {

  }
  refresh() {
    this.columnsHeader = [];
    this.requestbody = {};
    this.otif = null;
    this.selectedRegions = [];
    this.selectedTeams = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.regions = [];
    this.customers = [];
    this.plants = [];
    this.teams = [];
    this.getDefaultDateRange();
    this.dateRange = null;
    this.getFiltersData();

  }
  getTableDataFromApi() {
    this.viewDetailsTable = 'summary';
    this.tableDisplaySummary = false;
    this.sortedtabledata = [];
    this.tabledata = [];
    this.tabledataObj = [];
    this.dtOptions = {};
    this.requestbody = {
      "email": this.userId_val
    }

    this.itESservice.sendPostRequest(environment.baseURL_FREQ+'bu_scorecard', this.requestbody).subscribe((data) => {
      console.log(data);

      if (data['data']['result'] != undefined && data['status'] == 'SUCCESS') {
        // let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
        this.userId.saveInfo(environment.user_tracking_api, { 'username': this.userId_val, 'report_name': 'BU Summary' }).subscribe(reportdata => {
          console.log(reportdata);
        })
      }

      let arrayG = data['data']['result'];

      this.headers = data['data']['headers'];
      let k = 0;
      for (let l = 0; l < arrayG.length; l++) {

        let temprow = {};
        if (arrayG[l]['Attributes'] == 'GM') {


          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Target_GM') {


          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = "Target GM" + " & (%)";

            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Logistics Cost') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'CM') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }

        else if (arrayG[l]['Attributes'] == 'CM1') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }

        else if (arrayG[l]['Attributes'] == 'CM2') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Returns') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Breakeven') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }

        else if (arrayG[l]['Attributes'] == 'TOD Provisioning') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Runner Cost') {
          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }
          this.newArrayG[k++] = temprow;
        }
        else if (arrayG[l]['Attributes'] == 'Payroll & Infra') {

          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Region' || this.headers[h] == 'Attributes') {
              if (this.headers[h] == 'Region')
                temprow['Region'] = arrayG[l][this.headers[h]];
              if (this.headers[h] == 'Attributes')
                temprow['Attributes'] = arrayG[l][this.headers[h]] + " & (%)";
            }
            else {
              if (arrayG[l + 1][this.headers[h]] != '-%' || arrayG[l + 1][this.headers[h]] != '0.0%')
                temprow[this.headers[h]] = (arrayG[l][this.headers[h]].toString()).concat("  (" + arrayG[l + 1][this.headers[h]] + ")")
              if (arrayG[l + 1][this.headers[h]] == '-%' || arrayG[l + 1][this.headers[h]] == '0.0%')
                temprow[this.headers[h]] = (('<span  style="color:black!important;">' + arrayG[l][this.headers[h]] + '</span>').toString()).concat(" -")

            }
          }

          this.newArrayG[k++] = temprow;
        }

        else if (arrayG[l]['Attributes'] != '%GM' && arrayG[l]['Attributes'] != '%Target_GM' && arrayG[l]['Attributes'] != '%Logistics Cost' && arrayG[l]['Attributes'] != '%CM' && arrayG[l]['Attributes'] != '%CM1' && arrayG[l]['Attributes'] != '%CM2'
          && arrayG[l]['Attributes'] != '%Runner Cost' && arrayG[l]['Attributes'] != '%TOD Provisioning' && arrayG[l]['Attributes'] != '%Payroll & Infra' && arrayG[l]['Attributes'] != '%Breakeven' && arrayG[l]['Attributes'] != '%Returns') {
          this.newArrayG[k++] = arrayG[l];
        }

      }
      let dA = 0
      for (let d = 0; d < this.newArrayG.length; d++) {
        let temprow = {};
        if (this.newArrayG[d][this.headers[1]] != 'Marketing Cost' && this.newArrayG[d][this.headers[1]] != 'Reinvoice Revenue' &&
          this.newArrayG[d][this.headers[1]] != 'Inventory - Fresh' && this.newArrayG[d][this.headers[1]] != 'Cost/HC' &&
          this.newArrayG[d][this.headers[1]] != 'Inventory - Return' && this.newArrayG[d][this.headers[1]] != 'Payroll'
          && this.newArrayG[d][this.headers[1]] != 'Infra') {

          for (let h = 0; h < this.headers.length; h++) {
            temprow[this.headers[h]] = this.newArrayG[d][this.headers[h]];
          }
          this.detaileddata[dA++] = temprow;

        }

      }
      console.log(this.detaileddata);

      data['data']['result'] = this.detaileddata;
      this.obj = this.buService.post(data);
      if (this.obj['table']) {
        this.dtOptions = this.obj['table'];
        this.tableDisplay = true;
      }
    })
  }
  getSummary() {
    this.viewDetailsTable = 'detailed';
    this.detaileddata = [];
    this.sortedtabledata = [];
    this.tabledata = [];
    this.tabledataObj = [];
    this.dtOptions = {};
    let data = { 'data': { 'headers': [], 'result': [] } }
    data['data']['result'] = this.newArrayG;
    data['data']['headers'] = this.headers;

    this.summaryobj = this.buService.post(data);

    this.tableDisplay = false;

    if (this.summaryobj['table']) {


      this.userId.saveInfo(environment.user_tracking_api, { 'username': this.userId_val, 'report_name': 'BU Detailed' }).subscribe(reportdata => {
        console.log(reportdata);
      })
      this.dtOptionsSummary = this.summaryobj['table'];

      this.tableDisplaySummary = true;
    }
  }
  getTopLevel() {
    this.searchVal = 1;
    let self = this;
    this.viewDetailsTable = 'toplevel'
    this.detaileddata = [];
    this.sortedtabledata = [];
    this.tabledata = [];
    this.tabledataObj = [];
    this.dtOptions = {};
    this.tableDisplay = false;
    this.tableDisplaySummary = false;
    this.tableDisplaytopLevel = false;
    this.toplevelArray = [];
    console.log("Top Level");
    this.requestTopLevel = { 'username': this.userId_val, 'enterprise_type': this.enterpriseTypeVal }
    this.toplevelService.postData(environment.baseURL_FREQ+'bu_top_level', this.requestTopLevel).subscribe(data => {
      console.log(data);
      let parent = data['data']['parent'];
      let toplevelArray = data['data']['result'];

      this.headers = data['data']['headers'];
      let k = 0;


      for (let l = 0; l < toplevelArray.length; l++) {

        let temprow = {};

        if (toplevelArray[l][this.headers[1]].includes('Region')) {
          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Zone' || this.headers[h] == 'Region' || this.headers[h] == 'Attribute') {
              temprow[this.headers[h]] = '<i>' + toplevelArray[l][this.headers[h]] + '</i>';
            }
            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] != '-') {
              if ((toplevelArray[l][this.headers[h]]) != 0.0 || (toplevelArray[l][this.headers[h]]) != '-' || (toplevelArray[l][this.headers[h + 1]]) != '-') {

                if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 0 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 84)
                  temprow[this.headers[h]] = '<i style="color:red;font-weight:600;">' + toplevelArray[l][this.headers[h]] + '</i>'

                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 85 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 95)
                  temprow[this.headers[h]] = "<i style='color:#e59a0f;font-weight:600;'>" + toplevelArray[l][this.headers[h]] + "</i>";
                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) > 95)
                  temprow[this.headers[h]] = "<i style='color:green;font-weight:600;'>" + toplevelArray[l][this.headers[h]] + "</i>";

              }
              if ((toplevelArray[l][this.headers[h + 1]]) == 0.0 || (toplevelArray[l][this.headers[h + 1]]) == '-') {

                temprow[this.headers[h]] = '<i style="color:black;">' + toplevelArray[l][this.headers[h]] + '</i>'

              }
            }

            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] == '-') {
              temprow[this.headers[h]] = '<i style="color:black;">' + toplevelArray[l][this.headers[h]] + '</i>'
            }
            if (this.headers[h].includes("Target")) {
              temprow[this.headers[h]] = '<i style="color:black;">' + toplevelArray[l][this.headers[h]] + '</i>'
            }
          }

          this.toplevelArray[k++] = temprow;
        }
        else if (toplevelArray[l][this.headers[0]].includes('Grand Total')) {
          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Zone' || this.headers[h] == 'Region' || this.headers[h] == 'Attribute') {
              temprow[this.headers[h]] = '<b style="color:black;"><i>' + toplevelArray[l][this.headers[h]] + '</i></b>';
            }
            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] != '-') {
              if ((toplevelArray[l][this.headers[h + 1]]) != '-') {

                if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 0 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 84)
                  temprow[this.headers[h]] = '<b style="color:red;">' + toplevelArray[l][this.headers[h]] + '</b>'

                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 85 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 95)
                  temprow[this.headers[h]] = "<b style='color:#e59a0f;'>" + toplevelArray[l][this.headers[h]] + "</b>";
                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) > 95)
                  temprow[this.headers[h]] = "<b style='color:green;'>" + toplevelArray[l][this.headers[h]] + "</b>";

              }
              if ((toplevelArray[l][this.headers[h + 1]]) == '-') {

                temprow[this.headers[h]] = '<b style="color:black;">' + toplevelArray[l][this.headers[h]] + '</b>'

              }
            }
            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] == '-') {
              temprow[this.headers[h]] = '<i style="color:black;">' + toplevelArray[l][this.headers[h]] + '</i>'
            }
            if (this.headers[h].includes("Target")) {
              temprow[this.headers[h]] = '<b style="color:black;">' + toplevelArray[l][this.headers[h]] + '</b>'
            }
          }

          this.toplevelArray[k++] = temprow;
        }
        else if (!(toplevelArray[l][this.headers[0]].includes('Grand Total'))) {
          for (let h = 0; h < this.headers.length; h++) {
            if (this.headers[h] == 'Zone' || this.headers[h] == 'Region' || this.headers[h] == 'Attribute') {
              temprow[this.headers[h]] = toplevelArray[l][this.headers[h]];
            }
            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] != '-') {

              if ((toplevelArray[l][this.headers[h + 1]]) != '-') {

                if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 0 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 84)
                  temprow[this.headers[h]] = '<b style="color:red;">' + toplevelArray[l][this.headers[h]] + '</b>'

                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) >= 85 && Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) <= 95)
                  temprow[this.headers[h]] = "<b style='color:#e59a0f;'>" + toplevelArray[l][this.headers[h]] + "</b>";
                else if (Math.round((toplevelArray[l][this.headers[h]] / toplevelArray[l][this.headers[h + 1]]) * 100) > 95)
                  temprow[this.headers[h]] = "<b style='color:green;'>" + toplevelArray[l][this.headers[h]] + "</b>";

              }
              if ((toplevelArray[l][this.headers[h + 1]]) == '-') {

                temprow[this.headers[h]] = '<span style="color:black;">' + toplevelArray[l][this.headers[h]] + '</span>'

              }
            }
            if (this.headers[h].includes("Achieved") && toplevelArray[l][this.headers[h]] == '-') {
              temprow[this.headers[h]] = '<i style="color:black;">' + toplevelArray[l][this.headers[h]] + '</i>'
            }
            if (this.headers[h].includes("Target")) {
              temprow[this.headers[h]] = '<span style="color:black;">' + toplevelArray[l][this.headers[h]] + '</span>'
            }
          }

          this.toplevelArray[k++] = temprow;
        }

      }




      console.log(this.toplevelArray);

      let parentthHeader = '<th style="z-index: 500;position: fixed;width: 79px;">BU</th><th colspan="2"  style="z-index: 500;position: sticky;left: 118px;width: 231px;" >Values in Lakh</th>';
      console.log(parent);
      for (let t = 0; t < parent.length; t++) {

        if (parent[t] == parent[t + 1]) {
          parentthHeader = '<th colspan="2">' + parent[t] + '</th>';
          t = t + 1;
        }
      }
      parentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + parentthHeader + '</tr>'

      data['data']['result'] = this.toplevelArray;
      this.topLevelobj = this.toplevelService.postMgmt(data);

      if (this.topLevelobj['table']) {


        this.userId.saveInfo(environment.user_tracking_api, { 'username': this.userId_val, 'report_name': 'BU Top Level' }).subscribe(reportdata => {
          console.log(reportdata);
        })

        this.dtOptionsTopLevel = {};
        this.dtOptionsTopLevel = this.topLevelobj['table'];
        if (this.searchVal == 1) {

          this.dtOptionsTopLevel["headerCallback"] = function (thead, data, start, end, display) {
            console.log(self.searchVal);
            if (self.searchVal == 1) {
              console.log(parent)

              $(thead).before(parentthHeader)
              this.flagSearch = true;
              self.searchVal = self.searchVal + 1;

            }
            else {
              console.log()
            }
          }
        }
        else {
          console.log()
        }
        this.tableDisplaytopLevel = true;
      }

    })

  }
  getMoglixSummary() {
    let self = this;
    this.viewDetailsTable = 'moglixSummary'
    this.detaileddata = [];
    this.searchVal = 1;
    this.tabledata = [];
    this.tabledataObj = [];
    this.dtOptions = {};
    this.tableDisplay = false;
    this.tableDisplaySummary = false;
    this.tableDisplay = false;
    this.tableDisplaytopLevel = false;
    this.tableDisplaymoglixSummary = false;
    console.log('moglix Summay')
    let m = 0;
    this.buService.postData('https://analytics-web.moglilabs.com/freq_report/moglix_summary', { 'username': this.userId_val, is_dropship: this.dropshipValue }).subscribe(data => {



      this.moglixSummary = data;
      let response = this.moglixSummary;
      let firstHeaders = response['data']['parent1'];
      let secondHeaders = response['data']['parent2'];
      let thirdHeaders = response['data']['headers'];

      let moglixSummaryArray = response['data']['result'];
      let moglixSummaryNewArray = [];
      for (let l = 0; l < moglixSummaryArray.length; l++) {

        let temprow = {};

        if (moglixSummaryArray[l][response['data']['headers'][1]] && moglixSummaryArray[l][response['data']['headers'][1]].includes('Grand Total')) {
          for (let h = 0; h < response['data']['headers'].length; h++) {
            if (response['data']['headers'][h] == 'Vertical' || response['data']['headers'][h] == 'BU' ||
              response['data']['headers'][h] == 'Zone' || response['data']['headers'][h] == 'Region') {
              temprow[response['data']['headers'][h]] = '<b>' + moglixSummaryArray[l][response['data']['headers'][h]] + '</b>';
            }


            if (response['data']['headers'][h].includes("Actual")) {
              if ((moglixSummaryArray[l][response['data']['headers'][h - 1]]) != 0 && moglixSummaryArray[l][response['data']['headers'][h]] != 0) {

                if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) >= 0 && Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) <= 84)
                  temprow[response['data']['headers'][h]] = '<b style="color:red;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</b>'
                else if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) >= 85 && Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) <= 95)
                  temprow[response['data']['headers'][h]] = "<b style='color:#e59a0f;'>" + moglixSummaryArray[l][response['data']['headers'][h]] + "</b>";
                else if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) > 95)
                  temprow[response['data']['headers'][h]] = "<b style='color:green;'>" + moglixSummaryArray[l][response['data']['headers'][h]] + "</b>";
                else
                  temprow[response['data']['headers'][h]] = '<b style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + "--" + moglixSummaryArray[l][response['data']['headers'][h - 1]] + '</b>'

              }

              if ((moglixSummaryArray[l][response['data']['headers'][h - 1]]) == 0) {

                temprow[response['data']['headers'][h]] = '<b style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</b>'

              }
            }
            if (response['data']['headers'][h].includes("Actual") && moglixSummaryArray[l][response['data']['headers'][h]] == 0) {
              temprow[response['data']['headers'][h]] = '<i style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</i>'
            }
            if (response['data']['headers'][h].includes("Target")) {
              temprow[response['data']['headers'][h]] = '<span style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</span>'
            }
            if (response['data']['headers'][h].includes("Growth")) {
              temprow[response['data']['headers'][h]] = '<span style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</span>'
            }
          }
          moglixSummaryNewArray[m++] = temprow;

        }

        else if (!(moglixSummaryArray[l][response['data']['headers'][1]] && moglixSummaryArray[l][response['data']['headers'][1]].includes('Grand Total'))) {
          for (let h = 0; h < response['data']['headers'].length; h++) {

            if (response['data']['headers'][h] == 'Vertical' || response['data']['headers'][h] == 'BU' ||
              response['data']['headers'][h] == 'Zone' || response['data']['headers'][h] == 'Region') {
              temprow[response['data']['headers'][h]] = moglixSummaryArray[l][response['data']['headers'][h]];
            }


            if (response['data']['headers'][h].includes("Actual")) {
              //temprow[response['data']['headers'][h]] = '<b style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</b>'
              if ((moglixSummaryArray[l][response['data']['headers'][h - 1]]) != 0 && moglixSummaryArray[l][response['data']['headers'][h]] != 0) {

                if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) >= 0 && Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) <= 84)
                  temprow[response['data']['headers'][h]] = '<b style="color:red;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</b>'
                else if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) >= 85 && Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) <= 95)
                  temprow[response['data']['headers'][h]] = "<b style='color:#e59a0f;'>" + moglixSummaryArray[l][response['data']['headers'][h]] + "</b>";
                else if (Math.round((moglixSummaryArray[l][response['data']['headers'][h]] / moglixSummaryArray[l][response['data']['headers'][h - 1]]) * 100) > 95)
                  temprow[response['data']['headers'][h]] = "<b style='color:green;'>" + moglixSummaryArray[l][response['data']['headers'][h]] + "</b>";
                else
                  temprow[response['data']['headers'][h]] = '<b style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + "--" + moglixSummaryArray[l][response['data']['headers'][h - 1]] + '</b>'

              }






              if ((moglixSummaryArray[l][response['data']['headers'][h - 1]]) == 0) {

                temprow[response['data']['headers'][h]] = '<span style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</span>'

              }
            }
            if (response['data']['headers'][h].includes("Actual") && moglixSummaryArray[l][response['data']['headers'][h]] == 0) {
              temprow[response['data']['headers'][h]] = '<i style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</i>'
            }
            if (response['data']['headers'][h].includes("Target")) {
              temprow[response['data']['headers'][h]] = '<span style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</span>'
            }
            if (response['data']['headers'][h].includes("Growth")) {
              temprow[response['data']['headers'][h]] = '<span style="color:black;">' + moglixSummaryArray[l][response['data']['headers'][h]] + '</span>'
            }
          }
          moglixSummaryNewArray[m++] = temprow;

        }

      }


      console.log(moglixSummaryNewArray)









      let k = 0;
      let p = 0;
      let parent = response['data']['parent1'];
      // let parent2 = ["Mar-21", "Mar-21", "Feb-21", "Feb-21", "Growth %", "Mar-21", "Mar-21", "Feb-21", "Feb-21", "Growth %"];
      let parent2 = [];
      let j = 0;
      //Mar-21_GMV_Targeto hoo i have started that 
      for (let t = 3; t < thirdHeaders.length; t++) {
        parent2[j] = (thirdHeaders[t].slice(0, 6));
        if (t == 8 || t == 15) {
          parent2[j] = "Growth %";
        }
        j++;
      }
      console.log(parent2)
      let parentthHeader = '<th colspan="2" style="border-bottom: none!important;z-index: 500;position: fixed;width: 230px;height:19px;">Values in Lakh</th>';
      let parentthHeader2 = '<th style="position: relative;" colspan="2"><span style="aglin:right!important"></span></th>';


      for (let f = 0; f < firstHeaders.length; f++) {
        if (f == 0)
          parentthHeader = parentthHeader + '<th style="position: relative;left: 84px;border-bottom: none;" colspan="7">' + parent[0] + '</th>';
        if (f == 7)
          parentthHeader = parentthHeader + '<th style="position: relative;left: 83px;" colspan="7">' + parent[7] + '</th>';
      }

      for (let p = 0; p < parent2.length; p++) {
        if (p == 0) {
          parentthHeader2 = '<th colspan="2"><span style="aglin:right!important">' + parent2[p] + '</span></th>';
        }
        if (p != 0) {
          if (parent2[p] != "Growth %") {

            parentthHeader2 = parentthHeader2 + '<th colspan="2">' + parent2[p] +'</th>';
            p = p + 1;
          }
          if (parent2[p] == "Growth %") {
            parentthHeader2 = parentthHeader2+'<th  colspan="0.5">' + parent2[p] +'</th>';
          }
     
        }

      }
      console.log(parentthHeader2)
      parentthHeader = '<tr style="background-color:#6F6F6F;color:white;" >' + parentthHeader + '</tr>' + '<tr style="background-color:#6F6F6F;color:white;" ><th colspan="2"></th>' + parentthHeader2 + '</tr>'



      response['data']['result'] = moglixSummaryNewArray;

      this.moglixSummaryobj = this.buService.postMoglixSummary(response);

      if (this.moglixSummaryobj['table']) {

        this.userId.saveInfo(environment.user_tracking_api, { 'username': this.userId_val, 'report_name': 'BU Moglix Summary' }).subscribe(reportdata => {
          console.log(reportdata);
        })
        this.dtOptionsMoglixSummary = {};

        this.dtOptionsMoglixSummary = this.moglixSummaryobj['table'];
        if (this.searchVal == 1) {

          this.dtOptionsMoglixSummary["headerCallback"] = function (thead, data, start, end, display) {
            console.log(self.searchVal);
            if (self.searchVal == 1) {
              console.log(parent)

              $(thead).before(parentthHeader)
              this.flagSearch = true;
              self.searchVal = self.searchVal + 1;

            }
            else {
              console.log()
            }
          }
        }
        else {
          console.log()
        }
        this.tableDisplaymoglixSummary = true;

      }

    })



  }
  ngOnChanges(changes: SimpleChanges) {

  }
  exportAsXLSX() {
    if (this.viewDetailsTable == 'summary') {
      this.excelData = this.summaryobj['excel'];
      this.Service.exportAsExcelFile(this.excelData, 'VMI Projection');
    }
  }

}
