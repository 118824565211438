export class UrlConfig {

  static accessMapping = 'team_region_mapping';
  static loginUrl =      '/auth/microsoft';
  static getGraphAPI =   '/graphs';
  static getMapDataAPI = '/region/trend';
  static getSMEAPI =     '/spec';
  static getSSDEAPI=     '/ssde';
  static sme_summary = 'sme_summary';
  static sme_inside_sales ='sme_inside_sales';  
static sme_self_serve ='sme_self_serve';  
//self Serve
  static pivottablefiltersinput = 'request';
  static datatable = 'query';
  //buscorecard
  static buUpload = 'upload_static_data';
  static bu_scorecard = 'bu_scorecard';
}

