import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AdvancePOSupplyService {

  
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  readonly item_es_apiBaseURL = environment.baseURL_FREQ;

  constructor(private http: HttpClient) { }

  post(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['po_data']['headers'];
    this.finalresult = finalpostObj['po_data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postmrn(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['po_mrn']['headers'];
    this.finalresult = finalpostObj['po_mrn']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  saveInfo(urlpath, data) {
  
    return this.http.post(urlpath, data)
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = Number(obj[key]);
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);

    this.dtOptions = {
      
      ordering: false,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      fixedHeader: {
        header: true
    },
      scrollY: '70vh',
      paging: false,
      data: tableData,
      columns: this.headers,
      dom: '<"top"i><"bottom"flp>rt<"clear">',     
      fixedColumns:{
       leftColumns:2
     },
      columnDefs: [
        {

          width: 300,
          // "visible": false, "targets": 0,
          render: function (data, type, row) {
            let GMpercent = (row["Index"] >0) ? true : false;
            let Trendingpercent = (row["Attributes"] == "% Trending") ? true : false;
            let Over30Days = (row["Attributes"] == "Overdue > 30 Days (Lakhs)") ? true : false;;
            let AchievedLakhs = (row["Attributes"] == "Achieved (Lakhs)") ? true : false;
            let NetSalesExcludingTaxLakhs = (row["Attributes"] == "Net Sales Excluding Tax (Lakhs)") ? true : false;

            if (GMpercent || Trendingpercent || Over30Days || AchievedLakhs || NetSalesExcludingTaxLakhs)
              return '<b id="cellvalue">' + data + '</b>';
            else               
              return '<span id="cellvalue">' + data + '</span>';
            
          }

        }
      ],
      language: {
        info: this.timestampval,

        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }

  getDateItemTrackingES() {
    return this.http.get(this.item_es_apiBaseURL + "item_tracking/get_date_limit");
  }   
}
