import { Injectable } from '@angular/core';


import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {CommonService} from './shared/common.service';
// import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorsService implements HttpInterceptor {
  count = 0;

  constructor(private spinner: NgxSpinnerService, private commonService: CommonService ) { }


// intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let userToken = localStorage.getItem('authUser');
    if(userToken){
     // console.log(userToken)
      userToken = userToken.replace(/"/g, '');
      request = request.clone({headers:request.headers.set('Authorization', 'Bearer ' + userToken),withCredentials: false});
    }
    this.startRequest();
    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.endRequest();
          }
        }, error => {
          this.endRequest();
          if(error.status === 401){
            console.log(error.error.message)
            if(error.error.message.includes("User vertical details are not found"))
            {
              this.commonService.showToast("Portal down", 4);
            }
            else{
              this.commonService.showToast(error.error.message,4)
            }
          }
          
         // console.error(error.status);
        })
      );
  }

  startRequest() {
    this.count += 1;
    this.spinner.show();
  }

  endRequest() {
    this.count -= 1;
    if (this.count <= 0) {
      this.spinner.hide();
    }
  }


}
