export class PinCodes {
  static pins =
    {
      '110004': {'lat': '28.6453', 'name': 'Rashtrapati Bhawan,Central Delhi,Delhi', 'lon': '77.2128'},
      '110005': {'lat': '28.6551', 'name': 'Sat Nagar,Central Delhi,Delhi', 'lon': '77.1833'},
      '110006': {'lat': '28.6453', 'name': 'Jama Masjid,Central Delhi,Delhi', 'lon': '77.2128'},
      '110007': {'lat': '28.6764', 'name': 'Kamla Nagar (North Delhi),North Delhi,Delhi', 'lon': '77.2074'},
      '110001': {'lat': '28.6369', 'name': 'Sansad Marg H.O,Central Delhi,Delhi', 'lon': '77.2183'},
      '110002': {'lat': '28.6453', 'name': 'Ajmeri Gate Extn.,Central Delhi,Delhi', 'lon': '77.2456'},
      '110003': {'lat': '28.5947', 'name': 'C G O Complex,South Delhi,Delhi', 'lon': '77.2264'},
      '736182': {'lat': '26.8477', 'name': 'Jaigaon,Alipurduar,West Bengal', 'lon': '89.3756'},
      '110008': {'lat': '28.6453', 'name': 'Patel Nagar (Central Delhi),Central Delhi,Delhi', 'lon': '77.2128'},
      '110009': {'lat': '28.7121', 'name': 'H.S.Sangh,North West Delhi,Delhi', 'lon': '77.206'},
      '825407': {'lat': '24.5087', 'name': 'Dhorakola,Koderma,Jharkhand', 'lon': '85.7236'},
      '472331': {'lat': '25.1088', 'name': 'Mahobachak,Tikamgarh,Madhya Pradesh', 'lon': '78.9962'},
      '185102': {'lat': '33.7935', 'name': 'Salunian,Poonch,Jammu & Kashmir', 'lon': '74.2643'},
      '185101': {'lat': '33.7633', 'name': 'Nangali Sahib,Poonch,Jammu & Kashmir', 'lon': '74.155'},
      '246174': {'lat': '30.2139', 'name': 'Dungripanth,Pauri Garhwal,Uttarakhand', 'lon': '78.8041'},
      '472337': {'lat': '25.1893', 'name': 'Jerakhas,Tikamgarh,Madhya Pradesh', 'lon': '78.6717'},
      '472336': {'lat': '25.1911', 'name': 'SakaeraBhadaran,Tikamgarh,Madhya Pradesh', 'lon': '78.7857'},
      '577111': {'lat': '13.2356', 'name': 'Gullanpet,Chikkamagaluru,Karnataka', 'lon': '75.6599'},
      '562112': {'lat': '12.7642', 'name': 'Karikaldoddi,Ramanagar,Karnataka', 'lon': '76.7468'},
      '577113': {'lat': '13.1529', 'name': 'Hosahalli,Chikkamagaluru,Karnataka', 'lon': '75.5637'},
      '577112': {'lat': '13.3714', 'name': 'Melpal,Chikkamagaluru,Karnataka', 'lon': '75.4125'},
      '577115': {'lat': '13.3646', 'name': 'Umblebylu,Shivamogga,Karnataka', 'lon': '75.5804'},
      '577114': {'lat': '13.5561', 'name': 'Shanthigrama,Chikkamagaluru,Karnataka', 'lon': '75.2773'},
      '577117': {'lat': '13.3987', 'name': 'Hiregadde,Chikkamagaluru,Karnataka', 'lon': '75.422'},
      '577116': {'lat': '13.5741', 'name': 'Hogarehalli,Chikkamagaluru,Karnataka', 'lon': '75.8892'},
      '148101': {'lat': '30.428', 'name': 'Ncc Barnala,Barnala,Punjab', 'lon': '75.5239'},
      '755049': {'lat': '20.5266', 'name': 'Krushnapur Sasan,Jajapur,Odisha', 'lon': '83.4527'},
      '581358': {'lat': '14.6436', 'name': 'Kupgadde,Uttara Kannada,Karnataka', 'lon': '74.9227'},
      '581359': {'lat': '14.853', 'name': 'Kannadgal,Uttara Kannada,Karnataka', 'lon': '74.6895'},
      '581356': {'lat': '14.4937', 'name': 'Tergaon,Uttara Kannada,Karnataka', 'lon': '74.8009'},
      '581357': {'lat': '14.3679', 'name': 'Kodsani,Uttara Kannada,Karnataka', 'lon': '74.5042'},
      '581354': {'lat': '14.0297', 'name': 'Shirali Bazar,Uttara Kannada,Karnataka', 'lon': '74.528'},
      '581355': {'lat': '14.3357', 'name': 'Avarguppa,Uttara Kannada,Karnataka', 'lon': '74.874'},
      '581352': {'lat': '14.4937', 'name': 'Kanasagiri,Uttara Kannada,Karnataka', 'lon': '74.8009'},
      '581353': {'lat': '14.4937', 'name': 'Manjuguni,Uttara Kannada,Karnataka', 'lon': '74.8009'},
      '581350': {'lat': '14.7941', 'name': 'Bailur,Uttara Kannada,Karnataka', 'lon': '74.6229'},
      '581351': {'lat': '14.9497', 'name': 'Hubbangeri,Uttara Kannada,Karnataka', 'lon': '74.6556'},
      '571434': {'lat': '12.5712', 'name': 'Pattasomanahalli,Mandya,Karnataka', 'lon': '76.6399'},
      '497553': {'lat': '22.8542', 'name': 'Kurasia Colly,Koriya,Chattisgarh', 'lon': '82.8212'},
      '497555': {'lat': '22.8542', 'name': 'Godripara,Koriya,Chattisgarh', 'lon': '82.8212'},
      '571435': {'lat': '12.48', 'name': 'Pandavapura R S,Mandya,Karnataka', 'lon': '76.6709'},
      '497559': {'lat': '22.8542', 'name': 'Gelhapani,Koriya,Chattisgarh', 'lon': '82.8212'},
      '614602': {'lat': '10.3986', 'name': 'Pannavayal,Thanjavur,Tamil Nadu', 'lon': '79.2456'},
      '614601': {'lat': '10.3986', 'name': 'Pattukottai H.O,Thanjavur,Tamil Nadu', 'lon': '79.2456'},
      '497001': {'lat': '22.6229', 'name': 'Barion,Surguja,Chattisgarh', 'lon': '82.8003'},
      '642134': {'lat': '10.5691', 'name': 'Ramanamudali Pudur,Coimbatore,Tamil Nadu', 'lon': '76.9894'},
      '642132': {'lat': '10.5195', 'name': 'Deepalapatti,Coimbatore,Tamil Nadu', 'lon': '76.9127'},
      '626116': {'lat': '9.443', 'name': 'Seshapuram,Virudhunagar,Tamil Nadu', 'lon': '77.8389'},
      '642130': {'lat': '10.5195', 'name': 'Karamalai Bazaar,Coimbatore,Tamil Nadu', 'lon': '76.9127'},
      '582113': {'lat': '15.3613', 'name': 'Hirewaddatti,Gadag,Karnataka', 'lon': '75.8483'},
      '812005': {'lat': '24.9717', 'name': 'Sahjatpur,Bhagalpur,Bihar', 'lon': '85.9349'},
      '812004': {'lat': '25.2442', 'name': 'Amribishapur,Bhagalpur,Bihar', 'lon': '86.6791'},
      '812007': {'lat': '25.1778', 'name': 'T. N. B College,Bhagalpur,Bihar', 'lon': '86.5176'},
      '812006': {'lat': '25.3523', 'name': 'Kishandaspur,Bhagalpur,Bihar', 'lon': '87.1805'},
      '812001': {'lat': '25.2542', 'name': 'Mayaganj,Bhagalpur,Bihar', 'lon': '86.9867'},
      '571432': {'lat': '12.7776', 'name': 'Bramhadevarahalli,Mandya,Karnataka', 'lon': '76.7014'},
      '812003': {'lat': '25.2671', 'name': 'M M Ashram,Bhagalpur,Bihar', 'lon': '87.0205'},
      '639203': {'lat': '10.6802', 'name': 'Isanatham,Karur,Tamil Nadu', 'lon': '77.9845'},
      '712125': {'lat': '22.9775', 'name': 'Telinipara,Hooghly,West Bengal', 'lon': '88.1701'},
      '712124': {'lat': '22.8245', 'name': 'Nanda,Hooghly,West Bengal', 'lon': '88.3384'},
      '712123': {'lat': '22.9333', 'name': 'Bandel Junction,Hooghly,West Bengal', 'lon': '88.3667'},
      '712122': {'lat': '23.0762', 'name': 'Selampur,Hooghly,West Bengal', 'lon': '87.9877'},
      '712121': {'lat': '22.9775', 'name': 'Addconagar,Hooghly,West Bengal', 'lon': '88.1701'},
      '755022': {'lat': '19.4846', 'name': 'Korei,Jajapur,Odisha', 'lon': '84.6204'},
      '458330': {'lat': '25.6565', 'name': 'Athana,Neemuch,Madhya Pradesh', 'lon': '76.7198'},
      '626117': {'lat': '9.443', 'name': 'Pudupalayam (Virudhunagar),Virudhunagar,Tamil Nadu', 'lon': '77.8389'},
      '458336': {'lat': '25.3131', 'name': 'Jeeran,Neemuch,Madhya Pradesh', 'lon': '76.2874'},
      '639202': {'lat': '10.8913', 'name': 'Uttupatti,Karur,Tamil Nadu', 'lon': '77.8343'},
      '382350': {'lat': '22.9868', 'name': 'Khodiyarnagar,Ahmedabad,Gujarat', 'lon': '72.5632'},
      '582116': {'lat': '15.1015', 'name': 'Balehosur,Gadag,Karnataka', 'lon': '75.5297'},
      '382355': {'lat': '22.7699', 'name': 'Isanpur-mota,Gandhi Nagar,Gujarat', 'lon': '72.604'},
      '562119': {'lat': '13.0925', 'name': 'Herandyappanahalli,Ramanagar,Karnataka', 'lon': '76.8351'},
      '225126': {'lat': '26.6374', 'name': 'Trivediganj,Barabanki,Uttar Pradesh', 'lon': '81.4163'},
      '639205': {'lat': '10.7345', 'name': 'Inunganur,Karur,Tamil Nadu', 'lon': '77.9479'},
      '734311': {'lat': '27.1539', 'name': 'Kagay Bazar,Kalimpong,West Bengal', 'lon': '88.6145'},
      '734312': {'lat': '27.0639', 'name': 'Tista Bridge,Kalimpong,West Bengal', 'lon': '88.426'},
      '734313': {'lat': '27.0886', 'name': 'School Dhura,Darjiling,West Bengal', 'lon': '88.5287'},
      '734314': {'lat': '27.086', 'name': 'Kafer,Kalimpong,West Bengal', 'lon': '88.6617'},
      '734315': {'lat': '27.0886', 'name': 'Burmeck,Kalimpong,West Bengal', 'lon': '88.5287'},
      '734316': {'lat': '27.0886', 'name': 'Sindebong,Kalimpong,West Bengal', 'lon': '88.5287'},
      '734317': {'lat': '27.0886', 'name': 'Echhey,Darjiling,West Bengal', 'lon': '88.5287'},
      '470881': {'lat': '23.5844', 'name': 'Patnakuya,Damoh,Madhya Pradesh', 'lon': '79.6847'},
      '194101': {'lat': '33.96195', 'name': 'Durbuk,Leh,Jammu & Kashmir', 'lon': '	77.35864'},
      '493449': {'lat': '21.1034', 'name': 'Samhar,Mahasamund,Chattisgarh', 'lon': '82.3268'},
      '194103': {'lat': '34.6072', 'name': 'Shaimshakharboo,Kargil,Jammu & Kashmir', 'lon': '76.1861'},
      '194102': {'lat': '34.3669', 'name': 'Pandrass,Kargil,Jammu & Kashmir', 'lon': '75.5907'},
      '194105': {'lat': '33.96195', 'name': 'Bazgo,Leh,Jammu & Kashmir', 'lon': '77.35864'},
      '194104': {'lat': '34.31392', 'name': 'Mathoo,Leh,Jammu & Kashmir', 'lon': '77.53037'},
      '194107': {'lat': '	34.2903253', 'name': 'Likir,Leh,Jammu & Kashmir', 'lon': '	77.2132056'},
      '194106': {'lat': '34.2903253', 'name': 'Takmachik,Leh,Jammu & Kashmir', 'lon': '77.2132056'},
      '194109': {'lat': '34.5099', 'name': 'Khangral,Kargil,Jammu & Kashmir', 'lon': '76.4496'},
      '454441': {'lat': '22.921', 'name': 'Keshvi,Dhar,Madhya Pradesh', 'lon': '75.667'},
      '341533': {'lat': '27.06', 'name': 'Lichana,Nagaur,Rajasthan', 'lon': '74.9172'},
      '791122': {'lat': '27.9911', 'name': 'Nacho,Upper Subansiri,Arunachal Pradesh', 'lon': '94.2295'},
      '752002': {'lat': '20.1512', 'name': 'Mahodadhi,Puri,Odisha', 'lon': '85.6629'},
      '791120': {'lat': '27.444', 'name': 'Hari,Lower Subansiri,Arunachal Pradesh', 'lon': '93.6761'},
      '791121': {'lat': '27.5535', 'name': 'Kimin,Papum Pare,Arunachal Pradesh', 'lon': '93.7868'},
      '248018': {'lat': '30.4819', 'name': 'Mehunwala,Dehradun,Uttarakhand', 'lon': '77.8114'},
      '472339': {'lat': '24.8391', 'name': 'Dhamanakha,Tikamgarh,Madhya Pradesh', 'lon': '79.045'},
      '583216': {'lat': '14.9366', 'name': 'Karadi Ayyanahalli,Ballari,Karnataka', 'lon': '75.7304'},
      '571610': {'lat': '12.3853', 'name': 'Chikkadaganahalli,Mysuru,Karnataka', 'lon': '76.2352'},
      '231213': {'lat': '24.705', 'name': 'Retikala,Sonbhadra,Uttar Pradesh', 'lon': '83.2192'},
      '614901': {'lat': '10.4847', 'name': 'Kannugudi,Thanjavur,Tamil Nadu', 'lon': '79.3414'},
      '444810': {'lat': '21.5088', 'name': 'Sirasgaon Kasba,Amravati,Maharashtra', 'lon': '77.5277'},
      '392110': {'lat': '21.9932', 'name': 'Bhuva,Bharuch,Gujarat', 'lon': '72.8705'},
      '755020': {'lat': '20.733', 'name': 'Ferro Chrome Project,Jajapur,Odisha', 'lon': '85.8278'},
      '562111': {'lat': '13.4056', 'name': 'Shivagange,Bangalore Rural,Karnataka', 'lon': '76.9897'},
      '722122': {'lat': '22.9478', 'name': 'Janta,Bankura,West Bengal', 'lon': '87.9687'},
      '824112': {'lat': '24.7682', 'name': 'R S. Bigha,Aurangabad(BH),Bihar', 'lon': '84.2624'},
      '678624': {'lat': '10.7555', 'name': 'Walayar Dam,Palakkad,Kerala', 'lon': '76.7709'},
      '678623': {'lat': '10.7555', 'name': 'Kanjikode West,Palakkad,Kerala', 'lon': '76.7709'},
      '678622': {'lat': '10.7555', 'name': 'Elapulli South,Palakkad,Kerala', 'lon': '76.7709'},
      '678621': {'lat': '10.7555', 'name': 'Pampampallam,Palakkad,Kerala', 'lon': '76.7709'},
      '734226': {'lat': '27.019', 'name': 'Geile Banjyang,Darjiling,West Bengal', 'lon': '88.378'},
      '627133': {'lat': '8.2792', 'name': 'Mahendragiri,Tirunelveli,Tamil Nadu', 'lon': '77.6031'},
      '562117': {'lat': '12.5828', 'name': 'Torebekuppe,Ramanagar,Karnataka', 'lon': '77.2467'},
      '387320': {'lat': '22.1873', 'name': 'Silod,Kheda,Gujarat', 'lon': '72.5899'},
      '387325': {'lat': '22.6124', 'name': 'Kanjari,Kheda,Gujarat', 'lon': '72.9211'},
      '628151': {'lat': '9.1863', 'name': 'Punnakayal,Tuticorin,Tamil Nadu', 'lon': '78.4321'},
      '673639': {'lat': '11.2372', 'name': 'Maithra,Malappuram,Kerala', 'lon': '76.0659'},
      '673638': {'lat': '11.2727', 'name': 'Muthuvallur,Malappuram,Kerala', 'lon': '76.056'},
      '824111': {'lat': '24.5675', 'name': 'Matpa,Aurangabad(BH),Bihar', 'lon': '84.2602'},
      '628152': {'lat': '8.6582', 'name': 'Pazhaiyalkayal,Tuticorin,Tamil Nadu', 'lon': '78.0737'},
      '756030': {'lat': '21.6028', 'name': 'Amarda Road,Baleswar,Odisha', 'lon': '86.3771'},
      '686583': {'lat': '9.4741', 'name': 'Kidangoor South,Kottayam,Kerala', 'lon': '76.6794'},
      '686582': {'lat': '9.4741', 'name': 'Kunnonni,Kottayam,Kerala', 'lon': '76.6794'},
      '686581': {'lat': '9.4741', 'name': 'Chennad,Kottayam,Kerala', 'lon': '76.6794'},
      '686580': {'lat': '9.7034', 'name': 'Adukkam,Kottayam,Kerala', 'lon': '76.8128'},
      '673635': {'lat': '11.2333', 'name': 'Calicut University,Malappuram,Kerala', 'lon': '76.0375'},
      '673634': {'lat': '10.7379', 'name': 'Kolakkattuchali,Malappuram,Kerala', 'lon': '76.2518'},
      '673637': {'lat': '11.6978', 'name': 'Andiyoorkunnu,Malappuram,Kerala', 'lon': '76.1702'},
      '673636': {'lat': '11.2333', 'name': 'Puthur-pallikkal,Malappuram,Kerala', 'lon': '76.0375'},
      '734227': {'lat': '26.9709', 'name': 'Soureni Bazar,Darjiling,West Bengal', 'lon': '88.3462'},
      '207121': {'lat': '27.4388', 'name': 'Kawar,Etah,Uttar Pradesh', 'lon': '78.7447'},
      '176062': {'lat': '31.9605', 'name': 'Auc Palampur,Kangra,Himachal Pradesh', 'lon': '76.5313'},
      '176063': {'lat': '31.9605', 'name': 'Sagoor,Kangra,Himachal Pradesh', 'lon': '76.5313'},
      '176060': {'lat': '31.8742', 'name': 'Dhoran,Kangra,Himachal Pradesh', 'lon': '76.3242'},
      '176061': {'lat': '31.9492', 'name': 'Deogram,Kangra,Himachal Pradesh', 'lon': '76.5743'},
      '176066': {'lat': '31.9605', 'name': 'Ghallour,Kangra,Himachal Pradesh', 'lon': '76.5313'},
      '176064': {'lat': '32.0928', 'name': 'Bhattoo Samula,Kangra,Himachal Pradesh', 'lon': '76.4924'},
      '176065': {'lat': '31.9935', 'name': 'Rajhoon,Kangra,Himachal Pradesh', 'lon': '76.4765'},
      '824118': {'lat': '25.1566', 'name': 'Malpa,Jehanabad,Bihar', 'lon': '85.0301'},
      '383006': {'lat': '23.6807', 'name': 'Boria (Sabarkantha),Sabarkantha,Gujarat', 'lon': '73.0601'},
      '690501': {'lat': '9.1266', 'name': 'Kaduvinal,Alappuzha,Kerala', 'lon': '76.5807'},
      '690502': {'lat': '9.1694', 'name': 'Kayangulam H.O,Alappuzha,Kerala', 'lon': '76.591'},
      '690503': {'lat': '9.1761', 'name': 'Pallickal Naduvilemuri,Alappuzha,Kerala', 'lon': '76.5633'},
      '690504': {'lat': '9.1588', 'name': 'Pallickal -nooranad,Pathanamthitta,Kerala', 'lon': '76.6395'},
      '690505': {'lat': '9.1694', 'name': 'Vedaraplavu,Alappuzha,Kerala', 'lon': '76.591'},
      '690506': {'lat': '9.1694', 'name': 'Choolatheruvu,Alappuzha,Kerala', 'lon': '76.591'},
      '383001': {'lat': '23.6807', 'name': 'Jambudi,Sabarkantha,Gujarat', 'lon': '73.0601'},
      '690508': {'lat': '9.1694', 'name': 'Pathiyoor,Alappuzha,Kerala', 'lon': '76.591'},
      '690509': {'lat': '9.246', 'name': 'Kollakadavu,Alappuzha,Kerala', 'lon': '76.5887'},
      '532284': {'lat': '18.9442', 'name': 'Brc Puram,Srikakulam,Andhra Pradesh', 'lon': '84.5845'},
      '363520': {'lat': '22.248', 'name': 'Kherdi,Surendra Nagar,Gujarat', 'lon': '71.0419'},
      '754028': {'lat': '20.1521', 'name': 'Agrahat,Cuttack,Odisha', 'lon': '85.0591'},
      '754029': {'lat': '20.5697', 'name': 'Athagarh College,Cuttack,Odisha', 'lon': '85.4842'},
      '497226': {'lat': '22.9108', 'name': 'Kumda Colly,Surguja,Chattisgarh', 'lon': '82.7928'},
      '796581': {'lat': '22.9284', 'name': 'Bualpui H,Lunglei,Mizoram', 'lon': '93.0987'},
      '227809': {'lat': '26.5398', 'name': 'Rachkheta,Sultanpur,Uttar Pradesh', 'lon': '81.9445'},
      '755044': {'lat': '20.5266', 'name': 'Darpanigarh,Jajapur,Odisha', 'lon': '83.4527'},
      '141121': {'lat': '30.9575', 'name': 'Pawat,Ludhiana,Punjab', 'lon': '75.9386'},
      '141120': {'lat': '30.8087', 'name': 'Bilga,Ludhiana,Punjab', 'lon': '75.8382'},
      '141123': {'lat': '30.9575', 'name': 'Sahibana,Ludhiana,Punjab', 'lon': '75.9386'},
      '141122': {'lat': '30.8289', 'name': 'Jaspal Banger,Ludhiana,Punjab', 'lon': '75.8919'},
      '141125': {'lat': '30.9024', 'name': 'Sihala,Ludhiana,Punjab', 'lon': '76.289'},
      '792055': {'lat': '27.9126', 'name': 'Two Hut,Changlang,Arunachal Pradesh', 'lon': '96.1288'},
      '141127': {'lat': '31.5327', 'name': 'Chhounta,Ludhiana,Punjab', 'lon': '75.1683'},
      '768233': {'lat': '21.6748', 'name': 'Orient Colliery Brajarajnagar,Jharsuguda,Odisha', 'lon': '83.778'},
      '700014': {'lat': '22.1745', 'name': 'Linton Street,Kolkata,West Bengal', 'lon': '88.4933'},
      '600031': {'lat': '13.07', 'name': 'Chetput,Chennai,Tamil Nadu', 'lon': '80.2408'},
      '609404': {'lat': '11.0259', 'name': 'Manganallur,Nagapattinam,Tamil Nadu', 'lon': '79.6301'},
      '609403': {'lat': '10.9915', 'name': 'Kaliyakudi,Tiruvarur,Tamil Nadu', 'lon': '79.7113'},
      '609402': {'lat': '11.0347', 'name': 'Perambur,Nagapattinam,Tamil Nadu', 'lon': '79.6969'},
      '609401': {'lat': '11.0559', 'name': 'Elandangudi,Nagapattinam,Tamil Nadu', 'lon': '79.6495'},
      '246722': {'lat': '29.2389', 'name': 'Birla Farm,Bijnor,Uttar Pradesh', 'lon': '78.6571'},
      '273165': {'lat': '26.8533', 'name': 'Gangibazar,Gorakhpur,Uttar Pradesh', 'lon': '83.0242'},
      '362650': {'lat': '21.8008', 'name': 'Chikhlodra,Junagadh,Gujarat', 'lon': '70.4998'},
      '781125': {'lat': '26.0637', 'name': 'Dhengargaon,Kamrup,Assam', 'lon': '91.517'},
      '273164': {'lat': '26.8089', 'name': 'Akasarawa,Maharajganj,Uttar Pradesh', 'lon': '83.0058'},
      '331021': {'lat': '28.2205', 'name': 'Ratan Nagar,Churu,Rajasthan', 'lon': '74.923'},
      '755024': {'lat': '20.7318', 'name': 'Thannual,Jajapur,Odisha', 'lon': '86.1093'},
      '755043': {'lat': '20.5266', 'name': 'Beruda,Jajapur,Odisha', 'lon': '83.4527'},
      '711227': {'lat': '22.7038', 'name': 'Ghoshpara (Howrah),Howrah,West Bengal', 'lon': '88.5234'},
      '711226': {'lat': '22.526', 'name': 'Rampur,Howrah,West Bengal', 'lon': '88.0676'},
      '246727': {'lat': '28.9689', 'name': 'Nagli Pathwari,Bijnor,Uttar Pradesh', 'lon': '78.4378'},
      '331023': {'lat': '28.6205', 'name': 'Gagadwas,Churu,Rajasthan', 'lon': '75.3673'},
      '246726': {'lat': '29.3027', 'name': 'Paijaniya,Bijnor,Uttar Pradesh', 'lon': '78.2819'},
      '735209': {'lat': '26.7644', 'name': 'Damdim,Jalpaiguri,West Bengal', 'lon': '88.8616'},
      '735208': {'lat': '26.7644', 'name': 'Dalsingpara,Alipurduar,West Bengal', 'lon': '88.8616'},
      '825316': {'lat': '23.76', 'name': 'Hesagarha,Ramgarh,Jharkhand', 'lon': '85.4895'},
      '331022': {'lat': '28.1724', 'name': 'Sahanali Bari,Churu,Rajasthan', 'lon': '74.6012'},
      '246725': {'lat': '29.1696', 'name': 'Roniya,Bijnor,Uttar Pradesh', 'lon': '78.3622'},
      '248013': {'lat': '30.1623', 'name': 'Kulhan,Dehradun,Uttarakhand', 'lon': '78.4813'},
      '391130': {'lat': '22.6032', 'name': 'Chorngala,Vadodara,Gujarat', 'lon': '73.4'},
      '735203': {'lat': '26.6771', 'name': 'Binnaguri,Jalpaiguri,West Bengal', 'lon': '88.4814'},
      '735202': {'lat': '26.792', 'name': 'Mogolkata,Jalpaiguri,West Bengal', 'lon': '89.0287'},
      '391135': {'lat': '22.2345', 'name': 'Nana Amadara,Vadodara,Gujarat', 'lon': '73.5717'},
      '735204': {'lat': '26.7042', 'name': 'Khagenhat,Alipurduar,West Bengal', 'lon': '89.1455'},
      '626119': {'lat': '9.3733', 'name': 'V.Muthulingapuram,Virudhunagar,Tamil Nadu', 'lon': '77.5853'},
      '331024': {'lat': '28.1636', 'name': 'Takhalsar,Sikar,Rajasthan', 'lon': '75.0728'},
      '518001': {'lat': '15.6835', 'name': 'Kurnool Bazar,Kurnool,Andhra Pradesh', 'lon': '78.1919'},
      '518002': {'lat': '15.7074', 'name': 'KNL-camp-B,Kurnool,Andhra Pradesh', 'lon': '78.0736'},
      '518003': {'lat': '15.8162', 'name': 'Kalluru,Kurnool,Andhra Pradesh', 'lon': '78.0289'},
      '518004': {'lat': '15.8594', 'name': 'Tholisapuram,Kurnool,Andhra Pradesh', 'lon': '78.0011'},
      '518005': {'lat': '15.6835', 'name': 'Ashoknagar (Kurnool),Kurnool,Andhra Pradesh', 'lon': '78.1919'},
      '518006': {'lat': '14.4521', 'name': 'Stantanpuram,Kurnool,Andhra Pradesh', 'lon': '79.9939'},
      '518007': {'lat': '15.6835', 'name': 'B.Tandrapadu,Kurnool,Andhra Pradesh', 'lon': '78.1919'},
      '743424': {'lat': '22.614', 'name': 'Kachua,North 24 Parganas,West Bengal', 'lon': '88.5475'},
      '743425': {'lat': '22.4784', 'name': 'Khalisadi,North 24 Parganas,West Bengal', 'lon': '88.5822'},
      '751030': {'lat': '20.2597', 'name': 'Khandagiri,Khorda,Odisha', 'lon': '85.7899'},
      '331026': {'lat': '28.3878', 'name': 'Tamkore,Jhujhunu,Rajasthan', 'lon': '75.2402'},
      '470661': {'lat': '23.8191', 'name': 'Bijadongary,Damoh,Madhya Pradesh', 'lon': '79.684'},
      '743422': {'lat': '22.6191', 'name': 'Biramnagar,North 24 Parganas,West Bengal', 'lon': '88.8788'},
      '743423': {'lat': '22.6856', 'name': 'Gobardhanpur,North 24 Parganas,West Bengal', 'lon': '88.4668'},
      '628712': {'lat': '9.0403', 'name': 'Sakkammalpuram,Tuticorin,Tamil Nadu', 'lon': '78.123'},
      '799125': {'lat': '23.4668', 'name': 'Paschim Paticherri Colony,South Tripura,Tripura', 'lon': '91.4007'},
      '628716': {'lat': '9.3939', 'name': 'Nalatinputhur,Tuticorin,Tamil Nadu', 'lon': '78.2106'},
      '585321': {'lat': '16.9472', 'name': 'Minaspur,Yadgir,Karnataka', 'lon': '77.3265'},
      '628714': {'lat': '9.1045', 'name': 'Parivillikottai,Tuticorin,Tamil Nadu', 'lon': '77.7964'},
      '638701': {'lat': '11.3577', 'name': 'Sivanmalai,Erode,Tamil Nadu', 'lon': '78.1161'},
      '628718': {'lat': '8.8908', 'name': 'Achankulam,Tuticorin,Tamil Nadu', 'lon': '78.0044'},
      '497557': {'lat': '22.8542', 'name': 'Sonawani Colly,Koriya,Chattisgarh', 'lon': '82.8212'},
      '734424': {'lat': '26.6827', 'name': 'Bendubi,Darjiling,West Bengal', 'lon': '88.22'},
      '685609': {'lat': '9.2946', 'name': 'Thopramkduy,Idukki,Kerala', 'lon': '76.7223'},
      '685608': {'lat': '8.7401', 'name': 'Vengallur,Idukki,Kerala', 'lon': '76.8021'},
      '734425': {'lat': '26.6827', 'name': 'Chital Ghata,Darjiling,West Bengal', 'lon': '88.22'},
      '685601': {'lat': '9.2946', 'name': 'Kulamavu,Idukki,Kerala', 'lon': '76.7223'},
      '471408': {'lat': '24.435', 'name': 'Lakhanguwa,Chhatarpur,Madhya Pradesh', 'lon': '79.7571'},
      '685603': {'lat': '9.2946', 'name': 'Idukki Painavu,Idukki,Kerala', 'lon': '76.7223'},
      '685602': {'lat': '9.2946', 'name': 'Maniyaramkudy,Idukki,Kerala', 'lon': '76.7223'},
      '685605': {'lat': '9.8987', 'name': 'Ezhallur,Idukki,Kerala', 'lon': '76.7404'},
      '685604': {'lat': '9.2946', 'name': 'Rajamudi,Idukki,Kerala', 'lon': '76.7223'},
      '685607': {'lat': '9.2946', 'name': 'Mundanmudy,Idukki,Kerala', 'lon': '76.7223'},
      '685606': {'lat': '9.2451', 'name': 'Keerithode,Idukki,Kerala', 'lon': '76.6244'},
      '637208': {'lat': '10.8189', 'name': 'Kondalam,Namakkal,Tamil Nadu', 'lon': '78.7729'},
      '637209': {'lat': '11.3873', 'name': 'Chinnathambipalayam,Namakkal,Tamil Nadu', 'lon': '77.7972'},
      '585320': {'lat': '17.2713', 'name': 'Kerolli,Kalaburagi,Karnataka', 'lon': '77.3228'},
      '440019': {'lat': '21.1555', 'name': 'C.R.P.F. Nagpur,Nagpur,Maharashtra', 'lon': '79.0909'},
      '440018': {'lat': '21.1526', 'name': 'Ganjipeth,Nagpur,Maharashtra', 'lon': '79.0981'},
      '440015': {'lat': '21.1555', 'name': 'Narendra Nagar (Nagpur),Nagpur,Maharashtra', 'lon': '79.0909'},
      '440014': {'lat': '21.1793', 'name': 'Bezonbagh,Nagpur,Maharashtra', 'lon': '79.0923'},
      '440017': {'lat': '21.1555', 'name': 'Panchsheel Nagar,Nagpur,Maharashtra', 'lon': '79.0909'},
      '440016': {'lat': '21.1555', 'name': 'SRPF,Nagpur,Maharashtra', 'lon': '79.0909'},
      '637204': {'lat': '11.1403', 'name': 'Kapilamalai,Namakkal,Tamil Nadu', 'lon': '77.9709'},
      '440010': {'lat': '21.1555', 'name': 'Gokulpeth,Nagpur,Maharashtra', 'lon': '79.0909'},
      '440013': {'lat': '21.1555', 'name': 'Katolroad,Nagpur,Maharashtra', 'lon': '79.0909'},
      '440012': {'lat': '21.1346', 'name': 'Netaji Market,Nagpur,Maharashtra', 'lon': '79.0823'},
      '744209': {'lat': '12.6435', 'name': 'Uttara,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.8794'},
      '721201': {'lat': '22.9041', 'name': 'Ghatmura,West Midnapore,West Bengal', 'lon': '87.9015'},
      '584113': {'lat': '15.8964', 'name': 'Shavantagera,Raichur,Karnataka', 'lon': '76.6354'},
      '584111': {'lat': '16.2646', 'name': 'Kothadoddi,Raichur,Karnataka', 'lon': '76.9902'},
      '584116': {'lat': '15.0823', 'name': 'Herundi,Raichur,Karnataka', 'lon': '76.4502'},
      '584115': {'lat': '16.3831', 'name': 'Yerdona,Raichur,Karnataka', 'lon': '76.5727'},
      '450661': {'lat': '21.6807', 'name': 'Bilud,East Nimar,Madhya Pradesh', 'lon': '76.1212'},
      '584118': {'lat': '16.2618', 'name': 'Machanur,Raichur,Karnataka', 'lon': '76.7071'},
      '475110': {'lat': '25.7425', 'name': 'Mena,Gwalior,Madhya Pradesh', 'lon': '78.3168'},
      '825402': {'lat': '24.1161', 'name': 'Deokuli,Hazaribag,Jharkhand', 'lon': '85.4399'},
      '764014': {'lat': '19.1567', 'name': 'T.Gumandi,Koraput,Odisha', 'lon': '83.0443'},
      '791111': {'lat': '27.422', 'name': 'Itanagar H.O,Papum Pare,Arunachal Pradesh', 'lon': '93.6719'},
      '521163': {'lat': '16.4779', 'name': 'Bhadrirajupalem,Krishna,Andhra Pradesh', 'lon': '80.8534'},
      '521162': {'lat': '16.7656', 'name': 'Ramannapet,Krishna,Andhra Pradesh', 'lon': '81.4013'},
      '452015': {'lat': '22.6984', 'name': 'Industrial Estate (Indore),Indore,Madhya Pradesh', 'lon': '75.9292'},
      '521165': {'lat': '16.3228', 'name': 'Penamakuru,Krishna,Andhra Pradesh', 'lon': '80.8271'},
      '521164': {'lat': '16.3981', 'name': 'Kadavakollu,Krishna,Andhra Pradesh', 'lon': '80.8528'},
      '638672': {'lat': '10.7581', 'name': 'Selampalayam,Erode,Tamil Nadu', 'lon': '77.7443'},
      '638673': {'lat': '10.7581', 'name': 'Manakadavu,Erode,Tamil Nadu', 'lon': '77.7443'},
      '691590': {'lat': '8.9959', 'name': 'Koivila,Kollam,Kerala', 'lon': '76.7631'},
      '854318': {'lat': '25.9875', 'name': 'Mushari Idgah,Araria,Bihar', 'lon': '87.125'},
      '343002': {'lat': '25.2918', 'name': 'I.A.Jalore,Jalor,Rajasthan', 'lon': '72.8598'},
      '274603': {'lat': '26.0846', 'name': 'Karjahan,Deoria,Uttar Pradesh', 'lon': '83.3364'},
      '343001': {'lat': '25.2918', 'name': 'Rewatra,Jalor,Rajasthan', 'lon': '72.8598'},
      '313328': {'lat': '25.0954', 'name': 'Junda,Rajsamand,Rajasthan', 'lon': '74.1546'},
      '620015': {'lat': '10.7715', 'name': 'Tirunedungulam,Tiruchirappalli,Tamil Nadu', 'lon': '78.8526'},
      '313329': {'lat': '25.3642', 'name': 'Railmagra,Rajsamand,Rajasthan', 'lon': '73.7594'},
      '228161': {'lat': '26.2168', 'name': 'Magarsan Kala,Sultanpur,Uttar Pradesh', 'lon': '82.2576'},
      '825315': {'lat': '24.0332', 'name': 'Konardam,Hazaribag,Jharkhand', 'lon': '85.4957'},
      '452012': {'lat': '22.6984', 'name': 'Rajendra Nagar (Indore),Indore,Madhya Pradesh', 'lon': '75.9292'},
      '563101': {'lat': '13.1543', 'name': 'Kolar Gandhi Nagar,Kolar,Karnataka', 'lon': '78.1317'},
      '334603': {'lat': '28.4799', 'name': 'Dulmera,Bikaner,Rajasthan', 'lon': '74.2322'},
      '132122': {'lat': '29.5889', 'name': 'Atta,Panipat,Haryana', 'lon': '77.0049'},
      '382630': {'lat': '23.3145', 'name': 'Rupal,Gandhi Nagar,Gujarat', 'lon': '72.6011'},
      '203150': {'lat': '28.4396', 'name': 'Pondhery,Bulandshahr,Uttar Pradesh', 'lon': '78.4082'},
      '497229': {'lat': '23.0404', 'name': 'Ketka,Surguja,Chattisgarh', 'lon': '82.8247'},
      '136118': {'lat': '30.1487', 'name': 'Kurukshetra H.O,Kurukshetra,Haryana', 'lon': '76.6856'},
      '136119': {'lat': '29.8008', 'name': 'Hassanpur,Kurukshetra,Haryana', 'lon': '76.7816'},
      '277304': {'lat': '25.8516', 'name': 'Karammar,Ballia,Uttar Pradesh', 'lon': '84.114'},
      '136117': {'lat': '29.6084', 'name': 'Dubbal,Kaithal,Haryana', 'lon': '76.3941'},
      '790116': {'lat': '27.3143', 'name': 'Dedza,West Kameng,Arunachal Pradesh', 'lon': '92.3716'},
      '501111': {'lat': '17.4488', 'name': 'Chittigadda,K.V.Rangareddy,Telangana', 'lon': '78.1168'},
      '413103': {'lat': '20.4967', 'name': 'Lakhewadi,Pune,Maharashtra', 'lon': '75.6918'},
      '413102': {'lat': '18.0823', 'name': 'Dorlewadi,Pune,Maharashtra', 'lon': '75.5755'},
      '413101': {'lat': '18.3536', 'name': 'Sangaramnagar,Solapur,Maharashtra', 'lon': '75.8'},
      '480001': {'lat': '22.0365', 'name': 'Bankanaganpur,Chhindwara,Madhya Pradesh', 'lon': '79.0092'},
      '413107': {'lat': '18.2797', 'name': 'Tarangphal,Solapur,Maharashtra', 'lon': '75.5589'},
      '413106': {'lat': '19.4639', 'name': 'Bijawadi,Pune,Maharashtra', 'lon': '75.8748'},
      '413105': {'lat': '18.7053', 'name': 'Khanote,Pune,Maharashtra', 'lon': '75.4385'},
      '413104': {'lat': '19.1167', 'name': 'Katyachiwadi,Pune,Maharashtra', 'lon': '74.1'},
      '452013': {'lat': '22.6984', 'name': 'Indore Cat,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '413109': {'lat': '18.6894', 'name': 'Gursale,Solapur,Maharashtra', 'lon': '75.758'},
      '413108': {'lat': '18.7351', 'name': 'Tambave,Solapur,Maharashtra', 'lon': '75.5962'},
      '504214': {'lat': '18.9937', 'name': 'Nandarampally,Adilabad,Telangana', 'lon': '79.5585'},
      '591263': {'lat': '16.5403', 'name': 'Dhonewadi,Belagavi,Karnataka', 'lon': '74.44'},
      '629004': {'lat': '8.1671', 'name': 'Nagercoil Industrial Estate,Kanyakumari,Tamil Nadu', 'lon': '77.465'},
      '630005': {'lat': '9.5765', 'name': 'Devakottai Road,Sivaganga,Tamil Nadu', 'lon': '78.5306'},
      '313321': {'lat': '24.7555', 'name': 'Sameecha,Rajsamand,Rajasthan', 'lon': '73.6761'},
      '630003': {'lat': '8.5913', 'name': 'Cecri Campus,Sivaganga,Tamil Nadu', 'lon': '78.0491'},
      '630002': {'lat': '10.0801', 'name': 'Karaikudi Southstreet,Sivaganga,Tamil Nadu', 'lon': '78.7782'},
      '591265': {'lat': '16.1195', 'name': 'Ramtirth,Belagavi,Karnataka', 'lon': '74.823'},
      '629002': {'lat': '8.1632', 'name': 'Edalakudy,Kanyakumari,Tamil Nadu', 'lon': '77.4319'},
      '360380': {'lat': '21.847', 'name': 'Kagvad,Rajkot,Gujarat', 'lon': '70.6974'},
      '453552': {'lat': '22.5387', 'name': 'Simrol,Indore,Madhya Pradesh', 'lon': '75.9111'},
      '313327': {'lat': '25.1851', 'name': 'Jhor,Rajsamand,Rajasthan', 'lon': '74.0792'},
      '560010': {'lat': '12.9604', 'name': 'Rajajinagar IVth Block,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560011': {'lat': '12.9604', 'name': 'Madhavan Park,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560012': {'lat': '12.9604', 'name': 'Science Institute,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560013': {'lat': '12.9604', 'name': 'Jalahalli H.O,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560015': {'lat': '12.9604', 'name': 'Jalahalli West,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560016': {'lat': '12.9604', 'name': 'Ramamurthy Nagar,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560017': {'lat': '12.9604', 'name': 'NAL,Bengaluru,Karnataka', 'lon': '77.5673'},
      '560018': {'lat': '12.9604', 'name': 'Goripalya SO,Bengaluru,Karnataka', 'lon': '77.5673'},
      '313325': {'lat': '24.4143', 'name': 'Taladari,Rajsamand,Rajasthan', 'lon': '73.7371'},
      '737116': {'lat': '27.66355', 'name': 'Phamtam,North Sikkim,Sikkim', 'lon': '88.37785'},
      '737113': {'lat': '27.35046', 'name': 'Bermiok,East Sikkim,Sikkim', 'lon': '88.73805'},
      '370630': {'lat': '21.8717', 'name': 'Dolatpar,Kachchh,Gujarat', 'lon': '70.1557'},
      '770022': {'lat': '21.7933', 'name': 'Gadiajore,Sundergarh,Odisha', 'lon': '83.8968'},
      '770023': {'lat': '21.5417', 'name': 'Gyanpali,Sundergarh,Odisha', 'lon': '84.0365'},
      '770020': {'lat': '21.3382', 'name': 'Baurimundagaon,Sundergarh,Odisha', 'lon': '84.1244'},
      '770021': {'lat': '21.5176', 'name': 'Aunlabahal,Sundergarh,Odisha', 'lon': '84.0624'},
      '770024': {'lat': '21.5417', 'name': 'Bileimunda,Sundergarh,Odisha', 'lon': '84.0365'},
      '770025': {'lat': '21.5417', 'name': 'NTPC,21,Darlipali SO', 'lon': '83.432910'},
      '532445': {'lat': '18.5238', 'name': 'Khandyam,Srikakulam,Andhra Pradesh', 'lon': '83.7876'},
      '753014': {'lat': '20.4887', 'name': 'Avinab Bidanasi,Cuttack,Odisha', 'lon': '85.839'},
      '416235': {'lat': '18.1182', 'name': 'Koulage,Kolhapur,Maharashtra', 'lon': '75.9769'},
      '532440': {'lat': '18.5472', 'name': 'Devudala,Srikakulam,Andhra Pradesh', 'lon': '83.758'},
      '365440': {'lat': '21.4854', 'name': 'Samdhiyala,Amreli,Gujarat', 'lon': '70.9807'},
      '532443': {'lat': '18.3625', 'name': 'Kuddapalli,Srikakulam,Andhra Pradesh', 'lon': '83.5005'},
      '761213': {'lat': '18.972', 'name': 'Parisola,Gajapati,Odisha', 'lon': '84.1579'},
      '523201': {'lat': '15.8568', 'name': 'Chakrayapalem,Prakasam,Andhra Pradesh', 'lon': '79.9529'},
      '761211': {'lat': '19.3213', 'name': 'Batisiripur,Gajapati,Odisha', 'lon': '84.0744'},
      '761210': {'lat': '19.0506', 'name': 'Tatipati,Gajapati,Odisha', 'lon': '84.0629'},
      '761217': {'lat': '19.5568', 'name': 'Panigonda,Gajapati,Odisha', 'lon': '84.0747'},
      '761215': {'lat': '19.8979', 'name': 'Sabara,Gajapati,Odisha', 'lon': '84.0175'},
      '761214': {'lat': '19.6378', 'name': 'Nuagada,Gajapati,Odisha', 'lon': '84.3678'},
      '796710': {'lat': '23.2628', 'name': 'Bunghmun,Lunglei,Mizoram', 'lon': '92.475'},
      '123035': {'lat': '28.359', 'name': 'Kannaura,Rewari,Haryana', 'lon': '76.6377'},
      '382705': {'lat': '23.6065', 'name': 'Vadasma,Mahesana,Gujarat', 'lon': '72.6305'},
      '243639': {'lat': '28.0182', 'name': 'Shahjad Nagar,Budaun,Uttar Pradesh', 'lon': '78.9977'},
      '123031': {'lat': '28.3626', 'name': 'CUH M.Garh,Mahendragarh,Haryana', 'lon': '76.7003'},
      '689602': {'lat': '9.265', 'name': 'Puthusserry South,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '225125': {'lat': '26.4957', 'name': 'Gerawan,Barabanki,Uttar Pradesh', 'lon': '81.6393'},
      '635754': {'lat': '11.747', 'name': 'Udayendiram,Vellore,Tamil Nadu', 'lon': '78.6806'},
      '284303': {'lat': '25.8324', 'name': 'Atariya,Jhansi,Uttar Pradesh', 'lon': '78.91'},
      '274602': {'lat': '26.1706', 'name': 'Reoli,Deoria,Uttar Pradesh', 'lon': '83.9115'},
      '571429': {'lat': '12.6631', 'name': 'Desihalli,Mandya,Karnataka', 'lon': '77.0136'},
      '571428': {'lat': '12.4868', 'name': 'Malagaranahalli,Mandya,Karnataka', 'lon': '76.9623'},
      '207403': {'lat': '27.7893', 'name': 'Mehmood Pur Pukhata,Etah,Uttar Pradesh', 'lon': '78.7824'},
      '453555': {'lat': '22.861', 'name': 'Shri Aurobindo,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '680721': {'lat': '10.4712', 'name': 'Poringalkuthu,Thrissur,Kerala', 'lon': '76.3162'},
      '571422': {'lat': '12.6181', 'name': 'Honnayakanahalli,Mandya,Karnataka', 'lon': '76.8801'},
      '571421': {'lat': '12.4667', 'name': 'Halagur,Mandya,Karnataka', 'lon': '77.0552'},
      '680722': {'lat': '10.3207', 'name': 'Chalakudi R S,Thrissur,Kerala', 'lon': '76.3368'},
      '431004': {'lat': '19.705', 'name': 'Begumpura,Aurangabad,Maharashtra', 'lon': '75.7394'},
      '680724': {'lat': '10.5482', 'name': 'Chaipankuzhi,Thrissur,Kerala', 'lon': '76.3041'},
      '571425': {'lat': '12.7373', 'name': 'Koppa-mandya,Mandya,Karnataka', 'lon': '76.9378'},
      '571424': {'lat': '12.4139', 'name': 'Hittanahalli,Mandya,Karnataka', 'lon': '76.9521'},
      '273155': {'lat': '27.006', 'name': 'Pharendra Khurd,Maharajganj,Uttar Pradesh', 'lon': '83.0878'},
      '585328': {'lat': '18.0832', 'name': 'Dawargaon,Bidar,Karnataka', 'lon': '77.2406'},
      '673028': {'lat': '11.3046', 'name': 'Arakinar,Kozhikode,Kerala', 'lon': '75.76'},
      '673029': {'lat': '11.3046', 'name': 'Tiruvannur Nada,Kozhikode,Kerala', 'lon': '75.76'},
      '273152': {'lat': '26.7462', 'name': 'Patra Bazar,Gorakhpur,Uttar Pradesh', 'lon': '83.4565'},
      '673027': {'lat': '11.3046', 'name': 'Nallalam,Kozhikode,Kerala', 'lon': '75.76'},
      '673020': {'lat': '11.3046', 'name': 'Calicut Collectorate,Kozhikode,Kerala', 'lon': '75.76'},
      '673021': {'lat': '11.3046', 'name': 'Puthiyangadi,Kozhikode,Kerala', 'lon': '75.76'},
      '799256': {'lat': '24.0447', 'name': 'Narendranagar,North Tripura,Tripura', 'lon': '91.7553'},
      '632204': {'lat': '12.9351', 'name': 'Kavasampattu,Vellore,Tamil Nadu', 'lon': '79.1865'},
      '622209': {'lat': '10.1691', 'name': 'Kallur-kilanilai,Pudukkottai,Tamil Nadu', 'lon': '78.8555'},
      '304502': {'lat': '26.1883', 'name': 'Tordisagar,Tonk,Rajasthan', 'lon': '75.5113'},
      '516401': {'lat': '14.446', 'name': 'T Thummalapalle,Cuddapah,Andhra Pradesh', 'lon': '78.142'},
      '307043': {'lat': '24.02', 'name': 'Chhibagaon,Sirohi,Rajasthan', 'lon': '74.1061'},
      '631202': {'lat': '12.6952', 'name': 'Kothakuppam,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '631203': {'lat': '13.0836', 'name': 'Senji,Tiruvallur,Tamil Nadu', 'lon': '79.828'},
      '503235': {'lat': '19.1257', 'name': 'Pegdapalli,Nizamabad,Telangana', 'lon': '78.1847'},
      '631201': {'lat': '13.2431', 'name': 'Nemili,Tiruvallur,Tamil Nadu', 'lon': '79.94'},
      '631206': {'lat': '13.2279', 'name': 'Krishnasamudram,Tiruvallur,Tamil Nadu', 'lon': '79.5156'},
      '631207': {'lat': '12.7696', 'name': 'Veliagaram,Tiruvallur,Tamil Nadu', 'lon': '79.4806'},
      '631204': {'lat': '13.0866', 'name': 'Muthukondapuram,Tiruvallur,Tamil Nadu', 'lon': '79.511'},
      '631205': {'lat': '13.1607', 'name': 'Kg Kandigai,Tiruvallur,Tamil Nadu', 'lon': '79.5369'},
      '584128': {'lat': '15.6782', 'name': 'Harapur,Raichur,Karnataka', 'lon': '76.5696'},
      '621302': {'lat': '10.2251', 'name': 'Mohavanur,Tiruchirappalli,Tamil Nadu', 'lon': '77.9611'},
      '631208': {'lat': '13.2874', 'name': 'Pandravedu,Tiruvallur,Tamil Nadu', 'lon': '79.5137'},
      '631209': {'lat': '13.1971', 'name': 'Tiruttanisouth,Tiruvallur,Tamil Nadu', 'lon': '79.605'},
      '326501': {'lat': '24.4651', 'name': 'Kanwara,Jhalawar,Rajasthan', 'lon': '76.0286'},
      '505172': {'lat': '18.0474', 'name': 'Peddapalli H.O,Karim Nagar,Telangana', 'lon': '79.0733'},
      '204215': {'lat': '27.8763', 'name': 'Basai Babas,Hathras,Uttar Pradesh', 'lon': '78.3491'},
      '639201': {'lat': '10.7948', 'name': 'Kanakkuvelampatti,Karur,Tamil Nadu', 'lon': '77.897'},
      '423108': {'lat': '20.6168', 'name': 'Ravalgaon,Nashik,Maharashtra', 'lon': '74.4006'},
      '423109': {'lat': '19.8934', 'name': 'Savali Vihir Farm,Ahmed Nagar,Maharashtra', 'lon': '74.3562'},
      '505174': {'lat': '18.0474', 'name': 'Appannapet,Karim Nagar,Telangana', 'lon': '79.0733'},
      '303908': {'lat': '26.6945', 'name': 'Kotkhawada,Jaipur,Rajasthan', 'lon': '76.0035'},
      '423104': {'lat': '20.0855', 'name': 'Jalgaon Nimbayati,Nashik,Maharashtra', 'lon': '74.814'},
      '423105': {'lat': '20.2592', 'name': 'Kalwadi,Nashik,Maharashtra', 'lon': '74.0714'},
      '423106': {'lat': '20.3251', 'name': 'Mandwad,Nashik,Maharashtra', 'lon': '74.8964'},
      '423107': {'lat': '19.3483', 'name': 'Sakuri,Ahmed Nagar,Maharashtra', 'lon': '74.299'},
      '303903': {'lat': '26.6864', 'name': 'Badapadampura,Jaipur,Rajasthan', 'lon': '75.8773'},
      '423101': {'lat': '20.2388', 'name': 'Sogras,Nashik,Maharashtra', 'lon': '74.3815'},
      '423102': {'lat': '20.3265', 'name': 'Meshi,Nashik,Maharashtra', 'lon': '74.5466'},
      '639207': {'lat': '10.2569', 'name': 'Ponnagoundanur,Karur,Tamil Nadu', 'lon': '77.951'},
      '204216': {'lat': '27.856', 'name': 'Dwarikapur,Hathras,Uttar Pradesh', 'lon': '78.1375'},
      '754253': {'lat': '20.458', 'name': 'Mandia,Kendrapara,Odisha', 'lon': '84.9103'},
      '623401': {'lat': '9.7612', 'name': 'Radhanur,Ramanathapuram,Tamil Nadu', 'lon': '78.7622'},
      '754250': {'lat': '21.6147', 'name': 'Panasudha,Kendrapara,Odisha', 'lon': '85.5944'},
      '282008': {'lat': '27.213', 'name': 'I. A. F.,Agra,Uttar Pradesh', 'lon': '78.1858'},
      '721304': {'lat': '22.5198', 'name': 'Dhekia,West Midnapore,West Bengal', 'lon': '87.9343'},
      '603210': {'lat': '12.6009', 'name': 'Urapakkam,Kanchipuram,Tamil Nadu', 'lon': '79.8214'},
      '603211': {'lat': '12.6009', 'name': 'Annai Anjugam Nagar,Kanchipuram,Tamil Nadu', 'lon': '79.8214'},
      '403507': {'lat': '15.6026', 'name': 'Moira,North Goa,Goa', 'lon': '73.839'},
      '477566': {'lat': '26.2015', 'name': 'Nai Garhi,Bhind,Madhya Pradesh', 'lon': '78.4095'},
      '721306': {'lat': '22.5198', 'name': 'Talbagicha,West Midnapore,West Bengal', 'lon': '87.9343'},
      '403504': {'lat': '15.5932', 'name': 'Mayem,North Goa,Goa', 'lon': '73.9457'},
      '403515': {'lat': '15.5108', 'name': 'Saipem,North Goa,Goa', 'lon': '73.766'},
      '403517': {'lat': '15.6272', 'name': 'Oxel,North Goa,Goa', 'lon': '73.7663'},
      '796261': {'lat': '23.902', 'name': 'Buhban,Aizawl,Mizoram', 'lon': '93.0008'},
      '403511': {'lat': '15.5445', 'name': 'Sem Saligao,North Goa,Goa', 'lon': '73.8029'},
      '403510': {'lat': '15.5796', 'name': 'Canca,North Goa,Goa', 'lon': '73.7941'},
      '403513': {'lat': '15.6575', 'name': 'Ozari,North Goa,Goa', 'lon': '73.8572'},
      '403512': {'lat': '15.7599', 'name': 'Tamboxem,North Goa,Goa', 'lon': '73.8371'},
      '802123': {'lat': '25.4295', 'name': 'Diwan De Barka Gaon,Buxar,Bihar', 'lon': '84.05'},
      '515271': {'lat': '13.9424', 'name': 'Thirumaladevarapalli,Ananthapur,Andhra Pradesh', 'lon': '77.1015'},
      '741301': {'lat': '23.601', 'name': 'Ushidpur,Nadia,West Bengal', 'lon': '88.1273'},
      '802120': {'lat': '25.6371', 'name': 'Rani Singhanpura,Buxar,Bihar', 'lon': '84.2126'},
      '802127': {'lat': '25.4763', 'name': 'Murar,Buxar,Bihar', 'lon': '84.2216'},
      '802126': {'lat': '25.4482', 'name': 'Athar,Buxar,Bihar', 'lon': '84.1772'},
      '802125': {'lat': '25.4296', 'name': 'Waina,Buxar,Bihar', 'lon': '84.2819'},
      '741319': {'lat': '23.601', 'name': 'Bidyanagar,Nadia,West Bengal', 'lon': '88.1273'},
      '177106': {'lat': '31.9108', 'name': 'Dadasiba,Kangra,Himachal Pradesh', 'lon': '76.0442'},
      '177107': {'lat': '31.8214', 'name': 'Lag Baliana,Kangra,Himachal Pradesh', 'lon': '76.2119'},
      '177104': {'lat': '31.9894', 'name': 'Sunhet,Kangra,Himachal Pradesh', 'lon': '76.1745'},
      '177105': {'lat': '32.0185', 'name': 'Gheori,Kangra,Himachal Pradesh', 'lon': '75.9459'},
      '177103': {'lat': '32.1578', 'name': 'Beehan,Kangra,Himachal Pradesh', 'lon': '76.2998'},
      '632519': {'lat': '12.9865', 'name': 'Ammundi,Vellore,Tamil Nadu', 'lon': '79.2505'},
      '177101': {'lat': '31.8558', 'name': 'Dehra H.O,Kangra,Himachal Pradesh', 'lon': '76.2205'},
      '632517': {'lat': '12.824', 'name': 'Arapakkam,Vellore,Tamil Nadu', 'lon': '79.5493'},
      '632516': {'lat': '13.0718', 'name': 'Periamittoor,Vellore,Tamil Nadu', 'lon': '79.2264'},
      '632515': {'lat': '12.9825', 'name': 'Seekarajapuram,Vellore,Tamil Nadu', 'lon': '79.2661'},
      '632514': {'lat': '13.0843', 'name': 'Vasur,Vellore,Tamil Nadu', 'lon': '79.2803'},
      '632513': {'lat': '12.9076', 'name': 'Valluvambakkam,Vellore,Tamil Nadu', 'lon': '79.3763'},
      '632512': {'lat': '12.7747', 'name': 'Poosimalaikuppam,Tiruvannamalai,Tamil Nadu', 'lon': '79.3745'},
      '177108': {'lat': '31.6563', 'name': 'Suhin,Kangra,Himachal Pradesh', 'lon': '76.43'},
      '177109': {'lat': '31.7862', 'name': 'Suhin,Una,Himachal Pradesh', 'lon': '76.227'},
      '225202': {'lat': '26.4133', 'name': 'Telwari,Barabanki,Uttar Pradesh', 'lon': '80.9585'},
      '262522': {'lat': '29.9726', 'name': 'Mallagarkha,Pithoragarh,Uttarakhand', 'lon': '79.2469'},
      '312401': {'lat': '25.3276', 'name': 'Badwal,Chittorgarh,Rajasthan', 'lon': '75.4568'},
      '802135': {'lat': '25.9667', 'name': 'Asha Parari,Buxar,Bihar', 'lon': '85.6756'},
      '741316': {'lat': '23.601', 'name': 'Bablari,Nadia,West Bengal', 'lon': '88.1273'},
      '623403': {'lat': '9.6474', 'name': 'Muhilthagam,Ramanathapuram,Tamil Nadu', 'lon': '78.9581'},
      '623402': {'lat': '9.8076', 'name': 'Chinnakeeramangalam,Ramanathapuram,Tamil Nadu', 'lon': '78.833'},
      '431009': {'lat': '20.0215', 'name': 'Garkheda,Aurangabad,Maharashtra', 'lon': '75.2735'},
      '486331': {'lat': '24.6811', 'name': 'Damodar  Garh,Rewa,Madhya Pradesh', 'lon': '81.7434'},
      '623407': {'lat': '10.0754', 'name': 'Thinaiyathur,Ramanathapuram,Tamil Nadu', 'lon': '78.4344'},
      '623406': {'lat': '10.9995', 'name': 'Marungoor,Ramanathapuram,Tamil Nadu', 'lon': '79.2138'},
      '623404': {'lat': '9.9429', 'name': 'Nagarikathan,Ramanathapuram,Tamil Nadu', 'lon': '78.7604'},
      '442710': {'lat': '19.4182', 'name': 'Kurumwelli,Chandrapur,Maharashtra', 'lon': '80.4776'},
      '802130': {'lat': '25.6368', 'name': 'Dallupur,Buxar,Bihar', 'lon': '84.3054'},
      '623409': {'lat': '9.7604', 'name': 'Kurumilangudi,Ramanathapuram,Tamil Nadu', 'lon': '79.0374'},
      '472447': {'lat': '25.2741', 'name': 'Uparakhas,Tikamgarh,Madhya Pradesh', 'lon': '78.913'},
      '632317': {'lat': '12.5751', 'name': 'Velapadi-Arni,Tiruvannamalai,Tamil Nadu', 'lon': '79.3268'},
      '742138': {'lat': '23.4606', 'name': 'Rasorah,Murshidabad,West Bengal', 'lon': '88.2538'},
      '403508': {'lat': '15.5934', 'name': 'Aldona,North Goa,Goa', 'lon': '73.8748'},
      '181152': {'lat': '32.6562', 'name': 'Bathindi,Jammu,Jammu & Kashmir', 'lon': '74.8391'},
      '573142': {'lat': '12.8611', 'name': 'Doddamagge,Hassan,Karnataka', 'lon': '75.9804'},
      '277402': {'lat': '25.7635', 'name': 'Jawahi Dear,Ballia,Uttar Pradesh', 'lon': '84.3228'},
      '573141': {'lat': '13.0948', 'name': 'Masakanahalli,Hassan,Karnataka', 'lon': '76.583'},
      '304503': {'lat': '26.0821', 'name': 'Lamba Hari Singh,Tonk,Rajasthan', 'lon': '74.8496'},
      '410102': {'lat': '18.9240938', 'name': 'Matheran,Raigarh(MH),Maharashtra', 'lon': '73.3189988'},
      '802314': {'lat': '25.4585', 'name': 'Kayamnagar,Bhojpur,Bihar', 'lon': '84.5115'},
      '844119': {'lat': '25.8146', 'name': 'Ghataro,Vaishali,Bihar', 'lon': '85.1862'},
      '632501': {'lat': '12.9889', 'name': 'Kalmelkuppam,Vellore,Tamil Nadu', 'lon': '79.4113'},
      '246419': {'lat': '29.8935', 'name': 'Baksir,Rudraprayag,Uttarakhand', 'lon': '79.1222'},
      '632502': {'lat': '13.012', 'name': 'Melkalathur,Vellore,Tamil Nadu', 'lon': '79.56'},
      '670649': {'lat': '11.6697', 'name': 'Ummenchira,Kannur,Kerala', 'lon': '76.0032'},
      '790002': {'lat': '27.1006', 'name': 'Tenzingaon,West Kameng,Arunachal Pradesh', 'lon': '92.1039'},
      '632503': {'lat': '12.8736', 'name': 'Jawaharpuram,Vellore,Tamil Nadu', 'lon': '79.351'},
      '790003': {'lat': '27.2048', 'name': 'Rupa,West Kameng,Arunachal Pradesh', 'lon': '92.4008'},
      '670641': {'lat': '11.6697', 'name': 'Ponniam,Kannur,Kerala', 'lon': '76.0032'},
      '632504': {'lat': '12.7612', 'name': 'Valayathur,Vellore,Tamil Nadu', 'lon': '79.3087'},
      '670643': {'lat': '11.8408', 'name': 'Mooriyad,Kannur,Kerala', 'lon': '75.6093'},
      '670642': {'lat': '11.6697', 'name': 'Kadirur-tly,Kannur,Kerala', 'lon': '76.0032'},
      '670645': {'lat': '11.5131', 'name': 'Ellumannam,Wayanad,Kerala', 'lon': '76.2001'},
      '670644': {'lat': '11.8215', 'name': 'Kampatti,Wayanad,Kerala', 'lon': '75.8721'},
      '670646': {'lat': '11.6697', 'name': 'Panavally,Wayanad,Kerala', 'lon': '76.0032'},
      '758028': {'lat': '21.2532', 'name': 'Kuntla,Kendujhar,Odisha', 'lon': '85.5332'},
      '758029': {'lat': '21.4333', 'name': 'Kathabari,Kendujhar,Odisha', 'lon': '85.6102'},
      '790001': {'lat': '27.2888', 'name': 'Rahung,Papum Pare,Arunachal Pradesh', 'lon': '93.5969'},
      '632506': {'lat': '12.6668', 'name': 'Pudur- Kaniyanur,Vellore,Tamil Nadu', 'lon': '79.2235'},
      '621212': {'lat': '11.0063', 'name': 'Perambalur Collectorate,Perambalur,Tamil Nadu', 'lon': '78.5566'},
      '758020': {'lat': '21.9913', 'name': 'Tukuna,Kendujhar,Odisha', 'lon': '85.9913'},
      '758021': {'lat': '21.4895', 'name': 'Raitola,Kendujhar,Odisha', 'lon': '85.8081'},
      '758022': {'lat': '20.7694', 'name': 'Karagola,Kendujhar,Odisha', 'lon': '86.1059'},
      '758023': {'lat': '21.2937', 'name': 'Podasingidi,Kendujhar,Odisha', 'lon': '85.5564'},
      '821105': {'lat': '25.2129', 'name': 'Pachilikhi,Kaimur (Bhabua),Bihar', 'lon': '83.5671'},
      '758025': {'lat': '20.8939', 'name': 'Bangorekota,Jajapur,Odisha', 'lon': '85.4145'},
      '758026': {'lat': '21.1406', 'name': 'Baliparbata,Kendujhar,Odisha', 'lon': '85.1465'},
      '758027': {'lat': '21.4385', 'name': 'Manata,Kendujhar,Odisha', 'lon': '85.1491'},
      '801113': {'lat': '25.6126', 'name': 'Balua,Patna,Bihar', 'lon': '84.9745'},
      '792001': {'lat': '27.9126', 'name': 'Danglat,Lohit,Arunachal Pradesh', 'lon': '96.1288'},
      '787023': {'lat': '27.4154', 'name': 'Doolahat,Lakhimpur,Assam', 'lon': '94.684'},
      '787026': {'lat': '27.1045', 'name': 'Hollowdanga,Dhemaji,Assam', 'lon': '93.5082'},
      '854312': {'lat': '26.03', 'name': 'Bangama,Araria,Bihar', 'lon': '87.2742'},
      '801111': {'lat': '25.5927', 'name': 'Kamla Gopalpur,Patna,Bihar', 'lon': '85.0462'},
      '791125': {'lat': '27.5535', 'name': 'Likabali,West Siang,Arunachal Pradesh', 'lon': '93.7868'},
      '517236': {'lat': '13.7453', 'name': 'Kotagudibanda,Chittoor,Andhra Pradesh', 'lon': '79.2122'},
      '517237': {'lat': '13.9542', 'name': 'Pallavolu,Chittoor,Andhra Pradesh', 'lon': '78.3968'},
      '517234': {'lat': '13.3166', 'name': 'Vootupalle,Chittoor,Andhra Pradesh', 'lon': '78.6184'},
      '517235': {'lat': '14.1631', 'name': 'Thimmapuram,Chittoor,Andhra Pradesh', 'lon': '79.299'},
      '454331': {'lat': '22.1957', 'name': 'Badwaniya,Dhar,Madhya Pradesh', 'lon': '74.8027'},
      '791123': {'lat': '27.5535', 'name': 'Kolaptukar,Papum Pare,Arunachal Pradesh', 'lon': '93.7868'},
      '204214': {'lat': '27.6437', 'name': 'Nagla Veer Sahai,Hathras,Uttar Pradesh', 'lon': '78.2958'},
      '247232': {'lat': '29.8756', 'name': 'Gaderheri,Saharanpur,Uttar Pradesh', 'lon': '77.8064'},
      '845414': {'lat': '25.9221', 'name': 'Sundar Patti,East Champaran,Bihar', 'lon': '84.92'},
      '795113': {'lat': '24.6432', 'name': 'Patsoi Part II,Imphal West,Manipur', 'lon': '93.6703'},
      '795112': {'lat': '24.4427', 'name': 'T Waichong,Senapati,Manipur', 'lon': '93.5044'},
      '795117': {'lat': '24.7381', 'name': 'Tuikham,Churachandpur,Manipur', 'lon': '93.7279'},
      '795116': {'lat': '24.806309', 'name': 'Ngamphabung,Jaribam,Manipur', 'lon': '93.9481869'},
      '795115': {'lat': '24.7381', 'name': 'Kashimpur,Imphal West,Manipur', 'lon': '93.7279'},
      '795114': {'lat': '24.9574', 'name': 'Pangei Yangdong,Imphal East,Manipur', 'lon': '94.0242'},
      '641025': {'lat': '11.0386', 'name': 'Velandipalayam,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '641024': {'lat': '11.0386', 'name': 'Sundarapuram,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '641027': {'lat': '11.0386', 'name': 'Rathinapuri,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '795118': {'lat': '25.0285', 'name': 'Dolang,Bishnupur,Manipur', 'lon': '94.1033'},
      '641021': {'lat': '11.0386', 'name': 'Coimbatore Industrial Estate,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '641020': {'lat': '11.0386', 'name': 'Naickenpalayam,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '641023': {'lat': '10.9588', 'name': 'Konavaikalpalayam,Coimbatore,Tamil Nadu', 'lon': '76.9924'},
      '641022': {'lat': '11.1184', 'name': 'N.G.G.O Colony,Coimbatore,Tamil Nadu', 'lon': '76.969'},
      '571617': {'lat': '12.5344', 'name': 'Chunchunakatte,Mysuru,Karnataka', 'lon': '76.2944'},
      '233229': {'lat': '25.9032', 'name': 'Barachawar,Ghazipur,Uttar Pradesh', 'lon': '83.7914'},
      '534266': {'lat': '16.5591', 'name': 'Bhimala Puram,West Godavari,Andhra Pradesh', 'lon': '81.2876'},
      '534267': {'lat': '17.0294', 'name': 'Penumadam,West Godavari,Andhra Pradesh', 'lon': '81.5401'},
      '534265': {'lat': '16.4963', 'name': 'Vadangi Palem,West Godavari,Andhra Pradesh', 'lon': '81.7417'},
      '517588': {'lat': '13.4793', 'name': 'Pravalvanneswarapuram,Chittoor,Andhra Pradesh', 'lon': '79.935'},
      '517589': {'lat': '13.4612', 'name': 'Beerakuppam,Chittoor,Andhra Pradesh', 'lon': '79.8309'},
      '534260': {'lat': '16.5911', 'name': 'Palakol Bazar,West Godavari,Andhra Pradesh', 'lon': '81.1919'},
      '517584': {'lat': '13.4737', 'name': 'Parameswaramangalam,Chittoor,Andhra Pradesh', 'lon': '79.6698'},
      '621004': {'lat': '11.0981', 'name': 'Kilakunnupatti,Tiruchirappalli,Tamil Nadu', 'lon': '78.6528'},
      '517586': {'lat': '13.232', 'name': 'Kosalanagaram,Chittoor,Andhra Pradesh', 'lon': '79.767'},
      '517587': {'lat': '13.4071', 'name': 'Vengalathur,Chittoor,Andhra Pradesh', 'lon': '79.7222'},
      '621001': {'lat': '11.139', 'name': 'Senappanallur,Tiruchirappalli,Tamil Nadu', 'lon': '78.541'},
      '517581': {'lat': '14.0638', 'name': 'Palamangalam,Chittoor,Andhra Pradesh', 'lon': '79.7768'},
      '517582': {'lat': '13.3475', 'name': 'Udamalakudithi,Chittoor,Andhra Pradesh', 'lon': '79.359'},
      '517583': {'lat': '13.4026', 'name': 'Cherlopalle,Chittoor,Andhra Pradesh', 'lon': '78.5966'},
      '574253': {'lat': '12.8908', 'name': 'Borimar,Dakshina Kannada,Karnataka', 'lon': '75.0976'},
      '841238': {'lat': '26.1069', 'name': 'Pasnauli,Siwan,Bihar', 'lon': '84.4935'},
      '841239': {'lat': '26.0309', 'name': 'Sematar,Siwan,Bihar', 'lon': '84.47'},
      '403721': {'lat': '15.2324', 'name': 'Varca,South Goa,Goa', 'lon': '73.9431'},
      '396210': {'lat': '20.4143', 'name': 'Dunetha,Daman,Daman & Diu', 'lon': '72.8324'},
      '841232': {'lat': '25.7663', 'name': 'Mahmodpur,Siwan,Bihar', 'lon': '84.8781'},
      '841233': {'lat': '26.0535', 'name': 'P.Harsar,Siwan,Bihar', 'lon': '84.5488'},
      '574259': {'lat': '12.6753', 'name': 'Panaje,Dakshina Kannada,Karnataka', 'lon': '75.4782'},
      '841231': {'lat': '26.2481', 'name': 'Nadiwan,Siwan,Bihar', 'lon': '84.6552'},
      '841236': {'lat': '26.0697', 'name': 'Rampur Ke Tola,Siwan,Bihar', 'lon': '84.3667'},
      '444813': {'lat': '21.5088', 'name': 'Semadoh,Amravati,Maharashtra', 'lon': '77.5277'},
      '841234': {'lat': '26.0138', 'name': 'Bishwania,Siwan,Bihar', 'lon': '84.4285'},
      '841235': {'lat': '26.1364', 'name': 'Draili Mathia,Siwan,Bihar', 'lon': '84.3126'},
      '395001': {'lat': '21.1941', 'name': 'Gopipura,Surat,Gujarat', 'lon': '72.5018'},
      '395003': {'lat': '21.1941', 'name': 'Jhampa,Surat,Gujarat', 'lon': '72.5018'},
      '395002': {'lat': '21.1941', 'name': 'Sagrampura Putli,Surat,Gujarat', 'lon': '72.5018'},
      '395005': {'lat': '21.3326', 'name': 'Selut,Surat,Gujarat', 'lon': '72.0706'},
      '395004': {'lat': '21.1941', 'name': 'Dabholi,Surat,Gujarat', 'lon': '72.5018'},
      '395007': {'lat': '21.0556', 'name': 'Gavier,Surat,Gujarat', 'lon': '72.933'},
      '395006': {'lat': '21.1941', 'name': 'Varachha Road,Surat,Gujarat', 'lon': '72.5018'},
      '395009': {'lat': '21.1941', 'name': 'Ramnagar (Surat),Surat,Gujarat', 'lon': '72.5018'},
      '395008': {'lat': '21.1941', 'name': 'Fulpada,Surat,Gujarat', 'lon': '72.5018'},
      '204211': {'lat': '27.6499', 'name': 'Northa Isepur,Hathras,Uttar Pradesh', 'lon': '78.4005'},
      '722121': {'lat': '23.387', 'name': 'Masanjhar,Bankura,West Bengal', 'lon': '87.1052'},
      '176219': {'lat': '32.2396', 'name': 'Mcleodganj,Kangra,Himachal Pradesh', 'lon': '76.3255'},
      '176218': {'lat': '32.1259', 'name': 'Khaniara,Kangra,Himachal Pradesh', 'lon': '76.2119'},
      '226202': {'lat': '26.8416', 'name': 'Raitha,Lucknow,Uttar Pradesh', 'lon': '80.9067'},
      '176211': {'lat': '32.3713', 'name': 'Khawara,Kangra,Himachal Pradesh', 'lon': '75.9072'},
      '176210': {'lat': '31.9371', 'name': 'Dole,Kangra,Himachal Pradesh', 'lon': '76.3229'},
      '176213': {'lat': '32.1755', 'name': 'Sakoh,Kangra,Himachal Pradesh', 'lon': '75.9124'},
      '176215': {'lat': '32.1259', 'name': 'Kotwali Bazar Dharamsala,Kangra,Himachal Pradesh', 'lon': '76.2119'},
      '176214': {'lat': '32.1182', 'name': 'Shamirpur,Kangra,Himachal Pradesh', 'lon': '76.2431'},
      '176217': {'lat': '32.0698', 'name': 'Tundo,Kangra,Himachal Pradesh', 'lon': '76.1695'},
      '176216': {'lat': '32.2627', 'name': 'Dharamsala Cantt,Kangra,Himachal Pradesh', 'lon': '76.295'},
      '822121': {'lat': '24.262', 'name': 'Mahdeiya,Palamau,Jharkhand', 'lon': '83.4571'},
      '822120': {'lat': '24.3919', 'name': 'Goradih,Palamau,Jharkhand', 'lon': '83.8175'},
      '822123': {'lat': '24.1163', 'name': 'Dipauwa,Palamau,Jharkhand', 'lon': '84.1664'},
      '210129': {'lat': '25.5774', 'name': 'Padmai,Banda,Uttar Pradesh', 'lon': '80.7862'},
      '822125': {'lat': '23.8504', 'name': 'Naw Bandaria,Garhwa,Jharkhand', 'lon': '83.781'},
      '635652': {'lat': '12.1335', 'name': 'Anganathavalasai,Vellore,Tamil Nadu', 'lon': '78.8707'},
      '822126': {'lat': '24.0213', 'name': 'Dhawadih,Palamau,Jharkhand', 'lon': '84.2725'},
      '365635': {'lat': '21.3025', 'name': 'Rughnathpur,Amreli,Gujarat', 'lon': '71.1809'},
      '822128': {'lat': '24.1788', 'name': 'Tandwa,Palamau,Jharkhand', 'lon': '84.1939'},
      '360460': {'lat': '21.777', 'name': 'Dhank,Rajkot,Gujarat', 'lon': '70.1311'},
      '365630': {'lat': '21.4264', 'name': 'Sajiyavadar,Amreli,Gujarat', 'lon': '71.1936'},
      '335502': {'lat': '29.0232', 'name': 'Gheu,Hanumangarh,Rajasthan', 'lon': '74.9727'},
      '335503': {'lat': '29.0787', 'name': 'Sherda,Hanumangarh,Rajasthan', 'lon': '75.3497'},
      '335501': {'lat': '28.8899', 'name': 'Bhadi,Hanumangarh,Rajasthan', 'lon': '75.2155'},
      '335504': {'lat': '29.1519', 'name': 'Nathwania,Hanumangarh,Rajasthan', 'lon': '74.9929'},
      '743292': {'lat': '22.6809', 'name': 'Panitar,North 24 Parganas,West Bengal', 'lon': '88.9039'},
      '491222': {'lat': '20.8884', 'name': 'Sanoud,Durg,Chattisgarh', 'lon': '81.3432'},
      '505002': {'lat': '18.3624', 'name': 'Chinthakunta,Karim Nagar,Telangana', 'lon': '79.1723'},
      '314403': {'lat': '23.5524', 'name': 'Gara Gokal,Dungarpur,Rajasthan', 'lon': '73.68'},
      '210126': {'lat': '25.465', 'name': 'Gadaria,Banda,Uttar Pradesh', 'lon': '80.5618'},
      '743290': {'lat': '23.1467', 'name': 'Dighari,North 24 Parganas,West Bengal', 'lon': '88.803'},
      '160062': {'lat': '30.7465', 'name': 'Chandigarh Sector 62,Mohali,Punjab', 'lon': '76.7417'},
      '506142': {'lat': '18.058', 'name': 'Mupparam,Warangal,Telangana', 'lon': '79.2022'},
      '506143': {'lat': '17.8626', 'name': 'Ghanpur Station,Warangal,Telangana', 'lon': '79.467'},
      '506146': {'lat': '17.955', 'name': 'Palakurthy,Warangal,Telangana', 'lon': '79.3584'},
      '263137': {'lat': '29.4521', 'name': 'Bhatalia,Nainital,Uttarakhand', 'lon': '79.7206'},
      '506144': {'lat': '17.8887', 'name': 'Abadighanpur,Warangal,Telangana', 'lon': '79.4069'},
      '222109': {'lat': '25.8536', 'name': 'Garhabaghrai,Jaunpur,Uttar Pradesh', 'lon': '82.5637'},
      '788818': {'lat': '25.1', 'name': 'Baro Mulkoi,North Cachar Hills,Assam', 'lon': '92.8333'},
      '788819': {'lat': '25.2421', 'name': 'Kanaan,North Cachar Hills,Assam', 'lon': '93.0147'},
      '600130': {'lat': '12.5938', 'name': 'Navalur,Kanchipuram,Tamil Nadu', 'lon': '80.0429'},
      '788815': {'lat': '24.9687', 'name': 'Khelma PT VII,Cachar,Assam', 'lon': '92.5691'},
      '788816': {'lat': '25.3341', 'name': 'Jalalpur (Cachar),Cachar,Assam', 'lon': '92.6808'},
      '788817': {'lat': '26.1177', 'name': 'Behara PT IV,Cachar,Assam', 'lon': '91.9722'},
      '534006': {'lat': '16.57', 'name': 'Ramachandra Rao Peta,West Godavari,Andhra Pradesh', 'lon': '80.9808'},
      '803201': {'lat': '25.5096', 'name': 'Fatwa,Patna,Bihar', 'lon': '85.305'},
      '243401': {'lat': '28.4494', 'name': 'Kudha,Bareilly,Uttar Pradesh', 'lon': '79.4223'},
      '204212': {'lat': '27.3695', 'name': 'Bwarwana,Hathras,Uttar Pradesh', 'lon': '78.7406'},
      '243402': {'lat': '28.1969', 'name': 'Milak Majhera,Bareilly,Uttar Pradesh', 'lon': '79.3643'},
      '713326': {'lat': '23.5854', 'name': 'Chotodighari,Paschim Bardhaman,West Bengal', 'lon': '87.5061'},
      '723202': {'lat': '23.3861', 'name': 'Bagbinda,Purulia,West Bengal', 'lon': '85.9525'},
      '713325': {'lat': '23.6483', 'name': 'Rambandh,Paschim Bardhaman,West Bengal', 'lon': '87.2163'},
      '713322': {'lat': '23.6518', 'name': 'Siduli,Purba Bardhaman,West Bengal', 'lon': '87.1648'},
      '713323': {'lat': '23.5854', 'name': 'Ballavpur,Purba Bardhaman,West Bengal', 'lon': '87.5061'},
      '713321': {'lat': '23.1782', 'name': 'Andalgram,Burdwan,West Bengal', 'lon': '87.8981'},
      '825319': {'lat': '24.0454', 'name': 'Katkamsandi,Hazaribag,Jharkhand', 'lon': '85.3035'},
      '342606': {'lat': '26.7324', 'name': 'Rajshri Nagar,Jodhpur,Rajasthan', 'lon': '73.1533'},
      '580029': {'lat': '15.318', 'name': 'Hubli Bharat Mill,Dharwad,Karnataka', 'lon': '75.1763'},
      '580028': {'lat': '15.197', 'name': 'Mattigatti,Dharwad,Karnataka', 'lon': '75.2014'},
      '342603': {'lat': '26.6501', 'name': 'Nadsar,Jodhpur,Rajasthan', 'lon': '73.4206'},
      '342602': {'lat': '26.1078', 'name': 'Jetiwas,Jodhpur,Rajasthan', 'lon': '73.9466'},
      '342601': {'lat': '26.3004', 'name': 'Bhagasani,Jodhpur,Rajasthan', 'lon': '73.9768'},
      '786614': {'lat': '27.3779', 'name': 'Tipam,Dibrugarh,Assam', 'lon': '95.2828'},
      '793210': {'lat': '25.3816', 'name': 'Lumshnong,Jaintia Hills,Meghalaya', 'lon': '92.4569'},
      '580020': {'lat': '15.318', 'name': 'Hubli Mangalwar Peth,Dharwad,Karnataka', 'lon': '75.1763'},
      '786610': {'lat': '27.3779', 'name': 'Dhaman,Dibrugarh,Assam', 'lon': '95.2828'},
      '516362': {'lat': '14.7616', 'name': 'Maduru,Cuddapah,Andhra Pradesh', 'lon': '78.6171'},
      '516361': {'lat': '14.896', 'name': 'Somulavaripalle,Cuddapah,Andhra Pradesh', 'lon': '79.0473'},
      '516360': {'lat': '14.8127', 'name': 'Korrapadu Road,Cuddapah,Andhra Pradesh', 'lon': '78.9643'},
      '262901': {'lat': '28.0712', 'name': 'Ambora,Kheri,Uttar Pradesh', 'lon': '80.7285'},
      '262903': {'lat': '27.2932', 'name': 'Rakehati,Kheri,Uttar Pradesh', 'lon': '80.6184'},
      '847224': {'lat': '26.3649', 'name': 'Devhar,Madhubani,Bihar', 'lon': '86.2785'},
      '533274': {'lat': '17.0747', 'name': 'Ganti,East Godavari,Andhra Pradesh', 'lon': '81.7932'},
      '262904': {'lat': '27.481', 'name': 'Sampurna Nagar,Kheri,Uttar Pradesh', 'lon': '81.0253'},
      '262907': {'lat': '27.9225', 'name': 'Dhakherwa Khalsa,Kheri,Uttar Pradesh', 'lon': '80.33'},
      '262906': {'lat': '27.9157', 'name': 'Belaparsua,Kheri,Uttar Pradesh', 'lon': '80.892'},
      '262908': {'lat': '27.2474', 'name': 'Bhoora,Kheri,Uttar Pradesh', 'lon': '81.7008'},
      '755001': {'lat': '20.8112', 'name': 'Jajpur College,Jajapur,Odisha', 'lon': '85.7612'},
      '572175': {'lat': '13.6873', 'name': 'Doddahosahalli,Tumakuru,Karnataka', 'lon': '77.315'},
      '272125': {'lat': '26.849', 'name': 'Chhata,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.9449'},
      '384345': {'lat': '23.9321', 'name': 'Sundhia,Mahesana,Gujarat', 'lon': '72.7025'},
      '788108': {'lat': '24.9431', 'name': 'Martycherra,Cachar,Assam', 'lon': '92.9591'},
      '788109': {'lat': '24.977', 'name': 'Kumbhirgram Airport,Cachar,Assam', 'lon': '92.7592'},
      '384340': {'lat': '23.6893', 'name': 'Sardarpur,Mahesana,Gujarat', 'lon': '72.7685'},
      '284302': {'lat': '25.5054', 'name': 'Kudri,Jhansi,Uttar Pradesh', 'lon': '78.954'},
      '284301': {'lat': '25.5536', 'name': 'Aupara,Jhansi,Uttar Pradesh', 'lon': '78.8492'},
      '788102': {'lat': '24.977', 'name': 'Barthal T E,Cachar,Assam', 'lon': '92.7592'},
      '788103': {'lat': '24.977', 'name': 'Lakhipur (Cachar),Cachar,Assam', 'lon': '92.7592'},
      '788101': {'lat': '25.1913', 'name': 'Daluagram,Cachar,Assam', 'lon': '92.5868'},
      '788106': {'lat': '24.977', 'name': 'Fulertal,Cachar,Assam', 'lon': '92.7592'},
      '788107': {'lat': '24.3235', 'name': 'Bararkap,Cachar,Assam', 'lon': '92.8981'},
      '788104': {'lat': '24.8072', 'name': 'Uttar Lalpani,Cachar,Assam', 'lon': '93.1099'},
      '385340': {'lat': '23.6593', 'name': 'Ganjisar,Patan,Gujarat', 'lon': '71.973'},
      '388110': {'lat': '23.0996', 'name': 'Hadgood,Anand,Gujarat', 'lon': '73.1479'},
      '734101': {'lat': '27.051', 'name': 'Rose Bank,Darjiling,West Bengal', 'lon': '88.2618'},
      '272164': {'lat': '26.4826', 'name': 'Devapar,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.9523'},
      '508280': {'lat': '17.1422', 'name': 'Gonrentla,Nalgonda,Telangana', 'lon': '79.5929'},
      '508286': {'lat': '17.5397', 'name': 'Veeravally,Nalgonda,Telangana', 'lon': '78.9806'},
      '508285': {'lat': '17.4539', 'name': 'Pahilwanpur,Nalgonda,Telangana', 'lon': '78.9761'},
      '508284': {'lat': '17.3195', 'name': 'Pochampalli (Nalgonda),Nalgonda,Telangana', 'lon': '79.2856'},
      '673631': {'lat': '11.1799', 'name': 'Perumukham,Kozhikode,Kerala', 'lon': '75.8414'},
      '370490': {'lat': '23.0523', 'name': 'Varadia,Kachchh,Gujarat', 'lon': '68.9775'},
      '802136': {'lat': '25.5652', 'name': 'Khirauli,Buxar,Bihar', 'lon': '84.1386'},
      '573136': {'lat': '12.6385', 'name': 'Bidarakka,Hassan,Karnataka', 'lon': '76.1841'},
      '625207': {'lat': '10.9892', 'name': 'Thenkarai,Madurai,Tamil Nadu', 'lon': '78.2501'},
      '673633': {'lat': '11.178', 'name': 'Vazhayur East,Malappuram,Kerala', 'lon': '75.8689'},
      '612701': {'lat': '10.9102', 'name': 'Oothucadu,Tiruvarur,Tamil Nadu', 'lon': '79.3393'},
      '612702': {'lat': '10.9499', 'name': 'Darasuram,Thanjavur,Tamil Nadu', 'lon': '79.3561'},
      '673632': {'lat': '11.2002', 'name': 'Karad Paramba,Malappuram,Kerala', 'lon': '75.867'},
      '623523': {'lat': '9.2726', 'name': 'Kumbaram,Ramanathapuram,Tamil Nadu', 'lon': '78.9023'},
      '272148': {'lat': '26.8864', 'name': 'Pipra Pratham,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.8291'},
      '686587': {'lat': '9.1223', 'name': 'Elakad,Kottayam,Kerala', 'lon': '76.7576'},
      '625122': {'lat': '11.7359', 'name': 'Vellaripatti,Madurai,Tamil Nadu', 'lon': '78.6225'},
      '721451': {'lat': '22.3836', 'name': 'Deulinekurseni,West Midnapore,West Bengal', 'lon': '87.8747'},
      '686586': {'lat': '9.4741', 'name': 'Moonnilavu,Kottayam,Kerala', 'lon': '76.6794'},
      '144422': {'lat': '31.0527', 'name': 'Urapar,Nawanshahr,Punjab', 'lon': '75.9633'},
      '144421': {'lat': '31.0921', 'name': 'Mehmoodpur,Nawanshahr,Punjab', 'lon': '76.0376'},
      '332411': {'lat': '27.6366', 'name': 'Malikpur,Sikar,Rajasthan', 'lon': '75.2875'},
      '686585': {'lat': '9.5966', 'name': 'Chengalam,Kottayam,Kerala', 'lon': '76.4678'},
      '754245': {'lat': '20.9911', 'name': 'Babar,Kendrapara,Odisha', 'lon': '84.9048'},
      '451881': {'lat': '21.6918', 'name': 'Temala,Barwani,Madhya Pradesh', 'lon': '74.6155'},
      '686584': {'lat': '9.4741', 'name': 'Chempilavu,Kottayam,Kerala', 'lon': '76.6794'},
      '431011': {'lat': '19.705', 'name': 'Kanchanwadi,Aurangabad,Maharashtra', 'lon': '75.7394'},
      '431010': {'lat': '19.705', 'name': 'Satara Parisar,Aurangabad,Maharashtra', 'lon': '75.7394'},
      '577131': {'lat': '13.4338', 'name': 'Pandaavalli,Chikkamagaluru,Karnataka', 'lon': '75.7662'},
      '577130': {'lat': '13.3499', 'name': 'Sirvase,Chikkamagaluru,Karnataka', 'lon': '75.6544'},
      '577137': {'lat': '13.5175', 'name': 'Togarihanakal,Chikkamagaluru,Karnataka', 'lon': '75.806'},
      '577136': {'lat': '13.3613', 'name': 'Kadavanthi,Chikkamagaluru,Karnataka', 'lon': '75.6905'},
      '577135': {'lat': '13.8198', 'name': 'Sakrepatna,Chikkamagaluru,Karnataka', 'lon': '76.0514'},
      '577134': {'lat': '13.6108', 'name': 'Madabur,Chikkamagaluru,Karnataka', 'lon': '75.512'},
      '577139': {'lat': '13.4292', 'name': 'Masige,Chikkamagaluru,Karnataka', 'lon': '75.281'},
      '577138': {'lat': '13.428', 'name': 'S. Madapura,Chikkamagaluru,Karnataka', 'lon': '76.1692'},
      '248124': {'lat': '30.6908', 'name': 'Lakhamandal,Dehradun,Uttarakhand', 'lon': '77.9668'},
      '735133': {'lat': '26.4845', 'name': 'Debithakurbari,Jalpaiguri,West Bengal', 'lon': '88.6071'},
      '246401': {'lat': '30.1579', 'name': 'Tangsa,Chamoli,Uttarakhand', 'lon': '79.3713'},
      '607302': {'lat': '11.5415', 'name': 'Vehakollai,Cuddalore,Tamil Nadu', 'lon': '78.7192'},
      '755062': {'lat': '22.0443', 'name': 'Jharanga,Jajapur,Odisha', 'lon': '85.606'},
      '755061': {'lat': '20.0629', 'name': 'Erdang Natra,Kendrapara,Odisha', 'lon': '84.8975'},
      '607301': {'lat': '12.1209', 'name': 'Ayikuppam,Cuddalore,Tamil Nadu', 'lon': '79.4639'},
      '607308': {'lat': '11.7497', 'name': 'Gandhi Nagar (Cuddalore),Cuddalore,Tamil Nadu', 'lon': '79.0886'},
      '413716': {'lat': '19.4762', 'name': 'Musalwadi,Ahmed Nagar,Maharashtra', 'lon': '75.5072'},
      '413717': {'lat': '19.5639', 'name': 'Karegaon Factory,Ahmed Nagar,Maharashtra', 'lon': '75.2002'},
      '413714': {'lat': '19.4762', 'name': 'Nimgaon Jali,Ahmed Nagar,Maharashtra', 'lon': '75.5072'},
      '413715': {'lat': '19.0471', 'name': 'Narsali,Ahmed Nagar,Maharashtra', 'lon': '75.0643'},
      '413712': {'lat': '19.2034', 'name': 'Lohgaon,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '413713': {'lat': '19.4762', 'name': 'Loni Kd,Ahmed Nagar,Maharashtra', 'lon': '75.5072'},
      '413710': {'lat': '19.5405', 'name': 'Kolhar Kd,Ahmed Nagar,Maharashtra', 'lon': '74.5339'},
      '413711': {'lat': '21.0725', 'name': 'Pathre Bk,Ahmed Nagar,Maharashtra', 'lon': '77.3501'},
      '413718': {'lat': '19.6637', 'name': 'Harigaon,Ahmed Nagar,Maharashtra', 'lon': '74.7031'},
      '413719': {'lat': '19.4762', 'name': 'Ekrukhe ,Ahmed Nagar,Maharashtra', 'lon': '75.5072'},
      '691015': {'lat': '8.7996', 'name': 'Kilikollur East,Kollam,Kerala', 'lon': '76.9297'},
      '691014': {'lat': '8.9292', 'name': 'Chandanathope,Kollam,Kerala', 'lon': '76.6409'},
      '643241': {'lat': '11.4344', 'name': 'Kunnalady,Nilgiris,Tamil Nadu', 'lon': '76.6831'},
      '691016': {'lat': '8.8721', 'name': 'Thekkevila,Kollam,Kerala', 'lon': '76.6734'},
      '691011': {'lat': '8.8576', 'name': 'Eravipuram,Kollam,Kerala', 'lon': '76.6279'},
      '691010': {'lat': '8.878', 'name': 'Vadakkevila,Kollam,Kerala', 'lon': '76.6305'},
      '691013': {'lat': '8.8721', 'name': 'Kollam Civil Station,Kollam,Kerala', 'lon': '76.6734'},
      '691012': {'lat': '8.8721', 'name': 'Thirumullavaram,Kollam,Kerala', 'lon': '76.6734'},
      '492012': {'lat': '21.1879', 'name': 'Krishak Nagar,Raipur,Chattisgarh', 'lon': '81.6898'},
      '635751': {'lat': '12.6841', 'name': 'Khaderpet,Vellore,Tamil Nadu', 'lon': '78.6207'},
      '785705': {'lat': '26.3242', 'name': 'Chowdangpathar,Golaghat,Assam', 'lon': '94.0871'},
      '785704': {'lat': '26.3242', 'name': 'Murmuria,Jorhat,Assam', 'lon': '94.0871'},
      '691019': {'lat': '8.9017', 'name': 'Uliyakovil,Kollam,Kerala', 'lon': '76.6017'},
      '785702': {'lat': '26.3242', 'name': 'Murphulani,Golaghat,Assam', 'lon': '94.0871'},
      '785701': {'lat': '26.3242', 'name': 'Bhadhara,Sibsagar,Assam', 'lon': '94.0871'},
      '785700': {'lat': '26.3242', 'name': 'Kopradhara,Jorhat,Assam', 'lon': '94.0871'},
      '638706': {'lat': '10.8137', 'name': 'Sankarandampalayam,Erode,Tamil Nadu', 'lon': '77.527'},
      '642111': {'lat': '11.0299', 'name': 'Krishnapuram (Coimbatore),Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '642112': {'lat': '11.0299', 'name': 'Dhully,Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '642113': {'lat': '11.0299', 'name': 'Madathukulam,Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '642114': {'lat': '11.0299', 'name': 'Malayandipattinam,Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '642117': {'lat': '11.0299', 'name': 'Mudis,Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '742405': {'lat': '24.3965', 'name': 'Charmahula,Murshidabad,West Bengal', 'lon': '87.961'},
      '802132': {'lat': '25.3259', 'name': 'Satowanti,Kaimur (Bhabua),Bihar', 'lon': '83.7483'},
      '261401': {'lat': '27.5177', 'name': 'Islamnagar,Sitapur,Uttar Pradesh', 'lon': '80.8574'},
      '712149': {'lat': '23.5692', 'name': 'Panchgora,Hooghly,West Bengal', 'lon': '88.0292'},
      '712148': {'lat': '23.3728', 'name': 'Ramnathpur,Hooghly,West Bengal', 'lon': '88.1905'},
      '470125': {'lat': '23.5734', 'name': 'Toda Tarafdar,Sagar,Madhya Pradesh', 'lon': '78.775'},
      '506013': {'lat': '17.5325', 'name': 'Pragathi Industrial Estate,Warangal,Telangana', 'lon': '79.0467'},
      '742407': {'lat': '23.9685', 'name': 'Chaltia,Murshidabad,West Bengal', 'lon': '87.8257'},
      '487770': {'lat': '22.7449', 'name': 'Gotitoriya,Narsinghpur,Madhya Pradesh', 'lon': '78.8144'},
      '742406': {'lat': '23.3102', 'name': 'Aminabad,Murshidabad,West Bengal', 'lon': '87.2788'},
      '712147': {'lat': '23.398', 'name': 'Hoera,Hooghly,West Bengal', 'lon': '87.7477'},
      '712146': {'lat': '23.3102', 'name': 'Ilsobamondalai,Hooghly,West Bengal', 'lon': '87.2788'},
      '638054': {'lat': '11.3635', 'name': 'Vellankoil,Erode,Tamil Nadu', 'lon': '77.46'},
      '638055': {'lat': '11.3479', 'name': 'Kuthambi,Erode,Tamil Nadu', 'lon': '77.4929'},
      '638056': {'lat': '11.2672', 'name': 'Pallagoundenpalayam,Erode,Tamil Nadu', 'lon': '77.9895'},
      '638057': {'lat': '11.3429', 'name': 'Seenapuram,Erode,Tamil Nadu', 'lon': '77.5372'},
      '638051': {'lat': '11.2595', 'name': 'Mukasipidariyur,Erode,Tamil Nadu', 'lon': '77.6698'},
      '761008': {'lat': '20.0452', 'name': 'Sunadei,Ganjam,Odisha', 'lon': '84.9659'},
      '761009': {'lat': '20.043', 'name': 'Koturu,Ganjam,Odisha', 'lon': '84.6615'},
      '761006': {'lat': '19.7452', 'name': 'Barango,Ganjam,Odisha', 'lon': '84.4079'},
      '761007': {'lat': '19.4069', 'name': 'Golanda,Ganjam,Odisha', 'lon': '83.5705'},
      '761004': {'lat': '20.8222', 'name': 'Kelua,Ganjam,Odisha', 'lon': '84.367'},
      '761005': {'lat': '19.0236', 'name': 'Jarada Ankuli,Ganjam,Odisha', 'lon': '84.3752'},
      '761002': {'lat': '19.0714', 'name': 'Venkataraipur,Ganjam,Odisha', 'lon': '84.5469'},
      '761003': {'lat': '19.4951', 'name': 'Bellagam,Ganjam,Odisha', 'lon': '84.6796'},
      '485115': {'lat': '24.2373', 'name': 'Jhiriya,Satna,Madhya Pradesh', 'lon': '81.0657'},
      '761001': {'lat': '19.7843', 'name': 'Bhabinipur,Ganjam,Odisha', 'lon': '83.5272'},
      '721603': {'lat': '22.2016', 'name': 'Natshal,East Midnapore,West Bengal', 'lon': '88.0368'},
      '629809': {'lat': '8.203', 'name': 'Peyankuzhi,Kanyakumari,Tamil Nadu', 'lon': '77.3476'},
      '757014': {'lat': '21.2971', 'name': 'Baghuasole,Mayurbhanj,Odisha', 'lon': '85.2582'},
      '629804': {'lat': '8.202', 'name': 'Chemponvilai,Kanyakumari,Tamil Nadu', 'lon': '77.3469'},
      '389190': {'lat': '23.1387', 'name': 'Sagdapada,Dahod,Gujarat', 'lon': '74.02092'},
      '629801': {'lat': '8.202', 'name': 'Kattimancode,Kanyakumari,Tamil Nadu', 'lon': '77.3469'},
      '623528': {'lat': '9.2235', 'name': 'Kilakidaram,Ramanathapuram,Tamil Nadu', 'lon': '78.6339'},
      '629803': {'lat': '8.202', 'name': 'Konamkadu,Kanyakumari,Tamil Nadu', 'lon': '77.3469'},
      '629802': {'lat': '8.202', 'name': 'Alancode,Kanyakumari,Tamil Nadu', 'lon': '77.3469'},
      '755006': {'lat': '20.8112', 'name': 'Dehurianandapur,Jajapur,Odisha', 'lon': '85.7612'},
      '392135': {'lat': '22.5135', 'name': 'Jolva,Bharuch,Gujarat', 'lon': '73.113'},
      '306114': {'lat': '25.8846', 'name': 'Bagri Nagar,Pali,Rajasthan', 'lon': '73.9008'},
      '392130': {'lat': '22.5135', 'name': 'Nandida,Bharuch,Gujarat', 'lon': '73.113'},
      '571302': {'lat': '12.2088', 'name': 'Hadinaru,Mysuru,Karnataka', 'lon': '76.6717'},
      '791102': {'lat': '28.0475', 'name': 'Nari,East Siang,Arunachal Pradesh', 'lon': '95.2829'},
      '791103': {'lat': '28.3184', 'name': 'GTC,East Siang,Arunachal Pradesh', 'lon': '95.077'},
      '515511': {'lat': '14.4683', 'name': 'Uppalapadu,Ananthapur,Andhra Pradesh', 'lon': '77.915'},
      '791105': {'lat': '28.9834', 'name': 'Singa,Upper Siang,Arunachal Pradesh', 'lon': '94.8568'},
      '577555': {'lat': '14.6053', 'name': 'Pandarahally,Chitradurga,Karnataka', 'lon': '75.9703'},
      '247669': {'lat': '29.6573', 'name': 'Harora,Saharanpur,Uttar Pradesh', 'lon': '78.073'},
      '247668': {'lat': '29.8415', 'name': 'Manakpur Adampur,Haridwar,Uttarakhand', 'lon': '77.7596'},
      '531126': {'lat': '17.4184', 'name': 'Gullipadu,Visakhapatnam,Andhra Pradesh', 'lon': '82.6397'},
      '531127': {'lat': '17.3431', 'name': 'Pedaramabhadrapuram,Visakhapatnam,Andhra Pradesh', 'lon': '82.5846'},
      '247667': {'lat': '29.9144', 'name': 'Marghoobpur,Haridwar,Uttarakhand', 'lon': '77.9291'},
      '623529': {'lat': '9.4263', 'name': 'Ariyangundu,Ramanathapuram,Tamil Nadu', 'lon': '78.8147'},
      '247661': {'lat': '29.6573', 'name': 'Chudiala,Haridwar,Uttarakhand', 'lon': '78.073'},
      '670650': {'lat': '11.8455', 'name': 'Nedumpoil,Kannur,Kerala', 'lon': '75.8127'},
      '247663': {'lat': '29.7351', 'name': 'Dabki Kalan,Haridwar,Uttarakhand', 'lon': '77.994'},
      '247662': {'lat': '28.8152', 'name': 'Kheri Shikohpur,Haridwar,Uttarakhand', 'lon': '78.6641'},
      '673613': {'lat': '11.404', 'name': 'Kakkur,Kozhikode,Kerala', 'lon': '75.8136'},
      '673612': {'lat': '11.4484', 'name': 'Kannadipoil,Kozhikode,Kerala', 'lon': '75.8301'},
      '673611': {'lat': '11.3255', 'name': 'Kizhakkumuri,Kozhikode,Kerala', 'lon': '75.8038'},
      '678642': {'lat': '10.804', 'name': 'Mannur West,Palakkad,Kerala', 'lon': '76.4652'},
      '389220': {'lat': '23.0299', 'name': 'Hathivan,Panch Mahals,Gujarat', 'lon': '73.4953'},
      '673616': {'lat': '11.431', 'name': 'Kannan Kara,Kozhikode,Kerala', 'lon': '75.8041'},
      '673614': {'lat': '11.4878', 'name': 'Tiruvode,Kozhikode,Kerala', 'lon': '75.7743'},
      '124111': {'lat': '28.957', 'name': 'Girawar,Rohtak,Haryana', 'lon': '76.4015'},
      '531023': {'lat': '17.8042', 'name': 'Govada,Visakhapatnam,Andhra Pradesh', 'lon': '82.9644'},
      '124113': {'lat': '28.1853', 'name': 'Khairari,Rohtak,Haryana', 'lon': '77.4127'},
      '124112': {'lat': '29.3352', 'name': 'Bhaini Surjan,Rohtak,Haryana', 'lon': '76.1416'},
      '755008': {'lat': '20.4313', 'name': 'Singhapur,Jajapur,Odisha', 'lon': '84.8701'},
      '531021': {'lat': '17.6283', 'name': 'Payakarao Bonangi,Visakhapatnam,Andhra Pradesh', 'lon': '83.0793'},
      '754024': {'lat': '20.7157', 'name': 'Barithengada,Jajapur,Odisha', 'lon': '85.5971'},
      '680612': {'lat': '10.5089', 'name': 'Kanjani,Thrissur,Kerala', 'lon': '76.1306'},
      '500095': {'lat': '17.3535', 'name': 'Putlibowli,Hyderabad,Telangana', 'lon': '78.2402'},
      '754025': {'lat': '20.5392', 'name': 'Harianta,Cuttack,Odisha', 'lon': '85.9151'},
      '676553': {'lat': '10.9479', 'name': 'Kallarmangalam,Malappuram,Kerala', 'lon': '76.0252'},
      '680615': {'lat': '10.5056', 'name': 'Engandiyur,Thrissur,Kerala', 'lon': '76.0656'},
      '676551': {'lat': '10.9173', 'name': 'Parammalangadi,Malappuram,Kerala', 'lon': '75.9706'},
      '754026': {'lat': '20.5697', 'name': 'Daulatabad (Cuttack),Cuttack,Odisha', 'lon': '85.4842'},
      '680614': {'lat': '10.5089', 'name': 'Vatanapally Beach,Thrissur,Kerala', 'lon': '76.1306'},
      '798622': {'lat': '26.8149', 'name': 'Kongon,Mon,Nagaland', 'lon': '94.8108'},
      '754027': {'lat': '20.5697', 'name': 'Koyalpada,Cuttack,Odisha', 'lon': '85.4842'},
      '176048': {'lat': '31.6429575', 'name': 'Kakrot,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427092'},
      '176049': {'lat': '32.208', 'name': 'Dakhyora,Hamirpur(HP),Himachal Pradesh', 'lon': '76.1157'},
      '176044': {'lat': '31.6429595', 'name': 'Mundkhar,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427072'},
      '176045': {'lat': '31.6404988', 'name': 'Bhoranj,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6375442'},
      '176047': {'lat': '32.0477', 'name': 'Barana,Kangra,Himachal Pradesh', 'lon': '76.3801'},
      '176040': {'lat': '31.5120757', 'name': 'Bijhari,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5510905'},
      '176041': {'lat': '31.5284202', 'name': 'Bhota,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4635544'},
      '176042': {'lat': '32.05515', 'name': 'Samtana Kalan,Hamirpur(HP),Himachal Pradesh', 'lon': '76.21695'},
      '176043': {'lat': '31.6429585', 'name': 'Ladraur,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427082'},
      '141103': {'lat': '30.7984', 'name': 'Mohie,Ludhiana,Punjab', 'lon': '75.7216'},
      '141102': {'lat': '30.8722', 'name': 'Issewal,Ludhiana,Punjab', 'lon': '75.7053'},
      '141101': {'lat': '30.8381', 'name': 'Jangpura,Ludhiana,Punjab', 'lon': '75.6614'},
      '141107': {'lat': '30.8406', 'name': 'Barhmi,Ludhiana,Punjab', 'lon': '75.6779'},
      '754022': {'lat': '20.7858', 'name': 'Bainchua,Cuttack,Odisha', 'lon': '84.8119'},
      '141105': {'lat': '31.3755', 'name': 'Sarabha,Ludhiana,Punjab', 'lon': '75.5361'},
      '141104': {'lat': '31.0092', 'name': 'Sudhar,Ludhiana,Punjab', 'lon': '75.7534'},
      '535580': {'lat': '18.446', 'name': 'Chinakada,Vizianagaram,Andhra Pradesh', 'lon': '83.4121'},
      '535581': {'lat': '18.3921', 'name': 'Uddangi,Vizianagaram,Andhra Pradesh', 'lon': '83.2589'},
      '141109': {'lat': '30.6572', 'name': 'Raekot,Ludhiana,Punjab', 'lon': '76.0842'},
      '690561': {'lat': '9.1119', 'name': 'Sooranad North,Kollam,Kerala', 'lon': '76.6237'},
      '495686': {'lat': '21.9865', 'name': 'Saragaon,Janjgir-champa,Chattisgarh', 'lon': '82.7558'},
      '503164': {'lat': '18.3473', 'name': 'Sirnapalli R.S.,Nizamabad,Telangana', 'lon': '78.2279'},
      '600067': {'lat': '12.9517', 'name': 'Erumaivettipalayam,Tiruvallur,Tamil Nadu', 'lon': '79.6216'},
      '802156': {'lat': '25.5227', 'name': 'Dariapur,Bhojpur,Bihar', 'lon': '84.6597'},
      '798620': {'lat': '26.0213', 'name': 'Tokiye,Zunhebotto,Nagaland', 'lon': '94.528'},
      '600064': {'lat': '13.0063', 'name': 'Hasthinapuram,Kanchipuram,Tamil Nadu', 'lon': '79.8869'},
      '391445': {'lat': '23.5549', 'name': 'Husepura,Vadodara,Gujarat', 'lon': '73.2091'},
      '735222': {'lat': '26.8537', 'name': 'Manabari,Jalpaiguri,West Bengal', 'lon': '89.0856'},
      '735221': {'lat': '26.8537', 'name': 'Panwar Basty,Jalpaiguri,West Bengal', 'lon': '89.0856'},
      '391110': {'lat': '23.0923', 'name': 'Shirola,Narmada,Gujarat', 'lon': '73.6732'},
      '735227': {'lat': '26.7024', 'name': 'Baksaduar,Alipurduar,West Bengal', 'lon': '89.6107'},
      '391440': {'lat': '22.917', 'name': 'Sandha,Vadodara,Gujarat', 'lon': '72.9269'},
      '391115': {'lat': '21.1355', 'name': 'Mota Fofalia,Vadodara,Gujarat', 'lon': '73.2578'},
      '735224': {'lat': '26.8537', 'name': 'Amguri Bazar,Cooch Behar,West Bengal', 'lon': '89.0856'},
      '711203': {'lat': '22.4233', 'name': 'Bhattanagar,Howrah,West Bengal', 'lon': '88.312'},
      '600062': {'lat': '13.158', 'name': 'Senthilnagar,Tiruvallur,Tamil Nadu', 'lon': '80.1063'},
      '735229': {'lat': '26.8537', 'name': 'Sailihat,Jalpaiguri,West Bengal', 'lon': '89.0856'},
      '735228': {'lat': '26.8537', 'name': 'Muznai,Alipurduar,West Bengal', 'lon': '89.0856'},
      '712204': {'lat': '23.2396', 'name': 'Chatra (Hooghly),Hooghly,West Bengal', 'lon': '88.1534'},
      '711205': {'lat': '21.7928', 'name': 'Abhoynagar,Howrah,West Bengal', 'lon': '88.2272'},
      '600063': {'lat': '13.0063', 'name': 'Srinivasanagar (Kanchipuram),Kanchipuram,Tamil Nadu', 'lon': '79.8869'},
      '600060': {'lat': '13.1269', 'name': 'Madhavaram (Tiruvallur),Tiruvallur,Tamil Nadu', 'lon': '80.2467'},
      '802157': {'lat': '25.5765', 'name': 'Gajrajganj,Bhojpur,Bihar', 'lon': '84.5335'},
      '798621': {'lat': '26.6983', 'name': 'Waunching,Mon,Nagaland', 'lon': '94.9508'},
      '600061': {'lat': '12.9805', 'name': 'Nanganallur,Kanchipuram,Tamil Nadu', 'lon': '80.1881'},
      '712203': {'lat': '23.2396', 'name': 'Bahirserampore,Hooghly,West Bengal', 'lon': '88.1534'},
      '700152': {'lat': '22.9182', 'name': 'Dhalua,South 24 Parganas,West Bengal', 'lon': '88.8563'},
      '587330': {'lat': '16.9796', 'name': 'Gothe,Bagalkot,Karnataka', 'lon': '76.5917'},
      '587331': {'lat': '16.2205', 'name': 'Hippargi,Bagalkot,Karnataka', 'lon': '75.6254'},
      '534455': {'lat': '17.1575', 'name': 'Taduvai,West Godavari,Andhra Pradesh', 'lon': '81.2495'},
      '248122': {'lat': '30.4409', 'name': 'Barlowganj,Dehradun,Uttarakhand', 'lon': '78.0892'},
      '534456': {'lat': '17.1159', 'name': 'Ratsannagudem,West Godavari,Andhra Pradesh', 'lon': '81.1317'},
      '534451': {'lat': '17.0692', 'name': 'G.Kothapalli,West Godavari,Andhra Pradesh', 'lon': '81.2466'},
      '534450': {'lat': '16.7713', 'name': 'Gopannapalem,West Godavari,Andhra Pradesh', 'lon': '81.1165'},
      '534452': {'lat': '16.9047', 'name': 'Vempally,West Godavari,Andhra Pradesh', 'lon': '81.1553'},
      '799101': {'lat': '23.5257', 'name': 'Paharpur,South Tripura,Tripura', 'lon': '91.6588'},
      '799102': {'lat': '23.6637', 'name': 'Krishnakishorenagar,Sepahijala,Tripura', 'lon': '91.2781'},
      '685620': {'lat': '10.2764', 'name': 'Michaelgiri,Idukki,Kerala', 'lon': '77.162'},
      '799104': {'lat': '23.5938', 'name': 'Lebacherra,South Tripura,Tripura', 'lon': '91.3855'},
      '799105': {'lat': '23.4879619', 'name': 'Palatana,Gomti,Tripura', 'lon': '91.429662'},
      '802154': {'lat': '25.2879', 'name': 'Bemari,Bhojpur,Bihar', 'lon': '84.4063'},
      '364150': {'lat': '21.7674', 'name': 'Royal,Bhavnagar,Gujarat', 'lon': '71.3684'},
      '441924': {'lat': '21.079', 'name': 'Manegaon,Bhandara,Maharashtra', 'lon': '79.6349'},
      '440037': {'lat': '21.1272', 'name': 'BESA Road,Nagpur,Maharashtra', 'lon': '79.0655'},
      '440036': {'lat': '21.1112', 'name': 'Jaitala,Nagpur,Maharashtra', 'lon': '79.0224'},
      '440035': {'lat': '21.1272', 'name': 'Kalmna Market Yard,Nagpur,Maharashtra', 'lon': '79.0655'},
      '440034': {'lat': '21.1272', 'name': 'Mhalginagar,Nagpur,Maharashtra', 'lon': '79.0655'},
      '440033': {'lat': '21.1272', 'name': 'University Campus (Nagpur),Nagpur,Maharashtra', 'lon': '79.0655'},
      '440032': {'lat': '21.1431', 'name': 'Mahal (Nagpur),Nagpur,Maharashtra', 'lon': '79.1086'},
      '122018': {'lat': '28.4001', 'name': 'Gurgaon South name II,Gurgaon,Haryana', 'lon': '76.9661'},
      '440030': {'lat': '21.1272', 'name': 'Nadt Campus,Nagpur,Maharashtra', 'lon': '79.0655'},
      '122016': {'lat': '28.4001', 'name': 'Industrial Complex Dundahera,Gurgaon,Haryana', 'lon': '76.9661'},
      '122017': {'lat': '28.4001', 'name': 'Palam Vihar (Gurgaon),Gurgaon,Haryana', 'lon': '76.9661'},
      '751018': {'lat': '20.7521', 'name': 'Badagarh Brit Colony,Khorda,Odisha', 'lon': '84.5579'},
      '122015': {'lat': '28.4001', 'name': 'Palam Road,Gurgaon,Haryana', 'lon': '76.9661'},
      '364485': {'lat': '21.8211', 'name': 'Amarnagar (Rajkot),Rajkot,Gujarat', 'lon': '70.9454'},
      '122010': {'lat': '28.4001', 'name': 'DLF Ph-III,Gurgaon,Haryana', 'lon': '76.9661'},
      '122011': {'lat': '28.4001', 'name': 'Gurgaon Sector 56,Gurgaon,Haryana', 'lon': '76.9661'},
      '534186': {'lat': '17.5283', 'name': 'Vundurru,West Godavari,Andhra Pradesh', 'lon': '82.307'},
      '500034': {'lat': '17.4168', 'name': 'Banjara Hills,Hyderabad,Telangana', 'lon': '78.4384'},
      '798627': {'lat': '26.1661', 'name': 'Lumami,Zunhebotto,Nagaland', 'lon': '94.4871'},
      '211022': {'lat': '25.5156', 'name': 'C.R.P.F Group Centre Ismailganj,Allahabad,Uttar Pradesh', 'lon': '81.7868'},
      '146108': {'lat': '31.3262', 'name': 'Makhsoospur,Hoshiarpur,Punjab', 'lon': '75.9508'},
      '146109': {'lat': '31.3471', 'name': 'Gandhowal,Hoshiarpur,Punjab', 'lon': '75.884'},
      '146106': {'lat': '31.5164', 'name': 'Ganeshpur Bharta,Hoshiarpur,Punjab', 'lon': '75.8385'},
      '146107': {'lat': '31.3224', 'name': 'Paldi (Hoshiarpur),Hoshiarpur,Punjab', 'lon': '75.9822'},
      '146104': {'lat': '31.4435', 'name': 'Patti,Hoshiarpur,Punjab', 'lon': '75.9331'},
      '146105': {'lat': '31.3534', 'name': 'Bahowal,Hoshiarpur,Punjab', 'lon': '75.9805'},
      '146102': {'lat': '31.4902', 'name': 'Chabbewal,Hoshiarpur,Punjab', 'lon': '75.5052'},
      '581237': {'lat': '14.9833', 'name': 'Hesarur,Haveri,Karnataka', 'lon': '75.5112'},
      '146101': {'lat': '31.5497', 'name': 'Manolian,Hoshiarpur,Punjab', 'lon': '75.6624'},
      '700133': {'lat': '22.5719', 'name': 'Michealnagar,North 24 Parganas,West Bengal', 'lon': '88.394'},
      '752118': {'lat': '20.2479', 'name': 'Jaleswarpada,Puri,Odisha', 'lon': '85.8149'},
      '364730': {'lat': '22.9013', 'name': 'Rasnal,Bhavnagar,Gujarat', 'lon': '69.7646'},
      '271881': {'lat': '27.6243', 'name': 'Charda,Bahraich,Uttar Pradesh', 'lon': '81.3253'},
      '752113': {'lat': '20.1446', 'name': 'Tentuligaon,Puri,Odisha', 'lon': '85.0773'},
      '752110': {'lat': '19.967', 'name': 'Narua,Puri,Odisha', 'lon': '86.0132'},
      '752111': {'lat': '20.6307', 'name': 'Junei,Puri,Odisha', 'lon': '85.5908'},
      '752116': {'lat': '20.2479', 'name': 'Mahalpada,Puri,Odisha', 'lon': '85.8149'},
      '609405': {'lat': '10.9629', 'name': 'Injikudi,Tiruvarur,Tamil Nadu', 'lon': '79.6608'},
      '752114': {'lat': '20.1804', 'name': 'Biswalpada,Puri,Odisha', 'lon': '86.0867'},
      '752115': {'lat': '20.2479', 'name': 'Bhatpatna,Khorda,Odisha', 'lon': '85.8149'},
      '343024': {'lat': '25.3412', 'name': 'Chandana,Jalor,Rajasthan', 'lon': '74.0134'},
      '343025': {'lat': '25.2348', 'name': 'Degaon,Jalor,Rajasthan', 'lon': '72.5025'},
      '343027': {'lat': '24.8015', 'name': 'Surachand,Jalor,Rajasthan', 'lon': '71.5476'},
      '225416': {'lat': '26.9386', 'name': 'Jargawan,Barabanki,Uttar Pradesh', 'lon': '81.3274'},
      '343021': {'lat': '25.3482', 'name': 'Elana,Jalor,Rajasthan', 'lon': '73.2594'},
      '751016': {'lat': '20.7521', 'name': 'Chandra Sekhar Pur,Khorda,Odisha', 'lon': '84.5579'},
      '343023': {'lat': '25.1992', 'name': 'Dhansa,Jalor,Rajasthan', 'lon': '72.2728'},
      '502307': {'lat': '17.7399', 'name': 'Pashamailaram,Medak,Telangana', 'lon': '78.1477'},
      '831001': {'lat': '22.808', 'name': 'Bistupur Bazar,East Singhbhum,Jharkhand', 'lon': '86.2029'},
      '752030': {'lat': '20.606', 'name': 'Badakul,Khorda,Odisha', 'lon': '85.1467'},
      '343028': {'lat': '25.1843', 'name': 'Serna,Jalor,Rajasthan', 'lon': '72.4304'},
      '343029': {'lat': '24.9185', 'name': 'Nimbawas,Jalor,Rajasthan', 'lon': '74.367'},
      '631552': {'lat': '12.915', 'name': 'Siruvakkam,Kanchipuram,Tamil Nadu', 'lon': '79.714'},
      '798625': {'lat': '26.4794', 'name': 'Phomching,lonleng,Nagaland', 'lon': '94.8107'},
      '691578': {'lat': '8.8368', 'name': 'Chirakkarathazham,Kollam,Kerala', 'lon': '76.7334'},
      '691579': {'lat': '8.8663', 'name': 'Uliyanad,Kollam,Kerala', 'lon': '76.7057'},
      '691576': {'lat': '8.908', 'name': 'Kannanallur,Kollam,Kerala', 'lon': '76.693'},
      '691577': {'lat': '8.8663', 'name': 'Decent Jn,Kollam,Kerala', 'lon': '76.7057'},
      '691574': {'lat': '8.8663', 'name': 'Kizhakkenela,Kollam,Kerala', 'lon': '76.7057'},
      '691572': {'lat': '8.8663', 'name': 'Chathannur,Kollam,Kerala', 'lon': '76.7057'},
      '691573': {'lat': '8.8805', 'name': 'Kummallur,Kollam,Kerala', 'lon': '76.7156'},
      '691571': {'lat': '8.8698', 'name': 'Mylakkadu,Kollam,Kerala', 'lon': '76.689'},
      '415413': {'lat': '17.9519', 'name': 'Masuchiwadi,Sangli,Maharashtra', 'lon': '75.7536'},
      '415412': {'lat': '17.9519', 'name': 'Devwadi,Sangli,Maharashtra', 'lon': '75.7536'},
      '415411': {'lat': '18.2356', 'name': 'Kurlap,Sangli,Maharashtra', 'lon': '76.2851'},
      '415410': {'lat': '17.1009', 'name': 'Panumbre Turf Shirala,Sangli,Maharashtra', 'lon': '74.1591'},
      '751017': {'lat': '20.7521', 'name': 'Mancheswar Railway Colony,Khorda,Odisha', 'lon': '84.5579'},
      '450110': {'lat': '22.0592', 'name': 'Borkheda Kalan,East Nimar,Madhya Pradesh', 'lon': '76.577'},
      '415415': {'lat': '17.9519', 'name': 'Warnavati Vasahat,Sangli,Maharashtra', 'lon': '75.7536'},
      '415414': {'lat': '17.9519', 'name': 'S S K Sakharale,Sangli,Maharashtra', 'lon': '75.7536'},
      '450119': {'lat': '22.0598', 'name': 'Narmada Nagar (East Nimar),East Nimar,Madhya Pradesh', 'lon': '76.5164'},
      '388470': {'lat': '22.5289', 'name': 'Sunav,Anand,Gujarat', 'lon': '72.7989'},
      '322205': {'lat': '26.4307', 'name': 'Khandeep,Sawai Madhopur,Rajasthan', 'lon': '76.1572'},
      '416143': {'lat': '16.4697', 'name': 'Lat,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '416144': {'lat': '19.2647', 'name': 'Akiwate Industrial Estate,Kolhapur,Maharashtra', 'lon': '77.4552'},
      '416146': {'lat': '19.2647', 'name': 'Yadrav,Kolhapur,Maharashtra', 'lon': '77.4552'},
      '402401': {'lat': '18.3388', 'name': 'Janjira Murud,Raigarh(MH),Maharashtra', 'lon': '73.02045'},
      '136130': {'lat': '30.0972', 'name': 'Ajrana Kalan,Kurukshetra,Haryana', 'lon': '76.787'},
      '136131': {'lat': '29.9764', 'name': 'Mathana,Kurukshetra,Haryana', 'lon': '76.9556'},
      '136132': {'lat': '30.0241', 'name': 'Bakali,Kurukshetra,Haryana', 'lon': '77.0593'},
      '276129': {'lat': '25.9155', 'name': 'Karami,Mau,Uttar Pradesh', 'lon': '83.27'},
      '382610': {'lat': '23.2631', 'name': 'Piplaj,Gandhi Nagar,Gujarat', 'lon': '72.6739'},
      '136135': {'lat': '30.1502', 'name': 'Landi,Kurukshetra,Haryana', 'lon': '76.8588'},
      '136136': {'lat': '30.1748', 'name': 'Mandheri,Kurukshetra,Haryana', 'lon': '76.7415'},
      '621210': {'lat': '11.1659', 'name': 'Karikali,Tiruchirappalli,Tamil Nadu', 'lon': '78.4635'},
      '635655': {'lat': '13.0515', 'name': 'Palayapalayam,Vellore,Tamil Nadu', 'lon': '79.5064'},
      '695008': {'lat': '8.4467', 'name': 'Beemapally,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '695009': {'lat': '8.4467', 'name': 'Manacaud,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '621214': {'lat': '11.1471', 'name': 'Mahadevi,Tiruchirappalli,Tamil Nadu', 'lon': '78.4022'},
      '621215': {'lat': '10.8096', 'name': 'Arangoor,Tiruchirappalli,Tamil Nadu', 'lon': '78.3968'},
      '621216': {'lat': '11.0063', 'name': 'Thalakudi,Tiruchirappalli,Tamil Nadu', 'lon': '78.5566'},
      '635654': {'lat': '12.8123', 'name': 'Natham,Vellore,Tamil Nadu', 'lon': '79.0104'},
      '695002': {'lat': '8.4467', 'name': 'Karumom,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '695003': {'lat': '8.5225', 'name': 'Kaudiar Square,Thiruvananthapuram,Kerala', 'lon': '76.9606'},
      '695001': {'lat': '8.4467', 'name': 'Thiruvananthapuram G.P.O.,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '695006': {'lat': '8.4467', 'name': 'Tirumala (Thiruvananthapuram),Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '695007': {'lat': '8.371', 'name': 'Thiruvananthapuram  Beach,Thiruvananthapuram,Kerala', 'lon': '77.0028'},
      '695004': {'lat': '8.4467', 'name': 'Mg College,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '695005': {'lat': '8.4467', 'name': 'Perurkada,Thiruvananthapuram,Kerala', 'lon': '76.9817'},
      '444604': {'lat': '20.8233', 'name': 'V.M.V. (Amravati),Amravati,Maharashtra', 'lon': '77.5284'},
      '444605': {'lat': '20.8233', 'name': 'H. V. Nagar,Amravati,Maharashtra', 'lon': '77.5284'},
      '444606': {'lat': '20.8233', 'name': 'Congress Nagar (Amravati),Amravati,Maharashtra', 'lon': '77.5284'},
      '444607': {'lat': '20.8233', 'name': 'Sai Nagar,Amravati,Maharashtra', 'lon': '77.5284'},
      '444601': {'lat': '20.9142', 'name': 'Bhaji Bazar Amravati,Amravati,Maharashtra', 'lon': '77.7597'},
      '444602': {'lat': '20.8142', 'name': 'Uttamsara,Amravati,Maharashtra', 'lon': '77.5053'},
      '444603': {'lat': '20.8233', 'name': 'Shivaji Nagar (Amravati),Amravati,Maharashtra', 'lon': '77.5284'},
      '561202': {'lat': '14.0222', 'name': 'Kotagudda,Tumakuru,Karnataka', 'lon': '77.2066'},
      '561203': {'lat': '13.1907', 'name': 'Sri Subramanyaghati,Bangalore Rural,Karnataka', 'lon': '77.3615'},
      '591287': {'lat': '15.9528', 'name': 'Chinchani,Belagavi,Karnataka', 'lon': '75.3478'},
      '561201': {'lat': '13.0839', 'name': 'Veeregowdanadoddi,Ramanagar,Karnataka', 'lon': '77.0768'},
      '561206': {'lat': '13.6137', 'name': 'Dn Palya,Chikkaballapur,Karnataka', 'lon': '77.5875'},
      '561207': {'lat': '13.6662', 'name': 'Yallampalli,Chikkaballapur,Karnataka', 'lon': '77.6597'},
      '561204': {'lat': '13.1926', 'name': 'Kadalappanahalli,Bangalore Rural,Karnataka', 'lon': '77.0548'},
      '561205': {'lat': '13.3697', 'name': 'Tubugere,Bangalore Rural,Karnataka', 'lon': '76.5972'},
      '751015': {'lat': '20.7521', 'name': 'I R C Village,Khorda,Odisha', 'lon': '84.5579'},
      '560078': {'lat': '13.2257', 'name': 'Yelachenahalli,Bengaluru,Karnataka', 'lon': '77.575'},
      '560079': {'lat': '12.836', 'name': 'Basaveshwaranagar,Bengaluru,Karnataka', 'lon': '77.4149'},
      '560072': {'lat': '13.2257', 'name': 'Nagarbhavi,Bengaluru,Karnataka', 'lon': '77.575'},
      '560073': {'lat': '13.289', 'name': 'Chikkabidarkal,Bengaluru,Karnataka', 'lon': '77.5146'},
      '388610': {'lat': '22.2378', 'name': 'Khadodhi,Anand,Gujarat', 'lon': '72.7591'},
      '560071': {'lat': '13.2257', 'name': 'Domlur,Bengaluru,Karnataka', 'lon': '77.575'},
      '560076': {'lat': '12.9833', 'name': 'Bannerghatta Road,Bengaluru,Karnataka', 'lon': '77.5833'},
      '560077': {'lat': '13.2257', 'name': 'Kothanur,Bengaluru,Karnataka', 'lon': '77.575'},
      '560074': {'lat': '12.9055', 'name': 'Kumbalagodu,Bengaluru,Karnataka', 'lon': '77.4187'},
      '560075': {'lat': '12.836', 'name': 'New Thippasandra,Bengaluru,Karnataka', 'lon': '77.4149'},
      '523262': {'lat': '15.5716', 'name': 'Manduvavaripalem,Prakasam,Andhra Pradesh', 'lon': '80.0646'},
      '523263': {'lat': '15.5459', 'name': 'Ilapavuluru,Prakasam,Andhra Pradesh', 'lon': '79.7879'},
      '523260': {'lat': '15.9263', 'name': 'Chinakothapalli,Prakasam,Andhra Pradesh', 'lon': '80.0481'},
      '523261': {'lat': '15.8682', 'name': 'Bhagavanrajupalem,Prakasam,Andhra Pradesh', 'lon': '80.0603'},
      '394160': {'lat': '21.297', 'name': 'Fulwadi,Surat,Gujarat', 'lon': '73.3162'},
      '523264': {'lat': '15.7548', 'name': 'Turakapalem,Prakasam,Andhra Pradesh', 'lon': '79.8345'},
      '523265': {'lat': '15.5159', 'name': 'Boppidivaripalem,Prakasam,Andhra Pradesh', 'lon': '79.7526'},
      '721125': {'lat': '23.5234', 'name': 'Banisole,West Midnapore,West Bengal', 'lon': '87.6598'},
      '500036': {'lat': '17.3939', 'name': 'Osmannagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.4529'},
      '721124': {'lat': '25.2838', 'name': 'Brahmansasan,West Midnapore,West Bengal', 'lon': '87.9695'},
      '689627': {'lat': '9.265', 'name': 'Melpadom,Alappuzha,Kerala', 'lon': '76.7873'},
      '689626': {'lat': '9.265', 'name': 'Parumala,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689625': {'lat': '9.265', 'name': 'Muttathukonam,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689624': {'lat': '9.265', 'name': 'Peringilipuram,Alappuzha,Kerala', 'lon': '76.7873'},
      '689623': {'lat': '9.265', 'name': 'Kuttemperur,Alappuzha,Kerala', 'lon': '76.7873'},
      '689622': {'lat': '9.265', 'name': 'Mannar,Alappuzha,Kerala', 'lon': '76.7873'},
      '689621': {'lat': '9.265', 'name': 'Niranam Central,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '824210': {'lat': '24.9913', 'name': 'Bara,Gaya,Bihar', 'lon': '84.8529'},
      '585222': {'lat': '16.987', 'name': 'Ranjol,Kalaburagi,Karnataka', 'lon': '77.1915'},
      '585223': {'lat': '16.7154', 'name': 'Shahapur Town,Yadgir,Karnataka', 'lon': '76.7979'},
      '585220': {'lat': '17.0062', 'name': 'Rangampeth,Yadgir,Karnataka', 'lon': '76.9926'},
      '585221': {'lat': '16.9021', 'name': 'Duppalli,Yadgir,Karnataka', 'lon': '77.1195'},
      '585226': {'lat': '17.5236', 'name': 'Yedlapur,Bidar,Karnataka', 'lon': '76.7674'},
      '585227': {'lat': '17.6599', 'name': 'Karaknalli,Bidar,Karnataka', 'lon': '77.3555'},
      '585224': {'lat': '16.6264', 'name': 'Kavadimatti,Yadgir,Karnataka', 'lon': '76.9136'},
      '585225': {'lat': '17.0271', 'name': 'Rawoor,Kalaburagi,Karnataka', 'lon': '76.9761'},
      '224231': {'lat': '26.4294', 'name': 'Hiri Pakaria,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4399'},
      '224230': {'lat': '26.2868', 'name': 'Baragaon Brahimpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5849'},
      '141115': {'lat': '30.9291', 'name': 'Lubangarh,Ludhiana,Punjab', 'lon': '76.198'},
      '224232': {'lat': '26.3661', 'name': 'Hakeem Pur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4676'},
      '803202': {'lat': '25.4564', 'name': 'Baikathpur,Patna,Bihar', 'lon': '85.2222'},
      '224234': {'lat': '26.5536', 'name': 'Rohiyawan,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4545'},
      '271601': {'lat': '27.1718', 'name': 'Bishunpur Bairiya,Gonda,Uttar Pradesh', 'lon': '82.0632'},
      '224238': {'lat': '26.3661', 'name': 'Vidhut Nagar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4676'},
      '500033': {'lat': '17.4334', 'name': 'Dr.B R Ambedkar O.U ,Hyderabad,Telangana', 'lon': '78.4111'},
      '751013': {'lat': '20.7521', 'name': 'Regional Research Laboratory,Khorda,Odisha', 'lon': '84.5579'},
      '271609': {'lat': '27.2832', 'name': 'Pipra Ram Chandar,Balrampur,Uttar Pradesh', 'lon': '82.3236'},
      '491444': {'lat': '21.4216', 'name': 'Bijetala,Rajnandgaon,Chattisgarh', 'lon': '81.3258'},
      '507128': {'lat': '17.7151', 'name': 'Mothipattinagar,Khammam,Telangana', 'lon': '80.8442'},
      '226501': {'lat': '26.7625', 'name': 'Sathwara,Lucknow,Uttar Pradesh', 'lon': '81.0697'},
      '305401': {'lat': '26.3029', 'name': 'Baghsuri,Ajmer,Rajasthan', 'lon': '74.6845'},
      '305402': {'lat': '26.3182', 'name': 'Tilana,Ajmer,Rajasthan', 'lon': '74.944'},
      '415640': {'lat': '17.8883', 'name': 'Udhale Budruk,Ratnagiri,Maharashtra', 'lon': '73.4305'},
      '835208': {'lat': '23.3314', 'name': 'Sewsoreng,Gumla,Jharkhand', 'lon': '84.6707'},
      '795104': {'lat': '25.4788', 'name': 'Phaibung Khullen,Senapati,Manipur', 'lon': '94.135'},
      '495688': {'lat': '21.8509', 'name': 'Kawaljhar,Janjgir-champa,Chattisgarh', 'lon': '82.9421'},
      '305403': {'lat': '25.7746', 'name': 'Khiriya,Ajmer,Rajasthan', 'lon': '74.6214'},
      '223223': {'lat': '25.555', 'name': 'Gajendra Patti Bhidaura,Azamgarh,Uttar Pradesh', 'lon': '83.1705'},
      '305404': {'lat': '25.8748', 'name': 'Pharkia,Ajmer,Rajasthan', 'lon': '75.1446'},
      '751010': {'lat': '20.7521', 'name': 'Rasulgarh,Khorda,Odisha', 'lon': '84.5579'},
      '835203': {'lat': '23.0922', 'name': 'Asro,Gumla,Jharkhand', 'lon': '84.7194'},
      '223227': {'lat': '25.9909', 'name': 'Firuddin Pur,Azamgarh,Uttar Pradesh', 'lon': '82.8835'},
      '795106': {'lat': '24.8782', 'name': 'Oklon,Senapati,Manipur', 'lon': '93.9796'},
      '223225': {'lat': '26.1567', 'name': 'Rama Pur,Azamgarh,Uttar Pradesh', 'lon': '82.8052'},
      '305405': {'lat': '25.8061', 'name': 'Aloli,Ajmer,Rajasthan', 'lon': '75.139'},
      '713154': {'lat': '23.6009', 'name': 'Amadpur,Purba Bardhaman,West Bengal', 'lon': '87.8438'},
      '795107': {'lat': '25.0338', 'name': 'J Phaijang,Senapati,Manipur', 'lon': '93.8933'},
      '713151': {'lat': '24.0318', 'name': 'Maheshdanga,Purba Bardhaman,West Bengal', 'lon': '87.675'},
      '713150': {'lat': '23.5206', 'name': 'Churpuni,Bankura,West Bengal', 'lon': '88.0851'},
      '713153': {'lat': '23.6009', 'name': 'Bhatkunda,Purba Bardhaman,West Bengal', 'lon': '87.8438'},
      '713152': {'lat': '23.5474', 'name': 'Bhuera,Purba Bardhaman,West Bengal', 'lon': '87.6552'},
      '804404': {'lat': '24.9751', 'name': 'Chakand R.S,Gaya,Bihar', 'lon': '85.0654'},
      '305407': {'lat': '25.782', 'name': 'Ghatiyali,Ajmer,Rajasthan', 'lon': '75.231'},
      '795101': {'lat': '24.8782', 'name': 'Julbalching,Kakching,Manipur', 'lon': '93.9796'},
      '464661': {'lat': '23.4649', 'name': 'Uncher,Raisen,Madhya Pradesh', 'lon': '77.7254'},
      '516421': {'lat': '14.4374', 'name': 'Krishnamgaripalle,Cuddapah,Andhra Pradesh', 'lon': '78.298'},
      '192202': {'lat': '33.7041', 'name': 'Muti Handoo,Ananthnag,Jammu & Kashmir', 'lon': '75.1966'},
      '502336': {'lat': '17.1893', 'name': 'Tuniki,Medak,Telangana', 'lon': '78.7009'},
      '641038': {'lat': '11.0329', 'name': 'Kuppakonanpudur,Coimbatore,Tamil Nadu', 'lon': '77.2503'},
      '796017': {'lat': '23.7846', 'name': 'Sawleng,Aizawl,Mizoram', 'lon': '92.8256'},
      '796015': {'lat': '23.8005', 'name': 'Zanlawn,Aizawl,Mizoram', 'lon': '92.7342'},
      '641039': {'lat': '11.0329', 'name': 'Telungupalayam,Coimbatore,Tamil Nadu', 'lon': '77.2503'},
      '796012': {'lat': '23.749', 'name': 'Ramhlun,Aizawl,Mizoram', 'lon': '92.7255'},
      '502271': {'lat': '18.0773', 'name': 'Cheelapalli,Medak,Telangana', 'lon': '77.9289'},
      '843112': {'lat': '25.7052', 'name': 'Saraiya Bazar,Muzaffarpur,Bihar', 'lon': '85.5245'},
      '731238': {'lat': '24.3346', 'name': 'Rudranagar,Birbhum,West Bengal', 'lon': '87.8422'},
      '151508': {'lat': '30.3673', 'name': 'Ubha,Mansa,Punjab', 'lon': '75.1394'},
      '151509': {'lat': '30.5368', 'name': 'Maur Mandi,Mansa,Punjab', 'lon': '75.3253'},
      '770001': {'lat': '21.748', 'name': 'Sundargarh Court,Sundergarh,Odisha', 'lon': '84.886'},
      '770002': {'lat': '21.748', 'name': 'Meghdega,Sundergarh,Odisha', 'lon': '84.886'},
      '151502': {'lat': '30.0094', 'name': 'Ahmedpur,Mansa,Punjab', 'lon': '75.6795'},
      '425442': {'lat': '21.6678', 'name': 'Lakhapur,Nandurbar,Maharashtra', 'lon': '74.322'},
      '151501': {'lat': '30.4863', 'name': 'Bareta,Mansa,Punjab', 'lon': '75.2582'},
      '151506': {'lat': '29.8171', 'name': 'Ghuddu Wala,Mansa,Punjab', 'lon': '75.3142'},
      '151507': {'lat': '29.667', 'name': 'Jhanda Kalan,Mansa,Punjab', 'lon': '75.274'},
      '151504': {'lat': '30.0965', 'name': 'Khiwa Khurd,Mansa,Punjab', 'lon': '75.5747'},
      '425444': {'lat': '21.514', 'name': 'Chirkhan,Nandurbar,Maharashtra', 'lon': '74.9948'},
      '424318': {'lat': '21.0479', 'name': 'Navara-navari,Dhule,Maharashtra', 'lon': '74.8129'},
      '416315': {'lat': '18.3553', 'name': 'Malwadi,Sangli,Maharashtra', 'lon': '76.0248'},
      '306101': {'lat': '26.3784', 'name': 'Sewariya,Pali,Rajasthan', 'lon': '74.1923'},
      '606708': {'lat': '12.2781', 'name': 'Mottakkal,Tiruvannamalai,Tamil Nadu', 'lon': '78.9699'},
      '424310': {'lat': '20.9025', 'name': 'Chhail,Dhule,Maharashtra', 'lon': '74.5708'},
      '424311': {'lat': '20.7282', 'name': 'Narvhal,Dhule,Maharashtra', 'lon': '74.6607'},
      '639110': {'lat': '10.8318', 'name': 'Rengachipatti,Karur,Tamil Nadu', 'lon': '78.4789'},
      '505152': {'lat': '16.6356', 'name': 'Gumpula,Karim Nagar,Telangana', 'lon': '79.3689'},
      '505153': {'lat': '18.4372', 'name': 'Tharupalli,Karim Nagar,Telangana', 'lon': '79.3969'},
      '765002': {'lat': '19.1661', 'name': 'Rayagada S.F.,Rayagada,Odisha', 'lon': '83.443'},
      '843327': {'lat': '26.0438', 'name': 'B.Jairam,Sitamarhi,Bihar', 'lon': '85.3115'},
      '843324': {'lat': '26.1708', 'name': 'Vishwa,Sitamarhi,Bihar', 'lon': '86.2081'},
      '416316': {'lat': '19.076', 'name': 'Ankalkhop,Sangli,Maharashtra', 'lon': '75.1058'},
      '843322': {'lat': '25.8093', 'name': 'Jogiwana,Sitamarhi,Bihar', 'lon': '86.4761'},
      '735207': {'lat': '26.7644', 'name': 'Chamurchi,Jalpaiguri,West Bengal', 'lon': '88.8616'},
      '843320': {'lat': '26.3624', 'name': 'Chinachak,Sitamarhi,Bihar', 'lon': '85.994'},
      '843321': {'lat': '25.885', 'name': 'Dhanaur,Muzaffarpur,Bihar', 'lon': '85.7511'},
      '584140': {'lat': '15.9603', 'name': 'Upparhal,Raichur,Karnataka', 'lon': '76.9364'},
      '416311': {'lat': '19.3071', 'name': 'Dahiwadi,Sangli,Maharashtra', 'lon': '75.3975'},
      '229128': {'lat': '26.0296', 'name': 'Baghaula,Raebareli,Uttar Pradesh', 'lon': '81.2033'},
      '735206': {'lat': '26.8841', 'name': 'Mathachulka,Jalpaiguri,West Bengal', 'lon': '88.7907'},
      '829131': {'lat': '23.6861', 'name': 'Src,Ramgarh,Jharkhand', 'lon': '85.5704'},
      '843328': {'lat': '25.8236', 'name': 'Runnisaidpur,Sitamarhi,Bihar', 'lon': '86.0939'},
      '639117': {'lat': '11.0645', 'name': 'Noyyal,Karur,Tamil Nadu', 'lon': '77.9732'},
      '515212': {'lat': '13.8189', 'name': 'Birepalli,Ananthapur,Andhra Pradesh', 'lon': '77.8819'},
      '609803': {'lat': '11.0092', 'name': 'Tiruvavaduthurai,Nagapattinam,Tamil Nadu', 'lon': '79.499'},
      '515211': {'lat': '13.767', 'name': 'Santhebidanur,Ananthapur,Andhra Pradesh', 'lon': '77.5005'},
      '312619': {'lat': '24.4233', 'name': 'Banslai,Chittorgarh,Rajasthan', 'lon': '74.1654'},
      '639114': {'lat': '10.9465', 'name': 'Vedichipalayam,Karur,Tamil Nadu', 'lon': '78.1491'},
      '312615': {'lat': '24.9405', 'name': 'Veerawali,Chittorgarh,Rajasthan', 'lon': '75.7459'},
      '312614': {'lat': '24.7529', 'name': 'Jaloda Jagir,Chittorgarh,Rajasthan', 'lon': '75.2065'},
      '312617': {'lat': '24.5627', 'name': 'Kailash  Nagar,Chittorgarh,Rajasthan', 'lon': '74.6449'},
      '312616': {'lat': '24.5489', 'name': 'Kheranarsingh Mata,Chittorgarh,Rajasthan', 'lon': '74.3996'},
      '312613': {'lat': '24.9246', 'name': 'Chiksi,Chittorgarh,Rajasthan', 'lon': '74.7337'},
      '312612': {'lat': '23.3914', 'name': 'Samari,Chittorgarh,Rajasthan', 'lon': '74.1934'},
      '680703': {'lat': '9.7225', 'name': 'Edathuruthy,Thrissur,Kerala', 'lon': '76.8488'},
      '680702': {'lat': '9.7225', 'name': 'Karanchira,Thrissur,Kerala', 'lon': '76.8488'},
      '680701': {'lat': '9.7225', 'name': 'Thanissery,Thrissur,Kerala', 'lon': '76.8488'},
      '141116': {'lat': '30.8', 'name': 'Gill,Ludhiana,Punjab', 'lon': '75.8529'},
      '743428': {'lat': '22.6247', 'name': 'Zafarpur,North 24 Parganas,West Bengal', 'lon': '88.2876'},
      '829134': {'lat': '23.7271', 'name': 'Chainpur,Ramgarh,Jharkhand', 'lon': '85.6046'},
      '638053': {'lat': '11.2882', 'name': 'Perundurai Sanitorium,Erode,Tamil Nadu', 'lon': '77.6842'},
      '802316': {'lat': '25.6912', 'name': 'Sinhaghat,Bhojpur,Bihar', 'lon': '84.647'},
      '711101': {'lat': '22.3294', 'name': 'Pachalbazar,Howrah,West Bengal', 'lon': '88.3994'},
      '247343': {'lat': '29.6682', 'name': 'Titron,Saharanpur,Uttar Pradesh', 'lon': '77.3239'},
      '431604': {'lat': '19.1617', 'name': 'Karimabad Nanded,Nanded,Maharashtra', 'lon': '77.2144'},
      '431605': {'lat': '19.1721', 'name': 'Maralak,Nanded,Maharashtra', 'lon': '77.2611'},
      '431606': {'lat': '19.0802', 'name': 'SRTMU Nanded,Nanded,Maharashtra', 'lon': '77.2722'},
      '431601': {'lat': '19.1617', 'name': 'Sachkhand Nanded,Nanded,Maharashtra', 'lon': '77.2144'},
      '431602': {'lat': '19.1911', 'name': 'Yeshwantnagar  Nanded,Nanded,Maharashtra', 'lon': '77.1549'},
      '431603': {'lat': '19.1153', 'name': 'Cidco Nanded,Nanded,Maharashtra', 'lon': '77.3479'},
      '638476': {'lat': '11.4225', 'name': 'Kadukkampalayam,Erode,Tamil Nadu', 'lon': '77.3455'},
      '306104': {'lat': '25.8956', 'name': 'Ramasani - Bala,Pali,Rajasthan', 'lon': '73.6798'},
      '764041': {'lat': '18.7065', 'name': 'Attanda,Malkangiri,Odisha', 'lon': '82.3284'},
      '573164': {'lat': '12.8055', 'name': 'Bagivalu,Hassan,Karnataka', 'lon': '76.2041'},
      '522619': {'lat': '16.1005', 'name': 'Turlapadu,Guntur,Andhra Pradesh', 'lon': '80.2606'},
      '396590': {'lat': '23.0015', 'name': 'Bartad,Navsari,Gujarat', 'lon': '74.0092'},
      '743427': {'lat': '23.5239', 'name': 'Model Belghoria,North 24 Parganas,West Bengal', 'lon': '87.8703'},
      '533450': {'lat': '17.1074', 'name': 'F K Palem,East Godavari,Andhra Pradesh', 'lon': '82.2141'},
      '711102': {'lat': '22.5667', 'name': 'Dinabandhu Instt.,Howrah,West Bengal', 'lon': '88.3167'},
      '573162': {'lat': '13.1463', 'name': 'Kittankere,Hassan,Karnataka', 'lon': '76.2128'},
      '847451': {'lat': '26.3369', 'name': 'Kunauli Bazar,Supaul,Bihar', 'lon': '86.7743'},
      '522611': {'lat': '16.1297', 'name': 'Kammavaripalem,Guntur,Andhra Pradesh', 'lon': '80.0962'},
      '522612': {'lat': '16.4217', 'name': 'Obulesunipalle,Guntur,Andhra Pradesh', 'lon': '79.5578'},
      '522613': {'lat': '16.301', 'name': 'Gundalapadu,Guntur,Andhra Pradesh', 'lon': '80.17'},
      '522614': {'lat': '16.4058', 'name': 'Narasimhapuri,Guntur,Andhra Pradesh', 'lon': '79.7069'},
      '522615': {'lat': '16.0608', 'name': 'Cheemalamarri,Guntur,Andhra Pradesh', 'lon': '79.9381'},
      '522616': {'lat': '16.1255', 'name': 'Chilakaluripet Bazar,Guntur,Andhra Pradesh', 'lon': '80.1091'},
      '522617': {'lat': '16.2988', 'name': 'Annavarappadu,Guntur,Andhra Pradesh', 'lon': '80.0915'},
      '470663': {'lat': '23.7442', 'name': 'Road,Damoh,Madhya Pradesh', 'lon': '79.5706'},
      '581148': {'lat': '14.7499', 'name': 'Wardi,Haveri,Karnataka', 'lon': '75.283'},
      '581145': {'lat': '14.5669', 'name': 'Chalageri,Haveri,Karnataka', 'lon': '75.7188'},
      '306105': {'lat': '26.0487', 'name': 'Birantiya Kalan,Pali,Rajasthan', 'lon': '74.1007'},
      '581411': {'lat': '14.7128', 'name': 'Nandikatta,Uttara Kannada,Karnataka', 'lon': '74.6736'},
      '277302': {'lat': '25.9164', 'name': 'Khejuri,Ballia,Uttar Pradesh', 'lon': '84.1099'},
      '581412': {'lat': '14.7128', 'name': 'Kirwatti,Uttara Kannada,Karnataka', 'lon': '74.6736'},
      '670663': {'lat': '11.3078', 'name': 'Kadambur-kannur,Kannur,Kerala', 'lon': '75.7679'},
      '670662': {'lat': '11.7979', 'name': 'Muzhappilangad,Kannur,Kerala', 'lon': '75.4511'},
      '670661': {'lat': '11.4703', 'name': 'Melur-tly,Kannur,Kerala', 'lon': '75.8013'},
      '711103': {'lat': '22.5436', 'name': 'Andul Road,Howrah,West Bengal', 'lon': '88.4146'},
      '847108': {'lat': '26.2778', 'name': 'LauKahi Dhanchhiha,Madhubani,Bihar', 'lon': '86.3025'},
      '711107': {'lat': '22.6167', 'name': 'Howrah Vic.Cot.Mill,Howrah,West Bengal', 'lon': '88.35'},
      '246431': {'lat': '29.955', 'name': 'Mehalchauri,Chamoli,Uttarakhand', 'lon': '79.3709'},
      '847109': {'lat': '26.5997', 'name': 'Araria Sangra,Madhubani,Bihar', 'lon': '85.8119'},
      '630408': {'lat': '9.8329', 'name': 'Kavathugudi,Sivaganga,Tamil Nadu', 'lon': '78.8205'},
      '246435': {'lat': '30.2512', 'name': 'Ustoli,Chamoli,Uttarakhand', 'lon': '79.5328'},
      '679581': {'lat': '10.7405', 'name': 'Marancheri,Malappuram,Kerala', 'lon': '75.971'},
      '679580': {'lat': '10.6938', 'name': 'Perumbadappu,Malappuram,Kerala', 'lon': '75.9616'},
      '679583': {'lat': '10.7587', 'name': 'Ponani Nagaram,Malappuram,Kerala', 'lon': '75.974'},
      '679582': {'lat': '10.8194', 'name': 'Kaladi-MLP,Malappuram,Kerala', 'lon': '76.0064'},
      '679585': {'lat': '10.7587', 'name': 'Alankode,Malappuram,Kerala', 'lon': '75.974'},
      '679584': {'lat': '10.7587', 'name': 'Puranga,Malappuram,Kerala', 'lon': '75.974'},
      '679587': {'lat': '10.7151', 'name': 'Eramangalam,Malappuram,Kerala', 'lon': '75.978'},
      '679586': {'lat': '10.7569', 'name': 'Ponani South,Malappuram,Kerala', 'lon': '75.9362'},
      '413531': {'lat': '18.9384', 'name': 'Wasangaon,Osmanabad,Maharashtra', 'lon': '76.3763'},
      '805141': {'lat': '24.8905', 'name': 'Roh,Nawada,Bihar', 'lon': '85.675'},
      '802311': {'lat': '25.7958', 'name': 'Neknam Tola,Bhojpur,Bihar', 'lon': '84.5805'},
      '847105': {'lat': '25.9142', 'name': 'S.Majarahia,Samastipur,Bihar', 'lon': '85.7646'},
      '630702': {'lat': '9.7239', 'name': 'Melayur,Sivaganga,Tamil Nadu', 'lon': '78.6566'},
      '248140': {'lat': '30.23', 'name': 'Dodhli,Dehradun,Uttarakhand', 'lon': '78.0734'},
      '248143': {'lat': '30.2431', 'name': 'Ithrana,Dehradun,Uttarakhand', 'lon': '78.2275'},
      '230142': {'lat': '26.0495', 'name': 'Jogipur,Pratapgarh,Uttar Pradesh', 'lon': '81.8678'},
      '845449': {'lat': '26.8169', 'name': 'Puraina Gosai,West Champaran,Bihar', 'lon': '84.536'},
      '248146': {'lat': '30.3107', 'name': 'Seemadwar,Dehradun,Uttarakhand', 'lon': '78.0182'},
      '632104': {'lat': '12.6101', 'name': 'Maruthavallipalayam,Vellore,Tamil Nadu', 'lon': '78.8584'},
      '484887': {'lat': '23.0223', 'name': 'Johila Project,Anuppur,Madhya Pradesh', 'lon': '81.413'},
      '494771': {'lat': '20.1577', 'name': 'Badejharkatta,Kanker,Chattisgarh', 'lon': '80.7109'},
      '764042': {'lat': '18.7065', 'name': 'Ondrahal,Koraput,Odisha', 'lon': '82.3284'},
      '802312': {'lat': '25.3691', 'name': 'Belghat Balua,Bhojpur,Bihar', 'lon': '84.8549'},
      '711105': {'lat': '22.5436', 'name': 'Dasnagar,Howrah,West Bengal', 'lon': '88.4146'},
      '625523': {'lat': '9.8112', 'name': 'Lakshmipuram (Theni),Theni,Tamil Nadu', 'lon': '77.4123'},
      '517214': {'lat': '13.6728', 'name': 'Mudupulavemula,Chittoor,Andhra Pradesh', 'lon': '78.7847'},
      '621012': {'lat': '11.2938', 'name': 'T.Baderpettai,Tiruchirappalli,Tamil Nadu', 'lon': '78.4964'},
      '344044': {'lat': '25.7483', 'name': 'Meli Bandha,Barmer,Rajasthan', 'lon': '72.1784'},
      '481551': {'lat': '22.1303', 'name': 'Nagarwada R.S.,Balaghat,Madhya Pradesh', 'lon': '80.1238'},
      '517213': {'lat': '14.3753', 'name': 'Vagalla,Chittoor,Andhra Pradesh', 'lon': '78.2742'},
      '641007': {'lat': '10.7622', 'name': 'Seeranaickenpalayam,Coimbatore,Tamil Nadu', 'lon': '78.145'},
      '641006': {'lat': '10.8911', 'name': 'Ganapathy,Coimbatore,Tamil Nadu', 'lon': '77.0331'},
      '641005': {'lat': '10.999', 'name': 'Singanallur,Coimbatore,Tamil Nadu', 'lon': '77.0324'},
      '641004': {'lat': '11.0333', 'name': 'Gandhimaanagar,Coimbatore,Tamil Nadu', 'lon': '77'},
      '641003': {'lat': '10.9214', 'name': 'Lawley Road,Coimbatore,Tamil Nadu', 'lon': '77.3026'},
      '641002': {'lat': '10.9214', 'name': 'R.S.Puram South,Coimbatore,Tamil Nadu', 'lon': '77.3026'},
      '641001': {'lat': '10.9214', 'name': 'Coimbatore Bazaar,Coimbatore,Tamil Nadu', 'lon': '77.3026'},
      '741232': {'lat': '23.0732', 'name': "Cooper's Camp,Nadia,West Bengal", 'lon': '88.2769'},
      '248005': {'lat': '30.2924', 'name': 'Tunwala,Dehradun,Uttarakhand', 'lon': '78.0966'},
      '248006': {'lat': '30.2796', 'name': 'Newforest,Dehradun,Uttarakhand', 'lon': '78.1467'},
      '621011': {'lat': '11.5191', 'name': 'Top Sengattupatti,Tiruchirappalli,Tamil Nadu', 'lon': '78.4241'},
      '802313': {'lat': '25.4914', 'name': 'Daulatpur,Buxar,Bihar', 'lon': '84.1018'},
      '711106': {'lat': '22.5436', 'name': 'Howrah Loco Depot,Howrah,West Bengal', 'lon': '88.4146'},
      '641009': {'lat': '10.9214', 'name': 'Ramnagar Coimbatore,Coimbatore,Tamil Nadu', 'lon': '77.3026'},
      '625527': {'lat': '9.8867', 'name': 'Chinnakattalai,Theni,Tamil Nadu', 'lon': '77.3673'},
      '574244': {'lat': '13.2359', 'name': 'Kuntady,Udupi,Karnataka', 'lon': '74.8763'},
      '843107': {'lat': '26.1511', 'name': 'Geyaspur,Muzaffarpur,Bihar', 'lon': '85.3308'},
      '412802': {'lat': '18.2491', 'name': 'Ahire,Satara,Maharashtra', 'lon': '75.1056'},
      '625524': {'lat': '9.8658', 'name': 'Pannaipuram,Theni,Tamil Nadu', 'lon': '77.2836'},
      '731241': {'lat': '24.213', 'name': 'Dunigram,Birbhum,West Bengal', 'lon': '87.9181'},
      '731240': {'lat': '24.1639', 'name': 'Gheedaha,Birbhum,West Bengal', 'lon': '87.8602'},
      '731243': {'lat': '24.2653', 'name': 'Harioka,Birbhum,West Bengal', 'lon': '87.7712'},
      '625525': {'lat': '9.8867', 'name': 'Suruliyar Lower Camp,Theni,Tamil Nadu', 'lon': '77.3673'},
      '731245': {'lat': '24.0489', 'name': 'Sanakpur,Birbhum,West Bengal', 'lon': '87.8456'},
      '636501': {'lat': '11.701', 'name': 'Pakkanadu,Salem,Tamil Nadu', 'lon': '77.8211'},
      '636502': {'lat': '11.7115', 'name': 'Periakadampatti,Salem,Tamil Nadu', 'lon': '77.9456'},
      '412804': {'lat': '18.3771', 'name': 'Bavadhan,Satara,Maharashtra', 'lon': '74.9778'},
      '831006': {'lat': '22.7767', 'name': 'Jugsalai,East Singhbhum,Jharkhand', 'lon': '86.1835'},
      '831007': {'lat': '23.0604', 'name': 'N M L,East Singhbhum,Jharkhand', 'lon': '85.9466'},
      '831004': {'lat': '23.0301', 'name': 'Telco Works,East Singhbhum,Jharkhand', 'lon': '85.9734'},
      '831005': {'lat': '22.5826', 'name': 'Kadma,East Singhbhum,Jharkhand', 'lon': '86.4527'},
      '831002': {'lat': '23.0809', 'name': 'Itagarh,Seraikela-kharsawan,Jharkhand', 'lon': '85.96'},
      '742189': {'lat': '24.3535', 'name': 'Kashipur (Murshidabad) ,Murshidabad,West Bengal', 'lon': '87.8983'},
      '532322': {'lat': '18.577', 'name': 'Kapaskudda,Srikakulam,Andhra Pradesh', 'lon': '83.7074'},
      '742184': {'lat': '23.8093', 'name': 'Kanfala,Murshidabad,West Bengal', 'lon': '88.0131'},
      '574240': {'lat': '12.986', 'name': 'Old Town Ujire,Dakshina Kannada,Karnataka', 'lon': '75.3431'},
      '742187': {'lat': '24.7341', 'name': 'Basudebkhali,Murshidabad,West Bengal', 'lon': '87.9239'},
      '831009': {'lat': '23.0604', 'name': 'Agrico,East Singhbhum,Jharkhand', 'lon': '85.9466'},
      '534240': {'lat': '16.5842', 'name': 'Nelapogula,West Godavari,Andhra Pradesh', 'lon': '81.4741'},
      '679324': {'lat': '11.0312', 'name': 'Mankada Pallippuram,Malappuram,Kerala', 'lon': '76.1614'},
      '679327': {'lat': '11.1312', 'name': 'Poolamanna,Malappuram,Kerala', 'lon': '76.284'},
      '679326': {'lat': '11.0692', 'name': 'Velliyanchery,Malappuram,Kerala', 'lon': '76.2565'},
      '534244': {'lat': '16.5842', 'name': 'Vissakoderu,West Godavari,Andhra Pradesh', 'lon': '81.4741'},
      '534245': {'lat': '16.5842', 'name': 'Tillapudi,West Godavari,Andhra Pradesh', 'lon': '81.4741'},
      '679323': {'lat': '10.916', 'name': 'Valapuram,Malappuram,Kerala', 'lon': '76.2088'},
      '534247': {'lat': '16.5842', 'name': 'Panjavemavaram,West Godavari,Andhra Pradesh', 'lon': '81.4741'},
      '574243': {'lat': '12.7663', 'name': 'Odiyoor,Dakshina Kannada,Karnataka', 'lon': '75.1036'},
      '679329': {'lat': '11.2727', 'name': 'Eranhimangad,Malappuram,Kerala', 'lon': '76.2257'},
      '679328': {'lat': '11.1934', 'name': 'Naduvath,Malappuram,Kerala', 'lon': '76.224'},
      '630554': {'lat': '9.675', 'name': 'Pahaianchan,Sivaganga,Tamil Nadu', 'lon': '78.4947'},
      '125075': {'lat': '30.0833', 'name': 'Thersahidau,Sirsa,Haryana', 'lon': '75.5574'},
      '125077': {'lat': '29.7831', 'name': 'Panjuana,Sirsa,Haryana', 'lon': '74.8784'},
      '125076': {'lat': '29.5245', 'name': 'Nanuana,Sirsa,Haryana', 'lon': '74.8369'},
      '731304': {'lat': '24.5164', 'name': 'Langalhata,Birbhum,West Bengal', 'lon': '87.8648'},
      '125078': {'lat': '29.6375', 'name': 'Baragudha,Sirsa,Haryana', 'lon': '75.3455'},
      '731302': {'lat': '23.6053', 'name': 'Daronda Ranipara,Birbhum,West Bengal', 'lon': '88.1013'},
      '731303': {'lat': '24.0819', 'name': 'Kuniara,Birbhum,West Bengal', 'lon': '87.8396'},
      '583152': {'lat': '14.9877', 'name': 'Anthapura,Ballari,Karnataka', 'lon': '76.7782'},
      '583153': {'lat': '14.8627', 'name': 'Shankar Hill Town Colony,Ballari,Karnataka', 'lon': '76.4751'},
      '610001': {'lat': '12.9146', 'name': 'Kodikalpalayam,Tiruvarur,Tamil Nadu', 'lon': '79.7636'},
      '583154': {'lat': '14.7794', 'name': 'Buduguppa,Ballari,Karnataka', 'lon': '76.273'},
      '470669': {'lat': '23.792', 'name': 'Tada,Sagar,Madhya Pradesh', 'lon': '79.6019'},
      '395023': {'lat': '21.6722', 'name': 'Bhestan,Surat,Gujarat', 'lon': '72.6841'},
      '841243': {'lat': '26.1969', 'name': 'Jagdishpur Kothi,Siwan,Bihar', 'lon': '84.3893'},
      '247340': {'lat': '29.7906', 'name': 'Raniyala Dayalpur,Saharanpur,Uttar Pradesh', 'lon': '77.4242'},
      '247341': {'lat': '29.7723', 'name': 'Rangail,Saharanpur,Uttar Pradesh', 'lon': '77.2338'},
      '229216': {'lat': '26.0643', 'name': 'Beni Madhiganj,Raebareli,Uttar Pradesh', 'lon': '80.8641'},
      '834011': {'lat': '23.299', 'name': 'Krishnapuri S O,Ranchi,Jharkhand', 'lon': '85.3809'},
      '475220': {'lat': '25.825', 'name': 'Kariyawati,Gwalior,Madhya Pradesh', 'lon': '78.0418'},
      '711109': {'lat': '22.6218', 'name': 'Podra,Howrah,West Bengal', 'lon': '88.7067'},
      '229210': {'lat': '26.3811', 'name': 'Drigpal Ganj,Raebareli,Uttar Pradesh', 'lon': '81.0756'},
      '229211': {'lat': '26.2288', 'name': 'Bakuliha,Raebareli,Uttar Pradesh', 'lon': '81.2314'},
      '473105': {'lat': '24.6944', 'name': 'Rampurcolony,Guna,Madhya Pradesh', 'lon': '77.121'},
      '229212': {'lat': '26.2288', 'name': 'Samodha,Raebareli,Uttar Pradesh', 'lon': '81.2314'},
      '822102': {'lat': '23.997', 'name': 'Jogiyahi Tola,Palamau,Jharkhand', 'lon': '84.1036'},
      '822101': {'lat': '24.0475', 'name': 'Daltonganj Bazar,Palamau,Jharkhand', 'lon': '84.0611'},
      '764049': {'lat': '18.05866', 'name': 'Bharanpur,Nabarangapur,Odisha', 'lon': '81.5475'},
      '344708': {'lat': '25.5606', 'name': 'Ramdeoria,Barmer,Rajasthan', 'lon': '71.6244'},
      '506165': {'lat': '18.1967', 'name': 'Akulavarighanpur,Warangal,Telangana', 'lon': '79.664'},
      '506166': {'lat': '18.065', 'name': 'Mamnoor,Warangal,Telangana', 'lon': '79.5612'},
      '506167': {'lat': '18.065', 'name': 'Jangaon H.O,Warangal,Telangana', 'lon': '79.5612'},
      '506163': {'lat': '17.6085', 'name': 'Matair,Warangal,Telangana', 'lon': '79.6675'},
      '335524': {'lat': '28.8656', 'name': 'Bisrasar,Hanumangarh,Rajasthan', 'lon': '74.265'},
      '335525': {'lat': '29.0409', 'name': 'Silwala Kalan,Hanumangarh,Rajasthan', 'lon': '74.5576'},
      '335526': {'lat': '29.6269', 'name': 'Rathikhara,Hanumangarh,Rajasthan', 'lon': '74.2061'},
      '344703': {'lat': '25.6884', 'name': 'Kharantia Khurd,Barmer,Rajasthan', 'lon': '71.6314'},
      '344704': {'lat': '25.2525', 'name': 'Boli,Barmer,Rajasthan', 'lon': '72.4458'},
      '344705': {'lat': '26.2356', 'name': 'Rajdal,Barmer,Rajasthan', 'lon': '71.2142'},
      '516163': {'lat': '13.6968', 'name': 'Kothapet,Cuddapah,Andhra Pradesh', 'lon': '79.0572'},
      '516162': {'lat': '14.5122', 'name': 'Sivalapalli,Cuddapah,Andhra Pradesh', 'lon': '78.9734'},
      '606111': {'lat': '11.4124', 'name': 'Pennadam R.S.,Cuddalore,Tamil Nadu', 'lon': '79.1892'},
      '487225': {'lat': '22.8645', 'name': 'Amgaon Bada,Narsinghpur,Madhya Pradesh', 'lon': '79.1292'},
      '487221': {'lat': '22.8861', 'name': 'Mohad,Narsinghpur,Madhya Pradesh', 'lon': '79.0714'},
      '160047': {'lat': '30.708', 'name': 'Sector 44 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7621'},
      '711414': {'lat': '22.4652', 'name': 'Siddheswar,Howrah,West Bengal', 'lon': '88.0574'},
      '248014': {'lat': '30.2852', 'name': 'Shamshergarh,Dehradun,Uttarakhand', 'lon': '78.083'},
      '160043': {'lat': '30.741482', 'name': 'District Court Sector-43,05,Chandigar', 'lon': '76.768066'},
      '764048': {'lat': '18.7065', 'name': 'Malkangiri Colony,Malkangiri,Odisha', 'lon': '82.3284'},
      '711410': {'lat': '23.1175', 'name': 'Sibnarayanchak,Howrah,West Bengal', 'lon': '88.2278'},
      '711411': {'lat': '22.6191', 'name': 'Kolora,Howrah,West Bengal', 'lon': '88.1892'},
      '600012': {'lat': '13.3357', 'name': 'Perambur Barracks,Chennai,Tamil Nadu', 'lon': '79.9451'},
      '563103': {'lat': '13.1206', 'name': 'Shillengere,Kolar,Karnataka', 'lon': '78.193'},
      '637202': {'lat': '11.4056', 'name': 'Elachipalayam,Namakkal,Tamil Nadu', 'lon': '78.018'},
      '414601': {'lat': '19.1877', 'name': 'Pimpalgaon Ujjani,Ahmed Nagar,Maharashtra', 'lon': '74.7884'},
      '415607': {'lat': '17.5557', 'name': 'Rajivili,Ratnagiri,Maharashtra', 'lon': '74.1248'},
      '414603': {'lat': '19.6675', 'name': 'Khedle Kajali,Ahmed Nagar,Maharashtra', 'lon': '75.7361'},
      '414602': {'lat': '19.1575', 'name': 'Mali Chinchore,Ahmed Nagar,Maharashtra', 'lon': '75.9985'},
      '414605': {'lat': '19.6562', 'name': 'Bhende SK,Ahmed Nagar,Maharashtra', 'lon': '75.5862'},
      '414604': {'lat': '19.9996', 'name': 'Georai,Ahmed Nagar,Maharashtra', 'lon': '75.2919'},
      '414607': {'lat': '19.1464', 'name': 'Kangoni,Ahmed Nagar,Maharashtra', 'lon': '76.8033'},
      '414606': {'lat': '19.2034', 'name': 'Chanda,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '414609': {'lat': '19.4851', 'name': 'Usthal Dumala,Ahmed Nagar,Maharashtra', 'lon': '74.9684'},
      '713301': {'lat': '23.8832', 'name': 'Asansol H.O,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '713302': {'lat': '23.8832', 'name': 'Jhingri Mohallah,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '713303': {'lat': '23.8832', 'name': 'Rambandhutalaw,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '713304': {'lat': '23.8832', 'name': 'Hill View,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '713305': {'lat': '23.8832', 'name': 'Ramkrishna Mission,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '415608': {'lat': '15.919', 'name': 'Narduve,Ratnagiri,Maharashtra', 'lon': '74.1636'},
      '415609': {'lat': '18.8806', 'name': 'Masrang,Ratnagiri,Maharashtra', 'lon': '74.6948'},
      '516309': {'lat': '14.2981', 'name': 'Thummalapalle,Cuddapah,Andhra Pradesh', 'lon': '78.9455'},
      '628502': {'lat': '10.8028', 'name': 'G.V.N.Kalluri,Tirunelveli,Tamil Nadu', 'lon': '77.9152'},
      '628501': {'lat': '10.5136', 'name': 'Kovilpatti H.O,Tuticorin,Tamil Nadu', 'lon': '77.9534'},
      '577534': {'lat': '14.3244', 'name': 'Echaghatta,Davangere,Karnataka', 'lon': '76.0713'},
      '637206': {'lat': '11.1994', 'name': 'Koodachery,Namakkal,Tamil Nadu', 'lon': '77.9986'},
      '577533': {'lat': '13.7669', 'name': 'Karpurakatte,Chitradurga,Karnataka', 'lon': '76.3077'},
      '637207': {'lat': '11.1746', 'name': 'Keerambur,Namakkal,Tamil Nadu', 'lon': '78.0742'},
      '431142': {'lat': '19.8365', 'name': 'Kittiadgaon,Beed,Maharashtra', 'lon': '76.8138'},
      '416552': {'lat': '15.9008', 'name': 'DSF Halkarni,Kolhapur,Maharashtra', 'lon': '74.242'},
      '533218': {'lat': '16.8225', 'name': 'Rollapalem,East Godavari,Andhra Pradesh', 'lon': '81.3341'},
      '788127': {'lat': '24.6915', 'name': 'Hayairbond,Cachar,Assam', 'lon': '92.8687'},
      '788120': {'lat': '24.6853', 'name': 'Bidyaratanpur,Cachar,Assam', 'lon': '92.761'},
      '788121': {'lat': '24.6642', 'name': 'Kabuganj Bazar,Cachar,Assam', 'lon': '92.8659'},
      '788123': {'lat': '24.6915', 'name': 'Punimukh,Cachar,Assam', 'lon': '92.8687'},
      '533212': {'lat': '16.5826', 'name': 'Dontikurru,East Godavari,Andhra Pradesh', 'lon': '82.1528'},
      '533213': {'lat': '17.0524', 'name': 'Samanasa,East Godavari,Andhra Pradesh', 'lon': '82.0372'},
      '533210': {'lat': '16.4862', 'name': 'Mogallamori,East Godavari,Andhra Pradesh', 'lon': '81.4719'},
      '533211': {'lat': '16.4972', 'name': 'Velavalapalli,East Godavari,Andhra Pradesh', 'lon': '81.5337'},
      '533216': {'lat': '15.7738', 'name': 'Krapa Chintalapudi,East Godavari,Andhra Pradesh', 'lon': '80.6666'},
      '533217': {'lat': '16.4862', 'name': 'Devaguptam,East Godavari,Andhra Pradesh', 'lon': '81.4719'},
      '533214': {'lat': '16.111', 'name': 'Munjavarapukottu,East Godavari,Andhra Pradesh', 'lon': '81.0343'},
      '533215': {'lat': '16.4862', 'name': 'Bodasakurru,East Godavari,Andhra Pradesh', 'lon': '81.4719'},
      '585411': {'lat': '17.5938', 'name': 'Bhatasangvi,Bidar,Karnataka', 'lon': '76.9247'},
      '144620': {'lat': '31.319', 'name': 'Mahabalipur,Kapurthala,Punjab', 'lon': '75.3491'},
      '585413': {'lat': '17.9963', 'name': 'Janthi,Bidar,Karnataka', 'lon': '77.3251'},
      '585412': {'lat': '17.5987', 'name': 'Chitguppa,Bidar,Karnataka', 'lon': '77.1946'},
      '585415': {'lat': '17.5938', 'name': 'Dakulgi,Bidar,Karnataka', 'lon': '76.9247'},
      '585414': {'lat': '17.5938', 'name': 'Hallikhed (B),Bidar,Karnataka', 'lon': '76.9247'},
      '585417': {'lat': '17.3703', 'name': 'Murki,Bidar,Karnataka', 'lon': '76.8318'},
      '365610': {'lat': '21.8159', 'name': 'Pithvajal,Amreli,Gujarat', 'lon': '71.1037'},
      '394370': {'lat': '21.2659', 'name': 'Gujarpur,Surat,Gujarat', 'lon': '73.072'},
      '585418': {'lat': '17.5938', 'name': 'Kumar Chincholli,Bidar,Karnataka', 'lon': '76.9247'},
      '756114': {'lat': '20.7923', 'name': 'Ganijang,Bhadrak,Odisha', 'lon': '84.7904'},
      '756115': {'lat': '20.8419', 'name': 'Sandado,Bhadrak,Odisha', 'lon': '85.9611'},
      '756112': {'lat': '21.5203', 'name': 'Bahudarada,Bhadrak,Odisha', 'lon': '85.9726'},
      '394375': {'lat': '21.2167', 'name': 'Uchchhal,Tapi,Gujarat', 'lon': '73.7167'},
      '637017': {'lat': '10.8824', 'name': 'K.Pudupalayam,Namakkal,Tamil Nadu', 'lon': '78.2408'},
      '756111': {'lat': '20.9256', 'name': 'Champulipada,Bhadrak,Odisha', 'lon': '85.5563'},
      '623532': {'lat': '9.616', 'name': 'Melapudukudi,Ramanathapuram,Tamil Nadu', 'lon': '78.7402'},
      '623531': {'lat': '9.6992', 'name': 'Tirupalaikudi,Ramanathapuram,Tamil Nadu', 'lon': '78.9812'},
      '385410': {'lat': '24.093', 'name': 'Badargadh,Banaskantha,Gujarat', 'lon': '72.4925'},
      '583105': {'lat': '15.0956', 'name': 'Bellary Jnanasagara,Ballari,Karnataka', 'lon': '76.7489'},
      '211021': {'lat': '25.5156', 'name': 'Rajarshi Tandon Open University Pha,Allahabad,Uttar Pradesh', 'lon': '81.7868'},
      '583104': {'lat': '15.1944', 'name': 'Sanjay Gandhi Nagar,Ballari,Karnataka', 'lon': '76.8659'},
      '370475': {'lat': '22.9029', 'name': 'Bayath,Kachchh,Gujarat', 'lon': '69.148'},
      '623537': {'lat': '9.5299', 'name': 'P.Kodikulam,Ramanathapuram,Tamil Nadu', 'lon': '78.8616'},
      '577538': {'lat': '14.2516', 'name': 'Thimmanayakanakote,Chitradurga,Karnataka', 'lon': '76.8404'},
      '147301': {'lat': '30.6515', 'name': 'Mandigobindgarh It,Fatehgarh Sahib,Punjab', 'lon': '76.2416'},
      '625108': {'lat': '10.1157', 'name': 'Kodukkampatti,Madurai,Tamil Nadu', 'lon': '78.4012'},
      '625109': {'lat': '10.0086', 'name': 'Saruguvalayapatti,Madurai,Tamil Nadu', 'lon': '78.3682'},
      '272128': {'lat': '26.6633', 'name': 'Bardia Kunwar,Basti,Uttar Pradesh', 'lon': '82.1392'},
      '272129': {'lat': '26.9406', 'name': 'Sikandarpur,Basti,Uttar Pradesh', 'lon': '82.6681'},
      '148022': {'lat': '30.4891', 'name': 'Mullabadha,Sangrur,Punjab', 'lon': '76.0076'},
      '521390': {'lat': '16.3582', 'name': 'Pedamaddali,Krishna,Andhra Pradesh', 'lon': '80.9856'},
      '583101': {'lat': '15.1368', 'name': 'Bellary Herialukadam,Ballari,Karnataka', 'lon': '76.9275'},
      '421005': {'lat': '19.3148', 'name': 'Ulhasnagar-5,Thane,Maharashtra', 'lon': '73.7854'},
      '421004': {'lat': '19.3148', 'name': 'Ulhasnagar-4,Thane,Maharashtra', 'lon': '73.7854'},
      '625104': {'lat': '9.9986', 'name': 'Vowalthottam,Madurai,Tamil Nadu', 'lon': '78.5732'},
      '389110': {'lat': '23.05895', 'name': 'Chopda Buzarg,Dahod,Gujarat', 'lon': '73.85756'},
      '625106': {'lat': '10.0375', 'name': 'Arittapatti,Madurai,Tamil Nadu', 'lon': '78.2848'},
      '625107': {'lat': '9.9272', 'name': 'Puduthamaraipatti,Madurai,Tamil Nadu', 'lon': '78.2389'},
      '641602': {'lat': '11.0099', 'name': 'Tirupur North,Coimbatore,Tamil Nadu', 'lon': '77.3289'},
      '148020': {'lat': '30.9531', 'name': 'Mohamedpur,Sangrur,Punjab', 'lon': '75.6755'},
      '583103': {'lat': '14.9371', 'name': 'Gududur,Ballari,Karnataka', 'lon': '76.7326'},
      '143419': {'lat': '31.2182', 'name': 'Asal Autar,Tarn Taran,Punjab', 'lon': '74.6092'},
      '143416': {'lat': '31.3413', 'name': 'Mahaneke Jandh,Tarn Taran,Punjab', 'lon': '74.9428'},
      '143415': {'lat': '31.3282', 'name': 'Kairon,Tarn Taran,Punjab', 'lon': '74.8637'},
      '583102': {'lat': '15.2984', 'name': 'Ibrahimpura,Ballari,Karnataka', 'lon': '76.6996'},
      '143413': {'lat': '31.554', 'name': 'Manawala Khurd,Tarn Taran,Punjab', 'lon': '74.9677'},
      '143412': {'lat': '31.1968', 'name': 'Jaunke,Tarn Taran,Punjab', 'lon': '74.9221'},
      '143411': {'lat': '31.3654', 'name': 'Usman,Tarn Taran,Punjab', 'lon': '74.9687'},
      '143410': {'lat': '31.4669', 'name': 'Gandiwind Dhattal,Tarn Taran,Punjab', 'lon': '74.8325'},
      '712306': {'lat': '22.7478', 'name': 'Gangadharpur Bazar,Hooghly,West Bengal', 'lon': '88.2487'},
      '712305': {'lat': '22.9137', 'name': 'Nagbal,Hooghly,West Bengal', 'lon': '88.2125'},
      '712304': {'lat': '22.7129', 'name': 'Monirampur,Hooghly,West Bengal', 'lon': '88.2523'},
      '712303': {'lat': '22.9419', 'name': 'Ghosla,Hooghly,West Bengal', 'lon': '88.3005'},
      '624211': {'lat': '10.2123', 'name': 'Ayyankottai,Dindigul,Tamil Nadu', 'lon': '77.7609'},
      '712301': {'lat': '22.8808', 'name': 'Kanui Banka,Hooghly,West Bengal', 'lon': '88.0924'},
      '144208': {'lat': '31.6775', 'name': 'Bhikhowal,Hoshiarpur,Punjab', 'lon': '75.8296'},
      '825302': {'lat': '23.9604', 'name': 'Salgawan,Hazaribag,Jharkhand', 'lon': '85.2837'},
      '712308': {'lat': '22.9655', 'name': 'Chopa,Hooghly,West Bengal', 'lon': '88.1729'},
      '783389': {'lat': '26.3469', 'name': 'Boitamari,Bongaigaon,Assam', 'lon': '90.6011'},
      '783388': {'lat': '26.2585', 'name': 'A.P.Mill,Bongaigaon,Assam', 'lon': '91.1281'},
      '755003': {'lat': '20.882', 'name': 'Bari Cuttack,Jajapur,Odisha', 'lon': '85.1875'},
      '581316': {'lat': '14.7333', 'name': 'Hattikeri,Uttara Kannada,Karnataka', 'lon': '74.3'},
      '581317': {'lat': '14.8985', 'name': 'Asnoti,Uttara Kannada,Karnataka', 'lon': '74.2512'},
      '581314': {'lat': '14.7067', 'name': 'Kalleswar,Uttara Kannada,Karnataka', 'lon': '74.4703'},
      '581315': {'lat': '14.5534', 'name': 'Khursi,Uttara Kannada,Karnataka', 'lon': '74.6922'},
      '783381': {'lat': '26.4638', 'name': 'Simlaguri,Bongaigaon,Assam', 'lon': '90.5639'},
      '783380': {'lat': '26.3656', 'name': 'North Baitamari,Bongaigaon,Assam', 'lon': '90.6348'},
      '783383': {'lat': '26.3656', 'name': 'Garugaon,Bongaigaon,Assam', 'lon': '90.6348'},
      '783382': {'lat': '26.2265', 'name': 'Barjana,Bongaigaon,Assam', 'lon': '90.5725'},
      '783385': {'lat': '26.4973', 'name': 'Kajalgaon,Bongaigaon,Assam', 'lon': '90.5216'},
      '783384': {'lat': '26.3148', 'name': 'B.S.Gram,Bongaigaon,Assam', 'lon': '90.4514'},
      '627851': {'lat': '9.1836', 'name': 'Alangulam,Tirunelveli,Tamil Nadu', 'lon': '77.593'},
      '627852': {'lat': '9.0032', 'name': 'Agarakattu,Tirunelveli,Tamil Nadu', 'lon': '77.3449'},
      '628207': {'lat': '8.5876', 'name': 'Puraiyur,Tuticorin,Tamil Nadu', 'lon': '78.0497'},
      '627854': {'lat': '9.0532', 'name': 'Kidanganeri,Tirunelveli,Tamil Nadu', 'lon': '77.5938'},
      '627855': {'lat': '9.1749', 'name': 'Ayyapuram,Tirunelveli,Tamil Nadu', 'lon': '77.398'},
      '413736': {'lat': '19.4252', 'name': 'Loni Bk,Ahmed Nagar,Maharashtra', 'lon': '76.5295'},
      '413737': {'lat': '19.1469', 'name': 'Babhleshwar,Ahmed Nagar,Maharashtra', 'lon': '74.9649'},
      '413738': {'lat': '19.6116', 'name': 'Warwandi,Ahmed Nagar,Maharashtra', 'lon': '75.6153'},
      '413739': {'lat': '20.1694', 'name': 'Bhamathan,Ahmed Nagar,Maharashtra', 'lon': '75.8086'},
      '303004': {'lat': '27.0451', 'name': 'Bhateri,Dausa,Rajasthan', 'lon': '76.3592'},
      '303005': {'lat': '26.6122', 'name': 'Kudli,Jaipur,Rajasthan', 'lon': '75.735'},
      '303002': {'lat': '27.1368', 'name': 'Kali Pahadi,Jaipur,Rajasthan', 'lon': '75.9403'},
      '303003': {'lat': '26.6377', 'name': 'Paldi,Jaipur,Rajasthan', 'lon': '76.2165'},
      '303001': {'lat': '27.1195', 'name': 'Dangaarwara,Jaipur,Rajasthan', 'lon': '75.8862'},
      '202134': {'lat': '28.016', 'name': 'Budha Gaon,Aligarh,Uttar Pradesh', 'lon': '78.5088'},
      '313704': {'lat': '25.0049', 'name': 'Chitrawas.,Udaipur,Rajasthan', 'lon': '73.5039'},
      '624219': {'lat': '10.2722', 'name': 'Sirunaickenpatti,Dindigul,Tamil Nadu', 'lon': '78.1358'},
      '721628': {'lat': '22.2498', 'name': 'Basulia,East Midnapore,West Bengal', 'lon': '88.1065'},
      '630556': {'lat': '9.9151', 'name': 'Panangadi,Sivaganga,Tamil Nadu', 'lon': '78.5987'},
      '833221': {'lat': '22.1649', 'name': 'Barajamda,West Singhbhum,Jharkhand', 'lon': '85.4272'},
      '503112': {'lat': '18.356', 'name': 'Kamareddy Arts & Science,Nizamabad,Telangana', 'lon': '78.3365'},
      '503110': {'lat': '18.2424', 'name': 'Baswannapalli,Nizamabad,Telangana', 'lon': '78.3003'},
      '503111': {'lat': '18.3009', 'name': 'Kamareddy H.O,Nizamabad,Telangana', 'lon': '78.2481'},
      '311401': {'lat': '25.4694', 'name': 'Babrana,Bhilwara,Rajasthan', 'lon': '74.7216'},
      '847238': {'lat': '26.3803', 'name': 'Rahika,Madhubani,Bihar', 'lon': '86.0204'},
      '401203': {'lat': '19.4158', 'name': 'Umrale,Thane,Maharashtra', 'lon': '72.8008'},
      '401202': {'lat': '19.4191', 'name': 'Umela,Thane,Maharashtra', 'lon': '72.9126'},
      '401201': {'lat': '19.4191', 'name': 'Girij,Thane,Maharashtra', 'lon': '72.9126'},
      '458664': {'lat': '25.0977', 'name': 'Balaguda,Mandsaur,Madhya Pradesh', 'lon': '78.1123'},
      '401207': {'lat': '19.4191', 'name': 'Papdi,Thane,Maharashtra', 'lon': '72.9126'},
      '401206': {'lat': '19.4191', 'name': 'Ganeshpuri,Thane,Maharashtra', 'lon': '72.9126'},
      '401204': {'lat': '19.4869', 'name': 'Vedhe,Thane,Maharashtra', 'lon': '73.0277'},
      '401209': {'lat': '19.4191', 'name': 'Nallosapare E,Thane,Maharashtra', 'lon': '72.9126'},
      '401208': {'lat': '19.3547', 'name': 'Satiwai,Thane,Maharashtra', 'lon': '72.9094'},
      '458669': {'lat': '24.0822', 'name': 'Behpur,Mandsaur,Madhya Pradesh', 'lon': '76.517'},
      '761028': {'lat': '19.5253', 'name': 'Polango,Ganjam,Odisha', 'lon': '84.3562'},
      '761029': {'lat': '20.5861', 'name': 'Khallikote RS,Ganjam,Odisha', 'lon': '85.4897'},
      '470771': {'lat': '23.8127', 'name': 'Hindoriya,Damoh,Madhya Pradesh', 'lon': '79.5273'},
      '221310': {'lat': '25.3225', 'name': 'Darawasi,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.9591'},
      '761020': {'lat': '19.9269', 'name': 'Chatrapur H.O,Ganjam,Odisha', 'lon': '84.5389'},
      '193404': {'lat': '34.0665', 'name': 'Dhobiwan,Baramulla,Jammu & Kashmir', 'lon': '74.5238'},
      '721513': {'lat': '22.351', 'name': 'Jhantibandh,West Midnapore,West Bengal', 'lon': '87.0859'},
      '193401': {'lat': '34.0716', 'name': 'Beerwah,Srinagar,Jammu & Kashmir', 'lon': '74.5846'},
      '761025': {'lat': '19.9269', 'name': 'Jayashree,Ganjam,Odisha', 'lon': '84.5389'},
      '193403': {'lat': '34.0536', 'name': 'Gulmarg,Baramulla,Jammu & Kashmir', 'lon': '74.3819'},
      '193402': {'lat': '34.0589', 'name': 'Hardichhloo,Baramulla,Jammu & Kashmir', 'lon': '74.4228'},
      '221505': {'lat': '25.4641', 'name': 'Katwaroopur,Allahabad,Uttar Pradesh', 'lon': '82.0058'},
      '632107': {'lat': '12.7834', 'name': 'Veppankuppam,Vellore,Tamil Nadu', 'lon': '78.877'},
      '221502': {'lat': '25.3797', 'name': 'Bhelsi,Allahabad,Uttar Pradesh', 'lon': '82.2402'},
      '221503': {'lat': '25.4966', 'name': 'Beerapur,Allahabad,Uttar Pradesh', 'lon': '82.1218'},
      '177601': {'lat': '31.706961', 'name': 'Samirpur,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6612849'},
      '221508': {'lat': '25.3317', 'name': 'Sikikhurda,Allahabad,Uttar Pradesh', 'lon': '82.0039'},
      '833223': {'lat': '22.0724', 'name': 'Meghahatuburu,West Singhbhum,Jharkhand', 'lon': '85.2692'},
      '470673': {'lat': '23.8611', 'name': 'Ajni,Damoh,Madhya Pradesh', 'lon': '79.3959'},
      '721641': {'lat': '23.0254', 'name': 'Jiakhali,East Midnapore,West Bengal', 'lon': '87.8966'},
      '402308': {'lat': '18.237053', 'name': 'Jamgaon,Raigarh(MH),Maharashtra', 'lon': '73.283597'},
      '503123': {'lat': '18.2698', 'name': 'Sangameshwar,Nizamabad,Telangana', 'lon': '78.4547'},
      '723201': {'lat': '23.206', 'name': 'Hinjuri Shyampur,Purulia,West Bengal', 'lon': '86.5939'},
      '396105': {'lat': '21.8727', 'name': 'Borlai,Valsad,Gujarat', 'lon': '73.3439'},
      '271403': {'lat': '26.8962', 'name': 'Tiwari Bazar,Gonda,Uttar Pradesh', 'lon': '81.9512'},
      '829207': {'lat': '23.6929', 'name': 'Richughuta,Latehar,Jharkhand', 'lon': '84.4819'},
      '364710': {'lat': '22.5464', 'name': 'Dhakania,Bhavnagar,Gujarat', 'lon': '71.2849'},
      '531105': {'lat': '17.7377', 'name': 'Duppulavada,Visakhapatnam,Andhra Pradesh', 'lon': '82.7162'},
      '249155': {'lat': '30.0116', 'name': 'Bajiyalgaon,Tehri Garhwal,Uttarakhand', 'lon': '78.7659'},
      '311402': {'lat': '25.3569', 'name': 'Ghodas,Bhilwara,Rajasthan', 'lon': '74.3732'},
      '249151': {'lat': '30.4529', 'name': 'Thati-dhanari,Uttarkashi,Uttarakhand', 'lon': '78.191'},
      '249152': {'lat': '30.1165', 'name': 'Tarakot,Uttarkashi,Uttarakhand', 'lon': '78.8232'},
      '678661': {'lat': '10.3905', 'name': 'Tunacadavu,Palakkad,Kerala', 'lon': '76.7924'},
      '464258': {'lat': '24.2646', 'name': 'Hinotia,Vidisha,Madhya Pradesh', 'lon': '77.4771'},
      '273007': {'lat': '26.8446', 'name': 'Maina Bhagar,Gorakhpur,Uttar Pradesh', 'lon': '83.3062'},
      '678543': {'lat': '10.6568', 'name': 'Vavulliapuram,Palakkad,Kerala', 'lon': '76.4882'},
      '226303': {'lat': '26.6928', 'name': 'Adampur Janubi,Lucknow,Uttar Pradesh', 'lon': '80.7756'},
      '494444': {'lat': '18.8186', 'name': 'Etpal,Bijapur(CGH),Chattisgarh', 'lon': '80.9516'},
      '804401': {'lat': '25.0204', 'name': 'Kodra,Arwal,Bihar', 'lon': '84.6553'},
      '494347': {'lat': '20.2174', 'name': 'Vishrampuri,Bastar,Chattisgarh', 'lon': '81.8508'},
      '465689': {'lat': '23.924', 'name': 'Khajurigokul,Rajgarh,Madhya Pradesh', 'lon': '76.4493'},
      '712410': {'lat': '23.4607', 'name': 'Shyampur,Hooghly,West Bengal', 'lon': '87.6462'},
      '690544': {'lat': '9.0792', 'name': 'Karunagappally North,Kollam,Kerala', 'lon': '76.4916'},
      '505454': {'lat': '18.7038', 'name': 'Potharam,Karim Nagar,Telangana', 'lon': '78.8449'},
      '690546': {'lat': '9.0792', 'name': 'Amrithapuri,Kollam,Kerala', 'lon': '76.4916'},
      '690547': {'lat': '9.0792', 'name': 'Alumpeedika,Kollam,Kerala', 'lon': '76.4916'},
      '690540': {'lat': '9.0792', 'name': 'Karimthottuva,Kollam,Kerala', 'lon': '76.4916'},
      '505450': {'lat': '18.7112', 'name': 'Varsikonda,Karim Nagar,Telangana', 'lon': '78.8748'},
      '690542': {'lat': '9.0792', 'name': 'Athinad North,Kollam,Kerala', 'lon': '76.4916'},
      '203395': {'lat': '28.3072', 'name': 'Bohich,Bulandshahr,Uttar Pradesh', 'lon': '77.9986'},
      '210203': {'lat': '25.3957', 'name': 'Pahari Bujurg Sani,Chitrakoot,Uttar Pradesh', 'lon': '80.7243'},
      '690548': {'lat': '9.0792', 'name': 'Thamallackal  North,Alappuzha,Kerala', 'lon': '76.4916'},
      '445101': {'lat': '20.5249', 'name': 'Watkhed,Yavatmal,Maharashtra', 'lon': '78.3255'},
      '445102': {'lat': '20.556', 'name': 'Mozar,Yavatmal,Maharashtra', 'lon': '77.7853'},
      '445103': {'lat': '19.9593', 'name': 'Lonbehel,Yavatmal,Maharashtra', 'lon': '78.0908'},
      '445105': {'lat': '20.1659', 'name': 'Nakapardi,Yavatmal,Maharashtra', 'lon': '78.0364'},
      '445106': {'lat': '19.8394', 'name': 'Bhansara,Yavatmal,Maharashtra', 'lon': '77.9289'},
      '847301': {'lat': '25.7712', 'name': 'Darshuar,Samastipur,Bihar', 'lon': '86.1776'},
      '445109': {'lat': '20.2286', 'name': 'Pimpri Buti,Yavatmal,Maharashtra', 'lon': '78.0712'},
      '160071': {'lat': '30.7465', 'name': 'Chandigarh Sector 71,Mohali,Punjab', 'lon': '76.7417'},
      '737102': {'lat': '27.2719', 'name': 'Dhajey,East Sikkim,Sikkim', 'lon': '88.61755'},
      '603405': {'lat': '12.5922', 'name': 'Salur,Kanchipuram,Tamil Nadu', 'lon': '80.0009'},
      '732212': {'lat': '24.7458', 'name': 'Jadupur,Malda,West Bengal', 'lon': '87.9659'},
      '732211': {'lat': '25.1367', 'name': 'Milki,Malda,West Bengal', 'lon': '88.0923'},
      '603406': {'lat': '12.5815', 'name': 'Kavanur Pudhucheri,Kanchipuram,Tamil Nadu', 'lon': '79.7753'},
      '603401': {'lat': '12.2558', 'name': 'Chunampet,Kanchipuram,Tamil Nadu', 'lon': '79.9033'},
      '732216': {'lat': '24.5731', 'name': 'Umakantatola,Malda,West Bengal', 'lon': '87.974'},
      '515531': {'lat': '14.0811', 'name': 'Eddulavaripalli,Ananthapur,Andhra Pradesh', 'lon': '77.9842'},
      '603402': {'lat': '12.5828', 'name': 'Elaganar,Kanchipuram,Tamil Nadu', 'lon': '79.7172'},
      '206128': {'lat': '26.841', 'name': 'Anantraam Sonasi,Etawah,Uttar Pradesh', 'lon': '79.1207'},
      '624613': {'lat': '10.5031', 'name': 'Poolampati,Dindigul,Tamil Nadu', 'lon': '77.7061'},
      '403709': {'lat': '15.2756', 'name': 'Curtorim,South Goa,Goa', 'lon': '74.0216'},
      '609605': {'lat': '10.9434', 'name': 'Karaikoilpathu,Karaikal,Pondicherry', 'lon': '79.7558'},
      '333304': {'lat': '28.1975', 'name': 'Birol,Jhujhunu,Rajasthan', 'lon': '75.5453'},
      '496109': {'lat': '21.8385', 'name': 'Punjipathra,Raigarh,Chattisgarh', 'lon': '83.3186'},
      '496440': {'lat': '21.702', 'name': 'Padigaon,Raigarh,Chattisgarh', 'lon': '83.3881'},
      '600014': {'lat': '13.0506', 'name': 'Lloyds Estate,Chennai,Tamil Nadu', 'lon': '80.2642'},
      '575008': {'lat': '13.1602', 'name': 'Konchady,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '534475': {'lat': '16.8156', 'name': 'Kavvagunta,West Godavari,Andhra Pradesh', 'lon': '81.0453'},
      '575001': {'lat': '13.1602', 'name': 'Mangalore Collectorate,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575003': {'lat': '13.1602', 'name': 'District Courts (Dakshina Kannada),Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575002': {'lat': '13.1602', 'name': 'Balmatta,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575005': {'lat': '13.1602', 'name': 'Kulshekar H.O,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575004': {'lat': '13.1602', 'name': 'Bijai,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575007': {'lat': '13.1602', 'name': 'Bajal,Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '575006': {'lat': '13.1602', 'name': 'Ashoknagar(MR),Dakshina Kannada,Karnataka', 'lon': '75.0735'},
      '442908': {'lat': '20.186', 'name': 'Jiwati,Chandrapur,Maharashtra', 'lon': '78.9057'},
      '442901': {'lat': '20.3083', 'name': 'Ballarpur Paper Mill,Chandrapur,Maharashtra', 'lon': '79.2311'},
      '587315': {'lat': '16.5204', 'name': 'Sasalatti,Bagalkot,Karnataka', 'lon': '75.0649'},
      '442903': {'lat': '20.479', 'name': 'Navtala,Chandrapur,Maharashtra', 'lon': '79.4699'},
      '442902': {'lat': '20.1179', 'name': 'Sumthana,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '442905': {'lat': '20.1172', 'name': 'Warud Road,Chandrapur,Maharashtra', 'lon': '79.1803'},
      '442904': {'lat': '20.2809', 'name': 'Kewada Mokasa,Chandrapur,Maharashtra', 'lon': '79.414'},
      '442907': {'lat': '20.3478', 'name': 'Ekarjuna,Chandrapur,Maharashtra', 'lon': '79.0442'},
      '442906': {'lat': '20.1179', 'name': 'Bhendala,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '364130': {'lat': '21.9081', 'name': 'Nincha Kotda,Bhavnagar,Gujarat', 'lon': '72.9807'},
      '342605': {'lat': '26.1647', 'name': 'Hungaon Kallan,Jodhpur,Rajasthan', 'lon': '73.565'},
      '364135': {'lat': '21.9081', 'name': 'Reliya,Bhavnagar,Gujarat', 'lon': '72.9807'},
      '605402': {'lat': '12.0156', 'name': 'Thiruvamathur,Villupuram,Tamil Nadu', 'lon': '79.4232'},
      '757017': {'lat': '21.1075', 'name': 'Nankua,Mayurbhanj,Odisha', 'lon': '85.4405'},
      '524137': {'lat': '14.3495', 'name': 'Inamadugu,Nellore,Andhra Pradesh', 'lon': '79.85'},
      '700064': {'lat': '22.4839', 'name': 'Labony Estate,North 24 Parganas,West Bengal', 'lon': '88.3038'},
      '700067': {'lat': '22.4839', 'name': 'Lily Biscuit,Kolkata,West Bengal', 'lon': '88.3038'},
      '524134': {'lat': '14.0655', 'name': 'Chapalapalli,Nellore,Andhra Pradesh', 'lon': '79.5787'},
      '700061': {'lat': '22.4839', 'name': 'Dakshin Behala,South 24 Parganas,West Bengal', 'lon': '88.3038'},
      '524132': {'lat': '13.9365', 'name': 'Peturu,Nellore,Andhra Pradesh', 'lon': '79.5581'},
      '524131': {'lat': '14.0622', 'name': 'Vedicherla,Nellore,Andhra Pradesh', 'lon': '79.6794'},
      '700062': {'lat': '22.4839', 'name': 'W.B.Governors Camp.,Kolkata,West Bengal', 'lon': '88.3038'},
      '700069': {'lat': '22.4839', 'name': 'Income Tax Building,Kolkata,West Bengal', 'lon': '88.3038'},
      '700068': {'lat': '22.4839', 'name': 'Jodhpur Park,Kolkata,West Bengal', 'lon': '88.3038'},
      '501510': {'lat': '17.2889', 'name': 'Turkayamzal,K.V.Rangareddy,Telangana', 'lon': '78.4901'},
      '501511': {'lat': '17.2827', 'name': 'Sanghinagar,K.V.Rangareddy,Telangana', 'lon': '78.6357'},
      '501512': {'lat': '17.2863', 'name': 'Ramoji Film name,K.V.Rangareddy,Telangana', 'lon': '78.7099'},
      '600106': {'lat': '13.0734', 'name': 'D G Vaishnav College,Chennai,Tamil Nadu', 'lon': '80.2069'},
      '249132': {'lat': '30.3103', 'name': 'Bagiyal,Tehri Garhwal,Uttarakhand', 'lon': '79.0071'},
      '614001': {'lat': '10.4949', 'name': 'Vanakkara Street,Tiruvarur,Tamil Nadu', 'lon': '79.4256'},
      '345026': {'lat': '26.5175', 'name': 'Loona Kallan,Jaisalmer,Rajasthan', 'lon': '73.1019'},
      '345027': {'lat': '27.0774', 'name': 'Kunda,Jaisalmer,Rajasthan', 'lon': '70.9034'},
      '345024': {'lat': '26.6603', 'name': 'Pannasar,Jaisalmer,Rajasthan', 'lon': '71.8893'},
      '345025': {'lat': '26.0158', 'name': 'Rajmathai,Jaisalmer,Rajasthan', 'lon': '72.8624'},
      '345022': {'lat': '27.5845', 'name': 'Raghwa,Jaisalmer,Rajasthan', 'lon': '70.4259'},
      '345023': {'lat': '27.0212', 'name': 'Chayan,Jaisalmer,Rajasthan', 'lon': '71.277'},
      '345021': {'lat': '26.7975', 'name': 'Pokaran name,Jaisalmer,Rajasthan', 'lon': '72.3656'},
      '249137': {'lat': '30.4831', 'name': 'Munikireti,Tehri Garhwal,Uttarakhand', 'lon': '78.7457'},
      '495118': {'lat': '22.9303', 'name': 'Ratga,Bilaspur(CGH),Chattisgarh', 'lon': '82.0339'},
      '133301': {'lat': '30.8398', 'name': 'B.C.W. Suraj Pur,Panchkula,Haryana', 'lon': '76.9406'},
      '133302': {'lat': '30.8398', 'name': 'Kharanwali,Panchkula,Haryana', 'lon': '76.9406'},
      '249136': {'lat': '30.9959', 'name': 'Gangotri,Uttarkashi,Uttarakhand', 'lon': '78.9432'},
      '249135': {'lat': '30.6362', 'name': 'Gorsali,Uttarkashi,Uttarakhand', 'lon': '78.7438'},
      '607112': {'lat': '11.7093', 'name': 'Melkavrapattu,Cuddalore,Tamil Nadu', 'lon': '79.3474'},
      '813110': {'lat': '24.8957', 'name': 'Raugaon,Munger,Bihar', 'lon': '87.1112'},
      '743395': {'lat': '22.3921', 'name': 'Kecharkur Gopalnagar,South 24 Parganas,West Bengal', 'lon': '88.2627'},
      '624616': {'lat': '10.4958', 'name': 'Paruthiyur,Dindigul,Tamil Nadu', 'lon': '77.7595'},
      '605803': {'lat': '11.9488', 'name': 'C Meyyur,Villupuram,Tamil Nadu', 'lon': '79.0072'},
      '605802': {'lat': '11.9854', 'name': 'Kadambur (Villupuram),Villupuram,Tamil Nadu', 'lon': '79.0423'},
      '605801': {'lat': '11.9269', 'name': 'Ariyalur (Villupuram),Villupuram,Tamil Nadu', 'lon': '78.9862'},
      '743399': {'lat': '22.3131', 'name': 'Ramdebpur,South 24 Parganas,West Bengal', 'lon': '88.3402'},
      '682511': {'lat': '10.019', 'name': 'Malipuram,Ernakulam,Kerala', 'lon': '76.2278'},
      '813113': {'lat': '25.1314', 'name': 'Habibpur (Bhagalpur),Bhagalpur,Bihar', 'lon': '87.0266'},
      '691551': {'lat': '9.1383', 'name': 'Choorakode,Pathanamthitta,Kerala', 'lon': '76.7133'},
      '691552': {'lat': '8.961', 'name': 'Edakkad,Kollam,Kerala', 'lon': '76.8749'},
      '691553': {'lat': '9.0833', 'name': 'Manampuzha,Kollam,Kerala', 'lon': '76.7744'},
      '691554': {'lat': '9.0833', 'name': 'Puthumala,Pathanamthitta,Kerala', 'lon': '76.7744'},
      '691555': {'lat': '9.1863', 'name': 'Kodumon East,Pathanamthitta,Kerala', 'lon': '76.7677'},
      '691556': {'lat': '9.1286', 'name': 'Chelikuzhy,Pathanamthitta,Kerala', 'lon': '76.796'},
      '691557': {'lat': '9.0833', 'name': 'Kura,Kollam,Kerala', 'lon': '76.7744'},
      '441904': {'lat': '21.1612', 'name': 'Bhandara Town,Bhandara,Maharashtra', 'lon': '79.9317'},
      '441905': {'lat': '21.1292', 'name': 'Mandvi,Bhandara,Maharashtra', 'lon': '79.7509'},
      '441906': {'lat': '21.0752', 'name': 'Pipri,Bhandara,Maharashtra', 'lon': '79.5693'},
      '441907': {'lat': '21.5638', 'name': 'Chikhala,Bhandara,Maharashtra', 'lon': '79.7271'},
      '441901': {'lat': '21.2263', 'name': 'Murdoli,Gondia,Maharashtra', 'lon': '80.2026'},
      '441902': {'lat': '21.2633', 'name': 'Satgaon,Gondia,Maharashtra', 'lon': '80.4299'},
      '370001': {'lat': '23.3924', 'name': 'Bhuj Darbargadh Chowk,Kachchh,Gujarat', 'lon': '69.8923'},
      '388450': {'lat': '22.7613', 'name': 'Mariumpura,Anand,Gujarat', 'lon': '72.992'},
      '410406': {'lat': '19.4781', 'name': 'Shivali,Pune,Maharashtra', 'lon': '73.7845'},
      '410405': {'lat': '19.6594', 'name': 'Takave KH,Pune,Maharashtra', 'lon': '73.8791'},
      '231224': {'lat': '24.3821', 'name': 'Kakari Project,Sonbhadra,Uttar Pradesh', 'lon': '83.1862'},
      '441908': {'lat': '20.8111', 'name': 'Sarandi,Bhandara,Maharashtra', 'lon': '79.8015'},
      '441909': {'lat': '21.309', 'name': 'Mohadi,Bhandara,Maharashtra', 'lon': '79.6744'},
      '410401': {'lat': '18.7528', 'name': 'Kusgaon BK,Pune,Maharashtra', 'lon': '73.4057'},
      '695024': {'lat': '8.4954', 'name': 'Palkulangara,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695025': {'lat': '8.4954', 'name': 'Muttada,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695026': {'lat': '8.4954', 'name': 'Poonthura,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695027': {'lat': '8.4954', 'name': 'Pachallur,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695020': {'lat': '8.4954', 'name': 'Ookode,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695021': {'lat': '8.4954', 'name': 'Karikkakom,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695022': {'lat': '8.4954', 'name': 'Thiruvananthapuram  ISRO,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695023': {'lat': '8.4954', 'name': 'Thiruvananthapuram  Fort,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '416122': {'lat': '16.9138', 'name': 'Shiye,Kolhapur,Maharashtra', 'lon': '74.4432'},
      '416120': {'lat': '17.1879', 'name': 'Chinchwad,Kolhapur,Maharashtra', 'lon': '75.0399'},
      '416121': {'lat': '17.7362', 'name': 'Takawade,Kolhapur,Maharashtra', 'lon': '76.2333'},
      '695028': {'lat': '8.4954', 'name': 'Pandalacode,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '695029': {'lat': '8.4954', 'name': 'Anayara,Thiruvananthapuram,Kerala', 'lon': '76.9305'},
      '605011': {'lat': '11.8768', 'name': 'Venkata Nagar,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '561228': {'lat': '13.7233', 'name': 'Thokalahalli,Chikkaballapur,Karnataka', 'lon': '77.614'},
      '743345': {'lat': '22.1169', 'name': 'Kashipur,South 24 Parganas,West Bengal', 'lon': '88.4168'},
      '560054': {'lat': '13.0323', 'name': 'Sri Chowdeshwari,Bengaluru,Karnataka', 'lon': '77.5609'},
      '560055': {'lat': '12.9979', 'name': 'Malleswaram West,Bengaluru,Karnataka', 'lon': '77.5861'},
      '560056': {'lat': '12.9979', 'name': 'Mallathahalli,Bengaluru,Karnataka', 'lon': '77.5861'},
      '560057': {'lat': '12.9979', 'name': 'Peenya Dasarahalli,Bengaluru,Karnataka', 'lon': '77.5861'},
      '388630': {'lat': '22.6106', 'name': 'Nana Kalodara,Anand,Gujarat', 'lon': '72.1851'},
      '560051': {'lat': '12.9979', 'name': 'H.K.P. Road,Bengaluru,Karnataka', 'lon': '77.5861'},
      '560053': {'lat': '12.9979', 'name': 'Chickpet,Bengaluru,Karnataka', 'lon': '77.5861'},
      '475002': {'lat': '25.6157', 'name': 'Hastinapur,Gwalior,Madhya Pradesh', 'lon': '78.7277'},
      '605014': {'lat': '11.8768', 'name': 'Kilpudupattu,Villupuram,Tamil Nadu', 'lon': '79.8107'},
      '475001': {'lat': '25.5068', 'name': 'Amrol,Gwalior,Madhya Pradesh', 'lon': '78.4253'},
      '560058': {'lat': '12.9979', 'name': 'Peenya Small Industries,Bengaluru,Karnataka', 'lon': '77.5861'},
      '560059': {'lat': '12.9979', 'name': 'Rv Niketan,Bengaluru,Karnataka', 'lon': '77.5861'},
      '475005': {'lat': '25.967', 'name': 'Kalyani,Gwalior,Madhya Pradesh', 'lon': '78.2569'},
      '483222': {'lat': '23.401', 'name': 'Khinni,Jabalpur,Madhya Pradesh', 'lon': '79.9784'},
      '140101': {'lat': '30.7901', 'name': 'Morinda,Ropar,Punjab', 'lon': '76.4988'},
      '571807': {'lat': '12.4129', 'name': 'Maralagala,Mandya,Karnataka', 'lon': '76.7389'},
      '731104': {'lat': '23.9639', 'name': 'Tapaspur,Birbhum,West Bengal', 'lon': '87.63'},
      '571802': {'lat': '12.9405', 'name': 'Sathenahalli,Mandya,Karnataka', 'lon': '76.5465'},
      '585201': {'lat': '16.8697', 'name': 'Yadgiri H.O,Yadgir,Karnataka', 'lon': '77.0283'},
      '585202': {'lat': '16.8697', 'name': 'Kawalur,Yadgir,Karnataka', 'lon': '77.0283'},
      '523245': {'lat': '15.5746', 'name': 'Buchannapalem,Prakasam,Andhra Pradesh', 'lon': '79.3119'},
      '523246': {'lat': '15.2081', 'name': 'Gayamvaripalli,Prakasam,Andhra Pradesh', 'lon': '79.4434'},
      '523247': {'lat': '15.083', 'name': 'Pothavaram,Prakasam,Andhra Pradesh', 'lon': '79.4956'},
      '523240': {'lat': '15.3631', 'name': 'Podili H.O,Prakasam,Andhra Pradesh', 'lon': '79.4272'},
      '394410': {'lat': '21.4833', 'name': 'Mota Miya Mangrol,Surat,Gujarat', 'lon': '73.1167'},
      '394140': {'lat': '21.8141', 'name': 'Munjlav,Surat,Gujarat', 'lon': '73.151'},
      '689641': {'lat': '9.2422', 'name': 'Kozhencherry East,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '334302': {'lat': '27.8256', 'name': 'Bheloo,Bikaner,Rajasthan', 'lon': '72.4203'},
      '689643': {'lat': '9.2422', 'name': 'Elanthur,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689642': {'lat': '9.2422', 'name': 'Naranganam,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689645': {'lat': '9.2422', 'name': 'Makkankunnu,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689644': {'lat': '9.2422', 'name': 'Kuzhikala,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689647': {'lat': '9.2541', 'name': 'Omallur-kla,Pathanamthitta,Kerala', 'lon': '76.7308'},
      '689646': {'lat': '9.2422', 'name': 'Thengumcavu,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689649': {'lat': '9.2422', 'name': 'Anthiyalankavu,Pathanamthitta,Kerala', 'lon': '76.7424'},
      '689648': {'lat': '9.2303', 'name': 'Chandanapally,Pathanamthitta,Kerala', 'lon': '76.754'},
      '532484': {'lat': '18.344', 'name': 'Gudem,Srikakulam,Andhra Pradesh', 'lon': '83.9105'},
      '629101': {'lat': '8.4393', 'name': 'Maruthemparai,Kanyakumari,Tamil Nadu', 'lon': '77.3313'},
      '577566': {'lat': '14.1604', 'name': 'Kundawada,Davangere,Karnataka', 'lon': '76.264'},
      '201018': {'lat': '28.6994', 'name': 'Model Town,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '503175': {'lat': '18.2565', 'name': 'Dichpalli RS,Nizamabad,Telangana', 'lon': '78.4353'},
      '224210': {'lat': '26.4416', 'name': 'Kurki Bazar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.609'},
      '829103': {'lat': '23.9122', 'name': 'Barkakana NTS,Ramgarh,Jharkhand', 'lon': '85.5754'},
      '483220': {'lat': '23.2326', 'name': 'Badkheda,Jabalpur,Madhya Pradesh', 'lon': '79.9765'},
      '136156': {'lat': '30.0859', 'name': 'Jandola,Kurukshetra,Haryana', 'lon': '76.9578'},
      '313031': {'lat': '24.5059', 'name': 'Bari Undri,Udaipur,Rajasthan', 'lon': '73.6109'},
      '370040': {'lat': '23.2719', 'name': 'Fotdi,Kachchh,Gujarat', 'lon': '69.6957'},
      '313038': {'lat': '24.183', 'name': 'Samoda,Udaipur,Rajasthan', 'lon': '74.395'},
      '606204': {'lat': '11.6122', 'name': 'Thensiruvalur,Villupuram,Tamil Nadu', 'lon': '79.0082'},
      '606205': {'lat': '11.8356', 'name': 'La- Gudalur,Villupuram,Tamil Nadu', 'lon': '79.0894'},
      '606754': {'lat': '12.1105', 'name': 'Olaipadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.2411'},
      '606755': {'lat': '12.4311', 'name': 'Rajapalayam,Tiruvannamalai,Tamil Nadu', 'lon': '79.1677'},
      '606752': {'lat': '12.4261', 'name': 'Kothandavadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.3137'},
      '606753': {'lat': '12.0768', 'name': 'Thenkarimbalur,Tiruvannamalai,Tamil Nadu', 'lon': '79.0008'},
      '606202': {'lat': '11.6918', 'name': 'Kallakurichi H.O,Villupuram,Tamil Nadu', 'lon': '78.8923'},
      '606751': {'lat': '12.4361', 'name': 'Pathiyavadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.1236'},
      '603004': {'lat': '12.6363', 'name': 'Mahindra World name (Kanchipuram),Kanchipuram,Tamil Nadu', 'lon': '79.9843'},
      '606208': {'lat': '11.8346', 'name': 'Kilapattu,Villupuram,Tamil Nadu', 'lon': '78.9295'},
      '606209': {'lat': '11.7636', 'name': 'Arampoondi,Villupuram,Tamil Nadu', 'lon': '78.6805'},
      '802213': {'lat': '25.1731', 'name': 'Belvaiyan,Rohtas,Bihar', 'lon': '83.8836'},
      '245101': {'lat': '28.7302', 'name': 'Mahmoodpur,Ghaziabad,Uttar Pradesh', 'lon': '77.7767'},
      '209745': {'lat': '25.8733', 'name': 'Nagla Nan,Fatehpur,Uttar Pradesh', 'lon': '80.807'},
      '307001': {'lat': '24.8658', 'name': 'Vellangari,Sirohi,Rajasthan', 'lon': '72.7117'},
      '493992': {'lat': '21.1291', 'name': 'Parsadakala,Raipur,Chattisgarh', 'lon': '82.0833'},
      '209743': {'lat': '27.246', 'name': 'Ugrapur Sultan Patti,Farrukhabad,Uttar Pradesh', 'lon': '79.4816'},
      '508126': {'lat': '17.1362', 'name': 'Bommalaramaram,Nalgonda,Telangana', 'lon': '78.7712'},
      '629001': {'lat': '8.171', 'name': 'Putheri,Kanyakumari,Tamil Nadu', 'lon': '77.4982'},
      '249302': {'lat': '29.9212', 'name': 'Laxmanjhula (B),Pauri Garhwal,Uttarakhand', 'lon': '78.616'},
      '249304': {'lat': '29.9638', 'name': 'Swargashram,Pauri Garhwal,Uttarakhand', 'lon': '78.4974'},
      '209749': {'lat': '27.2945', 'name': 'Bhojpur,Farrukhabad,Uttar Pradesh', 'lon': '79.639'},
      '249306': {'lat': '29.9212', 'name': 'Ganga Bhogpur,Pauri Garhwal,Uttarakhand', 'lon': '78.616'},
      '522265': {'lat': '16.0184', 'name': 'Kannegantivaripalem,Guntur,Andhra Pradesh', 'lon': '80.8296'},
      '522264': {'lat': '15.7114', 'name': 'Singupalem,Guntur,Andhra Pradesh', 'lon': '80.4916'},
      '201013': {'lat': '28.6994', 'name': 'Govindpuram,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '522261': {'lat': '15.192', 'name': 'Varahapuram Agraharam,Guntur,Andhra Pradesh', 'lon': '79.9484'},
      '786008': {'lat': '26.5654', 'name': 'Raja Bheta,Dibrugarh,Assam', 'lon': '94.0443'},
      '425311': {'lat': '20.3591', 'name': 'Waghur Dam Colony,Jalgaon,Maharashtra', 'lon': '76.1007'},
      '522262': {'lat': '15.9554', 'name': 'Kallipalem,Guntur,Andhra Pradesh', 'lon': '80.741'},
      '786005': {'lat': '26.5654', 'name': 'Jalan Nagar,Dibrugarh,Assam', 'lon': '94.0443'},
      '786004': {'lat': '26.2871', 'name': 'Jamirah,Dibrugarh,Assam', 'lon': '93.8884'},
      '786007': {'lat': '26.8437', 'name': 'Dighaliagaon,Dibrugarh,Assam', 'lon': '94.2001'},
      '786006': {'lat': '26.5654', 'name': 'Lepetkata,Dibrugarh,Assam', 'lon': '94.0443'},
      '786001': {'lat': '26.5654', 'name': 'Graham Bazar,Dibrugarh,Assam', 'lon': '94.0443'},
      '522268': {'lat': '15.7114', 'name': 'Yeletipalem,Guntur,Andhra Pradesh', 'lon': '80.4916'},
      '786003': {'lat': '26.5654', 'name': 'Nirmaligaon,Dibrugarh,Assam', 'lon': '94.0443'},
      '786002': {'lat': '26.5654', 'name': 'Assam Medical College,Dibrugarh,Assam', 'lon': '94.0443'},
      '232330': {'lat': '25.4303', 'name': 'Dhanarhi,Ghazipur,Uttar Pradesh', 'lon': '83.7073'},
      '495117': {'lat': '22.6328', 'name': 'Keonchi,Bilaspur(CGH),Chattisgarh', 'lon': '81.7852'},
      '829106': {'lat': '23.9122', 'name': 'Deoria Bargaon,Ramgarh,Jharkhand', 'lon': '85.5754'},
      '743375': {'lat': '22.415', 'name': 'Nazra,South 24 Parganas,West Bengal', 'lon': '88.307'},
      '609608': {'lat': '10.948', 'name': 'Menangudi,Tiruvarur,Tamil Nadu', 'lon': '79.7036'},
      '742147': {'lat': '24.1605', 'name': 'Eroali,Murshidabad,West Bengal', 'lon': '87.9435'},
      '483225': {'lat': '23.6017', 'name': 'Sihora Khitola Bazar,Jabalpur,Madhya Pradesh', 'lon': '80.3788'},
      '491881': {'lat': '21.3234', 'name': 'Deoribhat,Rajnandgaon,Chattisgarh', 'lon': '80.8958'},
      '192121': {'lat': '34.0151', 'name': 'Nehama,Ananthnag,Jammu & Kashmir', 'lon': '74.919'},
      '843301': {'lat': '26.0372', 'name': 'Shaktipith Chandidham,Sitamarhi,Bihar', 'lon': '85.6152'},
      '192123': {'lat': '33.9306', 'name': 'Haripari,Pulwama,Jammu & Kashmir', 'lon': '75.1134'},
      '192122': {'lat': '33.9942', 'name': 'Cheersoo,Ananthnag,Jammu & Kashmir', 'lon': '74.9835'},
      '192125': {'lat': '34.0397', 'name': 'Gopal Pora,Ananthnag,Jammu & Kashmir', 'lon': '74.7612'},
      '192124': {'lat': '34.1151', 'name': 'Qaimoh,Kulgam,Jammu & Kashmir', 'lon': '74.8647'},
      '192126': {'lat': '33.9942', 'name': 'Laripora,Ananthnag,Jammu & Kashmir', 'lon': '74.9835'},
      '192129': {'lat': '33.8312', 'name': 'Chandrigam,Ananthnag,Jammu & Kashmir', 'lon': '75.3407'},
      '765025': {'lat': '19.2197', 'name': 'Guluguda,Rayagada,Odisha', 'lon': '83.7097'},
      '765026': {'lat': '19.508', 'name': 'Badapankal,Rayagada,Odisha', 'lon': '83.7735'},
      '736203': {'lat': '25.5088', 'name': 'Kumargramduar,Alipurduar,West Bengal', 'lon': '88.582'},
      '765020': {'lat': '19.7408', 'name': 'M.Patraguda,Rayagada,Odisha', 'lon': '83.7136'},
      '765021': {'lat': '19.8552', 'name': 'Raghubari,Rayagada,Odisha', 'lon': '83.4544'},
      '765022': {'lat': '19.1031', 'name': 'Kharlingi,Rayagada,Odisha', 'lon': '83.9038'},
      '765023': {'lat': '19.4887', 'name': 'Omadingi,Rayagada,Odisha', 'lon': '83.5238'},
      '744112': {'lat': '11.6647', 'name': 'Chakragaon,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7026'},
      '803302': {'lat': '25.3988', 'name': 'Chintamanchak,Patna,Bihar', 'lon': '86.0222'},
      '486670': {'lat': '24.5521', 'name': 'Kulakwar,Sidhi,Madhya Pradesh', 'lon': '81.6543'},
      '515231': {'lat': '14.0352', 'name': 'Karavulapalli,Ananthapur,Andhra Pradesh', 'lon': '78.0086'},
      '486675': {'lat': '24.4286', 'name': 'Khuteli,Sidhi,Madhya Pradesh', 'lon': '81.9891'},
      '182161': {'lat': '33.0523', 'name': 'Mantalai,Udhampur,Jammu & Kashmir', 'lon': '75.1451'},
      '262561': {'lat': '29.444', 'name': 'Sango,Champawat,Uttarakhand', 'lon': '79.9571'},
      '711322': {'lat': '22.942', 'name': 'Gabberia,Howrah,West Bengal', 'lon': '88.2746'},
      '301403': {'lat': '27.8323', 'name': 'Patalia,Alwar,Rajasthan', 'lon': '76.6184'},
      '471201': {'lat': '24.8566', 'name': 'Tidani,Chhatarpur,Madhya Pradesh', 'lon': '79.357'},
      '686608': {'lat': '9.7977', 'name': 'Maravanthuruthu,Kottayam,Kerala', 'lon': '76.3956'},
      '686609': {'lat': '9.7499', 'name': 'Mevelloor,Kottayam,Kerala', 'lon': '76.4428'},
      '400093': {'lat': '19.2361', 'name': 'Chakala Midc,Mumbai,Maharashtra', 'lon': '72.8338'},
      '400092': {'lat': '18.9596', 'name': 'Borivali West,Mumbai,Maharashtra', 'lon': '72.8496'},
      '400095': {'lat': '19.2361', 'name': 'Kharodi,Mumbai,Maharashtra', 'lon': '72.8338'},
      '400094': {'lat': '19.2361', 'name': 'Anushakti Nagar,Mumbai,Maharashtra', 'lon': '72.8338'},
      '400097': {'lat': '19.2361', 'name': 'Rani Sati Marg,Mumbai,Maharashtra', 'lon': '72.8338'},
      '400096': {'lat': '19.2361', 'name': 'Seepz,Mumbai,Maharashtra', 'lon': '72.8338'},
      '400099': {'lat': '19.2361', 'name': 'Airport (Mumbai),Mumbai,Maharashtra', 'lon': '72.8338'},
      '686601': {'lat': '9.6851', 'name': 'Moozhikulangara,Kottayam,Kerala', 'lon': '76.5057'},
      '686602': {'lat': '9.6664', 'name': 'Onamthuruthu,Kottayam,Kerala', 'lon': '76.5047'},
      '686603': {'lat': '9.7499', 'name': 'Chamakala,Kottayam,Kerala', 'lon': '76.4428'},
      '686604': {'lat': '9.75', 'name': 'Arunoottimangalam,Kottayam,Kerala', 'lon': '76.5'},
      '686605': {'lat': '9.779', 'name': 'Brahmamangalam,Kottayam,Kerala', 'lon': '76.4503'},
      '686606': {'lat': '9.7261', 'name': 'Pallippurathussery,Kottayam,Kerala', 'lon': '76.4065'},
      '686607': {'lat': '9.7119', 'name': 'Kothavara,Kottayam,Kerala', 'lon': '76.4255'},
      '844124': {'lat': '25.7241', 'name': 'Panapur Langa,Vaishali,Bihar', 'lon': '85.2908'},
      '244504': {'lat': '28.9369', 'name': 'Modha,Moradabad,Uttar Pradesh', 'lon': '78.717'},
      '244501': {'lat': '29.0201', 'name': 'Umari Kalan,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.5556'},
      '829105': {'lat': '23.6072', 'name': 'Bhadaninagar,Ramgarh,Jharkhand', 'lon': '85.3902'},
      '210001': {'lat': '25.5228', 'name': 'Banda Aliganj,Banda,Uttar Pradesh', 'lon': '80.5372'},
      '140604': {'lat': '30.7093', 'name': 'Baltana,Mohali,Punjab', 'lon': '76.4448'},
      '140603': {'lat': '30.6562', 'name': 'Zirakpur,Mohali,Punjab', 'lon': '76.8209'},
      '140602': {'lat': '30.5803', 'name': 'Khera Gajju,Patiala,Punjab', 'lon': '76.6309'},
      '382230': {'lat': '22.815', 'name': 'Sargwala,Ahmedabad,Gujarat', 'lon': '72.3099'},
      '506007': {'lat': '18.0142', 'name': 'Agricultur Research Station,Warangal,Telangana', 'lon': '79.5184'},
      '811112': {'lat': '25.2146', 'name': 'Loshghani,Lakhisarai,Bihar', 'lon': '86.3198'},
      '224159': {'lat': '26.1954', 'name': 'Makhdoom Baramadpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.576'},
      '581129': {'lat': '14.8811', 'name': 'Jagalbet,Uttara Kannada,Karnataka', 'lon': '75.3986'},
      '581128': {'lat': '14.8168', 'name': 'Anandavan,Haveri,Karnataka', 'lon': '75.4683'},
      '581121': {'lat': '14.8811', 'name': 'Bazarkunang,Uttara Kannada,Karnataka', 'lon': '75.3986'},
      '581120': {'lat': '14.6165', 'name': 'Sudambi,Haveri,Karnataka', 'lon': '75.3022'},
      '581123': {'lat': '15.4365', 'name': 'Kumarapattanam,Haveri,Karnataka', 'lon': '75.8539'},
      '581126': {'lat': '15.0691', 'name': 'Shishuvinhal,Haveri,Karnataka', 'lon': '75.3066'},
      '631606': {'lat': '12.7525', 'name': 'Madhur,Kanchipuram,Tamil Nadu', 'lon': '79.8573'},
      '631604': {'lat': '12.5905', 'name': 'Thenneri,Kanchipuram,Tamil Nadu', 'lon': '79.7827'},
      '631605': {'lat': '12.7219', 'name': 'Uttukadu,Kanchipuram,Tamil Nadu', 'lon': '79.5794'},
      '631603': {'lat': '12.7244', 'name': 'Neyyadupakkam,Kanchipuram,Tamil Nadu', 'lon': '79.7865'},
      '248165': {'lat': '30.6225', 'name': 'Ubhreau,Dehradun,Uttarakhand', 'lon': '77.833'},
      '631601': {'lat': '13.0198', 'name': 'Naickenpet,Kanchipuram,Tamil Nadu', 'lon': '79.8756'},
      '814116': {'lat': '24.3507', 'name': 'Satsang,Deoghar,Jharkhand', 'lon': '87.105'},
      '573131': {'lat': '13.063', 'name': 'Anathi,Hassan,Karnataka', 'lon': '76.3739'},
      '787060': {'lat': '27.7246', 'name': 'Ramdhan Dikhari,Dhemaji,Assam', 'lon': '94.8545'},
      '787061': {'lat': '27.7246', 'name': 'Talem,Dhemaji,Assam', 'lon': '94.8545'},
      '210504': {'lat': '25.3443', 'name': 'Khanna,Mahoba,Uttar Pradesh', 'lon': '79.6966'},
      '210505': {'lat': '25.964', 'name': 'Jhalokhar,Hamirpur,Uttar Pradesh', 'lon': '80.0251'},
      '210506': {'lat': '25.6529', 'name': 'Neoria,Hamirpur,Uttar Pradesh', 'lon': '79.9398'},
      '210507': {'lat': '25.8368', 'name': 'Gyondi,Mahoba,Uttar Pradesh', 'lon': '80.1316'},
      '210501': {'lat': '25.789', 'name': 'Ruripara,Hamirpur,Uttar Pradesh', 'lon': '79.9241'},
      '210502': {'lat': '25.9754', 'name': 'Surauli Bzg.,Hamirpur,Uttar Pradesh', 'lon': '80.0176'},
      '402207': {'lat': '18.7108577', 'name': 'Thal (Raigarh(MH)),Raigarh(MH),Maharashtra', 'lon': '72.9147515'},
      '402204': {'lat': '17.97685', 'name': 'Nagaon,Raigarh(MH),Maharashtra', 'lon': '73.3952'},
      '743368': {'lat': '22.4328', 'name': 'Panchagram Singhberia,South 24 Parganas,West Bengal', 'lon': '88.2677'},
      '402202': {'lat': '18.655496', 'name': 'Walke,Raigarh(MH),Maharashtra', 'lon': '72.867915'},
      '402203': {'lat': '18.5692531', 'name': 'Agrav,Raigarh(MH),Maharashtra', 'lon': '72.9286788'},
      '402201': {'lat': '18.658041', 'name': 'Alibag H.O,Raigarh(MH),Maharashtra', 'lon': '72.8885158'},
      '743373': {'lat': '23.3153', 'name': 'Dakshin Haradhanpur,South 24 Parganas,West Bengal', 'lon': '88.0233'},
      '402208': {'lat': '18.34398', 'name': 'Saswane,Raigarh(MH),Maharashtra', 'lon': '73.25729'},
      '402209': {'lat': '18.34398', 'name': 'Kurul RCF Colony,Raigarh(MH),Maharashtra', 'lon': '73.25729'},
      '845451': {'lat': '27.114', 'name': 'Maldi,West Champaran,Bihar', 'lon': '84.5082'},
      '758080': {'lat': '21.2284', 'name': 'Chilikdhara,Kendujhar,Odisha', 'lon': '85.3083'},
      '466001': {'lat': '22.8855', 'name': 'Janpur Bavdiya,Sehore,Madhya Pradesh', 'lon': '77.4189'},
      '637002': {'lat': '11.0962', 'name': 'Vasanthapuram,Namakkal,Tamil Nadu', 'lon': '78.2363'},
      '488001': {'lat': '24.4862', 'name': 'Dwari,Panna,Madhya Pradesh', 'lon': '80.0586'},
      '518452': {'lat': '15.6272', 'name': 'Nandanapalli,Kurnool,Andhra Pradesh', 'lon': '78.1282'},
      '396310': {'lat': '22.4571', 'name': 'Dhamdachha,Navsari,Gujarat', 'lon': '72.7655'},
      '743363': {'lat': '22.4854', 'name': 'Gourdaha,South 24 Parganas,West Bengal', 'lon': '88.5638'},
      '627008': {'lat': '8.5335', 'name': 'Gandhinagar (Tirunelveli),Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '191102': {'lat': '34.0246', 'name': 'Wuyan,Srinagar,Jammu & Kashmir', 'lon': '74.966'},
      '191103': {'lat': '34.0333', 'name': 'Mandakpal,Ananthnag,Jammu & Kashmir', 'lon': '74.9251'},
      '191101': {'lat': '34.0377', 'name': 'Khanmouh,Srinagar,Jammu & Kashmir', 'lon': '74.9046'},
      '811304': {'lat': '25.0374', 'name': 'Chordargah,Sheikhpura,Bihar', 'lon': '85.891'},
      '441225': {'lat': '20.1039', 'name': 'Kawathi,Chandrapur,Maharashtra', 'lon': '79.8057'},
      '441224': {'lat': '20.1179', 'name': 'Maroda,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '441226': {'lat': '20.1179', 'name': 'Futana,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '441221': {'lat': '20.4793', 'name': 'Kitali Bormal,Chandrapur,Maharashtra', 'lon': '79.5941'},
      '441223': {'lat': '20.342', 'name': 'Antargaon,Chandrapur,Maharashtra', 'lon': '79.6093'},
      '441222': {'lat': '20.2962', 'name': 'Kalamgaon Ganna,Chandrapur,Maharashtra', 'lon': '79.6382'},
      '721646': {'lat': '22.1594', 'name': 'Kalicharanpur,East Midnapore,West Bengal', 'lon': '88.3792'},
      '627003': {'lat': '8.5335', 'name': 'Vannarpettai,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '141801': {'lat': '30.2814', 'name': 'Manela,Patiala,Punjab', 'lon': '76.3539'},
      '754160': {'lat': '20.3493', 'name': 'Dagarpada,Jagatsinghapur,Odisha', 'lon': '85.803'},
      '754162': {'lat': '20.4503', 'name': 'Kolar,Jagatsinghapur,Odisha', 'lon': '83.0484'},
      '734123': {'lat': '27.051', 'name': 'Rangbull,Darjiling,West Bengal', 'lon': '88.2618'},
      '535463': {'lat': '18.5836', 'name': 'Chinagudaba,Vizianagaram,Andhra Pradesh', 'lon': '83.4408'},
      '173234': {'lat': '31.0011', 'name': 'Domeharbani,Solan,Himachal Pradesh', 'lon': '77.0814'},
      '734121': {'lat': '27.051', 'name': 'Dooteriah,Darjiling,West Bengal', 'lon': '88.2618'},
      '679309': {'lat': '10.9022', 'name': 'Vilayur West,Palakkad,Kerala', 'lon': '76.1638'},
      '679308': {'lat': '10.7591', 'name': 'Etapalam,Palakkad,Kerala', 'lon': '76.255'},
      '679307': {'lat': '10.8581', 'name': 'Kilmuri,Palakkad,Kerala', 'lon': '76.1698'},
      '679306': {'lat': '10.8166', 'name': 'Melepattambi,Palakkad,Kerala', 'lon': '76.2161'},
      '679305': {'lat': '10.8208', 'name': 'Cherukutangad,Palakkad,Kerala', 'lon': '76.2022'},
      '679304': {'lat': '10.8605', 'name': 'Vilathur,Palakkad,Kerala', 'lon': '76.1171'},
      '679303': {'lat': '10.8152', 'name': 'Muduthala,Palakkad,Kerala', 'lon': '76.173'},
      '534227': {'lat': '16.7866', 'name': 'Mortha,West Godavari,Andhra Pradesh', 'lon': '81.7228'},
      '679301': {'lat': '10.7562', 'name': 'Lakkidi,Palakkad,Kerala', 'lon': '76.4369'},
      '534225': {'lat': '16.7167', 'name': 'Gummampadu,West Godavari,Andhra Pradesh', 'lon': '81.6667'},
      '571122': {'lat': '12.1989', 'name': 'Kukkur,Mysuru,Karnataka', 'lon': '77.0222'},
      '121001': {'lat': '28.4873', 'name': 'N.I.F.M. Faridabad,Faridabad,Haryana', 'lon': '77.1724'},
      '121002': {'lat': '28.4172', 'name': 'Faridabad Sector 16a ,Faridabad,Haryana', 'lon': '77.393'},
      '121003': {'lat': '28.4651', 'name': 'Amarnagar (Faridabad),Faridabad,Haryana', 'lon': '77.3329'},
      '121004': {'lat': '28.5021', 'name': 'Chhainsa,Faridabad,Haryana', 'lon': '77.1177'},
      '121005': {'lat': '28.4873', 'name': 'Faridabad Sector 22,Faridabad,Haryana', 'lon': '77.1724'},
      '121006': {'lat': '28.4873', 'name': 'G.T. Road Faridabad,Faridabad,Haryana', 'lon': '77.1724'},
      '125058': {'lat': '29.4765', 'name': 'Mochiwali,Sirsa,Haryana', 'lon': '75.2551'},
      '121008': {'lat': '28.4873', 'name': 'Faridabad Sector 29,Faridabad,Haryana', 'lon': '77.1724'},
      '121009': {'lat': '28.4873', 'name': 'Surajkund Faridabad,Faridabad,Haryana', 'lon': '77.1724'},
      '125055': {'lat': '29.4246', 'name': 'Majudin,Sirsa,Haryana', 'lon': '75.3722'},
      '125054': {'lat': '29.5202', 'name': 'Manjui Khera,Sirsa,Haryana', 'lon': '75.28'},
      '125053': {'lat': '29.3462', 'name': 'Ban Mandori,Fatehabad,Haryana', 'lon': '75.3221'},
      '125052': {'lat': '29.2523', 'name': 'Daroli,Hisar,Haryana', 'lon': '75.5061'},
      '125051': {'lat': '29.5927', 'name': 'Marh,Hisar,Haryana', 'lon': '75.5162'},
      '125050': {'lat': '29.4899', 'name': 'Nai Mandi Fatehabad,Fatehabad,Haryana', 'lon': '75.448'},
      '466331': {'lat': '22.6692', 'name': 'Dhoulpur,Sehore,Madhya Pradesh', 'lon': '77.1847'},
      '795010': {'lat': '24.4065', 'name': 'Yourbung,Imphal East,Manipur', 'lon': '93.3452'},
      '500107': {'lat': '17.5902', 'name': 'Gopanpally,Hyderabad,Telangana', 'lon': '78.6668'},
      '134114': {'lat': '30.4839', 'name': 'Mansa  Devi Sec-5,Panchkula,Haryana', 'lon': '76.8972'},
      '134116': {'lat': '30.4839', 'name': 'Sector-26 Panchkula,Panchkula,Haryana', 'lon': '76.8972'},
      '134117': {'lat': '30.4839', 'name': 'Sector 20 Panchkula,Panchkula,Haryana', 'lon': '76.8972'},
      '134112': {'lat': '30.4839', 'name': 'Panchkula Sector 4,Panchkula,Haryana', 'lon': '76.8972'},
      '134113': {'lat': '30.4839', 'name': 'Industrial Estate  Panchkula,Panchkula,Haryana', 'lon': '76.8972'},
      '341022': {'lat': '27.338', 'name': 'Burdi,Nagaur,Rajasthan', 'lon': '74.3306'},
      '341023': {'lat': '27.1803', 'name': 'Gudha Jodhan,Nagaur,Rajasthan', 'lon': '74.1965'},
      '341021': {'lat': '27.1723', 'name': 'Raidhanu,Nagaur,Rajasthan', 'lon': '73.6452'},
      '134118': {'lat': '30.4839', 'name': 'Bhareli,Ambala,Haryana', 'lon': '76.8972'},
      '341027': {'lat': '27.165', 'name': 'Khanwar,Nagaur,Rajasthan', 'lon': '73.9281'},
      '341024': {'lat': '27.0459', 'name': 'Bachhwari,Nagaur,Rajasthan', 'lon': '74.4696'},
      '142047': {'lat': '31.0406', 'name': 'Chuchak Wind,Firozpur,Punjab', 'lon': '74.9059'},
      '680501': {'lat': '10.4712', 'name': 'Eranellur,Thrissur,Kerala', 'lon': '76.3162'},
      '480661': {'lat': '22.0355', 'name': 'Mugwani Kala,Seoni,Madhya Pradesh', 'lon': '79.5234'},
      '331301': {'lat': '28.5685', 'name': 'Kalari,Churu,Rajasthan', 'lon': '75.4976'},
      '331302': {'lat': '28.8143', 'name': 'Somasisar,Churu,Rajasthan', 'lon': '74.8457'},
      '331303': {'lat': '28.2161', 'name': 'Sankhan Tal,Churu,Rajasthan', 'lon': '75.6756'},
      '331304': {'lat': '28.5421', 'name': 'Jigsana Tal,Churu,Rajasthan', 'lon': '75.0065'},
      '331305': {'lat': '28.4658', 'name': 'Bhamreed,Churu,Rajasthan', 'lon': '75.5294'},
      '274207': {'lat': '26.107', 'name': 'Naumunda,Kushinagar,Uttar Pradesh', 'lon': '83.0087'},
      '483001': {'lat': '23.1065', 'name': 'Umaria Kudari,Jabalpur,Madhya Pradesh', 'lon': '80.116'},
      '680502': {'lat': '10.697', 'name': 'Choondal,Thrissur,Kerala', 'lon': '76.0648'},
      '484447': {'lat': '23.7942', 'name': 'Chukan,Anuppur,Madhya Pradesh', 'lon': '81.35'},
      '272171': {'lat': '26.6712', 'name': 'Gonare,Basti,Uttar Pradesh', 'lon': '82.8513'},
      '686651': {'lat': '9.7408', 'name': 'Ezhacherry,Kottayam,Kerala', 'lon': '76.7725'},
      '391320': {'lat': '22.854', 'name': 'Jawahar Nagar (Vadodara),Vadodara,Gujarat', 'lon': '73.4677'},
      '788723': {'lat': '24.6949', 'name': 'Basudevnagar,Karimganj,Assam', 'lon': '92.3182'},
      '788720': {'lat': '24.6949', 'name': 'Chaitanyanagar,Karimganj,Assam', 'lon': '92.3182'},
      '416712': {'lat': '21.4937', 'name': 'Koldhe,Ratnagiri,Maharashtra', 'lon': '77.0805'},
      '788726': {'lat': '24.6949', 'name': 'Katabari,Karimganj,Assam', 'lon': '92.3182'},
      '680507': {'lat': '10.5594', 'name': 'Pavaratty,Thrissur,Kerala', 'lon': '76.0495'},
      '788724': {'lat': '24.6181', 'name': 'Patharkandi Anchalik,Karimganj,Assam', 'lon': '92.3378'},
      '788725': {'lat': '24.6949', 'name': 'Tibhum,Karimganj,Assam', 'lon': '92.3182'},
      '313205': {'lat': '24.4214', 'name': 'Vasni Kalan,Udaipur,Rajasthan', 'lon': '74.0211'},
      '160020': {'lat': '30.7465', 'name': 'Sector 20 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7417'},
      '788728': {'lat': '24.6949', 'name': 'Manikbond,Karimganj,Assam', 'lon': '92.3182'},
      '680506': {'lat': '10.5618', 'name': 'Blangad,Thrissur,Kerala', 'lon': '76.0222'},
      '160025': {'lat': '30.7465', 'name': 'Maloya Colony,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '313203': {'lat': '24.4858', 'name': 'Phalichada,Udaipur,Rajasthan', 'lon': '73.9144'},
      '313202': {'lat': '24.7375', 'name': 'Shishvi,Rajsamand,Rajasthan', 'lon': '73.7257'},
      '211019': {'lat': '25.4166', 'name': 'Nibi Kalan,Allahabad,Uttar Pradesh', 'lon': '81.9418'},
      '211018': {'lat': '25.5625', 'name': 'Public Service Commission,Allahabad,Uttar Pradesh', 'lon': '81.7615'},
      '833201': {'lat': '22.4786', 'name': 'Bari Bazar,West Singhbhum,Jharkhand', 'lon': '85.8154'},
      '211011': {'lat': '25.5625', 'name': 'Rajrooppur,Allahabad,Uttar Pradesh', 'lon': '81.7615'},
      '211010': {'lat': '25.4671', 'name': 'I T I,36,Naini', 'lon': '81.83725'},
      '211013': {'lat': '25.8695', 'name': 'Sewaith,Allahabad,Uttar Pradesh', 'lon': '81.6505'},
      '211012': {'lat': '25.4524', 'name': 'Bamhrauli Uparhar,Allahabad,Uttar Pradesh', 'lon': '81.739'},
      '384120': {'lat': '23.7027', 'name': 'Butta Paldi,Mahesana,Gujarat', 'lon': '72.3869'},
      '211014': {'lat': '25.5625', 'name': 'C D A (P),Allahabad,Uttar Pradesh', 'lon': '81.7615'},
      '211017': {'lat': '25.5625', 'name': 'Allahabad High Court,Allahabad,Uttar Pradesh', 'lon': '81.7615'},
      '211016': {'lat': '25.5625', 'name': 'G T B Nagar,Allahabad,Uttar Pradesh', 'lon': '81.7615'},
      '415620': {'lat': '17.2877', 'name': 'Kespuri,Ratnagiri,Maharashtra', 'lon': '73.3626'},
      '415621': {'lat': '17.2877', 'name': 'Veral,Ratnagiri,Maharashtra', 'lon': '73.3626'},
      '516321': {'lat': '14.4536', 'name': 'Yerramalapalle,Cuddapah,Andhra Pradesh', 'lon': '78.4625'},
      '123034': {'lat': '28.3732', 'name': 'Sundrah,Rewari,Haryana', 'lon': '76.8881'},
      '415626': {'lat': '17.2877', 'name': 'Dorle,Ratnagiri,Maharashtra', 'lon': '73.3626'},
      '415628': {'lat': '17.5245', 'name': 'Omali,Ratnagiri,Maharashtra', 'lon': '73.4341'},
      '415629': {'lat': '17.0509', 'name': 'Kasarveli,Ratnagiri,Maharashtra', 'lon': '73.291'},
      '516329': {'lat': '14.3914', 'name': 'Vaimpalle,Cuddapah,Andhra Pradesh', 'lon': '78.4803'},
      '533234': {'lat': '16.936', 'name': 'Seethanagaram,East Godavari,Andhra Pradesh', 'lon': '81.8973'},
      '533235': {'lat': '16.7053', 'name': 'Pulidindi,East Godavari,Andhra Pradesh', 'lon': '81.5881'},
      '533236': {'lat': '17.4008', 'name': 'Laxmi Polavaram,East Godavari,Andhra Pradesh', 'lon': '82.3102'},
      '533237': {'lat': '16.936', 'name': 'Vadapalli,East Godavari,Andhra Pradesh', 'lon': '81.8973'},
      '731127': {'lat': '23.592', 'name': 'Sarenda,Birbhum,West Bengal', 'lon': '87.8073'},
      '533232': {'lat': '17.293', 'name': 'Narsipudi,East Godavari,Andhra Pradesh', 'lon': '82.2794'},
      '533233': {'lat': '16.7964', 'name': 'Jonnada,East Godavari,Andhra Pradesh', 'lon': '81.8624'},
      '224153': {'lat': '26.2131', 'name': 'Khandasa,Faizabad,Uttar Pradesh', 'lon': '82.0735'},
      '472246': {'lat': '25.4328', 'name': 'Barsoba,Tikamgarh,Madhya Pradesh', 'lon': '78.6695'},
      '533238': {'lat': '17.0175', 'name': 'Ithakota,East Godavari,Andhra Pradesh', 'lon': '81.9797'},
      '533239': {'lat': '16.936', 'name': 'Irusumanda,East Godavari,Andhra Pradesh', 'lon': '81.8973'},
      '610105': {'lat': '10.8923', 'name': 'Nannilam Cutchery,Tiruvarur,Tamil Nadu', 'lon': '79.5912'},
      '332406': {'lat': '27.5311', 'name': 'Jeenwas,Sikar,Rajasthan', 'lon': '75.3191'},
      '394310': {'lat': '21.1553', 'name': 'Haldharu,Surat,Gujarat', 'lon': '73.0273'},
      '394317': {'lat': '21.119', 'name': 'Vadadla Tanti,Surat,Gujarat', 'lon': '73.0068'},
      '394315': {'lat': '21.0827', 'name': 'Taraj,Surat,Gujarat', 'lon': '72.9862'},
      '756138': {'lat': '20.6016', 'name': 'Batula,Bhadrak,Odisha', 'lon': '85.0933'},
      '756139': {'lat': '21.3205', 'name': 'Arasa,Bhadrak,Odisha', 'lon': '85.4411'},
      '756130': {'lat': '20.8847', 'name': 'Bahabalpur,Bhadrak,Odisha', 'lon': '85.2239'},
      '756131': {'lat': '20.71', 'name': 'Tiadisahi,Bhadrak,Odisha', 'lon': '85.2004'},
      '756132': {'lat': '20.5154', 'name': 'Nalagohira,Bhadrak,Odisha', 'lon': '85.1007'},
      '756133': {'lat': '21.0699', 'name': 'Bouljoda,Bhadrak,Odisha', 'lon': '85.4671'},
      '585437': {'lat': '17.5846', 'name': 'Maisalga,Bidar,Karnataka', 'lon': '76.9747'},
      '585436': {'lat': '18.1391', 'name': 'Bedkunda,Bidar,Karnataka', 'lon': '77.371'},
      '756137': {'lat': '19.5332', 'name': 'Kasimpur,Bhadrak,Odisha', 'lon': '84.7957'},
      '246169': {'lat': '29.7868', 'name': 'Pokhra (A),Pauri Garhwal,Uttarakhand', 'lon': '78.9456'},
      '370455': {'lat': '22.831', 'name': 'Mota Bhadia,Kachchh,Gujarat', 'lon': '69.4845'},
      '391776': {'lat': '23.1355', 'name': 'Manjusar GIDC,Vadodara,Gujarat', 'lon': '73.3523'},
      '370450': {'lat': '22.831', 'name': 'Dedhiya,Kachchh,Gujarat', 'lon': '69.4845'},
      '577245': {'lat': '14.0436', 'name': 'Sunnadahalli,Shivamogga,Karnataka', 'lon': '75.2207'},
      '388150': {'lat': '22.8201', 'name': 'Manaj,Anand,Gujarat', 'lon': '72.8878'},
      '209502': {'lat': '25.8733', 'name': 'Lahra,Fatehpur,Uttar Pradesh', 'lon': '80.807'},
      '577243': {'lat': '15.2212', 'name': 'Mydolalau,Shivamogga,Karnataka', 'lon': '76.2965'},
      '627862': {'lat': '8.3718', 'name': 'Kilavirasigamani,Tirunelveli,Tamil Nadu', 'lon': '77.9141'},
      '506343': {'lat': '18.3563', 'name': 'Inchencherupalli,Warangal,Telangana', 'lon': '79.0311'},
      '506344': {'lat': '18.7441', 'name': 'Chalvai,Warangal,Telangana', 'lon': '79.6039'},
      '506345': {'lat': '17.87065', 'name': 'Palampet,Jayashankar,Telangana', 'lon': '79.28255'},
      '453551': {'lat': '22.9254', 'name': 'Kudana,Indore,Madhya Pradesh', 'lon': '75.8284'},
      '721167': {'lat': '22.3015', 'name': 'Dalpara,East Midnapore,West Bengal', 'lon': '87.6162'},
      '721166': {'lat': '22.242', 'name': 'Narma,West Midnapore,West Bengal', 'lon': '87.6211'},
      '721161': {'lat': '22.3015', 'name': 'Mohar,West Midnapore,West Bengal', 'lon': '87.6162'},
      '721160': {'lat': '22.361', 'name': 'Duan,West Midnapore,West Bengal', 'lon': '87.6112'},
      '453556': {'lat': '22.861', 'name': 'IIM CAMPUS RAO,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '210301': {'lat': '25.9195', 'name': 'Pauthia Bujurg,Hamirpur,Uttar Pradesh', 'lon': '80.156'},
      '506347': {'lat': '18.2727', 'name': 'Bittupalli,Warangal,Telangana', 'lon': '80.1836'},
      '484444': {'lat': '23.5531', 'name': 'Harad,Anuppur,Madhya Pradesh', 'lon': '81.1552'},
      '713362': {'lat': '24.3192', 'name': 'Hijalgora,Purba Bardhaman,West Bengal', 'lon': '87.7925'},
      '713363': {'lat': '23.3452', 'name': 'Ukhra Puratanhattala,Purba Bardhaman,West Bengal', 'lon': '87.899'},
      '713360': {'lat': '23.54', 'name': 'Chinakuri,Paschim Bardhaman,West Bengal', 'lon': '87.8777'},
      '713361': {'lat': '23.54', 'name': 'Suryanagar (Bardhaman),Paschim Bardhaman,West Bengal', 'lon': '87.8777'},
      '507163': {'lat': '17.333', 'name': 'Edulapuram,Khammam,Telangana', 'lon': '79.8203'},
      '507161': {'lat': '17.6227', 'name': 'Bachodu,Khammam,Telangana', 'lon': '79.1825'},
      '713365': {'lat': '23.54', 'name': 'Chittaranjan TS,Paschim Bardhaman,West Bengal', 'lon': '87.8777'},
      '713369': {'lat': '23.54', 'name': 'Kalyaneshwari,Paschim Bardhaman,West Bengal', 'lon': '87.8777'},
      '504295': {'lat': '19.4797', 'name': 'Wankidi,Adilabad,Telangana', 'lon': '79.3037'},
      '507169': {'lat': '17.0884', 'name': 'Buddaram,Khammam,Telangana', 'lon': '79.4494'},
      '507168': {'lat': '17.2368', 'name': 'Timmaraopeta,Khammam,Telangana', 'lon': '80.8769'},
      '641667': {'lat': '10.9726', 'name': 'Ganganaickenpalayam,Coimbatore,Tamil Nadu', 'lon': '77.5795'},
      '431002': {'lat': '19.4368', 'name': 'Gevarai,Aurangabad,Maharashtra', 'lon': '75.9614'},
      '641665': {'lat': '11.0076', 'name': 'Kandiankoil,Coimbatore,Tamil Nadu', 'lon': '77.4417'},
      '370230': {'lat': '22.9103', 'name': 'Kandla Free Trade Zone,Kachchh,Gujarat', 'lon': '70.2266'},
      '641663': {'lat': '11.0724', 'name': '63 Velampalayam,Coimbatore,Tamil Nadu', 'lon': '77.198'},
      '641662': {'lat': '11.0158', 'name': 'Kodangipalayam,Coimbatore,Tamil Nadu', 'lon': '77.2338'},
      '431003': {'lat': '19.705', 'name': 'Cidco Colony (Aurangabad),Aurangabad,Maharashtra', 'lon': '75.7394'},
      '460004': {'lat': '22.2095', 'name': 'Betul Bazaar,Betul,Madhya Pradesh', 'lon': '78.2014'},
      '460557': {'lat': '21.7511', 'name': 'Goula,Betul,Madhya Pradesh', 'lon': '77.9755'},
      '460554': {'lat': '21.9667', 'name': 'Kalmeshwara,Betul,Madhya Pradesh', 'lon': '77.5228'},
      '577126': {'lat': '13.4848', 'name': 'Melukoppa,Chikkamagaluru,Karnataka', 'lon': '75.416'},
      '641669': {'lat': '11.1416', 'name': 'Purandampalayam,Coimbatore,Tamil Nadu', 'lon': '77.3307'},
      '641668': {'lat': '11.0845', 'name': 'Semmandampalayam,Coimbatore,Tamil Nadu', 'lon': '77.1891'},
      '500012': {'lat': '17.4498', 'name': 'Begumbazar,Hyderabad,Telangana', 'lon': '78.5553'},
      '431001': {'lat': '19.705', 'name': 'Fazalpura,Aurangabad,Maharashtra', 'lon': '75.7394'},
      '581338': {'lat': '14.373', 'name': 'Hodkeshirur,Uttara Kannada,Karnataka', 'lon': '74.4455'},
      '581339': {'lat': '14.8611', 'name': 'Wailwada,Uttara Kannada,Karnataka', 'lon': '74.2086'},
      '581334': {'lat': '14.3149', 'name': 'Samsi,Uttara Kannada,Karnataka', 'lon': '74.4776'},
      '431006': {'lat': '19.705', 'name': 'Chikalthana Industrial Area,Aurangabad,Maharashtra', 'lon': '75.7394'},
      '382007': {'lat': '23.1505', 'name': 'Raysan,Gandhi Nagar,Gujarat', 'lon': '72.6399'},
      '382006': {'lat': '23.1505', 'name': '(Gandhinagar) Sector 6,Gandhi Nagar,Gujarat', 'lon': '72.6399'},
      '581330': {'lat': '14.472', 'name': 'Lukkeri,Uttara Kannada,Karnataka', 'lon': '74.408'},
      '581331': {'lat': '14.4928', 'name': 'Kanchikai,Uttara Kannada,Karnataka', 'lon': '74.7881'},
      '581332': {'lat': '14.4054', 'name': 'Kalbag,Uttara Kannada,Karnataka', 'lon': '74.4052'},
      '431007': {'lat': '19.235', 'name': 'Chittepimpalgaon,Aurangabad,Maharashtra', 'lon': '75.7591'},
      '360575': {'lat': '21.61', 'name': 'Porbandar M G Road,Porbandar,Gujarat', 'lon': '69.6824'},
      '458558': {'lat': '23.8749', 'name': 'Vishnia Dak Bunglow,Mandsaur,Madhya Pradesh', 'lon': '75.7559'},
      '360577': {'lat': '21.61', 'name': 'Porbandar G.I.D.C.,Porbandar,Gujarat', 'lon': '69.6824'},
      '360576': {'lat': '21.4853', 'name': 'Erda,Porbandar,Gujarat', 'lon': '69.7991'},
      '360570': {'lat': '21.6084', 'name': 'Bapodar,Porbandar,Gujarat', 'lon': '69.7871'},
      '247001': {'lat': '29.461', 'name': 'Tiwaya,Saharanpur,Uttar Pradesh', 'lon': '77.949'},
      '431005': {'lat': '19.705', 'name': 'High Court Building (Aurangabad),Aurangabad,Maharashtra', 'lon': '75.7394'},
      '360579': {'lat': '21.7662', 'name': 'Khapat,Porbandar,Gujarat', 'lon': '69.5105'},
      '360578': {'lat': '21.61', 'name': 'Chhaya,Porbandar,Gujarat', 'lon': '69.6824'},
      '843351': {'lat': '26.0066', 'name': 'Akhta,Sitamarhi,Bihar', 'lon': '85.73'},
      '571423': {'lat': '12.942', 'name': 'Hirekalale,Mandya,Karnataka', 'lon': '76.3869'},
      '630562': {'lat': '9.9945', 'name': 'Sakkandhi,Sivaganga,Tamil Nadu', 'lon': '78.4217'},
      '608102': {'lat': '11.4493', 'name': 'Pinnathur,Cuddalore,Tamil Nadu', 'lon': '79.7668'},
      '630566': {'lat': '10.0456', 'name': 'Aralikottai,Sivaganga,Tamil Nadu', 'lon': '78.4878'},
      '403701': {'lat': '15.1807', 'name': 'Assolna,South Goa,Goa', 'lon': '73.9712'},
      '249402': {'lat': '29.9843', 'name': 'Aurangabad,Haridwar,Uttarakhand', 'lon': '78.0063'},
      '247002': {'lat': '29.461', 'name': 'Ptc,Saharanpur,Uttar Pradesh', 'lon': '77.949'},
      '314031': {'lat': '23.8399', 'name': 'Semalia Pandya,Dungarpur,Rajasthan', 'lon': '74.1465'},
      '431008': {'lat': '20.0447', 'name': 'Tuljapur Sawangi,Aurangabad,Maharashtra', 'lon': '75.3456'},
      '425108': {'lat': '21.3359', 'name': 'Manje Hingona,Jalgaon,Maharashtra', 'lon': '74.9283'},
      '425109': {'lat': '20.8544', 'name': 'Toli Khurd,Jalgaon,Maharashtra', 'lon': '75.3056'},
      '283202': {'lat': '27.2509', 'name': 'Sawain,Agra,Uttar Pradesh', 'lon': '78.1941'},
      '425102': {'lat': '20.9109', 'name': 'Bhadli BK,Jalgaon,Maharashtra', 'lon': '74.9721'},
      '425103': {'lat': '21.041', 'name': 'Ringangaon,Jalgaon,Maharashtra', 'lon': '75.4709'},
      '425101': {'lat': '21.0416', 'name': 'Asoda,Jalgaon,Maharashtra', 'lon': '75.6076'},
      '392310': {'lat': '22.3743', 'name': 'Kiya,Vadodara,Gujarat', 'lon': '73.0328'},
      '425107': {'lat': '21.2271', 'name': 'Gorgavale,Jalgaon,Maharashtra', 'lon': '75.4226'},
      '425104': {'lat': '20.9721', 'name': 'Sonwad,Jalgaon,Maharashtra', 'lon': '75.3875'},
      '425105': {'lat': '20.927', 'name': 'Bhone,Jalgaon,Maharashtra', 'lon': '75.067'},
      '761042': {'lat': '19.1028', 'name': 'Karapada,Ganjam,Odisha', 'lon': '83.1031'},
      '761043': {'lat': '19.5135', 'name': 'Chatamundali,Ganjam,Odisha', 'lon': '84.8323'},
      '456443': {'lat': '23.3594', 'name': 'Khedakhajuria,Ujjain,Madhya Pradesh', 'lon': '75.7472'},
      '761041': {'lat': '19.3082', 'name': 'Badakharida,Ganjam,Odisha', 'lon': '83.9677'},
      '835220': {'lat': '23.0254', 'name': 'Dumardih,Gumla,Jharkhand', 'lon': '84.5822'},
      '761045': {'lat': '19.3082', 'name': 'Kanamana,Ganjam,Odisha', 'lon': '83.9677'},
      '835221': {'lat': '23.4432', 'name': 'Tarasiladon,Ranchi,Jharkhand', 'lon': '85.1714'},
      '261145': {'lat': '27.4455', 'name': 'Hathiya Gazipur,Sitapur,Uttar Pradesh', 'lon': '81.2713'},
      '389154': {'lat': '22.8317', 'name': 'Tarvadia Himat,Dahod,Gujarat', 'lon': '74.3646'},
      '389155': {'lat': '22.68791', 'name': 'Jambua,Dahod,Gujarat', 'lon': '74.32893'},
      '389152': {'lat': '22.65415', 'name': 'Pandadi,Dahod,Gujarat', 'lon': '74.1964'},
      '389151': {'lat': '22.82395', 'name': 'Dahod R.S,Dahod,Gujarat', 'lon': '74.33088'},
      '341520': {'lat': '26.5879', 'name': 'Nandoli Mertiyan,Nagaur,Rajasthan', 'lon': '74.4465'},
      '231221': {'lat': '24.2007', 'name': 'Banvasi Seva Ashram,Sonbhadra,Uttar Pradesh', 'lon': '83.0304'},
      '262302': {'lat': '28.5447', 'name': 'Dunidam,Pilibhit,Uttar Pradesh', 'lon': '79.4527'},
      '224001': {'lat': '26.6751', 'name': 'Gadopur Majhwa,Faizabad,Uttar Pradesh', 'lon': '82.2199'},
      '531162': {'lat': '18.0849', 'name': 'Revidi,Visakhapatnam,Andhra Pradesh', 'lon': '83.2455'},
      '531163': {'lat': '17.9274', 'name': 'Kothaparadesipalem,Visakhapatnam,Andhra Pradesh', 'lon': '83.2936'},
      '210429': {'lat': '25.5593', 'name': 'Kankuwan,Mahoba,Uttar Pradesh', 'lon': '79.4705'},
      '231225': {'lat': '24.3471', 'name': 'Dibulgani,Sonbhadra,Uttar Pradesh', 'lon': '82.9998'},
      '507115': {'lat': '17.9984', 'name': 'T.P.Campus,Khammam,Telangana', 'lon': '80.6455'},
      '628217': {'lat': '8.4062', 'name': 'Adaikalapuram,Tuticorin,Tamil Nadu', 'lon': '77.9734'},
      '583224': {'lat': '15.1594', 'name': 'Muthukuru,Ballari,Karnataka', 'lon': '76.1258'},
      '721427': {'lat': '22.4305', 'name': 'Kulanjara,East Midnapore,West Bengal', 'lon': '87.4447'},
      '508355': {'lat': '16.7859', 'name': 'Borraipalem,Nalgonda,Telangana', 'lon': '79.6106'},
      '752019': {'lat': '20.8127', 'name': 'Garhkharad,Khorda,Odisha', 'lon': '85.4399'},
      '210428': {'lat': '25.6267', 'name': 'Nauranga,Hamirpur,Uttar Pradesh', 'lon': '79.4556'},
      '273158': {'lat': '27.052', 'name': 'Dhrampur,Gorakhpur,Uttar Pradesh', 'lon': '83.2573'},
      '249175': {'lat': '30.0589', 'name': 'Chamolgaon,Tehri Garhwal,Uttarakhand', 'lon': '78.721'},
      '752018': {'lat': '20.3072', 'name': 'Brajamohanpur,Khorda,Odisha', 'lon': '85.2853'},
      '824103': {'lat': '24.8493', 'name': 'Choukhara,Aurangabad(BH),Bihar', 'lon': '84.5352'},
      '249171': {'lat': '30.0313', 'name': 'Kotiyalgaon,Uttarkashi,Uttarakhand', 'lon': '79.0311'},
      '642154': {'lat': '10.6355', 'name': 'Kannamanaickanur,Coimbatore,Tamil Nadu', 'lon': '77.2414'},
      '752017': {'lat': '20.1398', 'name': 'Garhbadaput,Puri,Odisha', 'lon': '85.1846'},
      '571116': {'lat': '12.937', 'name': 'Beeramballi,Mysuru,Karnataka', 'lon': '75.9671'},
      '314035': {'lat': '24.3537', 'name': 'Parda Mataji,Dungarpur,Rajasthan', 'lon': '74.3499'},
      '583229': {'lat': '15.2798', 'name': 'Bsavanna Camp,Gadag,Karnataka', 'lon': '75.9707'},
      '465001': {'lat': '23.5955', 'name': 'Bharad,Shajapur,Madhya Pradesh', 'lon': '76.4988'},
      '342001': {'lat': '25.8295', 'name': 'Kabutaron Ka Chowk,Jodhpur,Rajasthan', 'lon': '73.0011'},
      '342003': {'lat': '25.9249', 'name': 'Pratap Nagar (Jodhpur),Jodhpur,Rajasthan', 'lon': '73.0093'},
      '342005': {'lat': '25.9249', 'name': 'Kuri Bhagtasni Housing Board,Jodhpur,Rajasthan', 'lon': '73.0093'},
      '505474': {'lat': '18.4377', 'name': 'Yeradapalli,Karim Nagar,Telangana', 'lon': '79.3565'},
      '342007': {'lat': '25.9249', 'name': 'Jodhpur K.U.M. Mandore Road,Jodhpur,Rajasthan', 'lon': '73.0093'},
      '342006': {'lat': '26.3065', 'name': 'Jodhpur Umaid Bhawan,Jodhpur,Rajasthan', 'lon': '73.0423'},
      '342008': {'lat': '25.9249', 'name': 'Nandanwan,Jodhpur,Rajasthan', 'lon': '73.0093'},
      '222204': {'lat': '25.575', 'name': 'Gariaon,Jaunpur,Uttar Pradesh', 'lon': '82.2727'},
      '752012': {'lat': '20.7617', 'name': 'Bijayaramachandrapur,Puri,Odisha', 'lon': '84.9765'},
      '754082': {'lat': '20.4178', 'name': 'Salapada,Jajapur,Odisha', 'lon': '85.2538'},
      '261501': {'lat': '27.8579', 'name': 'Neemgaon,Kheri,Uttar Pradesh', 'lon': '80.6369'},
      '784508': {'lat': '26.7058', 'name': 'Shillonkhuti,Darrang,Assam', 'lon': '92.2266'},
      '784509': {'lat': '26.7537', 'name': 'Mazkhuti,Darrang,Assam', 'lon': '92.1021'},
      '261505': {'lat': '27.2452', 'name': 'Bisani Khera,Kheri,Uttar Pradesh', 'lon': '80.8791'},
      '261506': {'lat': '27.6268', 'name': 'Sunderwal,Kheri,Uttar Pradesh', 'lon': '81.0039'},
      '784502': {'lat': '26.7167', 'name': 'Manijharni,Sonitpur,Assam', 'lon': '92.7167'},
      '311001': {'lat': '25.519', 'name': 'Indra Market,Bhilwara,Rajasthan', 'lon': '74.6228'},
      '208024': {'lat': '26.418', 'name': 'Bima Vihar,Kanpur Nagar,Uttar Pradesh', 'lon': '80.298'},
      '784501': {'lat': '26.3796', 'name': 'Dekargaon,Sonitpur,Assam', 'lon': '91.2951'},
      '784506': {'lat': '26.8061', 'name': 'Jiagabharu,Sonitpur,Assam', 'lon': '92.5977'},
      '784507': {'lat': '26.8097', 'name': 'Mahalia Para,Darrang,Assam', 'lon': '92.2543'},
      '784504': {'lat': '26.7274', 'name': 'Fulaguri,Sonitpur,Assam', 'lon': '92.2645'},
      '784505': {'lat': '26.8377', 'name': 'Dopdopi,Sonitpur,Assam', 'lon': '92.6688'},
      '518206': {'lat': '15.4442', 'name': 'Bugganipalli,Kurnool,Andhra Pradesh', 'lon': '77.8925'},
      '827004': {'lat': '22.604', 'name': 'Sector- IV,Bokaro,Jharkhand', 'lon': '85.533'},
      '209217': {'lat': '26.1685', 'name': 'Baikuthpur,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3428'},
      '209214': {'lat': '26.1628', 'name': 'Gayatri Seedh Peeth Khadeshar,Kanpur Nagar,Uttar Pradesh', 'lon': '80.5482'},
      '192221': {'lat': '33.5339', 'name': 'Brinnal Lammer,Kulgam,Jammu & Kashmir', 'lon': '75.1844'},
      '209210': {'lat': '27.11', 'name': 'Boramau,Kanpur Dehat,Uttar Pradesh', 'lon': '79.0346'},
      '515003': {'lat': '14.2442', 'name': 'Sri Venkateswara Puram,Ananthapur,Andhra Pradesh', 'lon': '78.1917'},
      '515002': {'lat': '14.4758', 'name': 'Bramhanayaleru,Ananthapur,Andhra Pradesh', 'lon': '78.0778'},
      '515001': {'lat': '14.2442', 'name': 'Ramachandra Nagar,Ananthapur,Andhra Pradesh', 'lon': '78.1917'},
      '827006': {'lat': '22.604', 'name': 'Sector-VI,Bokaro,Jharkhand', 'lon': '85.533'},
      '515005': {'lat': '14.2442', 'name': 'Rajaji Street,Ananthapur,Andhra Pradesh', 'lon': '78.1917'},
      '396120': {'lat': '21.7065', 'name': 'Manekpore,Valsad,Gujarat', 'lon': '73.4056'},
      '827001': {'lat': '22.604', 'name': 'Bokaro Steel name H.O,Bokaro,Jharkhand', 'lon': '85.533'},
      '323615': {'lat': '25.4915', 'name': 'Lakheri Gaon,Bundi,Rajasthan', 'lon': '75.7079'},
      '323614': {'lat': '25.2584', 'name': 'Sumerganj Mandi,Bundi,Rajasthan', 'lon': '76.131'},
      '323616': {'lat': '25.5871', 'name': 'Neem Ka Khera,Bundi,Rajasthan', 'lon': '75.5332'},
      '323613': {'lat': '25.7129', 'name': 'Indergarh (Bundi),Bundi,Rajasthan', 'lon': '75.3119'},
      '387520': {'lat': '22.9288', 'name': 'Malavada,Kheda,Gujarat', 'lon': '72.664'},
      '575023': {'lat': '12.7824', 'name': 'Someshwar Uchil,Dakshina Kannada,Karnataka', 'lon': '74.892'},
      '575022': {'lat': '13.2367', 'name': 'Kotekar,Dakshina Kannada,Karnataka', 'lon': '74.9986'},
      '575020': {'lat': '12.8057', 'name': 'Ullal,Dakshina Kannada,Karnataka', 'lon': '74.8606'},
      '670142': {'lat': '11.5439', 'name': 'Chuzhali,Kannur,Kerala', 'lon': '75.8907'},
      '670143': {'lat': '11.9871', 'name': 'Aril,Kannur,Kerala', 'lon': '75.5584'},
      '575025': {'lat': '13.2367', 'name': 'Srinivasnagar,Dakshina Kannada,Karnataka', 'lon': '74.9986'},
      '670141': {'lat': '11.6547', 'name': 'Taliparamba H.O,Kannur,Kerala', 'lon': '75.8077'},
      '464665': {'lat': '23.0498', 'name': 'Keolajhir,Raisen,Madhya Pradesh', 'lon': '78.019'},
      '575029': {'lat': '13.8465', 'name': 'Pedamale,Dakshina Kannada,Karnataka', 'lon': '75.1735'},
      '575028': {'lat': '12.9024', 'name': 'Moodushedde,Dakshina Kannada,Karnataka', 'lon': '74.8934'},
      '683112': {'lat': '10.0614', 'name': 'Erumathala,Ernakulam,Kerala', 'lon': '76.3263'},
      '683111': {'lat': '10.0614', 'name': 'Kochi Airport,Ernakulam,Kerala', 'lon': '76.3263'},
      '683110': {'lat': '10.0614', 'name': 'Muppathadam,Ernakulam,Kerala', 'lon': '76.3263'},
      '534411': {'lat': '16.5537', 'name': 'Yerramalla,West Godavari,Andhra Pradesh', 'lon': '81.3196'},
      '244712': {'lat': '29.2937', 'name': 'Barionwala,Udham Singh Nagar,Uttarakhand', 'lon': '78.8022'},
      '244713': {'lat': '29.2172', 'name': 'Kila Street,Udham Singh Nagar,Uttarakhand', 'lon': '78.9754'},
      '244715': {'lat': '29.4516', 'name': 'Peerumadra,Udham Singh Nagar,Uttarakhand', 'lon': '79.1015'},
      '244716': {'lat': '29.2824', 'name': 'Anandnagar,Udham Singh Nagar,Uttarakhand', 'lon': '79.0525'},
      '534416': {'lat': '16.8108', 'name': 'A.Gokavaram,West Godavari,Andhra Pradesh', 'lon': '81.3661'},
      '209859': {'lat': '26.5853', 'name': 'Asha Khera,Unnao,Uttar Pradesh', 'lon': '80.9033'},
      '251305': {'lat': '29.4892', 'name': 'Karoda Hathi,Muzaffarnagar,Uttar Pradesh', 'lon': '77.4264'},
      '796691': {'lat': '23.2794', 'name': "Mualthuam 'N',Lunglei,Mizoram", 'lon': '92.7063'},
      '453220': {'lat': '23.5941', 'name': 'Bhilbadoli,Indore,Madhya Pradesh', 'lon': '76.7944'},
      '228131': {'lat': '26.1002', 'name': 'Paharpur Rai Patti,Sultanpur,Uttar Pradesh', 'lon': '82.3214'},
      '209728': {'lat': '26.9833', 'name': 'Bahadurpur Majhgaon,Kannauj,Uttar Pradesh', 'lon': '79.605'},
      '122052': {'lat': '28.3553', 'name': 'IMT Manesar,Gurgaon,Haryana', 'lon': '76.9215'},
      '122051': {'lat': '28.3531', 'name': 'Manesar,Gurgaon,Haryana', 'lon': '76.9404'},
      '506381': {'lat': '17.1384', 'name': 'Manneguda,Mahabubabad,Telangana', 'lon': '79.9936'},
      '734434': {'lat': '26.5864', 'name': 'Kantivitta,Darjiling,West Bengal', 'lon': '88.368'},
      '611102': {'lat': '10.8005', 'name': 'Andanapettai,Nagapattinam,Tamil Nadu', 'lon': '79.7043'},
      '473770': {'lat': '24.9999', 'name': 'Endar,Shivpuri,Madhya Pradesh', 'lon': '77.8249'},
      '364110': {'lat': '21.6881', 'name': 'Thordi,Bhavnagar,Gujarat', 'lon': '72.2763'},
      '363320': {'lat': '23.0071', 'name': 'Pratappur,Surendra Nagar,Gujarat', 'lon': '72.0546'},
      '473774': {'lat': '25.3332', 'name': 'Rajgarh,Shivpuri,Madhya Pradesh', 'lon': '77.5078'},
      '473775': {'lat': '25.5117', 'name': 'Ranthkheda,Shivpuri,Madhya Pradesh', 'lon': '77.381'},
      '734105': {'lat': '27.0623', 'name': 'Badamtam,Darjiling,West Bengal', 'lon': '88.2829'},
      '124514': {'lat': '28.9642', 'name': 'Bhagwatipur,Rohtak,Haryana', 'lon': '76.4989'},
      '524152': {'lat': '14.7921', 'name': 'Kapparallatippa,Nellore,Andhra Pradesh', 'lon': '79.6407'},
      '124513': {'lat': '28.9642', 'name': 'Nindana,Rohtak,Haryana', 'lon': '76.4989'},
      '700048': {'lat': '22.6', 'name': 'Sadhana Ausudhalaya Road,North 24 Parganas,West Bengal', 'lon': '88.4'},
      '700047': {'lat': '22.4667', 'name': 'Raipur Jorabagan Road,Kolkata,West Bengal', 'lon': '88.3833'},
      '700046': {'lat': '22.5333', 'name': 'Gobinda Khatick Road,Kolkata,West Bengal', 'lon': '88.3917'},
      '700045': {'lat': '22.5333', 'name': 'Lake Gardens,Kolkata,West Bengal', 'lon': '88.3917'},
      '700044': {'lat': '22.5333', 'name': 'Rajabagan Dock Yard,Kolkata,West Bengal', 'lon': '88.3917'},
      '700043': {'lat': '22.5333', 'name': 'Sonai (Kolkata),Kolkata,West Bengal', 'lon': '88.3917'},
      '700042': {'lat': '22.5333', 'name': 'Bosepukur Road,Kolkata,West Bengal', 'lon': '88.3917'},
      '700041': {'lat': '22.5333', 'name': 'Paschim Putiari,Kolkata,West Bengal', 'lon': '88.3917'},
      '700040': {'lat': '22.5333', 'name': 'Netaji Nagar (Kolkata),Kolkata,West Bengal', 'lon': '88.3917'},
      '785108': {'lat': '26.8275', 'name': 'Charighoria,Jorhat,Assam', 'lon': '94.0862'},
      '721649': {'lat': '22.3578', 'name': 'Kurpai,East Midnapore,West Bengal', 'lon': '88.1922'},
      '509235': {'lat': '16.4342', 'name': 'Vennacherla,Mahabub Nagar,Telangana', 'lon': '78.2715'},
      '785101': {'lat': '26.6386', 'name': 'Borkhelia Goon,Jorhat,Assam', 'lon': '92.7316'},
      '785102': {'lat': '26.6386', 'name': 'Dakhin Pat Satra,Jorhat,Assam', 'lon': '92.7316'},
      '785105': {'lat': '26.6386', 'name': 'Karki Chuk Missing Gaon,Jorhat,Assam', 'lon': '92.7316'},
      '785104': {'lat': '26.4497', 'name': 'Balichapori,Golaghat,Assam', 'lon': '91.3771'},
      '785107': {'lat': '26.6386', 'name': 'Bailunggaon,Jorhat,Assam', 'lon': '92.7316'},
      '785106': {'lat': '26.6386', 'name': 'Botiamari,Jorhat,Assam', 'lon': '92.7316'},
      '345001': {'lat': '26.9764', 'name': 'Bhoo,Jaisalmer,Rajasthan', 'lon': '70.8351'},
      '609314': {'lat': '11.0683', 'name': 'Kodangudi,Nagapattinam,Tamil Nadu', 'lon': '79.6795'},
      '609313': {'lat': '11.0276', 'name': 'Tarangambadi,Nagapattinam,Tamil Nadu', 'lon': '79.8543'},
      '609312': {'lat': '11.0429', 'name': 'Buthanur,Nagapattinam,Tamil Nadu', 'lon': '79.7813'},
      '609311': {'lat': '11.0429', 'name': 'Tirumeignanam,Nagapattinam,Tamil Nadu', 'lon': '79.7813'},
      '609310': {'lat': '11.0365', 'name': 'Sendamangalam,Nagapattinam,Tamil Nadu', 'lon': '79.7803'},
      '628208': {'lat': '8.5098', 'name': 'Manaduthandapathu,Tuticorin,Tamil Nadu', 'lon': '78.0641'},
      '271825': {'lat': '27.4902', 'name': 'Gopchandpur,Bahraich,Uttar Pradesh', 'lon': '81.5929'},
      '841219': {'lat': '25.8026', 'name': 'Parsa,Saran,Bihar', 'lon': '84.908'},
      '764003': {'lat': '18.9973', 'name': 'Prasadraopeta,Koraput,Odisha', 'lon': '82.387'},
      '721648': {'lat': '24.4536', 'name': 'Mayachar,East Midnapore,West Bengal', 'lon': '87.9044'},
      '764005': {'lat': '18.8259', 'name': 'Anta,Koraput,Odisha', 'lon': '82.4728'},
      '828120': {'lat': '24.0055', 'name': 'Motinagar (Dhanbad),Dhanbad,Jharkhand', 'lon': '86.406'},
      '642127': {'lat': '10.3269', 'name': 'Akkamalai,Coimbatore,Tamil Nadu', 'lon': '76.9512'},
      '764004': {'lat': '18.9322', 'name': 'Jeypore(K) Irrigation Colony,Koraput,Odisha', 'lon': '82.4017'},
      '691532': {'lat': '8.9412', 'name': 'Ambalakkara,Kollam,Kerala', 'lon': '76.8541'},
      '691533': {'lat': '8.8994', 'name': 'Vayyanam,Kollam,Kerala', 'lon': '76.8349'},
      '691530': {'lat': '9.0806', 'name': 'Mannady (Pathanamthitta),Pathanamthitta,Kerala', 'lon': '76.8307'},
      '691531': {'lat': '9.0032', 'name': 'Extension Trg Centre,Kollam,Kerala', 'lon': '76.8003'},
      '691536': {'lat': '9.4646', 'name': 'Vattathamara,Kollam,Kerala', 'lon': '76.816'},
      '691537': {'lat': '8.9043', 'name': 'Mailode,Kollam,Kerala', 'lon': '76.7984'},
      '691534': {'lat': '9.0806', 'name': 'Kuriyode,Kollam,Kerala', 'lon': '76.8307'},
      '691535': {'lat': '8.8237', 'name': 'Kaithode,Kollam,Kerala', 'lon': '76.883'},
      '800006': {'lat': '25.5377', 'name': 'M.Y.Sandalpur,Patna,Bihar', 'lon': '85.2322'},
      '800007': {'lat': '25.5059', 'name': 'Dental College,Patna,Bihar', 'lon': '85.1651'},
      '691538': {'lat': '9.0806', 'name': 'Vettikavala,Kollam,Kerala', 'lon': '76.8307'},
      '800005': {'lat': '25.5377', 'name': 'Patna University,Patna,Bihar', 'lon': '85.2322'},
      '517401': {'lat': '13.3896', 'name': 'Arimanipenta,Chittoor,Andhra Pradesh', 'lon': '78.756'},
      '800003': {'lat': '25.5377', 'name': 'Kadamkuan,Patna,Bihar', 'lon': '85.2322'},
      '517403': {'lat': '13.1348', 'name': 'Naragallu,Chittoor,Andhra Pradesh', 'lon': '79.213'},
      '301708': {'lat': '27.9239', 'name': 'Santo,Alwar,Rajasthan', 'lon': '76.4912'},
      '388430': {'lat': '22.9231', 'name': 'Dharmaj-ranoli,Anand,Gujarat', 'lon': '73.1675'},
      '502255': {'lat': '18.0243', 'name': 'Uppu Lingapur,Medak,Telangana', 'lon': '78.5511'},
      '415109': {'lat': '17.3706', 'name': 'Chore,Satara,Maharashtra', 'lon': '74.1056'},
      '415108': {'lat': '17.6931', 'name': 'Shnoli,Satara,Maharashtra', 'lon': '74.4665'},
      '799141': {'lat': '23.0015', 'name': 'Thakurcherra,South Tripura,Tripura', 'lon': '91.7243'},
      '799142': {'lat': '23.0015', 'name': 'Charakbai,South Tripura,Tripura', 'lon': '91.7243'},
      '799143': {'lat': '23.0015', 'name': 'Amlighat,South Tripura,Tripura', 'lon': '91.7243'},
      '415101': {'lat': '16.4697', 'name': 'Man,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '415022': {'lat': '20.1806', 'name': 'Kelembe,Satara,Maharashtra', 'lon': '75.6891'},
      '415103': {'lat': '20.0428', 'name': 'Saikade,Satara,Maharashtra', 'lon': '77.0613'},
      '415102': {'lat': '17.4422', 'name': 'Mayani,Satara,Maharashtra', 'lon': '74.5502'},
      '415105': {'lat': '17.6931', 'name': 'Hajarmachi,Satara,Maharashtra', 'lon': '74.4665'},
      '415104': {'lat': '17.6931', 'name': 'Dhondewadi,Satara,Maharashtra', 'lon': '74.4665'},
      '415107': {'lat': '17.6686', 'name': 'Nandgaon,Satara,Maharashtra', 'lon': '74.1738'},
      '415023': {'lat': '20.1806', 'name': 'Mahiamngad,Satara,Maharashtra', 'lon': '75.6891'},
      '384245': {'lat': '23.5202', 'name': 'Samsherpura,Patan,Gujarat', 'lon': '71.8677'},
      '333042': {'lat': '27.4559', 'name': 'Chobdaranki Dhani,Jhujhunu,Rajasthan', 'lon': '75.5951'},
      '626204': {'lat': '9.096', 'name': 'Thulukkapatti,Virudhunagar,Tamil Nadu', 'lon': '77.6268'},
      '384246': {'lat': '23.4697', 'name': 'Dhanora,Patan,Gujarat', 'lon': '72.6904'},
      '384241': {'lat': '23.8468', 'name': 'Jesda,Patan,Gujarat', 'lon': '72.668'},
      '384240': {'lat': '23.3976', 'name': 'Vansa,Patan,Gujarat', 'lon': '72.7228'},
      '847337': {'lat': '25.9068', 'name': 'Chatwan,Darbhanga,Bihar', 'lon': '86.2956'},
      '626201': {'lat': '9.5263', 'name': 'Panayadipatti,Virudhunagar,Tamil Nadu', 'lon': '77.7823'},
      '851127': {'lat': '25.5593', 'name': 'Manjhaul,Begusarai,Bihar', 'lon': '86.1267'},
      '415021': {'lat': '20.1806', 'name': 'Ambawade S Koregaon,Satara,Maharashtra', 'lon': '75.6891'},
      '501106': {'lat': '18.0884', 'name': 'Kalkhoda,K.V.Rangareddy,Telangana', 'lon': '78.486'},
      '841405': {'lat': '26.4427', 'name': 'Neori,Gopalganj,Bihar', 'lon': '84.3773'},
      '416005': {'lat': '20.3396', 'name': 'Uchgaon,Kolhapur,Maharashtra', 'lon': '77.2373'},
      '841407': {'lat': '26.1955', 'name': 'Usri Bazar,Gopalganj,Bihar', 'lon': '84.6083'},
      '841406': {'lat': '26.0438', 'name': 'Mohmda Bazar,Siwan,Bihar', 'lon': '84.6596'},
      '630713': {'lat': '9.7022', 'name': 'Alavidangan,Sivaganga,Tamil Nadu', 'lon': '78.7245'},
      '303901': {'lat': '26.2919', 'name': 'Mandlia Meda,Jaipur,Rajasthan', 'lon': '75.8585'},
      '841403': {'lat': '25.8543', 'name': 'Dhangarha,Saran,Bihar', 'lon': '84.7533'},
      '313330': {'lat': '24.9152', 'name': 'Olanakhera,Rajsamand,Rajasthan', 'lon': '74.0079'},
      '360360': {'lat': '22.2673', 'name': 'Bava Pipaliya,Rajkot,Gujarat', 'lon': '71.1616'},
      '841409': {'lat': '26.2742', 'name': 'Usribazar,Gopalganj,Bihar', 'lon': '84.7822'},
      '313333': {'lat': '25.3136', 'name': 'Rinchhed,Rajsamand,Rajasthan', 'lon': '73.7344'},
      '571114': {'lat': '12.1075', 'name': 'Chakkodanahalli,Mysuru,Karnataka', 'lon': '76.5132'},
      '262541': {'lat': '30.099', 'name': 'Hachila,Pithoragarh,Uttarakhand', 'lon': '79.2746'},
      '781337': {'lat': '26.3796', 'name': 'Barkhanajan,Nalbari,Assam', 'lon': '91.2951'},
      '781334': {'lat': '26.3796', 'name': 'Balilecha,Nalbari,Assam', 'lon': '91.2951'},
      '781335': {'lat': '26.3796', 'name': 'Khatabari,Nalbari,Assam', 'lon': '91.2951'},
      '781333': {'lat': '26.3796', 'name': 'Dalbari,Nalbari,Assam', 'lon': '91.2951'},
      '781330': {'lat': '26.3796', 'name': 'Baghogra,Barpeta,Assam', 'lon': '91.2951'},
      '271865': {'lat': '27.7089', 'name': 'Jamunaha Bhawania Pur,Shrawasti,Uttar Pradesh', 'lon': '81.5568'},
      '781338': {'lat': '26.3796', 'name': 'Lakhopur,Nalbari,Assam', 'lon': '91.2951'},
      '781339': {'lat': '26.3796', 'name': 'Bala,Nalbari,Assam', 'lon': '91.2951'},
      '285130': {'lat': '26.2716', 'name': 'Eekon,Jalaun,Uttar Pradesh', 'lon': '79.455'},
      '416003': {'lat': '20.3396', 'name': 'Kolhapur H.O,Kolhapur,Maharashtra', 'lon': '77.2373'},
      '472118': {'lat': '25.0371', 'name': 'Karmora,Tikamgarh,Madhya Pradesh', 'lon': '79.0319'},
      '495335': {'lat': '22.0815', 'name': 'Kukusda,Bilaspur(CGH),Chattisgarh', 'lon': '81.8133'},
      '500111': {'lat': '17.5902', 'name': 'I S B Campus,K.V.Rangareddy,Telangana', 'lon': '78.6668'},
      '621710': {'lat': '11.2117', 'name': 'Therkupatti,Ariyalur,Tamil Nadu', 'lon': '79.3399'},
      '814146': {'lat': '24.223', 'name': 'Baragamharia,Dumka,Jharkhand', 'lon': '86.9044'},
      '365421': {'lat': '21.7352', 'name': 'Pansada,Amreli,Gujarat', 'lon': '71.2742'},
      '487114': {'lat': '22.9111', 'name': 'Nayagaon,Narsinghpur,Madhya Pradesh', 'lon': '79.3568'},
      '394120': {'lat': '21.462', 'name': 'Kosamba Bazar,Surat,Gujarat', 'lon': '72.9584'},
      '394125': {'lat': '21.4956', 'name': 'Mahuvej,Surat,Gujarat', 'lon': '72.9742'},
      '814144': {'lat': '24.6117', 'name': 'Ghormala Hunja,Dumka,Jharkhand', 'lon': '87.5082'},
      '144010': {'lat': '31.1191', 'name': 'Dhanowali,Jalandhar,Punjab', 'lon': '75.487'},
      '144011': {'lat': '31.1191', 'name': 'R.E.C. Jalandhar,Jalandhar,Punjab', 'lon': '75.487'},
      '628209': {'lat': '8.3773', 'name': 'Manapad,Tuticorin,Tamil Nadu', 'lon': '78.0563'},
      '689668': {'lat': '10.0253', 'name': 'Thonniamala,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '464881': {'lat': '23.5962', 'name': 'Dhuwaj,Raisen,Madhya Pradesh', 'lon': '78.8991'},
      '451449': {'lat': '21.8775', 'name': 'Ojhar,Barwani,Madhya Pradesh', 'lon': '75.2431'},
      '689663': {'lat': '10.0253', 'name': 'Vayyattupuzha,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '689662': {'lat': '10.0253', 'name': 'Pampa Triveni,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '689661': {'lat': '10.0253', 'name': 'Kumpalampoika,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '281502': {'lat': '27.5802', 'name': 'Paintha,Mathura,Uttar Pradesh', 'lon': '77.4448'},
      '689667': {'lat': '10.0253', 'name': 'Kochukoikkal,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '689666': {'lat': '10.0253', 'name': 'Malayalapuzha Thazham,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '689664': {'lat': '10.0253', 'name': 'Thalachira Eram,Pathanamthitta,Kerala', 'lon': '76.4571'},
      '452016': {'lat': '22.6984', 'name': 'Semlia Chau,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '126116': {'lat': '29.6479', 'name': 'Dumarkhan Kalan,Jind,Haryana', 'lon': '76.0988'},
      '126115': {'lat': '29.4422', 'name': 'Khatkar,Jind,Haryana', 'lon': '76.1858'},
      '126114': {'lat': '29.2022', 'name': 'Gatauli,Jind,Haryana', 'lon': '76.3689'},
      '126113': {'lat': '29.2537', 'name': 'Barthana,Jind,Haryana', 'lon': '76.4508'},
      '126112': {'lat': '29.3046', 'name': 'Rohar,Jind,Haryana', 'lon': '76.622'},
      '126111': {'lat': '29.4354', 'name': 'Durana,Jind,Haryana', 'lon': '76.3009'},
      '126110': {'lat': '29.4001', 'name': 'Baniya Khera,Jind,Haryana', 'lon': '76.4473'},
      '814141': {'lat': '24.2419', 'name': 'Raikinari,Dumka,Jharkhand', 'lon': '86.36'},
      '142042': {'lat': '31.0631', 'name': 'Karyal,Moga,Punjab', 'lon': '75.3067'},
      '452018': {'lat': '22.6984', 'name': 'Indore Tillaknagar,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '743351': {'lat': '22.1169', 'name': 'Enayetpur,South 24 Parganas,West Bengal', 'lon': '88.4168'},
      '416104': {'lat': '17.1709', 'name': 'Aurwad,Kolhapur,Maharashtra', 'lon': '75.154'},
      '416105': {'lat': '17.1709', 'name': 'Shedshal,Kolhapur,Maharashtra', 'lon': '75.154'},
      '416106': {'lat': '16.6568', 'name': 'Kurundwad,Kolhapur,Maharashtra', 'lon': '73.517'},
      '416107': {'lat': '17.1709', 'name': 'Danwad,Kolhapur,Maharashtra', 'lon': '75.154'},
      '416101': {'lat': '19.715', 'name': 'Nimshirgaon,Kolhapur,Maharashtra', 'lon': '79.0301'},
      '416102': {'lat': '17.1709', 'name': 'Haroli,Kolhapur,Maharashtra', 'lon': '75.154'},
      '416103': {'lat': '16.7353', 'name': 'Kutwad,Kolhapur,Maharashtra', 'lon': '74.6025'},
      '847409': {'lat': '26.3824', 'name': 'Bagha Kusmar,Madhubani,Bihar', 'lon': '86.4622'},
      '828122': {'lat': '23.8293', 'name': 'Saharpura,Dhanbad,Jharkhand', 'lon': '86.4719'},
      '416108': {'lat': '17.1709', 'name': 'Khidrapur,Kolhapur,Maharashtra', 'lon': '75.154'},
      '416109': {'lat': '16.3736', 'name': 'Majale,Kolhapur,Maharashtra', 'lon': '74.3101'},
      '682038': {'lat': '10.0492', 'name': 'Poonithura,Ernakulam,Kerala', 'lon': '76.3705'},
      '503120': {'lat': '18.0718', 'name': 'Tadwai,Nizamabad,Telangana', 'lon': '78.5737'},
      '461331': {'lat': '22.4387', 'name': 'Mahandgaon,Hoshangabad,Madhya Pradesh', 'lon': '77.0552'},
      '743356': {'lat': '22.7843', 'name': 'Ghoramara Mandirtala,South 24 Parganas,West Bengal', 'lon': '88.1145'},
      '461446': {'lat': '22.2958', 'name': 'Of Itarsi,Hoshangabad,Madhya Pradesh', 'lon': '78.0798'},
      '516464': {'lat': '14.7808', 'name': 'Jangamreddypalle,Cuddapah,Andhra Pradesh', 'lon': '78.3193'},
      '307028': {'lat': '24.9141', 'name': 'Posalia,Sirohi,Rajasthan', 'lon': '73.0989'},
      '307029': {'lat': '25.6542', 'name': 'Kamba,Jalor,Rajasthan', 'lon': '73.2873'},
      '307024': {'lat': '24.5455', 'name': 'Vasa,Sirohi,Rajasthan', 'lon': '72.9727'},
      '307025': {'lat': '24.3298', 'name': 'Jed,Udaipur,Rajasthan', 'lon': '73.2809'},
      '307026': {'lat': '24.4798', 'name': 'Aburoad,Sirohi,Rajasthan', 'lon': '72.7153'},
      '307027': {'lat': '24.7589', 'name': 'Badgaon,Sirohi,Rajasthan', 'lon': '72.7743'},
      '508105': {'lat': '18.1631', 'name': 'Bondugula,Nalgonda,Telangana', 'lon': '79.267'},
      '307022': {'lat': '24.8097', 'name': 'Peshua,Sirohi,Rajasthan', 'lon': '72.8838'},
      '322027': {'lat': '26.4605', 'name': 'Soorwal,Sawai Madhopur,Rajasthan', 'lon': '75.7586'},
      '621315': {'lat': '10.4337', 'name': 'Inamponnambalapatti,Tiruchirappalli,Tamil Nadu', 'lon': '78.3154'},
      '483775': {'lat': '23.9493', 'name': 'Deora Kala,Katni,Madhya Pradesh', 'lon': '80.6641'},
      '743357': {'lat': '23.8189', 'name': 'Madanganj,South 24 Parganas,West Bengal', 'lon': '87.8988'},
      '483770': {'lat': '23.7931', 'name': 'Kuthiya Mahgawan,Katni,Madhya Pradesh', 'lon': '80.7916'},
      '483773': {'lat': '23.6395', 'name': 'Bijouri,Katni,Madhya Pradesh', 'lon': '80.4439'},
      '824113': {'lat': '24.8898', 'name': 'Tarari,Gaya,Bihar', 'lon': '84.7788'},
      '844111': {'lat': '25.8957', 'name': 'Belwar,Vaishali,Bihar', 'lon': '85.3067'},
      '844112': {'lat': '25.8887', 'name': 'Bishanpur Arara,Vaishali,Bihar', 'lon': '85.4122'},
      '721642': {'lat': '22.661', 'name': 'Gobradan,East Midnapore,West Bengal', 'lon': '88.7265'},
      '610101': {'lat': '10.8184', 'name': 'Nadapur,Tiruvarur,Tamil Nadu', 'lon': '79.693'},
      '844115': {'lat': '25.6686', 'name': 'Gauspur Bariarpur,Vaishali,Bihar', 'lon': '85.3621'},
      '844116': {'lat': '25.7571', 'name': 'Bishanpur Bejha,Vaishali,Bihar', 'lon': '85.344'},
      '394430': {'lat': '22.2243', 'name': 'Verakui,Surat,Gujarat', 'lon': '73.4383'},
      '844118': {'lat': '25.9427', 'name': 'Bathna Mahodat,Vaishali,Bihar', 'lon': '85.3491'},
      '736135': {'lat': '26.1353', 'name': 'Matalhat,Cooch Behar,West Bengal', 'lon': '89.4613'},
      '743513': {'lat': '22.3024', 'name': 'Sitalatala Rajarampur,South 24 Parganas,West Bengal', 'lon': '88.2334'},
      '852128': {'lat': '25.9994', 'name': 'Rupauli Jiwachpur,Madhepura,Bihar', 'lon': '86.7924'},
      '852129': {'lat': '25.7117', 'name': 'Lagma Deorhi,Saharsa,Bihar', 'lon': '86.5611'},
      '852126': {'lat': '25.6135', 'name': 'Salkhua Bazar,Saharsa,Bihar', 'lon': '86.4991'},
      '743354': {'lat': '22.463', 'name': 'Khojkhidir,South 24 Parganas,West Bengal', 'lon': '88.2622'},
      '763004': {'lat': '19.76865', 'name': 'Nad,21,Sunabeda', 'lon': '82.15275'},
      '852125': {'lat': '26.2477', 'name': 'Govindpur,Supaul,Bihar', 'lon': '86.8001'},
      '852122': {'lat': '25.815', 'name': 'Tamot Persa,Madhepura,Bihar', 'lon': '86.7682'},
      '852123': {'lat': '26.0343', 'name': 'Bakunia,Saharsa,Bihar', 'lon': '86.0517'},
      '852121': {'lat': '25.4411', 'name': 'T. Dhanchhoa,Saharsa,Bihar', 'lon': '85.2594'},
      '721643': {'lat': '22.7639', 'name': 'Khanchi,East Midnapore,West Bengal', 'lon': '88.1081'},
      '621713': {'lat': '11.1625', 'name': 'Kilamathur,Ariyalur,Tamil Nadu', 'lon': '79.0518'},
      '361230': {'lat': '22.6015', 'name': 'Sachana,Jamnagar,Gujarat', 'lon': '70.2163'},
      '628251': {'lat': '8.5857', 'name': 'Sebathiahpuram,Tuticorin,Tamil Nadu', 'lon': '77.9918'},
      '203405': {'lat': '28.4915', 'name': 'Chithera,Bulandshahr,Uttar Pradesh', 'lon': '78.1349'},
      '192101': {'lat': '33.7392', 'name': 'Urranhal,Ananthnag,Jammu & Kashmir', 'lon': '75.147'},
      '322021': {'lat': '26.4129', 'name': 'Sawaimadhopur Bazar,Sawai Madhopur,Rajasthan', 'lon': '76.0676'},
      '577502': {'lat': '14.1604', 'name': 'Madanayakanahalli,Chitradurga,Karnataka', 'lon': '76.264'},
      '282010': {'lat': '27.1748', 'name': 'Nai Ki Mandi,Agra,Uttar Pradesh', 'lon': '77.9785'},
      '577501': {'lat': '14.1604', 'name': 'Chitradurga  Dist Office,Davangere,Karnataka', 'lon': '76.264'},
      '148018': {'lat': '30.4283', 'name': 'Bagrian,Sangrur,Punjab', 'lon': '76.0346'},
      '148019': {'lat': '30.3742', 'name': 'Bhogiwal,Sangrur,Punjab', 'lon': '75.9806'},
      '203403': {'lat': '28.3576', 'name': 'Bharkau,Bulandshahr,Uttar Pradesh', 'lon': '78.0123'},
      '610004': {'lat': '11.6906', 'name': 'Thandalai,Tiruvarur,Tamil Nadu', 'lon': '79.7668'},
      '573218': {'lat': '13.139', 'name': 'Madabalu,Hassan,Karnataka', 'lon': '76.0572'},
      '573219': {'lat': '13.1018', 'name': 'Kudrugundi,Hassan,Karnataka', 'lon': '76.1045'},
      '573216': {'lat': '13.1104', 'name': 'Savasihalli,Hassan,Karnataka', 'lon': '76.3854'},
      '573217': {'lat': '13.5247', 'name': 'Bylahalli,Hassan,Karnataka', 'lon': '75.5'},
      '573214': {'lat': '12.6323', 'name': 'Bage,Hassan,Karnataka', 'lon': '75.863'},
      '573215': {'lat': '13.0697', 'name': 'Paduvalalu,Hassan,Karnataka', 'lon': '75.8654'},
      '573212': {'lat': '13.0255', 'name': 'Sathenahalli,Hassan,Karnataka', 'lon': '76.1367'},
      '573213': {'lat': '13.2113', 'name': 'Hunsavalli,Hassan,Karnataka', 'lon': '76.0126'},
      '573210': {'lat': '12.68', 'name': 'Niduvani,Hassan,Karnataka', 'lon': '76.1777'},
      '573211': {'lat': '12.8509', 'name': 'Holenarsipur,Hassan,Karnataka', 'lon': '76.0492'},
      '403516': {'lat': '15.5469', 'name': 'Arpora,North Goa,Goa', 'lon': '73.7572'},
      '274309': {'lat': '26.4779', 'name': 'Pakari Buzurg,Kushinagar,Uttar Pradesh', 'lon': '83.9736'},
      '796184': {'lat': '23.2811', 'name': 'Lungchhuan,Serchhip,Mizoram', 'lon': '92.8494'},
      '221715': {'lat': '26.1991', 'name': 'Bahuta Chak Upadhyay,Ballia,Uttar Pradesh', 'lon': '83.8253'},
      '221717': {'lat': '26.0546', 'name': 'Isarpitha Patti,Ballia,Uttar Pradesh', 'lon': '83.9303'},
      '221716': {'lat': '26.1214', 'name': 'Mahari,Ballia,Uttar Pradesh', 'lon': '83.7134'},
      '221711': {'lat': '25.9571', 'name': 'Nikasi,Ballia,Uttar Pradesh', 'lon': '83.7962'},
      '221713': {'lat': '25.697', 'name': 'Nasirpurkalan,Ballia,Uttar Pradesh', 'lon': '83.9706'},
      '221712': {'lat': '25.8954', 'name': 'Saraybharti,Ballia,Uttar Pradesh', 'lon': '83.6898'},
      '759100': {'lat': '20.950006', 'name': 'Seepur,Angul,Odisha', 'lon': '85.216694'},
      '670334': {'lat': '12.0418', 'name': 'Ezhome,Kannur,Kerala', 'lon': '75.3473'},
      '500023': {'lat': '17.3613', 'name': 'Yakutpura,Hyderabad,Telangana', 'lon': '78.4896'},
      '670331': {'lat': '12.0418', 'name': 'Morazha,Kannur,Kerala', 'lon': '75.3473'},
      '721645': {'lat': '22.0838', 'name': 'Barbasudevpur,East Midnapore,West Bengal', 'lon': '88.0403'},
      '522658': {'lat': '16.1742', 'name': 'Gundepalle,Guntur,Andhra Pradesh', 'lon': '79.8097'},
      '522659': {'lat': '15.9605', 'name': 'Cheruvukommupalem,Guntur,Andhra Pradesh', 'lon': '79.6614'},
      '322023': {'lat': '26.3841', 'name': 'Moran,Sawai Madhopur,Rajasthan', 'lon': '76.2324'},
      '522657': {'lat': '15.6691', 'name': 'Kanumalacheruvu,Guntur,Andhra Pradesh', 'lon': '79.7052'},
      '382210': {'lat': '22.6537', 'name': 'Vishalpur,Ahmedabad,Gujarat', 'lon': '73.0122'},
      '581450': {'lat': '14.527', 'name': 'Urtota,Uttara Kannada,Karnataka', 'lon': '74.7782'},
      '581453': {'lat': '14.527', 'name': 'Ramnagar (Uttara Kannada),Uttara Kannada,Karnataka', 'lon': '74.7782'},
      '382213': {'lat': '22.6537', 'name': 'Devtimoti,Ahmedabad,Gujarat', 'lon': '73.0122'},
      '581107': {'lat': '15.1214', 'name': 'Harlapur,Dharwad,Karnataka', 'lon': '75.4139'},
      '581106': {'lat': '14.6569', 'name': 'Chinnamulagund,Haveri,Karnataka', 'lon': '75.4338'},
      '581105': {'lat': '15.575', 'name': 'Madhanabhavi,Dharwad,Karnataka', 'lon': '74.9302'},
      '581104': {'lat': '14.7134', 'name': 'Chikkaunshihosur,Haveri,Karnataka', 'lon': '75.1179'},
      '581109': {'lat': '14.5803', 'name': 'Betakerur,Haveri,Karnataka', 'lon': '75.4168'},
      '581108': {'lat': '14.7254', 'name': 'Belivigi,Haveri,Karnataka', 'lon': '75.5392'},
      '625101': {'lat': '10.1609', 'name': 'Mangalampatti,Madurai,Tamil Nadu', 'lon': '78.3696'},
      '734301': {'lat': '27.0277', 'name': 'Suruk,Kalimpong,West Bengal', 'lon': '88.5003'},
      '844508': {'lat': '25.7587', 'name': 'Rushtampur,Vaishali,Bihar', 'lon': '85.6704'},
      '211007': {'lat': '25.4608', 'name': 'Mohabbatganj Uparhar,Allahabad,Uttar Pradesh', 'lon': '81.8162'},
      '451441': {'lat': '22.1173', 'name': 'Mongargaon,West Nimar,Madhya Pradesh', 'lon': '75.7734'},
      '422213': {'lat': '19.9678', 'name': 'Pimpalgaon Bahula,Nashik,Maharashtra', 'lon': '73.6631'},
      '422212': {'lat': '19.6377', 'name': 'Samundi,Nashik,Maharashtra', 'lon': '73.782'},
      '422211': {'lat': '20.3727', 'name': 'Alangun,Nashik,Maharashtra', 'lon': '74.9723'},
      '422210': {'lat': '20.2592', 'name': 'Chatori,Nashik,Maharashtra', 'lon': '74.0714'},
      '614904': {'lat': '11.4375', 'name': 'Kandithampattu,Thanjavur,Tamil Nadu', 'lon': '78.6248'},
      '614905': {'lat': '10.5715', 'name': 'Tirumangalakottai,Thanjavur,Tamil Nadu', 'lon': '79.3815'},
      '422215': {'lat': '20.0366', 'name': 'Pimpri -achala,Nashik,Maharashtra', 'lon': '74.6618'},
      '422214': {'lat': '20.6043', 'name': 'Wavi Harsh,Nashik,Maharashtra', 'lon': '74.9467'},
      '249408': {'lat': '29.8965', 'name': 'Missarpur,Haridwar,Uttarakhand', 'lon': '78.1643'},
      '828207': {'lat': '23.8656', 'name': 'Maithon Dam,Dhanbad,Jharkhand', 'lon': '86.3688'},
      '636202': {'lat': '11.3314', 'name': 'Thirumanur,Namakkal,Tamil Nadu', 'lon': '78.0301'},
      '585327': {'lat': '17.8751', 'name': 'Basavakalyan Eng College,Bidar,Karnataka', 'lon': '76.9141'},
      '440027': {'lat': '21.0903', 'name': 'Vishwakarma Nagar,Nagpur,Maharashtra', 'lon': '78.9228'},
      '602105': {'lat': '12.9191', 'name': 'Mathur,Kanchipuram,Tamil Nadu', 'lon': '79.8125'},
      '585326': {'lat': '18.3015', 'name': 'Hangarga(B),Bidar,Karnataka', 'lon': '77.4366'},
      '845401': {'lat': '26.6703', 'name': 'Dhekaha Bazar,East Champaran,Bihar', 'lon': '84.8962'},
      '602108': {'lat': '12.9654', 'name': 'Tirupandiyur,Kanchipuram,Tamil Nadu', 'lon': '79.8289'},
      '311302': {'lat': '25.8612', 'name': 'Mogar,Bhilwara,Rajasthan', 'lon': '74.3111'},
      '585325': {'lat': '16.8145', 'name': 'Magingera,Kalaburagi,Karnataka', 'lon': '76.4677'},
      '311301': {'lat': '25.402', 'name': 'Darawat CO,Bhilwara,Rajasthan', 'lon': '74.489'},
      '686662': {'lat': '9.8694', 'name': 'Marika,Ernakulam,Kerala', 'lon': '76.5753'},
      '686663': {'lat': '9.9333', 'name': 'Ooramana,Ernakulam,Kerala', 'lon': '76.5'},
      '175015': {'lat': '31.8009', 'name': 'Shanan Power House,Mandi,Himachal Pradesh', 'lon': '76.8786'},
      '686666': {'lat': '9.8841', 'name': 'Kothamangalam College,Ernakulam,Kerala', 'lon': '76.5473'},
      '686667': {'lat': '9.8925', 'name': 'Pampakuda,Ernakulam,Kerala', 'lon': '76.5054'},
      '686664': {'lat': '9.875', 'name': 'Manidu,Ernakulam,Kerala', 'lon': '76.4917'},
      '686665': {'lat': '9.8362', 'name': 'Perumbadavam,Ernakulam,Kerala', 'lon': '76.5572'},
      '585323': {'lat': '16.6305', 'name': 'Rastapur,Yadgir,Karnataka', 'lon': '76.8536'},
      '686668': {'lat': '9.9697', 'name': 'Kavakkad,Ernakulam,Kerala', 'lon': '76.6717'},
      '686669': {'lat': '9.8841', 'name': 'Nellad,Ernakulam,Kerala', 'lon': '76.5473'},
      '585322': {'lat': '17.4496', 'name': 'Arankallur,Kalaburagi,Karnataka', 'lon': '77.0899'},
      '222105': {'lat': '25.7797', 'name': 'Poly,Jaunpur,Uttar Pradesh', 'lon': '82.6026'},
      '191121': {'lat': '34.1568', 'name': 'New Theed,Srinagar,Jammu & Kashmir', 'lon': '74.8931'},
      '515611': {'lat': '14.9225', 'name': 'Chitrachedu,Ananthapur,Andhra Pradesh', 'lon': '77.7323'},
      '518432': {'lat': '15.5336', 'name': 'Allur,Kurnool,Andhra Pradesh', 'lon': '78.0061'},
      '636201': {'lat': '11.1597', 'name': 'Nilavarapatti,Salem,Tamil Nadu', 'lon': '78.1794'},
      '671318': {'lat': '12.3965', 'name': 'Paniyal,Kasargod,Kerala', 'lon': '75.0351'},
      '671319': {'lat': '12.446', 'name': 'Mylatti,Kasargod,Kerala', 'lon': '75.042'},
      '441205': {'lat': '20.6284', 'name': 'Navkhala,Chandrapur,Maharashtra', 'lon': '79.6165'},
      '441204': {'lat': '20.8536', 'name': 'Salaigodhni,Nagpur,Maharashtra', 'lon': '79.2864'},
      '441203': {'lat': '20.8582', 'name': 'Mangrud,Nagpur,Maharashtra', 'lon': '79.2073'},
      '441202': {'lat': '21.0442', 'name': 'Deoli Kalan,Nagpur,Maharashtra', 'lon': '79.0376'},
      '441201': {'lat': '20.8488', 'name': 'Naxi,Nagpur,Maharashtra', 'lon': '79.4233'},
      '636015': {'lat': '11.7527', 'name': 'Dadubaikuttai,Salem,Tamil Nadu', 'lon': '78.2899'},
      '671310': {'lat': '12.2277', 'name': 'Edachakai,Kasargod,Kerala', 'lon': '75.205'},
      '671311': {'lat': '12.1165', 'name': 'Elambachi,Kasargod,Kerala', 'lon': '75.1852'},
      '671312': {'lat': '12.1646', 'name': 'Padnekadapuram,Kasargod,Kerala', 'lon': '75.1372'},
      '671313': {'lat': '12.25', 'name': 'Kayyur,Kasargod,Kerala', 'lon': '75.2319'},
      '671314': {'lat': '11.8231', 'name': 'Kottamala Estate,Kasargod,Kerala', 'lon': '75.5983'},
      '671315': {'lat': '12.2277', 'name': 'Kanhangad H.O,Kasargod,Kerala', 'lon': '75.205'},
      '671316': {'lat': '12.39', 'name': 'Keekan,Kasargod,Kerala', 'lon': '75.0714'},
      '671317': {'lat': '12.4623', 'name': 'Perumbala,Kasargod,Kerala', 'lon': '74.999'},
      '182205': {'lat': '33.374', 'name': 'Inderwal,Doda,Jammu & Kashmir', 'lon': '75.6918'},
      '182204': {'lat': '32.894', 'name': 'Gulabgarh,Udhampur,Jammu & Kashmir', 'lon': '75.3276'},
      '182206': {'lat': '33.096', 'name': 'Dul Hasti Project,Doda,Jammu & Kashmir', 'lon': '75.6907'},
      '182201': {'lat': '32.9035', 'name': 'Rukali,Doda,Jammu & Kashmir', 'lon': '75.616'},
      '784190': {'lat': '26.743', 'name': 'Shamtilla,Darrang,Assam', 'lon': '93.2247'},
      '182203': {'lat': '33.0741', 'name': 'Jakyas,Doda,Jammu & Kashmir', 'lon': '75.5603'},
      '182202': {'lat': '33.058', 'name': 'Dachhan,Doda,Jammu & Kashmir', 'lon': '75.6012'},
      '174011': {'lat': '31.22846', 'name': 'Kutehla,Bilaspur (HP),Himachal Pradesh', 'lon': '76.6631'},
      '636204': {'lat': '10.7117', 'name': 'Kuralnatham,Salem,Tamil Nadu', 'lon': '78.2181'},
      '331811': {'lat': '28.0736', 'name': 'Jodhasar,Churu,Rajasthan', 'lon': '73.8161'},
      '722139': {'lat': '23.36', 'name': 'Baidyalokhesole,Bankura,West Bengal', 'lon': '87.5381'},
      '273157': {'lat': '27.1454', 'name': 'Lehrabazar,Gorakhpur,Uttar Pradesh', 'lon': '83.2762'},
      '585329': {'lat': '17.7366', 'name': 'Nandgaon,Bidar,Karnataka', 'lon': '77.3087'},
      '586108': {'lat': '16.5916', 'name': 'Bijapur Amc,Vijayapura,Karnataka', 'lon': '75.7337'},
      '586109': {'lat': '16.6972', 'name': 'Hegadihal,Vijayapura,Karnataka', 'lon': '75.9682'},
      '586104': {'lat': '16.8063', 'name': 'Honnutagi,Vijayapura,Karnataka', 'lon': '76.4769'},
      '388710': {'lat': '22.8167', 'name': 'Vachchhesar,Vadodara,Gujarat', 'lon': '73.3833'},
      '586102': {'lat': '16.8536', 'name': 'Bijapur Sainik School,Vijayapura,Karnataka', 'lon': '76.4828'},
      '586103': {'lat': '16.5757', 'name': 'Jalageri,Vijayapura,Karnataka', 'lon': '76.0095'},
      '754141': {'lat': '20.6632', 'name': 'Chatua,Jagatsinghapur,Odisha', 'lon': '85.7085'},
      '586101': {'lat': '16.6854', 'name': 'Bijapur H.O,Vijayapura,Karnataka', 'lon': '76.0927'},
      '362150': {'lat': '22.3576', 'name': 'Khandheri,Junagadh,Gujarat', 'lon': '70.7331'},
      '464884': {'lat': '23.3031', 'name': 'Bamhorigodad,Raisen,Madhya Pradesh', 'lon': '78.45'},
      '125039': {'lat': '29.3107', 'name': 'Mirchpur,Hisar,Haryana', 'lon': '76.1802'},
      '125038': {'lat': '29.2706', 'name': 'Dharam Kheri,Hisar,Haryana', 'lon': '76.3259'},
      '571108': {'lat': '12.825', 'name': 'Thammadahalli,Mysuru,Karnataka', 'lon': '76.0096'},
      '571109': {'lat': '12.0096', 'name': 'Thondavadi,Chamrajnagar,Karnataka', 'lon': '76.6645'},
      '624709': {'lat': '10.3898', 'name': 'Kallipatti,Dindigul,Tamil Nadu', 'lon': '77.82'},
      '571104': {'lat': '12.4445', 'name': 'Kailasapura,Mysuru,Karnataka', 'lon': '75.975'},
      '781104': {'lat': '26.3442', 'name': 'Nizmanakuchi,Kamrup,Assam', 'lon': '91.7577'},
      '125033': {'lat': '29.0961', 'name': 'Sindher,Hisar,Haryana', 'lon': '75.9696'},
      '571107': {'lat': '12.2839', 'name': 'Chowthi,Mysuru,Karnataka', 'lon': '76.0985'},
      '781101': {'lat': '26.3053', 'name': 'Pubborka,Kamrup,Assam', 'lon': '91.6806'},
      '571101': {'lat': '12.4165', 'name': 'Kethupura,Mysuru,Karnataka', 'lon': '76.8116'},
      '125037': {'lat': '28.9775', 'name': 'Nalwa,Hisar,Haryana', 'lon': '75.8718'},
      '781102': {'lat': '26.1954', 'name': 'Borni,Kamrup,Assam', 'lon': '91.6023'},
      '683565': {'lat': '10.002', 'name': 'Kumarapuram (Ernakulam),Ernakulam,Kerala', 'lon': '76.4005'},
      '683562': {'lat': '10.0261', 'name': 'Pattimattam,Ernakulam,Kerala', 'lon': '76.4124'},
      '683563': {'lat': '10.0261', 'name': 'Naval Armament Depot - Aluva,Ernakulam,Kerala', 'lon': '76.4124'},
      '683561': {'lat': '10.0502', 'name': 'Edathala North,Ernakulam,Kerala', 'lon': '76.4243'},
      '844502': {'lat': '25.8154', 'name': 'Hilalpur Madarpur,Vaishali,Bihar', 'lon': '85.4061'},
      '247670': {'lat': '29.7701', 'name': 'Ransura,Haridwar,Uttarakhand', 'lon': '77.98'},
      '605108': {'lat': '11.9258', 'name': 'Kallapattu,Villupuram,Tamil Nadu', 'lon': '79.5783'},
      '605109': {'lat': '12.0485', 'name': 'Katrambakkam,Villupuram,Tamil Nadu', 'lon': '79.7205'},
      '605106': {'lat': '11.8584', 'name': 'Chellanchery,Cuddalore,Tamil Nadu', 'lon': '79.6829'},
      '605107': {'lat': '11.9531', 'name': 'V.Mathur,Villupuram,Tamil Nadu', 'lon': '79.6224'},
      '605104': {'lat': '11.9347', 'name': 'Bommayarpalayam,Pondicherry,Pondicherry', 'lon': '79.5307'},
      '605105': {'lat': '11.8703', 'name': 'Mokshakulam,Villupuram,Tamil Nadu', 'lon': '79.5829'},
      '605102': {'lat': '11.9162', 'name': 'Chinnababusamudram,Villupuram,Tamil Nadu', 'lon': '79.6853'},
      '680590': {'lat': '10.7233', 'name': 'Kanjirakode,Thrissur,Kerala', 'lon': '76.3188'},
      '605101': {'lat': '12.0055', 'name': 'Morattandi,Villupuram,Tamil Nadu', 'lon': '79.8089'},
      '768113': {'lat': '21.3405', 'name': 'Sahaspur,Sambalpur,Odisha', 'lon': '83.9614'},
      '131306': {'lat': '29.1262', 'name': 'Chirana,Sonipat,Haryana', 'lon': '76.7252'},
      '768111': {'lat': '21.3812', 'name': 'Dulampur,Bargarh,Odisha', 'lon': '83.8385'},
      '768110': {'lat': '21.4757', 'name': 'Barkote,Debagarh,Odisha', 'lon': '85.0105'},
      '793018': {'lat': '25.5378', 'name': 'Diengpasoh,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '793019': {'lat': '25.5378', 'name': 'Nongmynsong,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '516127': {'lat': '14.2375', 'name': 'Penagalur,Cuddapah,Andhra Pradesh', 'lon': '79.4838'},
      '516126': {'lat': '14.1651', 'name': 'Narayana(Nellore),Cuddapah,Andhra Pradesh', 'lon': '79.1601'},
      '516129': {'lat': '13.9286', 'name': 'Rayavaram (Cuddapah),Cuddapah,Andhra Pradesh', 'lon': '79.0056'},
      '516128': {'lat': '14.2341', 'name': 'Thummachetlapalli,Cuddapah,Andhra Pradesh', 'lon': '79.2893'},
      '793016': {'lat': '25.3804', 'name': 'Mawiong,East Khasi Hills,Meghalaya', 'lon': '91.8007'},
      '793017': {'lat': '25.5378', 'name': 'Mawlai,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '793010': {'lat': '25.5378', 'name': 'Pomlakrai,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '793011': {'lat': '25.5378', 'name': 'Assam Rifles,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '793012': {'lat': '25.5378', 'name': 'Tynring,East Khasi Hills,Meghalaya', 'lon': '91.8754'},
      '604502': {'lat': '12.4311', 'name': 'Matam,Tiruvannamalai,Tamil Nadu', 'lon': '79.1677'},
      '160003': {'lat': '30.7465', 'name': 'Behlana,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '160002': {'lat': '30.7465', 'name': 'Ind Area Chandigarh,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '160001': {'lat': '30.7465', 'name': 'New Sectt Chandigarh,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '131305': {'lat': '29.1398', 'name': 'Ganewari,Sonipat,Haryana', 'lon': '76.8139'},
      '610109': {'lat': '10.7525', 'name': 'Kilapadugai,Tiruvarur,Tamil Nadu', 'lon': '79.6352'},
      '160005': {'lat': '30.7465', 'name': 'IBC Chandigarh,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '160004': {'lat': '30.7465', 'name': 'Airforce Highground,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '609102': {'lat': '12.4025', 'name': 'Anaikaranchatram South,Nagapattinam,Tamil Nadu', 'lon': '79.9866'},
      '609103': {'lat': '11.3929', 'name': 'Keelamaruthandanallur,Nagapattinam,Tamil Nadu', 'lon': '79.7677'},
      '160009': {'lat': '30.7465', 'name': 'Sector 8(Chandgarh),Chandigarh,Chandigarh', 'lon': '76.7417'},
      '609101': {'lat': '11.9262', 'name': 'Kodakkaramoolai,Nagapattinam,Tamil Nadu', 'lon': '79.6906'},
      '609106': {'lat': '11.2004', 'name': 'Neipathur,Nagapattinam,Tamil Nadu', 'lon': '79.7938'},
      '609107': {'lat': '11.3929', 'name': 'Talachangadu,Nagapattinam,Tamil Nadu', 'lon': '79.7677'},
      '609104': {'lat': '11.2753', 'name': 'Pachaiperumal Nallur,Nagapattinam,Tamil Nadu', 'lon': '79.771'},
      '609105': {'lat': '11.151', 'name': 'Kaveripattinam Beach,Nagapattinam,Tamil Nadu', 'lon': '79.8407'},
      '530046': {'lat': '17.8909', 'name': 'Vadlapudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.6924'},
      '530047': {'lat': '17.7763', 'name': 'Chintalaagaraharam,Visakhapatnam,Andhra Pradesh', 'lon': '83.2054'},
      '530044': {'lat': '17.8909', 'name': 'Nadupuru,Visakhapatnam,Andhra Pradesh', 'lon': '82.6924'},
      '530045': {'lat': '17.7603', 'name': 'Gitam Engg. College,Visakhapatnam,Andhra Pradesh', 'lon': '83.1249'},
      '209505': {'lat': '27.612', 'name': 'Sikanderpur Khas,Fatehpur,Uttar Pradesh', 'lon': '79.2188'},
      '530043': {'lat': '17.7603', 'name': 'Dayalnagar,Visakhapatnam,Andhra Pradesh', 'lon': '83.1249'},
      '530040': {'lat': '17.7603', 'name': 'Pedagadili,Visakhapatnam,Andhra Pradesh', 'lon': '83.1249'},
      '530041': {'lat': '17.7603', 'name': 'Pothinamallayapalem,Visakhapatnam,Andhra Pradesh', 'lon': '83.1249'},
      '416221': {'lat': '16.3624', 'name': 'Sake,Kolhapur,Maharashtra', 'lon': '74.314'},
      '391340': {'lat': '22.854', 'name': 'Fazalpur,Vadodara,Gujarat', 'lon': '73.4677'},
      '673603': {'lat': '11.3556', 'name': 'Ponnamkayam,Kozhikode,Kerala', 'lon': '76.0029'},
      '391346': {'lat': '22.854', 'name': 'Dhanora,Vadodara,Gujarat', 'lon': '73.4677'},
      '788701': {'lat': '24.8384', 'name': 'Adarkona,Karimganj,Assam', 'lon': '92.4575'},
      '530048': {'lat': '17.8225', 'name': 'Marikavalasa,Visakhapatnam,Andhra Pradesh', 'lon': '83.3639'},
      '530049': {'lat': '17.7', 'name': 'Vedullanarava,Visakhapatnam,Andhra Pradesh', 'lon': '83.1535'},
      '534204': {'lat': '16.5259', 'name': 'China-amiram,West Godavari,Andhra Pradesh', 'lon': '81.4262'},
      '534206': {'lat': '16.5295', 'name': 'Bondada,West Godavari,Andhra Pradesh', 'lon': '81.4549'},
      '534207': {'lat': '16.4662', 'name': 'Pedagaruvu,West Godavari,Andhra Pradesh', 'lon': '81.5964'},
      '534201': {'lat': '16.5259', 'name': 'Bhimavaram H.O,West Godavari,Andhra Pradesh', 'lon': '81.4262'},
      '534202': {'lat': '16.8327', 'name': 'D.N.R.College,West Godavari,Andhra Pradesh', 'lon': '80.8508'},
      '642133': {'lat': '10.4947', 'name': 'Topslip,Coimbatore,Tamil Nadu', 'lon': '76.8744'},
      '175140': {'lat': '32.4739', 'name': 'Koksar,Lahul & Spiti,Himachal Pradesh', 'lon': '77.13'},
      '175141': {'lat': '31.1456', 'name': 'Shiah,Mandi,Himachal Pradesh', 'lon': '77.2629'},
      '175142': {'lat': '32.7772', 'name': 'Tindi,Lahul & Spiti,Himachal Pradesh', 'lon': '76.7054'},
      '175143': {'lat': '32.1961', 'name': 'Khakhnal,Kullu,Himachal Pradesh', 'lon': '77.2045'},
      '534208': {'lat': '16.4972', 'name': 'Rayalam,West Godavari,Andhra Pradesh', 'lon': '81.5074'},
      '534209': {'lat': '16.6', 'name': 'Mogallu,West Godavari,Andhra Pradesh', 'lon': '81.5667'},
      '272199': {'lat': '26.8946', 'name': 'Pakaria,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.1071'},
      '636902': {'lat': '12.1456', 'name': 'Eachambadi,Krishnagiri,Tamil Nadu', 'lon': '78.5199'},
      '636903': {'lat': '11.785', 'name': 'Keeraipatti,Dharmapuri,Tamil Nadu', 'lon': '78.5825'},
      '522016': {'lat': '15.9738', 'name': 'Endroy,Guntur,Andhra Pradesh', 'lon': '80.3929'},
      '522017': {'lat': '16.317', 'name': 'Budampadu,Guntur,Andhra Pradesh', 'lon': '80.501'},
      '636906': {'lat': '11.8607', 'name': 'Bairnaickenpatti,Dharmapuri,Tamil Nadu', 'lon': '78.5232'},
      '522015': {'lat': '16.1311', 'name': 'Vangipuram,Guntur,Andhra Pradesh', 'lon': '80.3869'},
      '742202': {'lat': '24.5917', 'name': 'Arjunpur (MurshidabB.O,Murshidabad,West Bengal', 'lon': '87.942'},
      '415643': {'lat': '16.7838', 'name': 'Panhale,Ratnagiri,Maharashtra', 'lon': '73.6585'},
      '522018': {'lat': '16.1364', 'name': 'Gorlavaripalem,Guntur,Andhra Pradesh', 'lon': '79.8943'},
      '522019': {'lat': '16.1905', 'name': 'Koyavaripalem,Guntur,Andhra Pradesh', 'lon': '80.3374'},
      '272190': {'lat': '26.7975', 'name': 'Jinswa,Basti,Uttar Pradesh', 'lon': '82.8918'},
      '756135': {'lat': '20.71', 'name': 'Bodakpatna,Bhadrak,Odisha', 'lon': '85.2004'},
      '834010': {'lat': '23.2747', 'name': 'Tetri,Ranchi,Jharkhand', 'lon': '85.3872'},
      '230301': {'lat': '26.0309', 'name': 'Amapur Berra,Pratapgarh,Uttar Pradesh', 'lon': '82.219'},
      '230302': {'lat': '25.8587', 'name': 'Shivgarh Bindaganj,Pratapgarh,Uttar Pradesh', 'lon': '81.9485'},
      '281104': {'lat': '27.4285', 'name': 'Jalesar R.S.,Hathras,Uttar Pradesh', 'lon': '78.21'},
      '230306': {'lat': '25.7064', 'name': 'Surwa Mishirpur,Pratapgarh,Uttar Pradesh', 'lon': '82.1492'},
      '229010': {'lat': '26.2331', 'name': 'Maliak Mau Aima,Raebareli,Uttar Pradesh', 'lon': '81.3246'},
      '585104': {'lat': '17.8079', 'name': 'GB N Gunj,Kalaburagi,Karnataka', 'lon': '77.0003'},
      '394335': {'lat': '21.8241', 'name': 'Vadhvania,Surat,Gujarat', 'lon': '73.415'},
      '394330': {'lat': '22.8168', 'name': 'Orna,Surat,Gujarat', 'lon': '73.4062'},
      '284419': {'lat': '25.4898', 'name': 'Pali Pahari,Jhansi,Uttar Pradesh', 'lon': '78.6295'},
      '585102': {'lat': '17.1186', 'name': 'C J  Colony,Kalaburagi,Karnataka', 'lon': '76.5011'},
      '855108': {'lat': '26.2284', 'name': 'Khodaganj,Kishanganj,Bihar', 'lon': '87.9473'},
      '814150': {'lat': '24.1789', 'name': 'Nanhidih,Dumka,Jharkhand', 'lon': '86.5624'},
      '243601': {'lat': '27.9969', 'name': 'Patiali Sarai,Budaun,Uttar Pradesh', 'lon': '79.1562'},
      '365650': {'lat': '21.3248', 'name': 'Khadadhar,Amreli,Gujarat', 'lon': '70.5536'},
      '473995': {'lat': '25.0839', 'name': 'Budhonrajapur,Shivpuri,Madhya Pradesh', 'lon': '78.163'},
      '370430': {'lat': '23.0786', 'name': 'Meghpar,Kachchh,Gujarat', 'lon': '69.5977'},
      '370435': {'lat': '22.9199', 'name': 'Nani Khakhar,Kachchh,Gujarat', 'lon': '69.4955'},
      '147021': {'lat': '30.3561', 'name': 'Janherian,Patiala,Punjab', 'lon': '76.5128'},
      '825414': {'lat': '24.4336', 'name': 'Hunterganj,Chatra,Jharkhand', 'lon': '84.8145'},
      '384205': {'lat': '23.6015', 'name': 'Chhathiarda,Mahesana,Gujarat', 'lon': '72.5229'},
      '844505': {'lat': '25.7382', 'name': 'Rasulpur Purusottam,Vaishali,Bihar', 'lon': '85.3919'},
      '825410': {'lat': '24.5077', 'name': 'Inderwa,Koderma,Jharkhand', 'lon': '85.7416'},
      '825411': {'lat': '24.0709', 'name': 'Dantokhurd,Hazaribag,Jharkhand', 'lon': '85.2584'},
      '825412': {'lat': '24.3719', 'name': 'Khijursota,Giridh,Jharkhand', 'lon': '85.9801'},
      '825413': {'lat': '24.3677', 'name': 'Gurio,Koderma,Jharkhand', 'lon': '85.7535'},
      '734222': {'lat': '27.0565', 'name': 'Tinchuley,Darjiling,West Bengal', 'lon': '88.3313'},
      '606907': {'lat': '12.468', 'name': 'Athuvambadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.1844'},
      '606906': {'lat': '12.4632', 'name': 'Elathur,Tiruvannamalai,Tamil Nadu', 'lon': '79.0202'},
      '606905': {'lat': '12.4311', 'name': 'Ramanathapuram,Tiruvannamalai,Tamil Nadu', 'lon': '79.1677'},
      '606904': {'lat': '12.4493', 'name': 'Vilapakkam,Tiruvannamalai,Tamil Nadu', 'lon': '79.1944'},
      '635901': {'lat': '12.5887', 'name': 'Periyakasinaickenpatti,Vellore,Tamil Nadu', 'lon': '78.5866'},
      '606902': {'lat': '12.4548', 'name': 'Thumbur,Tiruvannamalai,Tamil Nadu', 'lon': '79.2738'},
      '606901': {'lat': '11.3885', 'name': 'Kidampalayam,Tiruvannamalai,Tamil Nadu', 'lon': '79.0144'},
      '721148': {'lat': '24.0497', 'name': 'Chaipat,West Midnapore,West Bengal', 'lon': '87.5756'},
      '721147': {'lat': '22.7235', 'name': 'Shyamchandpur,West Midnapore,West Bengal', 'lon': '87.975'},
      '517619': {'lat': '13.6946', 'name': 'Modugulapalem,Chittoor,Andhra Pradesh', 'lon': '79.5937'},
      '721145': {'lat': '22.6386', 'name': 'Rangmetia,West Midnapore,West Bengal', 'lon': '88.4458'},
      '721144': {'lat': '22.1624', 'name': 'Ruinan,West Midnapore,West Bengal', 'lon': '87.6297'},
      '721143': {'lat': '22.164', 'name': 'Andhari,West Midnapore,West Bengal', 'lon': '87.0901'},
      '462043': {'lat': '23.4682', 'name': 'Bag Mungalia,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '462040': {'lat': '23.4682', 'name': 'IBD Hallmark name Kolar Road.,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '606908': {'lat': '12.4042', 'name': 'Kilpalur,Tiruvannamalai,Tamil Nadu', 'lon': '78.965'},
      '507101': {'lat': '17.4307', 'name': 'Bhajanamandir,Khammam,Telangana', 'lon': '80.6189'},
      '507103': {'lat': '17.4307', 'name': 'Venkateshkhani,Khammam,Telangana', 'lon': '80.6189'},
      '461228': {'lat': '22.4094', 'name': 'Shodalpur,Hoshangabad,Madhya Pradesh', 'lon': '77.2004'},
      '573144': {'lat': '13.5159', 'name': 'Kallusadarahalli,Hassan,Karnataka', 'lon': '76.314'},
      '713344': {'lat': '23.65', 'name': 'Nandi (Bardhaman),Paschim Bardhaman,West Bengal', 'lon': '87.0047'},
      '713346': {'lat': '23.65', 'name': 'Semallya,Purba Bardhaman,West Bengal', 'lon': '87.0047'},
      '713347': {'lat': '23.65', 'name': 'Raniganj H.O,Purba Bardhaman,West Bengal', 'lon': '87.0047'},
      '713340': {'lat': '23.65', 'name': 'Kalla Central Hospital,Paschim Bardhaman,West Bengal', 'lon': '87.0047'},
      '713341': {'lat': '23.65', 'name': 'Kanyapur,Paschim Bardhaman,West Bengal', 'lon': '87.0047'},
      '713342': {'lat': '23.4278', 'name': 'Kenda,Purba Bardhaman,West Bengal', 'lon': '87.7677'},
      '122505': {'lat': '28.4655', 'name': 'Wazipur,Gurgaon,Haryana', 'lon': '76.9372'},
      '641601': {'lat': '11.0099', 'name': 'Tirupur H.O,Coimbatore,Tamil Nadu', 'lon': '77.3289'},
      '641603': {'lat': '11.1226', 'name': 'Pichampalayampudur,Coimbatore,Tamil Nadu', 'lon': '77.3099'},
      '382024': {'lat': '23.0976', 'name': '(Gandhinagar) Sector 24,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '783349': {'lat': '26.1989', 'name': 'Piazbari Makrijhora,Dhubri,Assam', 'lon': '90.1292'},
      '783348': {'lat': '26.2899', 'name': 'Bangalipara,Dhubri,Assam', 'lon': '90.3394'},
      '382021': {'lat': '23.0976', 'name': '(Gandhinagar) Sector 21,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '641606': {'lat': '10.8892', 'name': 'Vijayapuram (Coimbatore),Coimbatore,Tamil Nadu', 'lon': '77.2168'},
      '783345': {'lat': '26.3617', 'name': 'Lakhiganj Bazar,Dhubri,Assam', 'lon': '90.1824'},
      '504105': {'lat': '19.1564', 'name': 'Soan,Adilabad,Telangana', 'lon': '78.3983'},
      '783347': {'lat': '26.764', 'name': 'Banargaon,Kokrajhar,Assam', 'lon': '92.2216'},
      '783346': {'lat': '26.4852', 'name': 'Kakormari,Kokrajhar,Assam', 'lon': '90.5361'},
      '212402': {'lat': '25.6747', 'name': 'Ramgarh Kothari,Allahabad,Uttar Pradesh', 'lon': '81.9405'},
      '212401': {'lat': '25.6986', 'name': 'Mandwa Bazar,Allahabad,Uttar Pradesh', 'lon': '82.3492'},
      '382028': {'lat': '23.0976', 'name': '(Gandhinagar) Sector 28,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '627814': {'lat': '11.2589', 'name': 'Ayiraperi,Tirunelveli,Tamil Nadu', 'lon': '79.7156'},
      '305802': {'lat': '26.5266', 'name': 'Sargoan,Ajmer,Rajasthan', 'lon': '74.8525'},
      '627811': {'lat': '11.2589', 'name': 'Tenkasi RS,Tirunelveli,Tamil Nadu', 'lon': '79.7156'},
      '627812': {'lat': '11.2589', 'name': 'Vavanagaram,Tirunelveli,Tamil Nadu', 'lon': '79.7156'},
      '627813': {'lat': '11.2589', 'name': 'Kattalaikudiyiruppu,Tirunelveli,Tamil Nadu', 'lon': '79.7156'},
      '627818': {'lat': '8.7987', 'name': 'Melagaram,Tirunelveli,Tamil Nadu', 'lon': '77.542'},
      '851126': {'lat': '25.4221', 'name': 'Simaria,Begusarai,Bihar', 'lon': '86.006'},
      '854109': {'lat': '25.619', 'name': 'Katihar Court,Katihar,Bihar', 'lon': '87.3422'},
      '768112': {'lat': '21.4501', 'name': 'Dangarpara,Sambalpur,Odisha', 'lon': '84.1733'},
      '360550': {'lat': '21.6873', 'name': 'Ranavav Railway Station,Porbandar,Gujarat', 'lon': '69.7448'},
      '628851': {'lat': '8.7483', 'name': 'Ellanaickenpatti,Tuticorin,Tamil Nadu', 'lon': '77.9377'},
      '388170': {'lat': '22.8168', 'name': 'Kanisa,Anand,Gujarat', 'lon': '73.4062'},
      '642207': {'lat': '10.6405', 'name': 'Chellappampalayam,Coimbatore,Tamil Nadu', 'lon': '77.226'},
      '630502': {'lat': '10.2161', 'name': 'Kannamangalapatti,Sivaganga,Tamil Nadu', 'lon': '78.4238'},
      '630501': {'lat': '10.4156', 'name': 'Surakudi,Sivaganga,Tamil Nadu', 'lon': '78.4001'},
      '721517': {'lat': '22.2721', 'name': 'Kanpursalbani,West Midnapore,West Bengal', 'lon': '86.9541'},
      '631101': {'lat': '13.0939', 'name': 'Nandiveduthangal,Vellore,Tamil Nadu', 'lon': '79.5789'},
      '814153': {'lat': '24.1179', 'name': 'Mohani,Godda,Jharkhand', 'lon': '84.9888'},
      '631102': {'lat': '13.0354', 'name': 'Kondapalayam,Vellore,Tamil Nadu', 'lon': '79.4467'},
      '144224': {'lat': '31.8232', 'name': 'Ramgarh Sikri,Nawanshahr,Punjab', 'lon': '75.78'},
      '221409': {'lat': '25.4308', 'name': 'Kukarauthi,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.54899'},
      '144221': {'lat': '31.8563', 'name': 'Ghagwal,Hoshiarpur,Punjab', 'lon': '75.7345'},
      '144223': {'lat': '31.7723', 'name': 'Behlakhan,Hoshiarpur,Punjab', 'lon': '75.8302'},
      '144222': {'lat': '31.9746', 'name': 'Handwal,Hoshiarpur,Punjab', 'lon': '75.7936'},
      '481332': {'lat': '21.7991', 'name': 'Jarah Mohgaon,Balaghat,Madhya Pradesh', 'lon': '79.9194'},
      '821312': {'lat': '24.912', 'name': 'Mahrajganj,Rohtas,Bihar', 'lon': '84.0759'},
      '453661': {'lat': '22.4306', 'name': 'Khedi Sihod,Indore,Madhya Pradesh', 'lon': '75.6367'},
      '506009': {'lat': '18.0142', 'name': 'Vidyaranyapuri,Warangal,Telangana', 'lon': '79.5184'},
      '389170': {'lat': '23.1009', 'name': 'Dhavadia,Dahod,Gujarat', 'lon': '74.1792'},
      '389172': {'lat': '22.9341', 'name': 'Bhanasimal,Dahod,Gujarat', 'lon': '74.00552'},
      '506008': {'lat': '18.0142', 'name': 'Railway Gate,Warangal,Telangana', 'lon': '79.5184'},
      '734223': {'lat': '26.8547', 'name': 'Norbung,Darjiling,West Bengal', 'lon': '88.3436'},
      '389175': {'lat': '23.06929', 'name': 'Vansia,Dahod,Gujarat', 'lon': '73.95943'},
      '571315': {'lat': '12.2879', 'name': 'Naganapura,Mysuru,Karnataka', 'lon': '76.3507'},
      '110088': {'lat': '28.7219', 'name': 'Shalamar,North West Delhi,Delhi', 'lon': '77.159'},
      '110089': {'lat': '28.7441', 'name': 'Rohini Sector 15,North West Delhi,Delhi', 'lon': '77.0732'},
      '571314': {'lat': '12.2495', 'name': 'Debur,Mysuru,Karnataka', 'lon': '76.432'},
      '110084': {'lat': '28.7486', 'name': 'Nathupura Village,New Delhi,Delhi', 'lon': '77.2096'},
      '110085': {'lat': '28.7201', 'name': 'Avantika,North Delhi,Delhi', 'lon': '77.1027'},
      '110086': {'lat': '28.7441', 'name': 'Kirari Suleman Nagar,North Delhi,Delhi', 'lon': '77.0732'},
      '110087': {'lat': '28.7441', 'name': 'Peeragarhi,West Delhi,Delhi', 'lon': '77.0732'},
      '110080': {'lat': '28.7441', 'name': 'Sangam Vihar,South Delhi,Delhi', 'lon': '77.0732'},
      '110081': {'lat': '28.7759', 'name': 'Punjab Khor,North West Delhi,Delhi', 'lon': '76.9649'},
      '110082': {'lat': '28.7745', 'name': 'Khera Kalan,North West Delhi,Delhi', 'lon': '77.1172'},
      '110083': {'lat': '28.7441', 'name': 'Mangolpuri I  Block,North West Delhi,Delhi', 'lon': '77.0732'},
      '571316': {'lat': '12.0788', 'name': 'Heggavady,Chamrajnagar,Karnataka', 'lon': '76.9099'},
      '531149': {'lat': '18.3767', 'name': 'Iragi,Visakhapatnam,Andhra Pradesh', 'lon': '83.0001'},
      '571311': {'lat': '12.2104', 'name': 'Ayyarahalli,Mysuru,Karnataka', 'lon': '76.7333'},
      '506168': {'lat': '18.2969', 'name': 'Gorlavaid,Warangal,Telangana', 'lon': '78.6865'},
      '332713': {'lat': '27.2792', 'name': 'Bhudoli,Sikar,Rajasthan', 'lon': '75.6453'},
      '571313': {'lat': '12.0178', 'name': 'Chamarajanagara,Chamrajnagar,Karnataka', 'lon': '76.9212'},
      '571312': {'lat': '12.3192', 'name': 'Vidyapeetha,Mysuru,Karnataka', 'lon': '76.6233'},
      '414205': {'lat': '18.9457', 'name': 'Murshadpur,Beed,Maharashtra', 'lon': '76.8963'},
      '414204': {'lat': '19.0028', 'name': 'Domri,Beed,Maharashtra', 'lon': '74.4384'},
      '500027': {'lat': '17.456', 'name': 'Stn Kachiguda H.O,Hyderabad,Telangana', 'lon': '78.6476'},
      '414201': {'lat': '19.0005', 'name': 'Sandve,Ahmed Nagar,Maharashtra', 'lon': '74.9178'},
      '414203': {'lat': '18.9926', 'name': 'Welturi,Beed,Maharashtra', 'lon': '75.543'},
      '414202': {'lat': '19.4436', 'name': 'Kuntephal Pundi,Beed,Maharashtra', 'lon': '75.8416'},
      '768109': {'lat': '21.3351', 'name': 'Kantabahal,Debagarh,Odisha', 'lon': '84.8094'},
      '508376': {'lat': '17.4396', 'name': 'Pinnaipalem,Nalgonda,Telangana', 'lon': '79.3672'},
      '250110': {'lat': '29.066', 'name': 'Dulhera,Meerut,Uttar Pradesh', 'lon': '77.6945'},
      '508373': {'lat': '16.8933', 'name': 'Gatlamallepally,Nalgonda,Telangana', 'lon': '78.967'},
      '414208': {'lat': '19.5053', 'name': 'Nimgaon Choba,Beed,Maharashtra', 'lon': '76.1327'},
      '276203': {'lat': '25.6875', 'name': 'Kubakhas,Azamgarh,Uttar Pradesh', 'lon': '83.1047'},
      '276202': {'lat': '26.0431', 'name': 'Karsara,Mau,Uttar Pradesh', 'lon': '83.5629'},
      '276201': {'lat': '25.7013', 'name': 'Bahadur Pur,Mau,Uttar Pradesh', 'lon': '82.9831'},
      '276207': {'lat': '26.2477', 'name': 'Tegar Pur,Azamgarh,Uttar Pradesh', 'lon': '82.7808'},
      '276206': {'lat': '26.0582', 'name': 'Mohanath,Azamgarh,Uttar Pradesh', 'lon': '82.6895'},
      '246731': {'lat': '29.1947', 'name': 'Mauzzampur Narayan,Bijnor,Uttar Pradesh', 'lon': '78.2582'},
      '276204': {'lat': '25.852', 'name': 'Rasoolpur,Mau,Uttar Pradesh', 'lon': '83.097'},
      '466221': {'lat': '23.0615', 'name': 'Arniyaganji,Sehore,Madhya Pradesh', 'lon': '76.5302'},
      '276208': {'lat': '25.8691', 'name': 'Kisundaspur,Azamgarh,Uttar Pradesh', 'lon': '82.5345'},
      '784524': {'lat': '26.6138', 'name': 'Ghoghrabazar,Darrang,Assam', 'lon': '92.0365'},
      '784525': {'lat': '26.6257', 'name': 'Kabir Ali,Darrang,Assam', 'lon': '92.2582'},
      '784526': {'lat': '26.741', 'name': 'Pachim Patla,Darrang,Assam', 'lon': '91.8212'},
      '784527': {'lat': '26.6138', 'name': 'No.2 Barangajuli,Darrang,Assam', 'lon': '92.0365'},
      '423607': {'lat': '19.9776', 'name': 'Maygaon Devi,Ahmed Nagar,Maharashtra', 'lon': '75.3028'},
      '784521': {'lat': '26.6569', 'name': 'Mechakapukhuri,Darrang,Assam', 'lon': '91.9138'},
      '784522': {'lat': '26.6384', 'name': 'Bholaguri,Darrang,Assam', 'lon': '91.779'},
      '784523': {'lat': '26.6138', 'name': 'Ratanpur,Darrang,Assam', 'lon': '92.0365'},
      '342023': {'lat': '26.4455', 'name': 'Bhandu Jati,Jodhpur,Rajasthan', 'lon': '72.5456'},
      '342022': {'lat': '26.349', 'name': 'Sai,Jodhpur,Rajasthan', 'lon': '72.9566'},
      '342021': {'lat': '26.5492', 'name': 'Jaitsar,Jodhpur,Rajasthan', 'lon': '72.6954'},
      '784528': {'lat': '26.6138', 'name': 'Nassansali,Darrang,Assam', 'lon': '92.0365'},
      '784529': {'lat': '26.3833', 'name': 'Chapaichowka,Darrang,Assam', 'lon': '91.9667'},
      '342025': {'lat': '25.9323', 'name': 'Bhalu Rajwan,Jodhpur,Rajasthan', 'lon': '73.6381'},
      '342024': {'lat': '26.1925', 'name': 'Popawas,Jodhpur,Rajasthan', 'lon': '73.0443'},
      '208008': {'lat': '26.5167', 'name': 'CAT,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '208009': {'lat': '26.5167', 'name': 'Armapore,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '141117': {'lat': '31.164', 'name': 'Nanakpur Jagera,Ludhiana,Punjab', 'lon': '75.8672'},
      '491661': {'lat': '20.8359', 'name': 'Umarwahi,Rajnandgaon,Chattisgarh', 'lon': '80.8937'},
      '141110': {'lat': '31.0136', 'name': 'Bassian Bet,Ludhiana,Punjab', 'lon': '75.6088'},
      '208002': {'lat': '26.5167', 'name': 'Employeement Exchange,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '208003': {'lat': '26.379', 'name': 'Chauki Jarib,Kanpur Nagar,Uttar Pradesh', 'lon': '81.3448'},
      '208004': {'lat': '26.5167', 'name': 'Kanpur Cantt. H.O,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '208005': {'lat': '26.5167', 'name': 'Shivaji Nagar (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '824211': {'lat': '24.6882', 'name': 'Betibigha,Gaya,Bihar', 'lon': '84.6966'},
      '505416': {'lat': '18.5387', 'name': 'Pathipaka,Karim Nagar,Telangana', 'lon': '79.0803'},
      '814152': {'lat': '24.0961', 'name': 'Ghorlas,Deoghar,Jharkhand', 'lon': '86.0511'},
      '500083': {'lat': '17.3898', 'name': 'Nagaram (K.V.Rangareddy),Hyderabad,Telangana', 'lon': '78.4699'},
      '754035': {'lat': '20.3044', 'name': 'Maniabandha,Cuttack,Odisha', 'lon': '85.1169'},
      '484770': {'lat': '23.9491', 'name': 'Nawangaon,Anuppur,Madhya Pradesh', 'lon': '80.4419'},
      '518225': {'lat': '15.5452', 'name': 'Erukalacheruvu,Kurnool,Andhra Pradesh', 'lon': '77.7717'},
      '141113': {'lat': '30.8445', 'name': 'Chhandran,Ludhiana,Punjab', 'lon': '76.0807'},
      '518220': {'lat': '15.3544', 'name': 'Alebad,Kurnool,Andhra Pradesh', 'lon': '77.8684'},
      '518221': {'lat': '15.239', 'name': 'Hussainapuram,Kurnool,Andhra Pradesh', 'lon': '77.9407'},
      '518222': {'lat': '15.3968', 'name': 'Udumulapadu,Kurnool,Andhra Pradesh', 'lon': '77.8627'},
      '828302': {'lat': '23.7167', 'name': 'Amlabad Colliery,Bokaro,Jharkhand', 'lon': '86.4'},
      '486888': {'lat': '24.2667', 'name': 'Dudhichua Project,Singrauli,Madhya Pradesh', 'lon': '82.1937'},
      '396140': {'lat': '20.4394', 'name': 'Phansa,Valsad,Gujarat', 'lon': '73.0413'},
      '491445': {'lat': '21.1584', 'name': 'Paragaonkhurd,Rajnandgaon,Chattisgarh', 'lon': '80.8048'},
      '505503': {'lat': '18.1921', 'name': 'Vilasagar,Karim Nagar,Telangana', 'lon': '79.5066'},
      '396145': {'lat': '20.4394', 'name': 'Barai,Valsad,Gujarat', 'lon': '73.0413'},
      '734421': {'lat': '26.6827', 'name': 'Bagdogra Air Port,Darjiling,West Bengal', 'lon': '88.22'},
      '261206': {'lat': '27.3038', 'name': 'Ishwarwara,Sitapur,Uttar Pradesh', 'lon': '81.2707'},
      '486553': {'lat': '24.5199', 'name': 'Patauta,Rewa,Madhya Pradesh', 'lon': '81.4432'},
      '515571': {'lat': '13.8714', 'name': 'Diguvathotlipalli,Ananthapur,Andhra Pradesh', 'lon': '78.352'},
      '833222': {'lat': '22.7406', 'name': 'Kiriburu Base Camp,West Singhbhum,Jharkhand', 'lon': '85.6306'},
      '486556': {'lat': '25.0308', 'name': 'Kotwa,Rewa,Madhya Pradesh', 'lon': '81.3191'},
      '848121': {'lat': '25.8647', 'name': 'Nikaspur Nisfi,Samastipur,Bihar', 'lon': '85.6783'},
      '486886': {'lat': '24.0448', 'name': 'Mada,Singrauli,Madhya Pradesh', 'lon': '82.5148'},
      '486887': {'lat': '24.2667', 'name': 'Amlori Project,Singrauli,Madhya Pradesh', 'lon': '82.1937'},
      '391421': {'lat': '22.9652', 'name': 'Sampla,Vadodara,Gujarat', 'lon': '73.0469'},
      '261204': {'lat': '27.302', 'name': 'Para Ramnagra,Sitapur,Uttar Pradesh', 'lon': '81.4993'},
      '690513': {'lat': '9.4277', 'name': 'Akamkudy,Alappuzha,Kerala', 'lon': '76.4746'},
      '473287': {'lat': '24.181', 'name': 'Todra,Guna,Madhya Pradesh', 'lon': '77.2072'},
      '690512': {'lat': '9.2788', 'name': 'Naduvattom,Alappuzha,Kerala', 'lon': '76.4863'},
      '606107': {'lat': '11.7902', 'name': 'Ulundurpet Busstand,Villupuram,Tamil Nadu', 'lon': '79.3263'},
      '445209': {'lat': '19.9821', 'name': 'Sawargaon Bunglow,Yavatmal,Maharashtra', 'lon': '77.6328'},
      '261201': {'lat': '27.1853', 'name': 'Keoty Badullah,Sitapur,Uttar Pradesh', 'lon': '80.9687'},
      '636013': {'lat': '11.6714', 'name': 'Salem Steel Plant,Salem,Tamil Nadu', 'lon': '78.0126'},
      '445203': {'lat': '20.2376', 'name': 'Tiwari,Yavatmal,Maharashtra', 'lon': '77.7356'},
      '445202': {'lat': '20.3518', 'name': 'Shendri (Bk) B..,Yavatmal,Maharashtra', 'lon': '77.6873'},
      '445201': {'lat': '19.9283', 'name': 'Borgaon (Linga ),Yavatmal,Maharashtra', 'lon': '77.5185'},
      '690510': {'lat': '9.4277', 'name': 'Olakettiambalam,Alappuzha,Kerala', 'lon': '76.4746'},
      '445207': {'lat': '19.5771', 'name': 'Bittergaon,Yavatmal,Maharashtra', 'lon': '77.9374'},
      '445206': {'lat': '19.6463', 'name': 'Marsul,Yavatmal,Maharashtra', 'lon': '77.6631'},
      '445205': {'lat': '19.9019', 'name': 'Mahagaon (Yavatmal),Yavatmal,Maharashtra', 'lon': '77.6197'},
      '445204': {'lat': '20.0455', 'name': 'Lewa,Yavatmal,Maharashtra', 'lon': '77.4458'},
      '582102': {'lat': '15.5544', 'name': 'Gadag Betgeri,Gadag,Karnataka', 'lon': '75.6539'},
      '582103': {'lat': '15.3306', 'name': 'Beldhadi,Gadag,Karnataka', 'lon': '75.6314'},
      '582101': {'lat': '15.3978', 'name': 'Gadag H.O,Gadag,Karnataka', 'lon': '75.6381'},
      '582104': {'lat': '15.3978', 'name': 'GIMS Gadag,Gadag,Karnataka', 'lon': '75.6381'},
      '821104': {'lat': '25.0154', 'name': 'Bhitari Bandh,Rohtas,Bihar', 'lon': '84.4619'},
      '534432': {'lat': '16.7578', 'name': 'Sriramvaram,West Godavari,Andhra Pradesh', 'lon': '81.1649'},
      '534437': {'lat': '16.5839', 'name': 'Satyavole,West Godavari,Andhra Pradesh', 'lon': '81.0381'},
      '534435': {'lat': '16.8863', 'name': 'Kuchimpudi,West Godavari,Andhra Pradesh', 'lon': '81.1083'},
      '606102': {'lat': '11.7145', 'name': 'Orathur,Villupuram,Tamil Nadu', 'lon': '79.2887'},
      '630561': {'lat': '10.0047', 'name': 'Sivaganga North,Sivaganga,Tamil Nadu', 'lon': '78.435'},
      '759102': {'lat': '20.9513', 'name': 'Deulbera Colliery,Angul,Odisha', 'lon': '85.2069'},
      '735205': {'lat': '26.7644', 'name': 'Carron,Jalpaiguri,West Bengal', 'lon': '88.8616'},
      '504231': {'lat': '19.0517', 'name': 'Mandamarri R.S.,Adilabad,Telangana', 'lon': '79.4126'},
      '768119': {'lat': '21.4716', 'name': 'K.Tainsar,Debagarh,Odisha', 'lon': '84.6866'},
      '246734': {'lat': '29.1263', 'name': 'Murhat,Bijnor,Uttar Pradesh', 'lon': '78.4041'},
      '246735': {'lat': '29.1505', 'name': 'Bhogpur,Bijnor,Uttar Pradesh', 'lon': '78.544'},
      '246736': {'lat': '29.0705', 'name': 'Bashta,Bijnor,Uttar Pradesh', 'lon': '78.214'},
      '246737': {'lat': '29.1618', 'name': 'Rahu Nagli,Bijnor,Uttar Pradesh', 'lon': '78.3754'},
      '458470': {'lat': '24.6056', 'name': 'Khor,Neemuch,Madhya Pradesh', 'lon': '75.1446'},
      '246732': {'lat': '29.6418', 'name': 'Sabdalpur Bitra,Bijnor,Uttar Pradesh', 'lon': '78.2145'},
      '722208': {'lat': '22.7496', 'name': 'Tajpur Purakona,Bankura,West Bengal', 'lon': '87.8749'},
      '209871': {'lat': '26.2482', 'name': 'Zamuniya Kachh,Unnao,Uttar Pradesh', 'lon': '80.8883'},
      '700029': {'lat': '22.4826', 'name': 'Dover Lane,Kolkata,West Bengal', 'lon': '88.4744'},
      '700028': {'lat': '22.3539', 'name': 'Rajabagan,Kolkata,West Bengal', 'lon': '88.7833'},
      '422008': {'lat': '20.2589', 'name': 'Trimurti Chowk,Nashik,Maharashtra', 'lon': '74.5521'},
      '422009': {'lat': '20.2589', 'name': 'Cidco Colony (Nashik),Nashik,Maharashtra', 'lon': '74.5521'},
      '422006': {'lat': '20.3795', 'name': 'Upnagar,Nashik,Maharashtra', 'lon': '73.9848'},
      '422007': {'lat': '20.2542', 'name': 'Industrial Estate (Nashik),Nashik,Maharashtra', 'lon': '75.1496'},
      '422004': {'lat': '20.2798', 'name': 'Dhakambe,Nashik,Maharashtra', 'lon': '74.0201'},
      '422005': {'lat': '20.2589', 'name': 'Bhonsala Military School,Nashik,Maharashtra', 'lon': '74.5521'},
      '422002': {'lat': '20.2589', 'name': 'Jeevan Prakash,Nashik,Maharashtra', 'lon': '74.5521'},
      '422003': {'lat': '20.2377', 'name': 'Lakhalgaon,Nashik,Maharashtra', 'lon': '74.4365'},
      '700027': {'lat': '22.4826', 'name': 'Alipore Civil Court,Kolkata,West Bengal', 'lon': '88.4744'},
      '422001': {'lat': '20.2589', 'name': 'Budhwar Peth,Nashik,Maharashtra', 'lon': '74.5521'},
      '601102': {'lat': '13.1879', 'name': 'Athivakkam,Kanchipuram,Tamil Nadu', 'lon': '79.7968'},
      '601103': {'lat': '13.1666', 'name': 'Tirukandalam,Tiruvallur,Tamil Nadu', 'lon': '79.8187'},
      '601101': {'lat': '13.3448', 'name': 'Pondavakkam,Tiruvallur,Tamil Nadu', 'lon': '80.0996'},
      '828109': {'lat': '24.1171', 'name': 'Bardohi,Dhanbad,Jharkhand', 'lon': '86.4667'},
      '828108': {'lat': '24.0728', 'name': 'Fri,Dhanbad,Jharkhand', 'lon': '86.506'},
      '614303': {'lat': '10.8744', 'name': 'Agaramangudi,Thanjavur,Tamil Nadu', 'lon': '79.2302'},
      '828105': {'lat': '24.0728', 'name': 'Shramik Nagar,Dhanbad,Jharkhand', 'lon': '86.506'},
      '828104': {'lat': '24.0854', 'name': 'Bowakalan,Dhanbad,Jharkhand', 'lon': '86.9118'},
      '491441': {'lat': '20.9902', 'name': 'Hardi,Durg,Chattisgarh', 'lon': '81.0966'},
      '828106': {'lat': '24.0728', 'name': 'Bastacola,Dhanbad,Jharkhand', 'lon': '86.506'},
      '828101': {'lat': '23.7943', 'name': 'Sendra,Dhanbad,Jharkhand', 'lon': '86.3359'},
      '635206': {'lat': '12.3698', 'name': 'Santhur,Krishnagiri,Tamil Nadu', 'lon': '78.5085'},
      '458883': {'lat': '24.1946', 'name': 'Melkheda,Mandsaur,Madhya Pradesh', 'lon': '75.5517'},
      '302020': {'lat': '26.8626', 'name': 'Mansarovar,Jaipur,Rajasthan', 'lon': '75.7633'},
      '581201': {'lat': '15.3894', 'name': 'Karadigudda,Dharwad,Karnataka', 'lon': '75.1538'},
      '271801': {'lat': '27.5253', 'name': 'Qanoongopura,Bahraich,Uttar Pradesh', 'lon': '81.8038'},
      '271802': {'lat': '27.6053', 'name': 'Chaukgoi,Shrawasti,Uttar Pradesh', 'lon': '81.6129'},
      '271803': {'lat': '27.7184', 'name': 'Laxmanpur Kothi,Shrawasti,Uttar Pradesh', 'lon': '81.9715'},
      '271804': {'lat': '27.455', 'name': 'Doobkalan,Shrawasti,Uttar Pradesh', 'lon': '81.7283'},
      '722203': {'lat': '23.043', 'name': 'Barbendya,Bankura,West Bengal', 'lon': '87.5309'},
      '271806': {'lat': '27.5476', 'name': 'Farda Sumerpur,Bahraich,Uttar Pradesh', 'lon': '81.684'},
      '458888': {'lat': '23.3647', 'name': 'Dhabla Bhagwan,Mandsaur,Madhya Pradesh', 'lon': '75.5507'},
      '517426': {'lat': '13.7135', 'name': 'Kanamanapalle,Chittoor,Andhra Pradesh', 'lon': '79.1475'},
      '517425': {'lat': '12.9027', 'name': 'Peddabangarunatham,Chittoor,Andhra Pradesh', 'lon': '78.5513'},
      '517424': {'lat': '13.1755', 'name': 'Bairupalle,Chittoor,Andhra Pradesh', 'lon': '78.5726'},
      '517423': {'lat': '13.0874', 'name': 'Sogadaballa,Chittoor,Andhra Pradesh', 'lon': '78.5482'},
      '517422': {'lat': '13.4592', 'name': 'Kandriga,Chittoor,Andhra Pradesh', 'lon': '78.8769'},
      '517421': {'lat': '13.2166', 'name': 'Varathur,Chittoor,Andhra Pradesh', 'lon': '79.2876'},
      '695604': {'lat': '8.7477', 'name': 'Kokkottukonam,Thiruvananthapuram,Kerala', 'lon': '76.8535'},
      '695605': {'lat': '8.7646', 'name': 'Kallambalam,Thiruvananthapuram,Kerala', 'lon': '76.7885'},
      '695606': {'lat': '8.7113', 'name': 'Nellanad,Thiruvananthapuram,Kerala', 'lon': '76.8967'},
      '695607': {'lat': '8.6601', 'name': 'Vellumannady,Thiruvananthapuram,Kerala', 'lon': '76.9156'},
      '503308': {'lat': '18.9741', 'name': 'Hasakothur,Nizamabad,Telangana', 'lon': '78.8067'},
      '695601': {'lat': '8.7613', 'name': 'Kilimanur Palace,Thiruvananthapuram,Kerala', 'lon': '76.8545'},
      '695602': {'lat': '8.7477', 'name': 'Malakkal,Thiruvananthapuram,Kerala', 'lon': '76.8535'},
      '695603': {'lat': '8.791', 'name': 'Maruthikunnu,Thiruvananthapuram,Kerala', 'lon': '76.7866'},
      '682554': {'lat': '9.1858', 'name': 'Chetlat,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '682555': {'lat': '9.1858', 'name': 'Kavaratti H.O,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '682556': {'lat': '9.1858', 'name': 'Kadamat,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '682557': {'lat': '10.0826', 'name': 'Kalpeni,Lakshadweep,Lakshadweep', 'lon': '73.6432'},
      '825303': {'lat': '24.0869', 'name': 'Rolla,Hazaribag,Jharkhand', 'lon': '85.2116'},
      '682551': {'lat': '9.1858', 'name': 'Androth,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '682552': {'lat': '9.1858', 'name': 'Amini,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '682553': {'lat': '9.1858', 'name': 'Agathi,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '800020': {'lat': '25.5831', 'name': 'Chitragupta Nagar,Patna,Bihar', 'lon': '85.1358'},
      '691515': {'lat': '8.9247', 'name': 'Nallila,Kollam,Kerala', 'lon': '76.7097'},
      '691516': {'lat': '8.86', 'name': 'Karingannur,Kollam,Kerala', 'lon': '76.7918'},
      '722206': {'lat': '22.6438', 'name': 'Betur,Bankura,West Bengal', 'lon': '88.2308'},
      '691510': {'lat': '8.9333', 'name': 'Meeyana,Kollam,Kerala', 'lon': '76.8687'},
      '691511': {'lat': '8.9538', 'name': 'Vellimon West,Kollam,Kerala', 'lon': '76.6498'},
      '691512': {'lat': '8.9111', 'name': 'Odanavattom,Kollam,Kerala', 'lon': '76.7844'},
      '800027': {'lat': '25.5904', 'name': 'New Jaganpura,Patna,Bihar', 'lon': '85.1624'},
      '415122': {'lat': '15.8832', 'name': 'Yeravale,Satara,Maharashtra', 'lon': '74.2736'},
      '275304': {'lat': '26.4722', 'name': 'Manik Pur Haraura,Mau,Uttar Pradesh', 'lon': '83.2724'},
      '415124': {'lat': '15.8832', 'name': 'Khodoshi,Satara,Maharashtra', 'lon': '74.2736'},
      '574313': {'lat': '12.5207', 'name': 'Soole Padavu,Dakshina Kannada,Karnataka', 'lon': '75.4399'},
      '388410': {'lat': '22.8406', 'name': 'Bandhani,Kheda,Gujarat', 'lon': '72.9044'},
      '509410': {'lat': '17.0566', 'name': 'Yelikatta,Mahabub Nagar,Telangana', 'lon': '78.1161'},
      '574314': {'lat': '12.5207', 'name': 'Peraje,Kodagu,Karnataka', 'lon': '75.4399'},
      '275302': {'lat': '26.0813', 'name': 'Fateh Pur Tal Naraja,Mau,Uttar Pradesh', 'lon': '83.4519'},
      '364465': {'lat': '21.8211', 'name': 'Derdi Kumbaji,Rajkot,Gujarat', 'lon': '70.9454'},
      '275301': {'lat': '25.9969', 'name': 'Katirahi Bugurg,Mau,Uttar Pradesh', 'lon': '83.5384'},
      '176601': {'lat': '32.0893', 'name': 'Singan,Una,Himachal Pradesh', 'lon': '76.3292'},
      '365220': {'lat': '23.0327', 'name': 'Bhurakhiya,Amreli,Gujarat', 'lon': '71.9838'},
      '470226': {'lat': '23.3455', 'name': 'Bijora,Sagar,Madhya Pradesh', 'lon': '79.0298'},
      '470221': {'lat': '23.6327', 'name': 'Chatubhata,Sagar,Madhya Pradesh', 'lon': '78.8346'},
      '470223': {'lat': '23.5934', 'name': 'Baheriya Kalan,Sagar,Madhya Pradesh', 'lon': '78.9389'},
      '384265': {'lat': '23.479', 'name': 'Anawada,Patan,Gujarat', 'lon': '73.2181'},
      '470229': {'lat': '23.7791', 'name': 'Garhakota,Sagar,Madhya Pradesh', 'lon': '79.1432'},
      '470228': {'lat': '23.7256', 'name': 'Bamori Bika,Sagar,Madhya Pradesh', 'lon': '79.0579'},
      '500025': {'lat': '17.3753', 'name': 'Rail Nilayam,Hyderabad,Telangana', 'lon': '78.4744'},
      '384260': {'lat': '23.7323', 'name': 'Pali,Mahesana,Gujarat', 'lon': '72.2607'},
      '276135': {'lat': '26.1268', 'name': 'Shbili,Azamgarh,Uttar Pradesh', 'lon': '83.0621'},
      '731133': {'lat': '24.1261', 'name': 'Ranipathar,Birbhum,West Bengal', 'lon': '87.6915'},
      '731132': {'lat': '24.1261', 'name': 'Nischintapur Jagatpur,Birbhum,West Bengal', 'lon': '87.6915'},
      '731130': {'lat': '24.1261', 'name': 'Kundira,Birbhum,West Bengal', 'lon': '87.6915'},
      '841427': {'lat': '26.5316', 'name': 'Purdiltola,Gopalganj,Bihar', 'lon': '84.5421'},
      '841426': {'lat': '26.4659', 'name': 'Matiyari,Gopalganj,Bihar', 'lon': '84.3414'},
      '841425': {'lat': '26.416', 'name': 'Bathubazar,Gopalganj,Bihar', 'lon': '84.4186'},
      '841424': {'lat': '25.8878', 'name': 'Pachraur,Saran,Bihar', 'lon': '84.8058'},
      '123301': {'lat': '28.3848', 'name': 'Jakhala,Rewari,Haryana', 'lon': '76.5351'},
      '194401': {'lat': '34.5509408', 'name': 'Bogdang,Leh,Jammu & Kashmir', 'lon': '77.553338'},
      '123303': {'lat': '28.4102', 'name': 'Nahar,Rewari,Haryana', 'lon': '76.3981'},
      '123302': {'lat': '28.3498', 'name': 'Tumna,Rewari,Haryana', 'lon': '76.5026'},
      '781310': {'lat': '26.4348', 'name': 'Khakrisal,Nalbari,Assam', 'lon': '91.1657'},
      '781311': {'lat': '26.4348', 'name': 'Erabamundi,Barpeta,Assam', 'lon': '91.1657'},
      '781312': {'lat': '26.4011', 'name': 'Chatla,Nalbari,Assam', 'lon': '91.2721'},
      '781313': {'lat': '26.4348', 'name': 'Simlaguri,Barpeta,Assam', 'lon': '91.1657'},
      '781314': {'lat': '26.4348', 'name': 'Santinagar (Barpeta),Barpeta,Assam', 'lon': '91.1657'},
      '781315': {'lat': '26.5028', 'name': 'Katajhar,Barpeta,Assam', 'lon': '90.9694'},
      '781316': {'lat': '26.4224', 'name': 'Ambari,Barpeta,Assam', 'lon': '90.98'},
      '781317': {'lat': '26.5541', 'name': 'Sukurngbari,Nalbari,Assam', 'lon': '90.5591'},
      '781318': {'lat': '26.5107', 'name': 'Samuagati,Barpeta,Assam', 'lon': '91.9216'},
      '781319': {'lat': '26.2457', 'name': 'Bankubhanga,Barpeta,Assam', 'lon': '91.101'},
      '560092': {'lat': '13.2257', 'name': 'Byatarayanapura,Bengaluru,Karnataka', 'lon': '77.575'},
      '560093': {'lat': '13.1077', 'name': 'C.V.Raman Nagar,Bengaluru,Karnataka', 'lon': '77.581'},
      '560094': {'lat': '13.1077', 'name': 'ISRO Anthariksha Bhavan,Bengaluru,Karnataka', 'lon': '77.581'},
      '560095': {'lat': '13.1077', 'name': 'Koramangala VI Bk,Bengaluru,Karnataka', 'lon': '77.581'},
      '560096': {'lat': '13.2257', 'name': 'Nandinilayout,Bengaluru,Karnataka', 'lon': '77.575'},
      '560097': {'lat': '13.2257', 'name': 'Vidyaranyapura,Bengaluru,Karnataka', 'lon': '77.575'},
      '737107': {'lat': '27.59275', 'name': 'Lumlingthyang,East Sikkim,Sikkim', 'lon': '88.48882'},
      '785631': {'lat': '27.2498', 'name': 'Paanjaan Borholla,Jorhat,Assam', 'lon': '94.7987'},
      '509339': {'lat': '17.1808', 'name': 'Pothreddipalli,Mahabub Nagar,Telangana', 'lon': '77.7229'},
      '412303': {'lat': '18.4498', 'name': 'Walunj,Pune,Maharashtra', 'lon': '73.9835'},
      '821112': {'lat': '25.2828', 'name': 'Doiya,Rohtas,Bihar', 'lon': '83.9592'},
      '689699': {'lat': '9.0863', 'name': 'Thekkuthode,Pathanamthitta,Kerala', 'lon': '76.8924'},
      '342029': {'lat': '26.7082', 'name': 'Kajnau Kallan,Jodhpur,Rajasthan', 'lon': '73.2987'},
      '735231': {'lat': '26.725', 'name': 'Maipatey Gaon,Darjiling,West Bengal', 'lon': '88.7436'},
      '273402': {'lat': '26.2324', 'name': 'Bhrauli,Gorakhpur,Uttar Pradesh', 'lon': '83.4633'},
      '144039': {'lat': '31.1969', 'name': 'Kandola Kalan,Jalandhar,Punjab', 'lon': '75.5767'},
      '273401': {'lat': '26.4911', 'name': 'Bhaghagarha,Gorakhpur,Uttar Pradesh', 'lon': '83.0284'},
      '273406': {'lat': '26.8146', 'name': 'Gaursaira,Gorakhpur,Uttar Pradesh', 'lon': '83.2727'},
      '735232': {'lat': '26.725', 'name': 'Binnaguri Cantt,Jalpaiguri,West Bengal', 'lon': '88.7436'},
      '273404': {'lat': '26.5441', 'name': 'Mokarimpur,Gorakhpur,Uttar Pradesh', 'lon': '83.3307'},
      '273405': {'lat': '26.5374', 'name': 'Bautha,Gorakhpur,Uttar Pradesh', 'lon': '83.4806'},
      '144030': {'lat': '31.3976', 'name': 'Kalra,Jalandhar,Punjab', 'lon': '75.777'},
      '144031': {'lat': '31.1228', 'name': 'Rurka Kalan,Jalandhar,Punjab', 'lon': '75.6996'},
      '144032': {'lat': '31.1788', 'name': 'Samrai,Jalandhar,Punjab', 'lon': '75.6323'},
      '144033': {'lat': '31.1621', 'name': 'Wajuha Khurd,Jalandhar,Punjab', 'lon': '75.5962'},
      '144034': {'lat': '31.1278', 'name': 'Bhardwajian,Jalandhar,Punjab', 'lon': '75.643'},
      '144035': {'lat': '31.1797', 'name': 'Kang Arryan,Jalandhar,Punjab', 'lon': '75.6257'},
      '144036': {'lat': '31.0499', 'name': 'Mowai,Jalandhar,Punjab', 'lon': '75.6544'},
      '412307': {'lat': '18.5796', 'name': 'Manjari Farm,Pune,Maharashtra', 'lon': '74.4154'},
      '202125': {'lat': '27.9378', 'name': 'Sadhu Ashram,Aligarh,Uttar Pradesh', 'lon': '78.1855'},
      '801306': {'lat': '25.2067', 'name': 'Kanhaiya Ganj,Nalanda,Bihar', 'lon': '85.1561'},
      '202127': {'lat': '27.8665', 'name': 'Talib Nagar,Aligarh,Uttar Pradesh', 'lon': '78.4228'},
      '202126': {'lat': '28.017', 'name': 'Debthala,Aligarh,Uttar Pradesh', 'lon': '78.1265'},
      '202121': {'lat': '27.6604', 'name': 'Suhawali,Aligarh,Uttar Pradesh', 'lon': '78.3542'},
      '412304': {'lat': '18.5796', 'name': 'Murti Modhave,Pune,Maharashtra', 'lon': '74.4154'},
      '202123': {'lat': '27.8185', 'name': 'Sehri Madangarhi,Aligarh,Uttar Pradesh', 'lon': '77.8583'},
      '801307': {'lat': '25.3766', 'name': 'Bhathar,Nalanda,Bihar', 'lon': '85.3925'},
      '503230': {'lat': '18.864', 'name': 'Madhavnagar,Nizamabad,Telangana', 'lon': '78.1735'},
      '403728': {'lat': '14.9445', 'name': 'Loliem,South Goa,Goa', 'lon': '74.0714'},
      '412305': {'lat': '18.5709', 'name': 'Harni,Pune,Maharashtra', 'lon': '74.0691'},
      '202129': {'lat': '28.0808', 'name': 'Pahari Pur,Aligarh,Uttar Pradesh', 'lon': '78.3809'},
      '801304': {'lat': '25.2655', 'name': 'Sigaryawn,Patna,Bihar', 'lon': '85.2249'},
      '629152': {'lat': '8.8335', 'name': 'Cheruvallur,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '209831': {'lat': '26.6543', 'name': 'Atesuwa,Unnao,Uttar Pradesh', 'lon': '80.9663'},
      '322216': {'lat': '27.3634', 'name': 'Tesgaon,Karauli,Rajasthan', 'lon': '75.9117'},
      '212653': {'lat': '25.9401', 'name': 'Mohammad Pur Gaunti,Fatehpur,Uttar Pradesh', 'lon': '80.975'},
      '322214': {'lat': '26.4522', 'name': 'Bairada,Sawai Madhopur,Rajasthan', 'lon': '76.421'},
      '322215': {'lat': '26.1987', 'name': 'Datasuti P T,Sawai Madhopur,Rajasthan', 'lon': '76.5142'},
      '688541': {'lat': '10.1508', 'name': 'Thirunallur,Alappuzha,Kerala', 'lon': '76.1896'},
      '688540': {'lat': '10.1508', 'name': 'Parayakad,Alappuzha,Kerala', 'lon': '76.1896'},
      '486661': {'lat': '24.3923', 'name': 'Sidhi Collectorate,Sidhi,Madhya Pradesh', 'lon': '81.2935'},
      '322211': {'lat': '26.5975', 'name': 'Radhopura,Sawai Madhopur,Rajasthan', 'lon': '76.5675'},
      '312202': {'lat': '24.6615', 'name': 'Dowani,Chittorgarh,Rajasthan', 'lon': '74.1782'},
      '312203': {'lat': '25.0649', 'name': 'Dindoli,Chittorgarh,Rajasthan', 'lon': '74.3938'},
      '734013': {'lat': '26.7111', 'name': 'New Rangia,Darjiling,West Bengal', 'lon': '88.3462'},
      '312201': {'lat': '24.3526', 'name': 'Mishron Ki Pipli,Chittorgarh,Rajasthan', 'lon': '74.115'},
      '312206': {'lat': '25.1351', 'name': 'Rewara,Chittorgarh,Rajasthan', 'lon': '74.367'},
      '151301': {'lat': '29.9763', 'name': 'Giana,Bathinda,Punjab', 'lon': '74.9951'},
      '151302': {'lat': '30.0518', 'name': 'Behniwal,Bathinda,Punjab', 'lon': '75.152'},
      '322219': {'lat': '26.5938', 'name': 'Meena Baroda,Sawai Madhopur,Rajasthan', 'lon': '76.9009'},
      '723215': {'lat': '23.2772', 'name': 'Pundag,Purulia,West Bengal', 'lon': '85.9069'},
      '848131': {'lat': '25.8802', 'name': 'Waini,Samastipur,Bihar', 'lon': '85.6736'},
      '723213': {'lat': '23.4387', 'name': 'Dumru,Purulia,West Bengal', 'lon': '85.9953'},
      '723212': {'lat': '23.2503', 'name': 'Nowadhi,Purulia,West Bengal', 'lon': '85.8922'},
      '801301': {'lat': '25.2773', 'name': 'Puna,Nalanda,Bihar', 'lon': '85.2724'},
      '632014': {'lat': '13.0318', 'name': 'Bramhapuram,Vellore,Tamil Nadu', 'lon': '79.2586'},
      '713132': {'lat': '22.766', 'name': 'Barashyambazar,Purba Bardhaman,West Bengal', 'lon': '88.3634'},
      '713131': {'lat': '22.766', 'name': 'Gatistha,Purba Bardhaman,West Bengal', 'lon': '88.3634'},
      '713130': {'lat': '22.766', 'name': 'Katwa Bazar,Purba Bardhaman,West Bengal', 'lon': '88.3634'},
      '848134': {'lat': '25.8229', 'name': 'Jitwarpur Chouth,Samastipur,Bihar', 'lon': '85.8479'},
      '841214': {'lat': '25.8734', 'name': 'Dhelhari,Saran,Bihar', 'lon': '84.6874'},
      '312027': {'lat': '25.1959', 'name': 'Chikarada,Chittorgarh,Rajasthan', 'lon': '74.7421'},
      '632012': {'lat': '13.0318', 'name': 'Saidapet (Vellore),Vellore,Tamil Nadu', 'lon': '79.2586'},
      '606710': {'lat': '12.4437', 'name': 'Oorgoundanur,Tiruvannamalai,Tamil Nadu', 'lon': '78.9712'},
      '507318': {'lat': '17.4251', 'name': 'Vepakuntla,Khammam,Telangana', 'lon': '80.288'},
      '212301': {'lat': '25.5513', 'name': 'Issauta,Allahabad,Uttar Pradesh', 'lon': '82.1435'},
      '632013': {'lat': '13.0318', 'name': 'Konavattam,Vellore,Tamil Nadu', 'lon': '79.2586'},
      '442303': {'lat': '20.6714', 'name': 'Pulgaon Camp,Wardha,Maharashtra', 'lon': '78.5313'},
      '442302': {'lat': '20.7204', 'name': 'Pipri Pargothan,Wardha,Maharashtra', 'lon': '78.3488'},
      '442301': {'lat': '20.5664', 'name': 'Nimbha,Wardha,Maharashtra', 'lon': '79.0139'},
      '442307': {'lat': '20.7433', 'name': 'Shekapur Bai,Wardha,Maharashtra', 'lon': '78.3793'},
      '442306': {'lat': '20.6449', 'name': 'Inzala,Wardha,Maharashtra', 'lon': '78.2924'},
      '442305': {'lat': '20.64', 'name': 'Waigaon Gond,Wardha,Maharashtra', 'lon': '78.9642'},
      '442304': {'lat': '20.7958', 'name': 'Bhankheda,Wardha,Maharashtra', 'lon': '78.5876'},
      '811211': {'lat': '25.2419', 'name': 'Ghorghat,Munger,Bihar', 'lon': '86.6524'},
      '641029': {'lat': '11.0386', 'name': 'Cherannagar,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '516484': {'lat': '14.5034', 'name': 'Bojjayapalle,Cuddapah,Andhra Pradesh', 'lon': '78.1501'},
      '632011': {'lat': '13.0318', 'name': 'Thuthipattu,Vellore,Tamil Nadu', 'lon': '79.2586'},
      '637404': {'lat': '11.3997', 'name': 'Tirumalaipatti,Namakkal,Tamil Nadu', 'lon': '78.1368'},
      '620101': {'lat': '10.8496', 'name': 'Kambarasampettai,Tiruchirappalli,Tamil Nadu', 'lon': '78.6754'},
      '641028': {'lat': '11.0386', 'name': 'Odayampalayam,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '625556': {'lat': '9.9392', 'name': 'C.Pudupatti,Theni,Tamil Nadu', 'lon': '77.7329'},
      '388215': {'lat': '21.8754', 'name': 'Khijalpur,Kheda,Gujarat', 'lon': '73.2227'},
      '500024': {'lat': '17.456', 'name': 'Sahifa,Hyderabad,Telangana', 'lon': '78.6476'},
      '843104': {'lat': '25.8355', 'name': 'Chak Sarmastpur,Muzaffarpur,Bihar', 'lon': '85.8294'},
      '801104': {'lat': '25.4364', 'name': 'Saidabah,Patna,Bihar', 'lon': '84.8514'},
      '637402': {'lat': '11.3863', 'name': 'Kalkurichi,Namakkal,Tamil Nadu', 'lon': '78.254'},
      '509335': {'lat': '16.4985', 'name': 'Kusumasamudram,Mahabub Nagar,Telangana', 'lon': '78.1461'},
      '637403': {'lat': '11.4232', 'name': '85 R.Komarapalayam,Namakkal,Tamil Nadu', 'lon': '78.1427'},
      '394101': {'lat': '21.2461', 'name': 'Mota Varachha,Surat,Gujarat', 'lon': '72.8561'},
      '209501': {'lat': '27.4443', 'name': 'Khalwara,Farrukhabad,Uttar Pradesh', 'lon': '79.3172'},
      '394107': {'lat': '21.2508', 'name': 'Amroli,Surat,Gujarat', 'lon': '72.8388'},
      '502306': {'lat': '17.5857', 'name': 'Ananthsagar,Medak,Telangana', 'lon': '78.0027'},
      '394105': {'lat': '21.2333', 'name': 'Utran Power House,Surat,Gujarat', 'lon': '72.8667'},
      '262525': {'lat': '29.8833', 'name': 'Chirra,Champawat,Uttarakhand', 'lon': '79.9206'},
      '637401': {'lat': '11.4488', 'name': 'Andagalur,Namakkal,Tamil Nadu', 'lon': '78.1413'},
      '852108': {'lat': '25.9584', 'name': 'Orahi,Madhepura,Bihar', 'lon': '86.6026'},
      '852109': {'lat': '26.1283', 'name': 'Devipur,Supaul,Bihar', 'lon': '86.6633'},
      '841216': {'lat': '25.7446', 'name': 'Dumari Balua,Saran,Bihar', 'lon': '84.9275'},
      '641026': {'lat': '11.0386', 'name': 'Komarapalayam Coimbatore,Coimbatore,Tamil Nadu', 'lon': '76.9807'},
      '488051': {'lat': '24.4676', 'name': 'Itwankala,Panna,Madhya Pradesh', 'lon': '80.1452'},
      '209504': {'lat': '27.7424', 'name': 'Rasidpur Mai,Farrukhabad,Uttar Pradesh', 'lon': '79.1425'},
      '342026': {'lat': '26.2896', 'name': 'Jodhpur BSF Trg Centre,Jodhpur,Rajasthan', 'lon': '73.0486'},
      '228171': {'lat': '26.3243', 'name': 'Alipur Saravan,Sultanpur,Uttar Pradesh', 'lon': '82.4149'},
      '731223': {'lat': '24.1887', 'name': 'Beliamritunjoypur,Birbhum,West Bengal', 'lon': '87.7706'},
      '393105': {'lat': '21.8315', 'name': 'Navitothidra,Narmada,Gujarat', 'lon': '73.2106'},
      '509336': {'lat': '16.9728', 'name': 'Devalfaslawar,Mahabub Nagar,Telangana', 'lon': '77.8796'},
      '852105': {'lat': '26.0348', 'name': 'Siyana,Supaul,Bihar', 'lon': '86.9091'},
      '852106': {'lat': '25.6959', 'name': 'Belhi Teghra,Saharsa,Bihar', 'lon': '86.5286'},
      '731222': {'lat': '24.4505', 'name': 'Omarpur,Birbhum,West Bengal', 'lon': '87.8601'},
      '577528': {'lat': '14.4813', 'name': 'Hucchavvanahalli,Davangere,Karnataka', 'lon': '76.4347'},
      '577529': {'lat': '14.1931', 'name': 'Gowrasamudra,Chitradurga,Karnataka', 'lon': '76.3465'},
      '843101': {'lat': '25.9546', 'name': 'Bashantpur Patti,Muzaffarpur,Bihar', 'lon': '85.2476'},
      '148030': {'lat': '30.0428', 'name': 'Lad Banjara,Sangrur,Punjab', 'lon': '75.7978'},
      '148031': {'lat': '29.8964', 'name': 'Ghorenab,Sangrur,Punjab', 'lon': '75.7331'},
      '205268': {'lat': '27.2756', 'name': 'Takharau,Mainpuri,Uttar Pradesh', 'lon': '79.2173'},
      '148033': {'lat': '30.1234', 'name': 'Chural Kalan,Sangrur,Punjab', 'lon': '75.8238'},
      '431150': {'lat': '20.5281', 'name': 'Wadgaon Tigji,Aurangabad,Maharashtra', 'lon': '75.3583'},
      '431151': {'lat': '20.2662', 'name': 'Andhari,Aurangabad,Maharashtra', 'lon': '75.4896'},
      '431152': {'lat': '20.0656', 'name': 'Sultanpur,Aurangabad,Maharashtra', 'lon': '75.293'},
      '431153': {'lat': '19.1775', 'name': 'Nalwandi,Beed,Maharashtra', 'lon': '76.4763'},
      '431154': {'lat': '19.8503', 'name': 'Sheoga,Aurangabad,Maharashtra', 'lon': '75.5783'},
      '577525': {'lat': '14.3579', 'name': 'Mathi,Davangere,Karnataka', 'lon': '75.8728'},
      '577526': {'lat': '14.0216', 'name': 'Beesanahally,Chitradurga,Karnataka', 'lon': '76.0905'},
      '361210': {'lat': '22.567', 'name': 'Khambhalida Motavas,Jamnagar,Gujarat', 'lon': '70.4177'},
      '841217': {'lat': '25.7597', 'name': 'Nayagaon,Saran,Bihar', 'lon': '85.0887'},
      '801106': {'lat': '25.4982', 'name': 'IIT Patna (Bihta),Patna,Bihar', 'lon': '84.8908'},
      '796091': {'lat': '23.6567', 'name': 'Tuirial H.E.P,Aizawl,Mizoram', 'lon': '92.7452'},
      '854336': {'lat': '25.9499', 'name': 'Aghra,Araria,Bihar', 'lon': '87.0413'},
      '509337': {'lat': '16.4985', 'name': 'Mangampet,Mahabub Nagar,Telangana', 'lon': '78.1461'},
      '450881': {'lat': '21.9362', 'name': 'Bamanda,East Nimar,Madhya Pradesh', 'lon': '76.5714'},
      '841210': {'lat': '25.9699', 'name': 'Kachnar,Siwan,Bihar', 'lon': '84.3589'},
      '712601': {'lat': '22.6703', 'name': 'Arambagh Court,Hooghly,West Bengal', 'lon': '88.2694'},
      '613008': {'lat': '10.8553', 'name': 'Thanjavur North Gate,Thanjavur,Tamil Nadu', 'lon': '79.1064'},
      '613009': {'lat': '10.9235', 'name': 'Co-operative Buildings,Thanjavur,Tamil Nadu', 'lon': '79.4329'},
      '262406': {'lat': '28.1894', 'name': 'Bhauwa Bazar,Bareilly,Uttar Pradesh', 'lon': '79.6455'},
      '262401': {'lat': '29.1595', 'name': 'Doraha,Udham Singh Nagar,Uttarakhand', 'lon': '79.1739'},
      '262402': {'lat': '29.0721', 'name': 'Lalkua,Nainital,Uttarakhand', 'lon': '79.5148'},
      '613001': {'lat': '11.0234', 'name': 'Thanjavur H.O,Thanjavur,Tamil Nadu', 'lon': '79.2904'},
      '613002': {'lat': '11.0811', 'name': 'Sakkarasamandam,Thanjavur,Tamil Nadu', 'lon': '78.0544'},
      '613003': {'lat': '10.8218', 'name': 'Annappanpettai,Thanjavur,Tamil Nadu', 'lon': '79.163'},
      '613004': {'lat': '10.7741', 'name': 'Thanjavur Medical College,Thanjavur,Tamil Nadu', 'lon': '79.0994'},
      '613005': {'lat': '10.8553', 'name': 'Inayathukkanpatti,Thanjavur,Tamil Nadu', 'lon': '79.1064'},
      '613006': {'lat': '10.7049', 'name': 'Mukasa Nanjikottai,Thanjavur,Tamil Nadu', 'lon': '79.1504'},
      '613007': {'lat': '10.8553', 'name': 'Rajappa Nagar,Thanjavur,Tamil Nadu', 'lon': '79.1064'},
      '410208': {'lat': '19.08855', 'name': 'Taloja,Raigarh(MH),Maharashtra', 'lon': '73.14627'},
      '272175': {'lat': '26.712', 'name': 'Umari Kalan,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.9793'},
      '480449': {'lat': '22.2176', 'name': 'Jatachhapar,Chhindwara,Madhya Pradesh', 'lon': '78.7142'},
      '523170': {'lat': '15.85', 'name': 'Kesavarappadu,Prakasam,Andhra Pradesh', 'lon': '80.2516'},
      '523171': {'lat': '15.9938', 'name': 'Poluru,Prakasam,Andhra Pradesh', 'lon': '80.2114'},
      '410201': {'lat': '18.969583', 'name': 'Gaulwadi,Raigarh(MH),Maharashtra', 'lon': '73.414437'},
      '743437': {'lat': '22.6165', 'name': 'Arbelia,North 24 Parganas,West Bengal', 'lon': '88.5454'},
      '410203': {'lat': '18.8305613', 'name': 'Shilphata,Raigarh(MH),Maharashtra', 'lon': '73.2846355'},
      '410202': {'lat': '18.834143', 'name': 'Savroli,Raigarh(MH),Maharashtra', 'lon': '73.288577'},
      '410205': {'lat': '18.5843', 'name': 'Pachhapur,Raigarh(MH),Maharashtra', 'lon': '73.27991'},
      '410204': {'lat': '18.834142', 'name': 'Khopoli Power House,Raigarh(MH),Maharashtra', 'lon': '73.288578'},
      '140133': {'lat': '31.0954', 'name': 'Bhanupali,Ropar,Punjab', 'lon': '75.7016'},
      '410206': {'lat': '18.8305613', 'name': 'Kamothe,Raigarh(MH),Maharashtra', 'lon': '73.2846355'},
      '262521': {'lat': '29.3354', 'name': 'Katiyani,Pithoragarh,Uttarakhand', 'lon': '79.9243'},
      '721406': {'lat': '22.2758', 'name': 'Manoharchak,East Midnapore,West Bengal', 'lon': '88.831'},
      '635109': {'lat': '12.6133', 'name': 'Kothapeta(Hosur),Krishnagiri,Tamil Nadu', 'lon': '77.9667'},
      '635108': {'lat': '12.5825', 'name': 'Marudepalli,Krishnagiri,Tamil Nadu', 'lon': '78.2446'},
      '635105': {'lat': '12.7998', 'name': 'Mudukurukki,Krishnagiri,Tamil Nadu', 'lon': '77.9719'},
      '635104': {'lat': '12.6039', 'name': 'Chintagampalli,Krishnagiri,Tamil Nadu', 'lon': '78.2873'},
      '635107': {'lat': '12.4204', 'name': 'Guttahalli,Krishnagiri,Tamil Nadu', 'lon': '77.8501'},
      '635106': {'lat': '12.3923', 'name': 'Kothalam,Krishnagiri,Tamil Nadu', 'lon': '78.1813'},
      '635101': {'lat': '12.4592', 'name': 'Gundalapatti,Krishnagiri,Tamil Nadu', 'lon': '78.2971'},
      '721404': {'lat': '22.2758', 'name': 'Karkuli,East Midnapore,West Bengal', 'lon': '88.831'},
      '635103': {'lat': '12.8488', 'name': 'Nallore,Krishnagiri,Tamil Nadu', 'lon': '77.8708'},
      '635102': {'lat': '12.3194', 'name': 'Manchukondapalli,Krishnagiri,Tamil Nadu', 'lon': '77.7689'},
      '480106': {'lat': '21.6558', 'name': 'Sausar,Chhindwara,Madhya Pradesh', 'lon': '78.7967'},
      '721405': {'lat': '22.2758', 'name': 'Fuleswar,East Midnapore,West Bengal', 'lon': '88.831'},
      '721402': {'lat': '22.2758', 'name': 'Damodarpur- Contai,East Midnapore,West Bengal', 'lon': '88.831'},
      '493441': {'lat': '21.2005', 'name': 'Rasni,Raipur,Chattisgarh', 'lon': '81.9281'},
      '607805': {'lat': '11.6587', 'name': 'Visoor,Cuddalore,Tamil Nadu', 'lon': '79.4648'},
      '262520': {'lat': '29.6827', 'name': 'Bilai,Pithoragarh,Uttarakhand', 'lon': '79.678'},
      '721403': {'lat': '22.2758', 'name': 'Baksispur,East Midnapore,West Bengal', 'lon': '88.831'},
      '845423': {'lat': '26.3833', 'name': 'Rampur Khauria,East Champaran,Bihar', 'lon': '84.7696'},
      '845422': {'lat': '26.4133', 'name': 'Son Barsa,East Champaran,Bihar', 'lon': '84.8616'},
      '628702': {'lat': '8.4269', 'name': 'Pothakalanvilai,Tuticorin,Tamil Nadu', 'lon': '77.948'},
      '845427': {'lat': '26.2403', 'name': 'Kh0ri Pakar,East Champaran,Bihar', 'lon': '85.3477'},
      '845426': {'lat': '26.1361', 'name': 'Bans Ghat,East Champaran,Bihar', 'lon': '84.8827'},
      '845425': {'lat': '26.6302', 'name': 'Sirni Bazar,East Champaran,Bihar', 'lon': '84.6263'},
      '845424': {'lat': '26.384', 'name': 'Tajpur Patkhaulia,East Champaran,Bihar', 'lon': '84.8003'},
      '841213': {'lat': '25.5779', 'name': 'Hasulahi Dwariya,Saran,Bihar', 'lon': '84.9343'},
      '621009': {'lat': '11.1114', 'name': 'Poonampalayam,Tiruchirappalli,Tamil Nadu', 'lon': '78.6287'},
      '845429': {'lat': '26.3776', 'name': 'Ashok Pakari,East Champaran,Bihar', 'lon': '84.9023'},
      '721401': {'lat': '22.2758', 'name': 'Contai H.O,East Midnapore,West Bengal', 'lon': '88.831'},
      '801102': {'lat': '25.3734', 'name': 'M.Dihuli,Patna,Bihar', 'lon': '84.8775'},
      '221314': {'lat': '25.24738', 'name': 'Sarawatkhani,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.63706'},
      '621008': {'lat': '11.1659', 'name': 'Govindapuram,Tiruchirappalli,Tamil Nadu', 'lon': '78.5941'},
      '228151': {'lat': '26.3922', 'name': 'Dhundhu,Sultanpur,Uttar Pradesh', 'lon': '82.0516'},
      '333041': {'lat': '28.068', 'name': 'Hetamsar,Jhujhunu,Rajasthan', 'lon': '75.2495'},
      '413409': {'lat': '19.2296', 'name': 'Umbarge,Solapur,Maharashtra', 'lon': '76.5306'},
      '531081': {'lat': '17.4208', 'name': 'Peda Doddigallu,Visakhapatnam,Andhra Pradesh', 'lon': '82.6338'},
      '531083': {'lat': '17.4615', 'name': 'Chinnagummuluru,Visakhapatnam,Andhra Pradesh', 'lon': '82.7272'},
      '531082': {'lat': '17.4831', 'name': 'Darlapudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.7383'},
      '531085': {'lat': '17.6564', 'name': 'Lingapuram,Visakhapatnam,Andhra Pradesh', 'lon': '82.5752'},
      '531084': {'lat': '17.7804', 'name': 'Old K D Peta,Visakhapatnam,Andhra Pradesh', 'lon': '82.4771'},
      '531087': {'lat': '17.653', 'name': 'Cheedipalem,Visakhapatnam,Andhra Pradesh', 'lon': '82.2947'},
      '413401': {'lat': '18.7774', 'name': 'Shripat Pimpari,Solapur,Maharashtra', 'lon': '76.4481'},
      '384160': {'lat': '23.7671', 'name': 'Unava,Mahesana,Gujarat', 'lon': '72.362'},
      '825109': {'lat': '24.3771', 'name': 'Dandadih,Koderma,Jharkhand', 'lon': '85.671'},
      '670310': {'lat': '12.0418', 'name': 'Ezhimala Naval Academy,Kannur,Kerala', 'lon': '75.3473'},
      '801103': {'lat': '25.5909', 'name': 'P.Painathi,Patna,Bihar', 'lon': '84.86'},
      '621007': {'lat': '11.0653', 'name': 'Abinimangalam,Tiruchirappalli,Tamil Nadu', 'lon': '78.668'},
      '389341': {'lat': '22.8811', 'name': 'Gunesia,Panch Mahals,Gujarat', 'lon': '73.5597'},
      '389340': {'lat': '22.8538', 'name': 'Mehlol,Panch Mahals,Gujarat', 'lon': '73.3584'},
      '396427': {'lat': '20.949', 'name': 'Amadpore,Navsari,Gujarat', 'lon': '72.8983'},
      '396424': {'lat': '20.949', 'name': 'Kabilpore,Navsari,Gujarat', 'lon': '72.8983'},
      '621006': {'lat': '10.8478', 'name': 'Peramangalam,Tiruchirappalli,Tamil Nadu', 'lon': '78.2262'},
      '396350': {'lat': '21.6185', 'name': 'Kolva,Navsari,Gujarat', 'lon': '72.8672'},
      '396421': {'lat': '20.949', 'name': 'Jalalpore,Navsari,Gujarat', 'lon': '72.8983'},
      '628907': {'lat': '9.1816', 'name': 'Kilavilathikulam,Tuticorin,Tamil Nadu', 'lon': '78.1811'},
      '628906': {'lat': '9.0815', 'name': 'Periyasamipuram,Tuticorin,Tamil Nadu', 'lon': '78.3633'},
      '628905': {'lat': '9.3224', 'name': 'Sennamareddipatti,Tuticorin,Tamil Nadu', 'lon': '78.1207'},
      '628904': {'lat': '9.7368', 'name': 'Nagalapuram (Tuticorin),Tuticorin,Tamil Nadu', 'lon': '78.1433'},
      '628903': {'lat': '9.0239', 'name': 'Kilavaippar,Tuticorin,Tamil Nadu', 'lon': '78.2467'},
      '628902': {'lat': '9.4809', 'name': 'Muthulapuram,Tuticorin,Tamil Nadu', 'lon': '77.9299'},
      '628901': {'lat': '9.0734', 'name': 'Melmandai,Tuticorin,Tamil Nadu', 'lon': '78.2909'},
      '203390': {'lat': '28.3237', 'name': 'Bhargava Pharmacy,Bulandshahr,Uttar Pradesh', 'lon': '78.2404'},
      '534268': {'lat': '16.3379', 'name': 'Kalagam Pudi,West Godavari,Andhra Pradesh', 'lon': '80.7256'},
      '762016': {'lat': '20.8782', 'name': 'Jogindrapur,Boudh,Odisha', 'lon': '83.5432'},
      '534269': {'lat': '16.3474', 'name': 'Chinamallam,West Godavari,Andhra Pradesh', 'lon': '80.7829'},
      '636030': {'lat': '11.5732', 'name': 'Mohan Nagar (Salem),Salem,Tamil Nadu', 'lon': '78.1955'},
      '231219': {'lat': '24.4322', 'name': 'Billi Markundi,Sonbhadra,Uttar Pradesh', 'lon': '82.8928'},
      '231218': {'lat': '24.2824', 'name': 'Renusagar,Sonbhadra,Uttar Pradesh', 'lon': '82.8583'},
      '231215': {'lat': '24.7009', 'name': 'Sarvat,Sonbhadra,Uttar Pradesh', 'lon': '82.9354'},
      '231217': {'lat': '24.2164', 'name': 'Sukhdevanand Ashram,Sonbhadra,Uttar Pradesh', 'lon': '83.0358'},
      '231216': {'lat': '24.8539', 'name': 'Siddhi Kala,Sonbhadra,Uttar Pradesh', 'lon': '83.0102'},
      '231211': {'lat': '24.9417', 'name': 'Bardiha Kala,Mirzapur,Uttar Pradesh', 'lon': '82.4007'},
      '182222': {'lat': '32.9794', 'name': 'Chanichinta,Doda,Jammu & Kashmir', 'lon': '75.7172'},
      '182221': {'lat': '33.0207', 'name': 'Bhalla,Doda,Jammu & Kashmir', 'lon': '75.6915'},
      '231212': {'lat': '24.7414', 'name': 'Naudiha,Sonbhadra,Uttar Pradesh', 'lon': '82.907'},
      '586128': {'lat': '16.8512', 'name': 'Chandkavathe,Vijayapura,Karnataka', 'lon': '76.3374'},
      '586129': {'lat': '16.3118', 'name': 'Kunchaganur,Vijayapura,Karnataka', 'lon': '76.1693'},
      '637214': {'lat': '11.0925', 'name': 'Emapalli,Namakkal,Tamil Nadu', 'lon': '77.3123'},
      '824116': {'lat': '24.8819', 'name': 'Dorma,Gaya,Bihar', 'lon': '84.6649'},
      '824114': {'lat': '24.6257', 'name': 'Malhara,Aurangabad(BH),Bihar', 'lon': '84.3796'},
      '824115': {'lat': '25.1913', 'name': 'Anurahi,Arwal,Bihar', 'lon': '84.7871'},
      '586120': {'lat': '16.7532', 'name': 'Korwar,Vijayapura,Karnataka', 'lon': '76.2126'},
      '586121': {'lat': '17.1595', 'name': 'Talewad,Vijayapura,Karnataka', 'lon': '76.7018'},
      '586122': {'lat': '16.6352', 'name': 'Hitinhalli,Vijayapura,Karnataka', 'lon': '75.9447'},
      '586123': {'lat': '16.696', 'name': 'Kakkalmeli,Vijayapura,Karnataka', 'lon': '76.3247'},
      '586124': {'lat': '16.2825', 'name': 'Nagarbetta,Vijayapura,Karnataka', 'lon': '76.2579'},
      '586125': {'lat': '16.7101', 'name': 'Sarwad,Vijayapura,Karnataka', 'lon': '75.6392'},
      '586127': {'lat': '17.0311', 'name': 'Aliyabad,Vijayapura,Karnataka', 'lon': '76.2443'},
      '781127': {'lat': '26.2077', 'name': 'Badlabazar,Kamrup,Assam', 'lon': '91.1467'},
      '781126': {'lat': '26.2685', 'name': 'Lowtola,Nalbari,Assam', 'lon': '91.6169'},
      '125011': {'lat': '29.3034', 'name': 'B.S.F Hisar,Hisar,Haryana', 'lon': '75.7113'},
      '781124': {'lat': '26.3127', 'name': 'Borakhat,Kamrup,Assam', 'lon': '90.9065'},
      '781123': {'lat': '26.0176', 'name': 'Kinangaon,Kamrup,Assam', 'lon': '91.2614'},
      '781122': {'lat': '26.243', 'name': 'Uparhali,Kamrup,Assam', 'lon': '91.7368'},
      '781121': {'lat': '26.3894', 'name': 'Halda,Kamrup,Assam', 'lon': '91.8024'},
      '781120': {'lat': '25.9774', 'name': 'Rajapara,Kamrup,Assam', 'lon': '91.4742'},
      '142048': {'lat': '30.8269', 'name': 'Khukhrana,Moga,Punjab', 'lon': '75.0883'},
      '781129': {'lat': '25.8902', 'name': 'Aradanga,Kamrup,Assam', 'lon': '91.1839'},
      '781128': {'lat': '26.118', 'name': 'Majirgaon,Kamrup,Assam', 'lon': '91.4061'},
      '182320': {'lat': '33.2398', 'name': 'Kakriyal,Reasi,Jammu & Kashmir', 'lon': '75.0799'},
      '734501': {'lat': '26.9251', 'name': 'Chanda Company,Jalpaiguri,West Bengal', 'lon': '88.5689'},
      '403725': {'lat': '15.2432', 'name': 'Dramapur,South Goa,Goa', 'lon': '73.9819'},
      '683541': {'lat': '10.0605', 'name': 'Keezhillam,Ernakulam,Kerala', 'lon': '76.5235'},
      '683542': {'lat': '10.1346', 'name': 'Perumbavoor H.O,Ernakulam,Kerala', 'lon': '76.4823'},
      '683543': {'lat': '10.1346', 'name': 'Rayonpuram,Ernakulam,Kerala', 'lon': '76.4823'},
      '683544': {'lat': '10.1747', 'name': 'Aimurikara,Ernakulam,Kerala', 'lon': '76.5129'},
      '683545': {'lat': '10.1446', 'name': 'Nedungapra,Ernakulam,Kerala', 'lon': '76.41'},
      '683546': {'lat': '10.1323', 'name': 'Paniely,Ernakulam,Kerala', 'lon': '76.5253'},
      '683547': {'lat': '10.1346', 'name': 'Ponjassery,Ernakulam,Kerala', 'lon': '76.4823'},
      '683548': {'lat': '10.1346', 'name': 'Iringole,Ernakulam,Kerala', 'lon': '76.4823'},
      '683549': {'lat': '10.1346', 'name': 'Asamannoor,Ernakulam,Kerala', 'lon': '76.4823'},
      '754106': {'lat': '20.9079', 'name': 'Mahilo,Jagatsinghapur,Odisha', 'lon': '84.8208'},
      '229201': {'lat': '26.1411', 'name': 'Bemaura,Raebareli,Uttar Pradesh', 'lon': '80.8818'},
      '841218': {'lat': '26.1448', 'name': 'Pakri Mohamdpur,Saran,Bihar', 'lon': '84.5354'},
      '841237': {'lat': '25.9942', 'name': 'Bhikhpur,Siwan,Bihar', 'lon': '84.3996'},
      '518145': {'lat': '14.9886', 'name': 'Nossam,Kurnool,Andhra Pradesh', 'lon': '78.3915'},
      '518412': {'lat': '15.4222', 'name': 'Prathakota-east,Kurnool,Andhra Pradesh', 'lon': '78.2746'},
      '518411': {'lat': '15.4222', 'name': 'Mutsumarri,Kurnool,Andhra Pradesh', 'lon': '78.2746'},
      '834002': {'lat': '23.3853', 'name': 'Jharkhand High Court,Ranchi,Jharkhand', 'lon': '85.2923'},
      '229207': {'lat': '26.1581', 'name': 'Murai Ka Bagh,Raebareli,Uttar Pradesh', 'lon': '81.0292'},
      '756119': {'lat': '21.0279', 'name': 'Dhusuri,Bhadrak,Odisha', 'lon': '84.1886'},
      '229206': {'lat': '26.178', 'name': 'Deepemau,Raebareli,Uttar Pradesh', 'lon': '81.2656'},
      '380002': {'lat': '23.0333', 'name': 'N C Market,Ahmedabad,Gujarat', 'lon': '72.6181'},
      '412115': {'lat': '19.7398', 'name': 'Ghotavade,Pune,Maharashtra', 'lon': '77.2028'},
      '516108': {'lat': '14.0211', 'name': 'Peddaorampadu,Cuddapah,Andhra Pradesh', 'lon': '79.2472'},
      '412112': {'lat': '19.1064', 'name': 'Dasve Lavasa,Pune,Maharashtra', 'lon': '76.7918'},
      '827014': {'lat': '23.6915', 'name': 'Balidih,Bokaro,Jharkhand', 'lon': '85.988'},
      '412110': {'lat': '18.5709', 'name': 'Naygaon,Pune,Maharashtra', 'lon': '74.0691'},
      '403409': {'lat': '15.4215', 'name': 'Betora I.E.,South Goa,Goa', 'lon': '74.0071'},
      '516101': {'lat': '14.022', 'name': 'Venkatapathiraju Kandrika,Cuddapah,Andhra Pradesh', 'lon': '79.3513'},
      '229204': {'lat': '26.0237', 'name': 'Ambara Mathai,Raebareli,Uttar Pradesh', 'lon': '81.2076'},
      '516107': {'lat': '14.1289', 'name': 'Dalavaipalli,Cuddapah,Andhra Pradesh', 'lon': '79.2131'},
      '516105': {'lat': '14.0404', 'name': 'Anantharajup R.S.,Cuddapah,Andhra Pradesh', 'lon': '79.3256'},
      '516104': {'lat': '14.172', 'name': 'Chitvel,Cuddapah,Andhra Pradesh', 'lon': '79.3305'},
      '742212': {'lat': '24.7843', 'name': 'Beniagram,Murshidabad,West Bengal', 'lon': '87.8722'},
      '742213': {'lat': '24.5643', 'name': 'Khodarampur,Murshidabad,West Bengal', 'lon': '88.0252'},
      '834008': {'lat': '23.3853', 'name': 'Adalhatu,Ranchi,Jharkhand', 'lon': '85.2923'},
      '622422': {'lat': '10.3525', 'name': 'Peraiyur,Pudukkottai,Tamil Nadu', 'lon': '78.7573'},
      '734503': {'lat': '26.9251', 'name': 'Gairibas,Darjiling,West Bengal', 'lon': '88.5689'},
      '756116': {'lat': '20.1817', 'name': 'Rajmukundapur,Bhadrak,Odisha', 'lon': '85.0638'},
      '175122': {'lat': '31.7256', 'name': 'Larji,Kullu,Himachal Pradesh', 'lon': '77.225'},
      '175123': {'lat': '31.5935', 'name': 'Gadagushaini,Mandi,Himachal Pradesh', 'lon': '77.2352'},
      '175121': {'lat': '31.6221', 'name': 'Thalout,Mandi,Himachal Pradesh', 'lon': '77.2307'},
      '175126': {'lat': '31.9045', 'name': 'Mohal,Kullu,Himachal Pradesh', 'lon': '77.4002'},
      '175124': {'lat': '31.5965', 'name': 'Kholanal,Mandi,Himachal Pradesh', 'lon': '77.1404'},
      '175125': {'lat': '31.6383', 'name': 'Rahla,Mandi,Himachal Pradesh', 'lon': '76.9929'},
      '143008': {'lat': '31.6757', 'name': 'Naushehra Nangli,Amritsar,Punjab', 'lon': '74.8708'},
      '175128': {'lat': '31.7323', 'name': 'Shirad,Kullu,Himachal Pradesh', 'lon': '77.1219'},
      '175129': {'lat': '31.72', 'name': 'Fozal,Kullu,Himachal Pradesh', 'lon': '77.25'},
      '679341': {'lat': '10.923', 'name': 'Mattarakkal,Malappuram,Kerala', 'lon': '76.2393'},
      '679340': {'lat': '10.9526', 'name': 'Eravimangalam,Malappuram,Kerala', 'lon': '76.2403'},
      '743293': {'lat': '22.7371', 'name': 'Kachua Swarupnagar,North 24 Parganas,West Bengal', 'lon': '88.7422'},
      '756117': {'lat': '20.863', 'name': 'Palikiri,Bhadrak,Odisha', 'lon': '85.3738'},
      '743291': {'lat': '22.6632', 'name': 'Champapukur,North 24 Parganas,West Bengal', 'lon': '88.8112'},
      '577233': {'lat': '13.8684', 'name': 'Kallihal,Shivamogga,Karnataka', 'lon': '75.9996'},
      '743297': {'lat': '22.8358', 'name': 'Naldugri,North 24 Parganas,West Bengal', 'lon': '88.7482'},
      '262528': {'lat': '29.3301', 'name': 'Chauramehta,Champawat,Uttarakhand', 'lon': '79.9497'},
      '743294': {'lat': '22.7343', 'name': 'Bohara,North 24 Parganas,West Bengal', 'lon': '88.5795'},
      '577542': {'lat': '13.6378', 'name': 'Kurubarahally,Chitradurga,Karnataka', 'lon': '76.4246'},
      '636014': {'lat': '10.9904', 'name': 'Kamaraj Nagar Colony,Salem,Tamil Nadu', 'lon': '78.4224'},
      '577543': {'lat': '14.4574', 'name': 'Chikkammanahally,Chitradurga,Karnataka', 'lon': '76.7143'},
      '522034': {'lat': '16.6894', 'name': 'Lam Ars,Guntur,Andhra Pradesh', 'lon': '80.2498'},
      '832107': {'lat': '22.7288', 'name': 'Kendamundi,Seraikela-kharsawan,Jharkhand', 'lon': '85.7409'},
      '577540': {'lat': '14.4632', 'name': 'Santhgudda,Chitradurga,Karnataka', 'lon': '76.6808'},
      '577541': {'lat': '14.7541', 'name': 'Byalahal,Chitradurga,Karnataka', 'lon': '75.925'},
      '364320': {'lat': '21.8657', 'name': 'Ramanka,Bhavnagar,Gujarat', 'lon': '71.7448'},
      '577546': {'lat': '14.0486', 'name': 'Byadarahally,Chitradurga,Karnataka', 'lon': '76.765'},
      '172201': {'lat': '31.4921', 'name': 'Kartot,Shimla,Himachal Pradesh', 'lon': '77.7412'},
      '735135': {'lat': '26.4845', 'name': 'Mohanvita,Jalpaiguri,West Bengal', 'lon': '88.6071'},
      '577547': {'lat': '14.0488', 'name': 'Gejjegondanahalli,Chikkamagaluru,Karnataka', 'lon': '76.0953'},
      '577544': {'lat': '13.9603', 'name': 'Navilehalu,Davangere,Karnataka', 'lon': '76.0457'},
      '306119': {'lat': '25.5021', 'name': 'Dhariya,Pali,Rajasthan', 'lon': '73.3668'},
      '587122': {'lat': '16.117', 'name': 'Muddapur,Bagalkot,Karnataka', 'lon': '75.6718'},
      '712408': {'lat': '22.7679', 'name': 'Dihibhursut,Howrah,West Bengal', 'lon': '88.0059'},
      '306116': {'lat': '25.5215', 'name': 'Falna  I. E.,Pali,Rajasthan', 'lon': '73.5581'},
      '306115': {'lat': '25.2218', 'name': 'Rani Gaon,Pali,Rajasthan', 'lon': '73.4503'},
      '577545': {'lat': '14.0431', 'name': 'Rangenahally,Chitradurga,Karnataka', 'lon': '76.6839'},
      '756171': {'lat': '20.8076', 'name': 'Kaithkhola,Bhadrak,Odisha', 'lon': '86.9261'},
      '228159': {'lat': '26.1358', 'name': 'Ramganj (Sultanpur),Sultanpur,Uttar Pradesh', 'lon': '82.0131'},
      '591340': {'lat': '13.9431', 'name': 'Kamatnur,Belagavi,Karnataka', 'lon': '75.3204'},
      '591344': {'lat': '15.8492', 'name': 'Kolvi,Belagavi,Karnataka', 'lon': '74.6483'},
      '591345': {'lat': '15.7083', 'name': 'Parwad,Belagavi,Karnataka', 'lon': '74.3234'},
      '591346': {'lat': '15.9887', 'name': 'Karavinkumpi,Belagavi,Karnataka', 'lon': '74.6656'},
      '600066': {'lat': '13.0063', 'name': 'Pozhal,Tiruvallur,Tamil Nadu', 'lon': '79.8869'},
      '147001': {'lat': '30.3793', 'name': 'Medical College (Patiala),Patiala,Punjab', 'lon': '76.1644'},
      '147002': {'lat': '30.3793', 'name': 'Urban Estate (Patiala),Patiala,Punjab', 'lon': '76.1644'},
      '147003': {'lat': '30.3793', 'name': 'D C W,Patiala,Punjab', 'lon': '76.1644'},
      '147004': {'lat': '30.4562', 'name': 'Govt. Printing Press,Patiala,Punjab', 'lon': '76.4223'},
      '147005': {'lat': '30.448', 'name': 'Majithia Enclave,Patiala,Punjab', 'lon': '75.5145'},
      '147006': {'lat': '30.4108', 'name': 'Chalela,Patiala,Punjab', 'lon': '76.3315'},
      '147007': {'lat': '30.3021', 'name': 'Nainkalan,Patiala,Punjab', 'lon': '76.2994'},
      '370145': {'lat': '23.378', 'name': 'Thoriyari,Kachchh,Gujarat', 'lon': '70.6291'},
      '370410': {'lat': '22.8863', 'name': 'Vadala,Kachchh,Gujarat', 'lon': '69.7884'},
      '370140': {'lat': '23.3471', 'name': 'Vijapasar,Kachchh,Gujarat', 'lon': '70.3131'},
      '370415': {'lat': '22.8863', 'name': 'Karaghogha,Kachchh,Gujarat', 'lon': '69.7884'},
      '600069': {'lat': '12.978', 'name': 'South Malayambakkam,Kanchipuram,Tamil Nadu', 'lon': '80.0678'},
      '583130': {'lat': '14.7693', 'name': 'Gandabommanahalli,Ballari,Karnataka', 'lon': '76.5857'},
      '583131': {'lat': '14.8528', 'name': 'Madlagere,Davangere,Karnataka', 'lon': '75.7858'},
      '583132': {'lat': '14.4933', 'name': 'Kanvi Thimmalapura,Ballari,Karnataka', 'lon': '76.4859'},
      '721129': {'lat': '23.5234', 'name': 'Karnagarh,West Midnapore,West Bengal', 'lon': '87.6598'},
      '390014': {'lat': '22.5635', 'name': 'Jambua,Vadodara,Gujarat', 'lon': '72.9091'},
      '390017': {'lat': '22.5635', 'name': 'Wadi S.N. Road,Vadodara,Gujarat', 'lon': '72.9091'},
      '390016': {'lat': '22.5635', 'name': 'Industrial Estate (Vadodara),Vadodara,Gujarat', 'lon': '72.9091'},
      '390011': {'lat': '22.5635', 'name': 'Manjalpur,Vadodara,Gujarat', 'lon': '72.9091'},
      '390010': {'lat': '22.5635', 'name': 'Vadsar,Vadodara,Gujarat', 'lon': '72.9091'},
      '390013': {'lat': '22.5635', 'name': 'Maneja,Vadodara,Gujarat', 'lon': '72.9091'},
      '390012': {'lat': '22.5635', 'name': 'Atladara,Vadodara,Gujarat', 'lon': '72.9091'},
      '721121': {'lat': '23.5946', 'name': 'Jagardanga,West Midnapore,West Bengal', 'lon': '87.5616'},
      '583134': {'lat': '14.2266', 'name': 'K Gajapura,Ballari,Karnataka', 'lon': '76.034'},
      '843325': {'lat': '25.9263', 'name': 'Kuama,Sitamarhi,Bihar', 'lon': '85.2398'},
      '721122': {'lat': '23.5234', 'name': 'Kongarer Ayma,West Midnapore,West Bengal', 'lon': '87.6598'},
      '390019': {'lat': '22.5635', 'name': 'Kumetha,Vadodara,Gujarat', 'lon': '72.9091'},
      '390018': {'lat': '22.5635', 'name': 'Karelibaug,Vadodara,Gujarat', 'lon': '72.9091'},
      '721127': {'lat': '22.6901', 'name': 'Sandhipur,West Midnapore,West Bengal', 'lon': '87.7202'},
      '583135': {'lat': '14.5102', 'name': 'Geddalagatta,Ballari,Karnataka', 'lon': '76.1433'},
      '628218': {'lat': '8.5372', 'name': 'Jerusalempatnam,Tuticorin,Tamil Nadu', 'lon': '78.0568'},
      '583136': {'lat': '14.611', 'name': 'Toolahalli,Ballari,Karnataka', 'lon': '75.7625'},
      '231311': {'lat': '25.1989', 'name': 'Chunar Cement Factory,Mirzapur,Uttar Pradesh', 'lon': '82.5273'},
      '502335': {'lat': '17.8891', 'name': 'Gunreddipally,Medak,Telangana', 'lon': '78.4718'},
      '502334': {'lat': '17.7019', 'name': 'Nemtur,Medak,Telangana', 'lon': '78.3833'},
      '583137': {'lat': '14.4707', 'name': 'Alagilawada,Davangere,Karnataka', 'lon': '76.0009'},
      '502331': {'lat': '17.6789', 'name': 'Kurthiwada,Medak,Telangana', 'lon': '78.4657'},
      '505185': {'lat': '18.5497', 'name': 'Narayanapur,Karim Nagar,Telangana', 'lon': '79.2409'},
      '507125': {'lat': '17.5136', 'name': 'P.V.Township,Khammam,Telangana', 'lon': '80.3133'},
      '507124': {'lat': '17.8703', 'name': 'Komararam,Khammam,Telangana', 'lon': '80.4934'},
      '382041': {'lat': '23.0447', 'name': 'Thermal Power Station,Gandhi Nagar,Gujarat', 'lon': '73.1427'},
      '416314': {'lat': '19.6707', 'name': 'Hatnoli,Sangli,Maharashtra', 'lon': '74.6267'},
      '762018': {'lat': '20.3835', 'name': 'Masinogora,Boudh,Odisha', 'lon': '85.0665'},
      '382042': {'lat': '23.0447', 'name': 'CRPF Campus G Nagar,Gandhi Nagar,Gujarat', 'lon': '73.1427'},
      '382045': {'lat': '23.0447', 'name': 'BSF GANDHINAGAR,Gandhi Nagar,Gujarat', 'lon': '73.1427'},
      '416310': {'lat': '17.0881', 'name': 'Morale,Sangli,Maharashtra', 'lon': '74.4571'},
      '416313': {'lat': '19.076', 'name': 'Junekhed,Sangli,Maharashtra', 'lon': '75.1058'},
      '416312': {'lat': '19.076', 'name': 'Biranwadi,Sangli,Maharashtra', 'lon': '75.1058'},
      '503310': {'lat': '18.2416', 'name': 'Kodapkal Kalan,Nizamabad,Telangana', 'lon': '77.7908'},
      '503311': {'lat': '18.2643', 'name': 'Ankasapur,Nizamabad,Telangana', 'lon': '78.469'},
      '639118': {'lat': '10.8543', 'name': 'Chellandipatti,Karur,Tamil Nadu', 'lon': '78.1569'},
      '639119': {'lat': '10.8717', 'name': 'Potharavuthanpatti,Karur,Tamil Nadu', 'lon': '78.3448'},
      '638502': {'lat': '11.5398', 'name': 'Kuppandampalayam,Erode,Tamil Nadu', 'lon': '77.5292'},
      '638503': {'lat': '11.6378', 'name': 'Gundry,Erode,Tamil Nadu', 'lon': '77.2926'},
      '847452': {'lat': '26.1206', 'name': 'Mauahi,Supaul,Bihar', 'lon': '86.6493'},
      '638501': {'lat': '11.61', 'name': 'Gettisamudram Colony,Erode,Tamil Nadu', 'lon': '77.5917'},
      '638506': {'lat': '11.5002', 'name': 'Pullappanaickenpalayam,Erode,Tamil Nadu', 'lon': '77.3722'},
      '638504': {'lat': '11.6721', 'name': 'Komarayanur,Erode,Tamil Nadu', 'lon': '77.6795'},
      '638505': {'lat': '11.0541', 'name': 'Erangattur,Erode,Tamil Nadu', 'lon': '78.3003'},
      '847106': {'lat': '25.626', 'name': 'Sarwara,Darbhanga,Bihar', 'lon': '84.9615'},
      '847107': {'lat': '25.2948', 'name': 'Balour,Muzaffarpur,Bihar', 'lon': '86.3167'},
      '847104': {'lat': '25.7749', 'name': 'Kaligaon,Darbhanga,Bihar', 'lon': '85.7626'},
      '523346': {'lat': '15.1165', 'name': 'P.Venkatapuram,Prakasam,Andhra Pradesh', 'lon': '79.1236'},
      '847102': {'lat': '25.6518', 'name': 'Karbi Dhankaul,Darbhanga,Bihar', 'lon': '85.7383'},
      '847103': {'lat': '25.9351', 'name': 'Shivnagarghat,Darbhanga,Bihar', 'lon': '86.0961'},
      '847101': {'lat': '25.7494', 'name': 'Nimathi,Darbhanga,Bihar', 'lon': '85.6515'},
      '123110': {'lat': '28.1937', 'name': 'Industrial Area Dharuhera,Rewari,Haryana', 'lon': '76.5265'},
      '628210': {'lat': '8.5827', 'name': 'Lakshmipuram,Tuticorin,Tamil Nadu', 'lon': '78.0354'},
      '210341': {'lat': '25.7869', 'name': 'Piteora,Hamirpur,Uttar Pradesh', 'lon': '79.6834'},
      '360531': {'lat': '21.8867', 'name': 'Satapar,Jamnagar,Gujarat', 'lon': '69.7051'},
      '680617': {'lat': '10.5089', 'name': 'Manalur,Thrissur,Kerala', 'lon': '76.1306'},
      '177401': {'lat': '31.6135', 'name': 'Dhanwin,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5958'},
      '577201': {'lat': '14.0147', 'name': 'Ravindranagr-shimoga,Shivamogga,Karnataka', 'lon': '75.4016'},
      '822129': {'lat': '24.119', 'name': 'Bhawnathpur TownShip,Garhwa,Jharkhand', 'lon': '83.9687'},
      '577203': {'lat': '13.9124', 'name': 'Vidyanagar Shimoga,Shivamogga,Karnataka', 'lon': '75.5791'},
      '577202': {'lat': '13.8534', 'name': 'Gajanur,Shivamogga,Karnataka', 'lon': '75.5397'},
      '577205': {'lat': '14.0147', 'name': 'Purudal,Shivamogga,Karnataka', 'lon': '75.4016'},
      '577204': {'lat': '14.0147', 'name': 'Vinobanagar Shimoga,Shivamogga,Karnataka', 'lon': '75.4016'},
      '577522': {'lat': '14.2798', 'name': 'Sanikere,Chitradurga,Karnataka', 'lon': '76.7415'},
      '804403': {'lat': '25.1872', 'name': 'Chhatiyana,Gaya,Bihar', 'lon': '85.1867'},
      '262553': {'lat': '30.0019', 'name': 'Nachni,Pithoragarh,Uttarakhand', 'lon': '80.0954'},
      '394716': {'lat': '21.0476', 'name': 'Pipaldahad,Surat,Gujarat', 'lon': '73.7154'},
      '394715': {'lat': '20.7978', 'name': 'Sarovar,Surat,Gujarat', 'lon': '73.5855'},
      '394710': {'lat': '20.6938', 'name': 'Chankhal,The Dangs,Gujarat', 'lon': '73.7738'},
      '680310': {'lat': '10.4005', 'name': 'Parappukkara,Thrissur,Kerala', 'lon': '76.2493'},
      '680311': {'lat': '10.2943', 'name': 'Kunnappilly,Thrissur,Kerala', 'lon': '76.3551'},
      '680312': {'lat': '10.3994', 'name': 'Inchakundu,Thrissur,Kerala', 'lon': '76.3346'},
      '506368': {'lat': '17.594', 'name': 'Jama Thanda,Mahabubabad,Telangana', 'lon': '79.8232'},
      '273151': {'lat': '27.0474', 'name': 'Baspar,Maharajganj,Uttar Pradesh', 'lon': '83.5679'},
      '680317': {'lat': '10.3797', 'name': 'Muttithadi,Thrissur,Kerala', 'lon': '76.3026'},
      '673121': {'lat': '11.6304', 'name': 'Kalpetta H.O,Wayanad,Kerala', 'lon': '76.0574'},
      '624216': {'lat': '10.3022', 'name': 'Kanalkadu,Dindigul,Tamil Nadu', 'lon': '77.6912'},
      '673123': {'lat': '11.5737', 'name': 'Kunnambetta,Wayanad,Kerala', 'lon': '76.0583'},
      '673122': {'lat': '11.6141', 'name': 'Puzhamutti,Wayanad,Kerala', 'lon': '76.0353'},
      '577523': {'lat': '14.1243', 'name': 'Chikkandawadi,Chitradurga,Karnataka', 'lon': '76.1082'},
      '673124': {'lat': '11.7033', 'name': 'Kambalakkad,Wayanad,Kerala', 'lon': '76.0786'},
      '144209': {'lat': '31.7062', 'name': 'Chowki Patiari,Hoshiarpur,Punjab', 'lon': '75.9111'},
      '389115': {'lat': '23.0142', 'name': 'Kadadara,Panch Mahals,Gujarat', 'lon': '73.8878'},
      '144207': {'lat': '31.7292', 'name': 'Mand Pandher,Hoshiarpur,Punjab', 'lon': '75.7507'},
      '144206': {'lat': '31.7654', 'name': 'Kukanet,Hoshiarpur,Punjab', 'lon': '75.9117'},
      '144205': {'lat': '31.646', 'name': 'Dasuya H.O,Hoshiarpur,Punjab', 'lon': '75.7136'},
      '144204': {'lat': '31.6581', 'name': 'Talwandi Sallan,Hoshiarpur,Punjab', 'lon': '75.6456'},
      '144202': {'lat': '31.6654', 'name': 'Jalalpur,Hoshiarpur,Punjab', 'lon': '75.5478'},
      '144201': {'lat': '31.487', 'name': 'Jandir,Jalandhar,Punjab', 'lon': '75.6766'},
      '829209': {'lat': '23.6843', 'name': 'Hindgir,Ranchi,Jharkhand', 'lon': '85.0546'},
      '829208': {'lat': '23.6484', 'name': 'Mc. Cluskiganj,Ranchi,Jharkhand', 'lon': '84.948'},
      '754021': {'lat': '20.5096', 'name': 'Nimpur,Cuttack,Odisha', 'lon': '85.9249'},
      '457340': {'lat': '23.5676', 'name': 'Mamatkheda,Ratlam,Madhya Pradesh', 'lon': '76.0192'},
      '480771': {'lat': '21.9854', 'name': 'Neewari,Seoni,Madhya Pradesh', 'lon': '79.7263'},
      '829201': {'lat': '24.1735', 'name': 'Bachra,Chatra,Jharkhand', 'lon': '84.8871'},
      '829203': {'lat': '23.7268', 'name': 'Dahunawada,Latehar,Jharkhand', 'lon': '84.717'},
      '829202': {'lat': '23.8331', 'name': 'Chedra,Latehar,Jharkhand', 'lon': '84.8658'},
      '829205': {'lat': '23.4758', 'name': 'Chama,Ranchi,Jharkhand', 'lon': '85.305'},
      '829204': {'lat': '23.7909', 'name': 'Karwal,Latehar,Jharkhand', 'lon': '84.3101'},
      '281303': {'lat': '27.4393', 'name': 'Gokul,Mathura,Uttar Pradesh', 'lon': '77.7202'},
      '829206': {'lat': '23.8069', 'name': 'Udaypur,Latehar,Jharkhand', 'lon': '84.5858'},
      '712417': {'lat': '24.0611', 'name': 'Sabalsingpur,Hooghly,West Bengal', 'lon': '87.5731'},
      '712416': {'lat': '23.1495', 'name': 'Natibpur,Hooghly,West Bengal', 'lon': '87.8823'},
      '712415': {'lat': '22.4424', 'name': 'Harinkhola,Hooghly,West Bengal', 'lon': '88.5162'},
      '712414': {'lat': '22.9376', 'name': 'Alati,Hooghly,West Bengal', 'lon': '88.0801'},
      '712413': {'lat': '22.4859', 'name': 'Dihiboira,Hooghly,West Bengal', 'lon': '88.1326'},
      '712412': {'lat': '22.8283', 'name': 'Baradigrui,Hooghly,West Bengal', 'lon': '88.0778'},
      '783361': {'lat': '26.4623', 'name': 'Simultapu,Kokrajhar,Assam', 'lon': '89.891'},
      '783360': {'lat': '26.3872', 'name': 'Panbari Bazar,Bongaigaon,Assam', 'lon': '90.4381'},
      '250222': {'lat': '29.1312', 'name': 'Mel,Meerut,Uttar Pradesh', 'lon': '77.7791'},
      '783369': {'lat': '26.4442', 'name': 'Salakati Project,Kokrajhar,Assam', 'lon': '90.364'},
      '722202': {'lat': '23.4166', 'name': 'Muktatore,Bankura,West Bengal', 'lon': '87.8906'},
      '313804': {'lat': '24.2077', 'name': 'Kanbai,Udaipur,Rajasthan', 'lon': '73.7261'},
      '313803': {'lat': '24.2954', 'name': 'Bayri,Udaipur,Rajasthan', 'lon': '73.7042'},
      '313802': {'lat': '24.111', 'name': 'Godawat Gorimba,Udaipur,Rajasthan', 'lon': '73.6013'},
      '313801': {'lat': '24.6879', 'name': 'Tidi,Udaipur,Rajasthan', 'lon': '74.2322'},
      '828107': {'lat': '24.0728', 'name': 'Domgarh,Dhanbad,Jharkhand', 'lon': '86.506'},
      '814133': {'lat': '24.4789', 'name': 'Maheshpur,Pakur,Jharkhand', 'lon': '87.7629'},
      '249131': {'lat': '30.1499', 'name': 'Sour Uppu,Tehri Garhwal,Uttarakhand', 'lon': '78.7942'},
      '249130': {'lat': '30.2597', 'name': 'Joulangi,Tehri Garhwal,Uttarakhand', 'lon': '78.3649'},
      '782412': {'lat': '26.1605', 'name': 'Ahatguri,Marigaon,Assam', 'lon': '92.3518'},
      '782413': {'lat': '26.2081', 'name': 'Umswar,Karbi Anglon,Assam', 'lon': '92.5395'},
      '782410': {'lat': '26.2078', 'name': 'Umlapher,Marigaon,Assam', 'lon': '92.406'},
      '782411': {'lat': '26.4586', 'name': 'Buraburi,Marigaon,Assam', 'lon': '92.0144'},
      '383316': {'lat': '24.1359', 'name': 'Itadi,Sabarkantha,Gujarat', 'lon': '72.8898'},
      '383317': {'lat': '23.5568', 'name': 'Moti Isrol,Sabarkantha,Gujarat', 'lon': '73.3665'},
      '383315': {'lat': '23.5424', 'name': 'Madapur Kampa,Sabarkantha,Gujarat', 'lon': '73.2095'},
      '625579': {'lat': '9.9392', 'name': 'Kadamalaikundu,Theni,Tamil Nadu', 'lon': '77.7329'},
      '383310': {'lat': '23.2581', 'name': 'Navi Shinol,Sabarkantha,Gujarat', 'lon': '73.5725'},
      '690507': {'lat': '9.1694', 'name': 'Evur,Alappuzha,Kerala', 'lon': '76.591'},
      '344025': {'lat': '25.8061', 'name': 'Mewanagar,Barmer,Rajasthan', 'lon': '72.7027'},
      '144628': {'lat': '31.303', 'name': 'Nasirpur,Kapurthala,Punjab', 'lon': '75.2476'},
      '344027': {'lat': '25.458', 'name': 'Jethantri,Barmer,Rajasthan', 'lon': '73.1059'},
      '344026': {'lat': '25.9729', 'name': 'Thumbali,Barmer,Rajasthan', 'lon': '72.9889'},
      '344021': {'lat': '25.865', 'name': 'Ratri,Barmer,Rajasthan', 'lon': '72.8862'},
      '344022': {'lat': '25.7177', 'name': 'Dakha,Barmer,Rajasthan', 'lon': '72.169'},
      '443106': {'lat': '20.3854', 'name': 'Satgaon Mhasla,Buldhana,Maharashtra', 'lon': '75.9872'},
      '201019': {'lat': '28.6994', 'name': 'Vaishali,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '443104': {'lat': '20.9255', 'name': 'Dhamangaon Badhe,Buldhana,Maharashtra', 'lon': '76.2918'},
      '443102': {'lat': '20.5356', 'name': 'Pimpri Gawali,Buldhana,Maharashtra', 'lon': '76.4423'},
      '443103': {'lat': '20.7321', 'name': 'Takarkhed,Buldhana,Maharashtra', 'lon': '76.2812'},
      '712401': {'lat': '22.7546', 'name': 'Jangalpara,Hooghly,West Bengal', 'lon': '88.2692'},
      '443101': {'lat': '20.8329', 'name': 'Panhera Wadoda,Buldhana,Maharashtra', 'lon': '76.2956'},
      '201010': {'lat': '28.6994', 'name': 'I.E.Sahibabad,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '201011': {'lat': '28.6994', 'name': 'Chander Nagar,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '201012': {'lat': '28.6667', 'name': 'Kaushambi,Ghaziabad,Uttar Pradesh', 'lon': '77.4333'},
      '722204': {'lat': '23.1441', 'name': 'Hatasuria,Bankura,West Bengal', 'lon': '87.8188'},
      '201014': {'lat': '28.8056', 'name': 'Shipra Sun name,Ghaziabad,Uttar Pradesh', 'lon': '78.4417'},
      '201015': {'lat': '28.6921', 'name': 'Udai Rampur Nagla,Ghaziabad,Uttar Pradesh', 'lon': '77.5562'},
      '201016': {'lat': '28.6994', 'name': 'Crossing Republik,Ghaziabad,Uttar Pradesh', 'lon': '78.0571'},
      '201017': {'lat': '28.7039', 'name': 'Raj Nagar Extension,Ghaziabad,Uttar Pradesh', 'lon': '77.433'},
      '271201': {'lat': '26.7331', 'name': 'Gajaihwa,Balrampur,Uttar Pradesh', 'lon': '82.2735'},
      '503301': {'lat': '18.5404', 'name': 'Mirzapur (Nizamabad),Nizamabad,Telangana', 'lon': '78.1033'},
      '303348': {'lat': '26.8247', 'name': 'Sanwarda,Jaipur,Rajasthan', 'lon': '75.3356'},
      '277401': {'lat': '25.714', 'name': 'Agrauli,Ballia,Uttar Pradesh', 'lon': '84.2515'},
      '508111': {'lat': '17.4259', 'name': 'Kapraipally,Nalgonda,Telangana', 'lon': '79.1735'},
      '228120': {'lat': '26.2593', 'name': 'Baraunsa,Sultanpur,Uttar Pradesh', 'lon': '82.2169'},
      '814131': {'lat': '25.0498', 'name': 'Bhorsar,Banka,Bihar', 'lon': '86.4922'},
      '362037': {'lat': '23.2521', 'name': 'Khamdhrol,Junagadh,Gujarat', 'lon': '73.7315'},
      '605601': {'lat': '11.9844', 'name': 'Orathur,Villupuram,Tamil Nadu', 'lon': '79.5196'},
      '605602': {'lat': '11.9359', 'name': 'Santhanagopalapuram,Villupuram,Tamil Nadu', 'lon': '79.5152'},
      '722207': {'lat': '23.0991', 'name': 'Bonbirsingha,Bankura,West Bengal', 'lon': '87.7587'},
      '480108': {'lat': '21.5245', 'name': 'Saonga,Chhindwara,Madhya Pradesh', 'lon': '78.8846'},
      '851101': {'lat': '25.5185', 'name': 'Begusarai H.O,Begusarai,Bihar', 'lon': '86.0349'},
      '744210': {'lat': '12.1853', 'name': 'Oralkatcha,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.7718'},
      '744211': {'lat': '12.1853', 'name': 'Peal Island,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7718'},
      '396165': {'lat': '21.2331', 'name': 'Bhathi Karmbeli,Valsad,Gujarat', 'lon': '73.1929'},
      '474001': {'lat': '25.6088', 'name': 'Itm,Gwalior,Madhya Pradesh', 'lon': '77.5828'},
      '474003': {'lat': '25.8606', 'name': 'Hazira Chowk,Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '474002': {'lat': '25.8606', 'name': 'Industrial Estate Gwalior,Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '474005': {'lat': '26.1797', 'name': 'Dharamshala [D.D. Nagar],Gwalior,Madhya Pradesh', 'lon': '78.155'},
      '474004': {'lat': '25.8606', 'name': 'Birla Nagar,Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '474007': {'lat': '25.8606', 'name': 'Moti Mahal,Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '474006': {'lat': '25.5189', 'name': 'Bilhati Kalan,Gwalior,Madhya Pradesh', 'lon': '78.2319'},
      '635201': {'lat': '12.2839', 'name': 'Thathampatti,Dharmapuri,Tamil Nadu', 'lon': '78.3112'},
      '474008': {'lat': '25.8606', 'name': 'Gwalior Fort,Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '344024': {'lat': '25.8167', 'name': 'Jasol,Barmer,Rajasthan', 'lon': '72.2167'},
      '794107': {'lat': '25.5545', 'name': 'Jadigitim,East Garo Hills,Meghalaya', 'lon': '90.3369'},
      '456003': {'lat': '23.4977', 'name': 'Kaliadeh,Ujjain,Madhya Pradesh', 'lon': '75.6802'},
      '670102': {'lat': '11.7345', 'name': 'Kurichiyil,Kannur,Kerala', 'lon': '75.4992'},
      '670103': {'lat': '11.7473', 'name': 'Moozhikkara,Kannur,Kerala', 'lon': '75.5151'},
      '670101': {'lat': '11.744', 'name': 'Thalassery H.O,Kannur,Kerala', 'lon': '75.6432'},
      '670106': {'lat': '11.7754', 'name': 'Dharmadam,Kannur,Kerala', 'lon': '75.4646'},
      '670107': {'lat': '11.744', 'name': 'Eranholi,Kannur,Kerala', 'lon': '75.6432'},
      '670104': {'lat': '12.0006', 'name': 'Cherakkara,Kannur,Kerala', 'lon': '75.5262'},
      '670105': {'lat': '12.0006', 'name': 'Vadakkumbad,Kannur,Kerala', 'lon': '75.5262'},
      '202128': {'lat': '28.0977', 'name': 'Baranadi,Aligarh,Uttar Pradesh', 'lon': '78.6206'},
      '813101': {'lat': '25.0622', 'name': 'Kamdeopur,Banka,Bihar', 'lon': '86.5957'},
      '480555': {'lat': '22.1214', 'name': 'Rampur,Chhindwara,Madhya Pradesh', 'lon': '78.8555'},
      '481445': {'lat': '21.867', 'name': 'Khamaria,Balaghat,Madhya Pradesh', 'lon': '80.0454'},
      '582120': {'lat': '15.2192', 'name': 'Chebbi,Gadag,Karnataka', 'lon': '75.6448'},
      '480224': {'lat': '22.5924', 'name': 'Batkakhapa,Chhindwara,Madhya Pradesh', 'lon': '79.2048'},
      '712405': {'lat': '22.8966', 'name': 'Khamarchandi,Hooghly,West Bengal', 'lon': '88.2839'},
      '794106': {'lat': '25.3008', 'name': 'Balurghat,West Garo Hills,Meghalaya', 'lon': '89.8494'},
      '494670': {'lat': '20.3209', 'name': 'Talakurra,Kanker,Chattisgarh', 'lon': '81.3028'},
      '415407': {'lat': '17.5081', 'name': 'Red,Sangli,Maharashtra', 'lon': '74.104'},
      '271205': {'lat': '27.4082', 'name': 'Mujehani,Balrampur,Uttar Pradesh', 'lon': '82.4198'},
      '612002': {'lat': '10.9706', 'name': 'Melakaveri H.O,Thanjavur,Tamil Nadu', 'lon': '79.4063'},
      '612001': {'lat': '10.9706', 'name': 'Krishnarayar Agraharam,Thanjavur,Tamil Nadu', 'lon': '79.4063'},
      '480223': {'lat': '22.1307', 'name': 'Meghaseoni,Chhindwara,Madhya Pradesh', 'lon': '78.9245'},
      '700003': {'lat': '22.7101', 'name': 'Amrita Bazar Partika,Kolkata,West Bengal', 'lon': '88.3167'},
      '700002': {'lat': '22.7101', 'name': 'Paikapara,Kolkata,West Bengal', 'lon': '88.3167'},
      '700001': {'lat': '22.7101', 'name': "Writer's Building,Kolkata,West Bengal", 'lon': '88.3167'},
      '700007': {'lat': '22.7101', 'name': 'Kalakar Street,Kolkata,West Bengal', 'lon': '88.3167'},
      '700006': {'lat': '22.7101', 'name': 'Pathuriaghata,Kolkata,West Bengal', 'lon': '88.3167'},
      '700005': {'lat': '22.7101', 'name': 'Ahritola,Kolkata,West Bengal', 'lon': '88.3167'},
      '700004': {'lat': '22.7101', 'name': 'R.G.Kar Medical College,Kolkata,West Bengal', 'lon': '88.3167'},
      '246285': {'lat': '29.9745', 'name': 'Kafald,Pauri Garhwal,Uttarakhand', 'lon': '79.1278'},
      '700009': {'lat': '22.7101', 'name': 'Raja Ram Mohan Sarani,Kolkata,West Bengal', 'lon': '88.3167'},
      '700008': {'lat': '22.7101', 'name': 'Barisha,Kolkata,West Bengal', 'lon': '88.3167'},
      '712406': {'lat': '23.2246', 'name': 'Routhkhana,Hooghly,West Bengal', 'lon': '87.7621'},
      '794105': {'lat': '25.5732', 'name': 'Rongsakhona,West Garo Hills,Meghalaya', 'lon': '90.0018'},
      '828127': {'lat': '24.0055', 'name': 'Saraidhela,Dhanbad,Jharkhand', 'lon': '86.406'},
      '828126': {'lat': '24.0055', 'name': 'Sudamdih,Dhanbad,Jharkhand', 'lon': '86.406'},
      '828125': {'lat': '24.1621', 'name': 'Kharkharee,Bokaro,Jharkhand', 'lon': '86.4403'},
      '828124': {'lat': '24.0055', 'name': 'A.C.C. Colony,Dhanbad,Jharkhand', 'lon': '86.406'},
      '828123': {'lat': '24.0055', 'name': 'S.I.T.,Dhanbad,Jharkhand', 'lon': '86.406'},
      '813109': {'lat': '25.3885', 'name': 'Punsia,Banka,Bihar', 'lon': '86.0391'},
      '828121': {'lat': '23.7762', 'name': 'Bhadrichak,Dhanbad,Jharkhand', 'lon': '86.3303'},
      '600131': {'lat': '12.5938', 'name': 'Sithalapakkam,Kanchipuram,Tamil Nadu', 'lon': '80.0429'},
      '605003': {'lat': '11.8768', 'name': 'Muthialpet,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '600132': {'lat': '12.5938', 'name': 'Darkash,Kanchipuram,Tamil Nadu', 'lon': '80.0429'},
      '828129': {'lat': '24.1757', 'name': 'Karitand,Dhanbad,Jharkhand', 'lon': '86.4641'},
      '605002': {'lat': '11.8768', 'name': 'Sri Aurobindo Ashram,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '271861': {'lat': '26.8854', 'name': 'Laliya,Balrampur,Uttar Pradesh', 'lon': '81.7453'},
      '410222': {'lat': '18.8939', 'name': 'Mohopada,Raigarh(MH),Maharashtra', 'lon': '73.1836'},
      '140115': {'lat': '31.0351', 'name': 'Massewal,Rupnagar,Punjab', 'lon': '76.3631'},
      '480559': {'lat': '22.3299', 'name': 'Jamuniya,Chhindwara,Madhya Pradesh', 'lon': '78.6297'},
      '485005': {'lat': '24.5029', 'name': 'Satna Birla Vikas,Satna,Madhya Pradesh', 'lon': '80.7135'},
      '387560': {'lat': '22.9288', 'name': 'Shetra,Kheda,Gujarat', 'lon': '72.664'},
      '140114': {'lat': '31.0982', 'name': 'Gardley,Rupnagar,Punjab', 'lon': '76.6098'},
      '485001': {'lat': '24.5029', 'name': 'Satna Mukhtiyarganj,Satna,Madhya Pradesh', 'lon': '80.7135'},
      '230132': {'lat': '26.0632', 'name': 'Dawapur,Pratapgarh,Uttar Pradesh', 'lon': '81.8158'},
      '140113': {'lat': '31.3215', 'name': 'Lodhi Majra,Rupnagar,Punjab', 'lon': '75.8067'},
      '455440': {'lat': '22.6235', 'name': 'Bijwad,Dewas,Madhya Pradesh', 'lon': '76.6224'},
      '742225': {'lat': '24.3317', 'name': 'Raghunathganj H.O,Murshidabad,West Bengal', 'lon': '87.9972'},
      '829101': {'lat': '23.6669', 'name': 'Argada,Ramgarh,Jharkhand', 'lon': '85.3796'},
      '274301': {'lat': '26.853', 'name': 'Fardaha,Kushinagar,Uttar Pradesh', 'lon': '83.7309'},
      '274302': {'lat': '26.7112', 'name': 'Dashahawa,Kushinagar,Uttar Pradesh', 'lon': '83.8086'},
      '274303': {'lat': '26.8104', 'name': 'Horlapur,Kushinagar,Uttar Pradesh', 'lon': '84.0562'},
      '274304': {'lat': '26.8057', 'name': 'Padrauna H.O,Kushinagar,Uttar Pradesh', 'lon': '83.6937'},
      '700082': {'lat': '22.3341', 'name': 'K.P.Bazar (Kolkata),Kolkata,West Bengal', 'lon': '88.3065'},
      '274306': {'lat': '26.9309', 'name': 'Chakhani Bhumihari Patti,Kushinagar,Uttar Pradesh', 'lon': '83.6325'},
      '274307': {'lat': '26.8057', 'name': 'Padrauna Sugar Factory,Kushinagar,Uttar Pradesh', 'lon': '83.6937'},
      '274308': {'lat': '26.6567', 'name': 'Nahar Chhapara,Kushinagar,Uttar Pradesh', 'lon': '83.1609'},
      '608401': {'lat': '11.317', 'name': 'Karuppur,Cuddalore,Tamil Nadu', 'lon': '79.6789'},
      '243407': {'lat': '28.5604', 'name': 'Paiga,Bareilly,Uttar Pradesh', 'lon': '79.4979'},
      '136042': {'lat': '29.755', 'name': 'Rasina,Kaithal,Haryana', 'lon': '76.6801'},
      '712602': {'lat': '22.6235', 'name': 'Mriga Chatra,Hooghly,West Bengal', 'lon': '88.1836'},
      '205262': {'lat': '27.2615', 'name': 'Himaunpur,Mainpuri,Uttar Pradesh', 'lon': '79.204'},
      '605008': {'lat': '11.8768', 'name': 'Lawspet,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '735302': {'lat': '22.3539', 'name': 'Domohani,Jalpaiguri,West Bengal', 'lon': '88.7833'},
      '636203': {'lat': '11.2644', 'name': 'R.Komarapalayam,Salem,Tamil Nadu', 'lon': '78.4393'},
      '335701': {'lat': '25.687', 'name': 'Kamrania,Ganganagar,Rajasthan', 'lon': '75.5395'},
      '335702': {'lat': '27.6594', 'name': 'Jetsar Farm,Ganganagar,Rajasthan', 'lon': '75.3434'},
      '335703': {'lat': '28.4771', 'name': '2a,Ganganagar,Rajasthan', 'lon': '74.1443'},
      '335704': {'lat': '29.1545', 'name': '12 Gb,Ganganagar,Rajasthan', 'lon': '73.5956'},
      '335705': {'lat': '29.3754', 'name': '8 SHPD,Ganganagar,Rajasthan', 'lon': '73.7447'},
      '335707': {'lat': '28.4771', 'name': '7 Knd,Ganganagar,Rajasthan', 'lon': '74.1443'},
      '833101': {'lat': '22.7628', 'name': 'Gopidih,Seraikela-kharsawan,Jharkhand', 'lon': '85.827'},
      '471515': {'lat': '24.8912', 'name': 'Ghoorchitehri,Chhatarpur,Madhya Pradesh', 'lon': '79.936'},
      '471516': {'lat': '25.2701', 'name': 'Gaurihar,Chhatarpur,Madhya Pradesh', 'lon': '80.1963'},
      '833102': {'lat': '22.8218', 'name': 'Bharania,Seraikela-kharsawan,Jharkhand', 'lon': '85.6399'},
      '471510': {'lat': '25.1173', 'name': 'Badora Kala,Chhatarpur,Madhya Pradesh', 'lon': '79.9041'},
      '833104': {'lat': '22.4147', 'name': 'Dimduli,West Singhbhum,Jharkhand', 'lon': '85.1844'},
      '833106': {'lat': '22.3213', 'name': 'Salai,West Singhbhum,Jharkhand', 'lon': '85.3333'},
      '473001': {'lat': '24.4181', 'name': 'Guna name,Guna,Madhya Pradesh', 'lon': '77.4432'},
      '783134': {'lat': '26.043', 'name': 'Melopara,Bongaigaon,Assam', 'lon': '90.9533'},
      '828307': {'lat': '23.9144', 'name': 'Karmatand,Bokaro,Jharkhand', 'lon': '86.4403'},
      '140118': {'lat': '31.0351', 'name': 'Agampur,Ropar,Punjab', 'lon': '76.3631'},
      '496245': {'lat': '21.7708', 'name': 'Tumla,Jashpur,Chattisgarh', 'lon': '81.2376'},
      '142060': {'lat': '30.8042', 'name': 'Kabbar Wacha,Firozpur,Punjab', 'lon': '74.8793'},
      '201007': {'lat': '28.6275', 'name': 'Mohan Nagar (Ghaziabad),Ghaziabad,Uttar Pradesh', 'lon': '77.621'},
      '794101': {'lat': '25.5545', 'name': 'Dakopgiri,West Garo Hills,Meghalaya', 'lon': '90.3369'},
      '201006': {'lat': '28.6275', 'name': 'Chikamberpur,Ghaziabad,Uttar Pradesh', 'lon': '77.621'},
      '802133': {'lat': '25.285', 'name': 'Manjhawari,Buxar,Bihar', 'lon': '84.7436'},
      '360320': {'lat': '21.8705', 'name': 'Gomta,Rajkot,Gujarat', 'lon': '70.7291'},
      '201005': {'lat': '28.6988', 'name': 'Bhopura,Ghaziabad,Uttar Pradesh', 'lon': '77.3334'},
      '842005': {'lat': '25.6423', 'name': 'M.I.C.,Muzaffarpur,Bihar', 'lon': '86.0016'},
      '152004': {'lat': '30.6773', 'name': 'Patel Nagar,Firozpur,Punjab', 'lon': '74.5082'},
      '152005': {'lat': '30.9814', 'name': 'Sandhe Hasham,Firozpur,Punjab', 'lon': '74.8097'},
      '152002': {'lat': '30.9951', 'name': 'Chak Haraj,Firozpur,Punjab', 'lon': '74.6612'},
      '152003': {'lat': '30.9175', 'name': 'Khai Pheme Ki,Firozpur,Punjab', 'lon': '74.5863'},
      '125201': {'lat': '29.8369', 'name': 'Taruana,Sirsa,Haryana', 'lon': '75.1386'},
      '841441': {'lat': '26.5324', 'name': 'Baghibazar,Gopalganj,Bihar', 'lon': '84.2437'},
      '841440': {'lat': '25.5694', 'name': 'Thawe,Gopalganj,Bihar', 'lon': '85.6398'},
      '841443': {'lat': '25.9685', 'name': 'Shio Mohammadpur,Saran,Bihar', 'lon': '84.7773'},
      '841442': {'lat': '25.9069', 'name': 'Nagra (Saran),Saran,Bihar', 'lon': '84.753'},
      '635122': {'lat': '12.4818', 'name': 'Sembadamuthur,Krishnagiri,Tamil Nadu', 'lon': '78.1222'},
      '781378': {'lat': '26.536', 'name': 'Haribhanga,Nalbari,Assam', 'lon': '91.4097'},
      '635121': {'lat': '12.6567', 'name': 'Chennasandiram,Krishnagiri,Tamil Nadu', 'lon': '78.1373'},
      '249193': {'lat': '30.0986', 'name': 'Sald,Uttarkashi,Uttarakhand', 'lon': '79.1165'},
      '431508': {'lat': '19.4578', 'name': 'Kausadi,Parbhani,Maharashtra', 'lon': '76.7289'},
      '781372': {'lat': '26.7706', 'name': 'Pamuapathar,Nalbari,Assam', 'lon': '91.3933'},
      '781373': {'lat': '26.536', 'name': 'Jalagaon,Nalbari,Assam', 'lon': '91.4097'},
      '781370': {'lat': '26.4526', 'name': 'Gobradal,Nalbari,Assam', 'lon': '91.3843'},
      '781371': {'lat': '26.4758', 'name': 'Bhaluki,Nalbari,Assam', 'lon': '91.2669'},
      '781376': {'lat': '26.536', 'name': 'Tulsibari,Kamrup,Assam', 'lon': '91.4097'},
      '781377': {'lat': '26.3602', 'name': 'Saraimari,Nalbari,Assam', 'lon': '91.6277'},
      '781374': {'lat': '26.4463', 'name': 'Bhojkuchi,Nalbari,Assam', 'lon': '91.2674'},
      '781375': {'lat': '26.4167', 'name': 'Helana,Nalbari,Assam', 'lon': '91.3333'},
      '342604': {'lat': '26.4711', 'name': 'Sewania,Jodhpur,Rajasthan', 'lon': '73.8019'},
      '484116': {'lat': '23.6668', 'name': 'Batura,Anuppur,Madhya Pradesh', 'lon': '81.1933'},
      '344011': {'lat': '26.0634', 'name': 'Lunukhurd,Barmer,Rajasthan', 'lon': '73.1425'},
      '143534': {'lat': '32.1955', 'name': 'Taragarh (Gurdaspur),Pathankot,Punjab', 'lon': '75.4763'},
      '484117': {'lat': '23.0567', 'name': 'Bargawan,Anuppur,Madhya Pradesh', 'lon': '81.684'},
      '495695': {'lat': '21.8703', 'name': 'Kusmul,Janjgir-champa,Chattisgarh', 'lon': '83.0531'},
      '609003': {'lat': '11.1043', 'name': 'Koranad South,Nagapattinam,Tamil Nadu', 'lon': '79.6272'},
      '814155': {'lat': '24.4624', 'name': 'Maliachak,Bokaro,Jharkhand', 'lon': '86.5134'},
      '495691': {'lat': '21.7786', 'name': 'Basin,Janjgir-champa,Chattisgarh', 'lon': '83.0003'},
      '210424': {'lat': '25.4591', 'name': 'Singhaupur Baghari,Mahoba,Uttar Pradesh', 'lon': '80.0168'},
      '495692': {'lat': '21.8007', 'name': 'Reda,Janjgir-champa,Chattisgarh', 'lon': '83.152'},
      '492101': {'lat': '21.0976', 'name': 'Kurud Camp,Raipur,Chattisgarh', 'lon': '81.7223'},
      '580023': {'lat': '15.3884', 'name': 'Hubli Keshwapur,Dharwad,Karnataka', 'lon': '75.2016'},
      '224116': {'lat': '26.8806', 'name': 'Abboopur,Faizabad,Uttar Pradesh', 'lon': '81.7357'},
      '801507': {'lat': '25.5834', 'name': 'AIIMS,Patna,Bihar', 'lon': '85.0681'},
      '509311': {'lat': '16.7396', 'name': 'Chinnaporla,Mahabub Nagar,Telangana', 'lon': '77.6835'},
      '635809': {'lat': '12.8933', 'name': 'Odiyathur,Vellore,Tamil Nadu', 'lon': '78.9299'},
      '275307': {'lat': '26.0011', 'name': 'Semari Jamal Pur,Mau,Uttar Pradesh', 'lon': '83.2598'},
      '275306': {'lat': '26.102', 'name': 'Mirpur Rahimabad,Mau,Uttar Pradesh', 'lon': '83.7395'},
      '275305': {'lat': '25.9588', 'name': 'Saharoj,Mau,Uttar Pradesh', 'lon': '83.534'},
      '700085': {'lat': '22.3341', 'name': 'K.G Bose Sarani,Kolkata,West Bengal', 'lon': '88.3065'},
      '275303': {'lat': '26.1277', 'name': 'Canal Head,Mau,Uttar Pradesh', 'lon': '83.5128'},
      '580021': {'lat': '15.318', 'name': 'Hubli KMC,Dharwad,Karnataka', 'lon': '75.1763'},
      '796891': {'lat': '22.5325', 'name': 'Balhlakawn,Lawngtlai,Mizoram', 'lon': '92.899'},
      '322230': {'lat': '26.9897', 'name': 'Karsoli,Karauli,Rajasthan', 'lon': '76.8606'},
      '412308': {'lat': '18.4643', 'name': 'Vadki,Pune,Maharashtra', 'lon': '73.9672'},
      '322234': {'lat': '26.5652', 'name': 'Katkad,Karauli,Rajasthan', 'lon': '77.0749'},
      '301023': {'lat': '27.7011', 'name': 'Rata Khurd,Alwar,Rajasthan', 'lon': '76.6514'},
      '610107': {'lat': '10.9801', 'name': 'Srivanjiam,Tiruvarur,Tamil Nadu', 'lon': '79.4842'},
      '322238': {'lat': '27.8021', 'name': 'Nangal Pahadi,Karauli,Rajasthan', 'lon': '76.2642'},
      '126152': {'lat': '29.5026', 'name': 'Dhanoda Kalan,Jind,Haryana', 'lon': '76.0363'},
      '412301': {'lat': '18.3383', 'name': 'Vanpuri,Pune,Maharashtra', 'lon': '74.4945'},
      '412306': {'lat': '18.5709', 'name': 'Murum,Pune,Maharashtra', 'lon': '74.0691'},
      '688562': {'lat': '9.3622', 'name': 'Chirayakom,Alappuzha,Kerala', 'lon': '76.4266'},
      '688561': {'lat': '9.3846', 'name': 'Amabalapuzha East  B O,Alappuzha,Kerala', 'lon': '76.3551'},
      '786611': {'lat': '27.3779', 'name': 'Rajgarh (Dibrugarh),Dibrugarh,Assam', 'lon': '95.2828'},
      '848132': {'lat': '25.7712', 'name': 'Bhagwanpur Lohagir,Samastipur,Bihar', 'lon': '85.8071'},
      '848133': {'lat': '25.706', 'name': 'Kashore,Samastipur,Bihar', 'lon': '86.1658'},
      '416418': {'lat': '16.9232', 'name': 'Kuktoli,Sangli,Maharashtra', 'lon': '74.8403'},
      '416419': {'lat': '18.6366', 'name': 'Alkud (M),Sangli,Maharashtra', 'lon': '75.9463'},
      '786612': {'lat': '27.3779', 'name': 'Ouguri,Dibrugarh,Assam', 'lon': '95.2828'},
      '637408': {'lat': '11.4338', 'name': 'Rasipuram Bazaar,Namakkal,Tamil Nadu', 'lon': '78.0192'},
      '637409': {'lat': '11.2948', 'name': 'Ramanathapuram,Namakkal,Tamil Nadu', 'lon': '78.2602'},
      '416412': {'lat': '19.817', 'name': 'Sordi,Sangli,Maharashtra', 'lon': '75.0032'},
      '416413': {'lat': '17.7244', 'name': 'Sonyal,Sangli,Maharashtra', 'lon': '75.1264'},
      '416410': {'lat': '17.7244', 'name': 'Miraj Audyogik Vasahat,Sangli,Maharashtra', 'lon': '75.1264'},
      '786613': {'lat': '27.3779', 'name': 'Borpathar,Dibrugarh,Assam', 'lon': '95.2828'},
      '416416': {'lat': '16.8544', 'name': 'Gajanan Mills Sangli,Sangli,Maharashtra', 'lon': '74.5642'},
      '416417': {'lat': '17.7244', 'name': 'Kavathe Piran,Sangli,Maharashtra', 'lon': '75.1264'},
      '416414': {'lat': '16.884', 'name': 'Vaddi,Sangli,Maharashtra', 'lon': '74.6105'},
      '416415': {'lat': '17.7244', 'name': 'Mouje Digraj,Sangli,Maharashtra', 'lon': '75.1264'},
      '843102': {'lat': '25.9363', 'name': 'Kishanpur Telour,Vaishali,Bihar', 'lon': '85.5007'},
      '843103': {'lat': '25.6537', 'name': 'P.Shyam,Muzaffarpur,Bihar', 'lon': '85.9574'},
      '385555': {'lat': '24.0221', 'name': 'Jakhel,Banaskantha,Gujarat', 'lon': '72.091'},
      '843106': {'lat': '26.1511', 'name': 'Gidha,Muzaffarpur,Bihar', 'lon': '85.3308'},
      '385001': {'lat': '24.1951', 'name': 'Vasan,Banaskantha,Gujarat', 'lon': '72.4787'},
      '385550': {'lat': '24.1411', 'name': 'Kunvarva,Banaskantha,Gujarat', 'lon': '72.2036'},
      '843105': {'lat': '25.9972', 'name': 'Titra Ashanand,Muzaffarpur,Bihar', 'lon': '85.5764'},
      '843108': {'lat': '25.8568', 'name': 'Chapra Dharampur Jaddu,Muzaffarpur,Bihar', 'lon': '85.3766'},
      '843109': {'lat': '25.6036', 'name': 'Nariar Panapur,Muzaffarpur,Bihar', 'lon': '84.7234'},
      '522202': {'lat': '16.2624', 'name': 'Katevaram,Guntur,Andhra Pradesh', 'lon': '80.6832'},
      '522201': {'lat': '16.2624', 'name': 'Tenali H.O,Guntur,Andhra Pradesh', 'lon': '80.6832'},
      '262902': {'lat': '26.7551', 'name': 'Dudwaghat,Kheri,Uttar Pradesh', 'lon': '81.5776'},
      '723127': {'lat': '23.3989', 'name': 'Dhelat Bamu,Purulia,West Bengal', 'lon': '86.7677'},
      '262905': {'lat': '28.0364', 'name': 'Naubana,Kheri,Uttar Pradesh', 'lon': '80.8185'},
      '625531': {'lat': '10.0159', 'name': 'T.Nagar (Theni),Theni,Tamil Nadu', 'lon': '77.4824'},
      '625530': {'lat': '9.914', 'name': 'Sindalacherry,Theni,Tamil Nadu', 'lon': '77.3018'},
      '625533': {'lat': '9.807', 'name': 'Hanumanthanpatti,Theni,Tamil Nadu', 'lon': '77.3272'},
      '625532': {'lat': '10.3958', 'name': 'Doddappanayakanur,Madurai,Tamil Nadu', 'lon': '78.357'},
      '625535': {'lat': '9.8337', 'name': 'E.Kottapatty,Madurai,Tamil Nadu', 'lon': '77.6787'},
      '625534': {'lat': '9.9262', 'name': 'Tadichery,Theni,Tamil Nadu', 'lon': '77.4976'},
      '625537': {'lat': '10.0574', 'name': 'Uthappanayakanur,Madurai,Tamil Nadu', 'lon': '78.436'},
      '625536': {'lat': '10.0069', 'name': 'P.Dharmathupatti,Theni,Tamil Nadu', 'lon': '77.6451'},
      '403601': {'lat': '15.3174', 'name': 'Gandhi Market Complex Margao,South Goa,Goa', 'lon': '73.9387'},
      '403602': {'lat': '15.3174', 'name': 'Fatorda,South Goa,Goa', 'lon': '73.9387'},
      '229203': {'lat': '26.1019', 'name': 'Pure Bharo Mishir,Raebareli,Uttar Pradesh', 'lon': '81.0007'},
      '143530': {'lat': '32.016', 'name': 'Khojepur,Gurdaspur,Punjab', 'lon': '75.4512'},
      '143006': {'lat': '31.6035', 'name': 'Kot Atma Ram,Amritsar,Punjab', 'lon': '74.909'},
      '517408': {'lat': '13.2443', 'name': 'Chalamangalam,Chittoor,Andhra Pradesh', 'lon': '78.6956'},
      '143001': {'lat': '31.5729', 'name': 'Amritsar G.P.O.,Amritsar,Punjab', 'lon': '75.0058'},
      '143002': {'lat': '31.6029', 'name': 'J.F.Mill,Amritsar,Punjab', 'lon': '74.8732'},
      '143003': {'lat': '31.6029', 'name': 'Nodal Delivery Office Amritsar,Amritsar,Punjab', 'lon': '74.8732'},
      '229209': {'lat': '26.059', 'name': 'Paho,Raebareli,Uttar Pradesh', 'lon': '80.9017'},
      '229208': {'lat': '26.2009', 'name': 'Nihastha,Raebareli,Uttar Pradesh', 'lon': '81.1833'},
      '756079': {'lat': '21.2851', 'name': 'Nayapalli,Baleswar,Odisha', 'lon': '86.0977'},
      '143009': {'lat': '31.5491', 'name': 'Thathgarh,Tarn Taran,Punjab', 'lon': '74.799'},
      '852213': {'lat': '25.5749', 'name': 'Laua Lagam,Madhepura,Bihar', 'lon': '86.952'},
      '431132': {'lat': '20.495', 'name': 'Madni,Aurangabad,Maharashtra', 'lon': '75.8299'},
      '431133': {'lat': '20.1344', 'name': 'Shendurwada,Aurangabad,Maharashtra', 'lon': '75.2291'},
      '431130': {'lat': '19.2926', 'name': 'Ardha Pimpri,Beed,Maharashtra', 'lon': '75.5969'},
      '431131': {'lat': '19.0769', 'name': 'Badibadi,Beed,Maharashtra', 'lon': '76.2174'},
      '431136': {'lat': '20.7418', 'name': 'Waladgaon,Aurangabad,Maharashtra', 'lon': '76.4428'},
      '431137': {'lat': '19.7359', 'name': 'Hiradpuri,Aurangabad,Maharashtra', 'lon': '75.9331'},
      '431134': {'lat': '20.4616', 'name': 'Pir Bawada,Aurangabad,Maharashtra', 'lon': '75.8848'},
      '431135': {'lat': '19.9163', 'name': 'Warud KH,Aurangabad,Maharashtra', 'lon': '75.84'},
      '393120': {'lat': '21.3997', 'name': 'Asha,Bharuch,Gujarat', 'lon': '73.3686'},
      '224186': {'lat': '26.3836', 'name': 'Rukunuddinpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6475'},
      '577548': {'lat': '13.3097', 'name': 'Saraswathipura,Chikkamagaluru,Karnataka', 'lon': '76.2527'},
      '577549': {'lat': '13.83', 'name': 'Siva R.S.,Chikkamagaluru,Karnataka', 'lon': '76.0317'},
      '224183': {'lat': '25.8705', 'name': 'Masorha,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.8676'},
      '393125': {'lat': '22.5497', 'name': 'Sabaria,Surat,Gujarat', 'lon': '70.4812'},
      '224181': {'lat': '26.2673', 'name': 'Aribpur Ramnagar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.7539'},
      '533102': {'lat': '17.3253', 'name': 'Nidigatla,East Godavari,Andhra Pradesh', 'lon': '82.2756'},
      '533103': {'lat': '17.4131', 'name': 'Danavaipeta,East Godavari,Andhra Pradesh', 'lon': '82.239'},
      '628219': {'lat': '8.4692', 'name': 'Pitchivilai,Tuticorin,Tamil Nadu', 'lon': '78.0388'},
      '533101': {'lat': '17.4131', 'name': 'Syamalamba Temple,East Godavari,Andhra Pradesh', 'lon': '82.239'},
      '533106': {'lat': '17.4131', 'name': 'Prakasamnagar,East Godavari,Andhra Pradesh', 'lon': '82.239'},
      '533107': {'lat': '17.5702', 'name': 'Punyakshetram,East Godavari,Andhra Pradesh', 'lon': '82.2568'},
      '533104': {'lat': '17.4131', 'name': 'Aryapuram,East Godavari,Andhra Pradesh', 'lon': '82.239'},
      '533105': {'lat': '17.4501', 'name': 'Weavers Colony,East Godavari,Andhra Pradesh', 'lon': '82.0572'},
      '628213': {'lat': '8.4785', 'name': 'Seerudaiyarpuram,Tuticorin,Tamil Nadu', 'lon': '78.0465'},
      '628212': {'lat': '8.5999', 'name': 'Viramanickam,Tuticorin,Tamil Nadu', 'lon': '78.0695'},
      '628211': {'lat': '8.5785', 'name': 'Idaiyanvilai,Tuticorin,Tamil Nadu', 'lon': '78.0317'},
      '370605': {'lat': '23.1943', 'name': 'Amara,Kachchh,Gujarat', 'lon': '69.0819'},
      '370602': {'lat': '23.1943', 'name': 'ATPS Nani Chher,Kachchh,Gujarat', 'lon': '69.0819'},
      '628216': {'lat': '8.5999', 'name': 'Virapandianpatanam,Tuticorin,Tamil Nadu', 'lon': '78.0695'},
      '628215': {'lat': '8.5999', 'name': 'Tiruchendur Devasthanam,Tuticorin,Tamil Nadu', 'lon': '78.0695'},
      '370601': {'lat': '23.1943', 'name': 'Narayan Sarovar,Kachchh,Gujarat', 'lon': '69.0819'},
      '284306': {'lat': '25.609', 'name': 'Nari,Jhansi,Uttar Pradesh', 'lon': '78.7887'},
      '382780': {'lat': '23.2878', 'name': 'Adariyana,Surendra Nagar,Gujarat', 'lon': '72.4227'},
      '303602': {'lat': '26.9805', 'name': 'Deva Ka Bas,Jaipur,Rajasthan', 'lon': '75.7243'},
      '284305': {'lat': '25.7648', 'name': 'Parichha Thermal Power Project,Jhansi,Uttar Pradesh', 'lon': '78.9863'},
      '643105': {'lat': '10.4856', 'name': 'Ottupattarai,Nilgiris,Tamil Nadu', 'lon': '77.0925'},
      '284304': {'lat': '25.8401', 'name': 'Badokhari,Jhansi,Uttar Pradesh', 'lon': '78.9351'},
      '643101': {'lat': '10.4856', 'name': 'Coonoor H.O,Nilgiris,Tamil Nadu', 'lon': '77.0925'},
      '643102': {'lat': '10.0388', 'name': 'Hulical,Nilgiris,Tamil Nadu', 'lon': '77.2327'},
      '643103': {'lat': '11.3791', 'name': 'Pasteur Institute,Nilgiris,Tamil Nadu', 'lon': '76.812'},
      '127201': {'lat': '28.5644', 'name': 'Sohansara,Bhiwani,Haryana', 'lon': '75.7849'},
      '277123': {'lat': '25.8931', 'name': 'Ratsar,Ballia,Uttar Pradesh', 'lon': '84.0368'},
      '303603': {'lat': '27.0425', 'name': 'Baseri Khurd,Jaipur,Rajasthan', 'lon': '74.9105'},
      '246488': {'lat': '29.9679', 'name': 'Nainisain,Chamoli,Uttarakhand', 'lon': '79.586'},
      '332711': {'lat': '27.208', 'name': 'Maonda Khurd,Sikar,Rajasthan', 'lon': '75.8019'},
      '277124': {'lat': '25.749', 'name': 'Ukachhi,Ballia,Uttar Pradesh', 'lon': '83.8132'},
      '140117': {'lat': '31.2016', 'name': 'Chanauli,Rupnagar,Punjab', 'lon': '76.1235'},
      '382255': {'lat': '22.0088', 'name': 'Godhavata,Ahmedabad,Gujarat', 'lon': '69.7145'},
      '410221': {'lat': '18.3667', 'name': 'Ongc Complex,16,panvel', 'lon': '72.9333'},
      '410220': {'lat': '18.8015', 'name': 'Patalganga,Raigarh(MH),Maharashtra', 'lon': '73.3228'},
      '382250': {'lat': '22.1561', 'name': 'Jeska,Ahmedabad,Gujarat', 'lon': '70.7707'},
      '140112': {'lat': '30.8869', 'name': 'Bassi Gujran,Mohali,Punjab', 'lon': '76.4071'},
      '140111': {'lat': '30.9274', 'name': 'Bhallian,Rupnagar,Punjab', 'lon': '76.4078'},
      '140110': {'lat': '30.8446', 'name': 'Phatwan,Mohali,Punjab', 'lon': '76.7242'},
      '783132': {'lat': '26.043', 'name': 'Jaleswar,Goalpara,Assam', 'lon': '90.9533'},
      '783133': {'lat': '26.043', 'name': 'Rajapara,Goalpara,Assam', 'lon': '90.9533'},
      '783130': {'lat': '26.154', 'name': 'Kahibari,Goalpara,Assam', 'lon': '91.3041'},
      '783131': {'lat': '26.043', 'name': 'Radhamadhabhat,Dhubri,Assam', 'lon': '90.9533'},
      '504311': {'lat': '19.2993', 'name': 'Gadiguda,Adilabad,Telangana', 'lon': '78.7046'},
      '504310': {'lat': '19.0962', 'name': 'Chintalchanda,Adilabad,Telangana', 'lon': '78.5875'},
      '140119': {'lat': '31.1115', 'name': 'Jhandian,Rupnagar,Punjab', 'lon': '76.5321'},
      '783135': {'lat': '25.7099', 'name': 'Manullapara,Dhubri,Assam', 'lon': '89.9008'},
      '277301': {'lat': '25.9657', 'name': 'Kummhaila,Ballia,Uttar Pradesh', 'lon': '84.0826'},
      '635126': {'lat': '12.7879', 'name': 'Hosur Indl. Complex,Krishnagiri,Tamil Nadu', 'lon': '77.8019'},
      '277303': {'lat': '26.0694', 'name': 'Lilkar,Ballia,Uttar Pradesh', 'lon': '84.0568'},
      '394355': {'lat': '21.172', 'name': 'Rayam,Surat,Gujarat', 'lon': '73.1872'},
      '635123': {'lat': '12.2969', 'name': 'Deveerahalli,Krishnagiri,Tamil Nadu', 'lon': '78.293'},
      '523155': {'lat': '14.7828', 'name': 'Chirala H.O,Prakasam,Andhra Pradesh', 'lon': '78.6505'},
      '523156': {'lat': '15.1527', 'name': 'Mgc Market(C ),Prakasam,Andhra Pradesh', 'lon': '79.7797'},
      '523157': {'lat': '15.8328', 'name': 'Perala,Prakasam,Andhra Pradesh', 'lon': '80.3622'},
      '303604': {'lat': '25.9455', 'name': 'Salt Colony,Jaipur,Rajasthan', 'lon': '74.7342'},
      '394352': {'lat': '21.2659', 'name': 'Lakhanpur,Surat,Gujarat', 'lon': '73.072'},
      '394601': {'lat': '21.5548', 'name': 'Bardoli H.O,Surat,Gujarat', 'lon': '73.1893'},
      '852161': {'lat': '25.5263', 'name': 'Dumri,Khagaria,Bihar', 'lon': '86.7346'},
      '394350': {'lat': '21.0529', 'name': 'Ninat,Surat,Gujarat', 'lon': '73.0844'},
      '177219': {'lat': '31.8686', 'name': 'Sunkali,Una,Himachal Pradesh', 'lon': '76.3258'},
      '177212': {'lat': '31.5421', 'name': 'Nangal Jarialan,Una,Himachal Pradesh', 'lon': '76.4036'},
      '177213': {'lat': '31.7649', 'name': 'Gondpur Banera,Una,Himachal Pradesh', 'lon': '76.08'},
      '177210': {'lat': '32.1818', 'name': 'Chaksarai,Una,Himachal Pradesh', 'lon': '76.2018'},
      '177211': {'lat': '31.912', 'name': 'Takarala,Una,Himachal Pradesh', 'lon': '76.4384'},
      '500037': {'lat': '17.3939', 'name': 'Rangareddynagar,Hyderabad,Telangana', 'lon': '78.4529'},
      '444915': {'lat': '21.1348', 'name': 'Yawali,Amravati,Maharashtra', 'lon': '78.1087'},
      '246486': {'lat': '30.3072', 'name': 'Tentura,Chamoli,Uttarakhand', 'lon': '79.4778'},
      '515435': {'lat': '14.8258', 'name': 'Narasapuram East,Ananthapur,Andhra Pradesh', 'lon': '77.7423'},
      '814113': {'lat': '24.1529', 'name': 'Deoghar College,Deoghar,Jharkhand', 'lon': '86.9182'},
      '678102': {'lat': '10.6812', 'name': 'Tattamangalam South,Palakkad,Kerala', 'lon': '76.7052'},
      '678103': {'lat': '10.6901', 'name': 'Vilayodi,Palakkad,Kerala', 'lon': '76.7526'},
      '678101': {'lat': '10.699', 'name': 'Chittur Courts,Palakkad,Kerala', 'lon': '76.8001'},
      '394170': {'lat': '22.1547', 'name': 'Nogama,Surat,Gujarat', 'lon': '73.0892'},
      '678104': {'lat': '10.6901', 'name': 'Chittur College,Palakkad,Kerala', 'lon': '76.7526'},
      '363030': {'lat': '22.7982', 'name': 'Timba,Surendra Nagar,Gujarat', 'lon': '71.5402'},
      '587201': {'lat': '15.8365', 'name': 'Katarki,Bagalkot,Karnataka', 'lon': '75.6835'},
      '285202': {'lat': '25.9908', 'name': 'Chhonk,Jalaun,Uttar Pradesh', 'lon': '79.671'},
      '624002': {'lat': '10.2612', 'name': 'Kuttathupatti,Dindigul,Tamil Nadu', 'lon': '78.0538'},
      '363035': {'lat': '22.7938', 'name': 'Wadhwanname Ind. Estate,Surendra Nagar,Gujarat', 'lon': '72.5843'},
      '624001': {'lat': '10.3315', 'name': 'Pandianagar,Dindigul,Tamil Nadu', 'lon': '78.1048'},
      '624004': {'lat': '10.3315', 'name': 'Settinaickenpatti,Dindigul,Tamil Nadu', 'lon': '78.1048'},
      '535523': {'lat': '19.0061', 'name': 'Nandrukona,Vizianagaram,Andhra Pradesh', 'lon': '83.5335'},
      '831012': {'lat': '22.9091', 'name': 'Kapali,Seraikela-kharsawan,Jharkhand', 'lon': '86.26947'},
      '802129': {'lat': '25.6188', 'name': 'Nagwan,Buxar,Bihar', 'lon': '84.1192'},
      '396403': {'lat': '21.0819', 'name': 'Aat,Navsari,Gujarat', 'lon': '72.5007'},
      '587204': {'lat': '16.293', 'name': 'Kaladgi  Bazar,Bagalkot,Karnataka', 'lon': '75.7572'},
      '396406': {'lat': '21.0819', 'name': 'Kalthan,Navsari,Gujarat', 'lon': '72.5007'},
      '396409': {'lat': '21.0819', 'name': 'Kalvach,Navsari,Gujarat', 'lon': '72.5007'},
      '535521': {'lat': '18.9163', 'name': 'Kottu,Vizianagaram,Andhra Pradesh', 'lon': '83.4826'},
      '671351': {'lat': '12.4407', 'name': 'Achanthuruthi,Kasargod,Kerala', 'lon': '75.0863'},
      '176101': {'lat': '32.0327', 'name': 'Kahanpat,Kangra,Himachal Pradesh', 'lon': '76.3636'},
      '679578': {'lat': '10.7903', 'name': 'Vattamkulam,Malappuram,Kerala', 'lon': '76.0331'},
      '176103': {'lat': '32.0319', 'name': 'Panchrukhi,Kangra,Himachal Pradesh', 'lon': '76.5893'},
      '176102': {'lat': '32.0683', 'name': 'Arla,Kangra,Himachal Pradesh', 'lon': '76.5241'},
      '504110': {'lat': '19.1202', 'name': 'Chincholi (B),Adilabad,Telangana', 'lon': '78.4779'},
      '176107': {'lat': '31.9232', 'name': 'Bachhwai,Kangra,Himachal Pradesh', 'lon': '76.4548'},
      '176109': {'lat': '31.8613', 'name': 'Tarpohal,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5857'},
      '176108': {'lat': '32.2665', 'name': 'Bhaleth,Hamirpur(HP),Himachal Pradesh', 'lon': '75.8492'},
      '754103': {'lat': '20.4871', 'name': 'Jagatsinghpur H.O,Jagatsinghapur,Odisha', 'lon': '85.2666'},
      '754102': {'lat': '20.4871', 'name': 'Adhangagarh,Jagatsinghapur,Odisha', 'lon': '85.2666'},
      '502303': {'lat': '18.0558', 'name': 'Lingaipally,Medak,Telangana', 'lon': '78.0661'},
      '754100': {'lat': '20.4204', 'name': 'Paramahansa,Cuttack,Odisha', 'lon': '85.5936'},
      '754107': {'lat': '20.4871', 'name': 'Kundeswar,Jagatsinghapur,Odisha', 'lon': '85.2666'},
      '383120': {'lat': '22.9362', 'name': 'Moyad,Sabarkantha,Gujarat', 'lon': '71.9992'},
      '754105': {'lat': '20.4871', 'name': 'Kulashree,Cuttack,Odisha', 'lon': '85.2666'},
      '754104': {'lat': '20.416', 'name': 'Radhanga,Jagatsinghapur,Odisha', 'lon': '85.2438'},
      '754109': {'lat': '20.4871', 'name': 'Nowgang,Jagatsinghapur,Odisha', 'lon': '85.2666'},
      '754108': {'lat': '20.4149', 'name': 'Kalio,Jagatsinghapur,Odisha', 'lon': '85.2048'},
      '261151': {'lat': '26.8191', 'name': 'Kotra Deokali,Sitapur,Uttar Pradesh', 'lon': '80.88'},
      '518380': {'lat': '15.4032', 'name': 'Jutur,Kurnool,Andhra Pradesh', 'lon': '77.5291'},
      '518385': {'lat': '15.2609', 'name': 'Hampa,Kurnool,Andhra Pradesh', 'lon': '77.4768'},
      '629153': {'lat': '8.8335', 'name': 'Chathancode,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '242305': {'lat': '28.0568', 'name': 'Sootha,Shahjahanpur,Uttar Pradesh', 'lon': '79.7664'},
      '365450': {'lat': '21.384', 'name': 'Sanali,Amreli,Gujarat', 'lon': '71.2165'},
      '781141': {'lat': '26.5807', 'name': 'Jambari,Kamrup,Assam', 'lon': '91.4583'},
      '230201': {'lat': '25.733', 'name': 'Kilahanapur,Pratapgarh,Uttar Pradesh', 'lon': '81.4525'},
      '271309': {'lat': '26.8732', 'name': 'Akbar Pur,Gonda,Uttar Pradesh', 'lon': '82.028'},
      '271308': {'lat': '26.9135', 'name': 'Iti Mankapur,Gonda,Uttar Pradesh', 'lon': '82.2277'},
      '494111': {'lat': '18.8863', 'name': 'Badesatti,Dantewada,Chattisgarh', 'lon': '81.6702'},
      '629151': {'lat': '8.3707', 'name': 'Chitharal,Kanyakumari,Tamil Nadu', 'lon': '77.2328'},
      '721128': {'lat': '22.4143', 'name': 'Debagram,West Midnapore,West Bengal', 'lon': '87.267'},
      '271303': {'lat': '26.7598', 'name': 'Lauwa Beerpur,Gonda,Uttar Pradesh', 'lon': '82.0328'},
      '362110': {'lat': '21.6442', 'name': 'Mangnathpipli,Junagadh,Gujarat', 'lon': '70.649'},
      '271301': {'lat': '26.8804', 'name': 'Ram Pur Bansawan,Gonda,Uttar Pradesh', 'lon': '82.13'},
      '271307': {'lat': '27.1497', 'name': 'Achalpur Kaithawalia,Gonda,Uttar Pradesh', 'lon': '82.3801'},
      '271306': {'lat': '27.1574', 'name': 'Bisunpur Grant,Balrampur,Uttar Pradesh', 'lon': '82.2626'},
      '271305': {'lat': '26.8094', 'name': 'Gaura Pandey,Basti,Uttar Pradesh', 'lon': '82.6464'},
      '271304': {'lat': '26.9135', 'name': 'Nawabganj S F,Gonda,Uttar Pradesh', 'lon': '82.2277'},
      '632315': {'lat': '12.6819', 'name': 'Ayyampalayam,Tiruvannamalai,Tamil Nadu', 'lon': '79.2831'},
      '632314': {'lat': '12.7137', 'name': 'Sirumur,Tiruvannamalai,Tamil Nadu', 'lon': '79.2258'},
      '571102': {'lat': '12.8212', 'name': 'Kothavalli,Mysuru,Karnataka', 'lon': '75.9727'},
      '632316': {'lat': '12.6275', 'name': 'Rattinamangalam,Tiruvannamalai,Tamil Nadu', 'lon': '79.3079'},
      '632311': {'lat': '12.4311', 'name': 'Kulathur,Tiruvannamalai,Tamil Nadu', 'lon': '79.1677'},
      '632313': {'lat': '12.5406', 'name': 'Namathodu,Tiruvannamalai,Tamil Nadu', 'lon': '79.3596'},
      '632312': {'lat': '12.6456', 'name': 'Kattukanur,Tiruvannamalai,Tamil Nadu', 'lon': '79.2809'},
      '632319': {'lat': '12.7732', 'name': 'Varagur Pudur,Tiruvannamalai,Tamil Nadu', 'lon': '79.1842'},
      '193201': {'lat': '34.3229', 'name': 'Nowpora,Baramulla,Jammu & Kashmir', 'lon': '74.4722'},
      '670595': {'lat': '11.9766', 'name': 'Edayannur,Kannur,Kerala', 'lon': '75.5297'},
      '534406': {'lat': '16.8222', 'name': 'Thokalapally,West Godavari,Andhra Pradesh', 'lon': '81.3771'},
      '400707': {'lat': '18.9089', 'name': 'Jaskhar,Raigarh(MH),Maharashtra', 'lon': '72.9822'},
      '400706': {'lat': '18.9894', 'name': 'Nerul Node-II,Thane,Maharashtra', 'lon': '72.961'},
      '670591': {'lat': '11.9303', 'name': 'Eachur,Kannur,Kerala', 'lon': '75.4455'},
      '670593': {'lat': '11.928', 'name': 'Kusavanvayal,Kannur,Kerala', 'lon': '75.4616'},
      '670592': {'lat': '11.928', 'name': 'Kanhirode,Kannur,Kerala', 'lon': '75.4616'},
      '683522': {'lat': '9.6778', 'name': 'Kunjithai,Ernakulam,Kerala', 'lon': '76.8295'},
      '683520': {'lat': '9.6778', 'name': 'Manjaly,Ernakulam,Kerala', 'lon': '76.8295'},
      '683521': {'lat': '9.6778', 'name': 'Vadakkumpuram,Ernakulam,Kerala', 'lon': '76.8295'},
      '400709': {'lat': '18.9894', 'name': 'Kopar Khairne,Thane,Maharashtra', 'lon': '72.961'},
      '400708': {'lat': '19.151', 'name': 'Airoli,Thane,Maharashtra', 'lon': '72.9962'},
      '743503': {'lat': '22.3252', 'name': 'Chandandaha,South 24 Parganas,West Bengal', 'lon': '88.2837'},
      '518166': {'lat': '14.9531', 'name': 'R.Lingamdinne,Kurnool,Andhra Pradesh', 'lon': '78.1732'},
      '518165': {'lat': '15.1474', 'name': 'Sanjamala,Kurnool,Andhra Pradesh', 'lon': '78.2993'},
      '743504': {'lat': '22.2209', 'name': 'Puratan Ramgarh,South 24 Parganas,West Bengal', 'lon': '88.1436'},
      '622404': {'lat': '10.3457', 'name': 'Rarapuram,Pudukkottai,Tamil Nadu', 'lon': '78.7013'},
      '622407': {'lat': '10.2803', 'name': 'Pudupatti (Pudukkottai),Pudukkottai,Tamil Nadu', 'lon': '78.536'},
      '622401': {'lat': '10.3115', 'name': 'Koppanapatti,Pudukkottai,Tamil Nadu', 'lon': '78.4691'},
      '622402': {'lat': '10.3121', 'name': 'Sundaram,Pudukkottai,Tamil Nadu', 'lon': '78.6187'},
      '622403': {'lat': '10.2016', 'name': 'Vendan patti,Pudukkottai,Tamil Nadu', 'lon': '78.5029'},
      '700095': {'lat': '22.4485', 'name': 'Golf Green,Kolkata,West Bengal', 'lon': '88.3883'},
      '305625': {'lat': '26.4024', 'name': 'Lodiyana,Ajmer,Rajasthan', 'lon': '76.2758'},
      '622409': {'lat': '9.8962', 'name': 'Rangiem,Pudukkottai,Tamil Nadu', 'lon': '78.472'},
      '636804': {'lat': '12.0662', 'name': 'Nallasenahalli,Dharmapuri,Tamil Nadu', 'lon': '78.1776'},
      '612703': {'lat': '10.93', 'name': 'Cholanmaligai,Thanjavur,Tamil Nadu', 'lon': '79.3477'},
      '389320': {'lat': '22.9867', 'name': 'Derol R S,Panch Mahals,Gujarat', 'lon': '73.4174'},
      '221306': {'lat': '25.2648', 'name': 'Sahasepur,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.4935'},
      '455115': {'lat': '22.7451', 'name': 'Khokriya,Dewas,Madhya Pradesh', 'lon': '76.4564'},
      '700087': {'lat': '22.3341', 'name': 'New Market,Kolkata,West Bengal', 'lon': '88.3065'},
      '221307': {'lat': '25.3146', 'name': 'Benipur Kundariya,Varanasi,Uttar Pradesh', 'lon': '82.7968'},
      '222131': {'lat': '25.9111', 'name': 'Khapraha],Jaunpur,Uttar Pradesh', 'lon': '82.0981'},
      '530008': {'lat': '17.7336', 'name': 'IRSD Area,Visakhapatnam,Andhra Pradesh', 'lon': '83.2717'},
      '530009': {'lat': '17.7133', 'name': 'Marripalem Vuda Colony,Visakhapatnam,Andhra Pradesh', 'lon': '83.2808'},
      '477001': {'lat': '26.5019', 'name': 'Goara,Bhind,Madhya Pradesh', 'lon': '78.6243'},
      '222136': {'lat': '25.5638', 'name': 'Dharaon,Jaunpur,Uttar Pradesh', 'lon': '82.7455'},
      '222137': {'lat': '25.5654', 'name': 'Tejgarh,Jaunpur,Uttar Pradesh', 'lon': '82.57'},
      '530002': {'lat': '17.7106', 'name': 'KGH,Visakhapatnam,Andhra Pradesh', 'lon': '83.3126'},
      '530003': {'lat': '17.7133', 'name': 'Ghandhi Place,Visakhapatnam,Andhra Pradesh', 'lon': '83.2808'},
      '175106': {'lat': '31.6987', 'name': 'Ghaniar,Mandi,Himachal Pradesh', 'lon': '76.9631'},
      '530001': {'lat': '17.7133', 'name': 'Fortward,Visakhapatnam,Andhra Pradesh', 'lon': '83.2808'},
      '530007': {'lat': '17.7467', 'name': 'Industrial Estate (Visakhapatnam),Visakhapatnam,Andhra Pradesh', 'lon': '83.2621'},
      '175102': {'lat': '31.3318', 'name': 'Doghari,Kullu,Himachal Pradesh', 'lon': '77.5279'},
      '530005': {'lat': '17.6587', 'name': 'Gandhigram (Visakhapatnam),Visakhapatnam,Andhra Pradesh', 'lon': '83.2742'},
      '847410': {'lat': '26.2642', 'name': 'Kachhubi,Madhubani,Bihar', 'lon': '86.35'},
      '624005': {'lat': '9.9374', 'name': 'Silaipadi,Dindigul,Tamil Nadu', 'lon': '78.0879'},
      '221308': {'lat': '25.3315', 'name': 'Rohi,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.97405'},
      '533292': {'lat': '17.3749', 'name': 'Nandarada,East Godavari,Andhra Pradesh', 'lon': '81.9018'},
      '533293': {'lat': '17.064', 'name': 'Munikodali,East Godavari,Andhra Pradesh', 'lon': '81.6996'},
      '533290': {'lat': '16.7615', 'name': 'Itikayalapalli,East Godavari,Andhra Pradesh', 'lon': '80.3207'},
      '533291': {'lat': '17.1087', 'name': 'Subhadrampeta,East Godavari,Andhra Pradesh', 'lon': '82.0214'},
      '533296': {'lat': '17.316', 'name': 'Srikrishnapatnam,East Godavari,Andhra Pradesh', 'lon': '82.5754'},
      '533297': {'lat': '17.3931', 'name': 'N.T.Rajapuram,East Godavari,Andhra Pradesh', 'lon': '82.2271'},
      '533294': {'lat': '17.5302', 'name': 'Pallakadiyam,East Godavari,Andhra Pradesh', 'lon': '82.3576'},
      '533295': {'lat': '17.6329', 'name': 'Chatlawada,East Godavari,Andhra Pradesh', 'lon': '81.6962'},
      '506002': {'lat': '17.9755', 'name': 'Railway Gate,Warangal,Telangana', 'lon': '79.6017'},
      '835228': {'lat': '22.8083', 'name': 'Sikariatand,Simdega,Jharkhand', 'lon': '84.4764'},
      '400079': {'lat': '19.1149', 'name': 'Psm Colony,Mumbai,Maharashtra', 'lon': '72.9267'},
      '847411': {'lat': '26.3534', 'name': 'Barsam,Madhubani,Bihar', 'lon': '86.3071'},
      '632103': {'lat': '12.9165287', 'name': 'Nemandhapura,Vellore,Tamil Nadu', 'lon': '79.1324866'},
      '822124': {'lat': '24.1917', 'name': 'Rajhara Rest,Garhwa,Jharkhand', 'lon': '83.9117'},
      '332709': {'lat': '27.1447', 'name': 'Dayara,Sikar,Rajasthan', 'lon': '75.8519'},
      '332708': {'lat': '27.0763', 'name': 'Bhadwari,Sikar,Rajasthan', 'lon': '75.7311'},
      '493887': {'lat': '20.633086', 'name': 'Kopra,Gariaband,Chattisgarh', 'lon': '82.062304'},
      '332705': {'lat': '26.455', 'name': 'Ladikabas,Sikar,Rajasthan', 'lon': '76.0059'},
      '332707': {'lat': '26.2859', 'name': 'Hathideh,Sikar,Rajasthan', 'lon': '75.6084'},
      '332706': {'lat': '27.6908', 'name': 'Chowkri,Sikar,Rajasthan', 'lon': '75.6361'},
      '332701': {'lat': '27.0186', 'name': 'Pithalpur,Sikar,Rajasthan', 'lon': '75.5153'},
      '332703': {'lat': '26.9606', 'name': 'Danta Ramgarh,Sikar,Rajasthan', 'lon': '75.7216'},
      '332702': {'lat': '27.2964', 'name': 'Mundiawas,Sikar,Rajasthan', 'lon': '75.1867'},
      '495448': {'lat': '22.6036', 'name': 'Gursia,Korba,Chattisgarh', 'lon': '82.4976'},
      '495449': {'lat': '22.3847', 'name': 'Ranjana,Korba,Chattisgarh', 'lon': '82.253'},
      '487661': {'lat': '22.916707', 'name': 'Pipriya Kala,Sainkheda,Madhya Pradesh', 'lon': '78.783293'},
      '813208': {'lat': '25.0577', 'name': 'Mirzachowki,Sahibganj,Jharkhand', 'lon': '87.3923'},
      '243641': {'lat': '27.7497', 'name': 'Katra Sadatganj,Budaun,Uttar Pradesh', 'lon': '79.2601'},
      '841316': {'lat': '25.7961', 'name': 'Magaidih,Saran,Bihar', 'lon': '84.8348'},
      '495444': {'lat': '22.311', 'name': 'Dhelwadih,Korba,Chattisgarh', 'lon': '82.3835'},
      '629179': {'lat': '8.3403', 'name': 'Unnamalakada,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '272177': {'lat': '26.3961', 'name': 'Naguwa,Basti,Uttar Pradesh', 'lon': '83.0269'},
      '495447': {'lat': '21.0783', 'name': 'Banki Mongra,Korba,Chattisgarh', 'lon': '82.114'},
      '600040': {'lat': '12.8819', 'name': 'Anna Nagar (Chennai),Chennai,Tamil Nadu', 'lon': '80.0885'},
      '600041': {'lat': '12.9535', 'name': 'Palavakkam (Kanchipuram),Kanchipuram,Tamil Nadu', 'lon': '80.2572'},
      '600042': {'lat': '13.0647', 'name': 'Velacheri,Chennai,Tamil Nadu', 'lon': '80.2523'},
      '515651': {'lat': '14.3033', 'name': 'Veldurthi,Ananthapur,Andhra Pradesh', 'lon': '77.7512'},
      '600044': {'lat': '12.8072', 'name': 'Nemilichery,Kanchipuram,Tamil Nadu', 'lon': '80.0591'},
      '600045': {'lat': '12.8819', 'name': 'Tambaram West,Kanchipuram,Tamil Nadu', 'lon': '80.0885'},
      '600046': {'lat': '12.8819', 'name': 'Tambaram IAF,Kanchipuram,Tamil Nadu', 'lon': '80.0885'},
      '600047': {'lat': '12.8819', 'name': 'Tambaram Sanatorium,Kanchipuram,Tamil Nadu', 'lon': '80.0885'},
      '600048': {'lat': '12.8884', 'name': 'Mannivakkam,Kanchipuram,Tamil Nadu', 'lon': '80.0641'},
      '600049': {'lat': '13.1228', 'name': 'Srinivasanagar,Chennai,Tamil Nadu', 'lon': '80.1703'},
      '517126': {'lat': '13.5079', 'name': 'Thurupupalle,Chittoor,Andhra Pradesh', 'lon': '78.8422'},
      '517127': {'lat': '13.1959', 'name': 'Kattmanchi,Chittoor,Andhra Pradesh', 'lon': '78.9332'},
      '517124': {'lat': '13.448', 'name': 'Chinnakalikiri,Chittoor,Andhra Pradesh', 'lon': '78.9484'},
      '517125': {'lat': '13.2367', 'name': 'Ramanaidupalle,Chittoor,Andhra Pradesh', 'lon': '79.21'},
      '517123': {'lat': '13.5308', 'name': 'Gangivaripalle,Chittoor,Andhra Pradesh', 'lon': '78.702'},
      '413228': {'lat': '17.5503', 'name': 'Achegaon,Solapur,Maharashtra', 'lon': '76.0653'},
      '413229': {'lat': '19.2612', 'name': 'Dongarkinhi,Beed,Maharashtra', 'lon': '76.3729'},
      '413226': {'lat': '17.6754', 'name': 'Pitapur,Solapur,Maharashtra', 'lon': '76.1574'},
      '695146': {'lat': '8.739', 'name': 'Palayamkunnu,Thiruvananthapuram,Kerala', 'lon': '76.7413'},
      '695145': {'lat': '8.7525', 'name': 'Sreenivasapuram,Thiruvananthapuram,Kerala', 'lon': '76.7604'},
      '695144': {'lat': '8.739', 'name': 'Moongode,Thiruvananthapuram,Kerala', 'lon': '76.7413'},
      '695143': {'lat': '8.7314', 'name': 'Thokkad,Thiruvananthapuram,Kerala', 'lon': '76.7467'},
      '695142': {'lat': '8.739', 'name': 'Cherunniyur,Thiruvananthapuram,Kerala', 'lon': '76.7413'},
      '695141': {'lat': '8.7333', 'name': 'Chilakur,Thiruvananthapuram,Kerala', 'lon': '76.7167'},
      '517129': {'lat': '14.1932', 'name': 'Pallecheruvu,Chittoor,Andhra Pradesh', 'lon': '78.9771'},
      '495224': {'lat': '21.9273', 'name': 'Badrathakur,Bilaspur(CGH),Chattisgarh', 'lon': '82.0327'},
      '502316': {'lat': '17.4238', 'name': 'Khajipet,Medak,Telangana', 'lon': '78.7657'},
      '285204': {'lat': '26.1824', 'name': 'Mainupur,Jalaun,Uttar Pradesh', 'lon': '79.732'},
      '630321': {'lat': '10.0939', 'name': 'Vethiyur,Sivaganga,Tamil Nadu', 'lon': '78.6439'},
      '691309': {'lat': '8.9403', 'name': 'Venture,Kollam,Kerala', 'lon': '76.9187'},
      '691308': {'lat': '8.95', 'name': 'Ottackal,Kollam,Kerala', 'lon': '77.0667'},
      '495222': {'lat': '21.9731', 'name': 'Hirri Mines,Bilaspur(CGH),Chattisgarh', 'lon': '82.05'},
      '502310': {'lat': '17.6066', 'name': 'Vittalapur,Medak,Telangana', 'lon': '79.3977'},
      '691305': {'lat': '8.9403', 'name': 'Punalur H.O,Kollam,Kerala', 'lon': '76.9187'},
      '691304': {'lat': '8.9403', 'name': 'Pullichira,Kollam,Kerala', 'lon': '76.9187'},
      '691307': {'lat': '8.993', 'name': 'Anapettakongal,Kollam,Kerala', 'lon': '76.9859'},
      '691306': {'lat': '8.9281', 'name': 'Vadamon,Kollam,Kerala', 'lon': '76.9098'},
      '691301': {'lat': '8.9403', 'name': 'Paravur (Kollam),Kollam,Kerala', 'lon': '76.9187'},
      '691303': {'lat': '8.8373', 'name': 'Kakkotumoola,Kollam,Kerala', 'lon': '76.6451'},
      '691302': {'lat': '8.9403', 'name': 'Puthenkulam,Kollam,Kerala', 'lon': '76.9187'},
      '516380': {'lat': '14.8466', 'name': 'K Kothapalle,Cuddapah,Andhra Pradesh', 'lon': '78.6783'},
      '506356': {'lat': '18.0386', 'name': 'Pangidipalli,Warangal,Telangana', 'lon': '79.4732'},
      '388205': {'lat': '22.5503', 'name': 'Dagjipura,Anand,Gujarat', 'lon': '72.7736'},
      '506355': {'lat': '17.4061', 'name': 'Komaravalli,Warangal,Telangana', 'lon': '78.3108'},
      '610103': {'lat': '10.7261', 'name': 'Kilamanali,Tiruvarur,Tamil Nadu', 'lon': '79.6323'},
      '523367': {'lat': '15.2466', 'name': 'Sanjeevaraopeta,Prakasam,Andhra Pradesh', 'lon': '78.6519'},
      '577133': {'lat': '13.2598', 'name': 'Kuduvalli,Chikkamagaluru,Karnataka', 'lon': '75.6892'},
      '441404': {'lat': '21.2479', 'name': 'Kamthi Colliery,Nagpur,Maharashtra', 'lon': '79.2265'},
      '577132': {'lat': '13.1439', 'name': 'Jogannakere,Chikkamagaluru,Karnataka', 'lon': '75.9407'},
      '639136': {'lat': '11.0258', 'name': 'Punnamchatram,Karur,Tamil Nadu', 'lon': '77.991'},
      '523369': {'lat': '15.306', 'name': 'Rajupalem (Prakasam),Prakasam,Andhra Pradesh', 'lon': '78.9634'},
      '523368': {'lat': '15.4567', 'name': 'Anumalaveedu,Prakasam,Andhra Pradesh', 'lon': '78.99'},
      '365565': {'lat': '20.9852', 'name': 'Bhriangda,Amreli,Gujarat', 'lon': '71.449'},
      '507117': {'lat': '18.1395', 'name': 'Duginepalli,Khammam,Telangana', 'lon': '80.2009'},
      '360510': {'lat': '22.2941', 'name': 'Bhomiyavadar,Porbandar,Gujarat', 'lon': '71.6114'},
      '635808': {'lat': '12.628', 'name': 'Kottur,Vellore,Tamil Nadu', 'lon': '78.6966'},
      '365560': {'lat': '20.9852', 'name': 'Babriyadhar,Amreli,Gujarat', 'lon': '71.449'},
      '360515': {'lat': '22.6925', 'name': 'Motakalavad,Jamnagar,Gujarat', 'lon': '71.827'},
      '635804': {'lat': '12.9794', 'name': 'Thottalam,Vellore,Tamil Nadu', 'lon': '79.2337'},
      '635805': {'lat': '12.8661', 'name': 'Melvayathinankuppam,Vellore,Tamil Nadu', 'lon': '78.7795'},
      '635806': {'lat': '12.9221', 'name': 'Rajakuppam,Vellore,Tamil Nadu', 'lon': '78.8231'},
      '635807': {'lat': '12.7362', 'name': 'Minnur,Vellore,Tamil Nadu', 'lon': '78.6739'},
      '635801': {'lat': '12.6124', 'name': 'Kodayanchi,Vellore,Tamil Nadu', 'lon': '78.7389'},
      '635802': {'lat': '12.3762', 'name': 'Ambur Bazaar,Vellore,Tamil Nadu', 'lon': '78.9251'},
      '635803': {'lat': '12.9273', 'name': 'Kosavanpudur,Vellore,Tamil Nadu', 'lon': '78.9214'},
      '251316': {'lat': '29.5456', 'name': 'Khokani,Muzaffarnagar,Uttar Pradesh', 'lon': '77.7583'},
      '604203': {'lat': '12.2969', 'name': 'Arugavur,Villupuram,Tamil Nadu', 'lon': '79.5118'},
      '501144': {'lat': '17.3166', 'name': 'Agnoor,K.V.Rangareddy,Telangana', 'lon': '77.935'},
      '370165': {'lat': '23.7979', 'name': 'Mauvana,Kachchh,Gujarat', 'lon': '70.6438'},
      '577228': {'lat': '13.733', 'name': 'Amruthapura,Dakshina Kannada,Karnataka', 'lon': '75.8293'},
      '493111': {'lat': '21.4215', 'name': 'Barbanda,Raipur,Chattisgarh', 'lon': '81.8387'},
      '370160': {'lat': '23.5399', 'name': 'Padampar,Kachchh,Gujarat', 'lon': '70.873'},
      '577223': {'lat': '14.0607', 'name': 'Jeenahalli,Davangere,Karnataka', 'lon': '75.6748'},
      '577222': {'lat': '13.8738', 'name': 'Malavagoppa,Shivamogga,Karnataka', 'lon': '75.6142'},
      '577221': {'lat': '13.9937', 'name': 'Guddada Kommaranahalli,Davangere,Karnataka', 'lon': '75.8472'},
      '577220': {'lat': '14.0241', 'name': 'Singanabidre,Shivamogga,Karnataka', 'lon': '75.2082'},
      '577227': {'lat': '13.9579', 'name': 'B.Beeranahalli,Shivamogga,Karnataka', 'lon': '75.6637'},
      '577226': {'lat': '13.826', 'name': 'Guthi Yedehalli,Shivamogga,Karnataka', 'lon': '75.1624'},
      '577225': {'lat': '13.9135', 'name': 'Kommanahalu,Shivamogga,Karnataka', 'lon': '75.681'},
      '577224': {'lat': '14.0875', 'name': 'Kyasinakere,Davangere,Karnataka', 'lon': '75.8015'},
      '394730': {'lat': '20.8152', 'name': 'Waghai,Navsari,Gujarat', 'lon': '73.0897'},
      '825325': {'lat': '23.823', 'name': 'Kedla,Ramgarh,Jharkhand', 'lon': '85.0091'},
      '229412': {'lat': '25.481', 'name': 'Balraj Nagar,Allahabad,Uttar Pradesh', 'lon': '81.8515'},
      '251310': {'lat': '29.5696', 'name': 'Tuglakpur,Muzaffarnagar,Uttar Pradesh', 'lon': '77.847'},
      '229410': {'lat': '25.8726', 'name': 'Kaithola,Pratapgarh,Uttar Pradesh', 'lon': '81.8315'},
      '229411': {'lat': '25.2989', 'name': 'Mendara,Allahabad,Uttar Pradesh', 'lon': '81.9717'},
      '713209': {'lat': '24.2028', 'name': 'Durgapur Re College,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '251311': {'lat': '29.146', 'name': 'Kutesra,Muzaffarnagar,Uttar Pradesh', 'lon': '77.6596'},
      '273213': {'lat': '26.2698', 'name': 'Patakhuli,Gorakhpur,Uttar Pradesh', 'lon': '83.3225'},
      '273212': {'lat': '26.4138', 'name': 'Jhudiya,Gorakhpur,Uttar Pradesh', 'lon': '83.3992'},
      '273211': {'lat': '26.5778', 'name': 'Barayapar,Gorakhpur,Uttar Pradesh', 'lon': '83.2555'},
      '629174': {'lat': '8.3403', 'name': 'Keralapuram,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '243505': {'lat': '28.6286', 'name': 'Nagaria Sobarni,Bareilly,Uttar Pradesh', 'lon': '79.3098'},
      '673103': {'lat': '11.6103', 'name': 'Vadakara Beach,Kozhikode,Kerala', 'lon': '75.7806'},
      '673102': {'lat': '11.6103', 'name': 'Rayarangoth,Kozhikode,Kerala', 'lon': '75.7806'},
      '673101': {'lat': '11.6103', 'name': 'Vadakara H.O,Kozhikode,Kerala', 'lon': '75.7806'},
      '131001': {'lat': '28.9871', 'name': 'Delhi Road,Sonipat,Haryana', 'lon': '76.9937'},
      '673106': {'lat': '11.6166', 'name': 'Muttungal West,Kozhikode,Kerala', 'lon': '75.5867'},
      '673105': {'lat': '11.6103', 'name': 'Pathiyarakkara,Kozhikode,Kerala', 'lon': '75.7806'},
      '673104': {'lat': '11.625', 'name': 'Kurikkilad,Kozhikode,Kerala', 'lon': '75.611'},
      '851206': {'lat': '25.565', 'name': 'Mohraghat Prass,Khagaria,Bihar', 'lon': '86.4007'},
      '804407': {'lat': '25.0717', 'name': 'Bauri Beldari,Jehanabad,Bihar', 'lon': '85.1408'},
      '851204': {'lat': '25.4507', 'name': 'Chandpur Khurd,Khagaria,Bihar', 'lon': '86.2756'},
      '851205': {'lat': '25.6947', 'name': 'Gaura Shakti Tola,Khagaria,Bihar', 'lon': '86.7048'},
      '851202': {'lat': '25.4373', 'name': 'Borna,Khagaria,Bihar', 'lon': '86.6172'},
      '851203': {'lat': '25.4186', 'name': 'Garmohini,Khagaria,Bihar', 'lon': '86.6522'},
      '713147': {'lat': '23.5307', 'name': 'Jhilu,Purba Bardhaman,West Bengal', 'lon': '87.94'},
      '501142': {'lat': '17.5172', 'name': 'Adikcherla,K.V.Rangareddy,Telangana', 'lon': '78.3064'},
      '804408': {'lat': '25.0931', 'name': 'Jehanabad H.O,Jehanabad,Bihar', 'lon': '84.9963'},
      '243501': {'lat': '28.4437', 'name': 'Meerapur Rafiabad,Bareilly,Uttar Pradesh', 'lon': '79.4523'},
      '783301': {'lat': '26.3513', 'name': 'Dhubri Bazar,Dhubri,Assam', 'lon': '90.3634'},
      '629177': {'lat': '8.3247', 'name': 'Aruvikkarai,Kanyakumari,Tamil Nadu', 'lon': '77.2726'},
      '833202': {'lat': '22.4675', 'name': 'Singhpokharia,West Singhbhum,Jharkhand', 'lon': '85.824'},
      '243502': {'lat': '28.3987', 'name': 'Teolia,Bareilly,Uttar Pradesh', 'lon': '79.1488'},
      '342028': {'lat': '26.3837', 'name': 'Khirja Khas,Jodhpur,Rajasthan', 'lon': '72.3389'},
      '231210': {'lat': '24.9347', 'name': 'Sabha Lilwahi,Sonbhadra,Uttar Pradesh', 'lon': '82.6754'},
      '504220': {'lat': '18.9729', 'name': 'Madaram Township,Adilabad,Telangana', 'lon': '79.1431'},
      '243503': {'lat': '28.1498', 'name': 'Rampura Ratan,Bareilly,Uttar Pradesh', 'lon': '79.5164'},
      '151111': {'lat': '30.2974', 'name': 'Lehra Dhur Kot,Bathinda,Punjab', 'lon': '75.176'},
      '607303': {'lat': '11.609', 'name': 'Serakuppam,Cuddalore,Tamil Nadu', 'lon': '79.2645'},
      '245205': {'lat': '28.7276', 'name': 'Brijghat,Hapur,Uttar Pradesh', 'lon': '78.1723'},
      '245207': {'lat': '28.8768', 'name': 'Rajethi,Ghaziabad,Uttar Pradesh', 'lon': '78.006'},
      '245206': {'lat': '28.8382', 'name': 'Ajrara,Meerut,Uttar Pradesh', 'lon': '77.8191'},
      '245201': {'lat': '28.7123', 'name': 'Upera,Ghaziabad,Uttar Pradesh', 'lon': '77.9189'},
      '713141': {'lat': '23.7218', 'name': 'Khana Junction,Purba Bardhaman,West Bengal', 'lon': '87.8313'},
      '245208': {'lat': '28.6707', 'name': 'Nek Nanpur Nanai,Ghaziabad,Uttar Pradesh', 'lon': '78.1339'},
      '799015': {'lat': '23.7062', 'name': 'Tebaria,South Tripura,Tripura', 'lon': '91.4203'},
      '250401': {'lat': '29.0357', 'name': 'Pilona,Meerut,Uttar Pradesh', 'lon': '77.7939'},
      '689673': {'lat': '9.265', 'name': 'Cherukulanji,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '752069': {'lat': '20.128', 'name': 'Nayagarh College,Nayagarh,Odisha', 'lon': '85.1158'},
      '782435': {'lat': '26.0028', 'name': 'Menmeji,Karbi Anglon,Assam', 'lon': '92.856'},
      '344001': {'lat': '25.8322', 'name': 'Barmer RS,Barmer,Rajasthan', 'lon': '72.8272'},
      '250406': {'lat': '28.8001', 'name': 'Agwanpur,Meerut,Uttar Pradesh', 'lon': '78.2096'},
      '383330': {'lat': '23.1193', 'name': 'Madhavgadh,Sabarkantha,Gujarat', 'lon': '73.247'},
      '782439': {'lat': '26.0028', 'name': 'Barhawar,Nagaon,Assam', 'lon': '92.856'},
      '383335': {'lat': '23.2846', 'name': 'Vankaneda,Sabarkantha,Gujarat', 'lon': '73.3843'},
      '506224': {'lat': '17.738', 'name': 'Salakpur,Warangal,Telangana', 'lon': '79.271'},
      '522414': {'lat': '16.5743', 'name': 'Srinagar,Guntur,Andhra Pradesh', 'lon': '79.7927'},
      '522415': {'lat': '16.568', 'name': 'Jangamaheswarapuram,Guntur,Andhra Pradesh', 'lon': '79.6369'},
      '522412': {'lat': '16.4798', 'name': 'Nagavaram,Guntur,Andhra Pradesh', 'lon': '80.057'},
      '522413': {'lat': '16.4715', 'name': 'Kubadpuram,Guntur,Andhra Pradesh', 'lon': '79.8757'},
      '522410': {'lat': '16.5296', 'name': 'Andukuru,Guntur,Andhra Pradesh', 'lon': '80.1727'},
      '522411': {'lat': '16.5667', 'name': 'Emmajigudem,Guntur,Andhra Pradesh', 'lon': '79.9914'},
      '321642': {'lat': '25.4862', 'name': 'Naom,Bharatpur,Rajasthan', 'lon': '73.4888'},
      '403410': {'lat': '15.3349', 'name': 'Sigao,South Goa,Goa', 'lon': '74.2066'},
      '521207': {'lat': '16.8021', 'name': 'Korlagunta,Krishna,Andhra Pradesh', 'lon': '80.8929'},
      '521202': {'lat': '16.8102', 'name': 'Hanumanthunigudem,Krishna,Andhra Pradesh', 'lon': '80.8264'},
      '401607': {'lat': '19.909', 'name': 'Rankol,Thane,Maharashtra', 'lon': '72.9531'},
      '521201': {'lat': '16.8151', 'name': 'Dum Dum Gardens,Krishna,Andhra Pradesh', 'lon': '80.9308'},
      '752060': {'lat': '20.1177', 'name': 'Garhsanput,Khorda,Odisha', 'lon': '85.6125'},
      '403503': {'lat': '15.6485', 'name': 'Advalpal,North Goa,Goa', 'lon': '73.916'},
      '177025': {'lat': '31.6429545', 'name': 'Dho,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427122'},
      '177024': {'lat': '31.6429565', 'name': 'Chanruhi,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427102'},
      '177027': {'lat': '31.7241474', 'name': 'Kot (Hamirpur(HP)),Hamirpur(HP),Himachal Pradesh', 'lon': '76.5514774'},
      '177026': {'lat': '31.6039864', 'name': 'Galore,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5054665'},
      '177021': {'lat': '31.7115', 'name': 'Bagwara,Hamirpur(HP),Himachal Pradesh', 'lon':'76.6623'},
      '177020': {'lat': '31.6861795', 'name': 'Khiah,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5213042'},
      '177023': {'lat': '31.6861815', 'name': 'Gawardu,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5213022'},
      '177022': {'lat': '31.7895348', 'name': 'Utpur,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6079813'},
      '752066': {'lat': '20.2485', 'name': 'Gopalipada,Khorda,Odisha', 'lon': '85.1437'},
      '629170': {'lat': '8.3403', 'name': 'Palukal,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '177029': {'lat': '31.68595', 'name': 'Bharthian,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5865'},
      '177028': {'lat': '31.82985', 'name': 'Bauru,Hamirpur(HP),Himachal Pradesh', 'lon': '76.60228'},
      '401606': {'lat': '19.5949', 'name': 'Modgaon,Thane,Maharashtra', 'lon': '73.3603'},
      '423605': {'lat': '19.8176', 'name': 'Jawalke,Ahmed Nagar,Maharashtra', 'lon': '74.3963'},
      '734010': {'lat': '26.723', 'name': 'Nemai,Darjiling,West Bengal', 'lon': '88.3806'},
      '414304': {'lat': '19.5808', 'name': 'Vasunde,Ahmed Nagar,Maharashtra', 'lon': '74.4827'},
      '670741': {'lat': '11.8049', 'name': 'Pathiriyad,Kannur,Kerala', 'lon': '75.481'},
      '414306': {'lat': '18.96', 'name': 'Vadner BK,Ahmed Nagar,Maharashtra', 'lon': '74.2813'},
      '752065': {'lat': '20.1528', 'name': 'Rajsunakhala,Nayagarh,Odisha', 'lon': '85.1174'},
      '457770': {'lat': '23.3068', 'name': 'Mathmath,Jhabua,Madhya Pradesh', 'lon': '75.1262'},
      '414301': {'lat': '20.0761', 'name': 'Hanga,Ahmed Nagar,Maharashtra', 'lon': '75.5161'},
      '414302': {'lat': '19.2302', 'name': 'Vadzire,Ahmed Nagar,Maharashtra', 'lon': '74.6006'},
      '414303': {'lat': '19.792', 'name': 'Pimpalgaon Rotha,Ahmed Nagar,Maharashtra', 'lon': '74.8269'},
      '572219': {'lat': '13.0272', 'name': 'Belavatha,Tumakuru,Karnataka', 'lon': '76.9117'},
      '572218': {'lat': '13.3394', 'name': 'Yelanadu,Tumakuru,Karnataka', 'lon': '76.7422'},
      '306502': {'lat': '25.4513', 'name': 'Magartalab,Pali,Rajasthan', 'lon': '73.6095'},
      '628008': {'lat': '8.5782', 'name': 'Levingipuram,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '271001': {'lat': '27.1328', 'name': 'Civil Line (Gonda),Gonda,Uttar Pradesh', 'lon': '81.9744'},
      '572211': {'lat': '13.507', 'name': 'Ammasandra,Tumakuru,Karnataka', 'lon': '75.9823'},
      '628007': {'lat': '8.5782', 'name': 'Heavy Water Project Colony,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '572213': {'lat': '13.6339', 'name': 'Idagur,Tumakuru,Karnataka', 'lon': '76.1114'},
      '572212': {'lat': '13.0869', 'name': 'Aralaguppe,Tumakuru,Karnataka', 'lon': '76.4997'},
      '572215': {'lat': '13.1308', 'name': 'Dunda,Tumakuru,Karnataka', 'lon': '76.5946'},
      '572214': {'lat': '13.2706', 'name': 'Honnebagi,Tumakuru,Karnataka', 'lon': '76.5577'},
      '572217': {'lat': '13.307', 'name': 'Gudigondanahalli,Tumakuru,Karnataka', 'lon': '76.4173'},
      '572216': {'lat': '13.2801', 'name': 'Gubbi Hosahalli,Tumakuru,Karnataka', 'lon': '76.6882'},
      '485778': {'lat': '24.3458', 'name': 'Kathaha,Satna,Madhya Pradesh', 'lon': '81.1037'},
      '271003': {'lat': '27.1255', 'name': 'Jhauhana,Gonda,Uttar Pradesh', 'lon': '81.9733'},
      '796009': {'lat': '23.498', 'name': 'Govt. Complex,Aizawl,Mizoram', 'lon': '92.7292'},
      '621301': {'lat': '10.7696', 'name': 'Kilasakkarakottai,Karur,Tamil Nadu', 'lon': '78.2139'},
      '506103': {'lat': '17.0452', 'name': 'Apparajpalli,Mahabubabad,Telangana', 'lon': '78.9605'},
      '494115': {'lat': '18.6838', 'name': 'Kikirpal,Dantewada,Chattisgarh', 'lon': '81.7386'},
      '496336': {'lat': '22.4825', 'name': 'Dobh,Jashpur,Chattisgarh', 'lon': '83.5795'},
      '233311': {'lat': '25.5943', 'name': 'Parsotia,Ghazipur,Uttar Pradesh', 'lon': '83.3098'},
      '494114': {'lat': '18.0354', 'name': 'Errabore,Dantewada,Chattisgarh', 'lon': '81.2517'},
      '678731': {'lat': '10.7982', 'name': 'Palakkad Industrial Estate,Palakkad,Kerala', 'lon': '76.6907'},
      '678732': {'lat': '10.7982', 'name': 'Kottekkad,Palakkad,Kerala', 'lon': '76.6907'},
      '504203': {'lat': '19.0957', 'name': 'Patha Yelcapur,Adilabad,Telangana', 'lon': '78.7302'},
      '742237': {'lat': '24.2214', 'name': 'Manigram,Murshidabad,West Bengal', 'lon': '87.7501'},
      '486775': {'lat': '24.2425', 'name': 'Kudia,Sidhi,Madhya Pradesh', 'lon': '81.5438'},
      '244001': {'lat': '28.823', 'name': 'Bhaisian,Moradabad,Uttar Pradesh', 'lon': '78.751'},
      '507166': {'lat': '17.8092', 'name': 'P.N.Puram,Khammam,Telangana', 'lon': '79.656'},
      '643243': {'lat': '11.3776', 'name': 'Yellanhalli,Nilgiris,Tamil Nadu', 'lon': '76.7446'},
      '641201': {'lat': '10.9066', 'name': 'Panapatti,Coimbatore,Tamil Nadu', 'lon': '77.0558'},
      '643242': {'lat': '11.3598', 'name': 'Kunjapanai,Nilgiris,Tamil Nadu', 'lon': '76.9337'},
      '643213': {'lat': '11.4146', 'name': 'Koderi,Nilgiris,Tamil Nadu', 'lon': '76.8562'},
      '823005': {'lat': '25.0146', 'name': 'Asc Centre(N) Gaya,Gaya,Bihar', 'lon': '85.1076'},
      '493118': {'lat': '21.8305', 'name': 'Singarpur,Raipur,Chattisgarh', 'lon': '82.003'},
      '251201': {'lat': '29.1413', 'name': 'Titoda,Muzaffarnagar,Uttar Pradesh', 'lon': '77.7826'},
      '251202': {'lat': '29.562', 'name': 'Maleera,Muzaffarnagar,Uttar Pradesh', 'lon': '77.6689'},
      '823004': {'lat': '24.7399', 'name': 'Pandey Parasawan,Gaya,Bihar', 'lon': '84.9115'},
      '230501': {'lat': '25.9866', 'name': 'Dubeypur,Pratapgarh,Uttar Pradesh', 'lon': '81.8433'},
      '140308': {'lat': '30.6887', 'name': 'Sohana,Mohali,Punjab', 'lon': '76.7066'},
      '643240': {'lat': '11.5659', 'name': 'Ambalamoola,Nilgiris,Tamil Nadu', 'lon': '76.3708'},
      '500058': {'lat': '17.3724', 'name': 'Kanchanbagh,Hyderabad,Telangana', 'lon': '78.437'},
      '274508': {'lat': '26.1666', 'name': 'Ajana,Deoria,Uttar Pradesh', 'lon': '83.9473'},
      '271302': {'lat': '26.9333', 'name': 'Bhorha,Gonda,Uttar Pradesh', 'lon': '82.17'},
      '503246': {'lat': '18.8627', 'name': 'Tungini,Nizamabad,Telangana', 'lon': '78.3427'},
      '140301': {'lat': '30.769', 'name': 'Radiala,Mohali,Punjab', 'lon': '76.6697'},
      '140306': {'lat': '30.6444', 'name': 'Manauli,Mohali,Punjab', 'lon': '76.7217'},
      '140307': {'lat': '30.6852', 'name': 'Jhanjeri,Mohali,Punjab', 'lon': '76.6175'},
      '460449': {'lat': '22.0403', 'name': 'Shabhapur Colloery,Betul,Madhya Pradesh', 'lon': '77.9443'},
      '824303': {'lat': '24.8427', 'name': 'Rajwariya Kala,Aurangabad(BH),Bihar', 'lon': '84.2541'},
      '824302': {'lat': '24.5627', 'name': 'Sartu,Aurangabad(BH),Bihar', 'lon': '84.5953'},
      '518594': {'lat': '15.2426', 'name': 'Peddakambalur,Kurnool,Andhra Pradesh', 'lon': '78.6043'},
      '805236': {'lat': '24.9326', 'name': 'Jethian,Gaya,Bihar', 'lon': '85.3209'},
      '518593': {'lat': '15.3998', 'name': 'Maddur,Kurnool,Andhra Pradesh', 'lon': '78.4079'},
      '246429': {'lat': '30.2547', 'name': 'Ladoli,Rudraprayag,Uttarakhand', 'lon': '79.1812'},
      '274149': {'lat': '26.9308', 'name': 'Mujdiha,Kushinagar,Uttar Pradesh', 'lon': '83.4784'},
      '742235': {'lat': '24.2744', 'name': 'Jarur,Murshidabad,West Bengal', 'lon': '87.8508'},
      '503245': {'lat': '18.7547', 'name': 'Nagepur,Nizamabad,Telangana', 'lon': '78.0183'},
      '518598': {'lat': '15.4147', 'name': 'Gutupalli,Kurnool,Andhra Pradesh', 'lon': '77.916'},
      '518599': {'lat': '15.4996', 'name': 'Musalayacheruvu,Kurnool,Andhra Pradesh', 'lon': '78.0972'},
      '342802': {'lat': '25.9862', 'name': 'Guda Vishnoiyan,Jodhpur,Rajasthan', 'lon': '72.9843'},
      '209601': {'lat': '27.3686', 'name': 'Fatehgarh R.S.,Farrukhabad,Uttar Pradesh', 'lon': '79.6519'},
      '828142': {'lat': '24.0568', 'name': 'Ukma,Dhanbad,Jharkhand', 'lon': '86.4539'},
      '210209': {'lat': '25.2687', 'name': 'Baria,Chitrakoot,Uttar Pradesh', 'lon': '81.2636'},
      '503180': {'lat': '17.8206', 'name': 'Shakkarnagar,Nizamabad,Telangana', 'lon': '78.114'},
      '492013': {'lat': '21.2333', 'name': 'Sunder Nagar,Raipur,Chattisgarh', 'lon': '81.6333'},
      '682042': {'lat': '10.1131', 'name': 'Infopark-Kochi,Ernakulam,Kerala', 'lon': '76.2858'},
      '635752': {'lat': '12.6435', 'name': 'Nimmiyambattu,Vellore,Tamil Nadu', 'lon': '78.6504'},
      '682040': {'lat': '10.1131', 'name': 'Nettoor,Ernakulam,Kerala', 'lon': '76.2858'},
      '682041': {'lat': '10.1131', 'name': 'AIMS Ponekkara,Ernakulam,Kerala', 'lon': '76.2858'},
      '321411': {'lat': '26.5942', 'name': 'Khan Kheda,Bharatpur,Rajasthan', 'lon': '76.9275'},
      '321410': {'lat': '26.7407', 'name': 'Singhada,Bharatpur,Rajasthan', 'lon': '77.1055'},
      '415309': {'lat': '17.3811', 'name': 'Bhood,Sangli,Maharashtra', 'lon': '74.6447'},
      '415308': {'lat': '17.3414', 'name': 'Valvan,Sangli,Maharashtra', 'lon': '74.7762'},
      '210207': {'lat': '25.5209', 'name': 'Chillimal,Chitrakoot,Uttar Pradesh', 'lon': '81.0575'},
      '637411': {'lat': '11.4103', 'name': 'Devanurnadu,Namakkal,Tamil Nadu', 'lon': '78.5445'},
      '210205': {'lat': '25.2842', 'name': 'Chandramara,Chitrakoot,Uttar Pradesh', 'lon': '81.2466'},
      '210204': {'lat': '25.3131', 'name': 'Bhaisaundha,Chitrakoot,Uttar Pradesh', 'lon': '80.369'},
      '415303': {'lat': '19.3701', 'name': 'Chinchani Ambak,Sangli,Maharashtra', 'lon': '76.5787'},
      '785703': {'lat': '26.3242', 'name': 'Sologuri Kachari,Golaghat,Assam', 'lon': '94.0871'},
      '415301': {'lat': '18.2081', 'name': 'Kautholi,Sangli,Maharashtra', 'lon': '75.5649'},
      '210208': {'lat': '25.1446', 'name': 'Bargarh,Chitrakoot,Uttar Pradesh', 'lon': '81.4475'},
      '415307': {'lat': '17.2606', 'name': 'Sultangade,Sangli,Maharashtra', 'lon': '74.7157'},
      '415306': {'lat': '17.3308', 'name': 'Hivtad,Sangli,Maharashtra', 'lon': '74.8734'},
      '415305': {'lat': '18.2575', 'name': 'Shelakbav,Sangli,Maharashtra', 'lon': '74.789'},
      '415304': {'lat': '19.1945', 'name': 'Shalgaon,Sangli,Maharashtra', 'lon': '77.2977'},
      '492014': {'lat': '21.1879', 'name': 'Saddu,Raipur,Chattisgarh', 'lon': '81.6898'},
      '458228': {'lat': '23.3933', 'name': 'Kadwasa,Neemuch,Madhya Pradesh', 'lon': '75.9376'},
      '848504': {'lat': '25.6309', 'name': 'Keshonarainpur,Samastipur,Bihar', 'lon': '85.6153'},
      '627604': {'lat': '8.7004', 'name': 'Suttamally Vilakku,Tirunelveli,Tamil Nadu', 'lon': '77.6535'},
      '387540': {'lat': '22.7979', 'name': 'Kanera,Kheda,Gujarat', 'lon': '72.5787'},
      '627601': {'lat': '8.7469', 'name': 'Mukkudal Vadakku,Tirunelveli,Tamil Nadu', 'lon': '77.5274'},
      '387230': {'lat': '23.2676', 'name': 'Tundel,Kheda,Gujarat', 'lon': '72.8698'},
      '627603': {'lat': '8.9144', 'name': 'Vadakku Ariyanayagipuram Petta,Tirunelveli,Tamil Nadu', 'lon': '77.6748'},
      '604153': {'lat': '12.1238', 'name': 'Palapadi,Villupuram,Tamil Nadu', 'lon': '79.3412'},
      '380018': {'lat': '22.9764', 'name': 'Saraspur,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380019': {'lat': '22.9764', 'name': 'D Cabin,Ahmedabad,Gujarat', 'lon': '72.836'},
      '637410': {'lat': '11.435', 'name': 'Morangam,Namakkal,Tamil Nadu', 'lon': '78.0722'},
      '342027': {'lat': '26.1651', 'name': 'Daikara,Jodhpur,Rajasthan', 'lon': '73.2799'},
      '743318': {'lat': '22.9861', 'name': 'Chak Alampur,South 24 Parganas,West Bengal', 'lon': '88.0516'},
      '491332': {'lat': '21.5635', 'name': 'Lenjwara,Durg,Chattisgarh', 'lon': '81.5309'},
      '380013': {'lat': '22.9764', 'name': 'Stadium Marg,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380014': {'lat': '22.9764', 'name': 'Navjivan,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380015': {'lat': '22.9764', 'name': 'I I M,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380016': {'lat': '22.9764', 'name': 'Public Office (Ahmedabad),Ahmedabad,Gujarat', 'lon': '72.836'},
      '560500': {'lat': '13.0082', 'name': 'CPC Income Tax Department,Bengaluru,Karnataka', 'lon': '77.5293'},
      '505162': {'lat': '18.7059', 'name': 'Mulasala,Karim Nagar,Telangana', 'lon': '78.8125'},
      '855114': {'lat': '25.5861', 'name': 'Millik Palagarh,Katihar,Bihar', 'lon': '87.6931'},
      '241304': {'lat': '26.9915', 'name': 'Hans Barauli,Hardoi,Uttar Pradesh', 'lon': '81.0506'},
      '444117': {'lat': '20.7489', 'name': 'Pathardi,Akola,Maharashtra', 'lon': '77.0533'},
      '444110': {'lat': '20.2744', 'name': 'Dhanaj KD,Washim,Maharashtra', 'lon': '77.2345'},
      '444111': {'lat': '20.5333', 'name': 'Jaulkhed,Akola,Maharashtra', 'lon': '77.0885'},
      '176314': {'lat': '32.3358', 'name': 'Bhanota Salga,Chamba,Himachal Pradesh', 'lon': '76.3602'},
      '176315': {'lat': '32.4576', 'name': 'Hadsar,Chamba,Himachal Pradesh', 'lon': '76.564'},
      '176316': {'lat': '32.844', 'name': 'Bairagarh,Chamba,Himachal Pradesh', 'lon': '76.0347'},
      '176317': {'lat': '32.3441', 'name': 'Surangani,Chamba,Himachal Pradesh', 'lon': '76.3487'},
      '176310': {'lat': '32.3441', 'name': 'Chamba H.O,Chamba,Himachal Pradesh', 'lon': '76.3487'},
      '176311': {'lat': '32.1939', 'name': 'Killod,Chamba,Himachal Pradesh', 'lon': '76.3833'},
      '176312': {'lat': '32.0849', 'name': 'Seri Andral,Chamba,Himachal Pradesh', 'lon': '76.5775'},
      '176313': {'lat': '32.4521', 'name': 'Awan,Chamba,Himachal Pradesh', 'lon': '75.9588'},
      '614903': {'lat': '10.4809', 'name': 'Periyakottai,Thanjavur,Tamil Nadu', 'lon': '79.4357'},
      '176318': {'lat': '32.3013', 'name': 'Luddu,Chamba,Himachal Pradesh', 'lon': '76.4579'},
      '176319': {'lat': '32.6969', 'name': 'Pukhri,Chamba,Himachal Pradesh', 'lon': '76.2083'},
      '142049': {'lat': '30.7365', 'name': 'Lande,Moga,Punjab', 'lon': '75.7763'},
      '182301': {'lat': '33.2398', 'name': 'Katra (Udhampur),Udhampur,Jammu & Kashmir', 'lon': '75.0799'},
      '848502': {'lat': '25.5981', 'name': 'Gangeshwar Nagar,Samastipur,Bihar', 'lon': '85.6989'},
      '843330': {'lat': '25.3605', 'name': 'Kohbarawa,Sitamarhi,Bihar', 'lon': '86.693'},
      '363435': {'lat': '23.1615', 'name': 'Gundiyala,Surendra Nagar,Gujarat', 'lon': '73.0224'},
      '473793': {'lat': '25.6446', 'name': 'Gajigarh,Shivpuri,Madhya Pradesh', 'lon': '77.8851'},
      '363430': {'lat': '22.5493', 'name': 'Navaniya,Surendra Nagar,Gujarat', 'lon': '71.4832'},
      '637412': {'lat': '11.4032', 'name': 'Kullamanaickenpatti,Namakkal,Tamil Nadu', 'lon': '78.2088'},
      '271871': {'lat': '27.5757', 'name': 'Kot Bazar,Shrawasti,Uttar Pradesh', 'lon': '81.7208'},
      '272173': {'lat': '26.6665', 'name': 'Girdhar Pur,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.0021'},
      '841460': {'lat': '25.5961', 'name': 'Kaituka Nandan,Saran,Bihar', 'lon': '85.9653'},
      '273403': {'lat': '26.5729', 'name': 'Bhuswal,Gorakhpur,Uttar Pradesh', 'lon': '83.2989'},
      '670594': {'lat': '11.8772', 'name': 'Varam,Kannur,Kerala', 'lon': '75.4097'},
      '334021': {'lat': '28.625', 'name': 'Takhatpura,Bikaner,Rajasthan', 'lon': '73.5079'},
      '481879': {'lat': '23.112', 'name': 'Dullopur,Dindori,Madhya Pradesh', 'lon': '80.8421'},
      '781354': {'lat': '26.453', 'name': 'Chapania,Kamrup,Assam', 'lon': '91.573'},
      '781355': {'lat': '26.5736', 'name': 'Assam Syntex,Nalbari,Assam', 'lon': '91.3017'},
      '152022': {'lat': '30.2946', 'name': 'Ranjitgarh,Muktsar,Punjab', 'lon': '74.541'},
      '152023': {'lat': '30.3347', 'name': 'Chhanga Khurd,Firozpur,Punjab', 'lon': '74.8477'},
      '781350': {'lat': '26.477', 'name': 'Chatama,Nalbari,Assam', 'lon': '91.4874'},
      '781351': {'lat': '26.477', 'name': 'Kalag,Nalbari,Assam', 'lon': '91.4874'},
      '781352': {'lat': '26.346', 'name': 'Khablarvitha,Barpeta,Assam', 'lon': '91.677'},
      '781353': {'lat': '26.4389', 'name': 'Sandheli,Nalbari,Assam', 'lon': '91.4266'},
      '152028': {'lat': '31.0085', 'name': 'Sanehar,Firozpur,Punjab', 'lon': '74.9397'},
      '400701': {'lat': '19.1167', 'name': 'Ghansoli,Thane,Maharashtra', 'lon': '72.9833'},
      '827302': {'lat': '23.6076', 'name': 'Kasmar,Bokaro,Jharkhand', 'lon': '85.9326'},
      '411020': {'lat': '18.4769', 'name': 'Botanical Garden (Pune),Pune,Maharashtra', 'lon': '74.3505'},
      '754120': {'lat': '20.5614', 'name': 'Atharbanki Rly Colony,Jagatsinghapur,Odisha', 'lon': '85.3065'},
      '794104': {'lat': '25.5545', 'name': 'Holidayganj,West Garo Hills,Meghalaya', 'lon': '90.3369'},
      '400703': {'lat': '19.0787', 'name': 'Turbhe Market,Thane,Maharashtra', 'lon': '73.0005'},
      '794103': {'lat': '25.5545', 'name': 'Kharapara,West Garo Hills,Meghalaya', 'lon': '90.3369'},
      '400702': {'lat': '18.876905', 'name': 'Sheva,Raigarh(MH),Maharashtra', 'lon': '72.939695'},
      '226031': {'lat': '26.9084', 'name': 'Jankipuram Extension,Lucknow,Uttar Pradesh', 'lon': '80.9505'},
      '226030': {'lat': '26.9084', 'name': 'Sushant Golf name,Lucknow,Uttar Pradesh', 'lon': '80.9505'},
      '794102': {'lat': '25.1895', 'name': 'Emangiri,South Garo Hills,Meghalaya', 'lon': '90.6479'},
      '695312': {'lat': '8.7124', 'name': 'Melvettoor,Thiruvananthapuram,Kerala', 'lon': '76.7431'},
      '695313': {'lat': '8.7124', 'name': 'Kudavur,Thiruvananthapuram,Kerala', 'lon': '76.7431'},
      '695310': {'lat': '8.7124', 'name': 'Elakamon-kizhakkepuram,Thiruvananthapuram,Kerala', 'lon': '76.7431'},
      '695311': {'lat': '8.7636', 'name': 'Edava,Thiruvananthapuram,Kerala', 'lon': '76.6975'},
      '695316': {'lat': '8.5931', 'name': 'Pallipuram (Thiruvananthapuram),Thiruvananthapuram,Kerala', 'lon': '76.853'},
      '695317': {'lat': '8.7124', 'name': 'Thonnakkal,Thiruvananthapuram,Kerala', 'lon': '76.7431'},
      '206246': {'lat': '26.7989', 'name': 'Kanchausi Bazar,Auraiya,Uttar Pradesh', 'lon': '79.6661'},
      '695318': {'lat': '8.7124', 'name': 'Vennicode,Thiruvananthapuram,Kerala', 'lon': '76.7431'},
      '263642': {'lat': '29.8518', 'name': 'Bilonasera,Bageshwar,Uttarakhand', 'lon': '79.7612'},
      '811303': {'lat': '24.6924', 'name': 'S Batpar,Jamui,Bihar', 'lon': '86.3771'},
      '209870': {'lat': '26.6058', 'name': 'Hedrabad,Unnao,Uttar Pradesh', 'lon': '80.8772'},
      '321409': {'lat': '27.1467', 'name': 'Ghatri,Bharatpur,Rajasthan', 'lon': '77.3046'},
      '814154': {'lat': '25.0363', 'name': 'Anjana,Godda,Jharkhand', 'lon': '87.3074'},
      '184152': {'lat': '32.4369', 'name': 'Kirian Gandial,Kathua,Jammu & Kashmir', 'lon': '75.5265'},
      '184151': {'lat': '32.344', 'name': 'Khokhial,Kathua,Jammu & Kashmir', 'lon': '75.4231'},
      '814157': {'lat': '24.3678', 'name': 'Mohanpur Hat,Deoghar,Jharkhand', 'lon': '85.7157'},
      '712704': {'lat': '22.7745', 'name': 'Kumirmorah,Hooghly,West Bengal', 'lon': '88.2257'},
      '814156': {'lat': '25.0351', 'name': 'Sundmara,Godda,Jharkhand', 'lon': '87.405'},
      '503223': {'lat': '18.7584', 'name': 'Aloor,Nizamabad,Telangana', 'lon': '78.2436'},
      '480221': {'lat': '22.3337', 'name': 'Pipariya Rajguru,Chhindwara,Madhya Pradesh', 'lon': '79.226'},
      '416214': {'lat': '17.122', 'name': 'Ukhalu,Kolhapur,Maharashtra', 'lon': '74.1809'},
      '415313': {'lat': '17.1177', 'name': 'Tupari,Sangli,Maharashtra', 'lon': '74.6544'},
      '202165': {'lat': '27.9805', 'name': 'Malav,Aligarh,Uttar Pradesh', 'lon': '77.7653'},
      '416215': {'lat': '16.9857', 'name': 'Ukoli,Kolhapur,Maharashtra', 'lon': '74.1264'},
      '491228': {'lat': '20.6022', 'name': 'Mines Office Dallirajhara,Durg,Chattisgarh', 'lon': '81.0947'},
      '491229': {'lat': '20.745', 'name': 'Dhokla,Rajnandgaon,Chattisgarh', 'lon': '80.7339'},
      '416436': {'lat': '17.6348', 'name': 'Kupwad MIDC Area,Sangli,Maharashtra', 'lon': '75.3385'},
      '416437': {'lat': '17.112', 'name': 'Subhashnagar,Sangli,Maharashtra', 'lon': '74.7699'},
      '341501': {'lat': '26.9144', 'name': 'Badu (Nagaur),Nagaur,Rajasthan', 'lon': '74.2273'},
      '688501': {'lat': '9.466', 'name': 'Kuttamangalam,Alappuzha,Kerala', 'lon': '76.4352'},
      '491221': {'lat': '21.1912', 'name': 'Gadadih,Durg,Chattisgarh', 'lon': '81.3538'},
      '688503': {'lat': '9.466', 'name': 'Moncombu Thekkekara,Alappuzha,Kerala', 'lon': '76.4352'},
      '688502': {'lat': '9.4502', 'name': 'Chathurthiakary,Alappuzha,Kerala', 'lon': '76.4129'},
      '688505': {'lat': '9.466', 'name': 'Chempumpuram,Alappuzha,Kerala', 'lon': '76.4352'},
      '688504': {'lat': '9.466', 'name': 'Kannady,Alappuzha,Kerala', 'lon': '76.4352'},
      '322254': {'lat': '27.1539', 'name': 'Thigharia,Karauli,Rajasthan', 'lon': '76.6502'},
      '688506': {'lat': '9.4819', 'name': 'Cherukara Kuttanad,Alappuzha,Kerala', 'lon': '76.4574'},
      '797104': {'lat': '25.8234', 'name': 'Phugwu,Phek,Nagaland', 'lon': '94.2273'},
      '384221': {'lat': '23.7107', 'name': 'Takodi,Patan,Gujarat', 'lon': '72.5192'},
      '384220': {'lat': '23.7147', 'name': 'Dharmoda,Patan,Gujarat', 'lon': '72.1128'},
      '721242': {'lat': '22.8169', 'name': 'Dianpur,West Midnapore,West Bengal', 'lon': '87.5959'},
      '384225': {'lat': '23.7087', 'name': 'Zilia,Patan,Gujarat', 'lon': '72.7225'},
      '416219': {'lat': '16.396', 'name': 'Savarde BK,Kolhapur,Maharashtra', 'lon': '74.1914'},
      '848114': {'lat': '25.6798', 'name': 'Kamraon,Samastipur,Bihar', 'lon': '85.8173'},
      '848115': {'lat': '25.8928', 'name': 'Malikaur,Samastipur,Bihar', 'lon': '85.7146'},
      '384229': {'lat': '23.7107', 'name': 'Palasar,Patan,Gujarat', 'lon': '72.5192'},
      '848117': {'lat': '25.7704', 'name': 'Rebra,Samastipur,Bihar', 'lon': '85.7476'},
      '788025': {'lat': '24.8667', 'name': 'Ganirgram,Cachar,Assam', 'lon': '92.7667'},
      '811309': {'lat': '25.0733', 'name': 'Pokhrama,Lakhisarai,Bihar', 'lon': '86.4276'},
      '574285': {'lat': '12.7806', 'name': 'Sharavoor,Dakshina Kannada,Karnataka', 'lon': '75.368'},
      '788026': {'lat': '24.8352', 'name': 'Rajnagar,Cachar,Assam', 'lon': '92.7158'},
      '385570': {'lat': '24.1521', 'name': 'Chala,Banaskantha,Gujarat', 'lon': '71.3864'},
      '502108': {'lat': '17.8618', 'name': 'Chikode,Medak,Telangana', 'lon': '78.6'},
      '811308': {'lat': '24.897', 'name': 'T. Chhapa,Jamui,Bihar', 'lon': '86.3813'},
      '562104': {'lat': '13.5604', 'name': 'Chikkapyalagurkj,Chikkaballapur,Karnataka', 'lon': '77.9334'},
      '385575': {'lat': '24.2266', 'name': 'Dharadhara,Banaskantha,Gujarat', 'lon': '71.2601'},
      '843124': {'lat': '25.5953', 'name': 'P.Mushahari,Muzaffarpur,Bihar', 'lon': '85.5812'},
      '843125': {'lat': '26.2096', 'name': 'Kurnowl,Muzaffarpur,Bihar', 'lon': '84.9945'},
      '843126': {'lat': '25.9109', 'name': 'Laloo Chapra,Muzaffarpur,Bihar', 'lon': '85.3752'},
      '843127': {'lat': '25.9787', 'name': 'Baburban Tajpur,Muzaffarpur,Bihar', 'lon': '85.5089'},
      '843120': {'lat': '26.1036', 'name': 'Tola Muza,Muzaffarpur,Bihar', 'lon': '85.0156'},
      '843121': {'lat': '25.5574', 'name': 'Pilkhi Gajpatti,Muzaffarpur,Bihar', 'lon': '85.5268'},
      '797105': {'lat': '25.8234', 'name': 'Chiephobozou,Kohima,Nagaland', 'lon': '94.2273'},
      '843123': {'lat': '25.5085', 'name': 'Jaintpur Estate,Muzaffarpur,Bihar', 'lon': '86.1613'},
      '230133': {'lat': '25.8227', 'name': 'Prithviganj Bazar,Pratapgarh,Uttar Pradesh', 'lon': '82.1114'},
      '481001': {'lat': '21.8705', 'name': 'Balaghat H.O,Balaghat,Madhya Pradesh', 'lon': '80.1624'},
      '230131': {'lat': '25.8033', 'name': 'Pure Narsingh Bhan,Pratapgarh,Uttar Pradesh', 'lon': '82.0417'},
      '230130': {'lat': '25.9822', 'name': 'Laxmanpur (Pratapgarh),Pratapgarh,Uttar Pradesh', 'lon': '81.9339'},
      '230137': {'lat': '25.9624', 'name': 'Bahuchara,Pratapgarh,Uttar Pradesh', 'lon': '81.8636'},
      '230136': {'lat': '25.9576', 'name': 'Harakhpur Kotwa,Pratapgarh,Uttar Pradesh', 'lon': '81.7539'},
      '230135': {'lat': '26.0327', 'name': 'Pirthiganj Bazar,Pratapgarh,Uttar Pradesh', 'lon': '82.0249'},
      '743502': {'lat': '22.3916', 'name': 'Bhangar Gobindapur,South 24 Parganas,West Bengal', 'lon': '88.503'},
      '311030': {'lat': '25.7556', 'name': 'Kasuriya,Bhilwara,Rajasthan', 'lon': '74.6907'},
      '230139': {'lat': '26.0034', 'name': 'Rahatikar,Pratapgarh,Uttar Pradesh', 'lon': '81.7301'},
      '230138': {'lat': '26.257', 'name': 'Saifabad,Pratapgarh,Uttar Pradesh', 'lon': '82.052'},
      '796770': {'lat': '22.4912', 'name': 'Udalthana,Lawngtlai,Mizoram', 'lon': '92.7373'},
      '505476': {'lat': '18.1013', 'name': 'Yelkathurthi,Karim Nagar,Telangana', 'lon': '79.4401'},
      '625519': {'lat': '9.9603', 'name': 'Highwavys,Theni,Tamil Nadu', 'lon': '77.7791'},
      '625518': {'lat': '9.6783', 'name': 'Keelagudalur,Theni,Tamil Nadu', 'lon': '77.2495'},
      '638052': {'lat': '11.2246', 'name': 'Ingur,Erode,Tamil Nadu', 'lon': '77.5813'},
      '571123': {'lat': '12.0422', 'name': 'Chirakanahalli,Chamrajnagar,Karnataka', 'lon': '76.7881'},
      '518523': {'lat': '15.4239', 'name': 'Karimaddula,Kurnool,Andhra Pradesh', 'lon': '78.884'},
      '603204': {'lat': '12.7039', 'name': 'Karunilam,Kanchipuram,Tamil Nadu', 'lon': '79.8292'},
      '625517': {'lat': '9.963', 'name': 'G.Usilampatti,Theni,Tamil Nadu', 'lon': '77.5704'},
      '625516': {'lat': '9.7365', 'name': 'Karunagamuthanpatti,Theni,Tamil Nadu', 'lon': '77.2847'},
      '625515': {'lat': '9.8572', 'name': 'Markeyankottai,Theni,Tamil Nadu', 'lon': '77.3779'},
      '625514': {'lat': '10.1696', 'name': 'Checkanurani,Madurai,Tamil Nadu', 'lon': '78.5422'},
      '485113': {'lat': '24.5655', 'name': 'Kripalpur Harijan Tola,Satna,Madhya Pradesh', 'lon': '80.9244'},
      '365480': {'lat': '22.1848', 'name': 'Tori,Amreli,Gujarat', 'lon': '71.0925'},
      '485112': {'lat': '24.6207', 'name': 'Kunwa,Satna,Madhya Pradesh', 'lon': '80.6013'},
      '585287': {'lat': '16.7694', 'name': 'Maddarki,Yadgir,Karnataka', 'lon': '76.8086'},
      '603209': {'lat': '12.6009', 'name': 'Panangottur,Kanchipuram,Tamil Nadu', 'lon': '79.8214'},
      '506001': {'lat': '17.9962', 'name': 'Hanamkonda H.O,Warangal,Telangana', 'lon': '79.5407'},
      '799045': {'lat': '23.6622', 'name': 'Champaknagar,South Tripura,Tripura', 'lon': '91.3881'},
      '361250': {'lat': '23.6177', 'name': 'Badanpar,Jamnagar,Gujarat', 'lon': '72.406'},
      '431118': {'lat': '20.3835', 'name': 'Sawaladbara,Aurangabad,Maharashtra', 'lon': '76.1331'},
      '571120': {'lat': '12.6487', 'name': 'Vyasarajapura,Mysuru,Karnataka', 'lon': '77.202'},
      '431114': {'lat': '20.4195', 'name': 'Hasnabad,Aurangabad,Maharashtra', 'lon': '75.1241'},
      '431115': {'lat': '20.2242', 'name': 'Mali Wadgaon,Aurangabad,Maharashtra', 'lon': '75.0435'},
      '431116': {'lat': '20.3156', 'name': 'Parala,Aurangabad,Maharashtra', 'lon': '75.3288'},
      '431117': {'lat': '20.4931', 'name': 'Ajantha,Aurangabad,Maharashtra', 'lon': '75.718'},
      '431110': {'lat': '20.1785', 'name': 'Agarwadgaon,Aurangabad,Maharashtra', 'lon': '75.6307'},
      '110100': {'lat': '28.6563', 'name': 'Foreign Post Delhi IBC,Central Delhi,Delhi', 'lon': '77.1366'},
      '431112': {'lat': '20.5088', 'name': 'Anwa,Aurangabad,Maharashtra', 'lon': '75.6577'},
      '431113': {'lat': '20.0215', 'name': 'Bharadi,Aurangabad,Maharashtra', 'lon': '75.2735'},
      '132037': {'lat': '29.6247', 'name': 'Phoosgarh,Karnal,Haryana', 'lon': '77.0141'},
      '132036': {'lat': '29.6502', 'name': 'Jundla,Karnal,Haryana', 'lon': '76.8724'},
      '136044': {'lat': '29.5838', 'name': 'Kheri Simbal Wali,Kaithal,Haryana', 'lon': '76.4837'},
      '533124': {'lat': '16.9315', 'name': 'Rajavolu,East Godavari,Andhra Pradesh', 'lon': '81.807'},
      '533125': {'lat': '16.9307', 'name': 'Vemagiri,East Godavari,Andhra Pradesh', 'lon': '81.806'},
      '533126': {'lat': '16.9167', 'name': 'Madhavarayudupalem,East Godavari,Andhra Pradesh', 'lon': '81.8333'},
      '185203': {'lat': '33.3788', 'name': 'Poni,Udhampur,Jammu & Kashmir', 'lon': '74.4219'},
      '132039': {'lat': '29.5708', 'name': 'Ardhana,Karnal,Haryana', 'lon': '76.6115'},
      '370625': {'lat': '23.4435', 'name': 'Kotda (Madh),Kachchh,Gujarat', 'lon': '68.9365'},
      '562107': {'lat': '12.98', 'name': 'Neralur,Bengaluru,Karnataka', 'lon': '77.3743'},
      '370627': {'lat': '23.428', 'name': 'Gaduli,Kachchh,Gujarat', 'lon': '68.9953'},
      '571121': {'lat': '12.2825', 'name': 'Thumbasoge,Mysuru,Karnataka', 'lon': '76.4783'},
      '497101': {'lat': '22.4734', 'name': 'Sedam,Surguja,Chattisgarh', 'lon': '82.4278'},
      '361160': {'lat': '22.0659', 'name': 'Machhlivad,Jamnagar,Gujarat', 'lon': '70.5002'},
      '509217': {'lat': '17.1929', 'name': 'Mamidipally,Mahabub Nagar,Telangana', 'lon': '78.3087'},
      '509216': {'lat': '17.0647', 'name': 'Chatanpally,Mahabub Nagar,Telangana', 'lon': '78.2128'},
      '431808': {'lat': '19.2549', 'name': 'Bolsa,Nanded,Maharashtra', 'lon': '77.8879'},
      '431809': {'lat': '18.8996', 'name': 'Hussa,Nanded,Maharashtra', 'lon': '77.6075'},
      '403526': {'lat': '15.6235', 'name': 'Tivim Ie,North Goa,Goa', 'lon': '73.8377'},
      '509210': {'lat': '17.0133', 'name': 'Singwar,Mahabub Nagar,Telangana', 'lon': '77.7697'},
      '431802': {'lat': '19.0587', 'name': 'Veersani,Nanded,Maharashtra', 'lon': '77.1318'},
      '431803': {'lat': '19.4867', 'name': 'Iregaon,Nanded,Maharashtra', 'lon': '78.146'},
      '431801': {'lat': '19.2346', 'name': 'Theraban,Nanded,Maharashtra', 'lon': '77.6407'},
      '431806': {'lat': '19.1508', 'name': 'Mugat,Nanded,Maharashtra', 'lon': '77.4317'},
      '431807': {'lat': '19.3247', 'name': 'Bitnal,Nanded,Maharashtra', 'lon': '77.5489'},
      '431804': {'lat': '19.4902', 'name': 'Sindgi,Nanded,Maharashtra', 'lon': '78.2303'},
      '431805': {'lat': '19.8544', 'name': 'Dundara,Nanded,Maharashtra', 'lon': '78.1304'},
      '441614': {'lat': '21.5034', 'name': 'Boda,Gondia,Maharashtra', 'lon': '80.021'},
      '224195': {'lat': '25.914', 'name': 'Khamipurnidhiywan,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.3653'},
      '403529': {'lat': '15.6235', 'name': 'Bicholim Industrial Estate,North Goa,Goa', 'lon': '73.8377'},
      '562101': {'lat': '13.4655', 'name': 'Kethenahalli,Chikkaballapur,Karnataka', 'lon': '77.687'},
      '460553': {'lat': '21.9151', 'name': 'Lakhapur,Betul,Madhya Pradesh', 'lon': '78.0513'},
      '571127': {'lat': '11.8516', 'name': 'Punajanur,Chamrajnagar,Karnataka', 'lon': '76.9294'},
      '638312': {'lat': '11.4713', 'name': 'Thottipalayam,Erode,Tamil Nadu', 'lon': '77.6372'},
      '638311': {'lat': '11.621', 'name': 'Kadappanalur,Erode,Tamil Nadu', 'lon': '77.7432'},
      '788152': {'lat': '25.1', 'name': 'Bishnunagar East,Hailakandi,Assam', 'lon': '92.8333'},
      '638316': {'lat': '11.5322', 'name': 'Vasavi College,Erode,Tamil Nadu', 'lon': '77.6114'},
      '638315': {'lat': '11.4359', 'name': 'Vellalapalayam,Erode,Tamil Nadu', 'lon': '77.3591'},
      '638314': {'lat': '11.573', 'name': 'Mukasipudur,Erode,Tamil Nadu', 'lon': '77.6392'},
      '173032': {'lat': '30.6246', 'name': 'Sangana,Sirmaur,Himachal Pradesh', 'lon': '77.5561'},
      '761124': {'lat': '19.7382', 'name': 'Badaborasingi,Ganjam,Odisha', 'lon': '84.0489'},
      '173030': {'lat': '30.532', 'name': 'Bramapapri,Sirmaur,Himachal Pradesh', 'lon': '77.2221'},
      '173031': {'lat': '30.5667', 'name': 'Dhualakuan,Sirmaur,Himachal Pradesh', 'lon': '77.3474'},
      '761121': {'lat': '20.3228', 'name': 'Samarbandha,Ganjam,Odisha', 'lon': '85.0241'},
      '761120': {'lat': '19.9614', 'name': 'Khamarpalli,Ganjam,Odisha', 'lon': '84.5675'},
      '761123': {'lat': '19.7708', 'name': 'Pailipada,Ganjam,Odisha', 'lon': '83.9854'},
      '523135': {'lat': '15.4762', 'name': 'Pallepalem,Prakasam,Andhra Pradesh', 'lon': '80.1986'},
      '273409': {'lat': '26.388', 'name': 'Tarayapar,Gorakhpur,Uttar Pradesh', 'lon': '83.371'},
      '393140': {'lat': '22.7271', 'name': 'Taropa,Narmada,Gujarat', 'lon': '73.7011'},
      '393145': {'lat': '22.6291', 'name': 'Sunderpura,Bharuch,Gujarat', 'lon': '73.2022'},
      '206002': {'lat': '26.036', 'name': 'Adhiyapur,Etawah,Uttar Pradesh', 'lon': '79.2804'},
      '206003': {'lat': '26.7778', 'name': 'Ram Lila Road,Etawah,Uttar Pradesh', 'lon': '79.0714'},
      '206001': {'lat': '27.3061', 'name': 'Colleger Road Bharthana,Etawah,Uttar Pradesh', 'lon': '79.0177'},
      '673302': {'lat': '11.5072', 'name': 'Kadalundi,Kozhikode,Kerala', 'lon': '75.658'},
      '791109': {'lat': '27.1329', 'name': 'Nirjuli,Papum Pare,Arunachal Pradesh', 'lon': '93.7465'},
      '741255': {'lat': '22.941', 'name': 'Noapara,Nadia,West Bengal', 'lon': '88.637'},
      '515411': {'lat': '14.9159', 'name': 'Tadpatri Bazar,Ananthapur,Andhra Pradesh', 'lon': '77.9604'},
      '741257': {'lat': '22.941', 'name': 'Kasthadanga,Nadia,West Bengal', 'lon': '88.637'},
      '515413': {'lat': '14.809', 'name': 'Bhogasamudram Project,Ananthapur,Andhra Pradesh', 'lon': '78.1111'},
      '515414': {'lat': '14.5988', 'name': 'Putlur,Ananthapur,Andhra Pradesh', 'lon': '78.3346'},
      '515415': {'lat': '14.9639', 'name': 'Velamakur,Ananthapur,Andhra Pradesh', 'lon': '78.0021'},
      '741253': {'lat': '22.941', 'name': 'Eruli,Nadia,West Bengal', 'lon': '88.637'},
      '741252': {'lat': '22.941', 'name': 'Krishivisyavidyalaya,Nadia,West Bengal', 'lon': '88.637'},
      '576219': {'lat': '13.8015', 'name': 'Moodumata,Udupi,Karnataka', 'lon': '74.6304'},
      '576218': {'lat': '13.5655', 'name': 'Kodibengre,Udupi,Karnataka', 'lon': '74.7534'},
      '562103': {'lat': '13.4998', 'name': 'Agalagurki,Chikkaballapur,Karnataka', 'lon': '77.6703'},
      '576215': {'lat': '13.4263', 'name': 'Karje,Udupi,Karnataka', 'lon': '74.8764'},
      '576214': {'lat': '13.8519', 'name': 'Mayyadi,Udupi,Karnataka', 'lon': '74.67'},
      '576217': {'lat': '13.606', 'name': 'Mudlakatte,Udupi,Karnataka', 'lon': '74.6967'},
      '576216': {'lat': '13.6502', 'name': 'Ganguli,Udupi,Karnataka', 'lon': '74.6707'},
      '576211': {'lat': '13.6359', 'name': 'Kavradi,Udupi,Karnataka', 'lon': '74.7695'},
      '531040': {'lat': '18.3433', 'name': 'Aradakota,Visakhapatnam,Andhra Pradesh', 'lon': '82.8957'},
      '576213': {'lat': '13.4206', 'name': 'Haradi,Udupi,Karnataka', 'lon': '74.7147'},
      '177110': {'lat': '31.7477', 'name': 'Dhalwari,Una,Himachal Pradesh', 'lon': '76.3112'},
      '306703': {'lat': '25.3328', 'name': 'Sumer,Pali,Rajasthan', 'lon': '73.6194'},
      '431402': {'lat': '19.2282', 'name': 'Pimpri Deshmukh,Parbhani,Maharashtra', 'lon': '76.9215'},
      '273004': {'lat': '26.783', 'name': 'Ashok Nagar (Gorakhpur),Gorakhpur,Uttar Pradesh', 'lon': '83.3852'},
      '273005': {'lat': '26.9887', 'name': 'Gita Press,Gorakhpur,Uttar Pradesh', 'lon': '82.9737'},
      '273002': {'lat': '26.6912', 'name': 'Jungle Ramgarh,Gorakhpur,Uttar Pradesh', 'lon': '83.3606'},
      '273003': {'lat': '26.7899', 'name': 'Arogyamandir,Gorakhpur,Uttar Pradesh', 'lon': '83.32'},
      '585353': {'lat': '17.8531', 'name': 'Maniknagar,Bidar,Karnataka', 'lon': '77.0761'},
      '333001': {'lat': '27.9664', 'name': 'Paratappura,Jhujhunu,Rajasthan', 'lon': '75.2621'},
      '274601': {'lat': '26.0314', 'name': 'Parsia Dewar,Deoria,Uttar Pradesh', 'lon': '83.5442'},
      '273008': {'lat': '26.7899', 'name': 'Kunraghat H.O,Gorakhpur,Uttar Pradesh', 'lon': '83.32'},
      '273009': {'lat': '26.7899', 'name': 'Gorakhpur University,Gorakhpur,Uttar Pradesh', 'lon': '83.32'},
      '403729': {'lat': '15.2046', 'name': 'Navelim Camp,South Goa,Goa', 'lon': '73.9732'},
      '670358': {'lat': '12.0418', 'name': 'Payangadi R S,Kannur,Kerala', 'lon': '75.3473'},
      '791101': {'lat': '27.9314', 'name': 'Dali,West Siang,Arunachal Pradesh', 'lon': '94.7569'},
      '670353': {'lat': '12.0418', 'name': 'Padiotchal,Kannur,Kerala', 'lon': '75.3473'},
      '124010': {'lat': '28.8571', 'name': 'Bhali Anandpur,Rohtak,Haryana', 'lon': '76.5513'},
      '403509': {'lat': '15.58', 'name': 'Anjuna,North Goa,Goa', 'lon': '73.7449'},
      '396469': {'lat': '20.8894', 'name': 'Kurel,Navsari,Gujarat', 'lon': '72.9973'},
      '841437': {'lat': '26.3715', 'name': 'Mahuwan,Gopalganj,Bihar', 'lon': '84.9706'},
      '396463': {'lat': '20.9357', 'name': 'Italva,Navsari,Gujarat', 'lon': '72.9794'},
      '396460': {'lat': '20.8228', 'name': 'Kanera,Navsari,Gujarat', 'lon': '72.892'},
      '585355': {'lat': '16.7679', 'name': 'Tumkur,Yadgir,Karnataka', 'lon': '77.0697'},
      '396466': {'lat': '20.9097', 'name': 'Dabhlai,Navsari,Gujarat', 'lon': '73.1205'},
      '222132': {'lat': '25.7549', 'name': 'Kohara Sultanpur,Jaunpur,Uttar Pradesh', 'lon': '82.6036'},
      '500056': {'lat': '17.3724', 'name': 'Ramakrishna Puram,Hyderabad,Telangana', 'lon': '78.437'},
      '500057': {'lat': '17.3724', 'name': 'Vijay Nagar Colony (Hyderabad),Hyderabad,Telangana', 'lon': '78.437'},
      '500054': {'lat': '17.3724', 'name': 'HMT Township,Hyderabad,Telangana', 'lon': '78.437'},
      '176128': {'lat': '32.1252', 'name': 'Nagehar,Kangra,Himachal Pradesh', 'lon': '76.4406'},
      '500052': {'lat': '17.299', 'name': 'Shivarampalli,K.V.Rangareddy,Telangana', 'lon': '78.4186'},
      '500053': {'lat': '17.34', 'name': 'Falaknuma,Hyderabad,Telangana', 'lon': '78.4852'},
      '500050': {'lat': '17.3898', 'name': 'Chandanagar,Hyderabad,Telangana', 'lon': '78.4699'},
      '174021': {'lat': '22.7749169', 'name': 'Amarpur,Bilaspur (HP),Himachal Pradesh', 'lon': '81.9637'},
      '756019': {'lat': '21.3726', 'name': 'Badapal,Baleswar,Odisha', 'lon': '86.1634'},
      '176125': {'lat': '31.9394', 'name': 'Sehal,Kangra,Himachal Pradesh', 'lon': '76.6603'},
      '222135': {'lat': '25.715', 'name': 'Mehandi,Jaunpur,Uttar Pradesh', 'lon': '82.5105'},
      '736176': {'lat': '26.0188', 'name': 'Khochabari,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '250615': {'lat': '28.9184', 'name': 'Lalyana,Bagpat,Uttar Pradesh', 'lon': '77.4219'},
      '171011': {'lat': '31.1616', 'name': 'Badehari,Shimla,Himachal Pradesh', 'lon': '77.0922'},
      '736172': {'lat': '26.0188', 'name': 'Gossairhat Bandar,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '250611': {'lat': '29.1246', 'name': 'Kotana,Bagpat,Uttar Pradesh', 'lon': '77.3122'},
      '736170': {'lat': '26.0188', 'name': 'Ghughumari,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '736171': {'lat': '26.0188', 'name': 'Kalpani,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '261131': {'lat': '26.8209', 'name': 'Sharifganj,Sitapur,Uttar Pradesh', 'lon': '80.8807'},
      '334604': {'lat': '28.8706', 'name': 'Rambag,Bikaner,Rajasthan', 'lon': '73.7639'},
      '334602': {'lat': '28.4109', 'name': 'Rawansar,Bikaner,Rajasthan', 'lon': '73.9514'},
      '573102': {'lat': '12.7584', 'name': 'Bychanahalli,Hassan,Karnataka', 'lon': '76.1247'},
      '742163': {'lat': '23.5221', 'name': 'Sompara,Murshidabad,West Bengal', 'lon': '88.3129'},
      '394230': {'lat': '21.4053', 'name': 'Kansad,Surat,Gujarat', 'lon': '73.2118'},
      '821308': {'lat': '25.0921', 'name': 'Indrapuri,Rohtas,Bihar', 'lon': '84.3504'},
      '175104': {'lat': '31.9266', 'name': 'Archandi,Kullu,Himachal Pradesh', 'lon': '77.1544'},
      '605758': {'lat': '12.0455', 'name': 'Vasanthakrishnapuram,Villupuram,Tamil Nadu', 'lon': '79.2139'},
      '174026': {'lat': '31.635334', 'name': 'Kuthera BLP,Bilaspur (HP),Himachal Pradesh', 'lon': '76.437136'},
      '175105': {'lat': '31.989', 'name': 'Kasladi,Kullu,Himachal Pradesh', 'lon': '77.3239'},
      '821301': {'lat': '25.0108', 'name': 'Akorhi Bazar,Rohtas,Bihar', 'lon': '84.163'},
      '821302': {'lat': '25.0617', 'name': 'Amjhore Camp,Rohtas,Bihar', 'lon': '84.2632'},
      '171015': {'lat': '31.1783', 'name': 'Baldeyan,Shimla,Himachal Pradesh', 'lon': '77.2459'},
      '821304': {'lat': '24.7483', 'name': 'Chunhatta,Rohtas,Bihar', 'lon': '83.9277'},
      '518360': {'lat': '15.6915', 'name': 'T.Sallakudlur,Kurnool,Andhra Pradesh', 'lon': '77.7505'},
      '821306': {'lat': '25.2458', 'name': 'Paruhar,Rohtas,Bihar', 'lon': '85.2564'},
      '821307': {'lat': '25.0617', 'name': 'Dehri BMP,Rohtas,Bihar', 'lon': '84.2632'},
      '799282': {'lat': '24.0789', 'name': 'Chinibagan,North Tripura,Tripura', 'lon': '91.8987'},
      '306001': {'lat': '25.7927', 'name': 'Sinla,Pali,Rajasthan', 'lon': '73.5283'},
      '799280': {'lat': '24.0789', 'name': 'Jagannathpur,North Tripura,Tripura', 'lon': '91.8987'},
      '799287': {'lat': '24.0789', 'name': 'Kamalpur Aerodrome,Dhalai,Tripura', 'lon': '91.8987'},
      '799286': {'lat': '24.0789', 'name': 'Apareshkar,Dhalai,Tripura', 'lon': '91.8987'},
      '799285': {'lat': '24.1959', 'name': 'Singibill,Dhalai,Tripura', 'lon': '91.8344'},
      '799284': {'lat': '24.0789', 'name': 'Ranipukur,Dhalai,Tripura', 'lon': '91.8987'},
      '609704': {'lat': '10.8432', 'name': 'Tirupugalur,Nagapattinam,Tamil Nadu', 'lon': '79.6968'},
      '799289': {'lat': '23.936', 'name': 'Lakraichowdhuripara,Dhalai,Tripura', 'lon': '91.8544'},
      '573103': {'lat': '13.3036', 'name': 'Sulekere,Hassan,Karnataka', 'lon': '76.3493'},
      '609701': {'lat': '10.8821', 'name': 'Enangudi,Nagapattinam,Tamil Nadu', 'lon': '79.6776'},
      '609702': {'lat': '10.8986', 'name': 'Polagam,Nagapattinam,Tamil Nadu', 'lon': '79.7137'},
      '175101': {'lat': '31.6816', 'name': 'Bharai,Mandi,Himachal Pradesh', 'lon': '77.2482'},
      '686576': {'lat': '9.7997', 'name': 'Amanakara,Kottayam,Kerala', 'lon': '76.6623'},
      '686577': {'lat': '9.633', 'name': 'Mallikassery,Kottayam,Kerala', 'lon': '76.679'},
      '686574': {'lat': '9.633', 'name': 'Kadappattoor,Kottayam,Kerala', 'lon': '76.679'},
      '686575': {'lat': '9.633', 'name': 'Pala Town,Kottayam,Kerala', 'lon': '76.679'},
      '686572': {'lat': '9.1584', 'name': 'Kummannoor,Kottayam,Kerala', 'lon': '76.5427'},
      '683501': {'lat': '10.1039', 'name': 'Kuttikkattukara,Ernakulam,Kerala', 'lon': '76.4103'},
      '683502': {'lat': '10.1039', 'name': 'Binanipuram,Ernakulam,Kerala', 'lon': '76.4103'},
      '686571': {'lat': '9.7155', 'name': 'Kadaplamattom,Kottayam,Kerala', 'lon': '76.607'},
      '175103': {'lat': '32.3143', 'name': 'Palchan,Kullu,Himachal Pradesh', 'lon': '77.1866'},
      '686578': {'lat': '9.697', 'name': 'Kizhaparayar,Kottayam,Kerala', 'lon': '76.7241'},
      '686579': {'lat': '9.7138', 'name': 'Choondacherry,Kottayam,Kerala', 'lon': '76.769'},
      '274802': {'lat': '26.7081', 'name': 'Belwaghat,Kushinagar,Uttar Pradesh', 'lon': '83.3035'},
      '274801': {'lat': '26.3231', 'name': 'Rampur Jungle,Kushinagar,Uttar Pradesh', 'lon': '82.6843'},
      '247666': {'lat': '29.838', 'name': 'Bijholi,Haridwar,Uttarakhand', 'lon': '77.8998'},
      '274807': {'lat': '25.1013', 'name': 'Hata Banuadih,Deoria,Uttar Pradesh', 'lon': '82.8206'},
      '796751': {'lat': '22.912', 'name': 'Nunsury,Lunglei,Mizoram', 'lon': '92.498'},
      '274808': {'lat': '25.9001', 'name': 'Pipara Ramdhar,Deoria,Uttar Pradesh', 'lon': '83.628'},
      '642110': {'lat': '11.0299', 'name': 'Kaliapuram,Coimbatore,Tamil Nadu', 'lon': '76.9632'},
      '515671': {'lat': '14.46', 'name': 'Dharmavaram Bazar,Ananthapur,Andhra Pradesh', 'lon': '77.9602'},
      '515672': {'lat': '14.46', 'name': 'Kunuthuru,Ananthapur,Andhra Pradesh', 'lon': '77.9602'},
      '227813': {'lat': '26.4524', 'name': 'Imligaon Chupi,Sultanpur,Uttar Pradesh', 'lon': '81.8798'},
      '518101': {'lat': '16.0722', 'name': 'Srisailam,Kurnool,Andhra Pradesh', 'lon': '78.8682'},
      '518102': {'lat': '16.0722', 'name': 'S.S.Dam East,Kurnool,Andhra Pradesh', 'lon': '78.8682'},
      '382850': {'lat': '23.5351', 'name': 'Khanusa,Mahesana,Gujarat', 'lon': '72.7149'},
      '607802': {'lat': '11.5795', 'name': 'Vadakkuvelur,Cuddalore,Tamil Nadu', 'lon': '79.6154'},
      '678641': {'lat': '10.8296', 'name': 'Vadasseri,Palakkad,Kerala', 'lon': '76.498'},
      '627412': {'lat': '8.9974', 'name': 'Sivasailam,Tirunelveli,Tamil Nadu', 'lon': '77.641'},
      '627413': {'lat': '8.9974', 'name': 'Adaichani,Tirunelveli,Tamil Nadu', 'lon': '77.641'},
      '627414': {'lat': '8.642', 'name': 'Thiruviruthanpulli,Tirunelveli,Tamil Nadu', 'lon': '77.5778'},
      '627415': {'lat': '9.9393', 'name': 'Poolankulam,Tirunelveli,Tamil Nadu', 'lon': '77.9962'},
      '627416': {'lat': '8.6979', 'name': 'Sattupathu,Tirunelveli,Tamil Nadu', 'lon': '77.4991'},
      '573101': {'lat': '13.0552', 'name': 'Malasavara,Hassan,Karnataka', 'lon': '75.7614'},
      '627418': {'lat': '8.9974', 'name': 'Kila Ambur,Tirunelveli,Tamil Nadu', 'lon': '77.641'},
      '743136': {'lat': '22.708', 'name': 'Nabanagar,North 24 Parganas,West Bengal', 'lon': '88.409'},
      '530024': {'lat': '17.8224', 'name': 'Salagramapuram,Visakhapatnam,Andhra Pradesh', 'lon': '83.2437'},
      '142050': {'lat': '31.0255', 'name': 'Pandori Khatrian,Firozpur,Punjab', 'lon': '74.9651'},
      '530026': {'lat': '17.7', 'name': 'Gajuwaka,Visakhapatnam,Andhra Pradesh', 'lon': '83.2167'},
      '530027': {'lat': '17.9438', 'name': 'Yellapuvanipalem,Visakhapatnam,Andhra Pradesh', 'lon': '83.2727'},
      '530020': {'lat': '17.8224', 'name': 'VM Bus Station,Visakhapatnam,Andhra Pradesh', 'lon': '83.2437'},
      '637304': {'lat': '11.5411', 'name': 'Vengipalayam,Namakkal,Tamil Nadu', 'lon': '77.9322'},
      '530022': {'lat': '17.8224', 'name': 'Isakathota,Visakhapatnam,Andhra Pradesh', 'lon': '83.2437'},
      '176320': {'lat': '32.6715', 'name': 'Sanghani,Chamba,Himachal Pradesh', 'lon': '76.0778'},
      '679321': {'lat': '10.9764', 'name': 'Angadipuram,Malappuram,Kerala', 'lon': '76.2011'},
      '382855': {'lat': '21.4352', 'name': 'Khadat,Gandhi Nagar,Gujarat', 'lon': '74.0108'},
      '530028': {'lat': '17.7668', 'name': 'Simhachalam Hills,Visakhapatnam,Andhra Pradesh', 'lon': '83.2499'},
      '530029': {'lat': '17.7576', 'name': 'Venkatapuram,Visakhapatnam,Andhra Pradesh', 'lon': '83.2066'},
      '485773': {'lat': '24.1414', 'name': 'Ghunwara,Satna,Madhya Pradesh', 'lon': '80.631'},
      '142053': {'lat': '30.8233', 'name': 'Nathowala Jadid,Moga,Punjab', 'lon': '75.2586'},
      '142054': {'lat': '30.8456', 'name': 'Kokri Heron,Moga,Punjab', 'lon': '75.3307'},
      '176324': {'lat': '32.5139', 'name': 'Koonr,Chamba,Himachal Pradesh', 'lon': '76.2629'},
      '362130': {'lat': '21.9302', 'name': 'Kalsari,Junagadh,Gujarat', 'lon': '70.4775'},
      '362135': {'lat': '21.1762', 'name': 'Sasan Gir,Junagadh,Gujarat', 'lon': '70.5892'},
      '146024': {'lat': '31.5906', 'name': 'Manguawal Nari,Hoshiarpur,Punjab', 'lon': '75.8379'},
      '210125': {'lat': '25.5161', 'name': 'Kamasin,Banda,Uttar Pradesh', 'lon': '80.907'},
      '363421': {'lat': '22.5526', 'name': 'Untadi,Surendra Nagar,Gujarat', 'lon': '71.8666'},
      '146021': {'lat': '31.5796', 'name': 'Bassi Purani,Hoshiarpur,Punjab', 'lon': '75.9875'},
      '146023': {'lat': '31.4758', 'name': 'Chagran,Hoshiarpur,Punjab', 'lon': '75.9722'},
      '146022': {'lat': '31.4968', 'name': 'Taragarh,Hoshiarpur,Punjab', 'lon': '75.802'},
      '363660': {'lat': '23.0055', 'name': 'Bodki,Rajkot,Gujarat', 'lon': '70.6103'},
      '637212': {'lat': '11.3163', 'name': 'Elanagar,Namakkal,Tamil Nadu', 'lon': '78.1361'},
      '464776': {'lat': '23.217', 'name': 'Ramkhiria,Raisen,Madhya Pradesh', 'lon': '78.1184'},
      '493344': {'lat': '21.6318', 'name': 'Jhabdi,Raipur,Chattisgarh', 'lon': '82.4503'},
      '332722': {'lat': '27.1241', 'name': 'Kasarda,Sikar,Rajasthan', 'lon': '75.9484'},
      '332721': {'lat': '26.8139', 'name': 'Sakraimata,Sikar,Rajasthan', 'lon': '75.9383'},
      '847240': {'lat': '26.572', 'name': 'Umgaon kothi,Madhubani,Bihar', 'lon': '85.9672'},
      '363642': {'lat': '21.8717', 'name': 'Mahendranagar,Rajkot,Gujarat', 'lon': '70.1557'},
      '363643': {'lat': '22.4764', 'name': 'Sakat Sanala PO,Rajkot,Gujarat', 'lon': '70.7744'},
      '591308': {'lat': '15.928', 'name': 'Gokak Falls,Belagavi,Karnataka', 'lon': '74.9885'},
      '591309': {'lat': '15.8884', 'name': 'Madihali,Belagavi,Karnataka', 'lon': '74.8734'},
      '484660': {'lat': '23.629', 'name': 'Kaudiya,Shahdol,Madhya Pradesh', 'lon': '81.4728'},
      '591304': {'lat': '16.0559', 'name': 'Awarkhod,Belagavi,Karnataka', 'lon': '75.3293'},
      '591305': {'lat': '15.7478', 'name': 'Jaganur,Belagavi,Karnataka', 'lon': '75.1173'},
      '591306': {'lat': '15.8189', 'name': 'Badigwad,Belagavi,Karnataka', 'lon': '75.02'},
      '363641': {'lat': '22.5973', 'name': 'Ghunda (S),Rajkot,Gujarat', 'lon': '70.8981'},
      '591301': {'lat': '15.4691', 'name': 'Tavaragatti,Belagavi,Karnataka', 'lon': '74.5191'},
      '591302': {'lat': '15.6025', 'name': 'Topinkatti,Belagavi,Karnataka', 'lon': '74.4599'},
      '591303': {'lat': '16.6679', 'name': 'Ainapur,Belagavi,Karnataka', 'lon': '74.8953'},
      '321204': {'lat': '27.7005', 'name': 'Pathroli,Bharatpur,Rajasthan', 'lon': '77.0691'},
      '321205': {'lat': '27.4455', 'name': 'Poonchhari,Bharatpur,Rajasthan', 'lon': '76.9198'},
      '321206': {'lat': '27.1367', 'name': 'Jatoli Thoon,Bharatpur,Rajasthan', 'lon': '77.4502'},
      '762110': {'lat': '20.1646', 'name': 'Sarkipada,Kandhamal,Odisha', 'lon': '83.8861'},
      '415518': {'lat': '17.5477', 'name': 'Venegaon,Satara,Maharashtra', 'lon': '74.065'},
      '415519': {'lat': '17.5299', 'name': 'Bharatgaon Wadi,Satara,Maharashtra', 'lon': '74.068'},
      '415516': {'lat': '17.9871', 'name': 'Kavathe,Satara,Maharashtra', 'lon': '74.5224'},
      '415517': {'lat': '17.9871', 'name': 'Surur,Satara,Maharashtra', 'lon': '74.5224'},
      '415514': {'lat': '18.0553', 'name': 'Hateghar,Satara,Maharashtra', 'lon': '74.5306'},
      '415515': {'lat': '18.3756', 'name': 'Bhuinj,Satara,Maharashtra', 'lon': '75.2317'},
      '415512': {'lat': '17.9871', 'name': 'Rajachekurale,Satara,Maharashtra', 'lon': '74.5224'},
      '415513': {'lat': '17.9871', 'name': 'Udatare,Satara,Maharashtra', 'lon': '74.5224'},
      '415510': {'lat': '17.5407', 'name': 'Nandoshi,Satara,Maharashtra', 'lon': '74.3342'},
      '415511': {'lat': '18.2824', 'name': 'Ambheri,Satara,Maharashtra', 'lon': '74.6499'},
      '600028': {'lat': '13.025', 'name': 'Ramakrishna Nagar (Chennai),Chennai,Tamil Nadu', 'lon': '80.2575'},
      '272189': {'lat': '26.9979', 'name': 'Bhandariamanik Ganj,Siddharthnagar,Uttar Pradesh', 'lon': '82.8393'},
      '285205': {'lat': '25.919', 'name': 'Lohagarh,Jhansi,Uttar Pradesh', 'lon': '78.9877'},
      '600022': {'lat': '13.0484', 'name': 'Rajbhavan (Chennai),Chennai,Tamil Nadu', 'lon': '80.2473'},
      '600023': {'lat': '13.0484', 'name': 'Aynavaram,Chennai,Tamil Nadu', 'lon': '80.2473'},
      '600020': {'lat': '12.9967', 'name': 'Kasturibai Nagar,Chennai,Tamil Nadu', 'lon': '80.2603'},
      '600021': {'lat': '13.121', 'name': 'Washermanpet East,Chennai,Tamil Nadu', 'lon': '80.285'},
      '600026': {'lat': '13.0511', 'name': 'Vadapalani,Chennai,Tamil Nadu', 'lon': '80.2125'},
      '272181': {'lat': '26.4565', 'name': 'Itwakungai,Basti,Uttar Pradesh', 'lon': '82.6766'},
      '600024': {'lat': '13.0481', 'name': 'Kodambakkam,Chennai,Tamil Nadu', 'lon': '80.2214'},
      '600025': {'lat': '13.0484', 'name': 'Engineering College (Chennai),Chennai,Tamil Nadu', 'lon': '80.2473'},
      '413208': {'lat': '17.9106', 'name': 'Bhutashte,Solapur,Maharashtra', 'lon': '75.6208'},
      '517101': {'lat': '13.7231', 'name': 'Chandragiri H.O,Chittoor,Andhra Pradesh', 'lon': '79.4379'},
      '517102': {'lat': '13.7231', 'name': 'Buchinaidupalle,Chittoor,Andhra Pradesh', 'lon': '79.4379'},
      '743126': {'lat': '22.8119', 'name': 'Kushdanga,North 24 Parganas,West Bengal', 'lon': '88.5798'},
      '691322': {'lat': '8.9453', 'name': 'Kottavattom,Kollam,Kerala', 'lon': '76.9343'},
      '743123': {'lat': '22.8664', 'name': 'Muktarpur,North 24 Parganas,West Bengal', 'lon': '88.4011'},
      '743122': {'lat': '22.8347', 'name': 'Bengal Enamel,North 24 Parganas,West Bengal', 'lon': '88.4655'},
      '695121': {'lat': '8.6592', 'name': 'Neyyattinkara Town,Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '413201': {'lat': '18.9777', 'name': 'Nimbodi,Ahmed Nagar,Maharashtra', 'lon': '75.8513'},
      '695123': {'lat': '8.6592', 'name': 'Kodungavila,Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '695122': {'lat': '8.6592', 'name': 'Plamootukada,Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '695125': {'lat': '8.6592', 'name': 'Vazhichal,Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '695124': {'lat': '8.6592', 'name': 'Kottackal (TVM),Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '413206': {'lat': '18.7996', 'name': 'Washimbe,Solapur,Maharashtra', 'lon': '75.555'},
      '695126': {'lat': '8.6592', 'name': 'Aruvipuram,Thiruvananthapuram,Kerala', 'lon': '76.838'},
      '502371': {'lat': '17.1989', 'name': 'Masanpally,Medak,Telangana', 'lon': '77.9803'},
      '388225': {'lat': '22.9465', 'name': 'Sui,Kheda,Gujarat', 'lon': '73.2224'},
      '502372': {'lat': '18.3971', 'name': 'Yellareddypet,Medak,Telangana', 'lon': '79.4406'},
      '743133': {'lat': '22.8211', 'name': 'Garulia,North 24 Parganas,West Bengal', 'lon': '88.3744'},
      '630309': {'lat': '10.3387', 'name': 'Dharmapatti,Sivaganga,Tamil Nadu', 'lon': '78.4132'},
      '388220': {'lat': '22.9407', 'name': 'Vinzol,Kheda,Gujarat', 'lon': '73.4051'},
      '629702': {'lat': '8.0997', 'name': 'Vivekanandapuram,Kanyakumari,Tamil Nadu', 'lon': '77.5411'},
      '630305': {'lat': '9.9906', 'name': 'A.Siruvayal,Sivaganga,Tamil Nadu', 'lon': '78.6873'},
      '630306': {'lat': '10.1639', 'name': 'Visalayankottai,Sivaganga,Tamil Nadu', 'lon': '78.5877'},
      '676309': {'lat': '11.0775', 'name': 'Kodinhi,Malappuram,Kerala', 'lon': '75.9094'},
      '630301': {'lat': '10.1639', 'name': 'Amaravathipudur,Sivaganga,Tamil Nadu', 'lon': '78.5877'},
      '630302': {'lat': '10.1639', 'name': 'Devakottai Palace,Sivaganga,Tamil Nadu', 'lon': '78.5877'},
      '581403': {'lat': '14.6008', 'name': 'Gandhinagar,Uttara Kannada,Karnataka', 'lon': '74.7593'},
      '272207': {'lat': '26.5586', 'name': 'Tetari Bazar,Siddharthnagar,Uttar Pradesh', 'lon': '83.1349'},
      '485772': {'lat': '24.0954', 'name': 'Latagaon,Satna,Madhya Pradesh', 'lon': '80.8438'},
      '224168': {'lat': '26.075', 'name': 'Adampur Tiloni,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5232'},
      '471301': {'lat': '24.6994', 'name': 'Gulganj,Chhatarpur,Madhya Pradesh', 'lon': '79.3715'},
      '173201': {'lat': '30.9014', 'name': 'Garkhal,Solan,Himachal Pradesh', 'lon': '76.9819'},
      '283206': {'lat': '27.2488', 'name': 'Bartara,Firozabad,Uttar Pradesh', 'lon': '78.4353'},
      '523303': {'lat': '16.0287', 'name': 'Kommalapadu,Prakasam,Andhra Pradesh', 'lon': '79.9441'},
      '173202': {'lat': '30.8997', 'name': 'Sanawar,Solan,Himachal Pradesh', 'lon': '76.9945'},
      '173205': {'lat': '31.0394', 'name': 'Haripur Sandoli,Solan,Himachal Pradesh', 'lon': '76.7941'},
      '173204': {'lat': '30.8986', 'name': 'KASAULI M T,Solan,Himachal Pradesh', 'lon': '76.9659'},
      '173207': {'lat': '30.9272', 'name': 'Brauri,Solan,Himachal Pradesh', 'lon': '77.1624'},
      '173206': {'lat': '30.9743', 'name': 'Kakkar Hatti,Solan,Himachal Pradesh', 'lon': '76.9776'},
      '173209': {'lat': '30.9143', 'name': 'Dharampur (Solan),Solan,Himachal Pradesh', 'lon': '77.0273'},
      '173208': {'lat': '31.2071', 'name': 'Batal,Solan,Himachal Pradesh', 'lon': '76.8999'},
      '394530': {'lat': '21.9507', 'name': 'Delasa,Surat,Gujarat', 'lon': '73.0107'},
      '689102': {'lat': '8.9263', 'name': 'Venpala,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689103': {'lat': '8.9263', 'name': 'Kuttapuzha,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '627856': {'lat': '9.0336', 'name': 'Sambavarvadakarai,Tirunelveli,Tamil Nadu', 'lon': '77.388'},
      '689101': {'lat': '8.9263', 'name': 'Tiruvalla H.O,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689106': {'lat': '8.9263', 'name': 'Thengeli,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689107': {'lat': '8.9263', 'name': 'Perumthuruthy,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689104': {'lat': '8.9263', 'name': 'Valanjavattom East,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689105': {'lat': '8.9263', 'name': 'Kattode,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689108': {'lat': '8.9263', 'name': 'Peringara,Pathanamthitta,Kerala', 'lon': '76.7973'},
      '689109': {'lat': '8.9263', 'name': 'Eramallikkara,Alappuzha,Kerala', 'lon': '76.7973'},
      '365541': {'lat': '21.6823', 'name': 'Kovaya,Amreli,Gujarat', 'lon': '71.3701'},
      '365540': {'lat': '21.6823', 'name': 'Lonhtpur,Amreli,Gujarat', 'lon': '71.3701'},
      '274305': {'lat': '26.9211', 'name': 'Sidhawey,Kushinagar,Uttar Pradesh', 'lon': '83.5798'},
      '285206': {'lat': '26.0373', 'name': 'Gaindoli,Jalaun,Uttar Pradesh', 'lon': '79.4576'},
      '735132': {'lat': '26.413', 'name': 'Garalbari,Jalpaiguri,West Bengal', 'lon': '88.7056'},
      '462024': {'lat': '24.1137', 'name': 'H.E. Hospital,Bhopal,Madhya Pradesh', 'lon': '77.8846'},
      '370105': {'lat': '23.1847', 'name': 'Padhar,Kachchh,Gujarat', 'lon': '69.81'},
      '676552': {'lat': '10.9054', 'name': 'Edayur North,Malappuram,Kerala', 'lon': '76.098'},
      '734427': {'lat': '26.6827', 'name': 'Bhajanpur Jote,Darjiling,West Bengal', 'lon': '88.22'},
      '131027': {'lat': '28.8392', 'name': 'Jainpur,Sonipat,Haryana', 'lon': '76.7864'},
      '144703': {'lat': '31.0648', 'name': 'Nawan Qilla,Jalandhar,Punjab', 'lon': '75.3578'},
      '131024': {'lat': '29.0833', 'name': 'Sitawali,Sonipat,Haryana', 'lon': '76.8791'},
      '131023': {'lat': '28.9536', 'name': 'Jhundpur,Sonipat,Haryana', 'lon': '77.1692'},
      '131022': {'lat': '28.9972', 'name': 'Tihar Bagru,Sonipat,Haryana', 'lon': '76.9011'},
      '131021': {'lat': '29.1124', 'name': 'Khewra,Sonipat,Haryana', 'lon': '76.7276'},
      '144532': {'lat': '31.1091', 'name': 'Panam,Nawanshahr,Punjab', 'lon': '76.156'},
      '131029': {'lat': '29.0617', 'name': 'Jatheri,Sonipat,Haryana', 'lon': '76.8795'},
      '131028': {'lat': '28.8664', 'name': 'Industrial Estate Kundli,Sonipat,Haryana', 'lon': '77.1436'},
      '246422': {'lat': '30.7321', 'name': 'Badrinath Seasonal,Chamoli,Uttarakhand', 'lon': '79.5054'},
      '713502': {'lat': '24.2174', 'name': 'Bera,Purba Bardhaman,West Bengal', 'lon': '87.9944'},
      '804420': {'lat': '25.1566', 'name': 'Tej Bigha,Jehanabad,Bihar', 'lon': '85.0466'},
      '804421': {'lat': '25.0725', 'name': 'Ratni Bazar,Jehanabad,Bihar', 'lon': '84.7993'},
      '804422': {'lat': '25.2556', 'name': 'Makhdumpur,Jehanabad,Bihar', 'lon': '84.9532'},
      '335062': {'lat': '29.9168', 'name': 'Kishanpura Utrada,Ganganagar,Rajasthan', 'lon': '74.3053'},
      '804424': {'lat': '25.0406', 'name': 'Main,Jehanabad,Bihar', 'lon': '84.9345'},
      '804425': {'lat': '25.1529', 'name': 'Pandoul,Jehanabad,Bihar', 'lon': '84.8596'},
      '804426': {'lat': '25.3417', 'name': 'Aiara,Jehanabad,Bihar', 'lon': '85.1093'},
      '804427': {'lat': '25.3551', 'name': 'Kurtha Bazar,Arwal,Bihar', 'lon': '84.9611'},
      '804428': {'lat': '25.1566', 'name': 'Belkhara,Jehanabad,Bihar', 'lon': '85.0301'},
      '804429': {'lat': '25.205', 'name': 'Koshma,Jehanabad,Bihar', 'lon': '84.895'},
      '516505': {'lat': '14.3071', 'name': 'T.Sallagarigela,Cuddapah,Andhra Pradesh', 'lon': '79.25'},
      '516504': {'lat': '14.0774', 'name': 'Saraswatipalli,Cuddapah,Andhra Pradesh', 'lon': '78.8424'},
      '516503': {'lat': '14.8318', 'name': 'T.Soudarivaripalli,Cuddapah,Andhra Pradesh', 'lon': '78.9087'},
      '516502': {'lat': '14.5533', 'name': 'Thiruvengalapuram,Cuddapah,Andhra Pradesh', 'lon': '79.0052'},
      '516501': {'lat': '14.4877', 'name': 'Akkireddygaripalli,Cuddapah,Andhra Pradesh', 'lon': '79.0833'},
      '333035': {'lat': '28.2905', 'name': 'Kherala,Jhujhunu,Rajasthan', 'lon': '75.5797'},
      '226026': {'lat': '26.849', 'name': 'Guramba,Lucknow,Uttar Pradesh', 'lon': '81.144'},
      '758034': {'lat': '21.6334', 'name': 'Bichakundi,Kendujhar,Odisha', 'lon': '85.5162'},
      '521190': {'lat': '16.868', 'name': 'Gummadidurru,Krishna,Andhra Pradesh', 'lon': '80.2953'},
      '226027': {'lat': '26.8717', 'name': 'U P Governer Camp,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '783323': {'lat': '26.3513', 'name': 'Aironjongla,Dhubri,Assam', 'lon': '90.3634'},
      '631302': {'lat': '13.1448', 'name': 'Rangapuram,Tiruvallur,Tamil Nadu', 'lon': '79.3977'},
      '631301': {'lat': '13.1456', 'name': 'Kadananagaram,Tiruvallur,Tamil Nadu', 'lon': '79.3701'},
      '783325': {'lat': '26.3513', 'name': 'Falimari  Pt-i,Dhubri,Assam', 'lon': '90.3634'},
      '783324': {'lat': '26.3513', 'name': 'Dharmasala,Dhubri,Assam', 'lon': '90.3634'},
      '383350': {'lat': '23.5037', 'name': 'Iploda,Sabarkantha,Gujarat', 'lon': '73.542'},
      '383355': {'lat': '23.5863', 'name': 'Napda,Sabarkantha,Gujarat', 'lon': '73.5829'},
      '226022': {'lat': '26.8717', 'name': 'Kalyanpur (Lucknow),Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '505211': {'lat': '18.8095', 'name': 'Gv8incline,Karim Nagar,Telangana', 'lon': '79.3808'},
      '643253': {'lat': '11.4412', 'name': 'Kolapalli,Nilgiris,Tamil Nadu', 'lon': '76.7277'},
      '505215': {'lat': '18.8095', 'name': 'Jyothinagar (Karim Nagar),Karim Nagar,Telangana', 'lon': '79.3808'},
      '226023': {'lat': '26.8717', 'name': '32 Bn PAC ,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '385210': {'lat': '23.865', 'name': 'Pirojpura,Banaskantha,Gujarat', 'lon': '72.5427'},
      '827015': {'lat': '23.709', 'name': 'Chira Chas,Bokaro,Jharkhand', 'lon': '86.1468'},
      '782450': {'lat': '26.1303', 'name': 'Baithalangsho,Karbi Anglon,Assam', 'lon': '92.741'},
      '226021': {'lat': '26.8717', 'name': 'Batha Sabauli,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '225208': {'lat': '26.9983', 'name': 'Puremanbhawan Singh,Barabanki,Uttar Pradesh', 'lon': '81.2161'},
      '342901': {'lat': '26.7949', 'name': 'Asop,Jodhpur,Rajasthan', 'lon': '73.5824'},
      '522435': {'lat': '15.744', 'name': 'Machavaram (Narasaraopet),Guntur,Andhra Pradesh', 'lon': '79.9869'},
      '522436': {'lat': '16.0629', 'name': 'Attaluru,Guntur,Andhra Pradesh', 'lon': '79.7053'},
      '522437': {'lat': '16.495', 'name': 'Veerapuram,Guntur,Andhra Pradesh', 'lon': '79.8817'},
      '522438': {'lat': '16.3422', 'name': 'Visadala,Guntur,Andhra Pradesh', 'lon': '80.2367'},
      '522439': {'lat': '16.5619', 'name': 'Vijayapuri South,Guntur,Andhra Pradesh', 'lon': '79.3113'},
      '483504': {'lat': '23.8167', 'name': 'Katni Cement Factory,Katni,Madhya Pradesh', 'lon': '80.3029'},
      '247452': {'lat': '29.2494', 'name': 'Tikrol,Saharanpur,Uttar Pradesh', 'lon': '77.9249'},
      '483501': {'lat': '23.8167', 'name': 'Kailwara Khurd,Katni,Madhya Pradesh', 'lon': '80.3029'},
      '827012': {'lat': '23.709', 'name': 'Marafari Colony,Bokaro,Jharkhand', 'lon': '86.1468'},
      '521225': {'lat': '16.5412', 'name': 'Gollapudi,Krishna,Andhra Pradesh', 'lon': '80.5784'},
      '521226': {'lat': '16.9835', 'name': 'Ramachandrapuram,Krishna,Andhra Pradesh', 'lon': '80.7319'},
      '521227': {'lat': '16.7257', 'name': 'Koduru,Krishna,Andhra Pradesh', 'lon': '80.6801'},
      '521228': {'lat': '16.6023', 'name': 'Ilaprolu,Krishna,Andhra Pradesh', 'lon': '80.5588'},
      '521229': {'lat': '16.7142', 'name': 'G Konduru,Krishna,Andhra Pradesh', 'lon': '80.5606'},
      '625704': {'lat': '9.6244', 'name': 'T.Pudupatti,Madurai,Tamil Nadu', 'lon': '78.1451'},
      '625705': {'lat': '9.8274', 'name': 'Thullukuttinayakanur,Theni,Tamil Nadu', 'lon': '77.8772'},
      '625702': {'lat': '9.3045', 'name': 'Jari Usilampatti,Madurai,Tamil Nadu', 'lon': '77.6756'},
      '625703': {'lat': '9.915', 'name': 'Periyapoolampatti,Madurai,Tamil Nadu', 'lon': '77.7651'},
      '342902': {'lat': '26.6863', 'name': 'Basni Sejan,Nagaur,Rajasthan', 'lon': '73.723'},
      '670721': {'lat': '11.7401', 'name': 'Nadavayal,Wayanad,Kerala', 'lon': '76.0737'},
      '845457': {'lat': '26.083', 'name': 'Patahi (East Champaran),East Champaran,Bihar', 'lon': '85.689'},
      '177007': {'lat': '31.6861825', 'name': 'Chabutra,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5213012'},
      '177006': {'lat': '31.6556898', 'name': 'Kashmir,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4334777'},
      '177005': {'lat': '31.7084', 'name': 'NIT Hamirpur,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5274'},
      '500055': {'lat': '17.3724', 'name': 'Gajularamaram,Hyderabad,Telangana', 'lon': '78.437'},
      '248158': {'lat': '30.5832', 'name': 'Bijau,Dehradun,Uttarakhand', 'lon': '77.9286'},
      '177001': {'lat': '31.6861815', 'name': 'Masiana,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5213022'},
      '827013': {'lat': '23.4972', 'name': 'Madhunia,Bokaro,Jharkhand', 'lon': '85.852'},
      '248159': {'lat': '30.4904', 'name': 'Chhibroo,Dehradun,Uttarakhand', 'lon': '77.7837'},
      '577601': {'lat': '14.486', 'name': 'Kunibelakere,Davangere,Karnataka', 'lon': '75.7889'},
      '244235': {'lat': '28.7991', 'name': 'Khadgujar,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.1994'},
      '275204': {'lat': '25.7082', 'name': 'Akbarpur,Ghazipur,Uttar Pradesh', 'lon': '83.2812'},
      '735134': {'lat': '26.5561', 'name': 'Fatapukur,Jalpaiguri,West Bengal', 'lon': '88.5086'},
      '244245': {'lat': '28.6822', 'name': 'Kundan Nagar,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.3158'},
      '244242': {'lat': '28.6778', 'name': 'Lohari Bhoor,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.3725'},
      '495442': {'lat': '22.1674', 'name': 'Selar,Bilaspur(CGH),Chattisgarh', 'lon': '82.2298'},
      '141106': {'lat': '30.9228', 'name': 'Halwara Aerodrome,Ludhiana,Punjab', 'lon': '75.6892'},
      '623708': {'lat': '9.3571', 'name': 'Peraiyur (Ramanathapuram),Ramanathapuram,Tamil Nadu', 'lon': '78.4494'},
      '504292': {'lat': '19.343', 'name': 'Kondampally,Adilabad,Telangana', 'lon': '78.9588'},
      '504293': {'lat': '19.2026', 'name': 'Movad,Adilabad,Telangana', 'lon': '79.0162'},
      '504294': {'lat': '19.3366', 'name': 'Devaiguda,Adilabad,Telangana', 'lon': '79.263'},
      '262580': {'lat': '29.4858', 'name': 'Dholigaon,Nainital,Uttarakhand', 'lon': '79.6693'},
      '504296': {'lat': '18.8274', 'name': 'Sirpur Khagaznagar,Adilabad,Telangana', 'lon': '79.2002'},
      '612503': {'lat': '11.1248', 'name': 'Atthiyur,Thanjavur,Tamil Nadu', 'lon': '79.3751'},
      '623701': {'lat': '9.5282', 'name': 'Urapuli,Ramanathapuram,Tamil Nadu', 'lon': '78.6143'},
      '623703': {'lat': '9.3962', 'name': 'Saveriyarpattinam,Ramanathapuram,Tamil Nadu', 'lon': '78.432'},
      '623704': {'lat': '9.3246', 'name': 'Chithrankudi,Ramanathapuram,Tamil Nadu', 'lon': '78.5401'},
      '623705': {'lat': '9.6612', 'name': 'P.Sirahikottai,Ramanathapuram,Tamil Nadu', 'lon': '78.586'},
      '623706': {'lat': '9.7088', 'name': 'Vilathur,Ramanathapuram,Tamil Nadu', 'lon': '78.3607'},
      '623707': {'lat': '9.5066', 'name': 'Paramakudi H.O,Ramanathapuram,Tamil Nadu', 'lon': '78.499'},
      '759118': {'lat': '21.430975', 'name': 'Odasha,Angul,Odisha', 'lon': '85.196001'},
      '487881': {'lat': '22.8399', 'name': 'Barhabada,Narsinghpur,Madhya Pradesh', 'lon': '79.1979'},
      '505184': {'lat': '18.5497', 'name': 'Puttapaka,Karim Nagar,Telangana', 'lon': '79.2409'},
      '494450': {'lat': '19.0301', 'name': 'Pharsegarh,Bijapur(CGH),Chattisgarh', 'lon': '80.6614'},
      '509349': {'lat': '16.7778', 'name': 'Sarvareddypally,Mahabub Nagar,Telangana', 'lon': '79.1268'},
      '509375': {'lat': '16.6555', 'name': 'Bommanpally,Mahabub Nagar,Telangana', 'lon': '78.874'},
      '508211': {'lat': '17.3799', 'name': 'Thungathurthy,Nalgonda,Telangana', 'lon': '79.3379'},
      '203391': {'lat': '28.3576', 'name': 'Rewara,Bulandshahr,Uttar Pradesh', 'lon': '78.0123'},
      '141108': {'lat': '30.9684', 'name': 'Andlu,Ludhiana,Punjab', 'lon': '75.5535'},
      '581211': {'lat': '14.6948', 'name': 'Konanatambagi,Haveri,Karnataka', 'lon': '75.7443'},
      '500080': {'lat': '17.2974', 'name': 'Gandhinagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.3132'},
      '800021': {'lat': '25.5904', 'name': 'Vidyut Parisad,Patna,Bihar', 'lon': '85.1624'},
      '600043': {'lat': '12.968', 'name': 'Rajajinagar (Kanchipuram),Kanchipuram,Tamil Nadu', 'lon': '80.1502'},
      '460110': {'lat': '21.7421', 'name': 'Tembhurni,Betul,Madhya Pradesh', 'lon': '77.7709'},
      '627356': {'lat': '8.6837', 'name': 'Kila Omanallur,Tirunelveli,Tamil Nadu', 'lon': '77.6939'},
      '491001': {'lat': '21.2518', 'name': 'Purda,Durg,Chattisgarh', 'lon': '81.2405'},
      '476221': {'lat': '26.2688', 'name': 'Bilgoun,Morena,Madhya Pradesh', 'lon': '77.8949'},
      '494635': {'lat': '20.3475', 'name': 'Karmoti,Kanker,Chattisgarh', 'lon': '80.9878'},
      '712223': {'lat': '23.2149', 'name': 'Nasibpur,Hooghly,West Bengal', 'lon': '88.3052'},
      '509002': {'lat': '16.7915', 'name': 'Palkonda,Mahabub Nagar,Telangana', 'lon': '77.9797'},
      '508218': {'lat': '16.9055', 'name': 'Bothalapalem,Nalgonda,Telangana', 'lon': '79.7295'},
      '509001': {'lat': '16.7915', 'name': 'Pathapalamur,Mahabub Nagar,Telangana', 'lon': '77.9797'},
      '800022': {'lat': '25.5904', 'name': 'B.G. Camp/raj Bhawan,Patna,Bihar', 'lon': '85.1624'},
      '759116': {'lat': '20.97585', 'name': 'Balanda,Angul,Odisha', 'lon': '85.11205'},
      '638106': {'lat': '11.1779', 'name': 'Erasinampalayam,Erode,Tamil Nadu', 'lon': '77.9112'},
      '638107': {'lat': '11.3299', 'name': 'Vilarasampatti,Erode,Tamil Nadu', 'lon': '77.6332'},
      '638104': {'lat': '10.9449', 'name': 'Komarayeevalasu,Erode,Tamil Nadu', 'lon': '77.8996'},
      '638105': {'lat': '11.0069', 'name': 'Vellierichal,Erode,Tamil Nadu', 'lon': '77.7555'},
      '638102': {'lat': '11.3946', 'name': 'Vadakuthaiyurpalayam,Erode,Tamil Nadu', 'lon': '77.6588'},
      '638103': {'lat': '10.5391', 'name': 'Merkupathi,Coimbatore,Tamil Nadu', 'lon': '77.4524'},
      '638101': {'lat': '11.1518', 'name': 'Palliyuthu,Erode,Tamil Nadu', 'lon': '77.7375'},
      '135101': {'lat': '30.2184', 'name': 'Khadri,Yamuna Nagar,Haryana', 'lon': '77.2126'},
      '135102': {'lat': '30.1818', 'name': 'Mughalwali,Yamuna Nagar,Haryana', 'lon': '77.1913'},
      '135103': {'lat': '30.2986', 'name': 'Lalheri Kalan,Yamuna Nagar,Haryana', 'lon': '77.4034'},
      '485221': {'lat': '24.7814', 'name': 'Majhgawa,Satna,Madhya Pradesh', 'lon': '80.5692'},
      '413582': {'lat': '18.3727', 'name': 'Upla (M),Osmanabad,Maharashtra', 'lon': '75.523'},
      '135106': {'lat': '30.2602', 'name': 'Malikpur Khadar,Yamuna Nagar,Haryana', 'lon': '77.4413'},
      '638109': {'lat': '11.134', 'name': 'Minnapalayam,Erode,Tamil Nadu', 'lon': '77.7866'},
      '800023': {'lat': '25.5904', 'name': 'L.B.S Nagar,Patna,Bihar', 'lon': '85.1624'},
      '314011': {'lat': '23.9071', 'name': 'Gamdi Deval,Dungarpur,Rajasthan', 'lon': '73.7263'},
      '766108': {'lat': '20.3906', 'name': 'Gochhaguda,Nuapada,Odisha', 'lon': '82.7233'},
      '392025': {'lat': '21.8817', 'name': 'Kurchan,Bharuch,Gujarat', 'lon': '72.8992'},
      '392020': {'lat': '22.6972', 'name': 'Dayadra,Bharuch,Gujarat', 'lon': '72.8982'},
      '506222': {'lat': '17.588', 'name': 'Mutharam,Warangal,Telangana', 'lon': '79.4533'},
      '766102': {'lat': '20.2398', 'name': 'Regeda,Kalahandi,Odisha', 'lon': '83.2569'},
      '766103': {'lat': '20.5338', 'name': 'Gotamunda,Kalahandi,Odisha', 'lon': '83.4757'},
      '766100': {'lat': '20.3245', 'name': 'Sergarh,Kalahandi,Odisha', 'lon': '83.1189'},
      '766101': {'lat': '20.5373', 'name': 'Gigina,Kalahandi,Odisha', 'lon': '82.8945'},
      '766106': {'lat': '20.2869', 'name': 'Michhapali,Nuapada,Odisha', 'lon': '82.7797'},
      '766107': {'lat': '20.6196', 'name': 'Nehena,Kalahandi,Odisha', 'lon': '83.3929'},
      '766104': {'lat': '20.9083', 'name': 'Jenjera,Nuapada,Odisha', 'lon': '82.5839'},
      '766105': {'lat': '20.7328', 'name': 'Kutenchuan,Nuapada,Odisha', 'lon': '82.591'},
      '462002': {'lat': '25.0345', 'name': 'Vidya Vihar,Bhopal,Madhya Pradesh', 'lon': '81.703'},
      '311806': {'lat': '25.1321', 'name': 'Negdia Ka Khera,Bhilwara,Rajasthan', 'lon': '74.214'},
      '627953': {'lat': '8.9619', 'name': 'Marukkalankulam,Tirunelveli,Tamil Nadu', 'lon': '77.6356'},
      '813102': {'lat': '24.8537', 'name': 'Chutia,Banka,Bihar', 'lon': '86.8409'},
      '387210': {'lat': '23.2676', 'name': 'Dethli,Kheda,Gujarat', 'lon': '72.8698'},
      '311801': {'lat': '25.2446', 'name': 'Ashaholi,Bhilwara,Rajasthan', 'lon': '74.3658'},
      '800024': {'lat': '25.5904', 'name': 'Keshari Nagar,Patna,Bihar', 'lon': '85.1624'},
      '144701': {'lat': '31.4867', 'name': 'Muridwal,Jalandhar,Punjab', 'lon': '75.3601'},
      '458468': {'lat': '24.4109', 'name': 'Kesharpura,Neemuch,Madhya Pradesh', 'lon': '75.0287'},
      '673525': {'lat': '11.5742', 'name': 'Mennaniam,Kozhikode,Kerala', 'lon': '75.7537'},
      '673524': {'lat': '11.5886', 'name': 'Muyipoth,Kozhikode,Kerala', 'lon': '75.7007'},
      '673527': {'lat': '11.25', 'name': 'Kakkayam,Kozhikode,Kerala', 'lon': '75.8333'},
      '673526': {'lat': '11.5379', 'name': 'Chakkittapara,Kozhikode,Kerala', 'lon': '75.7975'},
      '673521': {'lat': '11.0479', 'name': 'Kottakkal-igl,Kozhikode,Kerala', 'lon': '75.872'},
      '311803': {'lat': '25.4868', 'name': 'Borana CO,Bhilwara,Rajasthan', 'lon': '74.1285'},
      '673523': {'lat': '11.5522', 'name': 'Iringath,Kozhikode,Kerala', 'lon': '75.6503'},
      '673522': {'lat': '11.0107', 'name': 'Kilur Meladi,Kozhikode,Kerala', 'lon': '75.9024'},
      '311802': {'lat': '25.385', 'name': 'Karoi CO,Bhilwara,Rajasthan', 'lon': '74.5308'},
      '673529': {'lat': '11.4909', 'name': 'Katalur,Kozhikode,Kerala', 'lon': '75.6474'},
      '673528': {'lat': '11.265', 'name': 'Poozhithode,Kozhikode,Kerala', 'lon': '76.2482'},
      '144603': {'lat': '31.3288', 'name': 'Wadala Kalan,Kapurthala,Punjab', 'lon': '75.4193'},
      '743338': {'lat': '22.1142', 'name': 'Japtala,South 24 Parganas,West Bengal', 'lon': '88.5318'},
      '824233': {'lat': '25.0266', 'name': 'Dehuri,Gaya,Bihar', 'lon': '85.2327'},
      '414501': {'lat': '19.0469', 'name': 'Shingave Keshav,Ahmed Nagar,Maharashtra', 'lon': '74.6015'},
      '462004': {'lat': '25.0345', 'name': 'M.P. Vidhan Sabha,Bhopal,Madhya Pradesh', 'lon': '81.703'},
      '824232': {'lat': '24.7016', 'name': 'Charokharigarh,Jehanabad,Bihar', 'lon': '85.0001'},
      '743330': {'lat': '22.2993', 'name': 'Kustia,South 24 Parganas,West Bengal', 'lon': '88.4489'},
      '743331': {'lat': '22.3145', 'name': 'Diamond Harbour CC,South 24 Parganas,West Bengal', 'lon': '88.6625'},
      '743332': {'lat': '22.6268', 'name': 'Barandari Bagaria,South 24 Parganas,West Bengal', 'lon': '88.2046'},
      '723129': {'lat': '23.6167', 'name': 'Dhabani Radhamohanpur,Purulia,West Bengal', 'lon': '87.224'},
      '824231': {'lat': '24.7498', 'name': 'Kendui,Gaya,Bihar', 'lon': '84.975'},
      '743336': {'lat': '22.2052', 'name': 'Bireswarpur,South 24 Parganas,West Bengal', 'lon': '88.2369'},
      '743337': {'lat': '22.2569', 'name': 'Chatra Maldari,South 24 Parganas,West Bengal', 'lon': '88.5694'},
      '413227': {'lat': '17.3746', 'name': 'Tolnur,Solapur,Maharashtra', 'lon': '76.2103'},
      '520015': {'lat': '16.5482', 'name': 'Patamata Lanka,Krishna,Andhra Pradesh', 'lon': '80.6492'},
      '493773': {'lat': '20.6966', 'name': 'Borsi,Dhamtari,Chattisgarh', 'lon': '81.713'},
      '520010': {'lat': '16.4933', 'name': 'Chandramoulipuram,Krishna,Andhra Pradesh', 'lon': '80.6612'},
      '520011': {'lat': '16.5233', 'name': 'Lakshminagar,Krishna,Andhra Pradesh', 'lon': '80.6278'},
      '520012': {'lat': '16.525', 'name': 'Jaccampudi,Krishna,Andhra Pradesh', 'lon': '80.6095'},
      '520013': {'lat': '16.5233', 'name': 'Krishnalanka,Krishna,Andhra Pradesh', 'lon': '80.6278'},
      '142021': {'lat': '30.8384', 'name': 'Pamal,Ludhiana,Punjab', 'lon': '75.7402'},
      '142023': {'lat': '30.8', 'name': 'Tuggal,Ludhiana,Punjab', 'lon': '75.5666'},
      '142022': {'lat': '30.8509', 'name': 'Hamaunpura,Ludhiana,Punjab', 'lon': '75.8048'},
      '142025': {'lat': '30.8773', 'name': 'Majri,Ludhiana,Punjab', 'lon': '75.6107'},
      '142024': {'lat': '30.903', 'name': 'Malak,Ludhiana,Punjab', 'lon': '75.5323'},
      '142027': {'lat': '30.8941', 'name': 'Ayali Kalan,Ludhiana,Punjab', 'lon': '75.7597'},
      '363410': {'lat': '22.927', 'name': 'Khandiya,Surendra Nagar,Gujarat', 'lon': '71.6246'},
      '142029': {'lat': '30.5641', 'name': 'Dholan Kalan,Ludhiana,Punjab', 'lon': '75.7083'},
      '413222': {'lat': '18.1742', 'name': 'Khuneshwar,Solapur,Maharashtra', 'lon': '76.0701'},
      '413223': {'lat': '18.1714', 'name': 'Upalwate,Solapur,Maharashtra', 'lon': '75.274'},
      '586205': {'lat': '17.3126', 'name': 'Shiradon,Vijayapura,Karnataka', 'lon': '75.6581'},
      '586204': {'lat': '17.0824', 'name': 'Taddewadi,Vijayapura,Karnataka', 'lon': '76.1657'},
      '586207': {'lat': '17.3227', 'name': 'Arjanal,Vijayapura,Karnataka', 'lon': '75.8601'},
      '586206': {'lat': '17.0492', 'name': 'Devar Navadagi,Vijayapura,Karnataka', 'lon': '76.4906'},
      '586201': {'lat': '16.7453', 'name': 'Almat R.S.,Vijayapura,Karnataka', 'lon': '76.3537'},
      '517128': {'lat': '13.1749', 'name': 'Madhavaram,Chittoor,Andhra Pradesh', 'lon': '79.0656'},
      '586203': {'lat': '16.6178', 'name': 'Ivanagi,Vijayapura,Karnataka', 'lon': '76.1602'},
      '586202': {'lat': '15.9615', 'name': 'Gundagi,Vijayapura,Karnataka', 'lon': '76.1881'},
      '413221': {'lat': '18.7325', 'name': 'Malkavathe,Solapur,Maharashtra', 'lon': '75.8246'},
      '586209': {'lat': '16.7048', 'name': 'Tadavalaga,Vijayapura,Karnataka', 'lon': '76.074'},
      '586208': {'lat': '16.5415', 'name': 'Hunshyal,Vijayapura,Karnataka', 'lon': '76.123'},
      '243506': {'lat': '27.9739', 'name': 'Shivpuri Raudhauli,Bareilly,Uttar Pradesh', 'lon': '79.8632'},
      '275103': {'lat': '26.9987', 'name': 'Raikwardeeh,Mau,Uttar Pradesh', 'lon': '81.0347'},
      '504313': {'lat': '19.384', 'name': 'Jamni,Adilabad,Telangana', 'lon': '78.9495'},
      '627358': {'lat': '9.0617', 'name': 'Tachanallur,Tirunelveli,Tamil Nadu', 'lon': '77.6974'},
      '226017': {'lat': '27.7397', 'name': 'Avas Vikas Colony (Lucknow),Lucknow,Uttar Pradesh', 'lon': '80.0975'},
      '226016': {'lat': '27.2179', 'name': 'Ghazipur (Lucknow),Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '226015': {'lat': '27.2179', 'name': 'Cimap,Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '226014': {'lat': '27.2179', 'name': 'Sgpgi,Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '627859': {'lat': '8.9918', 'name': 'E.Meenakshipuram,Tirunelveli,Tamil Nadu', 'lon': '77.4362'},
      '226012': {'lat': '27.2179', 'name': 'L D A Colony,Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '226011': {'lat': '27.2179', 'name': 'Manaknagar,Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '560023': {'lat': '13.2257', 'name': 'Magadi Road,Bengaluru,Karnataka', 'lon': '77.575'},
      '495220': {'lat': '22.1092', 'name': 'High Court Bilaspur,Bilaspur(CGH),Chattisgarh', 'lon': '82.0015'},
      '742160': {'lat': '23.9474', 'name': 'Nashipur Rajbati,Murshidabad,West Bengal', 'lon': '88.0423'},
      '226019': {'lat': '27.2179', 'name': 'Industria Area Chinhat,Lucknow,Uttar Pradesh', 'lon': '80.5504'},
      '226018': {'lat': '27.0205', 'name': 'Ganesh Ganj (Lucknow),Lucknow,Uttar Pradesh', 'lon': '80.573'},
      '416632': {'lat': '17.6688', 'name': 'Ghotage,Sindhudurg,Maharashtra', 'lon': '74.8524'},
      '735223': {'lat': '26.9954', 'name': 'Indong,Jalpaiguri,West Bengal', 'lon': '88.8173'},
      '416630': {'lat': '17.6688', 'name': 'Poyare,Sindhudurg,Maharashtra', 'lon': '74.8524'},
      '275102': {'lat': '25.7997', 'name': 'Mohammadpur Math,Mau,Uttar Pradesh', 'lon': '83.0682'},
      '391220': {'lat': '22.4151', 'name': 'Ruvand,Vadodara,Gujarat', 'lon': '73.1891'},
      '685591': {'lat': '9.6906', 'name': 'Arakulam,Idukki,Kerala', 'lon': '76.9977'},
      '685590': {'lat': '9.6906', 'name': 'Kanjar,Idukki,Kerala', 'lon': '76.9977'},
      '641687': {'lat': '11.0925', 'name': 'Iduvampalayam,Coimbatore,Tamil Nadu', 'lon': '77.3123'},
      '685595': {'lat': '9.6906', 'name': 'Amayapra,Idukki,Kerala', 'lon': '76.9977'},
      '504106': {'lat': '19.2088', 'name': 'Koutla (K),Adilabad,Telangana', 'lon': '78.7005'},
      '621702': {'lat': '10.8788', 'name': 'Konnagudi,Tiruchirappalli,Tamil Nadu', 'lon': '78.914'},
      '621703': {'lat': '10.8067', 'name': 'Sathamangalam,Tiruchirappalli,Tamil Nadu', 'lon': '78.8275'},
      '621701': {'lat': '11.0449', 'name': 'Govindaputhur,Ariyalur,Tamil Nadu', 'lon': '79.2557'},
      '605201': {'lat': '12.1134', 'name': 'V.Mathur,Villupuram,Tamil Nadu', 'lon': '79.3721'},
      '621707': {'lat': '11.0155', 'name': 'Edayathangudi,Ariyalur,Tamil Nadu', 'lon': '79.0817'},
      '605203': {'lat': '12.0861', 'name': 'Asokapuri,Villupuram,Tamil Nadu', 'lon': '79.4658'},
      '605202': {'lat': '12.078', 'name': 'Sangeethamangalam,Villupuram,Tamil Nadu', 'lon': '79.4073'},
      '621708': {'lat': '11.2383', 'name': 'Adhanur,Perambalur,Tamil Nadu', 'lon': '79.0047'},
      '621709': {'lat': '11.357', 'name': 'Solankudikadu,Ariyalur,Tamil Nadu', 'lon': '79.1606'},
      '481768': {'lat': '22.3481', 'name': 'Tatri,Mandla,Madhya Pradesh', 'lon': '80.3894'},
      '735226': {'lat': '26.8537', 'name': 'Panabasti,Alipurduar,West Bengal', 'lon': '89.0856'},
      '202143': {'lat': '28.112', 'name': 'Balwant Nagaria,Aligarh,Uttar Pradesh', 'lon': '78.0079'},
      '415724': {'lat': '16.4462', 'name': 'Pangari Tarf Velamb,Ratnagiri,Maharashtra', 'lon': '74.3163'},
      '415727': {'lat': '17.6465', 'name': 'Mumbake,Ratnagiri,Maharashtra', 'lon': '73.3831'},
      '415726': {'lat': '17.9621', 'name': 'Asore,Ratnagiri,Maharashtra', 'lon': '73.9375'},
      '415720': {'lat': '20.0159', 'name': 'Burondi,Ratnagiri,Maharashtra', 'lon': '74.0745'},
      '202145': {'lat': '27.7019', 'name': 'Maisubhasgram,Aligarh,Uttar Pradesh', 'lon': '77.8592'},
      '415722': {'lat': '18.0553', 'name': 'Avashi,Ratnagiri,Maharashtra', 'lon': '74.5306'},
      '243302': {'lat': '28.4154', 'name': 'Rajupur Kalan,Bareilly,Uttar Pradesh', 'lon': '79.1778'},
      '243303': {'lat': '28.4494', 'name': 'Baragoan,Bareilly,Uttar Pradesh', 'lon': '79.4223'},
      '502290': {'lat': '17.9362', 'name': 'Gottimukla,Medak,Telangana', 'lon': '77.801'},
      '311026': {'lat': '26.0488', 'name': 'Karjaliya,Bhilwara,Rajasthan', 'lon': '74.7935'},
      '415729': {'lat': '17.9621', 'name': 'Sakhari Agar,Ratnagiri,Maharashtra', 'lon': '73.9375'},
      '415728': {'lat': '17.9621', 'name': 'Hedavi,Ratnagiri,Maharashtra', 'lon': '73.9375'},
      '502294': {'lat': '17.6959', 'name': 'Manjira Barrage,Medak,Telangana', 'lon': '78.0374'},
      '502295': {'lat': '17.5693', 'name': 'Terpole,Medak,Telangana', 'lon': '78.1929'},
      '688523': {'lat': '10.1954', 'name': 'Mararikulam North BO,Alappuzha,Kerala', 'lon': '76.1692'},
      '688522': {'lat': '9.5705', 'name': 'Kattor Kalavoor BO,Alappuzha,Kerala', 'lon': '76.3276'},
      '688521': {'lat': '10.1954', 'name': 'Pathirappally,Alappuzha,Kerala', 'lon': '76.1692'},
      '688527': {'lat': '10.5079', 'name': 'Karikkad BO,Alappuzha,Kerala', 'lon': '76.0901'},
      '688526': {'lat': '10.1954', 'name': 'Panavally,Alappuzha,Kerala', 'lon': '76.1692'},
      '688525': {'lat': '10.1954', 'name': 'Muhamma,Alappuzha,Kerala', 'lon': '76.1692'},
      '688524': {'lat': '10.1954', 'name': 'Cherthala Cutcherry,Alappuzha,Kerala', 'lon': '76.1692'},
      '688529': {'lat': '10.1954', 'name': 'Kadakkarapally,Alappuzha,Kerala', 'lon': '76.1692'},
      '688528': {'lat': '10.1954', 'name': 'Thycattussery,Alappuzha,Kerala', 'lon': '76.1692'},
      '628622': {'lat': '8.5755', 'name': 'Cherakulam,Tuticorin,Tamil Nadu', 'lon': '77.8111'},
      '628623': {'lat': '8.6177', 'name': 'Kurukattur,Tuticorin,Tamil Nadu', 'lon': '77.9959'},
      '628620': {'lat': '8.6417', 'name': 'Parakkiramapandi,Tuticorin,Tamil Nadu', 'lon': '77.9468'},
      '628621': {'lat': '9.1675', 'name': 'Puthukudi,Tuticorin,Tamil Nadu', 'lon': '77.7382'},
      '788003': {'lat': '25.0165', 'name': 'Dudpatil,Cachar,Assam', 'lon': '92.549'},
      '788002': {'lat': '25.0165', 'name': 'Larsingpar,Cachar,Assam', 'lon': '92.549'},
      '788001': {'lat': '25.0165', 'name': 'Silchar Court,Cachar,Assam', 'lon': '92.549'},
      '854116': {'lat': '25.515', 'name': 'Bastaul,Katihar,Bihar', 'lon': '87.6761'},
      '788007': {'lat': '25.0165', 'name': 'Kanchanpur,Cachar,Assam', 'lon': '92.549'},
      '788006': {'lat': '25.0165', 'name': 'Sarbadaynagar,Cachar,Assam', 'lon': '92.549'},
      '788005': {'lat': '25.0165', 'name': 'Kathal TE,Cachar,Assam', 'lon': '92.549'},
      '788004': {'lat': '25.0165', 'name': 'G C College,Cachar,Assam', 'lon': '92.549'},
      '788009': {'lat': '25.0165', 'name': 'Nutan Dayapur,Cachar,Assam', 'lon': '92.549'},
      '737103': {'lat': '27.350584', 'name': 'Chongey,East Sikkim,Sikkim', 'lon': '88.6724569'},
      '711206': {'lat': '22.4233', 'name': 'Sapuipara,Howrah,West Bengal', 'lon': '88.312'},
      '854113': {'lat': '25.5292', 'name': 'B.Deora,Katihar,Bihar', 'lon': '87.5785'},
      '423501': {'lat': '20.4355', 'name': 'Pale BK,Nashik,Maharashtra', 'lon': '74.1537'},
      '848125': {'lat': '25.8916', 'name': 'Kalaunjar,Samastipur,Bihar', 'lon': '85.6643'},
      '613403': {'lat': '10.719', 'name': 'Ravusapatti,Thanjavur,Tamil Nadu', 'lon': '79.0619'},
      '423502': {'lat': '20.4478', 'name': 'Desgaon,Nashik,Maharashtra', 'lon': '74.2873'},
      '284002': {'lat': '25.4623', 'name': 'Bhojala,Jhansi,Uttar Pradesh', 'lon': '79.1116'},
      '284003': {'lat': '25.4701', 'name': 'Jhansi Railway Station,Jhansi,Uttar Pradesh', 'lon': '78.5378'},
      '361011': {'lat': '21.573', 'name': 'Hadiana,Jamnagar,Gujarat', 'lon': '70.0878'},
      '361010': {'lat': '22.3975', 'name': 'Zakhar,Jamnagar,Gujarat', 'lon': '69.7195'},
      '361013': {'lat': '22.7047', 'name': 'Matva,Jamnagar,Gujarat', 'lon': '74.014'},
      '361012': {'lat': '22.2251', 'name': 'Veraval Moti,Jamnagar,Gujarat', 'lon': '71.2738'},
      '632001': {'lat': '12.7779', 'name': 'Velapadi,Vellore,Tamil Nadu', 'lon': '79.1353'},
      '385510': {'lat': '24.2285', 'name': 'Bhakharmoti,Banaskantha,Gujarat', 'lon': '72.3186'},
      '385515': {'lat': '24.0995', 'name': 'Dalwada,Banaskantha,Gujarat', 'lon': '72.472'},
      '364005': {'lat': '21.7726', 'name': 'Bhavnagar Anandnagar,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '364004': {'lat': '21.7726', 'name': 'Nari,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '481556': {'lat': '22.1807', 'name': 'Bhikewada,Balaghat,Madhya Pradesh', 'lon': '80.3361'},
      '364006': {'lat': '21.7726', 'name': 'Bhavnagar Station Road,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '364001': {'lat': '21.7726', 'name': 'Bhavnagar Nirmalnagar,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '364003': {'lat': '21.7726', 'name': 'Bhavnagar Para,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '364002': {'lat': '21.7726', 'name': 'Bhavnagar Takhteshwar,Bhavnagar,Gujarat', 'lon': '72.0728'},
      '754145': {'lat': '20.7115', 'name': 'Ppl Township,Jagatsinghapur,Odisha', 'lon': '85.661'},
      '632002': {'lat': '12.6921', 'name': 'CMC (Vellore),Vellore,Tamil Nadu', 'lon': '79.2603'},
      '584203': {'lat': '16.016', 'name': 'Sunkeshwar,Raichur,Karnataka', 'lon': '77.1514'},
      '416403': {'lat': '17.1277', 'name': 'Dhalgaon,Sangli,Maharashtra', 'lon': '75.0266'},
      '490009': {'lat': '21.2724', 'name': 'Bhilai West,Durg,Chattisgarh', 'lon': '81.4358'},
      '626607': {'lat': '9.6588', 'name': 'Narikudi,Virudhunagar,Tamil Nadu', 'lon': '78.273'},
      '764056': {'lat': '19.8548', 'name': 'Sasahandi,Koraput,Odisha', 'lon': '83.4836'},
      '764057': {'lat': '19.3596', 'name': 'Kathargada,Koraput,Odisha', 'lon': '82.6092'},
      '416402': {'lat': '17.587', 'name': 'Baj,Sangli,Maharashtra', 'lon': '75.4517'},
      '764052': {'lat': '18.6956', 'name': 'Populuru,Malkangiri,Odisha', 'lon': '82.5323'},
      '570008': {'lat': '12.1624', 'name': 'Gujjegowdanapura,Mysuru,Karnataka', 'lon': '76.4954'},
      '570009': {'lat': '12.2371', 'name': 'Saraswathipuram H.O,Mysuru,Karnataka', 'lon': '76.5683'},
      '570006': {'lat': '12.2371', 'name': 'University Campus (Mysuru),Mysuru,Karnataka', 'lon': '76.5683'},
      '570007': {'lat': '12.2371', 'name': 'Narasimha Raja Mohalla,Mysuru,Karnataka', 'lon': '76.5683'},
      '570004': {'lat': '12.2667', 'name': 'Krishnamurthypuram,Mysuru,Karnataka', 'lon': '76.6833'},
      '570005': {'lat': '12.2371', 'name': 'Mysuru Law Courts,Mysuru,Karnataka', 'lon': '76.5683'},
      '570002': {'lat': '12.2371', 'name': 'Vani Vilas Mohalla,Mysuru,Karnataka', 'lon': '76.5683'},
      '570003': {'lat': '12.3718', 'name': 'Note Mudran Nagar,Mysuru,Karnataka', 'lon': '76.6566'},
      '764058': {'lat': '19.2467', 'name': 'Ghotarla,Koraput,Odisha', 'lon': '83.4058'},
      '570001': {'lat': '12.2371', 'name': 'Mysuru H.O,Mysuru,Karnataka', 'lon': '76.5683'},
      '143518': {'lat': '31.8711', 'name': 'Fazula Chak,Gurdaspur,Punjab', 'lon': '75.2845'},
      '143519': {'lat': '31.9539', 'name': 'Zaffarwal,Gurdaspur,Punjab', 'lon': '75.3028'},
      '271805': {'lat': '26.6227', 'name': 'Puredeen Namgarh,Shrawasti,Uttar Pradesh', 'lon': '83.179'},
      '143512': {'lat': '31.8252', 'name': 'Fattupur,Gurdaspur,Punjab', 'lon': '75.3742'},
      '560107': {'lat': '13.0907', 'name': 'Achitnagar,Bangalore Rural,Karnataka', 'lon': '77.6423'},
      '143511': {'lat': '31.8393', 'name': 'Akarpura,Gurdaspur,Punjab', 'lon': '75.2651'},
      '143516': {'lat': '31.7094', 'name': 'Kot Todarmal,Gurdaspur,Punjab', 'lon': '75.391'},
      '143517': {'lat': '31.8377', 'name': 'Nanowal Jinder,Gurdaspur,Punjab', 'lon': '75.5657'},
      '143514': {'lat': '31.6759', 'name': 'Villa Baijju,Gurdaspur,Punjab', 'lon': '75.3395'},
      '143515': {'lat': '31.6656', 'name': 'Srihargobindpur,Gurdaspur,Punjab', 'lon': '75.4456'},
      '441401': {'lat': '21.4208', 'name': 'Manegaon Tek,Nagpur,Maharashtra', 'lon': '79.3289'},
      '370640': {'lat': '23.2192', 'name': 'Moti Sindhodi,Kachchh,Gujarat', 'lon': '68.7149'},
      '370645': {'lat': '23.1348', 'name': 'Khirsara,Kachchh,Gujarat', 'lon': '68.9359'},
      '641015': {'lat': '11.0167', 'name': 'Uppilipalayam,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '769015': {'lat': '21.8782', 'name': 'Rourkela - 15,Sundergarh,Odisha', 'lon': '84.638'},
      '769014': {'lat': '21.8782', 'name': 'Rourkela - 14,Sundergarh,Odisha', 'lon': '84.638'},
      '803306': {'lat': '25.3694', 'name': 'Prahladpur,Patna,Bihar', 'lon': '85.9507'},
      '769016': {'lat': '21.8782', 'name': 'Rourkela -16,Sundergarh,Odisha', 'lon': '84.638'},
      '769011': {'lat': '21.8782', 'name': 'Rourkela - 11,Sundergarh,Odisha', 'lon': '84.638'},
      '769010': {'lat': '21.8782', 'name': 'Rourkela - 10,Sundergarh,Odisha', 'lon': '84.638'},
      '769013': {'lat': '21.8782', 'name': 'Rourkela - 13,Sundergarh,Odisha', 'lon': '84.638'},
      '769012': {'lat': '21.8782', 'name': 'Katchery Road,Sundergarh,Odisha', 'lon': '84.638'},
      '843115': {'lat': '25.63', 'name': 'Rampur Dayal,Muzaffarpur,Bihar', 'lon': '85.1452'},
      '136020': {'lat': '29.7137', 'name': 'Jajjanpur,Kaithal,Haryana', 'lon': '76.2531'},
      '382745': {'lat': '22.5276', 'name': 'Malvan(Jat),Surendra Nagar,Gujarat', 'lon': '72.4427'},
      '136026': {'lat': '29.7064', 'name': 'Habri,Kaithal,Haryana', 'lon': '76.6298'},
      '136027': {'lat': '29.7869', 'name': 'Patti Afgan,Kaithal,Haryana', 'lon': '76.389'},
      '382740': {'lat': '22.5276', 'name': 'Pansar,Gandhi Nagar,Gujarat', 'lon': '72.4427'},
      '792111': {'lat': '27.8341', 'name': 'Yealing,Lohit,Arunachal Pradesh', 'lon': '95.8779'},
      '799211': {'lat': '24.0447', 'name': 'Madhuchoudhurypara,West Tripura,Tripura', 'lon': '91.7553'},
      '767019': {'lat': '21.06864', 'name': 'Sankara,Sonapur,Odisha', 'lon': '83.83085'},
      '262547': {'lat': '29.8473', 'name': 'Jipti,Pithoragarh,Uttarakhand', 'lon': '80.5369'},
      '360490': {'lat': '22.004', 'name': 'Vadasada,Junagadh,Gujarat', 'lon': '70.7119'},
      '303121': {'lat': '27.0598', 'name': 'NIMS University,Jaipur,Rajasthan', 'lon': '75.4623'},
      '303120': {'lat': '27.1106', 'name': 'Tala,Jaipur,Rajasthan', 'lon': '75.8962'},
      '303123': {'lat': '27.0217', 'name': 'Dungrikalan,Jaipur,Rajasthan', 'lon': '75.1368'},
      '303122': {'lat': '27.0598', 'name': 'Vedaant Gyan Valley,Jaipur,Rajasthan', 'lon': '75.4623'},
      '502125': {'lat': '17.6975', 'name': 'Podichanpally,Medak,Telangana', 'lon': '78.2434'},
      '754142': {'lat': '20.3164', 'name': 'Barakolikhala,Jagatsinghapur,Odisha', 'lon': '86.6085'},
      '843118': {'lat': '25.5621', 'name': 'Godanpatti,Muzaffarpur,Bihar', 'lon': '85.5744'},
      '451221': {'lat': '22.1932732', 'name': 'Somakhedi,West Nimar,Madhya Pradesh', 'lon': '75.5878533'},
      '767018': {'lat': '20.8681652', 'name': 'Khandahata,Sonapur,Odisha', 'lon': '84.0458076'},
      '713102': {'lat': '24.4057', 'name': 'Jamar,Purba Bardhaman,West Bengal', 'lon': '88.0651'},
      '483990': {'lat': '23.9105', 'name': 'Rithi,Katni,Madhya Pradesh', 'lon': '80.1425'},
      '450114': {'lat': '22.2351', 'name': 'Indhawadi,East Nimar,Madhya Pradesh', 'lon': '76.3934'},
      '477117': {'lat': '26.434', 'name': 'Barehad,Bhind,Madhya Pradesh', 'lon': '78.3695'},
      '523114': {'lat': '15.0993', 'name': 'Rallapadu Project,Prakasam,Andhra Pradesh', 'lon': '79.7326'},
      '523115': {'lat': '14.5717', 'name': 'Annaboinapalli,Prakasam,Andhra Pradesh', 'lon': '79.8928'},
      '523116': {'lat': '15.6213', 'name': 'Ammapalem,Prakasam,Andhra Pradesh', 'lon': '79.5158'},
      '523117': {'lat': '14.9735', 'name': 'Chintagumpalli,Prakasam,Andhra Pradesh', 'lon': '79.4857'},
      '523110': {'lat': '14.8833', 'name': 'Khambaladinne,Prakasam,Andhra Pradesh', 'lon': '79.5754'},
      '523111': {'lat': '15.4473', 'name': 'K.Agraharam,Prakasam,Andhra Pradesh', 'lon': '79.7315'},
      '523112': {'lat': '15.2814', 'name': 'Ambavaram Kothapalli,Prakasam,Andhra Pradesh', 'lon': '79.2865'},
      '523113': {'lat': '15.1462', 'name': 'Sakhavaram,Prakasam,Andhra Pradesh', 'lon': '79.8128'},
      '581187': {'lat': '14.9985', 'name': 'Nujji,Uttara Kannada,Karnataka', 'lon': '74.4403'},
      '581186': {'lat': '15.4124', 'name': 'Hudsa,Uttara Kannada,Karnataka', 'lon': '74.4109'},
      '761109': {'lat': '19.9406', 'name': 'Rai Bandha,Ganjam,Odisha', 'lon': '84.8241'},
      '761108': {'lat': '19.9776', 'name': 'Badabadangi,Ganjam,Odisha', 'lon': '84.4602'},
      '759016': {'lat': '20.0642', 'name': 'Sorisiapada,Dhenkanal,Odisha', 'lon': '83.8359'},
      '759017': {'lat': '20.8667', 'name': 'Dhalapada,Dhenkanal,Odisha', 'lon': '85.3048'},
      '759014': {'lat': '20.8662', 'name': 'Mahimagadi,Dhenkanal,Odisha', 'lon': '85.2129'},
      '759015': {'lat': '20.4657', 'name': 'Kankadahad Sadar,Dhenkanal,Odisha', 'lon': '85.2154'},
      '759013': {'lat': '21.0239', 'name': 'Saptasajya,Dhenkanal,Odisha', 'lon': '85.1885'},
      '734015': {'lat': '26.7111', 'name': 'Fulbari Hat,Jalpaiguri,West Bengal', 'lon': '88.3462'},
      '734014': {'lat': '26.6993', 'name': 'Gossaipur,Darjiling,West Bengal', 'lon': '88.3118'},
      '402116': {'lat': '18.4337', 'name': 'Roha AV,Raigarh(MH),Maharashtra', 'lon': '73.1498'},
      '402117': {'lat': '18.2146', 'name': 'Morba,Raigarh(MH),Maharashtra', 'lon': '73.2500'},
      '402114': {'lat': '18.0008', 'name': 'Kolmandla,Raigarh(MH),Maharashtra', 'lon': '73.08064'},
      '402115': {'lat': '18.03035', 'name': 'Jui Budruk,Raigarh(MH),Maharashtra', 'lon': '73.3636'},
      '402112': {'lat': '18.237058', 'name': 'Wavediwali,Raigarh(MH),Maharashtra', 'lon': '73.283592'},
      '402113': {'lat': '18.392752', 'name': 'Walwati,Raigarh(MH),Maharashtra', 'lon': '72.964343'},
      '402110': {'lat': '18.0688329', 'name': 'Jasawali,Raigarh(MH),Maharashtra', 'lon': '73.0478987'},
      '402111': {'lat': '18.237055', 'name': 'Rahatad,Raigarh(MH),Maharashtra', 'lon': '73.283595'},
      '230127': {'lat': '25.8875', 'name': 'Dwarikapur,Pratapgarh,Uttar Pradesh', 'lon': '82.2675'},
      '562129': {'lat': '13.2953', 'name': 'Bendiganahalli,Bangalore Rural,Karnataka', 'lon': '77.7676'},
      '228118': {'lat': '26.1435', 'name': 'Kamla Nehru Institute,Sultanpur,Uttar Pradesh', 'lon': '81.9422'},
      '228119': {'lat': '26.1435', 'name': 'Madhavpur Chhatauna,Sultanpur,Uttar Pradesh', 'lon': '81.9422'},
      '360040': {'lat': '21.9843', 'name': 'Panchavda,Rajkot,Gujarat', 'lon': '71.1032'},
      '680655': {'lat': '10.552', 'name': 'Ollukkara,Thrissur,Kerala', 'lon': '76.3017'},
      '534275': {'lat': '16.449', 'name': 'Lingaboyinacherla,West Godavari,Andhra Pradesh', 'lon': '81.692'},
      '576235': {'lat': '13.7051', 'name': 'Shenapura,Udupi,Karnataka', 'lon': '74.6901'},
      '680656': {'lat': '10.552', 'name': 'Kerala Agri-university,Thrissur,Kerala', 'lon': '76.3017'},
      '680651': {'lat': '10.5665', 'name': 'Nettissery,Thrissur,Kerala', 'lon': '76.2589'},
      '576232': {'lat': '13.837', 'name': 'Bijur,Udupi,Karnataka', 'lon': '74.6247'},
      '531061': {'lat': '17.4975', 'name': 'Murakada,Visakhapatnam,Andhra Pradesh', 'lon': '82.8758'},
      '531060': {'lat': '17.4638', 'name': 'S Rayavaram,Visakhapatnam,Andhra Pradesh', 'lon': '82.7625'},
      '562162': {'lat': '13.3568', 'name': 'Madanayakanahalli,Bengaluru,Karnataka', 'lon': '77.3132'},
      '517599': {'lat': '13.1995', 'name': 'Srikaverirajapuram,Chittoor,Andhra Pradesh', 'lon': '79.398'},
      '562163': {'lat': '13.2257', 'name': 'Arakere,Bangalore Rural,Karnataka', 'lon': '77.575'},
      '333023': {'lat': '28.2045', 'name': 'Pakori Dhani,Jhujhunu,Rajasthan', 'lon': '75.4764'},
      '333022': {'lat': '27.07', 'name': 'Dahani Risiyan,Jhujhunu,Rajasthan', 'lon': '75.2481'},
      '333021': {'lat': '28.1472', 'name': 'Ajari Bari,Jhujhunu,Rajasthan', 'lon': '75.5576'},
      '799103': {'lat': '23.612', 'name': 'Bagmara,South Tripura,Tripura', 'lon': '91.3498'},
      '333027': {'lat': '28.1677', 'name': 'Jodha Ka Bas,Jhujhunu,Rajasthan', 'lon': '75.6308'},
      '333026': {'lat': '27.9274', 'name': 'Rasoolpur,Jhujhunu,Rajasthan', 'lon': '75.7736'},
      '333025': {'lat': '28.2994', 'name': 'Nalawa,Jhujhunu,Rajasthan', 'lon': '75.4519'},
      '333024': {'lat': '28.1501', 'name': 'Islampur (Jhujhunu),Jhujhunu,Rajasthan', 'lon': '75.5399'},
      '333029': {'lat': '28.3133', 'name': 'Kasani,Jodhpur,Rajasthan', 'lon': '75.7452'},
      '333028': {'lat': '28.1018', 'name': 'Thincholi,Jhujhunu,Rajasthan', 'lon': '75.596'},
      '847122': {'lat': '25.7362', 'name': 'Bisfi,Darbhanga,Bihar', 'lon': '86.0642'},
      '262544': {'lat': '29.7251', 'name': 'Jarajibli,Pithoragarh,Uttarakhand', 'lon': '79.9442'},
      '508277': {'lat': '17.4251', 'name': 'Repakapatti,Nalgonda,Telangana', 'lon': '79.1665'},
      '847123': {'lat': '26.0017', 'name': 'Kathalia,Darbhanga,Bihar', 'lon': '85.9476'},
      '737134': {'lat': '27.26875', 'name': 'Gangkha Busty,East Sikkim,Sikkim', 'lon': '88.49347'},
      '250622': {'lat': '29.1947', 'name': 'Pusar,Bagpat,Uttar Pradesh', 'lon': '77.4163'},
      '508278': {'lat': '17.6078', 'name': 'Vempahad,Nalgonda,Telangana', 'lon': '79.1612'},
      '263628': {'lat': '29.7348', 'name': 'Takula,Almora,Uttarakhand', 'lon': '79.6914'},
      '786174': {'lat': '27.4229', 'name': 'Kathalguri,Tinsukia,Assam', 'lon': '95.7652'},
      '786171': {'lat': '27.4158', 'name': 'Bhimpathar,Tinsukia,Assam', 'lon': '95.2775'},
      '786170': {'lat': '27.0418', 'name': 'Ashok Nagar,Tinsukia,Assam', 'lon': '93.1198'},
      '786173': {'lat': '27.31', 'name': 'Jubilee Digboi,Tinsukia,Assam', 'lon': '94.7307'},
      '629704': {'lat': '8.1733', 'name': 'Thamaraikulam,Kanyakumari,Tamil Nadu', 'lon': '77.4752'},
      '786179': {'lat': '27.31', 'name': 'Tipong,Tinsukia,Assam', 'lon': '94.7307'},
      '713205': {'lat': '24.2028', 'name': 'Dhabani,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '507157': {'lat': '17.3716', 'name': 'Jujjularaopet,Khammam,Telangana', 'lon': '80.8793'},
      '801305': {'lat': '25.411', 'name': 'Shahjahanpur,Patna,Bihar', 'lon': '85.2998'},
      '500070': {'lat': '17.4661', 'name': 'Sarada Nagar,K.V.Rangareddy,Telangana', 'lon': '78.4914'},
      '500072': {'lat': '17.3898', 'name': 'Kukatpally,Hyderabad,Telangana', 'lon': '78.4699'},
      '500073': {'lat': '17.4661', 'name': 'Srinagar Colony,Hyderabad,Telangana', 'lon': '78.4914'},
      '246001': {'lat': '30.1527', 'name': 'Chardhar,Pauri Garhwal,Uttarakhand', 'lon': '78.7832'},
      '500075': {'lat': '17.3901', 'name': 'Wattinagulapalli,K.V.Rangareddy,Telangana', 'lon': '78.3279'},
      '500076': {'lat': '17.4447', 'name': 'Snehapuri Colony,K.V.Rangareddy,Telangana', 'lon': '78.5719'},
      '500077': {'lat': '17.4661', 'name': 'Kattedan Ie,Hyderabad,Telangana', 'lon': '78.4914'},
      '784115': {'lat': '26.6589', 'name': 'Kharupetiaghat,Darrang,Assam', 'lon': '92.4784'},
      '784114': {'lat': '26.7111', 'name': 'Dhansiri Bazar,Darrang,Assam', 'lon': '92.6274'},
      '784117': {'lat': '26.6589', 'name': 'Sirajuli,Sonitpur,Assam', 'lon': '92.4784'},
      '784116': {'lat': '26.5597', 'name': 'Jangalpara,Darrang,Assam', 'lon': '92.1983'},
      '784111': {'lat': '26.6589', 'name': 'Mejengjuli,Sonitpur,Assam', 'lon': '92.4784'},
      '784110': {'lat': '26.6563', 'name': 'Chitalmari,Sonitpur,Assam', 'lon': '92.4695'},
      '784113': {'lat': '26.6589', 'name': 'Bongaligaon,Darrang,Assam', 'lon': '92.4784'},
      '784112': {'lat': '26.6589', 'name': 'Orientoli,Sonitpur,Assam', 'lon': '92.4784'},
      '518347': {'lat': '15.553', 'name': 'Basarakodu,Kurnool,Andhra Pradesh', 'lon': '77.4624'},
      '518346': {'lat': '15.4939', 'name': 'Chinnahyti,Kurnool,Andhra Pradesh', 'lon': '77.1165'},
      '518345': {'lat': '15.8863', 'name': 'Mantralaya (Kurnool),Kurnool,Andhra Pradesh', 'lon': '77.4416'},
      '518344': {'lat': '15.7671', 'name': 'Dommaladinne,Kurnool,Andhra Pradesh', 'lon': '77.2608'},
      '518343': {'lat': '15.8533', 'name': 'Kanakaveedu,Kurnool,Andhra Pradesh', 'lon': '77.4848'},
      '481224': {'lat': '21.4203', 'name': 'Sadra,Balaghat,Madhya Pradesh', 'lon': '80.452'},
      '736156': {'lat': '25.315', 'name': 'Chakchaka,Cooch Behar,West Bengal', 'lon': '88.8041'},
      '736157': {'lat': '26.3144', 'name': 'Matiarkuthi,Cooch Behar,West Bengal', 'lon': '89.3013'},
      '736158': {'lat': '26.1634', 'name': 'Golenowhati.,Cooch Behar,West Bengal', 'lon': '89.1838'},
      '141201': {'lat': '30.8087', 'name': 'Kila Raipur,Ludhiana,Punjab', 'lon': '75.8382'},
      '141202': {'lat': '30.7685', 'name': 'Phalewal,Ludhiana,Punjab', 'lon': '75.7437'},
      '141203': {'lat': '30.938', 'name': 'Assi Kalan,Ludhiana,Punjab', 'lon': '75.8217'},
      '141204': {'lat': '30.8087', 'name': 'Dhulkot,Ludhiana,Punjab', 'lon': '75.8382'},
      '141205': {'lat': '30.9377', 'name': 'Maherna Kalan,Ludhiana,Punjab', 'lon': '76.3395'},
      '518349': {'lat': '14.8459', 'name': 'Jampapuram,Kurnool,Andhra Pradesh', 'lon': '77.4402'},
      '518348': {'lat': '15.6451', 'name': 'T.Sakibanda,Kurnool,Andhra Pradesh', 'lon': '77.2539'},
      '791001': {'lat': '28.2358', 'name': 'Darak,West Siang,Arunachal Pradesh', 'lon': '94.6742'},
      '791003': {'lat': '28.3892', 'name': 'Mechuka,West Siang,Arunachal Pradesh', 'lon': '94.8382'},
      '791002': {'lat': '28.5938', 'name': 'Mariyang,Upper Siang,Arunachal Pradesh', 'lon': '95.057'},
      '274705': {'lat': '26.4162', 'name': 'Ghanti,Deoria,Uttar Pradesh', 'lon': '84.0229'},
      '325208': {'lat': '24.9809', 'name': 'Sarota,Kota,Rajasthan', 'lon': '76.4936'},
      '799269': {'lat': '24.1597', 'name': 'Kangrai,North Tripura,Tripura', 'lon': '92.0329'},
      '801303': {'lat': '25.156', 'name': 'Panchlowa,Nalanda,Bihar', 'lon': '85.3104'},
      '799264': {'lat': '24.1597', 'name': 'Sayedabari,North Tripura,Tripura', 'lon': '92.0329'},
      '799266': {'lat': '24.1597', 'name': 'West Nalkata,North Tripura,Tripura', 'lon': '92.0329'},
      '799261': {'lat': '24.1597', 'name': 'Pearacherra,North Tripura,Tripura', 'lon': '92.0329'},
      '799260': {'lat': '24.1597', 'name': 'Deocherra,North Tripura,Tripura', 'lon': '92.0329'},
      '799263': {'lat': '24.1597', 'name': 'Nabincherra,North Tripura,Tripura', 'lon': '92.0329'},
      '799262': {'lat': '24.1597', 'name': 'Fulbari,North Tripura,Tripura', 'lon': '92.0329'},
      '263638': {'lat': '29.6892', 'name': 'Pokhari,Almora,Uttarakhand', 'lon': '79.5019'},
      '686555': {'lat': '9.5557', 'name': 'Kanjirappara,Kottayam,Kerala', 'lon': '76.7133'},
      '686008': {'lat': '9.5951', 'name': 'Arpookara ,Kottayam,Kerala', 'lon': '76.4958'},
      '686009': {'lat': '9.5951', 'name': 'Rubber Board,Kottayam,Kerala', 'lon': '76.4958'},
      '686006': {'lat': '9.5951', 'name': 'Nattassery S H Mount,Kottayam,Kerala', 'lon': '76.4958'},
      '686007': {'lat': '9.5951', 'name': 'Pallom,Kottayam,Kerala', 'lon': '76.4958'},
      '686004': {'lat': '9.5951', 'name': 'Parampuzha,Kottayam,Kerala', 'lon': '76.4958'},
      '686005': {'lat': '9.5951', 'name': 'Thazhathangady,Kottayam,Kerala', 'lon': '76.4958'},
      '686002': {'lat': '9.5951', 'name': 'Kottayam Collectorate,Kottayam,Kerala', 'lon': '76.4958'},
      '686003': {'lat': '9.5951', 'name': 'Kottayam West,Kottayam,Kerala', 'lon': '76.4958'},
      '686001': {'lat': '9.5951', 'name': 'Kottayam North,Kottayam,Kerala', 'lon': '76.4958'},
      '518123': {'lat': '15.0517', 'name': 'Meerjapuram,Kurnool,Andhra Pradesh', 'lon': '78.1144'},
      '518122': {'lat': '15.2413', 'name': 'Mettupalle,Kurnool,Andhra Pradesh', 'lon': '78.1158'},
      '431530': {'lat': '18.8732', 'name': 'Tokwadi,Beed,Maharashtra', 'lon': '76.5028'},
      '518124': {'lat': '15.2496', 'name': 'Yanakandla,Kurnool,Andhra Pradesh', 'lon': '78.4773'},
      '122012': {'lat': '28.4001', 'name': 'Vatika India Sector 82 Gurgaon,Gurgaon,Haryana', 'lon': '76.9661'},
      '414111': {'lat': '19.2076', 'name': 'Nimblak,Ahmed Nagar,Maharashtra', 'lon': '74.6664'},
      '414110': {'lat': '18.8453', 'name': 'MIRC,Ahmed Nagar,Maharashtra', 'lon': '75.5907'},
      '414113': {'lat': '18.6641', 'name': 'Munguswade,Ahmed Nagar,Maharashtra', 'lon': '76.0529'},
      '362730': {'lat': '23.1492', 'name': 'Motamansa,Amreli,Gujarat', 'lon': '74.002'},
      '396440': {'lat': '20.9904', 'name': 'Pethan,Navsari,Gujarat', 'lon': '72.8927'},
      '678506': {'lat': '10.6167', 'name': 'Kollengode West,Palakkad,Kerala', 'lon': '76.7'},
      '678507': {'lat': '10.6211', 'name': 'Govindapuram,Palakkad,Kerala', 'lon': '76.781'},
      '678504': {'lat': '10.599', 'name': 'Vadavannur,Palakkad,Kerala', 'lon': '76.6922'},
      '678505': {'lat': '10.599', 'name': 'Pallassana,Palakkad,Kerala', 'lon': '76.6922'},
      '535221': {'lat': '18.1795', 'name': 'Kirthubarthi,Vizianagaram,Andhra Pradesh', 'lon': '83.2607'},
      '678503': {'lat': '10.6802', 'name': 'Pudunagaram,Palakkad,Kerala', 'lon': '76.6836'},
      '678501': {'lat': '10.599', 'name': 'Koduvayur Agraharam,Palakkad,Kerala', 'lon': '76.6922'},
      '387002': {'lat': '23.0401', 'name': 'Methodist Hospital Tso Nad,Kheda,Gujarat', 'lon': '73.0053'},
      '387003': {'lat': '23.0401', 'name': 'Petlad Road,Kheda,Gujarat', 'lon': '73.0053'},
      '799003': {'lat': '23.6182', 'name': 'Charipara,West Tripura,Tripura', 'lon': '91.356'},
      '387001': {'lat': '23.0401', 'name': 'SRP Group Area Nadiad,Kheda,Gujarat', 'lon': '73.0053'},
      '799005': {'lat': '23.6182', 'name': 'Abhoynagar,West Tripura,Tripura', 'lon': '91.356'},
      '799004': {'lat': '23.6182', 'name': 'Srinagar Kacharipara,West Tripura,Tripura', 'lon': '91.356'},
      '678508': {'lat': '10.5384', 'name': 'Elavancheri,Palakkad,Kerala', 'lon': '76.6482'},
      '799006': {'lat': '23.6182', 'name': 'Nandannagar,West Tripura,Tripura', 'lon': '91.356'},
      '222175': {'lat': '25.9544', 'name': 'Pahetiapur,Jaunpur,Uttar Pradesh', 'lon': '82.3997'},
      '400608': {'lat': '19.1941', 'name': 'Tapasenagar,Thane,Maharashtra', 'lon': '73.0002'},
      '400604': {'lat': '19.1941', 'name': 'Wagle I.E.,Thane,Maharashtra', 'lon': '73.0002'},
      '400605': {'lat': '19.1941', 'name': 'Kalwa,Thane,Maharashtra', 'lon': '73.0002'},
      '400606': {'lat': '19.1941', 'name': 'Jekegram,Thane,Maharashtra', 'lon': '73.0002'},
      '400607': {'lat': '19.1941', 'name': 'Sandozbaugh,Thane,Maharashtra', 'lon': '73.0002'},
      '400601': {'lat': '19.1941', 'name': 'Thane Bazar,Thane,Maharashtra', 'lon': '73.0002'},
      '400602': {'lat': '19.1941', 'name': 'Gokhale Road (Thane),Thane,Maharashtra', 'lon': '73.0002'},
      '400603': {'lat': '19.1941', 'name': 'Thane East,Thane,Maharashtra', 'lon': '73.0002'},
      '231307': {'lat': '25.3713', 'name': 'Barkachha,Mirzapur,Uttar Pradesh', 'lon': '82.4839'},
      '505129': {'lat': '18.3373', 'name': 'Pothireddy Palli,Karim Nagar,Telangana', 'lon': '79.4651'},
      '146001': {'lat': '31.5344', 'name': 'Bharwain Road,Hoshiarpur,Punjab', 'lon': '75.8827'},
      '244412': {'lat': '28.4109', 'name': 'Bania Khera,Moradabad,Uttar Pradesh', 'lon': '78.8806'},
      '262501': {'lat': '29.7604', 'name': 'Siltham,Pithoragarh,Uttarakhand', 'lon': '79.7546'},
      '534280': {'lat': '16.4729', 'name': 'Mylavanilanka,West Godavari,Andhra Pradesh', 'lon': '81.5845'},
      '534281': {'lat': '16.3802', 'name': 'Serepalem,West Godavari,Andhra Pradesh', 'lon': '81.5493'},
      '632518': {'lat': '12.5905', 'name': 'Senganavaram,Vellore,Tamil Nadu', 'lon': '79.4076'},
      '582209': {'lat': '15.5827', 'name': 'Ron Taluk Kut,Gadag,Karnataka', 'lon': '75.5057'},
      '582208': {'lat': '15.5994', 'name': 'Shanawad,Dharwad,Karnataka', 'lon': '75.3594'},
      '231304': {'lat': '25.2055', 'name': 'Jalalpur Mafi,Mirzapur,Uttar Pradesh', 'lon': '82.812'},
      '582201': {'lat': '15.5249', 'name': 'Kittur,Dharwad,Karnataka', 'lon': '75.4738'},
      '582203': {'lat': '15.6282', 'name': 'Holehadagali,Gadag,Karnataka', 'lon': '75.4332'},
      '582202': {'lat': '15.7034', 'name': 'Koujageri,Gadag,Karnataka', 'lon': '75.5213'},
      '582205': {'lat': '15.5228', 'name': 'Anturbentur,Gadag,Karnataka', 'lon': '75.622'},
      '582204': {'lat': '15.495', 'name': 'Belhod,Gadag,Karnataka', 'lon': '75.5149'},
      '582207': {'lat': '15.856', 'name': 'Chicknargund,Gadag,Karnataka', 'lon': '75.299'},
      '582206': {'lat': '15.8595', 'name': 'Konnur (Gadag),Gadag,Karnataka', 'lon': '75.4955'},
      '577003': {'lat': '14.4481', 'name': 'Rm Yard- Davanagere,Davangere,Karnataka', 'lon': '75.9476'},
      '577002': {'lat': '14.3991', 'name': 'Davanagere name,Davangere,Karnataka', 'lon': '75.9429'},
      '244414': {'lat': '28.7503', 'name': 'Bajhawli,Moradabad,Uttar Pradesh', 'lon': '78.3269'},
      '577001': {'lat': '14.2019', 'name': 'Barline Road-Davanagere,Davangere,Karnataka', 'lon': '75.9671'},
      '605701': {'lat': '11.8937', 'name': 'Odiyathur,Villupuram,Tamil Nadu', 'lon': '79.2624'},
      '422305': {'lat': '20.2592', 'name': 'Dongargaon,Nashik,Maharashtra', 'lon': '74.0714'},
      '422304': {'lat': '20.1529', 'name': 'Vanasgaon,Nashik,Maharashtra', 'lon': '74.1487'},
      '422306': {'lat': '20.1453', 'name': 'Redgaon KH,Nashik,Maharashtra', 'lon': '74.2225'},
      '422301': {'lat': '20.104', 'name': 'Pimplus,Nashik,Maharashtra', 'lon': '74.4027'},
      '841435': {'lat': '26.1035', 'name': 'Chakari,Siwan,Bihar', 'lon': '84.3432'},
      '422303': {'lat': '20.0581', 'name': 'Kundegaon,Nashik,Maharashtra', 'lon': '74.1171'},
      '422302': {'lat': '20.152', 'name': 'Oney,Nashik,Maharashtra', 'lon': '74.2956'},
      '431125': {'lat': '19.2919', 'name': 'Yellambghat,Beed,Maharashtra', 'lon': '76.4529'},
      '203155': {'lat': '28.0148', 'name': 'Ahamedpur  Chauroli,Bulandshahr,Uttar Pradesh', 'lon': '77.6227'},
      '422308': {'lat': '20.1348', 'name': 'Ranwad,Nashik,Maharashtra', 'lon': '74.0916'},
      '577554': {'lat': '13.9377', 'name': 'Janthikolalu,Chitradurga,Karnataka', 'lon': '75.4515'},
      '509105': {'lat': '16.3435', 'name': 'Venkatampally,Mahabub Nagar,Telangana', 'lon': '78.2022'},
      '576124': {'lat': '13.389', 'name': 'Shiroor,Udupi,Karnataka', 'lon': '74.8448'},
      '577557': {'lat': '13.8984', 'name': 'H.D.Pura,Davangere,Karnataka', 'lon': '75.8046'},
      '263635': {'lat': '29.9866', 'name': 'Payya,Bageshwar,Uttarakhand', 'lon': '79.4883'},
      '274703': {'lat': '26.4254', 'name': 'Paratappur,Deoria,Uttar Pradesh', 'lon': '83.9304'},
      '577004': {'lat': '14.2019', 'name': 'Nittuvalli,Davangere,Karnataka', 'lon': '75.9671'},
      '415538': {'lat': '17.3333', 'name': 'Nimsode,Satara,Maharashtra', 'lon': '74.3167'},
      '415539': {'lat': '17.3333', 'name': 'Belawade BK,Satara,Maharashtra', 'lon': '74.3167'},
      '210432': {'lat': '25.8066', 'name': 'Amood,Hamirpur,Uttar Pradesh', 'lon': '79.6686'},
      '210433': {'lat': '25.1802', 'name': 'Srinagar (Mahoba),Mahoba,Uttar Pradesh', 'lon': '79.7748'},
      '231309': {'lat': '24.9525', 'name': 'Rampur Atari,Mirzapur,Uttar Pradesh', 'lon': '82.5515'},
      '577558': {'lat': '14.0922', 'name': 'Talavatti,Chitradurga,Karnataka', 'lon': '75.9378'},
      '415530': {'lat': '17.3333', 'name': 'Jamb (Kikali),Satara,Maharashtra', 'lon': '74.3167'},
      '507111': {'lat': '18.0476', 'name': 'B.R.Colony,Khammam,Telangana', 'lon': '80.281'},
      '601203': {'lat': '13.1832', 'name': 'Jothi Nagar,Tiruvallur,Tamil Nadu', 'lon': '80.1329'},
      '601202': {'lat': '13.454', 'name': 'Thanipoondi,Tiruvallur,Tamil Nadu', 'lon': '80.0047'},
      '601205': {'lat': '13.4233', 'name': 'Thirupalavanam,Tiruvallur,Tamil Nadu', 'lon': '80.271'},
      '601204': {'lat': '13.3302', 'name': 'Reddipalayam,Tiruvallur,Tamil Nadu', 'lon': '80.1546'},
      '415536': {'lat': '17.3333', 'name': 'Chandak,Satara,Maharashtra', 'lon': '74.3167'},
      '415537': {'lat': '17.6686', 'name': 'Bibi,Satara,Maharashtra', 'lon': '74.1738'},
      '695103': {'lat': '8.6989', 'name': 'Avanavancherry,Thiruvananthapuram,Kerala', 'lon': '76.8672'},
      '695102': {'lat': '8.5053', 'name': 'Alamcode,Thiruvananthapuram,Kerala', 'lon': '77.0803'},
      '695101': {'lat': '8.6344', 'name': 'Attingal H.O,Thiruvananthapuram,Kerala', 'lon': '76.9382'},
      '798607': {'lat': '26.3748', 'name': 'Alichen,Mokokchung,Nagaland', 'lon': '94.6222'},
      '798601': {'lat': '26.3094', 'name': 'lonmisa,Mokokchung,Nagaland', 'lon': '94.5206'},
      '798602': {'lat': '26.3748', 'name': 'Zangkham,Mon,Nagaland', 'lon': '94.6222'},
      '695104': {'lat': '8.6344', 'name': 'Parayathukonam,Thiruvananthapuram,Kerala', 'lon': '76.9382'},
      '487330': {'lat': '23.0394', 'name': 'Padhriya,Narsinghpur,Madhya Pradesh', 'lon': '79.0353'},
      '487334': {'lat': '23.0793', 'name': 'Bilgua,Narsinghpur,Madhya Pradesh', 'lon': '78.8824'},
      '487337': {'lat': '23.1419', 'name': 'Imjhira,Narsinghpur,Madhya Pradesh', 'lon': '78.8941'},
      '734009': {'lat': '26.731', 'name': 'Simulbari,Darjiling,West Bengal', 'lon': '88.9486'},
      '331028': {'lat': '28.3536', 'name': 'Malsisar,Jhujhunu,Rajasthan', 'lon': '75.2975'},
      '517167': {'lat': '13.5399', 'name': 'Ksheerasamudram,Chittoor,Andhra Pradesh', 'lon': '79.2351'},
      '845104': {'lat': '27.0792', 'name': 'Kathar,West Champaran,Bihar', 'lon': '84.3412'},
      '845105': {'lat': '26.8226', 'name': 'Borwal,West Champaran,Bihar', 'lon': '84.5006'},
      '845106': {'lat': '26.847', 'name': 'Cheutaha,West Champaran,Bihar', 'lon': '84.4468'},
      '845107': {'lat': '26.8496', 'name': 'Valmiki Nagar,West Champaran,Bihar', 'lon': '84.676'},
      '388245': {'lat': '23.0236', 'name': 'Sonipur,Kheda,Gujarat', 'lon': '73.3928'},
      '444708': {'lat': '20.6549', 'name': 'Sultanpur Nandu,Amravati,Maharashtra', 'lon': '77.9355'},
      '845102': {'lat': '26.754', 'name': 'Gaunaha,West Champaran,Bihar', 'lon': '84.411'},
      '845103': {'lat': '26.7465', 'name': 'Dianmarawa,West Champaran,Bihar', 'lon': '84.5167'},
      '444705': {'lat': '20.813', 'name': 'Chincholi Shinghane,Amravati,Maharashtra', 'lon': '77.3768'},
      '444704': {'lat': '21.1878', 'name': 'Kharala,Amravati,Maharashtra', 'lon': '77.572'},
      '444707': {'lat': '20.9958', 'name': 'Lahegaon,Amravati,Maharashtra', 'lon': '77.7064'},
      '444706': {'lat': '20.9481', 'name': 'Sanglood,Amravati,Maharashtra', 'lon': '77.2223'},
      '444701': {'lat': '20.6937', 'name': 'Hiwara BK,Amravati,Maharashtra', 'lon': '77.6871'},
      '444702': {'lat': '20.9686', 'name': 'Khairi Tembhru,Amravati,Maharashtra', 'lon': '77.9802'},
      '147202': {'lat': '30.5105', 'name': 'Agol,Patiala,Punjab', 'lon': '76.2111'},
      '147203': {'lat': '30.5642', 'name': 'Noorpur,Patiala,Punjab', 'lon': '76.172'},
      '636458': {'lat': '11.8082', 'name': 'Mallikundam,Salem,Tamil Nadu', 'lon': '77.9041'},
      '147201': {'lat': '30.3339', 'name': 'Dhablan,Patiala,Punjab', 'lon': '76.279'},
      '176501': {'lat': '31.8818', 'name': 'Sansarpur Terrace,Kangra,Himachal Pradesh', 'lon': '75.9485'},
      '176502': {'lat': '31.9412', 'name': 'Bari,Kangra,Himachal Pradesh', 'lon': '75.9883'},
      '636452': {'lat': '11.3244', 'name': 'Masilapalayam,Salem,Tamil Nadu', 'lon': '78.4134'},
      '636453': {'lat': '11.7727', 'name': 'Guttapatti,Salem,Tamil Nadu', 'lon': '77.9633'},
      '636451': {'lat': '11.8337', 'name': 'Periasathapadi,Salem,Tamil Nadu', 'lon': '77.9691'},
      '636456': {'lat': '11.4168', 'name': 'Salem Camp,Salem,Tamil Nadu', 'lon': '78.021'},
      '636457': {'lat': '11.75', 'name': 'Sanarapatti,Salem,Tamil Nadu', 'lon': '77.8801'},
      '636454': {'lat': '11.6741', 'name': 'Kanakkampatti,Salem,Tamil Nadu', 'lon': '78.2221'},
      '636455': {'lat': '11.6741', 'name': 'Olaipatti,Salem,Tamil Nadu', 'lon': '78.2221'},
      '394518': {'lat': '21.1842', 'name': 'Ongc Nagar,Surat,Gujarat', 'lon': '72.6893'},
      '756041': {'lat': '20.2958', 'name': 'Matiali,Baleswar,Odisha', 'lon': '84.9918'},
      '523329': {'lat': '15.8056', 'name': 'Gottipadia,Prakasam,Andhra Pradesh', 'lon': '79.2391'},
      '523328': {'lat': '15.8035', 'name': 'R.Ummadivaram,Prakasam,Andhra Pradesh', 'lon': '79.442'},
      '756044': {'lat': '21.4328', 'name': 'Aruhabad,Baleswar,Odisha', 'lon': '86.4705'},
      '756045': {'lat': '20.9991', 'name': 'Janhia,Baleswar,Odisha', 'lon': '86.21'},
      '756046': {'lat': '20.6337', 'name': 'Jagannathpur Bachhada,Bhadrak,Odisha', 'lon': '86.3221'},
      '756047': {'lat': '21.6541', 'name': 'Makhanpur,Baleswar,Odisha', 'lon': '85.6193'},
      '394510': {'lat': '21.186', 'name': 'Bhatpore,Surat,Gujarat', 'lon': '72.7641'},
      '173222': {'lat': '31.0455', 'name': 'Mamlig,Solan,Himachal Pradesh', 'lon': '77.034'},
      '173221': {'lat': '31.0387', 'name': 'Balera,Solan,Himachal Pradesh', 'lon': '77.0186'},
      '523320': {'lat': '15.8611', 'name': 'Gobbur,Prakasam,Andhra Pradesh', 'lon': '79.2475'},
      '523327': {'lat': '15.8165', 'name': 'Veerabhadrapuram,Prakasam,Andhra Pradesh', 'lon': '79.3394'},
      '523326': {'lat': '16.0145', 'name': 'Medepi,Prakasam,Andhra Pradesh', 'lon': '79.5357'},
      '394516': {'lat': '21.1842', 'name': 'Adityanagar,Surat,Gujarat', 'lon': '72.6893'},
      '394517': {'lat': '21.1833', 'name': 'Vasava,Surat,Gujarat', 'lon': '72.6519'},
      '500086': {'lat': '17.2974', 'name': 'Don Bosco Nagar,Hyderabad,Telangana', 'lon': '78.3132'},
      '752119': {'lat': '20.2479', 'name': 'Derunia,Puri,Odisha', 'lon': '85.8149'},
      '689124': {'lat': '9.3205', 'name': 'Vazharmangalam,Alappuzha,Kerala', 'lon': '76.6332'},
      '331507': {'lat': '27.7429', 'name': 'Guleria,Churu,Rajasthan', 'lon': '74.011'},
      '360003': {'lat': '22.4673', 'name': 'Rajkot Marketing Yard,Rajkot,Gujarat', 'lon': '70.8241'},
      '360002': {'lat': '22.5964', 'name': 'Rajkot Bhaktinagar,Rajkot,Gujarat', 'lon': '70.5192'},
      '360005': {'lat': '22.5964', 'name': 'Mota Mava,Rajkot,Gujarat', 'lon': '70.5192'},
      '360004': {'lat': '22.2117', 'name': 'Rajkot Malviyanagar,Rajkot,Gujarat', 'lon': '70.7591'},
      '360007': {'lat': '22.5964', 'name': 'Rajkot  Raiya Road,Rajkot,Gujarat', 'lon': '70.5192'},
      '360006': {'lat': '23.2395', 'name': 'Rajkot Bhomeshwar Plot,Rajkot,Gujarat', 'lon': '69.6695'},
      '465106': {'lat': '23.6894', 'name': 'Kapaliya,Shajapur,Madhya Pradesh', 'lon': '76.8785'},
      '605702': {'lat': '12.0563', 'name': 'Porasapattu,Villupuram,Tamil Nadu', 'lon': '78.9725'},
      '493555': {'lat': '21.3361', 'name': 'Jagdishpur (Mahasamund),Mahasamund,Chattisgarh', 'lon': '82.7734'},
      '389130': {'lat': '22.8754', 'name': 'Patangdi,Dahod,Gujarat', 'lon': '73.8831'},
      '325202': {'lat': '25.4849', 'name': 'Khajurana Kalan,Baran,Rajasthan', 'lon': '76.3548'},
      '600004': {'lat': '13.0754', 'name': 'Vivekananda College Madras,Chennai,Tamil Nadu', 'lon': '80.2737'},
      '600005': {'lat': '13.0572', 'name': 'Parthasarathy Koil,Chennai,Tamil Nadu', 'lon': '80.2778'},
      '600006': {'lat': '13.0754', 'name': 'Teynampet West,Chennai,Tamil Nadu', 'lon': '80.2737'},
      '307513': {'lat': '24.4606', 'name': 'Bant,Sirohi,Rajasthan', 'lon': '73.5779'},
      '600001': {'lat': '13.0914', 'name': 'Govt Stanley Hospital,Chennai,Tamil Nadu', 'lon': '80.2828'},
      '600002': {'lat': '13.0744', 'name': 'Madras Electriname System,Chennai,Tamil Nadu', 'lon': '80.2714'},
      '600003': {'lat': '13.0754', 'name': 'Edapalayam,Chennai,Tamil Nadu', 'lon': '80.2737'},
      '242226': {'lat': '27.8276', 'name': 'Dadraul,Shahjahanpur,Uttar Pradesh', 'lon': '79.8681'},
      '226203': {'lat': '27.0972', 'name': 'Usarana,Lucknow,Uttar Pradesh', 'lon': '80.8315'},
      '502273': {'lat': '17.7732', 'name': 'Choutkur,Medak,Telangana', 'lon': '78.0507'},
      '226201': {'lat': '26.5116', 'name': 'Bakshi Ka Talab,Lucknow,Uttar Pradesh', 'lon': '81.1139'},
      '600008': {'lat': '13.0717', 'name': 'Ethiraj Salai,Chennai,Tamil Nadu', 'lon': '80.2556'},
      '600009': {'lat': '13.0754', 'name': 'Fort St George,Chennai,Tamil Nadu', 'lon': '80.2737'},
      '451551': {'lat': '21.9952', 'name': 'Borlay,Barwani,Madhya Pradesh', 'lon': '74.8492'},
      '242221': {'lat': '27.6746', 'name': 'S Purkayasthan,Shahjahanpur,Uttar Pradesh', 'lon': '79.8045'},
      '632511': {'lat': '12.8417', 'name': 'Vadailluppai,Tiruvannamalai,Tamil Nadu', 'lon': '79.4733'},
      '804406': {'lat': '25.1646', 'name': 'Ghosi (Jehanabad),Jehanabad,Bihar', 'lon': '84.9732'},
      '822117': {'lat': '24.2038', 'name': 'Japla C F,Palamau,Jharkhand', 'lon': '84.0559'},
      '144519': {'lat': '31.5032', 'name': 'Pach Nangal Khushalpur,Hoshiarpur,Punjab', 'lon': '75.8355'},
      '144518': {'lat': '31.0484', 'name': 'Garh Padhiana,Nawanshahr,Punjab', 'lon': '76.0571'},
      '144517': {'lat': '31.0476', 'name': 'Hiala,Nawanshahr,Punjab', 'lon': '76.1082'},
      '144516': {'lat': '31.0911', 'name': 'Karimpur,Nawanshahr,Punjab', 'lon': '76.152'},
      '144515': {'lat': '31.0675', 'name': 'Mirpur Jattan,Nawanshahr,Punjab', 'lon': '76.2035'},
      '144514': {'lat': '31.1341', 'name': 'Gulshan Bazar Nawanshahar,Nawanshahr,Punjab', 'lon': '76.1299'},
      '144513': {'lat': '31.1165', 'name': 'Kamam,Nawanshahr,Punjab', 'lon': '76.0248'},
      '144512': {'lat': '31.1682', 'name': 'Palli Jhikki,Nawanshahr,Punjab', 'lon': '75.6193'},
      '144511': {'lat': '31.1091', 'name': 'Dosanjh Khurd,Nawanshahr,Punjab', 'lon': '76.156'},
      '144510': {'lat': '31.2357', 'name': 'Ladhana Jhikka,Nawanshahr,Punjab', 'lon': '75.9968'},
      '209622': {'lat': '27.4304', 'name': 'Amratpur,Farrukhabad,Uttar Pradesh', 'lon': '79.4613'},
      '209621': {'lat': '27.4437', 'name': 'Gandhi,Farrukhabad,Uttar Pradesh', 'lon': '79.6678'},
      '580114': {'lat': '15.1655', 'name': 'Gambhyapur,Dharwad,Karnataka', 'lon': '75.1128'},
      '580112': {'lat': '15.3841', 'name': 'Shirkol,Dharwad,Karnataka', 'lon': '75.2201'},
      '209625': {'lat': '27.3299', 'name': 'Awas Vikas Colony Farrukhabad,Farrukhabad,Uttar Pradesh', 'lon': '79.4743'},
      '713520': {'lat': '23.6319', 'name': 'Poshla,Purba Bardhaman,West Bengal', 'lon': '88.202'},
      '804402': {'lat': '25.0367', 'name': 'Fatehpur Sanda,Arwal,Bihar', 'lon': '84.7251'},
      '456331': {'lat': '23.9409', 'name': 'Bhatisuda,Ujjain,Madhya Pradesh', 'lon': '76.9913'},
      '632510': {'lat': '13.0879', 'name': 'Akkachikuppam,Vellore,Tamil Nadu', 'lon': '79.5347'},
      '580118': {'lat': '14.5099', 'name': 'Salakinkoppa,Dharwad,Karnataka', 'lon': '74.7908'},
      '225412': {'lat': '26.991', 'name': 'Mahmoodabad,Barabanki,Uttar Pradesh', 'lon': '81.3034'},
      '151401': {'lat': '30.0603', 'name': 'Chak Attar Singh Wala,Bathinda,Punjab', 'lon': '74.7822'},
      '225413': {'lat': '26.5911', 'name': 'Neochhana,Barabanki,Uttar Pradesh', 'lon': '81.4142'},
      '192301': {'lat': '33.8741', 'name': 'Shale Tokna,Pulwama,Jammu & Kashmir', 'lon': '74.8995'},
      '192303': {'lat': '33.7648', 'name': 'Tankiya Sahib,Pulwama,Jammu & Kashmir', 'lon': '74.8905'},
      '192302': {'lat': '34.1075', 'name': 'Turkoo Babuar,Pulwama,Jammu & Kashmir', 'lon': '74.54'},
      '192305': {'lat': '33.8643', 'name': 'Wasoora,Ananthnag,Jammu & Kashmir', 'lon': '74.6951'},
      '192304': {'lat': '33.9304', 'name': 'Kakapora,Pulwama,Jammu & Kashmir', 'lon': '74.71'},
      '736202': {'lat': '26.4762', 'name': 'Lalchandpur,Alipurduar,West Bengal', 'lon': '89.763'},
      '192306': {'lat': '33.9304', 'name': 'Brithipora,Pulwama,Jammu & Kashmir', 'lon': '74.71'},
      '207250': {'lat': '27.3801', 'name': 'Kharsulia,Etah,Uttar Pradesh', 'lon': '79.2342'},
      '497225': {'lat': '23.2597', 'name': 'Karamdiha,Surguja,Chattisgarh', 'lon': '82.8802'},
      '493229': {'lat': '21.4468', 'name': 'Telasi,Raipur,Chattisgarh', 'lon': '82.1579'},
      '246144': {'lat': '29.8923', 'name': 'Syalini Badro,Pauri Garhwal,Uttarakhand', 'lon': '78.5371'},
      '461661': {'lat': '22.7657', 'name': 'Aari,Hoshangabad,Madhya Pradesh', 'lon': '78.0018'},
      '249192': {'lat': '30.1136', 'name': 'Naidogi,Tehri Garhwal,Uttarakhand', 'lon': '78.341'},
      '782470': {'lat': '26.1303', 'name': 'Neparpatty,Karbi Anglon,Assam', 'lon': '92.741'},
      '343022': {'lat': '25.4192', 'name': 'Taliyana,Jalor,Rajasthan', 'lon': '72.0539'},
      '249196': {'lat': '30.5206', 'name': 'Nagrajadhar,Tehri Garhwal,Uttarakhand', 'lon': '78.3047'},
      '249199': {'lat': '29.9664', 'name': 'Tingri,Tehri Garhwal,Uttarakhand', 'lon': '79.1427'},
      '461668': {'lat': '22.7869', 'name': 'Pamli,Hoshangabad,Madhya Pradesh', 'lon': '78.1157'},
      '470666': {'lat': '23.9667', 'name': 'Sukha,Damoh,Madhya Pradesh', 'lon': '79.2221'},
      '303702': {'lat': '27.1695', 'name': 'Chomu,Jaipur,Rajasthan', 'lon': '75.7223'},
      '603112': {'lat': '12.7865', 'name': 'Muttukadu,Kanchipuram,Tamil Nadu', 'lon': '80.2506'},
      '361350': {'lat': '22.4676', 'name': 'Okha,Jamnagar,Gujarat', 'lon': '69.07'},
      '603110': {'lat': '12.7311', 'name': 'Venbedu,Kanchipuram,Tamil Nadu', 'lon': '80.1747'},
      '344043': {'lat': '25.431', 'name': 'Motisara,Barmer,Rajasthan', 'lon': '72.7892'},
      '303704': {'lat': '26.0912', 'name': 'Bhurthal,Jaipur,Rajasthan', 'lon': '76.5458'},
      '276288': {'lat': '26.0937', 'name': 'Sumhadeeh,Azamgarh,Uttar Pradesh', 'lon': '82.8479'},
      '497119': {'lat': '23.6154', 'name': 'Bardar,Surguja,Chattisgarh', 'lon': '83.7152'},
      '486001': {'lat': '24.5289', 'name': 'Rewa Ghoghar Mohalla,Rewa,Madhya Pradesh', 'lon': '81.3006'},
      '736204': {'lat': '25.0549', 'name': 'Salkumarhat,Alipurduar,West Bengal', 'lon': '87.767'},
      '486003': {'lat': '24.557', 'name': 'Rewa APSU,Rewa,Madhya Pradesh', 'lon': '81.3459'},
      '486004': {'lat': '24.4386', 'name': 'Rewa name,Rewa,Madhya Pradesh', 'lon': '81.1987'},
      '486005': {'lat': '24.4892', 'name': 'Khaur,Rewa,Madhya Pradesh', 'lon': '81.3438'},
      '486006': {'lat': '24.4947', 'name': 'Rausar,Rewa,Madhya Pradesh', 'lon': '81.2742'},
      '835301': {'lat': '23.376', 'name': 'Loyo,Ranchi,Jharkhand', 'lon': '85.0159'},
      '835303': {'lat': '23.2972', 'name': 'Piskanagri,Ranchi,Jharkhand', 'lon': '85.2179'},
      '835302': {'lat': '23.2689', 'name': 'Sahijana,Gumla,Jharkhand', 'lon': '84.6175'},
      '477446': {'lat': '26.4211', 'name': 'Tola,Bhind,Madhya Pradesh', 'lon': '78.7226'},
      '362020': {'lat': '22.9398', 'name': 'Umrali,Junagadh,Gujarat', 'lon': '71.6462'},
      '521241': {'lat': '16.5737', 'name': 'Rayanapadu,Krishna,Andhra Pradesh', 'lon': '80.5686'},
      '521246': {'lat': '16.3239', 'name': 'Kapileswarapuram (Krishna),Krishna,Andhra Pradesh', 'lon': '80.8671'},
      '521247': {'lat': '16.2665', 'name': 'Inapur,Krishna,Andhra Pradesh', 'lon': '80.8453'},
      '625234': {'lat': '10.0062', 'name': 'Melakkal,Madurai,Tamil Nadu', 'lon': '78.1578'},
      '521245': {'lat': '16.4056', 'name': 'Pedaogirala,Krishna,Andhra Pradesh', 'lon': '80.8243'},
      '242301': {'lat': '27.9196', 'name': 'Baisaribaisra,Shahjahanpur,Uttar Pradesh', 'lon': '79.6764'},
      '670704': {'lat': '11.9731', 'name': 'Edapuzha,Kannur,Kerala', 'lon': '75.7198'},
      '670705': {'lat': '12.037', 'name': 'Nuchiyad,Kannur,Kerala', 'lon': '75.6696'},
      '670706': {'lat': '11.9254', 'name': 'Kacherikadavu,Kannur,Kerala', 'lon': '75.6818'},
      '670701': {'lat': '11.9254', 'name': 'Ambilat,Kannur,Kerala', 'lon': '75.6818'},
      '670702': {'lat': '11.9238', 'name': 'Kara- Peravoor,Kannur,Kerala', 'lon': '75.5995'},
      '670703': {'lat': '11.8274', 'name': 'Punnad,Kannur,Kerala', 'lon': '75.9382'},
      '472005': {'lat': '24.6288', 'name': 'Khiriya Naka,Tikamgarh,Madhya Pradesh', 'lon': '78.8115'},
      '230404': {'lat': '25.7669', 'name': 'Vishwanathganj,Pratapgarh,Uttar Pradesh', 'lon': '81.9283'},
      '431709': {'lat': '18.8938', 'name': 'Manoor,Nanded,Maharashtra', 'lon': '77.5213'},
      '431708': {'lat': '18.9628', 'name': 'Dhanora,Nanded,Maharashtra', 'lon': '77.1841'},
      '472001': {'lat': '24.6076', 'name': 'Tikamgarh H.O,Tikamgarh,Madhya Pradesh', 'lon': '79.0086'},
      '497111': {'lat': '22.3657', 'name': 'Dhodhagaon,Surguja,Chattisgarh', 'lon': '82.8128'},
      '431705': {'lat': '19.6152', 'name': 'Gunda,Hingoli,Maharashtra', 'lon': '77.1189'},
      '431704': {'lat': '19.8137', 'name': 'Chabhara,Nanded,Maharashtra', 'lon': '77.9103'},
      '431707': {'lat': '18.9676', 'name': 'Kiwala,Nanded,Maharashtra', 'lon': '77.3048'},
      '431701': {'lat': '19.5176', 'name': 'Wapti,Hingoli,Maharashtra', 'lon': '77.2472'},
      '431703': {'lat': '19.6152', 'name': 'Kadoli,Hingoli,Maharashtra', 'lon': '77.1189'},
      '431702': {'lat': '19.6182', 'name': 'Khanapur Chitta,Hingoli,Maharashtra', 'lon': '77.4936'},
      '277207': {'lat': '25.9725', 'name': 'Bhikharia,Ballia,Uttar Pradesh', 'lon': '84.1749'},
      '382170': {'lat': '23.4088', 'name': 'Bakrana,Ahmedabad,Gujarat', 'lon': '72.1446'},
      '736206': {'lat': '26.5148', 'name': 'Bakla School Danga,Alipurduar,West Bengal', 'lon': '89.6522'},
      '242306': {'lat': '27.7518', 'name': 'Bhatpurarasulpur,Shahjahanpur,Uttar Pradesh', 'lon': '80.142'},
      '614715': {'lat': '10.9516', 'name': 'Ezhilur,Tiruvarur,Tamil Nadu', 'lon': '78.695'},
      '127032': {'lat': '28.9684', 'name': 'Milkpur,Bhiwani,Haryana', 'lon': '76.0639'},
      '127031': {'lat': '28.8613', 'name': 'Sui,Bhiwani,Haryana', 'lon': '76.0606'},
      '127030': {'lat': '29.0425', 'name': 'Umarwas,Bhiwani,Haryana', 'lon': '76.1726'},
      '614711': {'lat': '10.5303', 'name': 'Korukkai,Tiruvarur,Tamil Nadu', 'lon': '79.7057'},
      '614710': {'lat': '10.6111', 'name': 'Naduvakalappal,Tiruvarur,Tamil Nadu', 'lon': '79.5433'},
      '127035': {'lat': '28.9625', 'name': 'Bhurtana,Bhiwani,Haryana', 'lon': '75.9572'},
      '614712': {'lat': '10.2996', 'name': 'Talanayar IV Sethi,Nagapattinam,Tamil Nadu', 'lon': '79.4036'},
      '497117': {'lat': '23.0338', 'name': 'Sayar,Surguja,Chattisgarh', 'lon': '83.0237'},
      '497448': {'lat': '23.1316', 'name': 'South Jhagrakhand Colly,Koriya,Chattisgarh', 'lon': '82.2859'},
      '497449': {'lat': '22.9805', 'name': 'Chirmi,Koriya,Chattisgarh', 'lon': '82.3013'},
      '493526': {'lat': '21.6706', 'name': 'Bagbuda,Raipur,Chattisgarh', 'lon': '82.3048'},
      '314034': {'lat': '23.9112', 'name': 'Savgarh,Dungarpur,Rajasthan', 'lon': '73.9081'},
      '509203': {'lat': '17.1579', 'name': 'Mahadeanpet,Mahabub Nagar,Telangana', 'lon': '78.4297'},
      '574324': {'lat': '13.4836', 'name': 'Ajjibettu,Dakshina Kannada,Karnataka', 'lon': '75.4775'},
      '700020': {'lat': '22.4826', 'name': 'A.J.C.Bose Road,Kolkata,West Bengal', 'lon': '88.4744'},
      '800008': {'lat': '25.5986', 'name': 'Machharhatta,Patna,Bihar', 'lon': '85.2202'},
      '515865': {'lat': '14.8449', 'name': 'Muradi Hanumapuram,Ananthapur,Andhra Pradesh', 'lon': '77.0267'},
      '515867': {'lat': '14.6646', 'name': 'B.N.Halli,Ananthapur,Andhra Pradesh', 'lon': '76.9531'},
      '614013': {'lat': '10.7012', 'name': 'Kattakudi,Tiruvarur,Tamil Nadu', 'lon': '79.3633'},
      '515863': {'lat': '14.5829', 'name': 'Polepalli,Ananthapur,Andhra Pradesh', 'lon': '76.8792'},
      '796901': {'lat': '22.4521', 'name': 'Fungkah,Saiha,Mizoram', 'lon': '93.0196'},
      '441001': {'lat': '20.9768', 'name': 'Kamthi H.O,Nagpur,Maharashtra', 'lon': '79.5482'},
      '608801': {'lat': '12.2091', 'name': 'Theerthanagiri,Cuddalore,Tamil Nadu', 'lon': '79.5955'},
      '458001': {'lat': '23.5484', 'name': 'Mandsaur H.O,Mandsaur,Madhya Pradesh', 'lon': '76.5079'},
      '601301': {'lat': '12.921', 'name': 'Serpananchery,Kanchipuram,Tamil Nadu', 'lon': '80.025'},
      '700023': {'lat': '22.4826', 'name': 'Watgunge,Kolkata,West Bengal', 'lon': '88.4744'},
      '450117': {'lat': '21.9301', 'name': 'Adakheda,East Nimar,Madhya Pradesh', 'lon': '76.1763'},
      '145101': {'lat': '32.1197', 'name': 'Mirthal,Pathankot,Punjab', 'lon': '75.6183'},
      '800009': {'lat': '25.5484', 'name': 'Punadih,Patna,Bihar', 'lon': '85.2649'},
      '505490': {'lat': '18.265', 'name': 'Vennampalli,Karim Nagar,Telangana', 'lon': '79.1626'},
      '734204': {'lat': '27.0144', 'name': 'Dowhill,Darjiling,West Bengal', 'lon': '88.2031'},
      '450116': {'lat': '22.0995', 'name': 'Mojwadi,Khandwa,Madhya Pradesh', 'lon': '76.71'},
      '734203': {'lat': '26.8743', 'name': 'Ambootia,Darjiling,West Bengal', 'lon': '88.2412'},
      '505497': {'lat': '18.0312', 'name': 'Rathnagiri,Karim Nagar,Telangana', 'lon': '79.4581'},
      '734201': {'lat': '27.0824', 'name': 'Dara Goan,Darjiling,West Bengal', 'lon': '88.175'},
      '505498': {'lat': '18.4208', 'name': 'Sirsapalli,Karim Nagar,Telangana', 'lon': '78.9656'},
      '682002': {'lat': '10.1131', 'name': 'Mattancherry,Ernakulam,Kerala', 'lon': '76.2858'},
      '682003': {'lat': '10.1131', 'name': 'Willingdon Island,Ernakulam,Kerala', 'lon': '76.2858'},
      '325215': {'lat': '25.3475', 'name': 'Jaloda Tejaji,Baran,Rajasthan', 'lon': '76.4553'},
      '682001': {'lat': '10.1131', 'name': 'Kochi H.O,Ernakulam,Kerala', 'lon': '76.2858'},
      '682006': {'lat': '10.1131', 'name': 'Palluruthy South,Ernakulam,Kerala', 'lon': '76.2858'},
      '682007': {'lat': '10.1131', 'name': 'Kumbalangi,Ernakulam,Kerala', 'lon': '76.2858'},
      '682004': {'lat': '10.1131', 'name': 'Kochi Naval Base,Ernakulam,Kerala', 'lon': '76.2858'},
      '682005': {'lat': '10.1131', 'name': 'Thoppumpady,Ernakulam,Kerala', 'lon': '76.2858'},
      '821111': {'lat': '25.1111', 'name': 'Thanua,Rohtas,Bihar', 'lon': '84.1907'},
      '821110': {'lat': '25.2747', 'name': 'Kajura,Kaimur (Bhabua),Bihar', 'lon': '83.6393'},
      '682008': {'lat': '10.1131', 'name': 'S.Chellanam,Ernakulam,Kerala', 'lon': '76.2858'},
      '682009': {'lat': '10.1131', 'name': 'North End,Ernakulam,Kerala', 'lon': '76.2858'},
      '821115': {'lat': '25.1261', 'name': 'Sasaram Bazar,Rohtas,Bihar', 'lon': '84.0255'},
      '732128': {'lat': '25.0677', 'name': 'Magurai,Malda,West Bengal', 'lon': '87.9151'},
      '325219': {'lat': '24.6658', 'name': 'Tancha,Baran,Rajasthan', 'lon': '76.714'},
      '325218': {'lat': '24.9358', 'name': 'Ardan,Baran,Rajasthan', 'lon': '76.6143'},
      '392001': {'lat': '22.206', 'name': 'Bahruch R.S,Bharuch,Gujarat', 'lon': '72.8478'},
      '802208': {'lat': '25.2686', 'name': 'Koshiar,Bhojpur,Bihar', 'lon': '84.6014'},
      '314038': {'lat': '23.9899', 'name': 'Lalpani,Dungarpur,Rajasthan', 'lon': '73.681'},
      '802202': {'lat': '25.3658', 'name': 'Agiaon Bazar,Bhojpur,Bihar', 'lon': '84.139'},
      '314032': {'lat': '23.8407', 'name': 'Duchiya Bada,Dungarpur,Rajasthan', 'lon': '73.9775'},
      '396055': {'lat': '20.5571', 'name': 'Kamparia,Valsad,Gujarat', 'lon': '73.047'},
      '314030': {'lat': '23.4364', 'name': 'Kherasa,Dungarpur,Rajasthan', 'lon': '74.0363'},
      '314037': {'lat': '23.7589', 'name': 'Bilia Badgama,Dungarpur,Rajasthan', 'lon': '73.8131'},
      '314036': {'lat': '23.8759', 'name': 'Gada Moriya,Dungarpur,Rajasthan', 'lon': '73.8277'},
      '396051': {'lat': '23.1018', 'name': 'Khatana,Valsad,Gujarat', 'lon': '73.1852'},
      '396050': {'lat': '22.2536', 'name': 'Avdha,Valsad,Gujarat', 'lon': '73.1391'},
      '331027': {'lat': '28.231', 'name': 'Diloi South,Jhujhunu,Rajasthan', 'lon': '75.1496'},
      '509208': {'lat': '16.4004', 'name': 'Chityala,Mahabub Nagar,Telangana', 'lon': '77.7399'},
      '192231': {'lat': '33.3716', 'name': 'Ashmuji,Ananthnag,Jammu & Kashmir', 'lon': '75.1337'},
      '563138': {'lat': '13.5782', 'name': 'Somayajalapalli,Chikkaballapur,Karnataka', 'lon': '77.8362'},
      '563139': {'lat': '13.2354', 'name': 'Hassandahalli,Kolar,Karnataka', 'lon': '77.9507'},
      '563136': {'lat': '13.1502', 'name': 'Thimmaravathanahalli,Kolar,Karnataka', 'lon': '78.4112'},
      '563137': {'lat': '12.959', 'name': 'Tekal,Kolar,Karnataka', 'lon': '78.0652'},
      '563134': {'lat': '13.5177', 'name': 'Yarramavaripalli,Kolar,Karnataka', 'lon': '78.3418'},
      '563135': {'lat': '13.4439', 'name': 'Ronurthimmasandra,Kolar,Karnataka', 'lon': '78.0633'},
      '563132': {'lat': '13.2375', 'name': 'G.Marandahalli,Kolar,Karnataka', 'lon': '78.4874'},
      '563133': {'lat': '13.1382', 'name': 'Doddvallabi,Kolar,Karnataka', 'lon': '78.0047'},
      '563130': {'lat': '13.0838', 'name': 'Hurulagere,Kolar,Karnataka', 'lon': '77.8643'},
      '563131': {'lat': '13.0682', 'name': 'Angondahalli,Kolar,Karnataka', 'lon': '78.3643'},
      '250402': {'lat': '25.3881', 'name': 'Dhikauli,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '673509': {'lat': '11.7352', 'name': 'Parakkadavu,Kozhikode,Kerala', 'lon': '75.6542'},
      '673508': {'lat': '11.6411', 'name': 'Adukkath,Kozhikode,Kerala', 'lon': '75.755'},
      '673507': {'lat': '11.6484', 'name': 'Poolakkul,Kozhikode,Kerala', 'lon': '75.7074'},
      '673506': {'lat': '11.7228', 'name': 'Naripetta,Kozhikode,Kerala', 'lon': '75.7275'},
      '673505': {'lat': '11.7026', 'name': 'Mudavantheri,Kozhikode,Kerala', 'lon': '75.6191'},
      '673504': {'lat': '11.6846', 'name': 'Iyyangode,Kozhikode,Kerala', 'lon': '75.6549'},
      '673503': {'lat': '11.6555', 'name': 'Kuningad,Kozhikode,Kerala', 'lon': '75.6416'},
      '673502': {'lat': '11.6374', 'name': 'Edacheri North,Kozhikode,Kerala', 'lon': '76.0087'},
      '673501': {'lat': '11.6859', 'name': 'Eramala,Kozhikode,Kerala', 'lon': '75.591'},
      '250404': {'lat': '29.1886', 'name': 'Behsuma,Meerut,Uttar Pradesh', 'lon': '78.0085'},
      '700024': {'lat': '22.5439', 'name': 'T.G.Road,Kolkata,West Bengal', 'lon': '88.3067'},
      '502381': {'lat': '17.8531', 'name': 'Kongal Ghanpur,Medak,Telangana', 'lon': '78.6231'},
      '475335': {'lat': '25.7614', 'name': 'Tendot,Datia,Madhya Pradesh', 'lon': '78.746'},
      '475336': {'lat': '25.7769', 'name': 'Jouri,Datia,Madhya Pradesh', 'lon': '78.6956'},
      '475330': {'lat': '26.0114', 'name': 'Dhuan,Gwalior,Madhya Pradesh', 'lon': '78.2196'},
      '509351': {'lat': '16.4552', 'name': 'Theelair,Mahabub Nagar,Telangana', 'lon': '78.0781'},
      '509201': {'lat': '16.3519', 'name': 'Padara,Mahabub Nagar,Telangana', 'lon': '78.9215'},
      '271124': {'lat': '27.1864', 'name': 'Wazeerganj,Gonda,Uttar Pradesh', 'lon': '81.1374'},
      '712613': {'lat': '22.3893', 'name': 'Duadanda,Hooghly,West Bengal', 'lon': '88.4803'},
      '500009': {'lat': '17.3862', 'name': 'Manovikasnagar,Hyderabad,Telangana', 'lon': '78.462'},
      '314801': {'lat': '23.9609', 'name': 'Palisoda,Dungarpur,Rajasthan', 'lon': '73.5682'},
      '261302': {'lat': '27.3866', 'name': 'Shah Jalalpur,Sitapur,Uttar Pradesh', 'lon': '80.9502'},
      '261303': {'lat': '27.0527', 'name': 'Dhawarpara,Sitapur,Uttar Pradesh', 'lon': '80.7803'},
      '205301': {'lat': '27.226', 'name': 'Madhupuri,Mainpuri,Uttar Pradesh', 'lon': '79.2945'},
      '142003': {'lat': '30.4989', 'name': 'Moga Kty,Moga,Punjab', 'lon': '74.28'},
      '142002': {'lat': '30.4989', 'name': 'Chand Nau,Moga,Punjab', 'lon': '74.28'},
      '142001': {'lat': '30.4989', 'name': 'Industrial Area Moga,Moga,Punjab', 'lon': '74.28'},
      '610202': {'lat': '10.6374', 'name': 'Mavoor (Tiruvarur),Tiruvarur,Tamil Nadu', 'lon': '79.6317'},
      '712614': {'lat': '22.8434', 'name': 'Kantali,Hooghly,West Bengal', 'lon': '87.976'},
      '250005': {'lat': '28.7297', 'name': 'MIET,Meerut,Uttar Pradesh', 'lon': '78.0032'},
      '250004': {'lat': '28.2888', 'name': 'Shastri Nagar (Meerut),Meerut,Uttar Pradesh', 'lon': '78.6904'},
      '205303': {'lat': '27.023', 'name': 'Naigwan -kheria,Mainpuri,Uttar Pradesh', 'lon': '79.2584'},
      '250001': {'lat': '28.6839', 'name': 'Boundary Road,Meerut,Uttar Pradesh', 'lon': '78.015'},
      '314804': {'lat': '23.935', 'name': 'Bhiyana,Dungarpur,Rajasthan', 'lon': '73.4979'},
      '250003': {'lat': '28.987', 'name': 'Meerut Kutchery,Meerut,Uttar Pradesh', 'lon': '77.7263'},
      '457661': {'lat': '22.6242', 'name': 'Kardawad Badi,Jhabua,Madhya Pradesh', 'lon': '74.6834'},
      '326502': {'lat': '25.5195', 'name': 'Ghatod,Jhalawar,Rajasthan', 'lon': '74.4996'},
      '712616': {'lat': '24.3551', 'name': 'Balidewanganj,Hooghly,West Bengal', 'lon': '87.7809'},
      '144626': {'lat': '31.194', 'name': 'Sherpur Dona,Kapurthala,Punjab', 'lon': '75.2831'},
      '700026': {'lat': '22.4826', 'name': 'Kalimandir,Kolkata,West Bengal', 'lon': '88.4744'},
      '800002': {'lat': '25.5783', 'name': 'Anisabad,Patna,Bihar', 'lon': '85.1262'},
      '323021': {'lat': '25.2745', 'name': 'Dehit,Bundi,Rajasthan', 'lon': '75.6318'},
      '450112': {'lat': '22.0986', 'name': 'Gulgaon Raiyat,East Nimar,Madhya Pradesh', 'lon': '76.5028'},
      '486771': {'lat': '24.1839', 'name': 'Badkhara,Sidhi,Madhya Pradesh', 'lon': '81.5841'},
      '465667': {'lat': '23.5322', 'name': 'Geelakhedi,Rajgarh,Madhya Pradesh', 'lon': '76.4052'},
      '507114': {'lat': '17.8741', 'name': 'Burgampahad,Khammam,Telangana', 'lon': '80.1765'},
      '486776': {'lat': '24.3121', 'name': 'Bagad,Sidhi,Madhya Pradesh', 'lon': '81.376'},
      '144629': {'lat': '31.0919', 'name': 'Bara Jodh Singh,Jalandhar,Punjab', 'lon': '75.3178'},
      '501504': {'lat': '17.3502', 'name': 'Chilkur,K.V.Rangareddy,Telangana', 'lon': '78.2877'},
      '638301': {'lat': '11.5322', 'name': 'Bhavani Kudal,Erode,Tamil Nadu', 'lon': '77.6114'},
      '521401': {'lat': '16.9006', 'name': 'Konijerla,Krishna,Andhra Pradesh', 'lon': '80.972'},
      '521402': {'lat': '16.9832', 'name': 'Khambampadu,Krishna,Andhra Pradesh', 'lon': '80.3696'},
      '521403': {'lat': '16.9996', 'name': 'Kanumuru,Krishna,Andhra Pradesh', 'lon': '80.5619'},
      '621211': {'lat': '10.6493', 'name': 'Thumbalam,Tiruchirappalli,Tamil Nadu', 'lon': '78.3691'},
      '416614': {'lat': '19.9327', 'name': 'Waingani,Sindhudurg,Maharashtra', 'lon': '73.5291'},
      '416615': {'lat': '18.0062', 'name': 'Mithbav,Sindhudurg,Maharashtra', 'lon': '73.7666'},
      '416616': {'lat': '21.5947', 'name': 'Sandave,Sindhudurg,Maharashtra', 'lon': '74.1045'},
      '476332': {'lat': '25.5902', 'name': 'Dhondha,Sheopur,Madhya Pradesh', 'lon': '77.4858'},
      '416610': {'lat': '16.3762', 'name': 'Asalda,Sindhudurg,Maharashtra', 'lon': '73.5747'},
      '416611': {'lat': '18.0062', 'name': 'Talavade,Sindhudurg,Maharashtra', 'lon': '73.7666'},
      '416612': {'lat': '15.7513', 'name': 'Ilye,Sindhudurg,Maharashtra', 'lon': '74.0498'},
      '416613': {'lat': '16.3762', 'name': 'Taramumbari,Sindhudurg,Maharashtra', 'lon': '73.5747'},
      '801108': {'lat': '25.5782', 'name': 'Rampur Diara,Patna,Bihar', 'lon': '84.8481'},
      '621213': {'lat': '10.8521', 'name': 'Pukkathurai,Tiruchirappalli,Tamil Nadu', 'lon': '79.1837'},
      '380058': {'lat': '22.6573', 'name': 'Ghuma,Ahmedabad,Gujarat', 'lon': '74.1152'},
      '380059': {'lat': '23.0489', 'name': 'Shilaj,Ahmedabad,Gujarat', 'lon': '72.5123'},
      '626118': {'lat': '9.4224', 'name': 'M.Reddiapatti,Virudhunagar,Tamil Nadu', 'lon': '78.219'},
      '586105': {'lat': '16.6854', 'name': 'KSWU Campus Torvi,Vijayapura,Karnataka', 'lon': '76.0927'},
      '380054': {'lat': '22.9195', 'name': 'Bodakdev,Ahmedabad,Gujarat', 'lon': '73.0538'},
      '380055': {'lat': '22.9195', 'name': 'Juhapura,Ahmedabad,Gujarat', 'lon': '73.0538'},
      '384001': {'lat': '23.8153', 'name': 'Virta,Mahesana,Gujarat', 'lon': '73.1628'},
      '626115': {'lat': '9.7403', 'name': 'Kurandi,Virudhunagar,Tamil Nadu', 'lon': '78.1007'},
      '380050': {'lat': '22.9195', 'name': 'Ghodasar (Ahmedabad),Ahmedabad,Gujarat', 'lon': '73.0538'},
      '380051': {'lat': '22.9195', 'name': 'Jivraj Park,Ahmedabad,Gujarat', 'lon': '73.0538'},
      '380052': {'lat': '23.0522', 'name': 'Memnagar,Ahmedabad,Gujarat', 'lon': '72.5338'},
      '626111': {'lat': '9.4775', 'name': 'Puthur,Virudhunagar,Tamil Nadu', 'lon': '77.6896'},
      '415709': {'lat': '19.8506', 'name': 'Morvande,Ratnagiri,Maharashtra', 'lon': '75.4848'},
      '415708': {'lat': '17.6214', 'name': 'Lavel,Ratnagiri,Maharashtra', 'lon': '73.45'},
      '415706': {'lat': '17.5897', 'name': 'Umbarghar,Ratnagiri,Maharashtra', 'lon': '73.18'},
      '388580': {'lat': '22.8036', 'name': 'Jalsan,Anand,Gujarat', 'lon': '72.9322'},
      '621217': {'lat': '11.6088', 'name': 'Velakkanatham,Tiruchirappalli,Tamil Nadu', 'lon': '79.4994'},
      '414001': {'lat': '19.0512', 'name': 'Ahmednagar H.O,Ahmed Nagar,Maharashtra', 'lon': '75.1407'},
      '415702': {'lat': '17.51', 'name': 'Umroli,Ratnagiri,Maharashtra', 'lon': '73.3236'},
      '415701': {'lat': '16.5983', 'name': 'Waghivare,Ratnagiri,Maharashtra', 'lon': '74.5122'},
      '621218': {'lat': '10.8629', 'name': 'Edayathimangalam,Ariyalur,Tamil Nadu', 'lon': '78.7713'},
      '455111': {'lat': '22.9255', 'name': 'Tigiria Goga,Dewas,Madhya Pradesh', 'lon': '76.0493'},
      '263639': {'lat': '29.8657', 'name': 'Kantali,Almora,Uttarakhand', 'lon': '79.6235'},
      '793200': {'lat': '25.3816', 'name': 'Shnongrim,Jaintia Hills,Meghalaya', 'lon': '92.4569'},
      '628601': {'lat': '8.5768', 'name': 'Srivaikuntam H.O,Tuticorin,Tamil Nadu', 'lon': '77.8769'},
      '786601': {'lat': '27.3723', 'name': 'Balijan T.E.,Tinsukia,Assam', 'lon': '95.3075'},
      '502210': {'lat': '17.8814', 'name': 'Sajjapur,Medak,Telangana', 'lon': '77.7587'},
      '144702': {'lat': '31.1476', 'name': 'Mianwal Arrayan,Jalandhar,Punjab', 'lon': '75.2636'},
      '574242': {'lat': '13.0223', 'name': 'Kokrady,Dakshina Kannada,Karnataka', 'lon': '75.1505'},
      '581318': {'lat': '14.5483', 'name': 'Sugavi,Uttara Kannada,Karnataka', 'lon': '74.9871'},
      '627761': {'lat': '8.7987', 'name': 'Periyur,Tirunelveli,Tamil Nadu', 'lon': '77.542'},
      '134003': {'lat': '30.2792', 'name': 'Railway Road Ambala name,Ambala,Haryana', 'lon': '76.7113'},
      '621851': {'lat': '10.9699', 'name': 'Koilesanai,Ariyalur,Tamil Nadu', 'lon': '79.0255'},
      '232111': {'lat': '25.3881', 'name': 'Amdaha,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '232110': {'lat': '25.6318', 'name': 'Ghosawa,Varanasi,Uttar Pradesh', 'lon': '82.5088'},
      '752026': {'lat': '20.4519', 'name': 'Baunsagada,Nayagarh,Odisha', 'lon': '84.71'},
      '752027': {'lat': '20.2874', 'name': 'Soran,Khorda,Odisha', 'lon': '85.223'},
      '752024': {'lat': '20.5722', 'name': 'Champagarh,Nayagarh,Odisha', 'lon': '85.1906'},
      '752025': {'lat': '20.6174', 'name': 'Nakithana,Nayagarh,Odisha', 'lon': '84.7342'},
      '752022': {'lat': '21.7358', 'name': 'Kaluparaghat,Khorda,Odisha', 'lon': '85.6465'},
      '752023': {'lat': '20.5614', 'name': 'Badapari,Khorda,Odisha', 'lon': '85.9918'},
      '752020': {'lat': '20.1015', 'name': 'Olasingh,Khorda,Odisha', 'lon': '85.2865'},
      '752021': {'lat': '20.731', 'name': 'Tankol,Khorda,Odisha', 'lon': '85.2562'},
      '561208': {'lat': '13.3792', 'name': 'Gauribidanur Bzr,Chikkaballapur,Karnataka', 'lon': '77.2818'},
      '625001': {'lat': '10.0042', 'name': 'Vilakkuthoon,Madurai,Tamil Nadu', 'lon': '78.1748'},
      '421305': {'lat': '19.2166', 'name': 'Vidyashram,Thane,Maharashtra', 'lon': '73.2176'},
      '421306': {'lat': '18.946', 'name': 'Ganeshwadi (Thane),Thane,Maharashtra', 'lon': '73.0673'},
      '561209': {'lat': '13.2141', 'name': 'Chowtakuntapalli,Chikkaballapur,Karnataka', 'lon': '77.1494'},
      '625005': {'lat': '9.8572', 'name': 'Harveypatti,Madurai,Tamil Nadu', 'lon': '78.0598'},
      '421301': {'lat': '19.2166', 'name': 'Subhash Road (Thane),Thane,Maharashtra', 'lon': '73.2176'},
      '421302': {'lat': '19.2166', 'name': 'Kunde,Thane,Maharashtra', 'lon': '73.2176'},
      '421303': {'lat': '19.3081', 'name': 'Kone,Thane,Maharashtra', 'lon': '73.3095'},
      '625009': {'lat': '9.8908', 'name': 'Viradhanur,Madurai,Tamil Nadu', 'lon': '78.1503'},
      '625008': {'lat': '9.8444', 'name': 'Sivanandanagar,Madurai,Tamil Nadu', 'lon': '78.0164'},
      '421308': {'lat': '19.3002', 'name': 'Bhiwandi,Thane,Maharashtra', 'lon': '73.0588'},
      '570028': {'lat': '12.2907', 'name': 'Vajamangala,Mysuru,Karnataka', 'lon': '76.7375'},
      '570029': {'lat': '12.3537', 'name': 'Shakthinagar,Mysuru,Karnataka', 'lon': '76.5368'},
      '764072': {'lat': '19.667615', 'name': 'B.S.Padar,Nabarangapur,Odisha', 'lon': '82.206446'},
      '764073': {'lat': '19.667611', 'name': 'Jamranda,Nabarangapur,Odisha', 'lon': '82.20645'},
      '517571': {'lat': '13.4673', 'name': 'Alimelumangapuram,Chittoor,Andhra Pradesh', 'lon': '79.0143'},
      '764075': {'lat': '19.2333', 'name': 'Betal,Nabarangapur,Odisha', 'lon': '82.4211'},
      '764076': {'lat': '19.6519', 'name': 'Chitabeda,Nabarangapur,Odisha', 'lon': '82.3262'},
      '764077': {'lat': '19.8087', 'name': 'Patkhalia,Nabarangapur,Odisha', 'lon': '82.4678'},
      '570020': {'lat': '12.3537', 'name': 'Yadavagiri,Mysuru,Karnataka', 'lon': '76.5368'},
      '570022': {'lat': '12.3537', 'name': 'Ramakrishna Nagar (Mysuru),Mysuru,Karnataka', 'lon': '76.5368'},
      '394180': {'lat': '22.0054', 'name': 'Nansad,Surat,Gujarat', 'lon': '73.4336'},
      '222202': {'lat': '25.746', 'name': 'Kunwarpur,Jaunpur,Uttar Pradesh', 'lon': '82.5759'},
      '570025': {'lat': '12.3537', 'name': 'G S Ashram,Mysuru,Karnataka', 'lon': '76.5368'},
      '394185': {'lat': '22.7979', 'name': 'Kamrej Char Rasta,Surat,Gujarat', 'lon': '72.5787'},
      '570027': {'lat': '12.3537', 'name': 'Infosys Campus,Mysuru,Karnataka', 'lon': '76.5368'},
      '713201': {'lat': '24.2028', 'name': 'Durgapur Rabindra Pally,Paschim Bardhaman,West Bengal', 'lon': '87.9815'},
      '451440': {'lat': '21.8593', 'name': 'Satawad,West Nimar,Madhya Pradesh', 'lon': '75.4141'},
      '713203': {'lat': '24.2028', 'name': 'Bhiringi,Paschim Bardhaman,West Bengal', 'lon': '87.9815'},
      '713202': {'lat': '24.2028', 'name': 'Durgapur Coke Oven Colony,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '591119': {'lat': '15.881', 'name': 'Hirebudnur,Belagavi,Karnataka', 'lon': '75.025'},
      '591118': {'lat': '15.8282', 'name': 'Veerapur,Belagavi,Karnataka', 'lon': '75.0525'},
      '713207': {'lat': '24.2028', 'name': 'Durgapur Thermal Power Station,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '713206': {'lat': '24.2028', 'name': 'Fuljhore,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '591115': {'lat': '15.6739', 'name': 'Turkarshigihalli,Belagavi,Karnataka', 'lon': '74.8739'},
      '591114': {'lat': '15.9519', 'name': 'Sidnal,Belagavi,Karnataka', 'lon': '75.1064'},
      '591117': {'lat': '15.8822', 'name': 'Aratgal,Belagavi,Karnataka', 'lon': '75.0798'},
      '591111': {'lat': '15.8224', 'name': 'Hosur (Belgaum),Belagavi,Karnataka', 'lon': '74.9982'},
      '591110': {'lat': '15.7256', 'name': 'Dhaderkoppa,Belagavi,Karnataka', 'lon': '75.2411'},
      '591113': {'lat': '15.8282', 'name': 'Kakati,Belagavi,Karnataka', 'lon': '75.0525'},
      '591112': {'lat': '15.9722', 'name': 'Margankop,Belagavi,Karnataka', 'lon': '75.4318'},
      '733210': {'lat': '25.2082', 'name': 'Kichaktola,North Dinajpur,West Bengal', 'lon': '87.9116'},
      '483105': {'lat': '23.3722', 'name': 'Murrai,Jabalpur,Madhya Pradesh', 'lon': '79.8432'},
      '370665': {'lat': '23.3833', 'name': 'Nani Aral,Kachchh,Gujarat', 'lon': '69.2667'},
      '370660': {'lat': '23.2864', 'name': 'Bara Mota,Kachchh,Gujarat', 'lon': '68.9389'},
      '533262': {'lat': '17.0696', 'name': 'Kunduru,East Godavari,Andhra Pradesh', 'lon': '82.2028'},
      '303107': {'lat': '27.4446', 'name': 'Pathredi,Jaipur,Rajasthan', 'lon': '75.891'},
      '303106': {'lat': '26.8782', 'name': 'Jaisinghpura,Jaipur,Rajasthan', 'lon': '75.6011'},
      '303105': {'lat': '27.6788', 'name': 'Purshottampura,Jaipur,Rajasthan', 'lon': '76.3872'},
      '303104': {'lat': '27.2674', 'name': 'Lakher,Jaipur,Rajasthan', 'lon': '75.9709'},
      '303103': {'lat': '27.1862', 'name': 'Chhapra Kalan (Shahpura),Jaipur,Rajasthan', 'lon': '75.9836'},
      '303102': {'lat': '27.3498', 'name': 'Kuthada,Jaipur,Rajasthan', 'lon': '75.981'},
      '306421': {'lat': '25.7778', 'name': 'Kulthana,Pali,Rajasthan', 'lon': '73.1777'},
      '623806': {'lat': '9.5148', 'name': 'Sathaksalai,Ramanathapuram,Tamil Nadu', 'lon': '78.6237'},
      '382760': {'lat': '23.1465', 'name': 'Kharaghoda,Surendra Nagar,Gujarat', 'lon': '72.6134'},
      '792131': {'lat': '27.0217', 'name': 'Minthong,londing,Arunachal Pradesh', 'lon': '95.5368'},
      '457119': {'lat': '23.6081', 'name': 'Thambguradiya,Ratlam,Madhya Pradesh', 'lon': '75.4379'},
      '451447': {'lat': '21.8859', 'name': 'Wajhar,Barwani,Madhya Pradesh', 'lon': '75.039'},
      '382765': {'lat': '23.1465', 'name': 'Bamanva,Surendra Nagar,Gujarat', 'lon': '72.6134'},
      '303109': {'lat': '27.3956', 'name': 'Kharkara,Jaipur,Rajasthan', 'lon': '75.8928'},
      '303108': {'lat': '27.2541', 'name': 'Mordha,Jaipur,Rajasthan', 'lon': '75.8886'},
      '585402': {'lat': '17.9373', 'name': 'Naubad,Bidar,Karnataka', 'lon': '77.4016'},
      '741248': {'lat': '23.4733', 'name': 'Narapatipara,Nadia,West Bengal', 'lon': '88.1624'},
      '493222': {'lat': '21.6176', 'name': 'Salonikala,Raipur,Chattisgarh', 'lon': '82.8279'},
      '585403': {'lat': '17.6354', 'name': 'Chintalgera,Bidar,Karnataka', 'lon': '77.1288'},
      '221012': {'lat': '25.3179', 'name': 'Kustha Seva Ashram,Varanasi,Uttar Pradesh', 'lon': '82.9271'},
      '473990': {'lat': '25.0421', 'name': 'Kumhrraondi,Shivpuri,Madhya Pradesh', 'lon': '78.1268'},
      '321601': {'lat': '27.3673', 'name': 'Moloni,Bharatpur,Rajasthan', 'lon': '77.0989'},
      '638461': {'lat': '11.7334', 'name': 'Chikkahalli,Erode,Tamil Nadu', 'lon': '76.9441'},
      '638460': {'lat': '11.6425', 'name': 'Savakattupalayam,Erode,Tamil Nadu', 'lon': '77.0264'},
      '638462': {'lat': '11.3697', 'name': 'Thirumalaigoundenpalayam,Coimbatore,Tamil Nadu', 'lon': '77.2733'},
      '444510': {'lat': '20.1904', 'name': 'Assegaon Pen,Washim,Maharashtra', 'lon': '77.3217'},
      '385535': {'lat': '24.0719', 'name': 'Disa Mukhya Dak Ghar,Banaskantha,Gujarat', 'lon': '71.938'},
      '560104': {'lat': '13.0907', 'name': 'Hampinagar,Bengaluru,Karnataka', 'lon': '77.6423'},
      '560105': {'lat': '12.7861', 'name': 'Hennagara,Bengaluru,Karnataka', 'lon': '77.6385'},
      '385530': {'lat': '24.1679', 'name': 'Nesada-juna,Banaskantha,Gujarat', 'lon': '71.9945'},
      '560103': {'lat': '13.0907', 'name': 'Bellandur,Bengaluru,Karnataka', 'lon': '77.6423'},
      '560100': {'lat': '13.2257', 'name': 'Electronics name,Bengaluru,Karnataka', 'lon': '77.575'},
      '271123': {'lat': '26.8338', 'name': 'Bhgahar Buland,Gonda,Uttar Pradesh', 'lon': '82.0445'},
      '509110': {'lat': '16.8736', 'name': 'Dantanur,Mahabub Nagar,Telangana', 'lon': '77.7858'},
      '560108': {'lat': '13.2257', 'name': 'Anjanapura,Bengaluru,Karnataka', 'lon': '77.575'},
      '560109': {'lat': '13.0907', 'name': 'Thalaghattapura,Bengaluru,Karnataka', 'lon': '77.6423'},
      '382815': {'lat': '24.0651', 'name': 'Gavada,Mahesana,Gujarat', 'lon': '72.4215'},
      '382810': {'lat': '23.5247', 'name': 'Ajol,Gandhi Nagar,Gujarat', 'lon': '72.6522'},
      '321602': {'lat': '27.1249', 'name': 'Anchera,Bharatpur,Rajasthan', 'lon': '76.3026'},
      '193302': {'lat': '34.3179', 'name': 'Langate,Baramulla,Jammu & Kashmir', 'lon': '74.4305'},
      '394345': {'lat': '21.1727', 'name': 'Umrakh,Surat,Gujarat', 'lon': '73.0681'},
      '721633': {'lat': '22.6606', 'name': 'Kotebarh,East Midnapore,West Bengal', 'lon': '88.0631'},
      '193301': {'lat': '34.1459', 'name': 'Chatloosa,Baramulla,Jammu & Kashmir', 'lon': '74.6565'},
      '193306': {'lat': '34.2319', 'name': 'Rajpora,Pulwama,Jammu & Kashmir', 'lon': '74.5435'},
      '721634': {'lat': '22.3623', 'name': 'Dabuapukur,East Midnapore,West Bengal', 'lon': '87.7811'},
      '721637': {'lat': '22.4545', 'name': 'Biswas,East Midnapore,West Bengal', 'lon': '88.1278'},
      '637003': {'lat': '11.2359', 'name': 'Pappinaickenpatti,Namakkal,Tamil Nadu', 'lon': '78.1294'},
      '482004': {'lat': '23.2133', 'name': 'Amkhera,Jabalpur,Madhya Pradesh', 'lon': '79.9334'},
      '401101': {'lat': '19.4115', 'name': 'Rai,Thane,Maharashtra', 'lon': '73.396'},
      '401102': {'lat': '19.4226', 'name': 'Dhekale,Thane,Maharashtra', 'lon': '74.3807'},
      '401103': {'lat': '19.6574', 'name': 'Bavada,Thane,Maharashtra', 'lon': '73.0343'},
      '401105': {'lat': '19.4115', 'name': 'Bhayander East,Thane,Maharashtra', 'lon': '73.396'},
      '401106': {'lat': '19.2691', 'name': 'Uttan,Thane,Maharashtra', 'lon': '72.8096'},
      '401107': {'lat': '19.2836', 'name': 'Mira,Thane,Maharashtra', 'lon': '72.8675'},
      '203002': {'lat': '28.336', 'name': 'Naithala  Hasanpur,Bulandshahr,Uttar Pradesh', 'lon': '77.8354'},
      '689711': {'lat': '9.5077', 'name': 'Adichipuzha,Pathanamthitta,Kerala', 'lon': '76.5929'},
      '637001': {'lat': '11.1561', 'name': 'Namakkal Bazaar,Namakkal,Tamil Nadu', 'lon': '78.1905'},
      '737120': {'lat': '27.7867', 'name': 'Naga,North Sikkim,Sikkim', 'lon': '88.60382'},
      '221010': {'lat': '25.3076', 'name': 'Nagar Maha Palika,Varanasi,Uttar Pradesh', 'lon': '82.9933'},
      '815351': {'lat': '24.0632', 'name': 'Koiri Jamua,Jamtara,Jharkhand', 'lon': '86.7649'},
      '759037': {'lat': '20.92179', 'name': 'Samal Barrage Township,Angul,Odisha', 'lon': '85.7751'},
      '759039': {'lat': '21.1202', 'name': 'Badasuanlo,Dhenkanal,Odisha', 'lon': '85.4978'},
      '624601': {'lat': '10.4656', 'name': 'Palani East,Dindigul,Tamil Nadu', 'lon': '77.7684'},
      '394340': {'lat': '21.5202', 'name': 'Manekpor,Surat,Gujarat', 'lon': '74.2515'},
      '335025': {'lat': '29.7142', 'name': 'Ladhuwala,Ganganagar,Rajasthan', 'lon': '73.8832'},
      '335024': {'lat': '29.7739', 'name': '23 Ff,Hanumangarh,Rajasthan', 'lon': '73.8479'},
      '335027': {'lat': '29.597', 'name': 'Malkana Kalan,Ganganagar,Rajasthan', 'lon': '73.9423'},
      '335021': {'lat': '28.236', 'name': 'Sanwatsar,Ganganagar,Rajasthan', 'lon': '75.7638'},
      '335023': {'lat': '29.5781', 'name': 'Chak 30 H,Ganganagar,Rajasthan', 'lon': '74.0525'},
      '335022': {'lat': '29.8101', 'name': 'Chak 4 G Choti,Ganganagar,Rajasthan', 'lon': '73.728'},
      '224125': {'lat': '26.2841', 'name': 'Pakhanpurbichaila,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6992'},
      '332716': {'lat': '27.7322', 'name': 'Dalelpura,Jhujhunu,Rajasthan', 'lon': '76.1583'},
      '224127': {'lat': '26.4201', 'name': 'Sirhir,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '224126': {'lat': '26.6547', 'name': 'Maholi Uprahar,Faizabad,Uttar Pradesh', 'lon': '82.0736'},
      '224121': {'lat': '26.5542', 'name': 'Mahmedpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.1167'},
      '224120': {'lat': '26.6949', 'name': 'Hariharpur Kayasthan,Faizabad,Uttar Pradesh', 'lon': '81.5723'},
      '224123': {'lat': '26.6469', 'name': 'Swarg Dwar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.2665'},
      '457779': {'lat': '22.8906', 'name': 'Kheda,Jhabua,Madhya Pradesh', 'lon': '74.6718'},
      '531001': {'lat': '17.5315', 'name': 'Gandhinagaram (Visakhapatnam),Visakhapatnam,Andhra Pradesh', 'lon': '82.6071'},
      '737121': {'lat': '27.18305', 'name': 'Sanchabarey Daramdim,South Sikkim,Sikkim', 'lon': '88.26869'},
      '531002': {'lat': '17.5315', 'name': 'Batlapudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.6071'},
      '576257': {'lat': '13.6614', 'name': 'Vakwadi,Udupi,Karnataka', 'lon': '74.7853'},
      '246495': {'lat': '30.1579', 'name': 'Kurjhan,Rudraprayag,Uttarakhand', 'lon': '79.3713'},
      '506349': {'lat': '18.0544', 'name': 'Bollonipalli,Warangal,Telangana', 'lon': '79.491'},
      '680671': {'lat': '10.2316', 'name': 'Kara,Thrissur,Kerala', 'lon': '76.1528'},
      '680670': {'lat': '10.2359', 'name': 'Karupadanna,Thrissur,Kerala', 'lon': '76.1619'},
      '227807': {'lat': '26.7409', 'name': 'Hardoia,Sultanpur,Uttar Pradesh', 'lon': '81.3874'},
      '227806': {'lat': '26.216', 'name': 'Bhakhari,Sultanpur,Uttar Pradesh', 'lon': '82.1248'},
      '227805': {'lat': '26.4073', 'name': 'Majhana,Sultanpur,Uttar Pradesh', 'lon': '81.8068'},
      '227801': {'lat': '26.3582', 'name': 'Umra Dih,Sultanpur,Uttar Pradesh', 'lon': '81.8384'},
      '414305': {'lat': '19.2034', 'name': 'Kalas,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '476001': {'lat': '26.3732', 'name': 'Hetampur,Morena,Madhya Pradesh', 'lon': '78.2301'},
      '636121': {'lat': '11.5805', 'name': 'Valayamadevi,Salem,Tamil Nadu', 'lon': '78.6974'},
      '282009': {'lat': '27.1009', 'name': 'Nainana Jat,Agra,Uttar Pradesh', 'lon': '78.017'},
      '227808': {'lat': '26.2751', 'name': 'Maniyarpur,Sultanpur,Uttar Pradesh', 'lon': '81.8807'},
      '508250': {'lat': '17.0993', 'name': 'Kurmedu,Nalgonda,Telangana', 'lon': '79.049'},
      '508252': {'lat': '17.3526', 'name': 'Devalammanagaram,Nalgonda,Telangana', 'lon': '78.9535'},
      '457777': {'lat': '23.1044', 'name': 'Miyati,Jhabua,Madhya Pradesh', 'lon': '75.0302'},
      '508254': {'lat': '17.2518', 'name': 'Yellareddyguda,Nalgonda,Telangana', 'lon': '79.2387'},
      '394163': {'lat': '21.1875', 'name': 'Fali,Surat,Gujarat', 'lon': '73.3786'},
      '508256': {'lat': '16.8399', 'name': 'Thanedarpally,Nalgonda,Telangana', 'lon': '79.1442'},
      '532214': {'lat': '18.5609', 'name': 'Korasavada,Srikakulam,Andhra Pradesh', 'lon': '83.7854'},
      '508258': {'lat': '16.988', 'name': 'Bogguladone,Nalgonda,Telangana', 'lon': '78.8363'},
      '471001': {'lat': '25.5503', 'name': 'Pannanaka Chhatarpur,Chhatarpur,Madhya Pradesh', 'lon': '79.2954'},
      '464337': {'lat': '23.5142', 'name': 'Pathari (Vidisha),Vidisha,Madhya Pradesh', 'lon': '78.6979'},
      '464334': {'lat': '23.776', 'name': 'Mainwada,Raisen,Madhya Pradesh', 'lon': '78.2396'},
      '462036': {'lat': '23.4091', 'name': 'Tara Sewaniya,Bhopal,Madhya Pradesh', 'lon': '76.4504'},
      '532216': {'lat': '18.7704', 'name': 'Jhadupalli,Srikakulam,Andhra Pradesh', 'lon': '84.1853'},
      '151212': {'lat': '30.7018', 'name': 'Mehmuana,Faridkot,Punjab', 'lon': '74.6233'},
      '151213': {'lat': '30.7452', 'name': 'Dhurkot,Faridkot,Punjab', 'lon': '74.7833'},
      '786159': {'lat': '27.6999', 'name': 'Sukriting,Tinsukia,Assam', 'lon': '95.6124'},
      '786158': {'lat': '27.6999', 'name': 'Natun Balijan,Tinsukia,Assam', 'lon': '95.6124'},
      '786157': {'lat': '27.6999', 'name': 'Santipur Bazar,Tinsukia,Assam', 'lon': '95.6124'},
      '786156': {'lat': '27.6999', 'name': 'Kordoiguri,Tinsukia,Assam', 'lon': '95.6124'},
      '786155': {'lat': '27.831', 'name': 'Sadiya,Tinsukia,Assam', 'lon': '95.6682'},
      '786154': {'lat': '27.6999', 'name': 'Hatighuli,Tinsukia,Assam', 'lon': '95.6124'},
      '786153': {'lat': '27.6999', 'name': 'Rupaisiding,Tinsukia,Assam', 'lon': '95.6124'},
      '786152': {'lat': '27.6999', 'name': 'Tonganagaon,Tinsukia,Assam', 'lon': '95.6124'},
      '786151': {'lat': '27.5688', 'name': 'Dohotiagaon,Tinsukia,Assam', 'lon': '95.5566'},
      '786150': {'lat': '27.6999', 'name': 'Barhapjan,Tinsukia,Assam', 'lon': '95.6124'},
      '472101': {'lat': '24.9766', 'name': 'Iqbal Pura,Tikamgarh,Madhya Pradesh', 'lon': '78.6383'},
      '466665': {'lat': '23.2359', 'name': 'Chandbad  Jageer,Sehore,Madhya Pradesh', 'lon': '77.7254'},
      '829111': {'lat': '23.78', 'name': 'Hossir,Bokaro,Jharkhand', 'lon': '85.8276'},
      '829110': {'lat': '23.5372', 'name': 'Korrambey,Ramgarh,Jharkhand', 'lon': '85.6728'},
      '829113': {'lat': '23.7554', 'name': 'Jarangdih Colliery,Bokaro,Jharkhand', 'lon': '85.9122'},
      '829112': {'lat': '23.9106', 'name': 'I.E.Gomia,Bokaro,Jharkhand', 'lon': '85.8753'},
      '829114': {'lat': '23.7566', 'name': 'Jaridih Bazar,Bokaro,Jharkhand', 'lon': '85.9556'},
      '829117': {'lat': '23.5907', 'name': 'Kander,Ramgarh,Jharkhand', 'lon': '85.5012'},
      '829116': {'lat': '23.6915', 'name': 'Kathara,Bokaro,Jharkhand', 'lon': '85.988'},
      '816117': {'lat': '24.3693', 'name': 'Karudih,Dumka,Jharkhand', 'lon': '87.5613'},
      '816116': {'lat': '24.678', 'name': 'Bara Durgapur,Sahibganj,Jharkhand', 'lon': '87.691'},
      '816115': {'lat': '24.6159', 'name': 'Sakrigali Ghat,Sahibganj,Jharkhand', 'lon': '87.4398'},
      '505531': {'lat': '18.5657', 'name': 'Laxmipur,Karim Nagar,Telangana', 'lon': '79.0531'},
      '500016': {'lat': '17.4427', 'name': 'Begumpet,Hyderabad,Telangana', 'lon': '78.4751'},
      '500017': {'lat': '17.4381', 'name': 'Lallapet,Hyderabad,Telangana', 'lon': '78.5406'},
      '500014': {'lat': '17.401', 'name': 'Hakimpet,Hyderabad,Telangana', 'lon': '78.417'},
      '816110': {'lat': '24.9602', 'name': 'Pathna,Sahibganj,Jharkhand', 'lon': '87.3242'},
      '365460': {'lat': '21.3025', 'name': 'Dadva(Randal),Amreli,Gujarat', 'lon': '71.1809'},
      '518323': {'lat': '15.6673', 'name': 'Kapati,Kurnool,Andhra Pradesh', 'lon': '77.3346'},
      '612504': {'lat': '11.0677', 'name': 'Manikudi,Thanjavur,Tamil Nadu', 'lon': '79.4253'},
      '846004': {'lat': '25.9469', 'name': 'Darbhanga Chowk,Darbhanga,Bihar', 'lon': '85.9862'},
      '846005': {'lat': '26.4629', 'name': 'B.K.Asthan,Darbhanga,Bihar', 'lon': '86.2771'},
      '846006': {'lat': '26.1138', 'name': 'Subhankarpur,Darbhanga,Bihar', 'lon': '86.0253'},
      '846007': {'lat': '25.9464', 'name': 'G.Basailha,Darbhanga,Bihar', 'lon': '86.3187'},
      '445001': {'lat': '20.7045', 'name': 'Yavatmal Town,Yavatmal,Maharashtra', 'lon': '77.8924'},
      '736133': {'lat': '25.2821', 'name': 'Chaudanga,Cooch Behar,West Bengal', 'lon': '88.0425'},
      '445002': {'lat': '20.3449', 'name': 'Kinhi,Yavatmal,Maharashtra', 'lon': '78.0839'},
      '585228': {'lat': '17.1993', 'name': 'Hongunta,Kalaburagi,Karnataka', 'lon': '76.9358'},
      '736134': {'lat': '25.8631', 'name': 'Ruerkuthi,Cooch Behar,West Bengal', 'lon': '87.9353'},
      '363001': {'lat': '22.505', 'name': 'Surendranagar H.O,Surendra Nagar,Gujarat', 'lon': '72.0805'},
      '332712': {'lat': '27.1787', 'name': 'Nangal Nathusar,Sikar,Rajasthan', 'lon': '76.0506'},
      '603308': {'lat': '12.5926', 'name': 'Padalam,Kanchipuram,Tamil Nadu', 'lon': '79.9497'},
      '585229': {'lat': '17.0062', 'name': 'Shahabad ACC,Kalaburagi,Karnataka', 'lon': '76.9926'},
      '502314': {'lat': '17.8339', 'name': 'Konyal,Medak,Telangana', 'lon': '78.1212'},
      '312901': {'lat': '25.0452', 'name': 'Chogawadi,Chittorgarh,Rajasthan', 'lon': '74.5557'},
      '515455': {'lat': '15.0267', 'name': 'Nagarur,Ananthapur,Andhra Pradesh', 'lon': '77.79'},
      '603301': {'lat': '12.3968', 'name': 'Kayapakkam,Kanchipuram,Tamil Nadu', 'lon': '79.8244'},
      '603306': {'lat': '12.4477', 'name': 'Morapakkam,Kanchipuram,Tamil Nadu', 'lon': '79.8541'},
      '603307': {'lat': '12.7122', 'name': 'Ananthamangalam,Kanchipuram,Tamil Nadu', 'lon': '79.8556'},
      '603304': {'lat': '12.4581', 'name': 'Vilambur,Kanchipuram,Tamil Nadu', 'lon': '79.8643'},
      '515321': {'lat': '13.8262', 'name': 'Dokkalahalli,Ananthapur,Andhra Pradesh', 'lon': '77.0584'},
      '190019': {'lat': '34.14', 'name': 'Bagh Mehtab,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190018': {'lat': '34.14', 'name': 'Sebdan,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '506011': {'lat': '17.5325', 'name': 'Reddicolony,Warangal,Telangana', 'lon': '79.0467'},
      '387640': {'lat': '22.9166', 'name': 'Mal Itadi,Kheda,Gujarat', 'lon': '73.1325'},
      '190012': {'lat': '34.14', 'name': 'Zainakote,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190011': {'lat': '34.14', 'name': 'Anchar,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190010': {'lat': '34.14', 'name': 'Court Road,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190017': {'lat': '34.14', 'name': 'Parimpora,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190015': {'lat': '34.14', 'name': 'A K Pora,Srinagar,Jammu & Kashmir', 'lon': '74.7964'},
      '190014': {'lat': '34.14', 'name': 'Hyderpora,Budgam,Jammu & Kashmir', 'lon': '74.7964'},
      '686538': {'lat': '9.5135', 'name': 'Vakathanam South,Kottayam,Kerala', 'lon': '76.5697'},
      '686539': {'lat': '9.5079', 'name': 'Umbidi,Kottayam,Kerala', 'lon': '76.5379'},
      '502313': {'lat': '17.6986', 'name': 'Kanukunta,Medak,Telangana', 'lon': '78.4184'},
      '732103': {'lat': '24.2919', 'name': 'Rajbati Ramnagar,Malda,West Bengal', 'lon': '87.9302'},
      '686020': {'lat': '9.5713', 'name': 'Kiliroor North,Kottayam,Kerala', 'lon': '76.5199'},
      '686533': {'lat': '9.5138', 'name': 'Channanikadu,Kottayam,Kerala', 'lon': '76.5375'},
      '686022': {'lat': '9.5713', 'name': 'Chengalam South,Kottayam,Kerala', 'lon': '76.5199'},
      '686531': {'lat': '9.5079', 'name': 'Chingavanam,Kottayam,Kerala', 'lon': '76.5379'},
      '686536': {'lat': '9.5079', 'name': 'Perumpanachy,Kottayam,Kerala', 'lon': '76.5379'},
      '686537': {'lat': '9.5079', 'name': 'Pallickachirakavala,Kottayam,Kerala', 'lon': '76.5379'},
      '686534': {'lat': '9.4965', 'name': 'Kainady,Alappuzha,Kerala', 'lon': '76.5065'},
      '686535': {'lat': '9.5079', 'name': 'Thuruthy,Kottayam,Kerala', 'lon': '76.5379'},
      '431510': {'lat': '19.2803', 'name': 'Sawangi Bhambale,Parbhani,Maharashtra', 'lon': '76.7352'},
      '431511': {'lat': '19.051', 'name': 'Aherwadi,Parbhani,Maharashtra', 'lon': '77.1519'},
      '431512': {'lat': '19.6289', 'name': 'Amba,Jalna,Maharashtra', 'lon': '76.2098'},
      '431513': {'lat': '19.6979', 'name': 'Paheni,Hingoli,Maharashtra', 'lon': '77.1217'},
      '431514': {'lat': '18.9802', 'name': 'Badwani,Parbhani,Maharashtra', 'lon': '76.7541'},
      '431515': {'lat': '19.3667', 'name': 'Parli Vaijinath,Beed,Maharashtra', 'lon': '76.4304'},
      '431516': {'lat': '18.9136', 'name': 'Vita,Parbhani,Maharashtra', 'lon': '76.703'},
      '431517': {'lat': '18.693', 'name': 'Lokhandi Sawargaon,Beed,Maharashtra', 'lon': '76.317'},
      '442606': {'lat': '20.1179', 'name': 'Pendhari,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '431519': {'lat': '18.1187', 'name': 'Ghatnandur,Beed,Maharashtra', 'lon': '76.3053'},
      '442604': {'lat': '19.8202', 'name': 'Bhadbhidi,Gadchiroli,Maharashtra', 'lon': '79.9866'},
      '442605': {'lat': '20.4688', 'name': 'Gogaon,Chandrapur,Maharashtra', 'lon': '79.9018'},
      '332316': {'lat': '26.9776', 'name': 'Panlawa,Sikar,Rajasthan', 'lon': '73.4045'},
      '442603': {'lat': '19.9619', 'name': 'Navegaon Rayatwari,Gadchiroli,Maharashtra', 'lon': '79.8687'},
      '332315': {'lat': '28.02', 'name': 'Gassu,Sikar,Rajasthan', 'lon': '74.9537'},
      '623519': {'lat': '9.2691', 'name': 'Sundaramudaiyan,Ramanathapuram,Tamil Nadu', 'lon': '79.1073'},
      '623518': {'lat': '9.2757', 'name': 'Mandapam,Ramanathapuram,Tamil Nadu', 'lon': '79.1236'},
      '502312': {'lat': '17.6262', 'name': 'Remanguda,Medak,Telangana', 'lon': '79.0121'},
      '485446': {'lat': '24.369', 'name': 'Dhaurahara,Satna,Madhya Pradesh', 'lon': '81.0717'},
      '362245': {'lat': '21.3947', 'name': 'Panidhra,Junagadh,Gujarat', 'lon': '70.8256'},
      '623513': {'lat': '9.2881', 'name': 'Athiyuhtu,Ramanathapuram,Tamil Nadu', 'lon': '78.9143'},
      '362710': {'lat': '22.1099', 'name': 'Sindhaj,Junagadh,Gujarat', 'lon': '71.8283'},
      '623515': {'lat': '9.2272', 'name': 'Idambadal,Ramanathapuram,Tamil Nadu', 'lon': '78.7283'},
      '362240': {'lat': '22.0965', 'name': 'Ajak,Junagadh,Gujarat', 'lon': '71.5301'},
      '362715': {'lat': '22.1099', 'name': 'Ambujanagar,Junagadh,Gujarat', 'lon': '71.8283'},
      '623516': {'lat': '9.3081', 'name': 'Kadukkavalasai,Ramanathapuram,Tamil Nadu', 'lon': '79.0317'},
      '221311': {'lat': '25.3877', 'name': 'Mehandiganj,Varanasi,Uttar Pradesh', 'lon': '82.8871'},
      '464993': {'lat': '23.1986', 'name': 'Dewatia,Raisen,Madhya Pradesh', 'lon': '77.4383'},
      '271607': {'lat': '27.2873', 'name': 'Rampur Banghusra,Balrampur,Uttar Pradesh', 'lon': '82.2873'},
      '363040': {'lat': '22.1807', 'name': 'Bhadreshi,Surendra Nagar,Gujarat', 'lon': '72.0544'},
      '142046': {'lat': '30.5949', 'name': 'Didarewala,Moga,Punjab', 'lon': '75.2092'},
      '535204': {'lat': '18.1041', 'name': 'Kg Pathasala,Vizianagaram,Andhra Pradesh', 'lon': '83.5762'},
      '271604': {'lat': '27.1728', 'name': 'Dhaurahara,Gonda,Uttar Pradesh', 'lon': '82.321'},
      '124411': {'lat': '28.8308', 'name': 'Banyani,Rohtak,Haryana', 'lon': '76.4667'},
      '124412': {'lat': '28.7686', 'name': 'Garnauthi,Rohtak,Haryana', 'lon': '76.5125'},
      '470124': {'lat': '23.6878', 'name': 'Bina BORL,Sagar,Madhya Pradesh', 'lon': '78.6696'},
      '224235': {'lat': '25.2242', 'name': 'Sijhauli,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.3759'},
      '743610': {'lat': '22.7771', 'name': 'Hotor,South 24 Parganas,West Bengal', 'lon': '88.1942'},
      '271603': {'lat': '27.1428', 'name': 'Debari Kalan,Gonda,Uttar Pradesh', 'lon': '81.9154'},
      '441501': {'lat': '21.045', 'name': 'Mahurzari,Nagpur,Maharashtra', 'lon': '78.7646'},
      '142045': {'lat': '30.6756', 'name': 'Kishan Garh,Moga,Punjab', 'lon': '75.4573'},
      '246141': {'lat': '29.8125', 'name': 'Barsir,Rudraprayag,Uttarakhand', 'lon': '79.1194'},
      '221402': {'lat': '25.3824', 'name': 'Amawa Khurd,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.6517'},
      '177043': {'lat': '31.8059', 'name': 'Saleti,Kangra,Himachal Pradesh', 'lon': '76.2691'},
      '509204': {'lat': '16.6626', 'name': 'Nagaram,Mahabub Nagar,Telangana', 'lon': '77.7962'},
      '700142': {'lat': '22.4126', 'name': 'Santosh Pur (Maheshtala)so,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '272155': {'lat': '26.8183', 'name': 'Harraiya,Basti,Uttar Pradesh', 'lon': '82.6311'},
      '326038': {'lat': '24.7589', 'name': 'Maraitha,Jhalawar,Rajasthan', 'lon': '76.3505'},
      '700143': {'lat': '22.4126', 'name': 'Sarkarpool,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '272154': {'lat': '27.0367', 'name': 'Menhdupar,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.0128'},
      '142044': {'lat': '31.1424', 'name': 'Fatehgarh Sabrah,Firozpur,Punjab', 'lon': '75.3395'},
      '484776': {'lat': '24.2164', 'name': 'Budwa,Shahdol,Madhya Pradesh', 'lon': '81.407'},
      '700141': {'lat': '22.4069', 'name': 'Jalkhura,South 24 Parganas,West Bengal', 'lon': '88.1497'},
      '451224': {'lat': '22.2253', 'name': 'Itawadi,West Nimar,Madhya Pradesh', 'lon': '75.5750'},
      '451225': {'lat': '21.94105', 'name': 'Bhampura,West Nimar,Madhya Pradesh', 'lon': '75.8279'},
      '613705': {'lat': '10.8221', 'name': 'Abivirthiswaram,Tiruvarur,Tamil Nadu', 'lon': '79.4681'},
      '613704': {'lat': '10.7335', 'name': 'Kulikarai,Tiruvarur,Tamil Nadu', 'lon': '79.6007'},
      '613703': {'lat': '11.7874', 'name': 'Oorgudi,Tiruvarur,Tamil Nadu', 'lon': '78.8149'},
      '301427': {'lat': '27.8976', 'name': 'Padmara Khurd,Alwar,Rajasthan', 'lon': '76.4471'},
      '613701': {'lat': '10.987', 'name': 'Athisolamangalam,Tiruvarur,Tamil Nadu', 'lon': '79.7217'},
      '203131': {'lat': '28.0415', 'name': 'Bichoula,Bulandshahr,Uttar Pradesh', 'lon': '78.0876'},
      '203132': {'lat': '28.2617', 'name': 'Bhadaura Kamalpur,Bulandshahr,Uttar Pradesh', 'lon': '77.846'},
      '451228': {'lat': '22.0922', 'name': 'Choti Kasrawad,West Nimar,Madhya Pradesh', 'lon': '75.6252'},
      '246149': {'lat': '29.7173', 'name': 'Kotdwara Railway Station,Pauri Garhwal,Uttarakhand', 'lon': '78.5946'},
      '271313': {'lat': '27.029', 'name': 'Belahri Bujurg,Gonda,Uttar Pradesh', 'lon': '82.2866'},
      '480334': {'lat': '21.7263', 'name': 'Nandanwani,Chhindwara,Madhya Pradesh', 'lon': '78.5635'},
      '142043': {'lat': '30.9077', 'name': 'Shah Abu Bakkar,Firozpur,Punjab', 'lon': '75.1601'},
      '480331': {'lat': '21.9475', 'name': 'Ambamali,Chhindwara,Madhya Pradesh', 'lon': '78.6955'},
      '194202': {'lat': '	33.27291', 'name': 'Kungyam,Leh,Jammu & Kashmir', 'lon': '78.42924'},
      '325003': {'lat': '24.8474', 'name': 'Dhani Kasar,Kota,Rajasthan', 'lon': '76.0333'},
      '821303': {'lat': '24.9794', 'name': 'Banjari,Rohtas,Bihar', 'lon': '84.0277'},
      '194201': {'lat': '33.27291', 'name': 'Thiksay,Leh,Jammu & Kashmir', 'lon': '78.42924'},
      '480338': {'lat': '21.7546', 'name': 'Lingajatlapur,Chhindwara,Madhya Pradesh', 'lon': '78.2331'},
      '325004': {'lat': '25.4617', 'name': 'Binayaka,Kota,Rajasthan', 'lon': '76.4522'},
      '757083': {'lat': '21.3903', 'name': 'C.Mangalpur,Mayurbhanj,Odisha', 'lon': '85.3215'},
      '757082': {'lat': '21.8009', 'name': 'Hempur,Mayurbhanj,Odisha', 'lon': '85.7857'},
      '757081': {'lat': '21.3903', 'name': 'Nodhana,Mayurbhanj,Odisha', 'lon': '85.3215'},
      '606401': {'lat': '11.7776', 'name': 'Serapattu,Villupuram,Tamil Nadu', 'lon': '79.135'},
      '441809': {'lat': '21.1292', 'name': 'Kolari,Bhandara,Maharashtra', 'lon': '79.7509'},
      '757086': {'lat': '22.1801', 'name': 'Kuanrdihi,Mayurbhanj,Odisha', 'lon': '85.7447'},
      '757085': {'lat': '20.9939', 'name': 'Srinathpur,Mayurbhanj,Odisha', 'lon': '85.1842'},
      '757084': {'lat': '20.978', 'name': 'Brahmanmara,Mayurbhanj,Odisha', 'lon': '84.858'},
      '441805': {'lat': '21.2909', 'name': 'Pardi,Bhandara,Maharashtra', 'lon': '79.6641'},
      '441804': {'lat': '21.0159', 'name': 'Gunthara,Bhandara,Maharashtra', 'lon': '79.7057'},
      '441807': {'lat': '20.8618', 'name': 'Dongargaon Sadak,Gondia,Maharashtra', 'lon': '79.9748'},
      '441806': {'lat': '21.0482', 'name': 'Gondumri,Gondia,Maharashtra', 'lon': '79.9029'},
      '441801': {'lat': '21.1563', 'name': 'Gankhaira,Gondia,Maharashtra', 'lon': '79.7165'},
      '441803': {'lat': '20.7446', 'name': 'Lakhandur,Bhandara,Maharashtra', 'lon': '79.8827'},
      '441802': {'lat': '21.0539', 'name': 'Lawri Umri,Bhandara,Maharashtra', 'lon': '79.9264'},
      '621113': {'lat': '11.4055', 'name': 'Varagupadi,Perambalur,Tamil Nadu', 'lon': '79.083'},
      '571218': {'lat': '12.1771', 'name': 'Chembebellore,Kodagu,Karnataka', 'lon': '75.8165'},
      '621111': {'lat': '11.3141', 'name': 'Neikuppai,Tiruchirappalli,Tamil Nadu', 'lon': '78.8823'},
      '151211': {'lat': '29.9675', 'name': 'Bring Khera,Muktsar,Punjab', 'lon': '74.6396'},
      '413248': {'lat': '18.6191', 'name': 'Takali Sikandar,Solapur,Maharashtra', 'lon': '77.4089'},
      '413249': {'lat': '19.13', 'name': 'Ghatshilpargaon,Beed,Maharashtra', 'lon': '75.6248'},
      '621115': {'lat': '11.2988', 'name': 'Brammadesam,Perambalur,Tamil Nadu', 'lon': '78.9498'},
      '571219': {'lat': '12.3244', 'name': 'Devanur,Kodagu,Karnataka', 'lon': '75.8033'},
      '143407': {'lat': '31.2728', 'name': 'Munda Pind,Tarn Taran,Punjab', 'lon': '75.8226'},
      '630612': {'lat': '9.9846', 'name': 'Konthagai North,Sivaganga,Tamil Nadu', 'lon': '78.1704'},
      '245304': {'lat': '28.6496', 'name': 'Pilkhauwa ,Hapur,Uttar Pradesh', 'lon': '77.6956'},
      '630610': {'lat': '10.27', 'name': 'Vellikurichi,Sivaganga,Tamil Nadu', 'lon': '78.0239'},
      '630611': {'lat': '9.8134', 'name': 'Kalugarkadai,Sivaganga,Tamil Nadu', 'lon': '78.2584'},
      '444723': {'lat': '21.2672', 'name': 'Sirajgaon Band,Amravati,Maharashtra', 'lon': '77.6731'},
      '444720': {'lat': '21.3312', 'name': 'Surali,Amravati,Maharashtra', 'lon': '77.7806'},
      '743165': {'lat': '22.894', 'name': 'Puranandapally,North 24 Parganas,West Bengal', 'lon': '88.4152'},
      '142041': {'lat': '30.9116', 'name': 'Kokri Wehniwal,Firozpur,Punjab', 'lon': '75.1736'},
      '506366': {'lat': '18.3078', 'name': 'Akinepalli,Warangal,Telangana', 'lon': '79.6552'},
      '506367': {'lat': '17.8942', 'name': 'Solipuram,Warangal,Telangana', 'lon': '79.1873'},
      '506365': {'lat': '17.6865', 'name': 'Burgumadla,Mahabubabad,Telangana', 'lon': '79.3676'},
      '302036': {'lat': '26.7602', 'name': 'Jaisinghpura Khore,Jaipur,Rajasthan', 'lon': '76.0868'},
      '388260': {'lat': '23.2148', 'name': 'Kumbharwadi,Kheda,Gujarat', 'lon': '73.4328'},
      '303325': {'lat': '26.5619', 'name': 'Gadarwada Gujran,Dausa,Rajasthan', 'lon': '76.2759'},
      '388265': {'lat': '22.8763', 'name': 'Dev,Kheda,Gujarat', 'lon': '73.0167'},
      '506369': {'lat': '17.7534', 'name': 'Topanapalli,Mahabubabad,Telangana', 'lon': '79.7881'},
      '302038': {'lat': '26.7602', 'name': 'Kookas,Jaipur,Rajasthan', 'lon': '76.0868'},
      '741250': {'lat': '22.941', 'name': 'Kataganj,Nadia,West Bengal', 'lon': '88.637'},
      '363621': {'lat': '22.3908', 'name': 'Samadhiyala,Rajkot,Gujarat', 'lon': '71.3839'},
      '571211': {'lat': '12.1887', 'name': 'Hosur(Ammathi),Kodagu,Karnataka', 'lon': '75.9285'},
      '142040': {'lat': '30.6913', 'name': 'Ramuwala Harchoke,Moga,Punjab', 'lon': '75.3539'},
      '360023': {'lat': '21.9103', 'name': 'Ziyana,Rajkot,Gujarat', 'lon': '71.0896'},
      '360022': {'lat': '22.2344', 'name': 'Kotharia(TB Hosp),Rajkot,Gujarat', 'lon': '70.819'},
      '360021': {'lat': '21.9103', 'name': 'Mota Vada,Rajkot,Gujarat', 'lon': '71.0896'},
      '360020': {'lat': '22.1466', 'name': 'Padasan,Rajkot,Gujarat', 'lon': '70.1504'},
      '585309': {'lat': '16.7466', 'name': 'Gogi,Yadgir,Karnataka', 'lon': '76.6684'},
      '360026': {'lat': '21.9103', 'name': 'Sapar (Veraval),Rajkot,Gujarat', 'lon': '71.0896'},
      '360025': {'lat': '22.0615', 'name': 'Haripar,Rajkot,Gujarat', 'lon': '71.1629'},
      '360024': {'lat': '20.5087', 'name': 'Shapar( Halar),Rajkot,Gujarat', 'lon': '72.9457'},
      '585305': {'lat': '17.329', 'name': 'Garagapalli,Kalaburagi,Karnataka', 'lon': '77.4047'},
      '571214': {'lat': '12.3083', 'name': 'Palur,Kodagu,Karnataka', 'lon': '75.6884'},
      '585307': {'lat': '17.4267', 'name': 'Chimmaidlai,Kalaburagi,Karnataka', 'lon': '77.1739'},
      '585306': {'lat': '17.5256', 'name': 'Yelmamidi,Kalaburagi,Karnataka', 'lon': '77.1203'},
      '585301': {'lat': '17.143', 'name': 'Bellurgi,Kalaburagi,Karnataka', 'lon': '76.5557'},
      '756027': {'lat': '21.6233', 'name': 'Bahabalpur,Baleswar,Odisha', 'lon': '87.0623'},
      '585303': {'lat': '16.5722', 'name': 'Malla B,Kalaburagi,Karnataka', 'lon': '76.3889'},
      '571215': {'lat': '12.2404', 'name': 'Chennayanakote,Kodagu,Karnataka', 'lon': '75.9139'},
      '833213': {'lat': '22.2136', 'name': 'Gua,West Singhbhum,Jharkhand', 'lon': '85.3877'},
      '833212': {'lat': '22.3584', 'name': 'Danguaposi,West Singhbhum,Jharkhand', 'lon': '85.7446'},
      '833215': {'lat': '22.2333', 'name': 'Purnapani,West Singhbhum,Jharkhand', 'lon': '85.6173'},
      '571216': {'lat': '12.1667', 'name': 'Kotoor,Kodagu,Karnataka', 'lon': '75.9579'},
      '833217': {'lat': '22.1609', 'name': 'Jojo Camp,West Singhbhum,Jharkhand', 'lon': '85.5042'},
      '833216': {'lat': '22.7909', 'name': 'Kharsawangarh,Seraikela-kharsawan,Jharkhand', 'lon': '85.831'},
      '833219': {'lat': '22.6769', 'name': 'Pathermara,Seraikela-kharsawan,Jharkhand', 'lon': '85.9351'},
      '334001': {'lat': '28.1862', 'name': 'Kawani,Bikaner,Rajasthan', 'lon': '73.2906'},
      '331030': {'lat': '28.1999', 'name': 'Mahansar,Jhujhunu,Rajasthan', 'lon': '75.0473'},
      '571217': {'lat': '12.0387', 'name': 'Nalkeri,Kodagu,Karnataka', 'lon': '75.9391'},
      '334004': {'lat': '28.1862', 'name': 'Rcp Colony,Bikaner,Rajasthan', 'lon': '73.2906'},
      '334006': {'lat': '28.1862', 'name': 'Raj.Krishi Vishwavidhyalaya,Bikaner,Rajasthan', 'lon': '73.2906'},
      '480557': {'lat': '22.2578', 'name': 'Pagara,Chhindwara,Madhya Pradesh', 'lon': '78.7576'},
      '847213': {'lat': '26.3738', 'name': 'Jagatpur,Madhubani,Bihar', 'lon': '86.0509'},
      '700033': {'lat': '22.5668', 'name': 'Tollygunge H.O,Kolkata,West Bengal', 'lon': '88.3016'},
      '222180': {'lat': '26.0787', 'name': 'Imalo,Jaunpur,Uttar Pradesh', 'lon': '82.4224'},
      '760010': {'lat': '20.1568', 'name': 'Dura,Ganjam,Odisha', 'lon': '84.559'},
      '760011': {'lat': '20.1568', 'name': 'Ambapua,Ganjam,Odisha', 'lon': '84.559'},
      '232325': {'lat': '25.7187', 'name': 'Bara (Ghazipur),Ghazipur,Uttar Pradesh', 'lon': '83.803'},
      '303323': {'lat': '26.7169', 'name': 'Muhi,Dausa,Rajasthan', 'lon': '76.0256'},
      '506301': {'lat': '17.8892', 'name': 'Raghavapur,Warangal,Telangana', 'lon': '79.3945'},
      '251307': {'lat': '29.3', 'name': 'Kasampur Patheri,Muzaffarnagar,Uttar Pradesh', 'lon': '77.9265'},
      '224207': {'lat': '26.5224', 'name': 'Mumrej Nagar,Faizabad,Uttar Pradesh', 'lon': '82.0888'},
      '251306': {'lat': '29.4431', 'name': 'Pinna,Muzaffarnagar,Uttar Pradesh', 'lon': '77.5659'},
      '223222': {'lat': '26.0431', 'name': 'Oril,Mau,Uttar Pradesh', 'lon': '83.5629'},
      '223221': {'lat': '26.1603', 'name': 'Sukhi Pur,Azamgarh,Uttar Pradesh', 'lon': '83.0111'},
      '233305': {'lat': '25.6943', 'name': 'Pahetia,Ghazipur,Uttar Pradesh', 'lon': '83.4877'},
      '504218': {'lat': '18.7697', 'name': 'Devapur Cement Works,Adilabad,Telangana', 'lon': '79.3781'},
      '249201': {'lat': '30.1078', 'name': 'Railway Road Rishikesh,Dehradun,Uttarakhand', 'lon': '78.2925'},
      '502319': {'lat': '17.9964', 'name': 'Patancheru Ie,Medak,Telangana', 'lon': '78.8518'},
      '249203': {'lat': '30.0482', 'name': 'Pashulok,Dehradun,Uttarakhand', 'lon': '78.2513'},
      '228001': {'lat': '26.3639', 'name': 'Bus Station (Sultanpur),Sultanpur,Uttar Pradesh', 'lon': '81.9658'},
      '249205': {'lat': '30.0186', 'name': 'Raiwala,Dehradun,Uttarakhand', 'lon': '78.2293'},
      '249204': {'lat': '30.0482', 'name': 'S.N. Temple,Dehradun,Uttarakhand', 'lon': '78.2513'},
      '606305': {'lat': '11.6024', 'name': 'Eranji,Villupuram,Tamil Nadu', 'lon': '79.1715'},
      '606304': {'lat': '11.6316', 'name': 'Periyanesalur,Cuddalore,Tamil Nadu', 'lon': '79.2232'},
      '606301': {'lat': '11.5539', 'name': 'V  Mamandur,Villupuram,Tamil Nadu', 'lon': '78.8768'},
      '606303': {'lat': '11.4409', 'name': 'Vaithiyanathapuram,Cuddalore,Tamil Nadu', 'lon': '78.9791'},
      '606302': {'lat': '11.5026', 'name': 'Varambanur,Cuddalore,Tamil Nadu', 'lon': '79.1773'},
      '516237': {'lat': '13.4975', 'name': 'Siddavattam,Cuddapah,Andhra Pradesh', 'lon': '79.0812'},
      '814165': {'lat': '25.0912', 'name': 'Bapugram,Godda,Jharkhand', 'lon': '87.3083'},
      '246427': {'lat': '30.1384', 'name': 'Debal,Chamoli,Uttarakhand', 'lon': '79.7307'},
      '835206': {'lat': '23.2154', 'name': 'Katkahi,Gumla,Jharkhand', 'lon': '84.2281'},
      '516233': {'lat': '14.6559', 'name': 'Gopavaram Project Site,Cuddapah,Andhra Pradesh', 'lon': '78.4176'},
      '480551': {'lat': '22.3317', 'name': 'Navegaon,Chhindwara,Madhya Pradesh', 'lon': '78.5935'},
      '847211': {'lat': '26.2726', 'name': 'Madhubani H.O,Madhubani,Bihar', 'lon': '86.1455'},
      '801105': {'lat': '25.591', 'name': 'Khagaul Bazar,Patna,Bihar', 'lon': '85.0303'},
      '502318': {'lat': '17.5806', 'name': 'Malkapur (Jadi),Medak,Telangana', 'lon': '77.6099'},
      '457772': {'lat': '23.0549', 'name': 'Makodia,Jhabua,Madhya Pradesh', 'lon': '74.9117'},
      '641202': {'lat': '10.8344', 'name': 'Kurunallipalayam,Coimbatore,Tamil Nadu', 'lon': '77.0884'},
      '755010': {'lat': '20.6724', 'name': 'Kaipara,Jajapur,Odisha', 'lon': '85.5273'},
      '713156': {'lat': '23.5176', 'name': 'Ausgram,Purba Bardhaman,West Bengal', 'lon': '87.6661'},
      '755016': {'lat': '20.6919', 'name': 'Kandiahat,Jajapur,Odisha', 'lon': '86.5072'},
      '829144': {'lat': '24.1157', 'name': 'Tantri,Bokaro,Jharkhand', 'lon': '85.3643'},
      '247665': {'lat': '29.7488', 'name': 'Sherpur Khelmau,Haridwar,Uttarakhand', 'lon': '77.7843'},
      '755017': {'lat': '20.2746', 'name': 'Kujhalo,Jajapur,Odisha', 'lon': '84.9824'},
      '261121': {'lat': '27.6621', 'name': 'Koriya Udnapur,Sitapur,Uttar Pradesh', 'lon': '80.4518'},
      '755014': {'lat': '20.6724', 'name': 'Barikul,Jajapur,Odisha', 'lon': '85.5273'},
      '457773': {'lat': '22.8459', 'name': 'Kardawad,Jhabua,Madhya Pradesh', 'lon': '74.7391'},
      '755015': {'lat': '20.6724', 'name': 'Badabiruan,Jajapur,Odisha', 'lon': '85.5273'},
      '782490': {'lat': '26.1303', 'name': 'Chakihola,Karbi Anglon,Assam', 'lon': '92.741'},
      '829143': {'lat': '23.6193', 'name': 'Gegda,Ramgarh,Jharkhand', 'lon': '85.3184'},
      '712310': {'lat': '22.6685', 'name': 'Chakundi,Hooghly,West Bengal', 'lon': '88.291'},
      '577429': {'lat': '14.3461', 'name': 'Halagalale,Shivamogga,Karnataka', 'lon': '75.1091'},
      '577428': {'lat': '14.5566', 'name': 'Manchikoppa,Shivamogga,Karnataka', 'lon': '75.2167'},
      '370203': {'lat': '22.9959', 'name': 'Udaynagar,Kachchh,Gujarat', 'lon': '70.3401'},
      '577425': {'lat': '13.4841', 'name': 'Mathimane,Shivamogga,Karnataka', 'lon': '75.317'},
      '577424': {'lat': '13.8991', 'name': 'Huruli,Shivamogga,Karnataka', 'lon': '75.0396'},
      '577427': {'lat': '14.1892', 'name': 'Kappanahalli,Shivamogga,Karnataka', 'lon': '75.4289'},
      '577426': {'lat': '13.8952', 'name': 'Bellur(Hosanagar),Shivamogga,Karnataka', 'lon': '75.3838'},
      '577421': {'lat': '14.1588', 'name': 'Bhanukuli,Shivamogga,Karnataka', 'lon': '74.8262'},
      '572140': {'lat': '13.3451', 'name': 'Byatha,Tumakuru,Karnataka', 'lon': '77.2509'},
      '577423': {'lat': '14.0577', 'name': 'Thuppur,Shivamogga,Karnataka', 'lon': '75.4112'},
      '577422': {'lat': '13.8171', 'name': 'Hadigallu,Shivamogga,Karnataka', 'lon': '75.2686'},
      '625214': {'lat': '10.0216', 'name': 'Sholavandan Bazaar,Madurai,Tamil Nadu', 'lon': '77.9609'},
      '248195': {'lat': '30.44', 'name': 'I.P.E.,Dehradun,Uttarakhand', 'lon': '77.9836'},
      '370201': {'lat': '23.0833', 'name': 'Ganeshnagar,Kachchh,Gujarat', 'lon': '70.1333'},
      '521260': {'lat': '16.4709', 'name': 'Tarigoppula R.S.,Krishna,Andhra Pradesh', 'lon': '80.8351'},
      '521261': {'lat': '16.4123', 'name': 'Appikatla,Krishna,Andhra Pradesh', 'lon': '80.9187'},
      '521263': {'lat': '16.4393', 'name': 'Pedaparupudi,Krishna,Andhra Pradesh', 'lon': '80.9386'},
      '625218': {'lat': '10.028', 'name': 'Kachakatti,Madurai,Tamil Nadu', 'lon': '78.1439'},
      '502113': {'lat': '18.3427', 'name': 'Pathoor,Medak,Telangana', 'lon': '78.3954'},
      '641653': {'lat': '11.2444', 'name': 'Sokkampalayam,Coimbatore,Tamil Nadu', 'lon': '77.5695'},
      '835325': {'lat': '23.3313', 'name': 'Nagjua,Ranchi,Jharkhand', 'lon': '85.3708'},
      '325222': {'lat': '25.2554', 'name': 'Badan,Baran,Rajasthan', 'lon': '76.4847'},
      '431723': {'lat': '18.6733', 'name': 'Shahapur (Nanded),Nanded,Maharashtra', 'lon': '77.5998'},
      '431722': {'lat': '18.8282', 'name': 'Mugaon,Nanded,Maharashtra', 'lon': '77.4403'},
      '431721': {'lat': '19.9708', 'name': 'Tulshi,Nanded,Maharashtra', 'lon': '78.1502'},
      '192201': {'lat': '33.6634', 'name': 'Hardtour,Ananthnag,Jammu & Kashmir', 'lon': '75.2282'},
      '502205': {'lat': '17.8236', 'name': 'O.F.Yeddumailaram,Medak,Telangana', 'lon': '78.2002'},
      '250621': {'lat': '29.1419', 'name': 'Gunga Kheri,Bagpat,Uttar Pradesh', 'lon': '77.2739'},
      '250620': {'lat': '29.1447', 'name': 'Bamnauli,Bagpat,Uttar Pradesh', 'lon': '77.3485'},
      '627861': {'lat': '8.3718', 'name': 'Virakeralmpudur,Tirunelveli,Tamil Nadu', 'lon': '77.9141'},
      '522510': {'lat': '16.3666', 'name': 'Nagarjuna Nagar,Guntur,Andhra Pradesh', 'lon': '80.3629'},
      '250623': {'lat': '29.2119', 'name': 'Soop,Bagpat,Uttar Pradesh', 'lon': '77.2818'},
      '460330': {'lat': '22.1406', 'name': 'Chunahajuri,Betul,Madhya Pradesh', 'lon': '77.5606'},
      '325223': {'lat': '24.7936', 'name': 'Atone,Baran,Rajasthan', 'lon': '76.5347'},
      '741164': {'lat': '22.5709', 'name': 'Mahatpur,Nadia,West Bengal', 'lon': '88.3427'},
      '803117': {'lat': '25.0703', 'name': 'Madhar,Nalanda,Bihar', 'lon': '85.4232'},
      '803116': {'lat': '24.9722', 'name': 'Karubigha,Nalanda,Bihar', 'lon': '85.2735'},
      '803111': {'lat': '25.3056', 'name': 'Deepnagar,Nalanda,Bihar', 'lon': '85.2629'},
      '803110': {'lat': '25.3534', 'name': 'Belchi,Patna,Bihar', 'lon': '85.624'},
      '803113': {'lat': '25.2048', 'name': 'Kathauli,Nalanda,Bihar', 'lon': '85.4869'},
      '250625': {'lat': '29.2285', 'name': 'Gangnauli,Bagpat,Uttar Pradesh', 'lon': '77.362'},
      '140506': {'lat': '30.525', 'name': 'Dappar Ad,Mohali,Punjab', 'lon': '76.749'},
      '140507': {'lat': '30.5631', 'name': 'Rampur Sainia,Mohali,Punjab', 'lon': '76.8376'},
      '803119': {'lat': '25.2454', 'name': 'Peshaur,Nalanda,Bihar', 'lon': '85.593'},
      '764021': {'lat': '18.8178', 'name': 'A.Malkangiri,Koraput,Odisha', 'lon': '82.7662'},
      '382150': {'lat': '22.9032', 'name': 'Kokta,Ahmedabad,Gujarat', 'lon': '71.5782'},
      '785615': {'lat': '26.6225', 'name': 'Karuabahi Satra,Golaghat,Assam', 'lon': '93.7223'},
      '785614': {'lat': '26.5417', 'name': 'Dergaon Chariali,Golaghat,Assam', 'lon': '92.9667'},
      '785616': {'lat': '26.292', 'name': 'Kachamari,Jorhat,Assam', 'lon': '92.4548'},
      '785611': {'lat': '26.95', 'name': 'Rongorahat,Golaghat,Assam', 'lon': '94.1667'},
      '785610': {'lat': '26.4692', 'name': 'Borjan,Golaghat,Assam', 'lon': '94.0174'},
      '785613': {'lat': '26.6198', 'name': 'Letekujaan,Golaghat,Assam', 'lon': '93.4464'},
      '785612': {'lat': '26.6402', 'name': 'Lakhowjaan Tiniali,Golaghat,Assam', 'lon': '93.6005'},
      '492109': {'lat': '21.0976', 'name': 'Atarmara,Raipur,Chattisgarh', 'lon': '81.7223'},
      '250626': {'lat': '28.937', 'name': 'Mukri,Bagpat,Uttar Pradesh', 'lon': '77.4658'},
      '785619': {'lat': '26.6198', 'name': 'Kachupathar,Golaghat,Assam', 'lon': '93.4464'},
      '785618': {'lat': '26.9008', 'name': 'Batiporia,Golaghat,Assam', 'lon': '93.6763'},
      '695528': {'lat': '9.1681', 'name': 'Naruvamoodu,Thiruvananthapuram,Kerala', 'lon': '76.6753'},
      '642006': {'lat': '10.6498', 'name': 'Suleeswaran Patti,Coimbatore,Tamil Nadu', 'lon': '77'},
      '642007': {'lat': '10.6498', 'name': 'Vedasanthur,Coimbatore,Tamil Nadu', 'lon': '77'},
      '642004': {'lat': '10.6237', 'name': 'Karuppampalayam,Coimbatore,Tamil Nadu', 'lon': '76.9631'},
      '311024': {'lat': '25.2902', 'name': 'Sareri CO,Bhilwara,Rajasthan', 'lon': '74.2398'},
      '642002': {'lat': '10.6498', 'name': 'R.Ponnapuram,Coimbatore,Tamil Nadu', 'lon': '77'},
      '515842': {'lat': '15.0954', 'name': 'Donekal,Ananthapur,Andhra Pradesh', 'lon': '77.332'},
      '487110': {'lat': '22.806', 'name': 'Ranipipria,Narsinghpur,Madhya Pradesh', 'lon': '79.2447'},
      '642001': {'lat': '10.6498', 'name': 'Pollachi Bazaar,Coimbatore,Tamil Nadu', 'lon': '77'},
      '502114': {'lat': '18.0898', 'name': 'Irkode,Medak,Telangana', 'lon': '78.695'},
      '311023': {'lat': '25.961', 'name': 'Dhanop,Bhilwara,Rajasthan', 'lon': '74.8564'},
      '824301': {'lat': '24.6542', 'name': 'Entwan,Aurangabad(BH),Bihar', 'lon': '84.3208'},
      '311022': {'lat': '25.8214', 'name': 'Hurda,Bhilwara,Rajasthan', 'lon': '74.7254'},
      '842004': {'lat': '25.7029', 'name': 'Umanagar,Muzaffarpur,Bihar', 'lon': '85.8688'},
      '421601': {'lat': '19.2463', 'name': 'Piwali,Thane,Maharashtra', 'lon': '73.5187'},
      '842006': {'lat': '26.1511', 'name': 'Kalwari,Muzaffarpur,Bihar', 'lon': '85.3308'},
      '842001': {'lat': '25.4316', 'name': 'Saraiyaganj,Muzaffarpur,Bihar', 'lon': '87.4771'},
      '842002': {'lat': '25.7627', 'name': 'Susta,Muzaffarpur,Bihar', 'lon': '85.8905'},
      '842003': {'lat': '24.8111', 'name': 'M.I.T.,Muzaffarpur,Bihar', 'lon': '84.6213'},
      '473585': {'lat': '25.0972', 'name': 'Kafar,Shivpuri,Madhya Pradesh', 'lon': '78.1434'},
      '302006': {'lat': '26.9196', 'name': 'Raj Bhawan (Jaipur),Jaipur,Rajasthan', 'lon': '75.7878'},
      '421603': {'lat': '19.3442', 'name': 'Birwadi,Thane,Maharashtra', 'lon': '73.847'},
      '392240': {'lat': '21.8815', 'name': 'Kahan,Bharuch,Gujarat', 'lon': '73.01'},
      '246276': {'lat': '29.8604', 'name': 'Bhamraikhal,Pauri Garhwal,Uttarakhand', 'lon': '78.9575'},
      '492009': {'lat': '21.2495', 'name': 'Raipur Ganj,Raipur,Chattisgarh', 'lon': '81.629'},
      '421602': {'lat': '19.6451', 'name': 'Mokhawane,Thane,Maharashtra', 'lon': '73.4743'},
      '492008': {'lat': '21.2778', 'name': 'W.R.S.Colony,Raipur,Chattisgarh', 'lon': '81.634'},
      '271122': {'lat': '27.2781', 'name': 'Ursaina,Gonda,Uttar Pradesh', 'lon': '81.9963'},
      '421605': {'lat': '19.9244', 'name': 'Manda,Thane,Maharashtra', 'lon': '77.1744'},
      '571490': {'lat': '12.6167', 'name': 'Indiganatha,Chamrajnagar,Karnataka', 'lon': '76.8057'},
      '768202': {'lat': '21.841', 'name': 'Gudigaon,Jharsuguda,Odisha', 'lon': '84.0295'},
      '689506': {'lat': '9.9552', 'name': 'Pandanad,Alappuzha,Kerala', 'lon': '76.3676'},
      '177042': {'lat': '31.6570', 'name': 'Ghaloon,Hamirpur(HP),Himachal Pradesh', 'lon': '76.3537'},
      '177041': {'lat': '31.696334', 'name': 'Hathol,Hamirpur(HP),Himachal Pradesh', 'lon': '76.3729521'},
      '177040': {'lat': '31.5672', 'name': 'Panyali Jattan,Hamirpur(HP),Himachal Pradesh', 'lon': '76.36995'},
      '689502': {'lat': '9.2193', 'name': 'Keerukuzhy,Pathanamthitta,Kerala', 'lon': '76.7119'},
      '689503': {'lat': '10.2652', 'name': 'Ullannur,Pathanamthitta,Kerala', 'lon': '76.2591'},
      '177045': {'lat': '31.7563', 'name': 'Putrial,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4289'},
      '177044': {'lat': '31.7785074', 'name': 'Bara (Hamirpur(HP)),Hamirpur(HP),Himachal Pradesh', 'lon': '76.3444547'},
      '402307': {'lat': '17.8937', 'name': 'Tamhane,Raigarh(MH),Maharashtra', 'lon': '73.3856'},
      '402306': {'lat': '18.082036', 'name': 'Taliye,Raigarh(MH),Maharashtra', 'lon': '73.423364'},
      '402305': {'lat': '18.2914', 'name': 'Sandoshi,Raigarh(MH),Maharashtra', 'lon': '73.4357'},
      '177048': {'lat': '31.6862', 'name': 'Jol Sappar,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5213'},
      '402303': {'lat': '17.9890', 'name': 'Sakhar,Raigarh(MH),Maharashtra', 'lon': '73.5498'},
      '402302': {'lat': '	17.1581', 'name': 'Waki Budruk,Raigarh(MH),Maharashtra', 'lon': '73.8537'},
      '689508': {'lat': '9.752', 'name': 'Neduvaramkodu,Alappuzha,Kerala', 'lon': '76.4762'},
      '689509': {'lat': '9.2451', 'name': 'Venmony,Alappuzha,Kerala', 'lon': '76.6244'},
      '741156': {'lat': '23.692', 'name': 'Tungi,Nadia,West Bengal', 'lon': '88.1886'},
      '515154': {'lat': '14.2755', 'name': 'Gunipalli,Ananthapur,Andhra Pradesh', 'lon': '77.9421'},
      '741154': {'lat': '23.3101', 'name': 'Saligram,Nadia,West Bengal', 'lon': '88.1607'},
      '741155': {'lat': '23.7253', 'name': 'Baur,Nadia,West Bengal', 'lon': '88.1016'},
      '682028': {'lat': '10.1975', 'name': 'Vennala,Ernakulam,Kerala', 'lon': '76.2147'},
      '682029': {'lat': '10.1975', 'name': 'Matsyapuri,Ernakulam,Kerala', 'lon': '76.2147'},
      '334023': {'lat': '28.4499', 'name': 'Khajuwala,Bikaner,Rajasthan', 'lon': '73.4782'},
      '741151': {'lat': '23.6994', 'name': 'Chitrasali,Nadia,West Bengal', 'lon': '88.1823'},
      '682024': {'lat': '10.022', 'name': 'Edapally North,Ernakulam,Kerala', 'lon': '76.3087'},
      '682025': {'lat': '10.1975', 'name': 'Palarivattom,Ernakulam,Kerala', 'lon': '76.2147'},
      '682026': {'lat': '10.1975', 'name': 'Elamakkara,Ernakulam,Kerala', 'lon': '76.2147'},
      '682027': {'lat': '10.0568', 'name': 'Kothad,Ernakulam,Kerala', 'lon': '76.254'},
      '515159': {'lat': '14.3207', 'name': 'Gunjepalli,Ananthapur,Andhra Pradesh', 'lon': '77.8988'},
      '682021': {'lat': '10.1975', 'name': 'Vadacode Kailas Colony,Ernakulam,Kerala', 'lon': '76.2147'},
      '682022': {'lat': '10.1975', 'name': 'Kochi University,Ernakulam,Kerala', 'lon': '76.2147'},
      '682023': {'lat': '10.0577', 'name': 'Vaduthala,Ernakulam,Kerala', 'lon': '76.4727'},
      '441122': {'lat': '20.9657', 'name': 'Salaidhabha,Nagpur,Maharashtra', 'lon': '78.9728'},
      '441123': {'lat': '21.2204', 'name': 'Godhani,Nagpur,Maharashtra', 'lon': '79.0611'},
      '576114': {'lat': '13.3109', 'name': 'Kallianapura,Udupi,Karnataka', 'lon': '74.9636'},
      '576115': {'lat': '13.3838', 'name': 'Kemmannu,Udupi,Karnataka', 'lon': '74.7052'},
      '576112': {'lat': '13.4042', 'name': 'Kabbinale,Udupi,Karnataka', 'lon': '75.0518'},
      '576113': {'lat': '13.2884', 'name': 'Pernankila,Udupi,Karnataka', 'lon': '74.856'},
      '576111': {'lat': '13.166', 'name': 'Belmannu,Udupi,Karnataka', 'lon': '74.8692'},
      '396030': {'lat': '21.3463', 'name': 'Chharwada,Valsad,Gujarat', 'lon': '72.6189'},
      '396035': {'lat': '21.3463', 'name': 'Valsad I.E.,Valsad,Gujarat', 'lon': '72.6189'},
      '531219': {'lat': '18.0141', 'name': 'R P Agraharam,Visakhapatnam,Andhra Pradesh', 'lon': '83.228'},
      '563118': {'lat': '12.9957', 'name': 'Coromandel,Kolar,Karnataka', 'lon': '78.1749'},
      '532005': {'lat': '15.8948', 'name': 'Gujarathipeta,Srikakulam,Andhra Pradesh', 'lon': '81.4787'},
      '492002': {'lat': '21.7363', 'name': 'Mantralaya Raipur,Raipur,Chattisgarh', 'lon': '81.595'},
      '505514': {'lat': '18.8152', 'name': 'Mudriyal,Karim Nagar,Telangana', 'lon': '79.3805'},
      '532001': {'lat': '15.8948', 'name': 'Srikakulam Official Colony,Srikakulam,Andhra Pradesh', 'lon': '81.4787'},
      '223102': {'lat': '26.0554', 'name': 'Bhagasa,Jaunpur,Uttar Pradesh', 'lon': '82.5395'},
      '223103': {'lat': '26.094', 'name': 'Argupur Kala,Jaunpur,Uttar Pradesh', 'lon': '82.6168'},
      '473226': {'lat': '24.4073', 'name': 'Awan,Guna,Madhya Pradesh', 'lon': '77.1364'},
      '563113': {'lat': '13.102', 'name': 'Doddakambali,Kolar,Karnataka', 'lon': '78.3098'},
      '563114': {'lat': '12.886', 'name': 'C.A. Halli,Kolar,Karnataka', 'lon': '77.9703'},
      '563115': {'lat': '12.9957', 'name': 'Bharathnagar,Kolar,Karnataka', 'lon': '78.1749'},
      '563116': {'lat': '13.0142', 'name': 'Gannerahalli,Kolar,Karnataka', 'lon': '78.377'},
      '563117': {'lat': '12.9957', 'name': 'Championreefs,Kolar,Karnataka', 'lon': '78.1749'},
      '496113': {'lat': '21.8047', 'name': 'Katalpali "d",Raigarh,Chattisgarh', 'lon': '82.8732'},
      '741163': {'lat': '24.0224', 'name': 'Haripur Chhitka,Nadia,West Bengal', 'lon': '87.6555'},
      '151505': {'lat': '30.2774', 'name': 'Dulo Wal,Mansa,Punjab', 'lon': '75.388'},
      '496111': {'lat': '21.9898', 'name': 'Chhotegumda,Raigarh,Chattisgarh', 'lon': '83.0502'},
      '313331': {'lat': '25.3475', 'name': 'Vijaypura.,Udaipur,Rajasthan', 'lon': '73.9648'},
      '855102': {'lat': '25.529', 'name': 'Dasgram,Katihar,Bihar', 'lon': '87.7033'},
      '506352': {'lat': '17.9236', 'name': 'Incherla,Warangal,Telangana', 'lon': '79.607'},
      '496116': {'lat': '23.2948', 'name': 'Dharmajaigarh,Raigarh,Chattisgarh', 'lon': '83.9171'},
      '277214': {'lat': '25.8886', 'name': 'Ibrahimabad Uprwar,Ballia,Uttar Pradesh', 'lon': '84.2917'},
      '700160': {'lat': '22.7398', 'name': 'New Town Action Area III,North 24 Parganas,West Bengal', 'lon': '88.3282'},
      '502269': {'lat': '17.6136', 'name': 'Chilver,Medak,Telangana', 'lon': '78.0576'},
      '506145': {'lat': '17.9456', 'name': 'Sreepathipalli,Warangal,Telangana', 'lon': '79.4455'},
      '121101': {'lat': '28.7556', 'name': 'Manjhawali,Faridabad,Haryana', 'lon': '77.3842'},
      '121103': {'lat': '27.9993', 'name': 'Gehlab,Faridabad,Haryana', 'lon': '77.2233'},
      '121102': {'lat': '28.2474', 'name': 'Ghori,Faridabad,Haryana', 'lon': '77.2623'},
      '121105': {'lat': '27.9653', 'name': 'Khatela Sarai,Faridabad,Haryana', 'lon': '77.2853'},
      '121107': {'lat': '28.3327', 'name': 'Gulawad,Faridabad,Haryana', 'lon': '77.3548'},
      '121106': {'lat': '28.3327', 'name': 'Hodal,Faridabad,Haryana', 'lon': '77.3548'},
      '416805': {'lat': '16.4615', 'name': 'Vada (Sindhudurg),Sindhudurg,Maharashtra', 'lon': '73.3753'},
      '416804': {'lat': '16.4609', 'name': 'Thakurwadi,Sindhudurg,Maharashtra', 'lon': '73.2804'},
      '416807': {'lat': '16.4923', 'name': 'Tirlot,Sindhudurg,Maharashtra', 'lon': '73.3303'},
      '416806': {'lat': '16.5545', 'name': 'Girye,Sindhudurg,Maharashtra', 'lon': '73.3352'},
      '416801': {'lat': '16.4923', 'name': 'Waghivare,Sindhudurg,Maharashtra', 'lon': '73.3303'},
      '642109': {'lat': '9.1318', 'name': 'Arasampalayam,Coimbatore,Tamil Nadu', 'lon': '77.5939'},
      '416803': {'lat': '16.4923', 'name': 'Mutat,Sindhudurg,Maharashtra', 'lon': '73.3303'},
      '263679': {'lat': '29.8122', 'name': 'Lahur,Bageshwar,Uttarakhand', 'lon': '79.7962'},
      '457118': {'lat': '23.2571', 'name': 'Panthpiploda,Ratlam,Madhya Pradesh', 'lon': '75.9288'},
      '642108': {'lat': '10.3341', 'name': 'Iyerpadi,Coimbatore,Tamil Nadu', 'lon': '77.2037'},
      '263630': {'lat': '29.7487', 'name': 'Janoti Palari,Bageshwar,Uttarakhand', 'lon': '78.9966'},
      '474020': {'lat': '26.3161', 'name': 'Girgaon,Gwalior,Madhya Pradesh', 'lon': '78.2602'},
      '700089': {'lat': '22.3341', 'name': 'Kalindi Housing Estate,Kolkata,West Bengal', 'lon': '88.3065'},
      '700088': {'lat': '22.3341', 'name': 'Telecom Factory,Kolkata,West Bengal', 'lon': '88.3065'},
      '796470': {'lat': '23.7123', 'name': 'Boraibazar,Mammit,Mizoram', 'lon': '92.4717'},
      '700083': {'lat': '22.0808', 'name': 'Deshopriya Nagar,North 24 Parganas,West Bengal', 'lon': '88.1461'},
      '141010': {'lat': '30.8485', 'name': 'Moti Nagar,Ludhiana,Punjab', 'lon': '76.141'},
      '141013': {'lat': '30.8485', 'name': 'Basant Avenue,Ludhiana,Punjab', 'lon': '76.141'},
      '141012': {'lat': '30.8485', 'name': 'Rajguru Nagar,Ludhiana,Punjab', 'lon': '76.141'},
      '141015': {'lat': '30.8975', 'name': 'Karyana Kalan,Ludhiana,Punjab', 'lon': '75.9316'},
      '141014': {'lat': '30.8646', 'name': 'Daba Road,Ludhiana,Punjab', 'lon': '75.9185'},
      '141017': {'lat': '30.8549', 'name': 'Jugiana,Ludhiana,Punjab', 'lon': '75.9405'},
      '141016': {'lat': '30.8087', 'name': 'Lohora,Ludhiana,Punjab', 'lon': '75.8382'},
      '465680': {'lat': '23.5673', 'name': 'Seenkaturkipura,Rajgarh,Madhya Pradesh', 'lon': '76.7517'},
      '465683': {'lat': '23.6429', 'name': 'Purankhedi,Rajgarh,Madhya Pradesh', 'lon': '76.7935'},
      '465685': {'lat': '23.8612', 'name': 'Boda,Rajgarh,Madhya Pradesh', 'lon': '76.7346'},
      '768018': {'lat': '21.5174', 'name': 'Engineering College Burla,Sambalpur,Odisha', 'lon': '83.8727'},
      '768019': {'lat': '21.5174', 'name': 'Jyoti Vihar Burla,Sambalpur,Odisha', 'lon': '83.8727'},
      '133010': {'lat': '30.3285', 'name': 'Defence Colony,Ambala,Haryana', 'lon': '76.9422'},
      '768017': {'lat': '21.5098', 'name': 'Medical College Burla,Sambalpur,Odisha', 'lon': '83.8726'},
      '210201': {'lat': '25.306', 'name': 'P G College Attara,Banda,Uttar Pradesh', 'lon': '80.5695'},
      '742175': {'lat': '25.0597', 'name': 'Jhowbona,Murshidabad,West Bengal', 'lon': '87.9187'},
      '742174': {'lat': '22.6209', 'name': 'Dudsar,Murshidabad,West Bengal', 'lon': '87.9139'},
      '711313': {'lat': '22.5669', 'name': 'Ramchandrapur Bo (Sank.),Howrah,West Bengal', 'lon': '88.2397'},
      '711312': {'lat': '22.7573', 'name': 'Dakshin Radhanagar,Howrah,West Bengal', 'lon': '88.3137'},
      '711315': {'lat': '22.8336', 'name': 'Bhekutal,Howrah,West Bengal', 'lon': '88.0219'},
      '711314': {'lat': '23.0004', 'name': 'Jallabaj,Howrah,West Bengal', 'lon': '88.019'},
      '711317': {'lat': '22.8445', 'name': 'Radhadasi,Howrah,West Bengal', 'lon': '88.0661'},
      '711316': {'lat': '23.0454', 'name': 'Uluberia R.S,Howrah,West Bengal', 'lon': '87.9872'},
      '500022': {'lat': '17.456', 'name': 'Central Secretariat,Hyderabad,Telangana', 'lon': '78.6476'},
      '685554': {'lat': '9.7731', 'name': 'Chemmannar,Idukki,Kerala', 'lon': '77.1653'},
      '455223': {'lat': '22.823', 'name': 'Khajuriabina,Dewas,Madhya Pradesh', 'lon': '76.3'},
      '685551': {'lat': '9.719', 'name': 'Puttady,Idukki,Kerala', 'lon': '77.1483'},
      '832103': {'lat': '23.2202', 'name': 'Moubhandar,East Singhbhum,Jharkhand', 'lon': '86.0029'},
      '685553': {'lat': '9.8419', 'name': 'Mavady,Idukki,Kerala', 'lon': '77.1527'},
      '685552': {'lat': '9.7928', 'name': 'Sanniasioda,Idukki,Kerala', 'lon': '77.1887'},
      '843326': {'lat': '25.825', 'name': 'Pandaul Buzurg,Sitamarhi,Bihar', 'lon': '85.7842'},
      '281122': {'lat': '27.2431', 'name': 'Gadhya Latipur,Mathura,Uttar Pradesh', 'lon': '77.8301'},
      '281123': {'lat': '27.4411', 'name': 'Magorra,Mathura,Uttar Pradesh', 'lon': '77.7026'},
      '723104': {'lat': '24.6089', 'name': 'Kalabani,Purulia,West Bengal', 'lon': '87.8815'},
      '723101': {'lat': '23.2723', 'name': 'Jubli Compound,Purulia,West Bengal', 'lon': '87.7093'},
      '396530': {'lat': '22.1873', 'name': 'Chasa,Navsari,Gujarat', 'lon': '72.5899'},
      '723103': {'lat': '22.8645', 'name': 'Dimdiha,Purulia,West Bengal', 'lon': '86.7836'},
      '723102': {'lat': '22.808', 'name': 'Ralibera,Purulia,West Bengal', 'lon': '88.0861'},
      '626138': {'lat': '9.6779', 'name': 'Moovaraivendran,Virudhunagar,Tamil Nadu', 'lon': '78.1501'},
      '626139': {'lat': '9.3602', 'name': 'Kilavikulam,Virudhunagar,Tamil Nadu', 'lon': '77.5865'},
      '679554': {'lat': '10.8328', 'name': 'Malamalkkavu,Palakkad,Kerala', 'lon': '76.0751'},
      '765001': {'lat': '19.1661', 'name': 'Rayagada Gandhinagar,Rayagada,Odisha', 'lon': '83.443'},
      '679552': {'lat': '10.7695', 'name': 'Angadi,Palakkad,Kerala', 'lon': '76.0539'},
      '679553': {'lat': '10.7946', 'name': 'Kumbidi,Palakkad,Kerala', 'lon': '76.5586'},
      '679551': {'lat': '10.812', 'name': 'Anakkara (Palakkad),Palakkad,Kerala', 'lon': '76.0429'},
      '626130': {'lat': '9.4721', 'name': 'Vadamalapuram,Virudhunagar,Tamil Nadu', 'lon': '77.8409'},
      '626131': {'lat': '9.3552', 'name': 'Kallamanaickenpatti,Virudhunagar,Tamil Nadu', 'lon': '77.7468'},
      '626132': {'lat': '9.6364', 'name': 'Sethunarayanapuram,Virudhunagar,Tamil Nadu', 'lon': '77.6388'},
      '626133': {'lat': '9.6378', 'name': 'Pilavakkaldam,Virudhunagar,Tamil Nadu', 'lon': '77.601'},
      '626134': {'lat': '9.4135', 'name': 'Chettikurichi,Virudhunagar,Tamil Nadu', 'lon': '78.0546'},
      '626135': {'lat': '9.4835', 'name': 'Srivilliputhur Court Complexe,Virudhunagar,Tamil Nadu', 'lon': '77.7979'},
      '626136': {'lat': '9.3484', 'name': 'R.Reddiapatti,Virudhunagar,Tamil Nadu', 'lon': '77.6284'},
      '626137': {'lat': '9.4835', 'name': 'P.Ramachandrapuram,Virudhunagar,Tamil Nadu', 'lon': '77.7979'},
      '627007': {'lat': '8.3663', 'name': 'Jawahar Nagar (Tirunelveli),Tirunelveli,Tamil Nadu', 'lon': '77.9969'},
      '271840': {'lat': '27.5741', 'name': 'Bachkahwa,Shrawasti,Uttar Pradesh', 'lon': '81.6229'},
      '271841': {'lat': '27.5249', 'name': 'Chafaria Bazar,Bahraich,Uttar Pradesh', 'lon': '80.7032'},
      '122104': {'lat': '27.7501', 'name': 'Rawli,Gurgaon,Haryana', 'lon': '76.9567'},
      '122105': {'lat': '28.3681', 'name': 'Hasanpur,Gurgaon,Haryana', 'lon': '76.9408'},
      '207003': {'lat': '27.3866', 'name': 'Sheetal Pur,Etah,Uttar Pradesh', 'lon': '79.0282'},
      '122107': {'lat': '28.0827', 'name': 'Ferozepur Namak,Gurgaon,Haryana', 'lon': '77.021'},
      '122101': {'lat': '28.4832', 'name': 'Kadipur,Gurgaon,Haryana', 'lon': '76.8026'},
      '122102': {'lat': '27.9738', 'name': 'Maruthi Kunj,Gurgaon,Haryana', 'lon': '76.9248'},
      '122103': {'lat': '28.1902', 'name': 'Ghungola,Gurgaon,Haryana', 'lon': '77.0669'},
      '443403': {'lat': '20.9449', 'name': 'Adol BK,Buldhana,Maharashtra', 'lon': '76.4253'},
      '443402': {'lat': '21.0519', 'name': 'Jalgaon,Buldhana,Maharashtra', 'lon': '76.5346'},
      '443401': {'lat': '20.9061', 'name': 'Dhanora Vitali,Buldhana,Maharashtra', 'lon': '76.3465'},
      '795011': {'lat': '24.4065', 'name': 'Haotak Khullen,Bishnupur,Manipur', 'lon': '93.3452'},
      '122108': {'lat': '27.9256', 'name': 'Moolthan,Gurgaon,Haryana', 'lon': '77.0174'},
      '443404': {'lat': '20.8129', 'name': 'Shelgaon Mukund,Buldhana,Maharashtra', 'lon': '76.3725'},
      '246178': {'lat': '29.9216', 'name': 'Srikot,Pauri Garhwal,Uttarakhand', 'lon': '78.8647'},
      '246179': {'lat': '29.8141', 'name': 'Sulmorikhal,Pauri Garhwal,Uttarakhand', 'lon': '78.933'},
      '384285': {'lat': '23.599', 'name': 'Laxmipura,Patan,Gujarat', 'lon': '72.9687'},
      '229310': {'lat': '26.0597', 'name': 'Mohgawa,Raebareli,Uttar Pradesh', 'lon': '81.1293'},
      '246172': {'lat': '29.9007', 'name': 'Rithakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.82'},
      '246173': {'lat': '29.816', 'name': 'Hathnoor,Pauri Garhwal,Uttarakhand', 'lon': '78.8012'},
      '496331': {'lat': '23.027', 'name': 'Arra,Jashpur,Chattisgarh', 'lon': '84.0308'},
      '416208': {'lat': '18.955', 'name': 'Talashi,Kolhapur,Maharashtra', 'lon': '76.659'},
      '283111': {'lat': '27.0516', 'name': 'Swara,Agra,Uttar Pradesh', 'lon': '78.3982'},
      '246177': {'lat': '29.8798', 'name': 'Kadola,Pauri Garhwal,Uttarakhand', 'lon': '78.9948'},
      '207124': {'lat': '27.5121', 'name': 'Bilram,Etah,Uttar Pradesh', 'lon': '78.7132'},
      '246175': {'lat': '30.0593', 'name': 'Diusi,Pauri Garhwal,Uttarakhand', 'lon': '78.8251'},
      '271870': {'lat': '27.4356', 'name': 'Parsia,Shrawasti,Uttar Pradesh', 'lon': '81.8765'},
      '307803': {'lat': '25.0314', 'name': 'Sandhara,Jalor,Rajasthan', 'lon': '72.7209'},
      '416206': {'lat': '21.2219', 'name': 'Borbet,Kolhapur,Maharashtra', 'lon': '74.0885'},
      '843329': {'lat': '26.576', 'name': 'Nayagaon,Sitamarhi,Bihar', 'lon': '85.5397'},
      '583215': {'lat': '14.8125', 'name': 'Hampi Power House,Ballari,Karnataka', 'lon': '76.1184'},
      '583214': {'lat': '14.9488', 'name': 'G Kodihalli,Ballari,Karnataka', 'lon': '75.9323'},
      '583217': {'lat': '15.3011', 'name': 'Hyarada,Ballari,Karnataka', 'lon': '75.8591'},
      '416204': {'lat': '17.8906', 'name': 'Bhamate,Kolhapur,Maharashtra', 'lon': '74.5193'},
      '583211': {'lat': '15.1096', 'name': 'Amaravathi,Ballari,Karnataka', 'lon': '76.5334'},
      '303327': {'lat': '27.1496', 'name': 'Gullana,Dausa,Rajasthan', 'lon': '76.5834'},
      '583213': {'lat': '14.408', 'name': 'Kunchur,Davangere,Karnataka', 'lon': '75.9548'},
      '583212': {'lat': '15.0772', 'name': 'Marebbihal,Ballari,Karnataka', 'lon': '76.3726'},
      '473249': {'lat': '24.559', 'name': 'Gehunkheda,Guna,Madhya Pradesh', 'lon': '77.3331'},
      '583219': {'lat': '14.9899', 'name': 'Devagondanahalli,Ballari,Karnataka', 'lon': '75.9385'},
      '583218': {'lat': '14.5033', 'name': 'Tayanakanahalli,Ballari,Karnataka', 'lon': '76.458'},
      '828201': {'lat': '23.9439', 'name': 'Pradhan Khanta,Dhanbad,Jharkhand', 'lon': '86.1339'},
      '416201': {'lat': '16.8121', 'name': 'Ambavade,Kolhapur,Maharashtra', 'lon': '74.1101'},
      '458226': {'lat': '24.3001', 'name': 'Loharia,Neemuch,Madhya Pradesh', 'lon': '74.9803'},
      '625023': {'lat': '9.8751', 'name': 'Madras High Court Madurai Bench,Madurai,Tamil Nadu', 'lon': '78.1488'},
      '625022': {'lat': '9.7192', 'name': 'Tirumal,Madurai,Tamil Nadu', 'lon': '78.0498'},
      '625021': {'lat': '9.9266', 'name': 'Thenpalanji,Madurai,Tamil Nadu', 'lon': '78.0096'},
      '625020': {'lat': '9.8651', 'name': 'Sakkudi,Madurai,Tamil Nadu', 'lon': '78.2245'},
      '272001': {'lat': '26.7933', 'name': 'Gandhinagar (Basti),Basti,Uttar Pradesh', 'lon': '82.7685'},
      '844509': {'lat': '25.3883', 'name': 'Chakjamal,Vaishali,Bihar', 'lon': '85.5219'},
      '761018': {'lat': '19.1734', 'name': 'Ranigam,Gajapati,Odisha', 'lon': '84.1607'},
      '734426': {'lat': '26.6827', 'name': 'Buraganj,Darjiling,West Bengal', 'lon': '88.22'},
      '591136': {'lat': '16.2428', 'name': 'Yadwad,Belagavi,Karnataka', 'lon': '75.1803'},
      '638702': {'lat': '10.8192', 'name': 'Rudravathi,Erode,Tamil Nadu', 'lon': '77.4042'},
      '638703': {'lat': '11.0875', 'name': 'Thayamapalayam,Erode,Tamil Nadu', 'lon': '77.7807'},
      '509205': {'lat': '16.6307', 'name': 'Jatram Kalan,Mahabub Nagar,Telangana', 'lon': '77.6381'},
      '734423': {'lat': '26.6827', 'name': 'Pani Ghatta,Darjiling,West Bengal', 'lon': '88.22'},
      '591131': {'lat': '15.3275', 'name': 'Kodachwad,Belagavi,Karnataka', 'lon': '75.4038'},
      '591130': {'lat': '15.1444', 'name': 'Udpudi,Belagavi,Karnataka', 'lon': '75.4485'},
      '764011': {'lat': '19.1567', 'name': 'Deoghati,Koraput,Odisha', 'lon': '83.0443'},
      '284126': {'lat': '25.0347', 'name': 'Bhuchera,Lalitpur,Uttar Pradesh', 'lon': '78.4582'},
      '517551': {'lat': '13.7037', 'name': 'Pathiputtur,Chittoor,Andhra Pradesh', 'lon': '79.5316'},
      '734429': {'lat': '26.6827', 'name': 'Badrajote,Darjiling,West Bengal', 'lon': '88.22'},
      '811101': {'lat': '25.2758', 'name': 'Dhanuki,Nalanda,Bihar', 'lon': '85.7549'},
      '761015': {'lat': '19.5963', 'name': 'Mudumudia,Gajapati,Odisha', 'lon': '84.3951'},
      '811103': {'lat': '25.4276', 'name': 'Ugaban,Nalanda,Bihar', 'lon': '86.1703'},
      '851201': {'lat': '25.5439', 'name': 'Chautham,Khagaria,Bihar', 'lon': '86.6567'},
      '811105': {'lat': '25.0973', 'name': 'Audhey,Sheikhpura,Bihar', 'lon': '85.7917'},
      '811104': {'lat': '25.3043', 'name': 'Karkain,Patna,Bihar', 'lon': '85.9533'},
      '811107': {'lat': '25.2652', 'name': 'Dih Kushumbha,Sheikhpura,Bihar', 'lon': '85.2794'},
      '811106': {'lat': '25.2368', 'name': 'K. Tola Laxmipur,Lakhisarai,Bihar', 'lon': '86.1921'},
      '502256': {'lat': '17.952', 'name': 'Kakijanwada,Medak,Telangana', 'lon': '78.32'},
      '502257': {'lat': '17.8974', 'name': 'Karchal,Medak,Telangana', 'lon': '78.0039'},
      '627602': {'lat': '8.428', 'name': 'Tulukkapatti,Tirunelveli,Tamil Nadu', 'lon': '77.672'},
      '453112': {'lat': '22.7426', 'name': 'Palakhedi,Indore,Madhya Pradesh', 'lon': '75.7941'},
      '502251': {'lat': '17.8263', 'name': 'Mungi,Medak,Telangana', 'lon': '78.3436'},
      '416236': {'lat': '18.5854', 'name': 'Yalgud,Kolhapur,Maharashtra', 'lon': '76.2069'},
      '416234': {'lat': '16.4697', 'name': 'Nerli,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '173101': {'lat': '30.7385', 'name': 'Kathali Bharan,Sirmaur,Himachal Pradesh', 'lon': '77.3152'},
      '416232': {'lat': '18.9693', 'name': 'Bamani,Kolhapur,Maharashtra', 'lon': '76.6812'},
      '416230': {'lat': '16.5983', 'name': 'Bandiwade,Kolhapur,Maharashtra', 'lon': '74.5122'},
      '132054': {'lat': '29.2845', 'name': 'Kalri Jagir,Karnal,Haryana', 'lon': '76.6597'},
      '284404': {'lat': '24.3687', 'name': 'Gidwaha,Lalitpur,Uttar Pradesh', 'lon': '78.875'},
      '262310': {'lat': '28.8747', 'name': 'Chandani,Champawat,Uttarakhand', 'lon': '79.8096'},
      '627427': {'lat': '8.6747', 'name': 'Nalumukku,Tirunelveli,Tamil Nadu', 'lon': '77.5439'},
      '246142': {'lat': '29.4778', 'name': 'Kalagarh,Pauri Garhwal,Uttarakhand', 'lon': '78.7845'},
      '453115': {'lat': '22.8353', 'name': 'Sumtha,Indore,Madhya Pradesh', 'lon': '75.5919'},
      '621306': {'lat': '10.6197', 'name': 'Pannankombu,Tiruchirappalli,Tamil Nadu', 'lon': '78.4263'},
      '621307': {'lat': '10.5993', 'name': 'Aniyapur,Tiruchirappalli,Tamil Nadu', 'lon': '78.4126'},
      '621305': {'lat': '10.495', 'name': 'Minaveli,Pudukkottai,Tamil Nadu', 'lon': '78.5167'},
      '384421': {'lat': '23.0302', 'name': 'Jhotana,Mahesana,Gujarat', 'lon': '72.4338'},
      '761013': {'lat': '19.7817', 'name': 'P.Ramachandrapur,Ganjam,Odisha', 'lon': '84.6091'},
      '442001': {'lat': '20.7664', 'name': 'Waigaon Nipani,Wardha,Maharashtra', 'lon': '78.7001'},
      '466661': {'lat': '23.7839', 'name': 'Jharkheda,Sehore,Madhya Pradesh', 'lon': '76.7269'},
      '761012': {'lat': '20.5863', 'name': 'Gokarnapur,Ganjam,Odisha', 'lon': '84.3098'},
      '609801': {'lat': '10.9061', 'name': 'Palayagudalur,Nagapattinam,Tamil Nadu', 'lon': '79.5099'},
      '621308': {'lat': '10.4339', 'name': 'Kulathupatti,Sivaganga,Tamil Nadu', 'lon': '78.5114'},
      '284124': {'lat': '24.8373', 'name': 'Nauharkalan,Lalitpur,Uttar Pradesh', 'lon': '78.332'},
      '845315': {'lat': '26.8148', 'name': 'Mazirwa,East Champaran,Bihar', 'lon': '84.843'},
      '233227': {'lat': '25.5792', 'name': 'Pardhan Ki Bareji,Ghazipur,Uttar Pradesh', 'lon': '83.5585'},
      '233224': {'lat': '25.4872', 'name': 'Chochakpur,Ghazipur,Uttar Pradesh', 'lon': '83.4154'},
      '471625': {'lat': '24.4537', 'name': 'Bikarampur,Chhatarpur,Madhya Pradesh', 'lon': '80.4778'},
      '233222': {'lat': '25.77', 'name': 'Basudeopur,Ghazipur,Uttar Pradesh', 'lon': '83.7166'},
      '233223': {'lat': '25.5442', 'name': 'Rampur (Ghazipur),Ghazipur,Uttar Pradesh', 'lon': '83.1143'},
      '233221': {'lat': '25.6019', 'name': 'Singarpur,Ghazipur,Uttar Pradesh', 'lon': '83.0875'},
      '174004': {'lat': '31.3568465', 'name': 'Malyaur,Bilaspur (HP),Himachal Pradesh', 'lon': '76.7531672'},
      '174005': {'lat': '31.30175', 'name': 'Raghunath Pura,Bilaspur (HP),Himachal Pradesh', 'lon': '76.72305'},
      '174002': {'lat': '31.27879', 'name': 'Kaulan Wala Toba,Bilaspur (HP),Himachal Pradesh', 'lon': '76.656'},
      '174003': {'lat': '31.441928', 'name': 'Chalehli,Bilaspur (HP),Himachal Pradesh', 'lon': '76.712072'},
      '233228': {'lat': '25.7052', 'name': 'Tajpur Dehma,Ghazipur,Uttar Pradesh', 'lon': '83.9277'},
      '174001': {'lat': '31.3377', 'name': 'Bamta,Bilaspur (HP),Himachal Pradesh', 'lon': '76.7864'},
      '382830': {'lat': '22.4229', 'name': 'Kelisana,Mahesana,Gujarat', 'lon': '73.1675'},
      '496445': {'lat': '21.5598', 'name': 'Rapagula,Raigarh,Chattisgarh', 'lon': '83.1071'},
      '382835': {'lat': '22.4229', 'name': 'Ridrol,Gandhi Nagar,Gujarat', 'lon': '73.1675'},
      '284123': {'lat': '25.0227', 'name': 'Bhailoni Lodh,Lalitpur,Uttar Pradesh', 'lon': '78.7299'},
      '471318': {'lat': '24.2245', 'name': 'Saidara,Chhatarpur,Madhya Pradesh', 'lon': '79.3327'},
      '263153': {'lat': '28.9891', 'name': 'COLLECTORATE PARISAR,Udham Singh Nagar,Uttarakhand', 'lon': '79.3586'},
      '743312': {'lat': '22.3862', 'name': 'Nafarganj,South 24 Parganas,West Bengal', 'lon': '88.6875'},
      '847229': {'lat': '26.3464', 'name': 'Haripur Baxi Tola,Madhubani,Bihar', 'lon': '86.0206'},
      '847228': {'lat': '26.4481', 'name': 'Bhatchaura,Madhubani,Bihar', 'lon': '86.1602'},
      '761143': {'lat': '20.5228', 'name': 'B.Karadabadi,Ganjam,Odisha', 'lon': '84.5387'},
      '847226': {'lat': '26.537', 'name': 'Padma,Madhubani,Bihar', 'lon': '86.1409'},
      '761141': {'lat': '20.1385', 'name': 'Gallery,Ganjam,Odisha', 'lon': '84.5532'},
      '761140': {'lat': '20.0503', 'name': 'Tanarada,Ganjam,Odisha', 'lon': '85.1448'},
      '847223': {'lat': '26.1272', 'name': 'Parsuna,Madhubani,Bihar', 'lon': '85.9958'},
      '761146': {'lat': '20.904', 'name': 'Gothagam,Ganjam,Odisha', 'lon': '84.7539'},
      '413406': {'lat': '19.0148', 'name': 'Malegaon (Vairag),Solapur,Maharashtra', 'lon': '76.6595'},
      '284122': {'lat': '24.9244', 'name': 'Jamalpur,Lalitpur,Uttar Pradesh', 'lon': '78.4816'},
      '475675': {'lat': '26.0626', 'name': 'Padari,Datia,Madhya Pradesh', 'lon': '78.4043'},
      '607204': {'lat': '11.7615', 'name': 'Tirunavalur,Villupuram,Tamil Nadu', 'lon': '79.3754'},
      '475671': {'lat': '25.6625', 'name': 'Targuan,Datia,Madhya Pradesh', 'lon': '78.5396'},
      '475673': {'lat': '25.9248', 'name': 'Senguwan,Datia,Madhya Pradesh', 'lon': '78.531'},
      '614016': {'lat': '10.6455', 'name': 'Serangolam,Tiruvarur,Tamil Nadu', 'lon': '79.4202'},
      '180009': {'lat': '32.9251', 'name': 'SKUAST(CHATHA),Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '180005': {'lat': '32.9251', 'name': 'Karan Nagar (Jammu),Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '180004': {'lat': '32.9251', 'name': 'Guru Nanak Nagar (Jammu),Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '180007': {'lat': '32.9251', 'name': 'Janipur,Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '180006': {'lat': '32.9251', 'name': 'Qilla Bahu,Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '180001': {'lat': '32.9251', 'name': 'Bakshinagar,Jammu,Jammu & Kashmir', 'lon': '74.9142'},
      '335002': {'lat': '28.3117', 'name': 'Nathwala,Ganganagar,Rajasthan', 'lon': '75.2685'},
      '180003': {'lat': '32.6707', 'name': 'Jammu Cantt,Jammu,Jammu & Kashmir', 'lon': '75.0796'},
      '180002': {'lat': '33.0947', 'name': 'Gol Gujral,Jammu,Jammu & Kashmir', 'lon': '74.8039'},
      '680619': {'lat': '10.5089', 'name': 'Trithallur,Thrissur,Kerala', 'lon': '76.1306'},
      '680618': {'lat': '10.5121', 'name': 'Aranattukara,Thrissur,Kerala', 'lon': '76.1956'},
      '531029': {'lat': '18.0789', 'name': 'Vanthala,Visakhapatnam,Andhra Pradesh', 'lon': '82.4405'},
      '531028': {'lat': '17.9135', 'name': 'Kandivaram,Visakhapatnam,Andhra Pradesh', 'lon': '82.7734'},
      '246146': {'lat': '29.9766', 'name': 'Nahsain,Pauri Garhwal,Uttarakhand', 'lon': '78.657'},
      '680611': {'lat': '10.5089', 'name': 'Elthuruth,Thrissur,Kerala', 'lon': '76.1306'},
      '531022': {'lat': '17.8571', 'name': 'Rama Yogi Agraharam,Visakhapatnam,Andhra Pradesh', 'lon': '83.0076'},
      '680613': {'lat': '10.5089', 'name': 'Kandassankadavu,Thrissur,Kerala', 'lon': '76.1306'},
      '531020': {'lat': '18.1752', 'name': 'Ntpc Simhadri,Visakhapatnam,Andhra Pradesh', 'lon': '83.7157'},
      '531027': {'lat': '17.8961', 'name': 'Gadirai,Visakhapatnam,Andhra Pradesh', 'lon': '82.78'},
      '531026': {'lat': '17.8322', 'name': 'Chinamadina,Visakhapatnam,Andhra Pradesh', 'lon': '82.8606'},
      '531025': {'lat': '17.7627', 'name': 'Gunnempudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.8319'},
      '531024': {'lat': '18.2017', 'name': 'Iradapalle,Visakhapatnam,Andhra Pradesh', 'lon': '82.9456'},
      '606110': {'lat': '11.4643', 'name': 'C  Keeranur,Cuddalore,Tamil Nadu', 'lon': '79.349'},
      '759130': {'lat': '21.0419', 'name': 'Santarabandha,Angul,Odisha', 'lon': '84.88505'},
      '759132': {'lat': '20.85001', 'name': 'Purunakote,Angul,Odisha', 'lon': '85.09999'},
      '606115': {'lat': '11.685', 'name': 'Palakollai,Villupuram,Tamil Nadu', 'lon': '79.3512'},
      '232326': {'lat': '25.4024', 'name': 'Sinhani,Ghazipur,Uttar Pradesh', 'lon': '83.4224'},
      '462120': {'lat': '23.7566', 'name': 'Mangal Garh,Bhopal,Madhya Pradesh', 'lon': '77.2509'},
      '326037': {'lat': '24.4368', 'name': 'Khatkakhedi,Jhalawar,Rajasthan', 'lon': '76.7426'},
      '713143': {'lat': '23.5307', 'name': 'Koychor,Purba Bardhaman,West Bengal', 'lon': '87.94'},
      '508238': {'lat': '16.888', 'name': 'Togarrai,Nalgonda,Telangana', 'lon': '79.9498'},
      '505455': {'lat': '18.8804', 'name': 'New Bus Stand Jagtial,Karim Nagar,Telangana', 'lon': '78.807'},
      '232118': {'lat': '25.2819', 'name': 'Dhunnoo,Varanasi,Uttar Pradesh', 'lon': '83.0694'},
      '622501': {'lat': '10.4678', 'name': 'Thodaiyur,Pudukkottai,Tamil Nadu', 'lon': '78.7836'},
      '508234': {'lat': '17.1192', 'name': 'Ratnavaram,Nalgonda,Telangana', 'lon': '79.8797'},
      '425003': {'lat': '20.9173', 'name': 'Umala,Jalgaon,Maharashtra', 'lon': '75.6407'},
      '425002': {'lat': '20.7981', 'name': 'Bhokar,Jalgaon,Maharashtra', 'lon': '75.3731'},
      '425001': {'lat': '21.0011', 'name': 'Jalgaon Bazar,Jalgaon,Maharashtra', 'lon': '75.6111'},
      '577127': {'lat': '13.1646', 'name': 'Seethur,Chikkamagaluru,Karnataka', 'lon': '75.6417'},
      '276128': {'lat': '25.9874', 'name': 'Sewata,Azamgarh,Uttar Pradesh', 'lon': '82.9688'},
      '425004': {'lat': '21.0011', 'name': 'N M University Jalgaon,Jalgaon,Maharashtra', 'lon': '75.6111'},
      '276126': {'lat': '25.9623', 'name': 'Pithaupur Rasti,Mau,Uttar Pradesh', 'lon': '83.0466'},
      '276127': {'lat': '25.7889', 'name': 'Jameen Rasulpur,Azamgarh,Uttar Pradesh', 'lon': '83.0226'},
      '639112': {'lat': '10.3813', 'name': 'Pettavaithalai,Tiruchirappalli,Tamil Nadu', 'lon': '78.8214'},
      '276125': {'lat': '26.012', 'name': 'Chhattarpur Khushahal,Azamgarh,Uttar Pradesh', 'lon': '83.1072'},
      '274407': {'lat': '26.5599', 'name': 'Pagara Prsad Giri,Kushinagar,Uttar Pradesh', 'lon': '83.7028'},
      '276123': {'lat': '26.047', 'name': 'Bhawarpur,Azamgarh,Uttar Pradesh', 'lon': '83.0697'},
      '276121': {'lat': '26.1844', 'name': 'Patila Gaosh Pur,Azamgarh,Uttar Pradesh', 'lon': '83.1118'},
      '305408': {'lat': '25.9111', 'name': 'Dhundhari,Ajmer,Rajasthan', 'lon': '75.2212'},
      '784150': {'lat': '26.6833', 'name': 'Da Parbatia,Sonitpur,Assam', 'lon': '92.65'},
      '784153': {'lat': '26.6833', 'name': 'Khelmati,Sonitpur,Assam', 'lon': '92.65'},
      '741506': {'lat': '23.29', 'name': 'Krishnaganj,Nadia,West Bengal', 'lon': '88.1853'},
      '500030': {'lat': '17.3198', 'name': 'A.G.College,K.V.Rangareddy,Telangana', 'lon': '78.4031'},
      '784154': {'lat': '26.6833', 'name': 'Balipukhuri Tiniali,Sonitpur,Assam', 'lon': '92.65'},
      '424208': {'lat': '20.5153', 'name': 'Chincholi Pimpri,Jalgaon,Maharashtra', 'lon': '76.4159'},
      '757103': {'lat': '21.6178', 'name': 'Orachandbilla,Mayurbhanj,Odisha', 'lon': '85.8908'},
      '424206': {'lat': '20.7574', 'name': 'Nachankheda,Jalgaon,Maharashtra', 'lon': '75.6196'},
      '424207': {'lat': '20.9111', 'name': 'Shengole,Jalgaon,Maharashtra', 'lon': '75.6262'},
      '424204': {'lat': '20.6533', 'name': 'Mengaon,Jalgaon,Maharashtra', 'lon': '75.5769'},
      '424205': {'lat': '20.7085', 'name': 'Paldhi Pahur,Jalgaon,Maharashtra', 'lon': '75.683'},
      '424202': {'lat': '20.7054', 'name': 'Vadgaon Ambe,Jalgaon,Maharashtra', 'lon': '75.3683'},
      '424203': {'lat': '20.5833', 'name': 'Pimpalgaon,Jalgaon,Maharashtra', 'lon': '75.4833'},
      '305406': {'lat': '26.066', 'name': 'Hingonia,Ajmer,Rajasthan', 'lon': '75.1309'},
      '424201': {'lat': '20.6783', 'name': 'Anturli  -  Ii,Jalgaon,Maharashtra', 'lon': '75.496'},
      '229126': {'lat': '26.441', 'name': 'Kotwa Mohammadabad,Raebareli,Uttar Pradesh', 'lon': '81.3778'},
      '757101': {'lat': '21.5831', 'name': 'Radho,Mayurbhanj,Odisha', 'lon': '85.6858'},
      '741503': {'lat': '23.4489', 'name': 'Gede,Nadia,West Bengal', 'lon': '88.7637'},
      '413402': {'lat': '19.062', 'name': 'Raleras,Solapur,Maharashtra', 'lon': '75.644'},
      '639113': {'lat': '11.0742', 'name': 'Kadambankurichi,Karur,Tamil Nadu', 'lon': '78.0852'},
      '757106': {'lat': '21.3329', 'name': 'Sundhal,Mayurbhanj,Odisha', 'lon': '85.3209'},
      '261403': {'lat': '27.2973', 'name': 'Ramshala,Sitapur,Uttar Pradesh', 'lon': '80.5684'},
      '261402': {'lat': '27.351', 'name': 'Naimisharanya,Sitapur,Uttar Pradesh', 'lon': '80.4857'},
      '261405': {'lat': '27.4349', 'name': 'Machharehta,Sitapur,Uttar Pradesh', 'lon': '80.6137'},
      '261404': {'lat': '27.4849', 'name': 'Dadeura,Sitapur,Uttar Pradesh', 'lon': '80.7157'},
      '829135': {'lat': '23.6575', 'name': 'Bhurkunda,Ramgarh,Jharkhand', 'lon': '85.3519'},
      '757107': {'lat': '21.8879', 'name': 'Kainfulia,Mayurbhanj,Odisha', 'lon': '85.8805'},
      '518308': {'lat': '15.5767', 'name': 'Hebbatam,Kurnool,Andhra Pradesh', 'lon': '77.1233'},
      '757104': {'lat': '22.0034', 'name': 'Gayalmara,Mayurbhanj,Odisha', 'lon': '85.7284'},
      '723156': {'lat': '23.2878', 'name': 'Murardih,Purulia,West Bengal', 'lon': '86.4834'},
      '524421': {'lat': '13.9259', 'name': 'Kotambedu,Nellore,Andhra Pradesh', 'lon': '79.8718'},
      '518302': {'lat': '15.628', 'name': 'Peddathumbalam,Kurnool,Andhra Pradesh', 'lon': '77.2935'},
      '518301': {'lat': '15.6152', 'name': 'Adoni-town,Kurnool,Andhra Pradesh', 'lon': '77.251'},
      '757105': {'lat': '21.4039', 'name': 'Gangraj,Mayurbhanj,Odisha', 'lon': '85.53'},
      '222127': {'lat': '25.6674', 'name': 'Shahopatti,Jaunpur,Uttar Pradesh', 'lon': '82.4965'},
      '741238': {'lat': '23.0732', 'name': 'Gangsara Majhergram,Nadia,West Bengal', 'lon': '88.2769'},
      '412801': {'lat': '17.6686', 'name': 'Naigaon,Satara,Maharashtra', 'lon': '74.1738'},
      '515301': {'lat': '13.9521', 'name': 'Byagarlapalli,Ananthapur,Andhra Pradesh', 'lon': '77.2948'},
      '412803': {'lat': '18.3632', 'name': 'Phulenagar Wai,Satara,Maharashtra', 'lon': '74.5602'},
      '515303': {'lat': '13.9274', 'name': 'Cherlokodigepalli,Ananthapur,Andhra Pradesh', 'lon': '77.323'},
      '412805': {'lat': '18.3771', 'name': 'Bhilar,Satara,Maharashtra', 'lon': '74.9778'},
      '515305': {'lat': '14.0123', 'name': 'Jambalabanda,Ananthapur,Andhra Pradesh', 'lon': '77.1368'},
      '741235': {'lat': '23.0732', 'name': 'Bidhanpark,Nadia,West Bengal', 'lon': '88.2769'},
      '412806': {'lat': '17.6756', 'name': 'Sonat,Satara,Maharashtra', 'lon': '74.2283'},
      '686514': {'lat': '9.6101', 'name': 'Elamkadu,Kottayam,Kerala', 'lon': '76.8889'},
      '686515': {'lat': '9.5416', 'name': 'Kanam,Kottayam,Kerala', 'lon': '76.6986'},
      '686516': {'lat': '9.5573', 'name': 'Meenadom,Kottayam,Kerala', 'lon': '76.8523'},
      '686517': {'lat': '9.5573', 'name': 'Chakompathal,Kottayam,Kerala', 'lon': '76.8523'},
      '686510': {'lat': '9.4495', 'name': 'Chathenthara,Pathanamthitta,Kerala', 'lon': '76.8748'},
      '686511': {'lat': '9.5573', 'name': 'Mannadisala,Pathanamthitta,Kerala', 'lon': '76.8523'},
      '686512': {'lat': '9.5424', 'name': 'Vengathanam,Kottayam,Kerala', 'lon': '76.8406'},
      '686513': {'lat': '9.5375', 'name': 'Rajendraprasad Colony,Kottayam,Kerala', 'lon': '76.8852'},
      '401702': {'lat': '20.0847', 'name': 'Gholvad,Thane,Maharashtra', 'lon': '72.7289'},
      '801503': {'lat': '25.6341', 'name': 'Chandmari,Patna,Bihar', 'lon': '84.9787'},
      '686518': {'lat': '9.5573', 'name': 'Vizhikithode,Kottayam,Kerala', 'lon': '76.8523'},
      '686519': {'lat': '9.5573', 'name': 'Kavumbhagom,Kottayam,Kerala', 'lon': '76.8523'},
      '801506': {'lat': '25.5925', 'name': 'Sahay Nagar,Patna,Bihar', 'lon': '85.0684'},
      '401703': {'lat': '20.1002', 'name': 'Kosbad-hill,Thane,Maharashtra', 'lon': '72.7339'},
      '848236': {'lat': '25.7799', 'name': 'Singhia Ghat,Samastipur,Bihar', 'lon': '85.9496'},
      '639111': {'lat': '10.9427', 'name': 'Paramathi (Karur),Karur,Tamil Nadu', 'lon': '77.9194'},
      '831021': {'lat': '23.62', 'name': 'Nodal Delivery Office (Jamshedpur),East Singhbhum,Jharkhand', 'lon': '85.5497'},
      '274404': {'lat': '26.2054', 'name': 'Baghauchghat,Deoria,Uttar Pradesh', 'lon': '84.1635'},
      '572105': {'lat': '13.3294', 'name': 'SSIT Campus,Tumakuru,Karnataka', 'lon': '77.0787'},
      '401701': {'lat': '20.1157', 'name': 'Borigaon,Thane,Maharashtra', 'lon': '72.7388'},
      '670511': {'lat': '12.2553', 'name': 'Josegiri,Kannur,Kerala', 'lon': '75.4335'},
      '832110': {'lat': '22.9195', 'name': 'Azadnagar,East Singhbhum,Jharkhand', 'lon': '85.7645'},
      '832113': {'lat': '22.9195', 'name': 'Hata Chowk,East Singhbhum,Jharkhand', 'lon': '85.7645'},
      '832112': {'lat': '22.8449', 'name': 'Pokharia,Seraikela-kharsawan,Jharkhand', 'lon': '85.9516'},
      '623533': {'lat': '10.287', 'name': 'Uthirakosamangai,Ramanathapuram,Tamil Nadu', 'lon': '78.8998'},
      '362266': {'lat': '20.9462', 'name': 'Dabhor,Junagadh,Gujarat', 'lon': '70.3229'},
      '362265': {'lat': '20.9077', 'name': 'Veraval,Junagadh,Gujarat', 'lon': '70.3679'},
      '623530': {'lat': '9.5852', 'name': 'Toruvalur,Ramanathapuram,Tamil Nadu', 'lon': '78.5963'},
      '362263': {'lat': '21.2215', 'name': 'Anandpur,Junagadh,Gujarat', 'lon': '70.554'},
      '623536': {'lat': '8.4416', 'name': 'Sembidaiyarkulam,Ramanathapuram,Tamil Nadu', 'lon': '77.9135'},
      '362260': {'lat': '21.6015', 'name': 'Alidhra,Junagadh,Gujarat', 'lon': '71.0288'},
      '623538': {'lat': '9.7112', 'name': 'Arunoothimangalam,Ramanathapuram,Tamil Nadu', 'lon': '78.8363'},
      '833220': {'lat': '22.8933', 'name': 'Nengtasai,Seraikela-kharsawan,Jharkhand', 'lon': '85.7477'},
      '362268': {'lat': '21.7402', 'name': 'Ajotha,Junagadh,Gujarat', 'lon': '70.6908'},
      '639116': {'lat': '11.0221', 'name': 'Vangal East,Karur,Tamil Nadu', 'lon': '78.121'},
      '247656': {'lat': '29.7852', 'name': 'Libberheri,Haridwar,Uttarakhand', 'lon': '77.8836'},
      '491111': {'lat': '20.8039', 'name': 'Sankra,Durg,Chattisgarh', 'lon': '81.205'},
      '741181': {'lat': '22.0928', 'name': 'Paglachandi,Nadia,West Bengal', 'lon': '88.3603'},
      '627004': {'lat': '8.5335', 'name': 'Tirunelveli Pettai East,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '573165': {'lat': '13.0073', 'name': 'Devaladakere,Hassan,Karnataka', 'lon': '75.7106'},
      '678542': {'lat': '10.6633', 'name': 'Kattusseri,Palakkad,Kerala', 'lon': '76.4786'},
      '535260': {'lat': '18.2315', 'name': 'Bondapalli,Vizianagaram,Andhra Pradesh', 'lon': '83.3506'},
      '678541': {'lat': '10.6633', 'name': 'Alathur Mbr H.O,Palakkad,Kerala', 'lon': '76.4786'},
      '678546': {'lat': '10.6633', 'name': 'Erimayur,Palakkad,Kerala', 'lon': '76.4786'},
      '678544': {'lat': '10.6699', 'name': 'Athipotta,Palakkad,Kerala', 'lon': '76.4689'},
      '678545': {'lat': '10.6633', 'name': 'Pullode,Palakkad,Kerala', 'lon': '76.4786'},
      '403202': {'lat': '15.4294', 'name': 'Bambolim Complex,North Goa,Goa', 'lon': '73.9413'},
      '274402': {'lat': '26.6626', 'name': 'Dhuria Khas,Kushinagar,Uttar Pradesh', 'lon': '83.437'},
      '249145': {'lat': '30.3471', 'name': 'Chopriyalgaon,Tehri Garhwal,Uttarakhand', 'lon': '78.2406'},
      '509202': {'lat': '16.9571', 'name': 'Pomal,Mahabub Nagar,Telangana', 'lon': '78.1412'},
      '604154': {'lat': '12.1632', 'name': 'Olagapuram,Villupuram,Tamil Nadu', 'lon': '79.7719'},
      '605759': {'lat': '11.9448', 'name': 'Manampoondi,Villupuram,Tamil Nadu', 'lon': '79.2086'},
      '464240': {'lat': '23.969', 'name': 'Kulhar,Vidisha,Madhya Pradesh', 'lon': '78.0615'},
      '261125': {'lat': '27.1483', 'name': 'Nauwa Mahmoodpur,Sitapur,Uttar Pradesh', 'lon': '80.9584'},
      '274401': {'lat': '26.6301', 'name': 'Banbira,Kushinagar,Uttar Pradesh', 'lon': '83.8702'},
      '387620': {'lat': '23.0995', 'name': 'Thavad,Kheda,Gujarat', 'lon': '73.163'},
      '263632': {'lat': '29.9863', 'name': 'Utraura,Bageshwar,Uttarakhand', 'lon': '79.9225'},
      '743383': {'lat': '22.4339', 'name': 'Raidighi,South 24 Parganas,West Bengal', 'lon': '88.3542'},
      '624304': {'lat': '10.3013', 'name': 'Vadakattupatti,Dindigul,Tamil Nadu', 'lon': '78.107'},
      '641305': {'lat': '11.5034', 'name': 'Kallar,Coimbatore,Tamil Nadu', 'lon': '77.7977'},
      '517305': {'lat': '13.7805', 'name': 'Yellutla,Chittoor,Andhra Pradesh', 'lon': '78.4505'},
      '230502': {'lat': '26.2698', 'name': 'Raibi Rajanipur,Pratapgarh,Uttar Pradesh', 'lon': '82.3494'},
      '285126': {'lat': '26.2822', 'name': 'Ramhetpura,Jalaun,Uttar Pradesh', 'lon': '79.2013'},
      '639115': {'lat': '10.882', 'name': 'Perugamani,Tiruchirappalli,Tamil Nadu', 'lon': '78.5591'},
      '230503': {'lat': '25.9832', 'name': 'Kotha Newarhiya,Pratapgarh,Uttar Pradesh', 'lon': '81.8519'},
      '495112': {'lat': '22.3513', 'name': 'Ganiyari,Bilaspur(CGH),Chattisgarh', 'lon': '81.9617'},
      '495113': {'lat': '22.3088', 'name': 'Salkhanowgaon,Bilaspur(CGH),Chattisgarh', 'lon': '81.9503'},
      '301408': {'lat': '27.2434', 'name': 'Srichandpura,Alwar,Rajasthan', 'lon': '76.7131'},
      '301409': {'lat': '27.4332', 'name': 'Jamdoli,Alwar,Rajasthan', 'lon': '76.2796'},
      '502032': {'lat': '17.8409', 'name': 'Ramachandrapuram He,Medak,Telangana', 'lon': '78.3705'},
      '495115': {'lat': '22.1802', 'name': 'Salheghori,Bilaspur(CGH),Chattisgarh', 'lon': '81.5395'},
      '301404': {'lat': '27.7694', 'name': 'Bahroj,Alwar,Rajasthan', 'lon': '76.5954'},
      '301405': {'lat': '27.7154', 'name': 'Baghore,Alwar,Rajasthan', 'lon': '76.6752'},
      '301406': {'lat': '27.3877', 'name': 'Malakhera,Alwar,Rajasthan', 'lon': '76.6266'},
      '301407': {'lat': '27.8971', 'name': 'Balluwas,Alwar,Rajasthan', 'lon': '76.5464'},
      '301401': {'lat': '27.9669', 'name': 'Saraikalan,Alwar,Rajasthan', 'lon': '76.5799'},
      '301402': {'lat': '27.4257', 'name': 'Lakkakanagal,Alwar,Rajasthan', 'lon': '76.4397'},
      '533284': {'lat': '17.3943', 'name': 'Amudalabanda,East Godavari,Andhra Pradesh', 'lon': '81.889'},
      '502278': {'lat': '17.8106', 'name': 'Raiploe,Medak,Telangana', 'lon': '78.7005'},
      '612801': {'lat': '10.9501', 'name': 'Sathanur-PSM,Tiruvarur,Tamil Nadu', 'lon': '79.3358'},
      '641114': {'lat': '10.9562', 'name': 'Karunyanagar,Coimbatore,Tamil Nadu', 'lon': '76.7594'},
      '494556': {'lat': '18.5399', 'name': 'Gumyapal,Dantewada,Chattisgarh', 'lon': '81.3302'},
      '641111': {'lat': '10.9775', 'name': 'Vellalapalayam Podanur,Coimbatore,Tamil Nadu', 'lon': '77.026'},
      '641110': {'lat': '11.1623', 'name': 'Kondayampalayam,Coimbatore,Tamil Nadu', 'lon': '76.9973'},
      '641113': {'lat': '10.9968', 'name': 'Vivekanandapuram,Coimbatore,Tamil Nadu', 'lon': '76.9229'},
      '641112': {'lat': '10.8911', 'name': 'Ettimadai,Coimbatore,Tamil Nadu', 'lon': '76.9087'},
      '261208': {'lat': '27.4941', 'name': 'Ramuwapur,Sitapur,Uttar Pradesh', 'lon': '81.3907'},
      '363020': {'lat': '22.7028', 'name': 'Munjpar,Surendra Nagar,Gujarat', 'lon': '71.6156'},
      '176098': {'lat': '31.8571', 'name': 'Bhatwan,Kangra,Himachal Pradesh', 'lon': '76.4617'},
      '176097': {'lat': '31.8007', 'name': 'Barram Khurd,Kangra,Himachal Pradesh', 'lon': '76.6313'},
      '176096': {'lat': '31.9126', 'name': 'Kosri,Kangra,Himachal Pradesh', 'lon': '76.4971'},
      '176095': {'lat': '31.8007', 'name': 'Tammber,Kangra,Himachal Pradesh', 'lon': '76.6313'},
      '176094': {'lat': '32.1088', 'name': 'Draman,Kangra,Himachal Pradesh', 'lon': '76.3177'},
      '176093': {'lat': '31.6627', 'name': 'Kona,Kangra,Himachal Pradesh', 'lon': '76.7949'},
      '176092': {'lat': '31.9818', 'name': 'Kharuhal,Kangra,Himachal Pradesh', 'lon': '76.4579'},
      '176091': {'lat': '31.8287', 'name': 'Harsi,Kangra,Himachal Pradesh', 'lon': '76.3485'},
      '176090': {'lat': '31.8007', 'name': 'Lower Ghanala,Mandi,Himachal Pradesh', 'lon': '76.6313'},
      '493551': {'lat': '21.348', 'name': 'Bhimbhauri,Mahasamund,Chattisgarh', 'lon': '82.4904'},
      '534134': {'lat': '16.7717', 'name': 'Moyyeru,West Godavari,Andhra Pradesh', 'lon': '81.4423'},
      '743145': {'lat': '23.37', 'name': 'Majhipara,North 24 Parganas,West Bengal', 'lon': '88.3378'},
      '743144': {'lat': '22.805', 'name': 'Metal & Steel Factory,North 24 Parganas,West Bengal', 'lon': '88.3709'},
      '403206': {'lat': '15.4294', 'name': 'Goa University,North Goa,Goa', 'lon': '73.9413'},
      '464001': {'lat': '23.6183', 'name': 'Mohnakhejda,Vidisha,Madhya Pradesh', 'lon': '78.0318'},
      '261207': {'lat': '27.3929', 'name': 'Angarasi Shekhwapur,Sitapur,Uttar Pradesh', 'lon': '81.1155'},
      '841302': {'lat': '25.6957', 'name': 'Purwari Telpa,Saran,Bihar', 'lon': '84.7961'},
      '224158': {'lat': '26.4259', 'name': 'Uchhahpali,Faizabad,Uttar Pradesh', 'lon': '82.4651'},
      '841301': {'lat': '25.7231', 'name': 'Chapra H.O,Saran,Bihar', 'lon': '84.8277'},
      '841305': {'lat': '25.6318', 'name': 'Sengar Tola,Saran,Bihar', 'lon': '84.7223'},
      '302016': {'lat': '26.9258', 'name': 'Collectorate (Jaipur),Jaipur,Rajasthan', 'lon': '75.787'},
      '152020': {'lat': '30.6256', 'name': 'Railway Road Mandi Ladhuka,Fazilka,Punjab', 'lon': '74.6654'},
      '506342': {'lat': '17.5249', 'name': 'Pulukurthy,Warangal,Telangana', 'lon': '79.3254'},
      '302015': {'lat': '26.7865', 'name': 'Barkat Nagar,Jaipur,Rajasthan', 'lon': '75.5809'},
      '302012': {'lat': '26.9632', 'name': 'Khora Bisal,Jaipur,Rajasthan', 'lon': '75.7256'},
      '302013': {'lat': '26.3961', 'name': 'Vishwakarma Industrial Area,Jaipur,Rajasthan', 'lon': '75.0325'},
      '152021': {'lat': '31.0619', 'name': 'Malluwalia Wala,Firozpur,Punjab', 'lon': '74.8228'},
      '571236': {'lat': '12.6123', 'name': 'Kusbur,Kodagu,Karnataka', 'lon': '75.8714'},
      '571237': {'lat': '12.4032', 'name': 'Andagove,Kodagu,Karnataka', 'lon': '75.9192'},
      '571234': {'lat': '12.496', 'name': 'Guddehosur,Kodagu,Karnataka', 'lon': '76.2066'},
      '571235': {'lat': '12.7306', 'name': 'Alur Siddapura,Kodagu,Karnataka', 'lon': '76.0315'},
      '571232': {'lat': '12.7661', 'name': 'Kudumangalore,Kodagu,Karnataka', 'lon': '75.6888'},
      '302018': {'lat': '26.8468', 'name': 'Durgapura,Jaipur,Rajasthan', 'lon': '75.7924'},
      '571231': {'lat': '12.3244', 'name': 'Nirugunda,Kodagu,Karnataka', 'lon': '75.8033'},
      '274182': {'lat': '26.9308', 'name': 'Koraya,Deoria,Uttar Pradesh', 'lon': '83.4784'},
      '494553': {'lat': '18.7245', 'name': 'Bhansi,Dantewada,Chattisgarh', 'lon': '81.3035'},
      '224155': {'lat': '25.9753', 'name': 'Gaura Mohammadpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.8913'},
      '152024': {'lat': '30.5892', 'name': 'Chak Gulam Rasool Wala,Firozpur,Punjab', 'lon': '74.2204'},
      '481882': {'lat': '23.0504', 'name': 'Majhiyakhar,Dindori,Madhya Pradesh', 'lon': '80.7824'},
      '826009': {'lat': '24.0858', 'name': 'K.K.Polytechnic,Dhanbad,Jharkhand', 'lon': '86.7287'},
      '481995': {'lat': '22.3814', 'name': 'Khatola,Mandla,Madhya Pradesh', 'lon': '80.7038'},
      '152025': {'lat': '30.5322', 'name': 'Khara,Faridkot,Punjab', 'lon': '74.7137'},
      '609802': {'lat': '11.0092', 'name': 'Tiyagarajapuram,Thanjavur,Tamil Nadu', 'lon': '79.499'},
      '224157': {'lat': '26.4475', 'name': 'Hath Pakar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.323'},
      '152026': {'lat': '30.3962', 'name': 'Rahurian Wali,Muktsar,Punjab', 'lon': '74.5165'},
      '360590': {'lat': '21.4279', 'name': 'Bharvada,Porbandar,Gujarat', 'lon': '69.9417'},
      '246439': {'lat': '30.5493', 'name': 'Guptkashi,Rudraprayag,Uttarakhand', 'lon': '79.1512'},
      '768225': {'lat': '21.4101', 'name': 'R. Kudapali,Jharsuguda,Odisha', 'lon': '83.7121'},
      '768224': {'lat': '21.5041', 'name': 'Ragba,Sambalpur,Odisha', 'lon': '84.0001'},
      '768227': {'lat': '21.5808', 'name': 'Salebhadi,Sambalpur,Odisha', 'lon': '84.4229'},
      '768226': {'lat': '21.6396', 'name': 'Kandheikela,Jharsuguda,Odisha', 'lon': '83.5895'},
      '768221': {'lat': '21.1444', 'name': 'Baunslaga,Sambalpur,Odisha', 'lon': '84.4565'},
      '768220': {'lat': '21.8807', 'name': 'Bhimjore,Jharsuguda,Odisha', 'lon': '84.1557'},
      '827003': {'lat': '22.604', 'name': 'Sector- III,Bokaro,Jharkhand', 'lon': '85.533'},
      '768222': {'lat': '21.7387', 'name': 'Baxma,Sambalpur,Odisha', 'lon': '84.2366'},
      '826006': {'lat': '24.2252', 'name': 'Rajganj,Dhanbad,Jharkhand', 'lon': '86.9817'},
      '481990': {'lat': '23.113', 'name': 'Kachharimal,Dindori,Madhya Pradesh', 'lon': '80.6416'},
      '827009': {'lat': '22.604', 'name': 'Sector- IX,Bokaro,Jharkhand', 'lon': '85.533'},
      '768228': {'lat': '21.4104', 'name': 'Nuadihi,Debagarh,Odisha', 'lon': '84.8048'},
      '212111': {'lat': '25.3986', 'name': 'Purwa Khas,Allahabad,Uttar Pradesh', 'lon': '81.8418'},
      '496665': {'lat': '22.0468', 'name': 'Bojia,Raigarh,Chattisgarh', 'lon': '83.049'},
      '494552': {'lat': '18.6862', 'name': 'Etepal,Bijapur(CGH),Chattisgarh', 'lon': '81.4222'},
      '721629': {'lat': '22.284', 'name': 'Moyna,East Midnapore,West Bengal', 'lon': '88.054'},
      '473781': {'lat': '25.2676', 'name': 'Pahada Khurd,Shivpuri,Madhya Pradesh', 'lon': '77.5208'},
      '794110': {'lat': '25.7027', 'name': 'Gairong,East Garo Hills,Meghalaya', 'lon': '90.8233'},
      '794111': {'lat': '25.5693', 'name': 'Sakhadamgiri,East Garo Hills,Meghalaya', 'lon': '90.6142'},
      '794112': {'lat': '25.6007', 'name': 'Mendipathar,West Garo Hills,Meghalaya', 'lon': '90.6107'},
      '794114': {'lat': '25.3941', 'name': 'Dambuk Agal,South Garo Hills,Meghalaya', 'lon': '90.4309'},
      '416008': {'lat': '20.3396', 'name': 'Pratibhanagar,Kolhapur,Maharashtra', 'lon': '77.2373'},
      '411058': {'lat': '18.6032', 'name': 'Warje,Pune,Maharashtra', 'lon': '73.7626'},
      '411057': {'lat': '18.6032', 'name': 'Maan SO,Pune,Maharashtra', 'lon': '73.7626'},
      '416004': {'lat': '20.3396', 'name': 'Ujlaiwadi,Kolhapur,Maharashtra', 'lon': '77.2373'},
      '416007': {'lat': '16.4697', 'name': 'Kalamba,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '416006': {'lat': '20.3396', 'name': 'Kasba Bavada,Kolhapur,Maharashtra', 'lon': '77.2373'},
      '416001': {'lat': '20.4627', 'name': 'Kurukali,Kolhapur,Maharashtra', 'lon': '77.1326'},
      '411052': {'lat': '18.6032', 'name': 'Karvenagar,Pune,Maharashtra', 'lon': '73.7626'},
      '411051': {'lat': '18.6032', 'name': 'Anandnagar (Pune),Pune,Maharashtra', 'lon': '73.7626'},
      '416002': {'lat': '20.3396', 'name': 'Shaniwar Peth (Kolhapur),Kolhapur,Maharashtra', 'lon': '77.2373'},
      '811314': {'lat': '24.7608', 'name': 'P. Matihana,Jamui,Bihar', 'lon': '86.1832'},
      '811315': {'lat': '24.9843', 'name': 'B Bahadurpur,Jamui,Bihar', 'lon': '86.0109'},
      '811316': {'lat': '24.8626', 'name': 'Simultala,Jamui,Bihar', 'lon': '86.3378'},
      '811317': {'lat': '24.839', 'name': 'Khaira (Jamui),Jamui,Bihar', 'lon': '86.1605'},
      '811310': {'lat': '25.0794', 'name': 'Janki Dih Beldaria,Lakhisarai,Bihar', 'lon': '86.1733'},
      '811311': {'lat': '25.1717', 'name': 'Manjhway,Jamui,Bihar', 'lon': '85.9452'},
      '811312': {'lat': '24.7838', 'name': 'Anandpur,Jamui,Bihar', 'lon': '86.2952'},
      '811313': {'lat': '24.974', 'name': 'Mallehpur,Jamui,Bihar', 'lon': '86.2555'},
      '782126': {'lat': '26.5544', 'name': 'Salkati Pathar,Marigaon,Assam', 'lon': '92.4077'},
      '782127': {'lat': '26.4333', 'name': 'Dumkura Bazar,Marigaon,Assam', 'lon': '92.35'},
      '782124': {'lat': '26.5544', 'name': 'Fakali Pathar,Nagaon,Assam', 'lon': '92.4077'},
      '782125': {'lat': '26.3873', 'name': 'Teliachapari,Nagaon,Assam', 'lon': '91.3478'},
      '782122': {'lat': '26.9021', 'name': 'Borbheti,Nagaon,Assam', 'lon': '94.1717'},
      '782123': {'lat': '26.4679', 'name': 'Chamuagaon,Nagaon,Assam', 'lon': '92.4734'},
      '782120': {'lat': '26.5544', 'name': 'Ambagan,Nagaon,Assam', 'lon': '92.4077'},
      '782121': {'lat': '26.5544', 'name': 'Bhuragaon,Nagaon,Assam', 'lon': '92.4077'},
      '637405': {'lat': '11.2438', 'name': 'Kondamanaickenpatti,Namakkal,Tamil Nadu', 'lon': '78.1956'},
      '712235': {'lat': '22.7051', 'name': 'Konnagar,Hooghly,West Bengal', 'lon': '88.3445'},
      '826004': {'lat': '23.6676', 'name': 'Nagnagar,Dhanbad,Jharkhand', 'lon': '85.9699'},
      '516218': {'lat': '14.1802', 'name': 'Machanur,Cuddapah,Andhra Pradesh', 'lon': '79.0809'},
      '516217': {'lat': '15.1077', 'name': 'Kalasapadu,Cuddapah,Andhra Pradesh', 'lon': '78.9415'},
      '516216': {'lat': '14.445', 'name': 'Pendlimarri,Cuddapah,Andhra Pradesh', 'lon': '78.6399'},
      '516215': {'lat': '13.7647', 'name': 'Narayana Reddy Palli,Cuddapah,Andhra Pradesh', 'lon': '78.506'},
      '516214': {'lat': '14.1013', 'name': 'Reddyvaripalli,Cuddapah,Andhra Pradesh', 'lon': '78.2243'},
      '516213': {'lat': '14.3334', 'name': 'Mohiddin Sahib Palli,Cuddapah,Andhra Pradesh', 'lon': '79.064'},
      '361170': {'lat': '22.1907', 'name': 'Haripar,Jamnagar,Gujarat', 'lon': '69.9635'},
      '208011': {'lat': '26.5934', 'name': 'RBI Colony,Kanpur Nagar,Uttar Pradesh', 'lon': '80.6879'},
      '796004': {'lat': '23.498', 'name': 'Mizoram University,Aizawl,Mizoram', 'lon': '92.7292'},
      '533305': {'lat': '17.4522', 'name': 'Yandagandi,East Godavari,Andhra Pradesh', 'lon': '82.206'},
      '533306': {'lat': '17.5795', 'name': 'Kotipalli,East Godavari,Andhra Pradesh', 'lon': '81.8979'},
      '533307': {'lat': '16.9413', 'name': 'Vedurumudi,East Godavari,Andhra Pradesh', 'lon': '81.5004'},
      '533308': {'lat': '17.1885', 'name': 'Nelaturu,East Godavari,Andhra Pradesh', 'lon': '82.0118'},
      '533309': {'lat': '16.7398', 'name': 'Kapileswarapuram (East Godavari),East Godavari,Andhra Pradesh', 'lon': '80.9911'},
      '563121': {'lat': '13.5782', 'name': 'Peddapalli,Chikkaballapur,Karnataka', 'lon': '77.8362'},
      '721222': {'lat': '23.2438', 'name': 'Agar,West Midnapore,West Bengal', 'lon': '87.9178'},
      '758040': {'lat': '21.3503', 'name': 'Kiriburu Hill Top,Kendujhar,Odisha', 'lon': '85.398'},
      '175016': {'lat': '31.9063', 'name': 'Ootpur,Mandi,Himachal Pradesh', 'lon': '76.7548'},
      '175017': {'lat': '32.7479', 'name': 'Batwara,Mandi,Himachal Pradesh', 'lon': '76.6365'},
      '175014': {'lat': '31.6421', 'name': 'Kurati,Mandi,Himachal Pradesh', 'lon': '76.9693'},
      '176058': {'lat': '32.043', 'name': 'Bahadpur,Kangra,Himachal Pradesh', 'lon': '75.8109'},
      '175012': {'lat': '31.5782', 'name': 'Silhbadhani,Mandi,Himachal Pradesh', 'lon': '76.9814'},
      '175013': {'lat': '31.8721', 'name': 'Thaltukhod,Mandi,Himachal Pradesh', 'lon': '76.8182'},
      '175010': {'lat': '31.3316', 'name': 'Mahun,Mandi,Himachal Pradesh', 'lon': '77.1753'},
      '175011': {'lat': '31.3548', 'name': 'Sainj Bagra,Mandi,Himachal Pradesh', 'lon': '77.2771'},
      '721101': {'lat': '22.43775', 'name': 'Dharma (West Midnapore),Medinipur,West Bengal', 'lon': '87.30015'},
      '758046': {'lat': '21.7537', 'name': 'Kankada,Kendujhar,Odisha', 'lon': '85.7667'},
      '394550': {'lat': '21.1011', 'name': 'Dumasgam,Surat,Gujarat', 'lon': '72.704'},
      '175018': {'lat': '31.6307', 'name': 'Churad,Mandi,Himachal Pradesh', 'lon': '76.7728'},
      '394221': {'lat': '21.4053', 'name': 'Pandesara,Surat,Gujarat', 'lon': '73.2118'},
      '796005': {'lat': '23.6526', 'name': 'Khawchhete,Aizawl,Mizoram', 'lon': '92.7241'},
      '758044': {'lat': '21.3566', 'name': 'Sologuda,Kendujhar,Odisha', 'lon': '85.4352'},
      '577401': {'lat': '14.0138', 'name': 'Hamsagaru,Shivamogga,Karnataka', 'lon': '75.2922'},
      '627006': {'lat': '8.5335', 'name': 'Melathiru Vengadanathapuram,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '393025': {'lat': '23.2244', 'name': 'Kolvan,Bharuch,Gujarat', 'lon': '73.2694'},
      '251315': {'lat': '29.4139', 'name': 'Bhooma,Muzaffarnagar,Uttar Pradesh', 'lon': '78.0464'},
      '758045': {'lat': '21.3571', 'name': 'Khajirapat,Kendujhar,Odisha', 'lon': '85.6152'},
      '393020': {'lat': '21.6193', 'name': 'Piludra,Bharuch,Gujarat', 'lon': '72.904'},
      '572168': {'lat': '13.4622', 'name': 'Hirehalli SO,Tumakuru,Karnataka', 'lon': '76.9711'},
      '502270': {'lat': '17.8738', 'name': 'Nidijipla,Medak,Telangana', 'lon': '78.0522'},
      '712234': {'lat': '22.8847', 'name': 'Kanaipur,Hooghly,West Bengal', 'lon': '88.0798'},
      '712233': {'lat': '22.6861', 'name': 'Hindmotor,Hooghly,West Bengal', 'lon': '88.3357'},
      '361310': {'lat': '22.2929', 'name': 'Vachalabara,Jamnagar,Gujarat', 'lon': '69.5326'},
      '361315': {'lat': '22.0761', 'name': 'Ranjitpar,Jamnagar,Gujarat', 'lon': '69.6004'},
      '493338': {'lat': '21.6995', 'name': 'Parsadih,Raipur,Chattisgarh', 'lon': '82.6993'},
      '521286': {'lat': '16.4017', 'name': 'Atkur,Krishna,Andhra Pradesh', 'lon': '80.8449'},
      '423302': {'lat': '20.6626', 'name': 'Salher,Nashik,Maharashtra', 'lon': '74.6888'},
      '712232': {'lat': '23.1511', 'name': 'Bhadrakali,Hooghly,West Bengal', 'lon': '88.1839'},
      '423303': {'lat': '20.2308', 'name': 'Sompur,Nashik,Maharashtra', 'lon': '75.6932'},
      '144805': {'lat': '31.7359', 'name': 'CRPF Campus Sarai Khas,Jalandhar,Punjab', 'lon': '75.5821'},
      '144804': {'lat': '31.4944', 'name': 'Miani Bakerpur,Kapurthala,Punjab', 'lon': '75.3033'},
      '574267': {'lat': '12.8695', 'name': 'Badagayedapadavu,Dakshina Kannada,Karnataka', 'lon': '75.1001'},
      '144801': {'lat': '31.5405', 'name': 'Kala Khera,Jalandhar,Punjab', 'lon': '75.3648'},
      '144803': {'lat': '31.3541', 'name': 'Dialpur,Kapurthala,Punjab', 'lon': '75.4031'},
      '144802': {'lat': '31.4136', 'name': 'Kanjli,Kapurthala,Punjab', 'lon': '75.3739'},
      '643220': {'lat': '11.5338', 'name': 'Mangorange,Nilgiris,Tamil Nadu', 'lon': '76.5361'},
      '423301': {'lat': '20.6346', 'name': 'Kikwari KH,Nashik,Maharashtra', 'lon': '74.317'},
      '796007': {'lat': '22.8796', 'name': 'Chandmary,Aizawl,Mizoram', 'lon': '92.7495'},
      '431741': {'lat': '18.8867', 'name': 'Koklegaon,Nanded,Maharashtra', 'lon': '77.5632'},
      '431743': {'lat': '19.4343', 'name': 'Pewa,Nanded,Maharashtra', 'lon': '77.4645'},
      '431742': {'lat': '18.9642', 'name': 'Guntur,Nanded,Maharashtra', 'lon': '77.1733'},
      '431745': {'lat': '19.383', 'name': 'Shemboli,Nanded,Maharashtra', 'lon': '77.3933'},
      '431746': {'lat': '18.8', 'name': 'Warwnt,Nanded,Maharashtra', 'lon': '77.347'},
      '244225': {'lat': '28.9352', 'name': 'Dhauti,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.1764'},
      '821309': {'lat': '25.1121', 'name': 'B. Kaithi,Rohtas,Bihar', 'lon': '84.2818'},
      '847121': {'lat': '25.6035', 'name': 'Singhaso,Darbhanga,Bihar', 'lon': '86.1225'},
      '244221': {'lat': '28.9077', 'name': 'Bahadur Pur Khurd,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.4927'},
      '244222': {'lat': '28.8215', 'name': 'Sarkari  Ajij,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.477'},
      '244223': {'lat': '28.8917', 'name': 'Bhartiya Gram,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.4022'},
      '509358': {'lat': '17.4844', 'name': 'Kadthal,Mahabub Nagar,Telangana', 'lon': '78.0129'},
      '509357': {'lat': '16.5013', 'name': 'Donuru,Mahabub Nagar,Telangana', 'lon': '77.8345'},
      '509352': {'lat': '16.4466', 'name': 'Krishna,Mahabub Nagar,Telangana', 'lon': '77.3383'},
      '700103': {'lat': '22.966', 'name': 'Narendrapur,South 24 Parganas,West Bengal', 'lon': '88.2036'},
      '509350': {'lat': '16.9944', 'name': 'Polepally,Mahabub Nagar,Telangana', 'lon': '77.7979'},
      '845440': {'lat': '26.7184', 'name': 'Palanwa,East Champaran,Bihar', 'lon': '84.4242'},
      '581208': {'lat': '14.5229', 'name': 'Chikkamagnur,Haveri,Karnataka', 'lon': '75.3702'},
      '581209': {'lat': '15.4508', 'name': 'Ballarwad,Dharwad,Karnataka', 'lon': '75.3036'},
      '246441': {'lat': '30.0061', 'name': 'Gwaldam,Chamoli,Uttarakhand', 'lon': '79.5688'},
      '504219': {'lat': '18.8082', 'name': 'Rebbena,Adilabad,Telangana', 'lon': '79.4032'},
      '722201': {'lat': '22.8333', 'name': 'Akui,Bankura,West Bengal', 'lon': '88.0833'},
      '382130': {'lat': '22.7982', 'name': 'Malanpur,Surendra Nagar,Gujarat', 'lon': '71.5402'},
      '581202': {'lat': '14.7381', 'name': 'Naganur,Haveri,Karnataka', 'lon': '75.4177'},
      '581203': {'lat': '14.8852', 'name': 'Bommanahalli (Haveri),Haveri,Karnataka', 'lon': '75.1367'},
      '581204': {'lat': '14.9509', 'name': 'Kalghatagi,Dharwad,Karnataka', 'lon': '75.2653'},
      '581205': {'lat': '14.8176', 'name': 'Shiggaon Bzr,Haveri,Karnataka', 'lon': '75.3309'},
      '581206': {'lat': '15.8194', 'name': 'Pudakalakatti,Dharwad,Karnataka', 'lon': '75.045'},
      '581207': {'lat': '15.0507', 'name': 'Yaliwal,Dharwad,Karnataka', 'lon': '75.2328'},
      '689696': {'lat': '9.0621', 'name': 'Kamukumcherry,Kollam,Kerala', 'lon': '76.9079'},
      '689694': {'lat': '9.1211', 'name': 'Kalanjoor,Pathanamthitta,Kerala', 'lon': '76.9238'},
      '689695': {'lat': '9.0999', 'name': 'Kundayam,Kollam,Kerala', 'lon': '76.83'},
      '689692': {'lat': '9.0863', 'name': 'Ellimullumplackal,Pathanamthitta,Kerala', 'lon': '76.8924'},
      '689693': {'lat': '9.0863', 'name': 'Nedumoncavu,Pathanamthitta,Kerala', 'lon': '76.8924'},
      '143005': {'lat': '31.6029', 'name': 'Guru Nanak Dev University,Amritsar,Punjab', 'lon': '74.8732'},
      '689691': {'lat': '9.0863', 'name': 'Nellikkapara,Pathanamthitta,Kerala', 'lon': '76.8924'},
      '785636': {'lat': '26.8357', 'name': 'Hemlai,Jorhat,Assam', 'lon': '94.4744'},
      '785635': {'lat': '26.7063', 'name': 'Melenggrant,Jorhat,Assam', 'lon': '94.3952'},
      '785634': {'lat': '26.6572', 'name': 'Namrupia,Jorhat,Assam', 'lon': '94.3153'},
      '785633': {'lat': '26.8357', 'name': 'Kachukhat,Jorhat,Assam', 'lon': '94.4744'},
      '785632': {'lat': '26.8357', 'name': 'Birina Shayak,Jorhat,Assam', 'lon': '94.4744'},
      '689698': {'lat': '9.0863', 'name': 'Muthupezhumkal,Pathanamthitta,Kerala', 'lon': '76.8924'},
      '785630': {'lat': '26.3155', 'name': 'Titabor Tiniali,Jorhat,Assam', 'lon': '94.0639'},
      '515822': {'lat': '14.8527', 'name': 'Raketla,Ananthapur,Andhra Pradesh', 'lon': '77.1836'},
      '382115': {'lat': '23.0222', 'name': 'Chekhla,Ahmedabad,Gujarat', 'lon': '72.4071'},
      '248145': {'lat': '30.3541', 'name': 'K.P.Kshetra,Dehradun,Uttarakhand', 'lon': '78.0315'},
      '508204': {'lat': '16.9157', 'name': 'Choutapally,Nalgonda,Telangana', 'lon': '79.7773'},
      '331803': {'lat': '28.0699', 'name': 'Thakariyasar,Bikaner,Rajasthan', 'lon': '74.072'},
      '621005': {'lat': '11.0269', 'name': 'C.Ayyampalayam,Tiruchirappalli,Tamil Nadu', 'lon': '78.746'},
      '311204': {'lat': '25.7978', 'name': 'Barsani,Bhilwara,Rajasthan', 'lon': '74.5048'},
      '311203': {'lat': '25.1106', 'name': 'Bankda,Bhilwara,Rajasthan', 'lon': '75.1962'},
      '311202': {'lat': '25.7918', 'name': 'Bishniya,Bhilwara,Rajasthan', 'lon': '75.0204'},
      '311201': {'lat': '25.6989', 'name': 'Itunda,Bhilwara,Rajasthan', 'lon': '75.3355'},
      '331801': {'lat': '28.2597', 'name': 'Surjansar,Bikaner,Rajasthan', 'lon': '74.1632'},
      '209736': {'lat': '26.7828', 'name': 'Hamirpur,Kannauj,Uttar Pradesh', 'lon': '79.7853'},
      '413529': {'lat': '18.5487', 'name': 'Zari,Osmanabad,Maharashtra', 'lon': '76.7532'},
      '413528': {'lat': '18.648', 'name': 'Awad Shirpura,Osmanabad,Maharashtra', 'lon': '76.3096'},
      '247120': {'lat': '30.0846', 'name': 'Punwarka,Saharanpur,Uttar Pradesh', 'lon': '77.5833'},
      '413525': {'lat': '18.811', 'name': 'Kadaknathwadi,Osmanabad,Maharashtra', 'lon': '75.8734'},
      '413524': {'lat': '18.3955', 'name': 'Ujlamb,Osmanabad,Maharashtra', 'lon': '76.8189'},
      '413527': {'lat': '18.5582', 'name': 'Wangdhari,Osmanabad,Maharashtra', 'lon': '76.5346'},
      '413526': {'lat': '18.721', 'name': 'Pimpalgaon Kamleshwari,Osmanabad,Maharashtra', 'lon': '75.149'},
      '413521': {'lat': '18.1997', 'name': 'Yelnoor,Osmanabad,Maharashtra', 'lon': '76.8201'},
      '413520': {'lat': '18.1999', 'name': 'Tungi(BK),Latur,Maharashtra', 'lon': '76.3827'},
      '413523': {'lat': '18.1891', 'name': 'Satala,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '413522': {'lat': '18.5813', 'name': 'Halgara,Latur,Maharashtra', 'lon': '77.3923'},
      '689520': {'lat': '9.676', 'name': 'Pennukkara,Alappuzha,Kerala', 'lon': '76.4887'},
      '689521': {'lat': '9.676', 'name': 'Kozhuvallur,Alappuzha,Kerala', 'lon': '76.4887'},
      '791114': {'lat': '27.422', 'name': 'Sagalee,Papum Pare,Arunachal Pradesh', 'lon': '93.6719'},
      '509376': {'lat': '16.441', 'name': 'Uppununthala,Mahabub Nagar,Telangana', 'lon': '78.6529'},
      '607004': {'lat': '11.7312', 'name': 'M.Pudur,Cuddalore,Tamil Nadu', 'lon': '79.5871'},
      '814151': {'lat': '24.8012', 'name': 'Raoundhya,Dumka,Jharkhand', 'lon': '87.1784'},
      '248179': {'lat': '30.4395', 'name': 'Landaur,Dehradun,Uttarakhand', 'lon': '78.0793'},
      '221305': {'lat': '25.7389', 'name': 'Shahanshahpur,Varanasi,Uttar Pradesh', 'lon': '83.0155'},
      '791112': {'lat': '27.1763', 'name': 'Arunachal University,Papum Pare,Arunachal Pradesh', 'lon': '93.7551'},
      '284206': {'lat': '25.4655', 'name': 'Pasaura,Jhansi,Uttar Pradesh', 'lon': '79.0819'},
      '607006': {'lat': '11.754', 'name': 'Gundu Uppalavadi,Cuddalore,Tamil Nadu', 'lon': '79.7045'},
      '392040': {'lat': '21.9707', 'name': 'Devla,Bharuch,Gujarat', 'lon': '72.6134'},
      '396540': {'lat': '23.3924', 'name': 'Velanpore,Navsari,Gujarat', 'lon': '73.7175'},
      '607001': {'lat': '11.7579', 'name': 'Cuddalore H.O,Cuddalore,Tamil Nadu', 'lon': '79.7655'},
      '791110': {'lat': '27.1047', 'name': 'Naharlagun,Papum Pare,Arunachal Pradesh', 'lon': '93.6952'},
      '508279': {'lat': '18.146', 'name': 'Phanigiri,Suryapet,Telangana', 'lon': '79.2386'},
      '441108': {'lat': '20.9332', 'name': 'Bori (Nagpur),Nagpur,Maharashtra', 'lon': '79.0835'},
      '441109': {'lat': '21.2004', 'name': 'Sillewara Project,Nagpur,Maharashtra', 'lon': '79.1268'},
      '503202': {'lat': '18.6906', 'name': 'Jamlam,Nizamabad,Telangana', 'lon': '77.9737'},
      '441104': {'lat': '21.0048', 'name': 'Kapsi BK,Nagpur,Maharashtra', 'lon': '78.9726'},
      '441105': {'lat': '21.3197', 'name': 'Navegaon Khairi,Nagpur,Maharashtra', 'lon': '79.3082'},
      '441106': {'lat': '21.1825', 'name': 'Indora,Nagpur,Maharashtra', 'lon': '79.0986'},
      '441107': {'lat': '21.3569', 'name': 'Malegaon,Nagpur,Maharashtra', 'lon': '78.9798'},
      '441101': {'lat': '21.3704', 'name': 'Bichwa,Nagpur,Maharashtra', 'lon': '79.0482'},
      '441102': {'lat': '21.3102', 'name': 'Khaperkheda,Nagpur,Maharashtra', 'lon': '79.4524'},
      '441103': {'lat': '21.1', 'name': 'Kachari Sawanga,Nagpur,Maharashtra', 'lon': '78.7009'},
      '673542': {'lat': '10.0643', 'name': 'Villyappalli,Kozhikode,Kerala', 'lon': '76.1471'},
      '673541': {'lat': '11.6261', 'name': 'Chaniyankadavu,Kozhikode,Kerala', 'lon': '75.6743'},
      '502277': {'lat': '17.4091', 'name': 'Bakrichepial,Medak,Telangana', 'lon': '78.533'},
      '383260': {'lat': '23.2856', 'name': 'Vatrakgadh,Sabarkantha,Gujarat', 'lon': '73.1297'},
      '462010': {'lat': '24.0875', 'name': 'Chandbad,Bhopal,Madhya Pradesh', 'lon': '79.7572'},
      '505187': {'lat': '18.1809', 'name': 'Ragineedu,Karim Nagar,Telangana', 'lon': '79.6251'},
      '732208': {'lat': '25.0362', 'name': 'Madapur,Malda,West Bengal', 'lon': '88.0045'},
      '231312': {'lat': '25.1772', 'name': 'Balliparwa,Mirzapur,Uttar Pradesh', 'lon': '82.5524'},
      '231313': {'lat': '25.2031', 'name': 'Sri Niwas Dham,Mirzapur,Uttar Pradesh', 'lon': '82.4473'},
      '231314': {'lat': '25.2474', 'name': 'Jamua (Mirzapur),Mirzapur,Uttar Pradesh', 'lon': '82.7716'},
      '743134': {'lat': '22.9322', 'name': 'Halishahar,North 24 Parganas,West Bengal', 'lon': '88.4186'},
      '614801': {'lat': '10.5789', 'name': 'Ayingudi,Pudukkottai,Tamil Nadu', 'lon': '79.3245'},
      '614803': {'lat': '11.1227', 'name': 'Siruvalakadu,Thanjavur,Tamil Nadu', 'lon': '79.1501'},
      '614802': {'lat': '10.2744', 'name': 'Virayankottai,Thanjavur,Tamil Nadu', 'lon': '79.2782'},
      '614805': {'lat': '10.5789', 'name': 'Kodivayal,Pudukkottai,Tamil Nadu', 'lon': '79.3245'},
      '614804': {'lat': '10.2903', 'name': 'Peravurni,Thanjavur,Tamil Nadu', 'lon': '79.2016'},
      '614807': {'lat': '10.5789', 'name': 'Kodiakkarai,Nagapattinam,Tamil Nadu', 'lon': '79.3245'},
      '614806': {'lat': '10.5602', 'name': 'Thennampulam,Nagapattinam,Tamil Nadu', 'lon': '79.2894'},
      '614809': {'lat': '10.4705', 'name': 'Thethakudi Therkusethi,Nagapattinam,Tamil Nadu', 'lon': '79.8325'},
      '614808': {'lat': '10.5789', 'name': 'Nagakudaiyan,Nagapattinam,Tamil Nadu', 'lon': '79.3245'},
      '572132': {'lat': '13.6834', 'name': 'Vaderahalli,Tumakuru,Karnataka', 'lon': '77.2124'},
      '494776': {'lat': '20.7618', 'name': 'PV-15,Kanker,Chattisgarh', 'lon': '81.003'},
      '250221': {'lat': '29.35', 'name': 'Samoli Khash Pur,Meerut,Uttar Pradesh', 'lon': '77.6199'},
      '250223': {'lat': '28.9913', 'name': 'Sakoti Tanda,Meerut,Uttar Pradesh', 'lon': '77.7265'},
      '517646': {'lat': '13.6365', 'name': 'Sriname S E Z,Chittoor,Andhra Pradesh', 'lon': '79.572'},
      '743130': {'lat': '22.8767', 'name': 'Dogachia,North 24 Parganas,West Bengal', 'lon': '88.585'},
      '832109': {'lat': '23.2202', 'name': 'Adityapur Industrial Area,Seraikela-kharsawan,Jharkhand', 'lon': '86.0029'},
      '362520': {'lat': '20.7141', 'name': 'Diu,Diu,Daman & Diu', 'lon': '70.9822'},
      '362030': {'lat': '23.2521', 'name': 'Kariya,Junagadh,Gujarat', 'lon': '73.7315'},
      '517132': {'lat': '13.0503', 'name': 'Nangamangalam,Chittoor,Andhra Pradesh', 'lon': '79.1707'},
      '125112': {'lat': '29.5349', 'name': 'Kanoh Punia,Hisar,Haryana', 'lon': '76.0814'},
      '125113': {'lat': '29.509', 'name': 'Hassangarh,Hisar,Haryana', 'lon': '75.8891'},
      '125110': {'lat': '29.3622', 'name': 'Sahuwala -ii,Sirsa,Haryana', 'lon': '75.1195'},
      '125111': {'lat': '29.3778', 'name': 'Khajuri Jatti,Fatehabad,Haryana', 'lon': '75.7058'},
      '608602': {'lat': '11.4361', 'name': 'K.Alambadi,Cuddalore,Tamil Nadu', 'lon': '79.6523'},
      '209732': {'lat': '26.9799', 'name': 'Tirwa Ganj,Kannauj,Uttar Pradesh', 'lon': '79.8138'},
      '767071': {'lat': '21.1067', 'name': 'Sauntpur,Balangir,Odisha', 'lon': '83.7251'},
      '767070': {'lat': '21.1067', 'name': 'Of Badmal,Balangir,Odisha', 'lon': '83.7251'},
      '605007': {'lat': '11.8768', 'name': 'Mathalapattu,Cuddalore,Tamil Nadu', 'lon': '79.8107'},
      '605006': {'lat': '11.8768', 'name': 'Dhanvantry Nagar,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '605005': {'lat': '11.8768', 'name': 'Nellithoppe,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '605004': {'lat': '11.8768', 'name': 'Thengaithittu,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '400042': {'lat': '19.0832', 'name': 'Bhandup East,Mumbai,Maharashtra', 'lon': '72.8748'},
      '400043': {'lat': '19.0637', 'name': 'D.M. Colony,Mumbai,Maharashtra', 'lon': '72.9254'},
      '605001': {'lat': '11.8768', 'name': 'Pondicherry H.O,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '721455': {'lat': '22.3703', 'name': 'Bhuniajibarh,East Midnapore,West Bengal', 'lon': '88.2092'},
      '313332': {'lat': '25.5756', 'name': 'Jeeran,Rajsamand,Rajasthan', 'lon': '74.1313'},
      '754289': {'lat': '20.7458', 'name': 'Balia Bibhutipara,Kendrapara,Odisha', 'lon': '85.4262'},
      '605009': {'lat': '11.8768', 'name': 'Mottoupalayam,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '400049': {'lat': '18.9596', 'name': 'Juhu,Mumbai,Maharashtra', 'lon': '72.8496'},
      '474009': {'lat': '25.8606', 'name': 'Naya Bazar (Gwalior),Gwalior,Madhya Pradesh', 'lon': '78.1123'},
      '221309': {'lat': '25.285696', 'name': 'Narepar Sitamarhi,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.2718916'},
      '230402': {'lat': '25.7113', 'name': 'Sarai Nahar Rai,Pratapgarh,Uttar Pradesh', 'lon': '81.8759'},
      '312207': {'lat': '25.1421', 'name': 'Banakiyakalan,Chittorgarh,Rajasthan', 'lon': '74.5957'},
      '484110': {'lat': '23.8188', 'name': 'Samatpur,Shahdol,Madhya Pradesh', 'lon': '81.2505'},
      '521332': {'lat': '17.1611', 'name': 'Dokiparru,Krishna,Andhra Pradesh', 'lon': '82.1864'},
      '484113': {'lat': '22.8135', 'name': 'Pondi,Anuppur,Madhya Pradesh', 'lon': '81.7103'},
      '518512': {'lat': '15.4873', 'name': 'Eernipadu,Kurnool,Andhra Pradesh', 'lon': '78.5459'},
      '518513': {'lat': '15.0671', 'name': 'Galichennayapalem,Kurnool,Andhra Pradesh', 'lon': '78.3448'},
      '518510': {'lat': '15.2152', 'name': 'Nallagatla,Kurnool,Andhra Pradesh', 'lon': '78.5023'},
      '518511': {'lat': '15.5666', 'name': 'Chidukur,Kurnool,Andhra Pradesh', 'lon': '78.445'},
      '768038': {'lat': '21.3833', 'name': 'Gudesira,Bargarh,Odisha', 'lon': '83.584'},
      '768039': {'lat': '21.0833', 'name': 'Bhengrajpur,Bargarh,Odisha', 'lon': '83.034'},
      '609001': {'lat': '11.0935', 'name': 'Mayiladuthurai Bazaar,Nagapattinam,Tamil Nadu', 'lon': '79.6485'},
      '630210': {'lat': '10.0864', 'name': 'Tirukoshtiyur,Sivaganga,Tamil Nadu', 'lon': '78.5783'},
      '685571': {'lat': '9.6906', 'name': 'Muniyara,Idukki,Kerala', 'lon': '76.9977'},
      '504312': {'lat': '19.7206', 'name': 'Sunkidi,Adilabad,Telangana', 'lon': '78.3782'},
      '768030': {'lat': '21.4894', 'name': 'Kanakbira,Bargarh,Odisha', 'lon': '83.4864'},
      '768031': {'lat': '21.2106', 'name': 'Ambabhana,Bargarh,Odisha', 'lon': '83.4445'},
      '768032': {'lat': '21.1395', 'name': 'Samalaipadar,Bargarh,Odisha', 'lon': '83.687'},
      '768033': {'lat': '21.3089', 'name': 'Pandikipali,Bargarh,Odisha', 'lon': '83.666'},
      '768034': {'lat': '21.1842', 'name': 'Talpadar,Bargarh,Odisha', 'lon': '83.3572'},
      '768035': {'lat': '21.1764', 'name': 'Diptipur,Bargarh,Odisha', 'lon': '83.4858'},
      '768036': {'lat': '21.0059', 'name': 'Lakhamara,Bargarh,Odisha', 'lon': '83.0422'},
      '768037': {'lat': '20.9083', 'name': 'Dangabahal,Bargarh,Odisha', 'lon': '83.4525'},
      '391243': {'lat': '23.0759', 'name': 'Varnama,Vadodara,Gujarat', 'lon': '73.9423'},
      '723126': {'lat': '23.4194', 'name': 'Jorberia,Purulia,West Bengal', 'lon': '86.6102'},
      '391240': {'lat': '22.7845', 'name': 'Kambola,Vadodara,Gujarat', 'lon': '73.3231'},
      '391530': {'lat': '23.1188', 'name': 'Sandasal,Vadodara,Gujarat', 'lon': '73.7815'},
      '742159': {'lat': '24.1707', 'name': 'Joypur,Murshidabad,West Bengal', 'lon': '87.9755'},
      '391244': {'lat': '21.9872', 'name': 'Chhanchava,Vadodara,Gujarat', 'lon': '73.1678'},
      '641301': {'lat': '11.4579', 'name': 'Mettupalayam H.O,Coimbatore,Tamil Nadu', 'lon': '77.5996'},
      '322701': {'lat': '26.6719', 'name': 'Adalwada Kalan,Sawai Madhopur,Rajasthan', 'lon': '75.9628'},
      '322702': {'lat': '26.6843', 'name': 'Rajmana,Sawai Madhopur,Rajasthan', 'lon': '76.4482'},
      '322703': {'lat': '26.7119', 'name': 'Sarsop,Sawai Madhopur,Rajasthan', 'lon': '76.0983'},
      '322704': {'lat': '26.8471', 'name': 'Shiwar,Sawai Madhopur,Rajasthan', 'lon': '75.6693'},
      '631561': {'lat': '12.8461', 'name': 'Rajakulam,Kanchipuram,Tamil Nadu', 'lon': '79.7695'},
      '742151': {'lat': '23.1589', 'name': 'Sagia,Murshidabad,West Bengal', 'lon': '88.0465'},
      '723128': {'lat': '23.274', 'name': 'Dhakakendu,Purulia,West Bengal', 'lon': '86.4902'},
      '470775': {'lat': '24.1918', 'name': 'Fatehpur,Damoh,Madhya Pradesh', 'lon': '79.5208'},
      '621002': {'lat': '11.0376', 'name': 'Sorathur,Tiruchirappalli,Tamil Nadu', 'lon': '78.8161'},
      '470772': {'lat': '23.8127', 'name': 'Deodongra,Damoh,Madhya Pradesh', 'lon': '79.5273'},
      '628616': {'lat': '8.545', 'name': 'Nazareth R S,Tuticorin,Tamil Nadu', 'lon': '77.8664'},
      '246482': {'lat': '30.0494', 'name': 'Burjola,Chamoli,Uttarakhand', 'lon': '79.5626'},
      '246483': {'lat': '30.4893', 'name': 'Regari,Chamoli,Uttarakhand', 'lon': '79.6249'},
      '246481': {'lat': '30.016', 'name': 'Harmani,Chamoli,Uttarakhand', 'lon': '79.5907'},
      '454001': {'lat': '22.6866', 'name': 'Kherod,Dhar,Madhya Pradesh', 'lon': '75.7275'},
      '246487': {'lat': '29.7544', 'name': 'Chaurasain,Chamoli,Uttarakhand', 'lon': '78.9986'},
      '587202': {'lat': '15.9152', 'name': 'Wadageri,Bagalkot,Karnataka', 'lon': '75.9115'},
      '679571': {'lat': '10.8425', 'name': 'Perasannur,Malappuram,Kerala', 'lon': '76.0308'},
      '679572': {'lat': '10.8624', 'name': 'Irimbiliyam,Malappuram,Kerala', 'lon': '76.0922'},
      '679573': {'lat': '11.1321', 'name': 'Nariparamba,Malappuram,Kerala', 'lon': '76.1558'},
      '679574': {'lat': '10.8078', 'name': 'Pidavannur,Malappuram,Kerala', 'lon': '76.0432'},
      '679575': {'lat': '10.7102', 'name': 'Nannamukku,Malappuram,Kerala', 'lon': '76.03'},
      '679576': {'lat': '10.7847', 'name': 'Kololamba,Malappuram,Kerala', 'lon': '75.997'},
      '679577': {'lat': '10.8078', 'name': 'Ponani H.O,Malappuram,Kerala', 'lon': '76.0432'},
      '534305': {'lat': '16.9544', 'name': 'Katakoteswaram,West Godavari,Andhra Pradesh', 'lon': '81.5721'},
      '679579': {'lat': '10.7209', 'name': 'Pazhanji-mlp,Malappuram,Kerala', 'lon': '75.946'},
      '831003': {'lat': '23.0604', 'name': 'Golmuri,East Singhbhum,Jharkhand', 'lon': '85.9466'},
      '534301': {'lat': '16.908', 'name': 'Basivireddipeta,West Godavari,Andhra Pradesh', 'lon': '81.667'},
      '532222': {'lat': '18.6518', 'name': 'Pallisaradhi,Srikakulam,Andhra Pradesh', 'lon': '84.2494'},
      '832105': {'lat': '22.8583254', 'name': 'Gobarghusi,East Singhbhum,Jharkhand', 'lon': '86.3528738'},
      '534302': {'lat': '16.9231', 'name': 'Purushottapalli,West Godavari,Andhra Pradesh', 'lon': '81.709'},
      '636809': {'lat': '12.2023', 'name': 'Paupparapatti,Dharmapuri,Tamil Nadu', 'lon': '78.0584'},
      '636808': {'lat': '12.1599', 'name': 'Seeriyanahalli,Dharmapuri,Tamil Nadu', 'lon': '78.1405'},
      '688582': {'lat': '9.7902', 'name': 'Kanichukulangara BO,Alappuzha,Kerala', 'lon': '76.3133'},
      '761212': {'lat': '18.9167', 'name': 'Kuruguba,Gajapati,Odisha', 'lon': '84.2343'},
      '230204': {'lat': '25.7944', 'name': 'Aimajatupur,Pratapgarh,Uttar Pradesh', 'lon': '81.3704'},
      '636803': {'lat': '12.0142', 'name': 'Adagapadi,Dharmapuri,Tamil Nadu', 'lon': '77.9834'},
      '636805': {'lat': '12.404', 'name': 'Veppalahalli,Dharmapuri,Tamil Nadu', 'lon': '78.0583'},
      '324010': {'lat': '25.0136', 'name': 'Engineering College Kota,Kota,Rajasthan', 'lon': '76.0587'},
      '636807': {'lat': '12.0537', 'name': 'Samichettipatti,Dharmapuri,Tamil Nadu', 'lon': '78.0917'},
      '636806': {'lat': '12.3804', 'name': 'Samanur,Dharmapuri,Tamil Nadu', 'lon': '78.0096'},
      '603309': {'lat': '12.3897', 'name': 'Perukkaranai,Kanchipuram,Tamil Nadu', 'lon': '79.8706'},
      '246150': {'lat': '29.9029', 'name': 'Padampur Sukhro,Pauri Garhwal,Uttarakhand', 'lon': '78.9302'},
      '491226': {'lat': '21.0107', 'name': 'Latabod,Durg,Chattisgarh', 'lon': '81.2822'},
      '364280': {'lat': '23.1404', 'name': 'Tatania,Bhavnagar,Gujarat', 'lon': '73.2951'},
      '502311': {'lat': '17.7553', 'name': 'Sirigiripally,Medak,Telangana', 'lon': '78.5624'},
      '246159': {'lat': '29.8625', 'name': 'Lwali,Pauri Garhwal,Uttarakhand', 'lon': '79.0403'},
      '502293': {'lat': '17.7331', 'name': 'Pulkal,Medak,Telangana', 'lon': '77.9859'},
      '628614': {'lat': '8.545', 'name': 'Campbellabad,Tuticorin,Tamil Nadu', 'lon': '77.8664'},
      '248007': {'lat': '30.2266', 'name': 'Karbari Grant,Dehradun,Uttarakhand', 'lon': '78.1393'},
      '636404': {'lat': '11.8219', 'name': 'Karuppureddiyur,Salem,Tamil Nadu', 'lon': '77.8601'},
      '506329': {'lat': '17.8712', 'name': 'Elgur Station,Warangal,Telangana', 'lon': '79.7689'},
      '332042': {'lat': '26.5905', 'name': 'Bhuwala,Sikar,Rajasthan', 'lon': '75.6827'},
      '332041': {'lat': '26.5905', 'name': 'Kirdoli,Sikar,Rajasthan', 'lon': '75.6827'},
      '604202': {'lat': '12.2684', 'name': 'Anathur,Villupuram,Tamil Nadu', 'lon': '79.4049'},
      '515775': {'lat': '14.4429', 'name': 'G.Kottala,Ananthapur,Andhra Pradesh', 'lon': '77.8791'},
      '251314': {'lat': '29.123', 'name': 'Ghatayan,Muzaffarnagar,Uttar Pradesh', 'lon': '77.8651'},
      '604201': {'lat': '12.3735', 'name': 'Melvailamur,Villupuram,Tamil Nadu', 'lon': '79.2673'},
      '604206': {'lat': '12.2331', 'name': 'Modaiyur,Villupuram,Tamil Nadu', 'lon': '79.4922'},
      '604207': {'lat': '12.3086', 'name': 'Karuvampakkam,Villupuram,Tamil Nadu', 'lon': '79.611'},
      '604204': {'lat': '12.3702', 'name': 'Theppirampattu,Villupuram,Tamil Nadu', 'lon': '79.3185'},
      '604205': {'lat': '12.485', 'name': 'Konai,Villupuram,Tamil Nadu', 'lon': '79.8235'},
      '243504': {'lat': '28.7467', 'name': 'Mandanpur,Bareilly,Uttar Pradesh', 'lon': '79.4728'},
      '246749': {'lat': '29.7026', 'name': 'Bhaguwala,Bijnor,Uttar Pradesh', 'lon': '78.2563'},
      '604208': {'lat': '12.3817', 'name': 'Markonam,Villupuram,Tamil Nadu', 'lon': '79.3942'},
      '641008': {'lat': '10.9214', 'name': 'Kuniamuthur,Coimbatore,Tamil Nadu', 'lon': '77.3026'},
      '835219': {'lat': '23.467', 'name': 'Hendevelli,Ranchi,Jharkhand', 'lon': '85.4737'},
      '721454': {'lat': '22.3836', 'name': 'Katranka,East Midnapore,West Bengal', 'lon': '87.8747'},
      '251318': {'lat': '29.4208', 'name': 'Dhindawali,Muzaffarnagar,Uttar Pradesh', 'lon': '77.5277'},
      '251319': {'lat': '29.4139', 'name': 'Sisauli,Muzaffarnagar,Uttar Pradesh', 'lon': '77.4689'},
      '628613': {'lat': '8.5121', 'name': 'Palaniappapuram,Tuticorin,Tamil Nadu', 'lon': '77.8794'},
      '752068': {'lat': '20.169', 'name': 'Baunsiapada,Nayagarh,Odisha', 'lon': '85.1524'},
      '364060': {'lat': '21.7618', 'name': 'Kardej,Bhavnagar,Gujarat', 'lon': '71.4259'},
      '571189': {'lat': '12.3777', 'name': 'Hundimala,Mysuru,Karnataka', 'lon': '76.4478'},
      '583239': {'lat': '15.3352', 'name': 'Kondanayakanahalli,Ballari,Karnataka', 'lon': '76.4603'},
      '583238': {'lat': '15.3318', 'name': 'Lakamapur,Gadag,Karnataka', 'lon': '76.0455'},
      '583237': {'lat': '15.1362', 'name': 'Kudrimoti,Koppal,Karnataka', 'lon': '75.755'},
      '583236': {'lat': '15.3598', 'name': 'Sanganhal,Koppal,Karnataka', 'lon': '75.5857'},
      '583235': {'lat': '15.4558', 'name': 'Wadderhatti,Gadag,Karnataka', 'lon': '76.4361'},
      '583234': {'lat': '15.5483', 'name': 'Agalkera,Koppal,Karnataka', 'lon': '76.0975'},
      '583233': {'lat': '15.4311', 'name': 'Munirabad,Gadag,Karnataka', 'lon': '75.6663'},
      '583232': {'lat': '15.0437', 'name': 'Masbihanchinal,Koppal,Karnataka', 'lon': '75.555'},
      '583231': {'lat': '15.3523', 'name': 'Koppal H.O,Koppal,Karnataka', 'lon': '75.947'},
      '583230': {'lat': '15.5618', 'name': 'Hirebidnal,Koppal,Karnataka', 'lon': '76.176'},
      '274506': {'lat': '26.113', 'name': 'Majhauli Raj,Deoria,Uttar Pradesh', 'lon': '83.0456'},
      '632007': {'lat': '12.9143', 'name': 'Ka Farm,Vellore,Tamil Nadu', 'lon': '79.0177'},
      '764036': {'lat': '18.905', 'name': 'Upperkanti,Koraput,Odisha', 'lon': '82.972'},
      '764037': {'lat': '19.1214', 'name': 'Kasandi,Koraput,Odisha', 'lon': '83.2667'},
      '274502': {'lat': '26.291', 'name': 'Mehraunaghat,Deoria,Uttar Pradesh', 'lon': '83.8397'},
      '456668': {'lat': '23.6815', 'name': 'Nanded,Ujjain,Madhya Pradesh', 'lon': '76.7743'},
      '274501': {'lat': '26.3254', 'name': 'Noonkhar,Deoria,Uttar Pradesh', 'lon': '83.7748'},
      '823001': {'lat': '24.8014', 'name': 'Civil Lines (Gaya),Gaya,Bihar', 'lon': '84.6894'},
      '732139': {'lat': '25.3249', 'name': 'Baldiapukur,Malda,West Bengal', 'lon': '87.9524'},
      '823003': {'lat': '24.8156', 'name': 'Rasalpur,Gaya,Bihar', 'lon': '84.7578'},
      '823002': {'lat': '25.1967', 'name': 'Gaya R.S,Gaya,Bihar', 'lon': '84.9284'},
      '764038': {'lat': '19.1027', 'name': 'Panthulunga,Koraput,Odisha', 'lon': '82.7012'},
      '764039': {'lat': '18.7885', 'name': 'Kotiya,Koraput,Odisha', 'lon': '83.1179'},
      '628612': {'lat': '8.5957', 'name': 'Alwarthope West,Tuticorin,Tamil Nadu', 'lon': '77.959'},
      '608201': {'lat': '11.4033', 'name': 'Orathur,Cuddalore,Tamil Nadu', 'lon': '79.5779'},
      '282006': {'lat': '27.1811', 'name': 'Dhandupura,Agra,Uttar Pradesh', 'lon': '78.075'},
      '327602': {'lat': '23.2432', 'name': 'Dungra Kalan,Banswara,Rajasthan', 'lon': '74.2933'},
      '591153': {'lat': '15.7086', 'name': 'Holenaglapur,Belagavi,Karnataka', 'lon': '74.7612'},
      '793110': {'lat': '25.4998', 'name': 'Wahshiarkhmut,East Khasi Hills,Meghalaya', 'lon': '91.8829'},
      '591156': {'lat': '15.7086', 'name': 'Hosavantmuri,Belagavi,Karnataka', 'lon': '74.7612'},
      '855113': {'lat': '25.5552', 'name': 'Baltor,Katihar,Bihar', 'lon': '87.7954'},
      '391774': {'lat': '23.2644', 'name': 'Valavav,Vadodara,Gujarat', 'lon': '73.3468'},
      '855116': {'lat': '26.2593', 'name': 'Jirangachhi,Kishanganj,Bihar', 'lon': '87.9978'},
      '855117': {'lat': '25.6819', 'name': 'Khatia Pichhia,Kishanganj,Bihar', 'lon': '87.7734'},
      '641302': {'lat': '11.3214', 'name': 'Sinnakallipatti,Coimbatore,Tamil Nadu', 'lon': '77.0052'},
      '517536': {'lat': '13.8784', 'name': 'Kasaram,Chittoor,Andhra Pradesh', 'lon': '79.6972'},
      '410515': {'lat': '18.922', 'name': 'Chas,Pune,Maharashtra', 'lon': '73.8332'},
      '502279': {'lat': '17.3408', 'name': 'Wargal,Medak,Telangana', 'lon': '79.43'},
      '410516': {'lat': '19.2985', 'name': 'Gangapur KH,Pune,Maharashtra', 'lon': '74.6878'},
      '410511': {'lat': '19.2985', 'name': 'Shiroli B K,Pune,Maharashtra', 'lon': '74.6878'},
      '410510': {'lat': '19.2985', 'name': 'Savindane,Pune,Maharashtra', 'lon': '74.6878'},
      '410513': {'lat': '18.922', 'name': 'Chas (Pune),Pune,Maharashtra', 'lon': '73.8332'},
      '410512': {'lat': '18.9452', 'name': 'Kurwandi,Pune,Maharashtra', 'lon': '75.1632'},
      '263645': {'lat': '29.7241', 'name': 'Chaukuni,Almora,Uttarakhand', 'lon': '79.3671'},
      '327606': {'lat': '23.3512', 'name': 'Biladi,Banswara,Rajasthan', 'lon': '74.2851'},
      '388545': {'lat': '22.4479', 'name': 'Kavitha,Anand,Gujarat', 'lon': '72.7774'},
      '263641': {'lat': '29.9911', 'name': 'Matena,Bageshwar,Uttarakhand', 'lon': '79.8596'},
      '388543': {'lat': '22.3815', 'name': 'Alarsa,Anand,Gujarat', 'lon': '72.9566'},
      '388540': {'lat': '23.1131', 'name': 'Kasumbad,Anand,Gujarat', 'lon': '73.063'},
      '477441': {'lat': '26.3179', 'name': 'Machand,Bhind,Madhya Pradesh', 'lon': '79.0113'},
      '416210': {'lat': '16.8493', 'name': 'Vesarde,Kolhapur,Maharashtra', 'lon': '73.9203'},
      '416211': {'lat': '16.2505', 'name': 'Shirse,Kolhapur,Maharashtra', 'lon': '74.1545'},
      '416212': {'lat': '16.4', 'name': 'Shiroli (Sonyachi),Kolhapur,Maharashtra', 'lon': '74'},
      '416213': {'lat': '17.9153', 'name': 'Shittur Turf Malkapur,Kolhapur,Maharashtra', 'lon': '74.4532'},
      '382728': {'lat': '23.1307', 'name': 'Fuletra,Mahesana,Gujarat', 'lon': '72.377'},
      '382729': {'lat': '22.7528', 'name': 'Chhatral,Gandhi Nagar,Gujarat', 'lon': '72.6731'},
      '416216': {'lat': '16.577', 'name': 'Mhakave,Kolhapur,Maharashtra', 'lon': '74.3154'},
      '173213': {'lat': '30.9554', 'name': 'Dharot,Solan,Himachal Pradesh', 'lon': '77.077'},
      '416218': {'lat': '16.7174', 'name': 'Hasur Khurd,Kolhapur,Maharashtra', 'lon': '74.3037'},
      '382725': {'lat': '23.255', 'name': 'Palsana,Gandhi Nagar,Gujarat', 'lon': '72.6923'},
      '815312': {'lat': '24.0914', 'name': 'Khurchutta,Giridh,Jharkhand', 'lon': '86.1201'},
      '327605': {'lat': '23.4018', 'name': 'Akhebhanji Ka Gara,Banswara,Rajasthan', 'lon': '74.2169'},
      '815314': {'lat': '24.2897', 'name': 'Khairiadih,Giridh,Jharkhand', 'lon': '85.9403'},
      '382721': {'lat': '22.7528', 'name': 'Serisa,Gandhi Nagar,Gujarat', 'lon': '72.6731'},
      '815316': {'lat': '24.1831', 'name': 'Ranikhawa,Giridh,Jharkhand', 'lon': '86.1118'},
      '731244': {'lat': '24.1183', 'name': 'Bishnupur (Birbhum),Birbhum,West Bengal', 'lon': '87.8877'},
      '631501': {'lat': '12.6207', 'name': 'Little Kanchipuram,Kanchipuram,Tamil Nadu', 'lon': '79.6513'},
      '502109': {'lat': '18.0096', 'name': 'Manepally,Medak,Telangana', 'lon': '78.4626'},
      '508255': {'lat': '17.1219', 'name': 'G. Aeroli,Nalgonda,Telangana', 'lon': '79.1825'},
      '248003': {'lat': '30.365', 'name': 'Sinola,Dehradun,Uttarakhand', 'lon': '78.0338'},
      '843128': {'lat': '25.8874', 'name': 'H.Manshahi,Muzaffarpur,Bihar', 'lon': '85.5461'},
      '636503': {'lat': '12.069', 'name': 'Manathal,Salem,Tamil Nadu', 'lon': '78.1032'},
      '451220': {'lat': '21.52939', 'name': 'Ghatyabedi,West Nimar,Madhya Pradesh', 'lon': '75.65705'},
      '843129': {'lat': '25.8998', 'name': 'Shahila Balli,Muzaffarpur,Bihar', 'lon': '85.7672'},
      '673309': {'lat': '11.6935', 'name': 'Korothroad,Kozhikode,Kerala', 'lon': '75.5601'},
      '673308': {'lat': '11.6516', 'name': 'Onchiyam,Kozhikode,Kerala', 'lon': '75.5857'},
      '241407': {'lat': '27.481', 'name': 'Raingai,Hardoi,Uttar Pradesh', 'lon': '79.7073'},
      '630405': {'lat': '10.2434', 'name': 'Nerkuppai,Sivaganga,Tamil Nadu', 'lon': '78.5884'},
      '673301': {'lat': '11.15', 'name': 'Chaliyam,Kozhikode,Kerala', 'lon': '75.8167'},
      '845304': {'lat': '26.4606', 'name': 'Telhara Kala,East Champaran,Bihar', 'lon': '84.7223'},
      '673303': {'lat': '11.3647', 'name': 'Eranhikkal,Kozhikode,Kerala', 'lon': '75.7394'},
      '523315': {'lat': '15.5381', 'name': 'Pothalapadu,Prakasam,Andhra Pradesh', 'lon': '79.2958'},
      '673305': {'lat': '11.5072', 'name': 'Koyilandi H.O,Kozhikode,Kerala', 'lon': '75.658'},
      '673304': {'lat': '11.6411', 'name': 'Tiruvangoor,Kozhikode,Kerala', 'lon': '75.5796'},
      '673307': {'lat': '11.4768', 'name': 'Mudadi North,Kozhikode,Kerala', 'lon': '75.6687'},
      '673306': {'lat': '11.4213', 'name': 'Edakulam  Koyilandi,Kozhikode,Kerala', 'lon': '75.7268'},
      '174028': {'lat': '31.441928', 'name': 'Bhatwara,Bilaspur (HP),Himachal Pradesh', 'lon': '76.712072'},
      '174029': {'lat': '31.31889', 'name': 'Chhat,Bilaspur (HP),Himachal Pradesh', 'lon': '76.74373'},
      '171018': {'lat': '31.205', 'name': 'Basanatpur,Shimla,Himachal Pradesh', 'lon': '77.3226'},
      '171019': {'lat': '31.2541', 'name': 'Jalog,Shimla,Himachal Pradesh', 'lon': '77.2703'},
      '571450': {'lat': '12.7014', 'name': 'Honnaikanahalli,Mandya,Karnataka', 'lon': '77.0036'},
      '471606': {'lat': '24.7967', 'name': 'Dhawad,Chhatarpur,Madhya Pradesh', 'lon': '79.6661'},
      '502103': {'lat': '17.4111', 'name': 'Siddipet H.O,Medak,Telangana', 'lon': '78.505'},
      '171012': {'lat': '31.0946', 'name': 'Dhalli,Shimla,Himachal Pradesh', 'lon': '77.2625'},
      '171013': {'lat': '31.1783', 'name': 'Mehli,Shimla,Himachal Pradesh', 'lon': '77.2459'},
      '171010': {'lat': '31.1783', 'name': 'Tara Devi,Shimla,Himachal Pradesh', 'lon': '77.2459'},
      '174023': {'lat': '31.441926', 'name': 'Dangar,Bilaspur (HP),Himachal Pradesh', 'lon': '76.712074'},
      '174024': {'lat': '31.387248', 'name': 'Behna Jattan,Bilaspur (HP),Himachal Pradesh', 'lon': '76.7269127'},
      '171014': {'lat': '31.1224', 'name': 'Shakarah,Shimla,Himachal Pradesh', 'lon': '77.1902'},
      '174027': {'lat': '31.44193', 'name': 'Gandhalwin,Bilaspur (HP),Himachal Pradesh', 'lon': '76.71207'},
      '680589': {'lat': '10.6422', 'name': 'Karumathra,Thrissur,Kerala', 'lon': '76.2855'},
      '680588': {'lat': '10.664', 'name': 'Malavattom,Thrissur,Kerala', 'lon': '76.2793'},
      '502101': {'lat': '18.1173', 'name': 'Ramayampet,Medak,Telangana', 'lon': '78.4355'},
      '845305': {'lat': '26.9091', 'name': 'Gonaha,East Champaran,Bihar', 'lon': '84.8082'},
      '680581': {'lat': '10.6081', 'name': 'Chottupara,Thrissur,Kerala', 'lon': '76.2113'},
      '143105': {'lat': '31.438', 'name': 'Khaper Kheri,Amritsar,Punjab', 'lon': '74.8582'},
      '680583': {'lat': '10.701', 'name': 'Attur,Thrissur,Kerala', 'lon': '76.2885'},
      '680582': {'lat': '10.664', 'name': 'Wadakanchery-TC H.O,Thrissur,Kerala', 'lon': '76.2793'},
      '680585': {'lat': '10.6973', 'name': 'Pulakkad,Thrissur,Kerala', 'lon': '76.2131'},
      '680584': {'lat': '10.686', 'name': 'Nelluvaya,Thrissur,Kerala', 'lon': '76.1578'},
      '680587': {'lat': '10.6426', 'name': 'Vennur,Thrissur,Kerala', 'lon': '76.4033'},
      '680586': {'lat': '10.6928', 'name': 'Pangarappally,Thrissur,Kerala', 'lon': '76.3564'},
      '679105': {'lat': '10.7512', 'name': 'Mayannur,Thrissur,Kerala', 'lon': '76.3835'},
      '313334': {'lat': '25.127', 'name': 'Sapol,Rajsamand,Rajasthan', 'lon': '73.7531'},
      '679104': {'lat': '10.7426', 'name': 'Kanniampuram,Palakkad,Kerala', 'lon': '76.3997'},
      '229801': {'lat': '26.5246', 'name': 'Inhauna,Raebareli,Uttar Pradesh', 'lon': '81.493'},
      '753015': {'lat': '20.462521', 'name': 'Sector ll CDA,21,Cuttack', 'lon': '85.882988'},
      '229802': {'lat': '26.4552', 'name': 'Pendara,Raebareli,Uttar Pradesh', 'lon': '81.3223'},
      '241405': {'lat': '27.545', 'name': 'Mansoor Nagar,Hardoi,Uttar Pradesh', 'lon': '80.1533'},
      '224161': {'lat': '26.4201', 'name': 'Sarai Sagar,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '360070': {'lat': '22.0873', 'name': 'Anida Vachhra,Rajkot,Gujarat', 'lon': '70.6799'},
      '845302': {'lat': '26.3268', 'name': 'Bella,East Champaran,Bihar', 'lon': '84.894'},
      '180020': {'lat': '32.8652', 'name': 'Trikuta Nagar,Jammu,Jammu & Kashmir', 'lon': '74.9247'},
      '676306': {'lat': '11.1461', 'name': 'Tirurangadi Bazar,Malappuram,Kerala', 'lon': '75.9449'},
      '676307': {'lat': '10.958', 'name': 'Meenadathur,Malappuram,Kerala', 'lon': '75.9081'},
      '676304': {'lat': '11.1808', 'name': 'Kannattipadi,Malappuram,Kerala', 'lon': '75.8814'},
      '676305': {'lat': '11.0775', 'name': 'Kuttur North,Malappuram,Kerala', 'lon': '75.9094'},
      '676302': {'lat': '10.9393', 'name': 'Puthiyakadappuram,Malappuram,Kerala', 'lon': '75.8995'},
      '676303': {'lat': '11.0775', 'name': 'Anjapura,Malappuram,Kerala', 'lon': '75.9094'},
      '676301': {'lat': '11.0775', 'name': 'Vyrancode,Malappuram,Kerala', 'lon': '75.9094'},
      '712512': {'lat': '23.2719', 'name': 'Guptipara,Hooghly,West Bengal', 'lon': '88.1269'},
      '712513': {'lat': '23.1253', 'name': 'Nayasarai,Hooghly,West Bengal', 'lon': '88.5469'},
      '442914': {'lat': '20.3517', 'name': 'Khemjai,Chandrapur,Maharashtra', 'lon': '79.103'},
      '712514': {'lat': '23.1986', 'name': 'Sripur Bazar (Hooghly),Hooghly,West Bengal', 'lon': '88.3369'},
      '712515': {'lat': '23.1986', 'name': 'Dumurdaha,Hooghly,West Bengal', 'lon': '88.3369'},
      '335061': {'lat': '29.3765', 'name': 'Ridmalsar,Ganganagar,Rajasthan', 'lon': '74.476'},
      '335063': {'lat': '29.4394', 'name': 'Nukera,Hanumangarh,Rajasthan', 'lon': '74.4781'},
      '679102': {'lat': '10.7426', 'name': 'Varode,Palakkad,Kerala', 'lon': '76.3997'},
      '335065': {'lat': '28.2369', 'name': 'Manaksar,Hanumangarh,Rajasthan', 'lon': '75.5212'},
      '335064': {'lat': '29.7517', 'name': 'Narayangarh,Hanumangarh,Rajasthan', 'lon': '74.273'},
      '679325': {'lat': '11.0222', 'name': 'Chemmaniyode,Malappuram,Kerala', 'lon': '76.2378'},
      '845303': {'lat': '26.6223', 'name': 'Jhajhara,East Champaran,Bihar', 'lon': '84.9615'},
      '284205': {'lat': '25.3362', 'name': 'Deori Singhpura,Jhansi,Uttar Pradesh', 'lon': '79.0203'},
      '841215': {'lat': '25.9393', 'name': 'Badhakol,Saran,Bihar', 'lon': '85.0217'},
      '721424': {'lat': '22.8223', 'name': 'Thakurchak,West Midnapore,West Bengal', 'lon': '87.5059'},
      '486338': {'lat': '24.7106', 'name': 'Jodhpur,Rewa,Madhya Pradesh', 'lon': '81.6568'},
      '230134': {'lat': '25.8303', 'name': 'Sarkhelpur,Pratapgarh,Uttar Pradesh', 'lon': '82.2645'},
      '622002': {'lat': '10.3217', 'name': 'Idaiyathur,Pudukkottai,Tamil Nadu', 'lon': '78.6648'},
      '721425': {'lat': '22.0093', 'name': 'Bhupatinagar,East Midnapore,West Bengal', 'lon': '87.7037'},
      '508214': {'lat': '17.2214', 'name': 'Gumpula Tirumalgiri,Nalgonda,Telangana', 'lon': '79.5227'},
      '847421': {'lat': '26.4539', 'name': 'Basudevpur,Madhubani,Bihar', 'lon': '86.5046'},
      '759119': {'lat': '21.46213', 'name': 'Seegarh,Angul,Odisha', 'lon': '85.17258'},
      '534243': {'lat': '16.5842', 'name': 'Nandamurugaruvu,West Godavari,Andhra Pradesh', 'lon': '81.4741'},
      '508210': {'lat': '17.2588', 'name': 'Shaligouraram,Nalgonda,Telangana', 'lon': '79.321'},
      '492010': {'lat': '21.1879', 'name': 'Science College,Raipur,Chattisgarh', 'lon': '81.6898'},
      '508212': {'lat': '17.1172', 'name': 'Nemmikal,Nalgonda,Telangana', 'lon': '79.7095'},
      '508213': {'lat': '17.2107', 'name': 'K.K.Road,Nalgonda,Telangana', 'lon': '79.5131'},
      '389382': {'lat': '22.87745', 'name': 'Sajoi,Dahod,Gujarat', 'lon': '74.00478'},
      '759111': {'lat': '20.844402', 'name': 'Jindal Nagar,Angul,Odisha', 'lon': '85.151085'},
      '389380': {'lat': '22.62257', 'name': 'Khandania,Dahod,Gujarat', 'lon': '73.90983'},
      '759117': {'lat': '21.16155', 'name': 'Kakudia,Angul,Odisha', 'lon': '84.98092'},
      '383450': {'lat': '23.8656', 'name': 'Kishangadh,Sabarkantha,Gujarat', 'lon': '73.0785'},
      '841211': {'lat': '25.4754', 'name': 'K.P.Rampur,Saran,Bihar', 'lon': '84.9852'},
      '847422': {'lat': '25.9822', 'name': 'Balour,Darbhanga,Bihar', 'lon': '86.1'},
      '493778': {'lat': '20.3799', 'name': 'Sondur Project,Dhamtari,Chattisgarh', 'lon': '81.8712'},
      '841212': {'lat': '26.0046', 'name': 'Banpura Bazar,Saran,Bihar', 'lon': '84.4529'},
      '486450': {'lat': '24.606', 'name': 'Maddepur,Rewa,Madhya Pradesh', 'lon': '81.3708'},
      '414502': {'lat': '19.7933', 'name': 'Samsud Erandgaon,Ahmed Nagar,Maharashtra', 'lon': '75.7076'},
      '414503': {'lat': '19.2034', 'name': 'Hatgaon,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '493776': {'lat': '20.6709', 'name': 'Bhatgaon,Dhamtari,Chattisgarh', 'lon': '81.5287'},
      '679322': {'lat': '10.974', 'name': 'Kunnappally,Malappuram,Kerala', 'lon': '76.2487'},
      '493770': {'lat': '20.8178', 'name': 'Doma,Dhamtari,Chattisgarh', 'lon': '81.5913'},
      '414504': {'lat': '19.5645', 'name': 'Gaikwad Jalgaon,Ahmed Nagar,Maharashtra', 'lon': '75.3689'},
      '414505': {'lat': '19.2822', 'name': 'Sultanpur KHD,Ahmed Nagar,Maharashtra', 'lon': '75.182'},
      '272124': {'lat': '26.913', 'name': 'Misraulia,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.9106'},
      '206251': {'lat': '26.7683', 'name': 'Gailly,Auraiya,Uttar Pradesh', 'lon': '79.4989'},
      '847427': {'lat': '25.9564', 'name': 'Kasraur,Darbhanga,Bihar', 'lon': '86.2284'},
      '241402': {'lat': '27.374', 'name': 'Pithanapur Nagariya,Hardoi,Uttar Pradesh', 'lon': '79.9753'},
      '500010': {'lat': '17.5304', 'name': 'Bolarum Bazar,Hyderabad,Telangana', 'lon': '78.426'},
      '799115': {'lat': '23.4955', 'name': 'Bagabassa,South Tripura,Tripura', 'lon': '91.3298'},
      '462011': {'lat': '24.0875', 'name': 'Shiksha Mandal,Bhopal,Madhya Pradesh', 'lon': '79.7572'},
      '845301': {'lat': '26.6184', 'name': 'Purainia,East Champaran,Bihar', 'lon': '84.9247'},
      '784179': {'lat': '26.971', 'name': 'Borjuli Nepaligaon,Sonitpur,Assam', 'lon': '93.8695'},
      '784178': {'lat': '26.9081', 'name': 'Alupara,Sonitpur,Assam', 'lon': '93.7088'},
      '632009': {'lat': '12.8485', 'name': 'Ampuram,Vellore,Tamil Nadu', 'lon': '78.8705'},
      '632008': {'lat': '12.7779', 'name': 'Shenbakkam,Vellore,Tamil Nadu', 'lon': '79.1353'},
      '784173': {'lat': '26.971', 'name': 'Mijikajan,Sonitpur,Assam', 'lon': '93.8695'},
      '784172': {'lat': '27.4496', 'name': 'Bakori Doloni,Sonitpur,Assam', 'lon': '94.2788'},
      '784171': {'lat': '26.8535', 'name': 'Balijan Barua,Sonitpur,Assam', 'lon': '93.4797'},
      '784170': {'lat': '26.971', 'name': 'Ghahigaon Chariali,Sonitpur,Assam', 'lon': '93.8695'},
      '784177': {'lat': '26.971', 'name': 'Biswanathghat,Sonitpur,Assam', 'lon': '93.8695'},
      '784176': {'lat': '26.6726', 'name': 'Burigong,Sonitpur,Assam', 'lon': '94.0108'},
      '784175': {'lat': '26.971', 'name': 'No.2 Ghahigaon,Sonitpur,Assam', 'lon': '93.8695'},
      '784174': {'lat': '26.971', 'name': 'Balichang,Sonitpur,Assam', 'lon': '93.8695'},
      '524403': {'lat': '13.955', 'name': 'Yellore,Nellore,Andhra Pradesh', 'lon': '80.1158'},
      '524402': {'lat': '14.0166', 'name': 'Kurugonda,Nellore,Andhra Pradesh', 'lon': '79.959'},
      '524401': {'lat': '13.5859', 'name': 'Tada,Nellore,Andhra Pradesh', 'lon': '80.0315'},
      '524407': {'lat': '14.1804', 'name': 'Thoorpupundla,Nellore,Andhra Pradesh', 'lon': '79.6335'},
      '524406': {'lat': '14.1473', 'name': 'Thippavarappadu,Nellore,Andhra Pradesh', 'lon': '79.7651'},
      '524405': {'lat': '14.1771', 'name': 'Bandepalli,Nellore,Andhra Pradesh', 'lon': '79.5476'},
      '524404': {'lat': '14.0822', 'name': 'Kadagunta,Nellore,Andhra Pradesh', 'lon': '79.6784'},
      '524409': {'lat': '14.3021', 'name': 'Griddalur,Nellore,Andhra Pradesh', 'lon': '79.7244'},
      '524408': {'lat': '14.3004', 'name': 'Sanayapalem,Nellore,Andhra Pradesh', 'lon': '79.634'},
      '688004': {'lat': '9.4333', 'name': 'Punnapra,Alappuzha,Kerala', 'lon': '76.35'},
      '688005': {'lat': '9.4333', 'name': 'Kanjipadom,Alappuzha,Kerala', 'lon': '76.35'},
      '688006': {'lat': '9.4333', 'name': 'Kottankulangara,Alappuzha,Kerala', 'lon': '76.35'},
      '688007': {'lat': '9.4333', 'name': 'Kanjiramchira,Alappuzha,Kerala', 'lon': '76.35'},
      '688001': {'lat': '9.4333', 'name': 'Alappuzha Collectorate,Alappuzha,Kerala', 'lon': '76.35'},
      '688002': {'lat': '9.4333', 'name': 'Vattayal Ward,Alappuzha,Kerala', 'lon': '76.35'},
      '688003': {'lat': '9.4333', 'name': 'Sanathanapuram,Alappuzha,Kerala', 'lon': '76.35'},
      '562164': {'lat': '13.0266', 'name': 'Venkatagirikote,Bangalore Rural,Karnataka', 'lon': '77.1941'},
      '688008': {'lat': '9.4333', 'name': 'Thumpoly,Alappuzha,Kerala', 'lon': '76.35'},
      '688009': {'lat': '9.4333', 'name': 'Pazhaveedu,Alappuzha,Kerala', 'lon': '76.35'},
      '562160': {'lat': '12.7863', 'name': 'Channapatna H.O,Ramanagar,Karnataka', 'lon': '77.4545'},
      '562161': {'lat': '13.1252', 'name': 'Government Silk Farm,Ramanagar,Karnataka', 'lon': '77.3988'},
      '625513': {'lat': '9.9603', 'name': 'Bodinayakanur H.O,Theni,Tamil Nadu', 'lon': '77.7791'},
      '680631': {'lat': '10.4002', 'name': 'Ramavarmapuram,Thrissur,Kerala', 'lon': '76.2065'},
      '700075': {'lat': '22.5204', 'name': 'Viveknagar (Kolkata),Kolkata,West Bengal', 'lon': '88.2697'},
      '762019': {'lat': '20.5083', 'name': 'Sankarakhole,Kandhamal,Odisha', 'lon': '84.2948'},
      '625402': {'lat': '9.921', 'name': 'Vayalur,Madurai,Tamil Nadu', 'lon': '78.0063'},
      '700072': {'lat': '22.588', 'name': 'Hindustan Building,Kolkata,West Bengal', 'lon': '88.3185'},
      '700073': {'lat': '22.588', 'name': 'Tiretta Bazar,Kolkata,West Bengal', 'lon': '88.3185'},
      '485334': {'lat': '24.5078', 'name': 'Pilikothi,Satna,Madhya Pradesh', 'lon': '80.9332'},
      '683589': {'lat': '10.1717', 'name': 'Mekkad,Ernakulam,Kerala', 'lon': '76.3597'},
      '247776': {'lat': '29.447', 'name': 'Jasana Urf Mastgarh,Muzaffarnagar,Uttar Pradesh', 'lon': '77.3286'},
      '683585': {'lat': '10.154', 'name': 'Athani (Ernakulam),Ernakulam,Kerala', 'lon': '76.3552'},
      '683587': {'lat': '10.2', 'name': 'Malayattoor,Ernakulam,Kerala', 'lon': '76.5333'},
      '683580': {'lat': '10.1705', 'name': 'Vellarappilly South,Ernakulam,Kerala', 'lon': '76.4125'},
      '683581': {'lat': '10.1705', 'name': 'Kalady Plantation,Ernakulam,Kerala', 'lon': '76.4125'},
      '741302': {'lat': '23.601', 'name': 'Nabadwip H.O,Nadia,West Bengal', 'lon': '88.1273'},
      '244413': {'lat': '28.6709', 'name': 'Haryana,Moradabad,Uttar Pradesh', 'lon': '78.7941'},
      '844503': {'lat': '25.6412', 'name': 'Dilawarpur Govardhan,Vaishali,Bihar', 'lon': '85.3557'},
      '244411': {'lat': '28.5312', 'name': 'Khawari Awwal,Moradabad,Uttar Pradesh', 'lon': '78.8295'},
      '244410': {'lat': '28.4631', 'name': 'Sheoraj  Pur,Moradabad,Uttar Pradesh', 'lon': '78.6993'},
      '700148': {'lat': '22.4117', 'name': 'Harinavi,South 24 Parganas,West Bengal', 'lon': '88.4205'},
      '244415': {'lat': '29.0576', 'name': 'R.N. Sahaspur,Moradabad,Uttar Pradesh', 'lon': '78.892'},
      '518186': {'lat': '15.2032', 'name': 'Illuri Kotha Peta,Kurnool,Andhra Pradesh', 'lon': '78.3946'},
      '284202': {'lat': '25.6697', 'name': 'Nauta,Jhansi,Uttar Pradesh', 'lon': '79.2439'},
      '844501': {'lat': '25.8253', 'name': 'Supaul,Samastipur,Bihar', 'lon': '85.8514'},
      '362205': {'lat': '21.8378', 'name': 'Mohabatpur,Junagadh,Gujarat', 'lon': '70.9654'},
      '844507': {'lat': '25.7338', 'name': 'Mahnar Road Rs,Vaishali,Bihar', 'lon': '85.4682'},
      '206255': {'lat': '26.3266', 'name': 'Madahadaspur,Auraiya,Uttar Pradesh', 'lon': '80.1331'},
      '490042': {'lat': '21.2073', 'name': 'Pahanda,Durg,Chattisgarh', 'lon': '81.5426'},
      '731301': {'lat': '24.5795', 'name': 'Barha,Birbhum,West Bengal', 'lon': '87.7736'},
      '277506': {'lat': '25.7738', 'name': 'Middha,Ballia,Uttar Pradesh', 'lon': '84.1155'},
      '277504': {'lat': '25.327', 'name': 'Sohaon,Ballia,Uttar Pradesh', 'lon': '82.8963'},
      '277503': {'lat': '25.8754', 'name': 'Singhpur,Ballia,Uttar Pradesh', 'lon': '84.1055'},
      '700078': {'lat': '22.588', 'name': 'Jadavgarh,Kolkata,West Bengal', 'lon': '88.3185'},
      '277501': {'lat': '25.5136', 'name': 'Baghuna,Ballia,Uttar Pradesh', 'lon': '83.6836'},
      '700079': {'lat': '22.588', 'name': 'Italghacha,North 24 Parganas,West Bengal', 'lon': '88.3185'},
      '458389': {'lat': '23.577', 'name': 'Deepakheda,Mandsaur,Madhya Pradesh', 'lon': '76.5343'},
      '303701': {'lat': '27.0904', 'name': 'Nangal Ladi,Jaipur,Rajasthan', 'lon': '75.6301'},
      '610005': {'lat': '11.0263', 'name': 'Moongilkudi,Tiruvarur,Tamil Nadu', 'lon': '79.7788'},
      '422105': {'lat': '20.1657', 'name': 'Kotamgaon,Nashik,Maharashtra', 'lon': '75.053'},
      '422104': {'lat': '20.1657', 'name': 'Dahiwadi,Nashik,Maharashtra', 'lon': '75.053'},
      '422103': {'lat': '20.0079', 'name': 'Mhalsakore,Nashik,Maharashtra', 'lon': '74.7633'},
      '422102': {'lat': '20.2592', 'name': 'Brahmanwade,Nashik,Maharashtra', 'lon': '74.0714'},
      '422101': {'lat': '20.1657', 'name': 'Nehru Nagar (Nashik),Nashik,Maharashtra', 'lon': '75.053'},
      '303706': {'lat': '26.9645', 'name': 'Bassi Naga,Jaipur,Rajasthan', 'lon': '75.6029'},
      '508115': {'lat': '17.4888', 'name': 'Gandhamalla,Nalgonda,Telangana', 'lon': '79.0324'},
      '272206': {'lat': '26.6252', 'name': 'Sheopatinagar,Siddharthnagar,Uttar Pradesh', 'lon': '83.0825'},
      '515581': {'lat': '14.2256', 'name': 'Obulareddipalli,Ananthapur,Andhra Pradesh', 'lon': '78.3008'},
      '283126': {'lat': '27.2544', 'name': 'Poiya Tarrakpur,Agra,Uttar Pradesh', 'lon': '78.2309'},
      '396560': {'lat': '22.9231', 'name': 'Kantasvel,Navsari,Gujarat', 'lon': '74.3241'},
      '322025': {'lat': '25.9895', 'name': 'Naypur,Sawai Madhopur,Rajasthan', 'lon': '76.6636'},
      '271901': {'lat': '27.3192', 'name': 'Jarwal Road,Bahraich,Uttar Pradesh', 'lon': '81.2374'},
      '271903': {'lat': '27.2441', 'name': 'Umari,Bahraich,Uttar Pradesh', 'lon': '81.7303'},
      '271902': {'lat': '27.4184', 'name': 'Kundaspara,Bahraich,Uttar Pradesh', 'lon': '81.5123'},
      '610003': {'lat': '11.1309', 'name': 'Kekkarai,Tiruvarur,Tamil Nadu', 'lon': '79.7581'},
      '444312': {'lat': '19.5647', 'name': 'M.I.D.C. (Khamgaon),Buldhana,Maharashtra', 'lon': '77.0388'},
      '480115': {'lat': '22.1598', 'name': 'Chourai,Chhindwara,Madhya Pradesh', 'lon': '79.2227'},
      '444311': {'lat': '20.7489', 'name': 'Nimba,Akola,Maharashtra', 'lon': '77.0533'},
      '480110': {'lat': '21.918', 'name': 'Meghdone,Chhindwara,Madhya Pradesh', 'lon': '79.1208'},
      '480111': {'lat': '21.9122', 'name': 'Munganapar,Chhindwara,Madhya Pradesh', 'lon': '78.9696'},
      '480112': {'lat': '22.0325', 'name': 'Mohkhed,Chhindwara,Madhya Pradesh', 'lon': '79.1195'},
      '323026': {'lat': '25.5725', 'name': 'Sanwatgarh,Bundi,Rajasthan', 'lon': '75.6586'},
      '247342': {'lat': '29.8429', 'name': 'Tabur,Saharanpur,Uttar Pradesh', 'lon': '77.239'},
      '323024': {'lat': '25.5104', 'name': 'Mendi,Bundi,Rajasthan', 'lon': '75.4996'},
      '323025': {'lat': '25.3928', 'name': 'Guda Gokulpura,Bundi,Rajasthan', 'lon': '75.167'},
      '323022': {'lat': '25.2492', 'name': 'Gadarda,Bundi,Rajasthan', 'lon': '75.2718'},
      '323023': {'lat': '25.5598', 'name': 'Sahaspuriya,Bundi,Rajasthan', 'lon': '75.5135'},
      '400091': {'lat': '19.2361', 'name': 'Borivali H.O,Mumbai,Maharashtra', 'lon': '72.8338'},
      '799203': {'lat': '23.9225', 'name': 'Uttat Pulinpur,West Tripura,Tripura', 'lon': '91.6031'},
      '799202': {'lat': '24.007', 'name': 'Khowai Court,West Tripura,Tripura', 'lon': '91.6093'},
      '799201': {'lat': '24.0796', 'name': 'Belcherra,Khowai,Tripura', 'lon': '91.5997'},
      '387355': {'lat': '22.9658', 'name': 'Dumaral,Kheda,Gujarat', 'lon': '73.0444'},
      '799207': {'lat': '24.019', 'name': 'Chankhala,West Tripura,Tripura', 'lon': '91.625'},
      '387350': {'lat': '22.9658', 'name': 'Bnmroli,Kheda,Gujarat', 'lon': '73.0444'},
      '799204': {'lat': '24.007', 'name': 'Balarambazar,Dhalai,Tripura', 'lon': '91.6093'},
      '571427': {'lat': '12.6167', 'name': 'Aralakuppe,Mandya,Karnataka', 'lon': '76.9898'},
      '229405': {'lat': '26.1726', 'name': 'Munshiganj (Raebareli),Raebareli,Uttar Pradesh', 'lon': '81.2538'},
      '134008': {'lat': '30.2371', 'name': 'Budhangpur,Ambala,Haryana', 'lon': '76.6718'},
      '229404': {'lat': '25.9514', 'name': 'P.R.Kaithwal,Raebareli,Uttar Pradesh', 'lon': '81.4872'},
      '517325': {'lat': '13.6073', 'name': 'Madanapalle Society Colony,Chittoor,Andhra Pradesh', 'lon': '78.4516'},
      '517326': {'lat': '13.6073', 'name': 'Kotevuru,Chittoor,Andhra Pradesh', 'lon': '78.4516'},
      '134007': {'lat': '30.3876', 'name': 'Baldev Nagar,Ambala,Haryana', 'lon': '76.8069'},
      '134005': {'lat': '30.3571', 'name': 'Jandli,Ambala,Haryana', 'lon': '76.7964'},
      '328030': {'lat': '26.762', 'name': 'Sarani Khera,Dholpur,Rajasthan', 'lon': '77.65'},
      '328031': {'lat': '26.5807', 'name': 'Rahrai,Dholpur,Rajasthan', 'lon': '77.4503'},
      '143205': {'lat': '31.6288', 'name': 'Dhardeo,Amritsar,Punjab', 'lon': '75.2532'},
      '614014': {'lat': '10.6673', 'name': 'Serumangalam,Tiruvarur,Tamil Nadu', 'lon': '79.3973'},
      '506006': {'lat': '18.1427', 'name': 'Deshaipet,Warangal,Telangana', 'lon': '79.4385'},
      '143202': {'lat': '31.5584', 'name': 'Wadala Khurd,Amritsar,Punjab', 'lon': '75.2656'},
      '614015': {'lat': '10.5553', 'name': 'Kilakurichi,Thanjavur,Tamil Nadu', 'lon': '79.1293'},
      '571426': {'lat': '12.8065', 'name': 'Krishnarajapete,Mandya,Karnataka', 'lon': '76.509'},
      '143203': {'lat': '31.635', 'name': 'Jamalpur,Amritsar,Punjab', 'lon': '75.3376'},
      '757047': {'lat': '22.0898', 'name': 'Talakpokhari,Mayurbhanj,Odisha', 'lon': '86.1138'},
      '757046': {'lat': '21.0427', 'name': 'Moronda,Mayurbhanj,Odisha', 'lon': '84.9308'},
      '757045': {'lat': '21.2878', 'name': 'Pasana,Mayurbhanj,Odisha', 'lon': '85.1847'},
      '757043': {'lat': '21.8874', 'name': 'Rairangpur H.O,Mayurbhanj,Odisha', 'lon': '85.7057'},
      '757042': {'lat': '21.239', 'name': 'Badmouda,Mayurbhanj,Odisha', 'lon': '85.743'},
      '757041': {'lat': '21.3755', 'name': 'Jualia,Mayurbhanj,Odisha', 'lon': '85.8308'},
      '757040': {'lat': '21.5573', 'name': 'Jaida,Mayurbhanj,Odisha', 'lon': '85.9258'},
      '143201': {'lat': '31.4863', 'name': 'Kot Mehtab,Amritsar,Punjab', 'lon': '75.261'},
      '636122': {'lat': '11.5462', 'name': 'Achankuttapatti,Salem,Tamil Nadu', 'lon': '78.2023'},
      '757049': {'lat': '21.7383', 'name': 'Poda-astia,Mayurbhanj,Odisha', 'lon': '85.6444'},
      '757048': {'lat': '21.9759', 'name': 'Sargoda,Mayurbhanj,Odisha', 'lon': '85.7894'},
      '532215': {'lat': '18.7883', 'name': 'Vasundhara,Srikakulam,Andhra Pradesh', 'lon': '83.8048'},
      '535534': {'lat': '18.9046', 'name': 'Valasaballeru,Vizianagaram,Andhra Pradesh', 'lon': '83.7172'},
      '535240': {'lat': '17.9999', 'name': 'Nidigattu,Vizianagaram,Andhra Pradesh', 'lon': '83.2015'},
      '532211': {'lat': '18.5714', 'name': 'Kothalingudu,Srikakulam,Andhra Pradesh', 'lon': '84.0466'},
      '506005': {'lat': '17.9076', 'name': 'Kapulakanparthy,Warangal,Telangana', 'lon': '79.5986'},
      '532213': {'lat': '18.5609', 'name': 'Tiddimi,Srikakulam,Andhra Pradesh', 'lon': '83.7854'},
      '532212': {'lat': '18.5657', 'name': 'Dimilada,Srikakulam,Andhra Pradesh', 'lon': '83.759'},
      '272208': {'lat': '27.1653', 'name': 'Nagawa,Siddharthnagar,Uttar Pradesh', 'lon': '83.0774'},
      '733156': {'lat': '25.7707', 'name': 'Kantore,North Dinajpur,West Bengal', 'lon': '88.1834'},
      '532219': {'lat': '18.7433', 'name': 'Vonkuluru,Srikakulam,Andhra Pradesh', 'lon': '83.9049'},
      '532218': {'lat': '17.9114', 'name': 'Kondavooru,Srikakulam,Andhra Pradesh', 'lon': '82.7772'},
      '363002': {'lat': '22.505', 'name': 'Surendranagar M.P.S.C.,Surendra Nagar,Gujarat', 'lon': '72.0805'},
      '534111': {'lat': '16.9109', 'name': 'Ananthapallli,West Godavari,Andhra Pradesh', 'lon': '81.4013'},
      '534112': {'lat': '16.9167', 'name': 'Prakasaraopalem,West Godavari,Andhra Pradesh', 'lon': '81.4329'},
      '123401': {'lat': '28.3196', 'name': 'Kankarwali Rewari,Gurgaon,Haryana', 'lon': '76.611'},
      '262724': {'lat': '27.3492', 'name': 'Ghur Ghutta Bujurg,Kheri,Uttar Pradesh', 'lon': '81.3267'},
      '470120': {'lat': '23.8022', 'name': 'Bhapel,Sagar,Madhya Pradesh', 'lon': '78.5642'},
      '743613': {'lat': '22.6972', 'name': 'South Garia,South 24 Parganas,West Bengal', 'lon': '88.2933'},
      '509152': {'lat': '15.8799', 'name': 'Singwar,Mahabub Nagar,Telangana', 'lon': '78.1335'},
      '743611': {'lat': '22.2972', 'name': 'Batakhali Chandipur,South 24 Parganas,West Bengal', 'lon': '88.7883'},
      '431111': {'lat': '20.0215', 'name': 'Pathri,Aurangabad,Maharashtra', 'lon': '75.2735'},
      '152124': {'lat': '30.3313', 'name': 'Tahliwal Bodla,Firozpur,Punjab', 'lon': '74.2593'},
      '509153': {'lat': '15.8799', 'name': 'Undavelly,Mahabub Nagar,Telangana', 'lon': '78.1335'},
      '152121': {'lat': '30.2847', 'name': 'Kamal Wala,Firozpur,Punjab', 'lon': '74.0962'},
      '152123': {'lat': '30.3805', 'name': 'Behak,Firozpur,Punjab', 'lon': '74.0764'},
      '152122': {'lat': '30.2365', 'name': 'Jhummianwali,Firozpur,Punjab', 'lon': '74.1975'},
      '262721': {'lat': '27.8521', 'name': 'Mohammadpur Mahewa,Kheri,Uttar Pradesh', 'lon': '80.8034'},
      '144529': {'lat': '31.1001', 'name': 'Paddi Sura Singh,Hoshiarpur,Punjab', 'lon': '75.4485'},
      '152128': {'lat': '30.2592', 'name': 'Khuiyan Server,Firozpur,Punjab', 'lon': '74.2218'},
      '781039': {'lat': '26.2844', 'name': 'I I T,Kamrup,Assam', 'lon': '91.0994'},
      '781038': {'lat': '26.2844', 'name': 'Hatigaon Chariali,Kamrup,Assam', 'lon': '91.0994'},
      '302033': {'lat': '26.7602', 'name': 'Pratap Nagar Housing Board,Jaipur,Rajasthan', 'lon': '76.0868'},
      '302034': {'lat': '26.7602', 'name': 'Panchyawala,Jaipur,Rajasthan', 'lon': '76.0868'},
      '754228': {'lat': '20.8911', 'name': 'Pundalo,Kendrapara,Odisha', 'lon': '85.6383'},
      '333502': {'lat': '28.1877', 'name': 'Sanwlod,Jhujhunu,Rajasthan', 'lon': '75.8409'},
      '333503': {'lat': '28.0007', 'name': 'Tyonda,Jhujhunu,Rajasthan', 'lon': '75.966'},
      '781031': {'lat': '26.1849', 'name': 'Collegenagar,Kamrup,Assam', 'lon': '91.6786'},
      '781030': {'lat': '26.1975', 'name': 'Rongmahal,Kamrup,Assam', 'lon': '91.7202'},
      '571212': {'lat': '13.381', 'name': 'Kakkabbe,Kodagu,Karnataka', 'lon': '74.7805'},
      '781032': {'lat': '26.118', 'name': 'Indrapur,Kamrup,Assam', 'lon': '91.4061'},
      '781035': {'lat': '26.2844', 'name': 'Garchuk,Kamrup,Assam', 'lon': '91.0994'},
      '781034': {'lat': '26.2844', 'name': 'Odalbakra,Kamrup,Assam', 'lon': '91.0994'},
      '781037': {'lat': '26.2844', 'name': 'Panjabari,Kamrup,Assam', 'lon': '91.0994'},
      '781036': {'lat': '26.2844', 'name': 'Hengrabari,Kamrup,Assam', 'lon': '91.0994'},
      '762112': {'lat': '20.1508', 'name': 'Petapanga,Kandhamal,Odisha', 'lon': '84.2973'},
      '136043': {'lat': '29.6411', 'name': 'Bakal,Kaithal,Haryana', 'lon': '76.5752'},
      '341316': {'lat': '27.2577', 'name': 'Odit,Nagaur,Rajasthan', 'lon': '74.838'},
      '341317': {'lat': '27.3928', 'name': 'Dhurila,Nagaur,Rajasthan', 'lon': '74.378'},
      '341318': {'lat': '27.3835', 'name': 'Kharesh,Nagaur,Rajasthan', 'lon': '74.4565'},
      '341319': {'lat': '27.8771', 'name': 'Asarwa,Nagaur,Rajasthan', 'lon': '73.0086'},
      '754225': {'lat': '20.7055', 'name': 'Junapangara,Kendrapara,Odisha', 'lon': '85.6188'},
      '490036': {'lat': '21.3086', 'name': 'Murmunda,Durg,Chattisgarh', 'lon': '81.4623'},
      '509302': {'lat': '17.3183', 'name': 'Tatiparthy,Mahabub Nagar,Telangana', 'lon': '78.122'},
      '262728': {'lat': '27.9056', 'name': 'Patna Baijnatpurwa,Kheri,Uttar Pradesh', 'lon': '80.996'},
      '505532': {'lat': '18.6436', 'name': 'Vengalaipet,Karim Nagar,Telangana', 'lon': '79.2162'},
      '370620': {'lat': '23.4125', 'name': 'Laxmipar (N),Kachchh,Gujarat', 'lon': '69.0541'},
      '851118': {'lat': '25.4487', 'name': 'Baro,Begusarai,Bihar', 'lon': '85.9924'},
      '185201': {'lat': '33.1479', 'name': 'Panjah,Rajauri,Jammu & Kashmir', 'lon': '74.4168'},
      '793160': {'lat': '25.2302', 'name': 'Mukhialon,Jaintia Hills,Meghalaya', 'lon': '92.2435'},
      '793161': {'lat': '25.2756', 'name': 'Amlari,West Khasi Hills,Meghalaya', 'lon': '92.1409'},
      '851114': {'lat': '25.4462', 'name': 'Barauni Oil Refinery,Begusarai,Bihar', 'lon': '85.9319'},
      '768204': {'lat': '21.937', 'name': 'Talpatia,Jharsuguda,Odisha', 'lon': '84.0178'},
      '768203': {'lat': '21.7232', 'name': 'Industrial Estate Jharsuguda S,Jharsuguda,Odisha', 'lon': '84.0456'},
      '185202': {'lat': '33.2406', 'name': 'Tatapani,Rajauri,Jammu & Kashmir', 'lon': '74.38'},
      '768201': {'lat': '21.84', 'name': 'Jharsuguda H.O,Jharsuguda,Odisha', 'lon': '84.0102'},
      '768200': {'lat': '21.5488', 'name': 'Jamadarpali,Sambalpur,Odisha', 'lon': '83.9766'},
      '690104': {'lat': '9.2297', 'name': 'Valiyakulangara,Alappuzha,Kerala', 'lon': '76.5637'},
      '690105': {'lat': '9.2808', 'name': 'Thripperunthura,Alappuzha,Kerala', 'lon': '76.5185'},
      '690106': {'lat': '9.2297', 'name': 'Chettikulangara,Alappuzha,Kerala', 'lon': '76.5637'},
      '690107': {'lat': '9.2094', 'name': 'Thekkekara,Alappuzha,Kerala', 'lon': '76.5524'},
      '335001': {'lat': '29.7491', 'name': 'Goushala Road,Ganganagar,Rajasthan', 'lon': '74.3132'},
      '690101': {'lat': '9.2297', 'name': 'Mavelikara Cutcherry,Alappuzha,Kerala', 'lon': '76.5637'},
      '690102': {'lat': '9.2502', 'name': 'Puthiyacavu,Alappuzha,Kerala', 'lon': '76.5674'},
      '690103': {'lat': '9.2297', 'name': 'Kandiyoor,Alappuzha,Kerala', 'lon': '76.5637'},
      '466125': {'lat': '23.0985', 'name': 'Baijnath,Sehore,Madhya Pradesh', 'lon': '76.6207'},
      '412220': {'lat': '18.7816', 'name': 'MIDC Ranjangaon Ganpati,Pune,Maharashtra', 'lon': '74.2987'},
      '483113': {'lat': '23.2081', 'name': 'Kymori,Jabalpur,Madhya Pradesh', 'lon': '79.7582'},
      '690108': {'lat': '9.1782', 'name': 'Kunnam (Alappuzha),Alappuzha,Kerala', 'lon': '76.6165'},
      '466120': {'lat': '22.866', 'name': 'Nougaon,Sehore,Madhya Pradesh', 'lon': '76.6337'},
      '496115': {'lat': '21.8706', 'name': 'Salakia,Raigarh,Chattisgarh', 'lon': '82.5164'},
      '411039': {'lat': '18.8199', 'name': 'Bhosarigoan,Pune,Maharashtra', 'lon': '75.0311'},
      '411038': {'lat': '18.5333', 'name': 'Kothrud,Pune,Maharashtra', 'lon': '73.8667'},
      '801302': {'lat': '25.2472', 'name': 'Chiksoura,Nalanda,Bihar', 'lon': '85.1917'},
      '411031': {'lat': '18.8199', 'name': 'C M E,Pune,Maharashtra', 'lon': '75.0311'},
      '411030': {'lat': '18.5333', 'name': 'Sadashiv Peth,Pune,Maharashtra', 'lon': '73.8667'},
      '411033': {'lat': '18.5333', 'name': 'Punawale,Pune,Maharashtra', 'lon': '73.8667'},
      '411032': {'lat': '18.5333', 'name': 'Iaf Station,Pune,Maharashtra', 'lon': '73.8667'},
      '411035': {'lat': '18.6532', 'name': 'Akurdi,Pune,Maharashtra', 'lon': '73.7896'},
      '411034': {'lat': '18.5709', 'name': 'Kasarwadi,Pune,Maharashtra', 'lon': '74.0691'},
      '411037': {'lat': '18.5333', 'name': 'Market Yard (Pune),Pune,Maharashtra', 'lon': '73.8667'},
      '411036': {'lat': '18.5348', 'name': 'Mundhva AV,Pune,Maharashtra', 'lon': '73.9306'},
      '814160': {'lat': '24.8852', 'name': 'Dighisiwanpur,Godda,Jharkhand', 'lon': '86.8957'},
      '244901': {'lat': '28.4431', 'name': 'Bakania,Rampur,Uttar Pradesh', 'lon': '79.3081'},
      '796190': {'lat': '23.5008', 'name': 'Sumsuih,Aizawl,Mizoram', 'lon': '92.7255'},
      '388370': {'lat': '24.1655', 'name': 'Vadod,Anand,Gujarat', 'lon': '72.5466'},
      '814166': {'lat': '24.2777', 'name': 'Jamjori,Jamtara,Jharkhand', 'lon': '87.224'},
      '814167': {'lat': '23.717', 'name': 'Chaila Pathar,Dumka,Jharkhand', 'lon': '85.7647'},
      '782101': {'lat': '26.0159', 'name': 'Biharigaon,Nagaon,Assam', 'lon': '92.5179'},
      '782102': {'lat': '26.0159', 'name': 'Kandali,Nagaon,Assam', 'lon': '92.5179'},
      '782103': {'lat': '26.2333', 'name': 'Dighaldori,Nagaon,Assam', 'lon': '92.5167'},
      '782104': {'lat': '26.0364', 'name': 'Sukdal Sarubori,Marigaon,Assam', 'lon': '92.727'},
      '782105': {'lat': '25.8873', 'name': 'Doloichuba,Marigaon,Assam', 'lon': '92.4817'},
      '782106': {'lat': '26.292', 'name': 'Chatabar,Nagaon,Assam', 'lon': '92.4548'},
      '731242': {'lat': '24.2434', 'name': 'Gopegram,Birbhum,West Bengal', 'lon': '87.887'},
      '584167': {'lat': '15.5553', 'name': 'Salgunda,Raichur,Karnataka', 'lon': '76.6832'},
      '609808': {'lat': '11.0092', 'name': 'Terizhandur,Nagapattinam,Tamil Nadu', 'lon': '79.499'},
      '492017': {'lat': '21.1879', 'name': 'IBC Raipur,Raipur,Chattisgarh', 'lon': '81.6898'},
      '361150': {'lat': '22.2286', 'name': 'Valsura,Jamnagar,Gujarat', 'lon': '70.3853'},
      '516270': {'lat': '14.0918', 'name': 'Venkatreddygaripalli,Cuddapah,Andhra Pradesh', 'lon': '78.7409'},
      '522124': {'lat': '16.0971', 'name': 'Ponnur Bazar,Guntur,Andhra Pradesh', 'lon': '80.4486'},
      '442003': {'lat': '20.7664', 'name': 'Cottom Market,Wardha,Maharashtra', 'lon': '78.7001'},
      '609807': {'lat': '10.9416', 'name': 'Karuppur,Thanjavur,Tamil Nadu', 'lon': '79.2327'},
      '609806': {'lat': '11.0686', 'name': 'Vanathirajapuram,Nagapattinam,Tamil Nadu', 'lon': '79.6233'},
      '609805': {'lat': '11.0212', 'name': 'Edakiam,Nagapattinam,Tamil Nadu', 'lon': '79.5939'},
      '609804': {'lat': '11.0377', 'name': 'Manalur,Thanjavur,Tamil Nadu', 'lon': '79.4882'},
      '504107': {'lat': '19.1412', 'name': 'RGU IIIT Campus Basar,Adilabad,Telangana', 'lon': '78.1371'},
      '506164': {'lat': '18.065', 'name': 'Parkal H.O,Warangal,Telangana', 'lon': '79.5612'},
      '851212': {'lat': '25.5869', 'name': 'Bandehra,Khagaria,Bihar', 'lon': '86.716'},
      '521110': {'lat': '15.8525', 'name': 'Rangannagudem,Krishna,Andhra Pradesh', 'lon': '79.8178'},
      '521111': {'lat': '16.7492', 'name': 'Tukkuluru,Krishna,Andhra Pradesh', 'lon': '80.9046'},
      '175030': {'lat': '31.4496', 'name': 'Taleli,Mandi,Himachal Pradesh', 'lon': '76.8116'},
      '175031': {'lat': '31.6314', 'name': 'Brokhari,Mandi,Himachal Pradesh', 'lon': '77.1103'},
      '175032': {'lat': '31.9287', 'name': 'Prainsainthal,Mandi,Himachal Pradesh', 'lon': '76.8597'},
      '175033': {'lat': '31.6377', 'name': 'Bahnu,Mandi,Himachal Pradesh', 'lon': '76.6749'},
      '175034': {'lat': '31.4277', 'name': 'Bachwan,Mandi,Himachal Pradesh', 'lon': '76.8201'},
      '175035': {'lat': '31.6144', 'name': 'Shillibagi,Mandi,Himachal Pradesh', 'lon': '77.1184'},
      '175036': {'lat': '31.5495', 'name': 'Kathyaun,Mandi,Himachal Pradesh', 'lon': '76.9444'},
      '175037': {'lat': '31.5909', 'name': 'Cholthra,Mandi,Himachal Pradesh', 'lon': '76.9229'},
      '175038': {'lat': '31.5436', 'name': 'Rakol,Mandi,Himachal Pradesh', 'lon': '76.9778'},
      '175039': {'lat': '31.4122', 'name': 'Jahal,Mandi,Himachal Pradesh', 'lon': '77.1022'},
      '733215': {'lat': '25.7973', 'name': 'Domohana,North Dinajpur,West Bengal', 'lon': '87.957'},
      '229125': {'lat': '26.2511', 'name': 'Terukha,Raebareli,Uttar Pradesh', 'lon': '80.6122'},
      '229122': {'lat': '26.2485', 'name': 'Tikwamau,Raebareli,Uttar Pradesh', 'lon': '80.9808'},
      '229123': {'lat': '26.0173', 'name': 'Raghaupur,Raebareli,Uttar Pradesh', 'lon': '81.3929'},
      '229120': {'lat': '26.1795', 'name': 'Rail Coach Factory Lalganj(Raebareli),Raebareli,Uttar Pradesh', 'lon': '81.3276'},
      '229121': {'lat': '26.1996', 'name': 'Kundwal,Raebareli,Uttar Pradesh', 'lon': '81.0784'},
      '572101': {'lat': '13.3758', 'name': 'Tumkur name,Tumakuru,Karnataka', 'lon': '76.9751'},
      '361335': {'lat': '22.2099', 'name': 'Varvala,Jamnagar,Gujarat', 'lon': '69.1781'},
      '572103': {'lat': '13.3758', 'name': 'R G Market Tumkur,Tumakuru,Karnataka', 'lon': '76.9751'},
      '572102': {'lat': '13.6402', 'name': 'S G Extn Tumkur,Tumakuru,Karnataka', 'lon': '77.259'},
      '361330': {'lat': '22.4529', 'name': 'Bet,Jamnagar,Gujarat', 'lon': '69.0958'},
      '572104': {'lat': '13.342', 'name': 'Mydala,Tumakuru,Karnataka', 'lon': '77.1422'},
      '572107': {'lat': '13.3777', 'name': 'Bugudanahalli,Tumakuru,Karnataka', 'lon': '76.8182'},
      '572106': {'lat': '13.3236', 'name': 'Northren Extension,Tumakuru,Karnataka', 'lon': '76.8915'},
      '212663': {'lat': '25.8219', 'name': 'Tapani,Fatehpur,Uttar Pradesh', 'lon': '80.6173'},
      '851218': {'lat': '25.4415', 'name': 'Suhird Nagar,Begusarai,Bihar', 'lon': '86.1428'},
      '504273': {'lat': '19.2741', 'name': 'Kothmeer,Adilabad,Telangana', 'lon': '79.0095'},
      '132157': {'lat': '29.8152', 'name': 'Sitamai,Karnal,Haryana', 'lon': '76.7147'},
      '632004': {'lat': '12.7779', 'name': 'Bakiyath,Vellore,Tamil Nadu', 'lon': '79.1353'},
      '344701': {'lat': '26.208', 'name': 'Kashmir,Barmer,Rajasthan', 'lon': '71.854'},
      '344702': {'lat': '25.1851', 'name': 'Jalila,Barmer,Rajasthan', 'lon': '71.2647'},
      '453446': {'lat': '22.5828', 'name': 'Rasalpura,Indore,Madhya Pradesh', 'lon': '75.7859'},
      '502276': {'lat': '18.7125', 'name': 'Kodurkurd,Medak,Telangana', 'lon': '78.9515'},
      '307801': {'lat': '25.1369', 'name': 'Varada,Sirohi,Rajasthan', 'lon': '72.8267'},
      '721644': {'lat': '22.7639', 'name': 'Ramchakmoyna,East Midnapore,West Bengal', 'lon': '88.1081'},
      '212218': {'lat': '25.6108', 'name': 'Newada,Allahabad,Uttar Pradesh', 'lon': '81.9788'},
      '272270': {'lat': '27.0129', 'name': 'Bhataura,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.0578'},
      '272271': {'lat': '27.0338', 'name': 'Kudwa,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.0208'},
      '203001': {'lat': '28.2203', 'name': 'Panni  Nagar,Bulandshahr,Uttar Pradesh', 'lon': '78.3455'},
      '506169': {'lat': '18.2192', 'name': 'Adavikamalapur,Warangal,Telangana', 'lon': '79.8118'},
      '591216': {'lat': '16.3084', 'name': 'Borgaon (Belgaum),Belagavi,Karnataka', 'lon': '75.1161'},
      '591217': {'lat': '16.5651', 'name': 'Suttatti,Belagavi,Karnataka', 'lon': '74.8153'},
      '591214': {'lat': '16.3084', 'name': 'Shamanewadi,Belagavi,Karnataka', 'lon': '75.1161'},
      '591215': {'lat': '16.5386', 'name': 'Mangur,Belagavi,Karnataka', 'lon': '74.415'},
      '591212': {'lat': '17.0606', 'name': 'Tavashi,Belagavi,Karnataka', 'lon': '75.6057'},
      '591213': {'lat': '16.1195', 'name': 'Kerur,Belagavi,Karnataka', 'lon': '74.823'},
      '591211': {'lat': '16.3084', 'name': 'Lakhanapur,Belagavi,Karnataka', 'lon': '75.1161'},
      '504102': {'lat': '19.1326', 'name': 'Yedbid,Adilabad,Telangana', 'lon': '77.9647'},
      '335523': {'lat': '29.0171', 'name': 'Naulakhi,Hanumangarh,Rajasthan', 'lon': '74.7637'},
      '591218': {'lat': '16.3084', 'name': 'Arabhavimath,Belagavi,Karnataka', 'lon': '75.1161'},
      '591219': {'lat': '16.3084', 'name': 'Galataga,Belagavi,Karnataka', 'lon': '75.1161'},
      '613101': {'lat': '10.8443', 'name': 'Nadukaveri,Thanjavur,Tamil Nadu', 'lon': '79.0174'},
      '613103': {'lat': '10.7531', 'name': 'Kilathiruppanthuruthi,Thanjavur,Tamil Nadu', 'lon': '78.9445'},
      '613102': {'lat': '10.1665', 'name': 'Mekalathur,Thanjavur,Tamil Nadu', 'lon': '78.6746'},
      '613105': {'lat': '10.8681', 'name': 'Tiruchinampoondi,Thanjavur,Tamil Nadu', 'lon': '78.955'},
      '613104': {'lat': '10.8534', 'name': 'Kuthur,Thanjavur,Tamil Nadu', 'lon': '78.9718'},
      '576231': {'lat': '13.5514', 'name': 'Kedur,Udupi,Karnataka', 'lon': '74.7011'},
      '382445': {'lat': '22.6329', 'name': 'Vivekanand Nagar,Ahmedabad,Gujarat', 'lon': '72.7914'},
      '325001': {'lat': '24.8545', 'name': 'Kherdarasoolpur,Kota,Rajasthan', 'lon': '76.1909'},
      '632006': {'lat': '12.7779', 'name': 'Virudhambattu,Vellore,Tamil Nadu', 'lon': '79.1353'},
      '382110': {'lat': '22.7384', 'name': 'Fangadi,Ahmedabad,Gujarat', 'lon': '72.6843'},
      '382443': {'lat': '22.6329', 'name': 'Isanpur,Ahmedabad,Gujarat', 'lon': '72.7914'},
      '504103': {'lat': '19.2123', 'name': 'Malegoan,Adilabad,Telangana', 'lon': '78.0584'},
      '509371': {'lat': '16.8502', 'name': 'Kothlabad,Mahabub Nagar,Telangana', 'lon': '77.9213'},
      '382449': {'lat': '22.6329', 'name': 'Gatrad,Ahmedabad,Gujarat', 'lon': '72.7914'},
      '504215': {'lat': '18.9272', 'name': 'Velagnur,Adilabad,Telangana', 'lon': '79.1812'},
      '504216': {'lat': '18.5785', 'name': 'Wellal,Adilabad,Telangana', 'lon': '79.3597'},
      '262502': {'lat': '29.7604', 'name': 'Bisharh,Pithoragarh,Uttarakhand', 'lon': '79.7546'},
      '635602': {'lat': '12.5131', 'name': 'Karuppanur,Vellore,Tamil Nadu', 'lon': '78.5847'},
      '635601': {'lat': '12.4453', 'name': 'Gandhipet - TPT,Vellore,Tamil Nadu', 'lon': '78.6146'},
      '523292': {'lat': '15.1127', 'name': 'Chakicherla,Prakasam,Andhra Pradesh', 'lon': '80.0366'},
      '523291': {'lat': '15.0613', 'name': 'Mocherla,Prakasam,Andhra Pradesh', 'lon': '80.0003'},
      '505306': {'lat': '18.5284', 'name': 'Ambaripet,Karim Nagar,Telangana', 'lon': '78.8508'},
      '630212': {'lat': '10.1771', 'name': 'Iraniyur,Sivaganga,Tamil Nadu', 'lon': '78.6256'},
      '393002': {'lat': '21.6045', 'name': 'Kapodra,Bharuch,Gujarat', 'lon': '73.044'},
      '393001': {'lat': '21.6045', 'name': 'Boidra,Bharuch,Gujarat', 'lon': '73.044'},
      '630211': {'lat': '10.4855', 'name': 'Tirupattur Bus Stand,Sivaganga,Tamil Nadu', 'lon': '78.6206'},
      '844504': {'lat': '25.6418', 'name': 'Chandpura Nankar,Vaishali,Bihar', 'lon': '85.348'},
      '752016': {'lat': '20.7731', 'name': 'Nalibasanta,Puri,Odisha', 'lon': '84.8151'},
      '802158': {'lat': '25.3788', 'name': 'Barad Parwa,Bhojpur,Bihar', 'lon': '84.3405'},
      '802159': {'lat': '25.3753', 'name': 'Jitaura,Bhojpur,Bihar', 'lon': '84.3874'},
      '515803': {'lat': '15.1449', 'name': 'N.Narsapuram,Ananthapur,Andhra Pradesh', 'lon': '77.608'},
      '515774': {'lat': '14.8602', 'name': 'Kallur R.S.,Ananthapur,Andhra Pradesh', 'lon': '77.962'},
      '515801': {'lat': '15.1449', 'name': 'Modinabad,Ananthapur,Andhra Pradesh', 'lon': '77.608'},
      '802155': {'lat': '25.4176', 'name': 'Eyar,Bhojpur,Bihar', 'lon': '84.3794'},
      '802152': {'lat': '25.0574', 'name': 'Bimwan,Bhojpur,Bihar', 'lon': '84.739'},
      '509215': {'lat': '16.684', 'name': 'Gundlanarva,Mahabub Nagar,Telangana', 'lon': '78.6796'},
      '711413': {'lat': '23.2168', 'name': 'Sauria,Howrah,West Bengal', 'lon': '87.8341'},
      '413507': {'lat': '18.9395', 'name': 'Sarola Mandva,Osmanabad,Maharashtra', 'lon': '76.1789'},
      '413506': {'lat': '18.1381', 'name': 'Takviki,Osmanabad,Maharashtra', 'lon': '76.4032'},
      '413505': {'lat': '19.3105', 'name': 'Tandulwadi (Sonari),Osmanabad,Maharashtra', 'lon': '75.6244'},
      '413504': {'lat': '18.6649', 'name': 'Uloop,Osmanabad,Maharashtra', 'lon': '76.0095'},
      '413503': {'lat': '19.9303', 'name': 'Vashi (Osmanabad),Osmanabad,Maharashtra', 'lon': '76.4686'},
      '413502': {'lat': '18.4671', 'name': 'Bawachi,Osmanabad,Maharashtra', 'lon': '75.6181'},
      '413501': {'lat': '18.7453', 'name': 'Osmanabad H.O,Osmanabad,Maharashtra', 'lon': '76.1114'},
      '571457': {'lat': '11.8199', 'name': 'Odeyarapalyam,Chamrajnagar,Karnataka', 'lon': '77.244'},
      '743429': {'lat': '23.286', 'name': 'Taki Market,North 24 Parganas,West Bengal', 'lon': '88.2996'},
      '571455': {'lat': '12.622', 'name': 'Kanivekoppal,Mandya,Karnataka', 'lon': '76.6327'},
      '413509': {'lat': '18.5631', 'name': 'Arani,Osmanabad,Maharashtra', 'lon': '76.6233'},
      '413508': {'lat': '18.6899', 'name': 'Dhorala,Osmanabad,Maharashtra', 'lon': '76.2019'},
      '721428': {'lat': '21.6278', 'name': 'Saripur,East Midnapore,West Bengal', 'lon': '87.5197'},
      '721429': {'lat': '21.8995', 'name': 'Barida Bazar,East Midnapore,West Bengal', 'lon': '87.5379'},
      '274509': {'lat': '26.5213', 'name': 'Dol Chhapra,Deoria,Uttar Pradesh', 'lon': '83.2316'},
      '341551': {'lat': '28.1073', 'name': 'Payali,Nagaur,Rajasthan', 'lon': '75.3372'},
      '721420': {'lat': '22.4305', 'name': 'Alangiri,East Midnapore,West Bengal', 'lon': '87.4447'},
      '721422': {'lat': '22.3159', 'name': 'Uttar-tajpur,East Midnapore,West Bengal', 'lon': '88.0212'},
      '721423': {'lat': '22.4761', 'name': 'Balisai,East Midnapore,West Bengal', 'lon': '86.8761'},
      '638182': {'lat': '11.1049', 'name': 'Punjaiedayarkilmugam,Namakkal,Tamil Nadu', 'lon': '77.6441'},
      '638183': {'lat': '11.2509', 'name': 'Komarapalayam East,Namakkal,Tamil Nadu', 'lon': '77.6974'},
      '721426': {'lat': '22.6315', 'name': 'Kakrajit,West Midnapore,West Bengal', 'lon': '87.428'},
      '638181': {'lat': '11.1779', 'name': 'Pothanur,Namakkal,Tamil Nadu', 'lon': '77.6708'},
      '689548': {'lat': '9.4', 'name': 'Varayannur,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689549': {'lat': '9.4', 'name': 'Maramon,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689542': {'lat': '9.4', 'name': 'Nellimala,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689543': {'lat': '9.4', 'name': 'Madathumbhagom North,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689541': {'lat': '9.4', 'name': 'Thottabhagom,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689546': {'lat': '9.4', 'name': 'Othera,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689547': {'lat': '9.4', 'name': 'Muttumon,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689544': {'lat': '9.4', 'name': 'Thelliyoor,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '689545': {'lat': '9.4', 'name': 'Kolabhagom,Pathanamthitta,Kerala', 'lon': '76.6667'},
      '247777': {'lat': '29.5099', 'name': 'Thanabhawan,Muzaffarnagar,Uttar Pradesh', 'lon': '77.4176'},
      '400060': {'lat': '19.1699', 'name': 'Jogeshwari East,Mumbai,Maharashtra', 'lon': '72.8504'},
      '247775': {'lat': '29.3274', 'name': 'Tahirpur Bhabisa,Muzaffarnagar,Uttar Pradesh', 'lon': '77.2964'},
      '247774': {'lat': '29.39', 'name': 'Uncha Gaon,Muzaffarnagar,Uttar Pradesh', 'lon': '77.2123'},
      '247773': {'lat': '29.5354', 'name': 'Kairtoo,Muzaffarnagar,Uttar Pradesh', 'lon': '77.2242'},
      '247772': {'lat': '29.7441', 'name': 'Biralasi,Muzaffarnagar,Uttar Pradesh', 'lon': '77.458'},
      '247771': {'lat': '29.2811', 'name': 'Nala,Muzaffarnagar,Uttar Pradesh', 'lon': '77.2756'},
      '400061': {'lat': '18.9596', 'name': 'Madh,Mumbai,Maharashtra', 'lon': '72.8496'},
      '515110': {'lat': '13.8143', 'name': 'Kotlapalli,Ananthapur,Andhra Pradesh', 'lon': '77.8512'},
      '225414': {'lat': '26.9626', 'name': 'Bhitaura Lakhan,Barabanki,Uttar Pradesh', 'lon': '81.3276'},
      '671541': {'lat': '12.4994', 'name': 'Edneer,Kasargod,Kerala', 'lon': '75.1596'},
      '247129': {'lat': '29.4564', 'name': 'Talhapur,Saharanpur,Uttar Pradesh', 'lon': '78.129'},
      '671543': {'lat': '12.554', 'name': 'Kakkebettu,Kasargod,Kerala', 'lon': '75.1684'},
      '671542': {'lat': '12.522', 'name': 'Kotoor,Kasargod,Kerala', 'lon': '75.12'},
      '206124': {'lat': '26.8736', 'name': 'Sherpur,Etawah,Uttar Pradesh', 'lon': '79.0896'},
      '461001': {'lat': '22.3348', 'name': 'Panjara Kala,Hoshangabad,Madhya Pradesh', 'lon': '78.0923'},
      '400063': {'lat': '18.9596', 'name': 'Goregaon East,Mumbai,Maharashtra', 'lon': '72.8496'},
      '563159': {'lat': '13.5591', 'name': 'E. Thimmasandra,Chikkaballapur,Karnataka', 'lon': '78.0514'},
      '400064': {'lat': '19.197', 'name': 'Liberty Garden,Mumbai,Maharashtra', 'lon': '72.845'},
      '563157': {'lat': '13.2327', 'name': 'Vemagal,Kolar,Karnataka', 'lon': '78.0218'},
      '400065': {'lat': '19.1699', 'name': 'Aareymilk Colony,Mumbai,Maharashtra', 'lon': '72.8504'},
      '383240': {'lat': '23.6805', 'name': 'Meditimba,Sabarkantha,Gujarat', 'lon': '73.3751'},
      '250205': {'lat': '28.9048', 'name': 'Kazamabad Goon,Meerut,Uttar Pradesh', 'lon': '77.588'},
      '383245': {'lat': '23.0674', 'name': 'Bhetali,Sabarkantha,Gujarat', 'lon': '73.3444'},
      '383246': {'lat': '22.6214', 'name': 'Kundol Pal,Sabarkantha,Gujarat', 'lon': '73.7897'},
      '580027': {'lat': '15.3492', 'name': 'Gokul,Dharwad,Karnataka', 'lon': '75.0811'},
      '754227': {'lat': '20.7933', 'name': 'Pegarpara,Kendrapara,Odisha', 'lon': '86.1235'},
      '754224': {'lat': '21.5901', 'name': 'Kharinasi,Kendrapara,Odisha', 'lon': '84.8639'},
      '400067': {'lat': '19.1699', 'name': 'Kandivali West,Mumbai,Maharashtra', 'lon': '72.8504'},
      '754222': {'lat': '21.2713', 'name': 'Basupur,Kendrapara,Odisha', 'lon': '84.4945'},
      '754223': {'lat': '20.6626', 'name': 'Aitipur,Kendrapara,Odisha', 'lon': '84.7438'},
      '754220': {'lat': '20.6912', 'name': 'Koilipur,Kendrapara,Odisha', 'lon': '86.352'},
      '754221': {'lat': '21.1926', 'name': 'Mirzapur,Cuttack,Odisha', 'lon': '86.0148'},
      '722146': {'lat': '24.0931', 'name': 'Kalpathar,Bankura,West Bengal', 'lon': '87.5027'},
      '465116': {'lat': '22.6745', 'name': 'Khatsur,Shajapur,Madhya Pradesh', 'lon': '76.6327'},
      '465110': {'lat': '23.2819', 'name': 'Tilawad  Govind,Shajapur,Madhya Pradesh', 'lon': '76.3515'},
      '465113': {'lat': '22.6524', 'name': 'Nipania  Dhakar,Shajapur,Madhya Pradesh', 'lon': '76.0443'},
      '450554': {'lat': '22.2368', 'name': 'Mandhata Omkareshwar,Khandwa,Madhya Pradesh', 'lon': '76.0674'},
      '152001': {'lat': '30.8628', 'name': 'Cantt Railway Station Ferozepu,Firozpur,Punjab', 'lon': '74.6316'},
      '722144': {'lat': '23.7933', 'name': 'Kostia,Bankura,West Bengal', 'lon': '87.5136'},
      '307501': {'lat': '24.6211', 'name': 'Oriya,Sirohi,Rajasthan', 'lon': '72.7423'},
      '604505': {'lat': '12.5794', 'name': 'Melnemili,Tiruvannamalai,Tamil Nadu', 'lon': '79.4862'},
      '415606': {'lat': '17.7816', 'name': 'Mudhe Tarf Sawarda,Ratnagiri,Maharashtra', 'lon': '74.8385'},
      '247551': {'lat': '27.6543', 'name': 'Nagal (Saharanpur),Saharanpur,Uttar Pradesh', 'lon': '79.111'},
      '492015': {'lat': '21.2051', 'name': 'Deopuri,Raipur,Chattisgarh', 'lon': '81.6767'},
      '247554': {'lat': '29.6392', 'name': 'Ambehta Shekhan,Saharanpur,Uttar Pradesh', 'lon': '77.7228'},
      '458339': {'lat': '24.2829', 'name': 'Barkheda Dev Dungari,Mandsaur,Madhya Pradesh', 'lon': '74.9902'},
      '480107': {'lat': '21.8995', 'name': 'Jam,Chhindwara,Madhya Pradesh', 'lon': '78.8691'},
      '125133': {'lat': '29.6789', 'name': 'Meond Khurd,Fatehabad,Haryana', 'lon': '75.4207'},
      '415604': {'lat': '17.7614', 'name': 'Vehele,Ratnagiri,Maharashtra', 'lon': '73.2707'},
      '722143': {'lat': '23.3392', 'name': 'Japamali,Bankura,West Bengal', 'lon': '87.1821'},
      '505305': {'lat': '18.3907', 'name': 'Samudralingapur,Karim Nagar,Telangana', 'lon': '78.638'},
      '505304': {'lat': '17.8626', 'name': 'Srigadha,Karim Nagar,Telangana', 'lon': '78.7198'},
      '505307': {'lat': '18.4854', 'name': 'Thimmapur,Karim Nagar,Telangana', 'lon': '79.1392'},
      '415605': {'lat': '17.5557', 'name': 'Kadavali,Ratnagiri,Maharashtra', 'lon': '74.1248'},
      '505301': {'lat': '18.3532', 'name': 'Nimmapalli,Karim Nagar,Telangana', 'lon': '78.6887'},
      '480105': {'lat': '21.6873', 'name': 'Rajadi Pipla,Chhindwara,Madhya Pradesh', 'lon': '78.6791'},
      '505303': {'lat': '17.8756', 'name': 'Bandalingampalli,Karim Nagar,Telangana', 'lon': '80.1059'},
      '505302': {'lat': '18.2527', 'name': 'Chintalathana,Rajanna Sircilla,Telangana', 'lon': '79.0104'},
      '415602': {'lat': '17.5557', 'name': 'Shirgaon (CPN),Ratnagiri,Maharashtra', 'lon': '74.1248'},
      '722141': {'lat': '23.341', 'name': 'Dashere,Bankura,West Bengal', 'lon': '87.5717'},
      '604504': {'lat': '12.6148', 'name': 'Munugapattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.3574'},
      '415603': {'lat': '17.5557', 'name': 'Khadpoli,Ratnagiri,Maharashtra', 'lon': '74.1248'},
      '172116': {'lat': '31.5762', 'name': 'Rispa,Kinnaur,Himachal Pradesh', 'lon': '78.4268'},
      '172117': {'lat': '31.826', 'name': 'Mud,Lahul & Spiti,Himachal Pradesh', 'lon': '78.0869'},
      '172114': {'lat': '32.1586', 'name': 'Kee Gompa,Lahul & Spiti,Himachal Pradesh', 'lon': '78.1313'},
      '172115': {'lat': '32.0518', 'name': 'Nathpa,Kinnaur,Himachal Pradesh', 'lon': '76.7184'},
      '172112': {'lat': '31.826', 'name': 'Hango,Kinnaur,Himachal Pradesh', 'lon': '78.0869'},
      '172113': {'lat': '32.3735', 'name': 'Dhanker,Lahul & Spiti,Himachal Pradesh', 'lon': '77.2418'},
      '172110': {'lat': '31.6421', 'name': 'Speelo,Kinnaur,Himachal Pradesh', 'lon': '78.1822'},
      '172111': {'lat': '31.9148', 'name': 'Gue,Kinnaur,Himachal Pradesh', 'lon': '78.5854'},
      '400068': {'lat': '19.2565', 'name': 'Dahisar RS,Mumbai,Maharashtra', 'lon': '72.8733'},
      '400069': {'lat': '19.1145', 'name': 'Nagardas Road,Mumbai,Maharashtra', 'lon': '72.8712'},
      '415601': {'lat': '17.4359', 'name': 'Pophali,Ratnagiri,Maharashtra', 'lon': '73.6564'},
      '209308': {'lat': '26.3805', 'name': 'Tejpur,Kanpur Nagar,Uttar Pradesh', 'lon': '80.2158'},
      '172118': {'lat': '31.5932', 'name': 'Bhaba,Kinnaur,Himachal Pradesh', 'lon': '78.0371'},
      '732143': {'lat': '25.0056', 'name': 'Arapur,Malda,West Bengal', 'lon': '87.9102'},
      '732142': {'lat': '24.1942', 'name': 'Bachamari,Malda,West Bengal', 'lon': '87.81'},
      '732141': {'lat': '25.4076', 'name': 'Popra,Malda,West Bengal', 'lon': '87.8574'},
      '326516': {'lat': '24.3691', 'name': 'Sarwad,Jhalawar,Rajasthan', 'lon': '75.978'},
      '732147': {'lat': '25.1833', 'name': 'Bamangola,Malda,West Bengal', 'lon': '88.2667'},
      '732144': {'lat': '25.0056', 'name': 'Kotwali (Malda),Malda,West Bengal', 'lon': '87.9102'},
      '327022': {'lat': '23.8307', 'name': 'Asoda,Banswara,Rajasthan', 'lon': '74.1586'},
      '327023': {'lat': '23.8103', 'name': 'Padoli Rathor,Banswara,Rajasthan', 'lon': '74.3831'},
      '518533': {'lat': '15.3457', 'name': 'Vempenta,Kurnool,Andhra Pradesh', 'lon': '78.2082'},
      '327026': {'lat': '24.1153', 'name': 'Badgun,Banswara,Rajasthan', 'lon': '74.3377'},
      '327027': {'lat': '23.9717', 'name': 'Borpi Khera,Banswara,Rajasthan', 'lon': '74.1147'},
      '327024': {'lat': '23.5928', 'name': 'Partapur,Banswara,Rajasthan', 'lon': '74.174'},
      '327025': {'lat': '23.927', 'name': 'Sundanpur,Banswara,Rajasthan', 'lon': '75.3153'},
      '685511': {'lat': '9.6906', 'name': 'Kozhimala,Idukki,Kerala', 'lon': '76.9977'},
      '638313': {'lat': '11.491', 'name': 'Pudukkaraipudur,Erode,Tamil Nadu', 'lon': '77.5155'},
      '790101': {'lat': '27.4', 'name': 'Sangti,West Kameng,Arunachal Pradesh', 'lon': '92.1833'},
      '685512': {'lat': '9.6906', 'name': 'Chellarcoil,Idukki,Kerala', 'lon': '76.9977'},
      '685515': {'lat': '9.6906', 'name': 'Kattappana South,Idukki,Kerala', 'lon': '76.9977'},
      '685514': {'lat': '9.6906', 'name': 'Erattayar,Idukki,Kerala', 'lon': '76.9977'},
      '790105': {'lat': '27.5667', 'name': 'Mukto,Tawang,Arunachal Pradesh', 'lon': '91.9833'},
      '790104': {'lat': '27.5742', 'name': 'Lumberdung,Tawang,Arunachal Pradesh', 'lon': '91.9244'},
      '509411': {'lat': '17.0372', 'name': 'Pedripadu,Mahabub Nagar,Telangana', 'lon': '78.143'},
      '461005': {'lat': '22.3348', 'name': 'Sp Mills Hoshangabad,Hoshangabad,Madhya Pradesh', 'lon': '78.0923'},
      '326512': {'lat': '26.0629', 'name': 'Mishroli,Jhalawar,Rajasthan', 'lon': '75.358'},
      '507140': {'lat': '18', 'name': 'Raithwari Kothagudem,Khammam,Telangana', 'lon': '80.859'},
      '602002': {'lat': '13.0553', 'name': 'Melnallathur,Tiruvallur,Tamil Nadu', 'lon': '79.906'},
      '723143': {'lat': '24.05', 'name': 'Berada,Purulia,West Bengal', 'lon': '87.6874'},
      '723142': {'lat': '23.5693', 'name': 'Ramkanali,Purulia,West Bengal', 'lon': '87.1521'},
      '723145': {'lat': '23.2842', 'name': 'Baragoria,Purulia,West Bengal', 'lon': '85.9267'},
      '723147': {'lat': '23.2872', 'name': 'Baghra,Purulia,West Bengal', 'lon': '85.9225'},
      '622302': {'lat': '10.6015', 'name': 'Marudakonviduthi,Pudukkottai,Tamil Nadu', 'lon': '79.1602'},
      '723149': {'lat': '22.4181', 'name': 'Kukurgoria,Purulia,West Bengal', 'lon': '88.3425'},
      '723148': {'lat': '23.5693', 'name': 'Arjunjora,Purulia,West Bengal', 'lon': '87.1521'},
      '742133': {'lat': '24.1507', 'name': 'Mirzapur (MurshidabB.O,Murshidabad,West Bengal', 'lon': '87.9722'},
      '742132': {'lat': '24.0596', 'name': 'Satitara,Murshidabad,West Bengal', 'lon': '87.8867'},
      '742135': {'lat': '24.3321', 'name': 'Kalukhali,Murshidabad,West Bengal', 'lon': '88.058'},
      '742134': {'lat': '24.2925', 'name': 'Jhunka,Murshidabad,West Bengal', 'lon': '88.0692'},
      '742137': {'lat': '24.1086', 'name': 'Kandi Court,Murshidabad,West Bengal', 'lon': '88.0562'},
      '391510': {'lat': '23.0231', 'name': 'Lilora,Vadodara,Gujarat', 'lon': '73.5228'},
      '534327': {'lat': '16.9497', 'name': 'Miniminchilipadu,West Godavari,Andhra Pradesh', 'lon': '81.6822'},
      '534326': {'lat': '16.5618', 'name': 'Nadipudi,West Godavari,Andhra Pradesh', 'lon': '81.0607'},
      '534324': {'lat': '17.093', 'name': 'Munamarru,West Godavari,Andhra Pradesh', 'lon': '81.7291'},
      '244602': {'lat': '29.2226', 'name': 'Sherpur Patti,Moradabad,Uttar Pradesh', 'lon': '78.7707'},
      '534320': {'lat': '17.0568', 'name': 'Kakileru,West Godavari,Andhra Pradesh', 'lon': '82.1643'},
      '627151': {'lat': '8.5998', 'name': 'Ponnakudi,Tirunelveli,Tamil Nadu', 'lon': '77.7348'},
      '605755': {'lat': '11.9753', 'name': 'S.Kodungal,Villupuram,Tamil Nadu', 'lon': '79.3138'},
      '534329': {'lat': '16.8445', 'name': 'Munipalli,West Godavari,Andhra Pradesh', 'lon': '81.7079'},
      '534328': {'lat': '17.0525', 'name': 'Kothapalli Agraharam,West Godavari,Andhra Pradesh', 'lon': '81.5024'},
      '362015': {'lat': '21.2215', 'name': 'Vijapur,Junagadh,Gujarat', 'lon': '70.554'},
      '362540': {'lat': '20.9432', 'name': 'Ghoghla,Diu,Daman & Diu', 'lon': '71.0823'},
      '271602': {'lat': '27.123', 'name': 'Dutt Nagar,Gonda,Uttar Pradesh', 'lon': '81.8295'},
      '362011': {'lat': '23.5623', 'name': 'Patrapasar,Junagadh,Gujarat', 'lon': '72.7485'},
      '679512': {'lat': '10.8314', 'name': 'Ambalapara,Palakkad,Kerala', 'lon': '76.4127'},
      '679513': {'lat': '10.9353', 'name': 'Attasseri,Palakkad,Kerala', 'lon': '76.3748'},
      '679511': {'lat': '10.7962', 'name': 'Chunangad,Palakkad,Kerala', 'lon': '76.4033'},
      '679516': {'lat': '10.8701', 'name': 'Vengasseri,Palakkad,Kerala', 'lon': '76.4054'},
      '679514': {'lat': '10.8704', 'name': 'Kuttanasseri,Palakkad,Kerala', 'lon': '76.3958'},
      '679515': {'lat': '10.8426', 'name': 'Kadambur (Palakkad),Palakkad,Kerala', 'lon': '76.4112'},
      '364260': {'lat': '21.5738', 'name': 'Dhundsar,Bhavnagar,Gujarat', 'lon': '71.3339'},
      '805103': {'lat': '24.9422', 'name': 'Hasua,Nawada,Bihar', 'lon': '85.5808'},
      '761126': {'lat': '20.0423', 'name': 'Bhanjamargo,Ganjam,Odisha', 'lon': '84.4611'},
      '364265': {'lat': '21.5738', 'name': 'Shivendra Nagar,Bhavnagar,Gujarat', 'lon': '71.3339'},
      '222133': {'lat': '25.8142', 'name': 'Nayansand,Jaunpur,Uttar Pradesh', 'lon': '82.5803'},
      '790102': {'lat': '27.4852', 'name': 'P.Kessang,East Kameng,Arunachal Pradesh', 'lon': '92.0686'},
      '508248': {'lat': '17.0908', 'name': 'Kothapally,Nalgonda,Telangana', 'lon': '78.9773'},
      '580026': {'lat': '15.318', 'name': 'Hubli Tarihal Indl. Estate,Dharwad,Karnataka', 'lon': '75.1763'},
      '700155': {'lat': '22.7807', 'name': 'Abdalpur,North 24 Parganas,West Bengal', 'lon': '88.3001'},
      '621207': {'lat': '11.0498', 'name': 'Kidaram,Tiruchirappalli,Tamil Nadu', 'lon': '78.1784'},
      '700157': {'lat': '22.6028', 'name': 'Ghuni,North 24 Parganas,West Bengal', 'lon': '88.4493'},
      '700156': {'lat': '24.1048', 'name': 'Mahishbathan,North 24 Parganas,West Bengal', 'lon': '87.5989'},
      '303510': {'lat': '26.6053', 'name': 'Beechha,Dausa,Rajasthan', 'lon': '76.2739'},
      '303511': {'lat': '26.3189', 'name': 'Khatumbar,Dausa,Rajasthan', 'lon': '76.3411'},
      '700153': {'lat': '22.7807', 'name': 'Laskar Pur,South 24 Parganas,West Bengal', 'lon': '88.3001'},
      '621206': {'lat': '11.0732', 'name': 'Iyyampalayam,Tiruchirappalli,Tamil Nadu', 'lon': '78.5441'},
      '122413': {'lat': '28.2178', 'name': 'Dhani Chitarsain,Gurgaon,Haryana', 'lon': '76.7962'},
      '734221': {'lat': '26.9709', 'name': 'Plungdung,Darjiling,West Bengal', 'lon': '88.3462'},
      '761122': {'lat': '19.9527', 'name': 'Kamagada,Ganjam,Odisha', 'lon': '83.2094'},
      '700159': {'lat': '22.7807', 'name': 'Jyangra,North 24 Parganas,West Bengal', 'lon': '88.3001'},
      '621205': {'lat': '10.7037', 'name': 'Jayankondam,Tiruchirappalli,Tamil Nadu', 'lon': '78.4074'},
      '122414': {'lat': '28.8208', 'name': 'Syed Shahpur,Gurgaon,Haryana', 'lon': '76.7655'},
      '790106': {'lat': '27.4852', 'name': 'Gispu,Tawang,Arunachal Pradesh', 'lon': '92.0686'},
      '621204': {'lat': '10.9154', 'name': 'Amur,Tiruchirappalli,Tamil Nadu', 'lon': '78.5479'},
      '621203': {'lat': '10.9936', 'name': 'Balasamudram (Tiruchirappalli),Tiruchirappalli,Tamil Nadu', 'lon': '78.3203'},
      '785015': {'lat': '26.9371', 'name': 'Desoinagar,Jorhat,Assam', 'lon': '94.8545'},
      '785014': {'lat': '26.9371', 'name': 'Bongalpukhuri,Jorhat,Assam', 'lon': '94.8545'},
      '785013': {'lat': '26.9371', 'name': 'Assam Agriculture University,Jorhat,Assam', 'lon': '94.8545'},
      '785010': {'lat': '26.9371', 'name': 'Kalakhowa,Jorhat,Assam', 'lon': '94.8545'},
      '768052': {'lat': '21.7003', 'name': 'Dungri,Bargarh,Odisha', 'lon': '83.5622'},
      '481672': {'lat': '22.9012', 'name': 'Poundi Jhurki,Mandla,Madhya Pradesh', 'lon': '80.5718'},
      '768050': {'lat': '21.6758', 'name': 'Jamseth,Bargarh,Odisha', 'lon': '83.5792'},
      '307802': {'lat': '24.8731', 'name': 'Tanwari,Sirohi,Rajasthan', 'lon': '72.562'},
      '762030': {'lat': '20.8407', 'name': 'Rundimahula ,Boudh,Odisha', 'lon': '83.7264'},
      '621651': {'lat': '11.0091', 'name': 'Palanganatham,Ariyalur,Tamil Nadu', 'lon': '78.9204'},
      '457441': {'lat': '22.3919', 'name': 'Pritamnagar,Ratlam,Madhya Pradesh', 'lon': '75.7712'},
      '743445': {'lat': '22.399', 'name': 'Chaita,North 24 Parganas,West Bengal', 'lon': '88.0555'},
      '632604': {'lat': '12.8449', 'name': 'Sempalli,Vellore,Tamil Nadu', 'lon': '78.8909'},
      '580024': {'lat': '15.2475', 'name': 'Anchatageri,Dharwad,Karnataka', 'lon': '75.1407'},
      '147103': {'lat': '30.3018', 'name': 'Bhankher,Patiala,Punjab', 'lon': '76.4579'},
      '147102': {'lat': '29.9668', 'name': 'Sadharanpur,Patiala,Punjab', 'lon': '76.1689'},
      '364081': {'lat': '22.0572', 'name': 'Alang SBY,Bhavnagar,Gujarat', 'lon': '72.1886'},
      '788126': {'lat': '24.725', 'name': 'Singerbond,Cachar,Assam', 'lon': '92.979'},
      '805126': {'lat': '25.1284', 'name': 'Phatehpurmore,Nawada,Bihar', 'lon': '85.1091'},
      '805127': {'lat': '24.59', 'name': 'S Marighgawa,Nawada,Bihar', 'lon': '85.1542'},
      '805124': {'lat': '24.9451', 'name': 'Konadpur,Nawada,Bihar', 'lon': '85.6308'},
      '416550': {'lat': '15.9349', 'name': 'Devsu,Sindhudurg,Maharashtra', 'lon': '74.0237'},
      '743262': {'lat': '22.3578', 'name': 'Madiahat,North 24 Parganas,West Bengal', 'lon': '88.1922'},
      '743263': {'lat': '24.1383', 'name': 'Manicktala,North 24 Parganas,West Bengal', 'lon': '88.2387'},
      '797108': {'lat': '25.7675', 'name': 'Ketsapo,Phek,Nagaland', 'lon': '94.4743'},
      '797109': {'lat': '25.8908', 'name': 'Tsosesunyu,Kohima,Nagaland', 'lon': '94.2327'},
      '797106': {'lat': '25.7446', 'name': 'Agri College,Dimapur,Nagaland', 'lon': '93.9696'},
      '797107': {'lat': '25.8234', 'name': 'Thipuzumi,Phek,Nagaland', 'lon': '94.2273'},
      '695512': {'lat': '8.6873', 'name': 'Veliyamcode,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '695513': {'lat': '8.6873', 'name': 'Pozhiyoor,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '614210': {'lat': '10.9851', 'name': 'Valoothoor,Thanjavur,Tamil Nadu', 'lon': '78.9832'},
      '797103': {'lat': '25.8234', 'name': 'Tenyiphe,Dimapur,Nagaland', 'lon': '94.2273'},
      '743268': {'lat': '23.5448', 'name': 'Habra Prafulla Nagar,North 24 Parganas,West Bengal', 'lon': '88.2232'},
      '797101': {'lat': '25.8234', 'name': 'N.Song,Peren,Nagaland', 'lon': '94.2273'},
      '574226': {'lat': '12.8184', 'name': 'Mithabail,Dakshina Kannada,Karnataka', 'lon': '75.1696'},
      '574227': {'lat': '13.0761', 'name': 'Vidyagiri (Moodbidri),Dakshina Kannada,Karnataka', 'lon': '75.1017'},
      '574224': {'lat': '12.9809', 'name': 'Madanthyar,Dakshina Kannada,Karnataka', 'lon': '75.2535'},
      '574225': {'lat': '13.0161', 'name': 'Mijar,Dakshina Kannada,Karnataka', 'lon': '74.9725'},
      '574222': {'lat': '12.6458', 'name': 'Veerakamba,Dakshina Kannada,Karnataka', 'lon': '75.5862'},
      '574223': {'lat': '12.6433', 'name': 'Kanakamajal,Dakshina Kannada,Karnataka', 'lon': '75.275'},
      '574220': {'lat': '12.7946', 'name': 'Padnur,Dakshina Kannada,Karnataka', 'lon': '75.1518'},
      '574221': {'lat': '12.7267', 'name': 'Noojibalthila,Dakshina Kannada,Karnataka', 'lon': '75.4685'},
      '591173': {'lat': '15.7643', 'name': 'Yallammahill,Belagavi,Karnataka', 'lon': '75.0708'},
      '621102': {'lat': '11.3175', 'name': 'Viswagudi,Perambalur,Tamil Nadu', 'lon': '78.7888'},
      '464990': {'lat': '23.0267', 'name': 'Tamot,Raisen,Madhya Pradesh', 'lon': '77.6742'},
      '574228': {'lat': '13.0248', 'name': 'Kadirudyavara,Dakshina Kannada,Karnataka', 'lon': '75.3888'},
      '574229': {'lat': '12.834', 'name': 'Ichilampady,Dakshina Kannada,Karnataka', 'lon': '75.4451'},
      '636354': {'lat': '11.8618', 'name': 'Kanavaipudur,Salem,Tamil Nadu', 'lon': '78.1282'},
      '636351': {'lat': '11.7211', 'name': 'Umbilickampatti,Salem,Tamil Nadu', 'lon': '78.105'},
      '415019': {'lat': '18.885', 'name': 'Varne,Satara,Maharashtra', 'lon': '76.1932'},
      '636352': {'lat': '11.9068', 'name': 'T.Ganigarahalli,Dharmapuri,Tamil Nadu', 'lon': '77.9945'},
      '388560': {'lat': '21.4882', 'name': 'Khandhli,Anand,Gujarat', 'lon': '71.7182'},
      '415015': {'lat': '18.885', 'name': 'Varye,Satara,Maharashtra', 'lon': '76.1932'},
      '415014': {'lat': '18.885', 'name': 'Tondhoshi,Satara,Maharashtra', 'lon': '76.1932'},
      '415013': {'lat': '18.885', 'name': 'Thoseghar,Satara,Maharashtra', 'lon': '76.1932'},
      '415012': {'lat': '18.885', 'name': 'Bibhavi,Satara,Maharashtra', 'lon': '76.1932'},
      '415011': {'lat': '18.885', 'name': 'Wadhe,Satara,Maharashtra', 'lon': '76.1932'},
      '415010': {'lat': '17.6686', 'name': 'Dhumalwadi,Satara,Maharashtra', 'lon': '74.1738'},
      '304504': {'lat': '26.0545', 'name': 'Bheepur,Tonk,Rajasthan', 'lon': '75.2166'},
      '456770': {'lat': '23.3585', 'name': 'Nainawad,Ujjain,Madhya Pradesh', 'lon': '76.1644'},
      '394690': {'lat': '21.0892', 'name': 'Borkhadi,Surat,Gujarat', 'lon': '73.2795'},
      '621103': {'lat': '11.3647', 'name': 'Thaluthalai,Perambalur,Tamil Nadu', 'lon': '78.7245'},
      '207122': {'lat': '27.5665', 'name': 'Gahaitoo,Etah,Uttar Pradesh', 'lon': '78.4655'},
      '505209': {'lat': '18.7538', 'name': 'Maidpalli,Karim Nagar,Telangana', 'lon': '79.4518'},
      '328028': {'lat': '26.8099', 'name': 'Visnoda,Dholpur,Rajasthan', 'lon': '77.7815'},
      '207123': {'lat': '27.5121', 'name': 'Nadrai,Etah,Uttar Pradesh', 'lon': '78.7132'},
      '673323': {'lat': '11.4502', 'name': 'Kunnathara,Kozhikode,Kerala', 'lon': '75.7635'},
      '328027': {'lat': '26.7952', 'name': 'Kenthari,Dholpur,Rajasthan', 'lon': '77.4554'},
      '451770': {'lat': '21.7505', 'name': 'Niwali,Barwani,Madhya Pradesh', 'lon': '74.9294'},
      '580025': {'lat': '15.318', 'name': 'Amragol,Dharwad,Karnataka', 'lon': '75.1763'},
      '460447': {'lat': '22.1032', 'name': 'Sarni,Betul,Madhya Pradesh', 'lon': '78.1716'},
      '328026': {'lat': '26.4793', 'name': 'Sarmathura,Dholpur,Rajasthan', 'lon': '77.3596'},
      '805108': {'lat': '25.1894', 'name': 'Apsai,Nawada,Bihar', 'lon': '84.9684'},
      '638402': {'lat': '11.1756', 'name': 'Sundakkampalayam,Coimbatore,Tamil Nadu', 'lon': '77.9472'},
      '638401': {'lat': '11.5589', 'name': 'Sathy Bazaar,Erode,Tamil Nadu', 'lon': '77.2679'},
      '673328': {'lat': '11.6409', 'name': 'Mannur (Kozhikode),Kozhikode,Kerala', 'lon': '75.7771'},
      '328025': {'lat': '26.7199', 'name': 'Nadoli,Dholpur,Rajasthan', 'lon': '77.6155'},
      '360410': {'lat': '22.1841', 'name': 'Bhader,Rajkot,Gujarat', 'lon': '71.5304'},
      '144621': {'lat': '31.6389', 'name': 'Akbarpur,Kapurthala,Punjab', 'lon': '75.5336'},
      '328024': {'lat': '26.654', 'name': 'Bagcholi Khar,Dholpur,Rajasthan', 'lon': '77.7317'},
      '506015': {'lat': '17.5325', 'name': 'K C Colony,Warangal,Telangana', 'lon': '79.0467'},
      '637018': {'lat': '11.6463', 'name': 'Kalyanni,Namakkal,Tamil Nadu', 'lon': '78.3819'},
      '328023': {'lat': '26.8762', 'name': 'Kurenda,Dholpur,Rajasthan', 'lon': '77.8003'},
      '733208': {'lat': '26.0528', 'name': 'Chapore,North Dinajpur,West Bengal', 'lon': '87.9738'},
      '144623': {'lat': '31.2364', 'name': 'Nijjran,Jalandhar,Punjab', 'lon': '75.416'},
      '143204': {'lat': '31.5764', 'name': 'Bal Sarai,Amritsar,Punjab', 'lon': '75.3312'},
      '328022': {'lat': '26.8168', 'name': 'Ekta,Dholpur,Rajasthan', 'lon': '77.6513'},
      '752045': {'lat': '20.0664', 'name': 'Lalitapahandi,Puri,Odisha', 'lon': '85.8329'},
      '752046': {'lat': '20.003', 'name': 'Kothasahi,Puri,Odisha', 'lon': '85.8064'},
      '144622': {'lat': '31.5864', 'name': 'Bhadas,Kapurthala,Punjab', 'lon': '75.5087'},
      '402402': {'lat': '18.1677117', 'name': 'Dighi,Raigarh(MH),Maharashtra', 'lon': '73.0030633'},
      '805109': {'lat': '24.9011', 'name': 'Kosla,Nawada,Bihar', 'lon': '85.4419'},
      '741254': {'lat': '22.941', 'name': 'Kamgachi,Nadia,West Bengal', 'lon': '88.637'},
      '144625': {'lat': '31.2138', 'name': 'Sidhwan Dona,Kapurthala,Punjab', 'lon': '75.3322'},
      '484114': {'lat': '23.0912', 'name': 'Dhanpuri Colliery,Shahdol,Madhya Pradesh', 'lon': '80.1484'},
      '742136': {'lat': '24.0589', 'name': 'Gobor Hati,Murshidabad,West Bengal', 'lon': '88.12'},
      '456776': {'lat': '23.1575', 'name': 'Khedavada,Ujjain,Madhya Pradesh', 'lon': '75.2629'},
      '144624': {'lat': '31.3844', 'name': 'Raipur Arryan,Kapurthala,Punjab', 'lon': '75.3578'},
      '144104': {'lat': '31.4068', 'name': 'Daroli Kalan,Jalandhar,Punjab', 'lon': '75.6522'},
      '144105': {'lat': '31.3421', 'name': 'Badla Mahi,Hoshiarpur,Punjab', 'lon': '75.698'},
      '144106': {'lat': '31.4735', 'name': 'Adda Kathar,Jalandhar,Punjab', 'lon': '75.7575'},
      '741256': {'lat': '22.941', 'name': 'Ranaghat Baidyapur,Nadia,West Bengal', 'lon': '88.637'},
      '848204': {'lat': '25.6861', 'name': 'Kumharson,Begusarai,Bihar', 'lon': '86.15'},
      '756118': {'lat': '21.1165', 'name': 'Ambiligaon,Bhadrak,Odisha', 'lon': '86.6852'},
      '144102': {'lat': '31.3875', 'name': 'Khurdpur,Jalandhar,Punjab', 'lon': '75.2455'},
      '144103': {'lat': '31.422', 'name': 'Adampur AD,Jalandhar,Punjab', 'lon': '75.7564'},
      '848208': {'lat': '25.7578', 'name': 'Geeta Deodha,Samastipur,Bihar', 'lon': '86.1394'},
      '741251': {'lat': '22.941', 'name': 'Charsarhati,Nadia,West Bengal', 'lon': '88.637'},
      '585416': {'lat': '17.4125', 'name': 'Wanjarkhed,Bidar,Karnataka', 'lon': '76.8563'},
      '523190': {'lat': '15.9035', 'name': 'Idupulapadu,Prakasam,Andhra Pradesh', 'lon': '80.1961'},
      '632404': {'lat': '12.9377', 'name': 'Karai,Vellore,Tamil Nadu', 'lon': '79.3163'},
      '184203': {'lat': '32.5732', 'name': 'Dharalta,Kathua,Jammu & Kashmir', 'lon': '75.5683'},
      '184202': {'lat': '32.5151', 'name': 'Dhar Mahanpur,Kathua,Jammu & Kashmir', 'lon': '75.0823'},
      '184201': {'lat': '32.9338', 'name': 'Sandhar,Kathua,Jammu & Kashmir', 'lon': '75.2018'},
      '402403': {'lat': '18.1698962', 'name': 'Wadwali,Raigarh(MH),Maharashtra', 'lon': '73.0080436'},
      '184206': {'lat': '32.9706', 'name': 'Bandhar,Kathua,Jammu & Kashmir', 'lon': '75.0191'},
      '184205': {'lat': '32.7087', 'name': 'Dhamma,Udhampur,Jammu & Kashmir', 'lon': '75.2113'},
      '184204': {'lat': '32.6449', 'name': 'Huttar,Kathua,Jammu & Kashmir', 'lon': '75.5197'},
      '462016': {'lat': '24.0875', 'name': 'Shivaji Nagar (Bhopal),Bhopal,Madhya Pradesh', 'lon': '79.7572'},
      '636139': {'lat': '11.7342', 'name': 'Kolathukombai,Salem,Tamil Nadu', 'lon': '78.328'},
      '482011': {'lat': '22.7529', 'name': 'Jabalpur Factory,Jabalpur,Madhya Pradesh', 'lon': '79.6787'},
      '637013': {'lat': '11.3163', 'name': 'Pudukottai,Namakkal,Tamil Nadu', 'lon': '78.1361'},
      '629252': {'lat': '8.1478', 'name': 'Mondaikad,Kanyakumari,Tamil Nadu', 'lon': '77.3055'},
      '636138': {'lat': '10.5859', 'name': 'Koil Puthur,Salem,Tamil Nadu', 'lon': '79.6244'},
      '335041': {'lat': '29.6786', 'name': 'Sanwatsar,Ganganagar,Rajasthan', 'lon': '73.7069'},
      '335040': {'lat': '26.3209', 'name': 'Kharlan,Ganganagar,Rajasthan', 'lon': '74.735'},
      '676320': {'lat': '10.9921', 'name': 'Tayyalingal,Malappuram,Kerala', 'lon': '75.908'},
      '560065': {'lat': '12.9881', 'name': 'G.K.V.K.,Bengaluru,Karnataka', 'lon': '77.5052'},
      '224141': {'lat': '26.4212', 'name': 'Chandrikaganj,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4402'},
      '244601': {'lat': '29.0158', 'name': 'Nanhuwala,Moradabad,Uttar Pradesh', 'lon': '78.6966'},
      '224147': {'lat': '26.4171', 'name': 'Madainiya,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.9365'},
      '224146': {'lat': '26.3638', 'name': 'Saidpur Bhitari,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5504'},
      '209401': {'lat': '26.2843', 'name': 'Semarjhal,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3637'},
      '756113': {'lat': '20.5697', 'name': 'Alauti,Bhadrak,Odisha', 'lon': '85.8259'},
      '402404': {'lat': '18.1593387', 'name': 'Nagloli,Raigarh(MH),Maharashtra', 'lon': '72.9999833'},
      '722150': {'lat': '22.7619', 'name': 'Moukura,Bankura,West Bengal', 'lon': '87.0212'},
      '482010': {'lat': '23.2158', 'name': 'Richhai,Jabalpur,Madhya Pradesh', 'lon': '79.779'},
      '560066': {'lat': '12.9716', 'name': 'EPIP,Bengaluru,Karnataka', 'lon': '77.7473'},
      '757038': {'lat': '21.5016', 'name': 'Baunsdiha,Mayurbhanj,Odisha', 'lon': '85.9803'},
      '400088': {'lat': '19.0333', 'name': 'T.F.Donar,Mumbai,Maharashtra', 'lon': '72.9333'},
      '643236': {'lat': '11.4144', 'name': 'Hettakal,Nilgiris,Tamil Nadu', 'lon': '76.9277'},
      '394150': {'lat': '21.7171', 'name': 'Velanja,Surat,Gujarat', 'lon': '72.9538'},
      '757039': {'lat': '21.4334', 'name': 'Kumbhirda,Mayurbhanj,Odisha', 'lon': '85.4565'},
      '643233': {'lat': '11.4908', 'name': 'Pandalur,Nilgiris,Tamil Nadu', 'lon': '76.3392'},
      '212507': {'lat': '25.6728', 'name': 'Tejpur,Allahabad,Uttar Pradesh', 'lon': '81.9797'},
      '643231': {'lat': '11.3655', 'name': 'Berhatti,Nilgiris,Tamil Nadu', 'lon': '76.7844'},
      '643238': {'lat': '11.4236', 'name': 'Nonsuch,Nilgiris,Tamil Nadu', 'lon': '76.6838'},
      '643239': {'lat': '11.4236', 'name': 'Munnanad GDS,Nilgiris,Tamil Nadu', 'lon': '76.6838'},
      '141112': {'lat': '31.1555', 'name': 'Bhairro Munna,Ludhiana,Punjab', 'lon': '75.9107'},
      '734011': {'lat': '26.7111', 'name': 'Kadamtala (Darjiling),Darjiling,West Bengal', 'lon': '88.3462'},
      '385330': {'lat': '23.7464', 'name': 'Deodar,Banaskantha,Gujarat', 'lon': '72.6444'},
      '506348': {'lat': '17.7739', 'name': 'Gangireni Gudem,Warangal,Telangana', 'lon': '79.3602'},
      '523274': {'lat': '15.3221', 'name': 'Velagapudi,Prakasam,Andhra Pradesh', 'lon': '80.0652'},
      '678573': {'lat': '10.7085', 'name': 'Chennangad,Palakkad,Kerala', 'lon': '76.4722'},
      '328021': {'lat': '27.0625', 'name': 'Bari Rs,Dholpur,Rajasthan', 'lon': '77.211'},
      '532221': {'lat': '18.6929', 'name': 'Rentikota,Srikakulam,Andhra Pradesh', 'lon': '84.2872'},
      '621706': {'lat': '10.5997', 'name': 'Sirumayankudi,Tiruchirappalli,Tamil Nadu', 'lon': '78.6737'},
      '535502': {'lat': '18.8043', 'name': 'Seetharampuram,Vizianagaram,Andhra Pradesh', 'lon': '83.4677'},
      '576210': {'lat': '13.4775', 'name': 'Shiriyara,Udupi,Karnataka', 'lon': '74.7545'},
      '521325': {'lat': '16.8797', 'name': 'Komarru,Krishna,Andhra Pradesh', 'lon': '80.5984'},
      '521324': {'lat': '16.4193', 'name': 'Matlam,Krishna,Andhra Pradesh', 'lon': '81.3967'},
      '521327': {'lat': '16.4588', 'name': 'Lingavaram,Krishna,Andhra Pradesh', 'lon': '80.9971'},
      '521326': {'lat': '16.5386', 'name': 'Putlacheruvu,Krishna,Andhra Pradesh', 'lon': '81.1158'},
      '521321': {'lat': '16.5148', 'name': 'Pedavirivada,Krishna,Andhra Pradesh', 'lon': '81.0478'},
      '521323': {'lat': '16.3485', 'name': 'Guntakoduru,Krishna,Andhra Pradesh', 'lon': '80.5811'},
      '521322': {'lat': '16.3804', 'name': 'Gandhi Asramam,Krishna,Andhra Pradesh', 'lon': '80.9783'},
      '576212': {'lat': '13.5055', 'name': 'Belve,Udupi,Karnataka', 'lon': '75.0199'},
      '521329': {'lat': '16.1495', 'name': 'Singarayapalem,Krishna,Andhra Pradesh', 'lon': '80.5637'},
      '521328': {'lat': '16.2677', 'name': 'Ullipalem,Krishna,Andhra Pradesh', 'lon': '80.9827'},
      '443001': {'lat': '20.3394', 'name': 'Buldana H.O,Buldhana,Maharashtra', 'lon': '75.9568'},
      '324001': {'lat': '25.0237', 'name': 'Subhash Marg Kota,Kota,Rajasthan', 'lon': '75.6957'},
      '852215': {'lat': '26.2477', 'name': 'Baisa,Supaul,Bihar', 'lon': '86.8001'},
      '324003': {'lat': '25.0136', 'name': 'Industerial Estate Kota,Kota,Rajasthan', 'lon': '76.0587'},
      '324002': {'lat': '24.4084', 'name': 'Sogaria,Kota,Rajasthan', 'lon': '76.7433'},
      '324005': {'lat': '25.0136', 'name': 'Mahaveer Nagar Kota,Kota,Rajasthan', 'lon': '76.0587'},
      '324004': {'lat': '25.0136', 'name': 'Udyog Puri Kota,Kota,Rajasthan', 'lon': '76.0587'},
      '324007': {'lat': '25.1901', 'name': 'New Grain Mandi Kota H.O,Kota,Rajasthan', 'lon': '76.1223'},
      '324006': {'lat': '25.1825', 'name': 'Kaithuni Pole Kota,Kota,Rajasthan', 'lon': '75.8391'},
      '324009': {'lat': '25.0136', 'name': 'Dadabari Kota,Kota,Rajasthan', 'lon': '76.0587'},
      '324008': {'lat': '25.1384', 'name': 'Thermal Colony Kota,Kota,Rajasthan', 'lon': '75.976'},
      '852218': {'lat': '25.9087', 'name': 'Bheropatti,Madhepura,Bihar', 'lon': '86.5171'},
      '852219': {'lat': '25.5474', 'name': 'N. Bhagipur,Madhepura,Bihar', 'lon': '86.9086'},
      '110075': {'lat': '28.5458', 'name': 'Dwarka Sec-6,South West Delhi,Delhi', 'lon': '77.0784'},
      '110074': {'lat': '28.4672', 'name': 'Sawan Public School,South West Delhi,Delhi', 'lon': '77.1793'},
      '110077': {'lat': '28.5458', 'name': 'Dhulsiras,South West Delhi,Delhi', 'lon': '77.0784'},
      '110076': {'lat': '28.5339', 'name': 'Madanpur Khadar,South Delhi,Delhi', 'lon': '77.3025'},
      '110071': {'lat': '28.5458', 'name': 'Chhawla,South West Delhi,Delhi', 'lon': '77.0784'},
      '110070': {'lat': '28.5439', 'name': 'Masood Pur,South West Delhi,Delhi', 'lon': '77.1523'},
      '110073': {'lat': '28.5563', 'name': 'Mundela Kalan,South West Delhi,Delhi', 'lon': '76.8963'},
      '110072': {'lat': '28.6526', 'name': 'CRPF Jharoda Kalan,South West Delhi,Delhi', 'lon': '76.9521'},
      '721653': {'lat': '24.0696', 'name': 'Salikagarchak,East Midnapore,West Bengal', 'lon': '87.6294'},
      '388130': {'lat': '23.1936', 'name': 'Ashi,Anand,Gujarat', 'lon': '73.1101'},
      '283125': {'lat': '27.0564', 'name': 'Garhi Lal Kalan,Agra,Uttar Pradesh', 'lon': '78.0946'},
      '110078': {'lat': '28.6081', 'name': 'N.S.I.T. Dwarka,South West Delhi,Delhi', 'lon': '77.0193'},
      '148100': {'lat': '30.5471', 'name': 'Bakhatgarh,Barnala,Punjab', 'lon': '75.4194'},
      '185151': {'lat': '33.2182', 'name': 'Narian,Rajauri,Jammu & Kashmir', 'lon': '74.2883'},
      '185152': {'lat': '33.4478', 'name': 'Garan,Rajauri,Jammu & Kashmir', 'lon': '74.2479'},
      '185153': {'lat': '33.3986', 'name': 'Rahsiyot,Rajauri,Jammu & Kashmir', 'lon': '74.2566'},
      '185154': {'lat': '33.3614', 'name': 'Sadhoti,Jammu,Jammu & Kashmir', 'lon': '74.3335'},
      '185155': {'lat': '33.4314', 'name': 'Sehar,Rajauri,Jammu & Kashmir', 'lon': '74.2297'},
      '185156': {'lat': '33.2668', 'name': 'Tilla Tanda,Rajauri,Jammu & Kashmir', 'lon': '74.3077'},
      '148107': {'lat': '30.2856', 'name': 'Khudi  Kalan,Barnala,Punjab', 'lon': '75.4602'},
      '148108': {'lat': '30.33', 'name': 'Ghunas,Barnala,Punjab', 'lon': '75.3877'},
      '148109': {'lat': '30.4078', 'name': 'Wazid Ke Khurd,Barnala,Punjab', 'lon': '75.5864'},
      '273001': {'lat': '26.7666', 'name': 'Kazipur Kalan,Gorakhpur,Uttar Pradesh', 'lon': '83.3825'},
      '636012': {'lat': '11.9792', 'name': 'Moongilpadi,Salem,Tamil Nadu', 'lon': '78.2917'},
      '515341': {'lat': '13.7686', 'name': 'Tekulodu,Ananthapur,Andhra Pradesh', 'lon': '77.6812'},
      '623315': {'lat': '9.8661', 'name': 'Pananchayal,Ramanathapuram,Tamil Nadu', 'lon': '78.936'},
      '202281': {'lat': '28.0856', 'name': 'J D Singh,Aligarh,Uttar Pradesh', 'lon': '78.3499'},
      '670003': {'lat': '11.8675', 'name': 'Katalayi,Kannur,Kerala', 'lon': '75.3576'},
      '670002': {'lat': '11.9326', 'name': 'Civil Station Kannur,Kannur,Kerala', 'lon': '75.3538'},
      '670001': {'lat': '11.9326', 'name': 'Kannur Railway Station,Kannur,Kerala', 'lon': '75.3538'},
      '621705': {'lat': '11.1513', 'name': 'Kallankurichi,Ariyalur,Tamil Nadu', 'lon': '79.1189'},
      '670007': {'lat': '11.8385', 'name': 'Thottada,Kannur,Kerala', 'lon': '75.4208'},
      '670006': {'lat': '11.8636', 'name': 'Attadappa,Kannur,Kerala', 'lon': '75.4352'},
      '670005': {'lat': '12.2886', 'name': 'Kottali,Kannur,Kerala', 'lon': '75.1413'},
      '670004': {'lat': '11.8899', 'name': 'Pallikunnu,Kannur,Kerala', 'lon': '75.3565'},
      '670009': {'lat': '11.9175', 'name': 'Azhikode South,Kannur,Kerala', 'lon': '75.3416'},
      '670008': {'lat': '11.9326', 'name': 'Alavil,Kannur,Kerala', 'lon': '75.3538'},
      '210422': {'lat': '25.7948', 'name': 'Chhandaut,Hamirpur,Uttar Pradesh', 'lon': '79.592'},
      '431202': {'lat': '20.0215', 'name': 'Badnapur,Aurangabad,Maharashtra', 'lon': '75.2735'},
      '362225': {'lat': '23.985', 'name': 'Husenabad,Junagadh,Gujarat', 'lon': '72.4621'},
      '431204': {'lat': '19.4692', 'name': 'Hastapokhari,Jalna,Maharashtra', 'lon': '75.5492'},
      '431205': {'lat': '19.4995', 'name': 'Sukhapuri,Jalna,Maharashtra', 'lon': '75.7488'},
      '431206': {'lat': '19.8182', 'name': 'Bharaj,Jalna,Maharashtra', 'lon': '75.9819'},
      '431207': {'lat': '19.6436', 'name': 'Hivra Roshangaon,Jalna,Maharashtra', 'lon': '76.0835'},
      '431208': {'lat': '20.112', 'name': 'Hivra  Kabli,Jalna,Maharashtra', 'lon': '75.9866'},
      '431209': {'lat': '19.4886', 'name': 'Ramasgaon,Jalna,Maharashtra', 'lon': '75.9917'},
      '494226': {'lat': '20.343', 'name': 'Kibaibalenga,Bastar,Chattisgarh', 'lon': '81.7932'},
      '362229': {'lat': '21.2644', 'name': 'Badodar,Junagadh,Gujarat', 'lon': '70.3551'},
      '382305': {'lat': '22.5137', 'name': 'Bardoli-koti,Gandhi Nagar,Gujarat', 'lon': '72.8672'},
      '494223': {'lat': '19.6912', 'name': 'Rotma,Bastar,Chattisgarh', 'lon': '81.61'},
      '612301': {'lat': '10.9756', 'name': 'Adanur,Thanjavur,Tamil Nadu', 'lon': '79.3114'},
      '612302': {'lat': '10.9569', 'name': 'Gangadarapuram,Thanjavur,Tamil Nadu', 'lon': '79.3385'},
      '612303': {'lat': '10.981', 'name': 'Eraharam,Thanjavur,Tamil Nadu', 'lon': '79.338'},
      '636010': {'lat': '11.6741', 'name': 'Sandaipettai,Salem,Tamil Nadu', 'lon': '78.2221'},
      '362222': {'lat': '22.4718', 'name': 'Tinmas,Junagadh,Gujarat', 'lon': '71.0883'},
      '490023': {'lat': '21.2367', 'name': 'Vaishali Nagar Bhilai,Durg,Chattisgarh', 'lon': '81.3973'},
      '362220': {'lat': '21.3364', 'name': 'Khamidana,Junagadh,Gujarat', 'lon': '70.1737'},
      '362227': {'lat': '22.9236', 'name': 'Sondarda,Junagadh,Gujarat', 'lon': '70.9031'},
      '362226': {'lat': '22.4718', 'name': 'Mangrol Bundar,Junagadh,Gujarat', 'lon': '71.0883'},
      '382308': {'lat': '22.9215', 'name': 'Kanipur,Gandhi Nagar,Gujarat', 'lon': '73.5882'},
      '490026': {'lat': '21.2367', 'name': 'I E Bhilai,Durg,Chattisgarh', 'lon': '81.3973'},
      '229316': {'lat': '26.2288', 'name': 'Sarawan,Raebareli,Uttar Pradesh', 'lon': '81.2314'},
      '585236': {'lat': '17.2505', 'name': 'Madan Hipparga,Kalaburagi,Karnataka', 'lon': '76.1487'},
      '641049': {'lat': '10.9111', 'name': 'Chinnavedampatti,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '124303': {'lat': '29.0038', 'name': 'Chamaria,Rohtak,Haryana', 'lon': '76.6257'},
      '141118': {'lat': '30.7667', 'name': 'Saya Kalan,Ludhiana,Punjab', 'lon': '75.8448'},
      '743194': {'lat': '22.8827', 'name': 'Kelabagan,North 24 Parganas,West Bengal', 'lon': '88.4194'},
      '845101': {'lat': '27.0792', 'name': 'Sirisia,West Champaran,Bihar', 'lon': '84.3412'},
      '396195': {'lat': '21.7371', 'name': 'Chanod Md,Valsad,Gujarat', 'lon': '73.4941'},
      '396193': {'lat': '23.0929', 'name': 'Nani Tambadi,Valsad,Gujarat', 'lon': '73.9679'},
      '396191': {'lat': '20.3812', 'name': 'Vapi,Valsad,Gujarat', 'lon': '73.0203'},
      '624215': {'lat': '10.798', 'name': 'Nariyuthu,Dindigul,Tamil Nadu', 'lon': '78.163'},
      '271126': {'lat': '27.0916', 'name': 'Sarwangpurwa,Gonda,Uttar Pradesh', 'lon': '81.7989'},
      '141119': {'lat': '30.472', 'name': 'Sihan Daud,Ludhiana,Punjab', 'lon': '76.1022'},
      '210128': {'lat': '25.6988', 'name': 'Benda,Banda,Uttar Pradesh', 'lon': '80.6252'},
      '700154': {'lat': '22.7807', 'name': 'Rania,South 24 Parganas,West Bengal', 'lon': '88.3001'},
      '722155': {'lat': '23.5835', 'name': 'Sendra,Bankura,West Bengal', 'lon': '87.3385'},
      '722154': {'lat': '22.919', 'name': 'Bansichandipur,Bankura,West Bengal', 'lon': '87.8555'},
      '722157': {'lat': '23.2279', 'name': 'Ichharia,Bankura,West Bengal', 'lon': '87.2881'},
      '722156': {'lat': '22.9655', 'name': 'Mandalgram,Bankura,West Bengal', 'lon': '87.1613'},
      '722151': {'lat': '22.9131', 'name': 'Simlapal,Bankura,West Bengal', 'lon': '87.0718'},
      '152112': {'lat': '30.2262', 'name': 'Bhallerian,Muktsar,Punjab', 'lon': '74.4284'},
      '722153': {'lat': '23.1554', 'name': 'Bamuntore,Bankura,West Bengal', 'lon': '87.3394'},
      '722152': {'lat': '23.2608', 'name': 'Nachna,Bankura,West Bengal', 'lon': '87.0361'},
      '532185': {'lat': '18.4049', 'name': 'Amadalavalasa H.O,Srikakulam,Andhra Pradesh', 'lon': '83.908'},
      '323001': {'lat': '25.4137', 'name': 'Matunda,Bundi,Rajasthan', 'lon': '75.7627'},
      '532186': {'lat': '18.4049', 'name': 'Opivada Venkampeta,Srikakulam,Andhra Pradesh', 'lon': '83.908'},
      '673661': {'lat': '11.4709', 'name': 'Kayal,Kozhikode,Kerala', 'lon': '76.0049'},
      '387370': {'lat': '23.9069', 'name': 'Uttarsanda,Kheda,Gujarat', 'lon': '73.0218'},
      '326529': {'lat': '24.6596', 'name': 'Khairabad (Kota),Kota,Rajasthan', 'lon': '75.9307'},
      '387375': {'lat': '23.9069', 'name': 'Ravli,Kheda,Gujarat', 'lon': '73.0218'},
      '152115': {'lat': '30.0734', 'name': 'Mahni Kherea,Muktsar,Punjab', 'lon': '74.4317'},
      '203409': {'lat': '28.4526', 'name': 'Utrouly,Bulandshahr,Uttar Pradesh', 'lon': '77.9452'},
      '481668': {'lat': '22.3856', 'name': 'Bijegaon Colony,Mandla,Madhya Pradesh', 'lon': '80.1827'},
      '625103': {'lat': '9.9063', 'name': 'Valaicheripatti,Madurai,Tamil Nadu', 'lon': '78.2182'},
      '571201': {'lat': '12.6332', 'name': 'Madikeri DOC,Kodagu,Karnataka', 'lon': '75.655'},
      '743426': {'lat': '22.5455', 'name': 'Ghoni,North 24 Parganas,West Bengal', 'lon': '88.9366'},
      '272126': {'lat': '26.8723', 'name': 'Babhni Misra,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.8998'},
      '481663': {'lat': '22.723', 'name': 'Munu,Mandla,Madhya Pradesh', 'lon': '80.5552'},
      '152117': {'lat': '30.2222', 'name': 'Bahawal Bassi,Firozpur,Punjab', 'lon': '74.3215'},
      '481665': {'lat': '22.6003', 'name': 'Badhar,Balaghat,Madhya Pradesh', 'lon': '80.3535'},
      '481664': {'lat': '22.8576', 'name': 'Ramhepur,Mandla,Madhya Pradesh', 'lon': '80.4719'},
      '203407': {'lat': '28.544', 'name': 'Mundi  Bakapur,Bulandshahr,Uttar Pradesh', 'lon': '77.9507'},
      '421002': {'lat': '19.3148', 'name': 'Ulhasnagar-2,Thane,Maharashtra', 'lon': '73.7854'},
      '682309': {'lat': '10.2263', 'name': 'Irimbanam,Ernakulam,Kerala', 'lon': '76.2324'},
      '682308': {'lat': '9.9773', 'name': 'Ezhakkaranadu South,Ernakulam,Kerala', 'lon': '76.4105'},
      '224202': {'lat': '26.66', 'name': 'Kailkeshopur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.1358'},
      '676528': {'lat': '11.1321', 'name': 'Mattathur,Malappuram,Kerala', 'lon': '76.1558'},
      '682301': {'lat': '10.2263', 'name': 'Tripunithura Fort,Ernakulam,Kerala', 'lon': '76.2324'},
      '682303': {'lat': '10.2263', 'name': 'Karimugal,Ernakulam,Kerala', 'lon': '76.2324'},
      '682302': {'lat': '10.2263', 'name': 'Ambalamugal,Ernakulam,Kerala', 'lon': '76.2324'},
      '682305': {'lat': '10.0509', 'name': 'Kokkappilly,Ernakulam,Kerala', 'lon': '76.2833'},
      '682304': {'lat': '9.9365', 'name': 'Maradu,Ernakulam,Kerala', 'lon': '76.3238'},
      '682307': {'lat': '10.5833', 'name': 'Poothotta,Ernakulam,Kerala', 'lon': '76.0723'},
      '682306': {'lat': '10.2263', 'name': 'Eroor West,Ernakulam,Kerala', 'lon': '76.2324'},
      '757021': {'lat': '21.278', 'name': 'Kanimahuli,Mayurbhanj,Odisha', 'lon': '85.1591'},
      '757020': {'lat': '21.7785', 'name': 'Baladia,Mayurbhanj,Odisha', 'lon': '85.8314'},
      '757023': {'lat': '21.4468', 'name': 'Keshrasole,Mayurbhanj,Odisha', 'lon': '85.677'},
      '757022': {'lat': '21.2788', 'name': 'Saradiha,Mayurbhanj,Odisha', 'lon': '85.9936'},
      '757025': {'lat': '21.6147', 'name': 'Sakua,Mayurbhanj,Odisha', 'lon': '85.8161'},
      '757024': {'lat': '21.0258', 'name': 'Paikabasa,Mayurbhanj,Odisha', 'lon': '85.1116'},
      '757027': {'lat': '21.621', 'name': 'Sikidia,Mayurbhanj,Odisha', 'lon': '86.7428'},
      '757026': {'lat': '20.9794', 'name': 'Gendagadia,Mayurbhanj,Odisha', 'lon': '84.912'},
      '757029': {'lat': '21.719', 'name': 'Budhikhamari,Mayurbhanj,Odisha', 'lon': '85.2604'},
      '757028': {'lat': '21.8201', 'name': 'Khuntapur,Mayurbhanj,Odisha', 'lon': '86.4515'},
      '636102': {'lat': '12.0509', 'name': 'Aturbazaar,Salem,Tamil Nadu', 'lon': '79.7159'},
      '636103': {'lat': '11.6922', 'name': 'Ayodhiyapattinam,Salem,Tamil Nadu', 'lon': '78.26'},
      '636104': {'lat': '11.6737', 'name': 'Aranoothumalai,Salem,Tamil Nadu', 'lon': '78.4582'},
      '636105': {'lat': '11.4708', 'name': 'Naripadi,Salem,Tamil Nadu', 'lon': '78.6313'},
      '636106': {'lat': '11.5317', 'name': 'S.N.Mangalam,Salem,Tamil Nadu', 'lon': '78.4718'},
      '636107': {'lat': '11.5801', 'name': 'Seeliampatti,Salem,Tamil Nadu', 'lon': '78.4724'},
      '624212': {'lat': '10.7686', 'name': 'Pandrimalai,Dindigul,Tamil Nadu', 'lon': '77.9189'},
      '473101': {'lat': '24.348', 'name': 'Sehrog,Guna,Madhya Pradesh', 'lon': '77.5222'},
      '721126': {'lat': '24.1448', 'name': 'Chaklahana,West Midnapore,West Bengal', 'lon': '87.768'},
      '678582': {'lat': '10.4755', 'name': 'Mannarkkad,Palakkad,Kerala', 'lon': '76.4598'},
      '678583': {'lat': '11.1388', 'name': 'Kodakkad,Palakkad,Kerala', 'lon': '76.2347'},
      '799251': {'lat': '24.0447', 'name': 'Raghna,North Tripura,Tripura', 'lon': '91.7553'},
      '678581': {'lat': '10.6687', 'name': 'Kavundikkal,Palakkad,Kerala', 'lon': '76.7696'},
      '534176': {'lat': '16.7717', 'name': 'Cheepurugudem,West Godavari,Andhra Pradesh', 'lon': '81.4423'},
      '781013': {'lat': '26.1577', 'name': 'Jalukbari,Kamrup,Assam', 'lon': '91.7031'},
      '781012': {'lat': '26.1691', 'name': 'Dehangarigaon,Kamrup,Assam', 'lon': '91.6771'},
      '781011': {'lat': '26.1577', 'name': 'Gotanagar,Kamrup,Assam', 'lon': '91.7031'},
      '781010': {'lat': '26.118', 'name': 'Kamakhya,Kamrup,Assam', 'lon': '91.4061'},
      '781017': {'lat': '26.1495', 'name': 'Azara,Kamrup,Assam', 'lon': '91.642'},
      '781016': {'lat': '26.1577', 'name': 'Gopinathnagar,Kamrup,Assam', 'lon': '91.7031'},
      '781015': {'lat': '26.1577', 'name': 'Bongra,Kamrup,Assam', 'lon': '91.7031'},
      '781014': {'lat': '26.1577', 'name': 'Guwahati University H.O,Kamrup,Assam', 'lon': '91.7031'},
      '152107': {'lat': '30.218', 'name': 'Faqarsar,Muktsar,Punjab', 'lon': '74.4611'},
      '781019': {'lat': '26.118', 'name': 'Kahilipara,Kamrup,Assam', 'lon': '91.4061'},
      '781018': {'lat': '26.1577', 'name': 'Binovanagar,Kamrup,Assam', 'lon': '91.7031'},
      '507137': {'lat': '17.8067', 'name': 'Peddakamalapuram,Khammam,Telangana', 'lon': '80.7926'},
      '203129': {'lat': '28.1872', 'name': 'Sabitgarh,Bulandshahr,Uttar Pradesh', 'lon': '78.0037'},
      '152101': {'lat': '30.2457', 'name': 'Ghagga,Muktsar,Punjab', 'lon': '74.5788'},
      '342308': {'lat': '26.9821', 'name': 'Denok,Jodhpur,Rajasthan', 'lon': '72.2059'},
      '391165': {'lat': '22.6922', 'name': 'Khadkhad,Vadodara,Gujarat', 'lon': '73.9032'},
      '302029': {'lat': '26.8218', 'name': 'Lakhana,Jaipur,Rajasthan', 'lon': '75.6987'},
      '391160': {'lat': '23.1577', 'name': 'Harakhapur,Vadodara,Gujarat', 'lon': '73.4948'},
      '342302': {'lat': '26.9044', 'name': 'Lohawat Jatabas,Jodhpur,Rajasthan', 'lon': '72.6027'},
      '342303': {'lat': '26.8824', 'name': 'Khabra Khurd,Jodhpur,Rajasthan', 'lon': '72.1943'},
      '302028': {'lat': '27.0449', 'name': 'Bhanpur Kalan,Jaipur,Rajasthan', 'lon': '75.9218'},
      '342306': {'lat': '26.4847', 'name': 'Indroka,Jodhpur,Rajasthan', 'lon': '73.0261'},
      '342307': {'lat': '27.4121', 'name': 'Malamsingh Ki Sidh,Jodhpur,Rajasthan', 'lon': '72.2333'},
      '391168': {'lat': '22.2095', 'name': 'Rangpur (K),Vadodara,Gujarat', 'lon': '74.0194'},
      '342305': {'lat': '26.4865', 'name': 'Umed Nagar,Jodhpur,Rajasthan', 'lon': '72.9938'},
      '608501': {'lat': '11.1715', 'name': 'Periyakomatti,Cuddalore,Tamil Nadu', 'lon': '79.6304'},
      '506331': {'lat': '17.639', 'name': 'Viswanathapur,Warangal,Telangana', 'lon': '79.6942'},
      '608502': {'lat': '11.4866', 'name': 'Samiyarpettai,Cuddalore,Tamil Nadu', 'lon': '79.7527'},
      '761031': {'lat': '20.1165', 'name': 'Kanchana,Ganjam,Odisha', 'lon': '85.0483'},
      '506330': {'lat': '18.7277', 'name': 'Sangem,Warangal,Telangana', 'lon': '79.1172'},
      '212641': {'lat': '25.658', 'name': 'Pallawan,Fatehpur,Uttar Pradesh', 'lon': '81.1352'},
      '736146': {'lat': '26.1252', 'name': 'Kanfata,Cooch Behar,West Bengal', 'lon': '88.5347'},
      '793102': {'lat': '25.9023', 'name': 'Umdihar,Ri Bhoi,Meghalaya', 'lon': '91.8769'},
      '793103': {'lat': '25.6938', 'name': 'Umroi,Ri Bhoi,Meghalaya', 'lon': '92.0758'},
      '793101': {'lat': '25.6019', 'name': 'Jorabat,Ri Bhoi,Meghalaya', 'lon': '91.9144'},
      '793106': {'lat': '25.6019', 'name': 'Sonatola,East Khasi Hills,Meghalaya', 'lon': '91.9144'},
      '793107': {'lat': '25.6019', 'name': 'Bholaganj Bazar,East Khasi Hills,Meghalaya', 'lon': '91.9144'},
      '793104': {'lat': '25.6019', 'name': 'Mawlein Mawkhan,East Khasi Hills,Meghalaya', 'lon': '91.9144'},
      '793105': {'lat': '25.772', 'name': 'Umsohlait,Ri Bhoi,Meghalaya', 'lon': '91.948'},
      '793108': {'lat': '25.3596', 'name': 'Sohkmie,East Khasi Hills,Meghalaya', 'lon': '91.7471'},
      '793109': {'lat': '25.1837', 'name': 'Syndai,Jaintia Hills,Meghalaya', 'lon': '92.0244'},
      '412203': {'lat': '18.5384', 'name': 'Galandwadi,Pune,Maharashtra', 'lon': '74.1856'},
      '412202': {'lat': '18.8923', 'name': 'Koregaon Mul,Pune,Maharashtra', 'lon': '74.8038'},
      '412201': {'lat': '18.4837', 'name': 'Kadam Wak Wasti,Pune,Maharashtra', 'lon': '74.0265'},
      '412207': {'lat': '18.7055', 'name': 'Koregaon Bhivar,Pune,Maharashtra', 'lon': '74.336'},
      '412206': {'lat': '17.6686', 'name': 'Bhatghar,Satara,Maharashtra', 'lon': '74.1738'},
      '412205': {'lat': '19.6874', 'name': 'Anantnagar Nigade,Pune,Maharashtra', 'lon': '75.1606'},
      '412204': {'lat': '18.2627', 'name': 'Bhondave Wadi,Pune,Maharashtra', 'lon': '74.4223'},
      '851130': {'lat': '25.5098', 'name': 'Rajakpur,Begusarai,Bihar', 'lon': '86.2104'},
      '851131': {'lat': '25.5049', 'name': 'Ajhaur,Begusarai,Bihar', 'lon': '86.1804'},
      '412209': {'lat': '18.7537', 'name': 'Nimgaon Mahalungi,Pune,Maharashtra', 'lon': '74.2443'},
      '412208': {'lat': '19.4445', 'name': 'Hivare,Pune,Maharashtra', 'lon': '74.3686'},
      '851134': {'lat': '25.5281', 'name': 'Keshave,Begusarai,Bihar', 'lon': '86.7963'},
      '851135': {'lat': '25.4253', 'name': 'Bihat,Begusarai,Bihar', 'lon': '86.0208'},
      '712302': {'lat': '23.3187', 'name': 'Dedhara,Hooghly,West Bengal', 'lon': '88.0249'},
      '411013': {'lat': '18.5732', 'name': 'Hadpsar I.E.,Pune,Maharashtra', 'lon': '73.8739'},
      '411012': {'lat': '18.5788', 'name': 'Dapodi Bazar,Pune,Maharashtra', 'lon': '73.8263'},
      '411011': {'lat': '18.5732', 'name': 'Mangalwar Peth (Pune),Pune,Maharashtra', 'lon': '73.8739'},
      '637504': {'lat': '11.1722', 'name': 'Kalparapatti,Salem,Tamil Nadu', 'lon': '78.2087'},
      '411017': {'lat': '18.5732', 'name': 'Pimpri Colony,Pune,Maharashtra', 'lon': '73.8739'},
      '411016': {'lat': '18.5732', 'name': 'Govt. Polytechnic,Pune,Maharashtra', 'lon': '73.8739'},
      '411015': {'lat': '18.5722', 'name': 'Dighi Camp,Pune,Maharashtra', 'lon': '73.8796'},
      '411014': {'lat': '18.5685', 'name': 'Viman nagar,Pune,Maharashtra', 'lon': '73.9158'},
      '600084': {'lat': '13.0582', 'name': 'Flowers Road,Chennai,Tamil Nadu', 'lon': '80.24'},
      '600085': {'lat': '13.0156', 'name': 'Kotturpuram,Chennai,Tamil Nadu', 'lon': '80.2467'},
      '411019': {'lat': '18.5732', 'name': 'M.Phulenagar,Pune,Maharashtra', 'lon': '73.8739'},
      '411018': {'lat': '18.5732', 'name': 'Masulkar Colony,Pune,Maharashtra', 'lon': '73.8739'},
      '600081': {'lat': '13.1274', 'name': 'Tondiarpet West,Chennai,Tamil Nadu', 'lon': '80.2886'},
      '600082': {'lat': '13.0907', 'name': 'G K M Colony,Chennai,Tamil Nadu', 'lon': '80.2554'},
      '600083': {'lat': '13.0582', 'name': 'Ashoknagar (Chennai),Chennai,Tamil Nadu', 'lon': '80.24'},
      '202142': {'lat': '28.0494', 'name': 'Hinsel,Aligarh,Uttar Pradesh', 'lon': '78.0841'},
      '477335': {'lat': '26.3755', 'name': 'Padhora,Bhind,Madhya Pradesh', 'lon': '78.9306'},
      '854108': {'lat': '25.6417', 'name': 'Musapur,Katihar,Bihar', 'lon': '87.4154'},
      '477333': {'lat': '26.443', 'name': 'Kakahara,Bhind,Madhya Pradesh', 'lon': '79.026'},
      '477331': {'lat': '26.5246', 'name': 'Donchara,Bhind,Madhya Pradesh', 'lon': '78.9714'},
      '620003': {'lat': '10.8566', 'name': 'Woriur Bazaar,Tiruchirappalli,Tamil Nadu', 'lon': '78.5278'},
      '500039': {'lat': '17.4058', 'name': 'Uppal (K.V.Rangareddy),K.V.Rangareddy,Telangana', 'lon': '78.5591'},
      '500059': {'lat': '17.357', 'name': 'Saidabad Colony,Hyderabad,Telangana', 'lon': '78.5125'},
      '263128': {'lat': '29.3127', 'name': 'Bel,Nainital,Uttarakhand', 'lon': '79.4547'},
      '250342': {'lat': '29.1934', 'name': 'Laskaarganj,Meerut,Uttar Pradesh', 'lon': '77.5851'},
      '361130': {'lat': '22.2286', 'name': 'Jaga,Jamnagar,Gujarat', 'lon': '70.3853'},
      '221007': {'lat': '25.3653', 'name': 'Aktha,Varanasi,Uttar Pradesh', 'lon': '83.0115'},
      '516257': {'lat': '14.1177', 'name': 'Budida Gunta Palli,Cuddapah,Andhra Pradesh', 'lon': '78.8406'},
      '814102': {'lat': '24.2851', 'name': 'Khudimerkho,Dumka,Jharkhand', 'lon': '85.807'},
      '814103': {'lat': '24.4', 'name': 'Kathikund,Dumka,Jharkhand', 'lon': '87.2593'},
      '516259': {'lat': '14.0376', 'name': 'Chakrayapet,Cuddapah,Andhra Pradesh', 'lon': '78.7218'},
      '814101': {'lat': '24.3521', 'name': 'Bandarjori,Dumka,Jharkhand', 'lon': '86.6542'},
      '263126': {'lat': '29.278', 'name': 'Rnibagh,Nainital,Uttarakhand', 'lon': '79.621'},
      '388350': {'lat': '22.4809', 'name': 'Napad,Anand,Gujarat', 'lon': '72.9776'},
      '533344': {'lat': '17.6965', 'name': 'Peddada,East Godavari,Andhra Pradesh', 'lon': '82.8082'},
      '533345': {'lat': '17.1584', 'name': 'Pandalapaka,East Godavari,Andhra Pradesh', 'lon': '81.9251'},
      '533346': {'lat': '17.0219', 'name': 'Lolla,East Godavari,Andhra Pradesh', 'lon': '82.2257'},
      '533347': {'lat': '17.1584', 'name': 'Pulluru PHC,East Godavari,Andhra Pradesh', 'lon': '81.9251'},
      '533340': {'lat': '17.1584', 'name': 'Ippanapadu,East Godavari,Andhra Pradesh', 'lon': '81.9251'},
      '533341': {'lat': '17.2391', 'name': 'Z.Medapadu,East Godavari,Andhra Pradesh', 'lon': '82.1202'},
      '364740': {'lat': '20.6544', 'name': 'Jaliya,Bhavnagar,Gujarat', 'lon': '73.0371'},
      '533343': {'lat': '16.9773', 'name': 'Singampalli,East Godavari,Andhra Pradesh', 'lon': '82.0461'},
      '825408': {'lat': '24.2946', 'name': 'Mayurhand,Chatra,Jharkhand', 'lon': '85.1837'},
      '522101': {'lat': '16.0329', 'name': 'Railpeta Bapatla,Guntur,Andhra Pradesh', 'lon': '80.4233'},
      '522102': {'lat': '16.0329', 'name': 'Bapatla Engg College,Guntur,Andhra Pradesh', 'lon': '80.4233'},
      '403109': {'lat': '15.4761', 'name': 'N B Verem,North Goa,Goa', 'lon': '73.9417'},
      '533348': {'lat': '17.9287', 'name': 'Donkarayi,East Godavari,Andhra Pradesh', 'lon': '81.8005'},
      '533349': {'lat': '16.2812', 'name': 'Sriramagiri,East Godavari,Andhra Pradesh', 'lon': '80.4668'},
      '521138': {'lat': '16.2684', 'name': 'Inampudi,Krishna,Andhra Pradesh', 'lon': '80.943'},
      '521139': {'lat': '16.4757', 'name': 'Ganguru,Krishna,Andhra Pradesh', 'lon': '80.7417'},
      '250344': {'lat': '29.1314', 'name': 'Sarurpur Khurd,Meerut,Uttar Pradesh', 'lon': '77.5042'},
      '626125': {'lat': '9.5127', 'name': 'Srivilliputhur North,Virudhunagar,Tamil Nadu', 'lon': '77.6337'},
      '521130': {'lat': '16.5055', 'name': 'Venaktapuram,Krishna,Andhra Pradesh', 'lon': '80.8309'},
      '521131': {'lat': '17.8724', 'name': 'Lankapalli,Krishna,Andhra Pradesh', 'lon': '82.8737'},
      '521132': {'lat': '16.3405', 'name': 'Papanasanam,Krishna,Andhra Pradesh', 'lon': '80.7267'},
      '521133': {'lat': '16.15', 'name': 'V.Rudravaram,Krishna,Andhra Pradesh', 'lon': '80.2667'},
      '521134': {'lat': '16.4595', 'name': 'Pedapulipaka,Krishna,Andhra Pradesh', 'lon': '80.6844'},
      '521135': {'lat': '16.8262', 'name': 'Movva,Krishna,Andhra Pradesh', 'lon': '80.8446'},
      '521136': {'lat': '15.9964', 'name': 'Barlapudi,Krishna,Andhra Pradesh', 'lon': '80.173'},
      '521137': {'lat': '16.4777', 'name': 'Poranki,Krishna,Andhra Pradesh', 'lon': '80.7076'},
      '456313': {'lat': '24.4106', 'name': 'Chirola  Chhota,Ujjain,Madhya Pradesh', 'lon': '79.0409'},
      '212645': {'lat': '25.8727', 'name': 'Haswa,Fatehpur,Uttar Pradesh', 'lon': '80.9158'},
      '143109': {'lat': '31.5625', 'name': 'Khiala Kalan,Amritsar,Punjab', 'lon': '74.7612'},
      '143108': {'lat': '31.6106', 'name': 'Mohawa,Amritsar,Punjab', 'lon': '74.6032'},
      '175052': {'lat': '31.5997', 'name': 'Saigaloo,Mandi,Himachal Pradesh', 'lon': '76.8797'},
      '755005': {'lat': '20.951', 'name': 'Kakudikuda,Jajapur,Odisha', 'lon': '85.6841'},
      '175050': {'lat': '32.1204', 'name': 'Samour,Mandi,Himachal Pradesh', 'lon': '76.4623'},
      '175051': {'lat': '31.7733', 'name': 'Seoh,Mandi,Himachal Pradesh', 'lon': '76.7405'},
      '585367': {'lat': '17.320486', 'name': 'Central University of Karnataka,19,Kad', 'lon': '76.839752'},
      '524302': {'lat': '14.5455', 'name': 'Kamireddypadu,Nellore,Andhra Pradesh', 'lon': '79.4769'},
      '755004': {'lat': '20.8313', 'name': 'Ratalang,Jajapur,Odisha', 'lon': '86.6106'},
      '572129': {'lat': '13.4216', 'name': 'Dasarahally,Tumakuru,Karnataka', 'lon': '77.2553'},
      '572128': {'lat': '13.4381', 'name': 'Chikkatottalakere,Tumakuru,Karnataka', 'lon': '77.0642'},
      '755007': {'lat': '21.7032', 'name': 'Nathsahi,Jajapur,Odisha', 'lon': '87.1571'},
      '572123': {'lat': '13.3021', 'name': 'Huliyur Durga,Tumakuru,Karnataka', 'lon': '76.8679'},
      '572122': {'lat': '13.4289', 'name': 'Mulukunte,Tumakuru,Karnataka', 'lon': '76.369'},
      '572121': {'lat': '13.5279', 'name': 'Thogarighatta,Tumakuru,Karnataka', 'lon': '77.3129'},
      '572120': {'lat': '14.0524', 'name': 'Dawoodsab Palya,Tumakuru,Karnataka', 'lon': '76.9451'},
      '572127': {'lat': '13.7434', 'name': 'Singonahally,Tumakuru,Karnataka', 'lon': '77.4011'},
      '572126': {'lat': '12.8479', 'name': 'Seenappanahalli,Tumakuru,Karnataka', 'lon': '76.8181'},
      '572125': {'lat': '13.6797', 'name': 'Kuntegowdanahalli,Tumakuru,Karnataka', 'lon': '76.5774'},
      '572124': {'lat': '13.7841', 'name': 'Thippapura,Tumakuru,Karnataka', 'lon': '77.2868'},
      '755009': {'lat': '20.5792', 'name': 'Samantarapur,Jajapur,Odisha', 'lon': '85.6774'},
      '607003': {'lat': '11.6797', 'name': 'Akkarai Gori,Cuddalore,Tamil Nadu', 'lon': '79.2608'},
      '370210': {'lat': '22.9833', 'name': 'Kandla Port,Kachchh,Gujarat', 'lon': '70.2167'},
      '533003': {'lat': '15.9938', 'name': 'Engg.College,East Godavari,Andhra Pradesh', 'lon': '81.3194'},
      '533002': {'lat': '15.9938', 'name': 'Church Square,East Godavari,Andhra Pradesh', 'lon': '81.3194'},
      '533001': {'lat': '16.546', 'name': 'Govt.Gen Hospital,East Godavari,Andhra Pradesh', 'lon': '81.4196'},
      '334601': {'lat': '28.2337', 'name': 'Dheerera R.S.,Bikaner,Rajasthan', 'lon': '73.789'},
      '533006': {'lat': '13.2802', 'name': 'Karakuduru,East Godavari,Andhra Pradesh', 'lon': '79.4612'},
      '533005': {'lat': '17.2126', 'name': 'Apiic,East Godavari,Andhra Pradesh', 'lon': '82.2234'},
      '533004': {'lat': '15.9938', 'name': 'Ramaraopet,East Godavari,Andhra Pradesh', 'lon': '81.3194'},
      '497223': {'lat': '22.2165', 'name': 'Jajawal,Surguja,Chattisgarh', 'lon': '82.8451'},
      '497220': {'lat': '23.8748', 'name': 'Dolangi,Surguja,Chattisgarh', 'lon': '83.515'},
      '581319': {'lat': '13.4646', 'name': 'Nadumaskeri,Uttara Kannada,Karnataka', 'lon': '74.776'},
      '193122': {'lat': '34.143', 'name': 'Gingle,Baramulla,Jammu & Kashmir', 'lon': '74.1151'},
      '193123': {'lat': '34.0833', 'name': 'Garkote,Baramulla,Jammu & Kashmir', 'lon': '74.0334'},
      '127307': {'lat': '28.6402', 'name': 'Loharwara,Bhiwani,Haryana', 'lon': '76.3867'},
      '193121': {'lat': '34.1303', 'name': 'Goshbugh,Baramulla,Jammu & Kashmir', 'lon': '74.6135'},
      '127309': {'lat': '28.7179', 'name': 'Ajitpura,Bhiwani,Haryana', 'lon': '75.9676'},
      '127308': {'lat': '28.4572', 'name': 'Arya Nagar,Bhiwani,Haryana', 'lon': '76.1909'},
      '604151': {'lat': '12.2105', 'name': 'Padipallam,Villupuram,Tamil Nadu', 'lon': '79.2557'},
      '496118': {'lat': '22.0025', 'name': 'Sukrapara,Jashpur,Chattisgarh', 'lon': '83.2788'},
      '591230': {'lat': '16.7128', 'name': 'Desairhatti,Belagavi,Karnataka', 'lon': '75.1831'},
      '591231': {'lat': '16.2014', 'name': 'Nandgaon,Belagavi,Karnataka', 'lon': '74.7489'},
      '591232': {'lat': '14.7056', 'name': 'Vishnuwadi,Belagavi,Karnataka', 'lon': '75.1633'},
      '591233': {'lat': '15.5808', 'name': 'Khandratti,Belagavi,Karnataka', 'lon': '76.2343'},
      '591234': {'lat': '15.5983', 'name': 'Navalihal,Belagavi,Karnataka', 'lon': '74.9706'},
      '591235': {'lat': '15.8667', 'name': 'Kappalguddi,Belagavi,Karnataka', 'lon': '75.1831'},
      '591236': {'lat': '15.8751', 'name': 'Ammanagi,Belagavi,Karnataka', 'lon': '75.5278'},
      '591237': {'lat': '15.7188', 'name': 'Pangire (B),Belagavi,Karnataka', 'lon': '76.2794'},
      '591238': {'lat': '15.8751', 'name': 'Nainglaj,Belagavi,Karnataka', 'lon': '75.5278'},
      '591239': {'lat': '16.5587', 'name': 'Sadalga,Belagavi,Karnataka', 'lon': '74.5321'},
      '643007': {'lat': '11.4167', 'name': 'Dhavani,Nilgiris,Tamil Nadu', 'lon': '76.6863'},
      '643006': {'lat': '11.4167', 'name': 'Finger Post,Nilgiris,Tamil Nadu', 'lon': '76.6863'},
      '606811': {'lat': '12.2596', 'name': 'Naryiapattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.1535'},
      '643003': {'lat': '11.3319', 'name': 'Thangadu Oranali,Nilgiris,Tamil Nadu', 'lon': '76.663'},
      '643002': {'lat': '11.4624', 'name': 'Thuneri,Nilgiris,Tamil Nadu', 'lon': '76.7769'},
      '262527': {'lat': '29.4137', 'name': 'Barakote,Champawat,Uttarakhand', 'lon': '79.6744'},
      '712250': {'lat': '24.0021', 'name': 'Morepukur,Hooghly,West Bengal', 'lon': '87.6513'},
      '712701': {'lat': '22.8151', 'name': 'Bandhpur,Hooghly,West Bengal', 'lon': '88.2096'},
      '262524': {'lat': '29.4683', 'name': 'Nidil,Champawat,Uttarakhand', 'lon': '79.9696'},
      '262523': {'lat': '29.3708', 'name': 'Saindark,Champawat,Uttarakhand', 'lon': '79.9264'},
      '712706': {'lat': '22.7745', 'name': 'Charpur,Hooghly,West Bengal', 'lon': '88.2257'},
      '590019': {'lat': '15.9214', 'name': 'Belgaum Doordarshan Nagar,Belagavi,Karnataka', 'lon': '74.5751'},
      '590018': {'lat': '15.9745', 'name': 'VTU Campus Belgaum,Belagavi,Karnataka', 'lon': '74.5805'},
      '590017': {'lat': '15.9214', 'name': 'Belgaum MalMaruti Extension,Belagavi,Karnataka', 'lon': '74.5751'},
      '590016': {'lat': '15.9214', 'name': 'Belgaum Shivaji Nagar,Belagavi,Karnataka', 'lon': '74.5751'},
      '590015': {'lat': '15.8948', 'name': 'Ashte,Belagavi,Karnataka', 'lon': '74.5724'},
      '590014': {'lat': '15.9214', 'name': 'Wagawade RC,Belagavi,Karnataka', 'lon': '74.5751'},
      '425432': {'lat': '21.6961', 'name': 'Chikhali-BK,Nandurbar,Maharashtra', 'lon': '74.3686'},
      '590011': {'lat': '15.9214', 'name': 'Hindawadi,Belagavi,Karnataka', 'lon': '74.5751'},
      '590010': {'lat': '15.9214', 'name': 'Kailasnagar,Belagavi,Karnataka', 'lon': '74.5751'},
      '627853': {'lat': '8.8688', 'name': 'Sivalarkulam,Tirunelveli,Tamil Nadu', 'lon': '77.5251'},
      '501401': {'lat': '17.583', 'name': 'Athveli,K.V.Rangareddy,Telangana', 'lon': '78.9223'},
      '415801': {'lat': '16.9818', 'name': 'Purye Tarfe Devale,Ratnagiri,Maharashtra', 'lon': '73.697'},
      '382465': {'lat': '23.4751', 'name': 'Loliya,Ahmedabad,Gujarat', 'lon': '72.5668'},
      '415803': {'lat': '17.0464', 'name': 'Ghativale,Ratnagiri,Maharashtra', 'lon': '73.3937'},
      '382463': {'lat': '22.6573', 'name': 'Valinda,Ahmedabad,Gujarat', 'lon': '74.1152'},
      '415805': {'lat': '18.9554', 'name': 'Dale,Ratnagiri,Maharashtra', 'lon': '76.3091'},
      '415806': {'lat': '20.665', 'name': 'Ambolgad,Ratnagiri,Maharashtra', 'lon': '76.3335'},
      '382460': {'lat': '22.626', 'name': 'Salasar,Ahmedabad,Gujarat', 'lon': '71.8358'},
      '785673': {'lat': '27.1092', 'name': 'Dubba,Sibsagar,Assam', 'lon': '94.8701'},
      '785672': {'lat': '27.2053', 'name': 'Desanghat,Sibsagar,Assam', 'lon': '94.7353'},
      '785671': {'lat': '27.2053', 'name': 'Dihing Than,Golaghat,Assam', 'lon': '94.7353'},
      '785670': {'lat': '27.1874', 'name': 'Ratomora,Sibsagar,Assam', 'lon': '94.9156'},
      '785676': {'lat': '27.2754', 'name': 'Sessughat,Dibrugarh,Assam', 'lon': '94.4401'},
      '785675': {'lat': '27.2754', 'name': 'Kathalguri,Dibrugarh,Assam', 'lon': '94.8757'},
      '785674': {'lat': '27.2053', 'name': 'Nongalmora  Tiniali,Sibsagar,Assam', 'lon': '94.7353'},
      '303009': {'lat': '26.7244', 'name': 'Kansel,Jaipur,Rajasthan', 'lon': '75.4528'},
      '609607': {'lat': '10.9261', 'name': 'Vizhithiyur,Karaikal,Pondicherry', 'lon': '79.792'},
      '821305': {'lat': '24.9411', 'name': 'Gangouli,Rohtas,Bihar', 'lon': '84.1241'},
      '206129': {'lat': '26.8156', 'name': 'Bakhriya,Auraiya,Uttar Pradesh', 'lon': '79.2631'},
      '303006': {'lat': '26.9805', 'name': 'Dosara,Jaipur,Rajasthan', 'lon': '75.7243'},
      '610204': {'lat': '9.8086', 'name': 'Tiruvoimur,Nagapattinam,Tamil Nadu', 'lon': '78.994'},
      '303007': {'lat': '26.6959', 'name': 'Rampura Unti,Jaipur,Rajasthan', 'lon': '75.719'},
      '206121': {'lat': '26.2657', 'name': 'Bhurepurkalan,Auraiya,Uttar Pradesh', 'lon': '79.1594'},
      '609601': {'lat': '10.909', 'name': 'Ervadi,Nagapattinam,Tamil Nadu', 'lon': '79.7312'},
      '206123': {'lat': '26.9931', 'name': 'Parasana,Etawah,Uttar Pradesh', 'lon': '79.3018'},
      '206122': {'lat': '26.5266', 'name': 'Auraiya H.O,Auraiya,Uttar Pradesh', 'lon': '79.4652'},
      '206125': {'lat': '26.3901', 'name': 'Bachheri,Etawah,Uttar Pradesh', 'lon': '79.2369'},
      '151510': {'lat': '30.3072', 'name': 'Balloh,Mansa,Punjab', 'lon': '75.5859'},
      '206127': {'lat': '26.5314', 'name': 'Lakhana,Etawah,Uttar Pradesh', 'lon': '79.8479'},
      '416549': {'lat': '15.7777', 'name': 'Ghotagewadi,Sindhudurg,Maharashtra', 'lon': '74.0564'},
      '609603': {'lat': '10.9775', 'name': 'Velangudi,Tiruvarur,Tamil Nadu', 'lon': '79.7453'},
      '515281': {'lat': '13.9628', 'name': 'Maddanakunta,Ananthapur,Andhra Pradesh', 'lon': '77.0805'},
      '786012': {'lat': '26.5654', 'name': 'Harabari,Dibrugarh,Assam', 'lon': '94.0443'},
      '222139': {'lat': '25.9339', 'name': 'Manikala,Jaunpur,Uttar Pradesh', 'lon': '82.6751'},
      '609602': {'lat': '10.9174', 'name': 'Karaikal Bazaar,Karaikal,Pondicherry', 'lon': '79.8258'},
      '392220': {'lat': '22.6857', 'name': 'Simartha,Bharuch,Gujarat', 'lon': '73.7111'},
      '799281': {'lat': '24.0789', 'name': 'Latiapura,North Tripura,Tripura', 'lon': '91.8987'},
      '516432': {'lat': '14.9748', 'name': 'Vaddirala,Cuddapah,Andhra Pradesh', 'lon': '78.2512'},
      '571478': {'lat': '12.472', 'name': 'Kottatti,Mandya,Karnataka', 'lon': '76.8666'},
      '721443': {'lat': '21.9467', 'name': 'Barabatia,East Midnapore,West Bengal', 'lon': '87.4938'},
      '721440': {'lat': '22.0208', 'name': 'Srirampur,East Midnapore,West Bengal', 'lon': '87.7744'},
      '721441': {'lat': '22.63', 'name': 'Jhawgeria,East Midnapore,West Bengal', 'lon': '87.7168'},
      '721446': {'lat': '22.2088', 'name': 'Bolkhusda,East Midnapore,West Bengal', 'lon': '88.0387'},
      '516431': {'lat': '14.9022', 'name': 'Bodithippanapadu,Cuddapah,Andhra Pradesh', 'lon': '78.4206'},
      '721444': {'lat': '24.3444', 'name': 'Kanaidighi,East Midnapore,West Bengal', 'lon': '87.9089'},
      '721445': {'lat': '22.3564', 'name': 'Turka Kasba,West Midnapore,West Bengal', 'lon': '87.8021'},
      '458778': {'lat': '23.3747', 'name': 'Bhensoda Town,Mandsaur,Madhya Pradesh', 'lon': '76.2046'},
      '721448': {'lat': '21.9958', 'name': 'Juki,East Midnapore,West Bengal', 'lon': '87.8022'},
      '721449': {'lat': '22.3564', 'name': 'Daisai,East Midnapore,West Bengal', 'lon': '87.8021'},
      '752032': {'lat': '19.5667', 'name': 'Parikudgarh,Puri,Odisha', 'lon': '85.25'},
      '571475': {'lat': '12.7666', 'name': 'Gowdagere,Mandya,Karnataka', 'lon': '77.2784'},
      '571476': {'lat': '13.0117', 'name': 'Navile,Mandya,Karnataka', 'lon': '77.2711'},
      '571477': {'lat': '12.3955', 'name': 'Nagoonahalli,Mandya,Karnataka', 'lon': '76.7186'},
      '624103': {'lat': '10.3106', 'name': 'Poombarai,Dindigul,Tamil Nadu', 'lon': '77.4806'},
      '770013': {'lat': '21.9762', 'name': 'Sanghumunda,Sundergarh,Odisha', 'lon': '83.68'},
      '678009': {'lat': '10.7691', 'name': 'Kallekulangara,Palakkad,Kerala', 'lon': '76.6533'},
      '678008': {'lat': '10.7691', 'name': 'Akathethara,Palakkad,Kerala', 'lon': '76.6533'},
      '770012': {'lat': '21.9947', 'name': 'Dumabahal,Sundergarh,Odisha', 'lon': '83.8395'},
      '678003': {'lat': '10.7902', 'name': 'Newkalpathy,Palakkad,Kerala', 'lon': '76.6457'},
      '678002': {'lat': '10.7691', 'name': 'Olavakkot H.O,Palakkad,Kerala', 'lon': '76.6533'},
      '678001': {'lat': '10.7691', 'name': 'Palakkad H.O,Palakkad,Kerala', 'lon': '76.6533'},
      '678007': {'lat': '10.7691', 'name': 'Pallatheri,Palakkad,Kerala', 'lon': '76.6533'},
      '678006': {'lat': '10.7519', 'name': 'Pallipuram PG,Palakkad,Kerala', 'lon': '76.6824'},
      '678005': {'lat': '10.7691', 'name': 'Kallepulli,Palakkad,Kerala', 'lon': '76.6533'},
      '678004': {'lat': '10.7652', 'name': 'Koduntharapully,Palakkad,Kerala', 'lon': '76.6318'},
      '623608': {'lat': '9.5876', 'name': 'Pidariseri,Ramanathapuram,Tamil Nadu', 'lon': '78.41'},
      '221101': {'lat': '25.4806', 'name': 'Chaubepurkhurd,Varanasi,Uttar Pradesh', 'lon': '82.8499'},
      '465661': {'lat': '23.6243', 'name': 'Jhanjhadpur,Rajgarh,Madhya Pradesh', 'lon': '76.9062'},
      '221103': {'lat': '25.442', 'name': 'Manduadih,Varanasi,Uttar Pradesh', 'lon': '82.9045'},
      '221104': {'lat': '25.5089', 'name': 'Chaubeypur,Varanasi,Uttar Pradesh', 'lon': '82.9529'},
      '805128': {'lat': '24.7552', 'name': 'Pasarhi,Nawada,Bihar', 'lon': '85.2988'},
      '221106': {'lat': '25.3171', 'name': 'Phulawaria,Varanasi,Uttar Pradesh', 'lon': '82.9751'},
      '221107': {'lat': '25.3232', 'name': 'Koruta Bazar,Varanasi,Uttar Pradesh', 'lon': '82.9153'},
      '221108': {'lat': '25.442', 'name': 'Bhulanpur Pac,Varanasi,Uttar Pradesh', 'lon': '82.9045'},
      '221109': {'lat': '25.2116', 'name': 'Jayapur,Varanasi,Uttar Pradesh', 'lon': '82.8208'},
      '632105': {'lat': '12.412', 'name': 'Elavambadi,Vellore,Tamil Nadu', 'lon': '78.6828'},
      '805129': {'lat': '24.811', 'name': 'Dhandhar,Nawada,Bihar', 'lon': '85.5941'},
      '580002': {'lat': '15.4287', 'name': 'Dharwad S D M E college,Dharwad,Karnataka', 'lon': '75.0705'},
      '504202': {'lat': '19.1284', 'name': 'Ambaripet,Adilabad,Telangana', 'lon': '78.6606'},
      '181124': {'lat': '32.8598', 'name': 'Bsf Camp Paloura,Jammu,Jammu & Kashmir', 'lon': '74.7831'},
      '799288': {'lat': '24.1048', 'name': 'Masauli,North Tripura,Tripura', 'lon': '92.0073'},
      '181121': {'lat': '32.8598', 'name': 'Topsherkhania,Jammu,Jammu & Kashmir', 'lon': '74.7831'},
      '625582': {'lat': '9.5569', 'name': 'Bodimettu,Theni,Tamil Nadu', 'lon': '77.5544'},
      '181123': {'lat': '32.806', 'name': 'Bantalab,Jammu,Jammu & Kashmir', 'lon': '74.8304'},
      '181122': {'lat': '32.8705', 'name': 'Rabta,Jammu,Jammu & Kashmir', 'lon': '74.7736'},
      '263665': {'lat': '29.7793', 'name': 'Airari Bisht,Almora,Uttarakhand', 'lon': '79.3125'},
      '673586': {'lat': '11.5', 'name': 'Kakkad Pudupadi,Kozhikode,Kerala', 'lon': '75.9667'},
      '673585': {'lat': '11.3481', 'name': 'Madavoor,Kozhikode,Kerala', 'lon': '75.857'},
      '411007': {'lat': '18.5333', 'name': 'Ganeshkhind,Pune,Maharashtra', 'lon': '73.8667'},
      '673582': {'lat': '11.3782', 'name': 'Venappara,Kozhikode,Kerala', 'lon': '75.9427'},
      '673581': {'lat': '11.4622', 'name': 'Kadalmat,Wayanad,Kerala', 'lon': '75.9116'},
      '673580': {'lat': '11.8509', 'name': 'Kotancheri-tamaracheri,Kozhikode,Kerala', 'lon': '75.9891'},
      '632106': {'lat': '12.9862', 'name': 'Arumparuthi,Vellore,Tamil Nadu', 'lon': '79.0316'},
      '263663': {'lat': '29.6136', 'name': 'Shishuwa,Almora,Uttarakhand', 'lon': '79.3748'},
      '609703': {'lat': '10.8434', 'name': 'P.Kondagai,Nagapattinam,Tamil Nadu', 'lon': '79.7951'},
      '770019': {'lat': '21.6969', 'name': 'Remanda,Sundergarh,Odisha', 'lon': '84.0692'},
      '754208': {'lat': '20.5203', 'name': 'Sabalanga Kanipara,Cuttack,Odisha', 'lon': '86.2692'},
      '754209': {'lat': '21.1156', 'name': 'Agyanpur,Cuttack,Odisha', 'lon': '85.5245'},
      '770018': {'lat': '22.0754', 'name': 'Dahijira,Sundergarh,Odisha', 'lon': '84.4377'},
      '263661': {'lat': '29.8401', 'name': 'Jayanteshwar Simalchaura,Almora,Uttarakhand', 'lon': '79.1808'},
      '754200': {'lat': '20.0924', 'name': 'Madhyakachha,Cuttack,Odisha', 'lon': '85.0275'},
      '396385': {'lat': '20.7851', 'name': 'Malvan,Valsad,Gujarat', 'lon': '72.984'},
      '383220': {'lat': '23.7971', 'name': 'Khedavada,Sabarkantha,Gujarat', 'lon': '72.8511'},
      '754203': {'lat': '20.3837', 'name': 'Kendupanta,Cuttack,Odisha', 'lon': '85.3724'},
      '754204': {'lat': '20.508', 'name': 'Kuhunda,Cuttack,Odisha', 'lon': '85.6634'},
      '754205': {'lat': '20.602', 'name': 'Barada Cuttack,Jajapur,Odisha', 'lon': '85.5234'},
      '754206': {'lat': '20.5703', 'name': 'Gokana,Cuttack,Odisha', 'lon': '86.2173'},
      '383225': {'lat': '23.5723', 'name': 'Davad,Sabarkantha,Gujarat', 'lon': '72.7839'},
      '314001': {'lat': '23.8714', 'name': 'Mathugamda,Dungarpur,Rajasthan', 'lon': '73.6641'},
      '515751': {'lat': '14.4287', 'name': 'P Siddarampuram,Ananthapur,Andhra Pradesh', 'lon': '77.589'},
      '466111': {'lat': '23.0684', 'name': 'Kulaskalan,Sehore,Madhya Pradesh', 'lon': '76.7129'},
      '249403': {'lat': '29.9035', 'name': 'Roshnabad Kutchery,Haridwar,Uttarakhand', 'lon': '78.0797'},
      '686573': {'lat': '9.633', 'name': 'Mevada,Kottayam,Kerala', 'lon': '76.679'},
      '767030': {'lat': '21.0901', 'name': 'Badangomunda,Balangir,Odisha', 'lon': '83.9334'},
      '767033': {'lat': '20.8741', 'name': 'Lebda,Balangir,Odisha', 'lon': '83.4882'},
      '767032': {'lat': '21.1559', 'name': 'Khasbahal,Balangir,Odisha', 'lon': '84.031'},
      '767035': {'lat': '20.2285', 'name': 'Salebarat,Balangir,Odisha', 'lon': '82.9392'},
      '249401': {'lat': '29.9431', 'name': 'Bhimgoda,Haridwar,Uttarakhand', 'lon': '78.1581'},
      '767037': {'lat': '20.6043', 'name': 'Badsaimara,Balangir,Odisha', 'lon': '83.2152'},
      '767039': {'lat': '20.7706', 'name': 'Chatuanka,Balangir,Odisha', 'lon': '82.8478'},
      '767038': {'lat': '20.6862', 'name': 'Harisankar Road,Balangir,Odisha', 'lon': '82.726'},
      '683503': {'lat': '10.1039', 'name': 'Hmt Colony,Ernakulam,Kerala', 'lon': '76.4103'},
      '503114': {'lat': '18.3752', 'name': 'Pothangalkalan,Nizamabad,Telangana', 'lon': '78.1293'},
      '466115': {'lat': '22.9643', 'name': 'Semlijadid,Sehore,Madhya Pradesh', 'lon': '77.0563'},
      '506310': {'lat': '17.9035', 'name': 'Lohitha,Warangal,Telangana', 'lon': '79.5429'},
      '160104': {'lat': '30.7465', 'name': 'Dhakaoli,Mohali,Punjab', 'lon': '76.7417'},
      '791104': {'lat': '28.1268', 'name': 'Sirem,East Siang,Arunachal Pradesh', 'lon': '94.934'},
      '505327': {'lat': '18.6626', 'name': 'Jagtial H.O,Karim Nagar,Telangana', 'lon': '78.9629'},
      '505326': {'lat': '18.8215', 'name': 'Koratla Bazar,Karim Nagar,Telangana', 'lon': '78.7119'},
      '505325': {'lat': '18.6399', 'name': 'Vellolla,Karim Nagar,Telangana', 'lon': '78.9988'},
      '587312': {'lat': '16.363', 'name': 'Mahalingpur Town,Bagalkot,Karnataka', 'lon': '75.0938'},
      '391750': {'lat': '22.7517', 'name': 'Fartilizer Nagar,Vadodara,Gujarat', 'lon': '73.2951'},
      '524230': {'lat': '14.8316', 'name': 'Bijjampalle,Nellore,Andhra Pradesh', 'lon': '79.1684'},
      '518553': {'lat': '14.9738', 'name': 'Gotlur,Kurnool,Andhra Pradesh', 'lon': '78.5758'},
      '400008': {'lat': '18.9474', 'name': 'M A Marg,Mumbai,Maharashtra', 'lon': '72.8138'},
      '400009': {'lat': '18.9474', 'name': 'Noor Baug,Mumbai,Maharashtra', 'lon': '72.8138'},
      '524234': {'lat': '14.7596', 'name': 'Pathanapuram,Nellore,Andhra Pradesh', 'lon': '79.7154'},
      '327001': {'lat': '24.1534', 'name': 'Sevna,Banswara,Rajasthan', 'lon': '74.3421'},
      '524236': {'lat': '15.0202', 'name': 'Thotlacheruvupalli,Nellore,Andhra Pradesh', 'lon': '79.3283'},
      '457887': {'lat': '22.2882', 'name': 'Ambadaberi,Alirajpur,Madhya Pradesh', 'lon': '74.3651'},
      '400002': {'lat': '18.975', 'name': 'Thakurdwar,Mumbai,Maharashtra', 'lon': '72.8258'},
      '524239': {'lat': '14.9676', 'name': 'Kommi,Nellore,Andhra Pradesh', 'lon': '79.7616'},
      '400001': {'lat': '18.9474', 'name': 'Stock Exchange,Mumbai,Maharashtra', 'lon': '72.8138'},
      '400006': {'lat': '18.9596', 'name': 'Malabar Hill,Mumbai,Maharashtra', 'lon': '72.8496'},
      '400007': {'lat': '18.9636', 'name': 'N.S.Patkar Marg,Mumbai,Maharashtra', 'lon': '72.8193'},
      '400004': {'lat': '18.95', 'name': 'Charni Road,Mumbai,Maharashtra', 'lon': '72.8167'},
      '400005': {'lat': '18.9069', 'name': 'Colaba Bazar,Mumbai,Maharashtra', 'lon': '72.8106'},
      '515133': {'lat': '14.2051', 'name': 'Jagarajupalli,Ananthapur,Andhra Pradesh', 'lon': '78.004'},
      '632101': {'lat': '12.6075', 'name': 'Eripudhur,Vellore,Tamil Nadu', 'lon': '79.0949'},
      '741137': {'lat': '24.035', 'name': 'Palit Behgia,Nadia,West Bengal', 'lon': '88.0659'},
      '515134': {'lat': '14.155', 'name': 'Beedupalli,Ananthapur,Andhra Pradesh', 'lon': '77.8036'},
      '325221': {'lat': '24.8861', 'name': 'Gordhanpra,Baran,Rajasthan', 'lon': '76.5955'},
      '741138': {'lat': '24.0105', 'name': 'Kandoa,Nadia,West Bengal', 'lon': '87.614'},
      '741139': {'lat': '24.0269', 'name': 'Dhubulia,Nadia,West Bengal', 'lon': '87.9153'},
      '742113': {'lat': '24.1923', 'name': 'Dalelpara,Murshidabad,West Bengal', 'lon': '88.2688'},
      '535160': {'lat': '18.1593', 'name': 'Madanapuram,Vizianagaram,Andhra Pradesh', 'lon': '83.212'},
      '535161': {'lat': '18.031', 'name': 'L Kota,Vizianagaram,Andhra Pradesh', 'lon': '83.1636'},
      '685533': {'lat': '9.4364', 'name': 'Gavi,Pathanamthitta,Kerala', 'lon': '77.1598'},
      '685532': {'lat': '9.5564', 'name': 'Paloorkavu,Idukki,Kerala', 'lon': '76.9501'},
      '685531': {'lat': '9.5729', 'name': 'Karadikuzhy,Idukki,Kerala', 'lon': '77.0024'},
      '622506': {'lat': '10.2785', 'name': 'Rayavaram (Pudukkottai),Pudukkottai,Tamil Nadu', 'lon': '78.8268'},
      '387411': {'lat': '23.0401', 'name': 'Kheda H.O,Kheda,Gujarat', 'lon': '73.0053'},
      '458990': {'lat': '23.6489', 'name': 'Rawta,Mandsaur,Madhya Pradesh', 'lon': '76.2052'},
      '685535': {'lat': '9.5434', 'name': 'Anavilasam,Idukki,Kerala', 'lon': '77.0484'},
      '622502': {'lat': '10.5949', 'name': 'Killukkottai,Pudukkottai,Tamil Nadu', 'lon': '78.7508'},
      '632102': {'lat': '12.6987', 'name': 'Palaparambattu,Vellore,Tamil Nadu', 'lon': '79.0314'},
      '723168': {'lat': '24.1939', 'name': 'Manihara,Purulia,West Bengal', 'lon': '87.8753'},
      '670612': {'lat': '10.9189', 'name': 'Kezhallur,Kannur,Kerala', 'lon': '76.1313'},
      '670613': {'lat': '11.8559', 'name': 'Mowancheri,Kannur,Kerala', 'lon': '75.4736'},
      '246448': {'lat': '30.2119', 'name': 'Maikoti,Rudraprayag,Uttarakhand', 'lon': '79.461'},
      '670611': {'lat': '11.8977', 'name': 'Muzhappala,Kannur,Kerala', 'lon': '75.4887'},
      '210430': {'lat': '25.6466', 'name': 'Barauli Kharka,Hamirpur,Uttar Pradesh', 'lon': '79.5242'},
      '723161': {'lat': '23.2774', 'name': 'Bargoria,Purulia,West Bengal', 'lon': '86.4183'},
      '246445': {'lat': '30.2119', 'name': 'Kedarnath Seasional,Rudraprayag,Uttarakhand', 'lon': '79.461'},
      '246442': {'lat': '29.8864', 'name': 'Kyuri,Rudraprayag,Uttarakhand', 'lon': '79.3113'},
      '246443': {'lat': '30.5719', 'name': 'Bhyundhar,Chamoli,Uttarakhand', 'lon': '79.6073'},
      '246440': {'lat': '29.6125', 'name': 'Dewalkot,Chamoli,Uttarakhand', 'lon': '79.9409'},
      '274806': {'lat': '26.5404', 'name': 'Paikauli Maharaj,Deoria,Uttar Pradesh', 'lon': '83.5835'},
      '679534': {'lat': '10.7798', 'name': 'Thalakkasseri,Palakkad,Kerala', 'lon': '76.1187'},
      '534340': {'lat': '17.0597', 'name': 'Penkanametta,West Godavari,Andhra Pradesh', 'lon': '81.9387'},
      '679536': {'lat': '10.7553', 'name': 'Chalisseri,Palakkad,Kerala', 'lon': '76.098'},
      '534342': {'lat': '17.021', 'name': 'I.Pangidi,West Godavari,Andhra Pradesh', 'lon': '81.6176'},
      '753003': {'lat': '20.0308', 'name': 'Malgodown,Cuttack,Odisha', 'lon': '85.0993'},
      '679531': {'lat': '10.7339', 'name': 'Netumpura,Thrissur,Kerala', 'lon': '76.301'},
      '679532': {'lat': '10.4712', 'name': 'Thalassery,Thrissur,Kerala', 'lon': '76.3162'},
      '679533': {'lat': '10.7578', 'name': 'Thirumittakode,Palakkad,Kerala', 'lon': '76.1166'},
      '477105': {'lat': '26.5711', 'name': 'Simrao,Bhind,Madhya Pradesh', 'lon': '78.6394'},
      '232332': {'lat': '25.4414', 'name': 'Bhikhichaura,Ghazipur,Uttar Pradesh', 'lon': '83.5185'},
      '232331': {'lat': '25.3117', 'name': 'Khargasipur Urf Naibazar,Ghazipur,Uttar Pradesh', 'lon': '83.2851'},
      '486340': {'lat': '24.613', 'name': 'Bhir,Rewa,Madhya Pradesh', 'lon': '81.5885'},
      '232336': {'lat': '25.6339', 'name': 'Andharipur Sonwal,Ghazipur,Uttar Pradesh', 'lon': '83.5943'},
      '753009': {'lat': '20.2151', 'name': 'Telenga Bazar,Cuttack,Odisha', 'lon': '85.0791'},
      '753008': {'lat': '20.1446', 'name': 'Shelter Chhak,Cuttack,Odisha', 'lon': '85.0773'},
      '263667': {'lat': '29.7113', 'name': 'Kunhil,Almora,Uttarakhand', 'lon': '79.2668'},
      '362565': {'lat': '21.2055', 'name': 'Nana Samadhiyala,Junagadh,Gujarat', 'lon': '71.1458'},
      '362560': {'lat': '21.2055', 'name': 'Motadesar,Junagadh,Gujarat', 'lon': '71.1458'},
      '271851': {'lat': '27.6401', 'name': 'Matera Kalan,Bahraich,Uttar Pradesh', 'lon': '81.4603'},
      '396380': {'lat': '20.7851', 'name': 'Valoti,Navsari,Gujarat', 'lon': '72.984'},
      '796181': {'lat': '23.302', 'name': 'Chhiahtlang,Serchhip,Mizoram', 'lon': '92.9197'},
      '843333': {'lat': '25.7068', 'name': 'Dhadhi,Sitamarhi,Bihar', 'lon': '85.959'},
      '508253': {'lat': '17.1125', 'name': 'Velmakanne,Nalgonda,Telangana', 'lon': '78.9209'},
      '364240': {'lat': '22.2689', 'name': 'Randola,Bhavnagar,Gujarat', 'lon': '71.1908'},
      '364530': {'lat': '21.447', 'name': 'Vadal-moti,Bhavnagar,Gujarat', 'lon': '71.7423'},
      '332002': {'lat': '27.4938', 'name': 'Anokhu,Sikar,Rajasthan', 'lon': '74.9859'},
      '457114': {'lat': '23.4637', 'name': 'Piplia Sisodia,Ratlam,Madhya Pradesh', 'lon': '75.5526'},
      '517280': {'lat': '13.6043', 'name': 'Mushtoor,Chittoor,Andhra Pradesh', 'lon': '78.5137'},
      '271855': {'lat': '27.7486', 'name': 'Gaurapipra,Bahraich,Uttar Pradesh', 'lon': '81.506'},
      '246193': {'lat': '29.9331', 'name': 'Mandoli Malli,Pauri Garhwal,Uttarakhand', 'lon': '77.9938'},
      '246194': {'lat': '29.9331', 'name': 'Salda,Pauri Garhwal,Uttarakhand', 'lon': '77.9938'},
      '500003': {'lat': '17.3862', 'name': 'Kingsway,Hyderabad,Telangana', 'lon': '78.462'},
      '388345': {'lat': '22.5269', 'name': 'Gana,Anand,Gujarat', 'lon': '72.9314'},
      '246147': {'lat': '30.0421', 'name': 'Nawakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.9239'},
      '796186': {'lat': '23.1828', 'name': 'Ramlaitui,Lunglei,Mizoram', 'lon': '92.7383'},
      '208026': {'lat': '26.418', 'name': 'Indira Nagar (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '80.298'},
      '795140': {'lat': '24.7538', 'name': 'Tulihal,Imphal East,Manipur', 'lon': '93.8855'},
      '795141': {'lat': '24.9774', 'name': 'Tamenglon Khunjao,Tamenglon,Manipur', 'lon': '93.4533'},
      '795142': {'lat': '25.3148', 'name': 'Rajai Khunou,Ukhrul,Manipur', 'lon': '94.4139'},
      '762013': {'lat': '20.6637', 'name': 'Badabandha,Boudh,Odisha', 'lon': '84.4456'},
      '795144': {'lat': '25.1679', 'name': 'Huime,Ukhrul,Manipur', 'lon': '94.2891'},
      '795145': {'lat': '24.853', 'name': 'Maokot,Ukhrul,Manipur', 'lon': '94.3145'},
      '795146': {'lat': '24.8452', 'name': 'Ponlian,Imphal West,Manipur', 'lon': '93.8226'},
      '795147': {'lat': '24.7435', 'name': 'Kambiron,Tamenglon,Manipur', 'lon': '93.4175'},
      '795148': {'lat': '24.5892', 'name': 'Tollen,Thoubal,Manipur', 'lon': '94.0639'},
      '795149': {'lat': '24.6813', 'name': 'Huikap,Imphal East,Manipur', 'lon': '94.063'},
      '457888': {'lat': '22.1204', 'name': 'Foolmal,Alirajpur,Madhya Pradesh', 'lon': '74.2625'},
      '695572': {'lat': '8.5042', 'name': 'Amachal,Thiruvananthapuram,Kerala', 'lon': '77.0803'},
      '695573': {'lat': '8.5169', 'name': 'Moongode,Thiruvananthapuram,Kerala', 'lon': '77.0235'},
      '695570': {'lat': '8.5106', 'name': 'Machel,Thiruvananthapuram,Kerala', 'lon': '77.0519'},
      '695571': {'lat': '8.5106', 'name': 'Malayam,Thiruvananthapuram,Kerala', 'lon': '77.0519'},
      '311404': {'lat': '25.8993', 'name': 'Mindolia,Bhilwara,Rajasthan', 'lon': '75.2326'},
      '695574': {'lat': '8.5106', 'name': 'Paruthipally,Thiruvananthapuram,Kerala', 'lon': '77.0519'},
      '695575': {'lat': '8.5106', 'name': 'Punalal,Thiruvananthapuram,Kerala', 'lon': '77.0519'},
      '311408': {'lat': '25.9793', 'name': 'Munshi,Bhilwara,Rajasthan', 'lon': '74.2564'},
      '805101': {'lat': '24.811', 'name': 'Dharhara,Nawada,Bihar', 'lon': '85.5941'},
      '805102': {'lat': '24.9205', 'name': 'Tetariya,Nawada,Bihar', 'lon': '85.5118'},
      '515202': {'lat': '13.8197', 'name': 'P.Sadlapalli,Ananthapur,Andhra Pradesh', 'lon': '77.5428'},
      '805104': {'lat': '24.9328', 'name': 'Ohari,Nawada,Bihar', 'lon': '85.5551'},
      '743245': {'lat': '22.1465', 'name': 'Chand Para Bazar,North 24 Parganas,West Bengal', 'lon': '88.1117'},
      '805106': {'lat': '25.0163', 'name': 'Kadhar,Nawada,Bihar', 'lon': '86.0604'},
      '743247': {'lat': '22.4948', 'name': 'Kapileswarpur,North 24 Parganas,West Bengal', 'lon': '88.4232'},
      '797120': {'lat': '25.8905', 'name': 'Zubza,Kohima,Nagaland', 'lon': '94.3045'},
      '743249': {'lat': '23.5228', 'name': 'Ghonza,North 24 Parganas,West Bengal', 'lon': '88.1967'},
      '462101': {'lat': '23.6451', 'name': 'Dhamarra,Bhopal,Madhya Pradesh', 'lon': '77.1678'},
      '462100': {'lat': '23.6451', 'name': 'Nariyal Kheda,Bhopal,Madhya Pradesh', 'lon': '77.1678'},
      '805125': {'lat': '24.6449', 'name': 'Rajauli,Nawada,Bihar', 'lon': '85.5003'},
      '486669': {'lat': '24.1566', 'name': 'Mahkhor,Sidhi,Madhya Pradesh', 'lon': '81.5978'},
      '263601': {'lat': '29.5744', 'name': 'Ayarpani,Almora,Uttarakhand', 'lon': '79.6928'},
      '574201': {'lat': '12.6206', 'name': 'Puttur(D.K.) H.O,Dakshina Kannada,Karnataka', 'lon': '75.9494'},
      '574202': {'lat': '12.7164', 'name': 'Shanthigodu,Dakshina Kannada,Karnataka', 'lon': '75.6452'},
      '574203': {'lat': '12.3329', 'name': 'Ujirpade,Dakshina Kannada,Karnataka', 'lon': '76.862'},
      '505473': {'lat': '18.0519', 'name': 'Thangallapalli,Karim Nagar,Telangana', 'lon': '79.0704'},
      '473865': {'lat': '25.7005', 'name': 'Mohini,Shivpuri,Madhya Pradesh', 'lon': '77.9349'},
      '230002': {'lat': '25.9732', 'name': 'Dherehana,Pratapgarh,Uttar Pradesh', 'lon': '81.3865'},
      '495671': {'lat': '21.9262', 'name': 'Chicholi,Janjgir-champa,Chattisgarh', 'lon': '82.7379'},
      '495677': {'lat': '22.0939', 'name': 'Korba H.O,Korba,Chattisgarh', 'lon': '82.8006'},
      '495674': {'lat': '22.1912', 'name': 'Bhaisma,Korba,Chattisgarh', 'lon': '82.9004'},
      '805122': {'lat': '24.8928', 'name': 'Lond,Nawada,Bihar', 'lon': '85.2029'},
      '486666': {'lat': '24.0841', 'name': 'Kamachh,Sidhi,Madhya Pradesh', 'lon': '81.2487'},
      '384335': {'lat': '23.8375', 'name': 'Sobhasan,Mahesana,Gujarat', 'lon': '72.7255'},
      '283123': {'lat': '26.9104', 'name': 'Gajaura,Agra,Uttar Pradesh', 'lon': '78.4493'},
      '312623': {'lat': '24.8883', 'name': 'Panmodi,Chittorgarh,Rajasthan', 'lon': '74.2474'},
      '756181': {'lat': '20.5196', 'name': 'Prachinagar,Bhadrak,Odisha', 'lon': '84.7167'},
      '384330': {'lat': '23.726', 'name': 'Taranga Hill,Mahesana,Gujarat', 'lon': '72.9227'},
      '283124': {'lat': '26.9233', 'name': 'Jajau,Agra,Uttar Pradesh', 'lon': '77.9168'},
      '756182': {'lat': '20.5196', 'name': 'Aliha,Baleswar,Odisha', 'lon': '84.7167'},
      '473222': {'lat': '24.3734', 'name': 'Kumbhraj,Guna,Madhya Pradesh', 'lon': '77.0484'},
      '721514': {'lat': '22.5813', 'name': 'Pukuria,West Midnapore,West Bengal', 'lon': '86.9207'},
      '394670': {'lat': '21.5979', 'name': 'Fort Songadh,Surat,Gujarat', 'lon': '73.1087'},
      '721515': {'lat': '22.6167', 'name': 'Silda,West Midnapore,West Bengal', 'lon': '86.8167'},
      '574146': {'lat': '13.0317', 'name': 'Mukka,Dakshina Kannada,Karnataka', 'lon': '74.8111'},
      '574145': {'lat': '12.9285', 'name': 'Addoor,Dakshina Kannada,Karnataka', 'lon': '74.9296'},
      '574144': {'lat': '12.988', 'name': 'Kuppepadavu,Dakshina Kannada,Karnataka', 'lon': '74.9609'},
      '574143': {'lat': '12.8667', 'name': 'Farangipet,Dakshina Kannada,Karnataka', 'lon': '74.9673'},
      '574142': {'lat': '13.1827', 'name': 'Bajpe Airport,Dakshina Kannada,Karnataka', 'lon': '74.8431'},
      '574141': {'lat': '13.4722', 'name': 'Elinje,Dakshina Kannada,Karnataka', 'lon': '74.8461'},
      '283141': {'lat': '27.0659', 'name': 'Keshari,Firozabad,Uttar Pradesh', 'lon': '78.8448'},
      '360430': {'lat': '22.138', 'name': 'Chichod,Rajkot,Gujarat', 'lon': '70.9749'},
      '805123': {'lat': '24.9062', 'name': 'Kenasari,Nawada,Bihar', 'lon': '84.7931'},
      '761027': {'lat': '19.4333', 'name': 'Santoshpur,Ganjam,Odisha', 'lon': '85.0833'},
      '283142': {'lat': '27.1792', 'name': 'Urawar--hastref,Firozabad,Uttar Pradesh', 'lon': '78.8002'},
      '574148': {'lat': '13.0472', 'name': 'Kateel,Dakshina Kannada,Karnataka', 'lon': '74.8695'},
      '504297': {'lat': '19.0479', 'name': 'Manikyapur,Adilabad,Telangana', 'lon': '79.1923'},
      '835216': {'lat': '22.4197', 'name': 'Mahil,West Singhbhum,Jharkhand', 'lon': '85.5196'},
      '796310': {'lat': '23.5044', 'name': 'Vanchengpui,Champhai,Mizoram', 'lon': '93.1602'},
      '627417': {'lat': '8.6794', 'name': 'Kuniyur,Tirunelveli,Tamil Nadu', 'lon': '77.5533'},
      '623517': {'lat': '9.2318', 'name': 'Kombuthi,Ramanathapuram,Tamil Nadu', 'lon': '78.7854'},
      '623711': {'lat': '9.3334', 'name': 'Pooseri,Ramanathapuram,Tamil Nadu', 'lon': '78.5972'},
      '283145': {'lat': '27.1775', 'name': 'Ketana Harsha,Firozabad,Uttar Pradesh', 'lon': '78.4601'},
      '221507': {'lat': '25.4489', 'name': 'Mendewa,Allahabad,Uttar Pradesh', 'lon': '81.9364'},
      '583277': {'lat': '16.2178', 'name': 'Kusthagi Kut,Gadag,Karnataka', 'lon': '76.4242'},
      '583276': {'lat': '15.9527', 'name': 'K U Campus,Ballari,Karnataka', 'lon': '76.1801'},
      '583275': {'lat': '15.9527', 'name': 'Vidyanagar (Bellary),Ballari,Karnataka', 'lon': '76.1801'},
      '273006': {'lat': '26.7899', 'name': 'Gita Vatika,Gorakhpur,Uttar Pradesh', 'lon': '83.32'},
      '461990': {'lat': '22.8705', 'name': 'Jamunia,Narsinghpur,Madhya Pradesh', 'lon': '78.9341'},
      '583279': {'lat': '16.0524', 'name': 'Sanganhal,Gadag,Karnataka', 'lon': '76.3578'},
      '583278': {'lat': '15.6627', 'name': 'Ballutagi,Koppal,Karnataka', 'lon': '75.8917'},
      '272162': {'lat': '26.4283', 'name': 'Rosaya Bazar,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.7121'},
      '476111': {'lat': '26.5849', 'name': 'Runhera,Morena,Madhya Pradesh', 'lon': '78.1504'},
      '284135': {'lat': '25.3731', 'name': 'Bijauli,Jhansi,Uttar Pradesh', 'lon': '78.545'},
      '284136': {'lat': '25.0904', 'name': 'Matatila,Lalitpur,Uttar Pradesh', 'lon': '78.3791'},
      '484120': {'lat': '23.6668', 'name': 'S.K. Nagar,Shahdol,Madhya Pradesh', 'lon': '81.1933'},
      '835210': {'lat': '23.3313', 'name': 'Ghunsuli,Ranchi,Jharkhand', 'lon': '85.3708'},
      '828303': {'lat': '23.8971', 'name': 'Shivabudih,Bokaro,Jharkhand', 'lon': '86.4412'},
      '713428': {'lat': '26.0652', 'name': 'Mohara,Purba Bardhaman,West Bengal', 'lon': '87.9058'},
      '805121': {'lat': '24.8296', 'name': 'Lkhamohna,Nawada,Bihar', 'lon': '85.6422'},
      '604601': {'lat': '12.2256', 'name': 'Mekkalur,Tiruvannamalai,Tamil Nadu', 'lon': '79.2256'},
      '713424': {'lat': '23.3733', 'name': 'Nole,Purba Bardhaman,West Bengal', 'lon': '88.0871'},
      '713427': {'lat': '23.6524', 'name': 'Gopalbera,Purba Bardhaman,West Bengal', 'lon': '87.801'},
      '713426': {'lat': '24.0711', 'name': 'Bhandardihi,Purba Bardhaman,West Bengal', 'lon': '87.7373'},
      '713421': {'lat': '23.4278', 'name': 'Tajpur,Purba Bardhaman,West Bengal', 'lon': '87.7677'},
      '713420': {'lat': '23.3733', 'name': 'Rondia,Purba Bardhaman,West Bengal', 'lon': '88.0871'},
      '713423': {'lat': '22.7463', 'name': 'Konakrishnapur,Purba Bardhaman,West Bengal', 'lon': '88.0746'},
      '713422': {'lat': '23.0258', 'name': 'Mangalpur,Purba Bardhaman,West Bengal', 'lon': '88.3583'},
      '623512': {'lat': '9.2881', 'name': 'Alagankulam,Ramanathapuram,Tamil Nadu', 'lon': '78.9143'},
      '637301': {'lat': '11.3592', 'name': 'Mavelipalayam,Namakkal,Tamil Nadu', 'lon': '78.0183'},
      '412412': {'lat': '18.8867', 'name': 'Kandali,Pune,Maharashtra', 'lon': '74.1044'},
      '305801': {'lat': '26.6293', 'name': 'Bandar Sindari,Ajmer,Rajasthan', 'lon': '74.8975'},
      '412410': {'lat': '19.7452', 'name': 'Sakori Tarfe Belha,Pune,Maharashtra', 'lon': '74.2202'},
      '412411': {'lat': '18.6843', 'name': 'Bori BK,Pune,Maharashtra', 'lon': '75.3725'},
      '845420': {'lat': '26.061', 'name': 'Koilhara,East Champaran,Bihar', 'lon': '85.0054'},
      '637303': {'lat': '11.4504', 'name': 'Mothaiyanur,Namakkal,Tamil Nadu', 'lon': '77.9709'},
      '416526': {'lat': '17.4896', 'name': 'Hiralge,Kolhapur,Maharashtra', 'lon': '74.3088'},
      '416527': {'lat': '17.4896', 'name': 'Tillarinagar,Kolhapur,Maharashtra', 'lon': '74.3088'},
      '416524': {'lat': '17.4896', 'name': 'Chendavan,Sindhudurg,Maharashtra', 'lon': '74.3088'},
      '416525': {'lat': '15.9876', 'name': 'Deulwada Nerur,Sindhudurg,Maharashtra', 'lon': '73.6452'},
      '416522': {'lat': '19.9048', 'name': 'Niwatiwadi,Sindhudurg,Maharashtra', 'lon': '75.657'},
      '416523': {'lat': '17.4896', 'name': 'Terawalewadi,Sindhudurg,Maharashtra', 'lon': '74.3088'},
      '416520': {'lat': '16.5807', 'name': 'Bibavane,Sindhudurg,Maharashtra', 'lon': '73.5943'},
      '416521': {'lat': '17.4896', 'name': 'Varde,Sindhudurg,Maharashtra', 'lon': '74.3088'},
      '212212': {'lat': '25.5291', 'name': 'Manauri AF,Allahabad,Uttar Pradesh', 'lon': '81.5825'},
      '212213': {'lat': '25.5157', 'name': 'Sikandarpur Bazha,Allahabad,Uttar Pradesh', 'lon': '81.6236'},
      '505472': {'lat': '17.9846', 'name': 'Shivarampalli,Karim Nagar,Telangana', 'lon': '79.2649'},
      '815357': {'lat': '24.1877', 'name': 'Baramsoli,Deoghar,Jharkhand', 'lon': '86.6304'},
      '212216': {'lat': '25.4101', 'name': 'Maohar,Allahabad,Uttar Pradesh', 'lon': '81.6878'},
      '212217': {'lat': '25.814', 'name': 'Ajhuwa Bazar ED,Allahabad,Uttar Pradesh', 'lon': '81.5006'},
      '416528': {'lat': '15.9787', 'name': 'Industrial Area Pinguli,Sindhudurg,Maharashtra', 'lon': '73.7047'},
      '416529': {'lat': '17.4896', 'name': 'Hodavade,Sindhudurg,Maharashtra', 'lon': '74.3088'},
      '383410': {'lat': '23.8106', 'name': 'Diyoli,Sabarkantha,Gujarat', 'lon': '73.0628'},
      '643212': {'lat': '11.4731', 'name': 'Gudalur Bazaar,Nilgiris,Tamil Nadu', 'lon': '76.3794'},
      '606603': {'lat': '12.2607', 'name': 'Ayyampalayam,Tiruvannamalai,Tamil Nadu', 'lon': '79.0515'},
      '606604': {'lat': '12.2607', 'name': 'Kiliyapattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.0515'},
      '643215': {'lat': '11.4', 'name': 'Shanthur,Nilgiris,Tamil Nadu', 'lon': '76.7'},
      '643216': {'lat': '11.4516', 'name': 'Kadinamalai,Nilgiris,Tamil Nadu', 'lon': '76.9297'},
      '643217': {'lat': '11.464', 'name': 'Konavakorai,Nilgiris,Tamil Nadu', 'lon': '76.8729'},
      '643218': {'lat': '11.2864', 'name': 'Thuthurmattam,Nilgiris,Tamil Nadu', 'lon': '76.7418'},
      '643219': {'lat': '11.2711', 'name': 'Kinnakorai,Nilgiris,Tamil Nadu', 'lon': '76.6429'},
      '813211': {'lat': '25.4396', 'name': 'Kadrachak,Munger,Bihar', 'lon': '86.6641'},
      '813210': {'lat': '25.266', 'name': 'Machipur,Bhagalpur,Bihar', 'lon': '87.1139'},
      '813214': {'lat': '25.2003', 'name': 'S.T.P. Kahalgaon,Bhagalpur,Bihar', 'lon': '86.6553'},
      '385310': {'lat': '24.476', 'name': 'Bhanjana,Banaskantha,Gujarat', 'lon': '71.9562'},
      '620014': {'lat': '10.7498', 'name': 'Boiler Project - TR,Tiruchirappalli,Tamil Nadu', 'lon': '78.7763'},
      '620017': {'lat': '10.8889', 'name': 'Tennur,Tiruchirappalli,Tamil Nadu', 'lon': '78.5704'},
      '620016': {'lat': '10.7505', 'name': 'Ordnance Estate - TR,Tiruchirappalli,Tamil Nadu', 'lon': '78.7763'},
      '620011': {'lat': '10.7498', 'name': 'Kilakalkandarkottai,Tiruchirappalli,Tamil Nadu', 'lon': '78.7763'},
      '620010': {'lat': '10.8209', 'name': 'Kamarajanagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7285'},
      '620013': {'lat': '10.7971', 'name': 'Koothappar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7923'},
      '620012': {'lat': '10.8202', 'name': 'Fathimanagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7028'},
      '507202': {'lat': '17.3276', 'name': 'Choppakatlapalem,Khammam,Telangana', 'lon': '80.5072'},
      '507203': {'lat': '17.1032', 'name': 'Vangaveedu,Khammam,Telangana', 'lon': '80.2539'},
      '507201': {'lat': '16.9595', 'name': 'Gatlagowraram,Khammam,Telangana', 'lon': '80.5359'},
      '620019': {'lat': '10.7498', 'name': 'Pappakurichi Kattur,Tiruchirappalli,Tamil Nadu', 'lon': '78.7763'},
      '620018': {'lat': '10.7498', 'name': 'Thillai Nagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7763'},
      '507204': {'lat': '17.0194', 'name': 'Motamarri,Khammam,Telangana', 'lon': '80.3972'},
      '201318': {'lat': '28.474388', 'name': 'Sector-1,36,Greater Noida PO', 'lon': '77.503990'},
      '201313': {'lat': '28.5408', 'name': 'Amity University,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.7205'},
      '201312': {'lat': '28.4373', 'name': 'Gautam Budh University,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.5392'},
      '201311': {'lat': '28.5183', 'name': 'Container Depot,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.5518'},
      '201310': {'lat': '28.4793', 'name': 'Alpha Greater Noida,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.5734'},
      '201317': {'lat': '28.5408', 'name': 'Sector-128 Noida,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.7205'},
      '201316': {'lat': '28.5408', 'name': 'Sector-122 Noida,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.7205'},
      '201315': {'lat': '28.5408', 'name': 'Gurjinder Vihar,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.7205'},
      '201314': {'lat': '28.5877', 'name': 'Chainsa,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.8448'},
      '686143': {'lat': '9.6875', 'name': 'Akkarapadom,Kottayam,Kerala', 'lon': '76.7789'},
      '680545': {'lat': '10.5733', 'name': 'Peramangalam,Thrissur,Kerala', 'lon': '76.1689'},
      '680544': {'lat': '10.6233', 'name': 'Kattakampal,Thrissur,Kerala', 'lon': '76.1344'},
      '680011': {'lat': '10.5296', 'name': 'Kanattukara,Thrissur,Kerala', 'lon': '76.1974'},
      '680546': {'lat': '10.6083', 'name': 'Kaiparamba,Thrissur,Kerala', 'lon': '76.139'},
      '680541': {'lat': '10.6024', 'name': 'Mundur-TC,Thrissur,Kerala', 'lon': '76.1752'},
      '686141': {'lat': '9.6875', 'name': 'Vaikom H.O,Kottayam,Kerala', 'lon': '76.7789'},
      '680543': {'lat': '10.5787', 'name': 'Korattikkara,Thrissur,Kerala', 'lon': '76.0789'},
      '680014': {'lat': '10.4712', 'name': 'Vettukad,Thrissur,Kerala', 'lon': '76.3162'},
      '142032': {'lat': '30.4786', 'name': 'Pherurain,Ludhiana,Punjab', 'lon': '75.8803'},
      '400089': {'lat': '19.1247', 'name': 'Tilak Nagar (Mumbai),Mumbai,Maharashtra', 'lon': '72.9488'},
      '143118': {'lat': '31.412', 'name': 'Rampur Bhootwind,Tarn Taran,Punjab', 'lon': '75.1525'},
      '142033': {'lat': '30.9172', 'name': 'Bhaini Araiyan,Ludhiana,Punjab', 'lon': '75.4762'},
      '281403': {'lat': '27.7226', 'name': 'Gidoh,Mathura,Uttar Pradesh', 'lon': '77.7038'},
      '733130': {'lat': '25.6771', 'name': 'Dadhikotbari,North Dinajpur,West Bengal', 'lon': '88.2228'},
      '733133': {'lat': '25.34285', 'name': 'Radhakrishnapur,South Dinajpur,West Bengal', 'lon': '88.77835'},
      '143119': {'lat': '31.7121', 'name': 'Ram Diwali Muslama,Amritsar,Punjab', 'lon': '75.1383'},
      '142030': {'lat': '30.7002', 'name': 'Kamalpura,Ludhiana,Punjab', 'lon': '75.8747'},
      '741234': {'lat': '23.0732', 'name': 'Gayeshpur,Nadia,West Bengal', 'lon': '88.2769'},
      '686146': {'lat': '9.6875', 'name': 'Vaikom Thekkenada,Kottayam,Kerala', 'lon': '76.7789'},
      '577160': {'lat': '13.273', 'name': 'Huigere,Chikkamagaluru,Karnataka', 'lon': '75.4853'},
      '562123': {'lat': '13.1398', 'name': 'Yentiganahalli,Bangalore Rural,Karnataka', 'lon': '77.4117'},
      '562120': {'lat': '12.9435', 'name': 'Harathi,Ramanagar,Karnataka', 'lon': '77.1773'},
      '562121': {'lat': '12.626', 'name': 'Terubeedi,Ramanagar,Karnataka', 'lon': '77.5357'},
      '562126': {'lat': '12.4506', 'name': 'Kadahalli,Ramanagar,Karnataka', 'lon': '77.1895'},
      '562127': {'lat': '13.2215', 'name': 'Motagondanahalli,Bangalore Rural,Karnataka', 'lon': '77.5394'},
      '110059': {'lat': '28.681', 'name': 'K-5 Extension Mohan  Garden,West Delhi,Delhi', 'lon': '77.247'},
      '110058': {'lat': '28.681', 'name': 'Jail Road (West Delhi),West Delhi,Delhi', 'lon': '77.247'},
      '110057': {'lat': '28.681', 'name': 'Vasant Vihar-1,South West Delhi,Delhi', 'lon': '77.247'},
      '110056': {'lat': '28.681', 'name': 'New Multan Nagar,North West Delhi,Delhi', 'lon': '77.247'},
      '110055': {'lat': '28.6417', 'name': 'Multani Dhanda,Central Delhi,Delhi', 'lon': '77.2132'},
      '110054': {'lat': '28.6921', 'name': 'Majnu ka Tila,North Delhi,Delhi', 'lon': '77.2245'},
      '110053': {'lat': '28.6866', 'name': 'New Usmanpur,East Delhi,Delhi', 'lon': '77.2732'},
      '110052': {'lat': '28.681', 'name': 'Nimri,North West Delhi,Delhi', 'lon': '77.247'},
      '110051': {'lat': '28.681', 'name': 'Krishna Nagar H.O,East Delhi,Delhi', 'lon': '77.247'},
      '110050': {'lat': '28.681', 'name': 'Safdarjung Sorting Delivery Office,South Delhi,Delhi', 'lon': '77.247'},
      '143115': {'lat': '31.5494', 'name': 'Manawala Kalan,Tarn Taran,Punjab', 'lon': '75.0492'},
      '450991': {'lat': '21.9744', 'name': 'Kehlari,East Nimar,Madhya Pradesh', 'lon': '76.3803'},
      '142034': {'lat': '30.7266', 'name': 'Manuke,Ludhiana,Punjab', 'lon': '75.4784'},
      '185132': {'lat': '33.3189', 'name': 'Swari,Rajauri,Jammu & Kashmir', 'lon': '74.3729'},
      '185133': {'lat': '33.4224', 'name': 'Post Graduate College Rajouri,Rajauri,Jammu & Kashmir', 'lon': '74.3076'},
      '185131': {'lat': '33.4869', 'name': 'Dassal Jattan,Rajauri,Jammu & Kashmir', 'lon': '74.4088'},
      '185135': {'lat': '33.4645', 'name': 'Raikban,Rajauri,Jammu & Kashmir', 'lon': '74.4101'},
      '127114': {'lat': '28.807', 'name': 'Bamla,Bhiwani,Haryana', 'lon': '76.2435'},
      '209503': {'lat': '27.4648', 'name': 'Kuiyan Khera,Fatehpur,Uttar Pradesh', 'lon': '79.4585'},
      '127111': {'lat': '28.8185', 'name': 'Bapora,Bhiwani,Haryana', 'lon': '76.0672'},
      '614630': {'lat': '10.1228', 'name': 'Singavanam,Pudukkottai,Tamil Nadu', 'lon': '79.0903'},
      '145024': {'lat': '32.3679', 'name': 'Madhopur (Gurdaspur),Pathankot,Punjab', 'lon': '75.6049'},
      '581384': {'lat': '14.2414', 'name': 'Nagarbastikeri,Uttara Kannada,Karnataka', 'lon': '74.651'},
      '142039': {'lat': '30.7006', 'name': 'Baude,Moga,Punjab', 'lon': '75.3028'},
      '493662': {'lat': '20.7494', 'name': 'Magarlod,Dhamtari,Chattisgarh', 'lon': '81.8515'},
      '145026': {'lat': '32.2752', 'name': 'Narot Jailmal Singh,Pathankot,Punjab', 'lon': '75.4403'},
      '501141': {'lat': '17.1581', 'name': 'Gunj,K.V.Rangareddy,Telangana', 'lon': '78.2275'},
      '848201': {'lat': '25.6154', 'name': 'Ghagrha,Begusarai,Bihar', 'lon': '86.0433'},
      '124146': {'lat': '28.482', 'name': 'Mundahera,Jhajjar,Haryana', 'lon': '76.4931'},
      '721149': {'lat': '23.0979', 'name': 'Mahatabnagar,West Midnapore,West Bengal', 'lon': '88.2441'},
      '670571': {'lat': '12.1999', 'name': 'Rayarom,Kannur,Kerala', 'lon': '75.2634'},
      '124142': {'lat': '28.4821', 'name': 'Khorra,Jhajjar,Haryana', 'lon': '76.3036'},
      '848202': {'lat': '25.6212', 'name': 'Phaphaut,Begusarai,Bihar', 'lon': '86.0679'},
      '124141': {'lat': '28.4763', 'name': 'Power Plant Jharli,Rohtak,Haryana', 'lon': '76.3987'},
      '505471': {'lat': '18.2013', 'name': 'Mutharam,Karim Nagar,Telangana', 'lon': '79.3169'},
      '490001': {'lat': '21.2724', 'name': 'Jawahar Market Bhilai,Durg,Chattisgarh', 'lon': '81.4358'},
      '848203': {'lat': '25.5122', 'name': 'Bahadurpur,Khagaria,Bihar', 'lon': '86.6031'},
      '508112': {'lat': '17.3671', 'name': 'Velwarthy,Nalgonda,Telangana', 'lon': '79.0848'},
      '490006': {'lat': '21.2724', 'name': 'Maroda Bhilai,Durg,Chattisgarh', 'lon': '81.4358'},
      '301035': {'lat': '27.2544', 'name': 'Salwari,Alwar,Rajasthan', 'lon': '76.801'},
      '244103': {'lat': '28.826', 'name': 'Majhola (Moradabad),Moradabad,Uttar Pradesh', 'lon': '78.8117'},
      '244102': {'lat': '28.842', 'name': 'Deorhi Urf Hadipur,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.6587'},
      '244105': {'lat': '28.9019', 'name': 'Vikas Bhavan Moradabad,Moradabad,Uttar Pradesh', 'lon': '78.6834'},
      '301030': {'lat': '27.5091', 'name': 'Salpur,Alwar,Rajasthan', 'lon': '76.7077'},
      '144101': {'lat': '31.4068', 'name': 'Patara (Jalandhar),Jalandhar,Punjab', 'lon': '75.6522'},
      '382320': {'lat': '23.3442', 'name': 'Madhavgadh,Gandhi Nagar,Gujarat', 'lon': '72.729'},
      '382321': {'lat': '23.3217', 'name': 'Giyod,Gandhi Nagar,Gujarat', 'lon': '72.7807'},
      '140201': {'lat': '30.6062', 'name': 'Mubarakpur (Patiala),Mohali,Punjab', 'lon': '76.8363'},
      '848206': {'lat': '25.7121', 'name': 'Barra,Samastipur,Bihar', 'lon': '85.8872'},
      '509120': {'lat': '16.2588', 'name': 'Mahmadapur,Mahabub Nagar,Telangana', 'lon': '78.218'},
      '848207': {'lat': '25.6992', 'name': 'Sihma,Samastipur,Bihar', 'lon': '86.2446'},
      '509125': {'lat': '16.7275', 'name': 'Gadwal H.O,Mahabub Nagar,Telangana', 'lon': '78.2037'},
      '509127': {'lat': '16.8417', 'name': 'Medikonda,Mahabub Nagar,Telangana', 'lon': '77.8547'},
      '222138': {'lat': '25.6579', 'name': 'Pariyawan,Jaunpur,Uttar Pradesh', 'lon': '82.7028'},
      '500092': {'lat': '17.3535', 'name': 'Boduppal,Hyderabad,Telangana', 'lon': '78.2402'},
      '500093': {'lat': '17.3535', 'name': 'Vikasnagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.2402'},
      '500090': {'lat': '17.5371', 'name': 'Bachupally,K.V.Rangareddy,Telangana', 'lon': '78.3728'},
      '833103': {'lat': '22.5292', 'name': 'Kairam,West Singhbhum,Jharkhand', 'lon': '85.3759'},
      '500096': {'lat': '17.3535', 'name': 'Film Nagar,Hyderabad,Telangana', 'lon': '78.2402'},
      '263151': {'lat': '29.3158', 'name': 'Shaktifarm,Udham Singh Nagar,Uttarakhand', 'lon': '79.385'},
      '500094': {'lat': '17.3535', 'name': 'Sainikpuri,Hyderabad,Telangana', 'lon': '78.2402'},
      '396126': {'lat': '21.7065', 'name': 'Nevri,Valsad,Gujarat', 'lon': '73.4056'},
      '497450': {'lat': '23.1316', 'name': 'Khadgawan,Koriya,Chattisgarh', 'lon': '82.2859'},
      '451113': {'lat': '22.252617', 'name': 'Rahatkot,West Nimar,Madhya Pradesh', 'lon': '76.040421'},
      '224190': {'lat': '26.4295', 'name': 'Pirthivipur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4976'},
      '451111': {'lat': '22.18109', 'name': 'Ali Bujurg,West Nimar,Madhya Pradesh', 'lon': '76.0978'},
      '733202': {'lat': '26.2654', 'name': 'Jagtagaon,North Dinajpur,West Bengal', 'lon': '88.1898'},
      '451115': {'lat': '22.38428', 'name': 'Kithud,West Nimar,Madhya Pradesh', 'lon': '76.10983'},
      '485661': {'lat': '24.4195', 'name': 'Karhikala,Satna,Madhya Pradesh', 'lon': '80.7799'},
      '766029': {'lat': '21.2513', 'name': 'Rengsapali,Kalahandi,Odisha', 'lon': '83.796'},
      '766028': {'lat': '19.5804', 'name': 'Bardanga,Kalahandi,Odisha', 'lon': '82.6231'},
      '331501': {'lat': '27.836', 'name': 'Dunkar,Churu,Rajasthan', 'lon': '74.3178'},
      '524323': {'lat': '14.2532', 'name': 'Doruvulapalem,Nellore,Andhra Pradesh', 'lon': '80.0324'},
      '392140': {'lat': '22.7493', 'name': 'Pahaj,Bharuch,Gujarat', 'lon': '73.0678'},
      '700161': {'lat': '22.7398', 'name': 'New Town Action Area II,North 24 Parganas,West Bengal', 'lon': '88.3282'},
      '766020': {'lat': '19.9434', 'name': 'Bandhapari,Kalahandi,Odisha', 'lon': '83.5827'},
      '766023': {'lat': '19.9743', 'name': 'Nandagaon,Kalahandi,Odisha', 'lon': '83.6877'},
      '572133': {'lat': '13.631', 'name': 'Chinnenahally,Tumakuru,Karnataka', 'lon': '77.1958'},
      '766027': {'lat': '19.6711', 'name': 'Basantapada,Kalahandi,Odisha', 'lon': '83.8582'},
      '766026': {'lat': '19.3495', 'name': 'Ranibanhal,Kalahandi,Odisha', 'lon': '82.8777'},
      '627102': {'lat': '8.4553', 'name': 'Dohnavur,Tirunelveli,Tamil Nadu', 'lon': '77.5869'},
      '627103': {'lat': '8.4542', 'name': 'Tiruvengadanathapuram,Tirunelveli,Tamil Nadu', 'lon': '77.6059'},
      '387310': {'lat': '22.6112', 'name': 'Lambhvel,Anand,Gujarat', 'lon': '72.9328'},
      '627101': {'lat': '8.4042', 'name': 'Nallankulam,Tirunelveli,Tamil Nadu', 'lon': '77.6324'},
      '627106': {'lat': '8.6568', 'name': 'Erukkanthurai,Tirunelveli,Tamil Nadu', 'lon': '77.8918'},
      '627107': {'lat': '8.4309', 'name': 'Naduchalaiputhur,Tirunelveli,Tamil Nadu', 'lon': '77.6714'},
      '627104': {'lat': '8.1772', 'name': 'Vijayapathi,Tirunelveli,Tamil Nadu', 'lon': '77.7431'},
      '387315': {'lat': '22.6112', 'name': 'Chaklashi,Kheda,Gujarat', 'lon': '72.9328'},
      '627108': {'lat': '8.7987', 'name': 'Surangudi,Tirunelveli,Tamil Nadu', 'lon': '77.542'},
      '627109': {'lat': '10.4684', 'name': 'Sriregunathapuram,Tirunelveli,Tamil Nadu', 'lon': '79.846'},
      '263152': {'lat': '29.3287', 'name': 'Subashnagar,Udham Singh Nagar,Uttarakhand', 'lon': '79.0603'},
      '833105': {'lat': '22.8449', 'name': 'Jarakel,Seraikela-kharsawan,Jharkhand', 'lon': '85.9516'},
      '673640': {'lat': '11.246', 'name': 'Karumarakkad,Malappuram,Kerala', 'lon': '75.9552'},
      '673641': {'lat': '11.1885', 'name': 'Palakkad(MLP),Malappuram,Kerala', 'lon': '76.0108'},
      '673642': {'lat': '11.1484', 'name': 'Olamathil,Malappuram,Kerala', 'lon': '76.0483'},
      '624712': {'lat': '10.4837', 'name': 'Kuthiluppai,Dindigul,Tamil Nadu', 'lon': '77.9689'},
      '673645': {'lat': '11.2806', 'name': 'Chikkode,Malappuram,Kerala', 'lon': '75.8895'},
      '624710': {'lat': '10.5464', 'name': 'Jenankottai,Dindigul,Tamil Nadu', 'lon': '77.9203'},
      '673647': {'lat': '11.1808', 'name': 'Calicut Airport,Malappuram,Kerala', 'lon': '76.0015'},
      '678612': {'lat': '10.8064', 'name': 'Parli PG,Palakkad,Kerala', 'lon': '76.546'},
      '678613': {'lat': '10.7848', 'name': 'Mankarai,Palakkad,Kerala', 'lon': '76.5036'},
      '678611': {'lat': '10.7829', 'name': 'Chandrasekharapuram,Palakkad,Kerala', 'lon': '76.5761'},
      '263646': {'lat': '29.7206', 'name': 'Kotuli Malli,Almora,Uttarakhand', 'lon': '79.2442'},
      '841508': {'lat': '26.2905', 'name': 'Sahdi Giri,Gopalganj,Bihar', 'lon': '84.6927'},
      '841509': {'lat': '25.7917', 'name': 'Panjwar,Siwan,Bihar', 'lon': '84.5166'},
      '841504': {'lat': '26.0019', 'name': 'Raghunathpur (Siwan),Siwan,Bihar', 'lon': '84.2753'},
      '841505': {'lat': '26.5321', 'name': 'Sasamusa,Gopalganj,Bihar', 'lon': '84.3607'},
      '841506': {'lat': '26.1582', 'name': 'Dindayalpur,Siwan,Bihar', 'lon': '84.5161'},
      '841507': {'lat': '26.1218', 'name': 'Bherwania,Siwan,Bihar', 'lon': '84.6134'},
      '841501': {'lat': '26.5195', 'name': 'Durgmatihania,Gopalganj,Bihar', 'lon': '84.4'},
      '841502': {'lat': '26.031', 'name': 'Khujhwa,Siwan,Bihar', 'lon': '84.2244'},
      '841503': {'lat': '26.5685', 'name': 'Semra Bazar,Gopalganj,Bihar', 'lon': '84.4351'},
      '676508': {'lat': '11.0128', 'name': 'Thennala,Malappuram,Kerala', 'lon': '76.0669'},
      '676509': {'lat': '11.0785', 'name': 'Munduparmba,Malappuram,Kerala', 'lon': '76.1184'},
      '401605': {'lat': '19.7623', 'name': 'Sukhasale,Thane,Maharashtra', 'lon': '73.5785'},
      '401604': {'lat': '19.5422', 'name': 'Vashala,Thane,Maharashtra', 'lon': '74.7254'},
      '401603': {'lat': '19.9243', 'name': 'Valvande,Thane,Maharashtra', 'lon': '73.3195'},
      '401602': {'lat': '19.7623', 'name': 'Karaj Gaon,Thane,Maharashtra', 'lon': '73.5785'},
      '401601': {'lat': '19.9678', 'name': 'Badapokharan,Thane,Maharashtra', 'lon': '72.7126'},
      '247451': {'lat': '29.0158', 'name': 'Bhankla,Saharanpur,Uttar Pradesh', 'lon': '78.0079'},
      '676501': {'lat': '10.9855', 'name': 'Klari,Malappuram,Kerala', 'lon': '75.9679'},
      '676502': {'lat': '11.0128', 'name': 'Paravanna,Malappuram,Kerala', 'lon': '76.0669'},
      '676503': {'lat': '11.05', 'name': 'Indianur,Malappuram,Kerala', 'lon': '76.036'},
      '676504': {'lat': '11.0128', 'name': 'Chattipparamba,Malappuram,Kerala', 'lon': '76.0669'},
      '676505': {'lat': '11.0128', 'name': 'Malappuram H.O,Malappuram,Kerala', 'lon': '76.0669'},
      '676506': {'lat': '11.0103', 'name': 'Padinhattumuri,Malappuram,Kerala', 'lon': '76.1043'},
      '676507': {'lat': '11.0137', 'name': 'Makkaraparamba,Malappuram,Kerala', 'lon': '76.1321'},
      '306706': {'lat': '25.13', 'name': 'Barwa,Pali,Rajasthan', 'lon': '73.3049'},
      '363510': {'lat': '22.8543', 'name': 'Kalmad,Surendra Nagar,Gujarat', 'lon': '71.1087'},
      '176039': {'lat': '31.4635435', 'name': 'Deotsidh,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5611978'},
      '176038': {'lat': '32.0521', 'name': 'Jalari,Kangra,Himachal Pradesh', 'lon': '76.2698'},
      '535281': {'lat': '17.9843', 'name': 'Banadi,Vizianagaram,Andhra Pradesh', 'lon': '83.0836'},
      '535280': {'lat': '18.2219', 'name': 'Kondagumpam,Vizianagaram,Andhra Pradesh', 'lon': '83.416'},
      '757003': {'lat': '21.5242', 'name': 'Baghra Road,Mayurbhanj,Odisha', 'lon': '85.6169'},
      '757002': {'lat': '21.5242', 'name': 'Bhanjpur,Mayurbhanj,Odisha', 'lon': '85.6169'},
      '176037': {'lat': '32.0254', 'name': 'Luhna,Kangra,Himachal Pradesh', 'lon': '76.305'},
      '176036': {'lat': '31.7039', 'name': 'Sudhangal,Kangra,Himachal Pradesh', 'lon': '76.4101'},
      '176031': {'lat': '31.9453', 'name': 'Gummar,Kangra,Himachal Pradesh', 'lon': '76.5591'},
      '176030': {'lat': '31.913', 'name': 'Nahlian,Kangra,Himachal Pradesh', 'lon': '76.3847'},
      '176033': {'lat': '31.9832', 'name': 'Nandpur Bhatoli,Kangra,Himachal Pradesh', 'lon': '76.227'},
      '176032': {'lat': '31.8808', 'name': 'Majhin,Kangra,Himachal Pradesh', 'lon': '76.3945'},
      '535579': {'lat': '18.4753', 'name': 'Busayavalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.2875'},
      '535578': {'lat': '18.4673', 'name': 'Mallampeta,Vizianagaram,Andhra Pradesh', 'lon': '83.3793'},
      '306705': {'lat': '25.1888', 'name': 'Morkha,Pali,Rajasthan', 'lon': '73.3763'},
      '306912': {'lat': '25.3608', 'name': 'Tagatgarh,Pali,Rajasthan', 'lon': '73.0138'},
      '229135': {'lat': '26.1055', 'name': 'Satanpurwa,Raebareli,Uttar Pradesh', 'lon': '81.9977'},
      '306702': {'lat': '25.1927', 'name': 'Mada,Pali,Rajasthan', 'lon': '73.4417'},
      '535573': {'lat': '18.3655', 'name': 'Dibbaguddivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.2753'},
      '273311': {'lat': '26.998', 'name': 'Orwalliya,Maharajganj,Uttar Pradesh', 'lon': '83.4665'},
      '834013': {'lat': '23.299', 'name': 'Nodal Mechanized Delivery Office,Ranchi,Jharkhand', 'lon': '85.3809'},
      '284121': {'lat': '25.5053', 'name': 'Dhamnakhurd,Jhansi,Uttar Pradesh', 'lon': '78.7369'},
      '754071': {'lat': '20.4178', 'name': 'Kapaleswar,Cuttack,Odisha', 'lon': '85.2538'},
      '141401': {'lat': '30.6976', 'name': 'Nai Mandi Khanna,Ludhiana,Punjab', 'lon': '76.1274'},
      '799278': {'lat': '23.9923', 'name': 'Mendihaor,Dhalai,Tripura', 'lon': '91.6205'},
      '457555': {'lat': '22.5996', 'name': 'Chhaoni Dodia,Ratlam,Madhya Pradesh', 'lon': '76.3935'},
      '263150': {'lat': '29.3158', 'name': 'Kelakhera,Udham Singh Nagar,Uttarakhand', 'lon': '79.385'},
      '224149': {'lat': '26.3952', 'name': 'Jalalpur (Ambedkar Nagar),Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5281'},
      '331701': {'lat': '28.874', 'name': 'Dhanoti Chhoti,Churu,Rajasthan', 'lon': '75.3097'},
      '212104': {'lat': '25.1447', 'name': 'Trisentulapur,Allahabad,Uttar Pradesh', 'lon': '82.4043'},
      '175019': {'lat': '31.6421', 'name': 'Purana Nagar,Mandi,Himachal Pradesh', 'lon': '76.9693'},
      '464668': {'lat': '22.8307', 'name': 'Kamtone,Raisen,Madhya Pradesh', 'lon': '77.7855'},
      '571254': {'lat': '12.2058', 'name': 'Thithimathi,Kodagu,Karnataka', 'lon': '75.9869'},
      '571250': {'lat': '11.9714', 'name': 'Nagarahole,Kodagu,Karnataka', 'lon': '76.048'},
      '571251': {'lat': '12.7186', 'name': 'Moovathoklu,Kodagu,Karnataka', 'lon': '75.95'},
      '571252': {'lat': '12.8961', 'name': 'S.Kattemadu,Kodagu,Karnataka', 'lon': '75.7852'},
      '571253': {'lat': '12.6458', 'name': 'Arecad,Kodagu,Karnataka', 'lon': '75.914'},
      '391140': {'lat': '22.9273', 'name': 'Panej,Vadodara,Gujarat', 'lon': '73.2397'},
      '571342': {'lat': '12.0978', 'name': 'Chamarajanagara Pwd Colony,Chamrajnagar,Karnataka', 'lon': '76.7971'},
      '391145': {'lat': '22.1794', 'name': 'Gundicha,Vadodara,Gujarat', 'lon': '73.242'},
      '635124': {'lat': '12.5786', 'name': 'Belathur,Krishnagiri,Tamil Nadu', 'lon': '78.1851'},
      '700162': {'lat': '22.7398', 'name': 'Gouranganagar,North 24 Parganas,West Bengal', 'lon': '88.3282'},
      '212105': {'lat': '25.3828', 'name': 'Chheoki,Allahabad,Uttar Pradesh', 'lon': '81.861'},
      '209115': {'lat': '26.3411', 'name': 'Mubarakpur,Kanpur Dehat,Uttar Pradesh', 'lon': '79.693'},
      '224143': {'lat': '26.4104', 'name': 'Sabukpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.8642'},
      '209111': {'lat': '26.3865', 'name': 'Akauri,Kanpur Dehat,Uttar Pradesh', 'lon': '79.509'},
      '306708': {'lat': '25.4241', 'name': 'Pawa,Pali,Rajasthan', 'lon': '73.079'},
      '209112': {'lat': '26.1973', 'name': 'Selhoopur,Kanpur Dehat,Uttar Pradesh', 'lon': '79.8009'},
      '306709': {'lat': '25.2435', 'name': 'Balada,Pali,Rajasthan', 'lon': '73.3073'},
      '793126': {'lat': '25.5333', 'name': 'Mawthengkut,East Khasi Hills,Meghalaya', 'lon': '91.6473'},
      '793120': {'lat': '25.6176', 'name': 'Dungki - Ingdong,West Khasi Hills,Meghalaya', 'lon': '91.597'},
      '793121': {'lat': '25.4491', 'name': 'Weiloi,East Khasi Hills,Meghalaya', 'lon': '91.6976'},
      '793122': {'lat': '25.5333', 'name': 'Ucc,Ri Bhoi,Meghalaya', 'lon': '91.6473'},
      '793123': {'lat': '25.5333', 'name': 'Umsaw,Ri Bhoi,Meghalaya', 'lon': '91.6473'},
      '742306': {'lat': '23.7144', 'name': 'Char Rajapur,Murshidabad,West Bengal', 'lon': '88.5022'},
      '742304': {'lat': '24.2045', 'name': 'Sarangpur,Murshidabad,West Bengal', 'lon': '88.1546'},
      '742305': {'lat': '23.5965', 'name': 'D.Pareshpur,Murshidabad,West Bengal', 'lon': '88.2235'},
      '742302': {'lat': '24.5109', 'name': 'Daulatabad (Murshidabad),Murshidabad,West Bengal', 'lon': '87.9004'},
      '742303': {'lat': '23.9387', 'name': 'Garaimari,Murshidabad,West Bengal', 'lon': '87.6178'},
      '742301': {'lat': '24.6125', 'name': 'Bharatpur (Murshidabad),Murshidabad,West Bengal', 'lon': '87.8092'},
      '742308': {'lat': '25.0226', 'name': 'Kangali Mondalpara,Murshidabad,West Bengal', 'lon': '87.9393'},
      '246275': {'lat': '29.9366', 'name': 'Bharnao,Pauri Garhwal,Uttarakhand', 'lon': '79.0826'},
      '457550': {'lat': '23.6716', 'name': 'Sakraoda,Ratlam,Madhya Pradesh', 'lon': '75.5935'},
      '246277': {'lat': '29.8088', 'name': 'Andrauli,Pauri Garhwal,Uttarakhand', 'lon': '79.1732'},
      '456006': {'lat': '23.4977', 'name': 'Panthpiplai,Ujjain,Madhya Pradesh', 'lon': '75.6802'},
      '152033': {'lat': '30.631', 'name': 'Chak Kherewala,Firozpur,Punjab', 'lon': '74.4798'},
      '246279': {'lat': '29.6921', 'name': 'Gaulikhal,Pauri Garhwal,Uttarakhand', 'lon': '79.1013'},
      '246278': {'lat': '29.8984', 'name': 'Seramalla,Pauri Garhwal,Uttarakhand', 'lon': '79.0324'},
      '628753': {'lat': '8.6491', 'name': 'Sivagalai,Tuticorin,Tamil Nadu', 'lon': '77.9978'},
      '534156': {'lat': '16.9517', 'name': 'Surya Rao Palem,West Godavari,Andhra Pradesh', 'lon': '81.6802'},
      '271503': {'lat': '27.0975', 'name': 'Selhari,Gonda,Uttar Pradesh', 'lon': '81.8162'},
      '271502': {'lat': '27.0325', 'name': 'Usrair,Gonda,Uttar Pradesh', 'lon': '81.8089'},
      '227811': {'lat': '26.5882', 'name': 'Sukul Ka Bazar,Sultanpur,Uttar Pradesh', 'lon': '81.5947'},
      '450551': {'lat': '22.0652', 'name': 'Bhomwada,East Nimar,Madhya Pradesh', 'lon': '76.2858'},
      '201001': {'lat': '28.6275', 'name': 'Ghaziabad H.O,Ghaziabad,Uttar Pradesh', 'lon': '77.621'},
      '388330': {'lat': '22.7895', 'name': 'Khambholej,Anand,Gujarat', 'lon': '72.8631'},
      '496005': {'lat': '21.8764', 'name': 'Gorkha,Raigarh,Chattisgarh', 'lon': '83.3774'},
      '388335': {'lat': '22.7895', 'name': 'Tranol,Anand,Gujarat', 'lon': '72.8631'},
      '229130': {'lat': '26.1315', 'name': 'Malik Mau Chaubara,Raebareli,Uttar Pradesh', 'lon': '81.4766'},
      '364760': {'lat': '21.9762', 'name': 'Zinzavadar Nana,Bhavnagar,Gujarat', 'lon': '71.7407'},
      '521153': {'lat': '16.4324', 'name': 'Kolavennu,Krishna,Andhra Pradesh', 'lon': '80.7955'},
      '521150': {'lat': '16.2871', 'name': 'Kaja,Krishna,Andhra Pradesh', 'lon': '80.0181'},
      '521151': {'lat': '16.426', 'name': 'Proddutur,Krishna,Andhra Pradesh', 'lon': '80.7538'},
      '521156': {'lat': '16.2667', 'name': 'Elakurru,Krishna,Andhra Pradesh', 'lon': '81.0333'},
      '521157': {'lat': '16.2763', 'name': 'Perisepalli,Krishna,Andhra Pradesh', 'lon': '80.8264'},
      '584122': {'lat': '16.1051', 'name': 'Yeachnal,Raichur,Karnataka', 'lon': '76.5655'},
      '584123': {'lat': '16.076', 'name': 'Harvi,Raichur,Karnataka', 'lon': '77.0655'},
      '521158': {'lat': '16.3516', 'name': 'Palankipadu,Krishna,Andhra Pradesh', 'lon': '80.7551'},
      '832101': {'lat': '22.2500304', 'name': 'Angarpara,East Singhbhum,Jharkhand', 'lon': '86.7497997'},
      '584126': {'lat': '15.4437', 'name': 'Sunkeshwarhal,Raichur,Karnataka', 'lon': '75.9684'},
      '584127': {'lat': '16.2827', 'name': 'Nawli,Raichur,Karnataka', 'lon': '76.4967'},
      '584124': {'lat': '15.8996', 'name': 'Nagarabenchi,Raichur,Karnataka', 'lon': '76.2968'},
      '584125': {'lat': '15.9096', 'name': 'Bannigol,Raichur,Karnataka', 'lon': '76.2346'},
      '303305': {'lat': '26.571', 'name': 'Badwa,Jaipur,Rajasthan', 'lon': '75.9526'},
      '303304': {'lat': '26.9329', 'name': 'Peelwa Kalan,Dausa,Rajasthan', 'lon': '76.5191'},
      '228132': {'lat': '26.504', 'name': 'Kachha Bhitaura,Sultanpur,Uttar Pradesh', 'lon': '82.2221'},
      '635120': {'lat': '12.6082', 'name': 'Mallinayanapalli,Krishnagiri,Tamil Nadu', 'lon': '78.2825'},
      '394248': {'lat': '20.8937', 'name': 'Samba,Surat,Gujarat', 'lon': '73.2555'},
      '303303': {'lat': '26.7553', 'name': 'Chandrana,Dausa,Rajasthan', 'lon': '76.2568'},
      '303302': {'lat': '26.7471', 'name': 'Danau Kalan,Jaipur,Rajasthan', 'lon': '76.4023'},
      '394245': {'lat': '20.9346', 'name': 'Velanpur,Surat,Gujarat', 'lon': '73.2207'},
      '394246': {'lat': '20.9346', 'name': 'Jamania,Surat,Gujarat', 'lon': '73.2207'},
      '210202': {'lat': '25.2669', 'name': 'Titihara,Chitrakoot,Uttar Pradesh', 'lon': '80.7161'},
      '394240': {'lat': '20.9756', 'name': 'Karchelia,Surat,Gujarat', 'lon': '73.1858'},
      '828206': {'lat': '23.8656', 'name': 'Panchet Dam,Dhanbad,Jharkhand', 'lon': '86.3688'},
      '721647': {'lat': '22.7639', 'name': 'Purbachilka,East Midnapore,West Bengal', 'lon': '88.1081'},
      '828204': {'lat': '24.1741', 'name': 'Ledaharia,Dhanbad,Jharkhand', 'lon': '87.0645'},
      '828205': {'lat': '23.6152', 'name': 'Birsinghpur,Dhanbad,Jharkhand', 'lon': '85.9454'},
      '828202': {'lat': '23.7313', 'name': 'Merrah,Dhanbad,Jharkhand', 'lon': '86.7908'},
      '828203': {'lat': '24.2253', 'name': 'Kumardhubi,Dhanbad,Jharkhand', 'lon': '86.2596'},
      '226001': {'lat': '26.8507', 'name': 'Darul Safa,Lucknow,Uttar Pradesh', 'lon': '80.9942'},
      '505451': {'lat': '18.4827', 'name': 'Kothapalli (Karim Nagar),Karim Nagar,Telangana', 'lon': '79.064'},
      '263127': {'lat': '29.5423', 'name': 'Bist Estatre,Nainital,Uttarakhand', 'lon': '79.5944'},
      '226002': {'lat': '26.69', 'name': 'Bakkash,Lucknow,Uttar Pradesh', 'lon': '81.295'},
      '248171': {'lat': '30.2937', 'name': 'Kanwali,Dehradun,Uttarakhand', 'lon': '78.0035'},
      '228133': {'lat': '26.2157', 'name': 'Mirpur Saraiya,Sultanpur,Uttar Pradesh', 'lon': '82.2177'},
      '591307': {'lat': '15.928', 'name': 'Gokak Guruwarpeth,Belagavi,Karnataka', 'lon': '74.9885'},
      '825132': {'lat': '24.7426', 'name': 'Marchoi,Koderma,Jharkhand', 'lon': '85.7941'},
      '743128': {'lat': '22.8347', 'name': 'Athpur,North 24 Parganas,West Bengal', 'lon': '88.4655'},
      '643237': {'lat': '11.4236', 'name': 'Sandynallah,Nilgiris,Tamil Nadu', 'lon': '76.6838'},
      '226004': {'lat': '26.8346', 'name': 'Pandariba,Lucknow,Uttar Pradesh', 'lon': '80.9249'},
      '454552': {'lat': '22.3155', 'name': 'Bikhorn,Dhar,Madhya Pradesh', 'lon': '75.4796'},
      '505452': {'lat': '18.7632', 'name': 'Tattupalli,Karim Nagar,Telangana', 'lon': '78.9732'},
      '504323': {'lat': '19.1697', 'name': 'Waddur,Adilabad,Telangana', 'lon': '78.4702'},
      '504205': {'lat': '19', 'name': 'Mulmadugu,Adilabad,Telangana', 'lon': '78.7508'},
      '731202': {'lat': '24.1102', 'name': 'Laha,Birbhum,West Bengal', 'lon': '87.8629'},
      '504204': {'lat': '18.7553', 'name': 'Maddikal,Adilabad,Telangana', 'lon': '79.3866'},
      '226006': {'lat': '27.0365', 'name': 'Sant Market,Lucknow,Uttar Pradesh', 'lon': '79.6664'},
      '132113': {'lat': '29.3991', 'name': 'Allupur,Panipat,Haryana', 'lon': '76.6242'},
      '208023': {'lat': '26.418', 'name': 'T P Nagar,Kanpur Nagar,Uttar Pradesh', 'lon': '80.298'},
      '182101': {'lat': '33.2497', 'name': 'Omara Morh,Udhampur,Jammu & Kashmir', 'lon': '74.3491'},
      '132116': {'lat': '29.7789', 'name': 'Nariana,Karnal,Haryana', 'lon': '76.9168'},
      '132117': {'lat': '29.8888', 'name': 'Sidhpur,Karnal,Haryana', 'lon': '76.9616'},
      '132114': {'lat': '29.4478', 'name': 'Hari Singhpura Pundri,Karnal,Haryana', 'lon': '76.9621'},
      '132115': {'lat': '29.2269', 'name': 'Hathwala,Panipat,Haryana', 'lon': '77.1181'},
      '607105': {'lat': '11.5914', 'name': 'Nellikuppam Bazaar,Cuddalore,Tamil Nadu', 'lon': '79.5381'},
      '193101': {'lat': '34.1162', 'name': 'Khanpora,Baramulla,Jammu & Kashmir', 'lon': '74.2995'},
      '193103': {'lat': '34.1162', 'name': 'Wagoora,Baramulla,Jammu & Kashmir', 'lon': '74.2995'},
      '504201': {'lat': '18.5813', 'name': 'Parpally,Adilabad,Telangana', 'lon': '79.7109'},
      '193108': {'lat': '34.1162', 'name': 'Wanigam,Baramulla,Jammu & Kashmir', 'lon': '74.2995'},
      '193109': {'lat': '34.1162', 'name': 'Chandoosa,Baramulla,Jammu & Kashmir', 'lon': '74.2995'},
      '413130': {'lat': '18.3936', 'name': 'Madanwadi,Pune,Maharashtra', 'lon': '74.5945'},
      '509360': {'lat': '17.1819', 'name': 'Bhairampuram,Mahabub Nagar,Telangana', 'lon': '78.8275'},
      '614401': {'lat': '10.804', 'name': 'Chinnakadaitheru,Thanjavur,Tamil Nadu', 'lon': '79.3322'},
      '380005': {'lat': '23.0833', 'name': 'O N G C,Ahmedabad,Gujarat', 'lon': '72.6667'},
      '614403': {'lat': '10.7687', 'name': 'Chettichatram,Tiruvarur,Tamil Nadu', 'lon': '79.3644'},
      '614402': {'lat': '10.7497', 'name': 'Arundavapuram,Thanjavur,Tamil Nadu', 'lon': '79.3215'},
      '263142': {'lat': '29.0983', 'name': 'Halduchaur,Nainital,Uttarakhand', 'lon': '79.3622'},
      '263140': {'lat': '29.3004', 'name': 'Dewalchaur,Nainital,Uttarakhand', 'lon': '79.2876'},
      '380004': {'lat': '23.0333', 'name': 'Shahpur (Ahmedabad),Ahmedabad,Gujarat', 'lon': '72.6181'},
      '814120': {'lat': '24.549', 'name': 'Kaladumaria,Dumka,Jharkhand', 'lon': '87.0801'},
      '454773': {'lat': '22.5929', 'name': 'Dighthan,Dhar,Madhya Pradesh', 'lon': '75.6712'},
      '814122': {'lat': '24.549', 'name': 'Jhaunsa-Garti,Deoghar,Jharkhand', 'lon': '87.0801'},
      '385120': {'lat': '24.0317', 'name': 'Kuvarshi,Banaskantha,Gujarat', 'lon': '72.6943'},
      '782144': {'lat': '27.5467', 'name': 'Deorigaon,Karbi Anglon,Assam', 'lon': '94.7206'},
      '380003': {'lat': '23.0333', 'name': 'Delivery Hub Ahmedabad,Ahmedabad,Gujarat', 'lon': '72.6181'},
      '263148': {'lat': '29.0436', 'name': 'Suryanagar,Udham Singh Nagar,Uttarakhand', 'lon': '79.3182'},
      '274505': {'lat': '26.2871', 'name': 'D.B. Ashram,Deoria,Uttar Pradesh', 'lon': '83.6384'},
      '782140': {'lat': '26.416', 'name': 'Padumoni,Nagaon,Assam', 'lon': '92.8438'},
      '782141': {'lat': '26.3764', 'name': 'Barangatoli,Nagaon,Assam', 'lon': '92.796'},
      '782142': {'lat': '26.6409', 'name': 'Barmanipur,Nagaon,Assam', 'lon': '92.6828'},
      '782143': {'lat': '26.6031', 'name': 'Borghuli,Nagaon,Assam', 'lon': '92.9264'},
      '425418': {'lat': '21.1644', 'name': 'Dhanarat,Nandurbar,Maharashtra', 'lon': '74.101'},
      '425419': {'lat': '20.4304', 'name': 'Moramba,Nandurbar,Maharashtra', 'lon': '75.3512'},
      '262543': {'lat': '30.1119', 'name': 'Majhera,Pithoragarh,Uttarakhand', 'lon': '80.3477'},
      '770076': {'lat': '21.9022', 'name': 'Duduka,Sundergarh,Odisha', 'lon': '83.9244'},
      '262545': {'lat': '29.8214', 'name': 'Khet,Pithoragarh,Uttarakhand', 'lon': '80.3624'},
      '380001': {'lat': '23.0256', 'name': 'Khanpur (Ahmedabad),Ahmedabad,Gujarat', 'lon': '72.5769'},
      '770073': {'lat': '21.8438', 'name': 'Baragad,Sundergarh,Odisha', 'lon': '84.0556'},
      '770072': {'lat': '21.8481', 'name': 'Badbonga,Sundergarh,Odisha', 'lon': '84.0807'},
      '425410': {'lat': '21.4334', 'name': 'Anarad,Nandurbar,Maharashtra', 'lon': '74.5273'},
      '425411': {'lat': '21.3829', 'name': 'Manjare,Nandurbar,Maharashtra', 'lon': '74.4111'},
      '425412': {'lat': '21.107', 'name': 'Ashte,Nandurbar,Maharashtra', 'lon': '74.2661'},
      '425413': {'lat': '21.5808', 'name': 'Shirve,Dhule,Maharashtra', 'lon': '74.1631'},
      '425414': {'lat': '21.7533', 'name': 'Bijari,Nandurbar,Maharashtra', 'lon': '74.1757'},
      '425415': {'lat': '21.2857', 'name': 'Jamane,Nandurbar,Maharashtra', 'lon': '74.2573'},
      '425416': {'lat': '21.2868', 'name': 'Dogegaon,Nandurbar,Maharashtra', 'lon': '74.0519'},
      '425417': {'lat': '21.1615', 'name': 'Bandhare,Nandurbar,Maharashtra', 'lon': '74.0487'},
      '639008': {'lat': '10.4732', 'name': 'Poolampalayam,Karur,Tamil Nadu', 'lon': '76.9692'},
      '456222': {'lat': '23.0656', 'name': 'Ghudawan,Ujjain,Madhya Pradesh', 'lon': '76.3895'},
      '456224': {'lat': '23.3136', 'name': 'Batlawdi,Ujjain,Madhya Pradesh', 'lon': '75.3669'},
      '737128': {'lat': '27.136', 'name': 'Lungchuk,South Sikkim,Sikkim', 'lon': '88.34022'},
      '737126': {'lat': '27.17725', 'name': 'Nandugaon,South Sikkim,Sikkim', 'lon': '88.35085'},
      '639001': {'lat': '9.9116', 'name': 'Karur West,Karur,Tamil Nadu', 'lon': '77.608'},
      '639002': {'lat': '10.9422', 'name': 'Pallapalayam,Karur,Tamil Nadu', 'lon': '78.0018'},
      '639003': {'lat': '10.9098', 'name': 'Puthambur,Karur,Tamil Nadu', 'lon': '78.0464'},
      '382405': {'lat': '22.6504', 'name': 'Gyaspur,Ahmedabad,Gujarat', 'lon': '72.6974'},
      '639005': {'lat': '10.8298', 'name': 'Mookanankurichi,Karur,Tamil Nadu', 'lon': '78.0673'},
      '639006': {'lat': '11.0829', 'name': 'Vennamalai,Karur,Tamil Nadu', 'lon': '77.7953'},
      '639007': {'lat': '10.9112', 'name': 'Kathalapatti,Karur,Tamil Nadu', 'lon': '78.1091'},
      '629193': {'lat': '8.4393', 'name': 'Keezhkulam,Kanyakumari,Tamil Nadu', 'lon': '77.3313'},
      '140001': {'lat': '30.7402', 'name': 'Minisectt Ropar,Rupnagar,Punjab', 'lon': '76.4816'},
      '785699': {'lat': '26.9044', 'name': 'Numaligarh Refinery Project,Golaghat,Assam', 'lon': '94.633'},
      '785698': {'lat': '26.9044', 'name': 'Silakuti,Sibsagar,Assam', 'lon': '94.633'},
      '123001': {'lat': '28.0799', 'name': 'Madhana Kalan,Mahendragarh,Haryana', 'lon': '76.0612'},
      '785697': {'lat': '26.9528', 'name': 'Arjun Guri,Sibsagar,Assam', 'lon': '94.5406'},
      '785696': {'lat': '26.7962', 'name': 'Hatipoti Grant,Sibsagar,Assam', 'lon': '94.655'},
      '785691': {'lat': '27.0899', 'name': 'Bhojo,Golaghat,Assam', 'lon': '95.0234'},
      '785690': {'lat': '27.0246', 'name': 'Teokghat,Sibsagar,Assam', 'lon': '95.0163'},
      '785693': {'lat': '27.0157', 'name': 'Barpatra Te,Sibsagar,Assam', 'lon': '94.6644'},
      '532410': {'lat': '18.4949', 'name': 'Etcherla,Srikakulam,Andhra Pradesh', 'lon': '83.786'},
      '174305': {'lat': '31.5282984', 'name': 'Mehre,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4641212'},
      '174304': {'lat': '31.5255169', 'name': 'Knoh,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4606428'},
      '174307': {'lat': '31.3057', 'name': 'Thathoon,Una,Himachal Pradesh', 'lon': '76.8405'},
      '174306': {'lat': '31.425', 'name': 'Badehar,Una,Himachal Pradesh', 'lon': '76.3329'},
      '174301': {'lat': '31.6587', 'name': 'Chhatarpur,Una,Himachal Pradesh', 'lon': '76.1536'},
      '174303': {'lat': '31.2381', 'name': 'Dera Baba Rudra Nand,Una,Himachal Pradesh', 'lon': '76.6954'},
      '174302': {'lat': '32.0517', 'name': 'Satother,Una,Himachal Pradesh', 'lon': '76.1469'},
      '473330': {'lat': '24.6925', 'name': 'Bagulya,Ashok Nagar,Madhya Pradesh', 'lon': '77.588'},
      '393041': {'lat': '21.3036', 'name': 'Ambawadi (Bharuch),Bharuch,Gujarat', 'lon': '73.4506'},
      '393040': {'lat': '22.3543', 'name': 'Pangam,Bharuch,Gujarat', 'lon': '73.5464'},
      '174309': {'lat': '31.5255189', 'name': 'Bahina,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4606408'},
      '174308': {'lat': '31.5183', 'name': 'Kohdra,Una,Himachal Pradesh', 'lon': '77.0786'},
      '680751': {'lat': '10.6677', 'name': 'Nadathara,Thrissur,Kerala', 'lon': '76.3274'},
      '246124': {'lat': '29.8907', 'name': 'Jameli,Pauri Garhwal,Uttarakhand', 'lon': '78.6439'},
      '571416': {'lat': '12.6936', 'name': 'Gudigenahalli,Mandya,Karnataka', 'lon': '76.8288'},
      '571417': {'lat': '12.5102', 'name': 'Belakavadi,Mandya,Karnataka', 'lon': '76.9154'},
      '571415': {'lat': '12.693', 'name': 'Gendehosahalli,Mandya,Karnataka', 'lon': '76.5604'},
      '212621': {'lat': '25.8194', 'name': 'Gambhari,Fatehpur,Uttar Pradesh', 'lon': '80.7952'},
      '571418': {'lat': '12.9565', 'name': 'Arani,Mandya,Karnataka', 'lon': '76.7401'},
      '571419': {'lat': '12.4119', 'name': 'Keelaghatta,Mandya,Karnataka', 'lon': '76.6099'},
      '209863': {'lat': '26.5216', 'name': 'Rampur,Kanpur Dehat,Uttar Pradesh', 'lon': '79.8832'},
      '629003': {'lat': '8.1671', 'name': 'Peruvilai,Kanyakumari,Tamil Nadu', 'lon': '77.465'},
      '209862': {'lat': '25.3881', 'name': 'Akrampur,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '144311': {'lat': '31.1479', 'name': 'Dhesian Kahna,Jalandhar,Punjab', 'lon': '75.692'},
      '262531': {'lat': '29.826', 'name': 'Gartir,Pithoragarh,Uttarakhand', 'lon': '79.7015'},
      '202150': {'lat': '27.8309', 'name': 'Shahpur Madrak,Aligarh,Uttar Pradesh', 'lon': '78.2201'},
      '485551': {'lat': '24.0904', 'name': 'Jhingodar,Satna,Madhya Pradesh', 'lon': '80.5094'},
      '721467': {'lat': '22.1762', 'name': 'Chhatorkole,West Midnapore,West Bengal', 'lon': '87.6063'},
      '413544': {'lat': '18.745', 'name': 'Ajni (BK),Osmanabad,Maharashtra', 'lon': '76.4916'},
      '732213': {'lat': '24.7458', 'name': 'Pukhuria,Malda,West Bengal', 'lon': '87.9659'},
      '341518': {'lat': '26.4627', 'name': 'Bhanwal,Nagaur,Rajasthan', 'lon': '74.0564'},
      '341519': {'lat': '26.5351', 'name': 'Araksar,Nagaur,Rajasthan', 'lon': '74.9432'},
      '415730': {'lat': '18.2427', 'name': 'Humbri,Ratnagiri,Maharashtra', 'lon': '72.9877'},
      '689588': {'lat': '9.4478', 'name': 'Vaipur,Pathanamthitta,Kerala', 'lon': '76.7021'},
      '689589': {'lat': '9.4404', 'name': 'Punnavely,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689586': {'lat': '9.4404', 'name': 'Ezhumattoor,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689587': {'lat': '9.4404', 'name': 'Padimon,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689584': {'lat': '9.4404', 'name': 'Mallappally East,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689585': {'lat': '9.4404', 'name': 'Anikad Mallappally GDS,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689582': {'lat': '9.4404', 'name': 'Kottoor,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '689583': {'lat': '9.4404', 'name': 'Kallooppara,Pathanamthitta,Kerala', 'lon': '76.6564'},
      '341514': {'lat': '26.8511', 'name': 'Choliyas,Nagaur,Rajasthan', 'lon': '74.0309'},
      '689581': {'lat': '9.4329', 'name': 'Mundiappally,Pathanamthitta,Kerala', 'lon': '76.6107'},
      '732210': {'lat': '25.0957', 'name': 'Chakbahadurpur,Malda,West Bengal', 'lon': '87.9359'},
      '229202': {'lat': '26.2288', 'name': 'Kodra,Raebareli,Uttar Pradesh', 'lon': '81.2314'},
      '458118': {'lat': '24.1917', 'name': 'Booj,Neemuch,Madhya Pradesh', 'lon': '76.0654'},
      '785664': {'lat': '27.1513', 'name': 'Rupahi Mukh,Sibsagar,Assam', 'lon': '94.7565'},
      '603403': {'lat': '12.6498', 'name': 'Manampathi,Kanchipuram,Tamil Nadu', 'lon': '79.6585'},
      '785665': {'lat': '27.1513', 'name': 'Rudrasagar,Sibsagar,Assam', 'lon': '94.7565'},
      '732214': {'lat': '25.1228', 'name': 'Araidanga,Malda,West Bengal', 'lon': '87.9837'},
      '181102': {'lat': '32.5877', 'name': 'Shamka,Jammu,Jammu & Kashmir', 'lon': '74.7138'},
      '181101': {'lat': '32.634', 'name': 'Simble Camp,Jammu,Jammu & Kashmir', 'lon': '74.8191'},
      '383205': {'lat': '23.7898', 'name': 'Vaghpur,Sabarkantha,Gujarat', 'lon': '72.5636'},
      '209866': {'lat': '26.2786', 'name': 'Zamipur,Unnao,Uttar Pradesh', 'lon': '80.8227'},
      '785667': {'lat': '27.1513', 'name': 'Bhatiapar,Sibsagar,Assam', 'lon': '94.7565'},
      '607103': {'lat': '11.804', 'name': 'Kiliruppu,Cuddalore,Tamil Nadu', 'lon': '79.37'},
      '272182': {'lat': '26.7179', 'name': 'Sansarpur,Basti,Uttar Pradesh', 'lon': '82.4285'},
      '784010': {'lat': '26.5735', 'name': 'Tezpur Medical College,Sonitpur,Assam', 'lon': '91.1499'},
      '205265': {'lat': '27.3914', 'name': 'Lakhaura,Mainpuri,Uttar Pradesh', 'lon': '78.9564'},
      '828113': {'lat': '22.3314', 'name': 'Lakarka,Dhanbad,Jharkhand', 'lon': '85.396'},
      '505122': {'lat': '18.2623', 'name': 'Chelpur,Karim Nagar,Telangana', 'lon': '79.5135'},
      '743125': {'lat': '22.85', 'name': 'Gupter Bagan,North 24 Parganas,West Bengal', 'lon': '88.3833'},
      '477555': {'lat': '26.7353', 'name': 'Ahenti,Bhind,Madhya Pradesh', 'lon': '78.79'},
      '413209': {'lat': '17.8004', 'name': 'Uplai Khurd,Solapur,Maharashtra', 'lon': '75.6083'},
      '205263': {'lat': '27.3146', 'name': 'Ujhaiya- Fakirpur,Mainpuri,Uttar Pradesh', 'lon': '78.8762'},
      '532421': {'lat': '18.3099', 'name': 'Mabagam,Srikakulam,Andhra Pradesh', 'lon': '83.7606'},
      '503224': {'lat': '18.6175', 'name': 'Armoor H.O,Nizamabad,Telangana', 'lon': '78.3995'},
      '416401': {'lat': '16.791', 'name': 'Khatav,Sangli,Maharashtra', 'lon': '74.8051'},
      '458110': {'lat': '23.5585', 'name': 'Parda,Neemuch,Madhya Pradesh', 'lon': '75.6818'},
      '515731': {'lat': '14.8241', 'name': 'Garladinne,Ananthapur,Andhra Pradesh', 'lon': '77.5985'},
      '802113': {'lat': '25.6749', 'name': 'Chhotkarajpur,Buxar,Bihar', 'lon': '84.0874'},
      '802111': {'lat': '25.579', 'name': 'Nuaon,Buxar,Bihar', 'lon': '84.2366'},
      '802116': {'lat': '25.5964', 'name': 'Dahiwar,Buxar,Bihar', 'lon': '84.0552'},
      '802117': {'lat': '25.4914', 'name': 'Gagaura,Buxar,Bihar', 'lon': '84.1018'},
      '802114': {'lat': '25.344', 'name': 'Sukarwalia,Buxar,Bihar', 'lon': '84.343'},
      '802115': {'lat': '25.1814', 'name': 'Phaphadar,Buxar,Bihar', 'lon': '84.3481'},
      '802118': {'lat': '25.6306', 'name': 'Mansingh Patti Barkagaon,Buxar,Bihar', 'lon': '84.0825'},
      '802119': {'lat': '25.5332', 'name': 'Attaon,Buxar,Bihar', 'lon': '84.1415'},
      '201102': {'lat': '28.4902', 'name': 'Jaoli,Ghaziabad,Uttar Pradesh', 'lon': '77.7907'},
      '201103': {'lat': '28.4902', 'name': 'Tronica name,Ghaziabad,Uttar Pradesh', 'lon': '77.7907'},
      '424105': {'lat': '20.669', 'name': 'Bhadgaon,Jalgaon,Maharashtra', 'lon': '75.2294'},
      '424104': {'lat': '20.7912', 'name': 'Akhatwade,Jalgaon,Maharashtra', 'lon': '75.5294'},
      '424107': {'lat': '20.6168', 'name': 'Gudhe,Jalgaon,Maharashtra', 'lon': '75.028'},
      '424106': {'lat': '20.599', 'name': 'Dahiwad,Jalgaon,Maharashtra', 'lon': '74.9445'},
      '424101': {'lat': '20.5658', 'name': 'Chalisgaon Town,Jalgaon,Maharashtra', 'lon': '75.0922'},
      '635203': {'lat': '12.1067', 'name': 'Kalarpathi,Krishnagiri,Tamil Nadu', 'lon': '78.4153'},
      '424103': {'lat': '20.5377', 'name': 'Pimpri Budruk,Jalgaon,Maharashtra', 'lon': '75.3205'},
      '424102': {'lat': '20.3613', 'name': 'Vadale Vadali,Jalgaon,Maharashtra', 'lon': '75.3797'},
      '854339': {'lat': '26.4032', 'name': 'Lakshminia,Supaul,Bihar', 'lon': '87.0221'},
      '854338': {'lat': '26.0744', 'name': 'Saheban,Supaul,Bihar', 'lon': '87.3367'},
      '503225': {'lat': '18.148', 'name': 'Thimmapur,Nizamabad,Telangana', 'lon': '78.919'},
      '491230': {'lat': '20.5049', 'name': 'Kotagaon,Durg,Chattisgarh', 'lon': '81.0456'},
      '424109': {'lat': '20.3225', 'name': 'Chandora,Nashik,Maharashtra', 'lon': '74.8124'},
      '424108': {'lat': '20.4862', 'name': 'Malshevage,Jalgaon,Maharashtra', 'lon': '74.9347'},
      '585212': {'lat': '16.9715', 'name': 'Aurad,Kalaburagi,Karnataka', 'lon': '76.7615'},
      '400024': {'lat': '18.986', 'name': 'Nehru Nagar (Mumbai),Mumbai,Maharashtra', 'lon': '72.8259'},
      '400025': {'lat': '19.0164', 'name': 'New Prabhadevi Road,Mumbai,Maharashtra', 'lon': '72.8294'},
      '400026': {'lat': '18.9667', 'name': 'Gowalia Tank,Mumbai,Maharashtra', 'lon': '72.8'},
      '400027': {'lat': '18.986', 'name': 'V J B Udyan,Mumbai,Maharashtra', 'lon': '72.8259'},
      '400020': {'lat': '18.986', 'name': 'Central Building,Mumbai,Maharashtra', 'lon': '72.8259'},
      '400021': {'lat': '18.92839', 'name': 'Elephanta Caves Po,Raigarh(MH),Maharashtra', 'lon': '72.82235'},
      '400022': {'lat': '19.0333', 'name': 'Raoli Camp,Mumbai,Maharashtra', 'lon': '72.85'},
      '798623': {'lat': '26.4224', 'name': 'Tzudikong,Mokokchung,Nagaland', 'lon': '94.7346'},
      '686693': {'lat': '9.6535', 'name': 'Thalakode,Ernakulam,Kerala', 'lon': '76.7539'},
      '686692': {'lat': '10.103', 'name': 'Thrikkariyoor,Ernakulam,Kerala', 'lon': '76.637'},
      '686123': {'lat': '9.6875', 'name': 'Thidanad,Kottayam,Kerala', 'lon': '76.7789'},
      '686122': {'lat': '9.6875', 'name': 'Aruvithura,Kottayam,Kerala', 'lon': '76.7789'},
      '413203': {'lat': '18.7294', 'name': 'Veet,Solapur,Maharashtra', 'lon': '75.0731'},
      '413204': {'lat': '19.2034', 'name': 'Jategaon,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '232333': {'lat': '25.4655', 'name': 'Athatha,Ghazipur,Uttar Pradesh', 'lon': '83.6915'},
      '518573': {'lat': '15.7407', 'name': 'Yallur,Kurnool,Andhra Pradesh', 'lon': '79.9128'},
      '573115': {'lat': '13.2069', 'name': 'Chikmedur,Hassan,Karnataka', 'lon': '75.8238'},
      '744301': {'lat': '9.1833', 'name': 'Lapathy,Nicobar,Andaman & Nicobar Islands', 'lon': '92.7667'},
      '396521': {'lat': '21.9308', 'name': 'Ghej,Navsari,Gujarat', 'lon': '72.3141'},
      '744303': {'lat': '9.1833', 'name': 'Trinket,Nicobar,Andaman & Nicobar Islands', 'lon': '92.7667'},
      '744304': {'lat': '9.1833', 'name': 'Nehrugram,Nicobar,Andaman & Nicobar Islands', 'lon': '92.7667'},
      '462022': {'lat': '24.1137', 'name': 'Kokata,Bhopal,Madhya Pradesh', 'lon': '77.8846'},
      '573113': {'lat': '12.9954', 'name': 'Hangal,Hassan,Karnataka', 'lon': '76.1091'},
      '573112': {'lat': '13.418', 'name': 'Banavara R S,Hassan,Karnataka', 'lon': '76.171'},
      '623120': {'lat': '9.1901', 'name': 'T.Veppankulam,Ramanathapuram,Tamil Nadu', 'lon': '78.4271'},
      '382870': {'lat': '23.2443', 'name': 'Bhavsor,Mahesana,Gujarat', 'lon': '73.3428'},
      '413207': {'lat': '18.9304', 'name': 'Kuslamb,Beed,Maharashtra', 'lon': '75.8696'},
      '533429': {'lat': '17.331', 'name': 'Yeleswaram,East Godavari,Andhra Pradesh', 'lon': '82.1377'},
      '533428': {'lat': '17.4787', 'name': 'Yerramreddypalem,East Godavari,Andhra Pradesh', 'lon': '82.0077'},
      '322249': {'lat': '26.7049', 'name': 'Bugdar,Karauli,Rajasthan', 'lon': '76.8508'},
      '535145': {'lat': '18.2236', 'name': 'Muliaguda,Visakhapatnam,Andhra Pradesh', 'lon': '82.9875'},
      '535148': {'lat': '18.1124', 'name': 'Vemulapalli,Vizianagaram,Andhra Pradesh', 'lon': '83.2133'},
      '387120': {'lat': '22.8615', 'name': 'Vasna Margia,Kheda,Gujarat', 'lon': '72.7046'},
      '207402': {'lat': '27.0917', 'name': 'Gaupura,Etah,Uttar Pradesh', 'lon': '80.0793'},
      '172031': {'lat': '31.2909', 'name': 'Jarol,Shimla,Himachal Pradesh', 'lon': '77.4976'},
      '670631': {'lat': '11.705', 'name': 'Nediyenga,Kannur,Kerala', 'lon': '75.7322'},
      '670632': {'lat': '11.705', 'name': 'Kuniyampuzha,Kannur,Kerala', 'lon': '75.7322'},
      '670633': {'lat': '11.705', 'name': 'Pazhassikari,Kannur,Kerala', 'lon': '75.7322'},
      '172034': {'lat': '31.3736', 'name': 'Bonda,Shimla,Himachal Pradesh', 'lon': '77.4552'},
      '502375': {'lat': '17.9397', 'name': 'Velkatur,Medak,Telangana', 'lon': '78.7982'},
      '612402': {'lat': '10.904', 'name': 'Marudanallur,Thanjavur,Tamil Nadu', 'lon': '79.4078'},
      '613504': {'lat': '10.8132', 'name': 'Arsuthipattu,Thanjavur,Tamil Nadu', 'lon': '79.2721'},
      '613501': {'lat': '10.7581', 'name': 'Pulavarnatham,Thanjavur,Tamil Nadu', 'lon': '79.201'},
      '506003': {'lat': '17.9465', 'name': 'Bapujinagar (Warangal),Warangal,Telangana', 'lon': '79.5116'},
      '613503': {'lat': '10.7817', 'name': 'Sri Pushpam College,Thanjavur,Tamil Nadu', 'lon': '79.2462'},
      '613502': {'lat': '10.7423', 'name': 'Kumilakudi,Thanjavur,Tamil Nadu', 'lon': '79.2398'},
      '443002': {'lat': '20.3394', 'name': 'Dongar Khandala,Buldhana,Maharashtra', 'lon': '75.9568'},
      '140417': {'lat': '30.4434', 'name': 'Sandharsi,Patiala,Punjab', 'lon': '76.7021'},
      '470051': {'lat': '23.4685', 'name': 'Baxwaha,Sagar,Madhya Pradesh', 'lon': '78.0749'},
      '140412': {'lat': '30.496', 'name': 'Nogawan,Patiala,Punjab', 'lon': '76.3425'},
      '140413': {'lat': '30.7197', 'name': 'Bajheri,Mohali,Punjab', 'lon': '76.5439'},
      '629703': {'lat': '8.2081', 'name': 'Santhaiyadi,Kanyakumari,Tamil Nadu', 'lon': '77.4503'},
      '473335': {'lat': '25.0777', 'name': 'Gahora,Ashok Nagar,Madhya Pradesh', 'lon': '77.9784'},
      '612401': {'lat': '10.9443', 'name': 'Annalagraharam,Thanjavur,Tamil Nadu', 'lon': '79.4165'},
      '852214': {'lat': '26.1211', 'name': 'Govinpur,Supaul,Bihar', 'lon': '86.9717'},
      '322242': {'lat': '26.6812', 'name': 'Siloti,Karauli,Rajasthan', 'lon': '77.4669'},
      '631001': {'lat': '13.0633', 'name': 'Arakkonam H.O,Vellore,Tamil Nadu', 'lon': '79.645'},
      '360530': {'lat': '21.6533', 'name': 'Ishwaria,Porbandar,Gujarat', 'lon': '69.7781'},
      '462023': {'lat': '23.478', 'name': 'Govindpura,Bhopal,Madhya Pradesh', 'lon': '77.3961'},
      '852216': {'lat': '25.8646', 'name': 'Manuar,Darbhanga,Bihar', 'lon': '86.5093'},
      '700119': {'lat': '22.7945', 'name': 'Patulia,North 24 Parganas,West Bengal', 'lon': '88.2769'},
      '700118': {'lat': '22.7379', 'name': 'Rahara,North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '243723': {'lat': '28.2692', 'name': 'Sitholi,Budaun,Uttar Pradesh', 'lon': '78.7983'},
      '852217': {'lat': '25.577', 'name': 'Biratpur,Saharsa,Bihar', 'lon': '86.6884'},
      '332028': {'lat': '27.1346', 'name': 'Gunatoo,Sikar,Rajasthan', 'lon': '75.7638'},
      '332029': {'lat': '27.0783', 'name': 'Tiroki Badi,Sikar,Rajasthan', 'lon': '75.5527'},
      '248012': {'lat': '30.2798', 'name': 'Defence Colony,Dehradun,Uttarakhand', 'lon': '78.0545'},
      '332024': {'lat': '27.2426', 'name': 'Tarpura,Sikar,Rajasthan', 'lon': '75.3994'},
      '752003': {'lat': '20.2559', 'name': 'Srivihar,Puri,Odisha', 'lon': '85.4666'},
      '332026': {'lat': '27.8659', 'name': 'Ghirnia Bada,Sikar,Rajasthan', 'lon': '75.0099'},
      '332027': {'lat': '27.2231', 'name': 'Piprali,Sikar,Rajasthan', 'lon': '75.6538'},
      '700115': {'lat': '22.7379', 'name': 'Sukchar (North 24 Parganas),North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '332021': {'lat': '26.3177', 'name': 'Bajajgram,Sikar,Rajasthan', 'lon': '75.6617'},
      '700117': {'lat': '22.7186', 'name': 'Surya Sen Nagar,North 24 Parganas,West Bengal', 'lon': '88.3781'},
      '332023': {'lat': '27.0253', 'name': 'Sami,Sikar,Rajasthan', 'lon': '76.2885'},
      '731216': {'lat': '24.0762', 'name': 'Mohula,Birbhum,West Bengal', 'lon': '87.7092'},
      '731214': {'lat': '24.1395', 'name': 'Chunpalasi,Birbhum,West Bengal', 'lon': '87.8054'},
      '731215': {'lat': '24.2069', 'name': 'Sakodda,Birbhum,West Bengal', 'lon': '87.9004'},
      '852212': {'lat': '25.8166', 'name': 'Parri,Saharsa,Bihar', 'lon': '86.5898'},
      '733158': {'lat': '25.70254', 'name': 'Atrai,South Dinajpur,West Bengal', 'lon': '88.19292'},
      '731218': {'lat': '23.6193', 'name': 'Kotasur,Birbhum,West Bengal', 'lon': '87.8905'},
      '731219': {'lat': '24.3616', 'name': 'Malaypur,Birbhum,West Bengal', 'lon': '87.8519'},
      '621202': {'lat': '10.9305', 'name': 'Thinnakonam,Tiruchirappalli,Tamil Nadu', 'lon': '78.5251'},
      '795128': {'lat': '24.3884', 'name': 'Tollen,Churachandpur,Manipur', 'lon': '93.5819'},
      '795129': {'lat': '25.1964', 'name': 'Henbung,Kangpokpi,Manipur', 'lon': '93.9334'},
      '387430': {'lat': '23.0401', 'name': 'Sinhuj,Kheda,Gujarat', 'lon': '73.0053'},
      '795122': {'lat': '25.13', 'name': 'Kheljang,Kangpokpi,Manipur', 'lon': '93.9562'},
      '210206': {'lat': '25.3886', 'name': 'Kapna Itaura,Chitrakoot,Uttar Pradesh', 'lon': '80.9519'},
      '577229': {'lat': '13.8545', 'name': 'Donabaghatta,Shivamogga,Karnataka', 'lon': '75.6486'},
      '795126': {'lat': '24.7905', 'name': 'Bishnupur (Bishnupur),Bishnupur,Manipur', 'lon': '93.7861'},
      '795127': {'lat': '24.3299', 'name': 'Liwasarai,Chandel,Manipur', 'lon': '94.0038'},
      '795124': {'lat': '24.7905', 'name': 'Mayuran,Bishnupur,Manipur', 'lon': '93.7861'},
      '795125': {'lat': '25.3027', 'name': 'Dullen (T/C/D),Tamenglon,Manipur', 'lon': '93.7734'},
      '413301': {'lat': '17.9302', 'name': 'Modnimb,Solapur,Maharashtra', 'lon': '75.366'},
      '457775': {'lat': '23.0071', 'name': 'Jhaknawda,Jhabua,Madhya Pradesh', 'lon': '75.1902'},
      '413303': {'lat': '17.8439', 'name': 'Ashti (Solapur),Solapur,Maharashtra', 'lon': '75.4103'},
      '413302': {'lat': '17.866', 'name': 'Tulshi,Solapur,Maharashtra', 'lon': '75.2961'},
      '413305': {'lat': '19.3674', 'name': 'Patkhal,Solapur,Maharashtra', 'lon': '76.7199'},
      '413304': {'lat': '18.7801', 'name': 'Mendhapur,Solapur,Maharashtra', 'lon': '76.283'},
      '413307': {'lat': '17.5589', 'name': 'Ekhatpur,Solapur,Maharashtra', 'lon': '75.4031'},
      '413306': {'lat': '17.9232', 'name': 'Lotewadi,Solapur,Maharashtra', 'lon': '74.8561'},
      '413309': {'lat': '19.4807', 'name': 'Pare,Solapur,Maharashtra', 'lon': '76.4202'},
      '413308': {'lat': '18.4022', 'name': 'Nazara,Solapur,Maharashtra', 'lon': '75.8906'},
      '695551': {'lat': '8.6873', 'name': 'Chettachal,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '243725': {'lat': '28.2721', 'name': 'Khurampur Bhamori,Budaun,Uttar Pradesh', 'lon': '79.011'},
      '629602': {'lat': '8.0991', 'name': 'Thengamputhur,Kanyakumari,Tamil Nadu', 'lon': '77.4344'},
      '629601': {'lat': '8.0991', 'name': 'Melakrishnanputhur,Kanyakumari,Tamil Nadu', 'lon': '77.4344'},
      '517001': {'lat': '13.3103', 'name': 'Readspet,Chittoor,Andhra Pradesh', 'lon': '79.1847'},
      '743223': {'lat': '22.8464', 'name': 'Haripur (North 24 Parganas),North 24 Parganas,West Bengal', 'lon': '88.5736'},
      '517002': {'lat': '13.1586', 'name': 'Lalugardens,Chittoor,Andhra Pradesh', 'lon': '79.0945'},
      '517004': {'lat': '13.6137', 'name': 'Pantrampalle,Chittoor,Andhra Pradesh', 'lon': '79.3652'},
      '233002': {'lat': '25.7007', 'name': 'Akarmpur Banjaripur,Ghazipur,Uttar Pradesh', 'lon': '83.4551'},
      '577146': {'lat': '12.9737', 'name': 'S. Bidare,Chikkamagaluru,Karnataka', 'lon': '76.0393'},
      '263629': {'lat': '29.6495', 'name': 'Lweshal,Almora,Uttarakhand', 'lon': '79.6821'},
      '233001': {'lat': '25.7007', 'name': 'Degree College (Ghazipur),Ghazipur,Uttar Pradesh', 'lon': '83.4551'},
      '171218': {'lat': '31.0245', 'name': 'Junga,Shimla,Himachal Pradesh', 'lon': '77.1913'},
      '171219': {'lat': '31.0478', 'name': 'Salana,Shimla,Himachal Pradesh', 'lon': '77.1006'},
      '471405': {'lat': '24.6235', 'name': 'Bijawar,Chhatarpur,Madhya Pradesh', 'lon': '79.4899'},
      '171214': {'lat': '31.7559', 'name': 'Ranol,Shimla,Himachal Pradesh', 'lon': '77.059'},
      '171215': {'lat': '31.122', 'name': 'Mandhole,Shimla,Himachal Pradesh', 'lon': '77.3836'},
      '171216': {'lat': '31.101', 'name': 'Tooran,Shimla,Himachal Pradesh', 'lon': '77.8293'},
      '171217': {'lat': '30.8634', 'name': 'Bhalu,Shimla,Himachal Pradesh', 'lon': '77.5752'},
      '171210': {'lat': '30.9753', 'name': 'Pawan,Shimla,Himachal Pradesh', 'lon': '77.4764'},
      '171211': {'lat': '31.1073', 'name': 'Dewat,Shimla,Himachal Pradesh', 'lon': '77.4875'},
      '171212': {'lat': '31.1661', 'name': 'Kelvi,Shimla,Himachal Pradesh', 'lon': '77.3555'},
      '171213': {'lat': '31.132', 'name': 'Halanidhar,Shimla,Himachal Pradesh', 'lon': '77.3305'},
      '473665': {'lat': '25.4025', 'name': 'Dabarbhat,Shivpuri,Madhya Pradesh', 'lon': '78.4711'},
      '364515': {'lat': '21.3373', 'name': 'Pithvadi,Amreli,Gujarat', 'lon': '71.3035'},
      '577145': {'lat': '13.5941', 'name': 'Chikkanavangala,Chikkamagaluru,Karnataka', 'lon': '75.9279'},
      '473660': {'lat': '25.6654', 'name': 'Salaiya,Shivpuri,Madhya Pradesh', 'lon': '78.0496'},
      '473662': {'lat': '25.6119', 'name': 'Behgawa,Shivpuri,Madhya Pradesh', 'lon': '78.1365'},
      '364510': {'lat': '21.4194', 'name': 'Morchupna,Bhavnagar,Gujarat', 'lon': '71.6947'},
      '577142': {'lat': '13.5941', 'name': 'Kudremukh,Chikkamagaluru,Karnataka', 'lon': '75.9279'},
      '721432': {'lat': '23.5034', 'name': 'Kalagachia (East Midnapore),East Midnapore,West Bengal', 'lon': '87.9832'},
      '360452': {'lat': '22.0253', 'name': 'Chavandi,Rajkot,Gujarat', 'lon': '70.5655'},
      '523301': {'lat': '15.9317', 'name': 'Guntupalli,Prakasam,Andhra Pradesh', 'lon': '80.0344'},
      '360450': {'lat': '22.0253', 'name': 'Arni,Rajkot,Gujarat', 'lon': '70.5655'},
      '577140': {'lat': '13.6135', 'name': 'Yagati,Chikkamagaluru,Karnataka', 'lon': '76.1494'},
      '283104': {'lat': '27.0621', 'name': 'Bamrauli,Agra,Uttar Pradesh', 'lon': '78.3061'},
      '394380': {'lat': '21.5251', 'name': 'Kukurmunda,Tapi,Gujarat', 'lon': '74.1423'},
      '384315': {'lat': '23.6724', 'name': 'Rajgadh,Mahesana,Gujarat', 'lon': '72.5824'},
      '585443': {'lat': '18.2296', 'name': 'Chimegaon,Bidar,Karnataka', 'lon': '77.3105'},
      '283102': {'lat': '27.0463', 'name': 'Akhabai,Agra,Uttar Pradesh', 'lon': '77.9844'},
      '384310': {'lat': '23.7167', 'name': 'Khandosan,Mahesana,Gujarat', 'lon': '72.4574'},
      '523302': {'lat': '16.1088', 'name': 'Kamepalli,Prakasam,Andhra Pradesh', 'lon': '79.937'},
      '628801': {'lat': '8.6395', 'name': 'Korkai,Tuticorin,Tamil Nadu', 'lon': '78.058'},
      '574129': {'lat': '13.3137', 'name': 'Belady,Udupi,Karnataka', 'lon': '75.1335'},
      '523305': {'lat': '15.8571', 'name': 'Donakonda R.S.,Prakasam,Andhra Pradesh', 'lon': '79.4018'},
      '403103': {'lat': '15.4761', 'name': 'Shiroda (North Goa),North Goa,Goa', 'lon': '73.9417'},
      '523304': {'lat': '15.8504', 'name': 'Namasivayapuram,Prakasam,Andhra Pradesh', 'lon': '79.4107'},
      '756060': {'lat': '21.2851', 'name': 'Bahala,Baleswar,Odisha', 'lon': '86.0977'},
      '506112': {'lat': '17.7846', 'name': 'Ameenapur,Warangal,Telangana', 'lon': '79.533'},
      '284120': {'lat': '25.1447', 'name': 'Mathurapura,Jhansi,Uttar Pradesh', 'lon': '78.3897'},
      '831017': {'lat': '23.5378', 'name': 'Baridih Colony,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '829301': {'lat': '23.8254', 'name': 'Baroo,Bokaro,Jharkhand', 'lon': '86.3127'},
      '752089': {'lat': '20.3502', 'name': 'Kujamendhi,Nayagarh,Odisha', 'lon': '84.5149'},
      '721439': {'lat': '23.8386', 'name': 'Ayma Barbaria,East Midnapore,West Bengal', 'lon': '88.171'},
      '502110': {'lat': '17.4111', 'name': 'Medak Buss stand,Medak,Telangana', 'lon': '78.505'},
      '752080': {'lat': '20.1225', 'name': 'Pancharida,Nayagarh,Odisha', 'lon': '85.0598'},
      '752081': {'lat': '20.0162', 'name': 'Sakeri,Nayagarh,Odisha', 'lon': '84.9872'},
      '752082': {'lat': '20.111', 'name': 'Beruanbadi,Nayagarh,Odisha', 'lon': '84.9013'},
      '752083': {'lat': '20.2557', 'name': 'Nuagaon (Nayagarh),Nayagarh,Odisha', 'lon': '84.9678'},
      '752084': {'lat': '20.4313', 'name': 'Badasilinga,Nayagarh,Odisha', 'lon': '84.7034'},
      '385565': {'lat': '24.1456', 'name': 'Khengarpura,Banaskantha,Gujarat', 'lon': '72.2868'},
      '831010': {'lat': '23.5378', 'name': 'Telco G.M Office,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '713407': {'lat': '23.3857', 'name': 'Sukur,Purba Bardhaman,West Bengal', 'lon': '88.0371'},
      '713406': {'lat': '23.4026', 'name': 'Uchhagram,Purba Bardhaman,West Bengal', 'lon': '87.9525'},
      '144418': {'lat': '31.1788', 'name': 'Samrai,Jalandhar,Punjab', 'lon': '75.6323'},
      '144419': {'lat': '31.0531', 'name': 'Bharsingpura,Jalandhar,Punjab', 'lon': '75.8628'},
      '713403': {'lat': '23.4013', 'name': 'Kasba,Purba Bardhaman,West Bengal', 'lon': '87.5389'},
      '713402': {'lat': '23.3857', 'name': 'Arjunpur (Bardhaman),Purba Bardhaman,West Bengal', 'lon': '88.0371'},
      '713401': {'lat': '23.1342', 'name': 'Sanchra,Purba Bardhaman,West Bengal', 'lon': '88.0405'},
      '562149': {'lat': '13.2257', 'name': 'Bagalur (Bangalore),Bengaluru,Karnataka', 'lon': '77.575'},
      '229311': {'lat': '26.3795', 'name': 'Sehgon,Raebareli,Uttar Pradesh', 'lon': '81.6771'},
      '761016': {'lat': '19.4879', 'name': 'Anguru,Gajapati,Odisha', 'lon': '84.4721'},
      '144410': {'lat': '31.0368', 'name': 'P T S Phillaur,Jalandhar,Punjab', 'lon': '75.8228'},
      '144411': {'lat': '31.4106', 'name': 'Hardasspur,Kapurthala,Punjab', 'lon': '75.6793'},
      '144416': {'lat': '31.0863', 'name': 'Apra,Jalandhar,Punjab', 'lon': '75.8791'},
      '144417': {'lat': '31.0684', 'name': 'Aur,Nawanshahr,Punjab', 'lon': '76.0024'},
      '713409': {'lat': '23.2194', 'name': 'Bhaduripara,Purba Bardhaman,West Bengal', 'lon': '88.3629'},
      '144415': {'lat': '31.1176', 'name': 'Lassara,Nawanshahr,Punjab', 'lon': '75.8269'},
      '516356': {'lat': '14.7742', 'name': 'Alladupalle,Cuddapah,Andhra Pradesh', 'lon': '78.5707'},
      '587314': {'lat': '16.4757', 'name': 'Rabkavi,Bagalkot,Karnataka', 'lon': '75.1106'},
      '516355': {'lat': '14.7966', 'name': 'Nakkaladinne,Cuddapah,Andhra Pradesh', 'lon': '78.5903'},
      '502117': {'lat': '18.0771', 'name': 'Rajpally,Medak,Telangana', 'lon': '78.5523'},
      '516350': {'lat': '14.4769', 'name': 'Kathalur,Cuddapah,Andhra Pradesh', 'lon': '78.5711'},
      '148104': {'lat': '31.0358', 'name': 'Gangohar,Barnala,Punjab', 'lon': '75.1823'},
      '587316': {'lat': '15.4038', 'name': 'Sameerwadi,Bagalkot,Karnataka', 'lon': '75.478'},
      '516359': {'lat': '14.945', 'name': 'Gopayapalle,Cuddapah,Andhra Pradesh', 'lon': '78.5403'},
      '470441': {'lat': '24.1699', 'name': 'Khiriya Kala,Sagar,Madhya Pradesh', 'lon': '78.7161'},
      '628552': {'lat': '9.323', 'name': 'Pazhankottai,Tirunelveli,Tamil Nadu', 'lon': '77.8057'},
      '148106': {'lat': '30.2084', 'name': 'Bhani Mehraj,Barnala,Punjab', 'lon': '75.6891'},
      '587311': {'lat': '15.674', 'name': 'Navalagi,Bagalkot,Karnataka', 'lon': '75.5036'},
      '788155': {'lat': '26.3703', 'name': 'Rongpur North,Hailakandi,Assam', 'lon': '91.3481'},
      '416501': {'lat': '16.0776', 'name': 'Hanimnal,Kolhapur,Maharashtra', 'lon': '74.2914'},
      '394655': {'lat': '21.0697', 'name': 'Kapura,Tapi,Gujarat', 'lon': '73.3703'},
      '788156': {'lat': '25.7351', 'name': 'Dargarband Bazar,Hailakandi,Assam', 'lon': '92.0907'},
      '788151': {'lat': '25.7351', 'name': 'Hailakandi H.O,Hailakandi,Assam', 'lon': '92.0907'},
      '394652': {'lat': '21.653', 'name': 'Panwadi,Tapi,Gujarat', 'lon': '73.4178'},
      '394651': {'lat': '21.2423', 'name': 'Anumala,Tapi,Gujarat', 'lon': '73.4272'},
      '394650': {'lat': '22.15', 'name': 'Chikhali Khadka,Surat,Gujarat', 'lon': '73.4368'},
      '416508': {'lat': '15.9793', 'name': 'Rajagoli KH,Kolhapur,Maharashtra', 'lon': '74.3898'},
      '416509': {'lat': '15.9249', 'name': 'Keravade,Kolhapur,Maharashtra', 'lon': '74.1646'},
      '587313': {'lat': '15.8489', 'name': 'Gulgal Jambgi,Bagalkot,Karnataka', 'lon': '75.5968'},
      '226401': {'lat': '26.5871', 'name': 'Neewan,Lucknow,Uttar Pradesh', 'lon': '80.9307'},
      '473331': {'lat': '24.6094', 'name': 'Garroli,Ashok Nagar,Madhya Pradesh', 'lon': '77.8752'},
      '383430': {'lat': '23.875', 'name': 'Pratappura,Sabarkantha,Gujarat', 'lon': '73.0501'},
      '207125': {'lat': '27.6836', 'name': 'Dhiramai,Etah,Uttar Pradesh', 'lon': '78.6593'},
      '383434': {'lat': '24.0199', 'name': 'Rampur (Fudeda),Sabarkantha,Gujarat', 'lon': '72.7166'},
      '403715': {'lat': '15.2145', 'name': 'Chinchinim,South Goa,Goa', 'lon': '73.9755'},
      '365545': {'lat': '21.6823', 'name': 'Barman-nana,Amreli,Gujarat', 'lon': '71.3701'},
      '680567': {'lat': '11.2984', 'name': 'Valapad,Thrissur,Kerala', 'lon': '75.9418'},
      '680566': {'lat': '10.4199', 'name': 'Nattika,Thrissur,Kerala', 'lon': '76.0999'},
      '680565': {'lat': '10.7172', 'name': 'Peringottukara,Thrissur,Kerala', 'lon': '76.0861'},
      '680564': {'lat': '10.7172', 'name': 'Inchamudi,Thrissur,Kerala', 'lon': '76.0861'},
      '680563': {'lat': '10.7172', 'name': 'Paralam,Thrissur,Kerala', 'lon': '76.0861'},
      '680562': {'lat': '10.4333', 'name': 'Vallachira,Thrissur,Kerala', 'lon': '76.2167'},
      '680561': {'lat': '10.7172', 'name': 'Pootharakkal,Thrissur,Kerala', 'lon': '76.0861'},
      '403714': {'lat': '15.1009', 'name': 'Assolda,South Goa,Goa', 'lon': '74.0283'},
      '522311': {'lat': '16.079', 'name': 'Chandole,Guntur,Andhra Pradesh', 'lon': '80.568'},
      '522310': {'lat': '15.9885', 'name': 'Poondla,Guntur,Andhra Pradesh', 'lon': '80.5016'},
      '522313': {'lat': '16.2576', 'name': 'Yadavuru,Guntur,Andhra Pradesh', 'lon': '80.9145'},
      '522312': {'lat': '16.2988', 'name': 'Moparru,Guntur,Andhra Pradesh', 'lon': '80.0915'},
      '522315': {'lat': '16.0225', 'name': 'Itikampadu,Guntur,Andhra Pradesh', 'lon': '80.4825'},
      '522314': {'lat': '15.9235', 'name': 'Amudalapalli,Guntur,Andhra Pradesh', 'lon': '80.6574'},
      '680569': {'lat': '10.7172', 'name': 'Edassery,Thrissur,Kerala', 'lon': '76.0861'},
      '680568': {'lat': '10.7172', 'name': 'Edamuttam,Thrissur,Kerala', 'lon': '76.0861'},
      '521369': {'lat': '16.6117', 'name': 'Munjuluru,Krishna,Andhra Pradesh', 'lon': '81.1663'},
      '483053': {'lat': '23.1317', 'name': 'Majhita,Jabalpur,Madhya Pradesh', 'lon': '79.8011'},
      '360060': {'lat': '22.0493', 'name': 'Kundani,Rajkot,Gujarat', 'lon': '71.0642'},
      '841209': {'lat': '25.9249', 'name': 'Gola Mubarkpur,Saran,Bihar', 'lon': '84.4604'},
      '521366': {'lat': '16.2712', 'name': 'Pedanabazar,Krishna,Andhra Pradesh', 'lon': '80.8122'},
      '841208': {'lat': '25.9476', 'name': 'Ekma Chaulk,Saran,Bihar', 'lon': '84.601'},
      '485111': {'lat': '24.641', 'name': 'Chhibaura,Satna,Madhya Pradesh', 'lon': '81.0942'},
      '225205': {'lat': '27.0704', 'name': 'Madhawapur,Barabanki,Uttar Pradesh', 'lon': '81.397'},
      '263156': {'lat': '29.3158', 'name': 'Ghorakhal,Nainital,Uttarakhand', 'lon': '79.385'},
      '110039': {'lat': '28.805', 'name': 'Katewara,North West Delhi,Delhi', 'lon': '77.0309'},
      '110038': {'lat': '28.7192', 'name': 'Rajokari,South West Delhi,Delhi', 'lon': '77.1505'},
      '623603': {'lat': '9.6655', 'name': 'Neeravikarisalkulam,Ramanathapuram,Tamil Nadu', 'lon': '78.2787'},
      '562108': {'lat': '13.0952', 'name': 'Kanva,Ramanagar,Karnataka', 'lon': '77.0779'},
      '562109': {'lat': '12.7707', 'name': 'Manchanayakanahalli,Ramanagar,Karnataka', 'lon': '77.4178'},
      '370240': {'lat': '22.7026', 'name': 'Bhimasar \xef\xbf\xbd,Kachchh,Gujarat', 'lon': '70.0046'},
      '825314': {'lat': '23.8051', 'name': 'Banji,Ramgarh,Jharkhand', 'lon': '85.557'},
      '110031': {'lat': '28.6548', 'name': 'Raghubar Pura,East Delhi,Delhi', 'lon': '77.2752'},
      '110030': {'lat': '28.5227', 'name': 'Gadaipur,South West Delhi,Delhi', 'lon': '77.1876'},
      '110033': {'lat': '28.7192', 'name': 'Bhalaswa,North West Delhi,Delhi', 'lon': '77.1505'},
      '110032': {'lat': '28.6776', 'name': 'Shivaji Park (East Delhi),East Delhi,Delhi', 'lon': '77.2887'},
      '110035': {'lat': '28.6772', 'name': 'INDERLOK,North West Delhi,Delhi', 'lon': '77.1573'},
      '110034': {'lat': '28.7192', 'name': 'Sri Nagar Colony,North West Delhi,Delhi', 'lon': '77.1505'},
      '110037': {'lat': '28.548', 'name': 'Palam Airport,South West Delhi,Delhi', 'lon': '77.1133'},
      '110036': {'lat': '28.8243', 'name': 'Khampur,North West Delhi,Delhi', 'lon': '77.1342'},
      '680691': {'lat': '10.2862', 'name': 'Koolimuttam,Thrissur,Kerala', 'lon': '76.1356'},
      '700065': {'lat': '22.4839', 'name': 'Subhas Nagar,Kolkata,West Bengal', 'lon': '88.3038'},
      '680697': {'lat': '10.3047', 'name': 'Kuzhikkattussery,Thrissur,Kerala', 'lon': '76.3052'},
      '680699': {'lat': '10.3591', 'name': 'Pady,Thrissur,Kerala', 'lon': '76.4128'},
      '641697': {'lat': '12.9042', 'name': 'Ellapalayam,Coimbatore,Tamil Nadu', 'lon': '78.7984'},
      '755050': {'lat': '20.8559', 'name': 'Jaraka,Jajapur,Odisha', 'lon': '86.1522'},
      '755051': {'lat': '20.8706', 'name': 'Khanditar,Jajapur,Odisha', 'lon': '84.9206'},
      '614612': {'lat': '10.4315', 'name': 'Manakadu,Thanjavur,Tamil Nadu', 'lon': '79.2314'},
      '614613': {'lat': '10.4018', 'name': 'Mannangadu,Thanjavur,Tamil Nadu', 'lon': '79.4078'},
      '614614': {'lat': '10.4805', 'name': 'Kaduvettividuthi,Thanjavur,Tamil Nadu', 'lon': '79.1966'},
      '614615': {'lat': '10.4777', 'name': 'Sandangadu,Thanjavur,Tamil Nadu', 'lon': '79.2669'},
      '614616': {'lat': '10.0411', 'name': 'Muslim Street,Pudukkottai,Tamil Nadu', 'lon': '78.9235'},
      '614617': {'lat': '10.0153', 'name': 'Ammapattinam,Pudukkottai,Tamil Nadu', 'lon': '79.2314'},
      '614618': {'lat': '10.4315', 'name': 'Amaradakki,Pudukkottai,Tamil Nadu', 'lon': '79.2314'},
      '614619': {'lat': '10.4315', 'name': 'Karakathikottai,Pudukkottai,Tamil Nadu', 'lon': '79.2314'},
      '263631': {'lat': '29.7596', 'name': 'Kapoori,Bageshwar,Uttarakhand', 'lon': '79.9152'},
      '700060': {'lat': '22.5065', 'name': 'Mahendra Banerjee Road,Kolkata,West Bengal', 'lon': '88.3015'},
      '501121': {'lat': '17.3481', 'name': 'Karvelli,K.V.Rangareddy,Telangana', 'lon': '78.0387'},
      '832403': {'lat': '23.1846', 'name': 'Tiruldih,Seraikela-kharsawan,Jharkhand', 'lon': '85.5947'},
      '700063': {'lat': '22.4613', 'name': 'Paschim Barisha,Kolkata,West Bengal', 'lon': '88.306'},
      '832402': {'lat': '22.8517', 'name': 'Hudu,Seraikela-kharsawan,Jharkhand', 'lon': '86.0519'},
      '627424': {'lat': '8.6747', 'name': 'Ravanasamudram,Tirunelveli,Tamil Nadu', 'lon': '77.5439'},
      '263160': {'lat': '29.0511', 'name': 'Majra Anand Singh,Udham Singh Nagar,Uttarakhand', 'lon': '79.3202'},
      '832401': {'lat': '23.0541', 'name': 'Tankocha,Seraikela-kharsawan,Jharkhand', 'lon': '85.8041'},
      '301019': {'lat': '27.4495', 'name': 'R.H.B. Bhiwari,Alwar,Rajasthan', 'lon': '76.6564'},
      '301018': {'lat': '27.4495', 'name': 'Silarpur,Alwar,Rajasthan', 'lon': '76.6564'},
      '509103': {'lat': '16.2732', 'name': 'Wanaparthy H.O,Mahabub Nagar,Telangana', 'lon': '78.2248'},
      '509102': {'lat': '16.1866', 'name': 'Janumpally,Mahabub Nagar,Telangana', 'lon': '78.3031'},
      '712134': {'lat': '23.1011', 'name': 'Insura,Hooghly,West Bengal', 'lon': '88.178'},
      '712135': {'lat': '22.8841', 'name': 'Boinchigram,Hooghly,West Bengal', 'lon': '88.2335'},
      '712136': {'lat': '22.8622', 'name': 'Boraichanditala,Hooghly,West Bengal', 'lon': '88.368'},
      '712137': {'lat': '22.8841', 'name': 'Gondalpara,Hooghly,West Bengal', 'lon': '88.2335'},
      '304021': {'lat': '26.4653', 'name': 'Khandewat,Tonk,Rajasthan', 'lon': '75.8477'},
      '712139': {'lat': '22.8393', 'name': 'Mankundu Govt.Colony,Hooghly,West Bengal', 'lon': '88.3607'},
      '304023': {'lat': '25.9263', 'name': 'Kholiya,Tonk,Rajasthan', 'lon': '75.4258'},
      '304022': {'lat': '26.4417', 'name': 'Banasthali Vidya Peeth,Tonk,Rajasthan', 'lon': '75.8656'},
      '304025': {'lat': '26.38', 'name': 'Jhilai,Tonk,Rajasthan', 'lon': '75.9794'},
      '304024': {'lat': '26.1527', 'name': 'Banetha,Tonk,Rajasthan', 'lon': '75.7288'},
      '304026': {'lat': '25.8952', 'name': 'Charnet,Tonk,Rajasthan', 'lon': '75.7412'},
      '135001': {'lat': '30.0075', 'name': 'Ram Pura Olony,Yamuna Nagar,Haryana', 'lon': '77.2266'},
      '277216': {'lat': '25.9745', 'name': 'Suryabhanpur,Ballia,Uttar Pradesh', 'lon': '84.3516'},
      '135003': {'lat': '30.1888', 'name': 'Mussimbal,Yamuna Nagar,Haryana', 'lon': '77.2857'},
      '135002': {'lat': '30.0919', 'name': 'Damla,Yamuna Nagar,Haryana', 'lon': '77.2214'},
      '277213': {'lat': '25.9162', 'name': 'Sakarpura,Ballia,Uttar Pradesh', 'lon': '84.1732'},
      '135004': {'lat': '30.0939', 'name': 'Nahar Pur,Yamuna Nagar,Haryana', 'lon': '77.3216'},
      '277211': {'lat': '25.8272', 'name': 'Rohuan,Ballia,Uttar Pradesh', 'lon': '84.2954'},
      '277210': {'lat': '25.8282', 'name': 'Kewara,Ballia,Uttar Pradesh', 'lon': '84.2344'},
      '382340': {'lat': '22.9579', 'name': 'Kubernagar,Ahmedabad,Gujarat', 'lon': '72.8231'},
      '832404': {'lat': '23.0401', 'name': 'Lepatand,Seraikela-kharsawan,Jharkhand', 'lon': '85.9083'},
      '277219': {'lat': '25.9123', 'name': 'Kirtupur,Ballia,Uttar Pradesh', 'lon': '84.2554'},
      '382345': {'lat': '22.9579', 'name': 'Naroda Road,Ahmedabad,Gujarat', 'lon': '72.8231'},
      '624802': {'lat': '10.4264', 'name': 'Kanapadi,Dindigul,Tamil Nadu', 'lon': '77.9659'},
      '624801': {'lat': '10.2583', 'name': 'Kollapatti,Dindigul,Tamil Nadu', 'lon': '77.9192'},
      '734321': {'lat': '26.9319', 'name': 'Reang,Darjiling,West Bengal', 'lon': '88.4504'},
      '177301': {'lat': '31.67495', 'name': 'Basaral,Hamirpur(HP),Himachal Pradesh', 'lon': '76.33777'},
      '638009': {'lat': '11.3273', 'name': 'Rangampalayam,Erode,Tamil Nadu', 'lon': '77.7112'},
      '638008': {'lat': '11.3998', 'name': 'Samayasangiliagraharam,Namakkal,Tamil Nadu', 'lon': '77.7596'},
      '638007': {'lat': '11.313', 'name': 'Cauvery Railway Station,Erode,Tamil Nadu', 'lon': '77.7859'},
      '638006': {'lat': '11.3652', 'name': 'Pallipalayam,Namakkal,Tamil Nadu', 'lon': '77.7623'},
      '638005': {'lat': '11.2948', 'name': 'Peria Agraharam,Erode,Tamil Nadu', 'lon': '78.2602'},
      '638004': {'lat': '11.3377', 'name': 'Peria Semur,Erode,Tamil Nadu', 'lon': '77.7845'},
      '638003': {'lat': '11.3377', 'name': 'Vairapalayam,Erode,Tamil Nadu', 'lon': '77.7845'},
      '638002': {'lat': '11.305', 'name': 'Lakkapuram,Erode,Tamil Nadu', 'lon': '77.7554'},
      '638001': {'lat': '11.3377', 'name': 'Erode North,Erode,Tamil Nadu', 'lon': '77.7845'},
      '766002': {'lat': '20.0472', 'name': 'Risigaon,Kalahandi,Odisha', 'lon': '83.3801'},
      '766001': {'lat': '20.0472', 'name': 'Bhawanipatna Stadium,Kalahandi,Odisha', 'lon': '83.3801'},
      '227817': {'lat': '26.3092', 'name': 'Jagdishpur Industrial Area,Sultanpur,Uttar Pradesh', 'lon': '81.8812'},
      '403101': {'lat': '15.5111', 'name': 'Penha De France,North Goa,Goa', 'lon': '73.8283'},
      '741152': {'lat': '23.6783', 'name': 'Dhoradaha,Nadia,West Bengal', 'lon': '88.1909'},
      '444808': {'lat': '21.222', 'name': 'Parasapur,Amravati,Maharashtra', 'lon': '77.6013'},
      '444809': {'lat': '21.2141', 'name': 'Karanja Bahiram,Amravati,Maharashtra', 'lon': '77.772'},
      '444802': {'lat': '20.9122', 'name': 'Nanded BK,Amravati,Maharashtra', 'lon': '77.6157'},
      '444803': {'lat': '20.9642', 'name': 'Daryapur Town,Amravati,Maharashtra', 'lon': '77.3888'},
      '392165': {'lat': '22.0851', 'name': 'Pipalia,Bharuch,Gujarat', 'lon': '73.5587'},
      '444801': {'lat': '21.0344', 'name': 'Rewasa,Amravati,Maharashtra', 'lon': '77.6593'},
      '444806': {'lat': '21.0894', 'name': 'Asadpur,Amravati,Maharashtra', 'lon': '77.48'},
      '444807': {'lat': '21.3893', 'name': 'Salona,Amravati,Maharashtra', 'lon': '77.3986'},
      '444804': {'lat': '21.1271', 'name': 'Khairi,Amravati,Maharashtra', 'lon': '77.534'},
      '392160': {'lat': '22.0851', 'name': 'Vansi,Bharuch,Gujarat', 'lon': '73.5587'},
      '205261': {'lat': '27.0832', 'name': 'Sarakh,Mainpuri,Uttar Pradesh', 'lon': '78.8549'},
      '387335': {'lat': '23.3601', 'name': 'Abhripur,Kheda,Gujarat', 'lon': '72.9967'},
      '225415': {'lat': '26.9963', 'name': 'Udwat Nagar,Barabanki,Uttar Pradesh', 'lon': '81.8539'},
      '627127': {'lat': '8.1502', 'name': 'Kannankulam,Tirunelveli,Tamil Nadu', 'lon': '77.5914'},
      '387330': {'lat': '23.3664', 'name': 'Heranj,Kheda,Gujarat', 'lon': '73.0083'},
      '496330': {'lat': '23.1049', 'name': 'Irai,Jashpur,Chattisgarh', 'lon': '83.9789'},
      '431203': {'lat': '19.6929', 'name': 'Ambika Market Jalna,Jalna,Maharashtra', 'lon': '75.8818'},
      '678631': {'lat': '10.8619', 'name': 'Parasseri,Palakkad,Kerala', 'lon': '76.5162'},
      '678632': {'lat': '10.8952', 'name': 'Pulappatta,Palakkad,Kerala', 'lon': '76.4943'},
      '678633': {'lat': '10.7946', 'name': 'Alangad,Palakkad,Kerala', 'lon': '76.5586'},
      '504299': {'lat': '19.1343', 'name': 'Bejjur,Adilabad,Telangana', 'lon': '79.2403'},
      '676562': {'lat': '10.8491', 'name': 'Kuttayi,Malappuram,Kerala', 'lon': '75.9014'},
      '676561': {'lat': '10.8395', 'name': 'Chennara,Malappuram,Kerala', 'lon': '75.9258'},
      '741150': {'lat': '23.7269', 'name': 'Juranpur,Nadia,West Bengal', 'lon': '88.2314'},
      '600122': {'lat': '12.8808', 'name': 'Paraniputhur,Kanchipuram,Tamil Nadu', 'lon': '79.3531'},
      '412101': {'lat': '18.5217', 'name': 'Ordnance Factory Dehu Road,Pune,Maharashtra', 'lon': '74.4103'},
      '491666': {'lat': '20.5812', 'name': 'Kandadi,Rajnandgaon,Chattisgarh', 'lon': '80.8944'},
      '461551': {'lat': '22.5883', 'name': 'Pipliya Kala,Hoshangabad,Madhya Pradesh', 'lon': '77.7327'},
      '491557': {'lat': '21.1207', 'name': 'Jhithratola,Rajnandgaon,Chattisgarh', 'lon': '80.6826'},
      '274406': {'lat': '26.7125', 'name': 'Rakaba Junglepatti,Kushinagar,Uttar Pradesh', 'lon': '84.2279'},
      '144531': {'lat': '31.2422', 'name': 'Possi,Hoshiarpur,Punjab', 'lon': '76.0523'},
      '636140': {'lat': '10.7864', 'name': 'Kandashramam,Salem,Tamil Nadu', 'lon': '78.384'},
      '636141': {'lat': '11.5646', 'name': 'Ammampalayam,Salem,Tamil Nadu', 'lon': '78.6128'},
      '636142': {'lat': '11.3052', 'name': 'Mullukurichi,Namakkal,Tamil Nadu', 'lon': '78.5365'},
      '144530': {'lat': '31.3466', 'name': 'Gajjar,Hoshiarpur,Punjab', 'lon': '76.1487'},
      '690535': {'lat': '9.1223', 'name': 'Valiyazheekkal,Alappuzha,Kerala', 'lon': '76.5315'},
      '690534': {'lat': '9.1223', 'name': 'Chunakara,Alappuzha,Kerala', 'lon': '76.5315'},
      '690537': {'lat': '9.1738', 'name': 'Pullikanakku,Alappuzha,Kerala', 'lon': '76.5262'},
      '690536': {'lat': '9.0408', 'name': 'Vadakkumthala East,Kollam,Kerala', 'lon': '76.545'},
      '690531': {'lat': '9.1223', 'name': 'Pattoli Market,Alappuzha,Kerala', 'lon': '76.5315'},
      '690530': {'lat': '9.1223', 'name': 'Thamarakulam,Alappuzha,Kerala', 'lon': '76.5315'},
      '690533': {'lat': '9.1521', 'name': 'Njakkanal,Alappuzha,Kerala', 'lon': '76.5234'},
      '690532': {'lat': '9.1223', 'name': 'Chingoli,Alappuzha,Kerala', 'lon': '76.5315'},
      '313708': {'lat': '25.8431', 'name': 'Kyari,Udaipur,Rajasthan', 'lon': '74.5711'},
      '627118': {'lat': '8.4467', 'name': 'Vijayanarayanam,Tirunelveli,Tamil Nadu', 'lon': '77.8308'},
      '535559': {'lat': '18.4998', 'name': 'Mirthivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.3431'},
      '535558': {'lat': '18.5615', 'name': 'Bobbili H.O,Vizianagaram,Andhra Pradesh', 'lon': '83.4526'},
      '690539': {'lat': '9.1223', 'name': 'S R P Market,Kollam,Kerala', 'lon': '76.5315'},
      '690538': {'lat': '9.1223', 'name': 'Perungalam,Kollam,Kerala', 'lon': '76.5315'},
      '711111': {'lat': '22.6368', 'name': 'Jagacha,Howrah,West Bengal', 'lon': '88.288'},
      '711110': {'lat': '22.6368', 'name': 'Baksara,Howrah,West Bengal', 'lon': '88.288'},
      '754018': {'lat': '20.4178', 'name': 'Postal,Cuttack,Odisha', 'lon': '85.2538'},
      '606203': {'lat': '11.6724', 'name': 'Pudu Uchimedu,Villupuram,Tamil Nadu', 'lon': '79.0596'},
      '248123': {'lat': '30.7198', 'name': 'Jaddi,Dehradun,Uttarakhand', 'lon': '77.8572'},
      '422611': {'lat': '19.7109', 'name': 'Chincholi Gurav,Ahmed Nagar,Maharashtra', 'lon': '74.1933'},
      '422610': {'lat': '18.7375', 'name': 'Pimpalgaon Khad,Ahmed Nagar,Maharashtra', 'lon': '73.9681'},
      '754011': {'lat': '20.4178', 'name': 'Adaspur,Cuttack,Odisha', 'lon': '85.2538'},
      '754010': {'lat': '20.4178', 'name': 'Uaradha,Cuttack,Odisha', 'lon': '85.2538'},
      '754013': {'lat': '20.4178', 'name': 'CISF Mundali,Cuttack,Odisha', 'lon': '85.2538'},
      '754012': {'lat': '20.4178', 'name': 'Bhola,Khorda,Odisha', 'lon': '85.2538'},
      '854331': {'lat': '26.2959', 'name': 'Tarakhandah,Araria,Bihar', 'lon': '87.4752'},
      '271210': {'lat': '27.2165', 'name': 'Thakurapur,Balrampur,Uttar Pradesh', 'lon': '82.3883'},
      '271215': {'lat': '27.2601', 'name': 'Pipriha Jamuni,Balrampur,Uttar Pradesh', 'lon': '82.6597'},
      '587101': {'lat': '15.9374', 'name': 'Bagalkot B.H.School,Bagalkot,Karnataka', 'lon': '76.1284'},
      '226009': {'lat': '26.8507', 'name': 'Industrial Area (Lucknow),Lucknow,Uttar Pradesh', 'lon': '80.9942'},
      '362625': {'lat': '21.5131', 'name': 'Nanadia,Junagadh,Gujarat', 'lon': '70.0323'},
      '587102': {'lat': '15.9374', 'name': 'Gaddanakeri,Bagalkot,Karnataka', 'lon': '76.1284'},
      '822119': {'lat': '23.96', 'name': 'Hami,Latehar,Jharkhand', 'lon': '84.2533'},
      '587104': {'lat': '15.9374', 'name': 'Simikeri,Bagalkot,Karnataka', 'lon': '76.1284'},
      '476339': {'lat': '24.9477', 'name': 'Sheopur Baroda,Sheopur,Madhya Pradesh', 'lon': '76.7866'},
      '362620': {'lat': '21.5131', 'name': 'Kodvav,Junagadh,Gujarat', 'lon': '70.0323'},
      '470664': {'lat': '23.6796', 'name': 'Pashwaha,Damoh,Madhya Pradesh', 'lon': '79.8665'},
      '494224': {'lat': '19.8996', 'name': 'Chapka,Bastar,Chattisgarh', 'lon': '81.7106'},
      '508001': {'lat': '17.0848', 'name': 'Bottuguda,Nalgonda,Telangana', 'lon': '79.2439'},
      '494222': {'lat': '19.2961', 'name': 'Kinjoli,Bastar,Chattisgarh', 'lon': '82.0843'},
      '735218': {'lat': '26.7523', 'name': 'Chapadanga,Jalpaiguri,West Bengal', 'lon': '88.7218'},
      '735219': {'lat': '26.703', 'name': 'Kajoldighi,Jalpaiguri,West Bengal', 'lon': '88.7638'},
      '492099': {'lat': '20.5553', 'name': 'Tatibandh,Raipur,Chattisgarh', 'lon': '82.2147'},
      '494221': {'lat': '19.2597', 'name': 'Kohkapal,Bastar,Chattisgarh', 'lon': '81.9263'},
      '769007': {'lat': '21.8782', 'name': 'Fertilizer Township,Sundergarh,Odisha', 'lon': '84.638'},
      '735212': {'lat': '26.5233', 'name': 'Pradhanpara,Jalpaiguri,West Bengal', 'lon': '88.9736'},
      '735213': {'lat': '26.5233', 'name': 'Rangoli Bazna,Alipurduar,West Bengal', 'lon': '88.9736'},
      '391120': {'lat': '21.7354', 'name': 'Fatepura,Narmada,Gujarat', 'lon': '73.6475'},
      '391121': {'lat': '22.5438', 'name': 'Uchad,Narmada,Gujarat', 'lon': '73.4197'},
      '735216': {'lat': '25.4514', 'name': 'Promodnagar,Alipurduar,West Bengal', 'lon': '87.8886'},
      '735217': {'lat': '26.6889', 'name': 'Atiabari Bagan.,Alipurduar,West Bengal', 'lon': '89.4483'},
      '735214': {'lat': '26.5233', 'name': 'Dakshin Satali,Alipurduar,West Bengal', 'lon': '88.9736'},
      '391125': {'lat': '22.0327', 'name': 'Jojwa  R.S.,Vadodara,Gujarat', 'lon': '73.455'},
      '743411': {'lat': '22.8337', 'name': 'Basirhat Bazar,North 24 Parganas,West Bengal', 'lon': '88.8136'},
      '490021': {'lat': '21.1745', 'name': 'Bhilai East,Durg,Chattisgarh', 'lon': '81.475'},
      '743412': {'lat': '22.8337', 'name': 'Karnolkora,North 24 Parganas,West Bengal', 'lon': '88.8136'},
      '345028': {'lat': '27.4487', 'name': 'Tawriwala,Jaisalmer,Rajasthan', 'lon': '71.6639'},
      '229413': {'lat': '25.7735', 'name': 'Andhiyaari,Allahabad,Uttar Pradesh', 'lon': '81.9322'},
      '490020': {'lat': '21.2367', 'name': 'Smriti Nagar Bhilai,Durg,Chattisgarh', 'lon': '81.3973'},
      '605502': {'lat': '12.3081', 'name': 'Valudavur,Villupuram,Tamil Nadu', 'lon': '79.7056'},
      '481116': {'lat': '22.0938', 'name': 'Janpur,Balaghat,Madhya Pradesh', 'lon': '80.6992'},
      '621601': {'lat': '11.0216', 'name': 'Lalgudi H.O,Tiruchirappalli,Tamil Nadu', 'lon': '78.9534'},
      '605501': {'lat': '12.3081', 'name': 'Madurapakkam,Villupuram,Tamil Nadu', 'lon': '79.7056'},
      '470672': {'lat': '23.8127', 'name': 'Sarkhadi,Damoh,Madhya Pradesh', 'lon': '79.5273'},
      '383255': {'lat': '23.9041', 'name': 'Chikhala,Sabarkantha,Gujarat', 'lon': '73.1299'},
      '481111': {'lat': '22.1717', 'name': 'Boda,Balaghat,Madhya Pradesh', 'lon': '80.4768'},
      '490022': {'lat': '21.2367', 'name': 'S A F Lines Bhilai,Durg,Chattisgarh', 'lon': '81.3973'},
      '604101': {'lat': '12.2071', 'name': 'Avanipur,Villupuram,Tamil Nadu', 'lon': '79.5611'},
      '490025': {'lat': '21.2419', 'name': 'Janjgiri,Durg,Chattisgarh', 'lon': '81.4722'},
      '604102': {'lat': '12.1648', 'name': 'Thensiruvalur,Villupuram,Tamil Nadu', 'lon': '79.6993'},
      '490024': {'lat': '21.2729', 'name': 'Dhaur,Durg,Chattisgarh', 'lon': '81.3645'},
      '452007': {'lat': '22.7334', 'name': 'Indore Siyaganj,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '685618': {'lat': '10.0365', 'name': 'Sinkukandam,Idukki,Kerala', 'lon': '77.1773'},
      '685619': {'lat': '10.0777', 'name': 'Rajakumari,Idukki,Kerala', 'lon': '77.1209'},
      '673596': {'lat': '11.7137', 'name': 'Poothadi,Wayanad,Kerala', 'lon': '76.1639'},
      '685612': {'lat': '10.0797', 'name': 'Letchmi Estate,Idukki,Kerala', 'lon': '77.0551'},
      '685613': {'lat': '10.0648', 'name': 'Panniar Estate,Idukki,Kerala', 'lon': '77.1074'},
      '500068': {'lat': '17.3568', 'name': 'Mansoorabad,K.V.Rangareddy,Telangana', 'lon': '78.4524'},
      '491340': {'lat': '21.8943', 'name': 'Putpura,Durg,Chattisgarh', 'lon': '81.8086'},
      '685616': {'lat': '10.0777', 'name': 'Mattupatty ISP,Idukki,Kerala', 'lon': '77.1209'},
      '403703': {'lat': '15.1206', 'name': 'Balli,South Goa,Goa', 'lon': '74.0115'},
      '685614': {'lat': '10.0777', 'name': 'Chattamannar,Idukki,Kerala', 'lon': '77.1209'},
      '685615': {'lat': '10.1279', 'name': 'Sethuparvathipuram,Idukki,Kerala', 'lon': '77.2097'},
      '790103': {'lat': '27.4852', 'name': 'Seijosa,East Kameng,Arunachal Pradesh', 'lon': '92.0686'},
      '440008': {'lat': '21.1365', 'name': 'Bagadganj,Nagpur,Maharashtra', 'lon': '79.0912'},
      '440006': {'lat': '21.1365', 'name': 'Seminary Hills,Nagpur,Maharashtra', 'lon': '79.0912'},
      '440007': {'lat': '21.1365', 'name': 'Vayusena Nagar,Nagpur,Maharashtra', 'lon': '79.0912'},
      '440005': {'lat': '21.1365', 'name': 'Nagpur Airport,Nagpur,Maharashtra', 'lon': '79.0912'},
      '440002': {'lat': '21.1365', 'name': 'Nayapura,Nagpur,Maharashtra', 'lon': '79.0912'},
      '440003': {'lat': '21.1463', 'name': 'Ajni,Nagpur,Maharashtra', 'lon': '79.0849'},
      '440001': {'lat': '21.1365', 'name': 'Kasturchand Park,Nagpur,Maharashtra', 'lon': '79.0912'},
      '243636': {'lat': '27.9627', 'name': 'Kichhla,Budaun,Uttar Pradesh', 'lon': '78.9137'},
      '584104': {'lat': '16.1182', 'name': 'Askihal,Raichur,Karnataka', 'lon': '76.8655'},
      '484774': {'lat': '24.0869', 'name': 'Kharpa,Shahdol,Madhya Pradesh', 'lon': '81.4335'},
      '412107': {'lat': '17.2965', 'name': 'Kambegi,Pune,Maharashtra', 'lon': '73.8999'},
      '584101': {'lat': '16.202', 'name': 'Raichur R.S.,Raichur,Karnataka', 'lon': '77.2515'},
      '584102': {'lat': '16.2741', 'name': 'Kuruvapur,Raichur,Karnataka', 'lon': '77.4696'},
      '584103': {'lat': '16.153', 'name': 'Raichur Patel Road,Raichur,Karnataka', 'lon': '77.3691'},
      '207120': {'lat': '27.4832', 'name': 'Muiddinpur,Etah,Uttar Pradesh', 'lon': '78.5414'},
      '804423': {'lat': '24.8346', 'name': 'Baina Lakhaipur,Jehanabad,Bihar', 'lon': '84.7302'},
      '521178': {'lat': '16.9481', 'name': 'Tirumalagiri,Krishna,Andhra Pradesh', 'lon': '80.524'},
      '243630': {'lat': '28.0979', 'name': 'Sisaiya Gusain,Budaun,Uttar Pradesh', 'lon': '79.3651'},
      '521175': {'lat': '16.3078', 'name': 'Shermohammadpet,Krishna,Andhra Pradesh', 'lon': '80.6043'},
      '612904': {'lat': '11.0929', 'name': 'Thengachiperumalnatham,Ariyalur,Tamil Nadu', 'lon': '79.3304'},
      '612905': {'lat': '11.1759', 'name': 'Ulkottai,Tiruchirappalli,Tamil Nadu', 'lon': '79.3924'},
      '521170': {'lat': '16.6278', 'name': 'Veerullapadu,Krishna,Andhra Pradesh', 'lon': '80.7312'},
      '326514': {'lat': '24.4142', 'name': 'Piplia Khurd,Jhalawar,Rajasthan', 'lon': '76.3149'},
      '612901': {'lat': '11.1755', 'name': 'Gangaikondacholapuram,Ariyalur,Tamil Nadu', 'lon': '79.2468'},
      '225119': {'lat': '26.6847', 'name': 'Bibiapur,Barabanki,Uttar Pradesh', 'lon': '81.4081'},
      '228155': {'lat': '26.3232', 'name': 'Khadar Basantpur,Sultanpur,Uttar Pradesh', 'lon': '82.0027'},
      '206120': {'lat': '26.7113', 'name': 'Niwarikalan,Etawah,Uttar Pradesh', 'lon': '79.2373'},
      '303329': {'lat': '26.7169', 'name': 'A C Jobner,Jaipur,Rajasthan', 'lon': '76.0256'},
      '303328': {'lat': '26.7052', 'name': 'Jorpura Sundariawas,Jaipur,Rajasthan', 'lon': '75.6687'},
      '756084': {'lat': '20.0081', 'name': 'Sugo,Baleswar,Odisha', 'lon': '85.5625'},
      '756085': {'lat': '20.0081', 'name': 'Naskarpur,Baleswar,Odisha', 'lon': '85.5625'},
      '756086': {'lat': '20.0081', 'name': 'Chormara,Baleswar,Odisha', 'lon': '85.5625'},
      '756087': {'lat': '20.0081', 'name': 'Chamara Gaon,Baleswar,Odisha', 'lon': '85.5625'},
      '756080': {'lat': '20.0081', 'name': 'Sahada,Baleswar,Odisha', 'lon': '85.5625'},
      '756081': {'lat': '20.0081', 'name': 'Jamsuli,Baleswar,Odisha', 'lon': '85.5625'},
      '756083': {'lat': '20.0081', 'name': 'Bagada,Baleswar,Odisha', 'lon': '85.5625'},
      '243751': {'lat': '28.335', 'name': 'Mehmoodpur,Budaun,Uttar Pradesh', 'lon': '78.4532'},
      '243202': {'lat': '28.4494', 'name': 'Bilwa,Bareilly,Uttar Pradesh', 'lon': '79.4223'},
      '243201': {'lat': '28.8152', 'name': 'Siatheri,Bareilly,Uttar Pradesh', 'lon': '79.4224'},
      '741158': {'lat': '23.9808', 'name': 'Darermath,Nadia,West Bengal', 'lon': '88.7197'},
      '229215': {'lat': '26.0759', 'name': 'Malkegaon,Raebareli,Uttar Pradesh', 'lon': '80.9644'},
      '242123': {'lat': '27.8328', 'name': 'Seramau (North),Shahjahanpur,Uttar Pradesh', 'lon': '79.7179'},
      '493101': {'lat': '21.0713', 'name': 'Bishrampur,Raipur,Chattisgarh', 'lon': '82.174'},
      '731233': {'lat': '24.1204', 'name': 'Sandhigorabazar,Birbhum,West Bengal', 'lon': '87.7744'},
      '607104': {'lat': '11.785', 'name': 'Kalinjikuppam,Cuddalore,Tamil Nadu', 'lon': '79.6175'},
      '305624': {'lat': '25.9799', 'name': 'Badi,Ajmer,Rajasthan', 'lon': '74.7098'},
      '607106': {'lat': '11.8516', 'name': 'Sathipattu,Cuddalore,Tamil Nadu', 'lon': '79.5136'},
      '607107': {'lat': '11.8572', 'name': 'Valayampattu,Villupuram,Tamil Nadu', 'lon': '79.4251'},
      '284201': {'lat': '25.546', 'name': 'Ghughuwa,Jhansi,Uttar Pradesh', 'lon': '79.1526'},
      '607101': {'lat': '11.9033', 'name': 'Alagaperumalkuppam,Cuddalore,Tamil Nadu', 'lon': '79.1522'},
      '607102': {'lat': '11.7569', 'name': 'Pallavarayanatham,Cuddalore,Tamil Nadu', 'lon': '79.6238'},
      '382640': {'lat': '23.3146', 'name': 'Sardhav,Gandhi Nagar,Gujarat', 'lon': '72.9431'},
      '242406': {'lat': '27.764', 'name': 'Shahjapur,Shahjahanpur,Uttar Pradesh', 'lon': '79.9035'},
      '242407': {'lat': '28.034', 'name': 'Dhakiatiwari,Shahjahanpur,Uttar Pradesh', 'lon': '79.8511'},
      '792101': {'lat': '28.604', 'name': 'Alinye,Dibang Valley,Arunachal Pradesh', 'lon': '95.8664'},
      '242405': {'lat': '27.8156', 'name': 'Kajriniranjanpur,Shahjahanpur,Uttar Pradesh', 'lon': '80.4293'},
      '607108': {'lat': '11.8294', 'name': 'Enathirimangalam,Cuddalore,Tamil Nadu', 'lon': '79.4986'},
      '370030': {'lat': '23.2032', 'name': 'Jiyapar,Kachchh,Gujarat', 'lon': '69.4192'},
      '504306': {'lat': '19.0749', 'name': 'Mujgi,Adilabad,Telangana', 'lon': '78.35'},
      '465550': {'lat': '23.5797', 'name': 'Amba,Shajapur,Madhya Pradesh', 'lon': '76.3458'},
      '413114': {'lat': '18.5709', 'name': 'Shirsatwadi,Pune,Maharashtra', 'lon': '74.0691'},
      '413115': {'lat': '18.9724', 'name': 'Malegaon BK,Pune,Maharashtra', 'lon': '75.7184'},
      '413116': {'lat': '18.5709', 'name': 'Shivnagar,Pune,Maharashtra', 'lon': '74.0691'},
      '306301': {'lat': '26.4197', 'name': 'Kanwaliya  Kalan,Pali,Rajasthan', 'lon': '74.0869'},
      '413110': {'lat': '18.9724', 'name': 'Dhakale,Pune,Maharashtra', 'lon': '75.7184'},
      '413111': {'lat': '18.9724', 'name': 'Mandave,Solapur,Maharashtra', 'lon': '75.7184'},
      '413112': {'lat': '17.7578', 'name': 'Lavang,Solapur,Maharashtra', 'lon': '75.7053'},
      '413113': {'lat': '18.9579', 'name': 'Maloli,Solapur,Maharashtra', 'lon': '75.8714'},
      '306308': {'lat': '26.3181', 'name': 'Kharadi,Pali,Rajasthan', 'lon': '73.9439'},
      '799002': {'lat': '23.6182', 'name': 'Paschim Bhubanban,West Tripura,Tripura', 'lon': '91.356'},
      '413118': {'lat': '18.9724', 'name': 'Yeshwantnagar (Solapur),Solapur,Maharashtra', 'lon': '75.7184'},
      '312024': {'lat': '24.5651', 'name': 'Bhadsaura,Chittorgarh,Rajasthan', 'lon': '73.8896'},
      '611109': {'lat': '10.6961', 'name': 'Palakurichi,Nagapattinam,Tamil Nadu', 'lon': '79.7397'},
      '305621': {'lat': '26.0889', 'name': 'Paranga,Ajmer,Rajasthan', 'lon': '74.6567'},
      '497778': {'lat': '22.1945', 'name': 'Mandisarai,Koriya,Chattisgarh', 'lon': '82.4904'},
      '629180': {'lat': '8.2215', 'name': 'Manakarai,Kanyakumari,Tamil Nadu', 'lon': '77.3611'},
      '312025': {'lat': '25.1959', 'name': 'Senthi,Chittorgarh,Rajasthan', 'lon': '74.7421'},
      '305622': {'lat': '26.0389', 'name': 'Bubkiya,Ajmer,Rajasthan', 'lon': '74.7792'},
      '305623': {'lat': '26.0717', 'name': 'Kirap,Ajmer,Rajasthan', 'lon': '74.4532'},
      '560025': {'lat': '12.9634', 'name': 'CMP Centre And School,Bengaluru,Karnataka', 'lon': '77.6025'},
      '560024': {'lat': '12.9996', 'name': 'Hebbal Kempapura,Bengaluru,Karnataka', 'lon': '77.6359'},
      '560027': {'lat': '12.9506', 'name': 'Sampangiramnagar,Bengaluru,Karnataka', 'lon': '77.5952'},
      '388310': {'lat': '22.5635', 'name': 'Ajarpura,Anand,Gujarat', 'lon': '72.9091'},
      '516293': {'lat': '14.6466', 'name': 'Galivaripalle,Cuddapah,Andhra Pradesh', 'lon': '78.9662'},
      '388640': {'lat': '22.6106', 'name': 'Vatra,Anand,Gujarat', 'lon': '72.1851'},
      '388315': {'lat': '22.5635', 'name': 'Jol,Anand,Gujarat', 'lon': '72.9091'},
      '560022': {'lat': '12.9996', 'name': 'Yeshwanthpur Bazar,Bengaluru,Karnataka', 'lon': '77.6359'},
      '495552': {'lat': '22.0552', 'name': 'Piparsatti,Janjgir-champa,Chattisgarh', 'lon': '82.4227'},
      '495553': {'lat': '21.9762', 'name': 'Nariyara,Janjgir-champa,Chattisgarh', 'lon': '82.4099'},
      '495550': {'lat': '22.1146', 'name': 'Kaudia,Bilaspur(CGH),Chattisgarh', 'lon': '82.3001'},
      '495551': {'lat': '21.8753', 'name': 'Darrighat,Bilaspur(CGH),Chattisgarh', 'lon': '82.2858'},
      '560029': {'lat': '13.133', 'name': 'Dharmaram College,Bengaluru,Karnataka', 'lon': '77.9274'},
      '495557': {'lat': '21.7811', 'name': 'Misda,Janjgir-champa,Chattisgarh', 'lon': '82.6806'},
      '495554': {'lat': '21.6531', 'name': 'Bhainso,Janjgir-champa,Chattisgarh', 'lon': '82.3262'},
      '495555': {'lat': '22.1074', 'name': 'Ujwal Nagar (Bilaspur),Bilaspur(CGH),Chattisgarh', 'lon': '82.2545'},
      '382423': {'lat': '22.9968', 'name': 'Shertha,Gandhi Nagar,Gujarat', 'lon': '73.0657'},
      '382422': {'lat': '22.9968', 'name': 'Uvarsad,Gandhi Nagar,Gujarat', 'lon': '73.0657'},
      '382421': {'lat': '23.1202', 'name': 'Khodiyar,Ahmedabad,Gujarat', 'lon': '73.2617'},
      '382427': {'lat': '22.89', 'name': 'Ode,Ahmedabad,Gujarat', 'lon': '72.5973'},
      '382426': {'lat': '23.1505', 'name': 'Koba,Gandhi Nagar,Gujarat', 'lon': '72.6399'},
      '382425': {'lat': '23.0548', 'name': 'Mahijada,Ahmedabad,Gujarat', 'lon': '73.211'},
      '691559': {'lat': '8.8182', 'name': 'Valavupacha,Kollam,Kerala', 'lon': '76.9709'},
      '480994': {'lat': '22.5108', 'name': 'Chhinda,Seoni,Madhya Pradesh', 'lon': '79.9976'},
      '480996': {'lat': '22.2525', 'name': 'Pandiwada,Seoni,Madhya Pradesh', 'lon': '80.0243'},
      '382428': {'lat': '23.255', 'name': 'Bhat,Gandhi Nagar,Gujarat', 'lon': '72.6923'},
      '480990': {'lat': '22.3114', 'name': 'Kanhan Piparia,Seoni,Madhya Pradesh', 'lon': '79.6246'},
      '770052': {'lat': '21.9743', 'name': 'Kalta,Sundergarh,Odisha', 'lon': '85.2251'},
      '770051': {'lat': '21.5182', 'name': 'Tikayatpali,Sundergarh,Odisha', 'lon': '84.9047'},
      '193221': {'lat': '34.4422', 'name': 'Shilhal,Kupwara,Jammu & Kashmir', 'lon': '74.2599'},
      '631303': {'lat': '13.1426', 'name': 'Veeranathur,Tiruvallur,Tamil Nadu', 'lon': '79.4577'},
      '193223': {'lat': '34.5276', 'name': 'Maidanpora,Kupwara,Jammu & Kashmir', 'lon': '74.3949'},
      '193222': {'lat': '34.55', 'name': 'Kalaroose,Kupwara,Jammu & Kashmir', 'lon': '74.2173'},
      '193225': {'lat': '34.3761', 'name': 'Hajitrah,Kupwara,Jammu & Kashmir', 'lon': '73.8394'},
      '193224': {'lat': '33.9967', 'name': 'Kachhama,Kupwara,Jammu & Kashmir', 'lon': '74.4905'},
      '476224': {'lat': '26.3473', 'name': 'Jhundpura,Morena,Madhya Pradesh', 'lon': '77.501'},
      '737101': {'lat': '27.3389426', 'name': 'Gangtok H.O,East Sikkim,Sikkim', 'lon': '88.6064965'},
      '761206': {'lat': '18.9256', 'name': 'Goribandha,Gajapati,Odisha', 'lon': '83.855'},
      '761207': {'lat': '19.642', 'name': 'Ashrayagada,Gajapati,Odisha', 'lon': '84.4422'},
      '761200': {'lat': '19.1403', 'name': 'Jangam Street,Gajapati,Odisha', 'lon': '83.9713'},
      '523230': {'lat': '15.1177', 'name': 'Chakirala,Prakasam,Andhra Pradesh', 'lon': '79.4617'},
      '737106': {'lat': '27.2282031', 'name': 'Parkha,East Sikkim,Sikkim', 'lon': '88.587925'},
      '416707': {'lat': '18.779', 'name': 'Kondsur Budruk,Ratnagiri,Maharashtra', 'lon': '76.3111'},
      '123021': {'lat': '28.2242', 'name': 'Bocharia,Rewari,Haryana', 'lon': '76.5554'},
      '123023': {'lat': '29.3043', 'name': 'Thanwas,Mahendragarh,Haryana', 'lon': '77.0492'},
      '123024': {'lat': '28.3955', 'name': 'Madhogarh,Mahendragarh,Haryana', 'lon': '75.9426'},
      '629178': {'lat': '8.3403', 'name': 'Udayamarthandam,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '123027': {'lat': '28.4013', 'name': 'Sehlon,Mahendragarh,Haryana', 'lon': '76.373'},
      '123028': {'lat': '28.1904', 'name': 'Bairawas,Mahendragarh,Haryana', 'lon': '76.1312'},
      '123029': {'lat': '28.4851', 'name': 'Buddin,Mahendragarh,Haryana', 'lon': '76.4735'},
      '689614': {'lat': '9.265', 'name': 'Kaithakody,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689615': {'lat': '9.265', 'name': 'Kottanad,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689612': {'lat': '9.265', 'name': 'Ayroor North,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689613': {'lat': '9.265', 'name': 'Vellayil,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '629173': {'lat': '8.3403', 'name': 'Vizhunthaiyambalam,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '689611': {'lat': '9.265', 'name': 'Kanjeettukara,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '326513': {'lat': '24.9607', 'name': 'Gadia,Jhalawar,Rajasthan', 'lon': '74.9582'},
      '686144': {'lat': '9.6875', 'name': 'Ambika Market,Kottayam,Kerala', 'lon': '76.7789'},
      '631304': {'lat': '13.1838', 'name': 'Sgbkandigai,Tiruvallur,Tamil Nadu', 'lon': '79.4422'},
      '174321': {'lat': '31.6703', 'name': 'Thanakalan,Una,Himachal Pradesh', 'lon': '76.5056'},
      '174320': {'lat': '31.6703', 'name': 'Talmehra,Una,Himachal Pradesh', 'lon': '76.5056'},
      '571438': {'lat': '12.6397', 'name': 'Srirangapatna H.O,Mandya,Karnataka', 'lon': '76.7928'},
      '571439': {'lat': '12.1108', 'name': 'Saggiam,Chamrajnagar,Karnataka', 'lon': '77.3102'},
      '680732': {'lat': '10.2383', 'name': 'Vadama,Thrissur,Kerala', 'lon': '76.2615'},
      '680733': {'lat': '10.2144', 'name': 'Madathumpady,Thrissur,Kerala', 'lon': '76.2451'},
      '571436': {'lat': '12.775', 'name': 'Doddasomanahalli,Mandya,Karnataka', 'lon': '76.5507'},
      '680731': {'lat': '10.2909', 'name': 'Ashtamichira,Thrissur,Kerala', 'lon': '76.2841'},
      '571430': {'lat': '12.5254', 'name': 'Nelamakanahalli,Mandya,Karnataka', 'lon': '77.1606'},
      '571431': {'lat': '12.7124', 'name': 'Chinya,Mandya,Karnataka', 'lon': '76.7026'},
      '680734': {'lat': '10.1953', 'name': 'Iranikulam,Thrissur,Kerala', 'lon': '76.2929'},
      '571433': {'lat': '13.1142', 'name': 'Kadalur,Mandya,Karnataka', 'lon': '76.3489'},
      '673011': {'lat': '11.2664', 'name': 'Nadakavu,Kozhikode,Kerala', 'lon': '75.8167'},
      '673010': {'lat': '11.288', 'name': 'Vengeri,Kozhikode,Kerala', 'lon': '75.788'},
      '673012': {'lat': '11.3048', 'name': 'Marikunnu,Kozhikode,Kerala', 'lon': '75.8259'},
      '673015': {'lat': '11.1715', 'name': 'Beypore North,Kozhikode,Kerala', 'lon': '75.8061'},
      '673014': {'lat': '11.2664', 'name': 'Guruvayurappan College,Kozhikode,Kerala', 'lon': '75.8167'},
      '673017': {'lat': '11.289', 'name': 'Chevarambalam,Kozhikode,Kerala', 'lon': '75.8168'},
      '673016': {'lat': '11.2703', 'name': 'Govinda Puram,Kozhikode,Kerala', 'lon': '75.8014'},
      '673019': {'lat': '11.2529', 'name': 'Pantheerankavu,Kozhikode,Kerala', 'lon': '75.8906'},
      '673018': {'lat': '11.2664', 'name': 'Calicut Arts & Science College,Kozhikode,Kerala', 'lon': '75.8167'},
      '613601': {'lat': '10.7936', 'name': 'Kalvirayanpettai,Thanjavur,Tamil Nadu', 'lon': '79.0573'},
      '263659': {'lat': '29.9278', 'name': 'Uprari Chaukote,Almora,Uttarakhand', 'lon': '79.2359'},
      '209735': {'lat': '27.1258', 'name': 'Tera Jaket,Kannauj,Uttar Pradesh', 'lon': '79.657'},
      '209734': {'lat': '27.012', 'name': 'Khama,Kannauj,Uttar Pradesh', 'lon': '79.6879'},
      '423701': {'lat': '20.559', 'name': 'Akoli Wadgaon,Aurangabad,Maharashtra', 'lon': '75.0485'},
      '209731': {'lat': '27.0462', 'name': 'Jeramau Alampur,Kannauj,Uttar Pradesh', 'lon': '79.6903'},
      '209733': {'lat': '27.0712', 'name': 'Saunsarapur,Kannauj,Uttar Pradesh', 'lon': '79.819'},
      '626005': {'lat': '11.7353', 'name': 'Amathur,Virudhunagar,Tamil Nadu', 'lon': '78.6982'},
      '273161': {'lat': '27.1064', 'name': 'Belouha,Maharajganj,Uttar Pradesh', 'lon': '83.2115'},
      '273163': {'lat': '26.7072', 'name': 'Khesrari,Gorakhpur,Uttar Pradesh', 'lon': '83.2408'},
      '273162': {'lat': '27.2028', 'name': 'Rajmandir Kala,Maharajganj,Uttar Pradesh', 'lon': '83.3674'},
      '209739': {'lat': '27.2635', 'name': 'Danmandi,Farrukhabad,Uttar Pradesh', 'lon': '79.5631'},
      '209738': {'lat': '26.8546', 'name': 'Rampur Majhila,Kannauj,Uttar Pradesh', 'lon': '79.7231'},
      '431811': {'lat': '19.5583', 'name': 'Sheniwarpeth,Nanded,Maharashtra', 'lon': '78.0709'},
      '495684': {'lat': '22.5219', 'name': 'Balco Nagar Korba,Janjgir-champa,Chattisgarh', 'lon': '82.7091'},
      '632201': {'lat': '12.9351', 'name': 'Perumankuppam,Vellore,Tamil Nadu', 'lon': '79.1865'},
      '431810': {'lat': '19.7769', 'name': 'Bodhadi KH,Nanded,Maharashtra', 'lon': '78.1184'},
      '228121': {'lat': '26.4038', 'name': 'Jajjaur,Sultanpur,Uttar Pradesh', 'lon': '82.0073'},
      '481441': {'lat': '22.0118', 'name': 'Jam,Balaghat,Madhya Pradesh', 'lon': '80.0534'},
      '505210': {'lat': '18.8095', 'name': 'Fertilizername,Karim Nagar,Telangana', 'lon': '79.3808'},
      '321633': {'lat': '27.1477', 'name': 'Bedar,Alwar,Rajasthan', 'lon': '76.6308'},
      '505101': {'lat': '18.2062', 'name': 'Kannur,Karim Nagar,Telangana', 'lon': '79.5067'},
      '209312': {'lat': '26.2701', 'name': 'Malasa,Kanpur Dehat,Uttar Pradesh', 'lon': '79.9414'},
      '305012': {'lat': '26.4793', 'name': 'Mss Hatundi,Ajmer,Rajasthan', 'lon': '74.659'},
      '441903': {'lat': '20.7364', 'name': 'Kurza,Bhandara,Maharashtra', 'lon': '79.1107'},
      '209311': {'lat': '26.4127', 'name': 'Mohana (E.U.P. 7305),Kanpur Dehat,Uttar Pradesh', 'lon': '79.9717'},
      '503206': {'lat': '18.5976', 'name': 'Govur,Nizamabad,Telangana', 'lon': '77.9485'},
      '209310': {'lat': '26.5136', 'name': 'Asvi,Kanpur Dehat,Uttar Pradesh', 'lon': '79.7807'},
      '754248': {'lat': '20.732', 'name': 'Rangani,Kendrapara,Odisha', 'lon': '85.2217'},
      '754244': {'lat': '20.8615', 'name': 'Mahendinagar,Kendrapara,Odisha', 'lon': '85.0632'},
      '172101': {'lat': '31.5062', 'name': 'Nigulsari,Kinnaur,Himachal Pradesh', 'lon': '77.3347'},
      '754246': {'lat': '20.8615', 'name': 'Madanpur (Kendrapara),Kendrapara,Odisha', 'lon': '85.0632'},
      '505214': {'lat': '18.8095', 'name': 'Vittalanagar,Karim Nagar,Telangana', 'lon': '79.3808'},
      '754240': {'lat': '20.8615', 'name': 'Andara,Kendrapara,Odisha', 'lon': '85.0632'},
      '274408': {'lat': '26.5107', 'name': 'Sirsia Khohia,Deoria,Uttar Pradesh', 'lon': '83.7993'},
      '722158': {'lat': '23.5227', 'name': 'Murlu,Bankura,West Bengal', 'lon': '86.936'},
      '509334': {'lat': '16.8351', 'name': 'Buddaram,Mahabub Nagar,Telangana', 'lon': '77.8627'},
      '577597': {'lat': '13.7456', 'name': 'Thandaga,Chitradurga,Karnataka', 'lon': '76.2657'},
      '577596': {'lat': '14.109', 'name': 'Khandenahalli,Chitradurga,Karnataka', 'lon': '76.908'},
      '524203': {'lat': '15.1092', 'name': 'Thummalapenta ,Nellore,Andhra Pradesh', 'lon': '80.0039'},
      '482056': {'lat': '22.9473', 'name': 'Sahajpuri,Jabalpur,Madhya Pradesh', 'lon': '79.9001'},
      '333034': {'lat': '28.148', 'name': 'Kajala,Jhujhunu,Rajasthan', 'lon': '76.0491'},
      '410403': {'lat': '19.4781', 'name': 'Kaivalyadham,Pune,Maharashtra', 'lon': '73.7845'},
      '400078': {'lat': '19.0931', 'name': 'Bhandup Ind. Estate,Mumbai,Maharashtra', 'lon': '72.9049'},
      '577599': {'lat': '13.9033', 'name': 'Myaklurahally,Chitradurga,Karnataka', 'lon': '76.4917'},
      '577598': {'lat': '13.7812', 'name': 'Uduvally,Chitradurga,Karnataka', 'lon': '76.5668'},
      '503207': {'lat': '18.377', 'name': 'Sunkini,Nizamabad,Telangana', 'lon': '78.0618'},
      '410402': {'lat': '19.4781', 'name': 'Kurwande,Pune,Maharashtra', 'lon': '73.7845'},
      '403506': {'lat': '15.5304', 'name': 'Thana,North Goa,Goa', 'lon': '74.1632'},
      '631052': {'lat': '12.9326', 'name': 'Ulianallore,Vellore,Tamil Nadu', 'lon': '79.5656'},
      '631051': {'lat': '12.5917', 'name': 'Pallur,Vellore,Tamil Nadu', 'lon': '79.7168'},
      '403505': {'lat': '15.5565', 'name': 'Kerim,North Goa,Goa', 'lon': '74.0147'},
      '403502': {'lat': '15.6193', 'name': 'Sirsaim,North Goa,Goa', 'lon': '73.8442'},
      '325201': {'lat': '25.3629', 'name': 'Tathed,Kota,Rajasthan', 'lon': '75.8514'},
      '403501': {'lat': '15.5859', 'name': 'Porvorim,North Goa,Goa', 'lon': '73.9513'},
      '802134': {'lat': '25.5525', 'name': 'Dharauli,Buxar,Bihar', 'lon': '84.2558'},
      '741315': {'lat': '23.601', 'name': 'Amghata,Nadia,West Bengal', 'lon': '88.1273'},
      '515711': {'lat': '14.8495', 'name': 'Marutla,Ananthapur,Andhra Pradesh', 'lon': '77.3874'},
      '741317': {'lat': '23.601', 'name': 'Aluipara,Nadia,West Bengal', 'lon': '88.1273'},
      '515241': {'lat': '13.9471', 'name': 'Kallagiri,Ananthapur,Andhra Pradesh', 'lon': '77.7053'},
      '802131': {'lat': '25.5908', 'name': 'Sheopur Diar,Buxar,Bihar', 'lon': '84.2573'},
      '721431': {'lat': '21.8532', 'name': 'Kunjapur,East Midnapore,West Bengal', 'lon': '87.9147'},
      '741313': {'lat': '23.601', 'name': 'Nidaya,Nadia,West Bengal', 'lon': '88.1273'},
      '686106': {'lat': '9.6875', 'name': 'Changanacherry Industrialnagar,Kottayam,Kerala', 'lon': '76.7789'},
      '686105': {'lat': '9.6875', 'name': 'Thrickodithanam,Kottayam,Kerala', 'lon': '76.7789'},
      '686104': {'lat': '9.6875', 'name': 'Kurisummoodu,Kottayam,Kerala', 'lon': '76.7789'},
      '686103': {'lat': '9.6875', 'name': 'Kumarankary,Alappuzha,Kerala', 'lon': '76.7789'},
      '686102': {'lat': '9.6875', 'name': 'Kunnamkary,Alappuzha,Kerala', 'lon': '76.7789'},
      '686101': {'lat': '9.6875', 'name': 'Changanacherry College,Kottayam,Kerala', 'lon': '76.7789'},
      '632507': {'lat': '12.674', 'name': 'Vethajipuram,Tiruvannamalai,Tamil Nadu', 'lon': '79.1057'},
      '632508': {'lat': '12.9327', 'name': 'Kadaperi,Vellore,Tamil Nadu', 'lon': '79.5074'},
      '632509': {'lat': '12.8965', 'name': 'Melvisharam,Vellore,Tamil Nadu', 'lon': '79.2718'},
      '854311': {'lat': '26.1036', 'name': 'Haryabara,Araria,Bihar', 'lon': '87.2743'},
      '801110': {'lat': '25.3379', 'name': 'Paipura Kala,Patna,Bihar', 'lon': '84.9024'},
      '854317': {'lat': '25.6743', 'name': 'Sudhani R.S.,Katihar,Bihar', 'lon': '87.7418'},
      '854316': {'lat': '26.0343', 'name': 'Amouna,Purnia,Bihar', 'lon': '87.2284'},
      '854315': {'lat': '25.8919', 'name': 'Machhatta,Purnia,Bihar', 'lon': '87.6207'},
      '400072': {'lat': '19.0931', 'name': 'Vihar Road,Mumbai,Maharashtra', 'lon': '72.9049'},
      '396220': {'lat': '20.3833', 'name': 'Magarwada,Daman,Daman & Diu', 'lon': '72.8333'},
      '484661': {'lat': '23.836', 'name': 'Baderi,Umaria,Madhya Pradesh', 'lon': '80.9454'},
      '210120': {'lat': '25.3855', 'name': 'Nagnedi,Banda,Uttar Pradesh', 'lon': '80.4639'},
      '484664': {'lat': '23.9654', 'name': 'Jhall,Umaria,Madhya Pradesh', 'lon': '80.9231'},
      '484665': {'lat': '23.5644', 'name': 'Kathar,Umaria,Madhya Pradesh', 'lon': '80.9719'},
      '415611': {'lat': '19.6644', 'name': 'Lowale,Ratnagiri,Maharashtra', 'lon': '75.4197'},
      '792110': {'lat': '28.31', 'name': 'Desali,Lower Dibang Valley,Arunachal Pradesh', 'lon': '95.7475'},
      '484669': {'lat': '24.016', 'name': 'Kadaudi,Shahdol,Madhya Pradesh', 'lon': '81.2997'},
      '825106': {'lat': '24.0019', 'name': 'Choudhary Bandh,Giridh,Jharkhand', 'lon': '86.1055'},
      '326520': {'lat': '24.6596', 'name': 'Barodia Kalan,Kota,Rajasthan', 'lon': '75.9307'},
      '277403': {'lat': '25.8327', 'name': 'Belahari,Ballia,Uttar Pradesh', 'lon': '84.2314'},
      '825102': {'lat': '23.7317', 'name': 'Angwali,Bokaro,Jharkhand', 'lon': '85.9858'},
      '825103': {'lat': '24.1595', 'name': 'Lawalon,Chatra,Jharkhand', 'lon': '84.6527'},
      '825101': {'lat': '23.5756', 'name': 'Lari,Ramgarh,Jharkhand', 'lon': '85.5726'},
      '802201': {'lat': '25.4652', 'name': 'Pawana,Bhojpur,Bihar', 'lon': '84.5717'},
      '825108': {'lat': '24.0526', 'name': 'Atki,Giridh,Jharkhand', 'lon': '86.0944'},
      '313204': {'lat': '24.5928', 'name': 'Uthnol,Udaipur,Rajasthan', 'lon': '73.9119'},
      '607401': {'lat': '11.7681', 'name': 'Tirumanikuzhi,Cuddalore,Tamil Nadu', 'lon': '79.3947'},
      '626149': {'lat': '9.6272', 'name': 'Ilandaikulam,Virudhunagar,Tamil Nadu', 'lon': '77.7034'},
      '533408': {'lat': '17.5465', 'name': 'Vontimamidi,East Godavari,Andhra Pradesh', 'lon': '82.4347'},
      '533407': {'lat': '17.4546', 'name': 'K E Chinnayyapalem,East Godavari,Andhra Pradesh', 'lon': '82.452'},
      '533406': {'lat': '17.2813', 'name': 'Latchireddipalem,East Godavari,Andhra Pradesh', 'lon': '82.2221'},
      '573135': {'lat': '12.9497', 'name': 'Dadighatta,Hassan,Karnataka', 'lon': '76.6165'},
      '573134': {'lat': '12.8836', 'name': 'Matasagara,Hassan,Karnataka', 'lon': '75.8683'},
      '573133': {'lat': '12.7829', 'name': 'Gangur,Hassan,Karnataka', 'lon': '76.177'},
      '607403': {'lat': '11.7681', 'name': 'Reddichavady,Pondicherry,Pondicherry', 'lon': '79.3947'},
      '533401': {'lat': '17.1885', 'name': 'S Annavaram,East Godavari,Andhra Pradesh', 'lon': '82.0118'},
      '573130': {'lat': '12.6318', 'name': 'Kadavina Hosahalli,Hassan,Karnataka', 'lon': '76.0312'},
      '742409': {'lat': '23.9685', 'name': 'Shaikhpara.,Murshidabad,West Bengal', 'lon': '87.8257'},
      '761118': {'lat': '19.6269', 'name': 'Adipur,Ganjam,Odisha', 'lon': '84.6711'},
      '535128': {'lat': '18.3078', 'name': 'Gulivinda Agraharam,Vizianagaram,Andhra Pradesh', 'lon': '83.5104'},
      '607402': {'lat': '11.7681', 'name': 'Thokkanambakkam,Cuddalore,Tamil Nadu', 'lon': '79.3947'},
      '326518': {'lat': '24.9411', 'name': 'Hatiya Kheri,Kota,Rajasthan', 'lon': '75.9594'},
      '734005': {'lat': '26.731', 'name': 'Jyoti Nagar,Darjiling,West Bengal', 'lon': '88.9486'},
      '742401': {'lat': '23.7708', 'name': 'Kagram,Murshidabad,West Bengal', 'lon': '88.1021'},
      '742402': {'lat': '23.9685', 'name': 'Talibpur,Murshidabad,West Bengal', 'lon': '87.8257'},
      '535126': {'lat': '18.5185', 'name': 'Goluguvalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.541'},
      '742404': {'lat': '23.9685', 'name': 'Koregram,Murshidabad,West Bengal', 'lon': '87.8257'},
      '535124': {'lat': '18.5064', 'name': 'Kunayavalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.4718'},
      '535125': {'lat': '18.4673', 'name': 'Mettapalli,Vizianagaram,Andhra Pradesh', 'lon': '83.3793'},
      '524319': {'lat': '14.2884', 'name': 'Naidupalem,Nellore,Andhra Pradesh', 'lon': '79.9301'},
      '524318': {'lat': '14.4602', 'name': 'Ponnapudipeddapalem,Nellore,Andhra Pradesh', 'lon': '79.8401'},
      '477445': {'lat': '25.8455', 'name': 'Mitawali,Bhind,Madhya Pradesh', 'lon': '79.0051'},
      '524313': {'lat': '14.8411', 'name': 'Kudithipalem,Nellore,Andhra Pradesh', 'lon': '80.046'},
      '524312': {'lat': '15.1347', 'name': 'Pegallapadu,Nellore,Andhra Pradesh', 'lon': '80.2717'},
      '524311': {'lat': '14.3856', 'name': 'Thotapalliguduru,Nellore,Andhra Pradesh', 'lon': '80.1098'},
      '524310': {'lat': '14.3613', 'name': 'Bodasi Idayapalli,Nellore,Andhra Pradesh', 'lon': '78.7979'},
      '524317': {'lat': '14.6095', 'name': 'Elamanchipadu,Nellore,Andhra Pradesh', 'lon': '79.9332'},
      '524316': {'lat': '14.579', 'name': 'Gundlammapalem,Nellore,Andhra Pradesh', 'lon': '80.0137'},
      '524315': {'lat': '14.6786', 'name': 'Batrakagallu,Nellore,Andhra Pradesh', 'lon': '80.0624'},
      '524314': {'lat': '14.5085', 'name': 'Pallipadu,Nellore,Andhra Pradesh', 'lon': '80.0551'},
      '758019': {'lat': '21.3165', 'name': 'Karadangi,Kendujhar,Odisha', 'lon': '85.3664'},
      '758018': {'lat': '21.0672', 'name': 'Guptaganga,Kendujhar,Odisha', 'lon': '85.2132'},
      '758015': {'lat': '21.2503', 'name': 'Dhakotha,Kendujhar,Odisha', 'lon': '85.8765'},
      '758014': {'lat': '21.068', 'name': 'Maidankela,Kendujhar,Odisha', 'lon': '85.1784'},
      '758017': {'lat': '21.3591', 'name': 'Rajanagar,Kendujhar,Odisha', 'lon': '85.3311'},
      '758016': {'lat': '21.663', 'name': 'Begna,Kendujhar,Odisha', 'lon': '85.887'},
      '758013': {'lat': '21.1324', 'name': 'Kempasada,Kendujhar,Odisha', 'lon': '85.2606'},
      '761110': {'lat': '20.0969', 'name': 'Raghunath Nagar (Ganjam),Ganjam,Odisha', 'lon': '84.619'},
      '845419': {'lat': '25.6844', 'name': 'Izara Nawada,East Champaran,Bihar', 'lon': '84.8244'},
      '577006': {'lat': '14.2019', 'name': 'Devaraj Urs Layout-Dvg,Davangere,Karnataka', 'lon': '75.9671'},
      '761111': {'lat': '19.7671', 'name': 'Dhaugam,Ganjam,Odisha', 'lon': '84.4102'},
      '323301': {'lat': '25.5241', 'name': 'Balkasa,Bundi,Rajasthan', 'lon': '75.8597'},
      '517247': {'lat': '13.378', 'name': 'Punganur Bus Stand,Chittoor,Andhra Pradesh', 'lon': '78.5588'},
      '700132': {'lat': '22.5719', 'name': 'Ganti,North 24 Parganas,West Bengal', 'lon': '88.394'},
      '700131': {'lat': '22.5719', 'name': 'Newbarackpur,North 24 Parganas,West Bengal', 'lon': '88.394'},
      '700130': {'lat': '22.5719', 'name': 'Taldharia Kapasia,North 24 Parganas,West Bengal', 'lon': '88.394'},
      '700137': {'lat': '22.4256', 'name': 'Hentalkhali,South 24 Parganas,West Bengal', 'lon': '88.1934'},
      '700136': {'lat': '22.7611', 'name': 'Narayanpur,North 24 Parganas,West Bengal', 'lon': '88.7615'},
      '700135': {'lat': '22.5864', 'name': 'Paikan,South 24 Parganas,West Bengal', 'lon': '88.4145'},
      '700134': {'lat': '22.5719', 'name': 'Nilachal,North 24 Parganas,West Bengal', 'lon': '88.394'},
      '761114': {'lat': '20.0969', 'name': 'Badula,Ganjam,Odisha', 'lon': '84.619'},
      '700139': {'lat': '22.5719', 'name': 'Vivekananda Pally,South 24 Parganas,West Bengal', 'lon': '88.394'},
      '700138': {'lat': '22.4711', 'name': 'Benjanharia,South 24 Parganas,West Bengal', 'lon': '88.1453'},
      '845406': {'lat': '26.6442', 'name': 'Kashi Pakari,East Champaran,Bihar', 'lon': '84.8081'},
      '761115': {'lat': '20.8372', 'name': 'Balisira,Ganjam,Odisha', 'lon': '84.4231'},
      '824219': {'lat': '24.6435', 'name': 'Nimabudhul,Gaya,Bihar', 'lon': '84.6667'},
      '577005': {'lat': '14.4188', 'name': 'Bapuji Vidyanagara,Davangere,Karnataka', 'lon': '75.915'},
      '761116': {'lat': '19.6785', 'name': 'Badakholi,Ganjam,Odisha', 'lon': '84.708'},
      '422402': {'lat': '19.9447', 'name': 'Korapgaon,Nashik,Maharashtra', 'lon': '74.3565'},
      '422403': {'lat': '20.0428', 'name': 'Aswali R.S,Nashik,Maharashtra', 'lon': '74.4886'},
      '638812': {'lat': '11.2707', 'name': 'Virumandampalayam,Erode,Tamil Nadu', 'lon': '77.6523'},
      '422401': {'lat': '19.4739', 'name': 'Belatgavhan,Nashik,Maharashtra', 'lon': '74.6206'},
      '824217': {'lat': '24.522', 'name': 'Parsawa,Gaya,Bihar', 'lon': '84.6918'},
      '415211': {'lat': '18.5574', 'name': 'Sonawade,Satara,Maharashtra', 'lon': '74.4986'},
      '795105': {'lat': '25.4161', 'name': 'Khongdei Simphung,Senapati,Manipur', 'lon': '94.112'},
      '415213': {'lat': '18.082039', 'name': 'Chimbhave,Raigarh(MH),Maharashtra', 'lon': '73.423361'},
      '415212': {'lat': '18.5574', 'name': 'Marul Haveli,Satara,Maharashtra', 'lon': '74.4986'},
      '731234': {'lat': '24.0927', 'name': 'Sainthia Bazar,Birbhum,West Bengal', 'lon': '87.6892'},
      '415214': {'lat': '18.5574', 'name': 'Padve,Ratnagiri,Maharashtra', 'lon': '74.4986'},
      '795102': {'lat': '24.8782', 'name': 'Thorchom,Chandel,Manipur', 'lon': '93.9796'},
      '795103': {'lat': '24.4468', 'name': 'Langmeidong,Kakching,Manipur', 'lon': '93.9124'},
      '641036': {'lat': '11.1173', 'name': 'Nanjundapuram,Coimbatore,Tamil Nadu', 'lon': '77.0488'},
      '641037': {'lat': '11.0329', 'name': 'Pappanaickenpalayam,Coimbatore,Tamil Nadu', 'lon': '77.2503'},
      '641034': {'lat': '11.0816', 'name': 'Tudiyalur,Coimbatore,Tamil Nadu', 'lon': '76.9446'},
      '641035': {'lat': '10.8592', 'name': 'Keeranatham,Coimbatore,Tamil Nadu', 'lon': '77.6785'},
      '641032': {'lat': '10.8423', 'name': 'Arisipalayam,Coimbatore,Tamil Nadu', 'lon': '77.0079'},
      '641033': {'lat': '11.0329', 'name': 'Neelikonampalayam,Coimbatore,Tamil Nadu', 'lon': '77.2503'},
      '641030': {'lat': '11.3534', 'name': 'Kavundampalayam Colony,Coimbatore,Tamil Nadu', 'lon': '77.4577'},
      '641031': {'lat': '11.1173', 'name': 'Narasimhanaickenpalayam,Coimbatore,Tamil Nadu', 'lon': '76.9363'},
      '627713': {'lat': '9.2397', 'name': 'Ilyarasanendal,Tirunelveli,Tamil Nadu', 'lon': '77.7268'},
      '481661': {'lat': '22.6445', 'name': 'Phoolsagar,Balaghat,Madhya Pradesh', 'lon': '80.4078'},
      '627719': {'lat': '9.1079', 'name': 'Kurinjakulam,Tirunelveli,Tamil Nadu', 'lon': '77.6088'},
      '722205': {'lat': '23.8237', 'name': 'Kharrah,Bankura,West Bengal', 'lon': '87.7387'},
      '743456': {'lat': '22.6389', 'name': 'Amodpur,North 24 Parganas,West Bengal', 'lon': '88.746'},
      '625708': {'lat': '9.7561', 'name': 'Solaipatti,Madurai,Tamil Nadu', 'lon': '77.9748'},
      '506004': {'lat': '18.0142', 'name': 'R E College,Warangal,Telangana', 'lon': '79.5184'},
      '203389': {'lat': '28.1814', 'name': 'NAPP,Bulandshahr,Uttar Pradesh', 'lon': '78.3677'},
      '484886': {'lat': '22.7383', 'name': 'Khati,Anuppur,Madhya Pradesh', 'lon': '81.6537'},
      '413322': {'lat': '17.2979', 'name': 'Nandeshwar,Solapur,Maharashtra', 'lon': '75.4297'},
      '621014': {'lat': '11.1057', 'name': 'Singalandapuram,Tiruchirappalli,Tamil Nadu', 'lon': '78.588'},
      '384151': {'lat': '23.5046', 'name': 'Nagvasana,Patan,Gujarat', 'lon': '72.9524'},
      '497446': {'lat': '23.1316', 'name': 'Jhagrakhand Colly,Koriya,Chattisgarh', 'lon': '82.2859'},
      '621010': {'lat': '11.2554', 'name': 'Turaiyur Bus Stand,Tiruchirappalli,Tamil Nadu', 'lon': '78.4921'},
      '413324': {'lat': '18.4049', 'name': 'Hiware,Solapur,Maharashtra', 'lon': '76.1217'},
      '444002': {'lat': '20.4534', 'name': 'Gajanan Nagar,Akola,Maharashtra', 'lon': '77.5627'},
      '444003': {'lat': '20.7489', 'name': 'Donwada,Akola,Maharashtra', 'lon': '77.0533'},
      '444001': {'lat': '20.4534', 'name': 'Akola H.O,Akola,Maharashtra', 'lon': '77.5627'},
      '444006': {'lat': '20.4565', 'name': 'Gandhigram,Akola,Maharashtra', 'lon': '77.6103'},
      '574241': {'lat': '12.7931', 'name': 'Ilanthila,Dakshina Kannada,Karnataka', 'lon': '75.3247'},
      '444004': {'lat': '20.2376', 'name': 'Chandur,Washim,Maharashtra', 'lon': '77.219'},
      '444005': {'lat': '20.4994', 'name': 'Jatherpeth Akola,Akola,Maharashtra', 'lon': '78.894'},
      '721501': {'lat': '22.8388', 'name': 'Aguibil,West Midnapore,West Bengal', 'lon': '86.5963'},
      '560050': {'lat': '12.9634', 'name': 'Banashankari,Bengaluru,Karnataka', 'lon': '77.6114'},
      '841245': {'lat': '26.2593', 'name': 'Narianpur,Siwan,Bihar', 'lon': '84.8128'},
      '841244': {'lat': '26.0574', 'name': 'Bhikha Bandh Satjora,Siwan,Bihar', 'lon': '84.511'},
      '574248': {'lat': '12.5616', 'name': 'Doddathota,Dakshina Kannada,Karnataka', 'lon': '75.4855'},
      '841242': {'lat': '26.1115', 'name': 'Ratanparauli,Siwan,Bihar', 'lon': '84.5662'},
      '841241': {'lat': '26.158', 'name': 'Gambhria,Siwan,Bihar', 'lon': '84.4247'},
      '841240': {'lat': '26.0541', 'name': 'Chandparsa,Siwan,Bihar', 'lon': '84.4324'},
      '395012': {'lat': '23.1064', 'name': 'Godadara,Surat,Gujarat', 'lon': '73.2309'},
      '395013': {'lat': '23.1064', 'name': 'Laskana,Surat,Gujarat', 'lon': '73.2309'},
      '395010': {'lat': '23.1064', 'name': 'Parvat Patia,Surat,Gujarat', 'lon': '73.2309'},
      '395011': {'lat': '23.1064', 'name': 'Puna Kumbharia,Surat,Gujarat', 'lon': '73.2309'},
      '395017': {'lat': '23.1064', 'name': 'Althan,Surat,Gujarat', 'lon': '73.2309'},
      '497447': {'lat': '23.1316', 'name': 'West Jhagrakhand Colly,Koriya,Chattisgarh', 'lon': '82.2859'},
      '612803': {'lat': '10.747', 'name': 'Puvanur,Tiruvarur,Tamil Nadu', 'lon': '79.4155'},
      '176208': {'lat': '32.167', 'name': 'Yol-jharer,Kangra,Himachal Pradesh', 'lon': '76.2123'},
      '176209': {'lat': '32.0342', 'name': 'Gaggal,Kangra,Himachal Pradesh', 'lon': '76.4518'},
      '635301': {'lat': '11.9993', 'name': 'Thippireddihalli,Dharmapuri,Tamil Nadu', 'lon': '78.2678'},
      '176202': {'lat': '32.1569', 'name': 'Sadwan,Kangra,Himachal Pradesh', 'lon': '76.0518'},
      '176203': {'lat': '32.1787', 'name': 'Danni,Kangra,Himachal Pradesh', 'lon': '76.597'},
      '176200': {'lat': '31.6517', 'name': 'Kherian,Kangra,Himachal Pradesh', 'lon': '76.7572'},
      '176201': {'lat': '32.2768', 'name': 'Rit Lower,Kangra,Himachal Pradesh', 'lon': '75.8171'},
      '176206': {'lat': '31.958', 'name': 'Bhanala,Kangra,Himachal Pradesh', 'lon': '76.3144'},
      '176207': {'lat': '32.2217', 'name': 'Khargata,Chamba,Himachal Pradesh', 'lon': '76.4305'},
      '176204': {'lat': '32.2109', 'name': 'Kotla Mangwal,Kangra,Himachal Pradesh', 'lon': '75.8119'},
      '176205': {'lat': '32.2199', 'name': 'Jaunta,Kangra,Himachal Pradesh', 'lon': '75.9986'},
      '470880': {'lat': '23.5127', 'name': 'Dhangaur Kalan,Damoh,Madhya Pradesh', 'lon': '79.4249'},
      '822133': {'lat': '24.1876', 'name': 'Meral,Garhwa,Jharkhand', 'lon': '83.7076'},
      '822131': {'lat': '24.5238', 'name': 'Kurhat Kataiya,Palamau,Jharkhand', 'lon': '84.2516'},
      '331403': {'lat': '28.4548', 'name': 'Bhojasar Upadhiyan,Churu,Rajasthan', 'lon': '74.5012'},
      '331402': {'lat': '28.3383', 'name': 'Sarsar,Churu,Rajasthan', 'lon': '74.6641'},
      '470339': {'lat': '23.8481', 'name': 'Narwa,Sagar,Madhya Pradesh', 'lon': '78.7589'},
      '470337': {'lat': '23.9165', 'name': 'Kerwana,Sagar,Madhya Pradesh', 'lon': '78.8501'},
      '470335': {'lat': '23.8481', 'name': 'Baretha,Sagar,Madhya Pradesh', 'lon': '78.7589'},
      '464986': {'lat': '23.1025', 'name': 'Inthkhedi,Raisen,Madhya Pradesh', 'lon': '77.8976'},
      '360470': {'lat': '21.7936', 'name': 'Mekha Timbi,Rajkot,Gujarat', 'lon': '70.2225'},
      '365620': {'lat': '21.7365', 'name': 'Monpur,Amreli,Gujarat', 'lon': '71.2012'},
      '224117': {'lat': '26.6799', 'name': 'Sandawa,Faizabad,Uttar Pradesh', 'lon': '81.9352'},
      '455116': {'lat': '23.1379', 'name': 'Jiyagarh,Dewas,Madhya Pradesh', 'lon': '76.4063'},
      '574109': {'lat': '13.0779', 'name': 'Sulkeri,Dakshina Kannada,Karnataka', 'lon': '75.1878'},
      '574108': {'lat': '13.3322', 'name': 'Ellare,Udupi,Karnataka', 'lon': '75.0042'},
      '271401': {'lat': '27.0185', 'name': 'Didsia Kala,Gonda,Uttar Pradesh', 'lon': '81.8898'},
      '271402': {'lat': '26.6987', 'name': 'Banau,Gonda,Uttar Pradesh', 'lon': '81.8136'},
      '516193': {'lat': '14.9557', 'name': 'Vaddemanu,Cuddapah,Andhra Pradesh', 'lon': '78.8357'},
      '574103': {'lat': '13.3538', 'name': 'Hejmadikodi,Udupi,Karnataka', 'lon': '75.031'},
      '574102': {'lat': '13.698', 'name': 'Gundyadka,Udupi,Karnataka', 'lon': '75.306'},
      '574101': {'lat': '13.3216', 'name': 'Ajekar,Udupi,Karnataka', 'lon': '75.0001'},
      '574107': {'lat': '13.4922', 'name': 'Nellikar,Dakshina Kannada,Karnataka', 'lon': '74.8959'},
      '574106': {'lat': '13.2222', 'name': 'Kalathur South,Udupi,Karnataka', 'lon': '74.7631'},
      '574105': {'lat': '13.2704', 'name': 'Subhash Nagar,Udupi,Karnataka', 'lon': '74.739'},
      '574104': {'lat': '13.3538', 'name': 'Karkala H.O,Udupi,Karnataka', 'lon': '75.031'},
      '609118': {'lat': '10.6823', 'name': 'Solasakkaranallur,Nagapattinam,Tamil Nadu', 'lon': '79.3661'},
      '226302': {'lat': '26.6191', 'name': 'Utrawan,Lucknow,Uttar Pradesh', 'lon': '81.1127'},
      '226301': {'lat': '26.5305', 'name': 'Jaitikhera,Lucknow,Uttar Pradesh', 'lon': '81.0724'},
      '676525': {'lat': '10.9305', 'name': 'Ezhupathekkar,Malappuram,Kerala', 'lon': '76.4378'},
      '507160': {'lat': '17.3614', 'name': 'Rajeswarapuram,Khammam,Telangana', 'lon': '79.9689'},
      '416505': {'lat': '17.2734', 'name': 'Kasar Kandgaon,Kolhapur,Maharashtra', 'lon': '74.5379'},
      '676522': {'lat': '10.9146', 'name': 'Kolaparamba,Malappuram,Kerala', 'lon': '76.3221'},
      '506135': {'lat': '17.8249', 'name': 'Pogullapalli,Warangal,Telangana', 'lon': '79.9367'},
      '506134': {'lat': '17.7094', 'name': 'Bhupathipet,Warangal,Telangana', 'lon': '79.6971'},
      '506132': {'lat': '18.0407', 'name': 'Muthojipet,Warangal,Telangana', 'lon': '79.9209'},
      '676523': {'lat': '11.1137', 'name': 'Kalkundu,Malappuram,Kerala', 'lon': '76.3265'},
      '788820': {'lat': '25.2285', 'name': 'Topadisa,North Cachar Hills,Assam', 'lon': '92.856'},
      '577125': {'lat': '13.3344', 'name': 'Kammaradi,Chikkamagaluru,Karnataka', 'lon': '75.5476'},
      '600104': {'lat': '13.0974', 'name': 'High Court Building (Chennai),Chennai,Tamil Nadu', 'lon': '80.195'},
      '600107': {'lat': '13.0647', 'name': 'Koyambedu,Chennai,Tamil Nadu', 'lon': '80.2523'},
      '676521': {'lat': '11.0964', 'name': 'Valluvangad South,Malappuram,Kerala', 'lon': '76.2241'},
      '600101': {'lat': '13.0974', 'name': 'Anna Nagar Western Extn,Chennai,Tamil Nadu', 'lon': '80.195'},
      '600100': {'lat': '12.9377', 'name': 'Pallikaranai,Kanchipuram,Tamil Nadu', 'lon': '80.2153'},
      '600103': {'lat': '13.3062', 'name': 'Manali New Town,Tiruvallur,Tamil Nadu', 'lon': '80.1565'},
      '600102': {'lat': '13.0974', 'name': 'Anna Nagar East,Chennai,Tamil Nadu', 'lon': '80.195'},
      '612501': {'lat': '11.703', 'name': 'Nirathanallur,Thanjavur,Tamil Nadu', 'lon': '79.4'},
      '636108': {'lat': '11.4867', 'name': 'Thandavarayapuram,Salem,Tamil Nadu', 'lon': '78.6482'},
      '497442': {'lat': '23.2826', 'name': 'Kachhod,Koriya,Chattisgarh', 'lon': '82.2706'},
      '636109': {'lat': '11.647', 'name': 'Olapadi,Salem,Tamil Nadu', 'lon': '78.3116'},
      '802122': {'lat': '25.3673', 'name': 'Nagpur,Buxar,Bihar', 'lon': '83.8186'},
      '853205': {'lat': '25.3084', 'name': 'Raghunitola,Bhagalpur,Bihar', 'lon': '87.1692'},
      '786621': {'lat': '27.1939', 'name': 'Ghinai,Dibrugarh,Assam', 'lon': '95.3193'},
      '786623': {'lat': '27.1939', 'name': 'Parbatpur,Dibrugarh,Assam', 'lon': '95.3193'},
      '786622': {'lat': '27.1939', 'name': 'Namrup Thermal Power Station,Dibrugarh,Assam', 'lon': '95.3193'},
      '731237': {'lat': '24.2618', 'name': 'Jesthabhabanipur,Birbhum,West Bengal', 'lon': '87.9951'},
      '580030': {'lat': '15.381', 'name': 'Hubli Gandhi Nagar,Dharwad,Karnataka', 'lon': '75.1084'},
      '580031': {'lat': '15.381', 'name': 'Hubli Unkal,Dharwad,Karnataka', 'lon': '75.1084'},
      '580032': {'lat': '15.381', 'name': 'Hubli Vijayanagar,Dharwad,Karnataka', 'lon': '75.1084'},
      '533244': {'lat': '16.5835', 'name': 'Mattaparru,East Godavari,Andhra Pradesh', 'lon': '79.7402'},
      '533247': {'lat': '16.741', 'name': 'Mamidikuduru,East Godavari,Andhra Pradesh', 'lon': '81.0201'},
      '416507': {'lat': '16.0209', 'name': 'Dukkarwadi,Kolhapur,Maharashtra', 'lon': '74.3133'},
      '533241': {'lat': '17.5048', 'name': 'Mukkamala,East Godavari,Andhra Pradesh', 'lon': '81.6638'},
      '533240': {'lat': '16.741', 'name': 'L.Gannavaram,East Godavari,Andhra Pradesh', 'lon': '81.0201'},
      '413205': {'lat': '19.0565', 'name': 'Bavi,Ahmed Nagar,Maharashtra', 'lon': '76.1405'},
      '533242': {'lat': '16.741', 'name': 'Razole H.O,East Godavari,Andhra Pradesh', 'lon': '81.0201'},
      '635204': {'lat': '12.33', 'name': 'Vilangamudi,Krishnagiri,Tamil Nadu', 'lon': '78.314'},
      '491885': {'lat': '21.5053', 'name': 'Kuthelikala,Rajnandgaon,Chattisgarh', 'lon': '81.0669'},
      '491771': {'lat': '20.791', 'name': 'Badgaon,Durg,Chattisgarh', 'lon': '81.1184'},
      '533249': {'lat': '17.1734', 'name': 'Podalada,East Godavari,Andhra Pradesh', 'lon': '81.9669'},
      '533248': {'lat': '16.0054', 'name': 'Vegivaripalem,East Godavari,Andhra Pradesh', 'lon': '80.3915'},
      '756100': {'lat': '20.7818', 'name': 'Bhadrak Court,Bhadrak,Odisha', 'lon': '85.4718'},
      '394630': {'lat': '20.968', 'name': 'Virpor,Tapi,Gujarat', 'lon': '73.3084'},
      '394633': {'lat': '21.1443', 'name': 'Gadat (Tapi),Tapi,Gujarat', 'lon': '73.6063'},
      '394635': {'lat': '21.2324', 'name': 'Dolvan,Tapi,Gujarat', 'lon': '73.7553'},
      '341001': {'lat': '27.0807', 'name': 'Gogelao,Nagaur,Rajasthan', 'lon': '73.8006'},
      '524341': {'lat': '14.4316', 'name': 'Chittalur,Nellore,Andhra Pradesh', 'lon': '79.6097'},
      '623534': {'lat': '9.4845', 'name': 'Mangundu,Ramanathapuram,Tamil Nadu', 'lon': '78.9324'},
      '626161': {'lat': '9.4806', 'name': 'Athipatti,Virudhunagar,Tamil Nadu', 'lon': '77.8738'},
      '247121': {'lat': '30.2414', 'name': 'Samaspur Naugaon,Saharanpur,Uttar Pradesh', 'lon': '77.5921'},
      '385350': {'lat': '23.7645', 'name': 'Par,Patan,Gujarat', 'lon': '71.1681'},
      '814148': {'lat': '24.4607', 'name': 'Patjore,Dumka,Jharkhand', 'lon': '86.6647'},
      '385421': {'lat': '24.0815', 'name': 'Pepol,Banaskantha,Gujarat', 'lon': '72.5344'},
      '249186': {'lat': '30.4574', 'name': 'Srikot,Tehri Garhwal,Uttarakhand', 'lon': '77.8988'},
      '577120': {'lat': '13.0141', 'name': 'Melubellare,Chikkamagaluru,Karnataka', 'lon': '75.6222'},
      '370485': {'lat': '22.8698', 'name': 'Sarli,Kachchh,Gujarat', 'lon': '69.63'},
      '522330': {'lat': '16.3547', 'name': 'Manchikalapudi,Guntur,Andhra Pradesh', 'lon': '80.6196'},
      '322001': {'lat': '26.7301', 'name': 'Adarsh Nagar (Sawai Madhopur),Sawai Madhopur,Rajasthan', 'lon': '76.8551'},
      '786192': {'lat': '27.3011', 'name': 'Hijiguri,Tinsukia,Assam', 'lon': '95.3439'},
      '786191': {'lat': '27.3011', 'name': 'Bokuloni Chariali,Dibrugarh,Assam', 'lon': '95.3439'},
      '786190': {'lat': '27.3011', 'name': 'Hansara,Tinsukia,Assam', 'lon': '95.3439'},
      '711113': {'lat': '22.6368', 'name': 'Balitikuri,Howrah,West Bengal', 'lon': '88.288'},
      '521343': {'lat': '16.5686', 'name': 'Prodduvaka,Krishna,Andhra Pradesh', 'lon': '81.0455'},
      '680503': {'lat': '10.697', 'name': 'Kunnamkulam H.O,Thrissur,Kerala', 'lon': '76.0648'},
      '521340': {'lat': '16.5714', 'name': 'Kottada,Krishna,Andhra Pradesh', 'lon': '81.2846'},
      '680505': {'lat': '10.4712', 'name': 'Kottappadi,Thrissur,Kerala', 'lon': '76.3162'},
      '680504': {'lat': '10.697', 'name': 'Koonamoochi,Thrissur,Kerala', 'lon': '76.0648'},
      '521345': {'lat': '16.5152', 'name': 'Perekegudem,Krishna,Andhra Pradesh', 'lon': '81.151'},
      '521344': {'lat': '16.4948', 'name': 'Mulaupparagudem,Krishna,Andhra Pradesh', 'lon': '81.3295'},
      '680509': {'lat': '10.697', 'name': 'Mullassery,Thrissur,Kerala', 'lon': '76.0648'},
      '680508': {'lat': '10.5571', 'name': 'Puvathur,Thrissur,Kerala', 'lon': '76.1019'},
      '272178': {'lat': '26.7619', 'name': 'Dari Deeha,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.8854'},
      '577121': {'lat': '13.1936', 'name': 'Hirebyle,Chikkamagaluru,Karnataka', 'lon': '75.4469'},
      '576225': {'lat': '13.4628', 'name': 'Achladi,Udupi,Karnataka', 'lon': '74.7382'},
      '333801': {'lat': '27.1834', 'name': 'Saraisurpura,Jhujhunu,Rajasthan', 'lon': '75.6957'},
      '332404': {'lat': '26.2443', 'name': 'Sargoth,Sikar,Rajasthan', 'lon': '75.8831'},
      '332405': {'lat': '27.1618', 'name': 'Palasara,Sikar,Rajasthan', 'lon': '75.7801'},
      '143422': {'lat': '31.3408', 'name': 'Manak Dheke,Tarn Taran,Punjab', 'lon': '75.124'},
      '524347': {'lat': '14.4205', 'name': 'Nelltur,Nellore,Andhra Pradesh', 'lon': '79.7347'},
      '848209': {'lat': '25.6538', 'name': 'Lilhaul,Samastipur,Bihar', 'lon': '85.7958'},
      '332401': {'lat': '27.4114', 'name': 'Churi Miyon,Sikar,Rajasthan', 'lon': '76.0793'},
      '225306': {'lat': '27.2037', 'name': 'Baddoopur,Barabanki,Uttar Pradesh', 'lon': '81.1398'},
      '225304': {'lat': '26.8697', 'name': 'Hetmapur,Barabanki,Uttar Pradesh', 'lon': '81.2179'},
      '576227': {'lat': '13.6874', 'name': 'Machattu,Udupi,Karnataka', 'lon': '74.7822'},
      '225302': {'lat': '27.047', 'name': 'Nindoora,Barabanki,Uttar Pradesh', 'lon': '81.2857'},
      '225303': {'lat': '27.2002', 'name': 'Ram Mandai,Barabanki,Uttar Pradesh', 'lon': '81.2936'},
      '225301': {'lat': '27.0596', 'name': 'Mujeebpur,Barabanki,Uttar Pradesh', 'lon': '81.1367'},
      '110013': {'lat': '28.5878', 'name': 'Dargah Sharif,South Delhi,Delhi', 'lon': '77.2541'},
      '110012': {'lat': '28.6109', 'name': 'National Physical Laboratory,Central Delhi,Delhi', 'lon': '77.1792'},
      '110011': {'lat': '28.6109', 'name': 'Nirman Bhawan,Central Delhi,Delhi', 'lon': '77.1792'},
      '110010': {'lat': '28.7552', 'name': 'Subroto Park,South West Delhi,Delhi', 'lon': '77.1485'},
      '110017': {'lat': '28.5189', 'name': 'Sarvodya Enclave,South Delhi,Delhi', 'lon': '77.2131'},
      '110016': {'lat': '28.5531', 'name': 'Hauz Khas Market,South West Delhi,Delhi', 'lon': '77.1916'},
      '110015': {'lat': '28.6109', 'name': 'Mansarover Garden,West Delhi,Delhi', 'lon': '77.1792'},
      '110014': {'lat': '28.6109', 'name': 'Jungpura,South Delhi,Delhi', 'lon': '77.1792'},
      '110019': {'lat': '28.6109', 'name': 'Chittranjan Park,South Delhi,Delhi', 'lon': '77.1792'},
      '110018': {'lat': '28.6396', 'name': 'Chand Nagar,West Delhi,Delhi', 'lon': '77.0884'},
      '577122': {'lat': '13.424', 'name': 'Kelagur(J),Chikkamagaluru,Karnataka', 'lon': '75.5449'},
      '577129': {'lat': '13.5966', 'name': 'Tanigebyle,Chikkamagaluru,Karnataka', 'lon': '75.8155'},
      '523241': {'lat': '15.5983', 'name': 'Regadipalli,Prakasam,Andhra Pradesh', 'lon': '79.4658'},
      '753010': {'lat': '20.461', 'name': 'Rajendra Nagar (Cuttack),Cuttack,Odisha', 'lon': '85.8695'},
      '700084': {'lat': '22.4607', 'name': 'Garia Garden,South 24 Parganas,West Bengal', 'lon': '88.3867'},
      '757102': {'lat': '21.5831', 'name': 'Pasuda,Mayurbhanj,Odisha', 'lon': '85.6858'},
      '209204': {'lat': '26.5337', 'name': 'Kahijari,Kanpur Dehat,Uttar Pradesh', 'lon': '79.8109'},
      '626205': {'lat': '8.8908', 'name': 'Karuppur,Tuticorin,Tamil Nadu', 'lon': '78.0044'},
      '802203': {'lat': '25.4229', 'name': 'Ayar,Bhojpur,Bihar', 'lon': '84.5353'},
      '753012': {'lat': '20.461', 'name': 'A D Market,Cuttack,Odisha', 'lon': '85.8695'},
      '273010': {'lat': '26.6916', 'name': 'M.M.M.E.College,Gorakhpur,Uttar Pradesh', 'lon': '83.4455'},
      '334303': {'lat': '27.6444', 'name': 'Gariyala,Bikaner,Rajasthan', 'lon': '72.4485'},
      '403108': {'lat': '15.4438', 'name': 'Goa -velha,North Goa,Goa', 'lon': '73.8857'},
      '753013': {'lat': '20.461', 'name': 'Kalyani Nagar,Cuttack,Odisha', 'lon': '85.8695'},
      '581349': {'lat': '14.9762', 'name': 'Neharunagar,Uttara Kannada,Karnataka', 'lon': '74.9938'},
      '581348': {'lat': '13.9936', 'name': 'Gunavante,Uttara Kannada,Karnataka', 'lon': '74.9148'},
      '581345': {'lat': '14.7726', 'name': 'Gaongeri,Uttara Kannada,Karnataka', 'lon': '74.256'},
      '581344': {'lat': '14.5833', 'name': 'Ulware,Uttara Kannada,Karnataka', 'lon': '74.3667'},
      '581347': {'lat': '14.8558', 'name': 'Hittlalli,Uttara Kannada,Karnataka', 'lon': '74.816'},
      '581346': {'lat': '14.7868', 'name': 'Oralgi,Uttara Kannada,Karnataka', 'lon': '74.9987'},
      '581341': {'lat': '14.2967', 'name': 'Pavinkurve,Uttara Kannada,Karnataka', 'lon': '74.4234'},
      '581340': {'lat': '14.5604', 'name': 'Devisara,Uttara Kannada,Karnataka', 'lon': '74.9504'},
      '581343': {'lat': '14.6629', 'name': 'Alvekodi(KMT),Uttara Kannada,Karnataka', 'lon': '74.8387'},
      '581342': {'lat': '14.2475', 'name': 'Kelaginoor,Uttara Kannada,Karnataka', 'lon': '74.4568'},
      '691006': {'lat': '8.8994', 'name': 'Pallithottam,Kollam,Kerala', 'lon': '76.5863'},
      '691007': {'lat': '8.8994', 'name': 'Thangasserry,Kollam,Kerala', 'lon': '76.5863'},
      '691004': {'lat': '8.8994', 'name': 'Kallumthazham,Kollam,Kerala', 'lon': '76.5863'},
      '691005': {'lat': '8.8994', 'name': 'T K M College,Kollam,Kerala', 'lon': '76.5863'},
      '691002': {'lat': '8.8994', 'name': 'Asramom,Kollam,Kerala', 'lon': '76.5863'},
      '691003': {'lat': '8.9191', 'name': 'Maruthadi,Kollam,Kerala', 'lon': '76.56'},
      '691001': {'lat': '8.8862', 'name': 'Kollam Bazar,Kollam,Kerala', 'lon': '76.5956'},
      '626202': {'lat': '9.2868', 'name': 'Masarpatti,Tuticorin,Tamil Nadu', 'lon': '78.0365'},
      '829133': {'lat': '23.6861', 'name': 'Souda Basti,Ramgarh,Jharkhand', 'lon': '85.5704'},
      '691008': {'lat': '8.893', 'name': 'Kadappakada,Kollam,Kerala', 'lon': '76.6032'},
      '691009': {'lat': '8.8994', 'name': 'Thevally,Kollam,Kerala', 'lon': '76.5863'},
      '642125': {'lat': '10.7456', 'name': 'Murugali Bazaar,Coimbatore,Tamil Nadu', 'lon': '77.1038'},
      '304001': {'lat': '26.2229', 'name': 'Dardahind,Tonk,Rajasthan', 'lon': '75.6503'},
      '642126': {'lat': '10.7456', 'name': 'Udamalpet East,Coimbatore,Tamil Nadu', 'lon': '77.1038'},
      '334305': {'lat': '27.8709', 'name': 'Phulasar Khurd,Bikaner,Rajasthan', 'lon': '72.4132'},
      '642123': {'lat': '10.9908', 'name': 'Zamin Kottampatti,Coimbatore,Tamil Nadu', 'lon': '76.8418'},
      '642122': {'lat': '10.5915', 'name': 'Kodingium,Coimbatore,Tamil Nadu', 'lon': '77.1972'},
      '501102': {'lat': '17.0587', 'name': 'Pudur,K.V.Rangareddy,Telangana', 'lon': '78.4228'},
      '501101': {'lat': '17.7942', 'name': 'Vikarabad H.O,Hyderabad,Telangana', 'lon': '78.468'},
      '642129': {'lat': '10.7456', 'name': 'Odayakulam,Coimbatore,Tamil Nadu', 'lon': '77.1038'},
      '642128': {'lat': '10.7456', 'name': 'Venkatesa Mills,Coimbatore,Tamil Nadu', 'lon': '77.1038'},
      '609110': {'lat': '11.2072', 'name': 'Sirkali H.O,Nagapattinam,Tamil Nadu', 'lon': '79.6065'},
      '262001': {'lat': '28.2485', 'name': 'Bithora Kalan,Pilibhit,Uttar Pradesh', 'lon': '80.1312'},
      '626203': {'lat': '9.5832', 'name': 'Padanthal,Virudhunagar,Tamil Nadu', 'lon': '78.0026'},
      '712152': {'lat': '24.1703', 'name': 'Melki,Hooghly,West Bengal', 'lon': '87.7433'},
      '500084': {'lat': '17.4656', 'name': 'Kondapur,K.V.Rangareddy,Telangana', 'lon': '78.3419'},
      '441702': {'lat': '20.7911', 'name': 'Pimpalgaon Khambi,Gondia,Maharashtra', 'lon': '80.0904'},
      '135021': {'lat': '30.274', 'name': 'Khirzrabad  East,Yamuna Nagar,Haryana', 'lon': '77.4561'},
      '441701': {'lat': '20.8373', 'name': 'Etikheda,Gondia,Maharashtra', 'lon': '80.0057'},
      '608901': {'lat': '11.3715', 'name': 'Alagapuram,Ariyalur,Tamil Nadu', 'lon': '79.3332'},
      '205121': {'lat': '27.1889', 'name': 'Musayatpur Kheriya,Mainpuri,Uttar Pradesh', 'lon': '78.7564'},
      '226010': {'lat': '26.8288', 'name': 'Vishal Khand,Lucknow,Uttar Pradesh', 'lon': '81.0147'},
      '629102': {'lat': '8.4393', 'name': 'Kodayarkilthangal,Kanyakumari,Tamil Nadu', 'lon': '77.3313'},
      '638060': {'lat': '11.3062', 'name': 'Thoppupalayam,Erode,Tamil Nadu', 'lon': '77.7207'},
      '758047': {'lat': '20.9433', 'name': 'Naradapur,Kendujhar,Odisha', 'lon': '85.1884'},
      '846002': {'lat': '25.7001', 'name': 'Thalwara,Darbhanga,Bihar', 'lon': '85.7142'},
      '629810': {'lat': '8.2068', 'name': 'Kandanvilai,Kanyakumari,Tamil Nadu', 'lon': '77.3281'},
      '451442': {'lat': '21.833144', 'name': 'Segaon (West Nimar),West Nimar,Madhya Pradesh', 'lon': '75.403056'},
      '802302': {'lat': '25.6261', 'name': 'Anaith,Bhojpur,Bihar', 'lon': '84.1362'},
      '802301': {'lat': '25.6261', 'name': 'Shahbad Distt.Board Ara,Bhojpur,Bihar', 'lon': '84.1362'},
      '274409': {'lat': '26.6158', 'name': 'Vedupar,Kushinagar,Uttar Pradesh', 'lon': '84.259'},
      '364522': {'lat': '21.447', 'name': 'Gadhakda,Amreli,Gujarat', 'lon': '71.7423'},
      '515501': {'lat': '14.2668', 'name': 'Chinnarayanipalli,Ananthapur,Andhra Pradesh', 'lon': '78.2141'},
      '571603': {'lat': '12.5621', 'name': 'Gandanahalli,Mysuru,Karnataka', 'lon': '76.4102'},
      '571602': {'lat': '12.5628', 'name': 'Hosur Kallahalli,Mysuru,Karnataka', 'lon': '76.4371'},
      '571601': {'lat': '12.551', 'name': 'Bheriya,Mysuru,Karnataka', 'lon': '76.3735'},
      '722138': {'lat': '22.6214', 'name': 'Jujur,Bankura,West Bengal', 'lon': '87.913'},
      '571607': {'lat': '12.6297', 'name': 'Krishnaraja Sagar,Mandya,Karnataka', 'lon': '76.4373'},
      '571606': {'lat': '12.616', 'name': 'Hosahalli,Mandya,Karnataka', 'lon': '76.6304'},
      '571605': {'lat': '12.8405', 'name': 'Alambavadikaval,Mandya,Karnataka', 'lon': '76.6938'},
      '571604': {'lat': '12.5602', 'name': 'Saligrama (Mysuru),Mysuru,Karnataka', 'lon': '76.2611'},
      '722133': {'lat': '23.227', 'name': 'Laxmanpur,Bankura,West Bengal', 'lon': '87.7217'},
      '722132': {'lat': '22.9876', 'name': 'Mankanali,Bankura,West Bengal', 'lon': '87.4276'},
      '722137': {'lat': '24.1794', 'name': 'Jorehira,Bankura,West Bengal', 'lon': '87.8621'},
      '722136': {'lat': '24.0388', 'name': 'Hatagram,Bankura,West Bengal', 'lon': '87.5766'},
      '722135': {'lat': '23.3801', 'name': 'Hijlitilabani,Bankura,West Bengal', 'lon': '87.1009'},
      '722134': {'lat': '22.3101', 'name': 'Shyamsundarpur,Bankura,West Bengal', 'lon': '87.2896'},
      '673604': {'lat': '11.2244', 'name': 'Kutaranni,Kozhikode,Kerala', 'lon': '75.9826'},
      '306023': {'lat': '25.6695', 'name': 'Chirpatiya,Pali,Rajasthan', 'lon': '73.7568'},
      '131304': {'lat': '29.209', 'name': 'Dhanana Ahladpur,Sonipat,Haryana', 'lon': '76.4552'},
      '678651': {'lat': '10.8107', 'name': 'Anakkal,Palakkad,Kerala', 'lon': '76.6634'},
      '131302': {'lat': '29.189', 'name': 'Butana,Sonipat,Haryana', 'lon': '76.6256'},
      '673601': {'lat': '11.3131', 'name': 'Nit  Campus Po.Kozhikode,Kozhikode,Kerala', 'lon': '75.9421'},
      '673602': {'lat': '11.1215', 'name': 'Nellika Paramba,Kozhikode,Kerala', 'lon': '76.0029'},
      '131301': {'lat': '29.091', 'name': 'Bali Brahman,Sonipat,Haryana', 'lon': '76.7386'},
      '206126': {'lat': '26.792', 'name': 'Dadora,Etawah,Uttar Pradesh', 'lon': '79.0837'},
      '609115': {'lat': '11.2691', 'name': 'Keeranallur,Nagapattinam,Tamil Nadu', 'lon': '79.8008'},
      '801505': {'lat': '25.5051', 'name': 'Phulwarisharif,Patna,Bihar', 'lon': '85.2021'},
      '306021': {'lat': '25.5841', 'name': 'Guda Ramsingh,Pali,Rajasthan', 'lon': '73.6416'},
      '209203': {'lat': '26.3805', 'name': 'Chaubepur,Kanpur Nagar,Uttar Pradesh', 'lon': '80.2158'},
      '124102': {'lat': '28.6679', 'name': 'Chamanpura,Jhajjar,Haryana', 'lon': '76.5496'},
      '124103': {'lat': '28.6344', 'name': 'Yakubpur,Jhajjar,Haryana', 'lon': '76.688'},
      '124106': {'lat': '28.5606', 'name': 'Matanhail,Jhajjar,Haryana', 'lon': '76.4471'},
      '124107': {'lat': '28.752', 'name': 'Chhochi,Jhajjar,Haryana', 'lon': '76.635'},
      '124104': {'lat': '28.6197', 'name': 'Nehru College Jhajjar,Jhajjar,Haryana', 'lon': '76.737'},
      '124105': {'lat': '28.5542', 'name': 'Bhupnia,Jhajjar,Haryana', 'lon': '76.8032'},
      '389210': {'lat': '23.1003', 'name': 'Padardi,Panch Mahals,Gujarat', 'lon': '73.294'},
      '124108': {'lat': '29.0372', 'name': 'Pataudah,Jhajjar,Haryana', 'lon': '76.7097'},
      '124109': {'lat': '28.6311', 'name': 'Niwada,Jhajjar,Haryana', 'lon': '76.5723'},
      '303506': {'lat': '26.7575', 'name': 'Thumdi,Dausa,Rajasthan', 'lon': '76.4428'},
      '577128': {'lat': '13.7005', 'name': 'Lakkavalli,Chikkamagaluru,Karnataka', 'lon': '75.6547'},
      '802205': {'lat': '25.2937', 'name': 'Sonbarsa,Bhojpur,Bihar', 'lon': '84.4808'},
      '506302': {'lat': '17.705', 'name': 'Kadavendi,Warangal,Telangana', 'lon': '79.6333'},
      '609114': {'lat': '11.1754', 'name': 'Tiruvengadu,Nagapattinam,Tamil Nadu', 'lon': '79.8095'},
      '835226': {'lat': '22.4353', 'name': 'Karramunda,Simdega,Jharkhand', 'lon': '84.4642'},
      '506303': {'lat': '17.6142', 'name': 'Cheetur,Warangal,Telangana', 'lon': '79.1006'},
      '676541': {'lat': '11.2153', 'name': 'Eranhikode,Malappuram,Kerala', 'lon': '76.1418'},
      '676542': {'lat': '10.2913', 'name': 'Pullippadam,Malappuram,Kerala', 'lon': '76.348'},
      '176071': {'lat': '31.8131', 'name': 'Simbal,Kangra,Himachal Pradesh', 'lon': '76.897'},
      '176073': {'lat': '31.9051', 'name': 'Paplah,Kangra,Himachal Pradesh', 'lon': '76.4947'},
      '500088': {'lat': '17.2974', 'name': 'Venkatadri,K.V.Rangareddy,Telangana', 'lon': '78.3132'},
      '176075': {'lat': '32.1088', 'name': 'Duhak,Kangra,Himachal Pradesh', 'lon': '76.3177'},
      '176077': {'lat': '32.0478', 'name': 'Gunehar,Kangra,Himachal Pradesh', 'lon': '76.7256'},
      '176076': {'lat': '31.4475', 'name': 'Kachhera,Kangra,Himachal Pradesh', 'lon': '76.8609'},
      '305206': {'lat': '26.3453', 'name': 'Nooriawas,Ajmer,Rajasthan', 'lon': '74.5579'},
      '305207': {'lat': '26.2847', 'name': 'Jethana,Ajmer,Rajasthan', 'lon': '74.4715'},
      '305204': {'lat': '26.2856', 'name': 'Karnos,Ajmer,Rajasthan', 'lon': '74.2865'},
      '305205': {'lat': '26.3017', 'name': 'Kotaj,Ajmer,Rajasthan', 'lon': '74.6296'},
      '305202': {'lat': '25.7544', 'name': 'Piplaj,Ajmer,Rajasthan', 'lon': '75.0912'},
      '305203': {'lat': '26.2986', 'name': 'Arjunpura,Ajmer,Rajasthan', 'lon': '74.5374'},
      '305201': {'lat': '26.2866', 'name': 'Leswa,Ajmer,Rajasthan', 'lon': '74.5031'},
      '141114': {'lat': '30.808', 'name': 'Ghungrali Sikhan,Ludhiana,Punjab', 'lon': '76.1567'},
      '754032': {'lat': '20.481', 'name': 'Bandhahuda,Cuttack,Odisha', 'lon': '85.0898'},
      '754031': {'lat': '20.4184', 'name': 'Bangarisinga,Cuttack,Odisha', 'lon': '85.0912'},
      '754030': {'lat': '20.6286', 'name': 'Baunsput,Cuttack,Odisha', 'lon': '84.9714'},
      '754037': {'lat': '20.4171', 'name': 'Ekdal,Cuttack,Odisha', 'lon': '85.1796'},
      '383010': {'lat': '23.4512', 'name': 'Kaniol,Sabarkantha,Gujarat', 'lon': '73.2461'},
      '690519': {'lat': '9.0363', 'name': 'Mynagappally North,Kollam,Kerala', 'lon': '76.5786'},
      '690518': {'lat': '9.4277', 'name': 'Karunagappaly H.O,Kollam,Kerala', 'lon': '76.4746'},
      '690517': {'lat': '9.3186', 'name': 'Pandy,Alappuzha,Kerala', 'lon': '76.4368'},
      '690516': {'lat': '9.2568', 'name': 'Mahadevikadu,Alappuzha,Kerala', 'lon': '76.4487'},
      '690515': {'lat': '10.6098', 'name': 'Pallana,Alappuzha,Kerala', 'lon': '76.4951'},
      '690514': {'lat': '9.3013', 'name': 'Mannarasala,Alappuzha,Kerala', 'lon': '76.4574'},
      '532291': {'lat': '18.7493', 'name': 'Ms Palli,Srikakulam,Andhra Pradesh', 'lon': '83.9151'},
      '532290': {'lat': '18.8941', 'name': 'Peddakojjiria,Srikakulam,Andhra Pradesh', 'lon': '84.0171'},
      '690511': {'lat': '9.4277', 'name': 'Muttom-alleppey,Alappuzha,Kerala', 'lon': '76.4746'},
      '532292': {'lat': '18.4598', 'name': 'Borivanka,Srikakulam,Andhra Pradesh', 'lon': '83.7112'},
      '751009': {'lat': '20.6966', 'name': 'Ashok Nagar (Khorda),Khorda,Odisha', 'lon': '84.974'},
      '246745': {'lat': '29.1143', 'name': 'Maqsoodpur,Bijnor,Uttar Pradesh', 'lon': '78.5912'},
      '274001': {'lat': '27.1255', 'name': 'Deoria Sadar R.S.,Deoria,Uttar Pradesh', 'lon': '77.7598'},
      '488222': {'lat': '24.4205', 'name': 'Kiratpur,Panna,Madhya Pradesh', 'lon': '80.1917'},
      '488220': {'lat': '24.8173', 'name': 'Madhvaganj,Panna,Madhya Pradesh', 'lon': '80.0938'},
      '609116': {'lat': '11.2072', 'name': 'Elathur,Nagapattinam,Tamil Nadu', 'lon': '79.6065'},
      '362640': {'lat': '21.5846', 'name': 'Zinjri,Junagadh,Gujarat', 'lon': '70.1405'},
      '735234': {'lat': '26.725', 'name': 'Gajaldoba,Jalpaiguri,West Bengal', 'lon': '88.7436'},
      '391105': {'lat': '21.4429', 'name': 'Bithli,Vadodara,Gujarat', 'lon': '73.3674'},
      '391107': {'lat': '21.6609', 'name': 'Meghakui,Vadodara,Gujarat', 'lon': '73.3115'},
      '735230': {'lat': '26.725', 'name': 'Haihaipathar,Jalpaiguri,West Bengal', 'lon': '88.7436'},
      '391101': {'lat': '22.315', 'name': 'Ampad,Vadodara,Gujarat', 'lon': '73.1438'},
      '391450': {'lat': '22.9652', 'name': 'Majatan,Vadodara,Gujarat', 'lon': '73.0469'},
      '735233': {'lat': '26.725', 'name': 'Nathuahat,Jalpaiguri,West Bengal', 'lon': '88.7436'},
      '854106': {'lat': '25.5639', 'name': 'Sandalpur,Katihar,Bihar', 'lon': '87.5082'},
      '854107': {'lat': '25.5265', 'name': 'Budhnagar,Katihar,Bihar', 'lon': '87.6302'},
      '854104': {'lat': '25.5165', 'name': 'Maraghia,Katihar,Bihar', 'lon': '87.3291'},
      '854105': {'lat': '25.5259', 'name': 'Katihar Bara Bazar,Katihar,Bihar', 'lon': '87.5041'},
      '854102': {'lat': '25.9196', 'name': 'Mohania Chakla,Purnia,Bihar', 'lon': '87.1081'},
      '854103': {'lat': '25.4758', 'name': 'Tingahhia,Katihar,Bihar', 'lon': '87.6162'},
      '854101': {'lat': '25.6199', 'name': 'Ayodhyaganj Bazar,Katihar,Bihar', 'lon': '87.181'},
      '487441': {'lat': '22.9666', 'name': 'Khulri,Narsinghpur,Madhya Pradesh', 'lon': '78.946'},
      '506324': {'lat': '17.5341', 'name': 'Vanthadapala,Warangal,Telangana', 'lon': '79.7727'},
      '281006': {'lat': '27.4427', 'name': 'Aurangabad,Mathura,Uttar Pradesh', 'lon': '77.7055'},
      '281005': {'lat': '27.3822', 'name': 'Mathura Refinery,Mathura,Uttar Pradesh', 'lon': '77.6606'},
      '281004': {'lat': '27.23', 'name': 'Krishna Nagar (Mathura),Mathura,Uttar Pradesh', 'lon': '78.0889'},
      '281003': {'lat': '27.561', 'name': 'Gayatri Tapo Bhoomi,Mathura,Uttar Pradesh', 'lon': '77.6256'},
      '281001': {'lat': '27.4898', 'name': 'Chhata Bazar (Mathura),Mathura,Uttar Pradesh', 'lon': '77.8117'},
      '846008': {'lat': '25.9469', 'name': 'K D S U,Darbhanga,Bihar', 'lon': '85.9862'},
      '743439': {'lat': '23.4323', 'name': 'South Charalkhali,North 24 Parganas,West Bengal', 'lon': '88.373'},
      '743438': {'lat': '22.8151', 'name': 'Kolsur,North 24 Parganas,West Bengal', 'lon': '88.4296'},
      '534448': {'lat': '17.0849', 'name': 'Regulakunta,West Godavari,Andhra Pradesh', 'lon': '81.3791'},
      '534449': {'lat': '17.0288', 'name': 'Uppalapadu,West Godavari,Andhra Pradesh', 'lon': '81.2156'},
      '534447': {'lat': '16.965', 'name': 'Jangareddigudem Bazar,West Godavari,Andhra Pradesh', 'lon': '81.305'},
      '534444': {'lat': '16.8663', 'name': 'Sridhar Velair,West Godavari,Andhra Pradesh', 'lon': '81.3395'},
      '450331': {'lat': '21.4295', 'name': 'Dargah-e-hakimi,Burhanpur,Madhya Pradesh', 'lon': '76.273'},
      '534442': {'lat': '16.7392', 'name': 'Dosapadu,West Godavari,Andhra Pradesh', 'lon': '81.2013'},
      '743435': {'lat': '22.795', 'name': 'Bishpur,North 24 Parganas,West Bengal', 'lon': '88.1206'},
      '518010': {'lat': '15.6507', 'name': 'N.Konthalapadu,Kurnool,Andhra Pradesh', 'lon': '78.2521'},
      '628701': {'lat': '8.6629', 'name': 'Chettikulam,Tuticorin,Tamil Nadu', 'lon': '78.0223'},
      '628703': {'lat': '8.4546', 'name': 'Periyathalai,Tuticorin,Tamil Nadu', 'lon': '77.9993'},
      '342301': {'lat': '27.2368', 'name': 'Tepu,Jodhpur,Rajasthan', 'lon': '72.2557'},
      '627502': {'lat': '8.5654', 'name': 'Kallikulam,Tirunelveli,Tamil Nadu', 'lon': '77.5882'},
      '628704': {'lat': '8.6882', 'name': 'Pannamparai,Tuticorin,Tamil Nadu', 'lon': '77.9057'},
      '387710': {'lat': '22.893', 'name': 'Karoli,Kheda,Gujarat', 'lon': '72.9752'},
      '627501': {'lat': '8.5142', 'name': 'Kilakku Kalakad,Tirunelveli,Tamil Nadu', 'lon': '77.5601'},
      '721452': {'lat': '22.871', 'name': 'Bhajachauli,East Midnapore,West Bengal', 'lon': '88.0316'},
      '271872': {'lat': '27.4381', 'name': 'Jiganiamahipal,Bahraich,Uttar Pradesh', 'lon': '81.6442'},
      '606206': {'lat': '11.9734', 'name': 'Peelamedu,Villupuram,Tamil Nadu', 'lon': '79.2921'},
      '846009': {'lat': '26.1074', 'name': 'Laxmisagar (Darbhanga),Darbhanga,Bihar', 'lon': '86.0165'},
      '637215': {'lat': '11.3305', 'name': 'Kuchi Palayam,Namakkal,Tamil Nadu', 'lon': '77.9535'},
      '606207': {'lat': '11.8022', 'name': 'Chellampattu,Villupuram,Tamil Nadu', 'lon': '78.8409'},
      '637213': {'lat': '11.4902', 'name': 'Kurumbalamahadevi,Namakkal,Tamil Nadu', 'lon': '77.1269'},
      '342304': {'lat': '26.7101', 'name': 'Mandore,Jodhpur,Rajasthan', 'lon': '73.478'},
      '637211': {'lat': '11.3305', 'name': 'Tiruchengodu South,Namakkal,Tamil Nadu', 'lon': '77.9535'},
      '637210': {'lat': '11.3972', 'name': 'Solasiramani,Namakkal,Tamil Nadu', 'lon': '77.6495'},
      '364140': {'lat': '21.6451', 'name': 'Kamlol Nani,Bhavnagar,Gujarat', 'lon': '73.057'},
      '364490': {'lat': '21.848', 'name': 'Zundala,Rajkot,Gujarat', 'lon': '70.9858'},
      '713324': {'lat': '24.4891', 'name': 'Begunia Bazar,Paschim Bardhaman,West Bengal', 'lon': '87.8306'},
      '364145': {'lat': '21.6451', 'name': 'Karmadiya,Bhavnagar,Gujarat', 'lon': '73.057'},
      '606201': {'lat': '11.5343', 'name': 'Dagamtheerthapuram,Villupuram,Tamil Nadu', 'lon': '78.7851'},
      '800028': {'lat': '25.5904', 'name': 'Patna High Court,Patna,Bihar', 'lon': '85.1624'},
      '762024': {'lat': '20.7146', 'name': 'Biranarasinghpur,Boudh,Odisha', 'lon': '84.3277'},
      '440020': {'lat': '21.0903', 'name': 'Neeri,Nagpur,Maharashtra', 'lon': '78.9228'},
      '440021': {'lat': '21.0903', 'name': 'A.D. Project,Nagpur,Maharashtra', 'lon': '78.9228'},
      '440022': {'lat': '21.0903', 'name': 'Shradhananpeth,Nagpur,Maharashtra', 'lon': '78.9228'},
      '440023': {'lat': '21.0859', 'name': 'Vyahad Bzk,Nagpur,Maharashtra', 'lon': '78.8883'},
      '440024': {'lat': '21.0903', 'name': 'Manewada Road,Nagpur,Maharashtra', 'lon': '78.9228'},
      '440025': {'lat': '21.108', 'name': 'Khamla,Nagpur,Maharashtra', 'lon': '79.0611'},
      '440026': {'lat': '21.0903', 'name': 'Uppalwadi,Nagpur,Maharashtra', 'lon': '78.9228'},
      '524101': {'lat': '14.1397', 'name': 'Gudur East,Nellore,Andhra Pradesh', 'lon': '79.7697'},
      '534198': {'lat': '16.6767', 'name': 'Bhuvanapalli,West Godavari,Andhra Pradesh', 'lon': '81.449'},
      '534199': {'lat': '16.618', 'name': 'Kalisipudi,West Godavari,Andhra Pradesh', 'lon': '81.4738'},
      '587121': {'lat': '16.2685', 'name': 'Gani,Bagalkot,Karnataka', 'lon': '75.6791'},
      '587120': {'lat': '15.7957', 'name': 'Chikmagi,Bagalkot,Karnataka', 'lon': '75.9927'},
      '587125': {'lat': '16.0326', 'name': 'Amarawadgi,Bagalkot,Karnataka', 'lon': '76.2099'},
      '587124': {'lat': '15.9774', 'name': 'Kelur,Bagalkot,Karnataka', 'lon': '75.9103'},
      '762021': {'lat': '19.7979', 'name': 'Sangadama,Kandhamal,Odisha', 'lon': '83.7062'},
      '816129': {'lat': '24.7133', 'name': 'Mangalhat English,Sahibganj,Jharkhand', 'lon': '87.0506'},
      '534195': {'lat': '16.7216', 'name': 'D.Gopavaram,West Godavari,Andhra Pradesh', 'lon': '81.4294'},
      '534196': {'lat': '16.7497', 'name': 'Chintapalli Agraharam,West Godavari,Andhra Pradesh', 'lon': '81.533'},
      '534197': {'lat': '16.532', 'name': 'A.Gopavaram,West Godavari,Andhra Pradesh', 'lon': '81.1664'},
      '363115': {'lat': '22.4087', 'name': 'Ingrodi,Surendra Nagar,Gujarat', 'lon': '72.1895'},
      '364720': {'lat': '22.2416', 'name': 'Kumbhara,Bhavnagar,Gujarat', 'lon': '71.5196'},
      '363110': {'lat': '22.8734', 'name': 'Bajarangpura,Surendra Nagar,Gujarat', 'lon': '71.7076'},
      '762022': {'lat': '20.1966', 'name': 'Kurmingia,Kandhamal,Odisha', 'lon': '84.3466'},
      '626003': {'lat': '10.9844', 'name': 'Meesalur,Virudhunagar,Tamil Nadu', 'lon': '78.4673'},
      '500020': {'lat': '17.4057', 'name': 'Golconda Chowrastha,Hyderabad,Telangana', 'lon': '78.4944'},
      '251327': {'lat': '29.3314', 'name': 'Badiwala,Muzaffarnagar,Uttar Pradesh', 'lon': '77.6651'},
      '222303': {'lat': '26.0482', 'name': 'Tamarsepur,Sultanpur,Uttar Pradesh', 'lon': '82.3386'},
      '222302': {'lat': '26.2749', 'name': 'Barua,Sultanpur,Uttar Pradesh', 'lon': '82.0201'},
      '222301': {'lat': '26.2749', 'name': 'Koiripur,Sultanpur,Uttar Pradesh', 'lon': '82.0201'},
      '768048': {'lat': '21.122', 'name': 'Jokhipali,Bargarh,Odisha', 'lon': '83.3726'},
      '421204': {'lat': '19.3173', 'name': 'Padle,Thane,Maharashtra', 'lon': '73.0597'},
      '421201': {'lat': '19.2167', 'name': 'Tilaknagar (Thane),Thane,Maharashtra', 'lon': '73.0833'},
      '421203': {'lat': '19.267', 'name': 'Dombivali I.A.,Thane,Maharashtra', 'lon': '73.0715'},
      '421202': {'lat': '19.267', 'name': 'Vishnunagar,Thane,Maharashtra', 'lon': '73.0715'},
      '691583': {'lat': '8.9673', 'name': 'Chavara,Kollam,Kerala', 'lon': '76.5419'},
      '691582': {'lat': '8.9944', 'name': 'Neendakara,Kollam,Kerala', 'lon': '76.5274'},
      '691581': {'lat': '8.9944', 'name': 'Sakthikulangara,Kollam,Kerala', 'lon': '76.5274'},
      '751007': {'lat': '20.6966', 'name': 'Satyanagar (Khorda),Khorda,Odisha', 'lon': '84.974'},
      '691585': {'lat': '8.9944', 'name': 'Mukundapuram,Kollam,Kerala', 'lon': '76.5274'},
      '691584': {'lat': '8.9944', 'name': 'Chavara South,Kollam,Kerala', 'lon': '76.5274'},
      '691589': {'lat': '8.9944', 'name': 'Umayanallur I E,Kollam,Kerala', 'lon': '76.5274'},
      '638661': {'lat': '10.8722', 'name': 'Vellavavipudur,Erode,Tamil Nadu', 'lon': '77.7428'},
      '638660': {'lat': '10.9377', 'name': 'Avanashipalyaam,Erode,Tamil Nadu', 'lon': '77.4642'},
      '415404': {'lat': '17.1267', 'name': 'Tambave,Sangli,Maharashtra', 'lon': '74.1864'},
      '415405': {'lat': '17.112', 'name': 'Chincholi,Sangli,Maharashtra', 'lon': '74.7699'},
      '415406': {'lat': '17.0906', 'name': 'Nerla,Sangli,Maharashtra', 'lon': '74.2246'},
      '388480': {'lat': '22.8406', 'name': 'Rupiapura,Anand,Gujarat', 'lon': '72.9044'},
      '415401': {'lat': '15.91', 'name': 'Aitawade BK,Sangli,Maharashtra', 'lon': '74.3432'},
      '415402': {'lat': '17.112', 'name': 'Mangrul,Sangli,Maharashtra', 'lon': '74.7699'},
      '415403': {'lat': '17.0078', 'name': 'Yedenipani,Sangli,Maharashtra', 'lon': '74.2623'},
      '464672': {'lat': '23.1781', 'name': 'Semrikhurd,Raisen,Madhya Pradesh', 'lon': '78.2337'},
      '415408': {'lat': '18.0913', 'name': 'Natoli,Sangli,Maharashtra', 'lon': '74.6717'},
      '415409': {'lat': '17.05', 'name': 'Narsinhpur,Sangli,Maharashtra', 'lon': '74.2667'},
      '382620': {'lat': '23.3146', 'name': 'Randheja,Gandhi Nagar,Gujarat', 'lon': '72.9431'},
      '721456': {'lat': '21.9837', 'name': 'Bamanbarh,East Midnapore,West Bengal', 'lon': '87.635'},
      '803221': {'lat': '25.4623', 'name': 'Pandarak,Patna,Bihar', 'lon': '85.8401'},
      '815354': {'lat': '24.1674', 'name': 'Kelahi,Jamtara,Jharkhand', 'lon': '86.6117'},
      '500026': {'lat': '17.4113', 'name': 'Nehrunagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.4935'},
      '641670': {'lat': '11.1184', 'name': 'Vettuvapalayam,Coimbatore,Tamil Nadu', 'lon': '77.1429'},
      '604410': {'lat': '12.7613', 'name': 'Tiruppanangadu,Tiruvannamalai,Tamil Nadu', 'lon': '79.6051'},
      '621220': {'lat': '11.2408', 'name': 'Nochiyam,Perambalur,Tamil Nadu', 'lon': '78.8635'},
      '641671': {'lat': '11.1702', 'name': 'Jallipatti,Coimbatore,Tamil Nadu', 'lon': '77.3496'},
      '464671': {'lat': '22.9697', 'name': 'Bankhedi,Raisen,Madhya Pradesh', 'lon': '78.312'},
      '271875': {'lat': '27.6772', 'name': 'Hardutt Nagar,Shrawasti,Uttar Pradesh', 'lon': '81.2481'},
      '248001': {'lat': '30.3213', 'name': 'Sayedwala,Dehradun,Uttarakhand', 'lon': '78.04'},
      '250617': {'lat': '25.3881', 'name': 'Halalpur,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '450337': {'lat': '22.4434', 'name': 'Aabud,East Nimar,Madhya Pradesh', 'lon': '76.5197'},
      '835224': {'lat': '23.1722', 'name': 'Sakrauli,Gumla,Jharkhand', 'lon': '84.776'},
      '560009': {'lat': '12.991', 'name': 'Bangalore Dist Offices Bldg,Bengaluru,Karnataka', 'lon': '77.5843'},
      '560008': {'lat': '12.991', 'name': 'H.A.L II Stage H.O,Bengaluru,Karnataka', 'lon': '77.5843'},
      '560007': {'lat': '12.991', 'name': 'Agram,Bengaluru,Karnataka', 'lon': '77.5843'},
      '560006': {'lat': '12.991', 'name': 'J.C.Nagar,Bengaluru,Karnataka', 'lon': '77.5843'},
      '560005': {'lat': '12.991', 'name': 'Fraser Town,Bengaluru,Karnataka', 'lon': '77.5843'},
      '560004': {'lat': '12.9833', 'name': 'Mavalli,Bengaluru,Karnataka', 'lon': '77.5833'},
      '560003': {'lat': '13.0097', 'name': 'Swimming Pool Extn,Bengaluru,Karnataka', 'lon': '77.5648'},
      '560002': {'lat': '12.9719', 'name': 'Bangalore Corporation Building,Bengaluru,Karnataka', 'lon': '77.5937'},
      '560001': {'lat': '12.9914', 'name': 'HighCourt,Bengaluru,Karnataka', 'lon': '77.5944'},
      '770039': {'lat': '21.5777', 'name': 'Gobira,Sundergarh,Odisha', 'lon': '85.1005'},
      '770038': {'lat': '21.7961', 'name': 'Talita,Sundergarh,Odisha', 'lon': '84.9625'},
      '523214': {'lat': '15.8212', 'name': 'J.Pangulur,Prakasam,Andhra Pradesh', 'lon': '79.9261'},
      '523213': {'lat': '15.7116', 'name': 'T.Kopperapadu,Prakasam,Andhra Pradesh', 'lon': '80.1121'},
      '523212': {'lat': '15.8535', 'name': 'Pitchikalagudipadu,Prakasam,Andhra Pradesh', 'lon': '80.0525'},
      '523211': {'lat': '15.6113', 'name': 'Basavannapalem,Prakasam,Andhra Pradesh', 'lon': '80.0229'},
      '770031': {'lat': '21.5377', 'name': 'Kalunga,Sundergarh,Odisha', 'lon': '83.5857'},
      '770033': {'lat': '22.051', 'name': 'Jhamankera,Sundergarh,Odisha', 'lon': '84.6608'},
      '770032': {'lat': '21.9012', 'name': 'Bandomunda,Sundergarh,Odisha', 'lon': '84.7169'},
      '770035': {'lat': '21.6646', 'name': 'Tainda,Sundergarh,Odisha', 'lon': '84.607'},
      '770034': {'lat': '22.2164', 'name': 'Chungimati,Sundergarh,Odisha', 'lon': '84.6635'},
      '770037': {'lat': '21.8834', 'name': 'Kumakela,Sundergarh,Odisha', 'lon': '84.7744'},
      '770036': {'lat': '22.237', 'name': 'Bangerkela,Sundergarh,Odisha', 'lon': '84.9568'},
      '365455': {'lat': '22.1881', 'name': 'Pipallag,Amreli,Gujarat', 'lon': '70.4679'},
      '629156': {'lat': '8.8335', 'name': 'Kappiyarai,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '629155': {'lat': '8.8335', 'name': 'Kanjiracode,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '365456': {'lat': '23.0747', 'name': 'Balapur,Amreli,Gujarat', 'lon': '70.1726'},
      '532457': {'lat': '18.7837', 'name': 'Nivagam,Srikakulam,Andhra Pradesh', 'lon': '83.9383'},
      '532456': {'lat': '18.9378', 'name': 'Singidi,Srikakulam,Andhra Pradesh', 'lon': '83.8136'},
      '532455': {'lat': '18.7883', 'name': 'Marripadu,Srikakulam,Andhra Pradesh', 'lon': '83.8048'},
      '532459': {'lat': '18.6601', 'name': 'Bhagiradhipuram,Srikakulam,Andhra Pradesh', 'lon': '83.9595'},
      '532458': {'lat': '18.4853', 'name': 'Dabbapadu,Srikakulam,Andhra Pradesh', 'lon': '83.7275'},
      '751003': {'lat': '20.6966', 'name': 'Baramunda Colony,Khorda,Odisha', 'lon': '84.974'},
      '629159': {'lat': '8.1972', 'name': 'Ritapuram,Kanyakumari,Tamil Nadu', 'lon': '77.2358'},
      '629158': {'lat': '8.8335', 'name': 'Maruthurkurichi,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '221302': {'lat': '25.3023', 'name': 'Gangapur (Varanasi),Varanasi,Uttar Pradesh', 'lon': '82.8856'},
      '221303': {'lat': '25.27759', 'name': 'Dhirpur,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.4337'},
      '485226': {'lat': '24.8609', 'name': 'Sukwaha,Satna,Madhya Pradesh', 'lon': '81.054'},
      '413202': {'lat': '19.4314', 'name': 'Sangavi ( BK),Solapur,Maharashtra', 'lon': '75.4596'},
      '224227': {'lat': '26.4872', 'name': 'Mumrejpur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6051'},
      '221304': {'lat': '25.34715', 'name': 'Chakkisundas,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.47855'},
      '224225': {'lat': '25.8221', 'name': 'Paliya Pratapshah,Faizabad,Uttar Pradesh', 'lon': '82.1605'},
      '507122': {'lat': '17.4732', 'name': 'Tadakalapudi,Khammam,Telangana', 'lon': '80.1361'},
      '224228': {'lat': '26.3842', 'name': 'Aghiyari,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.8107'},
      '425310': {'lat': '20.6649', 'name': 'Kapuswadi,Jalgaon,Maharashtra', 'lon': '76.1383'},
      '503125': {'lat': '18.3834', 'name': 'Jangaon,Nizamabad,Telangana', 'lon': '78.1816'},
      '465230': {'lat': '23.5422', 'name': 'Choma Dak Bangla,Shajapur,Madhya Pradesh', 'lon': '75.8696'},
      '370015': {'lat': '23.2719', 'name': 'Bhuj B S F Camp,Kachchh,Gujarat', 'lon': '69.6957'},
      '413581': {'lat': '18.2624', 'name': 'Devangra,Osmanabad,Maharashtra', 'lon': '76.5085'},
      '796070': {'lat': '23.5491', 'name': 'N.Chaltlang,Aizawl,Mizoram', 'lon': '92.7346'},
      '764070': {'lat': '19.28104', 'name': 'Charmula,Nabarangapur,Odisha', 'lon': '82.76006'},
      '456550': {'lat': '22.1728', 'name': 'Tulaheda,Ujjain,Madhya Pradesh', 'lon': '77.1461'},
      '743270': {'lat': '24.3294', 'name': 'Kumarkhola,North 24 Parganas,West Bengal', 'lon': '87.9528'},
      '805133': {'lat': '25.238', 'name': 'Orro,Nawada,Bihar', 'lon': '85.458'},
      '835235': {'lat': '22.5776', 'name': 'Konbegi,Simdega,Jharkhand', 'lon': '84.3841'},
      '835234': {'lat': '23.0822', 'name': 'Govindpur (Ranchi),Ranchi,Jharkhand', 'lon': '84.9783'},
      '500031': {'lat': '17.3939', 'name': 'Ibrahim Bagh Lines,Hyderabad,Telangana', 'lon': '78.4529'},
      '805132': {'lat': '24.9444', 'name': 'Samharigarh,Nawada,Bihar', 'lon': '85.6646'},
      '835231': {'lat': '23.2668', 'name': 'Kurag,Gumla,Jharkhand', 'lon': '84.4986'},
      '835230': {'lat': '23.5559', 'name': 'Chiraiyan,Gumla,Jharkhand', 'lon': '84.4375'},
      '835233': {'lat': '23.1277', 'name': 'Anjan,Gumla,Jharkhand', 'lon': '84.4854'},
      '835232': {'lat': '22.9783', 'name': 'Karomdi,Gumla,Jharkhand', 'lon': '84.4751'},
      '713146': {'lat': '23.2899', 'name': 'Paharhati,Purba Bardhaman,West Bengal', 'lon': '88.0965'},
      '614203': {'lat': '11.2208', 'name': 'Kabistalam,Thanjavur,Tamil Nadu', 'lon': '78.2416'},
      '713144': {'lat': '23.43', 'name': 'Samantapara,Purba Bardhaman,West Bengal', 'lon': '87.6334'},
      '713145': {'lat': '23.639', 'name': 'Purbakhanpur Bamunpara,Purba Bardhaman,West Bengal', 'lon': '87.9485'},
      '713142': {'lat': '23.4011', 'name': 'Oari,Purba Bardhaman,West Bengal', 'lon': '87.9051'},
      '516444': {'lat': '14.7866', 'name': 'Kondikandlapalle,Cuddapah,Andhra Pradesh', 'lon': '78.5202'},
      '713140': {'lat': '23.9378', 'name': 'Deara-Radhanagar,Purba Bardhaman,West Bengal', 'lon': '87.9528'},
      '764071': {'lat': '19.36575', 'name': 'Naktiguda,Nabarangapur,Odisha', 'lon': '82.50065'},
      '673032': {'lat': '11.3373', 'name': 'Calicut Beach,Kozhikode,Kerala', 'lon': '75.8271'},
      '303012': {'lat': '26.9298', 'name': 'Jeetawal,Jaipur,Rajasthan', 'lon': '75.8994'},
      '614201': {'lat': '10.8967', 'name': 'Ayyampet,Thanjavur,Tamil Nadu', 'lon': '79.1943'},
      '713148': {'lat': '24.0243', 'name': 'Panagar Bazara,Purba Bardhaman,West Bengal', 'lon': '87.7017'},
      '713149': {'lat': '23.209', 'name': 'Bhaita,Purba Bardhaman,West Bengal', 'lon': '87.9734'},
      '796001': {'lat': '23.498', 'name': 'Aizawl H.O,Aizawl,Mizoram', 'lon': '92.7292'},
      '500029': {'lat': '17.3968', 'name': 'Himayathnagar,Hyderabad,Telangana', 'lon': '78.4935'},
      '851133': {'lat': '25.4904', 'name': 'Teghra,Begusarai,Bihar', 'lon': '85.94'},
      '516411': {'lat': '15.1138', 'name': 'Nemaladinne,Cuddapah,Andhra Pradesh', 'lon': '78.2361'},
      '192230': {'lat': '33.6134', 'name': 'Chanderwadi (Sessional),Ananthnag,Jammu & Kashmir', 'lon': '74.9419'},
      '192233': {'lat': '34.4795', 'name': 'Badjahalan,Kulgam,Jammu & Kashmir', 'lon': '74.0906'},
      '192232': {'lat': '33.9563', 'name': 'Behibagh,Ananthnag,Jammu & Kashmir', 'lon': '74.834'},
      '796008': {'lat': '23.498', 'name': 'Armed Veng,Aizawl,Mizoram', 'lon': '92.7292'},
      '614207': {'lat': '10.9851', 'name': 'Rajaghiri,Thanjavur,Tamil Nadu', 'lon': '78.9832'},
      '799279': {'lat': '23.9923', 'name': 'Goldharpur,North Tripura,Tripura', 'lon': '91.6205'},
      '614404': {'lat': '10.777', 'name': 'Aravoor,Tiruvarur,Tamil Nadu', 'lon': '79.4346'},
      '614206': {'lat': '10.8621', 'name': 'Vaiyacheri,Thanjavur,Tamil Nadu', 'lon': '79.1586'},
      '631211': {'lat': '13.0989', 'name': 'Kasavarajpetta,Tiruvallur,Tamil Nadu', 'lon': '79.7233'},
      '631210': {'lat': '13.0825', 'name': 'Harichandrapuram,Tiruvallur,Tamil Nadu', 'lon': '79.7388'},
      '631213': {'lat': '13.2431', 'name': 'Alamelumangapuram,Tiruvallur,Tamil Nadu', 'lon': '79.94'},
      '631212': {'lat': '13.0989', 'name': 'Thalavedu,Tiruvallur,Tamil Nadu', 'lon': '79.7233'},
      '496001': {'lat': '21.8764', 'name': 'Jivri,Raigarh,Chattisgarh', 'lon': '83.3774'},
      '614205': {'lat': '10.9851', 'name': 'Uthamadhanapuram,Thanjavur,Tamil Nadu', 'lon': '78.9832'},
      '799277': {'lat': '24.332', 'name': 'Manuvelly,North Tripura,Tripura', 'lon': '92.0039'},
      '425327': {'lat': '20.5339', 'name': 'Parambi,Jalgaon,Maharashtra', 'lon': '76.3997'},
      '614204': {'lat': '10.9163', 'name': 'Koildevarayanpettai,Thanjavur,Tamil Nadu', 'lon': '79.2399'},
      '460440': {'lat': '22.2376', 'name': 'Kundi,Betul,Madhya Pradesh', 'lon': '77.9019'},
      '425452': {'lat': '21.7382', 'name': 'Molgi,Nandurbar,Maharashtra', 'lon': '74.0377'},
      '460443': {'lat': '22.033', 'name': 'Ghoradongari,Betul,Madhya Pradesh', 'lon': '78.0055'},
      '500028': {'lat': '17.3991', 'name': 'Murad Nagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.4577'},
      '241301': {'lat': '27.187', 'name': 'Dahigawan,Hardoi,Uttar Pradesh', 'lon': '80.0164'},
      '241302': {'lat': '27.0923', 'name': 'Odra Pachlai,Hardoi,Uttar Pradesh', 'lon': '80.445'},
      '241303': {'lat': '27.0903', 'name': 'Mallawan,Hardoi,Uttar Pradesh', 'lon': '80.3838'},
      '242401': {'lat': '27.8713', 'name': 'Powayan,Shahjahanpur,Uttar Pradesh', 'lon': '79.8092'},
      '241305': {'lat': '27.3287', 'name': 'Behsar,Hardoi,Uttar Pradesh', 'lon': '80.1625'},
      '423111': {'lat': '20.2798', 'name': 'Redgaon BK,Nashik,Maharashtra', 'lon': '74.0201'},
      '423110': {'lat': '20.1726', 'name': 'Kumbharde,Nashik,Maharashtra', 'lon': '73.9417'},
      '423117': {'lat': '20.3012', 'name': 'Kanmandale,Nashik,Maharashtra', 'lon': '74.0774'},
      '637505': {'lat': '11.55', 'name': 'Minnakkal Agraharam,Namakkal,Tamil Nadu', 'lon': '78.135'},
      '741504': {'lat': '23.5544', 'name': 'Daluabari,Nadia,West Bengal', 'lon': '88.3506'},
      '455001': {'lat': '22.9578', 'name': 'Dewas Vijayganjmandi,Dewas,Madhya Pradesh', 'lon': '76.2946'},
      '829132': {'lat': '23.6861', 'name': 'Turio,Bokaro,Jharkhand', 'lon': '85.5704'},
      '600088': {'lat': '12.9918', 'name': 'Adambakkam,Kanchipuram,Tamil Nadu', 'lon': '80.2035'},
      '843111': {'lat': '25.7081', 'name': 'B.Barna,Muzaffarpur,Bihar', 'lon': '85.7174'},
      '600089': {'lat': '13.0582', 'name': 'Ramapuram (Kanchipuram),Kanchipuram,Tamil Nadu', 'lon': '80.24'},
      '603203': {'lat': '12.2796', 'name': 'Kattankolathur,Kanchipuram,Tamil Nadu', 'lon': '79.9098'},
      '603202': {'lat': '12.8525', 'name': 'Guduvanchery,Kanchipuram,Tamil Nadu', 'lon': '80.0467'},
      '603201': {'lat': '12.6952', 'name': 'Elapakkam,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '637501': {'lat': '11.6741', 'name': 'Kalipatti,Salem,Tamil Nadu', 'lon': '78.2221'},
      '515261': {'lat': '14.5099', 'name': 'Beechaganipalli,Ananthapur,Andhra Pradesh', 'lon': '78.2267'},
      '683512': {'lat': '10.1632', 'name': 'Chendamangalam,Ernakulam,Kerala', 'lon': '76.2346'},
      '312606': {'lat': '24.9632', 'name': 'Sarsi,Chittorgarh,Rajasthan', 'lon': '74.6857'},
      '312604': {'lat': '24.7891', 'name': 'Gagrol,Chittorgarh,Rajasthan', 'lon': '74.6731'},
      '574232': {'lat': '12.6626', 'name': 'Kenya,Dakshina Kannada,Karnataka', 'lon': '75.4874'},
      '312602': {'lat': '24.6795', 'name': 'Potlajagir,Chittorgarh,Rajasthan', 'lon': '74.5094'},
      '312603': {'lat': '25.4116', 'name': 'Sadalkhera,Chittorgarh,Rajasthan', 'lon': '74.0986'},
      '312601': {'lat': '24.5279', 'name': 'Marjivi,Chittorgarh,Rajasthan', 'lon': '74.6289'},
      '403521': {'lat': '15.6235', 'name': 'Secretariat (North Goa),North Goa,Goa', 'lon': '73.8377'},
      '403523': {'lat': '15.55', 'name': 'Calvim,North Goa,Goa', 'lon': '73.85'},
      '403524': {'lat': '15.6868', 'name': 'Arambol,North Goa,Goa', 'lon': '73.7045'},
      '680711': {'lat': '10.4038', 'name': 'Panamkulam,Thrissur,Kerala', 'lon': '76.187'},
      '680712': {'lat': '10.4038', 'name': 'Madayikonam,Thrissur,Kerala', 'lon': '76.187'},
      '403527': {'lat': '15.6534', 'name': 'Mandrem,North Goa,Goa', 'lon': '73.7227'},
      '683517': {'lat': '10.1128', 'name': 'Varapuzha,Ernakulam,Kerala', 'lon': '76.2465'},
      '600086': {'lat': '13.0472', 'name': 'Gopalapuram (Chennai),Chennai,Tamil Nadu', 'lon': '80.2578'},
      '686560': {'lat': '9.6', 'name': 'Priyadarsini Hills,Kottayam,Kerala', 'lon': '76.4333'},
      '177114': {'lat': '31.9526992', 'name': 'Khabli,Bilaspur (HP),Himachal Pradesh', 'lon': '76.1819583'},
      '177117': {'lat': '31.9089', 'name': 'Muhal,Kangra,Himachal Pradesh', 'lon': '76.2517'},
      '600087': {'lat': '13.0439', 'name': 'Alwarthirunagar,Tiruvallur,Tamil Nadu', 'lon': '80.1725'},
      '177111': {'lat': '31.863', 'name': 'Qusba Kotla,Kangra,Himachal Pradesh', 'lon': '76.2871'},
      '683515': {'lat': '10.1128', 'name': 'Palliport,Ernakulam,Kerala', 'lon': '76.2465'},
      '177113': {'lat': '31.863', 'name': 'Chanour,Kangra,Himachal Pradesh', 'lon': '76.2871'},
      '177112': {'lat': '32.0984', 'name': 'Bathra,Kangra,Himachal Pradesh', 'lon': '76.1903'},
      '495660': {'lat': '21.9394', 'name': 'Bamhni Bazar,Janjgir-champa,Chattisgarh', 'lon': '82.7353'},
      '686562': {'lat': '9.6', 'name': 'Athirampuzha,Kottayam,Kerala', 'lon': '76.4333'},
      '177119': {'lat': '31.6539157', 'name': 'Ghumarli,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6564631'},
      '177118': {'lat': '31.68667', 'name': 'Bahanwin,Hamirpur(HP),Himachal Pradesh', 'lon': '76.42885'},
      '829130': {'lat': '23.6861', 'name': 'Prc,Ramgarh,Jharkhand', 'lon': '85.5704'},
      '495661': {'lat': '21.7569', 'name': 'Birra,Janjgir-champa,Chattisgarh', 'lon': '82.775'},
      '442707': {'lat': '19.6104', 'name': 'Mudholi Chak No. Ii,Gadchiroli,Maharashtra', 'lon': '79.9192'},
      '442705': {'lat': '19.4647', 'name': 'Lagam,Gadchiroli,Maharashtra', 'lon': '79.9623'},
      '442704': {'lat': '19.7689', 'name': 'Burgi,Gadchiroli,Maharashtra', 'lon': '80.2537'},
      '442703': {'lat': '19.4317', 'name': 'Allapalli,Gadchiroli,Maharashtra', 'lon': '80.0638'},
      '442702': {'lat': '19.9434', 'name': 'Chintaldhaba,Chandrapur,Maharashtra', 'lon': '79.5169'},
      '442701': {'lat': '19.8157', 'name': 'Dudholi Bamini,Chandrapur,Maharashtra', 'lon': '79.5987'},
      '145001': {'lat': '32.3267', 'name': 'Civil Court Pathankot,Gurdaspur,Punjab', 'lon': '75.7354'},
      '605752': {'lat': '11.9818', 'name': 'Vadakaraithayanur,Villupuram,Tamil Nadu', 'lon': '79.2175'},
      '605751': {'lat': '11.8813', 'name': 'Pazhangur,Villupuram,Tamil Nadu', 'lon': '79.152'},
      '605757': {'lat': '11.9448', 'name': 'Tirukkoyilur H.O,Villupuram,Tamil Nadu', 'lon': '79.2086'},
      '605756': {'lat': '11.6671', 'name': 'Thapovanam,Villupuram,Tamil Nadu', 'lon': '79.2882'},
      '442709': {'lat': '19.3301', 'name': 'Challewada,Gadchiroli,Maharashtra', 'lon': '80.0682'},
      '605754': {'lat': '12.015', 'name': 'Kangeyanur,Villupuram,Tamil Nadu', 'lon': '79.0857'},
      '828401': {'lat': '24.039', 'name': 'Ghunghusa,Dhanbad,Jharkhand', 'lon': '86.2834'},
      '828402': {'lat': '24.0136', 'name': 'Singhdaha,Dhanbad,Jharkhand', 'lon': '86.3362'},
      '828403': {'lat': '24.0784', 'name': 'Narra,Bokaro,Jharkhand', 'lon': '86.1461'},
      '828404': {'lat': '24.0477', 'name': 'Dugdha Coal washery,Bokaro,Jharkhand', 'lon': '86.2402'},
      '522626': {'lat': '16.0404', 'name': 'Murikipudi,Guntur,Andhra Pradesh', 'lon': '80.1024'},
      '274405': {'lat': '26.2267', 'name': 'Madrapali Bharth Rai,Deoria,Uttar Pradesh', 'lon': '83.6022'},
      '400028': {'lat': '18.986', 'name': 'Bhawani Shankar,Mumbai,Maharashtra', 'lon': '72.8259'},
      '181143': {'lat': '32.6242', 'name': 'Rahya,Jammu,Jammu & Kashmir', 'lon': '74.8637'},
      '533468': {'lat': '16.3684', 'name': 'Gorripudi,East Godavari,Andhra Pradesh', 'lon': '81.5254'},
      '181141': {'lat': '32.6061', 'name': 'Abtal,Samba,Jammu & Kashmir', 'lon': '74.8824'},
      '181145': {'lat': '32.6964', 'name': 'Purmandal,Jammu,Jammu & Kashmir', 'lon': '74.7888'},
      '533461': {'lat': '16.8', 'name': 'Chollangipeta,East Godavari,Andhra Pradesh', 'lon': '82.2333'},
      '477332': {'lat': '25.9272', 'name': 'Sihunda,Bhind,Madhya Pradesh', 'lon': '77.1455'},
      '533463': {'lat': '16.9468', 'name': 'P.Mallavaram,East Godavari,Andhra Pradesh', 'lon': '82.1045'},
      '533462': {'lat': '17.4515', 'name': 'Vemulavada,East Godavari,Andhra Pradesh', 'lon': '82.6784'},
      '581402': {'lat': '14.5582', 'name': 'Dodnalli,Uttara Kannada,Karnataka', 'lon': '74.8063'},
      '533464': {'lat': '16.5523', 'name': 'Yanam,Pondicherry,Pondicherry', 'lon': '81.4689'},
      '581400': {'lat': '14.8671', 'name': 'Kaiga Workers Colony,Uttara Kannada,Karnataka', 'lon': '74.3437'},
      '581401': {'lat': '14.8866', 'name': 'C.P.Bazar,Uttara Kannada,Karnataka', 'lon': '75.1705'},
      '670674': {'lat': '11.633', 'name': 'Santhigiri,Kannur,Kerala', 'lon': '75.7064'},
      '670675': {'lat': '11.7072', 'name': 'Mekkunnu,Kannur,Kerala', 'lon': '75.5868'},
      '670676': {'lat': '11.633', 'name': 'Kadavathur,Kannur,Kerala', 'lon': '75.7064'},
      '246428': {'lat': '29.9259', 'name': 'Farkande Talli,Chamoli,Uttarakhand', 'lon': '79.2445'},
      '670671': {'lat': '11.7486', 'name': 'Panniyannur,Kannur,Kerala', 'lon': '75.5756'},
      '670672': {'lat': '11.7409', 'name': 'Chokli,Kannur,Kerala', 'lon': '75.5793'},
      '670673': {'lat': '11.4787', 'name': 'Thondiyil,Kannur,Kerala', 'lon': '75.6739'},
      '246424': {'lat': '30.0941', 'name': 'Bachher,Chamoli,Uttarakhand', 'lon': '79.3237'},
      '246425': {'lat': '30.286', 'name': 'Jagoth,Rudraprayag,Uttarakhand', 'lon': '78.953'},
      '246426': {'lat': '30.1766', 'name': 'Maikot,Chamoli,Uttarakhand', 'lon': '79.4031'},
      '474015': {'lat': '26.0221', 'name': 'AB VIII TM Gwalior,Gwalior,Madhya Pradesh', 'lon': '78.148'},
      '246421': {'lat': '30.5089', 'name': 'Silli,Rudraprayag,Uttarakhand', 'lon': '79.1813'},
      '228178': {'lat': '26.2327', 'name': 'Belwai,Sultanpur,Uttar Pradesh', 'lon': '82.5'},
      '758037': {'lat': '22.1131', 'name': 'Bolani,Kendujhar,Odisha', 'lon': '85.3365'},
      '758036': {'lat': '21.6377', 'name': 'Matkambeda,Kendujhar,Odisha', 'lon': '85.3607'},
      '758035': {'lat': '21.6652', 'name': 'Nalada,Kendujhar,Odisha', 'lon': '85.0891'},
      '679591': {'lat': '10.73', 'name': 'Othalur West,Malappuram,Kerala', 'lon': '76.0608'},
      '758032': {'lat': '21.7118', 'name': 'Bhulda,Kendujhar,Odisha', 'lon': '85.5927'},
      '758031': {'lat': '21.7601', 'name': 'Khenda,Kendujhar,Odisha', 'lon': '85.4189'},
      '758030': {'lat': '21.6761', 'name': 'Gobarbeda,Kendujhar,Odisha', 'lon': '85.5392'},
      '758038': {'lat': '21.6377', 'name': 'Bileipada,Kendujhar,Odisha', 'lon': '85.3607'},
      '845456': {'lat': '26.7334', 'name': 'Bahuarawa,East Champaran,Bihar', 'lon': '84.7287'},
      '721507': {'lat': '22.6125', 'name': 'Jhargram H.O,West Midnapore,West Bengal', 'lon': '87.7099'},
      '845454': {'lat': '26.7449', 'name': 'Rampurwa Mahanwa,West Champaran,Bihar', 'lon': '84.6802'},
      '845455': {'lat': '27.0528', 'name': 'Musharwa,West Champaran,Bihar', 'lon': '84.4569'},
      '845452': {'lat': '27.0792', 'name': 'Chamukha,West Champaran,Bihar', 'lon': '84.3412'},
      '845453': {'lat': '27.0033', 'name': 'Siswa Basantpur,West Champaran,Bihar', 'lon': '84.4374'},
      '845450': {'lat': '26.6622', 'name': 'Turahapatti,West Champaran,Bihar', 'lon': '84.5035'},
      '413623': {'lat': '17.8859', 'name': 'Wadgaon Kati,Osmanabad,Maharashtra', 'lon': '75.9852'},
      '764078': {'lat': '19.24181', 'name': 'B. Maliguda,Nabarangapur,Odisha', 'lon': '82.7152'},
      '721505': {'lat': '22.8492', 'name': 'Khatkhura,West Midnapore,West Bengal', 'lon': '86.7415'},
      '845458': {'lat': '26.2513', 'name': 'Ganga Pipara,East Champaran,Bihar', 'lon': '84.5614'},
      '845459': {'lat': '26.6462', 'name': 'B. Banahura,West Champaran,Bihar', 'lon': '84.5158'},
      '205247': {'lat': '27.1612', 'name': 'Allau,Mainpuri,Uttar Pradesh', 'lon': '79.1092'},
      '485666': {'lat': '24.5533', 'name': 'Pawaiya,Satna,Madhya Pradesh', 'lon': '81.2032'},
      '843114': {'lat': '25.8587', 'name': 'Baligaon Chandpura,Vaishali,Bihar', 'lon': '85.5292'},
      '311805': {'lat': '25.3912', 'name': 'Chawandia,Bhilwara,Rajasthan', 'lon': '74.303'},
      '311804': {'lat': '25.5224', 'name': 'Nimbahera Jatan,Bhilwara,Rajasthan', 'lon': '74.4993'},
      '787035': {'lat': '26.6746', 'name': 'Mingmang,Dhemaji,Assam', 'lon': '92.7955'},
      '787034': {'lat': '26.6602', 'name': 'Khorachukgaon,Dhemaji,Assam', 'lon': '92.0187'},
      '787033': {'lat': '26.4497', 'name': 'Kerekjuli,Lakhimpur,Assam', 'lon': '91.3771'},
      '787032': {'lat': '26.7544', 'name': 'Konwargaon,Lakhimpur,Assam', 'lon': '93.5272'},
      '787031': {'lat': '26.6746', 'name': 'Hatilung,Lakhimpur,Assam', 'lon': '92.7955'},
      '765029': {'lat': '19.1072', 'name': 'Bhoimada,Rayagada,Odisha', 'lon': '83.7152'},
      '518466': {'lat': '15.7563', 'name': 'R.Kanapuram,Kurnool,Andhra Pradesh', 'lon': '77.8205'},
      '518467': {'lat': '15.6721', 'name': 'Anugonda,Kurnool,Andhra Pradesh', 'lon': '77.9022'},
      '518464': {'lat': '15.6959', 'name': 'Pyalakurthi,Kurnool,Andhra Pradesh', 'lon': '77.7918'},
      '518465': {'lat': '15.5661', 'name': 'Yelamakur,Kurnool,Andhra Pradesh', 'lon': '77.5569'},
      '518462': {'lat': '15.7182', 'name': 'T Samala Gudur,Kurnool,Andhra Pradesh', 'lon': '77.8355'},
      '518463': {'lat': '15.7044', 'name': 'Thippanuru,Kurnool,Andhra Pradesh', 'lon': '77.6393'},
      '824235': {'lat': '25.0854', 'name': 'Utrawan,Jehanabad,Bihar', 'lon': '84.8679'},
      '824234': {'lat': '25.5218', 'name': 'Sahdeokhap,Gaya,Bihar', 'lon': '86.0699'},
      '761030': {'lat': '20.4787', 'name': 'Kushadhipa,Ganjam,Odisha', 'lon': '85.2055'},
      '488059': {'lat': '24.1963', 'name': 'Sungraha,Panna,Madhya Pradesh', 'lon': '79.5935'},
      '518468': {'lat': '15.4115', 'name': 'T.Sanugondla,Kurnool,Andhra Pradesh', 'lon': '77.637'},
      '194303': {'lat': '33.683', 'name': 'Purtkchey,Kargil,Jammu & Kashmir', 'lon': '75.9275'},
      '194302': {'lat': '33.5629', 'name': 'Cha Lungnak,Kargil,Jammu & Kashmir', 'lon': '76.9373'},
      '194301': {'lat': '33.803', 'name': 'Sankoo,Kargil,Jammu & Kashmir', 'lon': '74.9177'},
      '507120': {'lat': '16.9332', 'name': 'Karivarigudem,Khammam,Telangana', 'lon': '79.6976'},
      '736201': {'lat': '25.5088', 'name': 'Kaijali Basti,Alipurduar,West Bengal', 'lon': '88.582'},
      '304803': {'lat': '25.7851', 'name': 'Polyada,Tonk,Rajasthan', 'lon': '75.4897'},
      '304802': {'lat': '25.8135', 'name': 'Dewarawas,Tonk,Rajasthan', 'lon': '75.5943'},
      '304801': {'lat': '26.3493', 'name': 'Kathmana,Tonk,Rajasthan', 'lon': '75.2877'},
      '721631': {'lat': '22.2092', 'name': 'Daudpurnandi,East Midnapore,West Bengal', 'lon': '88.2753'},
      '613401': {'lat': '10.7211', 'name': 'Tirumalaisamudram East,Thanjavur,Tamil Nadu', 'lon': '79.0136'},
      '613402': {'lat': '10.183', 'name': 'Palayapatti South,Thanjavur,Tamil Nadu', 'lon': '78.5651'},
      '304804': {'lat': '26.1512', 'name': 'Kaseer,Tonk,Rajasthan', 'lon': '76.0255'},
      '641018': {'lat': '11.0167', 'name': 'CBE Mpl.Central Busstand,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '641019': {'lat': '11.0167', 'name': 'Veerapandi Periyanaickenpalaya,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '721135': {'lat': '24.2471', 'name': 'Barida,East Midnapore,West Bengal', 'lon': '87.8066'},
      '283151': {'lat': '27.0909', 'name': 'Sothara,Firozabad,Uttar Pradesh', 'lon': '78.8605'},
      '606106': {'lat': '11.4224', 'name': 'Kilacheruvoi,Cuddalore,Tamil Nadu', 'lon': '79.1043'},
      '641010': {'lat': '10.964', 'name': 'Titipalayam,Coimbatore,Tamil Nadu', 'lon': '76.8788'},
      '641011': {'lat': '11.0167', 'name': 'Saibaba Mission,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '641012': {'lat': '11.0167', 'name': 'Gandhipuram (Coimbatore),Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '641013': {'lat': '11.0167', 'name': 'Govt.College Of Technology,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '641014': {'lat': '11.0167', 'name': 'Coimbatore Aerodrome,Coimbatore,Tamil Nadu', 'lon': '76.9707'},
      '362720': {'lat': '21.431', 'name': 'Kaj,Junagadh,Gujarat', 'lon': '71.0823'},
      '641016': {'lat': '11.0476', 'name': 'Edayapalayam,Coimbatore,Tamil Nadu', 'lon': '77.0808'},
      '641017': {'lat': '11.0823', 'name': 'Thoppampatti,Coimbatore,Tamil Nadu', 'lon': '76.9164'},
      '535101': {'lat': '18.2183', 'name': 'Devunikanapaka,Vizianagaram,Andhra Pradesh', 'lon': '83.4046'},
      '535102': {'lat': '18.3895', 'name': 'Gadelamarrivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.4626'},
      '736207': {'lat': '24.4433', 'name': 'Purba Shalbari,Alipurduar,West Bengal', 'lon': '87.961'},
      '388355': {'lat': '22.4809', 'name': 'Navli,Anand,Gujarat', 'lon': '72.9776'},
      '679336': {'lat': '10.9031', 'name': 'Choorakode,Palakkad,Kerala', 'lon': '76.3687'},
      '679337': {'lat': '10.8846', 'name': 'Chundampatta,Palakkad,Kerala', 'lon': '76.2004'},
      '679334': {'lat': '11.3826', 'name': 'Padiripadam,Malappuram,Kerala', 'lon': '76.257'},
      '679335': {'lat': '10.8764', 'name': 'Pombilaya,Palakkad,Kerala', 'lon': '76.2804'},
      '679332': {'lat': '11.2249', 'name': 'Chokkad,Malappuram,Kerala', 'lon': '76.2716'},
      '679333': {'lat': '11.3857', 'name': 'Kambalakallu,Malappuram,Kerala', 'lon': '76.3443'},
      '679330': {'lat': '11.303', 'name': 'Karulai,Malappuram,Kerala', 'lon': '76.2999'},
      '679331': {'lat': '11.1014', 'name': 'Karapuram,Malappuram,Kerala', 'lon': '76.2443'},
      '517592': {'lat': '13.3218', 'name': 'Adavikothuru,Chittoor,Andhra Pradesh', 'lon': '79.5608'},
      '517591': {'lat': '13.3478', 'name': 'Aroor,Chittoor,Andhra Pradesh', 'lon': '79.6953'},
      '517590': {'lat': '13.3256', 'name': 'Pudupet,Chittoor,Andhra Pradesh', 'lon': '79.5989'},
      '679338': {'lat': '10.9369', 'name': 'Panga South,Malappuram,Kerala', 'lon': '76.1273'},
      '679339': {'lat': '11.1733', 'name': 'Koorad,Malappuram,Kerala', 'lon': '76.2398'},
      '226005': {'lat': '26.9562', 'name': 'Pawanpuri (Lucknow),Lucknow,Uttar Pradesh', 'lon': '80.8792'},
      '629851': {'lat': '8.3113', 'name': 'Maramalai,Kanyakumari,Tamil Nadu', 'lon': '77.4262'},
      '125060': {'lat': '29.619', 'name': 'Bajekan,Sirsa,Haryana', 'lon': '75.3285'},
      '841221': {'lat': '25.8009', 'name': 'Arbindnagar,Saran,Bihar', 'lon': '85.0285'},
      '841220': {'lat': '25.9922', 'name': 'Keshri Mathia,Saran,Bihar', 'lon': '84.6063'},
      '700081': {'lat': '22.3341', 'name': 'Rajbari Colony,North 24 Parganas,West Bengal', 'lon': '88.3065'},
      '841222': {'lat': '25.774', 'name': 'Patti Sital,Saran,Bihar', 'lon': '85.1568'},
      '841225': {'lat': '25.1316', 'name': 'Bodha Chapra,Saran,Bihar', 'lon': '83.6836'},
      '841224': {'lat': '25.9374', 'name': 'Aruwa,Saran,Bihar', 'lon': '84.6772'},
      '841227': {'lat': '26.0664', 'name': 'Sarwe,Siwan,Bihar', 'lon': '84.445'},
      '841226': {'lat': '25.97', 'name': 'Chapia Bujurg,Siwan,Bihar', 'lon': '84.5765'},
      '583127': {'lat': '14.7506', 'name': 'Aladahalli,Davangere,Karnataka', 'lon': '76.1729'},
      '176225': {'lat': '32.3073', 'name': 'Soldha,Kangra,Himachal Pradesh', 'lon': '76.0422'},
      '583125': {'lat': '14.5697', 'name': 'Nichavvanahalli,Davangere,Karnataka', 'lon': '76.0754'},
      '583124': {'lat': '15.0171', 'name': 'Nidugurthi,Ballari,Karnataka', 'lon': '76.4757'},
      '583123': {'lat': '15.1956', 'name': 'Toranagallu,Ballari,Karnataka', 'lon': '76.6778'},
      '583122': {'lat': '15.4078', 'name': 'Nadavi,Ballari,Karnataka', 'lon': '76.4528'},
      '583121': {'lat': '15.3056', 'name': 'K.Belagal,Ballari,Karnataka', 'lon': '76.7165'},
      '583120': {'lat': '15.4033', 'name': 'Havinal,Ballari,Karnataka', 'lon': '76.8626'},
      '583129': {'lat': '15.066', 'name': 'Old Daroji,Ballari,Karnataka', 'lon': '76.5886'},
      '583128': {'lat': '14.7098', 'name': 'D Mallapura,Ballari,Karnataka', 'lon': '76.4298'},
      '584143': {'lat': '16.0482', 'name': 'Ramatnal,Raichur,Karnataka', 'lon': '76.7238'},
      '721632': {'lat': '23.1444', 'name': 'Nandakumar,East Midnapore,West Bengal', 'lon': '87.9658'},
      '835223': {'lat': '22.6181', 'name': 'Gutbahar,Simdega,Jharkhand', 'lon': '84.3121'},
      '403106': {'lat': '15.4761', 'name': 'Sant Estevam,South Goa,Goa', 'lon': '73.9417'},
      '451556': {'lat': '22.0043', 'name': 'Fatyapur,Barwani,Madhya Pradesh', 'lon': '74.9663'},
      '403107': {'lat': '15.5377', 'name': 'Marcela,South Goa,Goa', 'lon': '73.984'},
      '473440': {'lat': '24.4873', 'name': 'Keshopur,Ashok Nagar,Madhya Pradesh', 'lon': '77.9255'},
      '635207': {'lat': '12.149', 'name': 'Nochipatti,Krishnagiri,Tamil Nadu', 'lon': '78.391'},
      '533342': {'lat': '16.9711', 'name': 'Peraramachandrapuram,East Godavari,Andhra Pradesh', 'lon': '82.0478'},
      '822114': {'lat': '24.1854', 'name': 'Ranpura,Garhwa,Jharkhand', 'lon': '83.9125'},
      '365601': {'lat': '23.5172', 'name': 'Vathalpur K,Amreli,Gujarat', 'lon': '71.6847'},
      '822116': {'lat': '24.5454', 'name': 'Jagajamdiha,Palamau,Jharkhand', 'lon': '84.0705'},
      '403105': {'lat': '15.1964', 'name': 'Velguem,South Goa,Goa', 'lon': '74.1184'},
      '822110': {'lat': '24.0467', 'name': 'Katual,Palamau,Jharkhand', 'lon': '84.0038'},
      '822111': {'lat': '23.7588', 'name': 'Huttar Coliewary,Latehar,Jharkhand', 'lon': '84.1075'},
      '822112': {'lat': '24.3728', 'name': 'Barikhap,Latehar,Jharkhand', 'lon': '83.5744'},
      '822113': {'lat': '24.3114', 'name': 'Munkeri,Palamau,Jharkhand', 'lon': '84.2117'},
      '335511': {'lat': '29.2427', 'name': 'Biran,Hanumangarh,Rajasthan', 'lon': '75.3749'},
      '335513': {'lat': '26.8122', 'name': 'Ramsara Narain,Hanumangarh,Rajasthan', 'lon': '75.7831'},
      '335512': {'lat': '28.4738', 'name': 'CHAK 6 UTS,Hanumangarh,Rajasthan', 'lon': '75.5476'},
      '721635': {'lat': '22.1138', 'name': 'Tabakhali,East Midnapore,West Bengal', 'lon': '88.112'},
      '249405': {'lat': '29.9311', 'name': 'Patanjali Yogpeeth,Haridwar,Uttarakhand', 'lon': '77.9795'},
      '824236': {'lat': '25.0098', 'name': 'Fatehpur Nepa,Gaya,Bihar', 'lon': '84.8742'},
      '516175': {'lat': '14.8607', 'name': 'Nelatur,Cuddapah,Andhra Pradesh', 'lon': '78.6542'},
      '516172': {'lat': '14.732', 'name': 'Mydekur,Cuddapah,Andhra Pradesh', 'lon': '78.7736'},
      '516173': {'lat': '14.8018', 'name': 'Ganjikunta,Cuddapah,Andhra Pradesh', 'lon': '78.7465'},
      '600127': {'lat': '12.4956', 'name': 'Mambakkam,Kanchipuram,Tamil Nadu', 'lon': '79.8897'},
      '600126': {'lat': '12.6952', 'name': 'Mappedu,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '600125': {'lat': '13.0158', 'name': 'Manapakkam,Kanchipuram,Tamil Nadu', 'lon': '80.1761'},
      '600124': {'lat': '12.7207', 'name': 'Thirumushi,Tiruvallur,Tamil Nadu', 'lon': '79.6536'},
      '600123': {'lat': '13.2431', 'name': 'Nazarethpettai,Tiruvallur,Tamil Nadu', 'lon': '79.94'},
      '160055': {'lat': '30.7465', 'name': 'Chandigarh Sector 55,Mohali,Punjab', 'lon': '76.7417'},
      '242307': {'lat': '27.8124', 'name': 'Hindu Pattil,Shahjahanpur,Uttar Pradesh', 'lon': '79.8974'},
      '600120': {'lat': '13.256', 'name': 'Subbareddypalayam,Tiruvallur,Tamil Nadu', 'lon': '80.2818'},
      '506151': {'lat': '18.0699', 'name': 'Kyathampalli,Warangal,Telangana', 'lon': '79.5287'},
      '160059': {'lat': '30.7465', 'name': 'Chandigarh Sector 60,Mohali,Punjab', 'lon': '76.7417'},
      '600129': {'lat': '12.8745', 'name': 'Kovilambakkam,Kanchipuram,Tamil Nadu', 'lon': '79.763'},
      '600128': {'lat': '13.0407', 'name': 'Gerugambakkam,Kanchipuram,Tamil Nadu', 'lon': '79.2949'},
      '249404': {'lat': '29.8477', 'name': 'Dhanpura,Haridwar,Uttarakhand', 'lon': '78.1122'},
      '741126': {'lat': '23.3019', 'name': 'Patikabari,Nadia,West Bengal', 'lon': '87.8172'},
      '384170': {'lat': '23.8171', 'name': 'Unjha Kotkuva,Mahesana,Gujarat', 'lon': '72.3808'},
      '788803': {'lat': '24.9357', 'name': 'Badarpurghat,Karimganj,Assam', 'lon': '92.6461'},
      '788802': {'lat': '24.8614', 'name': 'Fulbari,Hailakandi,Assam', 'lon': '92.5963'},
      '788801': {'lat': '25.1', 'name': 'Salchapra,Hailakandi,Assam', 'lon': '92.8333'},
      '788806': {'lat': '24.8457', 'name': 'Srigouri,Karimganj,Assam', 'lon': '92.5086'},
      '788805': {'lat': '24.9357', 'name': 'Katigorah,Cachar,Assam', 'lon': '92.6461'},
      '788804': {'lat': '24.9357', 'name': 'Govindapur West,Cachar,Assam', 'lon': '92.6461'},
      '635205': {'lat': '12.2482', 'name': 'Gendiganahalli,Dharmapuri,Tamil Nadu', 'lon': '78.1874'},
      '711112': {'lat': '22.6368', 'name': 'Old Jagacha,Howrah,West Bengal', 'lon': '88.288'},
      '502247': {'lat': '18.0313', 'name': 'Dommat,Medak,Telangana', 'lon': '78.6011'},
      '713339': {'lat': '23.7668', 'name': 'Mohishila,Paschim Bardhaman,West Bengal', 'lon': '87.2745'},
      '713338': {'lat': '23.9614', 'name': 'Kajoragram,Purba Bardhaman,West Bengal', 'lon': '87.1708'},
      '507158': {'lat': '17.7677', 'name': 'Mallannapalem,Khammam,Telangana', 'lon': '80.218'},
      '507159': {'lat': '17.1636', 'name': 'Mallepalli,Khammam,Telangana', 'lon': '79.9719'},
      '713335': {'lat': '23.9614', 'name': 'Seakulberia,Paschim Bardhaman,West Bengal', 'lon': '87.1708'},
      '713334': {'lat': '23.7834', 'name': 'Domahani Bazar,Paschim Bardhaman,West Bengal', 'lon': '87.0715'},
      '713337': {'lat': '23.9614', 'name': 'Bidhanbag,Purba Bardhaman,West Bengal', 'lon': '87.1708'},
      '713336': {'lat': '23.9614', 'name': 'Mondalpur,Paschim Bardhaman,West Bengal', 'lon': '87.1708'},
      '713331': {'lat': '23.8568', 'name': 'Amladahi,Paschim Bardhaman,West Bengal', 'lon': '86.9032'},
      '713330': {'lat': '23.743', 'name': 'Charanpur,Paschim Bardhaman,West Bengal', 'lon': '87.0459'},
      '713333': {'lat': '24.5231', 'name': 'Gangutia,Paschim Bardhaman,West Bengal', 'lon': '87.369'},
      '713332': {'lat': '23.9614', 'name': 'Devchandnagar,Paschim Bardhaman,West Bengal', 'lon': '87.1708'},
      '799014': {'lat': '23.7062', 'name': 'Agartala ONGC,West Tripura,Tripura', 'lon': '91.4203'},
      '516312': {'lat': '14.5942', 'name': 'Vv Reddy Nagar,Cuddapah,Andhra Pradesh', 'lon': '78.5363'},
      '786602': {'lat': '27.3723', 'name': 'Jaloni Dibruduar,Dibrugarh,Assam', 'lon': '95.3075'},
      '516310': {'lat': '14.5942', 'name': 'Nidizuvvi,Cuddapah,Andhra Pradesh', 'lon': '78.5363'},
      '516311': {'lat': '14.5942', 'name': 'Krishna Nagar (Cuddapah),Cuddapah,Andhra Pradesh', 'lon': '78.5363'},
      '413224': {'lat': '17.9889', 'name': 'Tikekarwadi,Solapur,Maharashtra', 'lon': '76.0666'},
      '802221': {'lat': '25.2586', 'name': 'Imirtha,Rohtas,Bihar', 'lon': '84.2255'},
      '533264': {'lat': '17.1881', 'name': 'Kutukuluru,East Godavari,Andhra Pradesh', 'lon': '82.0802'},
      '533263': {'lat': '16.9794', 'name': 'Masakapalli,East Godavari,Andhra Pradesh', 'lon': '81.6933'},
      '524005': {'lat': '14.4685', 'name': 'Polytechnic (Nellore),Nellore,Andhra Pradesh', 'lon': '79.9445'},
      '533261': {'lat': '16.5593', 'name': 'Pulagurtha,East Godavari,Andhra Pradesh', 'lon': '80.9846'},
      '533260': {'lat': '17.5047', 'name': 'Siripuram,East Godavari,Andhra Pradesh', 'lon': '82.3378'},
      '788119': {'lat': '24.7256', 'name': 'Tulargram PT II,Cachar,Assam', 'lon': '92.8812'},
      '788118': {'lat': '24.7441', 'name': 'Silcuri,Cachar,Assam', 'lon': '92.7721'},
      '144630': {'lat': '31.3353', 'name': 'Talwandi Bhairon,Jalandhar,Punjab', 'lon': '75.3466'},
      '585401': {'lat': '17.7648', 'name': 'Bidar H.O,Bidar,Karnataka', 'lon': '77.2457'},
      '384355': {'lat': '23.405', 'name': 'Bamanva,Mahesana,Gujarat', 'lon': '73.0503'},
      '788111': {'lat': '24.9232', 'name': 'Dalu,Cachar,Assam', 'lon': '92.7829'},
      '788110': {'lat': '24.7441', 'name': 'Borkhola,Cachar,Assam', 'lon': '92.7721'},
      '788113': {'lat': '24.6322', 'name': 'Barthal,Cachar,Assam', 'lon': '92.7094'},
      '788112': {'lat': '24.7441', 'name': 'Clever House,Cachar,Assam', 'lon': '92.7721'},
      '788115': {'lat': '24.7441', 'name': 'Berabak PT II,Cachar,Assam', 'lon': '92.7721'},
      '788114': {'lat': '24.7441', 'name': 'Dulalgram,Cachar,Assam', 'lon': '92.7721'},
      '788117': {'lat': '24.6953', 'name': 'Sarbantilla,Hailakandi,Assam', 'lon': '92.715'},
      '788116': {'lat': '24.7441', 'name': 'Sundari PT II,Cachar,Assam', 'lon': '92.7721'},
      '388120': {'lat': '22.5333', 'name': 'Vallabh  Vidyanagar,Anand,Gujarat', 'lon': '72.9'},
      '388121': {'lat': '22.5333', 'name': 'Vithal Udyognagar,Anand,Gujarat', 'lon': '72.9'},
      '680104': {'lat': '10.5814', 'name': 'Thaikkad,Thrissur,Kerala', 'lon': '76.0693'},
      '802222': {'lat': '25.1873', 'name': 'Sikrahta Kala,Bhojpur,Bihar', 'lon': '84.5256'},
      '736208': {'lat': '25.5088', 'name': 'Jasodanga,Alipurduar,West Bengal', 'lon': '88.582'},
      '770041': {'lat': '21.7659', 'name': 'Sashyakala,Sundergarh,Odisha', 'lon': '85.0246'},
      '203209': {'lat': '28.2515', 'name': 'Karauli Banger,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.6025'},
      '486123': {'lat': '24.5983', 'name': 'Khaira Kankesra,Rewa,Madhya Pradesh', 'lon': '81.4421'},
      '531113': {'lat': '17.6278', 'name': 'Bheemaboinapalem,Visakhapatnam,Andhra Pradesh', 'lon': '82.6764'},
      '370465': {'lat': '22.4513', 'name': 'Vada,Kachchh,Gujarat', 'lon': '69.7512'},
      '370460': {'lat': '22.4513', 'name': 'Koday,Kachchh,Gujarat', 'lon': '69.7512'},
      '283105': {'lat': '27.6236', 'name': 'Sahata,Agra,Uttar Pradesh', 'lon': '78.119'},
      '531111': {'lat': '17.8089', 'name': 'Mondigedda,Visakhapatnam,Andhra Pradesh', 'lon': '82.435'},
      '802223': {'lat': '25.3294', 'name': 'Mukundpur,Bhojpur,Bihar', 'lon': '84.4963'},
      '680523': {'lat': '10.6302', 'name': 'Chittanjur,Thrissur,Kerala', 'lon': '76.0478'},
      '680522': {'lat': '10.5783', 'name': 'Chakkumkandam,Thrissur,Kerala', 'lon': '76.0554'},
      '680521': {'lat': '10.6242', 'name': 'Arthat,Thrissur,Kerala', 'lon': '76.0526'},
      '680520': {'lat': '10.6212', 'name': 'Thozhiyur,Thrissur,Kerala', 'lon': '76.0526'},
      '272153': {'lat': '27.1002', 'name': 'Bangawa,Basti,Uttar Pradesh', 'lon': '82.9134'},
      '272152': {'lat': '27.0246', 'name': 'Gopalpur,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.977'},
      '272151': {'lat': '26.6855', 'name': 'Khambha,Basti,Uttar Pradesh', 'lon': '82.6784'},
      '680524': {'lat': '10.6212', 'name': 'Padoor,Thrissur,Kerala', 'lon': '76.0526'},
      '606402': {'lat': '11.967', 'name': 'Rangappanur,Villupuram,Tamil Nadu', 'lon': '78.8843'},
      '143401': {'lat': '31.427', 'name': 'Tarn Taran H.O,Tarn Taran,Punjab', 'lon': '75.0451'},
      '143402': {'lat': '31.4693', 'name': 'Qilla Kavi Santokh,Tarn Taran,Punjab', 'lon': '74.8532'},
      '333705': {'lat': '27.9521', 'name': 'Dundlod RS,Jhujhunu,Rajasthan', 'lon': '75.2243'},
      '143406': {'lat': '31.4966', 'name': 'Pakhoke,Tarn Taran,Punjab', 'lon': '75.0105'},
      '400098': {'lat': '19.2361', 'name': 'Vidyanagari,Mumbai,Maharashtra', 'lon': '72.8338'},
      '143408': {'lat': '31.2303', 'name': 'Dhundhai Wala,Tarn Taran,Punjab', 'lon': '75.0081'},
      '143409': {'lat': '31.4802', 'name': 'Warah,Tarn Taran,Punjab', 'lon': '74.9556'},
      '245301': {'lat': '28.635', 'name': 'Dhaulana,Hapur,Uttar Pradesh', 'lon': '77.6512'},
      '285201': {'lat': '25.9331', 'name': 'Chandurra,Jalaun,Uttar Pradesh', 'lon': '79.1596'},
      '271125': {'lat': '27.1251', 'name': 'Baruee Gondha,Gonda,Uttar Pradesh', 'lon': '82.008'},
      '244303': {'lat': '28.617', 'name': 'Gauhat,Moradabad,Uttar Pradesh', 'lon': '78.3961'},
      '244302': {'lat': '28.5388', 'name': 'R.P. Niyaoli,Moradabad,Uttar Pradesh', 'lon': '78.5349'},
      '244301': {'lat': '28.6796', 'name': 'Azamnagar Chopra,Moradabad,Uttar Pradesh', 'lon': '78.7209'},
      '333701': {'lat': '27.8705', 'name': 'Churiajitgarh,Jhujhunu,Rajasthan', 'lon': '75.0957'},
      '741125': {'lat': '23.2195', 'name': 'Mayakole,Nadia,West Bengal', 'lon': '88.0994'},
      '285203': {'lat': '26.0626', 'name': 'Mawai Ahir,Jalaun,Uttar Pradesh', 'lon': '79.8285'},
      '244304': {'lat': '28.7273', 'name': 'Anchora  Kamboh,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.4618'},
      '755012': {'lat': '20.0603', 'name': 'Bachhalo,Jajapur,Odisha', 'lon': '85.213'},
      '755013': {'lat': '20.6724', 'name': 'Alakundanuagaon,Jajapur,Odisha', 'lon': '85.5273'},
      '581365': {'lat': '14.5765', 'name': 'Ganeshgudi,Uttara Kannada,Karnataka', 'lon': '74.406'},
      '755011': {'lat': '20.6576', 'name': 'Sanakuanlo,Jajapur,Odisha', 'lon': '85.6114'},
      '581363': {'lat': '15.1272', 'name': 'Bhagwati,Uttara Kannada,Karnataka', 'lon': '74.657'},
      '581362': {'lat': '14.4479', 'name': 'Manaki,Uttara Kannada,Karnataka', 'lon': '74.4544'},
      '581361': {'lat': '14.2826', 'name': 'Hadinbal,Uttara Kannada,Karnataka', 'lon': '74.5049'},
      '581360': {'lat': '14.8991', 'name': 'Mudgeri,Uttara Kannada,Karnataka', 'lon': '74.1317'},
      '783392': {'lat': '26.5214', 'name': 'Chakla,Bongaigaon,Assam', 'lon': '90.8806'},
      '783393': {'lat': '26.5214', 'name': 'Amteka Bazar,Bongaigaon,Assam', 'lon': '90.8806'},
      '783390': {'lat': '26.5214', 'name': 'Kaliagaon,Bongaigaon,Assam', 'lon': '90.8806'},
      '783391': {'lat': '26.5214', 'name': 'Alukhunda Bazar,Bongaigaon,Assam', 'lon': '90.8806'},
      '641652': {'lat': '11.1657', 'name': 'Tirumuruganpoondi,Coimbatore,Tamil Nadu', 'lon': '77.3712'},
      '143107': {'lat': '31.2759', 'name': 'Bhuse,Tarn Taran,Punjab', 'lon': '74.8808'},
      '783394': {'lat': '26.5214', 'name': 'Patabari,Bongaigaon,Assam', 'lon': '90.8806'},
      '370205': {'lat': '23.6285', 'name': 'Mathak,Kachchh,Gujarat', 'lon': '69.9604'},
      '413705': {'lat': '19.2034', 'name': 'Manjari,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '413704': {'lat': '18.7833', 'name': 'Katrad,Ahmed Nagar,Maharashtra', 'lon': '75.0066'},
      '413707': {'lat': '19.7681', 'name': 'Naur,Ahmed Nagar,Maharashtra', 'lon': '74.6155'},
      '413706': {'lat': '19.5683', 'name': 'Varshinde,Ahmed Nagar,Maharashtra', 'lon': '75.3828'},
      '413701': {'lat': '19.1003', 'name': 'Adhalgaon,Ahmed Nagar,Maharashtra', 'lon': '74.6259'},
      '627860': {'lat': '8.3718', 'name': 'Uthumalai,Tirunelveli,Tamil Nadu', 'lon': '77.9141'},
      '413703': {'lat': '18.8579', 'name': 'Bhoyare Gangarda,Ahmed Nagar,Maharashtra', 'lon': '74.5962'},
      '413702': {'lat': '19.7434', 'name': 'Dhavalgaon,Ahmed Nagar,Maharashtra', 'lon': '75.3272'},
      '143101': {'lat': '31.7237', 'name': 'Miran Kot,Amritsar,Punjab', 'lon': '74.941'},
      '311025': {'lat': '25.3251', 'name': 'Suthepa,Bhilwara,Rajasthan', 'lon': '74.556'},
      '142026': {'lat': '30.8567', 'name': 'Jagraon name,Ludhiana,Punjab', 'lon': '75.6153'},
      '413709': {'lat': '19.4764', 'name': 'Gondhavani,Ahmed Nagar,Maharashtra', 'lon': '74.9423'},
      '413708': {'lat': '20.2974', 'name': 'Sakarwadi,Ahmed Nagar,Maharashtra', 'lon': '75.4662'},
      '311021': {'lat': '25.1174', 'name': 'Lamba Khari Ka,Bhilwara,Rajasthan', 'lon': '74.6412'},
      '691020': {'lat': '8.8717', 'name': 'Koottikada,Kollam,Kerala', 'lon': '76.6384'},
      '691021': {'lat': '8.881', 'name': 'Pattathanam,Kollam,Kerala', 'lon': '76.6131'},
      '143103': {'lat': '31.7046', 'name': 'Kamalpur,Amritsar,Punjab', 'lon': '74.9007'},
      '142028': {'lat': '30.8567', 'name': 'New Rajguru Nagar,Ludhiana,Punjab', 'lon': '75.6153'},
      '492001': {'lat': '21.8417', 'name': 'Ravigram,Raipur,Chattisgarh', 'lon': '81.1181'},
      '476115': {'lat': '26.68', 'name': 'Rajodha,Morena,Madhya Pradesh', 'lon': '78.4225'},
      '492003': {'lat': '21.3132', 'name': 'Urla,Raipur,Chattisgarh', 'lon': '81.6064'},
      '143102': {'lat': '31.7715', 'name': 'Bhallapind,Amritsar,Punjab', 'lon': '74.863'},
      '492005': {'lat': '21.7363', 'name': 'Vidhan Sabha (Raipur),Raipur,Chattisgarh', 'lon': '81.595'},
      '492004': {'lat': '22.9994', 'name': 'Shanker Nagar,Raipur,Chattisgarh', 'lon': '81.9875'},
      '606105': {'lat': '11.4181', 'name': 'Murugangudi,Cuddalore,Tamil Nadu', 'lon': '79.2664'},
      '222142': {'lat': '25.6783', 'name': 'Sarauni,Jaunpur,Uttar Pradesh', 'lon': '82.9022'},
      '631152': {'lat': '13.0315', 'name': 'Suraksha Cisf Campus,Vellore,Tamil Nadu', 'lon': '79.753'},
      '631402': {'lat': '12.6952', 'name': 'Kilacheri,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '631151': {'lat': '13.0315', 'name': 'Sagayathottam,Vellore,Tamil Nadu', 'lon': '79.753'},
      '642107': {'lat': '10.6255', 'name': 'Kolarpatti,Coimbatore,Tamil Nadu', 'lon': '77.1117'},
      '642106': {'lat': '10.3341', 'name': 'Cinchona East,Coimbatore,Tamil Nadu', 'lon': '77.2037'},
      '642105': {'lat': '10.3341', 'name': 'Thalanar,Coimbatore,Tamil Nadu', 'lon': '77.2037'},
      '642104': {'lat': '10.583', 'name': 'Chinnappampalayam,Coimbatore,Tamil Nadu', 'lon': '76.9344'},
      '642103': {'lat': '10.3341', 'name': 'Subbegoundenpudur,Coimbatore,Tamil Nadu', 'lon': '77.2037'},
      '642102': {'lat': '10.8745', 'name': 'Kallapuram,Coimbatore,Tamil Nadu', 'lon': '76.9756'},
      '642101': {'lat': '10.3341', 'name': 'Upper Aliyar Dam,Coimbatore,Tamil Nadu', 'lon': '77.2037'},
      '576234': {'lat': '13.5529', 'name': 'Kokkarne,Udupi,Karnataka', 'lon': '76.0116'},
      '803118': {'lat': '25.2492', 'name': 'Doiya,Nalanda,Bihar', 'lon': '85.5015'},
      '606104': {'lat': '11.6046', 'name': 'Karnatham,Cuddalore,Tamil Nadu', 'lon': '79.2628'},
      '212502': {'lat': '25.6084', 'name': 'Soraon,Allahabad,Uttar Pradesh', 'lon': '81.8453'},
      '761019': {'lat': '19.4921', 'name': 'Alladigam,Ganjam,Odisha', 'lon': '84.9338'},
      '362004': {'lat': '23.0956', 'name': 'Junagadh Bhavnath,Junagadh,Gujarat', 'lon': '72.1775'},
      '523002': {'lat': '15.5222', 'name': 'Kurnool Road (O),Prakasam,Andhra Pradesh', 'lon': '79.6251'},
      '761014': {'lat': '19.1734', 'name': 'Singipur,Gajapati,Odisha', 'lon': '84.1607'},
      '761017': {'lat': '19.7366', 'name': 'Chelligada,Gajapati,Odisha', 'lon': '83.9783'},
      '523001': {'lat': '15.5222', 'name': 'N.S.C Colony (O),Prakasam,Andhra Pradesh', 'lon': '79.6251'},
      '761011': {'lat': '19.9633', 'name': 'L.N.Pur,Ganjam,Odisha', 'lon': '83.545'},
      '761010': {'lat': '19.9408', 'name': 'Jagamohanapur,Ganjam,Odisha', 'lon': '84.8424'},
      '173104': {'lat': '30.7728', 'name': 'Devamanal,Sirmaur,Himachal Pradesh', 'lon': '77.406'},
      '193411': {'lat': '33.4857', 'name': 'Qumroo,Budgam,Jammu & Kashmir', 'lon': '74.661'},
      '848216': {'lat': '25.799', 'name': 'Bihrha,Samastipur,Bihar', 'lon': '85.9946'},
      '389180': {'lat': '23.093946', 'name': 'Parathampur,Dahod,Gujarat', 'lon': '74.164434'},
      '606103': {'lat': '11.4647', 'name': 'Kathazhai,Cuddalore,Tamil Nadu', 'lon': '79.4433'},
      '212503': {'lat': '25.652', 'name': 'Aruvaon,Allahabad,Uttar Pradesh', 'lon': '81.7639'},
      '741509': {'lat': '23.5544', 'name': 'Shibnibas,Nadia,West Bengal', 'lon': '88.3506'},
      '741508': {'lat': '24.2843', 'name': 'Joyghata,Nadia,West Bengal', 'lon': '87.9277'},
      '249161': {'lat': '30.027', 'name': 'Dharidhungsir,Tehri Garhwal,Uttarakhand', 'lon': '78.8102'},
      '741505': {'lat': '23.4597', 'name': 'Hanskhali,Nadia,West Bengal', 'lon': '88.2516'},
      '515521': {'lat': '14.0857', 'name': 'N.P.Kunta,Ananthapur,Andhra Pradesh', 'lon': '78.3077'},
      '741507': {'lat': '24.1392', 'name': 'Majdia,Nadia,West Bengal', 'lon': '87.8542'},
      '396115': {'lat': '20.4688', 'name': 'Kolak,Valsad,Gujarat', 'lon': '72.8614'},
      '741501': {'lat': '23.5544', 'name': 'Barbaria,Nadia,West Bengal', 'lon': '88.3506'},
      '396110': {'lat': '20.4688', 'name': 'Khaparvada,Navsari,Gujarat', 'lon': '72.8614'},
      '741502': {'lat': '23.3591', 'name': 'Santinagar,Nadia,West Bengal', 'lon': '88.6625'},
      '848211': {'lat': '25.5441', 'name': 'Mahathi,Samastipur,Bihar', 'lon': '85.7251'},
      '148021': {'lat': '30.3915', 'name': 'Rohira,Sangrur,Punjab', 'lon': '76.5507'},
      '848210': {'lat': '25.7684', 'name': 'Baidyanathpur,Samastipur,Bihar', 'lon': '85.9477'},
      '531133': {'lat': '17.8609', 'name': 'Pedavalasa,Visakhapatnam,Andhra Pradesh', 'lon': '82.2281'},
      '209304': {'lat': '26.5127', 'name': 'Fatehpur Roshnai,Kanpur Nagar,Uttar Pradesh', 'lon': '80.5703'},
      '249146': {'lat': '30.4374', 'name': 'Sain,Tehri Garhwal,Uttarakhand', 'lon': '78.537'},
      '389232': {'lat': '23.3583', 'name': 'Limdi Timba,Panch Mahals,Gujarat', 'lon': '73.6089'},
      '389230': {'lat': '23.2913', 'name': 'Naroda,Panch Mahals,Gujarat', 'lon': '73.5806'},
      '140601': {'lat': '30.5392', 'name': 'Jhansla,Patiala,Punjab', 'lon': '76.484'},
      '389235': {'lat': '23.235', 'name': 'Movasa,Panch Mahals,Gujarat', 'lon': '73.7348'},
      '249141': {'lat': '30.4374', 'name': 'Gadoli,Tehri Garhwal,Uttarakhand', 'lon': '78.537'},
      '251002': {'lat': '28.9809', 'name': 'Muzaffar Nagar name,Muzaffarnagar,Uttar Pradesh', 'lon': '77.6883'},
      '678671': {'lat': '10.7242', 'name': 'Vilayannur,Palakkad,Kerala', 'lon': '76.5247'},
      '635001': {'lat': '12.5758', 'name': 'Kattinayanapalli,Krishnagiri,Tamil Nadu', 'lon': '78.2512'},
      '741221': {'lat': '23.6845', 'name': 'Barajaguli,Nadia,West Bengal', 'lon': '87.4441'},
      '209305': {'lat': '26.6874', 'name': 'Bhauti Pratappur,Kanpur Nagar,Uttar Pradesh', 'lon': '79.9367'},
      '273308': {'lat': '27.2025', 'name': 'Sonauli,Maharajganj,Uttar Pradesh', 'lon': '83.5333'},
      '823311': {'lat': '25.1643', 'name': 'Birnoi,Gaya,Bihar', 'lon': '85.1824'},
      '466446': {'lat': '22.7378', 'name': 'Rehti,Sehore,Madhya Pradesh', 'lon': '77.434'},
      '273305': {'lat': '26.6344', 'name': 'Laxmipurkhurd,Maharajganj,Uttar Pradesh', 'lon': '82.4733'},
      '176059': {'lat': '32.1559', 'name': 'Jia,Kangra,Himachal Pradesh', 'lon': '76.4501'},
      '535594': {'lat': '18.7234', 'name': 'Tamarakhandi Jammu,Vizianagaram,Andhra Pradesh', 'lon': '83.5124'},
      '535593': {'lat': '18.6181', 'name': 'Kurukutti,Vizianagaram,Andhra Pradesh', 'lon': '83.1596'},
      '535592': {'lat': '18.5076', 'name': 'Gadivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.1444'},
      '535591': {'lat': '18.4349', 'name': 'Kothavalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.1367'},
      '741222': {'lat': '23.656', 'name': 'Lalpur (Nadia),Nadia,West Bengal', 'lon': '86.9557'},
      '176053': {'lat': '32.0807', 'name': 'Sihal,Kangra,Himachal Pradesh', 'lon': '75.9647'},
      '176052': {'lat': '32.1088', 'name': 'Paddar,Kangra,Himachal Pradesh', 'lon': '76.3177'},
      '176051': {'lat': '32.0908', 'name': 'Panjhara,Kangra,Himachal Pradesh', 'lon': '75.9811'},
      '209306': {'lat': '26.6055', 'name': 'Mahuwa Nogaon,Kanpur Dehat,Uttar Pradesh', 'lon': '79.8523'},
      '176057': {'lat': '32.1497', 'name': 'Pantehar,Kangra,Himachal Pradesh', 'lon': '76.3781'},
      '176056': {'lat': '32.1026', 'name': 'Serathana,Kangra,Himachal Pradesh', 'lon': '76.3365'},
      '176055': {'lat': '32.076', 'name': 'Thill,Kangra,Himachal Pradesh', 'lon': '76.1726'},
      '176054': {'lat': '32.0031', 'name': 'Jassi,Kangra,Himachal Pradesh', 'lon': '76.1646'},
      '690571': {'lat': '9.1067', 'name': 'Nooranad Sanatorium,Alappuzha,Kerala', 'lon': '76.5933'},
      '273301': {'lat': '26.9596', 'name': 'Dhanaha Naik,Gorakhpur,Uttar Pradesh', 'lon': '83.6418'},
      '690573': {'lat': '9.1067', 'name': 'S V Market,Kollam,Kerala', 'lon': '76.5933'},
      '690572': {'lat': '9.1067', 'name': 'Eruva,Alappuzha,Kerala', 'lon': '76.5933'},
      '690574': {'lat': '9.1067', 'name': 'Manappally North,Kollam,Kerala', 'lon': '76.5933'},
      '383030': {'lat': '23.1897', 'name': 'Dhundhar,Sabarkantha,Gujarat', 'lon': '73.1233'},
      '732204': {'lat': '25.1364', 'name': 'Goraksha,Malda,West Bengal', 'lon': '87.9752'},
      '732205': {'lat': '24.9399', 'name': 'Baharal,Malda,West Bengal', 'lon': '87.878'},
      '732206': {'lat': '24.99', 'name': 'Jamirghata Sarkarpara,Malda,West Bengal', 'lon': '87.9253'},
      '732207': {'lat': '24.2529', 'name': 'Debipur Achintala,Malda,West Bengal', 'lon': '88.039'},
      '791113': {'lat': '27.422', 'name': 'Chimpu,Lower Subansiri,Arunachal Pradesh', 'lon': '93.6719'},
      '732201': {'lat': '24.5656', 'name': 'Golapganj,Malda,West Bengal', 'lon': '87.9504'},
      '732202': {'lat': '24.5939', 'name': 'Manikchak,Malda,West Bengal', 'lon': '87.7973'},
      '732203': {'lat': '25.4031', 'name': 'Bakdukra Anantalalpur,Malda,West Bengal', 'lon': '87.8175'},
      '209307': {'lat': '26.9364', 'name': 'Bhool,Kanpur Dehat,Uttar Pradesh', 'lon': '80.1924'},
      '140901': {'lat': '30.8312', 'name': 'Jayanti Devi,Mohali,Punjab', 'lon': '76.3669'},
      '732209': {'lat': '24.9293', 'name': 'Sovanagar,Malda,West Bengal', 'lon': '87.9576'},
      '791119': {'lat': '27.422', 'name': 'Yazali,Lower Subansiri,Arunachal Pradesh', 'lon': '93.6719'},
      '791118': {'lat': '27.964', 'name': 'Sangram,Kurung Kumey,Arunachal Pradesh', 'lon': '93.5532'},
      '755018': {'lat': '20.8071', 'name': 'Atta,Jajapur,Odisha', 'lon': '85.6347'},
      '581103': {'lat': '15.4273', 'name': 'Kadabagatti,Dharwad,Karnataka', 'lon': '74.7411'},
      '458220': {'lat': '24.5006', 'name': 'Lasur,Neemuch,Madhya Pradesh', 'lon': '75.6673'},
      '151005': {'lat': '30.4262', 'name': 'Balluna,Bathinda,Punjab', 'lon': '74.8657'},
      '458113': {'lat': '23.876', 'name': 'Chaplana,Neemuch,Madhya Pradesh', 'lon': '75.9243'},
      '496227': {'lat': '22.5473', 'name': 'Singibahar,Jashpur,Chattisgarh', 'lon': '83.9569'},
      '465335': {'lat': '23.01', 'name': 'Amlay,Shajapur,Madhya Pradesh', 'lon': '76.6071'},
      '415807': {'lat': '17.951', 'name': 'Chafavali,Ratnagiri,Maharashtra', 'lon': '74.2244'},
      '575018': {'lat': '12.784', 'name': 'Derlakatte,Dakshina Kannada,Karnataka', 'lon': '74.9302'},
      '575019': {'lat': '12.9163', 'name': 'MSEZ Colony,Dakshina Kannada,Karnataka', 'lon': '74.9742'},
      '497224': {'lat': '23.3873', 'name': 'Rehda,Surguja,Chattisgarh', 'lon': '83.8933'},
      '755019': {'lat': '20.8115', 'name': 'Sobra,Jajapur,Odisha', 'lon': '85.4423'},
      '757100': {'lat': '21.4901', 'name': 'Dahikoti,Mayurbhanj,Odisha', 'lon': '85.7004'},
      '577124': {'lat': '13.2265', 'name': 'Ganganakodige,Chikkamagaluru,Karnataka', 'lon': '75.5593'},
      '575010': {'lat': '13.0232', 'name': 'Panambur,Dakshina Kannada,Karnataka', 'lon': '75.0845'},
      '127306': {'lat': '28.606', 'name': 'Paitawas Kalan,Bhiwani,Haryana', 'lon': '76.3017'},
      '575016': {'lat': '13.0232', 'name': 'Shakthinagar (Dakshina Kannada),Dakshina Kannada,Karnataka', 'lon': '75.0845'},
      '575017': {'lat': '13.25', 'name': 'Permannur,Dakshina Kannada,Karnataka', 'lon': '75.0633'},
      '575014': {'lat': '12.8382', 'name': 'Krishnapura,Dakshina Kannada,Karnataka', 'lon': '75.2667'},
      '575015': {'lat': '13.0232', 'name': 'Kavur,Dakshina Kannada,Karnataka', 'lon': '75.0845'},
      '442918': {'lat': '19.9813', 'name': 'Pombhurna,Chandrapur,Maharashtra', 'lon': '79.57'},
      '442919': {'lat': '19.7118', 'name': 'Kalinagar,Gadchiroli,Maharashtra', 'lon': '79.9526'},
      '209301': {'lat': '26.5399', 'name': 'Baalai Bujurg,Kanpur Dehat,Uttar Pradesh', 'lon': '79.7643'},
      '534460': {'lat': '16.9821', 'name': 'Kanthampalem,West Godavari,Andhra Pradesh', 'lon': '81.0731'},
      '534461': {'lat': '17.0111', 'name': 'Nagireddigudem,West Godavari,Andhra Pradesh', 'lon': '81.0541'},
      '534462': {'lat': '16.9294', 'name': 'K.Gokavaram,West Godavari,Andhra Pradesh', 'lon': '81.0221'},
      '679106': {'lat': '10.7089', 'name': 'South Kondazhi,Thrissur,Kerala', 'lon': '76.4053'},
      '679101': {'lat': '10.7426', 'name': 'Ottapalam H.O,Palakkad,Kerala', 'lon': '76.3997'},
      '442917': {'lat': '20.0571', 'name': 'Awalpur Cement Project,Chandrapur,Maharashtra', 'lon': '79.3701'},
      '679103': {'lat': '10.7678', 'name': 'Sreeramakrishnanagar,Palakkad,Kerala', 'lon': '76.4103'},
      '534467': {'lat': '17.1003', 'name': 'T.Narasapuram,West Godavari,Andhra Pradesh', 'lon': '81.1173'},
      '622001': {'lat': '10.3559', 'name': 'Pudukkottai Pub Offices,Pudukkottai,Tamil Nadu', 'lon': '78.6876'},
      '364120': {'lat': '22.0543', 'name': 'Kukad,Bhavnagar,Gujarat', 'lon': '72.7525'},
      '622003': {'lat': '10.3416', 'name': 'Aharapatti,Pudukkottai,Tamil Nadu', 'lon': '78.6775'},
      '622004': {'lat': '10.4205', 'name': 'Mullur,Pudukkottai,Tamil Nadu', 'lon': '78.8588'},
      '622005': {'lat': '10.3559', 'name': 'SanthaNatha puram,Pudukkottai,Tamil Nadu', 'lon': '78.6876'},
      '628722': {'lat': '8.9299', 'name': 'Melaseithalai,Tuticorin,Tamil Nadu', 'lon': '78.0872'},
      '628721': {'lat': '9.305', 'name': 'Vanaramutti,Tuticorin,Tamil Nadu', 'lon': '77.8169'},
      '628720': {'lat': '9.7803', 'name': 'Mudukkalankulam,Tuticorin,Tamil Nadu', 'lon': '78.0192'},
      '799113': {'lat': '23.745127', 'name': 'East Bagabassa,Gomti,Tripura', 'lon': '91.746826'},
      '700076': {'lat': '22.6557', 'name': 'Dakshineswar,North 24 Parganas,West Bengal', 'lon': '88.3672'},
      '700077': {'lat': '22.588', 'name': 'Bediapara,Kolkata,West Bengal', 'lon': '88.3185'},
      '700074': {'lat': '22.588', 'name': 'Jawpore,Kolkata,West Bengal', 'lon': '88.3185'},
      '342011': {'lat': '26.281', 'name': 'Jodhpur Residency Road,Jodhpur,Rajasthan', 'lon': '73.0293'},
      '122009': {'lat': '28.418', 'name': 'Galleria DLF-IV,Gurgaon,Haryana', 'lon': '76.9839'},
      '122008': {'lat': '28.418', 'name': 'DLF Ph-II,Gurgaon,Haryana', 'lon': '76.9839'},
      '700070': {'lat': '22.588', 'name': 'Bansdroni,South 24 Parganas,West Bengal', 'lon': '88.3185'},
      '700071': {'lat': '22.588', 'name': 'Middleton Row,Kolkata,West Bengal', 'lon': '88.3185'},
      '122005': {'lat': '28.418', 'name': 'Air Force,Gurgaon,Haryana', 'lon': '76.9839'},
      '122004': {'lat': '28.4077', 'name': 'Sikohpur,Gurgaon,Haryana', 'lon': '76.9836'},
      '122007': {'lat': '28.418', 'name': 'Industrial Estate (Gurgaon),Gurgaon,Haryana', 'lon': '76.9839'},
      '122006': {'lat': '28.4592', 'name': 'Daultabad,Gurgaon,Haryana', 'lon': '76.985'},
      '122001': {'lat': '28.418', 'name': 'Shivaji Nagar (Gurgaon),Gurgaon,Haryana', 'lon': '76.9839'},
      '844506': {'lat': '25.5821', 'name': 'Hetanpur,Samastipur,Bihar', 'lon': '85.5495'},
      '122003': {'lat': '28.418', 'name': 'Gwal Pahari,Gurgaon,Haryana', 'lon': '76.9839'},
      '122002': {'lat': '28.418', 'name': 'DLF QE,Gurgaon,Haryana', 'lon': '76.9839'},
      '222125': {'lat': '25.917', 'name': 'Pyareypur Bijhawat,Jaunpur,Uttar Pradesh', 'lon': '82.4668'},
      '524129': {'lat': '13.8136', 'name': 'Sirasanambedu,Nellore,Andhra Pradesh', 'lon': '79.7747'},
      '614018': {'lat': '10.2692', 'name': 'Melanemmeli,Tiruvarur,Tamil Nadu', 'lon': '79.2372'},
      '614019': {'lat': '10.7037', 'name': 'Talayamangalam(VDR),Thanjavur,Tamil Nadu', 'lon': '79.2922'},
      '524121': {'lat': '14.2839', 'name': 'Mambathi,Nellore,Andhra Pradesh', 'lon': '79.8235'},
      '524123': {'lat': '13.811', 'name': 'Anepudi,Nellore,Andhra Pradesh', 'lon': '79.9964'},
      '524124': {'lat': '13.641', 'name': 'Phase-Ii,Nellore,Andhra Pradesh', 'lon': '80.1301'},
      '614017': {'lat': '10.4396', 'name': 'Kaniyakurichi,Thanjavur,Tamil Nadu', 'lon': '78.3263'},
      '524126': {'lat': '13.8817', 'name': 'Kanururajupalem,Nellore,Andhra Pradesh', 'lon': '79.8339'},
      '524127': {'lat': '14.0748', 'name': 'Chittamur,Nellore,Andhra Pradesh', 'lon': '79.9815'},
      '209303': {'lat': '26.676', 'name': 'Tigaei,Kanpur Dehat,Uttar Pradesh', 'lon': '80.0705'},
      '741249': {'lat': '22.7611', 'name': 'Panpur,Nadia,West Bengal', 'lon': '88.7615'},
      '146115': {'lat': '31.5307', 'name': 'Begumpur Jandiala,Nawanshahr,Punjab', 'lon': '75.8131'},
      '146114': {'lat': '31.5822', 'name': 'Nandachaur,Hoshiarpur,Punjab', 'lon': '75.7418'},
      '146116': {'lat': '31.6229', 'name': 'Sarhala Mundian,Hoshiarpur,Punjab', 'lon': '75.705'},
      '146111': {'lat': '31.4623', 'name': 'Bathian Brahmnan,Hoshiarpur,Punjab', 'lon': '75.8422'},
      '146110': {'lat': '31.5131', 'name': 'Kharkan Camp,Hoshiarpur,Punjab', 'lon': '75.9834'},
      '146113': {'lat': '31.608', 'name': 'Dhade Fateh Singh,Hoshiarpur,Punjab', 'lon': '75.7746'},
      '146112': {'lat': '31.4178', 'name': 'Rajpur Bhayan,Hoshiarpur,Punjab', 'lon': '75.8864'},
      '752109': {'lat': '20.3205', 'name': 'Jharling,Puri,Odisha', 'lon': '85.5049'},
      '752108': {'lat': '20.444', 'name': 'Krushnanagarapatna,Puri,Odisha', 'lon': '86.2824'},
      '752101': {'lat': '20.2794', 'name': 'Pahal,Khorda,Odisha', 'lon': '85.5584'},
      '752100': {'lat': '20.4148', 'name': 'Brahman Suanlo,Khorda,Odisha', 'lon': '85.5687'},
      '752103': {'lat': '20.4637', 'name': 'Majhihara,Khorda,Odisha', 'lon': '85.2916'},
      '752102': {'lat': '20.5201', 'name': 'Mahukhanda,Khorda,Odisha', 'lon': '85.3966'},
      '752105': {'lat': '20.0347', 'name': 'Nuagopalpur,Puri,Odisha', 'lon': '85.8799'},
      '752104': {'lat': '20.1529', 'name': 'Givindpur,Puri,Odisha', 'lon': '85.4092'},
      '752107': {'lat': '20.4148', 'name': 'Sorava,Puri,Odisha', 'lon': '85.5687'},
      '752106': {'lat': '21.8355', 'name': 'Bhogasalada,Puri,Odisha', 'lon': '85.551'},
      '225401': {'lat': '27.0636', 'name': 'Fattapur Kalan,Barabanki,Uttar Pradesh', 'lon': '81.0746'},
      '343032': {'lat': '24.7432', 'name': 'Nandia,Jalor,Rajasthan', 'lon': '73.456'},
      '225403': {'lat': '26.9386', 'name': 'Itaura,Barabanki,Uttar Pradesh', 'lon': '81.3274'},
      '343030': {'lat': '25.1108', 'name': 'Jaitu,Jalor,Rajasthan', 'lon': '72.6699'},
      '741246': {'lat': '23.4733', 'name': 'Haringhat River Research Insti,Nadia,West Bengal', 'lon': '88.1624'},
      '225404': {'lat': '26.8472', 'name': 'Neoli Danapur,Barabanki,Uttar Pradesh', 'lon': '81.5865'},
      '643004': {'lat': '11.3812', 'name': 'Mullikorai,Nilgiris,Tamil Nadu', 'lon': '76.6641'},
      '225409': {'lat': '26.7578', 'name': 'Kithaiya,Barabanki,Uttar Pradesh', 'lon': '81.5499'},
      '343039': {'lat': '24.7787', 'name': 'Surajwara,Jalor,Rajasthan', 'lon': '72.2868'},
      '495690': {'lat': '21.8307', 'name': 'Jaijaipur,Janjgir-champa,Chattisgarh', 'lon': '82.8172'},
      '500100': {'lat': '17.5902', 'name': 'Pochampally,K.V.Rangareddy,Telangana', 'lon': '78.6668'},
      '643001': {'lat': '11.4167', 'name': 'Charing Cross,Nilgiris,Tamil Nadu', 'lon': '76.6863'},
      '691566': {'lat': '9.0356', 'name': 'Pallickal-kottarakara,Kollam,Kerala', 'lon': '76.7623'},
      '691560': {'lat': '9.0584', 'name': 'Thamarakudy,Kollam,Kerala', 'lon': '76.7776'},
      '495334': {'lat': '21.9925', 'name': 'Puran,Bilaspur(CGH),Chattisgarh', 'lon': '81.6953'},
      '388465': {'lat': '22.5117', 'name': 'Palaj,Anand,Gujarat', 'lon': '72.7812'},
      '843334': {'lat': '26.158', 'name': 'B. Chandiha,Sitamarhi,Bihar', 'lon': '85.8601'},
      '202280': {'lat': '27.9824', 'name': 'Kalyanpur Rani,Aligarh,Uttar Pradesh', 'lon': '78.3122'},
      '388460': {'lat': '22.5411', 'name': 'Piplav,Anand,Gujarat', 'lon': '72.7635'},
      '202282': {'lat': '28.0811', 'name': 'Malviya Nagar (Aligarh),Aligarh,Uttar Pradesh', 'lon': '78.2505'},
      '500105': {'lat': '17.5902', 'name': 'Vengal Rao Nagar,Hyderabad,Telangana', 'lon': '78.6668'},
      '457226': {'lat': '22.9245', 'name': 'Jaora Choupati,Ratlam,Madhya Pradesh', 'lon': '75.0161'},
      '504272': {'lat': '17.9376', 'name': 'Tandur (A),Adilabad,Telangana', 'lon': '78.0582'},
      '712702': {'lat': '22.6932', 'name': 'Naity,Hooghly,West Bengal', 'lon': '88.2579'},
      '505467': {'lat': '18.3307', 'name': 'Sunderagiri,Karim Nagar,Telangana', 'lon': '79.3769'},
      '144606': {'lat': '31.386', 'name': 'Talwandi Chaudharian,Kapurthala,Punjab', 'lon': '75.2792'},
      '487001': {'lat': '22.9398', 'name': 'Kamtada,Narsinghpur,Madhya Pradesh', 'lon': '79.2539'},
      '695019': {'lat': '8.9043', 'name': 'Industrial Estate (Thiruvananthapuram),Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695018': {'lat': '8.9043', 'name': 'Pappanamcode,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '250341': {'lat': '29.0232', 'name': 'B. N. Nanu,Meerut,Uttar Pradesh', 'lon': '77.614'},
      '621209': {'lat': '10.9764', 'name': 'Thiruvengimalai,Tiruchirappalli,Tamil Nadu', 'lon': '78.3805'},
      '621208': {'lat': '11.276', 'name': 'Poolancheri,Tiruchirappalli,Tamil Nadu', 'lon': '78.5395'},
      '695011': {'lat': '8.9043', 'name': 'Ulloor,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695010': {'lat': '8.9043', 'name': 'TVM  R.K Mission,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695013': {'lat': '8.9043', 'name': 'Cheriyakonni,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695012': {'lat': '8.9043', 'name': 'Poojapura H.O,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695015': {'lat': '8.9043', 'name': 'Nalanchira,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695014': {'lat': '8.9043', 'name': 'Jagathy,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695017': {'lat': '8.9043', 'name': 'Sreekaryam,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '695016': {'lat': '8.9043', 'name': 'Thiruvananthapuram  Engg College,Thiruvananthapuram,Kerala', 'lon': '76.7984'},
      '174103': {'lat': '30.9029', 'name': 'Barotiwala,Solan,Himachal Pradesh', 'lon': '76.8349'},
      '174102': {'lat': '32.0284', 'name': 'Chhiachi,Solan,Himachal Pradesh', 'lon': '76.435'},
      '174101': {'lat': '31.1624', 'name': 'Galote,Solan,Himachal Pradesh', 'lon': '76.715'},
      '561211': {'lat': '13.2001', 'name': 'Varavani,Chikkaballapur,Karnataka', 'lon': '77.819'},
      '561210': {'lat': '13.5462', 'name': 'Haleupparahalli,Chikkaballapur,Karnataka', 'lon': '77.211'},
      '561213': {'lat': '13.6904', 'name': 'Thondebhavi Old Town,Chikkaballapur,Karnataka', 'lon': '77.1411'},
      '561212': {'lat': '13.776', 'name': 'Tholapalli,Chikkaballapur,Karnataka', 'lon': '78.0035'},
      '741245': {'lat': '23.7107', 'name': 'Saguna,Nadia,West Bengal', 'lon': '87.9627'},
      '560068': {'lat': '13.2257', 'name': 'Madivala,Bengaluru,Karnataka', 'lon': '77.575'},
      '843323': {'lat': '26.1079', 'name': 'B. Dhanushi,Sitamarhi,Bihar', 'lon': '85.8794'},
      '712258': {'lat': '22.6608', 'name': 'Uttarpara,Hooghly,West Bengal', 'lon': '88.3471'},
      '560061': {'lat': '12.9881', 'name': 'Subramanyapura,Bengaluru,Karnataka', 'lon': '77.5052'},
      '560060': {'lat': '13.1089', 'name': 'Chudenapura,Bengaluru,Karnataka', 'lon': '76.8949'},
      '560063': {'lat': '12.9881', 'name': 'BSF Campus Yelahanka,Bengaluru,Karnataka', 'lon': '77.5052'},
      '560062': {'lat': '12.9881', 'name': 'Doddakallasandra,Bengaluru,Karnataka', 'lon': '77.5052'},
      '571812': {'lat': '12.6887', 'name': 'Bachahalli,Mandya,Karnataka', 'lon': '76.5021'},
      '560064': {'lat': '13.2257', 'name': 'Yelahanka,Bengaluru,Karnataka', 'lon': '77.575'},
      '560067': {'lat': '12.9833', 'name': 'Kadugodi,Bengaluru,Karnataka', 'lon': '77.5833'},
      '571811': {'lat': '12.6036', 'name': 'Chunchanahalli,Mandya,Karnataka', 'lon': '76.3153'},
      '523271': {'lat': '15.4895', 'name': 'Chirrikurapadu,Prakasam,Andhra Pradesh', 'lon': '79.8507'},
      '523270': {'lat': '15.4671', 'name': 'Kuchipudi,Prakasam,Andhra Pradesh', 'lon': '79.6631'},
      '523273': {'lat': '15.5321', 'name': 'Vemulapadu,Prakasam,Andhra Pradesh', 'lon': '79.3402'},
      '523272': {'lat': '16.139', 'name': 'Konizedu,Prakasam,Andhra Pradesh', 'lon': '80.2731'},
      '394155': {'lat': '22.0467', 'name': 'Ghala,Surat,Gujarat', 'lon': '73.1181'},
      '523279': {'lat': '15.277', 'name': 'Paletipadu,Prakasam,Andhra Pradesh', 'lon': '79.3569'},
      '394405': {'lat': '21.4333', 'name': 'Hathoda,Surat,Gujarat', 'lon': '73.0167'},
      '813201': {'lat': '25.1793', 'name': 'Mamai,Munger,Bihar', 'lon': '86.6739'},
      '689652': {'lat': '9.265', 'name': 'Punnakkad,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689653': {'lat': '9.265', 'name': 'Musaliar College,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689650': {'lat': '9.265', 'name': 'Kattoor Kozhencherry,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689656': {'lat': '9.265', 'name': 'Vallicode Kottayam,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '813202': {'lat': '25.0641', 'name': 'Manjhili Matihani,Banka,Bihar', 'lon': '86.5219'},
      '689654': {'lat': '9.265', 'name': 'Kozhencherry  Thekkemala,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '571125': {'lat': '12.1606', 'name': 'Hyrige,Mysuru,Karnataka', 'lon': '76.4158'},
      '813203': {'lat': '25.3925', 'name': 'Sangitbaita,Bhagalpur,Bihar', 'lon': '87.3119'},
      '585237': {'lat': '16.3748', 'name': 'Bapparga,Yadgir,Karnataka', 'lon': '76.387'},
      '360110': {'lat': '22.4354', 'name': 'Makajimeghpar,Jamnagar,Gujarat', 'lon': '70.5404'},
      '224208': {'lat': '26.4201', 'name': 'Sonbarsa,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '224209': {'lat': '26.4734', 'name': 'Sulhepur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.1585'},
      '620007': {'lat': '10.7339', 'name': 'Tiruchirappalli Airport,Tiruchirappalli,Tamil Nadu', 'lon': '78.7184'},
      '224204': {'lat': '26.6825', 'name': 'Sohawal Saloni,Faizabad,Uttar Pradesh', 'lon': '82.19'},
      '224205': {'lat': '26.5318', 'name': 'Dahema,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.3152'},
      '802128': {'lat': '25.416', 'name': 'Chaubey Ke Prasia,Buxar,Bihar', 'lon': '83.9583'},
      '637019': {'lat': '11.1695', 'name': 'Avalnaickenpatti,Namakkal,Tamil Nadu', 'lon': '78.0978'},
      '224201': {'lat': '26.4201', 'name': 'Pora,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '209881': {'lat': '26.4248', 'name': 'Shahpur Tonda,Unnao,Uttar Pradesh', 'lon': '81.6338'},
      '224203': {'lat': '26.2975', 'name': 'Taarun Blgara,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.2912'},
      '450001': {'lat': '21.9312', 'name': 'Amulla,Burhanpur,Madhya Pradesh', 'lon': '76.1454'},
      '811307': {'lat': '24.9282', 'name': 'Jamui Court,Jamui,Bihar', 'lon': '86.1992'},
      '126125': {'lat': '29.5498', 'name': 'Kuchrana Kalan,Jind,Haryana', 'lon': '76.4071'},
      '273013': {'lat': '26.8162', 'name': 'Jingle Ayodhayay Prasad,Gorakhpur,Uttar Pradesh', 'lon': '83.4488'},
      '136129': {'lat': '30.1084', 'name': 'Thaska Miranji,Kurukshetra,Haryana', 'lon': '76.695'},
      '136128': {'lat': '30.0285', 'name': 'Murtzapur,Kurukshetra,Haryana', 'lon': '76.6223'},
      '600039': {'lat': '13.1111', 'name': 'Vyasarpadi,Chennai,Tamil Nadu', 'lon': '80.2647'},
      '500110': {'lat': '17.5902', 'name': 'Prashasan Nagar,Hyderabad,Telangana', 'lon': '78.6668'},
      '600038': {'lat': '13.0659', 'name': 'Icf Colony,Chennai,Tamil Nadu', 'lon': '80.2365'},
      '272172': {'lat': '26.6339', 'name': 'Chhitahi,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.962'},
      '250345': {'lat': '29.094', 'name': 'Titroda,Bagpat,Uttar Pradesh', 'lon': '77.3788'},
      '202141': {'lat': '27.9787', 'name': 'Narainpur,Aligarh,Uttar Pradesh', 'lon': '77.7441'},
      '605301': {'lat': '11.9379', 'name': 'Nannadu,Villupuram,Tamil Nadu', 'lon': '79.4581'},
      '131409': {'lat': '29.0247', 'name': 'Riwara,Sonipat,Haryana', 'lon': '76.7686'},
      '131408': {'lat': '29.1613', 'name': 'Garhi Sisana ED,Sonipat,Haryana', 'lon': '76.674'},
      '202140': {'lat': '27.9639', 'name': 'Rait,Aligarh,Uttar Pradesh', 'lon': '77.9542'},
      '712409': {'lat': '23.246', 'name': 'Karicharbheri,Hooghly,West Bengal', 'lon': '88.1275'},
      '713166': {'lat': '23.6359', 'name': 'Abujhati,Purba Bardhaman,West Bengal', 'lon': '87.9371'},
      '131403': {'lat': '29.0617', 'name': 'Badhana,Sonipat,Haryana', 'lon': '76.8795'},
      '131402': {'lat': '28.8775', 'name': 'Pehladpur Kiroli,Sonipat,Haryana', 'lon': '76.9123'},
      '415802': {'lat': '17.951', 'name': 'Kochari,Ratnagiri,Maharashtra', 'lon': '74.2244'},
      '637203': {'lat': '11.2366', 'name': 'Sullipalayam,Namakkal,Tamil Nadu', 'lon': '77.9766'},
      '202146': {'lat': '27.9374', 'name': 'Mangalayatan University,Aligarh,Uttar Pradesh', 'lon': '77.9299'},
      '233225': {'lat': '25.7357', 'name': 'Hartmanpur,Ghazipur,Uttar Pradesh', 'lon': '83.8621'},
      '516439': {'lat': '14.9127', 'name': 'Mylavaram (Cuddapah),Cuddapah,Andhra Pradesh', 'lon': '78.3818'},
      '415804': {'lat': '17.4898', 'name': 'Sangave,Ratnagiri,Maharashtra', 'lon': '73.5155'},
      '516434': {'lat': '14.8158', 'name': 'Gandikota,Cuddapah,Andhra Pradesh', 'lon': '78.2863'},
      '516433': {'lat': '14.9449', 'name': 'Talamanchipatnam,Cuddapah,Andhra Pradesh', 'lon': '78.3114'},
      '192212': {'lat': '33.9509', 'name': 'Nowgam,Ananthnag,Jammu & Kashmir', 'lon': '75.0932'},
      '192211': {'lat': '33.8159', 'name': 'Kriri Nowpora,Ananthnag,Jammu & Kashmir', 'lon': '75.1293'},
      '192210': {'lat': '33.6809', 'name': 'Fatehpora,Ananthnag,Jammu & Kashmir', 'lon': '75.1654'},
      '425308': {'lat': '21.0255', 'name': 'Varangaon Ord.Factory,Jalgaon,Maharashtra', 'lon': '75.9684'},
      '425309': {'lat': '21.0017', 'name': 'Nashirabad,Jalgaon,Maharashtra', 'lon': '75.6971'},
      '770011': {'lat': '21.9025', 'name': 'Nialipali,Sundergarh,Odisha', 'lon': '84.0279'},
      '201206': {'lat': '28.7713', 'name': 'Bhadoli,Ghaziabad,Uttar Pradesh', 'lon': '77.4311'},
      '770017': {'lat': '21.511', 'name': 'Buchukupara,Sundergarh,Odisha', 'lon': '84.2702'},
      '770016': {'lat': '21.7179', 'name': 'Bargaon (Sundergarh),Sundergarh,Odisha', 'lon': '84.1661'},
      '770015': {'lat': '22.1285', 'name': 'Tileikani,Sundergarh,Odisha', 'lon': '84.0417'},
      '770014': {'lat': '21.8346', 'name': 'Kusumura,Sundergarh,Odisha', 'lon': '84.0046'},
      '342801': {'lat': '26.0286', 'name': 'Rohicha Kallan,Jodhpur,Rajasthan', 'lon': '73.5797'},
      '425301': {'lat': '21.1496', 'name': 'Korpavalli,Jalgaon,Maharashtra', 'lon': '75.7311'},
      '425302': {'lat': '21.0754', 'name': 'Manvel,Jalgaon,Maharashtra', 'lon': '75.7332'},
      '425303': {'lat': '21.2477', 'name': 'Bidgaon,Jalgaon,Maharashtra', 'lon': '75.5188'},
      '425304': {'lat': '21.2371', 'name': 'Borkheda,Jalgaon,Maharashtra', 'lon': '76.0564'},
      '425305': {'lat': '20.9983', 'name': 'Fulgaon,Jalgaon,Maharashtra', 'lon': '75.9235'},
      '425306': {'lat': '20.7805', 'name': 'Melsangve,Jalgaon,Maharashtra', 'lon': '75.6926'},
      '425307': {'lat': '21.028', 'name': 'Khadke,Jalgaon,Maharashtra', 'lon': '75.7155'},
      '243301': {'lat': '28.1495', 'name': 'Kasumara,Bareilly,Uttar Pradesh', 'lon': '79.1372'},
      '502296': {'lat': '17.6598', 'name': 'Kasala,Medak,Telangana', 'lon': '78.2406'},
      '466445': {'lat': '22.7702', 'name': 'Budni Ghat,Sehore,Madhya Pradesh', 'lon': '77.6835'},
      '500035': {'lat': '17.3939', 'name': 'Huda Residential Complex,K.V.Rangareddy,Telangana', 'lon': '78.4529'},
      '796321': {'lat': '23.5424', 'name': 'Sesih,Champhai,Mizoram', 'lon': '93.3329'},
      '796320': {'lat': '23.079', 'name': 'Vangchhia,Champhai,Mizoram', 'lon': '93.2954'},
      '765013': {'lat': '19.5423', 'name': 'Goudaguda,Koraput,Odisha', 'lon': '83.103'},
      '765015': {'lat': '19.2806', 'name': 'Bilamala,Rayagada,Odisha', 'lon': '83.1046'},
      '600037': {'lat': '13.0659', 'name': 'Mogappair West,Tiruvallur,Tamil Nadu', 'lon': '80.2365'},
      '765017': {'lat': '19.2924273', 'name': 'Majhiguda,Nabarangapur,Odisha', 'lon': '83.1201356'},
      '765016': {'lat': '20.0691', 'name': 'Korapa,Rayagada,Odisha', 'lon': '83.8471'},
      '765019': {'lat': '19.436', 'name': 'Jhigidi,Rayagada,Odisha', 'lon': '83.5103'},
      '765018': {'lat': '19.4365', 'name': 'Paikapada,Rayagada,Odisha', 'lon': '83.5896'},
      '600036': {'lat': '13.0659', 'name': 'Indian Institute Of Technology,Chennai,Tamil Nadu', 'lon': '80.2365'},
      '532430': {'lat': '18.4135', 'name': 'Runku Hanumanthapuram,Srikakulam,Andhra Pradesh', 'lon': '83.6814'},
      '515201': {'lat': '13.8197', 'name': 'Hindupur Extns,Ananthapur,Andhra Pradesh', 'lon': '77.5428'},
      '744103': {'lat': '11.7167', 'name': 'Wandoor,South Andaman,Andaman & Nicobar Islands', 'lon': '92.6167'},
      '744101': {'lat': '11.6647', 'name': 'Marine Jetty,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7026'},
      '744106': {'lat': '11.6588', 'name': 'Shadipur,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7438'},
      '744107': {'lat': '11.6647', 'name': 'Stewertgunj,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7026'},
      '744104': {'lat': '11.6647', 'name': 'Neil Island,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7026'},
      '744105': {'lat': '11.6', 'name': 'Garacharama,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7333'},
      '312620': {'lat': '24.6265', 'name': 'Bhawaliya,Chittorgarh,Rajasthan', 'lon': '75.1512'},
      '623514': {'lat': '9.4776', 'name': 'Madavanur,Ramanathapuram,Tamil Nadu', 'lon': '78.8956'},
      '312622': {'lat': '24.7737', 'name': 'Adityapuram,Chittorgarh,Rajasthan', 'lon': '74.756'},
      '532432': {'lat': '18.4925', 'name': 'Gotivada,Srikakulam,Andhra Pradesh', 'lon': '83.9909'},
      '312624': {'lat': '25.189', 'name': 'Dunglawani,Chittorgarh,Rajasthan', 'lon': '74.2209'},
      '312625': {'lat': '25.3912', 'name': 'Dhariawad,Chittorgarh,Rajasthan', 'lon': '75.6524'},
      '312626': {'lat': '24.0423', 'name': 'Tothada,Udaipur,Rajasthan', 'lon': '74.5012'},
      '312627': {'lat': '25.1311', 'name': 'Kunwaliya,Chittorgarh,Rajasthan', 'lon': '74.71'},
      '835217': {'lat': '23.4553', 'name': 'Irba,Ranchi,Jharkhand', 'lon': '85.4404'},
      '201304': {'lat': '28.517', 'name': 'Chhaprauli Bengar,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.8097'},
      '835215': {'lat': '23.4339', 'name': 'Mesra,Ranchi,Jharkhand', 'lon': '85.4152'},
      '835214': {'lat': '23.1023', 'name': 'Sona,Gumla,Jharkhand', 'lon': '84.5347'},
      '835213': {'lat': '23.5299', 'name': 'Chiri,Lohardaga,Jharkhand', 'lon': '84.7495'},
      '835212': {'lat': '22.5663', 'name': 'Konjoba,Simdega,Jharkhand', 'lon': '84.1092'},
      '835211': {'lat': '22.5801', 'name': 'Domtoli,Simdega,Jharkhand', 'lon': '84.5664'},
      '201305': {'lat': '28.5401', 'name': 'Nepz Post Office,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.4077'},
      '313201': {'lat': '24.6985', 'name': 'Ghasa,Udaipur,Rajasthan', 'lon': '73.8372'},
      '229305': {'lat': '26.1507', 'name': 'Jais name,Raebareli,Uttar Pradesh', 'lon': '81.5809'},
      '835218': {'lat': '23.4746', 'name': 'Dumarpath,Gumla,Jharkhand', 'lon': '84.2678'},
      '400082': {'lat': '19.1247', 'name': 'Mulund Colony,Mumbai,Maharashtra', 'lon': '72.9488'},
      '400083': {'lat': '19.1247', 'name': 'Kannamwar Nagar,Mumbai,Maharashtra', 'lon': '72.9488'},
      '400080': {'lat': '19.176', 'name': 'Nehru Road (Mumbai),Mumbai,Maharashtra', 'lon': '72.9522'},
      '400081': {'lat': '18.9596', 'name': 'Mulund East,Mumbai,Maharashtra', 'lon': '72.8496'},
      '400086': {'lat': '19.1247', 'name': 'Sahakar Bhavan,Mumbai,Maharashtra', 'lon': '72.9488'},
      '400087': {'lat': '19.1247', 'name': 'NITIE,Mumbai,Maharashtra', 'lon': '72.9488'},
      '400084': {'lat': '19.1247', 'name': 'Barve Nagar,Mumbai,Maharashtra', 'lon': '72.9488'},
      '400085': {'lat': '19.1247', 'name': 'BARC,Mumbai,Maharashtra', 'lon': '72.9488'},
      '686635': {'lat': '9.669', 'name': 'Kudakkachira,Kottayam,Kerala', 'lon': '76.5592'},
      '686634': {'lat': '9.669', 'name': 'Kurichithanam,Kottayam,Kerala', 'lon': '76.5592'},
      '686637': {'lat': '9.669', 'name': 'Peroor,Kottayam,Kerala', 'lon': '76.5592'},
      '686636': {'lat': '9.669', 'name': 'Monippally,Kottayam,Kerala', 'lon': '76.5592'},
      '686631': {'lat': '9.669', 'name': 'Pattithanam,Kottayam,Kerala', 'lon': '76.5592'},
      '686630': {'lat': '9.669', 'name': 'Thellakom,Kottayam,Kerala', 'lon': '76.5592'},
      '686633': {'lat': '9.669', 'name': 'Kuravilangadu,Kottayam,Kerala', 'lon': '76.5592'},
      '686632': {'lat': '9.669', 'name': 'Kothanallur,Kottayam,Kerala', 'lon': '76.5592'},
      '829119': {'lat': '23.5874', 'name': 'Hariharpur,Ramgarh,Jharkhand', 'lon': '85.2917'},
      '145022': {'lat': '32.4429', 'name': 'Dunera,Pathankot,Punjab', 'lon': '75.8919'},
      '145023': {'lat': '32.3477', 'name': 'Sujanpur (Gurdaspur),Pathankot,Punjab', 'lon': '75.6409'},
      '522603': {'lat': '16.2579', 'name': 'Ravipadu (Guntur),Guntur,Andhra Pradesh', 'lon': '79.9256'},
      '145025': {'lat': '32.275', 'name': 'Malakpur,Pathankot,Punjab', 'lon': '75.5877'},
      '522601': {'lat': '16.2579', 'name': 'Prakashnagar,Guntur,Andhra Pradesh', 'lon': '79.9256'},
      '145027': {'lat': '32.286', 'name': 'Bamial,Pathankot,Punjab', 'lon': '75.3752'},
      '145029': {'lat': '32.4004', 'name': 'Shahpur Kandi Township,Pathankot,Punjab', 'lon': '75.7217'},
      '396580': {'lat': '23.8473', 'name': 'Ghodmal,Navsari,Gujarat', 'lon': '73.1904'},
      '533440': {'lat': '16.5068', 'name': 'Samalkot H.O,East Godavari,Andhra Pradesh', 'lon': '81.0652'},
      '533447': {'lat': '16.8854', 'name': 'Kothapalli (U),East Godavari,Andhra Pradesh', 'lon': '80.8734'},
      '533446': {'lat': '17.3238', 'name': 'Santhapydipala,East Godavari,Andhra Pradesh', 'lon': '82.3444'},
      '533445': {'lat': '17.0612', 'name': 'Gollaprolu,East Godavari,Andhra Pradesh', 'lon': '81.4922'},
      '533444': {'lat': '17.226', 'name': 'Chendurthi,East Godavari,Andhra Pradesh', 'lon': '82.064'},
      '472115': {'lat': '24.9683', 'name': 'Pathrguwa,Tikamgarh,Madhya Pradesh', 'lon': '79.1605'},
      '462037': {'lat': '23.2519', 'name': 'Peoples Campus,Bhopal,Madhya Pradesh', 'lon': '77.8742'},
      '533449': {'lat': '17.0182', 'name': 'Kothaperumallapuram,East Godavari,Andhra Pradesh', 'lon': '81.8707'},
      '533448': {'lat': '17.0833', 'name': 'Uppada,East Godavari,Andhra Pradesh', 'lon': '82.3333'},
      '472111': {'lat': '25.0722', 'name': 'Nayagaon,Tikamgarh,Madhya Pradesh', 'lon': '79.1924'},
      '743355': {'lat': '22.6563', 'name': 'Maitirhat,South 24 Parganas,West Bengal', 'lon': '88.2423'},
      '829118': {'lat': '23.6657', 'name': 'Patratu Diesel Colony ND,Ramgarh,Jharkhand', 'lon': '85.2924'},
      '764055': {'lat': '18.8538149', 'name': 'Kusumi,Nabarangapur,Odisha', 'lon': '82.566072'},
      '424307': {'lat': '21.0676', 'name': 'Burzad,Dhule,Maharashtra', 'lon': '74.6791'},
      '424306': {'lat': '20.2704', 'name': 'Kudashi,Dhule,Maharashtra', 'lon': '75.2957'},
      '424305': {'lat': '21.1311', 'name': 'Titane,Dhule,Maharashtra', 'lon': '74.3126'},
      '424304': {'lat': '20.9214', 'name': 'Rainpada,Dhule,Maharashtra', 'lon': '74.4937'},
      '424303': {'lat': '20.8998', 'name': 'Lonkhedi,Dhule,Maharashtra', 'lon': '74.5322'},
      '424302': {'lat': '20.8876', 'name': 'Kheda,Dhule,Maharashtra', 'lon': '74.5798'},
      '424301': {'lat': '21.1099', 'name': 'Kalkheda,Dhule,Maharashtra', 'lon': '74.6011'},
      '306701': {'lat': '25.2048', 'name': 'Sela,Pali,Rajasthan', 'lon': '73.2636'},
      '581421': {'lat': '13.8743', 'name': 'Kaikini,Uttara Kannada,Karnataka', 'lon': '74.8207'},
      '581423': {'lat': '14.8742', 'name': 'Beranki,Uttara Kannada,Karnataka', 'lon': '74.7243'},
      '424309': {'lat': '20.9881', 'name': 'Waghadi-bk,Dhule,Maharashtra', 'lon': '74.6054'},
      '424308': {'lat': '20.7382', 'name': 'Mordad,Dhule,Maharashtra', 'lon': '74.8393'},
      '422222': {'lat': '20.0296', 'name': 'Ycm Open Univesity,Nashik,Maharashtra', 'lon': '73.7156'},
      '503174': {'lat': '18.2565', 'name': 'APSP Camp (Nizamabad),Nizamabad,Telangana', 'lon': '78.4353'},
      '422221': {'lat': '20.0296', 'name': 'Ojhar A.F. Stn.,Nashik,Maharashtra', 'lon': '73.7156'},
      '670694': {'lat': '11.7677', 'name': 'Champad,Kannur,Kerala', 'lon': '75.5993'},
      '670692': {'lat': '11.7685', 'name': 'Elangot,Kannur,Kerala', 'lon': '75.5757'},
      '670693': {'lat': '11.7662', 'name': 'Vilakkottur,Kannur,Kerala', 'lon': '75.6464'},
      '721438': {'lat': '22.0208', 'name': 'Chandanpur,East Midnapore,West Bengal', 'lon': '87.7744'},
      '670691': {'lat': '11.7677', 'name': 'Kottayampoil,Kannur,Kerala', 'lon': '75.5993'},
      '403722': {'lat': '15.3548', 'name': 'Verna,South Goa,Goa', 'lon': '73.9245'},
      '741404': {'lat': '22.9083', 'name': 'Ashanandapara Street,Nadia,West Bengal', 'lon': '88.3917'},
      '609306': {'lat': '11.013', 'name': 'Tiruvilayattam,Nagapattinam,Tamil Nadu', 'lon': '79.7451'},
      '403723': {'lat': '15.1707', 'name': 'Ambelim,South Goa,Goa', 'lon': '73.9674'},
      '454775': {'lat': '22.602', 'name': 'Pithampur,Dhar,Madhya Pradesh', 'lon': '75.6965'},
      '454774': {'lat': '22.5839', 'name': 'Pithampur III,Dhar,Madhya Pradesh', 'lon': '75.646'},
      '403720': {'lat': '15.305', 'name': 'Raia,South Goa,Goa', 'lon': '73.971'},
      '403804': {'lat': '15.3895', 'name': 'Sada,South Goa,Goa', 'lon': '73.8253'},
      '680007': {'lat': '10.5015', 'name': 'Nedupuzha,Thrissur,Kerala', 'lon': '76.2071'},
      '576282': {'lat': '13.7156', 'name': 'Yedamoge,Udupi,Karnataka', 'lon': '74.9695'},
      '576283': {'lat': '13.7066', 'name': 'Kodladi,Udupi,Karnataka', 'lon': '74.9619'},
      '403726': {'lat': '15.2046', 'name': 'Zuarinagar,South Goa,Goa', 'lon': '73.9732'},
      '444719': {'lat': '21.4782', 'name': 'Bhokarbardi,Amravati,Maharashtra', 'lon': '77.3147'},
      '624210': {'lat': '10.2793', 'name': 'Pannaikadu,Dindigul,Tamil Nadu', 'lon': '77.6285'},
      '445230': {'lat': '19.7075', 'name': 'Bhavani Jahagir,Yavatmal,Maharashtra', 'lon': '77.8872'},
      '680553': {'lat': '10.5471', 'name': 'Puzhakkal,Thrissur,Kerala', 'lon': '76.1777'},
      '787059': {'lat': '27.5944', 'name': 'Cementmukh,Dhemaji,Assam', 'lon': '94.724'},
      '787058': {'lat': '27.1699', 'name': 'Dighali Debera,Dhemaji,Assam', 'lon': '94.2281'},
      '641062': {'lat': '11.059', 'name': 'Sinniampalayam,Coimbatore,Tamil Nadu', 'lon': '77.0838'},
      '403724': {'lat': '15.2215', 'name': 'Orlim,South Goa,Goa', 'lon': '73.9507'},
      '630312': {'lat': '9.8346', 'name': 'Neivayal,Ramanathapuram,Tamil Nadu', 'lon': '78.859'},
      '787051': {'lat': '27.1699', 'name': 'Moitri Ashram,Lakhimpur,Assam', 'lon': '94.2281'},
      '575030': {'lat': '13.0015', 'name': 'Delanthabettu,Dakshina Kannada,Karnataka', 'lon': '74.8296'},
      '787053': {'lat': '27.3097', 'name': 'Laguaborah,Dhemaji,Assam', 'lon': '94.4632'},
      '787052': {'lat': '26.3834', 'name': 'Fukhanarhat,Lakhimpur,Assam', 'lon': '92.7945'},
      '787055': {'lat': '27.1593', 'name': 'Joysagar,Lakhimpur,Assam', 'lon': '94.3832'},
      '787054': {'lat': '27.1699', 'name': 'Dhunaguri,Lakhimpur,Assam', 'lon': '94.2281'},
      '787057': {'lat': '27.1635', 'name': 'Hatigarh,Dhemaji,Assam', 'lon': '94.3917'},
      '787056': {'lat': '27.4255', 'name': 'Subansiri Koibarta Adarsha Gao,Lakhimpur,Assam', 'lon': '94.2933'},
      '803216': {'lat': '25.1707', 'name': 'Daruara,Nalanda,Bihar', 'lon': '85.537'},
      '518442': {'lat': '15.8394', 'name': 'Chelimilla,Kurnool,Andhra Pradesh', 'lon': '78.4897'},
      '491335': {'lat': '21.7472', 'name': 'Chandanu,Durg,Chattisgarh', 'lon': '81.4611'},
      '852201': {'lat': '25.9414', 'name': 'Saharsa Bazar,Saharsa,Bihar', 'lon': '86.45'},
      '396325': {'lat': '21.6185', 'name': 'Nandarkha,Navsari,Gujarat', 'lon': '72.8672'},
      '733129': {'lat': '24.0363', 'name': 'Krishnabati,North Dinajpur,West Bengal', 'lon': '88.0456'},
      '396321': {'lat': '21.6185', 'name': 'Bilimora H.O,Navsari,Gujarat', 'lon': '72.8672'},
      '454111': {'lat': '22.9026', 'name': 'Phulgawdi,Dhar,Madhya Pradesh', 'lon': '75.4699'},
      '458441': {'lat': '24.4109', 'name': 'Baghana Neemuch,Neemuch,Madhya Pradesh', 'lon': '75.0287'},
      '248011': {'lat': '30.2743', 'name': 'Selaqui,Dehradun,Uttarakhand', 'lon': '78.1823'},
      '630314': {'lat': '9.9752', 'name': 'Shanmuganathapuram,Sivaganga,Tamil Nadu', 'lon': '78.8254'},
      '248016': {'lat': '30.2743', 'name': 'Jollygrant,Dehradun,Uttarakhand', 'lon': '78.1823'},
      '248015': {'lat': '30.2743', 'name': 'Sudhowala,Dehradun,Uttarakhand', 'lon': '78.1823'},
      '344706': {'lat': '24.9663', 'name': 'Harpalia,Barmer,Rajasthan', 'lon': '71.0708'},
      '831015': {'lat': '23.5378', 'name': 'Gobindpur Housing Colony,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '831014': {'lat': '23.5378', 'name': 'N.I.T,Seraikela-kharsawan,Jharkhand', 'lon': '85.9803'},
      '532312': {'lat': '18.7179', 'name': 'Kesupuram,Srikakulam,Andhra Pradesh', 'lon': '84.1331'},
      '831016': {'lat': '23.5378', 'name': 'Rahargora,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '831011': {'lat': '22.8206', 'name': 'Sonari (East Singhbhum),East Singhbhum,Jharkhand', 'lon': '86.172'},
      '477111': {'lat': '26.6578', 'name': 'Ridoli,Bhind,Madhya Pradesh', 'lon': '78.6987'},
      '831013': {'lat': '23.5378', 'name': 'Adityapur,Seraikela-kharsawan,Jharkhand', 'lon': '85.9803'},
      '733123': {'lat': '24.307', 'name': 'Bariol,North Dinajpur,West Bengal', 'lon': '88.1246'},
      '627201': {'lat': '8.8775', 'name': 'Madavakurichi,Tirunelveli,Tamil Nadu', 'lon': '77.6571'},
      '629157': {'lat': '10.3953', 'name': 'Karinkal,Kanyakumari,Tamil Nadu', 'lon': '77.8671'},
      '627202': {'lat': '8.7258', 'name': 'Vagaikulam,Tirunelveli,Tamil Nadu', 'lon': '77.4396'},
      '831019': {'lat': '23.5378', 'name': 'Birsanagar,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '831018': {'lat': '23.5378', 'name': 'M.G.M Medical College,East Singhbhum,Jharkhand', 'lon': '85.9803'},
      '454335': {'lat': '22.1187', 'name': 'Lingwa,Dhar,Madhya Pradesh', 'lon': '74.9361'},
      '841203': {'lat': '26.0424', 'name': 'Nandamura,Siwan,Bihar', 'lon': '84.4353'},
      '841202': {'lat': '25.6011', 'name': 'Fatehpur Chain,Saran,Bihar', 'lon': '84.8428'},
      '841201': {'lat': '25.9245', 'name': 'Madansath,Saran,Bihar', 'lon': '84.5316'},
      '679313': {'lat': '10.8108', 'name': 'Kondurkara,Palakkad,Kerala', 'lon': '76.2268'},
      '841207': {'lat': '25.7412', 'name': 'Saidpur,Saran,Bihar', 'lon': '84.9934'},
      '841206': {'lat': '26.0162', 'name': 'Banpura Bazar,Saran,Bihar', 'lon': '84.6304'},
      '841205': {'lat': '25.9158', 'name': 'Khardahiya,Saran,Bihar', 'lon': '84.6794'},
      '841204': {'lat': '25.9725', 'name': 'Rasualpur Chatti,Saran,Bihar', 'lon': '84.5493'},
      '743710': {'lat': '22.8889', 'name': 'Panchberia,North 24 Parganas,West Bengal', 'lon': '88.4146'},
      '743711': {'lat': '22.8889', 'name': 'Kanchiara,North 24 Parganas,West Bengal', 'lon': '88.4146'},
      '248008': {'lat': '30.2008', 'name': 'Tapovan,Dehradun,Uttarakhand', 'lon': '78.4304'},
      '534250': {'lat': '16.7374', 'name': 'Pulapalli,West Godavari,Andhra Pradesh', 'lon': '81.5704'},
      '500008': {'lat': '17.4207', 'name': 'Toli Chowki,Hyderabad,Telangana', 'lon': '78.3509'},
      '125042': {'lat': '29.0736', 'name': 'Bhatol Jatan,Hisar,Haryana', 'lon': '76.1583'},
      '637015': {'lat': '11.078', 'name': 'Kumaripalyam,Namakkal,Tamil Nadu', 'lon': '78.1355'},
      '125044': {'lat': '29.3257', 'name': 'Satroad Khurd,Hisar,Haryana', 'lon': '75.7768'},
      '125047': {'lat': '29.3446', 'name': 'Landhari,Hisar,Haryana', 'lon': '75.6739'},
      '125048': {'lat': '29.4155', 'name': 'Dhanger,Fatehabad,Haryana', 'lon': '75.5503'},
      '125049': {'lat': '29.0922', 'name': 'Maijod,Hisar,Haryana', 'lon': '76.1501'},
      '671124': {'lat': '12.5427', 'name': 'Madhur,Kasargod,Kerala', 'lon': '74.9819'},
      '671123': {'lat': '12.5427', 'name': 'Cheroor,Kasargod,Kerala', 'lon': '74.9819'},
      '671122': {'lat': '12.5427', 'name': 'Talangara,Kasargod,Kerala', 'lon': '74.9819'},
      '671121': {'lat': '12.5427', 'name': 'Adkathbail,Kasargod,Kerala', 'lon': '74.9819'},
      '562157': {'lat': '13.1438', 'name': 'Chikkajala,Bengaluru,Karnataka', 'lon': '77.6169'},
      '491107': {'lat': '21.1167', 'name': 'Utai,Durg,Chattisgarh', 'lon': '81.3833'},
      '251203': {'lat': '29.3547', 'name': 'Mansurpur Village,Muzaffarnagar,Uttar Pradesh', 'lon': '77.7625'},
      '741401': {'lat': '23.3238', 'name': 'Dignagar,Nadia,West Bengal', 'lon': '88.452'},
      '501301': {'lat': '17.695', 'name': 'Edulabad,K.V.Rangareddy,Telangana', 'lon': '79.0645'},
      '284001': {'lat': '25.4688', 'name': 'Jhansi Kutchery,Jhansi,Uttar Pradesh', 'lon': '78.6335'},
      '637014': {'lat': '11.3442', 'name': 'Reddipudur,Namakkal,Tamil Nadu', 'lon': '78.1602'},
      '721657': {'lat': '22.8486', 'name': 'Hatiberia,East Midnapore,West Bengal', 'lon': '87.9962'},
      '458775': {'lat': '23.8733', 'name': 'Lotkhedi,Mandsaur,Madhya Pradesh', 'lon': '76.1722'},
      '800017': {'lat': '25.6716', 'name': 'B.S.E. Board,Patna,Bihar', 'lon': '85.0646'},
      '721447': {'lat': '21.8104', 'name': 'Kalyanpur Tentulmuri,East Midnapore,West Bengal', 'lon': '87.448'},
      '491331': {'lat': '21.1661', 'name': 'Bhatagaon,Durg,Chattisgarh', 'lon': '81.5085'},
      '243638': {'lat': '28.0667', 'name': 'Qadrabad,Budaun,Uttar Pradesh', 'lon': '78.7114'},
      '471111': {'lat': '24.9233', 'name': 'Chouwari,Chhatarpur,Madhya Pradesh', 'lon': '79.2657'},
      '741402': {'lat': '23.6202', 'name': 'Fulia Colony,Nadia,West Bengal', 'lon': '88.289'},
      '243637': {'lat': '27.8836', 'name': 'Mohammad Ganj,Budaun,Uttar Pradesh', 'lon': '79.2154'},
      '243634': {'lat': '28.1817', 'name': 'Puthi Sarai,Budaun,Uttar Pradesh', 'lon': '79.149'},
      '243635': {'lat': '27.8959', 'name': 'Puraini,Budaun,Uttar Pradesh', 'lon': '79.3472'},
      '243632': {'lat': '28.1069', 'name': 'Faizganj Behta,Budaun,Uttar Pradesh', 'lon': '78.8852'},
      '243633': {'lat': '28.1283', 'name': 'Mirzapura Sohra,Budaun,Uttar Pradesh', 'lon': '78.8847'},
      '743135': {'lat': '22.2022', 'name': 'Hazinagar,North 24 Parganas,West Bengal', 'lon': '88.2581'},
      '243631': {'lat': '27.9604', 'name': 'Allapur,Budaun,Uttar Pradesh', 'lon': '79.6402'},
      '506172': {'lat': '18.046', 'name': 'Akinepalli Mallaram,Warangal,Telangana', 'lon': '79.6518'},
      '506170': {'lat': '18.1951', 'name': 'Chainpak,Warangal,Telangana', 'lon': '79.6402'},
      '721656': {'lat': '23.1832', 'name': 'Terapakhia,East Midnapore,West Bengal', 'lon': '87.9551'},
      '516150': {'lat': '14.0812', 'name': 'Naramrajupalli,Cuddapah,Andhra Pradesh', 'lon': '79.091'},
      '516151': {'lat': '14.2918', 'name': 'Lebaka,Cuddapah,Andhra Pradesh', 'lon': '79.1923'},
      '516152': {'lat': '14.357', 'name': 'Konarachapalli,Cuddapah,Andhra Pradesh', 'lon': '79.041'},
      '768121': {'lat': '21.4659', 'name': 'Dholpara,Debagarh,Odisha', 'lon': '84.8037'},
      '312022': {'lat': '25.188', 'name': 'Bichhor,Chittorgarh,Rajasthan', 'lon': '74.6217'},
      '312023': {'lat': '25.3463', 'name': 'Sadi,Chittorgarh,Rajasthan', 'lon': '74.93'},
      '312021': {'lat': '24.8684', 'name': 'Sainik School,Chittorgarh,Rajasthan', 'lon': '75.724'},
      '416702': {'lat': '16.9677', 'name': 'Nanar,Ratnagiri,Maharashtra', 'lon': '73.5218'},
      '416703': {'lat': '16.5584', 'name': 'Nadgive,Sindhudurg,Maharashtra', 'lon': '73.6186'},
      '416701': {'lat': '17.8', 'name': 'Isavali,Ratnagiri,Maharashtra', 'lon': '73.5947'},
      '711409': {'lat': '22.5866', 'name': 'Nibra,Howrah,West Bengal', 'lon': '88.1876'},
      '391310': {'lat': '23.3498', 'name': 'Karchiya,Vadodara,Gujarat', 'lon': '73.8213'},
      '416704': {'lat': '20.3445', 'name': 'Hatade,Ratnagiri,Maharashtra', 'lon': '77.4601'},
      '416705': {'lat': '18.434', 'name': 'Wadavali,Ratnagiri,Maharashtra', 'lon': '75.1714'},
      '711405': {'lat': '22.6462', 'name': 'Narna,Howrah,West Bengal', 'lon': '88.2417'},
      '711404': {'lat': '22.6533', 'name': 'Mansinghpur,Howrah,West Bengal', 'lon': '88.126'},
      '160030': {'lat': '30.7325', 'name': 'Sector 29 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7341'},
      '416709': {'lat': '18.434', 'name': 'Katali Sagave,Ratnagiri,Maharashtra', 'lon': '75.1714'},
      '160036': {'lat': '30.7325', 'name': 'Sector 36 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7341'},
      '711403': {'lat': '22.6029', 'name': 'Munshidanga,Howrah,West Bengal', 'lon': '88.2775'},
      '313211': {'lat': '25.2163', 'name': 'Kabra,Rajsamand,Rajasthan', 'lon': '73.8923'},
      '626004': {'lat': '9.4825', 'name': 'Mettugundu,Virudhunagar,Tamil Nadu', 'lon': '78.0053'},
      '384110': {'lat': '23.8178', 'name': 'Der,Patan,Gujarat', 'lon': '72.2575'},
      '211008': {'lat': '25.3968', 'name': 'I L C,Allahabad,Uttar Pradesh', 'lon': '81.8852'},
      '403713': {'lat': '15.326', 'name': 'Utorda,South Goa,Goa', 'lon': '73.911'},
      '721655': {'lat': '22.8486', 'name': 'Nazirbaza5r,East Midnapore,West Bengal', 'lon': '87.9962'},
      '626001': {'lat': '10.9844', 'name': 'Virudhunagar Extension,Virudhunagar,Tamil Nadu', 'lon': '78.4673'},
      '626002': {'lat': '10.9844', 'name': 'Vadamalaikurichi,Virudhunagar,Tamil Nadu', 'lon': '78.4673'},
      '173235': {'lat': '31.2838', 'name': 'Saryanj,Solan,Himachal Pradesh', 'lon': '77.1229'},
      '211002': {'lat': '25.4932', 'name': 'Rajapur (Allahabad),Allahabad,Uttar Pradesh', 'lon': '81.8796'},
      '211003': {'lat': '25.5206', 'name': 'Krishna Nagar (Allahabad),Allahabad,Uttar Pradesh', 'lon': '81.4513'},
      '211001': {'lat': '25.5211', 'name': 'Allahabad New Cantt,Allahabad,Uttar Pradesh', 'lon': '81.954'},
      '211006': {'lat': '25.4502', 'name': 'Sohbatia Bagh,Allahabad,Uttar Pradesh', 'lon': '81.8791'},
      '173236': {'lat': '31.0429', 'name': 'Darwa,Solan,Himachal Pradesh', 'lon': '76.9095'},
      '211004': {'lat': '25.4608', 'name': 'Cavellary Lines,Allahabad,Uttar Pradesh', 'lon': '81.8162'},
      '211005': {'lat': '25.4608', 'name': 'Allahabad Fort,Allahabad,Uttar Pradesh', 'lon': '81.8162'},
      '415615': {'lat': '17.1507', 'name': 'Ganpatipule,Ratnagiri,Maharashtra', 'lon': '73.2754'},
      '415614': {'lat': '18.734', 'name': 'Jaigad,Ratnagiri,Maharashtra', 'lon': '74.284'},
      '415617': {'lat': '18.734', 'name': 'Vetoshi,Ratnagiri,Maharashtra', 'lon': '74.284'},
      '415616': {'lat': '20.8115', 'name': 'Golap,Ratnagiri,Maharashtra', 'lon': '75.1805'},
      '516330': {'lat': '14.5942', 'name': 'Idupulapaya(IIIT),Cuddapah,Andhra Pradesh', 'lon': '78.5363'},
      '415610': {'lat': '18.734', 'name': 'Kalambaste,Ratnagiri,Maharashtra', 'lon': '74.284'},
      '415613': {'lat': '18.734', 'name': 'Padve,Ratnagiri,Maharashtra', 'lon': '74.284'},
      '415612': {'lat': '17.7802', 'name': 'Tembye,Ratnagiri,Maharashtra', 'lon': '73.6041'},
      '507170': {'lat': '17.9816', 'name': 'Lokya Thanda,Khammam,Telangana', 'lon': '79.5022'},
      '173230': {'lat': '31.0429', 'name': 'Nauni,Solan,Himachal Pradesh', 'lon': '76.9095'},
      '415619': {'lat': '18.734', 'name': 'Charveli,Ratnagiri,Maharashtra', 'lon': '74.284'},
      '516339': {'lat': '14.5942', 'name': 'Kirthipalle,Cuddapah,Andhra Pradesh', 'lon': '78.5363'},
      '394520': {'lat': '21.2594', 'name': 'Variav,Surat,Gujarat', 'lon': '72.8192'},
      '847214': {'lat': '26.3613', 'name': 'Raghunidehat,Madhubani,Bihar', 'lon': '86.0875'},
      '403712': {'lat': '15.3367', 'name': 'Cansaulim,South Goa,Goa', 'lon': '73.8984'},
      '721654': {'lat': '22.1303', 'name': 'Kalikakundu,East Midnapore,West Bengal', 'lon': '88.0483'},
      '835209': {'lat': '23.3313', 'name': 'Tilmi,Ranchi,Jharkhand', 'lon': '85.3708'},
      '173233': {'lat': '30.9585', 'name': 'Ekhoo,Solan,Himachal Pradesh', 'lon': '76.91'},
      '627857': {'lat': '8.9207', 'name': 'Thirumalapuram,Tirunelveli,Tamil Nadu', 'lon': '77.651'},
      '394365': {'lat': '21.7084', 'name': 'Raniamba,Tapi,Gujarat', 'lon': '73.5759'},
      '637020': {'lat': '10.8933', 'name': 'N.Pudupatti,Namakkal,Tamil Nadu', 'lon': '78.1786'},
      '637021': {'lat': '11.2949', 'name': 'Pavithiram,Namakkal,Tamil Nadu', 'lon': '78.3275'},
      '394360': {'lat': '21.7084', 'name': 'Ghasiamedha,Surat,Gujarat', 'lon': '73.5759'},
      '533201': {'lat': '17.0747', 'name': "Rdo's Office Road,East Godavari,Andhra Pradesh", 'lon': '81.7932'},
      '403711': {'lat': '15.3984', 'name': 'Chicalim,South Goa,Goa', 'lon': '73.8422'},
      '721102': {'lat': '22.4617', 'name': 'Dherua,West Midnapore,West Bengal', 'lon': '87.1485'},
      '365660': {'lat': '21.6823', 'name': 'Trambakpur,Amreli,Gujarat', 'lon': '71.3701'},
      '332312': {'lat': '27.3565', 'name': 'Bathot,Sikar,Rajasthan', 'lon': '74.9541'},
      '263136': {'lat': '29.5193', 'name': 'Bhimtal,Nainital,Uttarakhand', 'lon': '79.486'},
      '825323': {'lat': '24.2126', 'name': 'Barkatha,Hazaribag,Jharkhand', 'lon': '85.619'},
      '206248': {'lat': '26.2933', 'name': 'Purva Jain,Auraiya,Uttar Pradesh', 'lon': '80.3365'},
      '756101': {'lat': '20.7818', 'name': 'Baralpokhari,Bhadrak,Odisha', 'lon': '85.4718'},
      '585421': {'lat': '18.1195', 'name': 'Wadgaon (D),Bidar,Karnataka', 'lon': '77.5181'},
      '627858': {'lat': '9.0209', 'name': 'Sundapandiapuram,Tirunelveli,Tamil Nadu', 'lon': '77.5591'},
      '673051': {'lat': '11.3373', 'name': 'Kozhikode Parcel Centre,Kozhikode,Kerala', 'lon': '75.8271'},
      '388140': {'lat': '23.0996', 'name': 'Chuva,Kheda,Gujarat', 'lon': '73.1479'},
      '403710': {'lat': '15.4025', 'name': 'Cortalim,South Goa,Goa', 'lon': '73.9088'},
      '721652': {'lat': '22.1454', 'name': 'Kumarchak,East Midnapore,West Bengal', 'lon': '87.9751'},
      '227411': {'lat': '26.2769', 'name': 'Kitiyawan,Sultanpur,Uttar Pradesh', 'lon': '81.7728'},
      '147101': {'lat': '30.2478', 'name': 'Gajjumajra,Patiala,Punjab', 'lon': '76.2609'},
      '227412': {'lat': '26.2119', 'name': 'Pashchim Duara,Sultanpur,Uttar Pradesh', 'lon': '81.8049'},
      '625110': {'lat': '9.9549', 'name': 'Poonjuthi,Madurai,Tamil Nadu', 'lon': '78.3155'},
      '227413': {'lat': '26.0376', 'name': 'Shivgarh Jalalpur,Sultanpur,Uttar Pradesh', 'lon': '82.1977'},
      '370445': {'lat': '22.8698', 'name': 'Mau,Kachchh,Gujarat', 'lon': '69.63'},
      '500001': {'lat': '17.3862', 'name': 'State Bank Of Hyderabad,Hyderabad,Telangana', 'lon': '78.462'},
      '721172': {'lat': '22.9768', 'name': 'Nonakuribazar,East Midnapore,West Bengal', 'lon': '87.7809'},
      '721171': {'lat': '22.9768', 'name': 'Ktpp T.S,East Midnapore,West Bengal', 'lon': '87.7809'},
      '272131': {'lat': '26.6618', 'name': 'Govindpara,Basti,Uttar Pradesh', 'lon': '82.8122'},
      '741201': {'lat': '24.4891', 'name': 'Ranaghat H.O,Nadia,West Bengal', 'lon': '87.8306'},
      '403717': {'lat': '15.2035', 'name': 'Carmona,South Goa,Goa', 'lon': '73.954'},
      '721651': {'lat': '22.8486', 'name': 'Putputia,East Midnapore,West Bengal', 'lon': '87.9962'},
      '507302': {'lat': '17.29', 'name': 'Kondrupadu,Khammam,Telangana', 'lon': '79.9203'},
      '584202': {'lat': '16.3448', 'name': 'Jookur,Raichur,Karnataka', 'lon': '77.2642'},
      '534222': {'lat': '17.3827', 'name': 'Kavalipuram,West Godavari,Andhra Pradesh', 'lon': '81.7704'},
      '713315': {'lat': '23.8168', 'name': 'Kapista,Paschim Bardhaman,West Bengal', 'lon': '86.9792'},
      '206242': {'lat': '26.7991', 'name': 'Sarai Chauri,Etawah,Uttar Pradesh', 'lon': '79.2869'},
      '833218': {'lat': '22.2115', 'name': 'Joteya,West Singhbhum,Jharkhand', 'lon': '85.5284'},
      '206243': {'lat': '26.7897', 'name': 'Indapamau,Auraiya,Uttar Pradesh', 'lon': '79.6852'},
      '221105': {'lat': '25.1563', 'name': 'Kajisarai,Varanasi,Uttar Pradesh', 'lon': '82.9651'},
      '743702': {'lat': '22.8767', 'name': 'Balisha Chowmatha,North 24 Parganas,West Bengal', 'lon': '88.585'},
      '802160': {'lat': '25.5697', 'name': 'Koilwar,Bhojpur,Bihar', 'lon': '84.7763'},
      '754212': {'lat': '20.4661', 'name': 'Mantiri,Kendrapara,Odisha', 'lon': '85.0268'},
      '403716': {'lat': '15.2644', 'name': 'Benaulim,South Goa,Goa', 'lon': '73.9281'},
      '721650': {'lat': '22.8722', 'name': 'Khodambari,East Midnapore,West Bengal', 'lon': '88.0876'},
      '679302': {'lat': '10.8208', 'name': 'Akalur,Palakkad,Kerala', 'lon': '76.2022'},
      '581301': {'lat': '14.6793', 'name': 'Karwar H.O,Uttara Kannada,Karnataka', 'lon': '74.2379'},
      '581303': {'lat': '14.4312', 'name': 'Kodibag,Uttara Kannada,Karnataka', 'lon': '74.4028'},
      '581302': {'lat': '14.6793', 'name': 'Karwar Baitkhol,Uttara Kannada,Karnataka', 'lon': '74.2379'},
      '581305': {'lat': '14.8399', 'name': 'Kathinkon,Uttara Kannada,Karnataka', 'lon': '74.151'},
      '581304': {'lat': '14.6793', 'name': 'Nandangadda,Uttara Kannada,Karnataka', 'lon': '74.2379'},
      '581307': {'lat': '14.6793', 'name': 'Binga,Uttara Kannada,Karnataka', 'lon': '74.2379'},
      '581306': {'lat': '14.6793', 'name': 'Baad(KWR),Uttara Kannada,Karnataka', 'lon': '74.2379'},
      '382016': {'lat': '23.0976', 'name': 'Vavol,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '581308': {'lat': '14.7668', 'name': 'Arge,Uttara Kannada,Karnataka', 'lon': '74.1598'},
      '755036': {'lat': '20.7192', 'name': 'Gopabandhunagar,Jajapur,Odisha', 'lon': '84.5907'},
      '382010': {'lat': '23.0976', 'name': 'BPC Gandhinagar,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '473880': {'lat': '25.0822', 'name': 'Tharkheda,Shivpuri,Madhya Pradesh', 'lon': '77.7732'},
      '124506': {'lat': '28.691', 'name': 'Mandothi,Jhajjar,Haryana', 'lon': '76.8561'},
      '473885': {'lat': '24.9937', 'name': 'Raosar Jagir,Guna,Madhya Pradesh', 'lon': '77.5494'},
      '275101': {'lat': '26.2438', 'name': 'Mau Town,Mau,Uttar Pradesh', 'lon': '82.6066'},
      '413728': {'lat': '19.2034', 'name': 'Wadali,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '413726': {'lat': '19.2003', 'name': 'Math Pimpari,Ahmed Nagar,Maharashtra', 'lon': '75.0508'},
      '413725': {'lat': '18.0828', 'name': 'Belpimpalgaon,Ahmed Nagar,Maharashtra', 'lon': '76.3195'},
      '764051': {'lat': '18.2681', 'name': 'Kambeda,Malkangiri,Odisha', 'lon': '82.0864'},
      '413723': {'lat': '19.3792', 'name': 'Chitali,Ahmed Nagar,Maharashtra', 'lon': '75.0818'},
      '413722': {'lat': '19.2003', 'name': 'Mulanagar,Ahmed Nagar,Maharashtra', 'lon': '75.0508'},
      '413721': {'lat': '19.501', 'name': 'Bherdapur,Ahmed Nagar,Maharashtra', 'lon': '74.6854'},
      '413720': {'lat': '19.2003', 'name': 'Subhashwadi,Ahmed Nagar,Maharashtra', 'lon': '75.0508'},
      '630555': {'lat': '9.8378', 'name': 'Satharasanpatti,Sivaganga,Tamil Nadu', 'lon': '78.5502'},
      '444407': {'lat': '20.5791', 'name': 'Borgaon KD,Akola,Maharashtra', 'lon': '77.2425'},
      '444404': {'lat': '20.3689', 'name': 'Dapura Kd,Washim,Maharashtra', 'lon': '77.6558'},
      '444405': {'lat': '20.4911', 'name': 'Titwa,Akola,Maharashtra', 'lon': '77.1853'},
      '444402': {'lat': '20.7489', 'name': 'Wanoja,Akola,Maharashtra', 'lon': '77.0533'},
      '444403': {'lat': '20.3696', 'name': 'Kasola,Washim,Maharashtra', 'lon': '77.7168'},
      '630553': {'lat': '10.0035', 'name': 'Alagamanagiri,Sivaganga,Tamil Nadu', 'lon': '78.5051'},
      '444401': {'lat': '20.7489', 'name': 'Dhabha,Akola,Maharashtra', 'lon': '77.0533'},
      '571126': {'lat': '11.9269', 'name': 'Kaligowdanahalli,Chamrajnagar,Karnataka', 'lon': '77.0629'},
      '630559': {'lat': '9.8042', 'name': 'Periakottai,Sivaganga,Tamil Nadu', 'lon': '78.3771'},
      '612603': {'lat': '10.8301', 'name': 'Engan,Thanjavur,Tamil Nadu', 'lon': '79.5472'},
      '444409': {'lat': '20.2271', 'name': 'Falegaon,Washim,Maharashtra', 'lon': '77.3054'},
      '612602': {'lat': '10.925', 'name': 'Mathur,Thanjavur,Tamil Nadu', 'lon': '79.4502'},
      '503101': {'lat': '18.443', 'name': 'Bhikno R.S.,Nizamabad,Telangana', 'lon': '78.5019'},
      '571124': {'lat': '12.6287', 'name': 'Kurubur,Mysuru,Karnataka', 'lon': '76.7176'},
      '848506': {'lat': '25.6328', 'name': 'Hardaspur Diara,Samastipur,Bihar', 'lon': '85.722'},
      '332317': {'lat': '27.6023', 'name': 'Badusar,Sikar,Rajasthan', 'lon': '76.2846'},
      '465339': {'lat': '22.7653', 'name': 'Mohammadpur Machnai,Shajapur,Madhya Pradesh', 'lon': '76.0733'},
      '121007': {'lat': '28.4873', 'name': 'Faridabad Sector 15,Faridabad,Haryana', 'lon': '77.1724'},
      '147105': {'lat': '30.0203', 'name': 'Shutrana,Patiala,Punjab', 'lon': '76.1857'},
      '503108': {'lat': '18.1039', 'name': 'Malthummeda,Nizamabad,Telangana', 'lon': '78.3386'},
      '612604': {'lat': '10.6797', 'name': 'Kandramanickam,Tiruvarur,Tamil Nadu', 'lon': '79.536'},
      '761037': {'lat': '19.1599', 'name': 'Nuapentho,Ganjam,Odisha', 'lon': '84.5272'},
      '744102': {'lat': '11.6833', 'name': 'Delanipur,South Andaman,Andaman & Nicobar Islands', 'lon': '92.7167'},
      '761035': {'lat': '20.1165', 'name': 'Sankuda,Ganjam,Odisha', 'lon': '85.0483'},
      '721504': {'lat': '22.5022', 'name': 'Harinarayanpur Dharampur,West Midnapore,West Bengal', 'lon': '86.9911'},
      '721503': {'lat': '23.4111', 'name': 'Tulibarh,West Midnapore,West Bengal', 'lon': '85.8956'},
      '761032': {'lat': '19.6243', 'name': 'Mardakote,Ganjam,Odisha', 'lon': '84.9408'},
      '413624': {'lat': '17.9614', 'name': 'Kati,Osmanabad,Maharashtra', 'lon': '75.889'},
      '125056': {'lat': '29.4359', 'name': 'JCD Vidyapeeth Sirsa,Sirsa,Haryana', 'lon': '75.4487'},
      '854117': {'lat': '25.5236', 'name': 'Rosna,Katihar,Bihar', 'lon': '87.5217'},
      '500005': {'lat': '17.3102', 'name': 'Pahadishareef,K.V.Rangareddy,Telangana', 'lon': '78.4997'},
      '764059': {'lat': '19.24635', 'name': 'Baghsiuni,Nabarangapur,Odisha', 'lon': '82.55765'},
      '571128': {'lat': '12.1858', 'name': 'Maleyur,Chamrajnagar,Karnataka', 'lon': '76.7034'},
      '629852': {'lat': '8.3113', 'name': 'Isanthimangalam,Kanyakumari,Tamil Nadu', 'lon': '77.4262'},
      '274201': {'lat': '26.5533', 'name': 'Chhitahi Bazar,Deoria,Uttar Pradesh', 'lon': '83.5551'},
      '571129': {'lat': '12.2723', 'name': 'Kupparavalli,Mysuru,Karnataka', 'lon': '76.5802'},
      '464770': {'lat': '23.0576', 'name': 'Gaiviyan,Raisen,Madhya Pradesh', 'lon': '78.483'},
      '246763': {'lat': '29.7962', 'name': 'Gaindi Khata,Haridwar,Uttarakhand', 'lon': '78.2582'},
      '147104': {'lat': '30.4962', 'name': 'Tarkheri,Patiala,Punjab', 'lon': '76.3221'},
      '246762': {'lat': '29.4276', 'name': 'Alaihdajpur Khajuba,Bijnor,Uttar Pradesh', 'lon': '78.2962'},
      '493195': {'lat': '21.5734', 'name': 'Tohda,Raipur,Chattisgarh', 'lon': '81.9415'},
      '493196': {'lat': '21.5996', 'name': 'Grasim Vihar Rawan,Raipur,Chattisgarh', 'lon': '82.0531'},
      '246761': {'lat': '29.3257', 'name': 'Amkhera  Sanjarpur,Bijnor,Uttar Pradesh', 'lon': '78.4413'},
      '574509': {'lat': '12.998', 'name': 'Yekkar,Dakshina Kannada,Karnataka', 'lon': '74.8683'},
      '500004': {'lat': '17.3872', 'name': 'Anandnagar (Hyderabad),Hyderabad,Telangana', 'lon': '78.4621'},
      '531117': {'lat': '17.6087', 'name': 'Vemulapudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.6138'},
      '531116': {'lat': '17.6559', 'name': 'Narsipatnam H.O,Visakhapatnam,Andhra Pradesh', 'lon': '82.5482'},
      '531115': {'lat': '17.5315', 'name': 'Gummadigonda,Visakhapatnam,Andhra Pradesh', 'lon': '82.4993'},
      '531114': {'lat': '17.6042', 'name': 'Kannampeta,Visakhapatnam,Andhra Pradesh', 'lon': '82.3699'},
      '680101': {'lat': '10.5943', 'name': 'Guruvayur Temple,Thrissur,Kerala', 'lon': '76.0411'},
      '680103': {'lat': '10.5949', 'name': 'Iringapuram,Thrissur,Kerala', 'lon': '76.0648'},
      '680102': {'lat': '10.6091', 'name': 'Kandanassery,Thrissur,Kerala', 'lon': '76.0839'},
      '396130': {'lat': '20.2282', 'name': 'Sarai,Valsad,Gujarat', 'lon': '72.7492'},
      '835201': {'lat': '22.4931', 'name': 'Kurkura,Simdega,Jharkhand', 'lon': '84.6146'},
      '396135': {'lat': '20.2282', 'name': 'Saronda,Valsad,Gujarat', 'lon': '72.7492'},
      '531118': {'lat': '17.7384', 'name': 'Cheedigummala,Visakhapatnam,Andhra Pradesh', 'lon': '82.5892'},
      '532127': {'lat': '18.3003', 'name': 'Marrivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.2703'},
      '532123': {'lat': '18.4998', 'name': 'Ambakhandi,Srikakulam,Andhra Pradesh', 'lon': '83.734'},
      '532122': {'lat': '18.5195', 'name': 'Sundarada,Vizianagaram,Andhra Pradesh', 'lon': '83.5861'},
      '500007': {'lat': '17.427', 'name': 'IICT,Hyderabad,Telangana', 'lon': '78.5353'},
      '143513': {'lat': '31.9099', 'name': 'Kala Afgana,Gurdaspur,Punjab', 'lon': '75.0159'},
      '465697': {'lat': '23.6716', 'name': 'Biaora Mandu,Rajgarh,Madhya Pradesh', 'lon': '76.6645'},
      '632318': {'lat': '12.679', 'name': 'Soraiyur,Vellore,Tamil Nadu', 'lon': '79.3972'},
      '274203': {'lat': '26.6715', 'name': 'Rampur Buzurug,Kushinagar,Uttar Pradesh', 'lon': '83.4876'},
      '403719': {'lat': '15.2967', 'name': 'Sem-rachol  Soso,South Goa,Goa', 'lon': '73.9417'},
      '613303': {'lat': '10.6542', 'name': 'Tirukanurpatti,Thanjavur,Tamil Nadu', 'lon': '79.0995'},
      '458771': {'lat': '23.811', 'name': 'C.C. Camp,Mandsaur,Madhya Pradesh', 'lon': '76.1763'},
      '249165': {'lat': '30.364', 'name': 'Khalargaon,Tehri Garhwal,Uttarakhand', 'lon': '78.674'},
      '458002': {'lat': '23.5484', 'name': 'Guradia Deda,Mandsaur,Madhya Pradesh', 'lon': '76.5079'},
      '835202': {'lat': '23.2676', 'name': 'Hatu Ghaghra,Ranchi,Jharkhand', 'lon': '85.0703'},
      '605651': {'lat': '12.139', 'name': 'V.Nallalam,Villupuram,Tamil Nadu', 'lon': '79.527'},
      '848213': {'lat': '25.7817', 'name': 'Jhajhra,Samastipur,Bihar', 'lon': '86.2737'},
      '500006': {'lat': '17.3862', 'name': 'Karwan Sahu,Hyderabad,Telangana', 'lon': '78.462'},
      '501506': {'lat': '17.5959', 'name': 'Cherlapatel Guda,K.V.Rangareddy,Telangana', 'lon': '78.8308'},
      '629154': {'lat': '8.8335', 'name': 'Kanjampuram,Kanyakumari,Tamil Nadu', 'lon': '77.3921'},
      '403718': {'lat': '15.3384', 'name': 'Loutolim,South Goa,Goa', 'lon': '73.9959'},
      '490011': {'lat': '21.2724', 'name': 'New Khursipar Bhilai,Durg,Chattisgarh', 'lon': '81.4358'},
      '342012': {'lat': '26.1717', 'name': 'Boranada,Jodhpur,Rajasthan', 'lon': '72.924'},
      '342013': {'lat': '26.1732', 'name': 'Rajasthan High Court NB,Jodhpur,Rajasthan', 'lon': '73.025'},
      '577123': {'lat': '13.4558', 'name': 'Alageshwara,Chikkamagaluru,Karnataka', 'lon': '75.3498'},
      '342014': {'lat': '26.1437', 'name': 'Lunawas Kallan,Jodhpur,Rajasthan', 'lon': '72.8233'},
      '342015': {'lat': '26.2264', 'name': 'Nandra Kallan,Jodhpur,Rajasthan', 'lon': '73.1204'},
      '470232': {'lat': '23.6589', 'name': 'Bichiya,Sagar,Madhya Pradesh', 'lon': '78.7177'},
      '505460': {'lat': '18.7463', 'name': 'Joganapalli,Karim Nagar,Telangana', 'lon': '78.8332'},
      '500101': {'lat': '17.5902', 'name': 'Lalgadi Malakpet,Hyderabad,Telangana', 'lon': '78.6668'},
      '505462': {'lat': '18.124', 'name': 'Bhushanraopet,Karim Nagar,Telangana', 'lon': '78.716'},
      '500103': {'lat': '17.5902', 'name': 'Jai Jawan Colony,Hyderabad,Telangana', 'lon': '78.6668'},
      '500104': {'lat': '17.5902', 'name': 'Chitrapuri Colony,K.V.Rangareddy,Telangana', 'lon': '78.6668'},
      '471313': {'lat': '24.6077', 'name': 'Sarkana,Chhatarpur,Madhya Pradesh', 'lon': '79.1228'},
      '505466': {'lat': '18.0992', 'name': 'Keshavapur,Karim Nagar,Telangana', 'lon': '79.4045'},
      '304507': {'lat': '25.9568', 'name': 'Hisampur,Tonk,Rajasthan', 'lon': '75.2235'},
      '505468': {'lat': '18.304', 'name': 'Huzurabad H.O,Karim Nagar,Telangana', 'lon': '79.187'},
      '505469': {'lat': '18.3629', 'name': 'Chengerla,Karim Nagar,Telangana', 'lon': '79.2387'},
      '690559': {'lat': '9.185', 'name': 'Perungala,Alappuzha,Kerala', 'lon': '76.5173'},
      '690558': {'lat': '9.2291', 'name': 'Mankamkuzhy,Alappuzha,Kerala', 'lon': '76.5999'},
      '301024': {'lat': '27.4281', 'name': 'Mundawar,Alwar,Rajasthan', 'lon': '76.1069'},
      '464774': {'lat': '23.1252', 'name': 'Padraikalan,Raisen,Madhya Pradesh', 'lon': '78.7098'},
      '301025': {'lat': '27.6675', 'name': 'Neekuch,Alwar,Rajasthan', 'lon': '76.8815'},
      '835204': {'lat': '23.202', 'name': 'Adalahatu,Ranchi,Jharkhand', 'lon': '85.6027'},
      '301022': {'lat': '27.3629', 'name': 'Gudhachurani,Alwar,Rajasthan', 'lon': '76.2881'},
      '605652': {'lat': '12.0495', 'name': 'V.Sathanur,Villupuram,Tamil Nadu', 'lon': '79.5632'},
      '341028': {'lat': '26.8209', 'name': 'Bhadora,Nagaur,Rajasthan', 'lon': '74.2134'},
      '605302': {'lat': '11.9745', 'name': 'Kangiyanur,Villupuram,Tamil Nadu', 'lon': '79.3969'},
      '445110': {'lat': '20.1648', 'name': 'Deurwadi Lad,Yavatmal,Maharashtra', 'lon': '77.8738'},
      '201204': {'lat': '28.8376', 'name': 'Nagla Akhu,Ghaziabad,Uttar Pradesh', 'lon': '77.561'},
      '515541': {'lat': '14.179', 'name': 'Mallepalli,Ananthapur,Andhra Pradesh', 'lon': '78.1368'},
      '201201': {'lat': '28.8566', 'name': 'Churiyala,Ghaziabad,Uttar Pradesh', 'lon': '77.6693'},
      '301020': {'lat': '27.8653', 'name': 'Dooghera,Alwar,Rajasthan', 'lon': '76.3927'},
      '274204': {'lat': '26.5045', 'name': 'Bardihadal,Deoria,Uttar Pradesh', 'lon': '83.4671'},
      '756123': {'lat': '20.9115', 'name': 'Atto,Bhadrak,Odisha', 'lon': '85.4187'},
      '304501': {'lat': '26.2426', 'name': 'Kalmanda,Tonk,Rajasthan', 'lon': '75.5438'},
      '609308': {'lat': '11.0182', 'name': 'Sankaranpandal,Nagapattinam,Tamil Nadu', 'lon': '79.7565'},
      '609309': {'lat': '11.1039', 'name': 'Arupathy,Nagapattinam,Tamil Nadu', 'lon': '79.7399'},
      '835205': {'lat': '23.3435', 'name': 'Brambe,Ranchi,Jharkhand', 'lon': '85.1653'},
      '323602': {'lat': '24.9535', 'name': 'Bhiya,Bundi,Rajasthan', 'lon': '75.7468'},
      '323603': {'lat': '25.6199', 'name': 'Baswada,Bundi,Rajasthan', 'lon': '75.8493'},
      '323601': {'lat': '25.2867', 'name': 'Kesoraipatan,Bundi,Rajasthan', 'lon': '75.7981'},
      '609301': {'lat': '11.1036', 'name': 'Umayalpuram,Nagapattinam,Tamil Nadu', 'lon': '79.8162'},
      '609302': {'lat': '11.1915', 'name': 'Arangakudi,Nagapattinam,Tamil Nadu', 'lon': '79.7129'},
      '609303': {'lat': '10.8494', 'name': 'Ayappadi,Nagapattinam,Tamil Nadu', 'lon': '79.7389'},
      '609304': {'lat': '11.1156', 'name': 'Kanjanagaram,Nagapattinam,Tamil Nadu', 'lon': '79.7061'},
      '609305': {'lat': '11.1033', 'name': 'Mannampandal,Nagapattinam,Tamil Nadu', 'lon': '79.6911'},
      '387530': {'lat': '22.9474', 'name': 'Mahelaj,Kheda,Gujarat', 'lon': '72.5757'},
      '609307': {'lat': '10.9825', 'name': 'Porayar Market,Nagapattinam,Tamil Nadu', 'lon': '79.617'},
      '464651': {'lat': '23.7869', 'name': 'Deewanganj,Raisen,Madhya Pradesh', 'lon': '77.7759'},
      '683101': {'lat': '10.0614', 'name': 'Aluva Bazar,Ernakulam,Kerala', 'lon': '76.3263'},
      '683102': {'lat': '10.0614', 'name': 'Union Christian College,Ernakulam,Kerala', 'lon': '76.3263'},
      '683104': {'lat': '10.0614', 'name': 'Rajagiri,Ernakulam,Kerala', 'lon': '76.3263'},
      '683105': {'lat': '10.0614', 'name': 'Marampally,Ernakulam,Kerala', 'lon': '76.3263'},
      '683106': {'lat': '10.0614', 'name': 'Thaikkattukara,Ernakulam,Kerala', 'lon': '76.3263'},
      '472221': {'lat': '25.0816', 'name': 'Kachhora,Tikamgarh,Madhya Pradesh', 'lon': '79.1926'},
      '683108': {'lat': '10.0614', 'name': 'Thottakkattukara,Ernakulam,Kerala', 'lon': '76.3263'},
      '391410': {'lat': '21.8333', 'name': 'Bill,Vadodara,Gujarat', 'lon': '73.4833'},
      '604301': {'lat': '12.2026', 'name': 'Perumukkal,Villupuram,Tamil Nadu', 'lon': '79.7348'},
      '577551': {'lat': '14.2102', 'name': 'Punyasthla,Davangere,Karnataka', 'lon': '75.8002'},
      '534401': {'lat': '16.806', 'name': 'Amberpeta,West Godavari,Andhra Pradesh', 'lon': '81.3241'},
      '679123': {'lat': '10.8045', 'name': 'K A Samajam,Palakkad,Kerala', 'lon': '76.2616'},
      '679122': {'lat': '10.8045', 'name': 'Mundakkottukurissi,Palakkad,Kerala', 'lon': '76.2616'},
      '679121': {'lat': '10.8045', 'name': 'Vadanamkurussi,Palakkad,Kerala', 'lon': '76.2616'},
      '431401': {'lat': '19.6096', 'name': 'Station Parbhani,Parbhani,Maharashtra', 'lon': '76.9337'},
      '341026': {'lat': '26.5222', 'name': 'Jhujhanda,Nagaur,Rajasthan', 'lon': '73.6679'},
      '485114': {'lat': '24.5117', 'name': 'Bharjuna Kala,Satna,Madhya Pradesh', 'lon': '81.0129'},
      '803307': {'lat': '25.3348', 'name': 'Baruane,Patna,Bihar', 'lon': '85.7468'},
      '274206': {'lat': '26.7372', 'name': 'Nautan Hathia Garh,Kushinagar,Uttar Pradesh', 'lon': '83.8305'},
      '754201': {'lat': '20.4557', 'name': 'Bhimrajpur,Cuttack,Odisha', 'lon': '84.9762'},
      '587301': {'lat': '15.9978', 'name': 'Kunchanur,Bagalkot,Karnataka', 'lon': '75.5946'},
      '341025': {'lat': '27.0281', 'name': 'Khatora,Nagaur,Rajasthan', 'lon': '73.7196'},
      '754202': {'lat': '20.8727', 'name': 'Sunakhandi,Cuttack,Odisha', 'lon': '86.0749'},
      '577550': {'lat': '13.611', 'name': 'Katignere,Chikkamagaluru,Karnataka', 'lon': '75.9944'},
      '803301': {'lat': '25.3429', 'name': 'Hathidah,Patna,Bihar', 'lon': '86.0003'},
      '843331': {'lat': '26.1497', 'name': 'Sutihara,Sitamarhi,Bihar', 'lon': '85.8726'},
      '607209': {'lat': '11.8556', 'name': 'Saravanapakkam,Villupuram,Tamil Nadu', 'lon': '79.3224'},
      '480667': {'lat': '22.1305', 'name': 'Dharnakala,Seoni,Madhya Pradesh', 'lon': '79.7481'},
      '803303': {'lat': '25.3694', 'name': 'Mokamaghat,Patna,Bihar', 'lon': '85.9507'},
      '363330': {'lat': '22.9358', 'name': 'Ratabhe,Surendra Nagar,Gujarat', 'lon': '71.1169'},
      '363331': {'lat': '22.9264', 'name': 'Charadva,MORBI,Gujarat', 'lon': '71.0255'},
      '524142': {'lat': '15.0296', 'name': 'Zakkepalligudur,Nellore,Andhra Pradesh', 'lon': '79.7424'},
      '124507': {'lat': '28.67624', 'name': 'Main Bazar,10,Bahadurgarh ', 'lon': '76.91339'},
      '124504': {'lat': '28.7918', 'name': 'Rewari Khera,Jhajjar,Haryana', 'lon': '76.8258'},
      '124505': {'lat': '28.7554', 'name': 'Bamnoli,Jhajjar,Haryana', 'lon': '76.904'},
      '700054': {'lat': '23.3945', 'name': 'Cmda Abasan,Kolkata,West Bengal', 'lon': '88.2559'},
      '700055': {'lat': '23.3945', 'name': 'Bangur Avenue,North 24 Parganas,West Bengal', 'lon': '88.2559'},
      '700056': {'lat': '23.3945', 'name': 'Texmaco,North 24 Parganas,West Bengal', 'lon': '88.2559'},
      '124501': {'lat': '28.6048', 'name': 'Sampla,Jhajjar,Haryana', 'lon': '76.6494'},
      '700058': {'lat': '22.6711', 'name': 'Elias Road,North 24 Parganas,West Bengal', 'lon': '88.3747'},
      '700059': {'lat': '22.5697', 'name': 'Baguiati Super Market ,North 24 Parganas,West Bengal', 'lon': '88.3697'},
      '754207': {'lat': '20.694', 'name': 'Bodhanga,Cuttack,Odisha', 'lon': '85.5839'},
      '577553': {'lat': '14.4823', 'name': 'Medekerenahalli,Davangere,Karnataka', 'lon': '76.239'},
      '607205': {'lat': '11.7222', 'name': 'Kandrakottai,Cuddalore,Tamil Nadu', 'lon': '79.4909'},
      '124508': {'lat': '28.6328', 'name': 'Daboda Khurd,Jhajjar,Haryana', 'lon': '76.7875'},
      '691601': {'lat': '9.1167', 'name': 'Inchavila,Kollam,Kerala', 'lon': '76.4833'},
      '691602': {'lat': '9.1167', 'name': 'Ashtamudi,Kollam,Kerala', 'lon': '76.4833'},
      '136021': {'lat': '29.8264', 'name': 'Sanghrouli,Kaithal,Haryana', 'lon': '76.6446'},
      '501509': {'lat': '17.4248', 'name': 'Akulamailaram,K.V.Rangareddy,Telangana', 'lon': '78.4611'},
      '501508': {'lat': '17.2027', 'name': 'Tadlapalliguda,K.V.Rangareddy,Telangana', 'lon': '78.6565'},
      '785112': {'lat': '26.832', 'name': 'Boloma,Jorhat,Assam', 'lon': '94.4165'},
      '607203': {'lat': '11.8908', 'name': 'Tiruvennainallur,Villupuram,Tamil Nadu', 'lon': '79.3701'},
      '785110': {'lat': '26.832', 'name': 'Sonowal Kachari,Jorhat,Assam', 'lon': '94.4165'},
      '785111': {'lat': '26.832', 'name': 'Neamatighat,Jorhat,Assam', 'lon': '94.4165'},
      '501503': {'lat': '17.5684', 'name': 'Kanmeta,K.V.Rangareddy,Telangana', 'lon': '78.3275'},
      '501502': {'lat': '17.9956', 'name': 'Dirsampalli,K.V.Rangareddy,Telangana', 'lon': '78.1614'},
      '501501': {'lat': '17.426', 'name': 'Kudmoor,K.V.Rangareddy,Telangana', 'lon': '78.4682'},
      '607202': {'lat': '11.7281', 'name': 'Sembiamadevi,Villupuram,Tamil Nadu', 'lon': '79.1915'},
      '345034': {'lat': '25.7472', 'name': 'Berisiyala,Jaisalmer,Rajasthan', 'lon': '73.7288'},
      '345031': {'lat': '27.03', 'name': 'Odhania,Jaisalmer,Rajasthan', 'lon': '71.6145'},
      '607201': {'lat': '10.8889', 'name': 'Kunjaram,Tiruchirappalli,Tamil Nadu', 'lon': '78.5704'},
      '345033': {'lat': '27.2871', 'name': 'Mohangarh (Jaisalmer),Jaisalmer,Rajasthan', 'lon': '71.2419'},
      '604303': {'lat': '12.2809', 'name': 'Mandava,Villupuram,Tamil Nadu', 'lon': '79.8177'},
      '604302': {'lat': '12.3325', 'name': 'Kiledayalam,Villupuram,Tamil Nadu', 'lon': '79.5838'},
      '577552': {'lat': '14.1638', 'name': 'Medikere,Davangere,Karnataka', 'lon': '75.9812'},
      '604307': {'lat': '12.2483', 'name': 'Ayyanthoppu,Villupuram,Tamil Nadu', 'lon': '79.6894'},
      '604306': {'lat': '12.2156', 'name': 'Vengandur,Villupuram,Tamil Nadu', 'lon': '79.5529'},
      '604305': {'lat': '12.3117', 'name': 'Olakkur,Villupuram,Tamil Nadu', 'lon': '79.7167'},
      '604304': {'lat': '12.1002', 'name': 'Thaludali,Villupuram,Tamil Nadu', 'lon': '79.6478'},
      '608306': {'lat': '11.232', 'name': 'Movur,Cuddalore,Tamil Nadu', 'lon': '79.5616'},
      '608305': {'lat': '11.361', 'name': 'Kothavasal,Cuddalore,Tamil Nadu', 'lon': '79.5853'},
      '608304': {'lat': '11.1928', 'name': 'Chettithangal,Cuddalore,Tamil Nadu', 'lon': '79.5019'},
      '608303': {'lat': '11.7497', 'name': 'Lalpet,Cuddalore,Tamil Nadu', 'lon': '79.4811'},
      '608302': {'lat': '11.3332', 'name': 'C.Arasur,Cuddalore,Tamil Nadu', 'lon': '79.6411'},
      '608301': {'lat': '11.2836', 'name': 'Sithamalli,Cuddalore,Tamil Nadu', 'lon': '79.5293'},
      '632055': {'lat': '12.6976', 'name': 'Ariyur Spinning Mills,Vellore,Tamil Nadu', 'lon': '79.5709'},
      '743387': {'lat': '22.345', 'name': 'Brindakhali,South 24 Parganas,West Bengal', 'lon': '88.5706'},
      '691543': {'lat': '8.8669', 'name': 'Cherupoika,Kollam,Kerala', 'lon': '76.8921'},
      '792056': {'lat': '27.9126', 'name': 'Bordumsa,Changlang,Arunachal Pradesh', 'lon': '96.1288'},
      '517418': {'lat': '13.8711', 'name': 'Gopidinne,Chittoor,Andhra Pradesh', 'lon': '78.4027'},
      '691540': {'lat': '8.9189', 'name': 'Veliyam West,Kollam,Kerala', 'lon': '76.7679'},
      '517416': {'lat': '13.7403', 'name': 'Thumabakuppam,Chittoor,Andhra Pradesh', 'lon': '79.5039'},
      '517417': {'lat': '13.3941', 'name': 'Narigapalle,Chittoor,Andhra Pradesh', 'lon': '78.5659'},
      '517414': {'lat': '13.526', 'name': 'Nellapalle,Chittoor,Andhra Pradesh', 'lon': '78.7411'},
      '517415': {'lat': '13.1724', 'name': 'Theertham,Chittoor,Andhra Pradesh', 'lon': '78.5887'},
      '203203': {'lat': '28.298', 'name': 'Dhanoura,Bulandshahr,Uttar Pradesh', 'lon': '77.6637'},
      '203202': {'lat': '28.3799', 'name': 'Gurukul  Sikandrabad,Bulandshahr,Uttar Pradesh', 'lon': '77.7162'},
      '203201': {'lat': '28.3656', 'name': 'Mirzapur Niloni,Bulandshahr,Uttar Pradesh', 'lon': '77.5676'},
      '625512': {'lat': '10.0415', 'name': 'Rajadhani,Theni,Tamil Nadu', 'lon': '77.5244'},
      '209747': {'lat': '27.0459', 'name': 'Sarifabad,Kannauj,Uttar Pradesh', 'lon': '79.4948'},
      '682509': {'lat': '10.0633', 'name': 'Nedungad,Ernakulam,Kerala', 'lon': '76.2262'},
      '388440': {'lat': '22.8406', 'name': 'Valetva,Kheda,Gujarat', 'lon': '72.9044'},
      '682507': {'lat': '9.9201', 'name': 'Mundamveli,Ernakulam,Kerala', 'lon': '76.2559'},
      '682506': {'lat': '9.9063', 'name': 'Panangad (Ernakulam),Ernakulam,Kerala', 'lon': '76.3113'},
      '682505': {'lat': '10.0099', 'name': 'Narakkal,Ernakulam,Kerala', 'lon': '76.2416'},
      '682504': {'lat': '10.0136', 'name': 'Mulavukad,Ernakulam,Kerala', 'lon': '76.2631'},
      '682503': {'lat': '10.0267', 'name': 'Elamkunnapuzha,Ernakulam,Kerala', 'lon': '76.2223'},
      '682502': {'lat': '10.0837', 'name': 'Edavanakad,Ernakulam,Kerala', 'lon': '76.2095'},
      '682501': {'lat': '10.0099', 'name': 'Ayyampilly,Ernakulam,Kerala', 'lon': '76.2416'},
      '441913': {'lat': '21.3408', 'name': 'Tumsar Road,Bhandara,Maharashtra', 'lon': '79.7846'},
      '441912': {'lat': '21.2663', 'name': 'Umarwada,Bhandara,Maharashtra', 'lon': '79.4428'},
      '441911': {'lat': '21.2266', 'name': 'Gangla,Gondia,Maharashtra', 'lon': '79.8743'},
      '441910': {'lat': '20.7804', 'name': 'Kodurli,Bhandara,Maharashtra', 'lon': '79.664'},
      '441916': {'lat': '21.2455', 'name': 'Lohara,Gondia,Maharashtra', 'lon': '80.4745'},
      '441915': {'lat': '21.4522', 'name': 'Wahani,Bhandara,Maharashtra', 'lon': '79.7907'},
      '441914': {'lat': '21.3928', 'name': 'Jamb,Bhandara,Maharashtra', 'lon': '79.5794'},
      '415112': {'lat': '18.6611', 'name': 'Vanzole,Satara,Maharashtra', 'lon': '75.7968'},
      '321609': {'lat': '27.1609', 'name': 'Banawar,Dausa,Rajasthan', 'lon': '76.6316'},
      '415110': {'lat': '18.6611', 'name': 'Mangalwar Peth Karad,Satara,Maharashtra', 'lon': '75.7968'},
      '415111': {'lat': '18.6611', 'name': 'Salshirambe,Satara,Maharashtra', 'lon': '75.7968'},
      '415116': {'lat': '17.6686', 'name': 'Arvi,Satara,Maharashtra', 'lon': '74.1738'},
      '244921': {'lat': '28.8811', 'name': 'Anwaria Talibabad,Rampur,Uttar Pradesh', 'lon': '79.2337'},
      '415114': {'lat': '18.6611', 'name': 'Beladare,Satara,Maharashtra', 'lon': '75.7968'},
      '415115': {'lat': '18.6611', 'name': 'Koprde Hawali,Satara,Maharashtra', 'lon': '75.7968'},
      '695033': {'lat': '8.5988', 'name': 'PMG Jn,Thiruvananthapuram,Kerala', 'lon': '76.8788'},
      '695032': {'lat': '8.5988', 'name': 'Aramada,Thiruvananthapuram,Kerala', 'lon': '76.8788'},
      '384255': {'lat': '23.8205', 'name': 'Khanpur Rajkuva,Patan,Gujarat', 'lon': '71.9752'},
      '691541': {'lat': '8.815', 'name': 'Kollayil,Kollam,Kerala', 'lon': '77.0164'},
      '695035': {'lat': '8.7143', 'name': 'Vanchiyoor Junction,Thiruvananthapuram,Kerala', 'lon': '76.841'},
      '695034': {'lat': '8.5988', 'name': 'Thiruvananthapuram  University,Thiruvananthapuram,Kerala', 'lon': '76.8788'},
      '306401': {'lat': '25.8937', 'name': 'Bhanwari,Pali,Rajasthan', 'lon': '73.4269'},
      '517419': {'lat': '13.1738', 'name': 'Pachanapalle,Chittoor,Andhra Pradesh', 'lon': '79.2455'},
      '305631': {'lat': '25.9933', 'name': 'Jeewana,Ajmer,Rajasthan', 'lon': '74.416'},
      '611003': {'lat': '10.8706', 'name': 'Palpannaichery,Nagapattinam,Tamil Nadu', 'lon': '79.7716'},
      '841416': {'lat': '26.2331', 'name': 'Khawaspur,Siwan,Bihar', 'lon': '84.6388'},
      '841417': {'lat': '26.1054', 'name': 'Masrakh,Saran,Bihar', 'lon': '84.8026'},
      '841414': {'lat': '25.8269', 'name': 'Afaur,Saran,Bihar', 'lon': '84.8664'},
      '305630': {'lat': '26.1365', 'name': 'Lamba Kund Ka,Ajmer,Rajasthan', 'lon': '74.5892'},
      '841412': {'lat': '25.836', 'name': 'G.S.Bangra,Saran,Bihar', 'lon': '84.8207'},
      '841413': {'lat': '26.19', 'name': 'Hatimpur,Gopalganj,Bihar', 'lon': '84.6629'},
      '841410': {'lat': '26.0264', 'name': 'Piyarepur,Saran,Bihar', 'lon': '84.9759'},
      '841411': {'lat': '25.9449', 'name': 'Bhithi Sahabuddin,Saran,Bihar', 'lon': '84.8563'},
      '611001': {'lat': '10.8706', 'name': 'Nagapattinam H.O,Nagapattinam,Tamil Nadu', 'lon': '79.7716'},
      '203205': {'lat': '28.8173', 'name': 'Kayasthwara  Sikandrabad,Bulandshahr,Uttar Pradesh', 'lon': '77.695'},
      '841418': {'lat': '25.9595', 'name': 'Marhowrah H.O,Saran,Bihar', 'lon': '84.8169'},
      '841419': {'lat': '26.0506', 'name': 'Husseepur,Saran,Bihar', 'lon': '84.8336'},
      '560043': {'lat': '13.0158', 'name': 'Jalavayuvihar,Bengaluru,Karnataka', 'lon': '77.6487'},
      '560042': {'lat': '13.005', 'name': 'Sivan Chetty Gardens,Bengaluru,Karnataka', 'lon': '77.3214'},
      '560041': {'lat': '13.2257', 'name': 'Jayanagar East,Bengaluru,Karnataka', 'lon': '77.575'},
      '388620': {'lat': '22.5333', 'name': 'Shakarpur,Anand,Gujarat', 'lon': '72.0499'},
      '560047': {'lat': '13.005', 'name': 'Viveknagar (Bangalore),Bengaluru,Karnataka', 'lon': '77.3214'},
      '560046': {'lat': '13.2257', 'name': 'Benson Town,Bengaluru,Karnataka', 'lon': '77.575'},
      '388625': {'lat': '22.9129', 'name': 'Dugari,Anand,Gujarat', 'lon': '72.1008'},
      '274403': {'lat': '26.5215', 'name': 'Kushinagar,Kushinagar,Uttar Pradesh', 'lon': '83.7723'},
      '560049': {'lat': '12.9833', 'name': 'Bidrahalli,Bengaluru,Karnataka', 'lon': '77.5833'},
      '560048': {'lat': '13.2257', 'name': 'Mahadevapura,Bengaluru,Karnataka', 'lon': '77.575'},
      '731102': {'lat': '23.6854', 'name': 'Januri,Birbhum,West Bengal', 'lon': '87.7386'},
      '731103': {'lat': '23.7968', 'name': 'Baraalunda,Birbhum,West Bengal', 'lon': '87.6952'},
      '731101': {'lat': '23.7968', 'name': 'Sonatoresuri,Birbhum,West Bengal', 'lon': '87.6952'},
      '285124': {'lat': '26.374', 'name': 'Jaigha,Jalaun,Uttar Pradesh', 'lon': '79.4013'},
      '285125': {'lat': '26.5345', 'name': 'Rampura Kirwaha,Jhansi,Uttar Pradesh', 'lon': '79.3258'},
      '577556': {'lat': '14.3942', 'name': 'Nirthadi,Davangere,Karnataka', 'lon': '76.0667'},
      '752120': {'lat': '20.3627', 'name': 'Nuapalamhat,Cuttack,Odisha', 'lon': '85.6457'},
      '285121': {'lat': '26.1626', 'name': 'Sirsa Dogarhi,Jalaun,Uttar Pradesh', 'lon': '79.1525'},
      '285122': {'lat': '25.8247', 'name': 'Mohana,Jalaun,Uttar Pradesh', 'lon': '79.4507'},
      '285123': {'lat': '26.167', 'name': 'Jalaun Town,Jalaun,Uttar Pradesh', 'lon': '79.3516'},
      '233301': {'lat': '25.7194', 'name': 'Parasa,Ghazipur,Uttar Pradesh', 'lon': '83.7764'},
      '233300': {'lat': '25.8916', 'name': 'Pandepur Radhey,Ghazipur,Uttar Pradesh', 'lon': '83.327'},
      '233303': {'lat': '25.685', 'name': 'Lawa,Ghazipur,Uttar Pradesh', 'lon': '83.6171'},
      '233302': {'lat': '25.5689', 'name': 'Saheri,Ghazipur,Uttar Pradesh', 'lon': '83.453'},
      '285128': {'lat': '26.9368', 'name': 'Sheikhpur Buzurg,Jalaun,Uttar Pradesh', 'lon': '79.4459'},
      '285129': {'lat': '26.327', 'name': 'Ajeetapur,Jalaun,Uttar Pradesh', 'lon': '79.2779'},
      '233307': {'lat': '25.7822', 'name': 'Uchauri,Ghazipur,Uttar Pradesh', 'lon': '82.981'},
      '233306': {'lat': '25.7881', 'name': 'Rampur Majha,Ghazipur,Uttar Pradesh', 'lon': '82.8499'},
      '585213': {'lat': '17.267', 'name': 'Hittal Siroor,Kalaburagi,Karnataka', 'lon': '76.6524'},
      '788722': {'lat': '24.8486', 'name': 'Durganagar,Karimganj,Assam', 'lon': '92.2791'},
      '585211': {'lat': '16.823', 'name': 'Rajola,Kalaburagi,Karnataka', 'lon': '76.8828'},
      '585210': {'lat': '16.8756', 'name': 'Madkol,Kalaburagi,Karnataka', 'lon': '76.7815'},
      '585217': {'lat': '17.311', 'name': 'Udachan,Kalaburagi,Karnataka', 'lon': '76.1695'},
      '585216': {'lat': '16.4916', 'name': 'Hadanoor,Yadgir,Karnataka', 'lon': '76.4223'},
      '585215': {'lat': '16.4605', 'name': 'Hebbal.B,Yadgir,Karnataka', 'lon': '76.506'},
      '585214': {'lat': '16.8905', 'name': 'Chapatla,Yadgir,Karnataka', 'lon': '77.4078'},
      '523253': {'lat': '15.6445', 'name': 'Tripurasundaripuram,Prakasam,Andhra Pradesh', 'lon': '79.7257'},
      '394421': {'lat': '22.9786', 'name': 'Mosali,Surat,Gujarat', 'lon': '73.3161'},
      '394130': {'lat': '20.8937', 'name': 'Segva,Surat,Gujarat', 'lon': '73.2555'},
      '585218': {'lat': '16.9115', 'name': 'Kanganahalli,Kalaburagi,Karnataka', 'lon': '77.0062'},
      '416713': {'lat': '21.4937', 'name': 'Dasur,Ratnagiri,Maharashtra', 'lon': '77.0805'},
      '523254': {'lat': '15.381', 'name': 'Bommireddipalli,Prakasam,Andhra Pradesh', 'lon': '79.5457'},
      '831020': {'lat': '22.5826', 'name': 'Pardih,East Singhbhum,Jharkhand', 'lon': '86.4527'},
      '689678': {'lat': '9.265', 'name': 'Mekozhur,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '344801': {'lat': '25.2709', 'name': 'Juna Mitha Kheda,Barmer,Rajasthan', 'lon': '72.6504'},
      '721232': {'lat': '22.3797', 'name': 'Dingal Kamargeria,West Midnapore,West Bengal', 'lon': '87.4336'},
      '689674': {'lat': '9.265', 'name': 'Pulloopram,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '365410': {'lat': '21.8236', 'name': 'Tramboda,Amreli,Gujarat', 'lon': '71.2478'},
      '689676': {'lat': '9.265', 'name': 'Makkapuzha,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689677': {'lat': '9.265', 'name': 'Chellakkad,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '689671': {'lat': '9.265', 'name': 'Chengalthadom,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '184120': {'lat': '32.6707', 'name': 'Chak Salaria,Jammu,Jammu & Kashmir', 'lon': '74.9983'},
      '788727': {'lat': '24.6949', 'name': 'Bazaricherra Bazar,Karimganj,Assam', 'lon': '92.3182'},
      '763008': {'lat': '20.4682', 'name': 'Litiguda,Koraput,Odisha', 'lon': '83.7161'},
      '344502': {'lat': '25.9741', 'name': 'Tamliyar,Barmer,Rajasthan', 'lon': '71.7535'},
      '481880': {'lat': '22.9674', 'name': 'Kamrasoda,Dindori,Madhya Pradesh', 'lon': '80.8551'},
      '481883': {'lat': '23.4436', 'name': 'Sarvahi,Dindori,Madhya Pradesh', 'lon': '80.7698'},
      '682508': {'lat': '10.0025', 'name': 'Ochanthuruth,Ernakulam,Kerala', 'lon': '76.2364'},
      '481885': {'lat': '22.8637', 'name': 'Deorikala,Mandla,Madhya Pradesh', 'lon': '80.4139'},
      '481884': {'lat': '22.7352', 'name': 'Rusha Raitwar,Dindori,Madhya Pradesh', 'lon': '81.289'},
      '313024': {'lat': '24.7429', 'name': 'Bhalon Ka Guda,Udaipur,Rajasthan', 'lon': '73.8496'},
      '313027': {'lat': '24.228', 'name': 'Saradi,Udaipur,Rajasthan', 'lon': '73.8944'},
      '313026': {'lat': '23.9593', 'name': 'Rathora,Udaipur,Rajasthan', 'lon': '74.0163'},
      '313022': {'lat': '25.0242', 'name': 'Khedi,Udaipur,Rajasthan', 'lon': '74.286'},
      '496225': {'lat': '22.4656', 'name': 'Kurkunga,Jashpur,Chattisgarh', 'lon': '83.7628'},
      '126101': {'lat': '29.3073', 'name': 'Deorer,Jind,Haryana', 'lon': '76.3966'},
      '126102': {'lat': '29.3166', 'name': 'Sindhwi Kheri,Jind,Haryana', 'lon': '76.293'},
      '212601': {'lat': '25.934', 'name': 'Bilandpur,Fatehpur,Uttar Pradesh', 'lon': '80.7632'},
      '160023': {'lat': '30.7465', 'name': 'Sector 23 ( Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7417'},
      '496223': {'lat': '22.3403', 'name': 'Dokra,Jashpur,Chattisgarh', 'lon': '82.6673'},
      '562130': {'lat': '13.1303', 'name': 'Soladevanahalli,Bangalore Rural,Karnataka', 'lon': '77.7495'},
      '606213': {'lat': '11.7464', 'name': 'Neelamangalam,Villupuram,Tamil Nadu', 'lon': '78.9801'},
      '160022': {'lat': '30.7465', 'name': 'Sector 22 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7417'},
      '301714': {'lat': '27.9495', 'name': 'Jalawas,Alwar,Rajasthan', 'lon': '76.4225'},
      '414403': {'lat': '19.2034', 'name': 'Jalalpur,Ahmed Nagar,Maharashtra', 'lon': '74.6769'},
      '414402': {'lat': '19.0052', 'name': 'Durgaon,Ahmed Nagar,Maharashtra', 'lon': '74.5931'},
      '414401': {'lat': '19.1331', 'name': 'Khandvi,Ahmed Nagar,Maharashtra', 'lon': '75.0603'},
      '249181': {'lat': '30.0411', 'name': 'Ghonti,Tehri Garhwal,Uttarakhand', 'lon': '78.997'},
      '465223': {'lat': '23.3803', 'name': 'Polaikhrud,Shajapur,Madhya Pradesh', 'lon': '76.5988'},
      '465220': {'lat': '23.3967', 'name': 'Dasta Khedi,Shajapur,Madhya Pradesh', 'lon': '76.5021'},
      '307019': {'lat': '24.954', 'name': 'Jkpuram,Sirohi,Rajasthan', 'lon': '73.1047'},
      '713103': {'lat': '23.9328', 'name': 'Chotonilpur,Purba Bardhaman,West Bengal', 'lon': '87.8392'},
      '713101': {'lat': '24.105', 'name': 'Bajepratappur NDDF,Purba Bardhaman,West Bengal', 'lon': '87.8869'},
      '713104': {'lat': '24.1486', 'name': 'Burdwan Rajbati,Purba Bardhaman,West Bengal', 'lon': '87.8041'},
      '276001': {'lat': '26.0037', 'name': 'Azamgarh name,Azamgarh,Uttar Pradesh', 'lon': '82.9819'},
      '628304': {'lat': '8.9427', 'name': 'Keelatattaparai,Tuticorin,Tamil Nadu', 'lon': '77.8177'},
      '230144': {'lat': '25.8619', 'name': 'Randvir,Pratapgarh,Uttar Pradesh', 'lon': '81.8496'},
      '763003': {'lat': '18.7920679', 'name': 'D.P.Camp,21,Sunabeda', 'lon': '82.7190605'},
      '628301': {'lat': '9.0787', 'name': 'Kandasamypuram,Tuticorin,Tamil Nadu', 'lon': '77.7107'},
      '230143': {'lat': '25.8645', 'name': 'Purmeisultanpur,Pratapgarh,Uttar Pradesh', 'lon': '81.6392'},
      '522256': {'lat': '16.1026', 'name': 'Bhattiprolu,Guntur,Andhra Pradesh', 'lon': '80.7807'},
      '522257': {'lat': '16.1209', 'name': 'Pesarlanka,Guntur,Andhra Pradesh', 'lon': '79.6508'},
      '522258': {'lat': '15.5588', 'name': 'Kolaganivaripalem,Guntur,Andhra Pradesh', 'lon': '79.888'},
      '522259': {'lat': '15.2797', 'name': 'Kanagala (Guntur),Guntur,Andhra Pradesh', 'lon': '79.3627'},
      '249185': {'lat': '30.8746', 'name': 'Gaddugaad,Uttarkashi,Uttarakhand', 'lon': '78.0773'},
      '516454': {'lat': '14.5969', 'name': 'B Cherlopalle,Cuddapah,Andhra Pradesh', 'lon': '78.1173'},
      '246469': {'lat': '30.5152', 'name': 'Fafanj,Rudraprayag,Uttarakhand', 'lon': '79.2159'},
      '382481': {'lat': '23.9941', 'name': 'Chandlodia,Ahmedabad,Gujarat', 'lon': '73.063'},
      '382480': {'lat': '23.0801', 'name': 'Ranip,Ahmedabad,Gujarat', 'lon': '72.5718'},
      '844127': {'lat': '25.9788', 'name': 'Kinaru,Muzaffarpur,Bihar', 'lon': '85.6789'},
      '786010': {'lat': '26.5654', 'name': 'Timonagaon,Dibrugarh,Assam', 'lon': '94.0443'},
      '844121': {'lat': '25.8689', 'name': 'Lalganj (Vaishali),Vaishali,Bihar', 'lon': '85.1739'},
      '844120': {'lat': '25.8453', 'name': 'M. Afzalpur,Vaishali,Bihar', 'lon': '85.4744'},
      '844123': {'lat': '25.9118', 'name': 'Jarang Rampur,Vaishali,Bihar', 'lon': '85.225'},
      '844122': {'lat': '25.9016', 'name': 'Mirza Nagar,Vaishali,Bihar', 'lon': '85.2657'},
      '224206': {'lat': '26.3546', 'name': 'Nuawan Baidara,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.3651'},
      '625562': {'lat': '9.6187', 'name': 'Rengasamudram,Theni,Tamil Nadu', 'lon': '77.7572'},
      '844128': {'lat': '25.8713', 'name': 'Rahimpur Jagdish,Vaishali,Bihar', 'lon': '85.307'},
      '321605': {'lat': '27.4727', 'name': 'Mathuraheda,Alwar,Rajasthan', 'lon': '77.0705'},
      '241202': {'lat': '26.4759', 'name': 'Mahitha,Hardoi,Uttar Pradesh', 'lon': '81.3662'},
      '848205': {'lat': '25.7816', 'name': 'Hassanpur S. Mill,Samastipur,Bihar', 'lon': '86.0554'},
      '321606': {'lat': '27.2934', 'name': 'Sonkher,Alwar,Rajasthan', 'lon': '76.7897'},
      '321607': {'lat': '27.3526', 'name': 'Khudiana,Alwar,Rajasthan', 'lon': '76.9188'},
      '602026': {'lat': '13.31', 'name': 'Uttukottai,Tiruvallur,Tamil Nadu', 'lon': '79.9206'},
      '241204': {'lat': '26.1176', 'name': 'Atia Sahpur,Hardoi,Uttar Pradesh', 'lon': '80.5631'},
      '321608': {'lat': '26.3314', 'name': 'Norangwada,Dausa,Rajasthan', 'lon': '75.9027'},
      '796410': {'lat': '23.8103', 'name': 'Ptc Lungverh,Aizawl,Mizoram', 'lon': '92.6523'},
      '361220': {'lat': '22.6415', 'name': 'Jiragadh,Jamnagar,Gujarat', 'lon': '70.593'},
      '743349': {'lat': '22.1125', 'name': 'Katamari,South 24 Parganas,West Bengal', 'lon': '88.4393'},
      '765033': {'lat': '20.5703', 'name': 'Laba,Rayagada,Odisha', 'lon': '83.4873'},
      '843316': {'lat': '26.1691', 'name': 'Sauli,Sitamarhi,Bihar', 'lon': '85.5044'},
      '843315': {'lat': '26.4648', 'name': 'S. Kala,Sitamarhi,Bihar', 'lon': '85.2183'},
      '843314': {'lat': '25.9073', 'name': 'B.Phulwariya,Sitamarhi,Bihar', 'lon': '85.2849'},
      '600068': {'lat': '13.0063', 'name': 'Manali (Tiruvallur),Tiruvallur,Tamil Nadu', 'lon': '79.8869'},
      '636704': {'lat': '12.14', 'name': 'Mittanoolahalli,Dharmapuri,Tamil Nadu', 'lon': '78.1849'},
      '843311': {'lat': '25.8889', 'name': 'Gisara,Sitamarhi,Bihar', 'lon': '85.8324'},
      '765034': {'lat': '21.7642', 'name': 'Marma,Rayagada,Odisha', 'lon': '85.5283'},
      '761107': {'lat': '19.9492', 'name': 'D.Singipur,Ganjam,Odisha', 'lon': '84.7939'},
      '636705': {'lat': '10.6847', 'name': 'Ungaranahalli,Dharmapuri,Tamil Nadu', 'lon': '78.8305'},
      '761106': {'lat': '19.5673', 'name': 'K.Karadakana,Ganjam,Odisha', 'lon': '84.5844'},
      '211015': {'lat': '25.2989', 'name': 'Subedarganj,Allahabad,Uttar Pradesh', 'lon': '81.9717'},
      '602023': {'lat': '12.8985', 'name': 'Allikuzhi,Tiruvallur,Tamil Nadu', 'lon': '79.8127'},
      '761105': {'lat': '19.5859', 'name': 'Athagadapatna,Ganjam,Odisha', 'lon': '85.1649'},
      '224145': {'lat': '26.5494', 'name': 'Faridpur Kalan,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5215'},
      '761104': {'lat': '20.1268', 'name': 'Ghodapolan,Ganjam,Odisha', 'lon': '84.8777'},
      '743348': {'lat': '22.0184', 'name': 'Dakshin Rajarampur,South 24 Parganas,West Bengal', 'lon': '88.2409'},
      '400003': {'lat': '18.95', 'name': 'Masjid,Mumbai,Maharashtra', 'lon': '72.8333'},
      '802206': {'lat': '25.4512', 'name': 'Sakhuan,Bhojpur,Bihar', 'lon': '84.629'},
      '761103': {'lat': '20.2503', 'name': 'Tanhara,Ganjam,Odisha', 'lon': '85.0122'},
      '625301': {'lat': '10.0329', 'name': 'Kidaripatti,Madurai,Tamil Nadu', 'lon': '78.2224'},
      '761102': {'lat': '19.4817', 'name': 'Sikiri,Ganjam,Odisha', 'lon': '84.7449'},
      '221706': {'lat': '25.9475', 'name': 'Ailakhaimilia,Mau,Uttar Pradesh', 'lon': '83.773'},
      '721133': {'lat': '22.4364', 'name': 'Gaganeswar,West Midnapore,West Bengal', 'lon': '87.9624'},
      '221705': {'lat': '25.872', 'name': 'Inderpur Bhalya,Mau,Uttar Pradesh', 'lon': '83.6745'},
      '761101': {'lat': '19.9492', 'name': 'Durabandha,Ganjam,Odisha', 'lon': '84.7939'},
      '416138': {'lat': '16.4697', 'name': 'Chandur,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '670327': {'lat': '12.0418', 'name': 'Edat,Kannur,Kerala', 'lon': '75.3473'},
      '670325': {'lat': '12.0418', 'name': 'Mattul North,Kannur,Kerala', 'lon': '75.3473'},
      '221709': {'lat': '26.2157', 'name': 'Isari Salempur,Ballia,Uttar Pradesh', 'lon': '83.4757'},
      '522661': {'lat': '16.2887', 'name': 'Muppalla(VKD),Guntur,Andhra Pradesh', 'lon': '79.8337'},
      '522660': {'lat': '15.9409', 'name': 'Ramudipalem,Guntur,Andhra Pradesh', 'lon': '79.7607'},
      '522663': {'lat': '16.2331', 'name': 'Gandiganumala,Guntur,Andhra Pradesh', 'lon': '79.6429'},
      '695030': {'lat': '8.5988', 'name': 'Kanjirampara,Thiruvananthapuram,Kerala', 'lon': '76.8788'},
      '802207': {'lat': '25.2778', 'name': 'Dhrubdihan,Bhojpur,Bihar', 'lon': '84.5156'},
      '824220': {'lat': '24.5116', 'name': 'Kurmama,Gaya,Bihar', 'lon': '84.9081'},
      '341510': {'lat': '26.3512', 'name': 'Dangawas,Nagaur,Rajasthan', 'lon': '74.0496'},
      '507305': {'lat': '17.2578', 'name': 'Kondavanamala,Khammam,Telangana', 'lon': '79.8908'},
      '695036': {'lat': '8.4833', 'name': 'Thiruvananthapuram  Chalai,Thiruvananthapuram,Kerala', 'lon': '76.9167'},
      '382225': {'lat': '23.0933', 'name': 'Sindharej,Ahmedabad,Gujarat', 'lon': '73.1752'},
      '581115': {'lat': '14.8033', 'name': 'Ranebennur Bzr,Haveri,Karnataka', 'lon': '75.8267'},
      '581440': {'lat': '14.4727', 'name': 'Antravalli,Uttara Kannada,Karnataka', 'lon': '74.4685'},
      '581117': {'lat': '15.1902', 'name': 'Hiregunjal,Dharwad,Karnataka', 'lon': '75.3382'},
      '581110': {'lat': '14.6293', 'name': 'Ijarilakmapur,Haveri,Karnataka', 'lon': '75.5138'},
      '382220': {'lat': '23.0714', 'name': 'Juval Rupavati,Ahmedabad,Gujarat', 'lon': '72.4824'},
      '581112': {'lat': '14.4458', 'name': 'Hiremugadur,Haveri,Karnataka', 'lon': '75.3303'},
      '581113': {'lat': '14.9423', 'name': 'Hirenarthi,Dharwad,Karnataka', 'lon': '75.0702'},
      '736205': {'lat': '25.5088', 'name': 'New Lands,Alipurduar,West Bengal', 'lon': '88.582'},
      '581118': {'lat': '14.9686', 'name': 'Huvinashigli,Haveri,Karnataka', 'lon': '75.3188'},
      '581119': {'lat': '14.5051', 'name': 'Ukkadagatri,Davangere,Karnataka', 'lon': '75.5724'},
      '796431': {'lat': '23.5326', 'name': 'Parvatui,Mammit,Mizoram', 'lon': '92.4493'},
      '423401': {'lat': '19.9475', 'name': 'Erandgaon Jeur,Nashik,Maharashtra', 'lon': '74.5417'},
      '423403': {'lat': '20.1903', 'name': 'Matulthan,Nashik,Maharashtra', 'lon': '74.5463'},
      '423402': {'lat': '20.0121', 'name': 'Angulgaon,Nashik,Maharashtra', 'lon': '74.5732'},
      '802204': {'lat': '25.2152', 'name': 'Sheopur,Bhojpur,Bihar', 'lon': '84.5477'},
      '721659': {'lat': '22.9706', 'name': 'Barsimulbari,East Midnapore,West Bengal', 'lon': '88.0291'},
      '824221': {'lat': '24.6688', 'name': 'Kelakala,Gaya,Bihar', 'lon': '84.5056'},
      '721658': {'lat': '22.187', 'name': 'Baroda,East Midnapore,West Bengal', 'lon': '88.1068'},
      '207244': {'lat': '27.0839', 'name': 'Raja Ka Rampur,Etah,Uttar Pradesh', 'lon': '79.1286'},
      '624622': {'lat': '10.8745', 'name': 'Pappanampatti,Coimbatore,Tamil Nadu', 'lon': '76.9756'},
      '695038': {'lat': '8.5988', 'name': 'PTP Nagar,Thiruvananthapuram,Kerala', 'lon': '76.8788'},
      '422208': {'lat': '20.1445', 'name': 'Nirgude,Nashik,Maharashtra', 'lon': '73.7644'},
      '422209': {'lat': '20.3129', 'name': 'Sakorewani,Nashik,Maharashtra', 'lon': '74.7226'},
      '734012': {'lat': '26.7111', 'name': 'Ranidanga,Darjiling,West Bengal', 'lon': '88.3462'},
      '422204': {'lat': '20.4939', 'name': 'Kulwandi,Nashik,Maharashtra', 'lon': '74.9598'},
      '422205': {'lat': '20.3075', 'name': 'Bopegaon,Nashik,Maharashtra', 'lon': '74.1929'},
      '422206': {'lat': '20.0947', 'name': 'Ojhar,Nashik,Maharashtra', 'lon': '73.9282'},
      '422207': {'lat': '20.3389', 'name': 'Korahte,Nashik,Maharashtra', 'lon': '76.2302'},
      '422201': {'lat': '20.0225', 'name': 'Kherwadi R.S,Nashik,Maharashtra', 'lon': '74.0014'},
      '422202': {'lat': '20.2567', 'name': 'Pade,Nashik,Maharashtra', 'lon': '74.8934'},
      '422203': {'lat': '20.396', 'name': 'Vilwandi,Nashik,Maharashtra', 'lon': '75.5251'},
      '758079': {'lat': '21.4385', 'name': 'Somagiri,Kendujhar,Odisha', 'lon': '85.6054'},
      '758078': {'lat': '21.2745', 'name': 'Raighati,Kendujhar,Odisha', 'lon': '85.4372'},
      '248198': {'lat': '30.4694', 'name': 'Vikasnagar (Dehradun),Dehradun,Uttarakhand', 'lon': '77.7728'},
      '248199': {'lat': '30.8621', 'name': 'Birnad,Dehradun,Uttarakhand', 'lon': '77.8182'},
      '248196': {'lat': '30.5968', 'name': 'Bullard,Dehradun,Uttarakhand', 'lon': '78.0026'},
      '248197': {'lat': '30.4158', 'name': 'Tilwari,Dehradun,Uttarakhand', 'lon': '77.9086'},
      '224164': {'lat': '26.2357', 'name': 'Parsawan Khurd,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.106'},
      '758076': {'lat': '21.0669', 'name': 'Raisuan Patkhuli,Kendujhar,Odisha', 'lon': '85.371'},
      '401405': {'lat': '19.0597', 'name': 'Veur,Thane,Maharashtra', 'lon': '72.8677'},
      '401404': {'lat': '19.2259', 'name': 'Palghar H.O,Thane,Maharashtra', 'lon': '72.8782'},
      '845418': {'lat': '26.189', 'name': 'Ruphara,East Champaran,Bihar', 'lon': '85.8326'},
      '602117': {'lat': '12.995', 'name': 'Irungattukottai,Kanchipuram,Tamil Nadu', 'lon': '79.9905'},
      '401401': {'lat': '19.2259', 'name': 'Kelwa Road,Thane,Maharashtra', 'lon': '72.8782'},
      '262540': {'lat': '29.8238', 'name': 'Dhaulkanda,Pithoragarh,Uttarakhand', 'lon': '79.7979'},
      '401403': {'lat': '19.7244', 'name': 'Kurze,Thane,Maharashtra', 'lon': '72.9097'},
      '401402': {'lat': '19.2259', 'name': 'Kelwa-mahim,Thane,Maharashtra', 'lon': '72.8782'},
      '845412': {'lat': '26.3709', 'name': 'Balo Chak,East Champaran,Bihar', 'lon': '84.9916'},
      '845413': {'lat': '25.8728', 'name': 'Ahiman Chapra,East Champaran,Bihar', 'lon': '84.6448'},
      '845411': {'lat': '26.2847', 'name': 'Ghiwadhar,East Champaran,Bihar', 'lon': '84.7426'},
      '845416': {'lat': '26.5431', 'name': 'Prasurampur,East Champaran,Bihar', 'lon': '84.9441'},
      '845417': {'lat': '26.3139', 'name': 'Madchhargawa,East Champaran,Bihar', 'lon': '84.5685'},
      '603101': {'lat': '12.767', 'name': 'Thimmavaram,Kanchipuram,Tamil Nadu', 'lon': '79.9148'},
      '845415': {'lat': '26.1764', 'name': 'Baidhnathpur,East Champaran,Bihar', 'lon': '84.8164'},
      '686616': {'lat': '10.4995', 'name': 'Newsprint Nagar,Kottayam,Kerala', 'lon': '76.2679'},
      '841415': {'lat': '25.868', 'name': 'Fherusa,Saran,Bihar', 'lon': '84.8818'},
      '686613': {'lat': '10.4995', 'name': 'Ezhumanthuruth,Kottayam,Kerala', 'lon': '76.2679'},
      '686612': {'lat': '10.4995', 'name': 'Thiruvampady BO,Kottayam,Kerala', 'lon': '76.2679'},
      '389370': {'lat': '22.4232', 'name': 'Vaghbod,Panch Mahals,Gujarat', 'lon': '73.6086'},
      '686610': {'lat': '10.4995', 'name': 'Karikode,Kottayam,Kerala', 'lon': '76.2679'},
      '456661': {'lat': '23.2256', 'name': 'Harsodhan,Ujjain,Madhya Pradesh', 'lon': '75.9032'},
      '456665': {'lat': '23.6244', 'name': 'KATHBAROD,Ujjain,Madhya Pradesh', 'lon': '76.244'},
      '456664': {'lat': '23.2262', 'name': 'Chandesara,Ujjain,Madhya Pradesh', 'lon': '75.8305'},
      '854112': {'lat': '25.5236', 'name': 'Amdabad,Katihar,Bihar', 'lon': '87.5217'},
      '518422': {'lat': '15.545', 'name': 'Vadlaramapuram,Kurnool,Andhra Pradesh', 'lon': '78.0233'},
      '454660': {'lat': '22.7945', 'name': 'Kachibadoda,Dhar,Madhya Pradesh', 'lon': '75.4092'},
      '759018': {'lat': '21.111', 'name': 'Muktapasi,Dhenkanal,Odisha', 'lon': '85.408'},
      '191111': {'lat': '33.9245', 'name': 'Arath,Budgam,Jammu & Kashmir', 'lon': '74.6725'},
      '671324': {'lat': '12.6516', 'name': 'Permude,Kasargod,Kerala', 'lon': '74.9722'},
      '191113': {'lat': '33.8072', 'name': 'R N Hall,Budgam,Jammu & Kashmir', 'lon': '74.8689'},
      '191112': {'lat': '33.8463', 'name': 'Keller Mastpora,Pulwama,Jammu & Kashmir', 'lon': '74.8035'},
      '671321': {'lat': '12.5196', 'name': 'Nirchal,Kasargod,Kerala', 'lon': '75.0296'},
      '671323': {'lat': '12.7364', 'name': 'Pavoor,Kasargod,Kerala', 'lon': '74.9292'},
      '671322': {'lat': '12.6844', 'name': 'Kayyaru,Kasargod,Kerala', 'lon': '74.9007'},
      '784184': {'lat': '26.7887', 'name': 'Gingia,Sonitpur,Assam', 'lon': '92.965'},
      '473551': {'lat': '25.495', 'name': 'Raishree,Shivpuri,Madhya Pradesh', 'lon': '77.7065'},
      '784182': {'lat': '26.7887', 'name': 'Towbhanga,Sonitpur,Assam', 'lon': '92.965'},
      '627759': {'lat': '9.4451', 'name': 'Krishnapuram (TVL),Tirunelveli,Tamil Nadu', 'lon': '77.6777'},
      '784180': {'lat': '26.7887', 'name': 'Nandikeswar,Sonitpur,Assam', 'lon': '92.965'},
      '441210': {'lat': '20.9786', 'name': 'Gohna,Nagpur,Maharashtra', 'lon': '79.4057'},
      '225123': {'lat': '24.9076', 'name': 'Para Khandauli,Barabanki,Uttar Pradesh', 'lon': '82.2618'},
      '441212': {'lat': '20.507', 'name': 'Rajoli (Chandrapur),Chandrapur,Maharashtra', 'lon': '79.6121'},
      '627758': {'lat': '9.2374', 'name': 'Nelkattumseval,Tirunelveli,Tamil Nadu', 'lon': '77.4617'},
      '441214': {'lat': '20.6844', 'name': 'Sirsi (Nagpur),Nagpur,Maharashtra', 'lon': '79.2486'},
      '176401': {'lat': '32.1223', 'name': 'Surarwan,Kangra,Himachal Pradesh', 'lon': '75.7306'},
      '176402': {'lat': '32.2078', 'name': 'Bhojpur,Kangra,Himachal Pradesh', 'lon': '75.7122'},
      '784189': {'lat': '26.7887', 'name': 'Karsontala,Sonitpur,Assam', 'lon': '92.965'},
      '622505': {'lat': '10.2313', 'name': 'Ramachandrapuram (Pudukkottai),Pudukkottai,Tamil Nadu', 'lon': '78.7837'},
      '754153': {'lat': '20.3865', 'name': 'Kalabuda,Kendrapara,Odisha', 'lon': '86.3714'},
      '622504': {'lat': '10.5813', 'name': 'Kulathur (Pudukkottai),Pudukkottai,Tamil Nadu', 'lon': '78.8155'},
      '622507': {'lat': '10.2562', 'name': 'Thulayanur,Pudukkottai,Tamil Nadu', 'lon': '78.75'},
      '534239': {'lat': '16.461', 'name': 'Gollavanitippa,West Godavari,Andhra Pradesh', 'lon': '81.5194'},
      '534238': {'lat': '16.5556', 'name': 'Andaluru,West Godavari,Andhra Pradesh', 'lon': '81.4012'},
      '627754': {'lat': '8.9912', 'name': 'Perungottur,Tirunelveli,Tamil Nadu', 'lon': '77.494'},
      '144406': {'lat': '31.3584', 'name': 'Dihana,Hoshiarpur,Punjab', 'lon': '75.8943'},
      '625529': {'lat': '9.8867', 'name': 'Thummagundu,Madurai,Tamil Nadu', 'lon': '77.3673'},
      '362140': {'lat': '22.4342', 'name': 'Akolvadi,Junagadh,Gujarat', 'lon': '70.4791'},
      '630709': {'lat': '9.6804', 'name': 'Sathani,Sivaganga,Tamil Nadu', 'lon': '78.6076'},
      '534230': {'lat': '16.5276', 'name': 'Aravalli,West Godavari,Andhra Pradesh', 'lon': '81.109'},
      '123501': {'lat': '28.1297', 'name': 'Naichana,Rewari,Haryana', 'lon': '76.5558'},
      '534235': {'lat': '16.5921', 'name': 'Sidhapuram,West Godavari,Andhra Pradesh', 'lon': '81.3792'},
      '508217': {'lat': '17.1309', 'name': 'Settipalem,Nalgonda,Telangana', 'lon': '79.5917'},
      '534237': {'lat': '16.5247', 'name': 'Doddanapudi,West Godavari,Andhra Pradesh', 'lon': '81.3949'},
      '534236': {'lat': '16.4623', 'name': 'Kalavapudi,West Godavari,Andhra Pradesh', 'lon': '81.3887'},
      '571130': {'lat': '12.3854', 'name': 'Kallur Naganahalli,Mysuru,Karnataka', 'lon': '76.5382'},
      '560040': {'lat': '13.005', 'name': 'Chandra Lay Out,Bengaluru,Karnataka', 'lon': '77.3214'},
      '571134': {'lat': '12.3886', 'name': 'Kattemalalavadi,Mysuru,Karnataka', 'lon': '76.252'},
      '121015': {'lat': '28.4873', 'name': 'Sector 55,Faridabad,Haryana', 'lon': '77.1724'},
      '121014': {'lat': '28.4873', 'name': 'Greater Faridabad,Faridabad,Haryana', 'lon': '77.1724'},
      '121013': {'lat': '28.4873', 'name': 'Sec-91,Faridabad,Haryana', 'lon': '77.1724'},
      '121012': {'lat': '28.4873', 'name': 'Sector-21D ,Faridabad,Haryana', 'lon': '77.1724'},
      '121010': {'lat': '28.4873', 'name': 'Nhpc Colony Faridabad,Faridabad,Haryana', 'lon': '77.1724'},
      '560045': {'lat': '13.005', 'name': 'Venkateshapura,Bengaluru,Karnataka', 'lon': '77.3214'},
      '625605': {'lat': '9.121', 'name': 'Murugamalainagar,Theni,Tamil Nadu', 'lon': '77.4532'},
      '134103': {'lat': '30.7987', 'name': 'I.T.B.P.  Bhanu,Panchkula,Haryana', 'lon': '76.9182'},
      '134102': {'lat': '30.7987', 'name': 'Mallah,Panchkula,Haryana', 'lon': '76.9182'},
      '134101': {'lat': '30.7987', 'name': 'H.M.T.Pinjore,Panchkula,Haryana', 'lon': '76.9182'},
      '636114': {'lat': '11.9178', 'name': 'Idayapatti,Salem,Tamil Nadu', 'lon': '78.4994'},
      '134107': {'lat': '30.7987', 'name': 'Chandi Mandir Cantt,Panchkula,Haryana', 'lon': '76.9182'},
      '134105': {'lat': '30.7987', 'name': 'Amravati Enclave,Panchkula,Haryana', 'lon': '76.9182'},
      '134104': {'lat': '30.7987', 'name': 'C.R.P.F. Pinjore,Panchkula,Haryana', 'lon': '76.9182'},
      '341031': {'lat': '26.6039', 'name': 'Sudwar,Nagaur,Rajasthan', 'lon': '75.1306'},
      '341030': {'lat': '27.1347', 'name': 'Pharrod,Nagaur,Rajasthan', 'lon': '74.0659'},
      '134109': {'lat': '30.7987', 'name': 'Railly,Panchkula,Haryana', 'lon': '76.9182'},
      '134108': {'lat': '30.7987', 'name': 'G.K. Panchkula,Panchkula,Haryana', 'lon': '76.9182'},
      '441217': {'lat': '20.3273', 'name': 'Delanwadi,Gadchiroli,Maharashtra', 'lon': '80.1331'},
      '636112': {'lat': '11.6598', 'name': 'Sheepfarm,Salem,Tamil Nadu', 'lon': '78.6427'},
      '793021': {'lat': '25.556', 'name': 'Sohryngkham,East Khasi Hills,Meghalaya', 'lon': '92.0616'},
      '768105': {'lat': '21.2332', 'name': 'Birsinghgarh,Sambalpur,Odisha', 'lon': '84.1382'},
      '516130': {'lat': '13.7464', 'name': 'Peddabalija Palli,Cuddapah,Andhra Pradesh', 'lon': '79.0543'},
      '793022': {'lat': '25.556', 'name': 'Nehu,East Khasi Hills,Meghalaya', 'lon': '92.0616'},
      '768102': {'lat': '21.3256', 'name': 'Kultatukura,Bargarh,Odisha', 'lon': '83.8347'},
      '680654': {'lat': '10.5475', 'name': 'Vellanikkara,Thrissur,Kerala', 'lon': '76.2583'},
      '133104': {'lat': '30.3196', 'name': 'Nahoni,Ambala,Haryana', 'lon': '76.969'},
      '246449': {'lat': '29.9869', 'name': 'Kotkandara,Chamoli,Uttarakhand', 'lon': '78.9108'},
      '133102': {'lat': '30.2396', 'name': 'Pasiala,Ambala,Haryana', 'lon': '76.8943'},
      '133103': {'lat': '30.2769', 'name': 'Gudhiana,Yamuna Nagar,Haryana', 'lon': '77.1363'},
      '133101': {'lat': '30.2757', 'name': 'Keshopur Samalkha,Ambala,Haryana', 'lon': '76.9551'},
      '160014': {'lat': '31.0594', 'name': 'Tira,Rupnagar,Punjab', 'lon': '76.4864'},
      '160015': {'lat': '30.7728', 'name': 'Sector 16 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7352'},
      '160016': {'lat': '30.7728', 'name': 'Nodal Delivery Office Chandigarh,Chandigarh,Chandigarh', 'lon': '76.7352'},
      '160017': {'lat': '30.7728', 'name': 'Chandigarh G.P.O.,Chandigarh,Chandigarh', 'lon': '76.7352'},
      '160011': {'lat': '30.7728', 'name': 'Sector 10 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7352'},
      '160012': {'lat': '30.7728', 'name': 'Sector 12 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7352'},
      '576233': {'lat': '13.9726', 'name': 'Chittoor,Udupi,Karnataka', 'lon': '74.8439'},
      '160018': {'lat': '30.7728', 'name': 'Sector 18 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7352'},
      '160019': {'lat': '30.7728', 'name': 'Sector 19 (Chandigarh),Chandigarh,Chandigarh', 'lon': '76.7352'},
      '788733': {'lat': '24.5998', 'name': 'Govindaganj Bazar,Karimganj,Assam', 'lon': '92.4019'},
      '788735': {'lat': '24.5998', 'name': 'Tongibari,Hailakandi,Assam', 'lon': '92.4019'},
      '680653': {'lat': '10.552', 'name': 'Peechi,Thrissur,Kerala', 'lon': '76.3017'},
      '788737': {'lat': '24.5998', 'name': 'Ghambhira Basti,Hailakandi,Assam', 'lon': '92.4019'},
      '788736': {'lat': '24.5998', 'name': 'Vetarband,Hailakandi,Assam', 'lon': '92.4019'},
      '602001': {'lat': '13.0553', 'name': 'Tiruvallur H.O,Tiruvallur,Tamil Nadu', 'lon': '79.906'},
      '680652': {'lat': '10.547', 'name': 'Pattikkad TC,Thrissur,Kerala', 'lon': '76.3448'},
      '752121': {'lat': '20.3627', 'name': 'Dovar,Puri,Odisha', 'lon': '85.6457'},
      '391330': {'lat': '22.3582', 'name': 'Sherkhi,Vadodara,Gujarat', 'lon': '73.114'},
      '285127': {'lat': '26.2663', 'name': 'Rudrapura,Jalaun,Uttar Pradesh', 'lon': '79.1618'},
      '721516': {'lat': '23.2906', 'name': 'Kharikasuli,West Midnapore,West Bengal', 'lon': '87.1338'},
      '384130': {'lat': '24.2088', 'name': 'Mahervada,Mahesana,Gujarat', 'lon': '72.9726'},
      '225124': {'lat': '26.5795', 'name': 'Chaubeesi,Barabanki,Uttar Pradesh', 'lon': '81.4316'},
      '442101': {'lat': '20.4723', 'name': 'Nandora Dafre,Wardha,Maharashtra', 'lon': '78.3311'},
      '534341': {'lat': '17.1463', 'name': 'Pochavaram,West Godavari,Andhra Pradesh', 'lon': '81.6369'},
      '442102': {'lat': '20.6021', 'name': 'Kopra,Wardha,Maharashtra', 'lon': '78.8801'},
      '442105': {'lat': '20.7424', 'name': 'Sindi,Wardha,Maharashtra', 'lon': '78.5871'},
      '442104': {'lat': '20.6181', 'name': 'Sukli Station,Wardha,Maharashtra', 'lon': '78.9172'},
      '442107': {'lat': '20.6163', 'name': 'Sawangi Meghe,Wardha,Maharashtra', 'lon': '78.5359'},
      '679535': {'lat': '10.579', 'name': 'Chathanur,Palakkad,Kerala', 'lon': '76.1631'},
      '415637': {'lat': '17.3411', 'name': 'Talekante,Ratnagiri,Maharashtra', 'lon': '73.3727'},
      '625602': {'lat': '10.0231', 'name': 'Sathakoilpatti,Theni,Tamil Nadu', 'lon': '77.4671'},
      '415634': {'lat': '17.551', 'name': 'Anjanvel,Ratnagiri,Maharashtra', 'lon': '73.1457'},
      '232339': {'lat': '25.4804', 'name': 'Basuka,Ghazipur,Uttar Pradesh', 'lon': '83.7426'},
      '533223': {'lat': '17.4366', 'name': 'Devarapalli,East Godavari,Andhra Pradesh', 'lon': '82.8914'},
      '533222': {'lat': '16.5533', 'name': 'Cheyyuru,East Godavari,Andhra Pradesh', 'lon': '82.0975'},
      '533221': {'lat': '16.9362', 'name': 'Sakurru,East Godavari,Andhra Pradesh', 'lon': '82.2643'},
      '533220': {'lat': '16.6589', 'name': 'T.Kothapalli,East Godavari,Andhra Pradesh', 'lon': '82.1875'},
      '144602': {'lat': '31.3536', 'name': 'Rail Coach Factory,Kapurthala,Punjab', 'lon': '75.3683'},
      '627357': {'lat': '8.7599', 'name': 'Naranammalpuram,Tirunelveli,Tamil Nadu', 'lon': '77.7399'},
      '533229': {'lat': '17.096', 'name': 'Chirata Pudi,East Godavari,Andhra Pradesh', 'lon': '81.8809'},
      '533228': {'lat': '16.9362', 'name': 'Billakurru,East Godavari,Andhra Pradesh', 'lon': '82.2643'},
      '144601': {'lat': '31.3801', 'name': 'Kapurthala H.O,Kapurthala,Punjab', 'lon': '75.3811'},
      '203206': {'lat': '28.5182', 'name': 'Til Begum  Pur,Bulandshahr,Uttar Pradesh', 'lon': '77.6454'},
      '753002': {'lat': '20.2151', 'name': 'Chandinchowk H.O,Cuttack,Odisha', 'lon': '85.0791'},
      '756127': {'lat': '20.4922', 'name': 'Moharampur,Bhadrak,Odisha', 'lon': '85.0665'},
      '756126': {'lat': '21.3168', 'name': 'Rudhunga,Baleswar,Odisha', 'lon': '86.2219'},
      '756125': {'lat': '20.5917', 'name': 'Mandari,Bhadrak,Odisha', 'lon': '85.9124'},
      '756124': {'lat': '21.6304', 'name': 'Ertal,Bhadrak,Odisha', 'lon': '85.062'},
      '394305': {'lat': '21.1542', 'name': 'Mohni,Surat,Gujarat', 'lon': '72.9614'},
      '756122': {'lat': '20.0865', 'name': 'Nerada,Bhadrak,Odisha', 'lon': '85.0847'},
      '756121': {'lat': '21.2725', 'name': 'Kulana,Bhadrak,Odisha', 'lon': '85.1833'},
      '756120': {'lat': '20.5828', 'name': 'Bhandari Pokhari,Bhadrak,Odisha', 'lon': '86.2071'},
      '756134': {'lat': '20.71', 'name': 'Ada,Bhadrak,Odisha', 'lon': '85.2004'},
      '627354': {'lat': '8.534', 'name': 'Alwaneri,Tirunelveli,Tamil Nadu', 'lon': '77.7473'},
      '756129': {'lat': '20.3801', 'name': 'Biriadia,Bhadrak,Odisha', 'lon': '85.6327'},
      '756128': {'lat': '20.8246', 'name': 'Aharapada,Bhadrak,Odisha', 'lon': '85.6828'},
      '401610': {'lat': '19.7623', 'name': 'Dapchari,Thane,Maharashtra', 'lon': '73.5785'},
      '365645': {'lat': '21.3574', 'name': 'Kadaya,Amreli,Gujarat', 'lon': '70.9044'},
      '284402': {'lat': '24.7756', 'name': 'Kailguwan,Lalitpur,Uttar Pradesh', 'lon': '78.6891'},
      '284403': {'lat': '24.6924', 'name': 'Katra Bazar Lalitpur,Lalitpur,Uttar Pradesh', 'lon': '78.4978'},
      '365640': {'lat': '21.5878', 'name': 'Govindpur,Amreli,Gujarat', 'lon': '70.8619'},
      '627359': {'lat': '8.8211', 'name': 'Pappiahpuram,Tirunelveli,Tamil Nadu', 'lon': '77.7179'},
      '284406': {'lat': '24.947', 'name': 'Arjunkhiriya,Lalitpur,Uttar Pradesh', 'lon': '79.271'},
      '825421': {'lat': '24.3115', 'name': 'Koderma Thermal Power Station,Koderma,Jharkhand', 'lon': '85.2903'},
      '370421': {'lat': '22.8392', 'name': 'Adani Port,Kachchh,Gujarat', 'lon': '69.7219'},
      '370427': {'lat': '23.0153', 'name': 'Baladia,Kachchh,Gujarat', 'lon': '70.7595'},
      '370425': {'lat': '23.1914', 'name': 'Vanki,Kachchh,Gujarat', 'lon': '71.797'},
      '241201': {'lat': '26.0996', 'name': 'Bara Gaon,Hardoi,Uttar Pradesh', 'lon': '81.833'},
      '243726': {'lat': '28.1882', 'name': 'Barategdar,Budaun,Uttar Pradesh', 'lon': '79.063'},
      '431716': {'lat': '18.6783', 'name': 'Jamb B K,Nanded,Maharashtra', 'lon': '77.262'},
      '314029': {'lat': '23.5773', 'name': 'Kahela,Dungarpur,Rajasthan', 'lon': '74.1056'},
      '248125': {'lat': '30.4813', 'name': 'Madrasu,Dehradun,Uttarakhand', 'lon': '77.8589'},
      '431714': {'lat': '18.9587', 'name': 'Kandhar,Nanded,Maharashtra', 'lon': '77.298'},
      '721150': {'lat': '22.472', 'name': 'Kismat Angua,West Midnapore,West Bengal', 'lon': '87.9707'},
      '721151': {'lat': '22.4586', 'name': 'Namalbarh,East Midnapore,West Bengal', 'lon': '87.7745'},
      '721152': {'lat': '22.4586', 'name': 'Harijhama,East Midnapore,West Bengal', 'lon': '87.7745'},
      '431715': {'lat': '18.8428', 'name': 'Dapaka G,Nanded,Maharashtra', 'lon': '77.3807'},
      '721154': {'lat': '22.4586', 'name': 'Sitapur,East Midnapore,West Bengal', 'lon': '87.7745'},
      '517620': {'lat': '13.8384', 'name': 'Vagavedu,Chittoor,Andhra Pradesh', 'lon': '79.5627'},
      '721156': {'lat': '22.8573', 'name': 'Patnabazar,West Midnapore,West Bengal', 'lon': '87.9845'},
      '721157': {'lat': '22.4586', 'name': 'Parhdaha,West Midnapore,West Bengal', 'lon': '87.7745'},
      '721158': {'lat': '22.4586', 'name': 'Baishnabchak,East Midnapore,West Bengal', 'lon': '87.7745'},
      '721159': {'lat': '22.033', 'name': 'Tufuria Kamalpur,West Midnapore,West Bengal', 'lon': '87.1722'},
      '431713': {'lat': '19.6103', 'name': 'Jambhala,Nanded,Maharashtra', 'lon': '77.6477'},
      '782001': {'lat': '26.3713', 'name': 'Nagaon Girls  College,Nagaon,Assam', 'lon': '92.2589'},
      '713370': {'lat': '24.2636', 'name': 'Nigha,Paschim Bardhaman,West Bengal', 'lon': '87.867'},
      '782003': {'lat': '26.3713', 'name': 'Itachali,Nagaon,Assam', 'lon': '92.2589'},
      '782002': {'lat': '26.3713', 'name': 'Majarati,Nagaon,Assam', 'lon': '92.2589'},
      '742149': {'lat': '24.1528', 'name': 'Khoshbagh,Murshidabad,West Bengal', 'lon': '88.1461'},
      '431710': {'lat': '18.8478', 'name': 'Belkoni,Nanded,Maharashtra', 'lon': '77.5824'},
      '670651': {'lat': '11.8455', 'name': 'Ambayathode,Kannur,Kerala', 'lon': '75.8127'},
      '523252': {'lat': '15.7321', 'name': 'Thotavenganapalem,Prakasam,Andhra Pradesh', 'lon': '79.7953'},
      '713378': {'lat': '24.2636', 'name': 'Haripur (Bardhaman),Purba Bardhaman,West Bengal', 'lon': '87.867'},
      '507118': {'lat': '17.9843', 'name': 'Chunchupalli,Khammam,Telangana', 'lon': '79.5455'},
      '431711': {'lat': '18.7882', 'name': 'Arjapur,Nanded,Maharashtra', 'lon': '77.7477'},
      '627451': {'lat': '8.6747', 'name': 'Gopalasamudram,Tirunelveli,Tamil Nadu', 'lon': '77.6379'},
      '585219': {'lat': '16.2568', 'name': 'Narayanpur (Yadgir),Yadgir,Karnataka', 'lon': '76.3777'},
      '642202': {'lat': '10.7937', 'name': 'Dasanaicken Palayam,Coimbatore,Tamil Nadu', 'lon': '77.1352'},
      '262202': {'lat': '28.104', 'name': 'Behtim,Pilibhit,Uttar Pradesh', 'lon': '79.8896'},
      '262203': {'lat': '28.3626', 'name': 'Jeorah Kalyanpur,Pilibhit,Uttar Pradesh', 'lon': '79.5553'},
      '500069': {'lat': '17.3568', 'name': 'R.C.Imarat,Hyderabad,Telangana', 'lon': '78.4524'},
      '262201': {'lat': '28.2317', 'name': 'Intgoan,Pilibhit,Uttar Pradesh', 'lon': '79.8907'},
      '783354': {'lat': '26.3513', 'name': 'Chitila Bhubannagar,Kokrajhar,Assam', 'lon': '90.3634'},
      '274704': {'lat': '26.4136', 'name': 'Inguri Bazar,Deoria,Uttar Pradesh', 'lon': '83.9011'},
      '783350': {'lat': '26.3513', 'name': 'Takampur,Kokrajhar,Assam', 'lon': '90.3634'},
      '532242': {'lat': '18.8683', 'name': 'Gudarurajamanipuram,Srikakulam,Andhra Pradesh', 'lon': '84.463'},
      '581329': {'lat': '15.2856', 'name': 'Ammankoppa,Uttara Kannada,Karnataka', 'lon': '74.7592'},
      '581328': {'lat': '14.8856', 'name': 'Halge,Uttara Kannada,Karnataka', 'lon': '74.2257'},
      '799250': {'lat': '24.0447', 'name': 'Dharmanagar H.O,North Tripura,Tripura', 'lon': '91.7553'},
      '532243': {'lat': '18.6893', 'name': 'Rangoi,Srikakulam,Andhra Pradesh', 'lon': '84.0081'},
      '382030': {'lat': '23.0976', 'name': '(Gandhinagar) Sector 30,Gandhi Nagar,Gujarat', 'lon': '72.8913'},
      '581322': {'lat': '14.3607', 'name': 'Bilgi (Uttara Kannada),Uttara Kannada,Karnataka', 'lon': '74.7972'},
      '581321': {'lat': '14.7083', 'name': 'Bhavikeri,Uttara Kannada,Karnataka', 'lon': '74.2653'},
      '581320': {'lat': '14.0212', 'name': 'Marukeri,Uttara Kannada,Karnataka', 'lon': '74.5546'},
      '581327': {'lat': '14.3422', 'name': 'Haldipur,Uttara Kannada,Karnataka', 'lon': '74.4267'},
      '581326': {'lat': '14.5487', 'name': 'Sanikatta,Uttara Kannada,Karnataka', 'lon': '74.3577'},
      '581325': {'lat': '14.6266', 'name': 'Ambewadi (Uttara Kannada),Uttara Kannada,Karnataka', 'lon': '74.7051'},
      '581324': {'lat': '14.7705', 'name': 'Todur Camp,Uttara Kannada,Karnataka', 'lon': '74.1984'},
      '360540': {'lat': '23.5216', 'name': 'Kharedi,Jamnagar,Gujarat', 'lon': '68.5248'},
      '210431': {'lat': '25.3443', 'name': 'Saidpur,Mahoba,Uttar Pradesh', 'lon': '79.6966'},
      '360545': {'lat': '21.7168', 'name': 'Boricha,Porbandar,Gujarat', 'lon': '69.6901'},
      '635703': {'lat': '12.5661', 'name': 'Athipattu(T),Tiruvannamalai,Tamil Nadu', 'lon': '78.9058'},
      '635702': {'lat': '12.5263', 'name': 'Kurusilapattu,Vellore,Tamil Nadu', 'lon': '78.676'},
      '635701': {'lat': '12.6202', 'name': 'Bheemakulam,Vellore,Tamil Nadu', 'lon': '78.7464'},
      '246721': {'lat': '29.4534', 'name': 'Raipur Berisal,Bijnor,Uttar Pradesh', 'lon': '78.1851'},
      '472010': {'lat': '24.4813', 'name': 'Ajnore,Chhatarpur,Madhya Pradesh', 'lon': '79.2335'},
      '388160': {'lat': '22.4479', 'name': 'Pandoli,Anand,Gujarat', 'lon': '72.7774'},
      '500067': {'lat': '17.3568', 'name': 'Suchitra Junction,Hyderabad,Telangana', 'lon': '78.4524'},
      '425111': {'lat': '20.7811', 'name': 'Dholi,Jalgaon,Maharashtra', 'lon': '74.9918'},
      '425110': {'lat': '20.7951', 'name': 'Vankothe,Jalgaon,Maharashtra', 'lon': '75.3097'},
      '425113': {'lat': '20.9109', 'name': 'Bholane,Jalgaon,Maharashtra', 'lon': '74.9721'},
      '425112': {'lat': '20.6043', 'name': 'Deogaon (Jalgaon),Jalgaon,Maharashtra', 'lon': '74.9467'},
      '425115': {'lat': '20.7801', 'name': 'Kurangi,Jalgaon,Maharashtra', 'lon': '75.4082'},
      '425114': {'lat': '20.617', 'name': 'Devpimpri,Jalgaon,Maharashtra', 'lon': '75.8235'},
      '425116': {'lat': '20.8668', 'name': 'Jalke,Jalgaon,Maharashtra', 'lon': '75.4898'},
      '400066': {'lat': '19.1699', 'name': 'Magthane,Mumbai,Maharashtra', 'lon': '72.8504'},
      '413608': {'lat': '18.0218', 'name': 'Wadgaon (G),Osmanabad,Maharashtra', 'lon': '76.3318'},
      '761054': {'lat': '19.3815', 'name': 'Sidheswara,Ganjam,Odisha', 'lon': '84.6958'},
      '689675': {'lat': '9.265', 'name': 'Ettichuvadu,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '413604': {'lat': '17.8878', 'name': 'Jakekur,Osmanabad,Maharashtra', 'lon': '76.4564'},
      '413605': {'lat': '18.1891', 'name': 'Murum,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '413606': {'lat': '17.891', 'name': 'Kadher,Osmanabad,Maharashtra', 'lon': '76.6712'},
      '413607': {'lat': '18.0723', 'name': 'Usturi,Osmanabad,Maharashtra', 'lon': '76.6317'},
      '413601': {'lat': '18.0584', 'name': 'Arali Bk.,Osmanabad,Maharashtra', 'lon': '76.1293'},
      '413602': {'lat': '17.8267', 'name': 'Wagdari (NLD),Osmanabad,Maharashtra', 'lon': '76.3116'},
      '413603': {'lat': '18.1891', 'name': 'Katgaon,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '711202': {'lat': '22.4233', 'name': 'Belur Math,Howrah,West Bengal', 'lon': '88.312'},
      '614707': {'lat': '10.3789', 'name': 'Ayakkarambalam III Sethi,Nagapattinam,Tamil Nadu', 'lon': '79.7879'},
      '248002': {'lat': '30.2796', 'name': 'Mohbewala,Dehradun,Uttarakhand', 'lon': '78.1467'},
      '389146': {'lat': '22.89', 'name': 'Padalia,Dahod,Gujarat', 'lon': '74.0764'},
      '629302': {'lat': '8.2278', 'name': 'Madavalayam,Kanyakumari,Tamil Nadu', 'lon': '77.4825'},
      '389140': {'lat': '22.82395', 'name': 'Agara,Dahod,Gujarat', 'lon': '74.03185'},
      '624220': {'lat': '10.0941', 'name': 'Nadakottai,Dindigul,Tamil Nadu', 'lon': '77.7406'},
      '629301': {'lat': '8.1769', 'name': 'Chenbagaramanputhur,Kanyakumari,Tamil Nadu', 'lon': '77.5047'},
      '577182': {'lat': '13.4292', 'name': 'Muthanagere,Chikkamagaluru,Karnataka', 'lon': '76.2574'},
      '321302': {'lat': '27.0501', 'name': 'Nagla Tehriyan,Bharatpur,Rajasthan', 'lon': '77.4639'},
      '577180': {'lat': '13.5546', 'name': 'G. Yaradakere,Chikkamagaluru,Karnataka', 'lon': '75.7938'},
      '577181': {'lat': '13.2543', 'name': 'Horanadu,Chikkamagaluru,Karnataka', 'lon': '75.3649'},
      '689672': {'lat': '9.265', 'name': 'Vayalathala,Pathanamthitta,Kerala', 'lon': '76.7873'},
      '500065': {'lat': '17.3568', 'name': 'Fatehdarwaza,Hyderabad,Telangana', 'lon': '78.4524'},
      '184121': {'lat': '32.7072', 'name': 'Sumb,Jammu,Jammu & Kashmir', 'lon': '75.0127'},
      '711201': {'lat': '22.6486', 'name': 'Daws Temple Rd,Howrah,West Bengal', 'lon': '88.3411'},
      '614703': {'lat': '10.5137', 'name': 'Nedumbalam,Tiruvarur,Tamil Nadu', 'lon': '79.6305'},
      '531173': {'lat': '17.8794', 'name': 'V. Juttada,Visakhapatnam,Andhra Pradesh', 'lon': '83.2106'},
      '628206': {'lat': '8.3988', 'name': 'Thandavankadu,Tuticorin,Tamil Nadu', 'lon': '78.052'},
      '680125': {'lat': '10.9299', 'name': 'Irinjalakuda North,Thrissur,Kerala', 'lon': '76.0033'},
      '680123': {'lat': '10.9299', 'name': 'Konathukunnu,Thrissur,Kerala', 'lon': '76.0033'},
      '680122': {'lat': '10.9299', 'name': 'Edathirinji,Thrissur,Kerala', 'lon': '76.0033'},
      '680121': {'lat': '10.9299', 'name': 'Irinjalakuda Mkt,Thrissur,Kerala', 'lon': '76.0033'},
      '501505': {'lat': '17.4148', 'name': 'Bandaraviryal,K.V.Rangareddy,Telangana', 'lon': '78.5322'},
      '825313': {'lat': '24.0099', 'name': 'Tatijharia,Hazaribag,Jharkhand', 'lon': '85.5774'},
      '606003': {'lat': '11.5216', 'name': 'Ko  Adhanur,Cuddalore,Tamil Nadu', 'lon': '79.3071'},
      '606001': {'lat': '11.5216', 'name': 'Vriddhachalam Cutchery,Cuddalore,Tamil Nadu', 'lon': '79.3071'},
      '532148': {'lat': '18.3586', 'name': 'Ramachandrapuram Agraharam,Srikakulam,Andhra Pradesh', 'lon': '83.6758'},
      '476134': {'lat': '26.226', 'name': 'Aisa,Morena,Madhya Pradesh', 'lon': '78.1932'},
      '506313': {'lat': '17.7388', 'name': 'Kothur,Warangal,Telangana', 'lon': '79.6652'},
      '250104': {'lat': '28.8979', 'name': 'Lalyana,Meerut,Uttar Pradesh', 'lon': '77.9253'},
      '250106': {'lat': '28.8665', 'name': 'Sholda,Meerut,Uttar Pradesh', 'lon': '78.243'},
      '250101': {'lat': '28.8045', 'name': 'Phulera,Bagpat,Uttar Pradesh', 'lon': '77.5102'},
      '250103': {'lat': '28.9168', 'name': 'Bhoor Baral,Meerut,Uttar Pradesh', 'lon': '77.6377'},
      '721155': {'lat': '22.4586', 'name': 'Bagnabarh,West Midnapore,West Bengal', 'lon': '87.7745'},
      '825312': {'lat': '23.99', 'name': 'Hurlung,Hazaribag,Jharkhand', 'lon': '85.4132'},
      '332001': {'lat': '27.5835', 'name': 'Nathawatpura,Sikar,Rajasthan', 'lon': '75.5012'},
      '506316': {'lat': '17.714', 'name': 'Zaffargadh,Warangal,Telangana', 'lon': '79.5303'},
      '209861': {'lat': '26.4276', 'name': 'Manjra Piper Khera,Unnao,Uttar Pradesh', 'lon': '80.8901'},
      '344501': {'lat': '25.8136', 'name': 'Boothia,Barmer,Rajasthan', 'lon': '70.4343'},
      '246724': {'lat': '29.1255', 'name': 'Tanda Shahuwala,Bijnor,Uttar Pradesh', 'lon': '78.3004'},
      '494331': {'lat': '20.0434', 'name': 'Keshkal,Bastar,Chattisgarh', 'lon': '81.6084'},
      '494333': {'lat': '20.6224', 'name': 'Geetpahar,Kanker,Chattisgarh', 'lon': '81.7346'},
      '140802': {'lat': '30.2814', 'name': 'Khant,Patiala,Punjab', 'lon': '76.3539'},
      '494335': {'lat': '20.2788', 'name': 'Largaon Markatola,Kanker,Chattisgarh', 'lon': '81.6902'},
      '494334': {'lat': '20.3569', 'name': 'Kanker Kutchery,Kanker,Chattisgarh', 'lon': '81.3987'},
      '494337': {'lat': '20.5165', 'name': 'Bhilai,Kanker,Chattisgarh', 'lon': '81.3901'},
      '494336': {'lat': '20.4355', 'name': 'Nathianawagaon,Kanker,Chattisgarh', 'lon': '81.4461'},
      '825311': {'lat': '23.8315', 'name': 'Harli,Hazaribag,Jharkhand', 'lon': '85.2753'},
      '500062': {'lat': '17.3568', 'name': 'Ecil,Hyderabad,Telangana', 'lon': '78.4524'},
      '624620': {'lat': '10.5842', 'name': 'Tirukoornam,Dindigul,Tamil Nadu', 'lon': '78.2901'},
      '505402': {'lat': '18.1572', 'name': 'Velgipur,Karim Nagar,Telangana', 'lon': '78.9995'},
      '784510': {'lat': '26.7198', 'name': 'Tamulbari,Darrang,Assam', 'lon': '91.9902'},
      '784513': {'lat': '26.7198', 'name': 'Sapongaon,Darrang,Assam', 'lon': '91.9902'},
      '505401': {'lat': '18.3994', 'name': 'Manwada,Karim Nagar,Telangana', 'lon': '78.9666'},
      '784514': {'lat': '26.7198', 'name': 'Ekrabari,Darrang,Assam', 'lon': '91.9902'},
      '209860': {'lat': '26.6514', 'name': 'Sikanderpur Karan,Unnao,Uttar Pradesh', 'lon': '80.7548'},
      '505405': {'lat': '18.0544', 'name': 'Valbhapur,Karim Nagar,Telangana', 'lon': '78.6813'},
      '507167': {'lat': '18.5382', 'name': 'Kalakodima,Khammam,Telangana', 'lon': '80.0726'},
      '506318': {'lat': '17.4952', 'name': 'Karkala,Warangal,Telangana', 'lon': '79.6458'},
      '209209': {'lat': '26.8716', 'name': 'Rawatpur Chaudhriyan,Kanpur Dehat,Uttar Pradesh', 'lon': '79.797'},
      '470227': {'lat': '23.6546', 'name': 'Barkheda Sikandar,Sagar,Madhya Pradesh', 'lon': '78.8899'},
      '518217': {'lat': '15.6004', 'name': 'Ramalla Kota,Kurnool,Andhra Pradesh', 'lon': '77.9824'},
      '518216': {'lat': '15.528', 'name': 'Veldurthi,Kurnool,Andhra Pradesh', 'lon': '77.9068'},
      '209205': {'lat': '26.2548', 'name': 'Sabhalpur,Kanpur Dehat,Uttar Pradesh', 'lon': '81.236'},
      '518218': {'lat': '15.67', 'name': 'L.Polur,Kurnool,Andhra Pradesh', 'lon': '77.9517'},
      '481331': {'lat': '21.8109', 'name': 'Newargaon,Balaghat,Madhya Pradesh', 'lon': '80.045'},
      '209206': {'lat': '26.1527', 'name': 'Ghatampur,Kanpur Nagar,Uttar Pradesh', 'lon': '80.168'},
      '481337': {'lat': '21.6344', 'name': 'Bhandarbodi,Balaghat,Madhya Pradesh', 'lon': '79.7103'},
      '507165': {'lat': '17.5934', 'name': 'Tatipudi,Khammam,Telangana', 'lon': '80.1089'},
      '481335': {'lat': '21.6671', 'name': 'Arambha,Balaghat,Madhya Pradesh', 'lon': '79.9854'},
      '209202': {'lat': '26.8675', 'name': 'Vakori,Kanpur Dehat,Uttar Pradesh', 'lon': '79.9703'},
      '396150': {'lat': '21.2331', 'name': 'Vankas,Valsad,Gujarat', 'lon': '73.1929'},
      '624621': {'lat': '10.2079', 'name': 'Kavalapatti,Dindigul,Tamil Nadu', 'lon': '77.8657'},
      '400704': {'lat': '19.01394', 'name': 'N.S.Karanja,Raigarh(MH),Maharashtra', 'lon': '73.0971'},
      '396155': {'lat': '21.2331', 'name': 'Karajgam,Valsad,Gujarat', 'lon': '73.1929'},
      '515561': {'lat': '13.7896', 'name': 'Gownipalli,Ananthapur,Andhra Pradesh', 'lon': '78.4924'},
      '403803': {'lat': '15.3891', 'name': 'Mormugao,South Goa,Goa', 'lon': '73.8149'},
      '403802': {'lat': '15.3958', 'name': 'Vasco-Da-Gama,South Goa,Goa', 'lon': '73.8157'},
      '403801': {'lat': '15.3895', 'name': 'A.P.Terminal,South Goa,Goa', 'lon': '73.8253'},
      '391430': {'lat': '22.7429', 'name': 'Kanda,Vadodara,Gujarat', 'lon': '72.8253'},
      '403806': {'lat': '15.3739', 'name': 'Bogmalo,South Goa,Goa', 'lon': '73.845'},
      '624711': {'lat': '10.6019', 'name': 'Devinaickenpatti,Dindigul,Tamil Nadu', 'lon': '77.9298'},
      '387510': {'lat': '22.9288', 'name': 'Undhela,Kheda,Gujarat', 'lon': '72.664'},
      '506391': {'lat': '18.0994', 'name': 'Varikol,Warangal,Telangana', 'lon': '79.5239'},
      '825317': {'lat': '24.0332', 'name': 'Jagarnath Dham,Hazaribag,Jharkhand', 'lon': '85.4957'},
      '458895': {'lat': '23.2938', 'name': 'Jaggakhedi,Mandsaur,Madhya Pradesh', 'lon': '76.0333'},
      '711204': {'lat': '22.6259', 'name': 'Shibtala Liluah,Howrah,West Bengal', 'lon': '88.3398'},
      '281406': {'lat': '27.6296', 'name': 'Atasbangar,Mathura,Uttar Pradesh', 'lon': '77.5861'},
      '496224': {'lat': '22.714', 'name': 'Bachhraon,Jashpur,Chattisgarh', 'lon': '83.4738'},
      '445215': {'lat': '19.9458', 'name': 'Shelu BK,Yavatmal,Maharashtra', 'lon': '77.9716'},
      '445216': {'lat': '20.0172', 'name': 'P.N.C. Pusad,Yavatmal,Maharashtra', 'lon': '77.9071'},
      '445210': {'lat': '20.2712', 'name': 'Satefal,Yavatmal,Maharashtra', 'lon': '77.9733'},
      '445211': {'lat': '20.272', 'name': 'Taroda,Yavatmal,Maharashtra', 'lon': '77.774'},
      '835225': {'lat': '23.092', 'name': 'Janumpiri,Ranchi,Jharkhand', 'lon': '85.6808'},
      '262311': {'lat': '28.8747', 'name': 'Sarasaria,Udham Singh Nagar,Uttarakhand', 'lon': '79.8096'},
      '477660': {'lat': '26.5184', 'name': 'Ingosa Raipur,Bhind,Madhya Pradesh', 'lon': '78.4831'},
      '534425': {'lat': '16.854', 'name': 'R.Pangidigudem,West Godavari,Andhra Pradesh', 'lon': '81.3135'},
      '534426': {'lat': '16.9415', 'name': 'Rallakunta,West Godavari,Andhra Pradesh', 'lon': '81.2469'},
      '534427': {'lat': '16.7615', 'name': 'Chettunnapadu,West Godavari,Andhra Pradesh', 'lon': '81.2315'},
      '244701': {'lat': '28.3197', 'name': 'Bhainsori,Rampur,Uttar Pradesh', 'lon': '79.8404'},
      '496220': {'lat': '22.4305', 'name': 'Ludeg,Jashpur,Chattisgarh', 'lon': '83.713'},
      '342311': {'lat': '26.8754', 'name': 'Bhiyasar,Jodhpur,Rajasthan', 'lon': '73.2063'},
      '342310': {'lat': '27.5631', 'name': 'Sanwara Gaon,Jaisalmer,Rajasthan', 'lon': '72.2502'},
      '209865': {'lat': '26.3736', 'name': 'Osiya,Unnao,Uttar Pradesh', 'lon': '80.8169'},
      '500074': {'lat': '17.3477', 'name': 'Rangareddy Dt Courts,K.V.Rangareddy,Telangana', 'lon': '78.5576'},
      '246728': {'lat': '29.3361', 'name': 'Jhalu,Bijnor,Uttar Pradesh', 'lon': '78.2261'},
      '203399': {'lat': '28.076', 'name': 'Chiroury,Bulandshahr,Uttar Pradesh', 'lon': '78.778'},
      '835222': {'lat': '23.397', 'name': 'Kamre,Ranchi,Jharkhand', 'lon': '85.2609'},
      '272193': {'lat': '27.3839', 'name': 'Ramwapur Raut,Siddharthnagar,Uttar Pradesh', 'lon': '82.2007'},
      '342312': {'lat': '27.1318', 'name': 'Bugri,Jodhpur,Rajasthan', 'lon': '73.0585'},
      '851216': {'lat': '25.3538', 'name': 'Kolwara,Khagaria,Bihar', 'lon': '86.77'},
      '475661': {'lat': '25.7356', 'name': 'Khiria Ghongu,Datia,Madhya Pradesh', 'lon': '78.3704'},
      '641666': {'lat': '11.205', 'name': 'Pongupalayam,Coimbatore,Tamil Nadu', 'lon': '77.3589'},
      '364470': {'lat': '21.7674', 'name': 'Dhudasiya,Rajkot,Gujarat', 'lon': '70.8645'},
      '201004': {'lat': '28.6275', 'name': 'Hindon Air Field,Ghaziabad,Uttar Pradesh', 'lon': '77.621'},
      '500078': {'lat': '17.6025', 'name': 'Devaryamjal,K.V.Rangareddy,Telangana', 'lon': '78.6205'},
      '700038': {'lat': '22.5553', 'name': 'Sahapur,Kolkata,West Bengal', 'lon': '88.3558'},
      '641664': {'lat': '11.0334', 'name': 'Nathagoundenpalayam,Coimbatore,Tamil Nadu', 'lon': '77.2096'},
      '500079': {'lat': '17.4661', 'name': 'Hasthinapuram,K.V.Rangareddy,Telangana', 'lon': '78.4914'},
      '700032': {'lat': '22.5668', 'name': 'Jadavpur University,Kolkata,West Bengal', 'lon': '88.3016'},
      '723153': {'lat': '23.1906', 'name': 'Raigara,Purulia,West Bengal', 'lon': '86.3502'},
      '700030': {'lat': '22.5668', 'name': 'Purba Sinthee,Kolkata,West Bengal', 'lon': '88.3016'},
      '700031': {'lat': '22.5668', 'name': 'K.P.Roy Lane,Kolkata,West Bengal', 'lon': '88.3016'},
      '700036': {'lat': '22.6413', 'name': 'Bengal Immunity,Kolkata,West Bengal', 'lon': '88.3773'},
      '423212': {'lat': '20.5217', 'name': 'Chandanpuri,Nashik,Maharashtra', 'lon': '74.5378'},
      '700034': {'lat': '22.5015', 'name': 'Jayshree Park,Kolkata,West Bengal', 'lon': '88.2788'},
      '700035': {'lat': '22.5668', 'name': 'Deshbandhu Road,Kolkata,West Bengal', 'lon': '88.3016'},
      '272192': {'lat': '27.0679', 'name': 'Tenuwagrant,Siddharthnagar,Uttar Pradesh', 'lon': '82.4872'},
      '762010': {'lat': '20.2517', 'name': 'Pikaradi,Kandhamal,Odisha', 'lon': '84.3421'},
      '423213': {'lat': '20.5552', 'name': 'Brahmangaon,Nashik,Maharashtra', 'lon': '74.2947'},
      '762011': {'lat': '20.247', 'name': 'Tellapali,Kandhamal,Odisha', 'lon': '84.0071'},
      '828116': {'lat': '23.7697', 'name': 'Balihari,Dhanbad,Jharkhand', 'lon': '86.3821'},
      '828117': {'lat': '23.5725', 'name': 'Burragarh,Dhanbad,Jharkhand', 'lon': '86.2216'},
      '221207': {'lat': '25.4827', 'name': 'Thaney Rampur,Varanasi,Uttar Pradesh', 'lon': '82.7365'},
      '828115': {'lat': '23.7283', 'name': 'Suranga,Dhanbad,Jharkhand', 'lon': '86.4336'},
      '828112': {'lat': '23.7167', 'name': 'Kalimela,Dhanbad,Jharkhand', 'lon': '86.4'},
      '762012': {'lat': '20.5125', 'name': 'Gandisara,Boudh,Odisha', 'lon': '84.518'},
      '828110': {'lat': '23.7', 'name': 'Bagdigi,Dhanbad,Jharkhand', 'lon': '86.4167'},
      '828111': {'lat': '23.8807', 'name': 'Goplaichak,Dhanbad,Jharkhand', 'lon': '86.2614'},
      '209867': {'lat': '26.3882', 'name': 'Vazidpur,Unnao,Uttar Pradesh', 'lon': '80.7792'},
      '500108': {'lat': '17.5902', 'name': 'R.G.I. Airport,K.V.Rangareddy,Telangana', 'lon': '78.6668'},
      '271835': {'lat': '27.6547', 'name': 'Dharsawan,Shrawasti,Uttar Pradesh', 'lon': '81.884'},
      '762014': {'lat': '20.5863', 'name': 'Boudhraj,Boudh,Odisha', 'lon': '84.6128'},
      '505001': {'lat': '18.3624', 'name': 'Karimnagar H.O,Karim Nagar,Telangana', 'lon': '79.1723'},
      '577537': {'lat': '14.4362', 'name': 'Mylanahally,Chitradurga,Karnataka', 'lon': '76.8295'},
      '271830': {'lat': '27.7945', 'name': 'Shivpur (Bahraich),Bahraich,Uttar Pradesh', 'lon': '81.5102'},
      '272191': {'lat': '27.1356', 'name': 'Amauna Pandey,Basti,Uttar Pradesh', 'lon': '82.7025'},
      '762015': {'lat': '20.6468', 'name': 'Ambajhari,Boudh,Odisha', 'lon': '84.1776'},
      '797006': {'lat': '25.662', 'name': 'Kigwema,Kohima,Nagaland', 'lon': '94.1084'},
      '797004': {'lat': '25.662', 'name': 'Thizama,Kohima,Nagaland', 'lon': '94.1084'},
      '797003': {'lat': '25.6698', 'name': 'Rusoma,Kohima,Nagaland', 'lon': '94.1951'},
      '797002': {'lat': '25.6542', 'name': 'Kohima Science College,Kohima,Nagaland', 'lon': '94.0218'},
      '797001': {'lat': '25.662', 'name': 'Kohima H.O,Kohima,Nagaland', 'lon': '94.1084'},
      '762017': {'lat': '20.6582', 'name': 'Srimal,Boudh,Odisha', 'lon': '83.751'},
      '460001': {'lat': '21.9183', 'name': 'Bharat Bharti,Betul,Madhya Pradesh', 'lon': '77.7254'},
      '691521': {'lat': '9.0114', 'name': 'Kulakada,Kollam,Kerala', 'lon': '76.7954'},
      '691520': {'lat': '8.9402', 'name': 'Ummannur,Kollam,Kerala', 'lon': '76.8147'},
      '691523': {'lat': '9.0114', 'name': 'Adur( Kla) H.O,Pathanamthitta,Kerala', 'lon': '76.7954'},
      '691522': {'lat': '9.0114', 'name': 'Panthaplavu,Kollam,Kerala', 'lon': '76.7954'},
      '691525': {'lat': '9.0114', 'name': 'Parakara,Pathanamthitta,Kerala', 'lon': '76.7954'},
      '691524': {'lat': '9.1068', 'name': 'Marur,Pathanamthitta,Kerala', 'lon': '76.8296'},
      '691527': {'lat': '8.9753', 'name': 'Nellikunnam,Kollam,Kerala', 'lon': '76.7772'},
      '691526': {'lat': '9.091', 'name': 'Thazhathuvadakku,Kollam,Kerala', 'lon': '76.7549'},
      '756023': {'lat': '21.7205', 'name': 'Beladandia,Baleswar,Odisha', 'lon': '85.5887'},
      '800014': {'lat': '25.6716', 'name': 'B.V. College,Patna,Bihar', 'lon': '85.0646'},
      '517432': {'lat': '13.1711', 'name': 'Moram,Chittoor,Andhra Pradesh', 'lon': '78.6934'},
      '800016': {'lat': '25.6716', 'name': 'Rajendra Nagar (Patna),Patna,Bihar', 'lon': '85.0646'},
      '800011': {'lat': '25.6716', 'name': 'Makhdumpur Digha,Patna,Bihar', 'lon': '85.0646'},
      '800010': {'lat': '25.6716', 'name': 'Sadaquat Ashram,Patna,Bihar', 'lon': '85.0646'},
      '800013': {'lat': '25.6716', 'name': 'Patliputra,Patna,Bihar', 'lon': '85.0646'},
      '800012': {'lat': '25.6716', 'name': 'Naya Panapur,Patna,Bihar', 'lon': '85.0646'},
      '388421': {'lat': '22.8763', 'name': 'Changa,Kheda,Gujarat', 'lon': '73.0167'},
      '465227': {'lat': '23.4688', 'name': 'Salsalai,Shajapur,Madhya Pradesh', 'lon': '76.5356'},
      '509408': {'lat': '17.1958', 'name': 'Eklaskhanpet,Mahabub Nagar,Telangana', 'lon': '78.2744'},
      '465226': {'lat': '23.6594', 'name': 'Khoriaama,Shajapur,Madhya Pradesh', 'lon': '76.3601'},
      '509406': {'lat': '16.6274', 'name': 'Appajipally,Mahabub Nagar,Telangana', 'lon': '77.9341'},
      '495001': {'lat': '22.0903', 'name': 'Gole Bazar Bilaspur,Bilaspur(CGH),Chattisgarh', 'lon': '82.1493'},
      '481226': {'lat': '21.7731', 'name': 'Keshalewada,Balaghat,Madhya Pradesh', 'lon': '80.2771'},
      '495004': {'lat': '22.1569', 'name': 'Ramdas Nagar,Bilaspur(CGH),Chattisgarh', 'lon': '82.0218'},
      '495006': {'lat': '22.0988', 'name': 'Spinning Mills Bilaspur,Bilaspur(CGH),Chattisgarh', 'lon': '82.1544'},
      '509401': {'lat': '16.3427', 'name': 'Appayapally,Mahabub Nagar,Telangana', 'lon': '78.5161'},
      '799153': {'lat': '23.2518', 'name': 'Rangamura,South Tripura,Tripura', 'lon': '91.4541'},
      '755028': {'lat': '21.1435', 'name': 'Chingudipal,Jajapur,Odisha', 'lon': '86.1786'},
      '799157': {'lat': '23.2518', 'name': 'Puran Rajbari,South Tripura,Tripura', 'lon': '91.4541'},
      '799156': {'lat': '23.2518', 'name': 'Madhabnagar,South Tripura,Tripura', 'lon': '91.4541'},
      '756020': {'lat': '21.3653', 'name': 'Mahispata,Baleswar,Odisha', 'lon': '86.2774'},
      '736159': {'lat': '26.2478', 'name': 'Mansai,Cooch Behar,West Bengal', 'lon': '89.2194'},
      '814112': {'lat': '24.3507', 'name': 'Jhaunsagarhi,Deoghar,Jharkhand', 'lon': '87.105'},
      '793111': {'lat': '25.4353', 'name': 'Cherrabazar,East Khasi Hills,Meghalaya', 'lon': '91.6422'},
      '360375': {'lat': '22.8634', 'name': 'Navagadh,Rajkot,Gujarat', 'lon': '71.3043'},
      '470235': {'lat': '23.4067', 'name': 'Tada,Sagar,Madhya Pradesh', 'lon': '78.7867'},
      '847308': {'lat': '26.5641', 'name': 'Durgapatti,Madhubani,Bihar', 'lon': '85.8291'},
      '384275': {'lat': '23.9073', 'name': 'Ganget,Patan,Gujarat', 'lon': '72.4279'},
      '360370': {'lat': '22.8634', 'name': 'Reshamdi Galol,Rajkot,Gujarat', 'lon': '71.3043'},
      '847304': {'lat': '25.8922', 'name': 'Kumhrauli,Darbhanga,Bihar', 'lon': '86.2553'},
      '847305': {'lat': '26.4206', 'name': 'Basuki Bihari,Madhubani,Bihar', 'lon': '86.2457'},
      '847306': {'lat': '25.9792', 'name': 'Kanaur,Darbhanga,Bihar', 'lon': '85.8955'},
      '847307': {'lat': '26.1511', 'name': 'Lakhanpur,Muzaffarpur,Bihar', 'lon': '85.3308'},
      '311403': {'lat': '25.4097', 'name': 'Meja,Bhilwara,Rajasthan', 'lon': '74.5539'},
      '847302': {'lat': '26.0758', 'name': 'Rarhi,Darbhanga,Bihar', 'lon': '85.9146'},
      '847303': {'lat': '26.1829', 'name': 'Jahangirtola,Darbhanga,Bihar', 'lon': '86.1705'},
      '755023': {'lat': '20.7701', 'name': 'Kabatabandha,Jajapur,Odisha', 'lon': '85.2422'},
      '793112': {'lat': '25.1786', 'name': 'Mawlon,East Khasi Hills,Meghalaya', 'lon': '91.6395'},
      '841434': {'lat': '26.247', 'name': 'Hariharpur Kala,Siwan,Bihar', 'lon': '84.6023'},
      '141206': {'lat': '30.7813', 'name': 'Ghawaddi,Ludhiana,Punjab', 'lon': '75.8911'},
      '841436': {'lat': '26.2537', 'name': 'Peoli,Gopalganj,Bihar', 'lon': '84.3396'},
      '581335': {'lat': '14.2697', 'name': 'Molkod,Uttara Kannada,Karnataka', 'lon': '74.4897'},
      '841438': {'lat': '26.2829', 'name': 'K.K. Kararia,Gopalganj,Bihar', 'lon': '84.3985'},
      '841439': {'lat': '26.1684', 'name': 'Agya,Siwan,Bihar', 'lon': '84.5847'},
      '581336': {'lat': '14.5903', 'name': 'Mathadeval,Uttara Kannada,Karnataka', 'lon': '74.765'},
      '825318': {'lat': '24.32', 'name': 'Dasarokhurd,Koderma,Jharkhand', 'lon': '85.7461'},
      '781325': {'lat': '26.6325', 'name': 'Hathinapur,Barpeta,Assam', 'lon': '91.6412'},
      '731129': {'lat': '23.1807', 'name': 'Majhigram,Birbhum,West Bengal', 'lon': '88.0357'},
      '781327': {'lat': '26.6242', 'name': 'Anchali,Barpeta,Assam', 'lon': '91.5994'},
      '781326': {'lat': '26.6187', 'name': 'Gobindapur,Barpeta,Assam', 'lon': '91.5716'},
      '781321': {'lat': '26.6242', 'name': 'Tarakansdi,Barpeta,Assam', 'lon': '91.5994'},
      '311407': {'lat': '25.8327', 'name': 'Taswaria Basa,Bhilwara,Rajasthan', 'lon': '74.9816'},
      '465677': {'lat': '23.9982', 'name': 'Bailas,Rajgarh,Madhya Pradesh', 'lon': '77.1006'},
      '755027': {'lat': '20.733', 'name': 'Sayedpur,Jajapur,Odisha', 'lon': '85.8278'},
      '731123': {'lat': '23.8367', 'name': 'Balijuri,Birbhum,West Bengal', 'lon': '87.3746'},
      '781329': {'lat': '26.6242', 'name': 'Daisingri,Nalbari,Assam', 'lon': '91.5994'},
      '781328': {'lat': '26.6242', 'name': 'Kathalmurighat,Barpeta,Assam', 'lon': '91.5994'},
      '731126': {'lat': '23.9585', 'name': 'Gohaliara,Birbhum,West Bengal', 'lon': '88.1305'},
      '755026': {'lat': '20.7527', 'name': 'Gobarghati,Jajapur,Odisha', 'lon': '86.0692'},
      '243720': {'lat': '28.3648', 'name': 'Dabtori,Budaun,Uttar Pradesh', 'lon': '79.0041'},
      '505481': {'lat': '18.26', 'name': 'Mudimanikyam,Karim Nagar,Telangana', 'lon': '79.1478'},
      '755025': {'lat': '21.0295', 'name': 'Mukundapur,Jajapur,Odisha', 'lon': '86.2171'},
      '272195': {'lat': '27.0558', 'name': 'Parsa Khurd,Siddharthnagar,Uttar Pradesh', 'lon': '82.8464'},
      '822118': {'lat': '24.042', 'name': 'Lesliganj,Palamau,Jharkhand', 'lon': '84.3588'},
      '581333': {'lat': '13.7838', 'name': 'Yettinbail,Uttara Kannada,Karnataka', 'lon': '74.6386'},
      '242021': {'lat': '27.7764', 'name': 'I.A. Babrala,Budaun,Uttar Pradesh', 'lon': '79.9089'},
      '811201': {'lat': '25.444', 'name': 'Munger Town,Munger,Bihar', 'lon': '86.4728'},
      '365430': {'lat': '21.7179', 'name': 'Kerala-j,Amreli,Gujarat', 'lon': '71.8936'},
      '144009': {'lat': '31.4375', 'name': 'Chogitti,Jalandhar,Punjab', 'lon': '75.3467'},
      '144008': {'lat': '31.4375', 'name': 'Charanjitpura,Jalandhar,Punjab', 'lon': '75.3467'},
      '365435': {'lat': '21.9116', 'name': 'Harsurpur,Amreli,Gujarat', 'lon': '71.2191'},
      '505186': {'lat': '18.4418', 'name': 'Bonthakuntapalli,Karim Nagar,Telangana', 'lon': '79.2498'},
      '144005': {'lat': '31.4375', 'name': 'Sadar Bazar Jalandhar Cantt,Jalandhar,Punjab', 'lon': '75.3467'},
      '144004': {'lat': '31.4254', 'name': 'Sodal Road,Jalandhar,Punjab', 'lon': '75.3178'},
      '144007': {'lat': '31.4375', 'name': 'Ladhewali,Jalandhar,Punjab', 'lon': '75.3467'},
      '144006': {'lat': '31.4375', 'name': 'P.A.P. Lines Jalandhar Cantt,Jalandhar,Punjab', 'lon': '75.3467'},
      '144001': {'lat': '31.4375', 'name': 'Railway Road (Jalandhar),Jalandhar,Punjab', 'lon': '75.3467'},
      '144003': {'lat': '31.4375', 'name': 'Bhargav Camp,Jalandhar,Punjab', 'lon': '75.3467'},
      '144002': {'lat': '31.4555', 'name': 'Basti Nau,Jalandhar,Punjab', 'lon': '75.39'},
      '202136': {'lat': '28.0423', 'name': 'Kanoi,Aligarh,Uttar Pradesh', 'lon': '78.3887'},
      '202137': {'lat': '28.0742', 'name': 'Palsera,Aligarh,Uttar Pradesh', 'lon': '77.6755'},
      '577532': {'lat': '14.1581', 'name': 'Madakarinayakanakote,Chitradurga,Karnataka', 'lon': '76.5559'},
      '202135': {'lat': '27.9836', 'name': 'Shiwala,Aligarh,Uttar Pradesh', 'lon': '78.1258'},
      '202132': {'lat': '28.1122', 'name': 'Bhagatpur,Aligarh,Uttar Pradesh', 'lon': '77.8809'},
      '202133': {'lat': '27.9977', 'name': 'Zafar Manzil,Aligarh,Uttar Pradesh', 'lon': '78.5218'},
      '202130': {'lat': '28.0794', 'name': 'Bhamora Kalan,Aligarh,Uttar Pradesh', 'lon': '78.3468'},
      '202131': {'lat': '28.0086', 'name': 'Chandaua Goverdhanapur,Aligarh,Uttar Pradesh', 'lon': '78.8191'},
      '711412': {'lat': '22.6398', 'name': 'Uttar Mansri,Howrah,West Bengal', 'lon': '87.96946'},
      '184101': {'lat': '32.4925', 'name': 'Kathua H.O,Kathua,Jammu & Kashmir', 'lon': '75.2689'},
      '184102': {'lat': '32.7278', 'name': 'Parliwand,Kathua,Jammu & Kashmir', 'lon': '74.8583'},
      '243727': {'lat': '28.3707', 'name': 'Jijora,Budaun,Uttar Pradesh', 'lon': '78.3906'},
      '184104': {'lat': '32.3749', 'name': 'Rakh Lachhipura,Kathua,Jammu & Kashmir', 'lon': '75.4741'},
      '202138': {'lat': '27.798', 'name': 'Pala Chand,Aligarh,Uttar Pradesh', 'lon': '77.8328'},
      '804405': {'lat': '25.1189', 'name': 'Dharaut,Jehanabad,Bihar', 'lon': '85.0682'},
      '452005': {'lat': '22.7435', 'name': 'Bijasan Road,Indore,Madhya Pradesh', 'lon': '75.8145'},
      '486117': {'lat': '24.6325', 'name': 'Katra (Rewa),Rewa,Madhya Pradesh', 'lon': '81.5178'},
      '688555': {'lat': '9.6487', 'name': 'Varanam,Alappuzha,Kerala', 'lon': '76.3764'},
      '452001': {'lat': '22.7233', 'name': 'Radio Colony Indore,Indore,Madhya Pradesh', 'lon': '75.87'},
      '628303': {'lat': '8.8281', 'name': 'Marudanvalvoo,Tuticorin,Tamil Nadu', 'lon': '77.864'},
      '452003': {'lat': '22.7334', 'name': 'Vallabhnagar (Indore),Indore,Madhya Pradesh', 'lon': '75.8422'},
      '452002': {'lat': '22.7334', 'name': 'Indore name-2,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '263680': {'lat': '29.7421', 'name': 'Ugalia,Almora,Uttarakhand', 'lon': '79.3718'},
      '628302': {'lat': '8.9', 'name': 'Maniyac R.S.,Tuticorin,Tamil Nadu', 'lon': '77.9392'},
      '452009': {'lat': '22.7334', 'name': 'Lokmanya Nagar Indore,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '416113': {'lat': '18.5613', 'name': 'June Pargaon,Kolhapur,Maharashtra', 'lon': '74.3727'},
      '416112': {'lat': '16.5734', 'name': 'Vathar T/F vadgaon,Kolhapur,Maharashtra', 'lon': '74.3309'},
      '416111': {'lat': '16.8258', 'name': 'Kumbhoj,Kolhapur,Maharashtra', 'lon': '74.4375'},
      '416110': {'lat': '16.9262', 'name': 'Narande,Kolhapur,Maharashtra', 'lon': '74.3397'},
      '794002': {'lat': '25.7466', 'name': 'Raja Apal,East Garo Hills,Meghalaya', 'lon': '90.3804'},
      '416116': {'lat': '16.9262', 'name': 'Ganganagar Ichalkaranji,Kolhapur,Maharashtra', 'lon': '74.3397'},
      '416115': {'lat': '16.9262', 'name': 'Jawaharnagar Ichalkaranji,Kolhapur,Maharashtra', 'lon': '74.3397'},
      '416114': {'lat': '16.8764', 'name': 'Satave,Kolhapur,Maharashtra', 'lon': '74.1909'},
      '416119': {'lat': '16.9262', 'name': 'Chinchwad,Kolhapur,Maharashtra', 'lon': '74.3397'},
      '416118': {'lat': '16.7131', 'name': 'Atigre,Kolhapur,Maharashtra', 'lon': '74.3619'},
      '313003': {'lat': '24.6534', 'name': 'Bhoiyon Ki Pancholi,Udaipur,Rajasthan', 'lon': '73.9448'},
      '313002': {'lat': '24.7078', 'name': 'Udaipur H Magri,Udaipur,Rajasthan', 'lon': '73.6695'},
      '313001': {'lat': '24.7168', 'name': 'Udaipur Surajpole,Udaipur,Rajasthan', 'lon': '73.6237'},
      '805105': {'lat': '25.0307', 'name': 'Maira Barich,Nalanda,Bihar', 'lon': '85.3716'},
      '144533': {'lat': '30.9854', 'name': 'Asron,Nawanshahr,Punjab', 'lon': '76.5033'},
      '713125': {'lat': '23.4125', 'name': 'Palar,Purba Bardhaman,West Bengal', 'lon': '87.9153'},
      '713126': {'lat': '23.7617', 'name': 'Goligram,Purba Bardhaman,West Bengal', 'lon': '87.979'},
      '713127': {'lat': '23.7333', 'name': 'Narayanpur,Purba Bardhaman,West Bengal', 'lon': '87.9833'},
      '508113': {'lat': '17.2785', 'name': 'Pallivada,Nalgonda,Telangana', 'lon': '79.3718'},
      '713121': {'lat': '24.1314', 'name': 'Sahebganj,Purba Bardhaman,West Bengal', 'lon': '87.9555'},
      '713122': {'lat': '23.449', 'name': 'Anukhal,Purba Bardhaman,West Bengal', 'lon': '88.3903'},
      '713123': {'lat': '23.7617', 'name': 'Gangatikuri,Purba Bardhaman,West Bengal', 'lon': '87.979'},
      '635710': {'lat': '12.7794', 'name': 'Nachiarkuppam,Vellore,Tamil Nadu', 'lon': '79.0056'},
      '805107': {'lat': '24.8563', 'name': 'Bhattagarh,Nawada,Bihar', 'lon': '85.6929'},
      '756025': {'lat': '21.4441', 'name': 'Parkhi,Baleswar,Odisha', 'lon': '87.0283'},
      '713129': {'lat': '23.7617', 'name': 'Amgoria,Purba Bardhaman,West Bengal', 'lon': '87.979'},
      '743248': {'lat': '22.1994', 'name': 'Adikashimpur,North 24 Parganas,West Bengal', 'lon': '88.0296'},
      '261502': {'lat': '27.8072', 'name': 'Laukahi Isanagar,Kheri,Uttar Pradesh', 'lon': '81.1913'},
      '516474': {'lat': '14.8309', 'name': 'Siregepalle,Cuddapah,Andhra Pradesh', 'lon': '78.1375'},
      '811202': {'lat': '25.444', 'name': 'Jamindigri,Munger,Bihar', 'lon': '86.4728'},
      '574198': {'lat': '12.9716', 'name': 'Petrame,Dakshina Kannada,Karnataka', 'lon': '75.4167'},
      '307031': {'lat': '24.8394', 'name': 'Thandi Beri,Sirohi,Rajasthan', 'lon': '73.1245'},
      '307030': {'lat': '25.3944', 'name': 'Valdara,Jalor,Rajasthan', 'lon': '73.2853'},
      '509321': {'lat': '16.6246', 'name': 'Cheepununthala,Mahabub Nagar,Telangana', 'lon': '78.5343'},
      '443304': {'lat': '20.2092', 'name': 'Deulgaon Sakharsha,Buldhana,Maharashtra', 'lon': '76.5445'},
      '844125': {'lat': '25.7822', 'name': 'Sarai,Vaishali,Bihar', 'lon': '85.2743'},
      '522238': {'lat': '16.141', 'name': 'A P Secretariat,Guntur,Andhra Pradesh', 'lon': '80.2457'},
      '250619': {'lat': '29.0369', 'name': 'Kherki,Bagpat,Uttar Pradesh', 'lon': '77.2397'},
      '443301': {'lat': '20.3254', 'name': 'Loni Gaoli,Buldhana,Maharashtra', 'lon': '76.817'},
      '443302': {'lat': '20.2697', 'name': 'Hirdao,Buldhana,Maharashtra', 'lon': '76.5175'},
      '443303': {'lat': '19.8855', 'name': 'Gohogaon,Buldhana,Maharashtra', 'lon': '76.6538'},
      '262551': {'lat': '29.8889', 'name': 'Borabunga,Pithoragarh,Uttarakhand', 'lon': '79.6965'},
      '522233': {'lat': '16.1654', 'name': 'Jaladi,Guntur,Andhra Pradesh', 'lon': '80.249'},
      '522236': {'lat': '16.4184', 'name': 'Tadikonda,Guntur,Andhra Pradesh', 'lon': '80.4548'},
      '522237': {'lat': '16.2291', 'name': 'Sakhamuru,Guntur,Andhra Pradesh', 'lon': '80.3812'},
      '522234': {'lat': '16.2294', 'name': 'Solasa,Guntur,Andhra Pradesh', 'lon': '80.2177'},
      '522235': {'lat': '16.0399', 'name': 'Garlapadu,Guntur,Andhra Pradesh', 'lon': '80.3526'},
      '394115': {'lat': '22.358', 'name': 'Bhadi,Bharuch,Gujarat', 'lon': '73.1644'},
      '394440': {'lat': '22.066', 'name': 'Vahar,Surat,Gujarat', 'lon': '73.6352'},
      '844126': {'lat': '25.7742', 'name': 'Pour,Vaishali,Bihar', 'lon': '85.4994'},
      '394110': {'lat': '22.1737', 'name': 'Kothwa,Surat,Gujarat', 'lon': '73.346'},
      '394111': {'lat': '22.2263', 'name': 'Kim Char Rasta,Surat,Gujarat', 'lon': '73.2941'},
      '394445': {'lat': '21.9843', 'name': 'Chavda,Surat,Gujarat', 'lon': '73.9351'},
      '625540': {'lat': '9.8382', 'name': 'Sukkangalpatti,Theni,Tamil Nadu', 'lon': '77.4322'},
      '207301': {'lat': '27.4223', 'name': 'Rohina Mirjapur,Etah,Uttar Pradesh', 'lon': '78.4351'},
      '844103': {'lat': '25.7521', 'name': 'Anzanpeer Chok,Vaishali,Bihar', 'lon': '85.1931'},
      '844102': {'lat': '25.7309', 'name': 'Hajipur Ind.Area,Vaishali,Bihar', 'lon': '85.8861'},
      '844101': {'lat': '25.8172', 'name': 'Hajipur Chowk,Vaishali,Bihar', 'lon': '85.2628'},
      '821101': {'lat': '25.0782', 'name': 'Rudrawar Kala,Kaimur (Bhabua),Bihar', 'lon': '83.6886'},
      '852139': {'lat': '26.1244', 'name': 'Latauna,Supaul,Bihar', 'lon': '86.8553'},
      '852138': {'lat': '25.8565', 'name': 'Tharbitta,Supaul,Bihar', 'lon': '86.2273'},
      '577144': {'lat': '13.4543', 'name': 'Bhavikere,Chikkamagaluru,Karnataka', 'lon': '75.6913'},
      '321610': {'lat': '26.9777', 'name': 'Sankarwada,Karauli,Rajasthan', 'lon': '76.842'},
      '852137': {'lat': '26.2209', 'name': 'Khunti,Supaul,Bihar', 'lon': '87.0367'},
      '852131': {'lat': '26.0862', 'name': 'Mauzaha,Supaul,Bihar', 'lon': '86.5176'},
      '852130': {'lat': '26.0568', 'name': 'Telwa Situhar,Supaul,Bihar', 'lon': '86.5119'},
      '852133': {'lat': '25.9323', 'name': 'Janardanpur.,Supaul,Bihar', 'lon': '86.6156'},
      '852132': {'lat': '26.0436', 'name': 'Chouraha,Madhepura,Bihar', 'lon': '86.8806'},
      '848113': {'lat': '25.8058', 'name': 'Singhiakhurd,Samastipur,Bihar', 'lon': '85.7765'},
      '577519': {'lat': '14.3939', 'name': 'Bhahadurghatta,Chitradurga,Karnataka', 'lon': '76.2079'},
      '577518': {'lat': '14.0819', 'name': 'Bndurga,Davangere,Karnataka', 'lon': '76.1638'},
      '577515': {'lat': '13.9059', 'name': 'Marabhaghatta,Chitradurga,Karnataka', 'lon': '76.2089'},
      '577517': {'lat': '14.3419', 'name': 'Kadabanakatte,Chitradurga,Karnataka', 'lon': '76.4349'},
      '577516': {'lat': '14.4531', 'name': 'Dulehole,Davangere,Karnataka', 'lon': '75.7286'},
      '577511': {'lat': '13.8861', 'name': 'Ikkanur,Chitradurga,Karnataka', 'lon': '76.6989'},
      '577513': {'lat': '14.5902', 'name': 'Diddige,Davangere,Karnataka', 'lon': '76.1735'},
      '577512': {'lat': '14.5007', 'name': 'Kittur,Davangere,Karnataka', 'lon': '76.1'},
      '392035': {'lat': '21.7667', 'name': 'Chaklad,Bharuch,Gujarat', 'lon': '73.1167'},
      '272302': {'lat': '26.6537', 'name': 'Meetanjot,Basti,Uttar Pradesh', 'lon': '82.8507'},
      '325209': {'lat': '24.8587', 'name': 'Anta Gas Power Project Anta,Baran,Rajasthan', 'lon': '76.6109'},
      '148001': {'lat': '30.2323', 'name': 'Sangrur H.O,Sangrur,Punjab', 'lon': '75.852'},
      '148002': {'lat': '30.2323', 'name': 'Uppli,Sangrur,Punjab', 'lon': '75.852'},
      '573201': {'lat': '13.0269', 'name': 'Eastern Extn,Hassan,Karnataka', 'lon': '76.1011'},
      '573202': {'lat': '13.0269', 'name': 'Hemavathinagar,Hassan,Karnataka', 'lon': '76.1011'},
      '362550': {'lat': '20.7862', 'name': 'Gharal,Junagadh,Gujarat', 'lon': '71.1481'},
      '225001': {'lat': '26.9494', 'name': 'Munshiganj (Barabanki),Barabanki,Uttar Pradesh', 'lon': '81.1629'},
      '225003': {'lat': '26.8854', 'name': 'Tindola,Barabanki,Uttar Pradesh', 'lon': '80.8976'},
      '225002': {'lat': '26.9067', 'name': 'Barabanki Sugar Mill,Barabanki,Uttar Pradesh', 'lon': '80.986'},
      '396045': {'lat': '22.2967', 'name': 'Dhanori,Valsad,Gujarat', 'lon': '71.3312'},
      '670308': {'lat': '12.053', 'name': 'Karanthat,Kannur,Kerala', 'lon': '75.191'},
      '670309': {'lat': '12.0418', 'name': 'Kovvapuram,Kannur,Kerala', 'lon': '75.3473'},
      '670301': {'lat': '11.9778', 'name': 'Othayammadom,Kannur,Kerala', 'lon': '75.3052'},
      '670302': {'lat': '12.0418', 'name': 'Mattul,Kannur,Kerala', 'lon': '75.3473'},
      '670303': {'lat': '12.0418', 'name': 'Sreestha,Kannur,Kerala', 'lon': '75.3473'},
      '670304': {'lat': '12.0418', 'name': 'Madayi,Kannur,Kerala', 'lon': '75.3473'},
      '670305': {'lat': '12.0418', 'name': 'Palakkode,Kannur,Kerala', 'lon': '75.3473'},
      '670306': {'lat': '11.8687', 'name': 'Porakunnu,Kannur,Kerala', 'lon': '75.7739'},
      '670307': {'lat': '12.1212', 'name': 'Payyan R.S.,Kannur,Kerala', 'lon': '75.2174'},
      '314024': {'lat': '23.7325', 'name': 'Karada,Dungarpur,Rajasthan', 'lon': '73.8551'},
      '522649': {'lat': '15.9872', 'name': 'Hassannayunipalem,Guntur,Andhra Pradesh', 'lon': '79.8611'},
      '533483': {'lat': '17.6294', 'name': 'Veeravaram,East Godavari,Andhra Pradesh', 'lon': '82.0511'},
      '600095': {'lat': '13.0675', 'name': 'Ayanambakkam,Tiruvallur,Tamil Nadu', 'lon': '80.1632'},
      '509325': {'lat': '17.7896', 'name': 'Thimmapur,Mahabub Nagar,Telangana', 'lon': '79.5385'},
      '314025': {'lat': '23.6795', 'name': 'Ganda Brahamania,Dungarpur,Rajasthan', 'lon': '74.0201'},
      '522647': {'lat': '15.9781', 'name': 'NSP Colony,Guntur,Andhra Pradesh', 'lon': '79.705'},
      '522646': {'lat': '16.0955', 'name': 'Kothaluru,Guntur,Andhra Pradesh', 'lon': '79.8506'},
      '485447': {'lat': '24.4417', 'name': 'Usrar,Satna,Madhya Pradesh', 'lon': '80.4027'},
      '410218': {'lat': '19.025352', 'name': 'Kalamboli Node,Raigarh(MH),Maharashtra', 'lon': '73.099148'},
      '325203': {'lat': '25.1854', 'name': 'Bhanda Heda,Kota,Rajasthan', 'lon': '76.1812'},
      '140126': {'lat': '30.9245', 'name': 'Bass Bhabour,Ropar,Punjab', 'lon': '76.4479'},
      '612204': {'lat': '10.9741', 'name': 'Sannapuram,Thanjavur,Tamil Nadu', 'lon': '79.4386'},
      '410210': {'lat': '18.88965', 'name': 'Kharghar,Raigarh(MH),Maharashtra', 'lon': '73.0771'},
      '140125': {'lat': '30.5969', 'name': 'Behrampur,Ropar,Punjab', 'lon': '76.6315'},
      '410216': {'lat': '18.95255', 'name': 'Jagdish Nagar,Raigarh(MH),Maharashtra', 'lon': '73.1837'},
      '140123': {'lat': '31.2602', 'name': 'Ganugwal,Rupnagar,Punjab', 'lon': '76.4783'},
      '612203': {'lat': '10.9828', 'name': 'Sengarangudiputhur,Thanjavur,Tamil Nadu', 'lon': '79.5873'},
      '325205': {'lat': '25.599', 'name': 'Krishi Upaj Mandi Baran,Baran,Rajasthan', 'lon': '76.6693'},
      '497331': {'lat': '22.7559', 'name': 'Girjapur,Koriya,Chattisgarh', 'lon': '82.1977'},
      '224229': {'lat': '26.6589', 'name': 'Bawan,Faizabad,Uttar Pradesh', 'lon': '81.9924'},
      '509324': {'lat': '17.6436', 'name': 'Jupally,Mahabub Nagar,Telangana', 'lon': '78.4367'},
      '325206': {'lat': '25.5712', 'name': 'Patunda,Baran,Rajasthan', 'lon': '76.0865'},
      '635118': {'lat': '12.6438', 'name': 'Belalam,Krishnagiri,Tamil Nadu', 'lon': '77.6778'},
      '635119': {'lat': '12.6221', 'name': 'Haleseebam,Krishnagiri,Tamil Nadu', 'lon': '77.9295'},
      '635116': {'lat': '12.438', 'name': 'Royakottah,Krishnagiri,Tamil Nadu', 'lon': '78.183'},
      '342309': {'lat': '26.8068', 'name': 'Barnau,Jodhpur,Rajasthan', 'lon': '72.518'},
      '635114': {'lat': '12.6126', 'name': 'Muthur,Krishnagiri,Tamil Nadu', 'lon': '77.7487'},
      '325207': {'lat': '24.9219', 'name': 'Kunjed,Baran,Rajasthan', 'lon': '76.4447'},
      '635112': {'lat': '12.4981', 'name': 'Kaveripattinam Bazaar,Krishnagiri,Tamil Nadu', 'lon': '78.6705'},
      '635113': {'lat': '12.6031', 'name': 'Ullukurukki,Krishnagiri,Tamil Nadu', 'lon': '77.8674'},
      '635110': {'lat': '12.6344', 'name': 'Hosur Cattle Farm,Krishnagiri,Tamil Nadu', 'lon': '77.79'},
      '635111': {'lat': '12.489', 'name': 'Mukkulam,Krishnagiri,Tamil Nadu', 'lon': '78.0327'},
      '431536': {'lat': '19.1426', 'name': 'Banvas,Parbhani,Maharashtra', 'lon': '76.5677'},
      '825167': {'lat': '24.4739', 'name': 'Laxmantunda,Giridh,Jharkhand', 'lon': '86.0107'},
      '470115': {'lat': '23.9062', 'name': 'Peepra,Sagar,Madhya Pradesh', 'lon': '78.5137'},
      '431537': {'lat': '19.2774', 'name': 'Jamb,Parbhani,Maharashtra', 'lon': '76.6841'},
      '124201': {'lat': '28.9176', 'name': 'Baghpur,Jhajjar,Haryana', 'lon': '76.6176'},
      '124202': {'lat': '28.6853', 'name': 'Pilana,Jhajjar,Haryana', 'lon': '76.4613'},
      '491888': {'lat': '21.8653', 'name': 'Rampur,Durg,Chattisgarh', 'lon': '81.5627'},
      '845434': {'lat': '26.4245', 'name': 'Manglapur Rajpur,East Champaran,Bihar', 'lon': '84.7511'},
      '845435': {'lat': '26.712', 'name': 'Khagani,East Champaran,Bihar', 'lon': '84.8165'},
      '845436': {'lat': '26.2114', 'name': 'Kajraha,East Champaran,Bihar', 'lon': '84.8269'},
      '845437': {'lat': '26.6517', 'name': 'Sapahi,East Champaran,Bihar', 'lon': '84.8526'},
      '845430': {'lat': '26.0649', 'name': 'Kodariya,East Champaran,Bihar', 'lon': '84.8452'},
      '845431': {'lat': '26.856', 'name': 'Panjiarwa,East Champaran,Bihar', 'lon': '84.8474'},
      '845432': {'lat': '26.6184', 'name': 'Konia,East Champaran,Bihar', 'lon': '84.9247'},
      '845433': {'lat': '26.8676', 'name': 'Paesauna Madan,East Champaran,Bihar', 'lon': '84.8093'},
      '845438': {'lat': '26.7621', 'name': 'Patzirwa,West Champaran,Bihar', 'lon': '84.4861'},
      '535501': {'lat': '18.7888', 'name': 'Parvatipuram Town,Vizianagaram,Andhra Pradesh', 'lon': '83.4242'},
      '456441': {'lat': '23.6056', 'name': 'Jharda,Ujjain,Madhya Pradesh', 'lon': '75.7251'},
      '829150': {'lat': '22.2442', 'name': 'Honhey,Ramgarh,Jharkhand', 'lon': '85.7866'},
      '333053': {'lat': '27.2612', 'name': 'Deepura,Jhujhunu,Rajasthan', 'lon': '75.967'},
      '456440': {'lat': '23.5896', 'name': 'Sagwali,Ujjain,Madhya Pradesh', 'lon': '75.4833'},
      '686671': {'lat': '10.0298', 'name': 'Koovalloor,Ernakulam,Kerala', 'lon': '76.6668'},
      '686670': {'lat': '9.95', 'name': 'Vadakode-vazhakulam,Ernakulam,Kerala', 'lon': '76.65'},
      '686673': {'lat': '9.9899', 'name': 'Nirmala,Ernakulam,Kerala', 'lon': '76.6584'},
      '686672': {'lat': '9.9899', 'name': 'Meenkunnam,Ernakulam,Kerala', 'lon': '76.6584'},
      '191132': {'lat': '33.7018', 'name': 'Rangreth,Budgam,Jammu & Kashmir', 'lon': '74.7781'},
      '191131': {'lat': '33.7018', 'name': 'Yangoora,Srinagar,Jammu & Kashmir', 'lon': '74.7781'},
      '515601': {'lat': '14.0346', 'name': 'Morasalapalli,Ananthapur,Andhra Pradesh', 'lon': '77.9646'},
      '843319': {'lat': '26.5442', 'name': 'Choraut,Sitamarhi,Bihar', 'lon': '85.7834'},
      '814118': {'lat': '24.1667', 'name': 'Singhni,Dumka,Jharkhand', 'lon': '87.1205'},
      '843318': {'lat': '25.2803', 'name': 'Goraul Sharif,Sitamarhi,Bihar', 'lon': '86.4741'},
      '481117': {'lat': '22.1622', 'name': 'Pondi,Balaghat,Madhya Pradesh', 'lon': '80.3481'},
      '636001': {'lat': '10.9497', 'name': 'Salem Second Agraharam,Salem,Tamil Nadu', 'lon': '77.8272'},
      '843317': {'lat': '25.9097', 'name': 'M. Kala,Sitamarhi,Bihar', 'lon': '85.8386'},
      '636003': {'lat': '11.6583', 'name': 'Ammapet Bazaar,Salem,Tamil Nadu', 'lon': '78.1834'},
      '636002': {'lat': '11.3712', 'name': 'Nethimedu,Salem,Tamil Nadu', 'lon': '78.0893'},
      '636005': {'lat': '11.3712', 'name': 'Suramangalam Railway Colony,Salem,Tamil Nadu', 'lon': '78.0893'},
      '636004': {'lat': '11.6755', 'name': 'Marakkadai,Salem,Tamil Nadu', 'lon': '78.1395'},
      '636007': {'lat': '11.4654', 'name': 'Salem North,Salem,Tamil Nadu', 'lon': '78.1896'},
      '636006': {'lat': '11.5355', 'name': 'Salem South,Salem,Tamil Nadu', 'lon': '78.1024'},
      '636009': {'lat': '10.6848', 'name': 'Leigh Bazaar,Salem,Tamil Nadu', 'lon': '77.9803'},
      '396360': {'lat': '20.8121', 'name': 'Duwada,Navsari,Gujarat', 'lon': '72.9981'},
      '480999': {'lat': '22.5226', 'name': 'Sajpani-dhanora,Seoni,Madhya Pradesh', 'lon': '79.8042'},
      '271002': {'lat': '27.1842', 'name': 'Bargaon (Gonda),Gonda,Uttar Pradesh', 'lon': '81.9826'},
      '282004': {'lat': '27.213', 'name': 'Taj Nagari,Agra,Uttar Pradesh', 'lon': '78.1858'},
      '335901': {'lat': '30.122', 'name': 'Dullapur Kerri,Ganganagar,Rajasthan', 'lon': '73.9339'},
      '282005': {'lat': '27.2188', 'name': 'K.H.Sansthan,Agra,Uttar Pradesh', 'lon': '78.012'},
      '231208': {'lat': '24.2364', 'name': 'Khokha Karahia,Sonbhadra,Uttar Pradesh', 'lon': '83.1592'},
      '396445': {'lat': '20.9904', 'name': 'Junathana,Navsari,Gujarat', 'lon': '72.8927'},
      '231206': {'lat': '24.6396', 'name': 'Churk,Sonbhadra,Uttar Pradesh', 'lon': '83.0979'},
      '843313': {'lat': '25.4429', 'name': 'Dumarbana,Sitamarhi,Bihar', 'lon': '84.5598'},
      '263138': {'lat': '29.5595', 'name': 'Pyura,Nainital,Uttarakhand', 'lon': '79.7231'},
      '483336': {'lat': '23.7542', 'name': 'Anghora,Katni,Madhya Pradesh', 'lon': '80.4015'},
      '843312': {'lat': '25.9226', 'name': 'Kokilwara,Muzaffarpur,Bihar', 'lon': '85.3255'},
      '814111': {'lat': '24.5442', 'name': 'Dumrchir,Pakur,Jharkhand', 'lon': '87.5764'},
      '754138': {'lat': '20.7067', 'name': 'Garam,Jagatsinghapur,Odisha', 'lon': '84.627'},
      '754139': {'lat': '20.2033', 'name': 'Kunjakothi,Jagatsinghapur,Odisha', 'lon': '86.4077'},
      '586119': {'lat': '16.7655', 'name': 'Madasanal,Vijayapura,Karnataka', 'lon': '76.4964'},
      '586118': {'lat': '16.4493', 'name': 'Hunsyal (SDG),Vijayapura,Karnataka', 'lon': '76.1492'},
      '851116': {'lat': '25.4462', 'name': 'B.T.P.P,Begusarai,Bihar', 'lon': '85.9319'},
      '586115': {'lat': '16.7331', 'name': 'Satihal,Vijayapura,Karnataka', 'lon': '76.0566'},
      '586114': {'lat': '16.9137', 'name': 'Babanagar,Vijayapura,Karnataka', 'lon': '75.4421'},
      '586117': {'lat': '17.2089', 'name': 'Deginal,Vijayapura,Karnataka', 'lon': '76.0499'},
      '586116': {'lat': '16.3528', 'name': 'Madikeshwar,Vijayapura,Karnataka', 'lon': '76.1412'},
      '682020': {'lat': '10.1975', 'name': 'Kadavanthara,Ernakulam,Kerala', 'lon': '76.2147'},
      '754137': {'lat': '20.3052', 'name': 'Sankheswar,Jagatsinghapur,Odisha', 'lon': '86.3294'},
      '586113': {'lat': '16.61', 'name': 'Jambagi,Vijayapura,Karnataka', 'lon': '76.3111'},
      '586112': {'lat': '15.8769', 'name': 'Shirakanhalli,Vijayapura,Karnataka', 'lon': '75.3652'},
      '125004': {'lat': '29.1328', 'name': 'Neoli Kalan,Hisar,Haryana', 'lon': '75.6576'},
      '125005': {'lat': '29.0365', 'name': 'Urban Estate  2 Hhisar,Hisar,Haryana', 'lon': '75.8648'},
      '125006': {'lat': '29.0543', 'name': 'Satroad Kalan,Hisar,Haryana', 'lon': '75.7998'},
      '125007': {'lat': '29.0251', 'name': 'Chakpanihar,Hisar,Haryana', 'lon': '75.6081'},
      '125001': {'lat': '29.0755', 'name': 'H;t;m;hisar,Hisar,Haryana', 'lon': '75.7562'},
      '201307': {'lat': '28.5929', 'name': 'Noida Sector 34,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.4021'},
      '502228': {'lat': '17.2562', 'name': 'Buchnelly,Medak,Telangana', 'lon': '77.584'},
      '814115': {'lat': '24.3507', 'name': 'Hindipith,Deoghar,Jharkhand', 'lon': '87.105'},
      '508002': {'lat': '17.0848', 'name': 'Dandampally,Nalgonda,Telangana', 'lon': '79.2439'},
      '272301': {'lat': '26.5941', 'name': 'Chilma Bazar,Basti,Uttar Pradesh', 'lon': '82.7301'},
      '814114': {'lat': '24.2348', 'name': 'Gurukul Vidyapith,Deoghar,Jharkhand', 'lon': '86.976'},
      '781138': {'lat': '26.263', 'name': 'Pachim Kajia Gaon,Nalbari,Assam', 'lon': '91.3011'},
      '571119': {'lat': '12.0957', 'name': 'Tagadur,Mysuru,Karnataka', 'lon': '76.8078'},
      '571118': {'lat': '12.0942', 'name': 'Kugalur,Mysuru,Karnataka', 'lon': '76.6712'},
      '781131': {'lat': '25.8083', 'name': 'Patharkhmah,Kamrup,Assam', 'lon': '91.2969'},
      '781132': {'lat': '26.0864', 'name': 'Rampur (Kamrup),Kamrup,Assam', 'lon': '91.46'},
      '781133': {'lat': '26.0759', 'name': 'Dagaon,Kamrup,Assam', 'lon': '91.3622'},
      '781134': {'lat': '26.0779', 'name': 'Kukurmara,Kamrup,Assam', 'lon': '91.4179'},
      '781135': {'lat': '25.9438', 'name': 'Singra Rajapara,Kamrup,Assam', 'lon': '91.1565'},
      '781136': {'lat': '26.0989', 'name': 'Bhauriabhita,Kamrup,Assam', 'lon': '91.1425'},
      '781137': {'lat': '26.1314', 'name': 'Mahimari,Kamrup,Assam', 'lon': '91.4625'},
      '683575': {'lat': '10.0975', 'name': 'Parappuram,Ernakulam,Kerala', 'lon': '76.3717'},
      '683574': {'lat': '10.1874', 'name': 'Manickamangalam,Ernakulam,Kerala', 'lon': '76.4458'},
      '683577': {'lat': '10.0975', 'name': 'Azhakam,Ernakulam,Kerala', 'lon': '76.3717'},
      '683576': {'lat': '10.0975', 'name': 'Karukutty,Ernakulam,Kerala', 'lon': '76.3717'},
      '683571': {'lat': '10.0577', 'name': 'Chowara,Ernakulam,Kerala', 'lon': '76.4727'},
      '683573': {'lat': '10.0975', 'name': 'Angamally South,Ernakulam,Kerala', 'lon': '76.3717'},
      '683572': {'lat': '10.054', 'name': 'Kidangoor,Ernakulam,Kerala', 'lon': '76.3531'},
      '502107': {'lat': '18.0768', 'name': 'Narayanaraopet,Medak,Telangana', 'lon': '78.6641'},
      '683579': {'lat': '11.7352', 'name': 'Kurumassery,Ernakulam,Kerala', 'lon': '75.6542'},
      '683578': {'lat': '10.1541', 'name': 'S.Aduvassery,Ernakulam,Kerala', 'lon': '76.3416'},
      '518405': {'lat': '15.2526', 'name': 'Chowtkur,Kurnool,Andhra Pradesh', 'lon': '77.6705'},
      '518155': {'lat': '15.1018', 'name': 'Peddaemmanur,Kurnool,Andhra Pradesh', 'lon': '78.3816'},
      '518401': {'lat': '15.6963', 'name': 'Tummalur,Kurnool,Andhra Pradesh', 'lon': '78.1719'},
      '206253': {'lat': '26.8611', 'name': 'Abhinaypur Pathakpur,Etawah,Uttar Pradesh', 'lon': '79.0605'},
      '605111': {'lat': '12.0076', 'name': 'Tiruchitambalam,Villupuram,Tamil Nadu', 'lon': '79.7878'},
      '605110': {'lat': '11.9036', 'name': 'Kilinjikuppam,Villupuram,Tamil Nadu', 'lon': '79.7455'},
      '501203': {'lat': '17.4706', 'name': 'Proddatur,K.V.Rangareddy,Telangana', 'lon': '78.1314'},
      '521122': {'lat': '16.3693', 'name': 'Bandlaicheruvu,Krishna,Andhra Pradesh', 'lon': '81.0694'},
      '493891': {'lat': '19.9522', 'name': 'Gohrapadar,Gariaband,Chattisgarh', 'lon': '82.7158'},
      '799009': {'lat': '23.6182', 'name': 'Lankamura,West Tripura,Tripura', 'lon': '91.356'},
      '793009': {'lat': '25.5675', 'name': 'Mawklot,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '793008': {'lat': '25.5675', 'name': 'Mawroh,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '508004': {'lat': '17.0848', 'name': 'Cherla Gouraram,Nalgonda,Telangana', 'lon': '79.2439'},
      '799008': {'lat': '23.6182', 'name': 'Paschim Noabadi,West Tripura,Tripura', 'lon': '91.356'},
      '793003': {'lat': '25.5707', 'name': 'Laitumkhrah,East Khasi Hills,Meghalaya', 'lon': '91.8977'},
      '516115': {'lat': '14.2071', 'name': 'Rajampet H.O,Cuddapah,Andhra Pradesh', 'lon': '79.2854'},
      '793001': {'lat': '25.5675', 'name': 'Meghalaya Secretariat,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '630307': {'lat': '10.0613', 'name': 'Patharakudi,Sivaganga,Tamil Nadu', 'lon': '78.7189'},
      '516110': {'lat': '14.2071', 'name': 'Venkatarajupalli,Cuddapah,Andhra Pradesh', 'lon': '79.2854'},
      '793006': {'lat': '25.5675', 'name': 'Rynjah,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '793005': {'lat': '25.5644', 'name': 'Upper Shillon,East Khasi Hills,Meghalaya', 'lon': '91.8312'},
      '793004': {'lat': '25.5675', 'name': 'Bishnupur,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '412108': {'lat': '19.1453', 'name': 'Shere,Pune,Maharashtra', 'lon': '74.4273'},
      '412109': {'lat': '18.7185', 'name': 'Sadumbre,Pune,Maharashtra', 'lon': '73.7664'},
      '742227': {'lat': '24.2505', 'name': 'Pasai,Murshidabad,West Bengal', 'lon': '87.9755'},
      '742226': {'lat': '24.3144', 'name': 'Noapara,Murshidabad,West Bengal', 'lon': '87.9452'},
      '854201': {'lat': '25.9486', 'name': 'Malinia Diara,Purnia,Bihar', 'lon': '87.3302'},
      '742223': {'lat': '24.075', 'name': 'Fatullapur,Murshidabad,West Bengal', 'lon': '88.0367'},
      '854202': {'lat': '25.9046', 'name': 'Maharaj Ganj,Purnia,Bihar', 'lon': '87.2516'},
      '609111': {'lat': '11.2664', 'name': 'Sirkali West,Nagapattinam,Tamil Nadu', 'lon': '79.7506'},
      '678502': {'lat': '10.599', 'name': 'Vemballur,Palakkad,Kerala', 'lon': '76.6922'},
      '412102': {'lat': '18.5217', 'name': 'Khandobachi Wadi,Pune,Maharashtra', 'lon': '74.4103'},
      '412103': {'lat': '18.1296', 'name': 'Korhale KH,Pune,Maharashtra', 'lon': '74.3619'},
      '412104': {'lat': '18.5204', 'name': 'Tekawade,Pune,Maharashtra', 'lon': '74.9495'},
      '412105': {'lat': '18.6776', 'name': 'Moshi,Pune,Maharashtra', 'lon': '73.8987'},
      '412106': {'lat': '18.5217', 'name': 'Kanhe,Pune,Maharashtra', 'lon': '74.4103'},
      '535220': {'lat': '18.2581', 'name': 'Pakirkitthali,Vizianagaram,Andhra Pradesh', 'lon': '83.4656'},
      '788719': {'lat': '24.8513', 'name': 'Mullaganj Bazar,Karimganj,Assam', 'lon': '92.3148'},
      '530051': {'lat': '17.7878', 'name': 'Chinamushidiwada,Visakhapatnam,Andhra Pradesh', 'lon': '83.2176'},
      '530053': {'lat': '17.7878', 'name': 'Pedamadaka,Visakhapatnam,Andhra Pradesh', 'lon': '83.2176'},
      '530052': {'lat': '17.7878', 'name': 'Anandapuram (Visakhapatnam),Visakhapatnam,Andhra Pradesh', 'lon': '83.2176'},
      '788713': {'lat': '24.8513', 'name': 'Nairgram,Karimganj,Assam', 'lon': '92.3148'},
      '788712': {'lat': '24.8513', 'name': 'Dharakuna,Karimganj,Assam', 'lon': '92.3148'},
      '788711': {'lat': '24.8513', 'name': 'Girishganj Bazar,Karimganj,Assam', 'lon': '92.3148'},
      '788710': {'lat': '24.8513', 'name': 'Karimganj H.O,Karimganj,Assam', 'lon': '92.3148'},
      '799001': {'lat': '23.6182', 'name': 'Agartala H.O,West Tripura,Tripura', 'lon': '91.356'},
      '534211': {'lat': '16.7593', 'name': 'Tanuku H.O,West Godavari,Andhra Pradesh', 'lon': '81.6753'},
      '534210': {'lat': '16.7776', 'name': 'Kumudavalli,West Godavari,Andhra Pradesh', 'lon': '81.6742'},
      '534217': {'lat': '16.7776', 'name': 'K.Illindalaparru,West Godavari,Andhra Pradesh', 'lon': '81.6742'},
      '534216': {'lat': '16.7854', 'name': 'Vundrajavaram,West Godavari,Andhra Pradesh', 'lon': '81.686'},
      '534215': {'lat': '16.7776', 'name': 'Venkatrayapuram,West Godavari,Andhra Pradesh', 'lon': '81.6742'},
      '470004': {'lat': '23.8414', 'name': 'Sagar Rajakhedi,Sagar,Madhya Pradesh', 'lon': '78.6908'},
      '743286': {'lat': '23.1454', 'name': 'Duttapara,North 24 Parganas,West Bengal', 'lon': '88.6128'},
      '534218': {'lat': '16.775', 'name': 'Pidiparru,West Godavari,Andhra Pradesh', 'lon': '81.656'},
      '470001': {'lat': '23.8414', 'name': 'Sagar Gopalganj,Sagar,Madhya Pradesh', 'lon': '78.6908'},
      '470003': {'lat': '23.8414', 'name': 'Sagar University,Sagar,Madhya Pradesh', 'lon': '78.6908'},
      '470002': {'lat': '23.8414', 'name': 'Sagar Jnpa,Sagar,Madhya Pradesh', 'lon': '78.6908'},
      '522001': {'lat': '16.2176', 'name': 'Bus Stand(Guntur),Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522003': {'lat': '16.2176', 'name': 'S.K.P.Temple(Guntur),Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522002': {'lat': '16.2176', 'name': 'Donka Road(Guntur),Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522005': {'lat': '16.2176', 'name': 'Ankireddypalem,Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522004': {'lat': '16.2176', 'name': 'Venugopalanagar Colony,Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522007': {'lat': '16.2176', 'name': 'Chandramoulinagar,Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522006': {'lat': '16.2176', 'name': 'Chowdaripeta(Guntur),Guntur,Andhra Pradesh', 'lon': '80.345'},
      '522009': {'lat': '16.2176', 'name': 'Perecherla Junction,Guntur,Andhra Pradesh', 'lon': '80.345'},
      '485441': {'lat': '24.4767', 'name': 'Barethiya,Satna,Madhya Pradesh', 'lon': '80.7379'},
      '480997': {'lat': '22.6245', 'name': 'Ghansore,Seoni,Madhya Pradesh', 'lon': '79.9514'},
      '232327': {'lat': '25.5083', 'name': 'Gahmar Bazar,Ghazipur,Uttar Pradesh', 'lon': '83.6041'},
      '225121': {'lat': '26.8425', 'name': 'Daravpur,Barabanki,Uttar Pradesh', 'lon': '81.2913'},
      '799007': {'lat': '23.6182', 'name': 'Banamalipur,West Tripura,Tripura', 'lon': '91.356'},
      '403002': {'lat': '15.4672', 'name': 'Caranzalem,North Goa,Goa', 'lon': '73.8119'},
      '403001': {'lat': '15.4723', 'name': 'St Inez,North Goa,Goa', 'lon': '73.8097'},
      '364310': {'lat': '22.0037', 'name': 'Patna Bhal,Bhavnagar,Gujarat', 'lon': '71.2545'},
      '364313': {'lat': '22.1572', 'name': 'Monpar,Bhavnagar,Gujarat', 'lon': '71.9627'},
      '403004': {'lat': '15.4785', 'name': 'Nio Dona Paula,North Goa,Goa', 'lon': '73.8339'},
      '143602': {'lat': '31.902', 'name': 'Hardorwal,Gurdaspur,Punjab', 'lon': '75.0921'},
      '143603': {'lat': '31.9699', 'name': 'Ramdass,Amritsar,Punjab', 'lon': '74.8767'},
      '306102': {'lat': '26.0542', 'name': 'Chittar,Pali,Rajasthan', 'lon': '74.1854'},
      '143601': {'lat': '31.8029', 'name': 'Tarpai,Amritsar,Punjab', 'lon': '75.0263'},
      '143606': {'lat': '31.7576', 'name': 'Balbawa,Amritsar,Punjab', 'lon': '74.9177'},
      '756144': {'lat': '20.7818', 'name': 'Apanda,Bhadrak,Odisha', 'lon': '85.4718'},
      '143604': {'lat': '31.9261', 'name': 'Khushalpur,Gurdaspur,Punjab', 'lon': '75.0209'},
      '143605': {'lat': '31.9284', 'name': 'Kotli Surat Malhi,Gurdaspur,Punjab', 'lon': '75.06'},
      '394320': {'lat': '23.042', 'name': 'Nagod,Surat,Gujarat', 'lon': '73.0759'},
      '394327': {'lat': '21.1616', 'name': 'Kadodara,Surat,Gujarat', 'lon': '72.9623'},
      '394326': {'lat': '21.2365', 'name': 'Vav Kathodara,Surat,Gujarat', 'lon': '73.0226'},
      '394325': {'lat': '23.0041', 'name': 'Umbhel,Surat,Gujarat', 'lon': '73.0141'},
      '804417': {'lat': '25.2361', 'name': 'Jehanabad R.S.,Jehanabad,Bihar', 'lon': '84.9442'},
      '222170': {'lat': '25.7154', 'name': 'Vijayeepur,Jaunpur,Uttar Pradesh', 'lon': '82.6533'},
      '825409': {'lat': '24.4123', 'name': 'Gajhandi,Koderma,Jharkhand', 'lon': '85.4581'},
      '630303': {'lat': '10.3066', 'name': 'Teralapur,Sivaganga,Tamil Nadu', 'lon': '78.8028'},
      '825403': {'lat': '24.3174', 'name': 'Pindra,Chatra,Jharkhand', 'lon': '84.7566'},
      '370405': {'lat': '22.8964', 'name': 'Ramania,Kachchh,Gujarat', 'lon': '69.6121'},
      '825401': {'lat': '23.99', 'name': 'Lohandi,Hazaribag,Jharkhand', 'lon': '85.4132'},
      '370155': {'lat': '23.6304', 'name': 'Sukhpar (V),Kachchh,Gujarat', 'lon': '70.9146'},
      '799120': {'lat': '23.4668', 'name': 'Radhakishorepur H.O,South Tripura,Tripura', 'lon': '91.4007'},
      '825406': {'lat': '23.99', 'name': 'Singhrawan,Hazaribag,Jharkhand', 'lon': '85.4132'},
      '370150': {'lat': '23.2212', 'name': 'Amaliyara,Kachchh,Gujarat', 'lon': '70.5668'},
      '825404': {'lat': '24.2591', 'name': 'Narayanpur,Chatra,Jharkhand', 'lon': '84.5291'},
      '272127': {'lat': '26.8274', 'name': 'Hiyaroopur,Basti,Uttar Pradesh', 'lon': '82.3509'},
      '390006': {'lat': '22.3218', 'name': 'Fatepura (Vadodara),Vadodara,Gujarat', 'lon': '73.1663'},
      '390007': {'lat': '22.3125', 'name': 'Vidhyut Nagar Colony,Vadodara,Gujarat', 'lon': '73.1771'},
      '390004': {'lat': '22.3218', 'name': 'Kapurai,Vadodara,Gujarat', 'lon': '73.1663'},
      '721139': {'lat': '22.4042', 'name': 'Bakulda,East Midnapore,West Bengal', 'lon': '87.7175'},
      '390002': {'lat': '22.3218', 'name': 'Sardar Nagar Society,Vadodara,Gujarat', 'lon': '73.1663'},
      '390003': {'lat': '22.3311', 'name': 'Chemical Industries,Vadodara,Gujarat', 'lon': '73.1555'},
      '390001': {'lat': '22.3218', 'name': 'Khanderao Market,Vadodara,Gujarat', 'lon': '73.1663'},
      '721132': {'lat': '23.1207', 'name': 'Reserve Bank Nm Ltd.,West Midnapore,West Bengal', 'lon': '87.8175'},
      '148102': {'lat': '30.4945', 'name': 'Nainewal,Barnala,Punjab', 'lon': '75.2978'},
      '721130': {'lat': '23.1207', 'name': 'Rain,East Midnapore,West Bengal', 'lon': '87.8175'},
      '721131': {'lat': '23.1207', 'name': 'Amdan,East Midnapore,West Bengal', 'lon': '87.8175'},
      '721136': {'lat': '23.9443', 'name': 'Kankra Atmaram,West Midnapore,West Bengal', 'lon': '87.7069'},
      '721137': {'lat': '23.1207', 'name': 'Burarihat,East Midnapore,West Bengal', 'lon': '87.8175'},
      '390008': {'lat': '22.3218', 'name': 'Eme,Vadodara,Gujarat', 'lon': '73.1663'},
      '390009': {'lat': '22.3218', 'name': 'Tarsali,Vadodara,Gujarat', 'lon': '73.1663'},
      '416231': {'lat': '16.4697', 'name': 'Panori,Kolhapur,Maharashtra', 'lon': '74.1598'},
      '713359': {'lat': '25.493', 'name': 'Old Sitarampur,Paschim Bardhaman,West Bengal', 'lon': '87.9075'},
      '713358': {'lat': '22.9455', 'name': 'Bansra,Purba Bardhaman,West Bengal', 'lon': '88.4456'},
      '507138': {'lat': '17.9953', 'name': 'Gouthampur,Khammam,Telangana', 'lon': '80.7096'},
      '502329': {'lat': '17.9793', 'name': 'Kowlampet,Medak,Telangana', 'lon': '78.4183'},
      '502324': {'lat': '17.7372', 'name': 'Icrisat,Medak,Telangana', 'lon': '77.9095'},
      '502325': {'lat': '17.7372', 'name': 'Aie Bollaram,Medak,Telangana', 'lon': '77.9095'},
      '713357': {'lat': '23.767', 'name': 'Salanpur,Paschim Bardhaman,West Bengal', 'lon': '86.8749'},
      '502321': {'lat': '17.6566', 'name': 'Chilkepally,Medak,Telangana', 'lon': '77.7399'},
      '416306': {'lat': '17.3788', 'name': 'Kumatha,Sangli,Maharashtra', 'lon': '74.3123'},
      '416307': {'lat': '17.3788', 'name': 'Kavathe Ekand,Sangli,Maharashtra', 'lon': '74.3123'},
      '416304': {'lat': '16.9074', 'name': 'Bisur,Sangli,Maharashtra', 'lon': '74.5995'},
      '416305': {'lat': '17.3788', 'name': 'Digraj Kasaba Agri School,Sangli,Maharashtra', 'lon': '74.3123'},
      '416302': {'lat': '17.112', 'name': 'Nagaon,Sangli,Maharashtra', 'lon': '74.7699'},
      '416303': {'lat': '16.9891', 'name': 'Bhilwadi R.S.,Sangli,Maharashtra', 'lon': '74.4679'},
      '416301': {'lat': '17.3788', 'name': 'Gotkhindi,Sangli,Maharashtra', 'lon': '74.3123'},
      '783370': {'lat': '26.4567', 'name': 'Haloadal,Kokrajhar,Assam', 'lon': '90.2525'},
      '783371': {'lat': '26.1863', 'name': 'Kheluapara,Dhubri,Assam', 'lon': '90.4478'},
      '783372': {'lat': '26.4674', 'name': 'Dhantala Bazar,Kokrajhar,Assam', 'lon': '90.4195'},
      '783373': {'lat': '26.551', 'name': 'Duttapur,Bongaigaon,Assam', 'lon': '90.413'},
      '712404': {'lat': '23.4387', 'name': 'Dingalhati,Hooghly,West Bengal', 'lon': '87.9324'},
      '783375': {'lat': '26.5807', 'name': 'Khungring,Kokrajhar,Assam', 'lon': '91.4583'},
      '783376': {'lat': '26.4295', 'name': 'Nayekgaon,Kokrajhar,Assam', 'lon': '90.513'},
      '416309': {'lat': '17.119', 'name': 'Balavadi(Bhalavani),Sangli,Maharashtra', 'lon': '74.4106'},
      '627803': {'lat': '8.9914', 'name': 'Elathur (Tirunelveli),Tirunelveli,Tamil Nadu', 'lon': '77.2946'},
      '627802': {'lat': '8.9303', 'name': 'Kasimajorpuram,Tirunelveli,Tamil Nadu', 'lon': '77.2695'},
      '635854': {'lat': '12.4483', 'name': 'Mallapalli,Vellore,Tamil Nadu', 'lon': '78.4036'},
      '635853': {'lat': '12.3626', 'name': 'Yelagiri Hills,Vellore,Tamil Nadu', 'lon': '78.4026'},
      '635852': {'lat': '12.6343', 'name': 'Mallagunta,Vellore,Tamil Nadu', 'lon': '78.5194'},
      '635851': {'lat': '12.5837', 'name': 'Reddiyur,Vellore,Tamil Nadu', 'lon': '78.5848'},
      '627804': {'lat': '9.2784', 'name': 'Nainaragaram,Tirunelveli,Tamil Nadu', 'lon': '77.5741'},
      '523356': {'lat': '15.402', 'name': 'Ankireddypalle,Prakasam,Andhra Pradesh', 'lon': '79.0064'},
      '523357': {'lat': '15.3688', 'name': 'Podilikondapalle,Prakasam,Andhra Pradesh', 'lon': '78.9076'},
      '627809': {'lat': '9.4845', 'name': 'Periyapillaivalasi,Tirunelveli,Tamil Nadu', 'lon': '77.727'},
      '627808': {'lat': '8.8652', 'name': 'Adaikalapattinam,Tirunelveli,Tamil Nadu', 'lon': '77.3666'},
      '463106': {'lat': '23.8729', 'name': 'Pipal Khedi,Bhopal,Madhya Pradesh', 'lon': '78.2557'},
      '711225': {'lat': '22.7038', 'name': 'Pacharul,Howrah,West Bengal', 'lon': '88.5234'},
      '360520': {'lat': '24.2438', 'name': 'Vadala Sethna,Jamnagar,Gujarat', 'lon': '72.4586'},
      '577213': {'lat': '13.8179', 'name': 'Garaga,Shivamogga,Karnataka', 'lon': '75.4028'},
      '171102': {'lat': '31.1537', 'name': 'Mangoo,Solan,Himachal Pradesh', 'lon': '77.3178'},
      '171103': {'lat': '31.1478', 'name': 'Sainj,Shimla,Himachal Pradesh', 'lon': '77.2861'},
      '577216': {'lat': '14.0245', 'name': 'Madakechilur,Shivamogga,Karnataka', 'lon': '75.6845'},
      '577217': {'lat': '13.9132', 'name': 'Taraganahalli,Davangere,Karnataka', 'lon': '75.8032'},
      '577214': {'lat': '14.1883', 'name': 'Hithla,Shivamogga,Karnataka', 'lon': '75.363'},
      '577215': {'lat': '13.9611', 'name': 'Voddanahalu,Davangere,Karnataka', 'lon': '76.014'},
      '577218': {'lat': '14.408', 'name': 'Rudrapura,Davangere,Karnataka', 'lon': '75.9548'},
      '577219': {'lat': '14.3103', 'name': 'Kumblur,Davangere,Karnataka', 'lon': '75.8143'},
      '742123': {'lat': '24.2387', 'name': 'Sripat Singh College,Murshidabad,West Bengal', 'lon': '88.2645'},
      '388180': {'lat': '23.3897', 'name': 'Vastana,Kheda,Gujarat', 'lon': '73.2166'},
      '506201': {'lat': '18.0161', 'name': 'Pasaramadla,Warangal,Telangana', 'lon': '79.3899'},
      '491996': {'lat': '21.7356', 'name': 'Ranjitpur,Kabirdham,Chattisgarh', 'lon': '81.3674'},
      '148105': {'lat': '30.6201', 'name': 'Bhathlan,Barnala,Punjab', 'lon': '75.5633'},
      '501202': {'lat': '17.2386', 'name': 'Mominpet,K.V.Rangareddy,Telangana', 'lon': '78.1824'},
      '389250': {'lat': '23.2623', 'name': 'Diwda Colony,Panch Mahals,Gujarat', 'lon': '73.7971'},
      '503145': {'lat': '17.5546', 'name': 'Kupriyal,Nizamabad,Telangana', 'lon': '78.2049'},
      '503144': {'lat': '18.267', 'name': 'Somarampet,Nizamabad,Telangana', 'lon': '78.2857'},
      '848130': {'lat': '25.8517', 'name': 'Baghi,Samastipur,Bihar', 'lon': '85.7105'},
      '144210': {'lat': '31.6034', 'name': 'Hussain Pur Lalowal,Hoshiarpur,Punjab', 'lon': '75.8604'},
      '144211': {'lat': '31.9805', 'name': 'Tranglian,Hoshiarpur,Punjab', 'lon': '75.6005'},
      '144212': {'lat': '31.6856', 'name': 'Jhawan,Hoshiarpur,Punjab', 'lon': '75.6672'},
      '144213': {'lat': '31.7968', 'name': 'Fatehpur Bhattlan,Hoshiarpur,Punjab', 'lon': '75.8077'},
      '144214': {'lat': '31.8753', 'name': 'Abdulapur,Hoshiarpur,Punjab', 'lon': '75.4617'},
      '144216': {'lat': '31.911', 'name': 'Talwara Township,Hoshiarpur,Punjab', 'lon': '75.8889'},
      '389160': {'lat': '22.89635', 'name': 'Uchvania,Dahod,Gujarat', 'lon': '74.30067'},
      '500043': {'lat': '17.5587', 'name': 'Srirangavaram,K.V.Rangareddy,Telangana', 'lon': '78.4382'},
      '624208': {'lat': '10.5463', 'name': 'Nilakottai H.O,Dindigul,Tamil Nadu', 'lon': '77.7496'},
      '209652': {'lat': '27.3321', 'name': 'Rajendra Nagar,Farrukhabad,Uttar Pradesh', 'lon': '79.3083'},
      '491223': {'lat': '20.9476', 'name': 'Nipani Tipani,Durg,Chattisgarh', 'lon': '81.406'},
      '822115': {'lat': '24.1755', 'name': 'Pansa,Palamau,Jharkhand', 'lon': '83.9038'},
      '209651': {'lat': '27.2865', 'name': 'Rohila,Farrukhabad,Uttar Pradesh', 'lon': '79.1951'},
      '624201': {'lat': '10.2082', 'name': 'Malayagondanpatti,Dindigul,Tamil Nadu', 'lon': '78.2556'},
      '624202': {'lat': '10.3758', 'name': 'Kumbaraiyur,Dindigul,Tamil Nadu', 'lon': '77.8911'},
      '624204': {'lat': '11.3245', 'name': 'Devarappanpatti,Dindigul,Tamil Nadu', 'lon': '76.8847'},
      '624206': {'lat': '10.618', 'name': 'Metturgate,Dindigul,Tamil Nadu', 'lon': '77.6839'},
      '231001': {'lat': '24.95', 'name': 'Mirzapur Chowk,Mirzapur,Uttar Pradesh', 'lon': '82.8503'},
      '221009': {'lat': '25.4584', 'name': 'Sahupuri,Chandauli,Uttar Pradesh', 'lon': '82.8226'},
      '221008': {'lat': '25.4584', 'name': 'Kusth Ashram,Varanasi,Uttar Pradesh', 'lon': '82.8226'},
      '110098': {'lat': '28.6114', 'name': 'AMPC Delivery,New Delhi,Delhi', 'lon': '77.2982'},
      '110093': {'lat': '28.6114', 'name': 'Loni Road Housing Complex,East Delhi,Delhi', 'lon': '77.2982'},
      '110092': {'lat': '28.6266', 'name': 'Nirman Vihar,East Delhi,Delhi', 'lon': '77.2794'},
      '110091': {'lat': '28.6063', 'name': 'Mayur Vihar Ph-I,East Delhi,Delhi', 'lon': '77.3045'},
      '110090': {'lat': '28.6114', 'name': 'Khajuri Khas,East Delhi,Delhi', 'lon': '77.2982'},
      '110097': {'lat': '28.6114', 'name': 'Kapashera.,South West Delhi,Delhi', 'lon': '77.2982'},
      '110096': {'lat': '28.6114', 'name': 'Vasundhra Enclave,East Delhi,Delhi', 'lon': '77.2982'},
      '110095': {'lat': '28.6114', 'name': 'Old Seemapuri,East Delhi,Delhi', 'lon': '77.2982'},
      '110094': {'lat': '28.6114', 'name': 'Johripur,East Delhi,Delhi', 'lon': '77.2982'},
      '127046': {'lat': '28.8722', 'name': 'Dhani Miran,Bhiwani,Haryana', 'lon': '75.7'},
      '851132': {'lat': '25.5845', 'name': 'Arjuntola Baryarpur,Begusarai,Bihar', 'lon': '86.0731'},
      '127045': {'lat': '28.9638', 'name': 'Barwa,Bhiwani,Haryana', 'lon': '75.6054'},
      '127042': {'lat': '28.7114', 'name': 'Ranila,Bhiwani,Haryana', 'lon': '76.3589'},
      '127043': {'lat': '28.7363', 'name': 'Jhully,Bhiwani,Haryana', 'lon': '75.755'},
      '127040': {'lat': '28.8716', 'name': 'Dulheri,Bhiwani,Haryana', 'lon': '75.8278'},
      '127041': {'lat': '28.9886', 'name': 'Bhaini Kungar,Bhiwani,Haryana', 'lon': '76.1204'},
      '531151': {'lat': '18.3099', 'name': 'Rangaseela,Visakhapatnam,Andhra Pradesh', 'lon': '82.8237'},
      '181221': {'lat': '32.8083', 'name': 'Migrant Township Jagti,Jammu,Jammu & Kashmir', 'lon': '74.8958'},
      '581116': {'lat': '14.4556', 'name': 'Makari,Haveri,Karnataka', 'lon': '75.5102'},
      '181224': {'lat': '32.6849', 'name': 'Tahra,Jammu,Jammu & Kashmir', 'lon': '74.9929'},
      '464224': {'lat': '24.1824', 'name': 'Mahelua,Vidisha,Madhya Pradesh', 'lon': '77.8593'},
      '249125': {'lat': '30.3226', 'name': 'Bhaneldi,Tehri Garhwal,Uttarakhand', 'lon': '78.6089'},
      '464226': {'lat': '24.0812', 'name': 'Karaiyahat,Vidisha,Madhya Pradesh', 'lon': '77.2099'},
      '532168': {'lat': '18.386', 'name': 'Lolugu,Srikakulam,Andhra Pradesh', 'lon': '83.7677'},
      '637205': {'lat': '10.9334', 'name': 'Elayampalayam,Namakkal,Tamil Nadu', 'lon': '78.2368'},
      '464221': {'lat': '23.8246', 'name': 'Ancheda,Vidisha,Madhya Pradesh', 'lon': '78.1314'},
      '249122': {'lat': '30.2054', 'name': 'Kundri,Tehri Garhwal,Uttarakhand', 'lon': '78.85'},
      '249123': {'lat': '30.4488', 'name': 'Chheti,Tehri Garhwal,Uttarakhand', 'lon': '78.1377'},
      '609609': {'lat': '10.9434', 'name': 'Tiruvettakudi,Karaikal,Pondicherry', 'lon': '79.7558'},
      '464228': {'lat': '24.1557', 'name': 'Garantha,Vidisha,Madhya Pradesh', 'lon': '77.6417'},
      '581111': {'lat': '14.4944', 'name': 'Kalagonda,Haveri,Karnataka', 'lon': '75.3949'},
      '493335': {'lat': '21.6258', 'name': 'Hatodbazar,Raipur,Chattisgarh', 'lon': '82.4415'},
      '493331': {'lat': '21.7095', 'name': 'Karmada,Raipur,Chattisgarh', 'lon': '82.1039'},
      '493332': {'lat': '21.664', 'name': 'Bharsela Bada,Raipur,Chattisgarh', 'lon': '82.1923'},
      '344037': {'lat': '26.1047', 'name': 'Jakhada,Barmer,Rajasthan', 'lon': '71.8791'},
      '344034': {'lat': '26.9352', 'name': 'Chandesara,Barmer,Rajasthan', 'lon': '72.473'},
      '344035': {'lat': '26.1505', 'name': 'Lunada,Barmer,Rajasthan', 'lon': '71.2924'},
      '576230': {'lat': '13.6841', 'name': 'Tallur (Udupi),Udupi,Karnataka', 'lon': '74.7062'},
      '344033': {'lat': '25.9464', 'name': 'Chadon Ki Dhani,Barmer,Rajasthan', 'lon': '71.98'},
      '509353': {'lat': '17.312', 'name': 'Kachwar,Mahabub Nagar,Telangana', 'lon': '77.8749'},
      '344031': {'lat': '25.1797', 'name': 'Sindhasawa Chauhan,Barmer,Rajasthan', 'lon': '72.5213'},
      '851115': {'lat': '25.4462', 'name': 'Barauni Urvark Nagar,Begusarai,Bihar', 'lon': '85.9319'},
      '201020': {'lat': '28.7389', 'name': 'Khora,Ghaziabad,Uttar Pradesh', 'lon': '77.7067'},
      '632113': {'lat': '12.8435', 'name': 'Sathupalayam,Vellore,Tamil Nadu', 'lon': '79.7691'},
      '208016': {'lat': '26.5934', 'name': 'Iit,Kanpur Nagar,Uttar Pradesh', 'lon': '80.6879'},
      '208015': {'lat': '26.5934', 'name': 'New Pac Lines,Kanpur Nagar,Uttar Pradesh', 'lon': '80.6879'},
      '208014': {'lat': '26.5934', 'name': 'Nirala Nagar,Kanpur Nagar,Uttar Pradesh', 'lon': '80.6879'},
      '208013': {'lat': '26.2429', 'name': 'COD (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '79.7876'},
      '208012': {'lat': '27.0884', 'name': 'Jawahar Nagar (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '81.7153'},
      '632115': {'lat': '12.8435', 'name': 'Thiruvalluvar University,Vellore,Tamil Nadu', 'lon': '79.7691'},
      '632114': {'lat': '12.8435', 'name': 'Sathyamangalam (Vellore)S.O,Vellore,Tamil Nadu', 'lon': '79.7691'},
      '509104': {'lat': '16.2657', 'name': 'Ammayapally,Mahabub Nagar,Telangana', 'lon': '78.207'},
      '453111': {'lat': '22.7599', 'name': 'Kalmer,Indore,Madhya Pradesh', 'lon': '75.7105'},
      '208019': {'lat': '26.4756', 'name': 'Syyed Nagar Post Office,Kanpur Nagar,Uttar Pradesh', 'lon': '80.2763'},
      '400103': {'lat': '19.1624', 'name': 'Mandapeshwar,Mumbai,Maharashtra', 'lon': '72.8694'},
      '400102': {'lat': '19.1624', 'name': 'Jogeshwari West,Mumbai,Maharashtra', 'lon': '72.8694'},
      '400101': {'lat': '19.1624', 'name': 'Kandivali East,Mumbai,Maharashtra', 'lon': '72.8694'},
      '400104': {'lat': '19.1624', 'name': 'Goregaon RS,Mumbai,Maharashtra', 'lon': '72.8694'},
      '486892': {'lat': '24.2865', 'name': 'Dudhmania,Sidhi,Madhya Pradesh', 'lon': '82.3364'},
      '711302': {'lat': '23.2934', 'name': 'Argori,Howrah,West Bengal', 'lon': '88.0202'},
      '486890': {'lat': '24.2865', 'name': 'Jayant Colliery,Sidhi,Madhya Pradesh', 'lon': '82.3364'},
      '392180': {'lat': '22.1713', 'name': 'Kavli,Bharuch,Gujarat', 'lon': '72.7465'},
      '143414': {'lat': '31.3607', 'name': 'Naushhra Dhalla,Amritsar,Punjab', 'lon': '74.8108'},
      '804418': {'lat': '25.1909', 'name': 'Saristabad,Jehanabad,Bihar', 'lon': '85.1605'},
      '206250': {'lat': '26.6992', 'name': 'Rajaumau,Auraiya,Uttar Pradesh', 'lon': '79.4907'},
      '396170': {'lat': '20.1972', 'name': 'Palgam,Valsad,Gujarat', 'lon': '72.7503'},
      '396171': {'lat': '20.1972', 'name': 'Umargam I.E.,Valsad,Gujarat', 'lon': '72.7503'},
      '474012': {'lat': '26.0221', 'name': 'S.P. Ashram,Gwalior,Madhya Pradesh', 'lon': '78.148'},
      '474010': {'lat': '26.0221', 'name': 'Sigora,Gwalior,Madhya Pradesh', 'lon': '78.148'},
      '474011': {'lat': '26.0221', 'name': 'New High Court,Gwalior,Madhya Pradesh', 'lon': '78.148'},
      '626110': {'lat': '9.443', 'name': 'Mohamed Sahibpuram,Virudhunagar,Tamil Nadu', 'lon': '77.8389'},
      '843332': {'lat': '26.3435', 'name': 'Manaraya,Sitamarhi,Bihar', 'lon': '85.2689'},
      '500049': {'lat': '17.529', 'name': 'Mathrusri Nagar,K.V.Rangareddy,Telangana', 'lon': '78.6839'},
      '306306': {'lat': '25.971', 'name': 'Sandiya,Pali,Rajasthan', 'lon': '73.7676'},
      '582111': {'lat': '15.4311', 'name': 'Kodikoppa,Gadag,Karnataka', 'lon': '75.6663'},
      '362315': {'lat': '22.3665', 'name': 'Kathrota,Junagadh,Gujarat', 'lon': '71.0239'},
      '582112': {'lat': '15.2627', 'name': 'Bellatti,Gadag,Karnataka', 'lon': '75.7337'},
      '582115': {'lat': '15.4311', 'name': 'Hallikeri,Gadag,Karnataka', 'lon': '75.6663'},
      '582114': {'lat': '15.7505', 'name': 'Chilzeri,Gadag,Karnataka', 'lon': '76.1496'},
      '582117': {'lat': '15.4071', 'name': 'Yelavatti,Gadag,Karnataka', 'lon': '75.4736'},
      '362310': {'lat': '22.3665', 'name': 'Chokli,Junagadh,Gujarat', 'lon': '71.0239'},
      '582119': {'lat': '15.6172', 'name': 'Halkeri,Gadag,Karnataka', 'lon': '75.879'},
      '582118': {'lat': '15.1182', 'name': 'Bagewadi,Gadag,Karnataka', 'lon': '75.762'},
      '505212': {'lat': '18.8095', 'name': 'Maidambanda,Karim Nagar,Telangana', 'lon': '79.3808'},
      '500048': {'lat': '17.288', 'name': 'Attapur,K.V.Rangareddy,Telangana', 'lon': '78.1774'},
      '332742': {'lat': '25.6438', 'name': 'Mandha Surera,Sikar,Rajasthan', 'lon': '76.0536'},
      '306307': {'lat': '26.0912', 'name': 'Sabalpura,Pali,Rajasthan', 'lon': '74.2281'},
      '363351': {'lat': '23.0645', 'name': 'Ranjitgadh,Surendra Nagar,Gujarat', 'lon': '71.0891'},
      '711301': {'lat': '22.4971', 'name': 'Dihimondal Ghat,Howrah,West Bengal', 'lon': '88.2851'},
      '700015': {'lat': '22.55', 'name': 'Seal Lane,Kolkata,West Bengal', 'lon': '88.3833'},
      '700016': {'lat': '22.8436', 'name': 'Madrassa,Kolkata,West Bengal', 'lon': '88.2293'},
      '700017': {'lat': '22.8436', 'name': 'Circus Avenue,Kolkata,West Bengal', 'lon': '88.2293'},
      '700010': {'lat': '22.8436', 'name': 'Beleghata H.O,Kolkata,West Bengal', 'lon': '88.2293'},
      '700011': {'lat': '22.8436', 'name': 'Narkeldanga,Kolkata,West Bengal', 'lon': '88.2293'},
      '700012': {'lat': '22.8436', 'name': 'Yogayog Bhawan,Kolkata,West Bengal', 'lon': '88.2293'},
      '735211': {'lat': '26.4963', 'name': 'Baganbari,Alipurduar,West Bengal', 'lon': '89.2102'},
      '456337': {'lat': '22.8809', 'name': 'Palsoda Makdavan,Ujjain,Madhya Pradesh', 'lon': '74.6313'},
      '246729': {'lat': '29.2132', 'name': 'Maseet,Bijnor,Uttar Pradesh', 'lon': '78.285'},
      '332746': {'lat': '27.6671', 'name': 'Thathwari,Jhujhunu,Rajasthan', 'lon': '76.0715'},
      '700018': {'lat': '24.1209', 'name': 'Rabindra Nagar (Kolkata),Kolkata,West Bengal', 'lon': '87.6724'},
      '700019': {'lat': '22.529', 'name': 'Golpark,Kolkata,West Bengal', 'lon': '88.368'},
      '625706': {'lat': '9.7543', 'name': 'Tirali,Madurai,Tamil Nadu', 'lon': '78.1364'},
      '422011': {'lat': '19.1728', 'name': 'Dwarka Corner,Nashik,Maharashtra', 'lon': '75.1743'},
      '422010': {'lat': '19.1728', 'name': 'Vilholi,Nashik,Maharashtra', 'lon': '75.1743'},
      '422013': {'lat': '19.1728', 'name': 'Sawarkar Nagar,Nashik,Maharashtra', 'lon': '75.1743'},
      '422012': {'lat': '19.1728', 'name': 'Ashok Nagar (Nashik),Nashik,Maharashtra', 'lon': '75.1743'},
      '444201': {'lat': '20.7563', 'name': 'Jastgaon,Buldhana,Maharashtra', 'lon': '76.9774'},
      '444202': {'lat': '21.0285', 'name': 'Palsi Zanshi,Buldhana,Maharashtra', 'lon': '76.6951'},
      '444203': {'lat': '20.5297', 'name': 'Chincholi Karfarma,Buldhana,Maharashtra', 'lon': '77.0839'},
      '444204': {'lat': '20.5111', 'name': 'Ladnapur,Buldhana,Maharashtra', 'lon': '76.4083'},
      '824201': {'lat': '24.6995', 'name': 'Lahuthua,Gaya,Bihar', 'lon': '85.1019'},
      '607804': {'lat': '11.5841', 'name': 'Kotteri,Cuddalore,Tamil Nadu', 'lon': '79.3772'},
      '828130': {'lat': '23.791', 'name': 'B.Polytechnic,Dhanbad,Jharkhand', 'lon': '85.7021'},
      '828131': {'lat': '23.8293', 'name': 'Madhuban,Dhanbad,Jharkhand', 'lon': '86.4719'},
      '828132': {'lat': '23.791', 'name': 'Patherdih Coal Washery,Dhanbad,Jharkhand', 'lon': '85.7021'},
      '828133': {'lat': '23.791', 'name': 'Bhulan Bararee,Dhanbad,Jharkhand', 'lon': '85.7021'},
      '828134': {'lat': '23.7751', 'name': 'Kumar Daga,Bokaro,Jharkhand', 'lon': '85.5735'},
      '607807': {'lat': '11.6048', 'name': 'Neyveli Thermal Bus Stand,Cuddalore,Tamil Nadu', 'lon': '79.521'},
      '622202': {'lat': '10.2402', 'name': 'Vadakattupatti,Pudukkottai,Tamil Nadu', 'lon': '78.84'},
      '622203': {'lat': '10.603', 'name': 'Minnathur,Pudukkottai,Tamil Nadu', 'lon': '79.1347'},
      '387570': {'lat': '23.0041', 'name': 'Sokhada,Kheda,Gujarat', 'lon': '73.0141'},
      '622201': {'lat': '10.3629', 'name': 'Mirattunilai,Pudukkottai,Tamil Nadu', 'lon': '78.7895'},
      '622204': {'lat': '10.067', 'name': 'Yembal,Pudukkottai,Tamil Nadu', 'lon': '78.9756'},
      '607801': {'lat': '11.7623', 'name': 'Block 10,25,neyveli', 'lon': '79.6726'},
      '609501': {'lat': '10.7835', 'name': 'Tiruvizhimizhalai,Tiruvarur,Tamil Nadu', 'lon': '79.4428'},
      '711307': {'lat': '22.5167', 'name': 'Khalisani,Howrah,West Bengal', 'lon': '88.0828'},
      '630557': {'lat': '9.9151', 'name': 'Sholapuram,Sivaganga,Tamil Nadu', 'lon': '78.5987'},
      '281501': {'lat': '27.464', 'name': 'Junsuti,Mathura,Uttar Pradesh', 'lon': '77.5726'},
      '458116': {'lat': '23.9192', 'name': 'Danta,Neemuch,Madhya Pradesh', 'lon': '75.5252'},
      '721301': {'lat': '22.3502', 'name': 'Benapur,West Midnapore,West Bengal', 'lon': '87.6683'},
      '182128': {'lat': '32.6968', 'name': 'Keya,Udhampur,Jammu & Kashmir', 'lon': '75.2715'},
      '721303': {'lat': '22.5198', 'name': 'Kalaikunda A F,West Midnapore,West Bengal', 'lon': '87.9343'},
      '721302': {'lat': '22.5198', 'name': 'Kharagpur Technology,West Midnapore,West Bengal', 'lon': '87.9343'},
      '721305': {'lat': '23.0289', 'name': 'Matkatpur,West Midnapore,West Bengal', 'lon': '88.7326'},
      '607803': {'lat': '11.5829', 'name': 'Block 5,25,Neyveli', 'lon': '79.47635'},
      '204213': {'lat': '27.5772', 'name': 'Pata Khas,Hathras,Uttar Pradesh', 'lon': '77.9467'},
      '735301': {'lat': '26.4156', 'name': 'Kamat Changrabandha,Cooch Behar,West Bengal', 'lon': '88.9187'},
      '695612': {'lat': '8.7691', 'name': 'Thalikuzhi,Thiruvananthapuram,Kerala', 'lon': '76.8384'},
      '695611': {'lat': '8.7383', 'name': 'Manambur,Thiruvananthapuram,Kerala', 'lon': '76.7975'},
      '695610': {'lat': '8.7691', 'name': 'Mithirmala,Thiruvananthapuram,Kerala', 'lon': '76.8384'},
      '626189': {'lat': '9.2927', 'name': 'Sinnakamanpatti,Virudhunagar,Tamil Nadu', 'lon': '77.7657'},
      '626188': {'lat': '9.2927', 'name': 'Dhalavaipuram,Virudhunagar,Tamil Nadu', 'lon': '77.7657'},
      '695615': {'lat': '8.7691', 'name': 'Vembayam,Thiruvananthapuram,Kerala', 'lon': '76.8384'},
      '695614': {'lat': '8.7999', 'name': 'Mulakkalathukavu,Thiruvananthapuram,Kerala', 'lon': '76.8792'},
      '491225': {'lat': '20.9152', 'name': 'Suregaon,Durg,Chattisgarh', 'lon': '81.1335'},
      '691509': {'lat': '8.9353', 'name': 'Nellimukku,Kollam,Kerala', 'lon': '76.73'},
      '691508': {'lat': '9.0326', 'name': 'Vilakudy,Kollam,Kerala', 'lon': '76.8492'},
      '824143': {'lat': '25.1076', 'name': 'Dhnaoh Bigha,Aurangabad(BH),Bihar', 'lon': '84.4704'},
      '815355': {'lat': '23.8662', 'name': 'Sitamurhi,Jamtara,Jharkhand', 'lon': '86.2926'},
      '691503': {'lat': '8.9801', 'name': 'Padappakara,Kollam,Kerala', 'lon': '76.6444'},
      '691502': {'lat': '8.9956', 'name': 'Munroethuruthu,Kollam,Kerala', 'lon': '76.7347'},
      '691501': {'lat': '8.9956', 'name': 'Nedumpaikulam,Kollam,Kerala', 'lon': '76.7347'},
      '691500': {'lat': '8.9956', 'name': 'Peruvelikara,Kollam,Kerala', 'lon': '76.7347'},
      '691507': {'lat': '9.0238', 'name': 'Thevalappuram,Kollam,Kerala', 'lon': '76.7592'},
      '691506': {'lat': '8.9956', 'name': 'Kottarakara H.O,Kollam,Kerala', 'lon': '76.7347'},
      '691505': {'lat': '8.9915', 'name': 'Idakkadom,Kollam,Kerala', 'lon': '76.717'},
      '691504': {'lat': '8.9956', 'name': 'Perumpuzha,Kollam,Kerala', 'lon': '76.7347'},
      '481449': {'lat': '21.6346', 'name': 'Miragpur,Balaghat,Madhya Pradesh', 'lon': '79.8369'},
      '824207': {'lat': '24.9546', 'name': 'Bmajhiawan,Gaya,Bihar', 'lon': '84.7766'},
      '471525': {'lat': '24.9294', 'name': 'Gohani,Chhatarpur,Madhya Pradesh', 'lon': '80.0553'},
      '768108': {'lat': '21.4478', 'name': 'Khilei,Debagarh,Odisha', 'lon': '84.7904'},
      '247231': {'lat': '30.0843', 'name': 'Bartha Kayastha,Saharanpur,Uttar Pradesh', 'lon': '77.5774'},
      '450445': {'lat': '21.828', 'name': 'Bambhada,Burhanpur,Madhya Pradesh', 'lon': '77.3711'},
      '637503': {'lat': '10.945', 'name': 'Thuthipalayam,Namakkal,Tamil Nadu', 'lon': '77.9226'},
      '281202': {'lat': '27.4711', 'name': 'Dangoli Banger,Mathura,Uttar Pradesh', 'lon': '78.3083'},
      '212214': {'lat': '25.4538', 'name': 'Kaushambi,Kaushambi,Uttar Pradesh', 'lon': '81.4642'},
      '416628': {'lat': '16.2388', 'name': 'Pokharan,Sindhudurg,Maharashtra', 'lon': '73.4089'},
      '711305': {'lat': '22.4939', 'name': 'Bauria,Howrah,West Bengal', 'lon': '88.168'},
      '630551': {'lat': '9.8555', 'name': 'Pulikanmoi,Sivaganga,Tamil Nadu', 'lon': '78.5009'},
      '815353': {'lat': '24.4442', 'name': 'Charpa,Deoghar,Jharkhand', 'lon': '86.8131'},
      '735215': {'lat': '26.7469', 'name': 'Madhubagan,Alipurduar,West Bengal', 'lon': '89.3538'},
      '360311': {'lat': '21.6853', 'name': 'Shivrajgadh,Rajkot,Gujarat', 'lon': '70.903'},
      '534007': {'lat': '16.6927', 'name': 'Kokkiripadu,West Godavari,Andhra Pradesh', 'lon': '81.0158'},
      '534004': {'lat': '16.6645', 'name': 'Komadavole,West Godavari,Andhra Pradesh', 'lon': '81.1581'},
      '534005': {'lat': '16.7212', 'name': 'Eluru R.S,West Godavari,Andhra Pradesh', 'lon': '81.1052'},
      '534002': {'lat': '16.305', 'name': 'Mupparu,West Godavari,Andhra Pradesh', 'lon': '80.7648'},
      '534003': {'lat': '16.7536', 'name': 'Pedakadimi,West Godavari,Andhra Pradesh', 'lon': '81.0397'},
      '534001': {'lat': '16.57', 'name': 'Eluru Bazar,West Godavari,Andhra Pradesh', 'lon': '80.9808'},
      '384212': {'lat': '23.5819', 'name': 'Vijapurda,Mahesana,Gujarat', 'lon': '72.1402'},
      '384210': {'lat': '23.5157', 'name': 'Becharaji,Mahesana,Gujarat', 'lon': '72.0772'},
      '833214': {'lat': '22.177', 'name': 'Punga,West Singhbhum,Jharkhand', 'lon': '85.8001'},
      '637502': {'lat': '11.4688', 'name': 'Teppakuttai,Salem,Tamil Nadu', 'lon': '78.139'},
      '384215': {'lat': '23.5488', 'name': 'Varvada,Mahesana,Gujarat', 'lon': '72.1087'},
      '560087': {'lat': '13.4353', 'name': 'Vartur,Bengaluru,Karnataka', 'lon': '77.5193'},
      '560086': {'lat': '13.0127', 'name': 'West of Chord Road II stage,Bengaluru,Karnataka', 'lon': '77.5078'},
      '560085': {'lat': '13.2257', 'name': 'Kathriguppe,Bengaluru,Karnataka', 'lon': '77.575'},
      '560084': {'lat': '13.2257', 'name': 'Lingarajapuram,Bengaluru,Karnataka', 'lon': '77.575'},
      '560083': {'lat': '13.2257', 'name': 'Bannerghatta,Bengaluru,Karnataka', 'lon': '77.575'},
      '560082': {'lat': '13.0127', 'name': 'Udaypura,Bangalore Rural,Karnataka', 'lon': '77.5078'},
      '560081': {'lat': '13.2257', 'name': 'Chandapura,Bengaluru,Karnataka', 'lon': '77.575'},
      '560080': {'lat': '13.2257', 'name': 'Sadashivanagar,Bengaluru,Karnataka', 'lon': '77.575'},
      '281504': {'lat': '27.5851', 'name': 'Pelkhu,Mathura,Uttar Pradesh', 'lon': '77.5114'},
      '643214': {'lat': '11.4146', 'name': 'Kagguchi,Nilgiris,Tamil Nadu', 'lon': '76.8562'},
      '505475': {'lat': '17.9883', 'name': 'Bijnur,Karim Nagar,Telangana', 'lon': '79.5277'},
      '560089': {'lat': '13.1876', 'name': 'Byatha,Bangalore Rural,Karnataka', 'lon': '77.52'},
      '560088': {'lat': '13.0127', 'name': 'Hessarghatta,Bangalore Rural,Karnataka', 'lon': '77.5078'},
      '621711': {'lat': '10.9812', 'name': 'Vandalaigudalur,Tiruchirappalli,Tamil Nadu', 'lon': '78.8752'},
      '208025': {'lat': '26.418', 'name': 'Saketpuri,Kanpur Nagar,Uttar Pradesh', 'lon': '80.298'},
      '461122': {'lat': '22.673', 'name': 'OFE Itarsi,Hoshangabad,Madhya Pradesh', 'lon': '77.6181'},
      '781309': {'lat': '26.303', 'name': 'Bhogarpar,Nalbari,Assam', 'lon': '91.0645'},
      '781308': {'lat': '26.3278', 'name': 'Satrakanara Bazar,Barpeta,Assam', 'lon': '90.4365'},
      '781307': {'lat': '26.3629', 'name': 'Namsala,Barpeta,Assam', 'lon': '91.2231'},
      '781306': {'lat': '26.3379', 'name': 'Gamurimuri,Nalbari,Assam', 'lon': '91.2087'},
      '781305': {'lat': '26.3054', 'name': 'Pacim Mazdia,Nalbari,Assam', 'lon': '91.1713'},
      '781304': {'lat': '26.3637', 'name': 'Gandhia,Nalbari,Assam', 'lon': '91.3439'},
      '781303': {'lat': '26.3834', 'name': 'Mularkuchi,Nalbari,Assam', 'lon': '92.7945'},
      '781302': {'lat': '26.3379', 'name': 'Baharihat,Barpeta,Assam', 'lon': '91.2087'},
      '781301': {'lat': '26.3379', 'name': 'Thakurbazar,Barpeta,Assam', 'lon': '91.2087'},
      '571301': {'lat': '12.2088', 'name': 'Srikantapuri Extension,Mysuru,Karnataka', 'lon': '76.6717'},
      '813213': {'lat': '25.1782', 'name': 'Gangapur Diara,Bhagalpur,Bihar', 'lon': '86.8442'},
      '492016': {'lat': '21.1879', 'name': 'Mana Basti.,Raipur,Chattisgarh', 'lon': '81.6898'},
      '208020': {'lat': '26.4731', 'name': 'Panki Ganga Ganj,Kanpur Nagar,Uttar Pradesh', 'lon': '80.2407'},
      '813212': {'lat': '24.7649', 'name': 'Kumri,Munger,Bihar', 'lon': '85.2588'},
      '713343': {'lat': '24.0224', 'name': 'Lalbazar,Paschim Bardhaman,West Bengal', 'lon': '87.6555'},
      '208021': {'lat': '26.3843', 'name': 'Taudhakpur,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3275'},
      '481776': {'lat': '22.4216', 'name': 'Gokulthana,Mandla,Madhya Pradesh', 'lon': '80.1431'},
      '273411': {'lat': '26.4769', 'name': 'Belabirbhan,Gorakhpur,Uttar Pradesh', 'lon': '83.4291'},
      '495687': {'lat': '21.9766', 'name': 'Baradwar,Janjgir-champa,Chattisgarh', 'lon': '82.8203'},
      '273413': {'lat': '26.5398', 'name': 'Girdharpur,Gorakhpur,Uttar Pradesh', 'lon': '83.3572'},
      '273412': {'lat': '26.3056', 'name': 'Rampur Bhaghaura,Gorakhpur,Uttar Pradesh', 'lon': '83.4091'},
      '495682': {'lat': '21.9375', 'name': 'Manikpur Colliery,Korba,Chattisgarh', 'lon': '82.8855'},
      '495683': {'lat': '22.1604', 'name': 'Pasarkhet,Korba,Chattisgarh', 'lon': '82.8768'},
      '630558': {'lat': '9.95', 'name': 'Paganeri,Sivaganga,Tamil Nadu', 'lon': '78.6'},
      '495689': {'lat': '21.9395', 'name': 'Sonadula,Janjgir-champa,Chattisgarh', 'lon': '82.9587'},
      '601201': {'lat': '13.3974', 'name': 'Periaobulapuam,Tiruvallur,Tamil Nadu', 'lon': '79.9891'},
      '711308': {'lat': '22.5044', 'name': 'Chengail,Howrah,West Bengal', 'lon': '88.1436'},
      '630552': {'lat': '9.8555', 'name': 'Kilapungudi,Sivaganga,Tamil Nadu', 'lon': '78.5009'},
      '209825': {'lat': '26.4092', 'name': 'Padri Kalan,Unnao,Uttar Pradesh', 'lon': '80.81'},
      '209827': {'lat': '27.2586', 'name': 'Unchgaon Sani,Unnao,Uttar Pradesh', 'lon': '81.0848'},
      '209821': {'lat': '26.5196', 'name': 'Sarwan,Unnao,Uttar Pradesh', 'lon': '80.8777'},
      '688570': {'lat': '9.85', 'name': 'Perumbalam,Alappuzha,Kerala', 'lon': '76.3667'},
      '322204': {'lat': '26.5806', 'name': 'Gandal,Sawai Madhopur,Rajasthan', 'lon': '76.6692'},
      '322201': {'lat': '26.4588', 'name': 'Udaikalan,Sawai Madhopur,Rajasthan', 'lon': '76.6396'},
      '485331': {'lat': '24.7042', 'name': 'Shahpur,Rewa,Madhya Pradesh', 'lon': '81.2432'},
      '322203': {'lat': '26.4028', 'name': 'Narayanpur Tatwara,Sawai Madhopur,Rajasthan', 'lon': '76.7029'},
      '322202': {'lat': '26.4155', 'name': 'Toksi,Sawai Madhopur,Rajasthan', 'lon': '76.6378'},
      '305901': {'lat': '25.2163', 'name': 'Borwa,Rajsamand,Rajasthan', 'lon': '73.8923'},
      '507208': {'lat': '17.9396', 'name': 'Nagiligonda,Khammam,Telangana', 'lon': '80.1568'},
      '452020': {'lat': '22.6259', 'name': 'Tillorbujurg,Indore,Madhya Pradesh', 'lon': '75.9278'},
      '507209': {'lat': '17.2649', 'name': 'Mucharam,Khammam,Telangana', 'lon': '80.3411'},
      '229401': {'lat': '26.2288', 'name': 'Pari,Raebareli,Uttar Pradesh', 'lon': '81.2314'},
      '743329': {'lat': '22.1996', 'name': 'Meraganj,South 24 Parganas,West Bengal', 'lon': '88.6324'},
      '601206': {'lat': '13.3529', 'name': 'Kilmudalambedu,Tiruvallur,Tamil Nadu', 'lon': '80.1205'},
      '606709': {'lat': '12.2335', 'name': 'Pakkiripalayam,Tiruvannamalai,Tamil Nadu', 'lon': '78.8001'},
      '798604': {'lat': '26.3748', 'name': 'Mongkholemba,Mokokchung,Nagaland', 'lon': '94.6222'},
      '507301': {'lat': '17.3193', 'name': 'Asupaka,Khammam,Telangana', 'lon': '80.968'},
      '508114': {'lat': '17.3976', 'name': 'Gundrampally,Nalgonda,Telangana', 'lon': '79.4586'},
      '821106': {'lat': '25.1572', 'name': 'Hatta (Kaimur (Bhabua)),Kaimur (Bhabua),Bihar', 'lon': '83.6521'},
      '507304': {'lat': '17.4753', 'name': 'Repalle,Khammam,Telangana', 'lon': '79.5732'},
      '606701': {'lat': '12.3089', 'name': 'Valayampattu,Tiruvannamalai,Tamil Nadu', 'lon': '78.7914'},
      '606703': {'lat': '12.2449', 'name': 'Andipattu,Tiruvannamalai,Tamil Nadu', 'lon': '78.7171'},
      '606702': {'lat': '12.2629', 'name': 'Alathur,Tiruvannamalai,Tamil Nadu', 'lon': '78.9215'},
      '606705': {'lat': '12.4316', 'name': 'Veeranandal,Tiruvannamalai,Tamil Nadu', 'lon': '78.5701'},
      '606704': {'lat': '12.4672', 'name': 'Vasudevampattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.2062'},
      '606707': {'lat': '12.1133', 'name': 'Kilsirupakkam,Tiruvannamalai,Tamil Nadu', 'lon': '79.1647'},
      '606706': {'lat': '12.1889', 'name': 'Sathanurdam,Tiruvannamalai,Tamil Nadu', 'lon': '78.861'},
      '803115': {'lat': '25.127', 'name': 'Pyarepur,Nalanda,Bihar', 'lon': '85.5591'},
      '522211': {'lat': '16.0544', 'name': 'Penugudurupadu,Guntur,Andhra Pradesh', 'lon': '80.3204'},
      '522212': {'lat': '16.0112', 'name': 'Jupudi,Guntur,Andhra Pradesh', 'lon': '80.267'},
      '522213': {'lat': '16.2703', 'name': 'Vadlamudi,Guntur,Andhra Pradesh', 'lon': '80.5875'},
      '796081': {'lat': '24.2239', 'name': 'Builum,Kolasib,Mizoram', 'lon': '92.6787'},
      '814158': {'lat': '24.8959', 'name': 'Guhiajori,Dumka,Jharkhand', 'lon': '87.42'},
      '453001': {'lat': '22.9462', 'name': 'Rolai,Indore,Madhya Pradesh', 'lon': '75.734'},
      '625522': {'lat': '9.8475', 'name': 'Kombai,Theni,Tamil Nadu', 'lon': '77.296'},
      '515551': {'lat': '13.9993', 'name': 'Uruvoi Mallireddypalli,Ananthapur,Andhra Pradesh', 'lon': '78.1512'},
      '625520': {'lat': '9.8914', 'name': 'Poomalaikundu,Theni,Tamil Nadu', 'lon': '77.4585'},
      '625521': {'lat': '9.8867', 'name': 'Kamayakoundanpatti,Theni,Tamil Nadu', 'lon': '77.3673'},
      '625526': {'lat': '9.7885', 'name': 'Royappanpatti,Theni,Tamil Nadu', 'lon': '77.3443'},
      '521001': {'lat': '16.7559', 'name': 'Balaramunipet,Krishna,Andhra Pradesh', 'lon': '81.1584'},
      '521002': {'lat': '16.5775', 'name': 'Mekavanipalem,Krishna,Andhra Pradesh', 'lon': '81.4554'},
      '521003': {'lat': '16.8451', 'name': 'Pedayadara,Krishna,Andhra Pradesh', 'lon': '81.01'},
      '625528': {'lat': '9.9621', 'name': 'Silamalai,Theni,Tamil Nadu', 'lon': '77.3371'},
      '798603': {'lat': '26.5709', 'name': 'Monyakshu,Mon,Nagaland', 'lon': '94.927'},
      '209302': {'lat': '26.5562', 'name': 'Chhatrsa,Kanpur Dehat,Uttar Pradesh', 'lon': '79.7423'},
      '396125': {'lat': '21.7065', 'name': 'Sukesh,Valsad,Gujarat', 'lon': '73.4056'},
      '803114': {'lat': '25.2277', 'name': 'Sinama,Nalanda,Bihar', 'lon': '85.1'},
      '144027': {'lat': '31.2945', 'name': 'Suranussi,Jalandhar,Punjab', 'lon': '75.7539'},
      '144026': {'lat': '31.564', 'name': 'Khambra,Jalandhar,Punjab', 'lon': '75.8547'},
      '144025': {'lat': '31.3766', 'name': 'Sheikhe Pind,Jalandhar,Punjab', 'lon': '75.6506'},
      '144024': {'lat': '31.0523', 'name': 'Kot Kalan,Jalandhar,Punjab', 'lon': '76.0849'},
      '144023': {'lat': '31.2945', 'name': 'Dakoha,Jalandhar,Punjab', 'lon': '75.7539'},
      '144022': {'lat': '31.2945', 'name': 'Mithapur,Jalandhar,Punjab', 'lon': '75.7539'},
      '144021': {'lat': '31.0792', 'name': 'Nagra (Jalandhar),Jalandhar,Punjab', 'lon': '75.6924'},
      '144020': {'lat': '31.2357', 'name': 'Qadian Wali,Jalandhar,Punjab', 'lon': '75.6186'},
      '144029': {'lat': '31.1091', 'name': 'Sheikhupur,Nawanshahr,Punjab', 'lon': '76.156'},
      '144028': {'lat': '31.2377', 'name': 'Chitti,Jalandhar,Punjab', 'lon': '75.4766'},
      '431147': {'lat': '20.3667', 'name': 'Chincholi Limbaji,Aurangabad,Maharashtra', 'lon': '75.2999'},
      '577536': {'lat': '14.511', 'name': 'N.Mahadevapura,Chitradurga,Karnataka', 'lon': '76.5946'},
      '577535': {'lat': '14.6534', 'name': 'Chikkobanahally,Chitradurga,Karnataka', 'lon': '76.3313'},
      '431144': {'lat': '19.6881', 'name': 'Pipmarkhed,Beed,Maharashtra', 'lon': '77.9838'},
      '431143': {'lat': '19.8235', 'name': 'Khandvi,Beed,Maharashtra', 'lon': '77.3476'},
      '686611': {'lat': '10.4995', 'name': 'Perumthuruth,Kottayam,Kerala', 'lon': '76.2679'},
      '577531': {'lat': '13.9413', 'name': 'Dogganahal,Chitradurga,Karnataka', 'lon': '75.828'},
      '577530': {'lat': '14.3625', 'name': 'Govinahalu,Davangere,Karnataka', 'lon': '75.7412'},
      '393115': {'lat': '22.0359', 'name': 'Razalwada,Bharuch,Gujarat', 'lon': '73.253'},
      '502285': {'lat': '17.5803', 'name': 'Kalivemula,Medak,Telangana', 'lon': '78.1089'},
      '515556': {'lat': '13.9366', 'name': 'Chikirevulapalli,Ananthapur,Andhra Pradesh', 'lon': '78.0425'},
      '393110': {'lat': '22.296', 'name': 'Talodra,Bharuch,Gujarat', 'lon': '73.3135'},
      '577539': {'lat': '13.9394', 'name': 'Talakatta,Chitradurga,Karnataka', 'lon': '76.1143'},
      '431148': {'lat': '19.4445', 'name': 'Pimpalwadi (Pirachi),Aurangabad,Maharashtra', 'lon': '74.8255'},
      '148023': {'lat': '30.3997', 'name': 'Bhurthala Mander,Sangrur,Punjab', 'lon': '75.8285'},
      '573226': {'lat': '13.018', 'name': 'Boovanahalli,Hassan,Karnataka', 'lon': '76.2082'},
      '573225': {'lat': '12.9695', 'name': 'Hirihalli,Hassan,Karnataka', 'lon': '76.3069'},
      '532403': {'lat': '18.2464', 'name': 'Budumuru,Srikakulam,Andhra Pradesh', 'lon': '83.7663'},
      '148027': {'lat': '29.8315', 'name': 'Banarsi,Sangrur,Punjab', 'lon': '76.0815'},
      '148026': {'lat': '30.2339', 'name': 'Bharo,Sangrur,Punjab', 'lon': '76.023'},
      '148025': {'lat': '30.4428', 'name': 'Guram,Barnala,Punjab', 'lon': '75.7041'},
      '573220': {'lat': '12.9667', 'name': 'Doddagenigere,Hassan,Karnataka', 'lon': '76.1832'},
      '628204': {'lat': '8.5714', 'name': 'Kayalpatnam Bazaar,Tuticorin,Tamil Nadu', 'lon': '78.1199'},
      '628205': {'lat': '8.4942', 'name': 'Kayamozhi,Tuticorin,Tamil Nadu', 'lon': '78.0686'},
      '148029': {'lat': '30.1321', 'name': 'Jharon,Sangrur,Punjab', 'lon': '75.6484'},
      '148028': {'lat': '30.2443', 'name': 'Sunam name,Sangrur,Punjab', 'lon': '75.8737'},
      '628201': {'lat': '8.5522', 'name': 'Moolakarai,Tuticorin,Tamil Nadu', 'lon': '78.0601'},
      '628202': {'lat': '8.569', 'name': 'Lakshmimanagaram,Tuticorin,Tamil Nadu', 'lon': '78.0981'},
      '628203': {'lat': '8.4292', 'name': 'Meyoor,Tuticorin,Tamil Nadu', 'lon': '78.0286'},
      '524301': {'lat': '14.4268', 'name': 'Manchalapalli,Nellore,Andhra Pradesh', 'lon': '79.7344'},
      '207246': {'lat': '27.5683', 'name': 'Bhensrasi,Etah,Uttar Pradesh', 'lon': '78.8759'},
      '532406': {'lat': '18.2461', 'name': 'Tonangi,Srikakulam,Andhra Pradesh', 'lon': '83.7781'},
      '532407': {'lat': '18.1535', 'name': 'Gollalapalem,Vizianagaram,Andhra Pradesh', 'lon': '83.221'},
      '532408': {'lat': '18.2164', 'name': 'Dharmavaram,Srikakulam,Andhra Pradesh', 'lon': '83.8856'},
      '532409': {'lat': '18.1542', 'name': 'Sancham,Srikakulam,Andhra Pradesh', 'lon': '83.6531'},
      '241124': {'lat': '27.5945', 'name': 'Anjhi Gaon,Hardoi,Uttar Pradesh', 'lon': '79.926'},
      '241125': {'lat': '27.1637', 'name': 'Tolwa Aat Danpur,Hardoi,Uttar Pradesh', 'lon': '79.987'},
      '241126': {'lat': '27.1459', 'name': 'Hathaura Raj,Hardoi,Uttar Pradesh', 'lon': '80.2879'},
      '241127': {'lat': '27.4083', 'name': 'Sandila I. Estate,Hardoi,Uttar Pradesh', 'lon': '79.9617'},
      '241121': {'lat': '27.26', 'name': 'Bhitha Maha Singh,Hardoi,Uttar Pradesh', 'lon': '80.0293'},
      '241122': {'lat': '27.369', 'name': 'Bamhna Khera,Hardoi,Uttar Pradesh', 'lon': '80.1623'},
      '241123': {'lat': '27.5213', 'name': 'Daultpur,Hardoi,Uttar Pradesh', 'lon': '79.826'},
      '613010': {'lat': '10.8553', 'name': 'Tamil University,Thanjavur,Tamil Nadu', 'lon': '79.1064'},
      '508206': {'lat': '17.2419', 'name': 'Ameenabad,Nalgonda,Telangana', 'lon': '79.5747'},
      '276140': {'lat': '26.1539', 'name': 'Chaklal Chand,Azamgarh,Uttar Pradesh', 'lon': '83.3351'},
      '585419': {'lat': '17.6809', 'name': 'Chandakapur,Bidar,Karnataka', 'lon': '76.7725'},
      '276141': {'lat': '26.057', 'name': 'Badasara Khalsa,Azamgarh,Uttar Pradesh', 'lon': '83.0668'},
      '641402': {'lat': '11.0243', 'name': 'Kannampalayam,Coimbatore,Tamil Nadu', 'lon': '77.1257'},
      '641401': {'lat': '12.596', 'name': 'Kangayampalayam,Coimbatore,Tamil Nadu', 'lon': '80.1486'},
      '641407': {'lat': '11.0866', 'name': 'Arasur,Coimbatore,Tamil Nadu', 'lon': '77.1146'},
      '276142': {'lat': '26.0431', 'name': 'Pipari,Mau,Uttar Pradesh', 'lon': '83.5629'},
      '504308': {'lat': '19.2457', 'name': 'Muthnur,Adilabad,Telangana', 'lon': '78.2343'},
      '504309': {'lat': '19.6355', 'name': 'Ghotkuri,Adilabad,Telangana', 'lon': '78.7946'},
      '460220': {'lat': '21.4236', 'name': 'Sakli,Betul,Madhya Pradesh', 'lon': '77.7222'},
      '504307': {'lat': '19.3112', 'name': 'Girnoor,Adilabad,Telangana', 'lon': '78.5934'},
      '504304': {'lat': '19.4128', 'name': 'Marlapally,Adilabad,Telangana', 'lon': '78.3051'},
      '276143': {'lat': '26.2599', 'name': 'Balaipur,Azamgarh,Uttar Pradesh', 'lon': '82.6946'},
      '504302': {'lat': '19.033', 'name': 'Coal Chemical Complex,Adilabad,Telangana', 'lon': '79.3382'},
      '621133': {'lat': '11.2856', 'name': 'Eraiyur (Perambalur),Perambalur,Tamil Nadu', 'lon': '78.8179'},
      '313901': {'lat': '24.201', 'name': 'Zawar Mines,Udaipur,Rajasthan', 'lon': '73.7705'},
      '504301': {'lat': '19.3322', 'name': 'Ramakrishnapur,Adilabad,Telangana', 'lon': '78.524'},
      '523165': {'lat': '15.8113', 'name': 'Gandhinagar,Prakasam,Andhra Pradesh', 'lon': '80.1943'},
      '140109': {'lat': '30.8782', 'name': 'Khizrabad West,Mohali,Punjab', 'lon': '76.6763'},
      '523167': {'lat': '15.6552', 'name': 'Pavulur,Prakasam,Andhra Pradesh', 'lon': '80.0701'},
      '523166': {'lat': '15.8378', 'name': 'Gavinivaripalem,Prakasam,Andhra Pradesh', 'lon': '80.4315'},
      '382260': {'lat': '22.71', 'name': 'Chandisar,Ahmedabad,Gujarat', 'lon': '72.196'},
      '140102': {'lat': '30.8367', 'name': 'Samana Kalan,Rupnagar,Punjab', 'lon': '76.4544'},
      '140103': {'lat': '30.8316', 'name': 'Mundhon Kalan,Mohali,Punjab', 'lon': '76.5726'},
      '382265': {'lat': '22.5684', 'name': 'Simej,Ahmedabad,Gujarat', 'lon': '72.4303'},
      '523168': {'lat': '15.8113', 'name': 'Karamchedu,Prakasam,Andhra Pradesh', 'lon': '80.1943'},
      '628006': {'lat': '8.5782', 'name': 'Thermal Nagagr,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '852116': {'lat': '25.5917', 'name': 'Naya Tola,Saharsa,Bihar', 'lon': '87.0061'},
      '852115': {'lat': '25.5609', 'name': 'Bishanpurarar,Madhepura,Bihar', 'lon': '86.6739'},
      '852114': {'lat': '25.7807', 'name': 'S.Millick,Madhepura,Bihar', 'lon': '86.6387'},
      '852113': {'lat': '25.8964', 'name': 'Jagjiwan Ashram,Madhepura,Bihar', 'lon': '86.8082'},
      '852112': {'lat': '25.9294', 'name': 'L. Bhagwati,Madhepura,Bihar', 'lon': '86.9841'},
      '852111': {'lat': '25.7375', 'name': 'Simrahi Bazar,Supaul,Bihar', 'lon': '86.2606'},
      '852110': {'lat': '25.9777', 'name': 'Kathbanshi,Supaul,Bihar', 'lon': '86.7544'},
      '635130': {'lat': '12.7148', 'name': 'Bukkasagaram,Krishnagiri,Tamil Nadu', 'lon': '77.9285'},
      '341029': {'lat': '27.5407', 'name': 'Sribalaji,Nagaur,Rajasthan', 'lon': '73.6708'},
      '276405': {'lat': '25.3508', 'name': 'Bhadir,Mau,Uttar Pradesh', 'lon': '83.2583'},
      '276404': {'lat': '25.3866', 'name': 'Mubarak Pur,Azamgarh,Uttar Pradesh', 'lon': '82.7589'},
      '276406': {'lat': '26.3717', 'name': 'Raut Mau,Azamgarh,Uttar Pradesh', 'lon': '82.9597'},
      '276403': {'lat': '25.9947', 'name': 'Mohammadabad Gohna,Mau,Uttar Pradesh', 'lon': '83.2398'},
      '276402': {'lat': '26.0088', 'name': 'Sulatanipur,Mau,Uttar Pradesh', 'lon': '82.9628'},
      '203412': {'lat': '28.6487', 'name': 'Barouli Basdev Pur,Bulandshahr,Uttar Pradesh', 'lon': '78.0529'},
      '638108': {'lat': '10.8856', 'name': 'Kuttapalayam,Erode,Tamil Nadu', 'lon': '77.6516'},
      '641658': {'lat': '10.9353', 'name': 'Jalakarichal,Coimbatore,Tamil Nadu', 'lon': '77.1578'},
      '680013': {'lat': '10.5586', 'name': 'Kuttur (Thrissur),Thrissur,Kerala', 'lon': '76.1998'},
      '458667': {'lat': '23.8792', 'name': 'Nandawta,Mandsaur,Madhya Pradesh', 'lon': '76.1979'},
      '680012': {'lat': '10.5094', 'name': 'Chettupuzha,Thrissur,Kerala', 'lon': '76.1676'},
      '444709': {'lat': '20.9009', 'name': 'Kamnapur Ghusal,Amravati,Maharashtra', 'lon': '77.9838'},
      '813209': {'lat': '25.3126', 'name': 'Salempurtola,Bhagalpur,Bihar', 'lon': '87.1684'},
      '246701': {'lat': '29.2954', 'name': 'Vidur Kuti,Bijnor,Uttar Pradesh', 'lon': '78.1006'},
      '757087': {'lat': '20.7926', 'name': 'Dhanghera,Mayurbhanj,Odisha', 'lon': '84.8031'},
      '627009': {'lat': '8.5335', 'name': 'Tirunelveli Collectorate,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '680010': {'lat': '10.553', 'name': 'Kolazhi RM,Thrissur,Kerala', 'lon': '76.2266'},
      '246123': {'lat': '30.0576', 'name': 'Goda,Pauri Garhwal,Uttarakhand', 'lon': '78.8971'},
      '413220': {'lat': '17.3579', 'name': 'Babalad,Solapur,Maharashtra', 'lon': '76.3669'},
      '571117': {'lat': '11.9843', 'name': 'Masanapura,Chamrajnagar,Karnataka', 'lon': '77.0168'},
      '627002': {'lat': '8.5335', 'name': 'Santhinagar,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '627001': {'lat': '8.5335', 'name': 'Minatchipuram,Tirunelveli,Tamil Nadu', 'lon': '77.8517'},
      '413411': {'lat': '17.9901', 'name': 'K.V.Nagar Barshi,Solapur,Maharashtra', 'lon': '75.6514'},
      '413410': {'lat': '17.9901', 'name': 'Khairao,Solapur,Maharashtra', 'lon': '75.6514'},
      '627005': {'lat': '8.7008', 'name': 'Melapalayam (Tirunelveli),Tirunelveli,Tamil Nadu', 'lon': '77.7066'},
      '413412': {'lat': '17.9901', 'name': 'Irle,Solapur,Maharashtra', 'lon': '75.6514'},
      '521301': {'lat': '16.4421', 'name': 'Gudivada Railway Station,Krishna,Andhra Pradesh', 'lon': '80.8967'},
      '686653': {'lat': '9.7408', 'name': 'Kadanad Meenachil,Kottayam,Kerala', 'lon': '76.7725'},
      '686652': {'lat': '9.7408', 'name': 'Melukavu,Kottayam,Kerala', 'lon': '76.7725'},
      '389330': {'lat': '22.6078', 'name': 'Maghasar,Panch Mahals,Gujarat', 'lon': '73.4627'},
      '680542': {'lat': '10.6882', 'name': 'Pazhanji,Thrissur,Kerala', 'lon': '76.0531'},
      '494777': {'lat': '20.0639', 'name': 'Erpanar,Kanker,Chattisgarh', 'lon': '80.7545'},
      '396436': {'lat': '20.9299', 'name': 'Parujan,Navsari,Gujarat', 'lon': '73.0988'},
      '396430': {'lat': '20.8638', 'name': 'Minkachha,Navsari,Gujarat', 'lon': '73.0783'},
      '396433': {'lat': '20.9953', 'name': 'Sarpore Pardi,Navsari,Gujarat', 'lon': '72.9463'},
      '396439': {'lat': '21.1264', 'name': 'Kothamadi,Navsari,Gujarat', 'lon': '72.7278'},
      '144806': {'lat': '31.2126', 'name': 'Khusropur,Jalandhar,Punjab', 'lon': '75.5666'},
      '515621': {'lat': '14.4443', 'name': 'Pitchireddikottala,Ananthapur,Andhra Pradesh', 'lon': '77.5902'},
      '176110': {'lat': '31.83651', 'name': 'Tira,Hamirpur(HP),Himachal Pradesh', 'lon': '76.51971'},
      '176111': {'lat': '31.7594608', 'name': 'Chamiana,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5490261'},
      '743221': {'lat': '22.8464', 'name': 'Adhata,North 24 Parganas,West Bengal', 'lon': '88.5736'},
      '176115': {'lat': '32.0697', 'name': 'Uttrala,Kangra,Himachal Pradesh', 'lon': '76.6393'},
      '401504': {'lat': '19.8354', 'name': 'Salwad,Thane,Maharashtra', 'lon': '72.8048'},
      '401506': {'lat': '19.8354', 'name': 'Tarapur Ti,Thane,Maharashtra', 'lon': '72.8048'},
      '573137': {'lat': '12.6529', 'name': 'Doddakundur,Hassan,Karnataka', 'lon': '76.0758'},
      '453441': {'lat': '22.6438', 'name': 'Badgonda,Indore,Madhya Pradesh', 'lon': '75.8177'},
      '401501': {'lat': '19.8036', 'name': 'Murbe,Thane,Maharashtra', 'lon': '72.756'},
      '401502': {'lat': '19.8318', 'name': 'Ghiwali,Thane,Maharashtra', 'lon': '72.8891'},
      '401503': {'lat': '19.8746', 'name': 'Vadhavan,Thane,Maharashtra', 'lon': '72.6851'},
      '754114': {'lat': '20.273', 'name': 'Madhusudanpursasan,Jagatsinghapur,Odisha', 'lon': '86.1116'},
      '261141': {'lat': '27.1748', 'name': 'Pailakisha,Sitapur,Uttar Pradesh', 'lon': '80.851'},
      '671326': {'lat': '12.606', 'name': 'Chittarikkal,Kasargod,Kerala', 'lon': '74.9866'},
      '754110': {'lat': '20.7516', 'name': 'Janakadeipur,Jagatsinghapur,Odisha', 'lon': '85.9054'},
      '754111': {'lat': '20.4103', 'name': 'S.Basandhara,Jagatsinghapur,Odisha', 'lon': '85.5578'},
      '754112': {'lat': '20.5703', 'name': 'Bentakar,Cuttack,Odisha', 'lon': '84.7399'},
      '586130': {'lat': '16.5042', 'name': 'Tajpur,Vijayapura,Karnataka', 'lon': '75.8926'},
      '231220': {'lat': '24.3821', 'name': 'Beena Project,Sonbhadra,Uttar Pradesh', 'lon': '83.1862'},
      '641050': {'lat': '10.9996', 'name': 'Malumichampatti,Coimbatore,Tamil Nadu', 'lon': '77.1255'},
      '231222': {'lat': '24.3821', 'name': 'Khadia Project,Sonbhadra,Uttar Pradesh', 'lon': '83.1862'},
      '231223': {'lat': '24.2586', 'name': 'Nadhira,Sonbhadra,Uttar Pradesh', 'lon': '83.151'},
      '609604': {'lat': '10.9434', 'name': 'Alathur,Nagapattinam,Tamil Nadu', 'lon': '79.7558'},
      '754119': {'lat': '21.2328', 'name': 'Tentulibelari,Jagatsinghapur,Odisha', 'lon': '86.7643'},
      '231226': {'lat': '24.5068', 'name': 'Dhuma,Sonbhadra,Uttar Pradesh', 'lon': '83.336'},
      '535183': {'lat': '17.8909', 'name': 'Tangudubilli,Visakhapatnam,Andhra Pradesh', 'lon': '82.6924'},
      '824101': {'lat': '24.7656', 'name': 'Aurangabad Kutchehry,Aurangabad(BH),Bihar', 'lon': '84.3868'},
      '518395': {'lat': '15.4063', 'name': 'Chinnahothur,Kurnool,Andhra Pradesh', 'lon': '77.2283'},
      '518396': {'lat': '15.1438', 'name': 'Gummanur,Kurnool,Andhra Pradesh', 'lon': '77.3429'},
      '824102': {'lat': '24.6987', 'name': 'Bajauli,Aurangabad(BH),Bihar', 'lon': '84.2681'},
      '518390': {'lat': '15.2767', 'name': 'Madananthapuram,Kurnool,Andhra Pradesh', 'lon': '77.5387'},
      '625707': {'lat': '9.7561', 'name': 'Chitoor,Madurai,Tamil Nadu', 'lon': '77.9748'},
      '481222': {'lat': '21.5172', 'name': 'Sawari,Balaghat,Madhya Pradesh', 'lon': '80.5446'},
      '281401': {'lat': '27.7289', 'name': 'Dhamsinga,Azamgarh,Uttar Pradesh', 'lon': '77.5603'},
      '232105': {'lat': '25.3881', 'name': 'Sakrari,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '493890': {'lat': '20.2557', 'name': 'Nistiguda,Raipur,Chattisgarh', 'lon': '82.3879'},
      '781150': {'lat': '26.118', 'name': 'Chandrapur (Kamrup),Kamrup,Assam', 'lon': '91.4061'},
      '733132': {'lat': '25.4724186', 'name': 'Berail,South Dinajpur,West Bengal', 'lon': '	88.2971925'},
      '506223': {'lat': '17.3908', 'name': 'Chiryal,Warangal,Telangana', 'lon': '78.9107'},
      '271319': {'lat': '26.9445', 'name': 'Haribashpur,Gonda,Uttar Pradesh', 'lon': '82.1007'},
      '506221': {'lat': '17.7718', 'name': 'Tammadapalli,Warangal,Telangana', 'lon': '79.0734'},
      '641654': {'lat': '11.1324', 'name': 'Periyayipalayam,Coimbatore,Tamil Nadu', 'lon': '77.4282'},
      '232107': {'lat': '25.3881', 'name': 'Ballipur,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '271310': {'lat': '27.076', 'name': 'Kasipur,Gonda,Uttar Pradesh', 'lon': '82.1089'},
      '271311': {'lat': '26.4146', 'name': 'Bhabhuwa Kot,Gonda,Uttar Pradesh', 'lon': '80.9426'},
      '271312': {'lat': '27.0592', 'name': 'Dhari Ghat,Gonda,Uttar Pradesh', 'lon': '82.4898'},
      '733134': {'lat': '22.9597', 'name': 'Bangalbari,North Dinajpur,West Bengal', 'lon': '88.2859'},
      '281405': {'lat': '27.5968', 'name': 'Hathia,Mathura,Uttar Pradesh', 'lon': '77.6276'},
      '632301': {'lat': '12.6349', 'name': 'Saidapettai Arni,Tiruvannamalai,Tamil Nadu', 'lon': '79.3112'},
      '281404': {'lat': '27.5968', 'name': 'Naugaon,Mathura,Uttar Pradesh', 'lon': '77.6276'},
      '502345': {'lat': '18.1408', 'name': 'Mallikarjunpally,Medak,Telangana', 'lon': '78.1386'},
      '815317': {'lat': '24.6634', 'name': 'Kishutand,Giridh,Jharkhand', 'lon': '86.2512'},
      '562122': {'lat': '13.1659', 'name': 'Bylanarasapura,Bangalore Rural,Karnataka', 'lon': '77.9074'},
      '670582': {'lat': '12.1407', 'name': 'Areekamala,Kannur,Kerala', 'lon': '75.5505'},
      '466118': {'lat': '22.9651', 'name': 'Metwada,Sehore,Madhya Pradesh', 'lon': '76.5437'},
      '400710': {'lat': '18.9894', 'name': 'Millenium Business Park,Thane,Maharashtra', 'lon': '72.961'},
      '670581': {'lat': '12.1407', 'name': 'Karippal,Kannur,Kerala', 'lon': '75.5505'},
      '683556': {'lat': '10.151', 'name': 'Vengola,Ernakulam,Kerala', 'lon': '76.4577'},
      '841446': {'lat': '25.838', 'name': 'Bharuli,Siwan,Bihar', 'lon': '84.3578'},
      '244717': {'lat': '29.3334', 'name': 'S.M. Nadehi,Udham Singh Nagar,Uttarakhand', 'lon': '79.0137'},
      '628105': {'lat': '9.2112', 'name': 'Taruvaikulam,Tuticorin,Tamil Nadu', 'lon': '78.0998'},
      '824237': {'lat': '24.7895', 'name': 'Punoul,Gaya,Bihar', 'lon': '84.8481'},
      '683550': {'lat': '10.151', 'name': 'Okkal,Ernakulam,Kerala', 'lon': '76.4577'},
      '628104': {'lat': '8.3031', 'name': 'Kollampirambu-dalavaipuram,Tuticorin,Tamil Nadu', 'lon': '77.4753'},
      '518176': {'lat': '15.5473', 'name': 'S.Kothur,Kurnool,Andhra Pradesh', 'lon': '78.5863'},
      '614704': {'lat': '10.7967', 'name': 'Thambikottai,Thanjavur,Tamil Nadu', 'lon': '79.1979'},
      '628103': {'lat': '10.3813', 'name': 'Melakootudankadu,Tuticorin,Tamil Nadu', 'lon': '78.8214'},
      '796701': {'lat': '23.2201', 'name': 'Vaisam,Lunglei,Mizoram', 'lon': '92.6304'},
      '628102': {'lat': '8.7211', 'name': 'Varthagareddipatti,Tuticorin,Tamil Nadu', 'lon': '77.9935'},
      '756040': {'lat': '20.5393', 'name': 'Kahalia,Baleswar,Odisha', 'lon': '84.7697'},
      '628101': {'lat': '9.4394', 'name': 'Maravanmadam,Tuticorin,Tamil Nadu', 'lon': '78.1091'},
      '562125': {'lat': '13.2257', 'name': 'Handenahalli,Bengaluru,Karnataka', 'lon': '77.575'},
      '173229': {'lat': '30.9209', 'name': 'Naina Tikkar,Sirmaur,Himachal Pradesh', 'lon': '77.0694'},
      '441215': {'lat': '20.2842', 'name': 'Bormala,Chandrapur,Maharashtra', 'lon': '79.7237'},
      '577168': {'lat': '13.4565', 'name': 'Lakkammanahalli,Chikkamagaluru,Karnataka', 'lon': '75.677'},
      '756043': {'lat': '20.993', 'name': 'Khantapada,Baleswar,Odisha', 'lon': '85.896'},
      '614705': {'lat': '10.5133', 'name': 'Karunavur,Tiruvarur,Tamil Nadu', 'lon': '79.5091'},
      '721458': {'lat': '22.3836', 'name': 'Noonhanda,East Midnapore,West Bengal', 'lon': '87.8747'},
      '176403': {'lat': '32.1027', 'name': 'Mand Manjwan,Kangra,Himachal Pradesh', 'lon': '75.8754'},
      '562128': {'lat': '12.8014', 'name': 'Manchanabele,Ramanagar,Karnataka', 'lon': '77.1486'},
      '742201': {'lat': '24.0811', 'name': 'Chabghati,Murshidabad,West Bengal', 'lon': '87.9102'},
      '175131': {'lat': '32.2574', 'name': 'Chhiyal,Kullu,Himachal Pradesh', 'lon': '77.1748'},
      '175130': {'lat': '32.1381', 'name': 'Raman,Kullu,Himachal Pradesh', 'lon': '77.1739'},
      '175133': {'lat': '32.6141', 'name': 'Jobrang,Lahul & Spiti,Himachal Pradesh', 'lon': '76.9047'},
      '175132': {'lat': '32.3655', 'name': 'Gemour,Lahul & Spiti,Himachal Pradesh', 'lon': '76.9698'},
      '175134': {'lat': '31.8127', 'name': 'Salwar,Mandi,Himachal Pradesh', 'lon': '77.07'},
      '470021': {'lat': '23.8816', 'name': 'Bamora,Sagar,Madhya Pradesh', 'lon': '78.8021'},
      '175136': {'lat': '32.2656', 'name': 'Haripur (Kullu),Kullu,Himachal Pradesh', 'lon': '77.0096'},
      '175139': {'lat': '32.6588', 'name': 'Mooring,Lahul & Spiti,Himachal Pradesh', 'lon': '76.7833'},
      '175138': {'lat': '32.2656', 'name': 'Neoli,Kullu,Himachal Pradesh', 'lon': '77.0096'},
      '751019': {'lat': '20.7521', 'name': 'Kalinga Vihar,Khorda,Odisha', 'lon': '84.5579'},
      '445401': {'lat': '20.4518', 'name': 'Katri,Yavatmal,Maharashtra', 'lon': '78.384'},
      '464886': {'lat': '23.3972', 'name': 'Sodarpur,Raisen,Madhya Pradesh', 'lon': '78.4181'},
      '445402': {'lat': '20.4239', 'name': 'Ralegaon,Yavatmal,Maharashtra', 'lon': '78.5211'},
      '173223': {'lat': '31.7362', 'name': 'Jadol Taproli,Sirmaur,Himachal Pradesh', 'lon': '76.7361'},
      '756049': {'lat': '21.2567', 'name': 'Sarugaon,Baleswar,Odisha', 'lon': '86.0396'},
      '751014': {'lat': '20.7521', 'name': 'Bhubaneswar Court,Khorda,Odisha', 'lon': '84.5579'},
      '173220': {'lat': '30.8372', 'name': 'Sector-2 Parwanoo,Solan,Himachal Pradesh', 'lon': '76.9759'},
      '364330': {'lat': '21.8477', 'name': 'Loliyana,Bhavnagar,Gujarat', 'lon': '72.6803'},
      '751012': {'lat': '20.7521', 'name': 'Nayapalli,Khorda,Odisha', 'lon': '84.5579'},
      '522020': {'lat': '16.2176', 'name': 'Harischandrapuram,Guntur,Andhra Pradesh', 'lon': '80.345'},
      '227409': {'lat': '26.2246', 'name': 'Bisundaspur,Sultanpur,Uttar Pradesh', 'lon': '81.7444'},
      '394515': {'lat': '21.1842', 'name': 'Kribhconagar,Surat,Gujarat', 'lon': '72.6893'},
      '122502': {'lat': '28.2486', 'name': 'Kakoria,Gurgaon,Haryana', 'lon': '76.696'},
      '122503': {'lat': '28.3413', 'name': 'Baslambi,Gurgaon,Haryana', 'lon': '76.8058'},
      '122504': {'lat': '28.3598', 'name': 'Noorgarh,Gurgaon,Haryana', 'lon': '76.7487'},
      '173225': {'lat': '31.0429', 'name': 'Jubbar,Solan,Himachal Pradesh', 'lon': '76.9095'},
      '122506': {'lat': '28.4616', 'name': 'Patil R.S.,Gurgaon,Haryana', 'lon': '76.8708'},
      '122508': {'lat': '27.9627', 'name': 'Naheda,Gurgaon,Haryana', 'lon': '77.1646'},
      '751011': {'lat': '20.7521', 'name': 'C R P Lines,Khorda,Odisha', 'lon': '84.5579'},
      '756168': {'lat': '20.6001', 'name': 'Kanheibindha,Bhadrak,Odisha', 'lon': '85.588'},
      '332718': {'lat': '27.4728', 'name': 'Chhajakinangal,Sikar,Rajasthan', 'lon': '75.8679'},
      '332719': {'lat': '27.7039', 'name': 'Garh Bhopji,Sikar,Rajasthan', 'lon': '75.4446'},
      '756163': {'lat': '20.9843', 'name': 'Bilana,Bhadrak,Odisha', 'lon': '84.9738'},
      '756162': {'lat': '20.4906', 'name': 'Suan,Bhadrak,Odisha', 'lon': '85.2'},
      '332714': {'lat': '27.6366', 'name': 'Govindpura,Sikar,Rajasthan', 'lon': '75.2875'},
      '332715': {'lat': '26.8856', 'name': 'Holikabas,Sikar,Rajasthan', 'lon': '75.9051'},
      '756167': {'lat': '20.6241', 'name': 'Paliabindha,Bhadrak,Odisha', 'lon': '85.2842'},
      '756166': {'lat': '19.979', 'name': 'Kalaspur,Baleswar,Odisha', 'lon': '84.8486'},
      '756165': {'lat': '20.6241', 'name': 'Bachhipur,Bhadrak,Odisha', 'lon': '85.2842'},
      '756164': {'lat': '20.9947', 'name': 'Bansada Kuamara,Bhadrak,Odisha', 'lon': '85.5035'},
      '625011': {'lat': '9.9485', 'name': 'Jaihindpuram,Madurai,Tamil Nadu', 'lon': '78.1616'},
      '828309': {'lat': '23.7333', 'name': 'Jitpur,Dhanbad,Jharkhand', 'lon': '86.4'},
      '825330': {'lat': '23.7328', 'name': 'Topa Coliery,Ramgarh,Jharkhand', 'lon': '85.523'},
      '243126': {'lat': '26.8952', 'name': 'DOHRIYA,Bareilly,Uttar Pradesh', 'lon': '79.2309'},
      '828306': {'lat': '24.3134', 'name': 'Nawagarh (Dhanbad),Dhanbad,Jharkhand', 'lon': '86.3535'},
      '828305': {'lat': '23.9144', 'name': 'Ramnagar Garh,Dhanbad,Jharkhand', 'lon': '86.4403'},
      '828304': {'lat': '23.7821', 'name': 'Malkera,Dhanbad,Jharkhand', 'lon': '86.2877'},
      '243122': {'lat': '28.0741', 'name': 'Ner Workshop,Bareilly,Uttar Pradesh', 'lon': '79.4607'},
      '243123': {'lat': '28.3099', 'name': 'Kesarpur Saidpur,Pilibhit,Uttar Pradesh', 'lon': '79.5067'},
      '828301': {'lat': '23.9144', 'name': 'Bhutgoria,Dhanbad,Jharkhand', 'lon': '86.4403'},
      '625017': {'lat': '9.9302', 'name': 'Anaiyur H.B. Colony,Madurai,Tamil Nadu', 'lon': '78.2959'},
      '600075': {'lat': '12.975', 'name': 'Pammal East,Kanchipuram,Tamil Nadu', 'lon': '80.1347'},
      '600074': {'lat': '12.9591', 'name': 'Polichalur,Kanchipuram,Tamil Nadu', 'lon': '80.1436'},
      '600077': {'lat': '12.9591', 'name': 'Ayapakkam,Tiruvallur,Tamil Nadu', 'lon': '80.1436'},
      '600076': {'lat': '12.9591', 'name': 'Korattur RS,Tiruvallur,Tamil Nadu', 'lon': '80.1436'},
      '600071': {'lat': '12.9591', 'name': 'Kamarajnagar,Tiruvallur,Tamil Nadu', 'lon': '80.1436'},
      '600070': {'lat': '12.9828', 'name': 'Anakaputhur,Kanchipuram,Tamil Nadu', 'lon': '80.1264'},
      '600073': {'lat': '12.6952', 'name': 'Selaiyur,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '600072': {'lat': '12.9591', 'name': 'Pattabiram,Tiruvallur,Tamil Nadu', 'lon': '80.1436'},
      '201003': {'lat': '28.7416', 'name': 'Ator Nagla,Ghaziabad,Uttar Pradesh', 'lon': '77.4102'},
      '847227': {'lat': '26.5237', 'name': 'Gajhara,Madhubani,Bihar', 'lon': '86.3715'},
      '600078': {'lat': '12.9591', 'name': 'Kalaignar Karunanidhi Nagar,Chennai,Tamil Nadu', 'lon': '80.1436'},
      '614302': {'lat': '10.8609', 'name': 'Mattayanthidal,Thanjavur,Tamil Nadu', 'lon': '79.273'},
      '360001': {'lat': '22.5964', 'name': 'Rajkot New Jagnath Plot,Rajkot,Gujarat', 'lon': '70.5192'},
      '614301': {'lat': '10.8489', 'name': 'Kovathagudi,Thanjavur,Tamil Nadu', 'lon': '79.2181'},
      '206252': {'lat': '26.8788', 'name': 'Badhin,Auraiya,Uttar Pradesh', 'lon': '79.9554'},
      '825336': {'lat': '23.8242', 'name': 'Charhi,Hazaribag,Jharkhand', 'lon': '85.4327'},
      '689126': {'lat': '9.3205', 'name': 'Perisserry,Alappuzha,Kerala', 'lon': '76.6332'},
      '390020': {'lat': '22.4024', 'name': 'Sayajiganj,Vadodara,Gujarat', 'lon': '73.0806'},
      '390021': {'lat': '22.4024', 'name': 'T B Sanatorium,Vadodara,Gujarat', 'lon': '73.0806'},
      '390022': {'lat': '22.4024', 'name': 'Harni,Vadodara,Gujarat', 'lon': '73.0806'},
      '390023': {'lat': '22.4024', 'name': 'Subhanpura,Vadodara,Gujarat', 'lon': '73.0806'},
      '390024': {'lat': '22.4024', 'name': 'Chhani Rd,Vadodara,Gujarat', 'lon': '73.0806'},
      '390025': {'lat': '22.4024', 'name': 'Waghodia Road,Vadodara,Gujarat', 'lon': '73.0806'},
      '691319': {'lat': '8.8892', 'name': 'Thekkumbhagom,Kollam,Kerala', 'lon': '77.0236'},
      '334022': {'lat': '28.0997', 'name': 'Bambloo,Bikaner,Rajasthan', 'lon': '73.419'},
      '691312': {'lat': '8.8892', 'name': 'Bharatheepuram,Kollam,Kerala', 'lon': '77.0236'},
      '691310': {'lat': '8.909', 'name': 'Villumala,Kollam,Kerala', 'lon': '77.0593'},
      '691311': {'lat': '8.8694', 'name': 'Mannur-anchal,Kollam,Kerala', 'lon': '76.9878'},
      '516390': {'lat': '14.4632', 'name': 'Ankalammapeta,Cuddapah,Andhra Pradesh', 'lon': '78.1499'},
      '516391': {'lat': '14.428', 'name': 'Erripalle,Cuddapah,Andhra Pradesh', 'lon': '78.1944'},
      '516396': {'lat': '14.516', 'name': 'Lopatnuthala,Cuddapah,Andhra Pradesh', 'lon': '78.0832'},
      '689122': {'lat': '9.3205', 'name': 'Neervilakom,Pathanamthitta,Kerala', 'lon': '76.6332'},
      '502300': {'lat': '18.1887', 'name': 'Muthangi,Medak,Telangana', 'lon': '79.0729'},
      '502301': {'lat': '17.713', 'name': 'Yerraballi BO,Siddipet,Telangana', 'lon': '78.9669'},
      '502302': {'lat': '17.683', 'name': 'Elakurthy,Medak,Telangana', 'lon': '78.3631'},
      '689123': {'lat': '9.3205', 'name': 'Arattupuzha,Pathanamthitta,Kerala', 'lon': '76.6332'},
      '457990': {'lat': '22.4368', 'name': 'Dabdi,Alirajpur,Madhya Pradesh', 'lon': '74.5777'},
      '502305': {'lat': '17.4899', 'name': 'Bdl Bhanur,Medak,Telangana', 'lon': '78.1774'},
      '388210': {'lat': '21.8754', 'name': 'Sundalpura,Anand,Gujarat', 'lon': '73.2227'},
      '457993': {'lat': '22.2653', 'name': 'Mandlinathu,Jhabua,Madhya Pradesh', 'lon': '74.5255'},
      '639101': {'lat': '10.8743', 'name': 'Andanallur,Tiruchirappalli,Tamil Nadu', 'lon': '78.5973'},
      '639103': {'lat': '10.9442', 'name': 'Inampuliyur,Tiruchirappalli,Tamil Nadu', 'lon': '78.4839'},
      '639102': {'lat': '10.9297', 'name': 'Munayanur,Karur,Tamil Nadu', 'lon': '78.1973'},
      '639105': {'lat': '11.2308', 'name': 'Mettumahadhanapuram,Karur,Tamil Nadu', 'lon': '78.6419'},
      '639104': {'lat': '10.9482', 'name': 'Manathattai,Karur,Tamil Nadu', 'lon': '78.3769'},
      '639107': {'lat': '10.8474', 'name': 'Thannirpalli,Karur,Tamil Nadu', 'lon': '78.4198'},
      '764044': {'lat': '18.4475', 'name': 'Kartanpalli,Malkangiri,Odisha', 'lon': '82.0722'},
      '639109': {'lat': '10.824', 'name': 'Moolapatti,Karur,Tamil Nadu', 'lon': '77.964'},
      '639108': {'lat': '10.9296', 'name': 'Valayalkaranpudur,Karur,Tamil Nadu', 'lon': '78.2246'},
      '403731': {'lat': '15.1725', 'name': 'Cavelossim,South Goa,Goa', 'lon': '73.9419'},
      '327604': {'lat': '23.4401', 'name': 'Pali Kalan,Banswara,Rajasthan', 'lon': '74.3784'},
      '360055': {'lat': '24.212', 'name': 'Ajmer,Rajkot,Gujarat', 'lon': '72.9571'},
      '360050': {'lat': '22.1603', 'name': 'Ambardi,Bhavnagar,Gujarat', 'lon': '72.0849'},
      '523370': {'lat': '15.5798', 'name': 'Ravipadu (Prakasam),Prakasam,Andhra Pradesh', 'lon': '79.1529'},
      '523371': {'lat': '15.7169', 'name': 'Thummalacheruvu,Prakasam,Andhra Pradesh', 'lon': '79.3124'},
      '523372': {'lat': '15.5148', 'name': 'Thurimella,Prakasam,Andhra Pradesh', 'lon': '78.9617'},
      '523373': {'lat': '15.2231', 'name': 'Komarole,Prakasam,Andhra Pradesh', 'lon': '79.0655'},
      '441208': {'lat': '20.3552', 'name': 'Churmura,Gadchiroli,Maharashtra', 'lon': '79.9842'},
      '814145': {'lat': '24.7128', 'name': 'B. Mahadeogarh,Dumka,Jharkhand', 'lon': '87.3199'},
      '764040': {'lat': '18.7065', 'name': 'Godihanjar,Koraput,Odisha', 'lon': '82.3284'},
      '610104': {'lat': '10.7907', 'name': 'Manakkal Ayyampet,Tiruvarur,Tamil Nadu', 'lon': '79.6012'},
      '764043': {'lat': '18.9355', 'name': 'Bodaput,Koraput,Odisha', 'lon': '82.6373'},
      '123106': {'lat': '28.1881', 'name': 'Dungerwas,Gurgaon,Haryana', 'lon': '76.754'},
      '695588': {'lat': '8.5842', 'name': 'Powdikonam,Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '123103': {'lat': '28.2156', 'name': 'Mayan,Rewari,Haryana', 'lon': '76.4183'},
      '123102': {'lat': '28.1673', 'name': 'Gothra,Rewari,Haryana', 'lon': '76.4131'},
      '123101': {'lat': '28.1769', 'name': 'Tint,Rewari,Haryana', 'lon': '76.5079'},
      '221112': {'lat': '25.3045', 'name': 'Saraimohana,Varanasi,Uttar Pradesh', 'lon': '82.5553'},
      '834001': {'lat': '23.3582', 'name': 'Church Road,Ranchi,Jharkhand', 'lon': '85.3299'},
      '570015': {'lat': '12.3134', 'name': 'Shivaraathreeswar Nagar,Mysuru,Karnataka', 'lon': '76.7455'},
      '834003': {'lat': '23.3097', 'name': 'Railway Colony Hatia,Ranchi,Jharkhand', 'lon': '85.3147'},
      '614020': {'lat': '10.5562', 'name': 'Tenparai,Tiruvarur,Tamil Nadu', 'lon': '79.4884'},
      '834005': {'lat': '23.3695', 'name': 'Hehal,Ranchi,Jharkhand', 'lon': '85.2857'},
      '834004': {'lat': '23.306', 'name': 'HEC Colony,Ranchi,Jharkhand', 'lon': '85.2772'},
      '834007': {'lat': '23.3853', 'name': 'Sail name,Ranchi,Jharkhand', 'lon': '85.2923'},
      '570014': {'lat': '12.3134', 'name': 'Jayanagar (Mysuru),Mysuru,Karnataka', 'lon': '76.7455'},
      '834009': {'lat': '23.3751', 'name': 'Ranchi Medical College,Ranchi,Jharkhand', 'lon': '85.2762'},
      '281302': {'lat': '27.5596', 'name': 'Garhumrao,Mathura,Uttar Pradesh', 'lon': '77.9221'},
      '577230': {'lat': '14.153', 'name': 'Chilurkadadakatte,Davangere,Karnataka', 'lon': '75.6728'},
      '577231': {'lat': '14.2489', 'name': 'Belalagere,Davangere,Karnataka', 'lon': '75.842'},
      '577232': {'lat': '13.7115', 'name': 'Kudumallige,Shivamogga,Karnataka', 'lon': '75.3359'},
      '174405': {'lat': '31.31075', 'name': 'Gahli Kohlwin,Hamirpur(HP),Himachal Pradesh', 'lon': '76.88536'},
      '225305': {'lat': '27.2563', 'name': 'Mithwara,Barabanki,Uttar Pradesh', 'lon': '81.2525'},
      '570016': {'lat': '12.3465', 'name': 'Hebbal Layout,Mysuru,Karnataka', 'lon': '76.6335'},
      '700049': {'lat': '22.5333', 'name': 'Nimta,North 24 Parganas,West Bengal', 'lon': '88.3917'},
      '305023': {'lat': '26.5368', 'name': 'Mohami,Ajmer,Rajasthan', 'lon': '74.7501'},
      '695583': {'lat': '8.5842', 'name': 'Karimanal,Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '570010': {'lat': '12.2503', 'name': 'Duddagere,Mysuru,Karnataka', 'lon': '76.7826'},
      '403402': {'lat': '15.4438', 'name': 'Velha-goa,North Goa,Goa', 'lon': '73.8857'},
      '273207': {'lat': '26.9635', 'name': 'Jagdaur,Maharajganj,Uttar Pradesh', 'lon': '83.6202'},
      '273201': {'lat': '26.7773', 'name': 'Bishwnathpur,Gorakhpur,Uttar Pradesh', 'lon': '83.37'},
      '273202': {'lat': '26.7066', 'name': 'Amhia,Gorakhpur,Uttar Pradesh', 'lon': '83.4754'},
      '273203': {'lat': '26.8518', 'name': 'Mithbel,Gorakhpur,Uttar Pradesh', 'lon': '82.8015'},
      '501143': {'lat': '17.1893', 'name': 'Damarched,K.V.Rangareddy,Telangana', 'lon': '77.4523'},
      '570012': {'lat': '12.3134', 'name': 'Jayalakshmipuram (Mysuru),Mysuru,Karnataka', 'lon': '76.7455'},
      '273209': {'lat': '26.6051', 'name': 'Dumarinivas,Gorakhpur,Uttar Pradesh', 'lon': '82.8132'},
      '493663': {'lat': '20.9237', 'name': 'Parkhanda,Dhamtari,Chattisgarh', 'lon': '81.6835'},
      '700158': {'lat': '22.7807', 'name': 'Bisharpara,North 24 Parganas,West Bengal', 'lon': '88.3001'},
      '713371': {'lat': '24.2636', 'name': 'Mithani,Paschim Bardhaman,West Bengal', 'lon': '87.867'},
      '756001': {'lat': '21.1341', 'name': 'Issannagar,Baleswar,Odisha', 'lon': '85.6365'},
      '493661': {'lat': '21.0591', 'name': 'Khorpa,Raipur,Chattisgarh', 'lon': '81.6476'},
      '577007': {'lat': '14.408', 'name': 'Kandagal,Davangere,Karnataka', 'lon': '75.9548'},
      '503307': {'lat': '18.6891', 'name': 'Bhimgal,Nizamabad,Telangana', 'lon': '78.5006'},
      '503306': {'lat': '18.4011', 'name': 'Dhadgi,Nizamabad,Telangana', 'lon': '77.7388'},
      '503305': {'lat': '18.4176', 'name': 'Kolas (I),Nizamabad,Telangana', 'lon': '77.7759'},
      '600007': {'lat': '13.0847', 'name': 'Vepery,Chennai,Tamil Nadu', 'lon': '80.2675'},
      '503302': {'lat': '18.2232', 'name': 'Mallur,Nizamabad,Telangana', 'lon': '77.9535'},
      '151108': {'lat': '30.4822', 'name': 'Kaur Singh Wala,Sangrur,Punjab', 'lon': '75.2043'},
      '151106': {'lat': '30.4324', 'name': 'Bhai Rupa,Bathinda,Punjab', 'lon': '75.2186'},
      '151104': {'lat': '30.3434', 'name': 'Alike,Bathinda,Punjab', 'lon': '75.3316'},
      '151105': {'lat': '30.2974', 'name': 'Mehraj,Bathinda,Punjab', 'lon': '75.176'},
      '151102': {'lat': '30.3319', 'name': 'Govind Pura,Mansa,Punjab', 'lon': '75.093'},
      '151103': {'lat': '30.2739', 'name': 'Mandi Kalan,Bathinda,Punjab', 'lon': '75.2399'},
      '503309': {'lat': '18.5386', 'name': 'Shakarga,Nizamabad,Telangana', 'lon': '77.605'},
      '151101': {'lat': '29.9084', 'name': 'Jhanduke,Mansa,Punjab', 'lon': '75.4389'},
      '813107': {'lat': '25.0269', 'name': 'Patsorikhaira,Banka,Bihar', 'lon': '87.0588'},
      '813106': {'lat': '24.766', 'name': 'Kandhar,Banka,Bihar', 'lon': '86.5038'},
      '813105': {'lat': '25.0345', 'name': 'Rauka,Banka,Bihar', 'lon': '86.936'},
      '813104': {'lat': '24.8724', 'name': 'Bhurna,Banka,Bihar', 'lon': '86.799'},
      '813103': {'lat': '24.9638', 'name': 'Barhouuia,Munger,Bihar', 'lon': '86.7576'},
      '756002': {'lat': '21.1341', 'name': 'Puruna Balasore,Baleswar,Odisha', 'lon': '85.6365'},
      '143114': {'lat': '31.7241', 'name': 'Jalalusman,Tarn Taran,Punjab', 'lon': '75.1512'},
      '713215': {'lat': '23.8798', 'name': 'Durgapur Brick Field,Paschim Bardhaman,West Bengal', 'lon': '87.5259'},
      '466114': {'lat': '22.3892', 'name': 'Chupadiya,Sehore,Madhya Pradesh', 'lon': '76.3997'},
      '766018': {'lat': '19.9951', 'name': 'Karmeli,Kalahandi,Odisha', 'lon': '83.1426'},
      '813108': {'lat': '25.2982', 'name': 'Laxmipur Chiraya,Banka,Bihar', 'lon': '86.7475'},
      '143501': {'lat': '31.7107', 'name': 'Mudhal,Amritsar,Punjab', 'lon': '74.9583'},
      '782401': {'lat': '26.1177', 'name': 'Panbari,Kamrup,Assam', 'lon': '91.9722'},
      '782403': {'lat': '26.1177', 'name': 'Topatali,Kamrup,Assam', 'lon': '91.9722'},
      '782402': {'lat': '26.1177', 'name': 'Nartap,Kamrup,Assam', 'lon': '91.9722'},
      '383305': {'lat': '23.4195', 'name': 'Dadarda,Sabarkantha,Gujarat', 'lon': '73.0729'},
      '383307': {'lat': '23.2466', 'name': 'Khilodia,Sabarkantha,Gujarat', 'lon': '73.1062'},
      '334401': {'lat': '27.8622', 'name': 'Gangashahar,Bikaner,Rajasthan', 'lon': '73.3056'},
      '334402': {'lat': '27.8622', 'name': 'Lalmdesar,Bikaner,Rajasthan', 'lon': '73.3056'},
      '334403': {'lat': '27.8622', 'name': 'Bhinasar,Bikaner,Rajasthan', 'lon': '73.3056'},
      '442505': {'lat': '19.9436', 'name': 'Dhanora Pipri,Chandrapur,Maharashtra', 'lon': '79.1327'},
      '442504': {'lat': '20.1179', 'name': 'Janampalli,Chandrapur,Maharashtra', 'lon': '79.4438'},
      '442507': {'lat': '19.5351', 'name': 'H.L.C.,Chandrapur,Maharashtra', 'lon': '79.6099'},
      '756003': {'lat': '21.1341', 'name': 'Fulwar-kaswa,Baleswar,Odisha', 'lon': '85.6365'},
      '442501': {'lat': '19.5351', 'name': 'Chandrapur Ord Fact,Chandrapur,Maharashtra', 'lon': '79.6099'},
      '201002': {'lat': '28.6667', 'name': 'Kavi Nagar,Ghaziabad,Uttar Pradesh', 'lon': '77.4333'},
      '442503': {'lat': '20.1305', 'name': 'Shivjinagar,Chandrapur,Maharashtra', 'lon': '79.034'},
      '442502': {'lat': '19.5351', 'name': 'Cementnagar,Chandrapur,Maharashtra', 'lon': '79.6099'},
      '143505': {'lat': '31.6724', 'name': 'Batala H.O,Gurdaspur,Punjab', 'lon': '75.145'},
      '344012': {'lat': '26.3688', 'name': 'Dudhwakhurd,Barmer,Rajasthan', 'lon': '75.4513'},
      '766019': {'lat': '19.9237', 'name': 'Ainli,Kalahandi,Odisha', 'lon': '83.0565'},
      '201009': {'lat': '28.6472', 'name': 'Arya Nagar (Ghaziabad),Ghaziabad,Uttar Pradesh', 'lon': '77.4282'},
      '591103': {'lat': '15.8894', 'name': 'Mavinkatti,Belagavi,Karnataka', 'lon': '74.6613'},
      '680307': {'lat': '10.3422', 'name': 'Chalakudi Town,Thrissur,Kerala', 'lon': '76.2977'},
      '680306': {'lat': '10.462', 'name': 'Trikkur,Thrissur,Kerala', 'lon': '76.2479'},
      '680305': {'lat': '10.4', 'name': 'Nellayi-thrissur,Thrissur,Kerala', 'lon': '76.3'},
      '680304': {'lat': '10.3422', 'name': 'Chimmoni Dam,Thrissur,Kerala', 'lon': '76.2977'},
      '680303': {'lat': '10.3422', 'name': 'Velupadam,Thrissur,Kerala', 'lon': '76.2977'},
      '680302': {'lat': '10.3422', 'name': 'Vattanathara,Thrissur,Kerala', 'lon': '76.2977'},
      '680301': {'lat': '10.4138', 'name': 'Pazhayi,Thrissur,Kerala', 'lon': '76.2606'},
      '443112': {'lat': '21.0828', 'name': 'Tadulwadi,Buldhana,Maharashtra', 'lon': '76.1743'},
      '641659': {'lat': '11.1762', 'name': 'Moperipalayam,Coimbatore,Tamil Nadu', 'lon': '77.1248'},
      '591101': {'lat': '16.0846', 'name': 'Akkatangerhal,Belagavi,Karnataka', 'lon': '75.359'},
      '680309': {'lat': '10.3422', 'name': 'Annanad,Thrissur,Kerala', 'lon': '76.2977'},
      '680308': {'lat': '10.2412', 'name': 'Koratti Kizhakkumuri,Thrissur,Kerala', 'lon': '76.3405'},
      '851117': {'lat': '25.5528', 'name': 'Refinery Township,Begusarai,Bihar', 'lon': '86.1246'},
      '177034': {'lat': '31.9462', 'name': 'Tikkar,Kangra,Himachal Pradesh', 'lon': '76.3561'},
      '177033': {'lat': '31.75248', 'name': 'Kohla,Hamirpur(HP),Himachal Pradesh', 'lon': '76.34292'},
      '177031': {'lat': '31.4738', 'name': 'Chulhari,Una,Himachal Pradesh', 'lon': '76.4066'},
      '635307': {'lat': '12.2541', 'name': 'Kethunaickenpatti,Krishnagiri,Tamil Nadu', 'lon': '78.5389'},
      '635306': {'lat': '12.0998', 'name': 'Anderipatti,Krishnagiri,Tamil Nadu', 'lon': '78.4941'},
      '635305': {'lat': '12.0558', 'name': 'Gobinathampatti,Dharmapuri,Tamil Nadu', 'lon': '78.1459'},
      '635304': {'lat': '12.2616', 'name': 'Salamarathupatti,Krishnagiri,Tamil Nadu', 'lon': '78.3755'},
      '635303': {'lat': '12.0874', 'name': 'Venkatadrihalli,Dharmapuri,Tamil Nadu', 'lon': '78.2822'},
      '635302': {'lat': '12.1266', 'name': 'Chintalpadi,Dharmapuri,Tamil Nadu', 'lon': '78.3114'},
      '177038': {'lat': '31.7132427', 'name': 'Kitpal,Hamirpur(HP),Himachal Pradesh', 'lon': '76.3557738'},
      '177039': {'lat': '31.5807', 'name': 'Bhindla,Una,Himachal Pradesh', 'lon': '76.2101'},
      '313906': {'lat': '24.4776', 'name': 'Sameeja,Udaipur,Rajasthan', 'lon': '73.3462'},
      '796501': {'lat': '23.5067', 'name': 'Khawrihnim,Mammit,Mizoram', 'lon': '92.5882'},
      '242042': {'lat': '27.64', 'name': 'Banda (Shahjahanpur),Shahjahanpur,Uttar Pradesh', 'lon': '80.1721'},
      '313904': {'lat': '24.2209', 'name': 'Thana,Udaipur,Rajasthan', 'lon': '73.8251'},
      '742224': {'lat': '24.6278', 'name': 'Nimtita Joykrishnapur,Murshidabad,West Bengal', 'lon': '87.9871'},
      '313905': {'lat': '25.1995', 'name': 'Sallada,Udaipur,Rajasthan', 'lon': '74.6851'},
      '794115': {'lat': '25.4623', 'name': 'Zigzak,West Garo Hills,Meghalaya', 'lon': '89.9333'},
      '241403': {'lat': '27.2574', 'name': 'Kuchila,Hardoi,Uttar Pradesh', 'lon': '80.0397'},
      '572201': {'lat': '13.2764', 'name': 'Sarthvally,Tumakuru,Karnataka', 'lon': '76.5612'},
      '244104': {'lat': '28.7794', 'name': 'Veerpur Than,Moradabad,Uttar Pradesh', 'lon': '79.0586'},
      '181204': {'lat': '32.8369', 'name': 'Bardoh,Jammu,Jammu & Kashmir', 'lon': '74.4836'},
      '181205': {'lat': '32.8167', 'name': 'Muthi,Jammu,Jammu & Kashmir', 'lon': '74.6802'},
      '181206': {'lat': '32.7942', 'name': 'Karloop,Jammu,Jammu & Kashmir', 'lon': '74.7083'},
      '181207': {'lat': '32.8167', 'name': 'Gura Manhasan,Jammu,Jammu & Kashmir', 'lon': '74.6802'},
      '181201': {'lat': '32.8585', 'name': 'Pangyari,Jammu,Jammu & Kashmir', 'lon': '74.7582'},
      '181202': {'lat': '32.8945', 'name': 'Bakore,Jammu,Jammu & Kashmir', 'lon': '74.7328'},
      '181203': {'lat': '32.7746', 'name': 'Nikkian,Jammu,Jammu & Kashmir', 'lon': '74.6215'},
      '744201': {'lat': '12.7167', 'name': 'Swdeshnagar,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.9'},
      '744203': {'lat': '12.6435', 'name': 'lon Island,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.8794'},
      '744202': {'lat': '13.211', 'name': 'Ramnagar,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.9389'},
      '744205': {'lat': '12.503', 'name': 'Bakultala,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.9077'},
      '744204': {'lat': '12.6435', 'name': 'Tugapur,North And Middle Andaman,Andaman & Nicobar Islands', 'lon': '92.8794'},
      '744207': {'lat': '12.6435', 'name': 'Vivekandapur,South Andaman,Andaman & Nicobar Islands', 'lon': '92.8794'},
      '744206': {'lat': '11.7167', 'name': 'Miletilak,South Andaman,Andaman & Nicobar Islands', 'lon': '92.6833'},
      '841223': {'lat': '25.9432', 'name': 'Narwan,Saran,Bihar', 'lon': '84.4781'},
      '509129': {'lat': '17.7973', 'name': 'Nandinne,Mahabub Nagar,Telangana', 'lon': '78.8001'},
      '835229': {'lat': '22.9536', 'name': 'Konbirnawatoli,Gumla,Jharkhand', 'lon': '84.8108'},
      '509128': {'lat': '16.4415', 'name': 'Chennipad,Mahabub Nagar,Telangana', 'lon': '78.0241'},
      '678722': {'lat': '10.6191', 'name': 'Nechur,Palakkad,Kerala', 'lon': '76.5983'},
      '678721': {'lat': '10.6191', 'name': 'Kuttanur,Palakkad,Kerala', 'lon': '76.5983'},
      '442916': {'lat': '20.5785', 'name': 'Kanhalgaon,Chandrapur,Maharashtra', 'lon': '79.3811'},
      '201008': {'lat': '28.2978', 'name': 'Piyaoli,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.7362'},
      '262533': {'lat': '29.7272', 'name': 'Dashauli,Pithoragarh,Uttarakhand', 'lon': '79.7648'},
      '224129': {'lat': '26.3569', 'name': 'Kaurahi,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5197'},
      '851112': {'lat': '25.5105', 'name': 'Jayintigram,Begusarai,Bihar', 'lon': '86.0853'},
      '271824': {'lat': '27.595', 'name': 'Mahasi Tahsil,Bahraich,Uttar Pradesh', 'lon': '81.3795'},
      '613202': {'lat': '10.8609', 'name': 'Tiruchottuthurai,Thanjavur,Tamil Nadu', 'lon': '79.1083'},
      '613203': {'lat': '10.8822', 'name': 'Mukasakaduveli,Thanjavur,Tamil Nadu', 'lon': '79.0412'},
      '613201': {'lat': '10.874', 'name': 'Uthamanallur,Thanjavur,Tamil Nadu', 'lon': '79.1135'},
      '613204': {'lat': '10.9701', 'name': 'Ponavasal,Thanjavur,Tamil Nadu', 'lon': '79.0712'},
      '613205': {'lat': '11.9333', 'name': 'Nagathi,Thanjavur,Tamil Nadu', 'lon': '79.3229'},
      '787110': {'lat': '27.5273', 'name': 'Bhebeli Gaon,Dhemaji,Assam', 'lon': '94.6755'},
      '494669': {'lat': '20.1407', 'name': 'Kodapakha,Kanker,Chattisgarh', 'lon': '80.9343'},
      '851113': {'lat': '25.4686', 'name': 'B.Deorhi,Begusarai,Bihar', 'lon': '85.9516'},
      '494665': {'lat': '20.4886', 'name': 'Panidober,Kanker,Chattisgarh', 'lon': '81.4283'},
      '271821': {'lat': '27.7364', 'name': 'Ganga Jamuni,Bahraich,Uttar Pradesh', 'lon': '81.4519'},
      '507164': {'lat': '17.2285', 'name': 'Kallorgudem,Khammam,Telangana', 'lon': '80.013'},
      '494661': {'lat': '20.5579', 'name': 'Gadhbengal,Narayanpur,Chattisgarh', 'lon': '81.6914'},
      '222203': {'lat': '25.6436', 'name': 'Badkhin,Jaunpur,Uttar Pradesh', 'lon': '82.5944'},
      '768104': {'lat': '21.2559', 'name': 'Ainlapali,Sambalpur,Odisha', 'lon': '83.9325'},
      '768106': {'lat': '20.9824', 'name': 'Tribanpur,Sambalpur,Odisha', 'lon': '84.2398'},
      '792105': {'lat': '27.7955', 'name': 'Padumani,Namsai,Arunachal Pradesh', 'lon': '96.1825'},
      '721253': {'lat': '22.9317', 'name': 'Ghoshkira,East Midnapore,West Bengal', 'lon': '87.8149'},
      '768107': {'lat': '21.5531', 'name': 'Katangapani,Debagarh,Odisha', 'lon': '84.3544'},
      '792104': {'lat': '27.8401', 'name': 'Hayuliang,Lohit,Arunachal Pradesh', 'lon': '96.1952'},
      '509126': {'lat': '16.5922', 'name': 'Julaikal,Mahabub Nagar,Telangana', 'lon': '78.2067'},
      '628003': {'lat': '8.5782', 'name': 'Tuticorin courts,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '321402': {'lat': '27.2229', 'name': 'Madha Pura,Bharatpur,Rajasthan', 'lon': '77.3511'},
      '321403': {'lat': '26.9324', 'name': 'Jotroli,Bharatpur,Rajasthan', 'lon': '77.4513'},
      '321401': {'lat': '27.0445', 'name': 'Farso,Bharatpur,Rajasthan', 'lon': '77.2629'},
      '321406': {'lat': '27.1161', 'name': 'Saindhli,Bharatpur,Rajasthan', 'lon': '77.1213'},
      '321407': {'lat': '26.7981', 'name': 'Mudia Sadh,Bharatpur,Rajasthan', 'lon': '76.7637'},
      '321404': {'lat': '26.9804', 'name': 'Khanwa,Bharatpur,Rajasthan', 'lon': '77.6216'},
      '321405': {'lat': '26.7858', 'name': 'Nagla Tula,Bharatpur,Rajasthan', 'lon': '77.4061'},
      '415315': {'lat': '17.1177', 'name': 'Lingiware,Sangli,Maharashtra', 'lon': '74.6544'},
      '321408': {'lat': '26.5817', 'name': 'Bajhera Kalan,Bharatpur,Rajasthan', 'lon': '76.5195'},
      '768103': {'lat': '21.2858', 'name': 'Talsirgida,Bargarh,Odisha', 'lon': '83.742'},
      '415310': {'lat': '17.1177', 'name': 'Bhikavadi BK,Sangli,Maharashtra', 'lon': '74.6544'},
      '415311': {'lat': '17.0392', 'name': 'Pare,Sangli,Maharashtra', 'lon': '74.6569'},
      '415312': {'lat': '17.4319', 'name': 'Vikhale,Satara,Maharashtra', 'lon': '74.6443'},
      '136034': {'lat': '30.0726', 'name': 'Harigarh Kingan,Kaithal,Haryana', 'lon': '76.356'},
      '455459': {'lat': '22.5961', 'name': 'Mohai,Dewas,Madhya Pradesh', 'lon': '76.6651'},
      '802164': {'lat': '25.3615', 'name': 'Kirkiri,Bhojpur,Bihar', 'lon': '84.7027'},
      '851111': {'lat': '25.3699', 'name': 'Marachi Kalan,Begusarai,Bihar', 'lon': '85.7541'},
      '812002': {'lat': '25.1421', 'name': 'Sujaganj Bazar,Bhagalpur,Bihar', 'lon': '85.0021'},
      '387550': {'lat': '22.9288', 'name': 'Kaloli,Kheda,Gujarat', 'lon': '72.664'},
      '387220': {'lat': '23.2676', 'name': 'Malataj,Anand,Gujarat', 'lon': '72.8698'},
      '754113': {'lat': '20.6213', 'name': 'Angeswarpada,Cuttack,Odisha', 'lon': '85.6603'},
      '828135': {'lat': '23.791', 'name': 'Chasnalla,Dhanbad,Jharkhand', 'lon': '85.7021'},
      '380028': {'lat': '22.9764', 'name': 'Shah Alam Roza,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380024': {'lat': '22.9764', 'name': 'I E Bapunagar,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380027': {'lat': '22.9764', 'name': 'Gandhi Ashram (Ahmedabad),Ahmedabad,Gujarat', 'lon': '72.836'},
      '380026': {'lat': '22.9764', 'name': 'Amraiwadi,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380021': {'lat': '22.9764', 'name': 'Rajpur Gomtipur,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380023': {'lat': '22.9764', 'name': 'Rakhial,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380022': {'lat': '22.9764', 'name': 'Calico Mills,Ahmedabad,Gujarat', 'lon': '72.836'},
      '574323': {'lat': '12.8025', 'name': 'Salethur,Dakshina Kannada,Karnataka', 'lon': '75.0404'},
      '574327': {'lat': '12.9225', 'name': 'Kurunjibag,Dakshina Kannada,Karnataka', 'lon': '75.2738'},
      '574326': {'lat': '12.85', 'name': 'Uruvalu,Dakshina Kannada,Karnataka', 'lon': '75.2536'},
      '574325': {'lat': '12.8173', 'name': 'Kodimbady,Dakshina Kannada,Karnataka', 'lon': '75.1873'},
      '444126': {'lat': '20.6786', 'name': 'Pimpri Kd,Akola,Maharashtra', 'lon': '77.5881'},
      '629501': {'lat': '8.115', 'name': 'Pozhikkarai,Kanyakumari,Tamil Nadu', 'lon': '77.41'},
      '629502': {'lat': '8.3069', 'name': 'Ganapathipuram,Kanyakumari,Tamil Nadu', 'lon': '77.3614'},
      '574328': {'lat': '12.7154', 'name': 'Dolpady,Dakshina Kannada,Karnataka', 'lon': '75.3972'},
      '500098': {'lat': '17.3898', 'name': 'Medipalli,Hyderabad,Telangana', 'lon': '78.4699'},
      '301707': {'lat': '28.0575', 'name': 'Tapukara,Alwar,Rajasthan', 'lon': '76.8949'},
      '301706': {'lat': '27.7644', 'name': 'Sakatpura (Bawad),Alwar,Rajasthan', 'lon': '76.5215'},
      '471501': {'lat': '24.8795', 'name': 'Budrakh,Chhatarpur,Madhya Pradesh', 'lon': '79.8742'},
      '301705': {'lat': '28.0072', 'name': 'Gheelot,Alwar,Rajasthan', 'lon': '76.3747'},
      '335711': {'lat': '28.4771', 'name': '6 D D,Ganganagar,Rajasthan', 'lon': '74.1443'},
      '816120': {'lat': '25.0367', 'name': 'Hatigarah,Sahibganj,Jharkhand', 'lon': '87.5908'},
      '142058': {'lat': '30.8778', 'name': 'Indergarh,Moga,Punjab', 'lon': '75.0738'},
      '303110': {'lat': '27.5775', 'name': 'Panchudala,Jaipur,Rajasthan', 'lon': '76.1715'},
      '301704': {'lat': '28.1096', 'name': 'Huria Kalan,Alwar,Rajasthan', 'lon': '76.3854'},
      '176321': {'lat': '32.7487', 'name': 'Jassourgarh,Chamba,Himachal Pradesh', 'lon': '76.1693'},
      '363427': {'lat': '22.783', 'name': 'Mulbavla,Surendra Nagar,Gujarat', 'lon': '71.9481'},
      '176323': {'lat': '32.6374', 'name': 'Mindhal,Chamba,Himachal Pradesh', 'lon': '76.4793'},
      '363425': {'lat': '22.608', 'name': 'Hadalabhal,Surendra Nagar,Gujarat', 'lon': '71.9267'},
      '176325': {'lat': '32.5095', 'name': 'Bhunad,Chamba,Himachal Pradesh', 'lon': '76.3507'},
      '363423': {'lat': '22.5716', 'name': 'Anandpur Bhal,Surendra Nagar,Gujarat', 'lon': '72.0555'},
      '142056': {'lat': '30.6466', 'name': 'Phulewala,Moga,Punjab', 'lon': '75.1546'},
      '176326': {'lat': '32.6775', 'name': 'Bharari,Chamba,Himachal Pradesh', 'lon': '76.3542'},
      '495445': {'lat': '22.4698', 'name': 'Sutarra,Korba,Chattisgarh', 'lon': '82.5574'},
      '560070': {'lat': '12.836', 'name': 'B Sk II Stage,Bengaluru,Karnataka', 'lon': '77.4149'},
      '360330': {'lat': '21.9157', 'name': 'Kamar Kotda,Rajkot,Gujarat', 'lon': '70.8459'},
      '312001': {'lat': '25.0703', 'name': 'Chittorgarh Fort,Chittorgarh,Rajasthan', 'lon': '74.0702'},
      '800004': {'lat': '25.5377', 'name': 'Naya Tola (Patna),Patna,Bihar', 'lon': '85.2322'},
      '781369': {'lat': '26.4408', 'name': 'Chilling,Nalbari,Assam', 'lon': '91.8873'},
      '781368': {'lat': '26.4408', 'name': 'Kalcheni,Nalbari,Assam', 'lon': '91.8873'},
      '828128': {'lat': '23.7912', 'name': 'Tundoo,Dhanbad,Jharkhand', 'lon': '86.2567'},
      '226028': {'lat': '26.8807', 'name': 'Juggaur,Lucknow,Uttar Pradesh', 'lon': '81.0444'},
      '226029': {'lat': '26.8717', 'name': 'Vrinda Van Colony,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '571320': {'lat': '12.2088', 'name': 'Cowdalli,Chamrajnagar,Karnataka', 'lon': '76.6717'},
      '781360': {'lat': '26.7306', 'name': 'Angarkata,Nalbari,Assam', 'lon': '91.5564'},
      '226024': {'lat': '26.8717', 'name': 'Aliganj (Lucknow),Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '226025': {'lat': '26.8717', 'name': 'B R A University,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '781365': {'lat': '26.182', 'name': 'Rangia R.S.,Kamrup,Assam', 'lon': '91.8321'},
      '781364': {'lat': '26.486', 'name': 'Maharipara,Kamrup,Assam', 'lon': '92.7973'},
      '781367': {'lat': '26.335', 'name': 'Zarkona,Nalbari,Assam', 'lon': '91.2507'},
      '781366': {'lat': '26.5311', 'name': 'Silkijhar,Kamrup,Assam', 'lon': '91.7267'},
      '301709': {'lat': '28.0031', 'name': 'Peepli,Alwar,Rajasthan', 'lon': '76.3115'},
      '487555': {'lat': '22.9993', 'name': 'Chirriya,Narsinghpur,Madhya Pradesh', 'lon': '78.8139'},
      '800001': {'lat': '25.5891', 'name': 'Rajapur Mainpura,Patna,Bihar', 'lon': '85.1171'},
      '487551': {'lat': '22.9523', 'name': 'Amgaonchhota,Narsinghpur,Madhya Pradesh', 'lon': '78.7714'},
      '486111': {'lat': '24.6787', 'name': 'Mahmoodpur,Rewa,Madhya Pradesh', 'lon': '81.5059'},
      '621704': {'lat': '11.1269', 'name': 'Ariyalur Bazaar,Ariyalur,Tamil Nadu', 'lon': '79.1346'},
      '184148': {'lat': '32.6021', 'name': 'Haripur Patil,Kathua,Jammu & Kashmir', 'lon': '75.0753'},
      '209801': {'lat': '26.6167', 'name': 'Unnao H.O,Unnao,Uttar Pradesh', 'lon': '80.8105'},
      '184144': {'lat': '32.4913', 'name': 'Maghloor,Kathua,Jammu & Kashmir', 'lon': '75.3387'},
      '184145': {'lat': '32.8113', 'name': 'Rajpura (Kathua),Kathua,Jammu & Kashmir', 'lon': '75.1242'},
      '184141': {'lat': '33.6635', 'name': 'Raikoota,Kathua,Jammu & Kashmir', 'lon': '74.3732'},
      '184142': {'lat': '32.4158', 'name': 'Gurah Mundian,Kathua,Jammu & Kashmir', 'lon': '75.3697'},
      '184143': {'lat': '32.798', 'name': 'Barnote,Kathua,Jammu & Kashmir', 'lon': '75.3244'},
      '305927': {'lat': '26.0586', 'name': 'Durgawas,Ajmer,Rajasthan', 'lon': '74.251'},
      '305926': {'lat': '25.7778', 'name': 'Bamanheda,Ajmer,Rajasthan', 'lon': '74.0229'},
      '305925': {'lat': '26.0168', 'name': 'Laget Khera,Rajsamand,Rajasthan', 'lon': '74.299'},
      '305924': {'lat': '25.6911', 'name': 'Malto Ki Ber,Ajmer,Rajasthan', 'lon': '73.9721'},
      '305923': {'lat': '25.881', 'name': 'Rawatmal,Ajmer,Rajasthan', 'lon': '74.1934'},
      '305922': {'lat': '25.9399', 'name': 'Biliyawas,Rajsamand,Rajasthan', 'lon': '74.2287'},
      '305921': {'lat': '25.7732', 'name': 'Kaladeh,Rajsamand,Rajasthan', 'lon': '74.0911'},
      '202170': {'lat': '27.9269', 'name': 'Bistauli,Aligarh,Uttar Pradesh', 'lon': '78.0793'},
      '788734': {'lat': '24.5998', 'name': 'Leelachela,Hailakandi,Assam', 'lon': '92.4019'},
      '364290': {'lat': '23.0747', 'name': 'Lusdi,Bhavnagar,Gujarat', 'lon': '70.1726'},
      '500051': {'lat': '17.3724', 'name': 'Hindustan Cables Ltd,Hyderabad,Telangana', 'lon': '78.437'},
      '364295': {'lat': '23.0747', 'name': 'Galthar,Bhavnagar,Gujarat', 'lon': '70.1726'},
      '412311': {'lat': '18.5796', 'name': 'Hargude,Pune,Maharashtra', 'lon': '74.4154'},
      '496242': {'lat': '22.3075', 'name': 'Farsabahar,Raigarh,Chattisgarh', 'lon': '83.0142'},
      '212661': {'lat': '25.8733', 'name': 'Mahana,Fatehpur,Uttar Pradesh', 'lon': '80.807'},
      '412312': {'lat': '18.5796', 'name': 'Tondal,Pune,Maharashtra', 'lon': '74.4154'},
      '212665': {'lat': '26.5553', 'name': 'Madokipur,Fatehpur,Uttar Pradesh', 'lon': '80.1575'},
      '322220': {'lat': '26.7092', 'name': 'Rendayal Gurjar,Sawai Madhopur,Rajasthan', 'lon': '76.8763'},
      '416409': {'lat': '17.587', 'name': 'Narwad,Sangli,Maharashtra', 'lon': '75.4517'},
      '416408': {'lat': '16.5', 'name': 'Karoli (M),Sangli,Maharashtra', 'lon': '74.4572'},
      '848122': {'lat': '25.7976', 'name': 'Srichandpur Kothia,Samastipur,Bihar', 'lon': '85.6505'},
      '384230': {'lat': '23.6015', 'name': 'Delmal,Patan,Gujarat', 'lon': '72.5229'},
      '848127': {'lat': '25.7256', 'name': 'Harpur Barhetta,Samastipur,Bihar', 'lon': '85.6371'},
      '695099': {'lat': '8.5818', 'name': "Kerala Governor's Camp,Thiruvananthapuram,Kerala", 'lon': '76.9083'},
      '722173': {'lat': '23.1715', 'name': 'Brahmandiha,Bankura,West Bengal', 'lon': '86.8339'},
      '788030': {'lat': '24.8313', 'name': 'Durganagar T E,Cachar,Assam', 'lon': '92.804'},
      '788031': {'lat': '24.8313', 'name': 'Dumurghat,Cachar,Assam', 'lon': '92.804'},
      '416405': {'lat': '18.4063', 'name': 'Nangole,Sangli,Maharashtra', 'lon': '75.8557'},
      '416404': {'lat': '17.0831', 'name': 'Vajrawad,Sangli,Maharashtra', 'lon': '75.2495'},
      '416407': {'lat': '17.112', 'name': 'Dongarwadi,Sangli,Maharashtra', 'lon': '74.7699'},
      '416406': {'lat': '17.587', 'name': 'Madhavnagar,Sangli,Maharashtra', 'lon': '75.4517'},
      '385545': {'lat': '24.178', 'name': 'Viruna,Banaskantha,Gujarat', 'lon': '72.2122'},
      '843110': {'lat': '25.4837', 'name': 'Ajijpur Chande,Vaishali,Bihar', 'lon': '85.5483'},
      '843113': {'lat': '25.8369', 'name': 'Simra Urf Afzalpur,Muzaffarpur,Bihar', 'lon': '85.4888'},
      '385010': {'lat': '24.2805', 'name': 'Bhatamal-moti,Banaskantha,Gujarat', 'lon': '72.4033'},
      '643201': {'lat': '11.4695', 'name': 'Aravenu,Nilgiris,Tamil Nadu', 'lon': '76.5523'},
      '385540': {'lat': '23.4819', 'name': 'Junadisa,Banaskantha,Gujarat', 'lon': '73.5033'},
      '843117': {'lat': '26.2977', 'name': 'Dharampur,Muzaffarpur,Bihar', 'lon': '84.9086'},
      '843116': {'lat': '25.527', 'name': 'Dakrama,Muzaffarpur,Bihar', 'lon': '85.7068'},
      '843119': {'lat': '25.6685', 'name': 'Prahladpur,Muzaffarpur,Bihar', 'lon': '85.3993'},
      '700092': {'lat': '22.4485', 'name': 'Regent Estate,Kolkata,West Bengal', 'lon': '88.3883'},
      '624403': {'lat': '10.1533', 'name': 'Kottaipatti,Dindigul,Tamil Nadu', 'lon': '77.7315'},
      '262121': {'lat': '28.4361', 'name': 'Barehpura,Pilibhit,Uttar Pradesh', 'lon': '79.7042'},
      '814147': {'lat': '24.7323', 'name': 'Kero Bazar,Godda,Jharkhand', 'lon': '87.4709'},
      '230124': {'lat': '25.9609', 'name': 'Gadhiyawan,Pratapgarh,Uttar Pradesh', 'lon': '82.1645'},
      '230125': {'lat': '26.0622', 'name': 'Atheha,Pratapgarh,Uttar Pradesh', 'lon': '81.7213'},
      '230126': {'lat': '25.8919', 'name': 'Pure Noti,Pratapgarh,Uttar Pradesh', 'lon': '81.7158'},
      '480881': {'lat': '21.6604', 'name': 'Piparwani,Seoni,Madhya Pradesh', 'lon': '79.5701'},
      '230121': {'lat': '25.9516', 'name': 'Gaura Mafi,Pratapgarh,Uttar Pradesh', 'lon': '82.3422'},
      '249195': {'lat': '30.1997', 'name': 'Mahidanda,Uttarkashi,Uttarakhand', 'lon': '78.6962'},
      '230128': {'lat': '25.832', 'name': 'Rajapur Bindhan,Pratapgarh,Uttar Pradesh', 'lon': '81.757'},
      '230129': {'lat': '25.7367', 'name': 'Kutulitya,Pratapgarh,Uttar Pradesh', 'lon': '81.9829'},
      '456335': {'lat': '23.2294', 'name': 'Barkheda  Pitramal,Ujjain,Madhya Pradesh', 'lon': '76.0281'},
      '224122': {'lat': '26.2066', 'name': 'Mubarakpur Daiyadeeh,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5484'},
      '799144': {'lat': '23.0015', 'name': 'Kanchannagar,South Tripura,Tripura', 'lon': '91.7243'},
      '799145': {'lat': '23.0015', 'name': 'Ludhua Tea Estate,South Tripura,Tripura', 'lon': '91.7243'},
      '625501': {'lat': '10.0696', 'name': 'Ayyur,Madurai,Tamil Nadu', 'lon': '78.0813'},
      '625503': {'lat': '10.139', 'name': 'Sendamangalam,Madurai,Tamil Nadu', 'lon': '78.1448'},
      '562110': {'lat': '13.19', 'name': 'Hegganahalli,Bangalore Rural,Karnataka', 'lon': '77.4465'},
      '144040': {'lat': '31.1606', 'name': 'Billi Chuharmi,Jalandhar,Punjab', 'lon': '75.3515'},
      '144043': {'lat': '31.3537', 'name': 'Ghure,Jalandhar,Punjab', 'lon': '75.4925'},
      '144042': {'lat': '31.1784', 'name': 'Chak Kalan,Jalandhar,Punjab', 'lon': '75.5352'},
      '144044': {'lat': '31.1428', 'name': 'Maheem,Jalandhar,Punjab', 'lon': '75.477'},
      '431121': {'lat': '19.6478', 'name': 'Adul Budruk,Aurangabad,Maharashtra', 'lon': '76.5642'},
      '431120': {'lat': '20.5645', 'name': 'Bahulkheda,Aurangabad,Maharashtra', 'lon': '75.5495'},
      '431123': {'lat': '18.9829', 'name': 'Warapgoan,Beed,Maharashtra', 'lon': '76.3026'},
      '431122': {'lat': '19.2919', 'name': 'M.GANJ,Beed,Maharashtra', 'lon': '76.4529'},
      '361240': {'lat': '23.2395', 'name': 'Shampar,Jamnagar,Gujarat', 'lon': '69.6695'},
      '431124': {'lat': '19.2077', 'name': 'Chikhalbeed,Beed,Maharashtra', 'lon': '77.0702'},
      '431127': {'lat': '19.5505', 'name': 'Reoki,Beed,Maharashtra', 'lon': '75.6451'},
      '431126': {'lat': '20.0435', 'name': 'Hingni BK,Beed,Maharashtra', 'lon': '77.5411'},
      '431129': {'lat': '19.335', 'name': 'Shirasdevi,Beed,Maharashtra', 'lon': '76.4769'},
      '431128': {'lat': '18.9382', 'name': 'Pohner,Beed,Maharashtra', 'lon': '76.2592'},
      '628001': {'lat': '8.4083', 'name': 'Tuticorin Keelur,Tuticorin,Tamil Nadu', 'lon': '78.0591'},
      '643005': {'lat': '11.4718', 'name': 'Kokkal,Nilgiris,Tamil Nadu', 'lon': '76.6747'},
      '476219': {'lat': '26.379', 'name': 'Galetha,Morena,Madhya Pradesh', 'lon': '77.8111'},
      '228142': {'lat': '26.3718', 'name': 'Parsa Darwa,Sultanpur,Uttar Pradesh', 'lon': '82.2689'},
      '228141': {'lat': '26.3324', 'name': 'Seotari,Sultanpur,Uttar Pradesh', 'lon': '82.3133'},
      '132024': {'lat': '29.6966', 'name': 'Gonder,Karnal,Haryana', 'lon': '76.7901'},
      '132022': {'lat': '29.7169', 'name': 'Nalipar,Karnal,Haryana', 'lon': '77.0947'},
      '132023': {'lat': '29.7355', 'name': 'Subri,Karnal,Haryana', 'lon': '77.0093'},
      '370615': {'lat': '23.3431', 'name': 'Rampar (R),Kachchh,Gujarat', 'lon': '69.2669'},
      '185234': {'lat': '33.3572', 'name': 'Ujjan,Rajauri,Jammu & Kashmir', 'lon': '74.7129'},
      '631502': {'lat': '12.6207', 'name': 'Sirukaveripakkam,Kanchipuram,Tamil Nadu', 'lon': '79.6513'},
      '370610': {'lat': '23.3431', 'name': 'Sanyra (J),Kachchh,Gujarat', 'lon': '69.2669'},
      '185233': {'lat': '33.3572', 'name': 'Mamanlot,Reasi,Jammu & Kashmir', 'lon': '74.7129'},
      '321614': {'lat': '26.4587', 'name': 'Talchhera,Bharatpur,Rajasthan', 'lon': '77.0115'},
      '814142': {'lat': '24.372', 'name': 'Kenmankathi,Deoghar,Jharkhand', 'lon': '85.9778'},
      '321613': {'lat': '26.888', 'name': 'Unkroond,Dausa,Rajasthan', 'lon': '76.9361'},
      '460225': {'lat': '21.8367', 'name': 'Amal,Betul,Madhya Pradesh', 'lon': '77.7543'},
      '627105': {'lat': '8.3854', 'name': 'Kavalkinaru,Tirunelveli,Tamil Nadu', 'lon': '77.5778'},
      '173001': {'lat': '30.6089', 'name': 'Nahan H.O,Sirmaur,Himachal Pradesh', 'lon': '77.3475'},
      '321612': {'lat': '26.8174', 'name': 'Palanheda,Dausa,Rajasthan', 'lon': '76.8382'},
      '497118': {'lat': '22.0138', 'name': 'Sankargarh,Surguja,Chattisgarh', 'lon': '82.5602'},
      '400011': {'lat': '18.9833', 'name': 'Haines Road,Mumbai,Maharashtra', 'lon': '72.8333'},
      '321611': {'lat': '26.772', 'name': 'Manderu,Karauli,Rajasthan', 'lon': '76.6339'},
      '400010': {'lat': '18.9681', 'name': 'Mazgaon Road,Mumbai,Maharashtra', 'lon': '72.845'},
      '847212': {'lat': '26.3141', 'name': 'Malangia,Madhubani,Bihar', 'lon': '86.0146'},
      '209721': {'lat': '27.284', 'name': 'Bazaria Chhibramau,Kannauj,Uttar Pradesh', 'lon': '79.3141'},
      '415106': {'lat': '17.4018', 'name': 'Chikhali(Masur),Satara,Maharashtra', 'lon': '74.2083'},
      '497114': {'lat': '22.7631', 'name': 'Jamkani,Surguja,Chattisgarh', 'lon': '83.0144'},
      '400013': {'lat': '19.0073', 'name': 'Delisle Road,Mumbai,Maharashtra', 'lon': '72.8421'},
      '497116': {'lat': '23.0125', 'name': 'Turna,Surguja,Chattisgarh', 'lon': '83.0618'},
      '721627': {'lat': '22.5931', 'name': 'Teghorijoteviram,East Midnapore,West Bengal', 'lon': '88.0914'},
      '224189': {'lat': '26.6012', 'name': 'Dillisaraiya,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.3826'},
      '400012': {'lat': '19', 'name': 'Parel Rly Work Shop,Mumbai,Maharashtra', 'lon': '72.8333'},
      '603111': {'lat': '12.5498', 'name': 'Ozhalur,Kanchipuram,Tamil Nadu', 'lon': '79.9877'},
      '814143': {'lat': '24.7424', 'name': 'Kunda (Deoghar),Deoghar,Jharkhand', 'lon': '85.7764'},
      '400015': {'lat': '19', 'name': 'Sewri,Mumbai,Maharashtra', 'lon': '72.85'},
      '721625': {'lat': '22.284', 'name': 'Hanubhunia,East Midnapore,West Bengal', 'lon': '88.054'},
      '230141': {'lat': '25.8174', 'name': 'Dhangarh,Pratapgarh,Uttar Pradesh', 'lon': '81.5238'},
      '400014': {'lat': '19.0073', 'name': 'Dadar Colony,Mumbai,Maharashtra', 'lon': '72.8421'},
      '783129': {'lat': '26.0733', 'name': 'Nidanpur,Goalpara,Assam', 'lon': '90.3281'},
      '783128': {'lat': '26.0672', 'name': 'Muhurirchar,Dhubri,Assam', 'lon': '90.511'},
      '382240': {'lat': '22.858', 'name': 'Baldana,Ahmedabad,Gujarat', 'lon': '72.6953'},
      '400017': {'lat': '19.05', 'name': 'Dharavi Road,Mumbai,Maharashtra', 'lon': '72.8667'},
      '382245': {'lat': '22.9027', 'name': 'Umrela,Ahmedabad,Gujarat', 'lon': '73.1215'},
      '712611': {'lat': '23.2158', 'name': 'Bhurkunda,Hooghly,West Bengal', 'lon': '87.9764'},
      '783120': {'lat': '26.1315', 'name': 'Balbala Kalapani,Goalpara,Assam', 'lon': '90.313'},
      '783123': {'lat': '25.96', 'name': 'Adakgiri,East Garo Hills,Meghalaya', 'lon': '91.0578'},
      '783122': {'lat': '26.0672', 'name': 'Nishangram,Goalpara,Assam', 'lon': '90.511'},
      '783125': {'lat': '26.106', 'name': 'Bakaitari,Goalpara,Assam', 'lon': '90.7957'},
      '783124': {'lat': '26.0672', 'name': 'Lela,Goalpara,Assam', 'lon': '90.511'},
      '783127': {'lat': '25.9054', 'name': 'Tumni,Dhubri,Assam', 'lon': '90.0181'},
      '783126': {'lat': '26.0603', 'name': 'Chotipara,Goalpara,Assam', 'lon': '90.6795'},
      '476335': {'lat': '24.939', 'name': 'Ochhapura,Sheopur,Madhya Pradesh', 'lon': '77.5411'},
      '741123': {'lat': '22.7017', 'name': 'Padmamala,Nadia,West Bengal', 'lon': '88.4695'},
      '476337': {'lat': '25.7377', 'name': 'Premsar,Sheopur,Madhya Pradesh', 'lon': '76.6553'},
      '523108': {'lat': '15.0057', 'name': 'Pedarajupalem,Prakasam,Andhra Pradesh', 'lon': '79.3513'},
      '415639': {'lat': '17.7425', 'name': 'Khedashi,Ratnagiri,Maharashtra', 'lon': '73.2869'},
      '741122': {'lat': '22.9069', 'name': 'Nadiasundalpur,Nadia,West Bengal', 'lon': '88.1139'},
      '562114': {'lat': '13.2898', 'name': 'Mugabala,Bangalore Rural,Karnataka', 'lon': '77.8832'},
      '205001': {'lat': '27.2425', 'name': 'Civil Court (Mainpuri),Mainpuri,Uttar Pradesh', 'lon': '78.9882'},
      '442106': {'lat': '21.0143', 'name': 'Kachnoor,Wardha,Maharashtra', 'lon': '78.364'},
      '761132': {'lat': '20.0649', 'name': 'G.Rambha,Ganjam,Odisha', 'lon': '84.5837'},
      '761133': {'lat': '20.0362', 'name': 'Baunsalundi,Ganjam,Odisha', 'lon': '84.6658'},
      '761131': {'lat': '20.0219', 'name': 'Rudhapadar,Ganjam,Odisha', 'lon': '84.7068'},
      '393135': {'lat': '21.0272', 'name': 'Dungari,Bharuch,Gujarat', 'lon': '73.0641'},
      '177209': {'lat': '31.7041', 'name': 'Kanger,Una,Himachal Pradesh', 'lon': '76.8777'},
      '177208': {'lat': '31.3017', 'name': 'Panjawar,Una,Himachal Pradesh', 'lon': '77.2597'},
      '393130': {'lat': '21.8623', 'name': 'Babda,Surat,Gujarat', 'lon': '73.3422'},
      '177205': {'lat': '31.5807', 'name': 'Ambota,Una,Himachal Pradesh', 'lon': '76.2101'},
      '177204': {'lat': '31.7861', 'name': 'Mawa Kaholan,Una,Himachal Pradesh', 'lon': '75.9977'},
      '177207': {'lat': '31.3757', 'name': 'Pandogha,Una,Himachal Pradesh', 'lon': '76.912'},
      '177206': {'lat': '31.5432', 'name': 'Oel (Una),Una,Himachal Pradesh', 'lon': '76.5342'},
      '177201': {'lat': '31.4441', 'name': 'Badoh,Una,Himachal Pradesh', 'lon': '76.5481'},
      '177203': {'lat': '31.4836', 'name': 'Kuthera Kherla,Una,Himachal Pradesh', 'lon': '76.6431'},
      '177202': {'lat': '31.5432', 'name': 'Mubarikpur,Una,Himachal Pradesh', 'lon': '76.5342'},
      '444903': {'lat': '21.1237', 'name': 'Shendurjana Bazar,Amravati,Maharashtra', 'lon': '78.0704'},
      '444902': {'lat': '20.9909', 'name': 'Shendola KH,Amravati,Maharashtra', 'lon': '78.3858'},
      '444901': {'lat': '21.0011', 'name': 'Mangrul Bhilasp,Amravati,Maharashtra', 'lon': '77.9694'},
      '573117': {'lat': '13.3975', 'name': 'Doddamettikurke,Hassan,Karnataka', 'lon': '76.3451'},
      '444907': {'lat': '21.1348', 'name': 'Satnoor,Amravati,Maharashtra', 'lon': '78.1087'},
      '444906': {'lat': '21.4505', 'name': 'Roshankheda,Amravati,Maharashtra', 'lon': '78.2896'},
      '444905': {'lat': '21.1232', 'name': 'Chincholi Gawal,Amravati,Maharashtra', 'lon': '78.0302'},
      '444904': {'lat': '20.8541', 'name': 'Sirasgaon Korde,Amravati,Maharashtra', 'lon': '77.9913'},
      '515425': {'lat': '14.382', 'name': 'H Sodanapalli,Ananthapur,Andhra Pradesh', 'lon': '78.3731'},
      '741247': {'lat': '23.4733', 'name': 'Pritinagar,Nadia,West Bengal', 'lon': '88.1624'},
      '444908': {'lat': '21.332', 'name': 'Karajgaon (Gadlighat),Amravati,Maharashtra', 'lon': '78.2032'},
      '641655': {'lat': '11.2553', 'name': 'Muriandampalayam,Coimbatore,Tamil Nadu', 'lon': '77.2215'},
      '756042': {'lat': '21.173', 'name': 'Saud,Baleswar,Odisha', 'lon': '86.3708'},
      '242303': {'lat': '27.7941', 'name': 'Kundramajra,Shahjahanpur,Uttar Pradesh', 'lon': '79.4746'},
      '841428': {'lat': '26.344', 'name': 'Khawajapur,Gopalganj,Bihar', 'lon': '84.5327'},
      '759001': {'lat': '20.9578', 'name': 'Dhenkanal College,Dhenkanal,Odisha', 'lon': '85.2872'},
      '805130': {'lat': '25.0091', 'name': 'Kochagoan,Nawada,Bihar', 'lon': '85.5925'},
      '531055': {'lat': '17.5469', 'name': 'Narasingabilli,Visakhapatnam,Andhra Pradesh', 'lon': '82.8395'},
      '450771': {'lat': '21.8316', 'name': 'Chhegaon Makhan,East Nimar,Madhya Pradesh', 'lon': '76.2157'},
      '576201': {'lat': '13.6614', 'name': 'Gandhi-maidan,Udupi,Karnataka', 'lon': '74.7853'},
      '735102': {'lat': '25.5004', 'name': 'Chaulhati,Jalpaiguri,West Bengal', 'lon': '88.2369'},
      '389310': {'lat': '22.6283', 'name': 'Bedhiya,Panch Mahals,Gujarat', 'lon': '73.5039'},
      '841404': {'lat': '26.2088', 'name': 'Hardia,Siwan,Bihar', 'lon': '84.3962'},
      '333012': {'lat': '25.9321', 'name': 'Chhawasari,Jhujhunu,Rajasthan', 'lon': '75.8057'},
      '762026': {'lat': '20.8018', 'name': 'Baghiapada,Boudh,Odisha', 'lon': '84.2768'},
      '522329': {'lat': '15.9825', 'name': 'Pittalavanipalem,Guntur,Andhra Pradesh', 'lon': '80.634'},
      '333011': {'lat': '28.2063', 'name': 'Loona,Jhujhunu,Rajasthan', 'lon': '75.3345'},
      '124021': {'lat': '28.8631', 'name': 'Pehrawar,Rohtak,Haryana', 'lon': '76.6515'},
      '508101': {'lat': '17.604', 'name': 'Anantharam,Nalgonda,Telangana', 'lon': '79.1139'},
      '124022': {'lat': '28.8875', 'name': 'Mokhra,Rohtak,Haryana', 'lon': '76.429'},
      '841401': {'lat': '25.8421', 'name': 'Sareya Basant,Saran,Bihar', 'lon': '84.777'},
      '481662': {'lat': '22.8231', 'name': 'Kudameli,Mandla,Madhya Pradesh', 'lon': '79.5717'},
      '486002': {'lat': '24.4386', 'name': 'Rewa Engineering College,Rewa,Madhya Pradesh', 'lon': '81.1987'},
      '573111': {'lat': '12.8152', 'name': 'Chowlagala,Hassan,Karnataka', 'lon': '76.4809'},
      '671348': {'lat': '12.6923', 'name': 'Bayar,Kasargod,Kerala', 'lon': '75.0243'},
      '800030': {'lat': '25.0723', 'name': 'Purvi Laxminagar,Patna,Bihar', 'lon': '84.0154'},
      '396418': {'lat': '21.0384', 'name': 'Gurukulsupa,Navsari,Gujarat', 'lon': '72.9498'},
      '515641': {'lat': '14.4586', 'name': 'Sandrascherla,Ananthapur,Andhra Pradesh', 'lon': '77.5227'},
      '396415': {'lat': '21.0384', 'name': 'Kalakachha,Navsari,Gujarat', 'lon': '72.9498'},
      '630710': {'lat': '9.9773', 'name': 'Sathanur,Sivaganga,Tamil Nadu', 'lon': '78.5932'},
      '396412': {'lat': '21.0384', 'name': 'Onjal,Navsari,Gujarat', 'lon': '72.9498'},
      '712311': {'lat': '22.6685', 'name': 'Dankuni Housing Complex,Hooghly,West Bengal', 'lon': '88.291'},
      '144041': {'lat': '31.0444', 'name': 'Umarwal Billa,Jalandhar,Punjab', 'lon': '75.5274'},
      '383110': {'lat': '22.9362', 'name': 'Chadasana,Sabarkantha,Gujarat', 'lon': '71.9992'},
      '500032': {'lat': '17.3939', 'name': 'Manuu,K.V.Rangareddy,Telangana', 'lon': '78.4529'},
      '829149': {'lat': '23.9778', 'name': 'T.T.P.S.Lalpania,Bokaro,Jharkhand', 'lon': '85.3508'},
      '313601': {'lat': '24.686', 'name': 'Bhatewaar,Udaipur,Rajasthan', 'lon': '74.0285'},
      '146103': {'lat': '31.3678', 'name': 'Bham,Hoshiarpur,Punjab', 'lon': '75.9406'},
      '313603': {'lat': '25.0295', 'name': 'Motida,Udaipur,Rajasthan', 'lon': '74.4635'},
      '313602': {'lat': '24.9909', 'name': 'Batharda Khurd,Udaipur,Rajasthan', 'lon': '74.5442'},
      '313604': {'lat': '25.353', 'name': 'Kali Bhit,Udaipur,Rajasthan', 'lon': '74.9059'},
      '841408': {'lat': '26.046', 'name': 'Sondhani,Siwan,Bihar', 'lon': '84.5414'},
      '824129': {'lat': '25.2492', 'name': 'Karma Pandih,Aurangabad(BH),Bihar', 'lon': '84.2997'},
      '824123': {'lat': '24.6053', 'name': 'K P Tendua,Aurangabad(BH),Bihar', 'lon': '84.2217'},
      '824122': {'lat': '24.8677', 'name': 'Lahasa,Aurangabad(BH),Bihar', 'lon': '84.5347'},
      '824121': {'lat': '25.0421', 'name': 'Dewaria Kala,Aurangabad(BH),Bihar', 'lon': '84.3754'},
      '824120': {'lat': '25.0651', 'name': 'Baghour,Aurangabad(BH),Bihar', 'lon': '84.6433'},
      '824127': {'lat': '25.1616', 'name': 'Sohasa,Arwal,Bihar', 'lon': '84.6904'},
      '824125': {'lat': '24.8168', 'name': 'Seoli Khaira,Aurangabad(BH),Bihar', 'lon': '84.5974'},
      '824124': {'lat': '24.9491', 'name': 'B. Sagarpur,Aurangabad(BH),Bihar', 'lon': '84.4504'},
      '306126': {'lat': '25.7079', 'name': 'Perwa,Pali,Rajasthan', 'lon': '73.5206'},
      '494122': {'lat': '18.2819', 'name': 'Pongabheji,Dantewada,Chattisgarh', 'lon': '81.3938'},
      '488333': {'lat': '24.3341', 'name': 'Kathbariya,Panna,Madhya Pradesh', 'lon': '80.365'},
      '799290': {'lat': '24.0447', 'name': 'Rajkandi,North Tripura,Tripura', 'lon': '91.7553'},
      '506244': {'lat': '17.8315', 'name': 'Aswaraopalli,Warangal,Telangana', 'lon': '79.3211'},
      '303905': {'lat': '26.8215', 'name': 'Mohanpura,Jaipur,Rajasthan', 'lon': '75.7379'},
      '781171': {'lat': '26.184', 'name': 'Patharquerry,Kamrup,Assam', 'lon': '91.4592'},
      '636701': {'lat': '12.1588', 'name': 'Dharmapuri H.O,Dharmapuri,Tamil Nadu', 'lon': '78.1636'},
      '224182': {'lat': '26.4201', 'name': 'Sanaha,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '632326': {'lat': '12.4311', 'name': 'Thatchur,Tiruvannamalai,Tamil Nadu', 'lon': '79.1677'},
      '182313': {'lat': '33.5759', 'name': 'Thanpal,Udhampur,Jammu & Kashmir', 'lon': '74.9725'},
      '182312': {'lat': '33.1595', 'name': 'Jyotipuram,Udhampur,Jammu & Kashmir', 'lon': '74.8304'},
      '182311': {'lat': '32.8822', 'name': 'District office Complex Reasi,Reasi,Jammu & Kashmir', 'lon': '75.0262'},
      '323307': {'lat': '24.8877', 'name': 'Jalkheda,Chittorgarh,Rajasthan', 'lon': '75.4401'},
      '182315': {'lat': '33.323', 'name': 'Deval,Udhampur,Jammu & Kashmir', 'lon': '75.527'},
      '172022': {'lat': '31.4014', 'name': 'Nogli,Shimla,Himachal Pradesh', 'lon': '77.6405'},
      '605401': {'lat': '11.8541', 'name': 'Valudareddy,Villupuram,Tamil Nadu', 'lon': '79.3985'},
      '303904': {'lat': '26.7675', 'name': 'Mohabatpura,Jaipur,Rajasthan', 'lon': '75.7412'},
      '172023': {'lat': '31.4314', 'name': 'Nirmand,Kullu,Himachal Pradesh', 'lon': '77.5725'},
      '249410': {'lat': '29.9035', 'name': 'Sadhubela Bhupatwala,Haridwar,Uttarakhand', 'lon': '78.0797'},
      '249411': {'lat': '29.9035', 'name': 'Shantikunj,Haridwar,Uttarakhand', 'lon': '78.0797'},
      '172021': {'lat': '31.3047', 'name': 'Nankhari,Shimla,Himachal Pradesh', 'lon': '77.5838'},
      '481666': {'lat': '22.8383', 'name': 'Piparia (B),Mandla,Madhya Pradesh', 'lon': '79.3441'},
      '628952': {'lat': '9.3266', 'name': 'Ayyanaruthu,Tuticorin,Tamil Nadu', 'lon': '78.1336'},
      '518112': {'lat': '15.4894', 'name': 'Alamur,Kurnool,Andhra Pradesh', 'lon': '78.3545'},
      '670601': {'lat': '11.9763', 'name': 'Cherupazhassi,Kannur,Kerala', 'lon': '75.4168'},
      '743124': {'lat': '22.8347', 'name': 'ESD (M),North 24 Parganas,West Bengal', 'lon': '88.4655'},
      '247453': {'lat': '29.6827', 'name': 'Shimlana,Saharanpur,Uttar Pradesh', 'lon': '77.5065'},
      '627425': {'lat': '8.4766', 'name': 'Sivanthipuram,Tirunelveli,Tamil Nadu', 'lon': '77.4896'},
      '622412': {'lat': '10.3468', 'name': 'Lembalakudi,Pudukkottai,Tamil Nadu', 'lon': '78.6818'},
      '622411': {'lat': '10.3468', 'name': 'Kesarapatti,Pudukkottai,Tamil Nadu', 'lon': '78.6818'},
      '627426': {'lat': '8.7125', 'name': 'Harikesavanallur,Tirunelveli,Tamil Nadu', 'lon': '77.5149'},
      '627421': {'lat': '8.6805', 'name': 'Manimuthar Project,Tirunelveli,Tamil Nadu', 'lon': '77.4285'},
      '627420': {'lat': '8.6747', 'name': 'Manjolai,Tirunelveli,Tamil Nadu', 'lon': '77.5439'},
      '627423': {'lat': '9.0263', 'name': 'Mudaliarpatti,Tirunelveli,Tamil Nadu', 'lon': '77.7097'},
      '627422': {'lat': '8.6747', 'name': 'Papanasam Mills,Tirunelveli,Tamil Nadu', 'lon': '77.5439'},
      '841422': {'lat': '25.9739', 'name': 'Hafijzpur,Saran,Bihar', 'lon': '84.7764'},
      '172025': {'lat': '31.4062', 'name': 'Sush,Kullu,Himachal Pradesh', 'lon': '77.1252'},
      '689121': {'lat': '9.3205', 'name': 'Chengannur H.O,Alappuzha,Kerala', 'lon': '76.6332'},
      '627428': {'lat': '8.6747', 'name': 'Agasthiyarpatti,Tirunelveli,Tamil Nadu', 'lon': '77.5439'},
      '246455': {'lat': '29.766', 'name': 'Narainbagar,Chamoli,Uttarakhand', 'lon': '79.5522'},
      '680663': {'lat': '10.2442', 'name': 'Pullut North,Thrissur,Kerala', 'lon': '76.2084'},
      '246764': {'lat': '29.4925', 'name': 'Jhilmila,Bijnor,Uttar Pradesh', 'lon': '78.3784'},
      '743127': {'lat': '22.8333', 'name': 'Paltapara,North 24 Parganas,West Bengal', 'lon': '88.3833'},
      '396240': {'lat': '20.2127', 'name': 'Meghval,Valsad,Gujarat', 'lon': '73.03'},
      '576247': {'lat': '13.6767', 'name': 'Gujjadi,Udupi,Karnataka', 'lon': '74.6596'},
      '530018': {'lat': '17.8578', 'name': 'Marripalem,Visakhapatnam,Andhra Pradesh', 'lon': '83.1259'},
      '222129': {'lat': '25.6322', 'name': 'Chitako,Jaunpur,Uttar Pradesh', 'lon': '82.7161'},
      '222128': {'lat': '25.6649', 'name': 'Tarati,Jaunpur,Uttar Pradesh', 'lon': '82.5038'},
      '530011': {'lat': '17.6852', 'name': 'Malkapuram,Visakhapatnam,Andhra Pradesh', 'lon': '83.2437'},
      '530013': {'lat': '17.8817', 'name': 'P & T Colony (VM),Visakhapatnam,Andhra Pradesh', 'lon': '83.3409'},
      '530012': {'lat': '17.8817', 'name': 'Sheelanagar,Visakhapatnam,Andhra Pradesh', 'lon': '83.3409'},
      '530015': {'lat': '17.8817', 'name': 'Zinc Smelter P,Visakhapatnam,Andhra Pradesh', 'lon': '83.3409'},
      '530014': {'lat': '17.8817', 'name': 'Naval Dock Yard,Visakhapatnam,Andhra Pradesh', 'lon': '83.3409'},
      '530017': {'lat': '17.8817', 'name': 'M.V.P.Colony,Visakhapatnam,Andhra Pradesh', 'lon': '83.3409'},
      '530016': {'lat': '18.1021', 'name': 'New Colony,Visakhapatnam,Andhra Pradesh', 'lon': '83.6531'},
      '832304': {'lat': '22.6575', 'name': 'Barakurshi,East Singhbhum,Jharkhand', 'lon': '86.426'},
      '814149': {'lat': '24.0766', 'name': 'Charak Mara,Deoghar,Jharkhand', 'lon': '86.4085'},
      '362120': {'lat': '21.3419', 'name': 'Ratang,Junagadh,Gujarat', 'lon': '70.6971'},
      '401609': {'lat': '19.7623', 'name': 'Suryanagar (Thane),Thane,Maharashtra', 'lon': '73.5785'},
      '401608': {'lat': '19.7623', 'name': 'Dahanu TPS,Thane,Maharashtra', 'lon': '73.5785'},
      '841287': {'lat': '26.1608', 'name': 'Kandhpakar,Siwan,Bihar', 'lon': '84.3895'},
      '841286': {'lat': '26.1228', 'name': 'Bakerganj,Siwan,Bihar', 'lon': '84.3655'},
      '363655': {'lat': '22.5469', 'name': 'Mavnagam,Jamnagar,Gujarat', 'lon': '70.3819'},
      '612804': {'lat': '10.9501', 'name': 'Thillaiyambur,Thanjavur,Tamil Nadu', 'lon': '79.3358'},
      '533285': {'lat': '17.5013', 'name': 'Soorampalem,East Godavari,Andhra Pradesh', 'lon': '82.199'},
      '363650': {'lat': '22.6562', 'name': 'Jabalpur,Rajkot,Gujarat', 'lon': '70.7494'},
      '533287': {'lat': '17.211', 'name': 'Vangalapudi,East Godavari,Andhra Pradesh', 'lon': '81.8908'},
      '533286': {'lat': '17.3638', 'name': 'Chinabhimpalli,East Godavari,Andhra Pradesh', 'lon': '81.9117'},
      '533289': {'lat': '16.8221', 'name': 'Vedurupaka,East Godavari,Andhra Pradesh', 'lon': '81.221'},
      '533288': {'lat': '17.4585', 'name': 'Rampa,East Godavari,Andhra Pradesh', 'lon': '81.7836'},
      '737111': {'lat': '27.2635', 'name': 'Yangtey,West Sikkim,Sikkim', 'lon': '88.2548'},
      '832301': {'lat': '22.53044', 'name': 'Lodhasholi,East Singhbhum,Jharkhand', 'lon': '86.7054'},
      '832302': {'lat': '23.6576', 'name': 'Kanas,East Singhbhum,Jharkhand', 'lon': '86.7861'},
      '284401': {'lat': '25.1004', 'name': 'Babina Cantt.,Jhansi,Uttar Pradesh', 'lon': '78.4243'},
      '466554': {'lat': '22.8667', 'name': 'Jait,Sehore,Madhya Pradesh', 'lon': '77.8448'},
      '832303': {'lat': '23.2668', 'name': 'Barajuri,East Singhbhum,Jharkhand', 'lon': '86.2339'},
      '495455': {'lat': '22.345', 'name': 'Balgi Project Korba,Korba,Chattisgarh', 'lon': '82.6025'},
      '495454': {'lat': '22.3335', 'name': 'Pandania,Korba,Chattisgarh', 'lon': '82.6735'},
      '495452': {'lat': '22.345', 'name': 'Wcl Gevra Project,Korba,Chattisgarh', 'lon': '82.6025'},
      '495450': {'lat': '22.3489', 'name': 'Vikas Bhawan,Korba,Chattisgarh', 'lon': '82.5788'},
      '517391': {'lat': '13.9814', 'name': 'T.Sodam,Chittoor,Andhra Pradesh', 'lon': '78.0393'},
      '517390': {'lat': '14.1723', 'name': 'T.Sowdasamudram,Chittoor,Andhra Pradesh', 'lon': '78.7348'},
      '476444': {'lat': '26.0197', 'name': 'Rithorakalan,Morena,Madhya Pradesh', 'lon': '78.4143'},
      '230405': {'lat': '26.0062', 'name': 'Darchhut,Pratapgarh,Uttar Pradesh', 'lon': '82.1305'},
      '284405': {'lat': '24.7069', 'name': 'Bamhori Bahadur Singh,Lalitpur,Uttar Pradesh', 'lon': '78.7598'},
      '762106': {'lat': '19.9672', 'name': 'Katadi,Kandhamal,Odisha', 'lon': '83.9003'},
      '762107': {'lat': '19.9374', 'name': 'Sirla,Kandhamal,Odisha', 'lon': '83.6355'},
      '762104': {'lat': '19.8453', 'name': 'Bhramarabadi,Kandhamal,Odisha', 'lon': '83.7024'},
      '762105': {'lat': '20.0224', 'name': 'Paikadharakote,Kandhamal,Odisha', 'lon': '83.9167'},
      '762102': {'lat': '20.1998', 'name': 'Nuagaon (Kandhamal),Kandhamal,Odisha', 'lon': '83.6525'},
      '762103': {'lat': '20.0627', 'name': 'Demingia,Kandhamal,Odisha', 'lon': '84.0975'},
      '762100': {'lat': '20.3543', 'name': 'Gadaguda,Kandhamal,Odisha', 'lon': '84.4515'},
      '762101': {'lat': '20.1182', 'name': 'Chanchedi,Kandhamal,Odisha', 'lon': '84.5795'},
      '230403': {'lat': '26.0994', 'name': 'Madhoganj (Pratapgarh),Pratapgarh,Uttar Pradesh', 'lon': '82.0107'},
      '757001': {'lat': '21.5242', 'name': 'Baripada R.S.,Mayurbhanj,Odisha', 'lon': '85.6169'},
      '762109': {'lat': '19.9585', 'name': 'Mediakia,Kandhamal,Odisha', 'lon': '83.7268'},
      '230401': {'lat': '26.0616', 'name': 'Sarai Rajai,Pratapgarh,Uttar Pradesh', 'lon': '82.111'},
      '600059': {'lat': '13.0252', 'name': 'LIC Colony Tambaram,Kanchipuram,Tamil Nadu', 'lon': '79.8562'},
      '600058': {'lat': '13.0252', 'name': 'Athipattu,Tiruvallur,Tamil Nadu', 'lon': '79.8562'},
      '600057': {'lat': '13.0252', 'name': 'Ennore Thermal Station,Tiruvallur,Tamil Nadu', 'lon': '79.8562'},
      '600056': {'lat': '13.0384', 'name': 'Iyyappanthangal,Kanchipuram,Tamil Nadu', 'lon': '79.8277'},
      '600055': {'lat': '13.1547', 'name': 'CRP Camp,Tiruvallur,Tamil Nadu', 'lon': '80.0694'},
      '600054': {'lat': '13.1147', 'name': 'Tank Factory Avadi,Tiruvallur,Tamil Nadu', 'lon': '80.1098'},
      '600053': {'lat': '13.2431', 'name': 'Oragadam,Tiruvallur,Tamil Nadu', 'lon': '79.94'},
      '600052': {'lat': '12.9674', 'name': 'Boochiathipattu,Tiruvallur,Tamil Nadu', 'lon': '79.4441'},
      '600051': {'lat': '13.1501', 'name': 'Madhavaram Milk Colony,Tiruvallur,Tamil Nadu', 'lon': '80.242'},
      '600050': {'lat': '12.9659', 'name': 'Jagadambigainagar,Tiruvallur,Tamil Nadu', 'lon': '79.7332'},
      '517643': {'lat': '13.526', 'name': 'Kovanur,Chittoor,Andhra Pradesh', 'lon': '79.3952'},
      '517642': {'lat': '13.7771', 'name': 'Peddakannali,Chittoor,Andhra Pradesh', 'lon': '79.7324'},
      '517641': {'lat': '13.7137', 'name': 'Pullareddikandriga,Chittoor,Andhra Pradesh', 'lon': '79.5556'},
      '517640': {'lat': '13.3625', 'name': 'Aravakothur,Chittoor,Andhra Pradesh', 'lon': '79.3552'},
      '517131': {'lat': '13.851', 'name': 'Puthur,Chittoor,Andhra Pradesh', 'lon': '78.8733'},
      '517130': {'lat': '13.4103', 'name': 'Govindareddipalle,Chittoor,Andhra Pradesh', 'lon': '78.9128'},
      '517645': {'lat': '13.7095', 'name': 'Buchinaidu Kandriga,Chittoor,Andhra Pradesh', 'lon': '79.8331'},
      '517644': {'lat': '13.6365', 'name': 'Srikalahasti North,Chittoor,Andhra Pradesh', 'lon': '79.572'},
      '695132': {'lat': '8.6828', 'name': 'Chenkal,Thiruvananthapuram,Kerala', 'lon': '76.8395'},
      '695133': {'lat': '8.6828', 'name': 'Mulluvila,Thiruvananthapuram,Kerala', 'lon': '76.8395'},
      '608601': {'lat': '11.4486', 'name': 'Manjakollai,Cuddalore,Tamil Nadu', 'lon': '79.6056'},
      '695134': {'lat': '8.6828', 'name': 'Vlathankara,Thiruvananthapuram,Kerala', 'lon': '76.8395'},
      '713211': {'lat': '24.1082', 'name': 'Durgapur Sagarbhanga Colony,Paschim Bardhaman,West Bengal', 'lon': '87.9171'},
      '742166': {'lat': '23.6798', 'name': 'Swaruppur,Murshidabad,West Bengal', 'lon': '88.1155'},
      '691331': {'lat': '9.0142', 'name': 'Shaliacarry Estate,Kollam,Kerala', 'lon': '76.884'},
      '691332': {'lat': '9.0406', 'name': 'Kariyara,Kollam,Kerala', 'lon': '76.8781'},
      '691333': {'lat': '8.9878', 'name': 'Tholicode,Kollam,Kerala', 'lon': '76.89'},
      '691334': {'lat': '9.0142', 'name': 'Nedungolam,Kollam,Kerala', 'lon': '76.884'},
      '612104': {'lat': '10.9763', 'name': 'Tiruvidamarudur,Thanjavur,Tamil Nadu', 'lon': '79.4545'},
      '388239': {'lat': '23.008', 'name': 'Wanakbori .T.P.S.,Kheda,Gujarat', 'lon': '74.1142'},
      '636406': {'lat': '11.8219', 'name': 'Mettur Thermal Project,Salem,Tamil Nadu', 'lon': '77.8601'},
      '636401': {'lat': '11.8219', 'name': 'Metturdam,Salem,Tamil Nadu', 'lon': '77.8601'},
      '636403': {'lat': '11.8219', 'name': 'Pudusampalli,Salem,Tamil Nadu', 'lon': '77.8601'},
      '636402': {'lat': '11.8219', 'name': 'Metturdam RS,Salem,Tamil Nadu', 'lon': '77.8601'},
      '388230': {'lat': '23.008', 'name': 'Chitlav,Kheda,Gujarat', 'lon': '74.1142'},
      '471315': {'lat': '24.5713', 'name': 'Nandgain Kal,Chhatarpur,Madhya Pradesh', 'lon': '79.2643'},
      '388235': {'lat': '23.008', 'name': 'Wanakbori,Kheda,Gujarat', 'lon': '74.1142'},
      '471311': {'lat': '24.4914', 'name': 'Sendpa,Chhatarpur,Madhya Pradesh', 'lon': '79.2819'},
      '173212': {'lat': '31.0916', 'name': 'RR Solan,Solan,Himachal Pradesh', 'lon': '77.2657'},
      '394810': {'lat': '22.4533', 'name': 'Dhamrad,Bharuch,Gujarat', 'lon': '73.1559'},
      '173210': {'lat': '30.8843', 'name': 'Dagshai,Solan,Himachal Pradesh', 'lon': '77.0523'},
      '173211': {'lat': '30.7381', 'name': 'Barog R.S.B.O,Solan,Himachal Pradesh', 'lon': '77.089'},
      '523316': {'lat': '15.5381', 'name': 'Markapur Bazar,Prakasam,Andhra Pradesh', 'lon': '79.2958'},
      '173217': {'lat': '30.9534', 'name': 'Dubloo,Shimla,Himachal Pradesh', 'lon': '77.2115'},
      '173214': {'lat': '30.9635', 'name': 'Kasauli MT,Solan,Himachal Pradesh', 'lon': '77.1628'},
      '173215': {'lat': '30.9778', 'name': 'Kawarag,Solan,Himachal Pradesh', 'lon': '77.1222'},
      '144012': {'lat': '31.1191', 'name': 'Reru,Jalandhar,Punjab', 'lon': '75.487'},
      '173218': {'lat': '30.9022', 'name': 'Manlogkalan,Solan,Himachal Pradesh', 'lon': '77.2256'},
      '639120': {'lat': '10.8352', 'name': 'Chinniyampalayam,Karur,Tamil Nadu', 'lon': '78.4017'},
      '365550': {'lat': '21.4962', 'name': 'Raydi,Amreli,Gujarat', 'lon': '70.9789'},
      '847428': {'lat': '25.6053', 'name': 'Bharathi,Darbhanga,Bihar', 'lon': '85.9924'},
      '365555': {'lat': '23.2906', 'name': 'Rabhda,Amreli,Gujarat', 'lon': '72.9648'},
      '635813': {'lat': '12.8031', 'name': 'Sembedu,Vellore,Tamil Nadu', 'lon': '79.383'},
      '635812': {'lat': '12.8492', 'name': 'Vadapudupet,Vellore,Tamil Nadu', 'lon': '78.8007'},
      '635811': {'lat': '13.0087', 'name': 'Arangaldurgam,Vellore,Tamil Nadu', 'lon': '79.0271'},
      '635810': {'lat': '12.8633', 'name': 'Thottithuraimottur,Vellore,Tamil Nadu', 'lon': '78.7247'},
      '847424': {'lat': '26.2378', 'name': 'Sakhwar,Darbhanga,Bihar', 'lon': '86.1743'},
      '635815': {'lat': '12.8247', 'name': 'Athurkuppam,Vellore,Tamil Nadu', 'lon': '78.9121'},
      '635814': {'lat': '12.1981', 'name': 'Shanankuppam,Vellore,Tamil Nadu', 'lon': '79.81'},
      '370110': {'lat': '23.1132', 'name': 'Varsamedi,Kachchh,Gujarat', 'lon': '70.0267'},
      '370115': {'lat': '23.1132', 'name': 'Dhamadka,Kachchh,Gujarat', 'lon': '70.0267'},
      '247671': {'lat': '29.6883', 'name': 'Brahmpur Khanpur,Haridwar,Uttarakhand', 'lon': '78.0892'},
      '713214': {'lat': '23.8798', 'name': 'Durgapur CRPF Camp,Purba Bardhaman,West Bengal', 'lon': '87.5259'},
      '227812': {'lat': '26.2346', 'name': 'Saraiya Mafi,Sultanpur,Uttar Pradesh', 'lon': '81.695'},
      '144526': {'lat': '31.6658', 'name': 'Mohar,Nawanshahr,Punjab', 'lon': '75.9047'},
      '144527': {'lat': '31.3019', 'name': 'Garhi Matton,Nawanshahr,Punjab', 'lon': '76.0615'},
      '144524': {'lat': '31.5926', 'name': 'Pojewal,Nawanshahr,Punjab', 'lon': '75.8816'},
      '144525': {'lat': '30.4984', 'name': 'Chandiani Khurd,Nawanshahr,Punjab', 'lon': '76.1558'},
      '144522': {'lat': '31.1141', 'name': 'Nighi,Nawanshahr,Punjab', 'lon': '75.95'},
      '144523': {'lat': '31.2911', 'name': 'Haibowal,Hoshiarpur,Punjab', 'lon': '75.9175'},
      '144520': {'lat': '31.2893', 'name': 'Thowana,Hoshiarpur,Punjab', 'lon': '75.9947'},
      '144521': {'lat': '31.3098', 'name': 'Thopia,Nawanshahr,Punjab', 'lon': '76.0114'},
      '229408': {'lat': '25.7806', 'name': 'Shayaad Yasinpur,Pratapgarh,Uttar Pradesh', 'lon': '81.4106'},
      '144528': {'lat': '31.6479', 'name': 'Kukkran,Hoshiarpur,Punjab', 'lon': '75.7418'},
      '394720': {'lat': '20.5784', 'name': 'Gotiamal,The Dangs,Gujarat', 'lon': '73.7507'},
      '131039': {'lat': '29.098', 'name': 'Kurar Brahimpur,Sonipat,Haryana', 'lon': '77.0684'},
      '227816': {'lat': '26.202', 'name': 'Mau Atwara,Sultanpur,Uttar Pradesh', 'lon': '81.9103'},
      '131030': {'lat': '29.098', 'name': 'TDI name Kundli,Sonipat,Haryana', 'lon': '77.0684'},
      '389120': {'lat': '23.2428', 'name': 'Mojari,Panch Mahals,Gujarat', 'lon': '73.6137'},
      '851215': {'lat': '25.5122', 'name': 'Jorawarpur,Khagaria,Bihar', 'lon': '86.6031'},
      '851214': {'lat': '25.5468', 'name': 'Balha ,Khagaria,Bihar', 'lon': '86.5683'},
      '851217': {'lat': '25.4977', 'name': 'Phulmallik,Begusarai,Bihar', 'lon': '86.3942'},
      '629166': {'lat': '8.3099', 'name': 'Chemparuthivilai,Kanyakumari,Tamil Nadu', 'lon': '77.2568'},
      '851211': {'lat': '25.5351', 'name': 'Mahipa Tol,Begusarai,Bihar', 'lon': '86.3692'},
      '851210': {'lat': '25.4189', 'name': 'Mahna,Begusarai,Bihar', 'lon': '86.0433'},
      '851213': {'lat': '25.3855', 'name': 'Patel Nagar Bharra,Khagaria,Bihar', 'lon': '86.4149'},
      '614714': {'lat': '10.4292', 'name': 'Annapettai,Nagapattinam,Tamil Nadu', 'lon': '79.6498'},
      '493114': {'lat': '21.5599', 'name': 'Neora (Raipur),Raipur,Chattisgarh', 'lon': '81.8137'},
      '493116': {'lat': '21.4333', 'name': 'Manohara,Raipur,Chattisgarh', 'lon': '81.7333'},
      '804419': {'lat': '25.0168', 'name': 'Keyal,Arwal,Bihar', 'lon': '84.8306'},
      '614717': {'lat': '10.6041', 'name': 'Adichapuram,Tiruvarur,Tamil Nadu', 'lon': '79.5264'},
      '796161': {'lat': '23.5708', 'name': 'Tlungvel,Aizawl,Mizoram', 'lon': '92.8903'},
      '493113': {'lat': '21.6432', 'name': 'Jangda,Raipur,Chattisgarh', 'lon': '81.835'},
      '711408': {'lat': '22.5866', 'name': 'Mato,Howrah,West Bengal', 'lon': '88.1876'},
      '212302': {'lat': '25.1369', 'name': 'Dari,Allahabad,Uttar Pradesh', 'lon': '82.0785'},
      '614716': {'lat': '10.5032', 'name': 'Pichankottagam,Tiruvarur,Tamil Nadu', 'lon': '79.6609'},
      '783339': {'lat': '26.3833', 'name': 'Jharnerchar,Dhubri,Assam', 'lon': '91.9667'},
      '212306': {'lat': '24.9067', 'name': 'Chiraon,Allahabad,Uttar Pradesh', 'lon': '81.9162'},
      '212307': {'lat': '25.2589', 'name': 'Ghodedeeh,Allahabad,Uttar Pradesh', 'lon': '82.0128'},
      '783334': {'lat': '26.1512', 'name': 'Barundanga,Dhubri,Assam', 'lon': '89.8416'},
      '783335': {'lat': '26.271', 'name': 'Satrasal,Dhubri,Assam', 'lon': '89.7904'},
      '783336': {'lat': '26.4267', 'name': 'Gambaribil,Kokrajhar,Assam', 'lon': '90.0688'},
      '783337': {'lat': '26.3033', 'name': 'Bashbari,Dhubri,Assam', 'lon': '90.0997'},
      '783330': {'lat': '26.0345', 'name': 'Madartari,Dhubri,Assam', 'lon': '90.2553'},
      '783331': {'lat': '26.1205', 'name': 'South Geramari,Dhubri,Assam', 'lon': '89.9511'},
      '783332': {'lat': '26.3338', 'name': 'Srinagar,Kokrajhar,Assam', 'lon': '89.8459'},
      '783333': {'lat': '26.5145', 'name': 'Grahampur,Kokrajhar,Assam', 'lon': '90.1306'},
      '383325': {'lat': '23.6593', 'name': 'Radodara,Sabarkantha,Gujarat', 'lon': '73.1637'},
      '505208': {'lat': '18.7538', 'name': 'Peddampet,Karim Nagar,Telangana', 'lon': '79.4518'},
      '614713': {'lat': '10.6133', 'name': 'Tiruturaipundi H.O,Tiruvarur,Tamil Nadu', 'lon': '79.326'},
      '383320': {'lat': '23.8167', 'name': 'Davli,Sabarkantha,Gujarat', 'lon': '73.1072'},
      '743129': {'lat': '22.8347', 'name': 'Fingapara,North 24 Parganas,West Bengal', 'lon': '88.4655'},
      '642203': {'lat': '10.8265', 'name': 'Jothampatti,Coimbatore,Tamil Nadu', 'lon': '77.5598'},
      '452014': {'lat': '22.6984', 'name': 'Khatiwala Tank,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '612105': {'lat': '11.0033', 'name': 'Tiruvisalur,Thanjavur,Tamil Nadu', 'lon': '79.428'},
      '721153': {'lat': '22.4586', 'name': 'Kultikri-ghatal,East Midnapore,West Bengal', 'lon': '87.7745'},
      '782429': {'lat': '26.3504', 'name': 'Sibpur,Nagaon,Assam', 'lon': '93.1671'},
      '782428': {'lat': '26.1015', 'name': 'Barapatia,Nagaon,Assam', 'lon': '92.7426'},
      '782427': {'lat': '26.3504', 'name': 'Rengbeng,Nagaon,Assam', 'lon': '93.1671'},
      '782426': {'lat': '26.5501', 'name': 'Puthimari Bazar,Nagaon,Assam', 'lon': '93.7041'},
      '782425': {'lat': '26.1998', 'name': 'Bargaon,Karbi Anglon,Assam', 'lon': '92.5175'},
      '629164': {'lat': '8.2922', 'name': 'Mukkampala,Kanyakumari,Tamil Nadu', 'lon': '77.3215'},
      '509209': {'lat': '16.5281', 'name': 'Yendabetla,Mahabub Nagar,Telangana', 'lon': '78.3517'},
      '522401': {'lat': '16.2843', 'name': 'Siripuram (Guntur),Guntur,Andhra Pradesh', 'lon': '80.6945'},
      '452010': {'lat': '22.6984', 'name': 'Vijay Nagar,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '522403': {'lat': '16.3724', 'name': 'Gandhi Chowk (Guntur),Guntur,Andhra Pradesh', 'lon': '80.1908'},
      '522402': {'lat': '16.4828', 'name': 'Lagadapadu,Guntur,Andhra Pradesh', 'lon': '80.2278'},
      '603127': {'lat': '12.5436', 'name': 'Anupuram,Kanchipuram,Tamil Nadu', 'lon': '79.9929'},
      '452011': {'lat': '22.6984', 'name': 'Indore R.S.S.Nagar,Indore,Madhya Pradesh', 'lon': '75.9292'},
      '522409': {'lat': '16.1322', 'name': 'Kogantivaripalem,Guntur,Andhra Pradesh', 'lon': '79.9043'},
      '522408': {'lat': '16.3167', 'name': 'Narnepadu,Guntur,Andhra Pradesh', 'lon': '80.1419'},
      '521215': {'lat': '16.9335', 'name': 'Kunaparajaparva,Krishna,Andhra Pradesh', 'lon': '80.7572'},
      '521214': {'lat': '16.9814', 'name': 'Polavaram,Krishna,Andhra Pradesh', 'lon': '80.9136'},
      '521211': {'lat': '17.3619', 'name': 'Seetharampuram,Krishna,Andhra Pradesh', 'lon': '82.0551'},
      '503188': {'lat': '18.5891', 'name': 'Raikur,Nizamabad,Telangana', 'lon': '77.873'},
      '521213': {'lat': '16.9057', 'name': 'Gullapudi,Krishna,Andhra Pradesh', 'lon': '80.9023'},
      '521212': {'lat': '16.1574', 'name': 'Surampalli,Krishna,Andhra Pradesh', 'lon': '80.2761'},
      '742161': {'lat': '24.1251', 'name': 'Kurnnarun,Murshidabad,West Bengal', 'lon': '87.9561'},
      '403406': {'lat': '15.4118', 'name': 'Tisca,South Goa,Goa', 'lon': '74.1171'},
      '503187': {'lat': '18.3428', 'name': 'Singitham,Nizamabad,Telangana', 'lon': '78.0065'},
      '403404': {'lat': '15.1964', 'name': 'Priol,South Goa,Goa', 'lon': '74.1184'},
      '403403': {'lat': '15.5297', 'name': 'Piedade,North Goa,Goa', 'lon': '73.9106'},
      '629165': {'lat': '8.3081', 'name': 'Marthandam North,Kanyakumari,Tamil Nadu', 'lon': '77.2214'},
      '403401': {'lat': '15.4035', 'name': 'Agapur Adpoi,South Goa,Goa', 'lon': '74.0095'},
      '612802': {'lat': '10.6716', 'name': 'Kalathur,Thanjavur,Tamil Nadu', 'lon': '79.2413'},
      '610207': {'lat': '10.6519', 'name': 'Koorathangudi,Nagapattinam,Tamil Nadu', 'lon': '79.6929'},
      '733209': {'lat': '26.0021', 'name': 'Hassan,North Dinajpur,West Bengal', 'lon': '87.888'},
      '303807': {'lat': '27.0323', 'name': 'Udaipuria,Jaipur,Rajasthan', 'lon': '75.804'},
      '632401': {'lat': '12.934', 'name': 'Ranipet Bazar,Vellore,Tamil Nadu', 'lon': '79.335'},
      '632403': {'lat': '12.9474', 'name': 'Ranipet Industrial Estate,Vellore,Tamil Nadu', 'lon': '79.317'},
      '632405': {'lat': '12.9707', 'name': 'Ekambaranallur,Vellore,Tamil Nadu', 'lon': '79.3116'},
      '303804': {'lat': '27.1542', 'name': 'Tigaria,Jaipur,Rajasthan', 'lon': '76.0418'},
      '733201': {'lat': '25.3471', 'name': 'Magnavita,North Dinajpur,West Bengal', 'lon': '87.8817'},
      '632406': {'lat': '12.9474', 'name': 'Bhel Ranipet,Vellore,Tamil Nadu', 'lon': '79.317'},
      '572224': {'lat': '13.4588', 'name': 'Bajagur,Tumakuru,Karnataka', 'lon': '76.6538'},
      '572225': {'lat': '12.9272', 'name': 'Samige Road Railway Station.,Tumakuru,Karnataka', 'lon': '75.8339'},
      '572226': {'lat': '12.9903', 'name': 'Navile,Tumakuru,Karnataka', 'lon': '76.7671'},
      '572227': {'lat': '13.501', 'name': 'Gonitumkur,Tumakuru,Karnataka', 'lon': '76.1666'},
      '572220': {'lat': '13.3731', 'name': 'K.Kallahalli,Tumakuru,Karnataka', 'lon': '76.4112'},
      '572221': {'lat': '13.364', 'name': 'Vittalapura,Tumakuru,Karnataka', 'lon': '76.6639'},
      '572222': {'lat': '13.076', 'name': 'Alalaghatta,Tumakuru,Karnataka', 'lon': '76.4291'},
      '572223': {'lat': '12.98', 'name': 'Haradagere,Tumakuru,Karnataka', 'lon': '76.8387'},
      '759019': {'lat': '20.8615', 'name': 'Bedapada,Dhenkanal,Odisha', 'lon': '85.362'},
      '572228': {'lat': '13.5202', 'name': 'Thimmanahalli,Tumakuru,Karnataka', 'lon': '76.9477'},
      '796571': {'lat': '22.9403', 'name': 'Tarpho,Lunglei,Mizoram', 'lon': '92.9651'},
      '303803': {'lat': '27.3323', 'name': 'Govindpura Baseri,Jaipur,Rajasthan', 'lon': '75.6958'},
      '522549': {'lat': '16.2749', 'name': 'Sankurathripadu,Guntur,Andhra Pradesh', 'lon': '80.0588'},
      '610206': {'lat': '10.6836', 'name': 'Vadapathimangalam,Tiruvarur,Tamil Nadu', 'lon': '79.5978'},
      '313011': {'lat': '24.7282', 'name': 'Thoor,Udaipur,Rajasthan', 'lon': '73.9656'},
      '713373': {'lat': '24.2636', 'name': 'Nischinta,Paschim Bardhaman,West Bengal', 'lon': '87.867'},
      '630001': {'lat': '10.0582', 'name': 'Muthupatnam,Sivaganga,Tamil Nadu', 'lon': '78.7645'},
      '485774': {'lat': '24.375', 'name': 'Bhenda,Satna,Madhya Pradesh', 'lon': '80.9424'},
      '713372': {'lat': '24.2636', 'name': 'Radhanagar Rly Colony,Paschim Bardhaman,West Bengal', 'lon': '87.867'},
      '507116': {'lat': '18.0573', 'name': 'Jaggaram,Khammam,Telangana', 'lon': '80.5277'},
      '303008': {'lat': '26.9805', 'name': 'Khudiyala,Jaipur,Rajasthan', 'lon': '75.7243'},
      '313015': {'lat': '24.4519', 'name': 'Umaramines,Udaipur,Rajasthan', 'lon': '73.8266'},
      '610205': {'lat': '10.6598', 'name': 'Keeralathur,Tiruvarur,Tamil Nadu', 'lon': '79.6237'},
      '263001': {'lat': '29.6508', 'name': 'Pangoot,Nainital,Uttarakhand', 'lon': '79.5286'},
      '612101': {'lat': '11.0154', 'name': 'Aduthurai,Thanjavur,Tamil Nadu', 'lon': '79.4809'},
      '713376': {'lat': '24.2636', 'name': 'Dhandadihi,Purba Bardhaman,West Bengal', 'lon': '87.867'},
      '143504': {'lat': '31.7273', 'name': 'Shahzada,Amritsar,Punjab', 'lon': '75.0839'},
      '847429': {'lat': '26.1406', 'name': 'Sinuar Gopal,Darbhanga,Bihar', 'lon': '86.2562'},
      '628004': {'lat': '8.5782', 'name': 'Tt. Thermal Power Plant,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '243002': {'lat': '28.3035', 'name': 'Mundia Ahamad Nagar,Bareilly,Uttar Pradesh', 'lon': '79.3254'},
      '212631': {'lat': '25.8393', 'name': 'Gauri Aura,Fatehpur,Uttar Pradesh', 'lon': '80.762'},
      '637406': {'lat': '11.3393', 'name': 'Oduvankurichi,Namakkal,Tamil Nadu', 'lon': '78.1381'},
      '507119': {'lat': '18.0476', 'name': 'Rudrampur,Khammam,Telangana', 'lon': '80.281'},
      '816101': {'lat': '24.6552', 'name': 'Chandsaher,Sahibganj,Jharkhand', 'lon': '87.7409'},
      '628005': {'lat': '8.5782', 'name': 'Athimarapatti,Tuticorin,Tamil Nadu', 'lon': '78.0462'},
      '148103': {'lat': '30.4018', 'name': 'Cheema,Barnala,Punjab', 'lon': '75.4441'},
      '816103': {'lat': '24.3424', 'name': 'Sarasdangal,Dumka,Jharkhand', 'lon': '87.2641'},
      '637407': {'lat': '11.5211', 'name': 'Vadugam Muniappampalayam,Namakkal,Tamil Nadu', 'lon': '78.1308'},
      '610203': {'lat': '10.5818', 'name': 'Ponnirai,Tiruvarur,Tamil Nadu', 'lon': '79.6384'},
      '135133': {'lat': '30.0802', 'name': 'Bhagu Majra,Yamuna Nagar,Haryana', 'lon': '77.1341'},
      '829107': {'lat': '24.359', 'name': 'Kanjkiro,Bokaro,Jharkhand', 'lon': '84.6553'},
      '422502': {'lat': '20.4035', 'name': 'Vinchuri-dalvi,Nashik,Maharashtra', 'lon': '75.473'},
      '422501': {'lat': '20.4035', 'name': 'Devlali Air Force,Nashik,Maharashtra', 'lon': '75.473'},
      '711401': {'lat': '22.5211', 'name': 'Kankrol,Howrah,West Bengal', 'lon': '88.1398'},
      '829104': {'lat': '23.7878', 'name': 'Bermo,Bokaro,Jharkhand', 'lon': '85.9353'},
      '518583': {'lat': '15.3951', 'name': 'Boyalakuntla,Kurnool,Andhra Pradesh', 'lon': '78.5786'},
      '143506': {'lat': '31.7328', 'name': 'Jaito Sarja,Gurdaspur,Punjab', 'lon': '75.2836'},
      '816107': {'lat': '24.6508', 'name': 'Pakur RS,Dumka,Jharkhand', 'lon': '87.8033'},
      '628002': {'lat': '8.7481', 'name': 'Therkuvirapandiapuram,Tuticorin,Tamil Nadu', 'lon': '78.0333'},
      '396067': {'lat': '20.325', 'name': 'Vadoli,Valsad,Gujarat', 'lon': '73.1744'},
      '396065': {'lat': '20.325', 'name': 'Arnai,Valsad,Gujarat', 'lon': '73.1744'},
      '396060': {'lat': '20.325', 'name': 'Mandavkhadak,Navsari,Gujarat', 'lon': '73.1744'},
      '500066': {'lat': '17.3568', 'name': 'High Court (Hyderabad),Hyderabad,Telangana', 'lon': '78.4524'},
      '451001': {'lat': '21.810756', 'name': 'Balwadi,West Nimar,Madhya Pradesh', 'lon': '75.5593212'},
      '325224': {'lat': '24.8757', 'name': 'Badipura,Baran,Rajasthan', 'lon': '76.8767'},
      '451335': {'lat': '22.19915', 'name': 'Rehgaon,West Nimar,Madhya Pradesh', 'lon': '75.75295'},
      '682051': {'lat': '10.1131', 'name': 'Kochi Parcel Centre,Ernakulam,Kerala', 'lon': '76.2858'},
      '682050': {'lat': '10.1131', 'name': 'International Business Centre,Ernakulam,Kerala', 'lon': '76.2858'},
      '325220': {'lat': '24.7348', 'name': 'Pachpada,Baran,Rajasthan', 'lon': '76.8807'},
      '380007': {'lat': '23.0333', 'name': 'Narayannagar,Ahmedabad,Gujarat', 'lon': '72.6181'},
      '500064': {'lat': '17.3568', 'name': 'Tadbun,Hyderabad,Telangana', 'lon': '78.4524'},
      '795009': {'lat': '24.4065', 'name': 'Haoreibi,Imphal West,Manipur', 'lon': '93.3452'},
      '795008': {'lat': '24.4065', 'name': 'Khongangpheidekpi,Imphal East,Manipur', 'lon': '93.3452'},
      '795005': {'lat': '24.4065', 'name': 'Khurai Khongnang Makhong,Imphal East,Manipur', 'lon': '93.3452'},
      '795004': {'lat': '24.4065', 'name': 'Langol Housing Complex,Imphal West,Manipur', 'lon': '93.3452'},
      '795007': {'lat': '25.3034', 'name': 'Lairouching,Senapati,Manipur', 'lon': '94.044'},
      '795006': {'lat': '24.2271', 'name': 'Leijangphai,Churachandpur,Manipur', 'lon': '93.2055'},
      '795001': {'lat': '24.4065', 'name': 'Imphal Bazar,Imphal West,Manipur', 'lon': '93.3452'},
      '795003': {'lat': '24.4065', 'name': 'Manipur University,Imphal East,Manipur', 'lon': '93.3452'},
      '795002': {'lat': '24.4065', 'name': 'Loitang Leikinthabi,Imphal East,Manipur', 'lon': '93.3452'},
      '678704': {'lat': '10.4848', 'name': 'Chittilancheri,Palakkad,Kerala', 'lon': '76.6128'},
      '678705': {'lat': '10.5909', 'name': 'Mudappallur,Palakkad,Kerala', 'lon': '76.5173'},
      '678706': {'lat': '10.4848', 'name': 'Vandali,Palakkad,Kerala', 'lon': '76.6128'},
      '678701': {'lat': '10.7946', 'name': 'Kannadi,Palakkad,Kerala', 'lon': '76.5586'},
      '678702': {'lat': '10.6456', 'name': 'Kozhalmannam Agraharam,Palakkad,Kerala', 'lon': '76.4654'},
      '678703': {'lat': '10.4848', 'name': 'Cheramangalam,Palakkad,Kerala', 'lon': '76.6128'},
      '610201': {'lat': '11.2578', 'name': 'Kachanam,Tiruvarur,Tamil Nadu', 'lon': '79.6343'},
      '802220': {'lat': '25.1578', 'name': 'Sioruan,Rohtas,Bihar', 'lon': '84.1822'},
      '561101': {'lat': '12.8801', 'name': 'Adarangi,Bangalore Rural,Karnataka', 'lon': '77.2084'},
      '495116': {'lat': '22.4046', 'name': 'Majhwani,Bilaspur(CGH),Chattisgarh', 'lon': '82.0648'},
      '442111': {'lat': '20.7811', 'name': 'Paunar,Wardha,Maharashtra', 'lon': '78.6703'},
      '507123': {'lat': '17.5614', 'name': 'B N Thanda,Khammam,Telangana', 'lon': '80.4588'},
      '325216': {'lat': '24.5594', 'name': 'Bansthuni,Baran,Rajasthan', 'lon': '75.7738'},
      '203408': {'lat': '28.547', 'name': 'Kurli,Bulandshahr,Uttar Pradesh', 'lon': '77.7465'},
      '444109': {'lat': '20.624', 'name': 'Kalambi Mahagaon,Akola,Maharashtra', 'lon': '77.0776'},
      '444108': {'lat': '20.4384', 'name': 'Adsul,Akola,Maharashtra', 'lon': '77.0015'},
      '444103': {'lat': '20.7151', 'name': 'Talegaon Bazar,Akola,Maharashtra', 'lon': '76.8959'},
      '444102': {'lat': '20.7215', 'name': 'Morgaon Kakad,Akola,Maharashtra', 'lon': '77.3318'},
      '444101': {'lat': '21.0915', 'name': 'Chohatta,Akola,Maharashtra', 'lon': '77.0904'},
      '444107': {'lat': '20.7319', 'name': 'Ghungshi,Akola,Maharashtra', 'lon': '77.4279'},
      '444106': {'lat': '20.6196', 'name': 'Kurum,Washim,Maharashtra', 'lon': '77.8876'},
      '444105': {'lat': '20.5322', 'name': 'Gurumandir Karanja,Washim,Maharashtra', 'lon': '77.2439'},
      '444104': {'lat': '20.696', 'name': 'Midc Shivar,Akola,Maharashtra', 'lon': '77.0981'},
      '176303': {'lat': '32.6987', 'name': 'Bailly,Chamba,Himachal Pradesh', 'lon': '76.1133'},
      '176302': {'lat': '32.0932', 'name': 'Parchhore,Chamba,Himachal Pradesh', 'lon': '76.2602'},
      '176301': {'lat': '32.3295', 'name': 'Ghatasni,Chamba,Himachal Pradesh', 'lon': '76.2629'},
      '142031': {'lat': '30.6391', 'name': 'Hathur,Ludhiana,Punjab', 'lon': '75.4472'},
      '142036': {'lat': '30.89', 'name': 'Nanaksar,Ludhiana,Punjab', 'lon': '75.8962'},
      '176306': {'lat': '32.585', 'name': 'Bathri-bazar,Chamba,Himachal Pradesh', 'lon': '75.9533'},
      '176305': {'lat': '32.2942', 'name': 'Dalhousie Cantt,Chamba,Himachal Pradesh', 'lon': '76.2115'},
      '176304': {'lat': '32.5522', 'name': 'Dalhousie Court Road,Chamba,Himachal Pradesh', 'lon': '75.9466'},
      '176309': {'lat': '32.461', 'name': 'Gareema,Chamba,Himachal Pradesh', 'lon': '76.4737'},
      '176308': {'lat': '32.676', 'name': 'Bhalei,Chamba,Himachal Pradesh', 'lon': '76.0263'},
      '466448': {'lat': '22.7932', 'name': 'Aamon,Sehore,Madhya Pradesh', 'lon': '77.9733'},
      '586216': {'lat': '16.4931', 'name': 'Angadgeri,Vijayapura,Karnataka', 'lon': '76.0889'},
      '586217': {'lat': '17.1446', 'name': 'Satalgaon,Vijayapura,Karnataka', 'lon': '76.0756'},
      '586214': {'lat': '16.528', 'name': 'Peerapur,Vijayapura,Karnataka', 'lon': '76.1732'},
      '586215': {'lat': '17.0148', 'name': 'Tenihalli,Vijayapura,Karnataka', 'lon': '76.0201'},
      '586212': {'lat': '16.2836', 'name': 'Bidarkundi,Vijayapura,Karnataka', 'lon': '76.2902'},
      '586213': {'lat': '16.403', 'name': 'Balabatti,Vijayapura,Karnataka', 'lon': '76.0946'},
      '586210': {'lat': '16.6108', 'name': 'Asangi Khurd,Vijayapura,Karnataka', 'lon': '76.1476'},
      '586211': {'lat': '17.104', 'name': 'Nimb R.S.,Vijayapura,Karnataka', 'lon': '75.8542'},
      '465118': {'lat': '23.1906', 'name': 'PADLIYA,Shajapur,Madhya Pradesh', 'lon': '76.6055'},
      '476554': {'lat': '26.591', 'name': 'Chichawali,Morena,Madhya Pradesh', 'lon': '78.231'},
      '608703': {'lat': '11.4248', 'name': 'Sri Pudur,Cuddalore,Tamil Nadu', 'lon': '79.3959'},
      '833203': {'lat': '22.2211', 'name': 'Jagannathpur,West Singhbhum,Jharkhand', 'lon': '85.6392'},
      '413310': {'lat': '18.6214', 'name': 'Chandapuri,Solapur,Maharashtra', 'lon': '75.2805'},
      '501158': {'lat': '17.3122', 'name': 'Sangamkalan,K.V.Rangareddy,Telangana', 'lon': '77.8234'},
      '781343': {'lat': '26.5004', 'name': 'Amingaon Chowk,Nalbari,Assam', 'lon': '91.4784'},
      '581323': {'lat': '14.3993', 'name': 'Sangtal,Uttara Kannada,Karnataka', 'lon': '74.4971'},
      '781341': {'lat': '26.5004', 'name': 'Balikaria,Nalbari,Assam', 'lon': '91.4784'},
      '781340': {'lat': '26.5004', 'name': 'Suradi,Nalbari,Assam', 'lon': '91.4784'},
      '781347': {'lat': '26.5004', 'name': 'Kamarkuchi,Nalbari,Assam', 'lon': '91.4784'},
      '781346': {'lat': '26.54', 'name': 'Kaljar,Nalbari,Assam', 'lon': '91.3489'},
      '781344': {'lat': '26.4211', 'name': 'Nayabasti,Nalbari,Assam', 'lon': '91.7374'},
      '271504': {'lat': '26.9339', 'name': 'Balmathar,Gonda,Uttar Pradesh', 'lon': '81.7225'},
      '781349': {'lat': '26.5004', 'name': 'Mahina,Nalbari,Assam', 'lon': '91.4784'},
      '781348': {'lat': '26.5004', 'name': 'Barkuriha,Nalbari,Assam', 'lon': '91.4784'},
      '562132': {'lat': '13.4202', 'name': 'Niduvanda,Bangalore Rural,Karnataka', 'lon': '77.0963'},
      '152032': {'lat': '30.3796', 'name': 'Mahabaddar,Muktsar,Punjab', 'lon': '74.4719'},
      '152031': {'lat': '30.402', 'name': 'Sukhana Ablu,Muktsar,Punjab', 'lon': '74.662'},
      '413317': {'lat': '17.7898', 'name': 'Manjari,Solapur,Maharashtra', 'lon': '75.4829'},
      '226008': {'lat': '26.7462', 'name': 'Maunda,Lucknow,Uttar Pradesh', 'lon': '80.8089'},
      '741202': {'lat': '23.5279', 'name': 'Halalpur-krishnapur,Nadia,West Bengal', 'lon': '88.5543'},
      '685588': {'lat': '9.8719', 'name': 'Devarupara,Idukki,Kerala', 'lon': '76.7827'},
      '685589': {'lat': '9.9029', 'name': 'Kannickal,Idukki,Kerala', 'lon': '76.7696'},
      '721211': {'lat': '23.2275', 'name': 'Chetua Rajnagar,West Midnapore,West Bengal', 'lon': '87.9256'},
      '685582': {'lat': '9.934', 'name': 'West Kodikulam,Idukki,Kerala', 'lon': '76.7566'},
      '685583': {'lat': '9.9029', 'name': 'Kuninji,Idukki,Kerala', 'lon': '76.7696'},
      '182104': {'lat': '33.2497', 'name': 'PTC Udhampur,Udhampur,Jammu & Kashmir', 'lon': '74.3491'},
      '685581': {'lat': '9.9029', 'name': 'Neyyassery,Idukki,Kerala', 'lon': '76.7696'},
      '685586': {'lat': '9.9029', 'name': 'Thattarathatta,Idukki,Kerala', 'lon': '76.7696'},
      '685587': {'lat': '9.9029', 'name': 'Mrala,Idukki,Kerala', 'lon': '76.7696'},
      '685584': {'lat': '9.9029', 'name': 'Thodupuzha West,Idukki,Kerala', 'lon': '76.7696'},
      '685585': {'lat': '9.9029', 'name': 'Anchiri,Idukki,Kerala', 'lon': '76.7696'},
      '695301': {'lat': '8.6701', 'name': 'Kaniyapuram,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '380006': {'lat': '23.0333', 'name': 'Ellisbridge,Ahmedabad,Gujarat', 'lon': '72.6181'},
      '695303': {'lat': '8.6701', 'name': 'Puthencurichy,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '695302': {'lat': '8.6701', 'name': 'Murukumpuzhaa,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '695305': {'lat': '8.6701', 'name': 'Azhurmarket,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '695304': {'lat': '8.6547', 'name': 'Katakam,Thiruvananthapuram,Kerala', 'lon': '76.7816'},
      '695307': {'lat': '8.6701', 'name': 'Kaikara,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '695306': {'lat': '8.6807', 'name': 'Melkadakkavur,Thiruvananthapuram,Kerala', 'lon': '76.7689'},
      '695309': {'lat': '8.6748', 'name': 'Poothura,Thiruvananthapuram,Kerala', 'lon': '76.7578'},
      '695308': {'lat': '8.6701', 'name': 'Vakkom,Thiruvananthapuram,Kerala', 'lon': '76.7694'},
      '509412': {'lat': '17.0926', 'name': 'Peddakarpamula,Mahabub Nagar,Telangana', 'lon': '78.094'},
      '307023': {'lat': '25.2004', 'name': 'Ishra,Sirohi,Rajasthan', 'lon': '73.5459'},
      '380009': {'lat': '23.0333', 'name': 'Ashram Road P.O,Ahmedabad,Gujarat', 'lon': '72.6181'},
      '380008': {'lat': '22.9911', 'name': 'S A Mills,Ahmedabad,Gujarat', 'lon': '72.6108'},
      '133205': {'lat': '30.1474', 'name': 'Kambassi,Ambala,Haryana', 'lon': '76.8692'},
      '133204': {'lat': '30.4204', 'name': 'Mehmoodpur,Yamuna Nagar,Haryana', 'lon': '77.2306'},
      '133207': {'lat': '30.277', 'name': 'Mullana Engineering College,Ambala,Haryana', 'lon': '77.0726'},
      '133206': {'lat': '30.2872', 'name': 'Fatehgarh Tumbi,Yamuna Nagar,Haryana', 'lon': '77.1801'},
      '133201': {'lat': '30.2215', 'name': 'Derasalimpur,Ambala,Haryana', 'lon': '77.0136'},
      '209864': {'lat': '26.3624', 'name': 'Sawaen,Unnao,Uttar Pradesh', 'lon': '81.0323'},
      '133203': {'lat': '30.2573', 'name': 'Mullana,Ambala,Haryana', 'lon': '76.9239'},
      '133202': {'lat': '30.2398', 'name': 'Manka Manki,Ambala,Haryana', 'lon': '77.0981'},
      '209869': {'lat': '26.8319', 'name': 'Vyoli Islamabad,Unnao,Uttar Pradesh', 'lon': '80.9648'},
      '209868': {'lat': '26.7445', 'name': 'Jogo Kot,Unnao,Uttar Pradesh', 'lon': '80.618'},
      '688534': {'lat': '9.8694', 'name': 'Aroor,Alappuzha,Kerala', 'lon': '76.305'},
      '688535': {'lat': '9.7869', 'name': 'Arookutty,Alappuzha,Kerala', 'lon': '76.3235'},
      '688536': {'lat': '9.7116', 'name': 'Vayalar,Alappuzha,Kerala', 'lon': '76.3389'},
      '688537': {'lat': '9.7869', 'name': 'Chandiroor BO,Alappuzha,Kerala', 'lon': '76.3235'},
      '688530': {'lat': '9.7869', 'name': 'Arthingal,Alappuzha,Kerala', 'lon': '76.3235'},
      '688531': {'lat': '9.7869', 'name': 'Andhakaranazhy,Alappuzha,Kerala', 'lon': '76.3235'},
      '688532': {'lat': '9.7667', 'name': 'Thuravoor,Alappuzha,Kerala', 'lon': '76.3167'},
      '688533': {'lat': '9.8', 'name': 'Kuthiathode,Alappuzha,Kerala', 'lon': '76.3333'},
      '756048': {'lat': '21.2318', 'name': 'Rairamchandrapur,Baleswar,Odisha', 'lon': '86.2789'},
      '688538': {'lat': '9.7869', 'name': 'Mannancherry,Alappuzha,Kerala', 'lon': '76.3235'},
      '688539': {'lat': '9.7869', 'name': 'Cherthala South BO,Alappuzha,Kerala', 'lon': '76.3235'},
      '788014': {'lat': '24.8313', 'name': 'Silchar Medical College,Cachar,Assam', 'lon': '92.804'},
      '788015': {'lat': '24.8313', 'name': 'Meherpur,Cachar,Assam', 'lon': '92.804'},
      '484881': {'lat': '23.1546', 'name': 'Khamraundh,Anuppur,Madhya Pradesh', 'lon': '81.3017'},
      '416420': {'lat': '17.112', 'name': 'Dhulgaon,Sangli,Maharashtra', 'lon': '74.7699'},
      '788010': {'lat': '24.8313', 'name': 'Bharakhai,Cachar,Assam', 'lon': '92.804'},
      '788011': {'lat': '24.8313', 'name': 'Irongmara Bazar,Cachar,Assam', 'lon': '92.804'},
      '509206': {'lat': '16.4935', 'name': 'Remadla,Mahabub Nagar,Telangana', 'lon': '78.3415'},
      '788013': {'lat': '24.8313', 'name': 'Neairgram,Cachar,Assam', 'lon': '92.804'},
      '322241': {'lat': '26.5443', 'name': 'Ata,Karauli,Rajasthan', 'lon': '76.8627'},
      '322240': {'lat': '26.7912', 'name': 'Bada Bujurg,Dausa,Rajasthan', 'lon': '76.2032'},
      '322243': {'lat': '25.9779', 'name': 'Rampur Dhabai,Karauli,Rajasthan', 'lon': '76.2371'},
      '754130': {'lat': '20.4569', 'name': 'Bodhapur,Cuttack,Odisha', 'lon': '85.7112'},
      '621805': {'lat': '11.3553', 'name': 'Kilaneduvoi,Ariyalur,Tamil Nadu', 'lon': '79.4186'},
      '621804': {'lat': '10.9123', 'name': 'Edayar,Ariyalur,Tamil Nadu', 'lon': '79.1398'},
      '621806': {'lat': '11.2653', 'name': 'Devanur,Ariyalur,Tamil Nadu', 'lon': '79.3172'},
      '621801': {'lat': '11.3355', 'name': 'Pattanamkurichi,Ariyalur,Tamil Nadu', 'lon': '79.3771'},
      '621803': {'lat': '11.0289', 'name': 'Anikduichan,Ariyalur,Tamil Nadu', 'lon': '79.2366'},
      '621802': {'lat': '11.1912', 'name': 'Kaluvanthondi,Ariyalur,Tamil Nadu', 'lon': '79.36'},
      '848102': {'lat': '25.822', 'name': 'Birsisngpur Deorhi,Samastipur,Bihar', 'lon': '85.7709'},
      '848101': {'lat': '25.843', 'name': 'B.R.B.College,Samastipur,Bihar', 'lon': '85.7883'},
      '754023': {'lat': '20.5697', 'name': 'Rudrapur,Jajapur,Odisha', 'lon': '85.4842'},
      '140501': {'lat': '30.4552', 'name': 'Tassimbli,Mohali,Punjab', 'lon': '76.8562'},
      '361002': {'lat': '22.4762', 'name': 'Bedeshwar,Jamnagar,Gujarat', 'lon': '70.0173'},
      '361003': {'lat': '22.4762', 'name': 'Aerodromme,Jamnagar,Gujarat', 'lon': '70.0173'},
      '361001': {'lat': '22.4762', 'name': 'Mandvi Chowk,Jamnagar,Gujarat', 'lon': '70.0173'},
      '361006': {'lat': '22.461', 'name': 'Kansumara,Jamnagar,Gujarat', 'lon': '69.9869'},
      '361007': {'lat': '22.4762', 'name': 'Navanagna,Jamnagar,Gujarat', 'lon': '70.0173'},
      '361004': {'lat': '22.4762', 'name': 'Udyognagar,Jamnagar,Gujarat', 'lon': '70.0173'},
      '361005': {'lat': '22.4762', 'name': 'Pavan Chakki,Jamnagar,Gujarat', 'lon': '70.0173'},
      '385566': {'lat': '24.4895', 'name': 'Mavsari,Banaskantha,Gujarat', 'lon': '71.9408'},
      '361008': {'lat': '22.4968', 'name': 'Medical Campus,Jamnagar,Gujarat', 'lon': '70.0823'},
      '361009': {'lat': '22.5014', 'name': 'Dhichada,Jamnagar,Gujarat', 'lon': '70.0436'},
      '502115': {'lat': '18.1269', 'name': 'Thonigandla,Medak,Telangana', 'lon': '78.8015'},
      '732127': {'lat': '25.0677', 'name': 'Charbabupur,Malda,West Bengal', 'lon': '87.9151'},
      '388001': {'lat': '22.8826', 'name': 'Anand H.O,Anand,Gujarat', 'lon': '73.0998'},
      '385560': {'lat': '23.8875', 'name': 'Tatiyana,Banaskantha,Gujarat', 'lon': '71.7698'},
      '325214': {'lat': '25.2769', 'name': 'Laxmi Pura,Baran,Rajasthan', 'lon': '76.3403'},
      '495119': {'lat': '22.3513', 'name': 'Pasan,Bilaspur(CGH),Chattisgarh', 'lon': '81.9617'},
      '734003': {'lat': '26.731', 'name': 'Champasari,Darjiling,West Bengal', 'lon': '88.9486'},
      '463111': {'lat': '23.7611', 'name': 'Meghra Kalan,Bhopal,Madhya Pradesh', 'lon': '78.1617'},
      '732121': {'lat': '25.0677', 'name': 'Gouramari,Malda,West Bengal', 'lon': '87.9151'},
      '835227': {'lat': '22.9457', 'name': 'Sarita,Gumla,Jharkhand', 'lon': '85.0981'},
      '225207': {'lat': '26.9746', 'name': 'Kamoli,Barabanki,Uttar Pradesh', 'lon': '81.4602'},
      '225206': {'lat': '26.847', 'name': 'Pyarepur,Barabanki,Uttar Pradesh', 'lon': '81.5145'},
      '203401': {'lat': '28.539', 'name': 'Visundhara,Bulandshahr,Uttar Pradesh', 'lon': '78.0576'},
      '225204': {'lat': '26.9998', 'name': 'Nainamau,Barabanki,Uttar Pradesh', 'lon': '81.286'},
      '225203': {'lat': '26.8769', 'name': 'Jahangeerabad Raj,Barabanki,Uttar Pradesh', 'lon': '81.2151'},
      '732123': {'lat': '25.4013', 'name': 'Arbora,Malda,West Bengal', 'lon': '87.9843'},
      '225201': {'lat': '27.2285', 'name': 'Bahramghat,Barabanki,Uttar Pradesh', 'lon': '81.1688'},
      '754136': {'lat': '20.458', 'name': 'Tentulipada,Jagatsinghapur,Odisha', 'lon': '84.6905'},
      '261205': {'lat': '27.5354', 'name': 'Jatpurwa Kishoreganj,Sitapur,Uttar Pradesh', 'lon': '81.1471'},
      '612610': {'lat': '10.0111', 'name': 'Kilapalaiyur,Tiruvarur,Tamil Nadu', 'lon': '78.9281'},
      '732122': {'lat': '23.0119', 'name': 'Singabad,Malda,West Bengal', 'lon': '87.5938'},
      '805112': {'lat': '25.059', 'name': 'Derma,Nawada,Bihar', 'lon': '85.7999'},
      '341513': {'lat': '27.0628', 'name': 'Alaniyawas,Nagaur,Rajasthan', 'lon': '74.8364'},
      '844113': {'lat': '25.9388', 'name': 'Balukaram,Vaishali,Bihar', 'lon': '85.1511'},
      '805111': {'lat': '24.9255', 'name': 'Mahnadpur,Nawada,Bihar', 'lon': '85.343'},
      '323303': {'lat': '25.1912', 'name': 'Anushakti,Chittorgarh,Rajasthan', 'lon': '75.6989'},
      '585292': {'lat': '16.473', 'name': 'Malkhed Road,Kalaburagi,Karnataka', 'lon': '76.5352'},
      '585291': {'lat': '16.3489', 'name': 'Geddalmari,Yadgir,Karnataka', 'lon': '76.5004'},
      '585290': {'lat': '16.5351', 'name': 'Thinthani,Yadgir,Karnataka', 'lon': '76.5525'},
      '821113': {'lat': '25.0192', 'name': 'Samardihan,Rohtas,Bihar', 'lon': '84.145'},
      '501218': {'lat': '17.2414', 'name': 'Nanajipur,K.V.Rangareddy,Telangana', 'lon': '78.3921'},
      '431109': {'lat': '20.0215', 'name': 'Malunja,Aurangabad,Maharashtra', 'lon': '75.2735'},
      '799253': {'lat': '24.0447', 'name': 'Algapur,North Tripura,Tripura', 'lon': '91.7553'},
      '185212': {'lat': '33.1869', 'name': 'Dodasanbala,Rajauri,Jammu & Kashmir', 'lon': '74.6933'},
      '185211': {'lat': '33.5874', 'name': 'Dhargloon,Poonch,Jammu & Kashmir', 'lon': '74.2565'},
      '388510': {'lat': '22.3609', 'name': 'Asarma,Anand,Gujarat', 'lon': '73.009'},
      '431103': {'lat': '20.163', 'name': 'Kalimath,Aurangabad,Maharashtra', 'lon': '75.5387'},
      '431102': {'lat': '20.0925', 'name': 'Galle Borgaon,Aurangabad,Maharashtra', 'lon': '75.2193'},
      '431101': {'lat': '20.2711', 'name': 'Kagazipura,Aurangabad,Maharashtra', 'lon': '75.3606'},
      '844117': {'lat': '25.7917', 'name': 'Paura,Vaishali,Bihar', 'lon': '85.2333'},
      '431107': {'lat': '19.1302', 'name': 'Harshi,Aurangabad,Maharashtra', 'lon': '76.274'},
      '431105': {'lat': '19.7647', 'name': 'Bokud Jalgaon,Aurangabad,Maharashtra', 'lon': '75.6467'},
      '431104': {'lat': '20.2567', 'name': 'Bharamba,Aurangabad,Maharashtra', 'lon': '75.3038'},
      '769006': {'lat': '21.8782', 'name': 'Rourkela - 6,Sundergarh,Odisha', 'lon': '84.638'},
      '132001': {'lat': '29.3671', 'name': 'Kanral Industrial Area,Karnal,Haryana', 'lon': '75.0151'},
      '769004': {'lat': '21.8782', 'name': 'Jail Road (Sundergarh),Sundergarh,Odisha', 'lon': '84.638'},
      '769005': {'lat': '21.8782', 'name': 'Rourkela - 5,Sundergarh,Odisha', 'lon': '84.638'},
      '769002': {'lat': '21.8782', 'name': 'Sector - 5,Sundergarh,Odisha', 'lon': '84.638'},
      '769003': {'lat': '21.8782', 'name': 'Sector - 15,Sundergarh,Odisha', 'lon': '84.638'},
      '769001': {'lat': '21.8782', 'name': 'Bisra Road,Sundergarh,Odisha', 'lon': '84.638'},
      '313207': {'lat': '25.6038', 'name': 'Pachhamata,Rajsamand,Rajasthan', 'lon': '74.3042'},
      '769008': {'lat': '21.8782', 'name': 'Rourkela - 8,Sundergarh,Odisha', 'lon': '84.638'},
      '769009': {'lat': '21.8782', 'name': 'Rourkela - 9,Sundergarh,Odisha', 'lon': '84.638'},
      '453331': {'lat': '22.8973', 'name': 'Tihi,Indore,Madhya Pradesh', 'lon': '76.7187'},
      '502220': {'lat': '17.2562', 'name': 'Zaheerabad H.O,Medak,Telangana', 'lon': '77.584'},
      '574218': {'lat': '12.4788', 'name': 'Nadugallu,Dakshina Kannada,Karnataka', 'lon': '75.6111'},
      '263634': {'lat': '29.8974', 'name': 'Horali,Bageshwar,Uttarakhand', 'lon': '79.4892'},
      '802209': {'lat': '25.2075', 'name': 'Tarari,Bhojpur,Bihar', 'lon': '84.4072'},
      '313206': {'lat': '24.718', 'name': 'Sanwar (Udaipur),Udaipur,Rajasthan', 'lon': '73.9263'},
      '203402': {'lat': '28.6733', 'name': 'Sathala,Bulandshahr,Uttar Pradesh', 'lon': '77.9787'},
      '502221': {'lat': '17.2562', 'name': 'Ippepally,Medak,Telangana', 'lon': '77.584'},
      '783101': {'lat': '26.1271', 'name': 'Dubapara,Goalpara,Assam', 'lon': '90.608'},
      '263637': {'lat': '29.7879', 'name': 'Bhainsar Gaon,Almora,Uttarakhand', 'lon': '79.5678'},
      '504346': {'lat': '19.4143', 'name': 'Yenda,Adilabad,Telangana', 'lon': '78.5709'},
      '425508': {'lat': '21.214', 'name': 'Kherala,Jalgaon,Maharashtra', 'lon': '75.8452'},
      '425506': {'lat': '21.166', 'name': 'Dasnoor,Jalgaon,Maharashtra', 'lon': '75.9656'},
      '425507': {'lat': '21.0159', 'name': 'Khirdi,Jalgaon,Maharashtra', 'lon': '75.7178'},
      '425504': {'lat': '21.2896', 'name': 'Savkheda B K,Jalgaon,Maharashtra', 'lon': '75.8979'},
      '425505': {'lat': '21.2211', 'name': 'Chinawal,Jalgaon,Maharashtra', 'lon': '75.9346'},
      '425502': {'lat': '21.1505', 'name': 'Tandalwadi,Jalgaon,Maharashtra', 'lon': '75.8894'},
      '425503': {'lat': '21.2194', 'name': 'Marul,Jalgaon,Maharashtra', 'lon': '75.8142'},
      '425501': {'lat': '21.1282', 'name': 'Udhali,Jalgaon,Maharashtra', 'lon': '75.9349'},
      '686015': {'lat': '9.6068', 'name': 'Pulikkuttissery,Kottayam,Kerala', 'lon': '76.5005'},
      '441601': {'lat': '21.1773', 'name': 'Phulchur,Gondia,Maharashtra', 'lon': '80.1214'},
      '581198': {'lat': '14.7427', 'name': 'Motebennur,Haveri,Karnataka', 'lon': '75.4732'},
      '581199': {'lat': '14.9479', 'name': 'Masanakatti,Haveri,Karnataka', 'lon': '75.2258'},
      '482051': {'lat': '22.9611', 'name': 'Tinsi,Jabalpur,Madhya Pradesh', 'lon': '79.8396'},
      '686546': {'lat': '9.5', 'name': 'Madappally,Kottayam,Kerala', 'lon': '76.6944'},
      '581195': {'lat': '14.9479', 'name': 'Baradwad,Dharwad,Karnataka', 'lon': '75.2258'},
      '581196': {'lat': '15.1933', 'name': 'Surashettikoppa,Dharwad,Karnataka', 'lon': '75.0666'},
      '263633': {'lat': '29.7549', 'name': 'Ratirketi,Bageshwar,Uttarakhand', 'lon': '79.6296'},
      '686545': {'lat': '9.5', 'name': 'Santhipuram,Kottayam,Kerala', 'lon': '76.6944'},
      '581193': {'lat': '14.9344', 'name': 'Konanakeri,Haveri,Karnataka', 'lon': '75.1964'},
      '173029': {'lat': '30.6156', 'name': 'Kamrau,Sirmaur,Himachal Pradesh', 'lon': '77.6379'},
      '761119': {'lat': '19.4921', 'name': 'Pantikhari,Ganjam,Odisha', 'lon': '84.9338'},
      '734006': {'lat': '26.731', 'name': 'Haidarpara,Jalpaiguri,West Bengal', 'lon': '88.9486'},
      '686544': {'lat': '9.5', 'name': 'Poovatholi,Kottayam,Kerala', 'lon': '76.6944'},
      '638112': {'lat': '11.2533', 'name': 'Vallipurathanpalayam,Erode,Tamil Nadu', 'lon': '77.6563'},
      '734001': {'lat': '26.731', 'name': 'Vivekanandapally,Jalpaiguri,West Bengal', 'lon': '88.9486'},
      '734002': {'lat': '26.9135', 'name': 'Salbari,Darjiling,West Bengal', 'lon': '88.3947'},
      '177220': {'lat': '31.4892', 'name': 'Palakwah,Una,Himachal Pradesh', 'lon': '76.7687'},
      '173021': {'lat': '30.4913', 'name': 'Misserwala,Sirmaur,Himachal Pradesh', 'lon': '77.5302'},
      '686543': {'lat': '9.4833', 'name': 'Erathuvadakara,Kottayam,Kerala', 'lon': '76.75'},
      '173023': {'lat': '30.6808', 'name': 'Bhajond,Sirmaur,Himachal Pradesh', 'lon': '77.4204'},
      '173022': {'lat': '30.6019', 'name': 'Kangta Failag,Sirmaur,Himachal Pradesh', 'lon': '77.4545'},
      '173025': {'lat': '30.7334', 'name': 'Manpur Devra,Sirmaur,Himachal Pradesh', 'lon': '77.577'},
      '173024': {'lat': '31.0318', 'name': 'Tikkari Kuthar,Sirmaur,Himachal Pradesh', 'lon': '77.1058'},
      '173027': {'lat': '30.7039', 'name': 'Shillai,Sirmaur,Himachal Pradesh', 'lon': '77.6559'},
      '173026': {'lat': '30.7399', 'name': 'Kw Bhood,Sirmaur,Himachal Pradesh', 'lon': '77.4603'},
      '393151': {'lat': '23.019', 'name': 'Kothi,Bharuch,Gujarat', 'lon': '73.6666'},
      '393150': {'lat': '21.7864', 'name': 'Mangrol,Bharuch,Gujarat', 'lon': '72.9251'},
      '636309': {'lat': '11.4155', 'name': 'Periapatti,Salem,Tamil Nadu', 'lon': '77.9025'},
      '393155': {'lat': '23.0086', 'name': 'Gora Colony,Bharuch,Gujarat', 'lon': '74.1551'},
      '686541': {'lat': '9.5167', 'name': 'Edayarickapuzha,Kottayam,Kerala', 'lon': '76.7'},
      '852127': {'lat': '25.6417', 'name': 'Tariama,Saharsa,Bihar', 'lon': '86.49'},
      '686540': {'lat': '9.5', 'name': 'Chelacompu,Kottayam,Kerala', 'lon': '76.6333'},
      '852124': {'lat': '25.9159', 'name': 'Menha Khadimpur,Saharsa,Bihar', 'lon': '86.5655'},
      '629701': {'lat': '8.1546', 'name': 'Therku Thamaraikulam,Kanyakumari,Tamil Nadu', 'lon': '77.493'},
      '223226': {'lat': '26.0023', 'name': 'Sikaraur Sahabari,Azamgarh,Uttar Pradesh', 'lon': '82.8309'},
      '503124': {'lat': '18.2398', 'name': 'Perumalla,Nizamabad,Telangana', 'lon': '78.131'},
      '680642': {'lat': '10.8662', 'name': 'Puthenpeedika,Thrissur,Kerala', 'lon': '75.9028'},
      '680641': {'lat': '10.8662', 'name': 'Anthikad,Thrissur,Kerala', 'lon': '75.9028'},
      '853201': {'lat': '25.3365', 'name': 'Bharamarpur,Bhagalpur,Bihar', 'lon': '86.9266'},
      '853202': {'lat': '25.4271', 'name': 'Ishapur Chorhar,Bhagalpur,Bihar', 'lon': '87.0196'},
      '853203': {'lat': '25.4526', 'name': 'Madhurpur,Bhagalpur,Bihar', 'lon': '86.6543'},
      '853204': {'lat': '25.247', 'name': 'Mohanpur,Munger,Bihar', 'lon': '86.4278'},
      '323306': {'lat': '24.8219', 'name': 'Vikram Nagar,Chittorgarh,Rajasthan', 'lon': '75.4422'},
      '503122': {'lat': '17.8516', 'name': 'Kalyani,Nizamabad,Telangana', 'lon': '78.1967'},
      '576228': {'lat': '13.908', 'name': 'Shiruru (Udupi),Udupi,Karnataka', 'lon': '74.6058'},
      '576229': {'lat': '14.0461', 'name': 'Siddapura (Udupi),Udupi,Karnataka', 'lon': '74.7166'},
      '333036': {'lat': '28.0051', 'name': 'Kishanpura,Jhujhunu,Rajasthan', 'lon': '75.9789'},
      '333030': {'lat': '28.3503', 'name': 'Jeeni,Jhujhunu,Rajasthan', 'lon': '75.7155'},
      '333031': {'lat': '28.4061', 'name': 'Likhawa,Jhujhunu,Rajasthan', 'lon': '75.6275'},
      '333032': {'lat': '28.3044', 'name': 'Gilonki  Dhani,Jhujhunu,Rajasthan', 'lon': '75.7587'},
      '333033': {'lat': '28.3085', 'name': 'Dhingaria,Jhujhunu,Rajasthan', 'lon': '75.8604'},
      '576220': {'lat': '13.8672', 'name': 'Kollur,Udupi,Karnataka', 'lon': '74.8127'},
      '576221': {'lat': '13.5335', 'name': 'Kotathattu,Udupi,Karnataka', 'lon': '74.7013'},
      '576222': {'lat': '13.4586', 'name': 'Molahalli,Udupi,Karnataka', 'lon': '75.001'},
      '576223': {'lat': '13.4791', 'name': 'Muddumane,Udupi,Karnataka', 'lon': '74.7945'},
      '576224': {'lat': '13.7488', 'name': 'Navunda,Udupi,Karnataka', 'lon': '74.6409'},
      '531075': {'lat': '17.8618', 'name': 'Mamidipalli,Visakhapatnam,Andhra Pradesh', 'lon': '82.8968'},
      '576226': {'lat': '13.6874', 'name': 'Yedabettu,Udupi,Karnataka', 'lon': '74.7822'},
      '531077': {'lat': '18.1676', 'name': 'Jarakkonda,Visakhapatnam,Andhra Pradesh', 'lon': '82.7805'},
      '273015': {'lat': '26.8341', 'name': 'Gorakhnath Mandir,Gorakhpur,Uttar Pradesh', 'lon': '83.1569'},
      '273014': {'lat': '26.7339', 'name': 'Jangle Laxmipur,Gorakhpur,Uttar Pradesh', 'lon': '83.3717'},
      '246747': {'lat': '29.358', 'name': 'Haraivli,Bijnor,Uttar Pradesh', 'lon': '78.5914'},
      '273016': {'lat': '26.7142', 'name': 'Davarpar,Gorakhpur,Uttar Pradesh', 'lon': '83.3698'},
      '341515': {'lat': '26.3636', 'name': 'Moriallan,Nagaur,Rajasthan', 'lon': '75.0135'},
      '205304': {'lat': '27.087', 'name': 'Manigaon,Mainpuri,Uttar Pradesh', 'lon': '79.2068'},
      '273012': {'lat': '26.7339', 'name': 'Loco  Workshop LSG,Gorakhpur,Uttar Pradesh', 'lon': '83.3717'},
      '323305': {'lat': '25.1912', 'name': 'Rawatbhatta  Bazar,Chittorgarh,Rajasthan', 'lon': '75.6989'},
      '505102': {'lat': '17.9507', 'name': 'Guniparthi,Karim Nagar,Telangana', 'lon': '79.4476'},
      '761052': {'lat': '20.1132', 'name': 'Golabandha,Ganjam,Odisha', 'lon': '84.5672'},
      '786160': {'lat': '26.5712', 'name': 'Barhullung,Tinsukia,Assam', 'lon': '93.0511'},
      '761055': {'lat': '19.4053', 'name': 'Sahadev Tikirapada,Ganjam,Odisha', 'lon': '84.4986'},
      '124001': {'lat': '28.8917', 'name': 'DLF Colony Rohtak,Rohtak,Haryana', 'lon': '76.6118'},
      '223224': {'lat': '25.898', 'name': 'Hubbi Ganj,Azamgarh,Uttar Pradesh', 'lon': '82.5973'},
      '628252': {'lat': '8.7064', 'name': 'Kaliyavoor,Tuticorin,Tamil Nadu', 'lon': '77.8217'},
      '246746': {'lat': '29.2564', 'name': 'Mukarabpuri,Bijnor,Uttar Pradesh', 'lon': '78.5589'},
      '700013': {'lat': '22.8436', 'name': 'Dharmatala,Kolkata,West Bengal', 'lon': '88.2293'},
      '283130': {'lat': '27.3269', 'name': 'Kheria Patikara,Firozabad,Uttar Pradesh', 'lon': '79.3119'},
      '323304': {'lat': '24.9838', 'name': 'Meghniwas,Chittorgarh,Rajasthan', 'lon': '75.8626'},
      '396472': {'lat': '21.031', 'name': 'Chandravasansupa,Navsari,Gujarat', 'lon': '72.9751'},
      '396475': {'lat': '21.031', 'name': 'Parthan,Navsari,Gujarat', 'lon': '72.9751'},
      '500045': {'lat': '17.529', 'name': 'A.Gs. Staff Quarters,Hyderabad,Telangana', 'lon': '78.6839'},
      '500044': {'lat': '17.3753', 'name': 'Ambernagar,Hyderabad,Telangana', 'lon': '78.4744'},
      '500047': {'lat': '17.4284', 'name': 'Malkajgiri,Hyderabad,Telangana', 'lon': '78.4936'},
      '500046': {'lat': '17.529', 'name': 'CUC,Hyderabad,Telangana', 'lon': '78.6839'},
      '500041': {'lat': '17.529', 'name': 'Raj Bhawan (Hyderabad),Hyderabad,Telangana', 'lon': '78.6839'},
      '500040': {'lat': '17.529', 'name': 'Aphb Colony Moulali,Hyderabad,Telangana', 'lon': '78.6839'},
      '622503': {'lat': '10.4529', 'name': 'Kattubawa Pallivasal,Pudukkottai,Tamil Nadu', 'lon': '78.7826'},
      '500042': {'lat': '17.529', 'name': 'Hal (Hyderabad),Hyderabad,Telangana', 'lon': '78.6839'},
      '784104': {'lat': '26.4206', 'name': 'Shalonibari,Sonitpur,Assam', 'lon': '93.2317'},
      '784105': {'lat': '26.0213', 'name': 'Natun Baithabhonga,Sonitpur,Assam', 'lon': '93.7795'},
      '784102': {'lat': '26.4206', 'name': 'Sonaipam,Sonitpur,Assam', 'lon': '93.2317'},
      '784103': {'lat': '26.4206', 'name': 'Chariduar,Sonitpur,Assam', 'lon': '93.2317'},
      '784101': {'lat': '26.8198', 'name': 'Nankey Patgaon,Sonitpur,Assam', 'lon': '92.684'},
      '736165': {'lat': '26.3599', 'name': 'Chandanchaura,Cooch Behar,West Bengal', 'lon': '89.5546'},
      '736167': {'lat': '26.3599', 'name': 'Kismat Adabari,Cooch Behar,West Bengal', 'lon': '89.5546'},
      '736160': {'lat': '26.3599', 'name': 'Bilsi,Cooch Behar,West Bengal', 'lon': '89.5546'},
      '821311': {'lat': '25.054', 'name': 'Kushdihra,Rohtas,Bihar', 'lon': '84.2612'},
      '821310': {'lat': '25.1351', 'name': 'Marojhian,Rohtas,Bihar', 'lon': '84.367'},
      '250609': {'lat': '28.9388', 'name': 'Baghpat Sugar Mill,Bagpat,Uttar Pradesh', 'lon': '77.2585'},
      '283136': {'lat': '27.3084', 'name': 'Akbarpur Kutukpur,Firozabad,Uttar Pradesh', 'lon': '78.6499'},
      '736169': {'lat': '26.3599', 'name': 'Rakhalmari,Cooch Behar,West Bengal', 'lon': '89.5546'},
      '736168': {'lat': '26.3599', 'name': 'Durganagar,Cooch Behar,West Bengal', 'lon': '89.5546'},
      '515405': {'lat': '14.9876', 'name': 'Edurur,Ananthapur,Andhra Pradesh', 'lon': '77.7108'},
      '326001': {'lat': '24.7136', 'name': 'Jhalawar H.O,Jhalawar,Rajasthan', 'lon': '76.2611'},
      '515402': {'lat': '15.5727', 'name': 'Bethapalli,Ananthapur,Andhra Pradesh', 'lon': '77.5857'},
      '515401': {'lat': '14.6599', 'name': 'Pathakothacheruvu,Ananthapur,Andhra Pradesh', 'lon': '77.7341'},
      '518350': {'lat': '15.6316', 'name': 'Banavanur,Kurnool,Andhra Pradesh', 'lon': '77.4431'},
      '796290': {'lat': '23.85', 'name': 'Teikhang,Champhai,Mizoram', 'lon': '93.2151'},
      '515408': {'lat': '14.8326', 'name': 'Peddayakkalur,Ananthapur,Andhra Pradesh', 'lon': '78.1069'},
      '190023': {'lat': '34.1286', 'name': 'Lal Bazar,Srinagar,Jammu & Kashmir', 'lon': '74.8108'},
      '190020': {'lat': '34.1286', 'name': 'Bachpora,Srinagar,Jammu & Kashmir', 'lon': '74.8108'},
      '190021': {'lat': '34.1286', 'name': 'Hamhama,Budgam,Jammu & Kashmir', 'lon': '74.8108'},
      '190024': {'lat': '34.1286', 'name': 'Zakoora,Srinagar,Jammu & Kashmir', 'lon': '74.8108'},
      '190025': {'lat': '34.1286', 'name': 'Skuast,Srinagar,Jammu & Kashmir', 'lon': '74.8108'},
      '361110': {'lat': '22.9029', 'name': 'Beraja Pasaya,Jamnagar,Gujarat', 'lon': '69.148'},
      '799275': {'lat': '23.9923', 'name': 'Karatichesdrra,Dhalai,Tripura', 'lon': '91.6205'},
      '799273': {'lat': '23.9923', 'name': 'Bindulal Karbaripara,North Tripura,Tripura', 'lon': '91.6205'},
      '799270': {'lat': '23.6526', 'name': 'Joyasree,North Tripura,Tripura', 'lon': '91.2372'},
      '799271': {'lat': '23.9923', 'name': 'Anandabazar,North Tripura,Tripura', 'lon': '91.6205'},
      '683513': {'lat': '10.132', 'name': 'Paravur Town,Ernakulam,Kerala', 'lon': '76.236'},
      '686564': {'lat': '9.6', 'name': 'Paduva,Kottayam,Kerala', 'lon': '76.4333'},
      '683511': {'lat': '10.1019', 'name': 'Karumalloor,Ernakulam,Kerala', 'lon': '76.291'},
      '281307': {'lat': '27.3271', 'name': 'Gutehara,Hathras,Uttar Pradesh', 'lon': '78.1583'},
      '686561': {'lat': '9.6', 'name': 'Mannanam,Kottayam,Kerala', 'lon': '76.4333'},
      '683516': {'lat': '10.1899', 'name': 'Maliankara,Ernakulam,Kerala', 'lon': '76.2008'},
      '686563': {'lat': '9.6', 'name': 'Kumarakom East,Kottayam,Kerala', 'lon': '76.4333'},
      '683514': {'lat': '9.977', 'name': 'Cherai,Ernakulam,Kerala', 'lon': '76.27'},
      '683519': {'lat': '10.1128', 'name': 'Kottuvally,Ernakulam,Kerala', 'lon': '76.2465'},
      '683518': {'lat': '10.1128', 'name': 'Kongorpilly,Ernakulam,Kerala', 'lon': '76.2465'},
      '281308': {'lat': '27.4294', 'name': 'Sonkh Khera,Mathura,Uttar Pradesh', 'lon': '77.9242'},
      '518134': {'lat': '15.1945', 'name': 'Revanur,Kurnool,Andhra Pradesh', 'lon': '78.5278'},
      '518135': {'lat': '15.3388', 'name': 'Ammireddinagar,Kurnool,Andhra Pradesh', 'lon': '78.3007'},
      '623501': {'lat': '9.0426', 'name': 'Ramanathapuram H.O,Ramanathapuram,Tamil Nadu', 'lon': '78.0503'},
      '815302': {'lat': '23.9074', 'name': 'Gadi Sermpur,Giridh,Jharkhand', 'lon': '86.1389'},
      '414106': {'lat': '19.1644', 'name': 'Majle Chincholi,Ahmed Nagar,Maharashtra', 'lon': '74.3729'},
      '414105': {'lat': '19.9993', 'name': 'Brahmni,Ahmed Nagar,Maharashtra', 'lon': '75.4588'},
      '414102': {'lat': '19.7058', 'name': 'Akhegaon Titarfa,Ahmed Nagar,Maharashtra', 'lon': '75.0227'},
      '414103': {'lat': '18.9511', 'name': 'Hiware Korda,Ahmed Nagar,Maharashtra', 'lon': '74.3846'},
      '414101': {'lat': '18.831', 'name': 'Mandavgan,Ahmed Nagar,Maharashtra', 'lon': '74.831'},
      '515661': {'lat': '14.5453', 'name': 'Musturu,Ananthapur,Andhra Pradesh', 'lon': '77.8198'},
      '584129': {'lat': '15.9074', 'name': 'Kyadigera,Raichur,Karnataka', 'lon': '76.5501'},
      '623504': {'lat': '9.6663', 'name': 'Surankottai,Ramanathapuram,Tamil Nadu', 'lon': '78.1513'},
      '623566': {'lat': '9.4399', 'name': 'Ervadi Dargha,Ramanathapuram,Tamil Nadu', 'lon': '78.7716'},
      '362725': {'lat': '23.7859', 'name': 'Kadvasan,Junagadh,Gujarat', 'lon': '72.6389'},
      '364765': {'lat': '22.0004', 'name': 'Pipaliya,Bhavnagar,Gujarat', 'lon': '71.6479'},
      '611106': {'lat': '10.7671', 'name': 'Vadakkupoigainallur,Nagapattinam,Tamil Nadu', 'lon': '79.8306'},
      '473443': {'lat': '24.2756', 'name': 'Malhargarh,Ashok Nagar,Madhya Pradesh', 'lon': '78.0663'},
      '473444': {'lat': '24.6094', 'name': 'Sehrai,Ashok Nagar,Madhya Pradesh', 'lon': '77.8752'},
      '473445': {'lat': '24.3259', 'name': 'Gaduli,Ashok Nagar,Madhya Pradesh', 'lon': '77.9409'},
      '473446': {'lat': '24.6094', 'name': 'Pranpura,Ashok Nagar,Madhya Pradesh', 'lon': '77.8752'},
      '799012': {'lat': '23.7062', 'name': 'Gandhigram,West Tripura,Tripura', 'lon': '91.4203'},
      '799013': {'lat': '23.72124', 'name': 'Matabari,Gomti,Tripura', 'lon': '91.74458'},
      '799010': {'lat': '23.7062', 'name': 'Agartala Secretariat,West Tripura,Tripura', 'lon': '91.4203'},
      '799011': {'lat': '23.7062', 'name': 'Sankumabari,West Tripura,Tripura', 'lon': '91.4203'},
      '247122': {'lat': '29.8561', 'name': 'Badshahi Bagh,Saharanpur,Uttar Pradesh', 'lon': '77.8346'},
      '627401': {'lat': '8.8359', 'name': 'Ambasamudram H.O,Tirunelveli,Tamil Nadu', 'lon': '77.5981'},
      '148017': {'lat': '30.3341', 'name': 'Harchandpur,Sangrur,Punjab', 'lon': '75.9337'},
      '530032': {'lat': '17.926', 'name': 'Ukkunagaram,Visakhapatnam,Andhra Pradesh', 'lon': '83.1431'},
      '530031': {'lat': '17.926', 'name': 'Islampeta,Visakhapatnam,Andhra Pradesh', 'lon': '83.1431'},
      '524004': {'lat': '14.2755', 'name': 'Kommarapudi,Nellore,Andhra Pradesh', 'lon': '79.8859'},
      '524003': {'lat': '14.4875', 'name': 'Bhaktavatsalanagar,Nellore,Andhra Pradesh', 'lon': '79.9812'},
      '524002': {'lat': '14.4784', 'name': 'Nellimitta Kandriga,Nellore,Andhra Pradesh', 'lon': '80.0347'},
      '524001': {'lat': '14.4655', 'name': 'Pogathota,Nellore,Andhra Pradesh', 'lon': '79.9874'},
      '208017': {'lat': '26.5666', 'name': 'Kalyanpur (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '80.9724'},
      '584120': {'lat': '16.0892', 'name': 'Markam Dinni,Raichur,Karnataka', 'lon': '76.7513'},
      '678510': {'lat': '10.5503', 'name': 'Ayalur,Palakkad,Kerala', 'lon': '76.5772'},
      '222149': {'lat': '26.2088', 'name': 'Bodari,Jaunpur,Uttar Pradesh', 'lon': '83.4198'},
      '222148': {'lat': '25.6714', 'name': 'Verdiha,Jaunpur,Uttar Pradesh', 'lon': '82.9687'},
      '811102': {'lat': '25.2236', 'name': 'Singhaul,Nalanda,Bihar', 'lon': '85.8152'},
      '222141': {'lat': '25.8299', 'name': 'Tezi Bazar,Jaunpur,Uttar Pradesh', 'lon': '82.5043'},
      '222143': {'lat': '25.6926', 'name': 'Ghagharia,Jaunpur,Uttar Pradesh', 'lon': '82.4858'},
      '535217': {'lat': '18.2104', 'name': 'Kella,Vizianagaram,Andhra Pradesh', 'lon': '83.4884'},
      '222145': {'lat': '25.8091', 'name': 'Shekhpur Khutahani,Jaunpur,Uttar Pradesh', 'lon': '82.4844'},
      '222144': {'lat': '26.0502', 'name': 'Gonapur,Jaunpur,Uttar Pradesh', 'lon': '82.631'},
      '222146': {'lat': '25.7789', 'name': 'Kanuani,Jaunpur,Uttar Pradesh', 'lon': '82.6108'},
      '622101': {'lat': '10.4636', 'name': 'Chittannavasal,Pudukkottai,Tamil Nadu', 'lon': '78.7226'},
      '221313': {'lat': '25.3881', 'name': 'Thathara,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '582210': {'lat': '15.0638', 'name': 'Shigli,Gadag,Karnataka', 'lon': '75.4676'},
      '582211': {'lat': '15.7323', 'name': 'Sudi,Gadag,Karnataka', 'lon': '75.8667'},
      '611104': {'lat': '10.8008', 'name': 'Kohur,Nagapattinam,Tamil Nadu', 'lon': '79.7667'},
      '251003': {'lat': '28.8935', 'name': 'Lachera,Muzaffarnagar,Uttar Pradesh', 'lon': '78.0042'},
      '251001': {'lat': '29.3915', 'name': 'Bhopa Road,Muzaffarnagar,Uttar Pradesh', 'lon': '77.6649'},
      '421502': {'lat': '19.6743', 'name': 'O.E.Ambernath,Thane,Maharashtra', 'lon': '76.291'},
      '421503': {'lat': '19.5353', 'name': 'Pimpaloli,Thane,Maharashtra', 'lon': '76.1449'},
      '421501': {'lat': '19.6743', 'name': 'Ambernath South,Thane,Maharashtra', 'lon': '76.291'},
      '421506': {'lat': '19.6743', 'name': 'Additional Ambernath,Thane,Maharashtra', 'lon': '76.291'},
      '421505': {'lat': '19.9522', 'name': 'Netaji Bazar,Thane,Maharashtra', 'lon': '76.5833'},
      '363670': {'lat': '22.6104', 'name': 'Nani Barar,Rajkot,Gujarat', 'lon': '72.8731'},
      '203141': {'lat': '28.1554', 'name': 'Shahpur  Kala,Bulandshahr,Uttar Pradesh', 'lon': '77.8822'},
      '503219': {'lat': '18.9547', 'name': 'Soanpet,Nizamabad,Telangana', 'lon': '78.4303'},
      '303301': {'lat': '26.3868', 'name': 'Palawalan Jatan,Jaipur,Rajasthan', 'lon': '75.4583'},
      '611103': {'lat': '10.6983', 'name': 'Venmanachery,Nagapattinam,Tamil Nadu', 'lon': '79.384'},
      '222001': {'lat': '25.7382', 'name': 'Shastri Marg,Jaunpur,Uttar Pradesh', 'lon': '82.6399'},
      '222002': {'lat': '25.7656', 'name': 'T.D. College,Jaunpur,Uttar Pradesh', 'lon': '82.5918'},
      '222003': {'lat': '25.7073', 'name': 'Saraikhwaja,Jaunpur,Uttar Pradesh', 'lon': '82.6941'},
      '591313': {'lat': '16.2565', 'name': 'Hadalga,Belagavi,Karnataka', 'lon': '74.4819'},
      '591312': {'lat': '16.1413', 'name': 'Masaguppi,Belagavi,Karnataka', 'lon': '75.4731'},
      '591311': {'lat': '15.8071', 'name': 'Kudchi Town,Belagavi,Karnataka', 'lon': '74.7262'},
      '591310': {'lat': '16.1785', 'name': 'Bhairnatti,Belagavi,Karnataka', 'lon': '75.1861'},
      '591317': {'lat': '15.7231', 'name': 'Bastwad,Belagavi,Karnataka', 'lon': '75.0543'},
      '591316': {'lat': '15.9579', 'name': 'Ugarkhurd Town,Belagavi,Karnataka', 'lon': '75.0499'},
      '591315': {'lat': '16.1195', 'name': 'Shedbal,Belagavi,Karnataka', 'lon': '74.823'},
      '591314': {'lat': '15.9579', 'name': 'Sankeshwar Sugar Factory,Belagavi,Karnataka', 'lon': '75.0499'},
      '832111': {'lat': '22.9195', 'name': 'Narwa Mines ,East Singhbhum,Jharkhand', 'lon': '85.7645'},
      '224284': {'lat': '26.8741', 'name': 'Astikaan,Ambedkar Nagar,Uttar Pradesh', 'lon': '81.8578'},
      '415509': {'lat': '18.3569', 'name': 'Varkute Malawadi,Satara,Maharashtra', 'lon': '76.1027'},
      '415508': {'lat': '18.7965', 'name': 'Vavarhire,Satara,Maharashtra', 'lon': '75.5778'},
      '700022': {'lat': '22.55', 'name': 'Hastings,Kolkata,West Bengal', 'lon': '88.3333'},
      '415505': {'lat': '18.0813', 'name': 'Jamb(Khatav),Satara,Maharashtra', 'lon': '75.0376'},
      '415504': {'lat': '18.0813', 'name': 'Mol (Diskal),Satara,Maharashtra', 'lon': '75.0376'},
      '415507': {'lat': '17.4586', 'name': 'Pimpari (K.Khatav),Satara,Maharashtra', 'lon': '74.6924'},
      '415506': {'lat': '18.0813', 'name': 'Mandave,Satara,Maharashtra', 'lon': '75.0376'},
      '415501': {'lat': '17.3254', 'name': 'Nigadi,Satara,Maharashtra', 'lon': '74.4408'},
      '415503': {'lat': '17.7705', 'name': 'Ranashingwadi,Satara,Maharashtra', 'lon': '74.3305'},
      '415502': {'lat': '17.7079', 'name': 'Pusegaon,Satara,Maharashtra', 'lon': '74.317'},
      '413217': {'lat': '17.4574', 'name': 'Nagore,Solapur,Maharashtra', 'lon': '76.2932'},
      '413216': {'lat': '17.4816', 'name': 'Basalegaon,Solapur,Maharashtra', 'lon': '76.2036'},
      '413215': {'lat': '17.6409', 'name': 'Hotagi,Solapur,Maharashtra', 'lon': '76.167'},
      '413214': {'lat': '17.8736', 'name': 'Anjangaon  Khilobache,Solapur,Maharashtra', 'lon': '75.6408'},
      '413213': {'lat': '17.796', 'name': 'Gotewadi,Solapur,Maharashtra', 'lon': '75.6584'},
      '413212': {'lat': '17.6409', 'name': 'Bhimanagar,Solapur,Maharashtra', 'lon': '76.167'},
      '413211': {'lat': '17.6409', 'name': 'Niranarshingpur,Solapur,Maharashtra', 'lon': '76.167'},
      '413210': {'lat': '17.6409', 'name': 'Mauje Ujani,Solapur,Maharashtra', 'lon': '76.167'},
      '303805': {'lat': '27.0427', 'name': 'Rundal,Jaipur,Rajasthan', 'lon': '75.7711'},
      '600030': {'lat': '13.0797', 'name': 'Shenoy Nagar,Chennai,Tamil Nadu', 'lon': '80.2244'},
      '600033': {'lat': '13.0389', 'name': 'Mambalam R.S.,Chennai,Tamil Nadu', 'lon': '80.2258'},
      '600032': {'lat': '13.0659', 'name': 'Ekkaduthangal,Chennai,Tamil Nadu', 'lon': '80.2365'},
      '600035': {'lat': '13.0269', 'name': 'Nandanam,Chennai,Tamil Nadu', 'lon': '80.2406'},
      '600034': {'lat': '13.0606', 'name': 'Nungambakkam High Road,Chennai,Tamil Nadu', 'lon': '80.2344'},
      '413219': {'lat': '17.8702', 'name': 'Korsegaon,Solapur,Maharashtra', 'lon': '76.4236'},
      '413218': {'lat': '17.6526', 'name': 'Safale,Solapur,Maharashtra', 'lon': '76.35'},
      '462039': {'lat': '23.2519', 'name': 'Trilanga,Bhopal,Madhya Pradesh', 'lon': '77.8742'},
      '462038': {'lat': '21.6415', 'name': 'Eintkhedi,Bhopal,Madhya Pradesh', 'lon': '75.9091'},
      '481771': {'lat': '22.4937', 'name': 'Silgi,Mandla,Madhya Pradesh', 'lon': '80.3921'},
      '611101': {'lat': '10.746', 'name': 'Adiakkamangalam,Tiruvarur,Tamil Nadu', 'lon': '79.6933'},
      '462033': {'lat': '23.2519', 'name': 'RGPV,Bhopal,Madhya Pradesh', 'lon': '77.8742'},
      '462031': {'lat': '23.2519', 'name': '3 EME Centre,Bhopal,Madhya Pradesh', 'lon': '77.8742'},
      '462030': {'lat': '23.7363', 'name': 'Bairagarh Kalan,Bhopal,Madhya Pradesh', 'lon': '79.0039'},
      '517113': {'lat': '13.6318', 'name': 'Cherukuvaripalle,Chittoor,Andhra Pradesh', 'lon': '78.7486'},
      '517112': {'lat': '13.511', 'name': 'Sankampalle,Chittoor,Andhra Pradesh', 'lon': '79.0167'},
      '388250': {'lat': '22.955', 'name': 'Angadi,Kheda,Gujarat', 'lon': '73.3084'},
      '171301': {'lat': '31.2508', 'name': 'Sunni Bhajji,Shimla,Himachal Pradesh', 'lon': '77.2156'},
      '560300': {'lat': '13.0082', 'name': 'Bangalore International Airport,Bengaluru,Karnataka', 'lon': '77.5293'},
      '388255': {'lat': '23.0476', 'name': 'Felsani,Kheda,Gujarat', 'lon': '73.3952'},
      '630313': {'lat': '9.9989', 'name': 'Chockanathapuram,Sivaganga,Tamil Nadu', 'lon': '78.6317'},
      '444717': {'lat': '21.3428', 'name': 'Kulangana,Amravati,Maharashtra', 'lon': '77.3003'},
      '630311': {'lat': '9.9174', 'name': 'Velayuthapattinam,Sivaganga,Tamil Nadu', 'lon': '78.7746'},
      '444710': {'lat': '20.862', 'name': 'Shendurjana Dha,Amravati,Maharashtra', 'lon': '77.8889'},
      '444711': {'lat': '20.7762', 'name': 'Nimbhora Bodkhe,Amravati,Maharashtra', 'lon': '78.0551'},
      '642120': {'lat': '10.8274', 'name': 'Vellalapalayam,Coimbatore,Tamil Nadu', 'lon': '77.1841'},
      '263626': {'lat': '29.5363', 'name': 'Rajyura,Almora,Uttarakhand', 'lon': '79.7331'},
      '507303': {'lat': '17.4631', 'name': 'Gandugulapalli,Khammam,Telangana', 'lon': '80.0752'},
      '530004': {'lat': '17.7167', 'name': 'LIC Building,Visakhapatnam,Andhra Pradesh', 'lon': '83.2833'},
      '847403': {'lat': '26.204', 'name': 'Deep,Madhubani,Bihar', 'lon': '86.3005'},
      '847402': {'lat': '26.2153', 'name': 'Sudairatauli,Madhubani,Bihar', 'lon': '86.2668'},
      '847401': {'lat': '26.3181', 'name': 'Andhra,Madhubani,Bihar', 'lon': '86.2747'},
      '847407': {'lat': '26.1138', 'name': 'Naruar,Darbhanga,Bihar', 'lon': '86.0253'},
      '847405': {'lat': '26.0979', 'name': 'Dasauth,Darbhanga,Bihar', 'lon': '86.2553'},
      '847404': {'lat': '26.3223', 'name': 'Choramahrail,Madhubani,Bihar', 'lon': '86.2337'},
      '523334': {'lat': '15.3714', 'name': 'Nekunambad,Prakasam,Andhra Pradesh', 'lon': '79.1321'},
      '523335': {'lat': '15.6797', 'name': 'Papinenipalle,Prakasam,Andhra Pradesh', 'lon': '78.9335'},
      '523336': {'lat': '15.6298', 'name': 'Mohiddin Puram,Prakasam,Andhra Pradesh', 'lon': '79.0267'},
      '847408': {'lat': '26.1155', 'name': 'Mahpatia,Madhubani,Bihar', 'lon': '86.4488'},
      '523330': {'lat': '15.8816', 'name': 'Obulakkapalli,Prakasam,Andhra Pradesh', 'lon': '79.3081'},
      '523331': {'lat': '15.8855', 'name': 'Ayanamukkala,Prakasam,Andhra Pradesh', 'lon': '79.1169'},
      '523332': {'lat': '15.6954', 'name': 'Malyavanthunipadu,Prakasam,Andhra Pradesh', 'lon': '79.2562'},
      '523333': {'lat': '15.6219', 'name': 'Maratithanda,Prakasam,Andhra Pradesh', 'lon': '79.076'},
      '689111': {'lat': '8.5321', 'name': 'Tiruvalla RS,Pathanamthitta,Kerala', 'lon': '76.9613'},
      '689110': {'lat': '9.2841', 'name': 'Nedumpuram,Pathanamthitta,Kerala', 'lon': '76.929'},
      '689113': {'lat': '8.5321', 'name': 'Alumthuruthy,Pathanamthitta,Kerala', 'lon': '76.9613'},
      '689112': {'lat': '8.5321', 'name': 'Chathankary,Pathanamthitta,Kerala', 'lon': '76.9613'},
      '689115': {'lat': '8.5321', 'name': 'Thirumoolapuram,Pathanamthitta,Kerala', 'lon': '76.9613'},
      '206249': {'lat': '27.1089', 'name': 'Pasuwa,Auraiya,Uttar Pradesh', 'lon': '79.7669'},
      '206244': {'lat': '26.7128', 'name': 'Dhupkari,Auraiya,Uttar Pradesh', 'lon': '79.6004'},
      '206245': {'lat': '26.8965', 'name': 'Vijaypura,Etawah,Uttar Pradesh', 'lon': '78.8638'},
      '451666': {'lat': '21.6608', 'name': 'Jamathi,Barwani,Madhya Pradesh', 'lon': '75.2651'},
      '206247': {'lat': '26.6169', 'name': 'Adhasi,Auraiya,Uttar Pradesh', 'lon': '79.4251'},
      '451660': {'lat': '21.925', 'name': 'Bhoinda,Barwani,Madhya Pradesh', 'lon': '76.171'},
      '206241': {'lat': '26.757', 'name': 'Dashehra,Auraiya,Uttar Pradesh', 'lon': '79.3268'},
      '247778': {'lat': '29.5515', 'name': 'Hath Choya,Muzaffarnagar,Uttar Pradesh', 'lon': '77.2855'},
      '365535': {'lat': '21.4016', 'name': 'Bhesvadi,Amreli,Gujarat', 'lon': '72.1227'},
      '477447': {'lat': '25.7583', 'name': 'Mihoni,Bhind,Madhya Pradesh', 'lon': '78.657'},
      '241404': {'lat': '27.6297', 'name': 'Harrai,Hardoi,Uttar Pradesh', 'lon': '80.2195'},
      '221208': {'lat': '25.6106', 'name': 'Garthama,Varanasi,Uttar Pradesh', 'lon': '82.8293'},
      '221202': {'lat': '25.4551', 'name': 'Mangari,Varanasi,Uttar Pradesh', 'lon': '82.8822'},
      '221201': {'lat': '25.4508', 'name': 'Anai,Varanasi,Uttar Pradesh', 'lon': '82.7366'},
      '261301': {'lat': '27.1601', 'name': 'Unchakhera Ajai,Sitapur,Uttar Pradesh', 'lon': '80.9416'},
      '847225': {'lat': '26.4754', 'name': 'Damo,Madhubani,Bihar', 'lon': '86.1026'},
      '221206': {'lat': '25.5242', 'name': 'Khalispur,Varanasi,Uttar Pradesh', 'lon': '82.8401'},
      '221204': {'lat': '25.4396', 'name': 'Ramapur Hathiwar,Varanasi,Uttar Pradesh', 'lon': '82.8022'},
      '285223': {'lat': '25.9246', 'name': 'Sikri Vyas,Jalaun,Uttar Pradesh', 'lon': '79.3444'},
      '822132': {'lat': '24.253', 'name': 'Bishrampur (Palamau),Palamau,Jharkhand', 'lon': '83.925'},
      '370135': {'lat': '23.4384', 'name': 'Vamka,Kachchh,Gujarat', 'lon': '70.4901'},
      '370130': {'lat': '23.0632', 'name': 'Chandia,Kachchh,Gujarat', 'lon': '69.9166'},
      '144501': {'lat': '31.1941', 'name': 'Jasso Mazara,Nawanshahr,Punjab', 'lon': '75.8422'},
      '144502': {'lat': '31.1617', 'name': 'Lidhar Kalan,Nawanshahr,Punjab', 'lon': '75.8769'},
      '144503': {'lat': '31.2567', 'name': 'Katarian,Nawanshahr,Punjab', 'lon': '75.9443'},
      '144504': {'lat': '31.1739', 'name': 'Bharo Mazara,Nawanshahr,Punjab', 'lon': '75.8813'},
      '144505': {'lat': '31.2053', 'name': 'Kaleran,Nawanshahr,Punjab', 'lon': '75.9537'},
      '144506': {'lat': '31.2046', 'name': 'Mahil Gailan,Nawanshahr,Punjab', 'lon': '76.0721'},
      '144507': {'lat': '31.1186', 'name': 'Mokandpur,Nawanshahr,Punjab', 'lon': '75.9558'},
      '144508': {'lat': '31.1918', 'name': 'Naura,Nawanshahr,Punjab', 'lon': '76.0508'},
      '144509': {'lat': '31.1632', 'name': 'Khankhana,Nawanshahr,Punjab', 'lon': '75.9391'},
      '263658': {'lat': '29.8053', 'name': 'Timilkhal,Almora,Uttarakhand', 'lon': '79.3763'},
      '700025': {'lat': '22.4826', 'name': 'Lansdowne Market,Kolkata,West Bengal', 'lon': '88.4744'},
      '485881': {'lat': '24.359', 'name': 'Dighiakala,Satna,Madhya Pradesh', 'lon': '81.0902'},
      '457336': {'lat': '23.2224', 'name': 'Kalalia,Ratlam,Madhya Pradesh', 'lon': '75.1063'},
      '713519': {'lat': '22.4069', 'name': 'Chakshanjandi,Purba Bardhaman,West Bengal', 'lon': '88.1497'},
      '457331': {'lat': '22.5881', 'name': 'Dhamedi,Ratlam,Madhya Pradesh', 'lon': '75.5002'},
      '457333': {'lat': '23.6755', 'name': 'Panchewa,Ratlam,Madhya Pradesh', 'lon': '74.9508'},
      '713515': {'lat': '23.3392', 'name': 'Nadanghat,Purba Bardhaman,West Bengal', 'lon': '88.3057'},
      '713514': {'lat': '23.3392', 'name': 'Singhi,Bankura,West Bengal', 'lon': '88.3057'},
      '457339': {'lat': '23.869', 'name': 'Hanumaniya,Ratlam,Madhya Pradesh', 'lon': '75.4755'},
      '713513': {'lat': '24.0811', 'name': 'Purbasthali,Bankura,West Bengal', 'lon': '87.9102'},
      '713512': {'lat': '23.3867', 'name': 'Chhatni,Purba Bardhaman,West Bengal', 'lon': '88.4436'},
      '509106': {'lat': '16.2732', 'name': 'Manigilla,Mahabub Nagar,Telangana', 'lon': '78.2248'},
      '244928': {'lat': '28.8067', 'name': 'Kemri (Rampur),Rampur,Uttar Pradesh', 'lon': '79.2048'},
      '796101': {'lat': '23.5558', 'name': 'Vairengte,Kolasib,Mizoram', 'lon': '92.8299'},
      '244922': {'lat': '28.4884', 'name': 'Madhukar,Rampur,Uttar Pradesh', 'lon': '78.9892'},
      '804435': {'lat': '25.0099', 'name': 'Pandabigha,Gaya,Bihar', 'lon': '84.8813'},
      '804434': {'lat': '25.1595', 'name': 'Lakhawar ED,Jehanabad,Bihar', 'lon': '85.0235'},
      '244927': {'lat': '28.0112', 'name': 'Naglia Aqil,Rampur,Uttar Pradesh', 'lon': '79.2852'},
      '804432': {'lat': '25.2492', 'name': 'Jaitipur Kurwa,Jehanabad,Bihar', 'lon': '85.1088'},
      '244925': {'lat': '29.0318', 'name': 'Daryal,Rampur,Uttar Pradesh', 'lon': '78.8593'},
      '804430': {'lat': '25.1595', 'name': 'K.Bhupat,Gaya,Bihar', 'lon': '85.0235'},
      '521185': {'lat': '16.5627', 'name': 'Ramireddipalli,Krishna,Andhra Pradesh', 'lon': '80.8006'},
      '284127': {'lat': '25.6867', 'name': 'Bhattagaon,Jhansi,Uttar Pradesh', 'lon': '80.1843'},
      '521181': {'lat': '17.0183', 'name': 'Narasimharaopalem,Krishna,Andhra Pradesh', 'lon': '80.8255'},
      '521180': {'lat': '16.7242', 'name': 'Kanchikacharla,Krishna,Andhra Pradesh', 'lon': '80.3625'},
      '521183': {'lat': '16.6192', 'name': 'Muppalla (Krishna),Krishna,Andhra Pradesh', 'lon': '80.3488'},
      '521182': {'lat': '16.6735', 'name': 'Kodavatikallu,Krishna,Andhra Pradesh', 'lon': '80.2056'},
      '855115': {'lat': '26.1925', 'name': 'Singhari,Kishanganj,Bihar', 'lon': '87.7239'},
      '284125': {'lat': '25.0242', 'name': 'Lake-view-camp,Lalitpur,Uttar Pradesh', 'lon': '78.5946'},
      '383340': {'lat': '23.1279', 'name': 'Indran,Sabarkantha,Gujarat', 'lon': '73.3138'},
      '249180': {'lat': '30.5659', 'name': 'Chulyani,Tehri Garhwal,Uttarakhand', 'lon': '78.0766'},
      '229301': {'lat': '26.3151', 'name': 'Bachrawan,Raebareli,Uttar Pradesh', 'lon': '80.9135'},
      '383345': {'lat': '22.8371', 'name': 'Ambalia,Ahmedabad,Gujarat', 'lon': '73.5102'},
      '847222': {'lat': '25.6134', 'name': 'Navkarhi,Madhubani,Bihar', 'lon': '85.9872'},
      '581102': {'lat': '14.7107', 'name': 'Akkialur,Haveri,Karnataka', 'lon': '75.1825'},
      '622303': {'lat': '10.3723', 'name': 'Kothakottai,Pudukkottai,Tamil Nadu', 'lon': '78.8892'},
      '229306': {'lat': '26.3589', 'name': 'Ondeeh,Raebareli,Uttar Pradesh', 'lon': '81.3324'},
      '241203': {'lat': '27.0896', 'name': 'Majhigawan Gadeora,Hardoi,Uttar Pradesh', 'lon': '80.6608'},
      '250002': {'lat': '28.9605', 'name': 'Lisari Gate,Meerut,Uttar Pradesh', 'lon': '77.7279'},
      '581101': {'lat': '14.7928', 'name': 'Kanchinegalur,Haveri,Karnataka', 'lon': '75.2418'},
      '229307': {'lat': '26.2105', 'name': 'Lahnga,Raebareli,Uttar Pradesh', 'lon': '81.5099'},
      '507306': {'lat': '17.2618', 'name': 'Ganeshpadu,Khammam,Telangana', 'lon': '80.99'},
      '782448': {'lat': '25.8533', 'name': 'Jengkha,Karbi Anglon,Assam', 'lon': '92.8827'},
      '277502': {'lat': '25.7501', 'name': 'Narhi,Ballia,Uttar Pradesh', 'lon': '84.0615'},
      '483119': {'lat': '23.1179', 'name': 'Bhaironghat Pipariya,Jabalpur,Madhya Pradesh', 'lon': '79.5015'},
      '229304': {'lat': '26.4861', 'name': 'Pure Thakur Ram Tewari,Raebareli,Uttar Pradesh', 'lon': '81.4244'},
      '782441': {'lat': '25.8862', 'name': 'Wirwar,Karbi Anglon,Assam', 'lon': '93.0109'},
      '782440': {'lat': '25.8862', 'name': 'Changmaji,Nagaon,Assam', 'lon': '93.0109'},
      '782442': {'lat': '26.0162', 'name': 'Sankardev Nagar,Karbi Anglon,Assam', 'lon': '92.9777'},
      '782445': {'lat': '25.8862', 'name': 'Barangatoli Bazar,Nagaon,Assam', 'lon': '93.0109'},
      '782447': {'lat': '25.749', 'name': 'Taralangso No 1,Karbi Anglon,Assam', 'lon': '93.17'},
      '782446': {'lat': '25.7962', 'name': 'Watijor,Karbi Anglon,Assam', 'lon': '93.0465'},
      '603108': {'lat': '12.4875', 'name': 'Madayathur,Kanchipuram,Tamil Nadu', 'lon': '79.9017'},
      '603109': {'lat': '12.5906', 'name': 'Thathalur,Kanchipuram,Tamil Nadu', 'lon': '80.0823'},
      '800019': {'lat': '25.6716', 'name': 'C.D.A,Patna,Bihar', 'lon': '85.0646'},
      '522426': {'lat': '16.1146', 'name': 'Pasivemula,Guntur,Andhra Pradesh', 'lon': '79.4212'},
      '603102': {'lat': '12.5029', 'name': 'Reactor Research Centre ,Kanchipuram,Tamil Nadu', 'lon': '80.0955'},
      '603103': {'lat': '12.7873', 'name': 'Kelambakkam,Kanchipuram,Tamil Nadu', 'lon': '80.2216'},
      '603104': {'lat': '11.8723', 'name': 'Poonjeri,Kanchipuram,Tamil Nadu', 'lon': '79.9328'},
      '603105': {'lat': '12.6822', 'name': 'Manambathi,Kanchipuram,Tamil Nadu', 'lon': '80.1218'},
      '522421': {'lat': '16.5522', 'name': 'Paluvai,Guntur,Andhra Pradesh', 'lon': '79.5382'},
      '603107': {'lat': '12.6627', 'name': 'Edayambudur,Kanchipuram,Tamil Nadu', 'lon': '79.8668'},
      '246446': {'lat': '30.0863', 'name': 'Kanda Maikhura,Chamoli,Uttarakhand', 'lon': '78.7796'},
      '275202': {'lat': '25.8022', 'name': 'Lediha,Ghazipur,Uttar Pradesh', 'lon': '83.1067'},
      '521235': {'lat': '17.0703', 'name': 'Sriniketan,Krishna,Andhra Pradesh', 'lon': '80.5977'},
      '521230': {'lat': '16.7138', 'name': 'Kuntamukkala,Krishna,Andhra Pradesh', 'lon': '80.6361'},
      '670731': {'lat': '11.7238', 'name': 'Thondernadu,Wayanad,Kerala', 'lon': '75.9567'},
      '815318': {'lat': '24.2963', 'name': 'Chittardih,Giridh,Jharkhand', 'lon': '86.0916'},
      '734008': {'lat': '26.731', 'name': 'Salugarah,Darjiling,West Bengal', 'lon': '88.9486'},
      '465441': {'lat': '23.5511', 'name': 'Tanodiya,Shajapur,Madhya Pradesh', 'lon': '76.0658'},
      '800018': {'lat': '25.6716', 'name': 'Bataganj,Patna,Bihar', 'lon': '85.0646'},
      '503322': {'lat': '18.6631', 'name': 'Telangana University,Nizamabad,Telangana', 'lon': '77.9191'},
      '735121': {'lat': '24.2943', 'name': 'Kantipara,Jalpaiguri,West Bengal', 'lon': '87.9142'},
      '275201': {'lat': '25.8573', 'name': 'Pali,Ghazipur,Uttar Pradesh', 'lon': '83.7113'},
      '263621': {'lat': '29.7822', 'name': 'Naulakote,Almora,Uttarakhand', 'lon': '79.4896'},
      '623712': {'lat': '9.4185', 'name': 'Venkittankurichi,Ramanathapuram,Tamil Nadu', 'lon': '78.6109'},
      '431718': {'lat': '18.432', 'name': 'Markhel,Nanded,Maharashtra', 'lon': '77.4721'},
      '431719': {'lat': '18.6027', 'name': 'Ravankola,Nanded,Maharashtra', 'lon': '77.3936'},
      '442404': {'lat': '20.0156', 'name': 'Chandrapur TPS,Chandrapur,Maharashtra', 'lon': '79.1078'},
      '431717': {'lat': '18.6983', 'name': 'Tadkhel,Nanded,Maharashtra', 'lon': '77.4823'},
      '442406': {'lat': '20.0306', 'name': 'Tadali,Chandrapur,Maharashtra', 'lon': '79.1944'},
      '522529': {'lat': '16.3052', 'name': 'Yerraguntlapadu,Guntur,Andhra Pradesh', 'lon': '80.2199'},
      '431712': {'lat': '19.6664', 'name': 'Banchincholi,Nanded,Maharashtra', 'lon': '77.1612'},
      '442401': {'lat': '20.1448', 'name': 'Chandrapur H.O,Chandrapur,Maharashtra', 'lon': '79.0792'},
      '442402': {'lat': '20.2301', 'name': 'Chandrapur name,Chandrapur,Maharashtra', 'lon': '78.946'},
      '442403': {'lat': '20.5774', 'name': 'Babupeth,Chandrapur,Maharashtra', 'lon': '78.8567'},
      '494447': {'lat': '18.5912', 'name': 'Usoor,Bijapur(CGH),Chattisgarh', 'lon': '80.9432'},
      '494446': {'lat': '18.8432', 'name': 'Tarlaguda,Bijapur(CGH),Chattisgarh', 'lon': '80.7761'},
      '382165': {'lat': '23.1697', 'name': 'Medha,Mahesana,Gujarat', 'lon': '72.2576'},
      '631004': {'lat': '13.0947', 'name': 'Seyyoor,Vellore,Tamil Nadu', 'lon': '79.692'},
      '494442': {'lat': '18.9808', 'name': 'Kodenar,Bastar,Chattisgarh', 'lon': '81.6678'},
      '494441': {'lat': '19.4717', 'name': 'Karli,Dantewada,Chattisgarh', 'lon': '81.5735'},
      '735122': {'lat': '25.2463', 'name': 'Fatehmamud,Cooch Behar,West Bengal', 'lon': '88.0977'},
      '799130': {'lat': '23.4118', 'name': 'Rayermura,West Tripura,Tripura', 'lon': '91.2931'},
      '624003': {'lat': '11.7946', 'name': 'Nagalnagar,Dindigul,Tamil Nadu', 'lon': '78.3592'},
      '494449': {'lat': '18.8682', 'name': 'Pondoom,Dantewada,Chattisgarh', 'lon': '81.4659'},
      '494448': {'lat': '18.7086', 'name': 'Pamgal,Bijapur(CGH),Chattisgarh', 'lon': '80.5972'},
      '614706': {'lat': '10.5452', 'name': 'Pinnathur,Tiruvarur,Tamil Nadu', 'lon': '79.4941'},
      '127025': {'lat': '28.7792', 'name': 'Aun,Bhiwani,Haryana', 'lon': '76.3416'},
      '127026': {'lat': '28.5898', 'name': 'Atela Khurd,Bhiwani,Haryana', 'lon': '76.096'},
      '127027': {'lat': '28.8839', 'name': 'Sai,Bhiwani,Haryana', 'lon': '76.2535'},
      '614702': {'lat': '10.4714', 'name': 'Idaiyur,Tiruvarur,Tamil Nadu', 'lon': '79.5787'},
      '127021': {'lat': '28.6418', 'name': 'Bus Stand Bhiwani,Bhiwani,Haryana', 'lon': '75.9763'},
      '127022': {'lat': '28.4948', 'name': 'Chang Road,Bhiwani,Haryana', 'lon': '76.2706'},
      '614701': {'lat': '10.3385', 'name': 'Eripurakarai,Thanjavur,Tamil Nadu', 'lon': '79.3685'},
      '507154': {'lat': '17.5983', 'name': 'Siil Campus,Khammam,Telangana', 'lon': '80.2896'},
      '127028': {'lat': '28.6816', 'name': 'Noonsar,Bhiwani,Haryana', 'lon': '75.6247'},
      '127029': {'lat': '28.6252', 'name': 'Kusumbhi,Bhiwani,Haryana', 'lon': '76.0301'},
      '614708': {'lat': '10.5452', 'name': 'Sendamangalam(TTP),Tiruvarur,Tamil Nadu', 'lon': '79.4941'},
      '508117': {'lat': '17.5042', 'name': 'Wadaparthy,Nalgonda,Telangana', 'lon': '78.9777'},
      '305813': {'lat': '26.3462', 'name': 'Bhamolao,Ajmer,Rajasthan', 'lon': '74.8826'},
      '843122': {'lat': '26.1674', 'name': 'Parsauni Pagahia,Muzaffarpur,Bihar', 'lon': '85.1044'},
      '305812': {'lat': '26.683', 'name': 'Chhota Naraina,Ajmer,Rajasthan', 'lon': '74.9412'},
      '496334': {'lat': '22.7383', 'name': 'Jaikari,Jashpur,Chattisgarh', 'lon': '84.1085'},
      '305817': {'lat': '26.449896', 'name': 'Central University of Rajasthan,24,Bandar Sindari', 'lon': '74.639915'},
      '515872': {'lat': '14.9562', 'name': 'Herial,Ananthapur,Andhra Pradesh', 'lon': '77.3058'},
      '515870': {'lat': '14.9819', 'name': 'Bollanaguddem,Ananthapur,Andhra Pradesh', 'lon': '77.6801'},
      '515871': {'lat': '14.9459', 'name': 'Bommanahal,Ananthapur,Andhra Pradesh', 'lon': '77.1561'},
      '800015': {'lat': '25.6716', 'name': 'Patna Sectt.,Patna,Bihar', 'lon': '85.0646'},
      '305815': {'lat': '26.6736', 'name': 'Khatoli,Ajmer,Rajasthan', 'lon': '74.8332'},
      '642003': {'lat': '10.6248', 'name': 'Kanjampatti,Coimbatore,Tamil Nadu', 'lon': '77.0761'},
      '493448': {'lat': '20.9096', 'name': 'Narra,Mahasamund,Chattisgarh', 'lon': '82.4833'},
      '306504': {'lat': '24.956', 'name': 'Kumatiya,Pali,Rajasthan', 'lon': '73.1418'},
      '509340': {'lat': '16.7949', 'name': 'Kuchur,Mahabub Nagar,Telangana', 'lon': '78.0859'},
      '638115': {'lat': '11.231', 'name': 'Kasbapettai,Erode,Tamil Nadu', 'lon': '77.7182'},
      '413132': {'lat': '18.3936', 'name': 'Loni Deokar,Pune,Maharashtra', 'lon': '74.5945'},
      '505480': {'lat': '18.0279', 'name': 'Ammanagurthi,Karim Nagar,Telangana', 'lon': '79.5069'},
      '638116': {'lat': '11.131', 'name': 'Kanjikovil,Erode,Tamil Nadu', 'lon': '77.814'},
      '638111': {'lat': '11.0161', 'name': 'Mulayampoondi,Erode,Tamil Nadu', 'lon': '77.951'},
      '638110': {'lat': '11.131', 'name': 'Thoranavavi,Erode,Tamil Nadu', 'lon': '77.814'},
      '413133': {'lat': '18.3936', 'name': 'Katfal,Pune,Maharashtra', 'lon': '74.5945'},
      '821108': {'lat': '24.9851', 'name': 'Telari,Rohtas,Bihar', 'lon': '83.8185'},
      '821109': {'lat': '25.1961', 'name': 'Salathua,Kaimur (Bhabua),Bihar', 'lon': '83.7194'},
      '454010': {'lat': '22.3595', 'name': 'Sodpur,Dhar,Madhya Pradesh', 'lon': '75.2582'},
      '263625': {'lat': '29.5194', 'name': 'Mathena,Almora,Uttarakhand', 'lon': '79.7434'},
      '821102': {'lat': '24.9368', 'name': 'Kharenda,Kaimur (Bhabua),Bihar', 'lon': '83.6964'},
      '821103': {'lat': '25.1152', 'name': 'Bararhi,Kaimur (Bhabua),Bihar', 'lon': '83.5167'},
      '643211': {'lat': '11.4125', 'name': 'Cherumulli,Nilgiris,Tamil Nadu', 'lon': '77.1296'},
      '712612': {'lat': '23.2506', 'name': 'Mandaran,Hooghly,West Bengal', 'lon': '87.9259'},
      '821107': {'lat': '25.1495', 'name': 'Ghordiha,Rohtas,Bihar', 'lon': '84.033'},
      '732138': {'lat': '25.2576', 'name': 'Parbatidanga,Malda,West Bengal', 'lon': '87.9852'},
      '574199': {'lat': '12.8312', 'name': 'Assaigoli,Dakshina Kannada,Karnataka', 'lon': '74.9173'},
      '396040': {'lat': '20.8057', 'name': 'Ruzvani,Navsari,Gujarat', 'lon': '72.2687'},
      '766118': {'lat': '20.2471', 'name': 'Gondabahali,Kalahandi,Odisha', 'lon': '82.8264'},
      '410101': {'lat': '19.0675386', 'name': 'Pashane,Raigarh(MH),Maharashtra', 'lon': '73.4586316'},
      '314028': {'lat': '23.8133', 'name': 'Kolkhanda,Dungarpur,Rajasthan', 'lon': '73.941'},
      '392030': {'lat': '21.7667', 'name': 'Nikora,Bharuch,Gujarat', 'lon': '73.1167'},
      '766111': {'lat': '20.2807', 'name': 'Boden,Nuapada,Odisha', 'lon': '82.5924'},
      '766110': {'lat': '19.9769', 'name': 'Santapur,Kalahandi,Odisha', 'lon': '83.3488'},
      '314026': {'lat': '23.52', 'name': 'Silohi,Dungarpur,Rajasthan', 'lon': '74.0203'},
      '314027': {'lat': '23.6618', 'name': 'Suraj Gaon,Dungarpur,Rajasthan', 'lon': '73.5295'},
      '325204': {'lat': '25.306', 'name': 'Toran,Kota,Rajasthan', 'lon': '75.7398'},
      '314021': {'lat': '24.6628', 'name': 'Raiki,Dungarpur,Rajasthan', 'lon': '73.2627'},
      '314022': {'lat': '24.2145', 'name': 'Valai,Dungarpur,Rajasthan', 'lon': '74.0743'},
      '314023': {'lat': '23.7884', 'name': 'Bhasore,Dungarpur,Rajasthan', 'lon': '73.9815'},
      '627652': {'lat': '8.3945', 'name': 'Ittamozhi,Tirunelveli,Tamil Nadu', 'lon': '77.8487'},
      '627651': {'lat': '8.3202', 'name': 'Kooduthalai,Tirunelveli,Tamil Nadu', 'lon': '77.9384'},
      '627657': {'lat': '8.4873', 'name': 'Kurugapuram,Tirunelveli,Tamil Nadu', 'lon': '77.7183'},
      '627654': {'lat': '8.4471', 'name': 'Anaikarai,Tirunelveli,Tamil Nadu', 'lon': '77.6237'},
      '212208': {'lat': '25.6177', 'name': 'Ahmedpur Asrauli,Allahabad,Uttar Pradesh', 'lon': '81.5136'},
      '712615': {'lat': '23.2158', 'name': 'Moloypur,Hooghly,West Bengal', 'lon': '87.9764'},
      '502001': {'lat': '17.8409', 'name': 'Sangareddy H.O,Medak,Telangana', 'lon': '78.3705'},
      '560037': {'lat': '13.0108', 'name': 'Marathahalli Colony,Bengaluru,Karnataka', 'lon': '77.7494'},
      '673513': {'lat': '11.6729', 'name': 'Chathangottunada,Kozhikode,Kerala', 'lon': '75.7673'},
      '673517': {'lat': '11.7256', 'name': 'Chuzhali,Kozhikode,Kerala', 'lon': '75.6492'},
      '535005': {'lat': '17.9817', 'name': 'Ainada,Visakhapatnam,Andhra Pradesh', 'lon': '82.8311'},
      '535004': {'lat': '18.0792', 'name': 'Sarika,Vizianagaram,Andhra Pradesh', 'lon': '83.362'},
      '535006': {'lat': '18.1525', 'name': 'Munginapalli,Vizianagaram,Andhra Pradesh', 'lon': '83.5015'},
      '535001': {'lat': '18.1169', 'name': 'Vizianagaram Market,Vizianagaram,Andhra Pradesh', 'lon': '83.4115'},
      '614902': {'lat': '10.2637', 'name': 'Athankaraipatti,Pudukkottai,Tamil Nadu', 'lon': '78.9154'},
      '535003': {'lat': '18.213', 'name': 'Karatam,Vizianagaram,Andhra Pradesh', 'lon': '83.3882'},
      '535002': {'lat': '18.1244', 'name': 'Dasannapeta,Vizianagaram,Andhra Pradesh', 'lon': '83.374'},
      '342037': {'lat': '26.5723', 'name': 'Kelva Kallan,Jodhpur,Rajasthan', 'lon': '73.2276'},
      '625019': {'lat': '9.9174', 'name': 'Thuvariman,Madurai,Tamil Nadu', 'lon': '78.0546'},
      '263145': {'lat': '28.9407', 'name': 'Patharchatta,Udham Singh Nagar,Uttarakhand', 'lon': '79.709'},
      '560034': {'lat': '13.0685', 'name': "St. John's Medical College,Bengaluru,Karnataka", 'lon': '77.6197'},
      '509228': {'lat': '17.3049', 'name': 'Inmulnarva,Mahabub Nagar,Telangana', 'lon': '78.7584'},
      '520008': {'lat': '16.6698', 'name': 'Vj Polytechnic,Krishna,Andhra Pradesh', 'lon': '81.0473'},
      '246474': {'lat': '29.7394', 'name': 'Senu,Chamoli,Uttarakhand', 'lon': '79.9315'},
      '614906': {'lat': '10.4571', 'name': 'Athikottai,Thanjavur,Tamil Nadu', 'lon': '79.3728'},
      '520003': {'lat': '17.1751', 'name': 'Gandhinagaram (Vijayawada),Krishna,Andhra Pradesh', 'lon': '82.1662'},
      '520002': {'lat': '16.6698', 'name': 'Durga Agraharam,Krishna,Andhra Pradesh', 'lon': '81.0473'},
      '520001': {'lat': '16.6698', 'name': 'Vja Rly Divl Office,Krishna,Andhra Pradesh', 'lon': '81.0473'},
      '520007': {'lat': '16.4919', 'name': 'Vasanthanagar,Krishna,Andhra Pradesh', 'lon': '80.6794'},
      '520004': {'lat': '16.5203', 'name': 'Machavaram (Krishna),Krishna,Andhra Pradesh', 'lon': '80.6642'},
      '483110': {'lat': '23.1489', 'name': 'Salaiya,Jabalpur,Madhya Pradesh', 'lon': '80.1589'},
      '263149': {'lat': '29.0983', 'name': 'D.F. Nagla,Udham Singh Nagar,Uttarakhand', 'lon': '79.3622'},
      '491665': {'lat': '21.0394', 'name': 'Rengadabri,Rajnandgaon,Chattisgarh', 'lon': '80.8192'},
      '712617': {'lat': '22.4044', 'name': 'Kishorepur,Hooghly,West Bengal', 'lon': '88.0156'},
      '491668': {'lat': '20.8931', 'name': 'Kumarda,Rajnandgaon,Chattisgarh', 'lon': '80.7732'},
      '751004': {'lat': '20.6966', 'name': 'Utkal University,Khorda,Odisha', 'lon': '84.974'},
      '560035': {'lat': '13.0108', 'name': 'Carmelram,Bengaluru,Karnataka', 'lon': '77.7494'},
      '142011': {'lat': '30.7811', 'name': 'Dhurkot,Moga,Punjab', 'lon': '75.2246'},
      '721212': {'lat': '22.7356', 'name': 'Rathipur,West Midnapore,West Bengal', 'lon': '87.807'},
      '334801': {'lat': '27.404', 'name': 'Sinjguru,Bikaner,Rajasthan', 'lon': '73.3983'},
      '334802': {'lat': '27.888', 'name': 'Mainsar,Bikaner,Rajasthan', 'lon': '74.7458'},
      '334803': {'lat': '27.5811', 'name': 'Nokha Villege,Bikaner,Rajasthan', 'lon': '73.6224'},
      '334804': {'lat': '28.1984', 'name': 'Panchoo,Bikaner,Rajasthan', 'lon': '73.6766'},
      '591254': {'lat': '16.1195', 'name': 'Kot,Belagavi,Karnataka', 'lon': '74.823'},
      '334808': {'lat': '27.6826', 'name': 'Delitalai,Bikaner,Rajasthan', 'lon': '73.7978'},
      '212206': {'lat': '25.4524', 'name': 'Vaish Kanti,Kaushambi,Uttar Pradesh', 'lon': '81.4268'},
      '770075': {'lat': '21.8641', 'name': 'Hemgir Road,Sundergarh,Odisha', 'lon': '83.6945'},
      '242223': {'lat': '27.6776', 'name': 'Deoriajhala,Shahjahanpur,Uttar Pradesh', 'lon': '79.8133'},
      '770074': {'lat': '21.9355', 'name': 'Rouldega,Sundergarh,Odisha', 'lon': '84.1698'},
      '281305': {'lat': '27.4986', 'name': 'Sihora,Mathura,Uttar Pradesh', 'lon': '77.7642'},
      '841421': {'lat': '26.0529', 'name': 'Sikati Bhikham,Saran,Bihar', 'lon': '84.772'},
      '822134': {'lat': '24.3178', 'name': 'Karuie,Garhwa,Jharkhand', 'lon': '83.778'},
      '815311': {'lat': '24.1803', 'name': 'Matrukha,Giridh,Jharkhand', 'lon': '86.2631'},
      '627757': {'lat': '9.3466', 'name': 'Inamkovilpatti,Tirunelveli,Tamil Nadu', 'lon': '77.6725'},
      '262542': {'lat': '29.6921', 'name': 'Surauli,Pithoragarh,Uttarakhand', 'lon': '80.2063'},
      '505403': {'lat': '18.3991', 'name': 'Hanmajipet,Karim Nagar,Telangana', 'lon': '79.0372'},
      '509338': {'lat': '17.2995', 'name': 'Chilmulmailwar,Mahabub Nagar,Telangana', 'lon': '78.6996'},
      '609504': {'lat': '10.8546', 'name': 'Panangudi,Tiruvarur,Tamil Nadu', 'lon': '79.6337'},
      '263624': {'lat': '29.6413', 'name': 'Khastilari,Almora,Uttarakhand', 'lon': '79.6383'},
      '609502': {'lat': '10.9016', 'name': 'Mudikondan,Tiruvarur,Tamil Nadu', 'lon': '79.6421'},
      '609503': {'lat': '10.8679', 'name': 'Sarabojirajapuram,Tiruvarur,Tamil Nadu', 'lon': '79.5041'},
      '466651': {'lat': '23.1721', 'name': 'Muktarnagar,Sehore,Madhya Pradesh', 'lon': '76.8002'},
      '770070': {'lat': '21.2483', 'name': 'Tunmura,Sundergarh,Odisha', 'lon': '84.5993'},
      '391210': {'lat': '22.019', 'name': 'Karmal,Vadodara,Gujarat', 'lon': '72.942'},
      '416620': {'lat': '16.2388', 'name': 'Kalsuli,Sindhudurg,Maharashtra', 'lon': '73.4089'},
      '416623': {'lat': '16.4177', 'name': 'Mond,Sindhudurg,Maharashtra', 'lon': '73.4587'},
      '735303': {'lat': '24.293', 'name': 'Dhulia Khalisa,Cooch Behar,West Bengal', 'lon': '88.454'},
      '735304': {'lat': '24.293', 'name': 'Upen Chowki Kuchlibari,Cooch Behar,West Bengal', 'lon': '88.454'},
      '735305': {'lat': '24.1096', 'name': 'Padamati,Jalpaiguri,West Bengal', 'lon': '87.6599'},
      '416626': {'lat': '16.0599', 'name': 'Revandi,Sindhudurg,Maharashtra', 'lon': '73.3592'},
      '281306': {'lat': '27.426', 'name': 'Kajrauthi,Mathura,Uttar Pradesh', 'lon': '78.1607'},
      '182121': {'lat': '32.8267', 'name': 'Garhi (Udhampur),Udhampur,Jammu & Kashmir', 'lon': '75.1739'},
      '182122': {'lat': '32.704', 'name': 'Dhiari,Udhampur,Jammu & Kashmir', 'lon': '75.4258'},
      '262546': {'lat': '30.1795', 'name': 'Gunji,Pithoragarh,Uttarakhand', 'lon': '80.8575'},
      '182124': {'lat': '32.8088', 'name': 'Hartrian,Udhampur,Jammu & Kashmir', 'lon': '75.2207'},
      '182125': {'lat': '33.0489', 'name': 'Galiote,Udhampur,Jammu & Kashmir', 'lon': '75.1354'},
      '182126': {'lat': '32.8783', 'name': 'Bainsta,Udhampur,Jammu & Kashmir', 'lon': '74.7154'},
      '182127': {'lat': '32.894', 'name': 'Battal,Udhampur,Jammu & Kashmir', 'lon': '75.3276'},
      '380061': {'lat': '22.9764', 'name': 'Ghatlodia,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380060': {'lat': '22.9764', 'name': 'Bhadaj,Ahmedabad,Gujarat', 'lon': '72.836'},
      '380063': {'lat': '22.9764', 'name': 'Sola H B C,Ahmedabad,Gujarat', 'lon': '72.836'},
      '621712': {'lat': '10.8986', 'name': 'Puvalur,Tiruchirappalli,Tamil Nadu', 'lon': '78.8303'},
      '621715': {'lat': '10.9686', 'name': 'Manjamedu Karaipakkam,Ariyalur,Tamil Nadu', 'lon': '79.1677'},
      '621714': {'lat': '11.2677', 'name': 'Nallampalayam,Ariyalur,Tamil Nadu', 'lon': '79.1703'},
      '621717': {'lat': '10.8705', 'name': 'Kilumathur,Perambalur,Tamil Nadu', 'lon': '78.729'},
      '621716': {'lat': '11.2985', 'name': 'Nallarikkai,Perambalur,Tamil Nadu', 'lon': '79.1122'},
      '621719': {'lat': '11.3106', 'name': 'Adhanakurichi,Ariyalur,Tamil Nadu', 'lon': '79.2566'},
      '621718': {'lat': '11.2396', 'name': 'Iluppaiyur,Ariyalur,Tamil Nadu', 'lon': '79.1257'},
      '481778': {'lat': '22.3985', 'name': 'Sigholi Mohgaon,Dindori,Madhya Pradesh', 'lon': '80.5699'},
      '306501': {'lat': '26.0119', 'name': 'Sapooni,Pali,Rajasthan', 'lon': '73.7934'},
      '306503': {'lat': '25.517', 'name': 'Banta R.S.,Pali,Rajasthan', 'lon': '73.4546'},
      '384012': {'lat': '23.8153', 'name': 'Ganpat Vidyanagar,Mahesana,Gujarat', 'lon': '73.1628'},
      '621729': {'lat': '10.9095', 'name': 'Ariyalur Cement Factory,Ariyalur,Tamil Nadu', 'lon': '78.9893'},
      '415710': {'lat': '17.7444', 'name': 'Dayal,Ratnagiri,Maharashtra', 'lon': '73.3201'},
      '415711': {'lat': '17.65', 'name': 'Vakavali,Ratnagiri,Maharashtra', 'lon': '73.4667'},
      '415712': {'lat': '17.7537', 'name': 'Pachavali,Ratnagiri,Maharashtra', 'lon': '73.2166'},
      '415713': {'lat': '18.1113', 'name': 'Asud,Ratnagiri,Maharashtra', 'lon': '73.5195'},
      '415714': {'lat': '17.8476', 'name': 'Sukondi,Ratnagiri,Maharashtra', 'lon': '73.0908'},
      '415715': {'lat': '17.4468', 'name': 'Kulvandi,Ratnagiri,Maharashtra', 'lon': '73.6291'},
      '415716': {'lat': '18.8256', 'name': 'Mugij,Ratnagiri,Maharashtra', 'lon': '74.5988'},
      '415717': {'lat': '17.9181', 'name': 'Kelshi,Ratnagiri,Maharashtra', 'lon': '73.0636'},
      '415718': {'lat': '17.8813', 'name': 'Kurwal Jawali,Ratnagiri,Maharashtra', 'lon': '73.4259'},
      '415719': {'lat': '17.6556', 'name': 'Karul,Ratnagiri,Maharashtra', 'lon': '73.2927'},
      '209841': {'lat': '26.7745', 'name': 'Chharihara,Unnao,Uttar Pradesh', 'lon': '80.6371'},
      '757093': {'lat': '21.7431', 'name': 'Joka (Mayurbhanj),Mayurbhanj,Odisha', 'lon': '85.538'},
      '815313': {'lat': '24.5978', 'name': 'Ganwan,Giridh,Jharkhand', 'lon': '85.8752'},
      '607005': {'lat': '11.7312', 'name': 'Capperquary,Cuddalore,Tamil Nadu', 'lon': '79.5871'},
      '643202': {'lat': '11.3632', 'name': 'Hubbathalai,Nilgiris,Tamil Nadu', 'lon': '76.7579'},
      '384003': {'lat': '23.8153', 'name': 'Mahesana Ongc Colony,Mahesana,Gujarat', 'lon': '73.1628'},
      '606611': {'lat': '12.2331', 'name': 'Vedanatham,Tiruvannamalai,Tamil Nadu', 'lon': '79.1176'},
      '384002': {'lat': '23.8153', 'name': 'Mahesana I E,Mahesana,Gujarat', 'lon': '73.1628'},
      '700110': {'lat': '22.7379', 'name': 'Tirtha Bharati,North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '503201': {'lat': '18.0743', 'name': 'Old Varni,Nizamabad,Telangana', 'lon': '78.1237'},
      '626114': {'lat': '9.3303', 'name': 'Poolangal,Virudhunagar,Tamil Nadu', 'lon': '78.2914'},
      '602106': {'lat': '12.9277', 'name': 'Mambakkam,Kanchipuram,Tamil Nadu', 'lon': '79.9065'},
      '637103': {'lat': '11.5196', 'name': 'Chinandipalayam,Namakkal,Tamil Nadu', 'lon': '77.9772'},
      '637102': {'lat': '11.6074', 'name': 'Koothampalayam,Namakkal,Tamil Nadu', 'lon': '77.944'},
      '637101': {'lat': '11.3163', 'name': 'Kurukkapatti,Namakkal,Tamil Nadu', 'lon': '78.1361'},
      '642201': {'lat': '10.8985', 'name': 'Periapatti,Coimbatore,Tamil Nadu', 'lon': '77.4049'},
      '637107': {'lat': '11.1889', 'name': 'Okkilipaltti,Namakkal,Tamil Nadu', 'lon': '78.4506'},
      '637105': {'lat': '11.615', 'name': 'Bodinaickenpatti,Namakkal,Tamil Nadu', 'lon': '77.8776'},
      '637104': {'lat': '11.1373', 'name': 'Pullagoundampatti,Namakkal,Tamil Nadu', 'lon': '78.5063'},
      '626112': {'lat': '9.443', 'name': 'Amanakkunatham,Virudhunagar,Tamil Nadu', 'lon': '77.8389'},
      '246129': {'lat': '29.8754', 'name': 'Dudharkhal,Pauri Garhwal,Uttarakhand', 'lon': '78.7908'},
      '246128': {'lat': '29.9078', 'name': 'Saurkhet,Pauri Garhwal,Uttarakhand', 'lon': '78.5887'},
      '506175': {'lat': '17.7505', 'name': 'Yenkiryal,Warangal,Telangana', 'lon': '79.1482'},
      '626113': {'lat': '9.443', 'name': 'Pandalgudi,Virudhunagar,Tamil Nadu', 'lon': '77.8389'},
      '232101': {'lat': '25.3719', 'name': 'Dul;hipur,Varanasi,Uttar Pradesh', 'lon': '83.1559'},
      '477222': {'lat': '26.2834', 'name': 'Dehgoun,Bhind,Madhya Pradesh', 'lon': '78.455'},
      '232103': {'lat': '25.3864', 'name': 'Bhabhaura,Varanasi,Uttar Pradesh', 'lon': '83.2519'},
      '232104': {'lat': '25.3881', 'name': 'Bisauri,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '621722': {'lat': '10.9095', 'name': 'Kulamanickam,Tiruchirappalli,Tamil Nadu', 'lon': '78.9893'},
      '232106': {'lat': '25.8055', 'name': 'Arkaura,Varanasi,Uttar Pradesh', 'lon': '83.1428'},
      '477227': {'lat': '26.1375', 'name': 'Adokhar,Bhind,Madhya Pradesh', 'lon': '78.4595'},
      '232108': {'lat': '25.2849', 'name': 'Kavarua,Varanasi,Uttar Pradesh', 'lon': '83.2142'},
      '232109': {'lat': '25.4202', 'name': 'Kharkhara,Varanasi,Uttar Pradesh', 'lon': '83.134'},
      '621104': {'lat': '11.1107', 'name': 'C.Mavilingai,Perambalur,Tamil Nadu', 'lon': '78.5909'},
      '281301': {'lat': '27.3687', 'name': 'Vairani,Mathura,Uttar Pradesh', 'lon': '77.8458'},
      '212106': {'lat': '25.1543', 'name': 'Sujani,Allahabad,Uttar Pradesh', 'lon': '81.9128'},
      '621105': {'lat': '11.0632', 'name': 'Nedungur,Tiruchirappalli,Tamil Nadu', 'lon': '78.8243'},
      '815315': {'lat': '24.1803', 'name': 'Dhuraita,Giridh,Jharkhand', 'lon': '86.2631'},
      '471105': {'lat': '24.8114', 'name': 'Jamtuli,Chhatarpur,Madhya Pradesh', 'lon': '79.754'},
      '385505': {'lat': '24.2835', 'name': 'Bhakodar,Banaskantha,Gujarat', 'lon': '72.0319'},
      '621106': {'lat': '11.2733', 'name': 'Kolathur,Perambalur,Tamil Nadu', 'lon': '78.893'},
      '471101': {'lat': '25.5363', 'name': 'Chandra Nagar,Chhatarpur,Madhya Pradesh', 'lon': '79.4939'},
      '385506': {'lat': '24.4073', 'name': 'Sardar Krishinagar,Banaskantha,Gujarat', 'lon': '72.3629'},
      '625012': {'lat': '9.9', 'name': 'Avanivapuram,Madurai,Tamil Nadu', 'lon': '78.1222'},
      '621107': {'lat': '11.2448', 'name': 'Tirupayar,Perambalur,Tamil Nadu', 'lon': '78.8196'},
      '625016': {'lat': '9.9234', 'name': 'Madurai Railway Divl Office,Madurai,Tamil Nadu', 'lon': '78.0898'},
      '535250': {'lat': '18.0648', 'name': 'Bhandevpuram,Visakhapatnam,Andhra Pradesh', 'lon': '83.2278'},
      '625014': {'lat': '9.9996', 'name': 'Velichanatham,Madurai,Tamil Nadu', 'lon': '78.1377'},
      '625015': {'lat': '9.9485', 'name': 'Thiagarajar Engg College,Madurai,Tamil Nadu', 'lon': '78.1616'},
      '261203': {'lat': '27.2952', 'name': 'Padukha,Sitapur,Uttar Pradesh', 'lon': '81.1622'},
      '625018': {'lat': '10.0086', 'name': 'Kumaram,Madurai,Tamil Nadu', 'lon': '78.0933'},
      '571213': {'lat': '12.1793', 'name': 'Ponnappasanthe,Kodagu,Karnataka', 'lon': '75.9422'},
      '737136': {'lat': '27.1876', 'name': 'Majitar,East Sikkim,Sikkim', 'lon': '88.4997'},
      '621101': {'lat': '11.2181', 'name': 'Ammapalayam,Perambalur,Tamil Nadu', 'lon': '78.7443'},
      '764045': {'lat': '18.392', 'name': 'Tandiki,Malkangiri,Odisha', 'lon': '81.9329'},
      '389365': {'lat': '23.0752', 'name': 'Malu,Panch Mahals,Gujarat', 'lon': '73.5862'},
      '764047': {'lat': '18.1743', 'name': 'Kongurukonda,Malkangiri,Odisha', 'lon': '81.5399'},
      '764046': {'lat': '18.8376', 'name': 'Parkonmala,Malkangiri,Odisha', 'lon': '82.538'},
      '570019': {'lat': '12.5028', 'name': 'Udayagiri (Mysuru),Mysuru,Karnataka', 'lon': '76.9021'},
      '570018': {'lat': '12.3394', 'name': 'Koorgalli,Mysuru,Karnataka', 'lon': '76.5729'},
      '695589': {'lat': '8.5842', 'name': 'Santhigiri,Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '517561': {'lat': '13.8339', 'name': 'Nethakuppam,Chittoor,Andhra Pradesh', 'lon': '79.2342'},
      '695587': {'lat': '8.5842', 'name': 'Chempazhanthy,Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '695586': {'lat': '8.5637', 'name': "St.Xavier's College,Thiruvananthapuram,Kerala", 'lon': '76.8484'},
      '695585': {'lat': '8.5842', 'name': 'Sainik School (Thiruvananthapuram),Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '695584': {'lat': '8.6133', 'name': 'Kattaikonam,Thiruvananthapuram,Kerala', 'lon': '76.9062'},
      '394190': {'lat': '21.8141', 'name': 'Kholvad,Surat,Gujarat', 'lon': '73.151'},
      '695582': {'lat': '8.5673', 'name': 'Kazhakuttam,Thiruvananthapuram,Kerala', 'lon': '76.8741'},
      '695581': {'lat': '8.5842', 'name': 'Karyavattom,Thiruvananthapuram,Kerala', 'lon': '76.8766'},
      '517569': {'lat': '13.5691', 'name': 'Erramaraju Palle,Chittoor,Andhra Pradesh', 'lon': '78.9462'},
      '713212': {'lat': '23.8037', 'name': 'Amlajora,Purba Bardhaman,West Bengal', 'lon': '87.3955'},
      '713213': {'lat': '23.8798', 'name': 'Benachity,Purba Bardhaman,West Bengal', 'lon': '87.5259'},
      '713210': {'lat': '23.8798', 'name': 'V.K.Nagar,Paschim Bardhaman,West Bengal', 'lon': '87.5259'},
      '686661': {'lat': '9.8841', 'name': 'Muvattupuzha H.O,Ernakulam,Kerala', 'lon': '76.5473'},
      '713216': {'lat': '23.8798', 'name': 'name Centre,Purba Bardhaman,West Bengal', 'lon': '87.5259'},
      '713217': {'lat': '23.8798', 'name': 'Oyaria,Purba Bardhaman,West Bengal', 'lon': '87.5259'},
      '591108': {'lat': '15.2469', 'name': 'Ambewadi,Belagavi,Karnataka', 'lon': '75.3207'},
      '591109': {'lat': '15.467', 'name': 'Mutnal,Belagavi,Karnataka', 'lon': '74.9391'},
      '591106': {'lat': '15.9756', 'name': 'Mangyankop,Belagavi,Karnataka', 'lon': '75.623'},
      '591107': {'lat': '15.7423', 'name': 'Hattialur,Belagavi,Karnataka', 'lon': '75.0616'},
      '591104': {'lat': '15.7193', 'name': 'Udikeri,Belagavi,Karnataka', 'lon': '74.9448'},
      '143502': {'lat': '31.779', 'name': 'Jaintipur,Amritsar,Punjab', 'lon': '75.1063'},
      '591102': {'lat': '15.8137', 'name': 'Bailhongal S.R. Chowk,Belagavi,Karnataka', 'lon': '74.9632'},
      '415705': {'lat': '17.4088', 'name': 'Adur (Ratnagiri),Ratnagiri,Maharashtra', 'lon': '73.204'},
      '143507': {'lat': '31.5287', 'name': 'Hardo Jhande,Gurdaspur,Punjab', 'lon': '75.34'},
      '389360': {'lat': '23.6395', 'name': 'Navakuwa,Panch Mahals,Gujarat', 'lon': '73.0568'},
      '370650': {'lat': '23.3129', 'name': 'Kanday,Kachchh,Gujarat', 'lon': '68.7574'},
      '302017': {'lat': '26.8378', 'name': 'Mrec Jaipur,Jaipur,Rajasthan', 'lon': '75.8256'},
      '370655': {'lat': '23.3129', 'name': 'Chhadura,Kachchh,Gujarat', 'lon': '68.7574'},
      '403705': {'lat': '15.2103', 'name': 'Pirla,South Goa,Goa', 'lon': '74.0882'},
      '361280': {'lat': '23.4282', 'name': 'Arablush,Jamnagar,Gujarat', 'lon': '72.4018'},
      '415703': {'lat': '16.9653', 'name': 'Kotluk,Ratnagiri,Maharashtra', 'lon': '73.5574'},
      '403706': {'lat': '15.2651', 'name': 'Cacora,South Goa,Goa', 'lon': '74.096'},
      '142052': {'lat': '30.9962', 'name': 'Ratta Khera Pb. Singh,Firozpur,Punjab', 'lon': '74.835'},
      '136038': {'lat': '30.0451', 'name': 'Amin,Kurukshetra,Haryana', 'lon': '76.7862'},
      '403707': {'lat': '15.2393', 'name': 'Talaulim,South Goa,Goa', 'lon': '73.9624'},
      '382755': {'lat': '24.1107', 'name': 'Zinzuwada,Surendra Nagar,Gujarat', 'lon': '72.4664'},
      '136030': {'lat': '29.9441', 'name': 'Ramgarh Ror,Kurukshetra,Haryana', 'lon': '76.5292'},
      '136033': {'lat': '29.9254', 'name': 'Ladana Chaku,Kaithal,Haryana', 'lon': '76.3327'},
      '136035': {'lat': '30.0099', 'name': 'Agondh,Kaithal,Haryana', 'lon': '76.2711'},
      '382750': {'lat': '23.3651', 'name': 'Naviyani,Surendra Nagar,Gujarat', 'lon': '71.8178'},
      '792103': {'lat': '27.4031', 'name': 'Piyong,Namsai,Arunachal Pradesh', 'lon': '95.9613'},
      '792102': {'lat': '27.7955', 'name': 'Kherem,Lohit,Arunachal Pradesh', 'lon': '96.1825'},
      '303119': {'lat': '26.7001', 'name': 'Bagawas Ahiran,Jaipur,Rajasthan', 'lon': '75.2486'},
      '413008': {'lat': '17.8026', 'name': 'Barur,Solapur,Maharashtra', 'lon': '76.4093'},
      '326021': {'lat': '24.4977', 'name': 'Ruparel,Jhalawar,Rajasthan', 'lon': '76.2505'},
      '360480': {'lat': '22.2116', 'name': 'Butavadar,Jamnagar,Gujarat', 'lon': '71.5193'},
      '413002': {'lat': '18.1432', 'name': 'Mangalwar Peth (Solapur),Solapur,Maharashtra', 'lon': '76.1009'},
      '413003': {'lat': '18.0452', 'name': 'Medical College (Solapur),Solapur,Maharashtra', 'lon': '76.1434'},
      '413001': {'lat': '18.0452', 'name': 'Navi Peth Solapur,Solapur,Maharashtra', 'lon': '76.1434'},
      '413006': {'lat': '17.6484', 'name': 'Ashok Chowk,Solapur,Maharashtra', 'lon': '75.9936'},
      '413007': {'lat': '18.0452', 'name': 'Solapur name,Solapur,Maharashtra', 'lon': '76.1434'},
      '413004': {'lat': '18.0452', 'name': 'Indiranagar (Solapur),Solapur,Maharashtra', 'lon': '76.1434'},
      '413005': {'lat': '18.0452', 'name': 'Sakhar Peth,Solapur,Maharashtra', 'lon': '76.1434'},
      '493445': {'lat': '21.239', 'name': 'Jalki,Mahasamund,Chattisgarh', 'lon': '82.2135'},
      '502130': {'lat': '17.9693', 'name': 'Toguta,Medak,Telangana', 'lon': '78.6319'},
      '630108': {'lat': '10.0514', 'name': 'Peerkalaikadu,Sivaganga,Tamil Nadu', 'lon': '78.8387'},
      '630106': {'lat': '10.1096', 'name': 'K.Alagapuri,Sivaganga,Tamil Nadu', 'lon': '78.7956'},
      '630107': {'lat': '10.1461', 'name': 'Ac.School,Sivaganga,Tamil Nadu', 'lon': '78.8031'},
      '630104': {'lat': '10.1037', 'name': 'Kandanurperumalkoil,Sivaganga,Tamil Nadu', 'lon': '78.8254'},
      '630105': {'lat': '10.1856', 'name': 'Kothamangalam (Sivaganga),Sivaganga,Tamil Nadu', 'lon': '78.8044'},
      '630102': {'lat': '10.12', 'name': 'Chettinad,Sivaganga,Tamil Nadu', 'lon': '78.7938'},
      '630103': {'lat': '10.1721', 'name': 'Nemathanpatti,Sivaganga,Tamil Nadu', 'lon': '78.7794'},
      '630101': {'lat': '10.1403', 'name': 'Chockalingampudur,Sivaganga,Tamil Nadu', 'lon': '78.6648'},
      '480882': {'lat': '22.3252', 'name': 'Bhongakheda,Seoni,Madhya Pradesh', 'lon': '79.458'},
      '262122': {'lat': '27.7072', 'name': 'Chandia Hazara,Pilibhit,Uttar Pradesh', 'lon': '80.2856'},
      '480880': {'lat': '22.1712', 'name': 'Pindrai- Buttey,Seoni,Madhya Pradesh', 'lon': '79.5119'},
      '613602': {'lat': '10.448', 'name': 'Chitrakudi,Thanjavur,Tamil Nadu', 'lon': '78.6379'},
      '480886': {'lat': '22.5475', 'name': 'Purwamal,Seoni,Madhya Pradesh', 'lon': '79.6032'},
      '480887': {'lat': '22.3396', 'name': 'Mohgaon Kachhi,Seoni,Madhya Pradesh', 'lon': '79.3835'},
      '480884': {'lat': '22.1968', 'name': 'Sadak Seoni,Seoni,Madhya Pradesh', 'lon': '79.9486'},
      '262124': {'lat': '27.3976', 'name': 'MATHANA  JAPTI,Pilibhit,Uttar Pradesh', 'lon': '80.3821'},
      '403708': {'lat': '15.2798', 'name': 'Seraulim,South Goa,Goa', 'lon': '73.9228'},
      '480888': {'lat': '22.7014', 'name': 'Pathadeori,Seoni,Madhya Pradesh', 'lon': '79.6461'},
      '425524': {'lat': '21.1489', 'name': 'Amoda,Jalgaon,Maharashtra', 'lon': '75.8288'},
      '322033': {'lat': '26.2103', 'name': 'Doongri,Sawai Madhopur,Rajasthan', 'lon': '76.6306'},
      '523101': {'lat': '15.3863', 'name': 'Kondikandukur,Prakasam,Andhra Pradesh', 'lon': '79.8154'},
      '847215': {'lat': '26.3994', 'name': 'B.Belahi,Madhubani,Bihar', 'lon': '86.0777'},
      '721626': {'lat': '22.198', 'name': 'Garbari,East Midnapore,West Bengal', 'lon': '87.9301'},
      '322030': {'lat': '26.35', 'name': 'Gotod,Sawai Madhopur,Rajasthan', 'lon': '76.3599'},
      '523105': {'lat': '15.2096', 'name': 'Kandukur South,Prakasam,Andhra Pradesh', 'lon': '79.7173'},
      '523104': {'lat': '15.2096', 'name': 'Woollapalem,Prakasam,Andhra Pradesh', 'lon': '79.7173'},
      '678762': {'lat': '10.977', 'name': 'Perimbadari,Palakkad,Kerala', 'lon': '76.448'},
      '523109': {'lat': '15.2285', 'name': 'Pandalapadu,Prakasam,Andhra Pradesh', 'lon': '79.9048'},
      '785692': {'lat': '26.45', 'name': 'lonpotia,Sibsagar,Assam', 'lon': '94.0833'},
      '475685': {'lat': '25.7882', 'name': 'Bargain,Datia,Madhya Pradesh', 'lon': '78.3777'},
      '475686': {'lat': '25.6362', 'name': 'Baroni Datia,Datia,Madhya Pradesh', 'lon': '78.3579'},
      '402109': {'lat': '18.4383154', 'name': 'Wali,Raigarh(MH),Maharashtra', 'lon': '73.120204'},
      '402108': {'lat': '18.6555', 'name': 'Kusumbale,Raigarh(MH),Maharashtra', 'lon': '72.867911'},
      '475682': {'lat': '25.8505', 'name': 'Rasulpura,Datia,Madhya Pradesh', 'lon': '78.5688'},
      '402105': {'lat': '18.136328', 'name': 'Chikhlap,Raigarh(MH),Maharashtra', 'lon': '73.110896'},
      '402104': {'lat': '18.716723', 'name': 'Talegaon Tarfe Goregaon,Raigarh(MH),Maharashtra', 'lon': '73.683277'},
      '402107': {'lat': '18.73754', 'name': 'Ambeghar,Raigarh(MH),Maharashtra', 'lon': '73.094407'},
      '402106': {'lat': '18.4311209', 'name': 'Wakanfata,Raigarh(MH),Maharashtra', 'lon': '73.1674078'},
      '402101': {'lat': '18.136325', 'name': 'Ambet,Raigarh(MH),Maharashtra', 'lon': '73.110899'},
      '402103': {'lat': '18.237056', 'name': 'Panhalghar,Raigarh(MH),Maharashtra', 'lon': '73.283594'},
      '402102': {'lat': '18.1115994', 'name': 'Dasgaon,Raigarh(MH),Maharashtra', 'lon': '73.3953328'},
      '335037': {'lat': '29.7819', 'name': 'Sardpura Jiwan,Ganganagar,Rajasthan', 'lon': '74.0035'},
      '221005': {'lat': '25.6825', 'name': 'Lanka (Varanasi),Varanasi,Uttar Pradesh', 'lon': '83.1125'},
      '560038': {'lat': '13.2257', 'name': 'Indiranagar (Bangalore),Bengaluru,Karnataka', 'lon': '77.575'},
      '224139': {'lat': '26.2662', 'name': 'Dulahoopur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.5659'},
      '224137': {'lat': '26.341', 'name': 'Alauddinur,Ambedkar Nagar,Uttar Pradesh', 'lon': '83.0214'},
      '224135': {'lat': '26.6457', 'name': 'Dhrampur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.2781'},
      '224132': {'lat': '26.3641', 'name': 'Pariyawan,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.1857'},
      '224133': {'lat': '26.3899', 'name': 'Jyoti Sadan,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4029'},
      '335038': {'lat': '30.0237', 'name': 'Phusewala,Ganganagar,Rajasthan', 'lon': '73.7378'},
      '335039': {'lat': '29.4667', 'name': '3mk,Ganganagar,Rajasthan', 'lon': '73.6309'},
      '680661': {'lat': '10.1241', 'name': 'Nadavaramba,Thrissur,Kerala', 'lon': '76.2484'},
      '680662': {'lat': '10.4712', 'name': 'Kottanallur,Thrissur,Kerala', 'lon': '76.3162'},
      '531011': {'lat': '17.5218', 'name': 'Tanthadi,Visakhapatnam,Andhra Pradesh', 'lon': '82.9482'},
      '680664': {'lat': '10.2084', 'name': 'Thiruvanchikulam,Thrissur,Kerala', 'lon': '76.2072'},
      '680665': {'lat': '10.1241', 'name': 'Panangad (Thrissur),Thrissur,Kerala', 'lon': '76.2484'},
      '680666': {'lat': '10.2181', 'name': 'Eriyad,Thrissur,Kerala', 'lon': '76.1593'},
      '680667': {'lat': '10.1241', 'name': 'Thuruthippuram,Ernakulam,Kerala', 'lon': '76.2484'},
      '680668': {'lat': '10.245', 'name': 'Pathazhakkad,Thrissur,Kerala', 'lon': '76.1881'},
      '680669': {'lat': '10.2326', 'name': 'Kandamkulam,Thrissur,Kerala', 'lon': '76.1951'},
      '531019': {'lat': '17.3144', 'name': 'Maredupudi,Visakhapatnam,Andhra Pradesh', 'lon': '82.533'},
      '249301': {'lat': '29.9042', 'name': 'Dondal,Pauri Garhwal,Uttarakhand', 'lon': '78.6634'},
      '233310': {'lat': '25.642', 'name': 'Chaura,Ghazipur,Uttar Pradesh', 'lon': '83.4617'},
      '221004': {'lat': '25.4584', 'name': 'D.L.W.,Varanasi,Uttar Pradesh', 'lon': '82.8226'},
      '246113': {'lat': '30.0307', 'name': 'Falda,Pauri Garhwal,Uttarakhand', 'lon': '78.7243'},
      '503217': {'lat': '18.3702', 'name': 'Fathepur,Nizamabad,Telangana', 'lon': '78.6183'},
      '759148': {'lat': '21.08632', 'name': 'Nehru Satabdi Nagar(Bharatpu)r,Angul,Odisha', 'lon': '85.13908'},
      '441207': {'lat': '20.4392', 'name': 'Amgaon Buti,Chandrapur,Maharashtra', 'lon': '79.8401'},
      '508266': {'lat': '17.692', 'name': 'Pedavoora,Nalgonda,Telangana', 'lon': '79.1937'},
      '606903': {'lat': '12.5878', 'name': 'Kasthambadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.2418'},
      '441206': {'lat': '20.5832', 'name': 'Paradgaon,Chandrapur,Maharashtra', 'lon': '79.8912'},
      '759141': {'lat': '21.08884', 'name': 'Patakumunda,Angul,Odisha', 'lon': '84.7544'},
      '244255': {'lat': '28.5045', 'name': 'Gangeshwari,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.2735'},
      '759143': {'lat': '20.85001', 'name': 'Badkerjang,Angul,Odisha', 'lon': '85.09999'},
      '227814': {'lat': '26.3211', 'name': 'Baqghauna,Sultanpur,Uttar Pradesh', 'lon': '81.8918'},
      '759145': {'lat': '20.8764141', 'name': 'Kandasar,Angul,Odisha', 'lon': '85.1582633'},
      '759146': {'lat': '21.08632', 'name': 'Kulei,Angul,Odisha', 'lon': '85.13908'},
      '759147': {'lat': '21.08632', 'name': 'Deepasikha,Angul,Odisha', 'lon': '85.13908'},
      '642204': {'lat': '10.4303', 'name': 'Samarayapatti,Coimbatore,Tamil Nadu', 'lon': '77.439'},
      '642205': {'lat': '10.6807', 'name': 'Pudupalayam,Coimbatore,Tamil Nadu', 'lon': '77.1398'},
      '642206': {'lat': '10.8745', 'name': 'Munduvelampatti,Coimbatore,Tamil Nadu', 'lon': '76.9756'},
      '636011': {'lat': '11.7527', 'name': 'Saminaickenpatti,Salem,Tamil Nadu', 'lon': '78.2899'},
      '786145': {'lat': '27.2366', 'name': 'Sripuria Bengali Gaon,Tinsukia,Assam', 'lon': '95.218'},
      '786146': {'lat': '27.4031', 'name': 'Chalihanagar,Tinsukia,Assam', 'lon': '95.2577'},
      '786147': {'lat': '27.5696', 'name': 'Limbuguri,Tinsukia,Assam', 'lon': '95.2973'},
      '786148': {'lat': '27.4031', 'name': 'Khetopathergaon,Tinsukia,Assam', 'lon': '95.2577'},
      '636016': {'lat': '11.7527', 'name': 'Fairlands,Salem,Tamil Nadu', 'lon': '78.2899'},
      '326024': {'lat': '24.588', 'name': 'KSTPP Jhalawar,Jhalawar,Rajasthan', 'lon': '76.1935'},
      '205119': {'lat': '27.1589', 'name': 'Darwah,Mainpuri,Uttar Pradesh', 'lon': '78.9192'},
      '636017': {'lat': '11.7527', 'name': 'Allikuttai,Salem,Tamil Nadu', 'lon': '78.2899'},
      '303806': {'lat': '26.9805', 'name': 'Naya Bas,Jaipur,Rajasthan', 'lon': '75.7243'},
      '423206': {'lat': '20.6224', 'name': 'Kashti,Nashik,Maharashtra', 'lon': '74.4832'},
      '423205': {'lat': '20.6315', 'name': 'Zodga,Nashik,Maharashtra', 'lon': '74.6235'},
      '423204': {'lat': '20.6859', 'name': 'Chirai,Nashik,Maharashtra', 'lon': '74.3441'},
      '423203': {'lat': '20.5571', 'name': 'Malegaon name,Nashik,Maharashtra', 'lon': '74.5523'},
      '423202': {'lat': '20.5825', 'name': 'Pimpalgaon,Nashik,Maharashtra', 'lon': '74.4351'},
      '423201': {'lat': '20.2592', 'name': 'Patne,Nashik,Maharashtra', 'lon': '74.0714'},
      '303801': {'lat': '26.6366', 'name': 'Pratapura,Jaipur,Rajasthan', 'lon': '75.8743'},
      '221006': {'lat': '25.4709', 'name': 'Babatpur Ad,Varanasi,Uttar Pradesh', 'lon': '82.8421'},
      '423208': {'lat': '20.2529', 'name': 'Savkarwadi,Nashik,Maharashtra', 'lon': '74.8709'},
      '829102': {'lat': '23.9122', 'name': 'Barkakana,Ramgarh,Jharkhand', 'lon': '85.5754'},
      '641043': {'lat': '10.9111', 'name': 'Sahs College,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '816102': {'lat': '24.6044', 'name': 'Baraghaghri,Pakur,Jharkhand', 'lon': '86.1935'},
      '494229': {'lat': '20.4399', 'name': 'Juganikalar,Bastar,Chattisgarh', 'lon': '81.5803'},
      '816104': {'lat': '24.6254', 'name': 'Surajbera,Pakur,Jharkhand', 'lon': '87.656'},
      '816105': {'lat': '24.6723', 'name': 'Kistonagar,Pakur,Jharkhand', 'lon': '87.8194'},
      '816106': {'lat': '24.4912', 'name': 'Rollagram,Pakur,Jharkhand', 'lon': '87.705'},
      '641042': {'lat': '10.9421', 'name': 'Kovaipudur,Coimbatore,Tamil Nadu', 'lon': '76.929'},
      '816108': {'lat': '24.7465', 'name': 'P.K.Bazar,Sahibganj,Jharkhand', 'lon': '87.4117'},
      '816109': {'lat': '25.209', 'name': 'Sahibganj College,Sahibganj,Jharkhand', 'lon': '87.6874'},
      '829108': {'lat': '23.9122', 'name': 'Giddi A,Ramgarh,Jharkhand', 'lon': '85.5754'},
      '829109': {'lat': '23.7219', 'name': 'Giddi C,Ramgarh,Jharkhand', 'lon': '85.3843'},
      '500063': {'lat': '17.3568', 'name': 'LIC Division,Hyderabad,Telangana', 'lon': '78.4524'},
      '784125': {'lat': '26.743', 'name': 'Mangaldoi H.O,Darrang,Assam', 'lon': '93.2247'},
      '500061': {'lat': '17.3568', 'name': 'Sitaphalmandi,Hyderabad,Telangana', 'lon': '78.4524'},
      '500060': {'lat': '17.3568', 'name': 'Chaithanyapuri Colony,K.V.Rangareddy,Telangana', 'lon': '78.4524'},
      '518333': {'lat': '15.825', 'name': 'Sulekeri,Kurnool,Andhra Pradesh', 'lon': '77.1168'},
      '641040': {'lat': '10.9111', 'name': 'Subramaniapuram.,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '641047': {'lat': '10.9111', 'name': 'Jothipuram,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '736145': {'lat': '25.9055', 'name': 'Nutanbosh.,Cooch Behar,West Bengal', 'lon': '87.9333'},
      '641046': {'lat': '10.9111', 'name': 'Bharathiyar University,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '191201': {'lat': '34.2947', 'name': 'Wussan,Ganderbal,Jammu & Kashmir', 'lon': '75.2'},
      '494228': {'lat': '20.2238', 'name': 'Jodekera,Bastar,Chattisgarh', 'lon': '81.4614'},
      '191203': {'lat': '34.1758', 'name': 'Sonamarg,Srinagar,Jammu & Kashmir', 'lon': '74.9148'},
      '191202': {'lat': '34.2613', 'name': 'Mamer,Srinagar,Jammu & Kashmir', 'lon': '74.9418'},
      '515465': {'lat': '14.4983', 'name': 'Araveedu,Ananthapur,Andhra Pradesh', 'lon': '78.38'},
      '441209': {'lat': '20.6703', 'name': 'Masali,Gadchiroli,Maharashtra', 'lon': '80.2624'},
      '484334': {'lat': '23.324', 'name': 'Payari,Anuppur,Madhya Pradesh', 'lon': '81.1941'},
      '454221': {'lat': '22.3844', 'name': 'Akhada,Dhar,Madhya Pradesh', 'lon': '74.8543'},
      '396375': {'lat': '20.6849', 'name': 'Dungri (Valsad),Valsad,Gujarat', 'lon': '72.92'},
      '190008': {'lat': '34.0672', 'name': 'Jawahar Nagar (Srinagar),Srinagar,Jammu & Kashmir', 'lon': '74.8257'},
      '190009': {'lat': '33.8359', 'name': 'Central Market,Srinagar,Jammu & Kashmir', 'lon': '74.8589'},
      '190004': {'lat': '33.8359', 'name': 'Batwara,Srinagar,Jammu & Kashmir', 'lon': '74.8589'},
      '190005': {'lat': '33.8359', 'name': 'Sanat Nagar,Budgam,Jammu & Kashmir', 'lon': '74.8589'},
      '190006': {'lat': '34.1621', 'name': 'Zakura,Srinagar,Jammu & Kashmir', 'lon': '74.8647'},
      '190007': {'lat': '33.8359', 'name': 'Sk Airport,Budgam,Jammu & Kashmir', 'lon': '74.8589'},
      '799254': {'lat': '24.0447', 'name': 'Dewanpassa,North Tripura,Tripura', 'lon': '91.7553'},
      '190001': {'lat': '33.8359', 'name': 'S.K.Sectrate,Srinagar,Jammu & Kashmir', 'lon': '74.8589'},
      '190002': {'lat': '34.0889', 'name': 'Safukadul,Srinagar,Jammu & Kashmir', 'lon': '74.8083'},
      '190003': {'lat': '33.4307', 'name': 'Nandpora,Srinagar,Jammu & Kashmir', 'lon': '74.8979'},
      '686019': {'lat': '9.5634', 'name': 'Thiruvanchoor,Kottayam,Kerala', 'lon': '76.528'},
      '686018': {'lat': '9.5833', 'name': 'Manganam,Kottayam,Kerala', 'lon': '76.55'},
      '767017': {'lat': '20.85192', 'name': 'Khambeswaripali,Sonapur,Odisha', 'lon': '83.8848'},
      '686548': {'lat': '9.5', 'name': 'Nalukody,Kottayam,Kerala', 'lon': '76.6944'},
      '686547': {'lat': '9.5', 'name': 'Kottangal,Pathanamthitta,Kerala', 'lon': '76.6944'},
      '686014': {'lat': '9.5634', 'name': 'Olessa,Kottayam,Kerala', 'lon': '76.528'},
      '686017': {'lat': '9.5634', 'name': 'Mariathuruthu,Kottayam,Kerala', 'lon': '76.528'},
      '686016': {'lat': '9.5634', 'name': 'Kumaranalloor,Kottayam,Kerala', 'lon': '76.528'},
      '686011': {'lat': '9.5', 'name': 'Eravinalloor,Kottayam,Kerala', 'lon': '76.5333'},
      '686542': {'lat': '9.5', 'name': 'Nedumkunnam,Kottayam,Kerala', 'lon': '76.6944'},
      '686013': {'lat': '9.5634', 'name': 'Nattakom,Kottayam,Kerala', 'lon': '76.528'},
      '686012': {'lat': '9.5634', 'name': 'Pakkil,Kottayam,Kerala', 'lon': '76.528'},
      '244251': {'lat': '28.9697', 'name': 'Khera Apraula,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.3699'},
      '431521': {'lat': '19.0874', 'name': 'Borwand BK,Parbhani,Maharashtra', 'lon': '76.6912'},
      '431520': {'lat': '18.9994', 'name': 'Parli Tps,Beed,Maharashtra', 'lon': '76.659'},
      '431523': {'lat': '18.6401', 'name': 'Radi,Beed,Maharashtra', 'lon': '76.3775'},
      '431522': {'lat': '18.1891', 'name': 'Kadepur,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '628503': {'lat': '9.9353', 'name': 'Padanthapuli 2,Tuticorin,Tamil Nadu', 'lon': '78.0297'},
      '396450': {'lat': '20.9904', 'name': 'Eru,Navsari,Gujarat', 'lon': '72.8927'},
      '623502': {'lat': '8.4189', 'name': 'Vairavanendal,Ramanathapuram,Tamil Nadu', 'lon': '77.9493'},
      '623503': {'lat': '9.0426', 'name': 'Pattanamkathan,Ramanathapuram,Tamil Nadu', 'lon': '78.0503'},
      '362255': {'lat': '21.2013', 'name': 'Jangar,Junagadh,Gujarat', 'lon': '71.6647'},
      '362250': {'lat': '21.6182', 'name': 'Budhecha,Junagadh,Gujarat', 'lon': '69.9417'},
      '825301': {'lat': '24.0078', 'name': 'Distt. Board,Hazaribag,Jharkhand', 'lon': '85.2567'},
      '535213': {'lat': '18.0657', 'name': 'Singavaram,Vizianagaram,Andhra Pradesh', 'lon': '83.5214'},
      '535214': {'lat': '18.0641', 'name': 'B S Factory,Vizianagaram,Andhra Pradesh', 'lon': '83.3347'},
      '535215': {'lat': '18.1339', 'name': 'Lakkidam,Vizianagaram,Andhra Pradesh', 'lon': '83.3078'},
      '535216': {'lat': '18.5002', 'name': 'Gudepuvalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.4224'},
      '678512': {'lat': '10.658', 'name': 'Kakkayur,Palakkad,Kerala', 'lon': '76.6409'},
      '535218': {'lat': '18.1312', 'name': 'Saripalli,Vizianagaram,Andhra Pradesh', 'lon': '83.483'},
      '622103': {'lat': '10.4761', 'name': 'Muckanamalaipatti,Pudukkottai,Tamil Nadu', 'lon': '78.7619'},
      '622102': {'lat': '10.5173', 'name': 'Uthkuli,Pudukkottai,Tamil Nadu', 'lon': '78.8375'},
      '622104': {'lat': '10.3975', 'name': 'Kudimianmalai,Pudukkottai,Tamil Nadu', 'lon': '78.6983'},
      '222162': {'lat': '25.8024', 'name': 'Sarsara,Jaunpur,Uttar Pradesh', 'lon': '82.425'},
      '222161': {'lat': '25.6135', 'name': 'Daudi,Jaunpur,Uttar Pradesh', 'lon': '82.5735'},
      '222165': {'lat': '25.6657', 'name': 'Bhiduna,Jaunpur,Uttar Pradesh', 'lon': '82.3585'},
      '562138': {'lat': '12.7527', 'name': 'Neralur,Ramanagar,Karnataka', 'lon': '76.9907'},
      '400612': {'lat': '19.1941', 'name': 'Kausa,Thane,Maharashtra', 'lon': '73.0002'},
      '400610': {'lat': '19.1941', 'name': 'Apna Bazar,Thane,Maharashtra', 'lon': '73.0002'},
      '577175': {'lat': '13.4259', 'name': 'Nagaralu,Chikkamagaluru,Karnataka', 'lon': '76.0915'},
      '400615': {'lat': '19.1941', 'name': 'Kasarvadavali,Thane,Maharashtra', 'lon': '73.0002'},
      '400614': {'lat': '19.1941', 'name': 'Belapur Node-- III,Thane,Maharashtra', 'lon': '73.0002'},
      '632505': {'lat': '13.045', 'name': 'Kilveeranam,Vellore,Tamil Nadu', 'lon': '79.4668'},
      '505470': {'lat': '18.6399', 'name': 'Mutharam,Karim Nagar,Telangana', 'lon': '79.0286'},
      '756059': {'lat': '21.2714', 'name': 'Nahanga,Baleswar,Odisha', 'lon': '86.4175'},
      '756058': {'lat': '21.6224', 'name': 'Uppardiha,Baleswar,Odisha', 'lon': '84.4045'},
      '482008': {'lat': '23.1176', 'name': 'Vidyut Nagar (Jabalpur),Jabalpur,Madhya Pradesh', 'lon': '79.9246'},
      '751005': {'lat': '20.6966', 'name': 'Sainik School (Khorda),Khorda,Odisha', 'lon': '84.974'},
      '605103': {'lat': '12.0755', 'name': 'Melpathi,Villupuram,Tamil Nadu', 'lon': '79.4489'},
      '756056': {'lat': '21.7722', 'name': 'Chhanapur,Baleswar,Odisha', 'lon': '86.0568'},
      '204101': {'lat': '27.593', 'name': 'Luheta Khurd,Hathras,Uttar Pradesh', 'lon': '78.1837'},
      '847204': {'lat': '26.0699', 'name': 'Bishnupur (Darbhanga),Darbhanga,Bihar', 'lon': '86.5509'},
      '721463': {'lat': '22.1762', 'name': 'Digha New Township,East Midnapore,West Bengal', 'lon': '87.6063'},
      '756055': {'lat': '22.0715', 'name': 'Chakabarahapur,Baleswar,Odisha', 'lon': '85.6452'},
      '751006': {'lat': '21.1067', 'name': 'Station Bazar,Khorda,Odisha', 'lon': '84.3525'},
      '751001': {'lat': '20.6966', 'name': 'Kharavela Nagar,Khorda,Odisha', 'lon': '84.974'},
      '332710': {'lat': '26.7879', 'name': 'Karad,Sikar,Rajasthan', 'lon': '75.81'},
      '284203': {'lat': '25.639', 'name': 'Moti Katra,Jhansi,Uttar Pradesh', 'lon': '79.2878'},
      '751031': {'lat': '20.2597', 'name': 'Patia,Khorda,Odisha', 'lon': '85.7899'},
      '142037': {'lat': '30.6367', 'name': 'Bir Raoke,Moga,Punjab', 'lon': '75.2903'},
      '756051': {'lat': '21.8355', 'name': 'Avana,Baleswar,Odisha', 'lon': '85.551'},
      '751002': {'lat': '20.4916', 'name': 'Kalyanpur Sasan,Khorda,Odisha', 'lon': '85.2848'},
      '517351': {'lat': '13.8464', 'name': 'Thummanamgutta,Chittoor,Andhra Pradesh', 'lon': '78.4775'},
      '517350': {'lat': '13.7032', 'name': 'Matluvaripalle,Chittoor,Andhra Pradesh', 'lon': '78.4749'},
      '517352': {'lat': '13.785', 'name': 'Thettu,Chittoor,Andhra Pradesh', 'lon': '78.4764'},
      '415527': {'lat': '19.4018', 'name': 'Kuroli Sidheshwar,Satara,Maharashtra', 'lon': '75.5209'},
      '415526': {'lat': '18.1272', 'name': 'Bhade,Satara,Maharashtra', 'lon': '74.0394'},
      '415525': {'lat': '17.9027', 'name': 'Rautwadi,Satara,Maharashtra', 'lon': '74.0813'},
      '415524': {'lat': '18.4133', 'name': 'Vikhale,Satara,Maharashtra', 'lon': '74.4814'},
      '415523': {'lat': '18.6409', 'name': 'Sangavi,Satara,Maharashtra', 'lon': '74.5597'},
      '415522': {'lat': '18.0525', 'name': 'Sakharwadi,Satara,Maharashtra', 'lon': '74.3332'},
      '415521': {'lat': '17.9982', 'name': 'Tambave (Lonand),Satara,Maharashtra', 'lon': '74.2036'},
      '415520': {'lat': '17.5477', 'name': 'Jalagewadi,Satara,Maharashtra', 'lon': '74.065'},
      '754143': {'lat': '20.7115', 'name': 'Sector-21,Jagatsinghapur,Odisha', 'lon': '85.661'},
      '767016': {'lat': '20.7317072', 'name': 'Kamsara,Sonapur,Odisha', 'lon': '83.6733979'},
      '625003': {'lat': '9.9181', 'name': 'Palanganatham,Madurai,Tamil Nadu', 'lon': '78.0807'},
      '415528': {'lat': '17.6686', 'name': 'Nandal,Satara,Maharashtra', 'lon': '74.1738'},
      '341512': {'lat': '26.4228', 'name': 'Rohindi,Nagaur,Rajasthan', 'lon': '75.0602'},
      '625002': {'lat': '10.4247', 'name': 'Ma Race Course,Madurai,Tamil Nadu', 'lon': '78.4423'},
      '757091': {'lat': '21.5851', 'name': 'Khairi Jashipur,Mayurbhanj,Odisha', 'lon': '85.9188'},
      '757092': {'lat': '20.7424', 'name': 'Labania,Mayurbhanj,Odisha', 'lon': '84.7303'},
      '389001': {'lat': '23.0937', 'name': 'Godhra Civil Lines,Panch Mahals,Gujarat', 'lon': '73.5392'},
      '210427': {'lat': '25.3006', 'name': 'Karahra Kalan,Mahoba,Uttar Pradesh', 'lon': '79.8948'},
      '210426': {'lat': '25.2295', 'name': 'Bagwaha,Mahoba,Uttar Pradesh', 'lon': '79.6678'},
      '210425': {'lat': '25.3443', 'name': 'Ainchana,Mahoba,Uttar Pradesh', 'lon': '79.6966'},
      '754140': {'lat': '21.2998', 'name': 'Borikhi,Jagatsinghapur,Odisha', 'lon': '84.5233'},
      '210423': {'lat': '25.2238', 'name': 'Jaitpur (Mahoba),Mahoba,Uttar Pradesh', 'lon': '79.4995'},
      '389002': {'lat': '23.0937', 'name': 'Vavdi Buzarg,Panch Mahals,Gujarat', 'lon': '73.5392'},
      '210421': {'lat': '25.403', 'name': 'Charkhari State,Mahoba,Uttar Pradesh', 'lon': '79.7488'},
      '625004': {'lat': '9.8961', 'name': 'Pasumalai,Madurai,Tamil Nadu', 'lon': '78.0813'},
      '798613': {'lat': '26.5459', 'name': 'Yaongyimsen,Mokokchung,Nagaland', 'lon': '94.6856'},
      '798612': {'lat': '26.2362', 'name': 'Pessu,Tuensang,Nagaland', 'lon': '94.8967'},
      '798611': {'lat': '26.4339', 'name': 'Sikur,Kiphire,Nagaland', 'lon': '94.7678'},
      '341511': {'lat': '26.5225', 'name': 'Kupras,Nagaur,Rajasthan', 'lon': '74.3277'},
      '798616': {'lat': '26.4339', 'name': 'Yangli,Tuensang,Nagaland', 'lon': '94.7678'},
      '798615': {'lat': '26.4339', 'name': 'lonchang,Mokokchung,Nagaland', 'lon': '94.7678'},
      '798614': {'lat': '26.4291', 'name': 'Ungar,Mokokchung,Nagaland', 'lon': '94.6213'},
      '341516': {'lat': '27.946', 'name': 'Parewari,Nagaur,Rajasthan', 'lon': '74.5973'},
      '798618': {'lat': '26.6543', 'name': 'Molungkhimong,Mokokchung,Nagaland', 'lon': '94.6638'},
      '621108': {'lat': '11.3464', 'name': 'S.Aduthurai,Perambalur,Tamil Nadu', 'lon': '78.6543'},
      '621109': {'lat': '11.1258', 'name': 'Ootathur,Perambalur,Tamil Nadu', 'lon': '78.8588'},
      '142035': {'lat': '31.0738', 'name': 'Kaunke Kalan,Ludhiana,Punjab', 'lon': '75.5034'},
      '475115': {'lat': '24.83', 'name': 'Sukhapatha,Gwalior,Madhya Pradesh', 'lon': '78.2238'},
      '142055': {'lat': '30.5267', 'name': 'Takhupura,Moga,Punjab', 'lon': '75.2867'},
      '847201': {'lat': '25.9883', 'name': 'Sajhuar,Darbhanga,Bihar', 'lon': '85.9408'},
      '517172': {'lat': '13.5902', 'name': 'Kamavaram Kothapet,Chittoor,Andhra Pradesh', 'lon': '79.07'},
      '272163': {'lat': '26.7405', 'name': 'Sardaha Sukul,Basti,Uttar Pradesh', 'lon': '82.679'},
      '388270': {'lat': '23.1129', 'name': 'Lakdi Poyada,Panch Mahals,Gujarat', 'lon': '73.4686'},
      '174201': {'lat': '31.38265', 'name': 'Tarsooh,Bilaspur (HP),Himachal Pradesh', 'lon': '76.44852'},
      '604001': {'lat': '12.2272', 'name': 'Avarapakkam,Villupuram,Tamil Nadu', 'lon': '79.6529'},
      '363310': {'lat': '22.9155', 'name': 'Gajanvav,Surendra Nagar,Gujarat', 'lon': '71.4034'},
      '285001': {'lat': '26.0878', 'name': 'Mandri,Jalaun,Uttar Pradesh', 'lon': '79.397'},
      '587206': {'lat': '16.0201', 'name': 'Ugalwat,Bagalkot,Karnataka', 'lon': '75.5168'},
      '735101': {'lat': '25.5004', 'name': 'Kadamtala (Jalpaiguri),Jalpaiguri,West Bengal', 'lon': '88.2369'},
      '244923': {'lat': '28.7287', 'name': 'Kaushal Ganj,Rampur,Uttar Pradesh', 'lon': '78.8303'},
      '274208': {'lat': '26.4291', 'name': 'Bhelaur,Deoria,Uttar Pradesh', 'lon': '83.5412'},
      '360035': {'lat': '22.1397', 'name': 'Sanganva,Rajkot,Gujarat', 'lon': '70.6106'},
      '360030': {'lat': '22.0669', 'name': 'Khareda,Rajkot,Gujarat', 'lon': '70.8869'},
      '751008': {'lat': '20.6966', 'name': 'Rajbhawan (Khorda),Khorda,Odisha', 'lon': '84.974'},
      '585318': {'lat': '17.1964', 'name': 'Devanoor,Kalaburagi,Karnataka', 'lon': '77.3951'},
      '585319': {'lat': '16.8238', 'name': 'Kadamgera,Yadgir,Karnataka', 'lon': '77.0061'},
      '585316': {'lat': '17.6145', 'name': 'Ambalga,Kalaburagi,Karnataka', 'lon': '76.8938'},
      '585317': {'lat': '17.1409', 'name': 'Gundgurthi,Kalaburagi,Karnataka', 'lon': '76.9932'},
      '585314': {'lat': '17.6987', 'name': 'Hodlur,Kalaburagi,Karnataka', 'lon': '76.5639'},
      '585315': {'lat': '16.641', 'name': 'Idloor,Yadgir,Karnataka', 'lon': '77.4039'},
      '585312': {'lat': '17.2752', 'name': 'Mangalgi,Kalaburagi,Karnataka', 'lon': '77.2341'},
      '585313': {'lat': '17.8996', 'name': 'Kinnisadak,Kalaburagi,Karnataka', 'lon': '77.1957'},
      '585310': {'lat': '17.0618', 'name': 'Kattisangavi,Kalaburagi,Karnataka', 'lon': '76.6973'},
      '585311': {'lat': '17.4083', 'name': 'Pattan,Kalaburagi,Karnataka', 'lon': '76.7213'},
      '676122': {'lat': '11.1333', 'name': 'Pappinippara,Malappuram,Kerala', 'lon': '76.142'},
      '676123': {'lat': '11.1659', 'name': 'Manhappatta,Malappuram,Kerala', 'lon': '76.1221'},
      '676121': {'lat': '11.1496', 'name': 'Manjeri Bazar,Malappuram,Kerala', 'lon': '76.132'},
      '331518': {'lat': '27.5391', 'name': 'Kalyansar,Churu,Rajasthan', 'lon': '73.9842'},
      '833204': {'lat': '22.5104', 'name': 'Barachiru,Seraikela-kharsawan,Jharkhand', 'lon': '85.859'},
      '764074': {'lat': '19.9166', 'name': 'Debagaon,Nabarangapur,Odisha', 'lon': '82.07835'},
      '331517': {'lat': '27.7559', 'name': 'Utalad,Churu,Rajasthan', 'lon': '74.7692'},
      '143531': {'lat': '32.1252', 'name': 'Awankha,Gurdaspur,Punjab', 'lon': '75.4768'},
      '335073': {'lat': '29.2718', 'name': 'Tejewala,Ganganagar,Rajasthan', 'lon': '74.277'},
      '143532': {'lat': '31.9837', 'name': 'Behrampur,Gurdaspur,Punjab', 'lon': '75.2554'},
      '142038': {'lat': '30.5777', 'name': 'Rajeana,Moga,Punjab', 'lon': '75.189'},
      '143533': {'lat': '32.1491', 'name': 'Gharota,Pathankot,Punjab', 'lon': '75.5795'},
      '847202': {'lat': '25.9935', 'name': 'Habidih,Darbhanga,Bihar', 'lon': '86.2455'},
      '221404': {'lat': '25.47305', 'name': 'Abarana,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.35965'},
      '600013': {'lat': '13.1141', 'name': 'Rayapuram,Chennai,Tamil Nadu', 'lon': '80.2947'},
      '411067': {'lat': '18.735', 'name': 'Aundh (Pune),Pune,Maharashtra', 'lon': '74.2538'},
      '313342': {'lat': '25.355', 'name': 'Naogama,Rajsamand,Rajasthan', 'lon': '74.032'},
      '600010': {'lat': '13.08', 'name': 'Kilpauk Medical College,Chennai,Tamil Nadu', 'lon': '80.2408'},
      '411062': {'lat': '18.8806', 'name': 'Talawade,Pune,Maharashtra', 'lon': '74.6948'},
      '600016': {'lat': '12.9846', 'name': 'Chennai Airport,Kanchipuram,Tamil Nadu', 'lon': '80.1747'},
      '411060': {'lat': '18.735', 'name': 'Mohamadwadi,Pune,Maharashtra', 'lon': '74.2538'},
      '411061': {'lat': '18.5893', 'name': 'Pimple Gurav,Pune,Maharashtra', 'lon': '73.8128'},
      '600019': {'lat': '13.1582', 'name': 'Tiruvottiyur West,Tiruvallur,Tamil Nadu', 'lon': '80.3018'},
      '600018': {'lat': '13.0433', 'name': 'Pr. Accountant General,Chennai,Tamil Nadu', 'lon': '80.2528'},
      '403005': {'lat': '15.4752', 'name': 'Santa Cruz,North Goa,Goa', 'lon': '73.8456'},
      '411068': {'lat': '18.735', 'name': 'Nanded (Pune),Pune,Maharashtra', 'lon': '74.2538'},
      '464113': {'lat': '23.5132', 'name': 'Satpada,Vidisha,Madhya Pradesh', 'lon': '78.2904'},
      '570023': {'lat': '12.3537', 'name': 'Kuvempunagar (Mysuru),Mysuru,Karnataka', 'lon': '76.5368'},
      '464114': {'lat': '24.1287', 'name': 'Sunkher,Vidisha,Madhya Pradesh', 'lon': '77.4312'},
      '209602': {'lat': '27.3251', 'name': 'Gaisinghpur,Farrukhabad,Uttar Pradesh', 'lon': '79.5074'},
      '516227': {'lat': '14.7451', 'name': 'Badvel,Cuddapah,Andhra Pradesh', 'lon': '79.0629'},
      '570026': {'lat': '12.4072', 'name': 'Doddamaragowdanahalli,Mysuru,Karnataka', 'lon': '76.4468'},
      '571105': {'lat': '12.3204', 'name': 'Panchavalli,Mysuru,Karnataka', 'lon': '76.3134'},
      '222201': {'lat': '25.9037', 'name': 'Khujhidih,Jaunpur,Uttar Pradesh', 'lon': '82.6044'},
      '571106': {'lat': '12.2859', 'name': 'Tattekere,Mysuru,Karnataka', 'lon': '76.237'},
      '516228': {'lat': '14.845', 'name': 'B . Kodur,Cuddapah,Andhra Pradesh', 'lon': '79.0323'},
      '425201': {'lat': '21.0429', 'name': 'Bhusawal Shivaji Nagar,Jalgaon,Maharashtra', 'lon': '75.7393'},
      '425203': {'lat': '20.6533', 'name': 'Bhusawal Ordnance Factory,Jalgaon,Maharashtra', 'lon': '75.9209'},
      '313341': {'lat': '25.4112', 'name': 'Baggad,Rajsamand,Rajasthan', 'lon': '73.9529'},
      '804451': {'lat': '25.2582', 'name': 'Bansbigha,Patna,Bihar', 'lon': '85.2063'},
      '804453': {'lat': '25.4351', 'name': 'Barah,Patna,Bihar', 'lon': '85.0447'},
      '804452': {'lat': '25.3867', 'name': 'Ghorahua,Patna,Bihar', 'lon': '85.0163'},
      '600011': {'lat': '13.1103', 'name': 'Perambur North,Chennai,Tamil Nadu', 'lon': '80.2425'},
      '262529': {'lat': '29.8243', 'name': 'Chingri,Pithoragarh,Uttarakhand', 'lon': '79.6791'},
      '700037': {'lat': '22.6', 'name': 'Belgachia Road,Kolkata,West Bengal', 'lon': '88.3833'},
      '141126': {'lat': '30.9304', 'name': 'Sri Bhaini Sahib,Ludhiana,Punjab', 'lon': '76.0968'},
      '781103': {'lat': '26.182', 'name': 'Bongshor,Kamrup,Assam', 'lon': '91.561'},
      '207249': {'lat': '27.5508', 'name': 'Nagla Leeladhar,Etah,Uttar Pradesh', 'lon': '78.7367'},
      '207248': {'lat': '27.5121', 'name': 'Udaipura,Etah,Uttar Pradesh', 'lon': '78.7132'},
      '207247': {'lat': '27.4771', 'name': 'Nagla Gajpat,Etah,Uttar Pradesh', 'lon': '79.2381'},
      '600017': {'lat': '13.1096', 'name': 'Thygarayanagar H.O,Chennai,Tamil Nadu', 'lon': '80.2146'},
      '262802': {'lat': '27.6188', 'name': 'Kotwora Raj,Kheri,Uttar Pradesh', 'lon': '80.7694'},
      '571103': {'lat': '12.4038', 'name': 'Halebeedu,Mysuru,Karnataka', 'lon': '76.3784'},
      '262804': {'lat': '27.9388', 'name': 'Piparia Dhani,Kheri,Uttar Pradesh', 'lon': '80.1985'},
      '262805': {'lat': '27.1967', 'name': 'Parsehra Madarpur,Kheri,Uttar Pradesh', 'lon': '80.9194'},
      '207241': {'lat': '27.6602', 'name': 'Sarsai Narau,Etah,Uttar Pradesh', 'lon': '78.77'},
      '713204': {'lat': '24.2028', 'name': 'Pratappur,Purba Bardhaman,West Bengal', 'lon': '87.9815'},
      '782462': {'lat': '26.1303', 'name': 'Phanglangso,Karbi Anglon,Assam', 'lon': '92.741'},
      '782461': {'lat': '26.1303', 'name': 'Manja,Karbi Anglon,Assam', 'lon': '92.741'},
      '782460': {'lat': '26.1303', 'name': 'Diphu H.O,Karbi Anglon,Assam', 'lon': '92.741'},
      '600015': {'lat': '13.1096', 'name': 'Guindy North,Chennai,Tamil Nadu', 'lon': '80.2146'},
      '700039': {'lat': '22.5668', 'name': 'Topsia,South 24 Parganas,West Bengal', 'lon': '88.3016'},
      '587207': {'lat': '16.0674', 'name': 'Hiregulbal,Bagalkot,Karnataka', 'lon': '75.9289'},
      '361345': {'lat': '22.3961', 'name': 'Bhimrana,Jamnagar,Gujarat', 'lon': '69.007'},
      '333504': {'lat': '28.1364', 'name': 'Khetri Nagar,Jhujhunu,Rajasthan', 'lon': '75.832'},
      '361347': {'lat': '22.3961', 'name': 'Surajkaradi,Jamnagar,Gujarat', 'lon': '69.007'},
      '713208': {'lat': '24.2028', 'name': 'Durgapur Alloy Steel Project,Paschim Bardhaman,West Bengal', 'lon': '87.9815'},
      '577436': {'lat': '13.8637', 'name': 'Heddaripura,Shivamogga,Karnataka', 'lon': '75.2128'},
      '577434': {'lat': '14.3079', 'name': 'B.Dodderi,Shivamogga,Karnataka', 'lon': '75.0727'},
      '577435': {'lat': '14.0704', 'name': 'Jog Falls,Shivamogga,Karnataka', 'lon': '75.137'},
      '577432': {'lat': '13.7271', 'name': 'Dooravasapura,Shivamogga,Karnataka', 'lon': '75.195'},
      '577433': {'lat': '14.257', 'name': 'Tattur,Shivamogga,Karnataka', 'lon': '75.1714'},
      '577430': {'lat': '14.2482', 'name': 'Marathur,Shivamogga,Karnataka', 'lon': '74.911'},
      '577431': {'lat': '14.2481', 'name': 'Thagarthi,Shivamogga,Karnataka', 'lon': '75.1842'},
      '460661': {'lat': '21.818', 'name': 'Yenus,Betul,Madhya Pradesh', 'lon': '78.1832'},
      '801501': {'lat': '25.5834', 'name': 'B.R. Centre,Patna,Bihar', 'lon': '85.0681'},
      '207002': {'lat': '27.5752', 'name': 'Sarvodaya Ashram,Etah,Uttar Pradesh', 'lon': '78.7319'},
      '828119': {'lat': '23.5725', 'name': 'Patherdih,Dhanbad,Jharkhand', 'lon': '86.2216'},
      '302031': {'lat': '26.6572', 'name': 'Jamdoli,Jaipur,Rajasthan', 'lon': '76.2486'},
      '503213': {'lat': '17.915', 'name': 'Deekampalli,Nizamabad,Telangana', 'lon': '78.7943'},
      '625221': {'lat': '10.2149', 'name': 'Sithalangudi,Madurai,Tamil Nadu', 'lon': '78.2261'},
      '521250': {'lat': '16.268', 'name': 'Aminapuram,Krishna,Andhra Pradesh', 'lon': '80.8685'},
      '828114': {'lat': '23.5725', 'name': 'Keshalpur,Dhanbad,Jharkhand', 'lon': '86.2216'},
      '521256': {'lat': '16.3569', 'name': 'Gopuvanipalem,Krishna,Andhra Pradesh', 'lon': '80.8971'},
      '460666': {'lat': '21.69', 'name': 'Birul Bazar,Betul,Madhya Pradesh', 'lon': '78.1082'},
      '261202': {'lat': '27.5246', 'name': 'Jahangirabad (Sitapur),Sitapur,Uttar Pradesh', 'lon': '81.1101'},
      '493885': {'lat': '21.2555', 'name': 'Baronda,Raipur,Chattisgarh', 'lon': '81.9994'},
      '460668': {'lat': '21.7124', 'name': 'Shirdi,Betul,Madhya Pradesh', 'lon': '78.1944'},
      '464111': {'lat': '22.2822', 'name': 'Dangarwada,Vidisha,Madhya Pradesh', 'lon': '80.0087'},
      '522508': {'lat': '16.3887', 'name': 'Kaza,Guntur,Andhra Pradesh', 'lon': '80.5412'},
      '522509': {'lat': '16.4327', 'name': 'Pedakakani,Guntur,Andhra Pradesh', 'lon': '80.6598'},
      '370510': {'lat': '23.6366', 'name': 'Dhrobana,Kachchh,Gujarat', 'lon': '69.813'},
      '370511': {'lat': '23.5386', 'name': 'Akri moti,Kachchh,Gujarat', 'lon': '68.6014'},
      '431731': {'lat': '18.6907', 'name': 'Sagroli,Nanded,Maharashtra', 'lon': '77.7372'},
      '522501': {'lat': '16.484', 'name': 'Penumaka,Guntur,Andhra Pradesh', 'lon': '80.5772'},
      '522502': {'lat': '16.4555', 'name': 'Neerukonda,Guntur,Andhra Pradesh', 'lon': '80.5408'},
      '522503': {'lat': '16.4373', 'name': 'Mangalagiri Bazar,Guntur,Andhra Pradesh', 'lon': '80.5787'},
      '382140': {'lat': '21.859', 'name': 'Devpura,Ahmedabad,Gujarat', 'lon': '72.6196'},
      '803107': {'lat': '25.2468', 'name': 'Nerut,Nalanda,Bihar', 'lon': '85.6792'},
      '382145': {'lat': '22.2701', 'name': 'Bhoyani,Ahmedabad,Gujarat', 'lon': '72.5306'},
      '803101': {'lat': '25.1766', 'name': 'Nalanda College,Nalanda,Bihar', 'lon': '85.5258'},
      '509381': {'lat': '17.1796', 'name': 'Kanmanur,Mahabub Nagar,Telangana', 'lon': '78.7719'},
      '509380': {'lat': '16.6568', 'name': 'Parvathapuram,Mahabub Nagar,Telangana', 'lon': '78.3964'},
      '509382': {'lat': '16.8578', 'name': 'Kothamolgara,Mahabub Nagar,Telangana', 'lon': '77.9387'},
      '509385': {'lat': '16.434', 'name': 'Laxmapur,Mahabub Nagar,Telangana', 'lon': '78.4585'},
      '803108': {'lat': '25.4171', 'name': 'Badhauna,Nalanda,Bihar', 'lon': '85.4001'},
      '803109': {'lat': '24.8892', 'name': 'Purani,Nawada,Bihar', 'lon': '85.8208'},
      '614723': {'lat': '10.282', 'name': 'Rendampulikadu,Thanjavur,Tamil Nadu', 'lon': '79.3122'},
      '785602': {'lat': '26.9248', 'name': 'Dapathar,Golaghat,Assam', 'lon': '94.3157'},
      '785603': {'lat': '26.3874', 'name': 'Ghiladhari,Golaghat,Assam', 'lon': '93.9637'},
      '785601': {'lat': '26.2834', 'name': 'Chungajan,Golaghat,Assam', 'lon': '92.5557'},
      '424004': {'lat': '20.8361', 'name': 'Dhule Market Yard,Dhule,Maharashtra', 'lon': '74.71'},
      '424005': {'lat': '20.9708', 'name': 'Nagaon,Dhule,Maharashtra', 'lon': '74.7836'},
      '424006': {'lat': '20.747', 'name': 'Arvi,Dhule,Maharashtra', 'lon': '74.7147'},
      '306603': {'lat': '25.3865', 'name': 'Neepal,Pali,Rajasthan', 'lon': '73.455'},
      '424001': {'lat': '20.8361', 'name': 'Dhule Collectorate,Dhule,Maharashtra', 'lon': '74.71'},
      '424002': {'lat': '20.8575', 'name': 'Kumar Nagar,Dhule,Maharashtra', 'lon': '74.7047'},
      '785609': {'lat': '26.6', 'name': 'Hatikhuli,Golaghat,Assam', 'lon': '93.35'},
      '758043': {'lat': '21.2879', 'name': 'Batto,Kendujhar,Odisha', 'lon': '85.1414'},
      '477557': {'lat': '26.5552', 'name': 'Baranso,Bhind,Madhya Pradesh', 'lon': '78.5859'},
      '274205': {'lat': '26.3438', 'name': 'Samogar,Deoria,Uttar Pradesh', 'lon': '83.6248'},
      '263651': {'lat': '29.615', 'name': 'Chaubattia,Almora,Uttarakhand', 'lon': '79.4568'},
      '504001': {'lat': '19.539', 'name': 'Bhagyanagar (Adilabad),Adilabad,Telangana', 'lon': '78.527'},
      '637302': {'lat': '11.4522', 'name': 'Nagichettipatti,Namakkal,Tamil Nadu', 'lon': '77.9365'},
      '504002': {'lat': '19.539', 'name': 'Chinchughat,Adilabad,Telangana', 'lon': '78.527'},
      '273309': {'lat': '27.2025', 'name': 'Koluhi,Maharajganj,Uttar Pradesh', 'lon': '83.5333'},
      '144632': {'lat': '31.1981', 'name': 'Chachoki,Kapurthala,Punjab', 'lon': '75.4917'},
      '413802': {'lat': '18.4934', 'name': 'Kurkumbh,Pune,Maharashtra', 'lon': '74.5484'},
      '509301': {'lat': '17.3183', 'name': 'Stn. Jadcherla H.O,Mahabub Nagar,Telangana', 'lon': '78.122'},
      '413801': {'lat': '18.4934', 'name': 'Boribyal,Pune,Maharashtra', 'lon': '74.5484'},
      '333501': {'lat': '27.8098', 'name': 'Kalota,Jhujhunu,Rajasthan', 'lon': '75.9196'},
      '271831': {'lat': '27.4379', 'name': 'Banthihwa,Shrawasti,Uttar Pradesh', 'lon': '82.2461'},
      '321615': {'lat': '26.9981', 'name': 'Jharauti,Bharatpur,Rajasthan', 'lon': '76.9603'},
      '734215': {'lat': '26.9083', 'name': 'Nagrispur,Darjiling,West Bengal', 'lon': '88.2224'},
      '734214': {'lat': '26.8882', 'name': 'Seeyok,Darjiling,West Bengal', 'lon': '88.1901'},
      '734217': {'lat': '26.8304', 'name': 'Jamdar Vitta,Darjiling,West Bengal', 'lon': '88.2625'},
      '734216': {'lat': '26.9571', 'name': 'Selimbong,Darjiling,West Bengal', 'lon': '88.1668'},
      '331031': {'lat': '28.3455', 'name': 'Raipuria,Churu,Rajasthan', 'lon': '74.6986'},
      '629901': {'lat': '8.231', 'name': 'Therekalpudur,Kanyakumari,Tamil Nadu', 'lon': '77.4331'},
      '734213': {'lat': '26.9083', 'name': 'Lamahatta,Darjiling,West Bengal', 'lon': '88.2224'},
      '144633': {'lat': '31.1791', 'name': 'Lakhanpal,Jalandhar,Punjab', 'lon': '75.6582'},
      '758041': {'lat': '21.954', 'name': 'Panchapokharia,Kendujhar,Odisha', 'lon': '85.5899'},
      '734218': {'lat': '26.9083', 'name': 'Phuguri,Darjiling,West Bengal', 'lon': '88.2224'},
      '306422': {'lat': '25.5053', 'name': 'Guda Endla,Pali,Rajasthan', 'lon': '73.2565'},
      '682011': {'lat': '9.967', 'name': 'Ernakulam H.O,Ernakulam,Kerala', 'lon': '76.3159'},
      '682010': {'lat': '9.967', 'name': 'Edakochi,Ernakulam,Kerala', 'lon': '76.3159'},
      '515144': {'lat': '14.2042', 'name': 'Amagondapalem,Ananthapur,Andhra Pradesh', 'lon': '77.8111'},
      '682012': {'lat': '9.967', 'name': 'Pachalam,Ernakulam,Kerala', 'lon': '76.3159'},
      '682015': {'lat': '9.967', 'name': 'Perumanur,Ernakulam,Kerala', 'lon': '76.3159'},
      '682017': {'lat': '9.967', 'name': 'Kaloor,Ernakulam,Kerala', 'lon': '76.3159'},
      '682016': {'lat': '9.967', 'name': 'Kochi M.G.Road,Ernakulam,Kerala', 'lon': '76.3159'},
      '682019': {'lat': '9.967', 'name': 'Vyttila,Ernakulam,Kerala', 'lon': '76.3159'},
      '682018': {'lat': '9.967', 'name': 'Ernakulam North,Ernakulam,Kerala', 'lon': '76.3159'},
      '799114': {'lat': '23.72124', 'name': 'Rajarbag,Gomti,Tripura', 'lon': '91.74458'},
      '792130': {'lat': '27.0217', 'name': 'Lazu,Tirap,Arunachal Pradesh', 'lon': '95.5368'},
      '493881': {'lat': '20.9717', 'name': 'Nawapara Rajim,Raipur,Chattisgarh', 'lon': '81.8588'},
      '802211': {'lat': '25.3032', 'name': 'Gunsej,Rohtas,Bihar', 'lon': '84.1568'},
      '802210': {'lat': '25.6399', 'name': 'Piania,Bhojpur,Bihar', 'lon': '84.751'},
      '392015': {'lat': '22.8683', 'name': 'Narmada Nagar (Bharuch),Bharuch,Gujarat', 'lon': '72.7487'},
      '802212': {'lat': '25.2103', 'name': 'K. Mahdeo,Rohtas,Bihar', 'lon': '84.2512'},
      '802215': {'lat': '25.1655', 'name': 'Barki Akorhi,Rohtas,Bihar', 'lon': '84.203'},
      '392012': {'lat': '22.0999', 'name': 'Bhuva,Bharuch,Gujarat', 'lon': '72.8836'},
      '392011': {'lat': '22.356', 'name': 'Haldarva,Bharuch,Gujarat', 'lon': '72.8386'},
      '802216': {'lat': '25.2377', 'name': 'Bahuara,Rohtas,Bihar', 'lon': '84.1918'},
      '802112': {'lat': '25.5658', 'name': 'Nainijor,Buxar,Bihar', 'lon': '84.3801'},
      '302037': {'lat': '26.7602', 'name': 'Mahindra World name (Jaipur),Jaipur,Rajasthan', 'lon': '76.0868'},
      '563129': {'lat': '13.1152', 'name': 'Kethganahalli,Kolar,Karnataka', 'lon': '77.2676'},
      '563128': {'lat': '13.2975', 'name': 'Talagavara,Kolar,Karnataka', 'lon': '77.9917'},
      '277209': {'lat': '25.9698', 'name': 'Moonchapra,Ballia,Uttar Pradesh', 'lon': '84.3067'},
      '563125': {'lat': '13.4153', 'name': 'T. Gollahalli,Chikkaballapur,Karnataka', 'lon': '78.0542'},
      '563124': {'lat': '13.6443', 'name': 'Puligal,Chikkaballapur,Karnataka', 'lon': '77.8343'},
      '563127': {'lat': '13.2932', 'name': 'Oorkunte Mittur,Kolar,Karnataka', 'lon': '78.0599'},
      '563126': {'lat': '13.3006', 'name': 'Velagalaburre,Kolar,Karnataka', 'lon': '78.1181'},
      '387240': {'lat': '23.2676', 'name': 'Balinta,Anand,Gujarat', 'lon': '72.8698'},
      '563120': {'lat': '13.3279', 'name': 'Doddachinnahalli,Kolar,Karnataka', 'lon': '78.0098'},
      '563123': {'lat': '13.3279', 'name': 'Yenumalapadi,Chikkaballapur,Karnataka', 'lon': '78.0098'},
      '563122': {'lat': '12.9563', 'name': 'Ganeshpuram,Kolar,Karnataka', 'lon': '78.2754'},
      '793014': {'lat': '25.5637', 'name': 'Nongthymmai,East Khasi Hills,Meghalaya', 'lon': '91.9064'},
      '673579': {'lat': '11.7953', 'name': 'Seetha Mount,Wayanad,Kerala', 'lon': '76.1532'},
      '793015': {'lat': '25.5232', 'name': 'Mawkynrew,East Khasi Hills,Meghalaya', 'lon': '91.9106'},
      '673572': {'lat': '11.3792', 'name': 'Kilakkoth,Kozhikode,Kerala', 'lon': '75.9088'},
      '673573': {'lat': '11.5014', 'name': 'Kattippara,Kozhikode,Kerala', 'lon': '75.8855'},
      '673570': {'lat': '11.5014', 'name': 'Iim Kozhikode Campus,Kozhikode,Kerala', 'lon': '75.8855'},
      '673571': {'lat': '11.4848', 'name': 'Padanilam,Kozhikode,Kerala', 'lon': '75.8344'},
      '673576': {'lat': '11.5512', 'name': 'Vythiri,Wayanad,Kerala', 'lon': '76.0392'},
      '673577': {'lat': '11.8146', 'name': 'Vallarmala,Wayanad,Kerala', 'lon': '75.713'},
      '673574': {'lat': '11.4265', 'name': 'Talayad,Kozhikode,Kerala', 'lon': '75.8802'},
      '673575': {'lat': '11.5014', 'name': 'Manjoora,Wayanad,Kerala', 'lon': '75.8855'},
      '768118': {'lat': '21.2545', 'name': 'Naktideul,Sambalpur,Odisha', 'lon': '84.5431'},
      '500087': {'lat': '17.2974', 'name': 'JJ Nagar Colony,Hyderabad,Telangana', 'lon': '78.3132'},
      '604501': {'lat': '12.5288', 'name': 'Kilputhur,Tiruvannamalai,Tamil Nadu', 'lon': '79.5023'},
      '465674': {'lat': '23.8052', 'name': 'Madhumilan Sintax,Rajgarh,Madhya Pradesh', 'lon': '76.93'},
      '604503': {'lat': '12.5414', 'name': 'Pernamallur,Tiruvannamalai,Tamil Nadu', 'lon': '79.4551'},
      '277201': {'lat': '25.8746', 'name': 'Jagdeva,Ballia,Uttar Pradesh', 'lon': '84.2676'},
      '230202': {'lat': '25.757', 'name': 'Lat Tara,Pratapgarh,Uttar Pradesh', 'lon': '81.4897'},
      '302039': {'lat': '26.966', 'name': 'Amba Bari,Jaipur,Rajasthan', 'lon': '75.7632'},
      '502281': {'lat': '17.2831', 'name': 'Alirajpet,Medak,Telangana', 'lon': '78.2121'},
      '742236': {'lat': '24.2391', 'name': 'Ntpc Farakka,Murshidabad,West Bengal', 'lon': '87.7837'},
      '416812': {'lat': '16.0572', 'name': 'Humarmala,Sindhudurg,Maharashtra', 'lon': '74.0544'},
      '416813': {'lat': '16.0572', 'name': 'Akhavane,Sindhudurg,Maharashtra', 'lon': '74.0544'},
      '416810': {'lat': '16.0572', 'name': 'Arule,Sindhudurg,Maharashtra', 'lon': '74.0544'},
      '416811': {'lat': '16.0572', 'name': 'Pomburla,Sindhudurg,Maharashtra', 'lon': '74.0544'},
      '607002': {'lat': '11.7312', 'name': 'Tiruppadiripuliyur West,Cuddalore,Tamil Nadu', 'lon': '79.5871'},
      '277204': {'lat': '26.8142', 'name': 'Dallan Chhapra,Ballia,Uttar Pradesh', 'lon': '83.9607'},
      '484771': {'lat': '24.2198', 'name': 'Deora,Sidhi,Madhya Pradesh', 'lon': '81.8353'},
      '363440': {'lat': '22.3925', 'name': 'Khintala,Surendra Nagar,Gujarat', 'lon': '71.3623'},
      '609108': {'lat': '11.2859', 'name': 'Puthur (SRK),Nagapattinam,Tamil Nadu', 'lon': '79.7058'},
      '488442': {'lat': '24.0602', 'name': 'Patanakala,Panna,Madhya Pradesh', 'lon': '79.4621'},
      '488443': {'lat': '23.5015', 'name': 'Jarguwan,Panna,Madhya Pradesh', 'lon': '79.0756'},
      '141008': {'lat': '31.982', 'name': 'Samrala Road,Ludhiana,Punjab', 'lon': '75.5161'},
      '609109': {'lat': '11.1628', 'name': 'Sembadaniruppu,Nagapattinam,Tamil Nadu', 'lon': '79.7625'},
      '488446': {'lat': '24.2499', 'name': 'Itain,Panna,Madhya Pradesh', 'lon': '79.8749'},
      '700099': {'lat': '22.5553', 'name': 'Kalikapur,Kolkata,West Bengal', 'lon': '88.3558'},
      '141002': {'lat': '30.8087', 'name': 'New Model Town,Ludhiana,Punjab', 'lon': '75.8382'},
      '141003': {'lat': '31.3267', 'name': 'A.P. Basti,Ludhiana,Punjab', 'lon': '75.6916'},
      '700096': {'lat': '22.4485', 'name': 'Brahmapur,South 24 Parganas,West Bengal', 'lon': '88.3883'},
      '141001': {'lat': '31.3267', 'name': 'Bharat Nagar (Ludhiana),Ludhiana,Punjab', 'lon': '75.6916'},
      '141006': {'lat': '31.3267', 'name': 'Gne College,Ludhiana,Punjab', 'lon': '75.6916'},
      '141007': {'lat': '30.9724', 'name': 'Khwajke,Ludhiana,Punjab', 'lon': '75.9345'},
      '141004': {'lat': '31.3267', 'name': 'P.A.U.,Ludhiana,Punjab', 'lon': '75.6916'},
      '700093': {'lat': '22.4485', 'name': 'Purba Putiary,South 24 Parganas,West Bengal', 'lon': '88.3883'},
      '766014': {'lat': '19.9257', 'name': 'Koligaon,Kalahandi,Odisha', 'lon': '82.8036'},
      '465691': {'lat': '24.0497', 'name': 'Kashikhedi,Rajgarh,Madhya Pradesh', 'lon': '76.3573'},
      '781382': {'lat': '26.3438', 'name': 'Khudradimu,Kamrup,Assam', 'lon': '91.6423'},
      '781381': {'lat': '26.3306', 'name': 'Sutargaon,Kamrup,Assam', 'lon': '91.985'},
      '781380': {'lat': '26.5263', 'name': 'Kalajalchowk,Kamrup,Assam', 'lon': '91.962'},
      '391775': {'lat': '23.1355', 'name': 'Lamdapura,Vadodara,Gujarat', 'lon': '73.3523'},
      '804454': {'lat': '25.3254', 'name': 'Bara,Patna,Bihar', 'lon': '85.1004'},
      '182142': {'lat': '33.0725', 'name': 'Karlah,Udhampur,Jammu & Kashmir', 'lon': '75.2873'},
      '182143': {'lat': '33.1183', 'name': 'Sanasar,Doda,Jammu & Kashmir', 'lon': '75.3089'},
      '742164': {'lat': '23.9474', 'name': 'Roshanbagh,Murshidabad,West Bengal', 'lon': '88.0423'},
      '182141': {'lat': '32.9731', 'name': 'Dhanas,Udhampur,Jammu & Kashmir', 'lon': '75.1623'},
      '182146': {'lat': '33.4018', 'name': 'Kaskoot,Doda,Jammu & Kashmir', 'lon': '75.0936'},
      '182147': {'lat': '33.1608', 'name': 'Kastigarh,Doda,Jammu & Kashmir', 'lon': '75.4705'},
      '182144': {'lat': '33.2644', 'name': 'Gool,Udhampur,Jammu & Kashmir', 'lon': '74.9824'},
      '182145': {'lat': '33.3618', 'name': 'Pogal,Doda,Jammu & Kashmir', 'lon': '75.2199'},
      '635202': {'lat': '12.1721', 'name': 'D Thurinjipatti,Dharmapuri,Tamil Nadu', 'lon': '78.2028'},
      '182148': {'lat': '33.4097', 'name': 'Neera,Doda,Jammu & Kashmir', 'lon': '74.8881'},
      '711309': {'lat': '22.5254', 'name': 'Delta Mil,Howrah,West Bengal', 'lon': '88.2176'},
      '483331': {'lat': '23.8038', 'name': 'Sihundi,Katni,Madhya Pradesh', 'lon': '80.0046'},
      '610106': {'lat': '10.3639', 'name': 'Thappalampuliyur,Tiruvarur,Tamil Nadu', 'lon': '79.3214'},
      '742168': {'lat': '23.9875', 'name': 'Bipra Sekhar,Murshidabad,West Bengal', 'lon': '87.922'},
      '854334': {'lat': '26.14', 'name': 'Maryganj,Araria,Bihar', 'lon': '87.0761'},
      '416609': {'lat': '17.7992', 'name': 'Dariste,Sindhudurg,Maharashtra', 'lon': '75.7764'},
      '416608': {'lat': '17.9529', 'name': 'Bilwas,Sindhudurg,Maharashtra', 'lon': '76.0224'},
      '263640': {'lat': '29.875', 'name': 'Syankote,Bageshwar,Uttarakhand', 'lon': '79.9008'},
      '632520': {'lat': '13.0628', 'name': 'Vallimalai,Vellore,Tamil Nadu', 'lon': '79.2786'},
      '444511': {'lat': '20.7489', 'name': 'Chondhi,Akola,Maharashtra', 'lon': '77.0533'},
      '416603': {'lat': '16.1567', 'name': 'Hedul,Sindhudurg,Maharashtra', 'lon': '73.6833'},
      '416602': {'lat': '20.7527', 'name': 'Aynal,Sindhudurg,Maharashtra', 'lon': '78.6173'},
      '416601': {'lat': '19.6614', 'name': 'Ghousari,Sindhudurg,Maharashtra', 'lon': '77.9338'},
      '391770': {'lat': '22.8779', 'name': 'Vasanpura,Vadodara,Gujarat', 'lon': '73.3634'},
      '416606': {'lat': '17.7992', 'name': 'Malvan H.O,Sindhudurg,Maharashtra', 'lon': '75.7764'},
      '416605': {'lat': '17.7992', 'name': 'Chouke,Sindhudurg,Maharashtra', 'lon': '75.7764'},
      '416604': {'lat': '15.905', 'name': 'Chafekhol,Sindhudurg,Maharashtra', 'lon': '73.8318'},
      '380049': {'lat': '22.9764', 'name': 'Nikol,Ahmedabad,Gujarat', 'lon': '72.836'},
      '626109': {'lat': '9.5287', 'name': 'Alagianallur,Virudhunagar,Tamil Nadu', 'lon': '78.1321'},
      '610102': {'lat': '10.7333', 'name': 'Kandodathavanitham,Tiruvarur,Tamil Nadu', 'lon': '79.5509'},
      '743347': {'lat': '22.5961', 'name': 'Gobindarampur,South 24 Parganas,West Bengal', 'lon': '88.093'},
      '518674': {'lat': '15.3709', 'name': 'Pasurapadu,Kurnool,Andhra Pradesh', 'lon': '78.4257'},
      '626105': {'lat': '9.5016', 'name': 'Ramasamy Nagar,Virudhunagar,Tamil Nadu', 'lon': '78.1474'},
      '560102': {'lat': '13.0907', 'name': 'HSR Layout,Bengaluru,Karnataka', 'lon': '77.6423'},
      '626107': {'lat': '9.5287', 'name': 'Kattangudi,Virudhunagar,Tamil Nadu', 'lon': '78.1321'},
      '621730': {'lat': '11.0087', 'name': 'Sannasinallur,Ariyalur,Tamil Nadu', 'lon': '79.1969'},
      '626101': {'lat': '9.5287', 'name': 'Aruppukottai East,Virudhunagar,Tamil Nadu', 'lon': '78.1321'},
      '626103': {'lat': '10.3813', 'name': 'Sengundrapuram,Virudhunagar,Tamil Nadu', 'lon': '78.8214'},
      '626102': {'lat': '9.4825', 'name': 'Nathampatti,Virudhunagar,Tamil Nadu', 'lon': '78.0053'},
      '628617': {'lat': '8.4895', 'name': 'Udayarkulam,Tuticorin,Tamil Nadu', 'lon': '77.8434'},
      '321021': {'lat': '27.2477', 'name': 'Hatheni,Bharatpur,Rajasthan', 'lon': '77.5735'},
      '321022': {'lat': '27.6461', 'name': 'Barauli Dhau,Bharatpur,Rajasthan', 'lon': '77.2561'},
      '321023': {'lat': '27.7239', 'name': 'Ghosinga,Bharatpur,Rajasthan', 'lon': '77.2671'},
      '321024': {'lat': '27.5583', 'name': 'Khesti,Bharatpur,Rajasthan', 'lon': '77.0689'},
      '321025': {'lat': '27.3276', 'name': 'Jatoli Rathbhan,Bharatpur,Rajasthan', 'lon': '77.5384'},
      '321026': {'lat': '26.7757', 'name': 'Kasoda,Bharatpur,Rajasthan', 'lon': '76.9412'},
      '495009': {'lat': '22.0041', 'name': 'Lofandi,Bilaspur(CGH),Chattisgarh', 'lon': '82.5991'},
      '321028': {'lat': '26.9041', 'name': 'Kherli Gadasia,Bharatpur,Rajasthan', 'lon': '77.2197'},
      '388590': {'lat': '22.719', 'name': 'Kasari,Anand,Gujarat', 'lon': '73.4431'},
      '480441': {'lat': '22.1761', 'name': 'Gajandoha,Chhindwara,Madhya Pradesh', 'lon': '78.8057'},
      '480447': {'lat': '22.1901', 'name': 'Barkuhi,Chhindwara,Madhya Pradesh', 'lon': '78.7047'},
      '628619': {'lat': '8.6599', 'name': 'Kongarayankurichi,Tuticorin,Tamil Nadu', 'lon': '77.8655'},
      '570011': {'lat': '12.3134', 'name': 'Siddarthanagar Nagar,Mysuru,Karnataka', 'lon': '76.7455'},
      '262801': {'lat': '28.1729', 'name': 'Hindustan Farm,Kheri,Uttar Pradesh', 'lon': '80.0168'},
      '496551': {'lat': '21.5377', 'name': 'Bengchi,Raigarh,Chattisgarh', 'lon': '83.2651'},
      '207245': {'lat': '27.6527', 'name': 'Yakootganj,Etah,Uttar Pradesh', 'lon': '78.9592'},
      '364230': {'lat': '22.0166', 'name': 'Ishwariya,Bhavnagar,Gujarat', 'lon': '71.9041'},
      '788709': {'lat': '24.5687', 'name': 'Jagannathi,Karimganj,Assam', 'lon': '92.4221'},
      '221002': {'lat': '25.4584', 'name': 'U.P. Collage,Varanasi,Uttar Pradesh', 'lon': '82.8226'},
      '722102': {'lat': '23.2593', 'name': 'Anchuri Salboni,Bankura,West Bengal', 'lon': '87.3935'},
      '384290': {'lat': '24.0263', 'name': 'Metrana,Patan,Gujarat', 'lon': '72.301'},
      '262803': {'lat': '28.2909', 'name': 'Suwabojh Jungle,Kheri,Uttar Pradesh', 'lon': '80.3438'},
      '207243': {'lat': '27.5121', 'name': 'Ballia,Etah,Uttar Pradesh', 'lon': '78.7132'},
      '509409': {'lat': '16.5771', 'name': 'Fareedpur,Mahabub Nagar,Telangana', 'lon': '77.7771'},
      '207242': {'lat': '28.1485', 'name': 'Kadarganj,Etah,Uttar Pradesh', 'lon': '78.4236'},
      '276124': {'lat': '26.0568', 'name': 'Ajamat Garh Estate,Azamgarh,Uttar Pradesh', 'lon': '82.9228'},
      '509407': {'lat': '16.8107', 'name': 'Udamalgidda,Mahabub Nagar,Telangana', 'lon': '77.6605'},
      '605013': {'lat': '11.8768', 'name': 'Saram(Py),Pondicherry,Pondicherry', 'lon': '79.8107'},
      '148024': {'lat': '30.3583', 'name': 'Sekha,Barnala,Punjab', 'lon': '75.6461'},
      '140108': {'lat': '30.8333', 'name': 'Sainfalpur,Rupnagar,Punjab', 'lon': '76.1406'},
      '752035': {'lat': '20.1628', 'name': 'Panchugaon,Khorda,Odisha', 'lon': '85.1291'},
      '752034': {'lat': '20.3382', 'name': 'Nachuni,Khorda,Odisha', 'lon': '85.1405'},
      '752037': {'lat': '20.323', 'name': 'Navalbasechilika,Khorda,Odisha', 'lon': '85.0206'},
      '495003': {'lat': '22.0698', 'name': 'Saida,Bilaspur(CGH),Chattisgarh', 'lon': '82.0812'},
      '752031': {'lat': '20.4113', 'name': 'Saliadam,Khorda,Odisha', 'lon': '84.8569'},
      '583203': {'lat': '15.2142', 'name': 'Hospet N C C,Ballari,Karnataka', 'lon': '76.094'},
      '583201': {'lat': '15.2142', 'name': 'Hospet H.O,Ballari,Karnataka', 'lon': '76.094'},
      '742238': {'lat': '24.2391', 'name': 'Palsandamore,Murshidabad,West Bengal', 'lon': '87.7837'},
      '752038': {'lat': '20.2543', 'name': 'Godikilo,Khorda,Odisha', 'lon': '85.1247'},
      '421312': {'lat': '19.7466', 'name': 'Khupri,Thane,Maharashtra', 'lon': '73.0878'},
      '421311': {'lat': '19.5992', 'name': 'Pimplas,Thane,Maharashtra', 'lon': '73.1509'},
      '458880': {'lat': '24.3186', 'name': 'Chiknia,Mandsaur,Madhya Pradesh', 'lon': '75.642'},
      '570032': {'lat': '12.3014', 'name': 'Vijayanagar IV Stage,Mysuru,Karnataka', 'lon': '76.6169'},
      '570031': {'lat': '12.3014', 'name': 'J.P.Nagar,Mysuru,Karnataka', 'lon': '76.6169'},
      '570030': {'lat': '12.3014', 'name': 'Vijayanagar III stage,Mysuru,Karnataka', 'lon': '76.6169'},
      '327603': {'lat': '23.4663', 'name': 'Chhinch,Banswara,Rajasthan', 'lon': '74.3105'},
      '311011': {'lat': '25.3857', 'name': 'Mahuwakhurd,Bhilwara,Rajasthan', 'lon': '74.6312'},
      '364050': {'lat': '22.7503', 'name': 'Sartanpar,Bhavnagar,Gujarat', 'lon': '72.9362'},
      '391345': {'lat': '22.854', 'name': 'Petrochemical  T Ship,Vadodara,Gujarat', 'lon': '73.4677'},
      '826003': {'lat': '24.0858', 'name': 'Jagjivan Nagar,Dhanbad,Jharkhand', 'lon': '86.7287'},
      '826001': {'lat': '24.0858', 'name': 'Naya Bazar Wasseypur,Dhanbad,Jharkhand', 'lon': '86.7287'},
      '203207': {'lat': '28.6121', 'name': 'Bambawar,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.552'},
      '517541': {'lat': '13.8355', 'name': 'Talarivettu,Chittoor,Andhra Pradesh', 'lon': '79.9877'},
      '143521': {'lat': '31.9507', 'name': 'Gurdaspur H.O,Gurdaspur,Punjab', 'lon': '75.3623'},
      '143520': {'lat': '31.9914', 'name': 'Gaznipur -e,Gurdaspur,Punjab', 'lon': '75.3491'},
      '143527': {'lat': '31.6664', 'name': 'Bahadurpur Rajoa,Gurdaspur,Punjab', 'lon': '75.3283'},
      '143526': {'lat': '32.0919', 'name': 'Magar Mudian,Gurdaspur,Punjab', 'lon': '75.3679'},
      '143525': {'lat': '32.1908', 'name': 'Habit Pindi -e,Gurdaspur,Punjab', 'lon': '75.3642'},
      '686121': {'lat': '9.6875', 'name': 'Nadackal,Kottayam,Kerala', 'lon': '76.7789'},
      '143529': {'lat': '31.9697', 'name': 'Tibber,Gurdaspur,Punjab', 'lon': '75.4159'},
      '143528': {'lat': '31.9263', 'name': 'Jagowal Bet,Gurdaspur,Punjab', 'lon': '75.4841'},
      '764063': {'lat': '19.0576', 'name': 'Bhatasiuni,Nabarangapur,Odisha', 'lon': '82.406'},
      '764062': {'lat': '20.2342', 'name': 'Serjholi,Rayagada,Odisha', 'lon': '82.697'},
      '764061': {'lat': '18.9348', 'name': 'Bajraguda,Nabarangapur,Odisha', 'lon': '82.6193'},
      '400029': {'lat': '18.986', 'name': 'Santacruz P&t Colony,Mumbai,Maharashtra', 'lon': '72.8259'},
      '591128': {'lat': '15.8465', 'name': 'Turamari,Belagavi,Karnataka', 'lon': '75.1069'},
      '591129': {'lat': '16.089', 'name': 'Akkisagar,Belagavi,Karnataka', 'lon': '75.3497'},
      '314404': {'lat': '24.0939', 'name': 'Vikasnagar (Dungarpur),Dungarpur,Rajasthan', 'lon': '73.5954'},
      '799150': {'lat': '23.2518', 'name': 'Kasari,South Tripura,Tripura', 'lon': '91.4541'},
      '314402': {'lat': '23.5897', 'name': 'Pohri Patelan,Dungarpur,Rajasthan', 'lon': '73.7666'},
      '686691': {'lat': '10.0808', 'name': 'Iramalloor,Ernakulam,Kerala', 'lon': '76.6836'},
      '314401': {'lat': '23.6402', 'name': 'Phaota,Dungarpur,Rajasthan', 'lon': '73.8955'},
      '591120': {'lat': '15.6008', 'name': 'Halga,Belagavi,Karnataka', 'lon': '74.9175'},
      '591121': {'lat': '15.9224', 'name': 'Mutwad,Belagavi,Karnataka', 'lon': '74.7936'},
      '591122': {'lat': '15.5566', 'name': 'Urabinatti,Belagavi,Karnataka', 'lon': '74.8456'},
      '591123': {'lat': '15.9211', 'name': 'Haletorgal,Belagavi,Karnataka', 'lon': '75.455'},
      '591124': {'lat': '15.87', 'name': 'Sindholi,Belagavi,Karnataka', 'lon': '74.6114'},
      '591125': {'lat': '15.7958', 'name': 'Tigadi,Belagavi,Karnataka', 'lon': '74.7183'},
      '591126': {'lat': '15.7891', 'name': 'Hoolikatti,Belagavi,Karnataka', 'lon': '75.1673'},
      '591127': {'lat': '15.8726', 'name': 'Gonnagar,Belagavi,Karnataka', 'lon': '75.4451'},
      '473332': {'lat': '24.8158', 'name': 'Dungasara,Ashok Nagar,Madhya Pradesh', 'lon': '77.6173'},
      '132046': {'lat': '29.5181', 'name': 'A.K.G.V.Mormajra,Karnal,Haryana', 'lon': '76.7243'},
      '132040': {'lat': '29.5187', 'name': 'Ballah,Karnal,Haryana', 'lon': '76.8496'},
      '132041': {'lat': '29.842', 'name': 'Garhi Jattan,Karnal,Haryana', 'lon': '77.0298'},
      '792121': {'lat': '27.2343', 'name': 'Manmao,Changlang,Arunachal Pradesh', 'lon': '95.9139'},
      '792120': {'lat': '27.1318', 'name': 'Namdang,Changlang,Arunachal Pradesh', 'lon': '95.7349'},
      '370670': {'lat': '23.4167', 'name': 'Kadia Nana,Kachchh,Gujarat', 'lon': '69.1167'},
      '456771': {'lat': '23.3317', 'name': 'Lohana,Ujjain,Madhya Pradesh', 'lon': '75.6684'},
      '370675': {'lat': '23.4167', 'name': 'Angiya Mota,Kachchh,Gujarat', 'lon': '69.1167'},
      '493888': {'lat': '20.2514', 'name': 'Shobha,Raipur,Chattisgarh', 'lon': '82.2079'},
      '384435': {'lat': '22.6112', 'name': 'Linch,Mahesana,Gujarat', 'lon': '72.9328'},
      '327601': {'lat': '23.4167', 'name': 'Rohanwari,Banswara,Rajasthan', 'lon': '74.1145'},
      '384430': {'lat': '23.4711', 'name': 'Virsoda,Mahesana,Gujarat', 'lon': '72.2373'},
      '769042': {'lat': '21.7238', 'name': 'Jabaghat,Sundergarh,Odisha', 'lon': '84.5352'},
      '769043': {'lat': '22.1872', 'name': 'Garjan,Sundergarh,Odisha', 'lon': '84.8436'},
      '636118': {'lat': '11.4698', 'name': 'Naraikinar,Namakkal,Tamil Nadu', 'lon': '78.4591'},
      '627114': {'lat': '8.2068', 'name': 'Leninjipuram,Tirunelveli,Tamil Nadu', 'lon': '77.6014'},
      '638458': {'lat': '11.3641', 'name': 'Pattampalayam,Coimbatore,Tamil Nadu', 'lon': '77.3367'},
      '638459': {'lat': '11.147', 'name': 'Sellappampalayam,Erode,Tamil Nadu', 'lon': '77.4747'},
      '193303': {'lat': '34.2319', 'name': 'Nandigam,Baramulla,Jammu & Kashmir', 'lon': '74.5435'},
      '638451': {'lat': '11.4902', 'name': 'Pudupeerkadavu`,Erode,Tamil Nadu', 'lon': '77.1269'},
      '638452': {'lat': '11.3627', 'name': 'Gobichettipalayam East,Erode,Tamil Nadu', 'lon': '77.3662'},
      '638453': {'lat': '11.4062', 'name': 'Alukuli,Erode,Tamil Nadu', 'lon': '77.4018'},
      '638454': {'lat': '11.4218', 'name': 'Akkaraikodiveri,Erode,Tamil Nadu', 'lon': '77.2694'},
      '638455': {'lat': '11.4279', 'name': 'Pandiampalayam,Erode,Tamil Nadu', 'lon': '77.5601'},
      '638456': {'lat': '11.3791', 'name': 'Talguni,Erode,Tamil Nadu', 'lon': '77.4293'},
      '638457': {'lat': '11.4204', 'name': 'Alingiam(Gobi),Erode,Tamil Nadu', 'lon': '77.3482'},
      '560115': {'lat': '13.2149', 'name': 'Kannamangala,Bengaluru,Karnataka', 'lon': '77.6671'},
      '560114': {'lat': '13.1391', 'name': 'Begur,Bengaluru,Karnataka', 'lon': '77.8431'},
      '560117': {'lat': '13.1229', 'name': 'Doddadunnasandra,Bengaluru,Karnataka', 'lon': '77.5689'},
      '385520': {'lat': '24.0893', 'name': 'Kanodar,Banaskantha,Gujarat', 'lon': '72.3935'},
      '560111': {'lat': '13.2257', 'name': 'Kumaraswamy Layout,Bengaluru,Karnataka', 'lon': '77.575'},
      '560110': {'lat': '13.1229', 'name': 'Ullalu Upanagar,Bengaluru,Karnataka', 'lon': '77.5689'},
      '560113': {'lat': '13.1229', 'name': 'Horamavu,Bengaluru,Karnataka', 'lon': '77.5689'},
      '560112': {'lat': '13.336', 'name': 'Kodigehalli,Bengaluru,Karnataka', 'lon': '77.6376'},
      '444507': {'lat': '20.1687', 'name': 'Pardi Asra,Washim,Maharashtra', 'lon': '77.3536'},
      '444506': {'lat': '20.0593', 'name': 'Shelukhadse,Washim,Maharashtra', 'lon': '76.8659'},
      '444505': {'lat': '20.1565', 'name': 'Kajalamba,Washim,Maharashtra', 'lon': '77.1159'},
      '444504': {'lat': '20.2141', 'name': 'Masalapen,Washim,Maharashtra', 'lon': '77.3985'},
      '444503': {'lat': '20.277', 'name': 'Kiniraja,Washim,Maharashtra', 'lon': '77.4568'},
      '444502': {'lat': '20.7329', 'name': 'Vadegaon,Akola,Maharashtra', 'lon': '78.45'},
      '444501': {'lat': '20.5778', 'name': 'Tulanga BK,Akola,Maharashtra', 'lon': '77.4406'},
      '482009': {'lat': '23.1937', 'name': 'Vehicle Factory,Jabalpur,Madhya Pradesh', 'lon': '79.9755'},
      '847203': {'lat': '25.9493', 'name': 'Bauram,Darbhanga,Bihar', 'lon': '86.2351'},
      '504303': {'lat': '19.3322', 'name': 'Srirampur Colony,Adilabad,Telangana', 'lon': '78.524'},
      '721636': {'lat': '22.6625', 'name': 'Salgechhia,East Midnapore,West Bengal', 'lon': '88.2222'},
      '384272': {'lat': '23.9674', 'name': 'Sampra,Patan,Gujarat', 'lon': '71.9707'},
      '847230': {'lat': '26.513', 'name': 'Manpour,Madhubani,Bihar', 'lon': '85.9582'},
      '402126': {'lat': '18.4993', 'name': 'Pipenagar,Raigarh(MH),Maharashtra', 'lon': '73.09705'},
      '402125': {'lat': '18.58745', 'name': 'PCTS Nagothane,Raigarh(MH),Maharashtra', 'lon': '73.1637'},
      '847233': {'lat': '26.0124', 'name': 'Habi Bhuar,Darbhanga,Bihar', 'lon': '86.0544'},
      '847234': {'lat': '26.2589', 'name': 'Shambhuar,Madhubani,Bihar', 'lon': '86.0961'},
      '402122': {'lat': '18.237054', 'name': 'Govele,Raigarh(MH),Maharashtra', 'lon': '73.283596'},
      '847236': {'lat': '26.3285', 'name': 'Raghopurbalat,Madhubani,Bihar', 'lon': '86.1639'},
      '402120': {'lat': '18.237052', 'name': 'Jite,Raigarh(MH),Maharashtra', 'lon': '73.283598'},
      '721604': {'lat': '22.0605', 'name': 'Haldia,East Midnapore,West Bengal', 'lon': '88.1097'},
      '636904': {'lat': '11.9963', 'name': 'Molayanur,Dharmapuri,Tamil Nadu', 'lon': '78.3925'},
      '721606': {'lat': '23.0907', 'name': 'Haldia O.R,East Midnapore,West Bengal', 'lon': '87.9318'},
      '721607': {'lat': '23.0907', 'name': 'Haldia T.S,East Midnapore,West Bengal', 'lon': '87.9318'},
      '848503': {'lat': '25.6343', 'name': 'Bochaha,Samastipur,Bihar', 'lon': '85.7994'},
      '761151': {'lat': '19.5977', 'name': 'Asura Bandha,Ganjam,Odisha', 'lon': '84.3206'},
      '721602': {'lat': '22.0758', 'name': 'Basudevpur,East Midnapore,West Bengal', 'lon': '88.1375'},
      '636905': {'lat': '11.5549', 'name': 'A.Pallipatti,Dharmapuri,Tamil Nadu', 'lon': '78.2106'},
      '624705': {'lat': '10.3699', 'name': 'Dharmathupatti,Dindigul,Tamil Nadu', 'lon': '77.8612'},
      '624619': {'lat': '10.4223', 'name': 'Kalanjipatti,Dindigul,Tamil Nadu', 'lon': '77.8097'},
      '624618': {'lat': '10.5156', 'name': 'Thalaiyuthu,Dindigul,Tamil Nadu', 'lon': '77.4847'},
      '759029': {'lat': '21.0727', 'name': 'Dashipur,Dhenkanal,Odisha', 'lon': '85.3458'},
      '759028': {'lat': '21.0773', 'name': 'Karagola,Dhenkanal,Odisha', 'lon': '85.5467'},
      '759027': {'lat': '21.5259', 'name': 'Gahamkhunti,Dhenkanal,Odisha', 'lon': '85.3617'},
      '759026': {'lat': '21.0647', 'name': 'Aluajharan,Dhenkanal,Odisha', 'lon': '85.5221'},
      '759025': {'lat': '21.2731', 'name': 'Kandabindha,Dhenkanal,Odisha', 'lon': '85.2819'},
      '759024': {'lat': '21.0945', 'name': 'Surapratapapur,Dhenkanal,Odisha', 'lon': '85.1157'},
      '759023': {'lat': '20.5995', 'name': 'Dighi,Dhenkanal,Odisha', 'lon': '85.1972'},
      '744302': {'lat': '9.1833', 'name': 'Gandhinagar,Nicobar,Andaman & Nicobar Islands', 'lon': '92.7667'},
      '759021': {'lat': '20.6298', 'name': 'Kalingapal,Dhenkanal,Odisha', 'lon': '85.3126'},
      '759020': {'lat': '20.6906', 'name': 'Thokar,Dhenkanal,Odisha', 'lon': '85.2308'},
      '224118': {'lat': '26.5902', 'name': 'Chandramau,Faizabad,Uttar Pradesh', 'lon': '81.6667'},
      '224119': {'lat': '26.8041', 'name': 'Pure Kamgar,Faizabad,Uttar Pradesh', 'lon': '81.6531'},
      '573116': {'lat': '12.9146', 'name': 'Gowdagere,Hassan,Karnataka', 'lon': '76.4317'},
      '482002': {'lat': '23.0996', 'name': 'Kamala Nehru Nagar,Jabalpur,Madhya Pradesh', 'lon': '80.0166'},
      '180018': {'lat': '32.7155', 'name': 'Udheywala,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180019': {'lat': '32.7155', 'name': 'Sidhra Housing Colony,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180016': {'lat': '32.7155', 'name': 'Vinaik Bazar,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180017': {'lat': '32.7737', 'name': 'Surinsar,Jammu,Jammu & Kashmir', 'lon': '75.0004'},
      '180015': {'lat': '32.7155', 'name': 'Chani Himat,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180012': {'lat': '32.7155', 'name': 'New Fruit Market,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180013': {'lat': '32.7155', 'name': 'Roopnagar Jammu Tawi,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '180010': {'lat': '32.6572', 'name': 'Kalu Chak,Jammu,Jammu & Kashmir', 'lon': '74.9017'},
      '180011': {'lat': '32.7155', 'name': 'Chowadi,Jammu,Jammu & Kashmir', 'lon': '74.951'},
      '456221': {'lat': '23.3807', 'name': 'Paslod,Ujjain,Madhya Pradesh', 'lon': '75.5639'},
      '212656': {'lat': '25.5863', 'name': 'Sothrapur,Fatehpur,Uttar Pradesh', 'lon': '81.1321'},
      '835101': {'lat': '23.4532', 'name': 'Halmad,Ranchi,Jharkhand', 'lon': '85.7477'},
      '415641': {'lat': '17.336', 'name': 'Pilavali,Ratnagiri,Maharashtra', 'lon': '73.5445'},
      '641048': {'lat': '10.9111', 'name': 'Vellanaipatti,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '531034': {'lat': '17.858', 'name': 'K Santhaplem,Visakhapatnam,Andhra Pradesh', 'lon': '83.0356'},
      '531035': {'lat': '17.7364', 'name': 'Chintagatla,Visakhapatnam,Andhra Pradesh', 'lon': '82.9948'},
      '531036': {'lat': '17.7521', 'name': 'Arjunagiri,Visakhapatnam,Andhra Pradesh', 'lon': '82.7992'},
      '680601': {'lat': '10.8701', 'name': 'Velur Bazar,Thrissur,Kerala', 'lon': '76.0435'},
      '531030': {'lat': '17.9206', 'name': 'Pedanandipalle Agraharam,Visakhapatnam,Andhra Pradesh', 'lon': '82.8421'},
      '531031': {'lat': '17.6143', 'name': 'Theeda,Visakhapatnam,Andhra Pradesh', 'lon': '82.8703'},
      '531032': {'lat': '17.8543', 'name': 'R.Bheemavaram,Visakhapatnam,Andhra Pradesh', 'lon': '83.0121'},
      '531033': {'lat': '17.6155', 'name': 'Vadrapalle,Visakhapatnam,Andhra Pradesh', 'lon': '82.9741'},
      '759122': {'lat': '20.844402', 'name': 'Angul H.O,Angul,Odisha', 'lon': '85.151085'},
      '759123': {'lat': '20.8902', 'name': 'Kukudang,Angul,Odisha', 'lon': '85.1476'},
      '759120': {'lat': '20.9243', 'name': 'Damal,Dhenkanal,Odisha', 'lon': '85.3471'},
      '759121': {'lat': '20.7766', 'name': 'Sibapur,Dhenkanal,Odisha', 'lon': '85.3045'},
      '759126': {'lat': '20.9412', 'name': 'Oskapalli,Angul,Odisha', 'lon': '84.47855'},
      '759127': {'lat': '20.89562', 'name': 'Kishoreganj,Angul,Odisha', 'lon': '84.3295'},
      '759124': {'lat': '21.10971', 'name': 'Machhakuta,Angul,Odisha', 'lon': '84.8817'},
      '759125': {'lat': '20.2993957', 'name': 'Puruna Manitiri,Angul,Odisha', 'lon': '85.327502'},
      '759128': {'lat': '20.83406', 'name': 'Kalandapal,Angul,Odisha', 'lon': '85.22423'},
      '759129': {'lat': '20.85001', 'name': 'Baragounia,Angul,Odisha', 'lon': '85.09999'},
      '493889': {'lat': '20.7467', 'name': 'Piperchhedi,Raipur,Chattisgarh', 'lon': '82.1212'},
      '508247': {'lat': '17.381', 'name': 'Chinnamadharam,Nalgonda,Telangana', 'lon': '80.1911'},
      '573119': {'lat': '12.898', 'name': 'Mududi,Hassan,Karnataka', 'lon': '76.4565'},
      '508245': {'lat': '17.1597', 'name': 'Vattiparthy,Nalgonda,Telangana', 'lon': '79.0661'},
      '508244': {'lat': '17.2063', 'name': 'Domalapally,Nalgonda,Telangana', 'lon': '79.0886'},
      '508243': {'lat': '16.6779', 'name': 'Vaddipatla,Nalgonda,Telangana', 'lon': '79.019'},
      '573118': {'lat': '13.0788', 'name': 'Dudda,Hassan,Karnataka', 'lon': '76.2147'},
      '151205': {'lat': '30.4707', 'name': 'Baja Khana,Faridkot,Punjab', 'lon': '75.0165'},
      '151204': {'lat': '30.4841', 'name': 'Matta,Fazilka,Punjab', 'lon': '74.8378'},
      '151207': {'lat': '30.599', 'name': 'Jiwan Wala,Faridkot,Punjab', 'lon': '74.9343'},
      '151206': {'lat': '30.4907', 'name': 'Siryewala,Bathinda,Punjab', 'lon': '75.0471'},
      '151201': {'lat': '30.3481', 'name': 'Nehian Wala,Bathinda,Punjab', 'lon': '74.8841'},
      '151203': {'lat': '30.4846', 'name': 'Faridkot Mandi,Faridkot,Punjab', 'lon': '74.8746'},
      '151202': {'lat': '30.4046', 'name': 'Rorikapura,Faridkot,Punjab', 'lon': '74.8737'},
      '325217': {'lat': '25.2491', 'name': 'Shahabad (Baran),Baran,Rajasthan', 'lon': '77.1538'},
      '786126': {'lat': '27.2421', 'name': 'Borguri,Tinsukia,Assam', 'lon': '94.612'},
      '151208': {'lat': '30.5474', 'name': 'Bambhia Bhai Ka,Faridkot,Punjab', 'lon': '74.8446'},
      '465337': {'lat': '23.6323', 'name': 'Dhabla Dheer,Shajapur,Madhya Pradesh', 'lon': '76.7541'},
      '786125': {'lat': '27.2421', 'name': 'Prakash Bazar,Tinsukia,Assam', 'lon': '94.612'},
      '515286': {'lat': '13.9945', 'name': 'Kenkara,Ananthapur,Andhra Pradesh', 'lon': '77'},
      '854205': {'lat': '25.792', 'name': 'Sanjhaghat,Purnia,Bihar', 'lon': '87.0274'},
      '784146': {'lat': '26.6676', 'name': 'Balabari,Darrang,Assam', 'lon': '92.4009'},
      '784147': {'lat': '26.6676', 'name': 'Pati Darrang,Darrang,Assam', 'lon': '92.4009'},
      '784144': {'lat': '26.6676', 'name': 'Santipukhuri,Darrang,Assam', 'lon': '92.4009'},
      '784145': {'lat': '26.6676', 'name': 'Marowachowka,Darrang,Assam', 'lon': '92.4009'},
      '784148': {'lat': '26.4695', 'name': 'Tengara,Darrang,Assam', 'lon': '91.844'},
      '784149': {'lat': '26.7336', 'name': 'Borbil Kachari,Sonitpur,Assam', 'lon': '92.5865'},
      '505529': {'lat': '18.77', 'name': 'Laxmipur,Karim Nagar,Telangana', 'lon': '78.9015'},
      '505528': {'lat': '18.1823', 'name': 'Gagillapur,Karim Nagar,Telangana', 'lon': '79.2436'},
      '829128': {'lat': '23.7835', 'name': 'Swang Colliery,Bokaro,Jharkhand', 'lon': '85.8543'},
      '829129': {'lat': '23.7041', 'name': 'Tenughat Dam,Bokaro,Jharkhand', 'lon': '85.7588'},
      '829124': {'lat': '23.7041', 'name': 'Religarha,Ramgarh,Jharkhand', 'lon': '85.7588'},
      '829125': {'lat': '23.6307', 'name': 'Sayal,Ramgarh,Jharkhand', 'lon': '85.5606'},
      '829126': {'lat': '23.7041', 'name': 'Sounda C,Ramgarh,Jharkhand', 'lon': '85.7588'},
      '829127': {'lat': '23.7041', 'name': 'Bedkaro,Bokaro,Jharkhand', 'lon': '85.7588'},
      '212303': {'lat': '25.2338', 'name': 'Uruwa,Allahabad,Uttar Pradesh', 'lon': '82.0972'},
      '829121': {'lat': '23.6548', 'name': 'Mungasarla,Bokaro,Jharkhand', 'lon': '85.8553'},
      '829122': {'lat': '23.7041', 'name': 'Ramgarh Cantt H.O,Ramgarh,Jharkhand', 'lon': '85.7588'},
      '829123': {'lat': '23.7345', 'name': 'Ulgada,Bokaro,Jharkhand', 'lon': '85.876'},
      '854204': {'lat': '25.6828', 'name': 'Sondeep,Purnia,Bihar', 'lon': '87.0648'},
      '261135': {'lat': '27.5789', 'name': 'Laharpur East,Sitapur,Uttar Pradesh', 'lon': '80.9921'},
      '505530': {'lat': '17.6246', 'name': 'Vachunur,Karim Nagar,Telangana', 'lon': '78.8588'},
      '736121': {'lat': '26.7042', 'name': 'Boragari,Alipurduar,West Bengal', 'lon': '89.1455'},
      '230304': {'lat': '25.9355', 'name': 'Medhauli,Pratapgarh,Uttar Pradesh', 'lon': '82.3331'},
      '736123': {'lat': '26.7042', 'name': 'Majherdabri,Alipurduar,West Bengal', 'lon': '89.1455'},
      '736122': {'lat': '26.7042', 'name': 'Banchukumari,Alipurduar,West Bengal', 'lon': '89.1455'},
      '518313': {'lat': '15.8831', 'name': 'Arlabanda,Kurnool,Andhra Pradesh', 'lon': '77.2241'},
      '603319': {'lat': '12.4554', 'name': 'Melmaruvathur,Kanchipuram,Tamil Nadu', 'lon': '79.8253'},
      '603311': {'lat': '12.3988', 'name': 'Peria Venmani,Kanchipuram,Tamil Nadu', 'lon': '79.8708'},
      '603310': {'lat': '12.3003', 'name': 'Sirunagar,Kanchipuram,Tamil Nadu', 'lon': '79.817'},
      '603313': {'lat': '12.3768', 'name': 'Neerpair,Kanchipuram,Tamil Nadu', 'lon': '79.9363'},
      '603312': {'lat': '12.767', 'name': 'K.Nelvoy,Kanchipuram,Tamil Nadu', 'lon': '79.9148'},
      '515331': {'lat': '13.8116', 'name': 'Bisalamanepalli,Ananthapur,Andhra Pradesh', 'lon': '77.5879'},
      '603314': {'lat': '12.3988', 'name': 'Pulithivakkam,Kanchipuram,Tamil Nadu', 'lon': '79.8708'},
      '515445': {'lat': '14.8742', 'name': 'Muchukota,Ananthapur,Andhra Pradesh', 'lon': '78.1082'},
      '741223': {'lat': '23.7129', 'name': 'Hinghnara,Nadia,West Bengal', 'lon': '87.9326'},
      '678685': {'lat': '10.6187', 'name': 'Mannapra,Palakkad,Kerala', 'lon': '76.4742'},
      '678684': {'lat': '10.6686', 'name': 'Mulangot,Palakkad,Kerala', 'lon': '76.3671'},
      '678687': {'lat': '10.6686', 'name': 'Puthucode,Palakkad,Kerala', 'lon': '76.3671'},
      '678686': {'lat': '10.6137', 'name': 'Paruvasseri,Palakkad,Kerala', 'lon': '76.4447'},
      '678681': {'lat': '10.6686', 'name': 'Koottala,Palakkad,Kerala', 'lon': '76.3671'},
      '678683': {'lat': '10.6091', 'name': 'Panniankara,Palakkad,Kerala', 'lon': '76.4745'},
      '678682': {'lat': '10.6686', 'name': 'Thannilapuram,Palakkad,Kerala', 'lon': '76.3671'},
      '387380': {'lat': '22.8207', 'name': 'Vaso,Kheda,Gujarat', 'lon': '73.4451'},
      '678688': {'lat': '10.8328', 'name': 'Kudallur,Palakkad,Kerala', 'lon': '76.0751'},
      '221405': {'lat': '25.6658', 'name': 'Rameswar,Varanasi,Uttar Pradesh', 'lon': '82.7348'},
      '723146': {'lat': '23.4672', 'name': 'Deoli,Purulia,West Bengal', 'lon': '86.7136'},
      '832108': {'lat': '22.9233', 'name': 'Dugdha,Seraikela-kharsawan,Jharkhand', 'lon': '85.9792'},
      '212657': {'lat': '25.9848', 'name': 'Barigawan,Fatehpur,Uttar Pradesh', 'lon': '80.8126'},
      '686521': {'lat': '9.5715', 'name': 'Poothakuzhy,Kottayam,Kerala', 'lon': '76.7497'},
      '686520': {'lat': '9.5449', 'name': 'Pazhayidom,Kottayam,Kerala', 'lon': '76.7695'},
      '670504': {'lat': '12.0588', 'name': 'Vilayancode,Kannur,Kerala', 'lon': '75.3248'},
      '686522': {'lat': '9.5982', 'name': 'Panamattom,Kottayam,Kerala', 'lon': '76.7299'},
      '670502': {'lat': '12.0588', 'name': 'Thiruvattoor,Kannur,Kerala', 'lon': '75.3248'},
      '670503': {'lat': '12.0588', 'name': 'Pariyaram Medical College,Kannur,Kerala', 'lon': '75.3248'},
      '670501': {'lat': '12.0588', 'name': 'Mandur-kannur,Kannur,Kerala', 'lon': '75.3248'},
      '623524': {'lat': '9.4263', 'name': 'Vellariodai,Ramanathapuram,Tamil Nadu', 'lon': '78.8147'},
      '623525': {'lat': '9.6345', 'name': 'Sengudi,Ramanathapuram,Tamil Nadu', 'lon': '78.8145'},
      '623526': {'lat': '9.2885', 'name': 'Olaikuda,Ramanathapuram,Tamil Nadu', 'lon': '79.3127'},
      '623527': {'lat': '9.4269', 'name': 'Muthuvayal,Ramanathapuram,Tamil Nadu', 'lon': '78.6934'},
      '623520': {'lat': '9.4263', 'name': 'Marine Fisheries,Ramanathapuram,Tamil Nadu', 'lon': '78.8147'},
      '623521': {'lat': '9.279', 'name': 'Akkamadam,Ramanathapuram,Tamil Nadu', 'lon': '79.2109'},
      '431509': {'lat': '19.5921', 'name': 'Pachalegaon,Parbhani,Maharashtra', 'lon': '77.1544'},
      '172030': {'lat': '31.3461', 'name': 'Virgarh,Kinnaur,Himachal Pradesh', 'lon': '77.4899'},
      '431507': {'lat': '19.3341', 'name': 'Raigavan,Jalna,Maharashtra', 'lon': '76.1934'},
      '431506': {'lat': '19.2237', 'name': 'Renapur,Parbhani,Maharashtra', 'lon': '76.4743'},
      '431505': {'lat': '19.2788', 'name': 'Manwath Town,Parbhani,Maharashtra', 'lon': '76.5298'},
      '431504': {'lat': '19.6825', 'name': 'Pangri BK,Jalna,Maharashtra', 'lon': '76.3932'},
      '431503': {'lat': '19.5648', 'name': 'Patoda,Jalna,Maharashtra', 'lon': '76.4226'},
      '172033': {'lat': '31.384', 'name': 'Nither,Kullu,Himachal Pradesh', 'lon': '77.4902'},
      '431501': {'lat': '19.6355', 'name': 'Vaidya Wadgaon,Jalna,Maharashtra', 'lon': '76.2856'},
      '172032': {'lat': '31.5373', 'name': 'Chowai,Kullu,Himachal Pradesh', 'lon': '77.3201'},
      '221403': {'lat': '25.5338', 'name': 'Kalikabara,Varanasi,Uttar Pradesh', 'lon': '82.7811'},
      '273017': {'lat': '26.7339', 'name': 'Siddharth Enclave,Gorakhpur,Uttar Pradesh', 'lon': '83.3717'},
      '731121': {'lat': '22.6001', 'name': 'Batikar,Birbhum,West Bengal', 'lon': '88.5271'},
      '261136': {'lat': '27.536', 'name': 'Lakhuwabehar,Sitapur,Uttar Pradesh', 'lon': '81.2319'},
      '362275': {'lat': '20.8125', 'name': 'Singsar,Junagadh,Gujarat', 'lon': '70.5479'},
      '362276': {'lat': '20.8125', 'name': 'Siddhigram,Junagadh,Gujarat', 'lon': '70.5479'},
      '585324': {'lat': '17.1823', 'name': 'Rummanagod,Kalaburagi,Karnataka', 'lon': '76.9379'},
      '854330': {'lat': '25.8814', 'name': 'Baraidagah,Purnia,Bihar', 'lon': '87.5257'},
      '753007': {'lat': '20.2151', 'name': 'O S E,Cuttack,Odisha', 'lon': '85.0791'},
      '535273': {'lat': '18.3235', 'name': 'Pedaraba,Visakhapatnam,Andhra Pradesh', 'lon': '83.2113'},
      '535270': {'lat': '18.2823', 'name': 'Tummikapalli,Vizianagaram,Andhra Pradesh', 'lon': '83.3492'},
      '678534': {'lat': '10.6534', 'name': 'Nanniode,Palakkad,Kerala', 'lon': '76.7736'},
      '678533': {'lat': '10.6686', 'name': 'Meenakshipuram,Palakkad,Kerala', 'lon': '76.744'},
      '678532': {'lat': '10.6686', 'name': 'Karippali,Palakkad,Kerala', 'lon': '76.744'},
      '678531': {'lat': '10.699', 'name': 'Peruvemba,Palakkad,Kerala', 'lon': '76.6849'},
      '731125': {'lat': '23.0615', 'name': 'Pursundi,Birbhum,West Bengal', 'lon': '87.7362'},
      '500091': {'lat': '17.3535', 'name': 'Hydershahkote,K.V.Rangareddy,Telangana', 'lon': '78.2402'},
      '625701': {'lat': '9.9355', 'name': 'K.Sennampatti,Madurai,Tamil Nadu', 'lon': '78.0557'},
      '641041': {'lat': '10.88', 'name': 'Pappanaickenpudur,Coimbatore,Tamil Nadu', 'lon': '77.1539'},
      '124406': {'lat': '28.9046', 'name': 'Assan,Rohtak,Haryana', 'lon': '76.8039'},
      '124404': {'lat': '28.8238', 'name': 'Hassangarh,Jhajjar,Haryana', 'lon': '76.8415'},
      '222181': {'lat': '25.627', 'name': 'Thanagaddi,Jaunpur,Uttar Pradesh', 'lon': '82.4378'},
      '242220': {'lat': '27.7254', 'name': 'Jearrahimpur,Shahjahanpur,Uttar Pradesh', 'lon': '79.8836'},
      '124401': {'lat': '28.9324', 'name': 'Paksma,Rohtak,Haryana', 'lon': '76.6991'},
      '501359': {'lat': '17.2269', 'name': 'Jaitwaram,K.V.Rangareddy,Telangana', 'lon': '78.6229'},
      '854333': {'lat': '26.2684', 'name': 'Maldwar,Purnia,Bihar', 'lon': '87.4263'},
      '753006': {'lat': '20.2151', 'name': 'C R R I (Cuttack),Cuttack,Odisha', 'lon': '85.0791'},
      '609201': {'lat': '11.106', 'name': 'Talanayar Vsk,Nagapattinam,Tamil Nadu', 'lon': '79.5953'},
      '609203': {'lat': '11.1267', 'name': 'Arulmozhithevan,Nagapattinam,Tamil Nadu', 'lon': '79.6036'},
      '609202': {'lat': '11.2048', 'name': 'Manalmedu,Nagapattinam,Tamil Nadu', 'lon': '79.5702'},
      '609205': {'lat': '10.9246', 'name': 'Tiruvalaputhur,Nagapattinam,Tamil Nadu', 'lon': '79.5872'},
      '609204': {'lat': '11.106', 'name': 'Marathurai,Nagapattinam,Tamil Nadu', 'lon': '79.5953'},
      '854203': {'lat': '25.806', 'name': 'Bishanpur Deorhi,Purnia,Bihar', 'lon': '87.0838'},
      '387650': {'lat': '23.1198', 'name': 'Bhoja Na Muvada,Kheda,Gujarat', 'lon': '73.0638'},
      '722183': {'lat': '23.2593', 'name': 'Mejia Th Power Station,Bankura,West Bengal', 'lon': '87.3935'},
      '611002': {'lat': '10.8706', 'name': 'Durgashariff,Nagapattinam,Tamil Nadu', 'lon': '79.7716'},
      '854332': {'lat': '26.3824', 'name': 'Bathraha Asha Bhag,Araria,Bihar', 'lon': '87.3826'},
      '532264': {'lat': '18.4819', 'name': 'Turakasasanam,Srikakulam,Andhra Pradesh', 'lon': '83.9238'},
      '274701': {'lat': '26.1144', 'name': 'Belwa Baboo,Deoria,Uttar Pradesh', 'lon': '83.4984'},
      '274702': {'lat': '26.2364', 'name': 'Sawarenji,Deoria,Uttar Pradesh', 'lon': '84.0383'},
      '517370': {'lat': '13.6122', 'name': 'Beerangi,Chittoor,Andhra Pradesh', 'lon': '78.2995'},
      '535546': {'lat': '18.4919', 'name': 'Koyyannapeta,Vizianagaram,Andhra Pradesh', 'lon': '83.3361'},
      '608002': {'lat': '11.6327', 'name': 'Uthamacholamangalam,Cuddalore,Tamil Nadu', 'lon': '79.4467'},
      '734124': {'lat': '27.051', 'name': 'Tukvar,Darjiling,West Bengal', 'lon': '88.2618'},
      '535547': {'lat': '18.609', 'name': 'Saraivalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.2161'},
      '244924': {'lat': '28.985', 'name': 'Nanka Rani,Rampur,Uttar Pradesh', 'lon': '79.0538'},
      '711104': {'lat': '22.5833', 'name': 'Olabibitala,Howrah,West Bengal', 'lon': '88.3'},
      '854335': {'lat': '25.7987', 'name': 'Fatehpur,Katihar,Bihar', 'lon': '87.8605'},
      '753004': {'lat': '20.2151', 'name': 'Nayabazar (Cuttack),Cuttack,Odisha', 'lon': '85.0791'},
      '415540': {'lat': '17.5', 'name': 'Pimpari (Man),Satara,Maharashtra', 'lon': '74.7044'},
      '636602': {'lat': '11.8309', 'name': 'Semmanatham,Salem,Tamil Nadu', 'lon': '78.2091'},
      '636601': {'lat': '11.9338', 'name': 'Kombuthooki,Salem,Tamil Nadu', 'lon': '78.5031'},
      '641109': {'lat': '10.9264', 'name': 'Thennamanallur,Coimbatore,Tamil Nadu', 'lon': '76.8515'},
      '612601': {'lat': '10.8616', 'name': 'Kodavasal,Tiruvarur,Tamil Nadu', 'lon': '79.4796'},
      '641107': {'lat': '10.8977', 'name': 'Kuppaipalayam,Coimbatore,Tamil Nadu', 'lon': '76.9528'},
      '641104': {'lat': '11.2456', 'name': 'Chikkarampalayam,Coimbatore,Tamil Nadu', 'lon': '76.9482'},
      '532263': {'lat': '18.8826', 'name': 'Yerramukkam,Srikakulam,Andhra Pradesh', 'lon': '84.582'},
      '641103': {'lat': '11.0178', 'name': 'Ravathur,Coimbatore,Tamil Nadu', 'lon': '77.0629'},
      '641101': {'lat': '10.1451', 'name': 'Mathipalayam,Coimbatore,Tamil Nadu', 'lon': '77.2006'},
      '176081': {'lat': '31.8981', 'name': 'Taragarh (Kangra),Kangra,Himachal Pradesh', 'lon': '76.5453'},
      '176082': {'lat': '31.9366', 'name': 'Alampur (Kangra),Kangra,Himachal Pradesh', 'lon': '76.3468'},
      '176083': {'lat': '32.0214', 'name': 'Boda Lower,Kangra,Himachal Pradesh', 'lon': '76.3235'},
      '176084': {'lat': '31.9159', 'name': 'Sarhoot,Kangra,Himachal Pradesh', 'lon': '76.5448'},
      '176085': {'lat': '32.0658', 'name': 'Nanaon,Kangra,Himachal Pradesh', 'lon': '76.4817'},
      '176086': {'lat': '31.8393', 'name': 'Lahat,Kangra,Himachal Pradesh', 'lon': '76.3651'},
      '176087': {'lat': '31.7488', 'name': 'Tikker Indorian,Kangra,Himachal Pradesh', 'lon': '76.9764'},
      '176088': {'lat': '32.0371', 'name': 'Chadhiar,Kangra,Himachal Pradesh', 'lon': '76.5366'},
      '176089': {'lat': '31.7387', 'name': 'Rananagar,Kangra,Himachal Pradesh', 'lon': '76.8455'},
      '757074': {'lat': '22.3834', 'name': 'Sapanchua,Mayurbhanj,Odisha', 'lon': '86.0815'},
      '757075': {'lat': '21.3056', 'name': 'Kamalasole,Mayurbhanj,Odisha', 'lon': '85.0256'},
      '757073': {'lat': '21.6753', 'name': 'Tukpalasia,Mayurbhanj,Odisha', 'lon': '85.4494'},
      '517152': {'lat': '13.4988', 'name': 'Mogarala,Chittoor,Andhra Pradesh', 'lon': '79.0676'},
      '413253': {'lat': '18.3187', 'name': 'Koravali,Solapur,Maharashtra', 'lon': '75.1457'},
      '413252': {'lat': '18.1617', 'name': 'Kavhe,Solapur,Maharashtra', 'lon': '75.4218'},
      '413251': {'lat': '18.5641', 'name': 'Salase,Solapur,Maharashtra', 'lon': '74.8258'},
      '413250': {'lat': '18.3187', 'name': 'Ridhore,Solapur,Maharashtra', 'lon': '75.1457'},
      '624101': {'lat': '10.3898', 'name': 'Machur,Dindigul,Tamil Nadu', 'lon': '77.82'},
      '413255': {'lat': '17.7393', 'name': 'Hiraj,Solapur,Maharashtra', 'lon': '75.8293'},
      '796111': {'lat': '24.1037', 'name': 'Palsang,Aizawl,Mizoram', 'lon': '92.9257'},
      '641045': {'lat': '10.9111', 'name': 'Puliyakulam,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '630602': {'lat': '9.8282', 'name': 'Muthanendhal,Sivaganga,Tamil Nadu', 'lon': '78.286'},
      '630606': {'lat': '9.8282', 'name': 'Kannar Street,Sivaganga,Tamil Nadu', 'lon': '78.286'},
      '630609': {'lat': '9.8282', 'name': 'Milaganoor,Sivaganga,Tamil Nadu', 'lon': '78.286'},
      '825418': {'lat': '24.4605', 'name': 'Taranakho,Giridh,Jharkhand', 'lon': '85.8986'},
      '854337': {'lat': '25.6834', 'name': 'Bhogabhatgama,Purnia,Bihar', 'lon': '87.5754'},
      '781040': {'lat': '26.2021', 'name': 'Sawkuchi,Kamrup,Assam', 'lon': '91.5785'},
      '495446': {'lat': '22.0178', 'name': 'Birda,Korba,Chattisgarh', 'lon': '82.661'},
      '506371': {'lat': '17.8568', 'name': 'Pembarthy,Warangal,Telangana', 'lon': '79.4636'},
      '506370': {'lat': '18.0292', 'name': 'Devannapet,Warangal,Telangana', 'lon': '79.4951'},
      '641044': {'lat': '10.9111', 'name': 'Siddhapudur,Coimbatore,Tamil Nadu', 'lon': '77.0415'},
      '305627': {'lat': '26.142', 'name': 'Rammalia,Ajmer,Rajasthan', 'lon': '74.7892'},
      '848301': {'lat': '25.8134', 'name': 'Rampur Kushaiya,Samastipur,Bihar', 'lon': '85.7987'},
      '363630': {'lat': '21.7674', 'name': 'Jetpar (M),Rajkot,Gujarat', 'lon': '70.8645'},
      '431736': {'lat': '18.8603', 'name': 'Selgaon,Nanded,Maharashtra', 'lon': '77.5527'},
      '363635': {'lat': '21.7674', 'name': 'Khakhrechi,Rajkot,Gujarat', 'lon': '70.8645'},
      '263660': {'lat': '29.7798', 'name': 'Timtha,Almora,Uttarakhand', 'lon': '79.3107'},
      '632521': {'lat': '12.8365', 'name': 'Uppupettai,Vellore,Tamil Nadu', 'lon': '79.2931'},
      '753001': {'lat': '20.47', 'name': 'Choudhury Bazar,Cuttack,Odisha', 'lon': '85.0606'},
      '756039': {'lat': '21.6466', 'name': 'Barbatia Bazar,Baleswar,Odisha', 'lon': '87.4622'},
      '756038': {'lat': '21.5299', 'name': 'Rasalpur,Baleswar,Odisha', 'lon': '87.1423'},
      '848302': {'lat': '25.9044', 'name': 'Kalyanpur Chowk,Samastipur,Bihar', 'lon': '85.9472'},
      '585330': {'lat': '17.9941', 'name': 'Humnabad Industrial Area,Bidar,Karnataka', 'lon': '77.2966'},
      '585331': {'lat': '17.8428', 'name': 'Yerbagh,Bidar,Karnataka', 'lon': '76.9788'},
      '756033': {'lat': '21.8952', 'name': 'Mankidia,Baleswar,Odisha', 'lon': '87.1928'},
      '756032': {'lat': '21.4787', 'name': 'Nayabazar Jaleswar,Baleswar,Odisha', 'lon': '86.5126'},
      '756035': {'lat': '21.6749', 'name': 'Nachinda,Baleswar,Odisha', 'lon': '86.9187'},
      '756034': {'lat': '21.4787', 'name': 'Masanbaria,Baleswar,Odisha', 'lon': '86.5126'},
      '756037': {'lat': '21.0484', 'name': 'Nafrai,Baleswar,Odisha', 'lon': '85.0101'},
      '756036': {'lat': '21.1713', 'name': 'Guneibasan,Baleswar,Odisha', 'lon': '85.7574'},
      '331029': {'lat': '28.4669', 'name': 'Sirsali,Churu,Rajasthan', 'lon': '75.1395'},
      '141415': {'lat': '30.6132', 'name': 'Jarg,Ludhiana,Punjab', 'lon': '76.0694'},
      '768234': {'lat': '21.6748', 'name': 'Itps Banaharpali,Jharsuguda,Odisha', 'lon': '83.778'},
      '826005': {'lat': '24.0858', 'name': 'BCCl Township,Dhanbad,Jharkhand', 'lon': '86.7287'},
      '676108': {'lat': '10.701', 'name': 'Triprangode,Malappuram,Kerala', 'lon': '76.0214'},
      '676109': {'lat': '10.9204', 'name': 'Niramaruthur,Malappuram,Kerala', 'lon': '75.8952'},
      '827010': {'lat': '24.0262', 'name': 'Kunduari,Bokaro,Jharkhand', 'lon': '86.5629'},
      '141414': {'lat': '30.7218', 'name': 'Ikolaha,Ludhiana,Punjab', 'lon': '76.1385'},
      '676104': {'lat': '10.909', 'name': 'Trikkandiyur,Malappuram,Kerala', 'lon': '75.9202'},
      '676105': {'lat': '10.8899', 'name': 'Thekkummuri,Malappuram,Kerala', 'lon': '75.923'},
      '676106': {'lat': '10.9536', 'name': 'Ponmundam,Malappuram,Kerala', 'lon': '75.9468'},
      '676107': {'lat': '10.701', 'name': 'Pookayil Bazar,Malappuram,Kerala', 'lon': '76.0214'},
      '331025': {'lat': '28.2872', 'name': 'Bas Ghasiram,Jhujhunu,Rajasthan', 'lon': '75.3027'},
      '676101': {'lat': '10.701', 'name': 'Tirur(kerala) H.O,Malappuram,Kerala', 'lon': '76.0214'},
      '676102': {'lat': '10.0328', 'name': 'Vettom-pallippuram,Malappuram,Kerala', 'lon': '76.2846'},
      '676103': {'lat': '10.922', 'name': 'Thalakkadathur,Malappuram,Kerala', 'lon': '75.96'},
      '760005': {'lat': '20.1568', 'name': 'Hillpatna,Ganjam,Odisha', 'lon': '84.559'},
      '760004': {'lat': '20.8006', 'name': 'Courtpeta,Ganjam,Odisha', 'lon': '84.0651'},
      '760007': {'lat': '19.3355', 'name': 'Badakusasthali,Ganjam,Odisha', 'lon': '84.8685'},
      '760006': {'lat': '20.1568', 'name': 'Panigrahipentho,Ganjam,Odisha', 'lon': '84.559'},
      '760001': {'lat': '20.1568', 'name': 'Badakhemundi Street,Ganjam,Odisha', 'lon': '84.559'},
      '212107': {'lat': '25.3096', 'name': 'Othgi Tarhar,Allahabad,Uttar Pradesh', 'lon': '81.7834'},
      '760003': {'lat': '20.2159', 'name': 'Phulta,Ganjam,Odisha', 'lon': '84.6204'},
      '760002': {'lat': '20.1568', 'name': 'Puruna Berhampur,Ganjam,Odisha', 'lon': '84.559'},
      '141411': {'lat': '30.2814', 'name': 'Barwali Khurd,Patiala,Punjab', 'lon': '76.3539'},
      '212108': {'lat': '25.1482', 'name': 'Bihariya,Allahabad,Uttar Pradesh', 'lon': '81.6322'},
      '212109': {'lat': '25.6282', 'name': 'Baharia Bazar,Allahabad,Uttar Pradesh', 'lon': '81.9859'},
      '760009': {'lat': '20.1568', 'name': 'Nabeen,Ganjam,Odisha', 'lon': '84.559'},
      '760008': {'lat': '20.1568', 'name': 'Lanjipalli,Ganjam,Odisha', 'lon': '84.559'},
      '411040': {'lat': '18.5789', 'name': 'Wanowarie,Pune,Maharashtra', 'lon': '73.8532'},
      '411041': {'lat': '18.5789', 'name': 'Vadgaon Budruk,Pune,Maharashtra', 'lon': '73.8532'},
      '411042': {'lat': '18.5789', 'name': 'Guruwar Peth,Pune,Maharashtra', 'lon': '73.8532'},
      '411043': {'lat': '18.5789', 'name': 'Dhankawadi,Pune,Maharashtra', 'lon': '73.8532'},
      '411044': {'lat': '18.5789', 'name': 'P.C.N.T.,Pune,Maharashtra', 'lon': '73.8532'},
      '411045': {'lat': '18.5586', 'name': 'N.I.A.,Pune,Maharashtra', 'lon': '73.7794'},
      '411046': {'lat': '18.5789', 'name': 'Katraj,Pune,Maharashtra', 'lon': '73.8532'},
      '411047': {'lat': '18.5992', 'name': 'Vadgaon Shinde,Pune,Maharashtra', 'lon': '73.927'},
      '411048': {'lat': '18.5789', 'name': 'Khondhwa KH,Pune,Maharashtra', 'lon': '73.8532'},
      '313323': {'lat': '24.9635', 'name': 'Bijnol,Rajsamand,Rajasthan', 'lon': '73.9056'},
      '141412': {'lat': '30.7693', 'name': 'Bagli Kalan,Ludhiana,Punjab', 'lon': '76.1553'},
      '416012': {'lat': '19.0875', 'name': 'Kolhapur name H.O,Kolhapur,Maharashtra', 'lon': '76.0262'},
      '416013': {'lat': '19.6021', 'name': 'R K Nagar (Kolhapur),Kolhapur,Maharashtra', 'lon': '76.4903'},
      '416010': {'lat': '18.1093', 'name': 'Padali Khurd,Kolhapur,Maharashtra', 'lon': '75.7481'},
      '416011': {'lat': '19.8084', 'name': 'Washi,Kolhapur,Maharashtra', 'lon': '76.0724'},
      '271202': {'lat': '27.2669', 'name': 'Belawa Bahuta,Gonda,Uttar Pradesh', 'lon': '82.0548'},
      '464331': {'lat': '23.6276', 'name': 'Mohammadgarh,Vidisha,Madhya Pradesh', 'lon': '78.1301'},
      '249124': {'lat': '30.2705', 'name': 'Bhagirathipuram,Tehri Garhwal,Uttarakhand', 'lon': '78.6059'},
      '516203': {'lat': '15.7163', 'name': 'Thudumaladinne,Cuddapah,Andhra Pradesh', 'lon': '78.2944'},
      '484336': {'lat': '22.9039', 'name': 'Pasan,Anuppur,Madhya Pradesh', 'lon': '81.707'},
      '414701': {'lat': '18.7165', 'name': 'Yelapane,Ahmed Nagar,Maharashtra', 'lon': '74.3741'},
      '271207': {'lat': '27.1776', 'name': 'Motipur,Balrampur,Uttar Pradesh', 'lon': '82.4275'},
      '628402': {'lat': '9.0926', 'name': 'Pudiyamputhur,Tuticorin,Tamil Nadu', 'lon': '77.7441'},
      '472446': {'lat': '25.3956', 'name': 'Saktibheron,Tikamgarh,Madhya Pradesh', 'lon': '79.2631'},
      '472445': {'lat': '26.0382', 'name': 'Ubora,Tikamgarh,Madhya Pradesh', 'lon': '78.9555'},
      '628401': {'lat': '9.7656', 'name': 'Kulasekaranallur,Tuticorin,Tamil Nadu', 'lon': '77.4924'},
      '533339': {'lat': '17.344', 'name': 'Chinaramanayyapeta,East Godavari,Andhra Pradesh', 'lon': '81.6347'},
      '472442': {'lat': '24.9883', 'name': 'Mudara,Tikamgarh,Madhya Pradesh', 'lon': '78.9467'},
      '800026': {'lat': '25.5977', 'name': 'B.H.Colony,Patna,Bihar', 'lon': '85.189'},
      '751027': {'lat': '20.8591', 'name': 'International Business Centre,Khorda,Odisha', 'lon': '84.4607'},
      '751026': {'lat': '20.8591', 'name': 'N.M.D OF PARCEL CENTRE Bhubaneswar,Khorda,Odisha', 'lon': '84.4607'},
      '751025': {'lat': '20.8591', 'name': 'G.G.P.Colony,Khorda,Odisha', 'lon': '84.4607'},
      '751024': {'lat': '20.8591', 'name': 'Patia Gds,Khorda,Odisha', 'lon': '84.4607'},
      '751023': {'lat': '20.8591', 'name': 'S.E Rly.Proj. Complex,Khorda,Odisha', 'lon': '84.4607'},
      '751022': {'lat': '20.8591', 'name': 'Bhoinagar,Khorda,Odisha', 'lon': '84.4607'},
      '751021': {'lat': '20.8591', 'name': 'Sailashree Vihar,Khorda,Odisha', 'lon': '84.4607'},
      '751020': {'lat': '20.8591', 'name': 'Pokhariput,Khorda,Odisha', 'lon': '84.4607'},
      '497451': {'lat': '23.1316', 'name': 'Haldibadi,Koriya,Chattisgarh', 'lon': '82.2859'},
      '624615': {'lat': '10.4864', 'name': 'Chinnakalayamputhur,Dindigul,Tamil Nadu', 'lon': '77.3238'},
      '202139': {'lat': '27.7066', 'name': 'K G W Sasni,Hathras,Uttar Pradesh', 'lon': '78.1286'},
      '500097': {'lat': '17.3535', 'name': 'Meerpet,Hyderabad,Telangana', 'lon': '78.2402'},
      '751029': {'lat': '20.8591', 'name': 'Ghatikia,Khorda,Odisha', 'lon': '84.4607'},
      '751028': {'lat': '20.8591', 'name': 'Tamando,Khorda,Odisha', 'lon': '84.4607'},
      '271209': {'lat': '26.9214', 'name': 'Bhawanipur Uppadhyay,Gonda,Uttar Pradesh', 'lon': '84.038'},
      '151210': {'lat': '30.2678', 'name': 'Sarawan Bodla,Muktsar,Punjab', 'lon': '74.3724'},
      '782485': {'lat': '26.0434', 'name': 'Borthal,Karbi Anglon,Assam', 'lon': '92.2762'},
      '700050': {'lat': '23.3945', 'name': 'Sinthee,Kolkata,West Bengal', 'lon': '88.2559'},
      '782486': {'lat': '25.9335', 'name': 'Khanduli,Karbi Anglon,Assam', 'lon': '92.6166'},
      '782481': {'lat': '26.0917', 'name': 'Eradighalpani,Karbi Anglon,Assam', 'lon': '93.1396'},
      '782480': {'lat': '26.0213', 'name': 'Khatkhati,Karbi Anglon,Assam', 'lon': '93.7795'},
      '393030': {'lat': '22.3913', 'name': 'Shera,Bharuch,Gujarat', 'lon': '73.3605'},
      '782482': {'lat': '26.0267', 'name': 'Pub Silputa,Karbi Anglon,Assam', 'lon': '92.8176'},
      '577418': {'lat': '13.7691', 'name': 'Kotethariga,Shivamogga,Karnataka', 'lon': '75.1456'},
      '577419': {'lat': '14.5542', 'name': 'Talagadde,Shivamogga,Karnataka', 'lon': '75.0782'},
      '452006': {'lat': '22.7334', 'name': 'Army Head Quarter,Indore,Madhya Pradesh', 'lon': '75.8422'},
      '627756': {'lat': '9.4451', 'name': 'Sankarankovil H.O,Tirunelveli,Tamil Nadu', 'lon': '77.6777'},
      '577411': {'lat': '13.6391', 'name': 'Kawrihaklu,Shivamogga,Karnataka', 'lon': '75.1149'},
      '577412': {'lat': '14.1772', 'name': 'Haridravathi,Shivamogga,Karnataka', 'lon': '75.0088'},
      '577413': {'lat': '14.4218', 'name': 'Moody,Shivamogga,Karnataka', 'lon': '75.1648'},
      '577414': {'lat': '13.3794', 'name': 'Aralasurali,Shivamogga,Karnataka', 'lon': '75.8798'},
      '577415': {'lat': '13.4564', 'name': 'Melige,Shivamogga,Karnataka', 'lon': '75.4609'},
      '577416': {'lat': '14.0463', 'name': 'Byranakoppa,Shivamogga,Karnataka', 'lon': '75.4598'},
      '577417': {'lat': '14.0984', 'name': 'Purappemane,Shivamogga,Karnataka', 'lon': '75.0706'},
      '625203': {'lat': '10.058', 'name': 'G.Kallupatti,Theni,Tamil Nadu', 'lon': '78.0743'},
      '625201': {'lat': '9.8731', 'name': 'Silaiman,Madurai,Tamil Nadu', 'lon': '78.1906'},
      '421102': {'lat': '19.2963', 'name': 'Manivali,Thane,Maharashtra', 'lon': '73.2276'},
      '421103': {'lat': '19.261', 'name': 'Varap,Thane,Maharashtra', 'lon': '73.2535'},
      '625205': {'lat': '9.4883', 'name': 'Karupatti,Madurai,Tamil Nadu', 'lon': '77.664'},
      '421101': {'lat': '19.3669', 'name': 'Padgha,Thane,Maharashtra', 'lon': '73.1758'},
      '700051': {'lat': '24.375', 'name': 'Birati,North 24 Parganas,West Bengal', 'lon': '87.8033'},
      '212620': {'lat': '25.7879', 'name': 'Andipur,Fatehpur,Uttar Pradesh', 'lon': '80.9021'},
      '802162': {'lat': '25.5434', 'name': 'Saneya Barhata,Bhojpur,Bihar', 'lon': '84.8026'},
      '226103': {'lat': '26.6952', 'name': 'Jindaur,Lucknow,Uttar Pradesh', 'lon': '80.6919'},
      '306602': {'lat': '25.5345', 'name': 'Beethora,Pali,Rajasthan', 'lon': '73.1318'},
      '462046': {'lat': '23.0817', 'name': 'Polaha,Raisen,Madhya Pradesh', 'lon': '77.5333'},
      '226102': {'lat': '26.6794', 'name': 'Kasmandi Khurd,Lucknow,Uttar Pradesh', 'lon': '81.2541'},
      '306601': {'lat': '25.3405', 'name': 'Varkana,Pali,Rajasthan', 'lon': '73.3664'},
      '276301': {'lat': '26.0759', 'name': 'Udiawan,Azamgarh,Uttar Pradesh', 'lon': '82.6299'},
      '721146': {'lat': '22.8008', 'name': 'Kalara,West Midnapore,West Bengal', 'lon': '88.6725'},
      '456010': {'lat': '23.4977', 'name': 'Ujjain  Kothi,Ujjain,Madhya Pradesh', 'lon': '75.6802'},
      '144819': {'lat': '31.4389', 'name': 'Ibrahimwal,Kapurthala,Punjab', 'lon': '75.4181'},
      '431750': {'lat': '19.2998', 'name': 'Malegaon,Nanded,Maharashtra', 'lon': '77.2699'},
      '462045': {'lat': '23.4682', 'name': 'Bangrasia,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '700052': {'lat': '23.3945', 'name': 'Kolkata Airport,Kolkata,West Bengal', 'lon': '88.2559'},
      '502280': {'lat': '17.9836', 'name': 'Khata,Medak,Telangana', 'lon': '77.9138'},
      '462042': {'lat': '23.4682', 'name': 'Bairagarh Chichali,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '221603': {'lat': '26.3614', 'name': 'Undaura,Mau,Uttar Pradesh', 'lon': '81.8588'},
      '221602': {'lat': '26.1815', 'name': 'Maryad Pur.,Mau,Uttar Pradesh', 'lon': '83.6446'},
      '221601': {'lat': '26.111', 'name': 'Dhrampur Bisnupur,Mau,Uttar Pradesh', 'lon': '83.8276'},
      '700111': {'lat': '22.6909', 'name': 'Ghola Bazar,North 24 Parganas,West Bengal', 'lon': '88.4077'},
      '575013': {'lat': '12.9167', 'name': 'Sandspit,Dakshina Kannada,Karnataka', 'lon': '74.8167'},
      '233304': {'lat': '25.5456', 'name': 'Dhuwarjun,Ghazipur,Uttar Pradesh', 'lon': '83.225'},
      '332025': {'lat': '27.4274', 'name': 'Chirasara,Sikar,Rajasthan', 'lon': '74.9322'},
      '799131': {'lat': '23.4755', 'name': 'Durgapur,Sepahijala,Tripura', 'lon': '91.2659'},
      '721140': {'lat': '22.5305', 'name': 'Barageria,West Midnapore,West Bengal', 'lon': '88.109'},
      '712202': {'lat': '23.2396', 'name': 'Mahesh -1,Hooghly,West Bengal', 'lon': '88.1534'},
      '700113': {'lat': '22.7379', 'name': 'Panchanantala,North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '712201': {'lat': '23.2396', 'name': 'Serampore H.O,Hooghly,West Bengal', 'lon': '88.1534'},
      '803120': {'lat': '25.3129', 'name': 'Choti Chariari,Nalanda,Bihar', 'lon': '85.1099'},
      '803121': {'lat': '25.3129', 'name': 'Ordnance Factory Rajgir,Nalanda,Bihar', 'lon': '85.1099'},
      '249121': {'lat': '29.9957', 'name': 'Garakot,Tehri Garhwal,Uttarakhand', 'lon': '78.6343'},
      '700112': {'lat': '22.7379', 'name': 'Pansila,North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '700053': {'lat': '22.5246', 'name': 'Kalabagan,Kolkata,West Bengal', 'lon': '88.3317'},
      '455332': {'lat': '22.7451', 'name': 'Vikrampur,Dewas,Madhya Pradesh', 'lon': '76.4564'},
      '382120': {'lat': '23.2864', 'name': 'Dabhsar,Ahmedabad,Gujarat', 'lon': '72.595'},
      '700114': {'lat': '22.6909', 'name': 'Bangodaya,North 24 Parganas,West Bengal', 'lon': '88.374'},
      '785621': {'lat': '27.0371', 'name': 'Golaghat H.O,Golaghat,Assam', 'lon': '93.9636'},
      '785622': {'lat': '27.0371', 'name': 'Kathalguri,Golaghat,Assam', 'lon': '93.9636'},
      '794005': {'lat': '25.7466', 'name': 'Shankinigiri,West Garo Hills,Meghalaya', 'lon': '90.3804'},
      '785625': {'lat': '27.0371', 'name': 'Athabari,Golaghat,Assam', 'lon': '93.9636'},
      '785626': {'lat': '27.0371', 'name': 'Maheema,Golaghat,Assam', 'lon': '93.9636'},
      '632603': {'lat': '12.7948', 'name': 'Puttavaripalli,Vellore,Tamil Nadu', 'lon': '79.3166'},
      '632602': {'lat': '12.8417', 'name': 'Gudiyattam South,Vellore,Tamil Nadu', 'lon': '78.8582'},
      '632601': {'lat': '12.8526', 'name': 'Thattimanapalli,Vellore,Tamil Nadu', 'lon': '78.7354'},
      '700116': {'lat': '22.7379', 'name': 'B.D.Sopan,North 24 Parganas,West Bengal', 'lon': '88.3427'},
      '635651': {'lat': '12.6063', 'name': 'Puthagaram,Vellore,Tamil Nadu', 'lon': '78.5813'},
      '492112': {'lat': '21.2097', 'name': 'Paraswani,Mahasamund,Chattisgarh', 'lon': '82.6513'},
      '635653': {'lat': '12.4896', 'name': 'Agaram(TPT),Vellore,Tamil Nadu', 'lon': '78.6041'},
      '794003': {'lat': '25.7466', 'name': 'Rom Bazar,West Garo Hills,Meghalaya', 'lon': '90.3804'},
      '515832': {'lat': '14.8881', 'name': 'Gadehothur,Ananthapur,Andhra Pradesh', 'lon': '77.6276'},
      '515787': {'lat': '14.2923', 'name': 'Thimmasamudram,Ananthapur,Andhra Pradesh', 'lon': '78.0161'},
      '796014': {'lat': '23.784', 'name': 'Bawngkawn,Aizawl,Mizoram', 'lon': '92.7784'},
      '488050': {'lat': '24.3059', 'name': 'Kakarahti,Panna,Madhya Pradesh', 'lon': '80.4007'},
      '713404': {'lat': '23.3857', 'name': 'Sonargoria,Purba Bardhaman,West Bengal', 'lon': '88.0371'},
      '794001': {'lat': '25.7466', 'name': 'Tura H.O,West Garo Hills,Meghalaya', 'lon': '90.3804'},
      '575011': {'lat': '13.0232', 'name': 'Baikampady,Dakshina Kannada,Karnataka', 'lon': '75.0845'},
      '461223': {'lat': '22.5019', 'name': 'Babadiabhau,Hoshangabad,Madhya Pradesh', 'lon': '77.2822'},
      '802183': {'lat': '25.5525', 'name': 'Gaudarh Rudranagar,Bhojpur,Bihar', 'lon': '84.3942'},
      '461221': {'lat': '22.2523', 'name': 'Lodhdi,Hoshangabad,Madhya Pradesh', 'lon': '77.4586'},
      '193504': {'lat': '34.2517', 'name': 'Safapora,Baramulla,Jammu & Kashmir', 'lon': '74.6259'},
      '193505': {'lat': '34.4383', 'name': 'Ashtingoo,Bandipur,Jammu & Kashmir', 'lon': '74.5575'},
      '313004': {'lat': '24.7078', 'name': 'Udaipur H.O,Udaipur,Rajasthan', 'lon': '73.6695'},
      '193501': {'lat': '34.2665', 'name': 'Panzinara,Baramulla,Jammu & Kashmir', 'lon': '74.5995'},
      '193502': {'lat': '34.2952', 'name': 'Mantrigam,Baramulla,Jammu & Kashmir', 'lon': '74.5267'},
      '193503': {'lat': '34.5906', 'name': 'Achoora,Bandipur,Jammu & Kashmir', 'lon': '74.9701'},
      '401302': {'lat': '19.45', 'name': 'Arnala,Thane,Maharashtra', 'lon': '72.75'},
      '401303': {'lat': '19.467', 'name': 'Parol,Thane,Maharashtra', 'lon': '72.8546'},
      '413534': {'lat': '20.1863', 'name': 'Ghatpimpri,Osmanabad,Maharashtra', 'lon': '74.9362'},
      '401301': {'lat': '19.1455', 'name': 'Kophrad,Thane,Maharashtra', 'lon': '73.474'},
      '413532': {'lat': '18.1891', 'name': 'Kodli,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '500018': {'lat': '17.4523', 'name': 'Moosapet,K.V.Rangareddy,Telangana', 'lon': '78.4324'},
      '401304': {'lat': '19.4004', 'name': 'Nirmal (Thane),Thane,Maharashtra', 'lon': '72.7651'},
      '401305': {'lat': '19.3291', 'name': 'Virar East,Thane,Maharashtra', 'lon': '73.109'},
      '689514': {'lat': '9.3031', 'name': 'Karithotta,Pathanamthitta,Kerala', 'lon': '76.585'},
      '500019': {'lat': '17.4865', 'name': 'Lingampalli,K.V.Rangareddy,Telangana', 'lon': '78.317'},
      '689511': {'lat': '9.3031', 'name': 'Cheriyanadu,Alappuzha,Kerala', 'lon': '76.585'},
      '689510': {'lat': '9.3031', 'name': 'Bhudhanoor,Alappuzha,Kerala', 'lon': '76.585'},
      '689513': {'lat': '9.3031', 'name': 'Mammoodu,Pathanamthitta,Kerala', 'lon': '76.585'},
      '689512': {'lat': '9.3031', 'name': 'Kudassanad,Alappuzha,Kerala', 'lon': '76.585'},
      '638151': {'lat': '11.5431', 'name': 'Karvazhi,Karur,Tamil Nadu', 'lon': '78.0195'},
      '638153': {'lat': '11.3605', 'name': 'Ganapathipalayam,Erode,Tamil Nadu', 'lon': '77.9402'},
      '638152': {'lat': '11.1278', 'name': 'Thamaraipalayam,Erode,Tamil Nadu', 'lon': '77.8696'},
      '624402': {'lat': '10.2656', 'name': 'Moongilpatti,Dindigul,Tamil Nadu', 'lon': '78.3104'},
      '638154': {'lat': '11.2029', 'name': 'Malayampalayam,Erode,Tamil Nadu', 'lon': '77.8565'},
      '624401': {'lat': '10.6447', 'name': 'Nathambazar,Dindigul,Tamil Nadu', 'lon': '78.1496'},
      '713124': {'lat': '23.7617', 'name': 'Natumohanpur,Purba Bardhaman,West Bengal', 'lon': '87.979'},
      '682039': {'lat': '10.0492', 'name': 'Rajagiri Valley,Ernakulam,Kerala', 'lon': '76.3705'},
      '396007': {'lat': '20.5969', 'name': 'Segvi,Valsad,Gujarat', 'lon': '72.9207'},
      '515164': {'lat': '14.1829', 'name': 'Munimadugu,Ananthapur,Andhra Pradesh', 'lon': '77.6584'},
      '396001': {'lat': '20.5967', 'name': 'Saron,Valsad,Gujarat', 'lon': '72.8987'},
      '396002': {'lat': '20.5971', 'name': 'Valsad Abrama,Valsad,Gujarat', 'lon': '72.9427'},
      '508116': {'lat': '17.4121', 'name': 'Bhongir H.O,Nalgonda,Telangana', 'lon': '79.1617'},
      '682033': {'lat': '10.0492', 'name': 'Changampuzha Nagar,Ernakulam,Kerala', 'lon': '76.3705'},
      '682032': {'lat': '10.0492', 'name': 'Thammanam,Ernakulam,Kerala', 'lon': '76.3705'},
      '682031': {'lat': '10.0492', 'name': 'Ernakulam High Court,Ernakulam,Kerala', 'lon': '76.3705'},
      '682030': {'lat': '10.0164', 'name': 'Kusumagiri,Ernakulam,Kerala', 'lon': '76.3417'},
      '682037': {'lat': '10.0492', 'name': 'Cochin Special Economic Zone,Ernakulam,Kerala', 'lon': '76.3705'},
      '682036': {'lat': '9.9584', 'name': 'Panampilly Nagar,Ernakulam,Kerala', 'lon': '76.2939'},
      '682035': {'lat': '10.0492', 'name': 'Ernakulam College,Ernakulam,Kerala', 'lon': '76.3705'},
      '682034': {'lat': '10.1728', 'name': 'Cheranallur,Ernakulam,Kerala', 'lon': '76.4759'},
      '576105': {'lat': '13.3835', 'name': 'Tenkabettu,Udupi,Karnataka', 'lon': '74.7434'},
      '576104': {'lat': '13.3928', 'name': 'Manipal H.O,Udupi,Karnataka', 'lon': '74.805'},
      '576107': {'lat': '13.3335', 'name': 'Badagabettu,Udupi,Karnataka', 'lon': '74.7999'},
      '576106': {'lat': '13.3691', 'name': 'Tenkanidiyur,Udupi,Karnataka', 'lon': '74.7097'},
      '576101': {'lat': '13.3345', 'name': 'Bannanje,Udupi,Karnataka', 'lon': '74.7643'},
      '563102': {'lat': '13.2216', 'name': 'K N Santorium,Kolar,Karnataka', 'lon': '78.0091'},
      '576103': {'lat': '13.5254', 'name': 'Ambalapadi,Udupi,Karnataka', 'lon': '74.7839'},
      '576102': {'lat': '13.6106', 'name': 'Doddanagudde,Udupi,Karnataka', 'lon': '75.1085'},
      '641605': {'lat': '11.0153', 'name': 'Veerapandi (Coimbatore),Coimbatore,Tamil Nadu', 'lon': '77.3582'},
      '274202': {'lat': '26.3048', 'name': 'Khaira Banua,Deoria,Uttar Pradesh', 'lon': '83.3896'},
      '700057': {'lat': '23.3945', 'name': 'Nowdapara,North 24 Parganas,West Bengal', 'lon': '88.2559'},
      '576108': {'lat': '13.3496', 'name': 'Thottam,Udupi,Karnataka', 'lon': '74.7039'},
      '641604': {'lat': '11.0261', 'name': 'Tirupur Collectorate,TIRUPPUR,Tamil Nadu', 'lon': '77.4991'},
      '482003': {'lat': '23.112', 'name': 'Tilwara Ghat,Jabalpur,Madhya Pradesh', 'lon': '79.8214'},
      '504109': {'lat': '19.1399', 'name': 'Thammapur,Adilabad,Telangana', 'lon': '78.0982'},
      '500011': {'lat': '17.4498', 'name': 'Bowenpally,Hyderabad,Telangana', 'lon': '78.5553'},
      '680616': {'lat': '10.5089', 'name': 'Kundaliyur,Thrissur,Kerala', 'lon': '76.1306'},
      '641607': {'lat': '11.1172', 'name': 'Tirupur East,Coimbatore,Tamil Nadu', 'lon': '77.3726'},
      '312403': {'lat': '24.9559', 'name': 'Karju,Chittorgarh,Rajasthan', 'lon': '74.6593'},
      '732215': {'lat': '24.7458', 'name': 'Pubarun,Malda,West Bengal', 'lon': '87.9659'},
      '504104': {'lat': '19.104', 'name': 'Panchagudi,Adilabad,Telangana', 'lon': '78.35'},
      '614810': {'lat': '10.3721', 'name': 'Agasthiampalli,Nagapattinam,Tamil Nadu', 'lon': '79.851'},
      '476228': {'lat': '26.158', 'name': 'Mamchon,Morena,Madhya Pradesh', 'lon': '77.7018'},
      '500015': {'lat': '17.4498', 'name': 'Trimulgherry H.O,Hyderabad,Telangana', 'lon': '78.5553'},
      '212405': {'lat': '25.5611', 'name': 'Ugrasen Pur,Allahabad,Uttar Pradesh', 'lon': '82.1872'},
      '735220': {'lat': '26.6952', 'name': 'Chotosalkumar,Alipurduar,West Bengal', 'lon': '89.2815'},
      '209125': {'lat': '26.4912', 'name': 'Gurdahi Bujurg,Kanpur Dehat,Uttar Pradesh', 'lon': '79.6959'},
      '212404': {'lat': '25.6107', 'name': 'Babuganj,Allahabad,Uttar Pradesh', 'lon': '81.9876'},
      '846001': {'lat': '25.9469', 'name': 'Imambari,Darbhanga,Bihar', 'lon': '85.9862'},
      '713128': {'lat': '23.7001', 'name': 'Shibda,Purba Bardhaman,West Bengal', 'lon': '87.801'},
      '743405': {'lat': '22.6259', 'name': 'Khalitpur,North 24 Parganas,West Bengal', 'lon': '88.6827'},
      '362510': {'lat': '20.7754', 'name': 'Nava Bandar,Junagadh,Gujarat', 'lon': '71.0465'},
      '504101': {'lat': '18.9627', 'name': 'Bidrelli,Adilabad,Telangana', 'lon': '78'},
      '333302': {'lat': '26.9265', 'name': 'Chhapoli,Jhujhunu,Rajasthan', 'lon': '75.0661'},
      '333303': {'lat': '26.9265', 'name': 'Tonk Chhhilri,Jhujhunu,Rajasthan', 'lon': '75.0661'},
      '846003': {'lat': '25.702', 'name': 'D.M.C.,Darbhanga,Bihar', 'lon': '86.5762'},
      '333307': {'lat': '26.5143', 'name': 'Dholekhera,Jhujhunu,Rajasthan', 'lon': '74.7894'},
      '767068': {'lat': '21.00002', 'name': 'Matiapali,Sonapur,Odisha', 'lon': '83.68905'},
      '333305': {'lat': '26.6288', 'name': 'Chhau,Jhujhunu,Rajasthan', 'lon': '75.1154'},
      '767066': {'lat': '19.9971', 'name': 'Adabahal,Balangir,Odisha', 'lon': '83.0571'},
      '767067': {'lat': '21.4432', 'name': 'Gourgoth,Balangir,Odisha', 'lon': '84.4255'},
      '333308': {'lat': '26.9265', 'name': 'Dhamora,Jhujhunu,Rajasthan', 'lon': '75.0661'},
      '767065': {'lat': '20.8094', 'name': 'Mirdhapali,Balangir,Odisha', 'lon': '84.0039'},
      '767062': {'lat': '20.93621', 'name': 'S.Patrapali,Sonapur,Odisha', 'lon': '84.17605'},
      '244926': {'lat': '28.7955', 'name': 'Modipur,Rampur,Uttar Pradesh', 'lon': '79.1055'},
      '767060': {'lat': '20.5022', 'name': 'Kuibahal,Balangir,Odisha', 'lon': '82.7978'},
      '767061': {'lat': '21.0386', 'name': 'Roth,Balangir,Odisha', 'lon': '84.1281'},
      '508233': {'lat': '17.2094', 'name': 'Akupamula,Nalgonda,Telangana', 'lon': '79.9402'},
      '450332': {'lat': '21.8887', 'name': 'Sheikhpura,Burhanpur,Madhya Pradesh', 'lon': '76.7721'},
      '321301': {'lat': '27.1078', 'name': 'Bhainsa,Bharatpur,Rajasthan', 'lon': '77.5097'},
      '391780': {'lat': '22.5162', 'name': 'Prathampura,Vadodara,Gujarat', 'lon': '73.1266'},
      '313322': {'lat': '24.7685', 'name': 'Unwas,Rajsamand,Rajasthan', 'lon': '73.7473'},
      '796441': {'lat': '23.8692', 'name': 'Dampui,Mammit,Mizoram', 'lon': '92.4054'},
      '249128': {'lat': '30.341', 'name': 'Mori (Tehri Garhwal),Tehri Garhwal,Uttarakhand', 'lon': '78.6107'},
      '321303': {'lat': '27.3506', 'name': 'Mudota,Bharatpur,Rajasthan', 'lon': '77.3145'},
      '302019': {'lat': '26.7865', 'name': 'Shyam Nagar (Jaipur),Jaipur,Rajasthan', 'lon': '75.5809'},
      '605010': {'lat': '11.8768', 'name': 'Ozhugarai,Pondicherry,Pondicherry', 'lon': '79.8107'},
      '134201': {'lat': '30.4525', 'name': 'Khanpur Brahmana,Ambala,Haryana', 'lon': '76.9494'},
      '134202': {'lat': '30.4468', 'name': 'Korwa Khurd,Ambala,Haryana', 'lon': '77.0479'},
      '134203': {'lat': '30.2929', 'name': 'Akbarpur,Ambala,Haryana', 'lon': '76.9205'},
      '134204': {'lat': '30.3285', 'name': 'Hangola,Ambala,Haryana', 'lon': '76.9422'},
      '134205': {'lat': '29.8001', 'name': 'Bhojkoti,Panchkula,Haryana', 'lon': '76.6012'},
      '204102': {'lat': '27.8859', 'name': 'Hathras Jn RS,Hathras,Uttar Pradesh', 'lon': '78.1371'},
      '736131': {'lat': '26.3771', 'name': 'Balakuthi,Cooch Behar,West Bengal', 'lon': '89.7699'},
      '768005': {'lat': '21.4256', 'name': 'Dhanupali,Sambalpur,Odisha', 'lon': '84.0292'},
      '768004': {'lat': '21.4925', 'name': 'Budharaja,Sambalpur,Odisha', 'lon': '83.9853'},
      '493996': {'lat': '20.7487', 'name': 'Kothigaon,Raipur,Chattisgarh', 'lon': '82.3213'},
      '768006': {'lat': '21.4495', 'name': 'Bareipali,Sambalpur,Odisha', 'lon': '83.9813'},
      '768001': {'lat': '21.446', 'name': 'Jharuapara,Sambalpur,Odisha', 'lon': '84.0012'},
      '721260': {'lat': '22.8464', 'name': 'Sirsa,West Midnapore,West Bengal', 'lon': '88.1806'},
      '768003': {'lat': '21.4875', 'name': 'Khetrajpur,Sambalpur,Odisha', 'lon': '83.957'},
      '768002': {'lat': '21.446', 'name': 'Mudipara,Sambalpur,Odisha', 'lon': '84.0012'},
      '742140': {'lat': '23.9287', 'name': 'Kolla,Murshidabad,West Bengal', 'lon': '87.617'},
      '391250': {'lat': '22.3552', 'name': 'Manjrol,Vadodara,Gujarat', 'lon': '73.0205'},
      '391520': {'lat': '23.1379', 'name': 'Karchia,Vadodara,Gujarat', 'lon': '73.2675'},
      '307032': {'lat': '24.791', 'name': 'Nichalagarh,Sirohi,Rajasthan', 'lon': '72.7865'},
      '742148': {'lat': '24.3584', 'name': 'Sekhalipur,Murshidabad,West Bengal', 'lon': '88.0833'},
      '685561': {'lat': '8.8694', 'name': 'Korangatti,Idukki,Kerala', 'lon': '76.9878'},
      '685562': {'lat': '8.8694', 'name': 'Mukkudam,Idukki,Kerala', 'lon': '76.9878'},
      '685563': {'lat': '8.8694', 'name': 'Ponmudi,Idukki,Kerala', 'lon': '76.9878'},
      '685565': {'lat': '8.8694', 'name': 'Thokkupara,Idukki,Kerala', 'lon': '76.9878'},
      '685566': {'lat': '8.8694', 'name': 'C. Kuthumkal,Idukki,Kerala', 'lon': '76.9878'},
      '626127': {'lat': '9.3859', 'name': 'Lakshmipuram,Virudhunagar,Tamil Nadu', 'lon': '78.049'},
      '626126': {'lat': '9.5073', 'name': 'Sundarapandiam,Virudhunagar,Tamil Nadu', 'lon': '77.9056'},
      '587156': {'lat': '16.2205', 'name': 'Shirur,Bagalkot,Karnataka', 'lon': '75.6254'},
      '626124': {'lat': '9.5734', 'name': 'Boovanathapuram,Virudhunagar,Tamil Nadu', 'lon': '77.8326'},
      '626123': {'lat': '9.5073', 'name': 'Sivakasi R S,Virudhunagar,Tamil Nadu', 'lon': '77.9056'},
      '626122': {'lat': '9.3935', 'name': 'Settiyarpatti,Virudhunagar,Tamil Nadu', 'lon': '77.4935'},
      '626121': {'lat': '9.3958', 'name': 'Chockanathanpudur,Virudhunagar,Tamil Nadu', 'lon': '77.4703'},
      '723130': {'lat': '23.2835', 'name': 'Mehi,Purulia,West Bengal', 'lon': '86.2496'},
      '723131': {'lat': '23.2912', 'name': 'Dangardih,Purulia,West Bengal', 'lon': '87.2151'},
      '723132': {'lat': '24.7322', 'name': 'Bansraya,Purulia,West Bengal', 'lon': '87.8261'},
      '723133': {'lat': '23.4704', 'name': 'Rakshatpur,Purulia,West Bengal', 'lon': '86.4988'},
      '626129': {'lat': '9.4181', 'name': 'P Thottiankulam,Virudhunagar,Tamil Nadu', 'lon': '78.0912'},
      '626128': {'lat': '9.5073', 'name': 'Thayilpatti,Virudhunagar,Tamil Nadu', 'lon': '77.9056'},
      '758086': {'lat': '21.3418', 'name': 'Chormalda,Kendujhar,Odisha', 'lon': '85.3861'},
      '758087': {'lat': '20.8351', 'name': 'Patilo,Kendujhar,Odisha', 'lon': '85.2241'},
      '758084': {'lat': '21.43', 'name': 'Jagamohanpur,Kendujhar,Odisha', 'lon': '84.9122'},
      '758085': {'lat': '21.6908', 'name': 'Kushakala,Kendujhar,Odisha', 'lon': '85.284'},
      '758082': {'lat': '21.3172', 'name': 'Tangiriapal,Kendujhar,Odisha', 'lon': '85.3586'},
      '758083': {'lat': '21.3172', 'name': 'Hatadihi,Kendujhar,Odisha', 'lon': '85.3586'},
      '534318': {'lat': '17.3543', 'name': 'Karagapadu,West Godavari,Andhra Pradesh', 'lon': '81.7422'},
      '758081': {'lat': '21.4613', 'name': 'Gholkunda,Kendujhar,Odisha', 'lon': '86.481'},
      '534316': {'lat': '16.9091', 'name': 'V.Ch.Gudem,West Godavari,Andhra Pradesh', 'lon': '81.0867'},
      '534315': {'lat': '17.3531', 'name': 'Chegondapalli,West Godavari,Andhra Pradesh', 'lon': '81.6559'},
      '534312': {'lat': '17.1876', 'name': 'Dippakayalapadu,West Godavari,Andhra Pradesh', 'lon': '81.5507'},
      '534313': {'lat': '16.7753', 'name': 'Devarapalli (West Godavari),West Godavari,Andhra Pradesh', 'lon': '81.174'},
      '534311': {'lat': '17.1971', 'name': 'Kamaiahkunta,West Godavari,Andhra Pradesh', 'lon': '81.4207'},
      '277121': {'lat': '25.8336', 'name': 'Budhuan,Ballia,Uttar Pradesh', 'lon': '84.0643'},
      '212658': {'lat': '25.5962', 'name': 'Garha,Fatehpur,Uttar Pradesh', 'lon': '81.0382'},
      '636812': {'lat': '12.0782', 'name': 'Nammanadahalli,Dharmapuri,Tamil Nadu', 'lon': '77.9422'},
      '636813': {'lat': '12.1295', 'name': 'Rajagollahalli,Dharmapuri,Tamil Nadu', 'lon': '77.9731'},
      '636810': {'lat': '12.0827', 'name': 'Ramagondahalli,Dharmapuri,Tamil Nadu', 'lon': '77.9198'},
      '636811': {'lat': '12.0009', 'name': 'Gendenahalli,Dharmapuri,Tamil Nadu', 'lon': '77.9457'},
      '246723': {'lat': '29.6205', 'name': 'Balawali,Bijnor,Uttar Pradesh', 'lon': '78.1532'},
      '603302': {'lat': '12.4195', 'name': 'Zamin Budur,Kanchipuram,Tamil Nadu', 'lon': '79.9039'},
      '603303': {'lat': '12.4149', 'name': 'Kinar Kandigai Pudur,Kanchipuram,Tamil Nadu', 'lon': '79.8122'},
      '364210': {'lat': '22.0834', 'name': 'Ambla,Bhavnagar,Gujarat', 'lon': '72.0375'},
      '284501': {'lat': '24.5815', 'name': 'Kailwara,Lalitpur,Uttar Pradesh', 'lon': '78.4948'},
      '614101': {'lat': '10.7026', 'name': 'Koothanallur East,Tiruvarur,Tamil Nadu', 'lon': '79.5172'},
      '614102': {'lat': '10.6923', 'name': 'Vadakovanur,Tiruvarur,Tamil Nadu', 'lon': '79.5054'},
      '614103': {'lat': '10.73', 'name': 'Segarai,Tiruvarur,Tamil Nadu', 'lon': '79.5111'},
      '246161': {'lat': '29.6518', 'name': 'Kalinkakhal (Kaleth),Pauri Garhwal,Uttarakhand', 'lon': '78.9866'},
      '734219': {'lat': '26.9575', 'name': 'Balasun,Darjiling,West Bengal', 'lon': '88.27'},
      '246163': {'lat': '29.9953', 'name': 'Soola,Pauri Garhwal,Uttarakhand', 'lon': '78.7245'},
      '246162': {'lat': '29.9607', 'name': 'Irakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.8488'},
      '246165': {'lat': '30.0659', 'name': 'Molthi,Pauri Garhwal,Uttarakhand', 'lon': '78.7829'},
      '246164': {'lat': '30.0588', 'name': 'Kalun,Pauri Garhwal,Uttarakhand', 'lon': '78.8698'},
      '246167': {'lat': '29.9742', 'name': 'Matiyalna,Pauri Garhwal,Uttarakhand', 'lon': '78.9474'},
      '246166': {'lat': '30.0266', 'name': 'Parsundakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.7589'},
      '133006': {'lat': '30.2901', 'name': 'Ind-estate,Ambala,Haryana', 'lon': '76.8908'},
      '133005': {'lat': '30.2901', 'name': 'Sarsehri,Ambala,Haryana', 'lon': '76.8908'},
      '133004': {'lat': '30.2306', 'name': 'Durana,Ambala,Haryana', 'lon': '76.7975'},
      '133001': {'lat': '30.2901', 'name': 'A.K. Line,Ambala,Haryana', 'lon': '76.8908'},
      '494237': {'lat': '19.8602', 'name': 'Otenda,Bastar,Chattisgarh', 'lon': '81.56'},
      '700151': {'lat': '22.7807', 'name': 'Dingelpota,South 24 Parganas,West Bengal', 'lon': '88.3001'},
      '603305': {'lat': '11.5198', 'name': 'Kodoovur,Kanchipuram,Tamil Nadu', 'lon': '79.3496'},
      '587155': {'lat': '15.5561', 'name': 'Alur S.K.,Bagalkot,Karnataka', 'lon': '75.4921'},
      '133008': {'lat': '30.3935', 'name': 'Majri,Ambala,Haryana', 'lon': '77.0121'},
      '583220': {'lat': '15.0832', 'name': 'Morigere,Ballari,Karnataka', 'lon': '76.2516'},
      '583221': {'lat': '15.3044', 'name': 'Kamalapura,Ballari,Karnataka', 'lon': '76.4765'},
      '583222': {'lat': '14.8777', 'name': 'G Nagalapura,Ballari,Karnataka', 'lon': '76.387'},
      '583223': {'lat': '14.7744', 'name': 'Gadiganur,Ballari,Karnataka', 'lon': '76.1701'},
      '364070': {'lat': '21.6031', 'name': 'Khadsaliya,Bhavnagar,Gujarat', 'lon': '72.2689'},
      '583225': {'lat': '15.18', 'name': 'T B Dam,Ballari,Karnataka', 'lon': '76.3094'},
      '583226': {'lat': '15.3282', 'name': 'Halwagali,Gadag,Karnataka', 'lon': '76.0872'},
      '583227': {'lat': '15.7254', 'name': 'Gangavathi ARS,Gadag,Karnataka', 'lon': '76.6013'},
      '583228': {'lat': '15.0572', 'name': 'Indargi,Koppal,Karnataka', 'lon': '76.128'},
      '443308': {'lat': '20.3054', 'name': 'Sonoshi,Buldhana,Maharashtra', 'lon': '75.9612'},
      '752015': {'lat': '20.3719', 'name': 'Muninda,Puri,Odisha', 'lon': '85.6171'},
      '752014': {'lat': '20.0605', 'name': 'Nua Someswarpur,Puri,Odisha', 'lon': '85.6594'},
      '752013': {'lat': '20.1167', 'name': 'Kerandipur,Puri,Odisha', 'lon': '85.4667'},
      '682013': {'lat': '9.967', 'name': 'Thevara,Ernakulam,Kerala', 'lon': '76.3159'},
      '752011': {'lat': '20.3216', 'name': 'Rebena Nuagaon,Puri,Odisha', 'lon': '85.1845'},
      '752010': {'lat': '19.8374', 'name': 'Bhubanapur,Puri,Odisha', 'lon': '85.1483'},
      '743222': {'lat': '22.8464', 'name': 'Iswarigacha,North 24 Parganas,West Bengal', 'lon': '88.5736'},
      '207401': {'lat': '27.7127', 'name': 'Kutukpur Kaseri,Etah,Uttar Pradesh', 'lon': '78.6246'},
      '700150': {'lat': '22.3528', 'name': 'Ramkrishna Pally,South 24 Parganas,West Bengal', 'lon': '88.2987'},
      '221701': {'lat': '25.8551', 'name': 'Hajauli,Ballia,Uttar Pradesh', 'lon': '83.9506'},
      '591143': {'lat': '16.1195', 'name': 'Handignur,Belagavi,Karnataka', 'lon': '74.823'},
      '734436': {'lat': '26.5864', 'name': 'Chathat,Darjiling,West Bengal', 'lon': '88.368'},
      '734431': {'lat': '26.5864', 'name': 'Sahabad,Darjiling,West Bengal', 'lon': '88.368'},
      '591147': {'lat': '16.1195', 'name': 'Mattikoppa,Belagavi,Karnataka', 'lon': '74.823'},
      '764001': {'lat': '18.9322', 'name': 'B.D.Pur,Koraput,Odisha', 'lon': '82.4017'},
      '517520': {'lat': '13.7454', 'name': 'Mamanduru,Chittoor,Andhra Pradesh', 'lon': '79.4623'},
      '764002': {'lat': '18.8809', 'name': 'Jeypore(K) R.S.,Koraput,Odisha', 'lon': '82.3977'},
      '517526': {'lat': '13.623', 'name': 'Ravillavarikandriga,Chittoor,Andhra Pradesh', 'lon': '79.587'},
      '394116': {'lat': '22.2263', 'name': 'Panoli I.E.,Bharuch,Gujarat', 'lon': '73.2941'},
      '764006': {'lat': '18.9322', 'name': 'Bariniput,Koraput,Odisha', 'lon': '82.4017'},
      '410506': {'lat': '18.735', 'name': 'Adhale BK,Pune,Maharashtra', 'lon': '73.6756'},
      '410507': {'lat': '18.821', 'name': 'Ambale,Pune,Maharashtra', 'lon': '76.3844'},
      '410504': {'lat': '19.5759', 'name': 'Nimgaon Sava,Pune,Maharashtra', 'lon': '74.7473'},
      '410505': {'lat': '18.6886', 'name': 'Gulani,Pune,Maharashtra', 'lon': '73.6256'},
      '410502': {'lat': '19.3864', 'name': 'Godre,Pune,Maharashtra', 'lon': '74.4703'},
      '410503': {'lat': '19.0044', 'name': 'Chandoli BK,Pune,Maharashtra', 'lon': '73.9435'},
      '415020': {'lat': '20.1806', 'name': 'Morghar,Satara,Maharashtra', 'lon': '75.6891'},
      '410501': {'lat': '17.8562', 'name': 'Shive,Pune,Maharashtra', 'lon': '74.2913'},
      '263656': {'lat': '29.8363', 'name': 'Jairambakhal,Almora,Uttarakhand', 'lon': '79.4255'},
      '505453': {'lat': '18.642', 'name': 'Thatpalli,Karim Nagar,Telangana', 'lon': '78.7553'},
      '495330': {'lat': '22.0747', 'name': 'Pharhada,Bilaspur(CGH),Chattisgarh', 'lon': '81.8217'},
      '263655': {'lat': '29.8144', 'name': 'Golchhina,Almora,Uttarakhand', 'lon': '79.4135'},
      '263652': {'lat': '29.6783', 'name': 'Majkhali,Almora,Uttarakhand', 'lon': '79.5221'},
      '263653': {'lat': '29.8511', 'name': 'Irah,Almora,Uttarakhand', 'lon': '79.4252'},
      '410508': {'lat': '19.2272', 'name': 'Dhamani,Pune,Maharashtra', 'lon': '74.5643'},
      '410509': {'lat': '19.9443', 'name': 'Phulawade,Pune,Maharashtra', 'lon': '74.8314'},
      '382710': {'lat': '23.5194', 'name': 'Mulsan,Mahesana,Gujarat', 'lon': '72.4137'},
      '262309': {'lat': '29.2988', 'name': 'Tanakpur,Champawat,Uttarakhand', 'lon': '80.0697'},
      '262308': {'lat': '29.101', 'name': 'Beria Majhola,Udham Singh Nagar,Uttarakhand', 'lon': '79.7422'},
      '382715': {'lat': '23.6218', 'name': 'Sujatpura,Mahesana,Gujarat', 'lon': '72.126'},
      '416220': {'lat': '16.2786', 'name': 'Aralgundi,Kolhapur,Maharashtra', 'lon': '74.3117'},
      '416223': {'lat': '16.3624', 'name': 'Hedawade,Kolhapur,Maharashtra', 'lon': '74.314'},
      '502287': {'lat': '18.192', 'name': 'Rasool,Medak,Telangana', 'lon': '77.7615'},
      '413530': {'lat': '20.4769', 'name': 'Ambegaon Masalga,Latur,Maharashtra', 'lon': '75.4936'},
      '242001': {'lat': '27.8446', 'name': 'Shahjahanpur Chawk,Shahjahanpur,Uttar Pradesh', 'lon': '79.7773'},
      '416229': {'lat': '16.4462', 'name': 'Pohale Turf Alate,Kolhapur,Maharashtra', 'lon': '74.3163'},
      '487118': {'lat': '23.0801', 'name': 'Rakhi,Narsinghpur,Madhya Pradesh', 'lon': '79.4154'},
      '262305': {'lat': '29.0362', 'name': 'Neoria Mobile,Pilibhit,Uttar Pradesh', 'lon': '79.751'},
      '384410': {'lat': '22.876', 'name': 'Nadasa,Mahesana,Gujarat', 'lon': '72.3985'},
      '621314': {'lat': '10.368', 'name': 'Adhigaram,Tiruchirappalli,Tamil Nadu', 'lon': '78.3485'},
      '621316': {'lat': '10.6039', 'name': 'Theravur,Pudukkottai,Tamil Nadu', 'lon': '78.5685'},
      '621311': {'lat': '11.0563', 'name': 'Thavalaveeranpatti,Tiruchirappalli,Tamil Nadu', 'lon': '78.2567'},
      '621310': {'lat': '10.4457', 'name': 'Maniyankurichi,Tiruchirappalli,Tamil Nadu', 'lon': '78.3676'},
      '621313': {'lat': '10.4429', 'name': 'Alathur (Kulittalai),Karur,Tamil Nadu', 'lon': '78.7163'},
      '621312': {'lat': '10.6098', 'name': 'Rasalipatti,Pudukkottai,Tamil Nadu', 'lon': '78.4706'},
      '277208': {'lat': '25.8543', 'name': 'Sonbarsa,Ballia,Uttar Pradesh', 'lon': '84.0621'},
      '221406': {'lat': '25.4463565', 'name': 'Kariyaon,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.5036917'},
      '802219': {'lat': '25.0613', 'name': 'Bisheni Kala,Rohtas,Bihar', 'lon': '84.2443'},
      '574197': {'lat': '13.1924', 'name': 'Maroor,Dakshina Kannada,Karnataka', 'lon': '75.1178'},
      '630410': {'lat': '10.3055', 'name': 'Varapur,Sivaganga,Tamil Nadu', 'lon': '78.4928'},
      '630411': {'lat': '9.8159', 'name': 'Kumaravelur,Sivaganga,Tamil Nadu', 'lon': '78.727'},
      '502286': {'lat': '18.0806', 'name': 'Manur,Medak,Telangana', 'lon': '77.8956'},
      '802218': {'lat': '25.2963', 'name': 'Puhpi,Rohtas,Bihar', 'lon': '84.1178'},
      '673312': {'lat': '11.7134', 'name': 'Peringadi,Kannur,Kerala', 'lon': '75.5481'},
      '673313': {'lat': '11.71', 'name': 'Olavilam,Kannur,Kerala', 'lon': '75.5557'},
      '673310': {'lat': '11.7017', 'name': 'Pallur,Mahe,Pondicherry', 'lon': '75.5347'},
      '673311': {'lat': '11.5515', 'name': 'New Mahe,Kannur,Kerala', 'lon': '75.7255'},
      '673316': {'lat': '11.5515', 'name': 'Kariyad South,Kannur,Kerala', 'lon': '75.7255'},
      '673317': {'lat': '11.5515', 'name': 'Annasseri,Kozhikode,Kerala', 'lon': '75.7255'},
      '673314': {'lat': '11.1333', 'name': 'Kadalundinagaram,Malappuram,Kerala', 'lon': '75.8333'},
      '673315': {'lat': '11.534', 'name': 'Cheekilode,Kozhikode,Kerala', 'lon': '75.8689'},
      '263623': {'lat': '29.6219', 'name': 'Dhauladevi ED,Almora,Uttarakhand', 'lon': '79.8089'},
      '799035': {'lat': '23.6622', 'name': 'Dinabandhupara,West Tripura,Tripura', 'lon': '91.3881'},
      '233231': {'lat': '25.6061', 'name': 'Mirzabad Mania,Ghazipur,Uttar Pradesh', 'lon': '83.7121'},
      '233230': {'lat': '25.7835', 'name': 'Rajapur Katya,Ghazipur,Uttar Pradesh', 'lon': '83.6697'},
      '233233': {'lat': '25.6487', 'name': 'Khardiha,Ghazipur,Uttar Pradesh', 'lon': '83.8665'},
      '233232': {'lat': '25.4287', 'name': 'Saitapatti,Ghazipur,Uttar Pradesh', 'lon': '83.5029'},
      '174015': {'lat': '31.2260734', 'name': 'Behal,Bilaspur (HP),Himachal Pradesh', 'lon': '76.6123834'},
      '174017': {'lat': '31.27879', 'name': 'Malhot,Bilaspur (HP),Himachal Pradesh', 'lon': '76.656'},
      '388713': {'lat': '22.8328', 'name': 'Tuwa,Panch Mahals,Gujarat', 'lon': '73.485'},
      '574260': {'lat': '12.6976', 'name': 'Moodambail,Dakshina Kannada,Karnataka', 'lon': '75.0708'},
      '174013': {'lat': '31.41865', 'name': 'Barmana,Bilaspur (HP),Himachal Pradesh', 'lon': '76.83161'},
      '174012': {'lat': '31.3737', 'name': 'Dhoun Kothi,Bilaspur (HP),Himachal Pradesh', 'lon': '76.83161'},
      '232102': {'lat': '25.3963', 'name': 'Akorha,Varanasi,Uttar Pradesh', 'lon': '82.7086'},
      '263620': {'lat': '29.6495', 'name': 'Kausani Estate,Almora,Uttarakhand', 'lon': '79.6821'},
      '388520': {'lat': '22.6045', 'name': 'Navapura,Anand,Gujarat', 'lon': '72.992'},
      '382825': {'lat': '23.4813', 'name': 'Gozaria,Mahesana,Gujarat', 'lon': '72.5635'},
      '680591': {'lat': '10.7233', 'name': 'Killimangalam,Thrissur,Kerala', 'lon': '76.3188'},
      '680596': {'lat': '10.7233', 'name': 'Thrissur Medical College,Thrissur,Kerala', 'lon': '76.3188'},
      '680594': {'lat': '10.7233', 'name': 'Kuthampilly,Thrissur,Kerala', 'lon': '76.3188'},
      '382820': {'lat': '23.4813', 'name': 'Tatosan,Mahesana,Gujarat', 'lon': '72.5635'},
      '686532': {'lat': '9.5079', 'name': 'Sachivothamapuram,Kottayam,Kerala', 'lon': '76.5379'},
      '629204': {'lat': '8.1256', 'name': 'Ammandivilai,Kanyakumari,Tamil Nadu', 'lon': '77.3203'},
      '629203': {'lat': '8.1256', 'name': 'Friday Market,Kanyakumari,Tamil Nadu', 'lon': '77.3203'},
      '629202': {'lat': '8.1256', 'name': 'Muttom,Kanyakumari,Tamil Nadu', 'lon': '77.3203'},
      '629201': {'lat': '8.1256', 'name': 'Anandanadarkudy,Kanyakumari,Tamil Nadu', 'lon': '77.3203'},
      '686021': {'lat': '9.5713', 'name': 'Pariyaram (Kottayam),Kottayam,Kerala', 'lon': '76.5199'},
      '585107': {'lat': '17.2967', 'name': 'Kalaburagi HCB,Kalaburagi,Karnataka', 'lon': '76.6671'},
      '585106': {'lat': '17.2967', 'name': 'GB Jnanaganga,Kalaburagi,Karnataka', 'lon': '76.6671'},
      '585105': {'lat': '17.3184', 'name': 'GB Ggh,Kalaburagi,Karnataka', 'lon': '76.8471'},
      '574265': {'lat': '12.9267', 'name': 'Karinje,Dakshina Kannada,Karnataka', 'lon': '75.1099'},
      '585103': {'lat': '17.1861', 'name': 'GB Bahamanipura,Kalaburagi,Karnataka', 'lon': '76.6064'},
      '396020': {'lat': '21.3463', 'name': 'Magod,Valsad,Gujarat', 'lon': '72.6189'},
      '585101': {'lat': '17.2967', 'name': 'GB Shahabazar,Kalaburagi,Karnataka', 'lon': '76.6671'},
      '508246': {'lat': '16.9455', 'name': 'Gudimalkapuram,Nalgonda,Telangana', 'lon': '79.9092'},
      '224172': {'lat': '26.4201', 'name': 'Gangauli,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '224171': {'lat': '26.4201', 'name': 'Janapur,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.6685'},
      '224176': {'lat': '26.3028', 'name': 'Mubarakpurpikar,Ambedkar Nagar,Uttar Pradesh', 'lon': '83.0636'},
      '334202': {'lat': '27.9169', 'name': 'Belasar,Bikaner,Rajasthan', 'lon': '73.5689'},
      '759040': {'lat': '21.08632', 'name': 'Talmul,Angul,Odisha', 'lon': '85.13908'},
      '462027': {'lat': '24.1137', 'name': 'IBC Bhopal,Bhopal,Madhya Pradesh', 'lon': '77.8846'},
      '334201': {'lat': '27.9169', 'name': 'Kesardesarjatan,Bikaner,Rajasthan', 'lon': '73.5689'},
      '506332': {'lat': '17.8935', 'name': 'Gundenga,Warangal,Telangana', 'lon': '79.8322'},
      '802214': {'lat': '25.1168', 'name': 'Burhwal,Rohtas,Bihar', 'lon': '84.2981'},
      '721624': {'lat': '22.284', 'name': 'Etamogra,East Midnapore,West Bengal', 'lon': '88.054'},
      '802217': {'lat': '24.858', 'name': 'Meari Bazar,Rohtas,Bihar', 'lon': '84.3316'},
      '203398': {'lat': '28.5009', 'name': 'Mauwa  Khera,Bulandshahr,Uttar Pradesh', 'lon': '78.0962'},
      '570017': {'lat': '12.3134', 'name': 'Vijaynagar II Stage,Mysuru,Karnataka', 'lon': '76.7455'},
      '142057': {'lat': '30.5977', 'name': 'Jita Singh Wala,Moga,Punjab', 'lon': '75.1267'},
      '462026': {'lat': '24.1137', 'name': 'Bawadia Kalan,Bhopal,Madhya Pradesh', 'lon': '77.8846'},
      '462020': {'lat': '24.1137', 'name': 'AIIMS,Bhopal,Madhya Pradesh', 'lon': '77.8846'},
      '625603': {'lat': '9.712', 'name': 'Jeyamangalam,Theni,Tamil Nadu', 'lon': '77.5237'},
      '332318': {'lat': '27.7593', 'name': 'Narsas,Sikar,Rajasthan', 'lon': '74.7795'},
      '249001': {'lat': '30.1448', 'name': 'Kuttha,Tehri Garhwal,Uttarakhand', 'lon': '78.6125'},
      '606109': {'lat': '11.5509', 'name': 'Adari,Cuddalore,Tamil Nadu', 'lon': '79.0488'},
      '606108': {'lat': '11.8666', 'name': 'Ma Podaiyur,Cuddalore,Tamil Nadu', 'lon': '79.1374'},
      '759104': {'lat': '20.8855702', 'name': 'Ballahar Chhak,Angul,Odisha', 'lon': '85.2191755'},
      '759105': {'lat': '21.51403', 'name': 'Kuluma,Angul,Odisha', 'lon': '85.25838'},
      '759106': {'lat': '20.8764151', 'name': 'Vikrampur,Angul,Odisha', 'lon': '85.1582623'},
      '759107': {'lat': '20.950012', 'name': 'Talcher Town,Angul,Odisha', 'lon': '85.216688'},
      '389390': {'lat': '23.185', 'name': 'Uchet,Panch Mahals,Gujarat', 'lon': '73.485'},
      '759101': {'lat': '20.950015', 'name': 'Talcher Thermal,Angul,Odisha', 'lon': '85.216685'},
      '383440': {'lat': '23.9197', 'name': 'Bhutiya,Sabarkantha,Gujarat', 'lon': '73.1485'},
      '759103': {'lat': '20.950011', 'name': 'Kalamchhuin,Angul,Odisha', 'lon': '85.216689'},
      '786101': {'lat': '27.2851', 'name': 'Dihing Hola,Dibrugarh,Assam', 'lon': '95.2188'},
      '786102': {'lat': '27.3328', 'name': 'Chabua Air Field,Dibrugarh,Assam', 'lon': '95.1908'},
      '786103': {'lat': '27.3804', 'name': 'Borabhayapuri,Dibrugarh,Assam', 'lon': '95.1628'},
      '508221': {'lat': '17.3393', 'name': 'Pedanemilla,Nalgonda,Telangana', 'lon': '79.5975'},
      '508223': {'lat': '17.4999', 'name': 'Vasthakonduru,Nalgonda,Telangana', 'lon': '79.1206'},
      '508224': {'lat': '17.4019', 'name': 'Solipeta,Suryapet,Telangana', 'lon': '79.5407'},
      '486448': {'lat': '24.7846', 'name': 'Umari,Rewa,Madhya Pradesh', 'lon': '81.4154'},
      '431518': {'lat': '18.8483', 'name': 'Dhanegaon Camp,Beed,Maharashtra', 'lon': '77.531'},
      '276139': {'lat': '26.2622', 'name': 'Rasulpur Nandalal,Azamgarh,Uttar Pradesh', 'lon': '83.2023'},
      '276138': {'lat': '26.3586', 'name': 'Berama,Azamgarh,Uttar Pradesh', 'lon': '82.9177'},
      '493225': {'lat': '21.3779', 'name': 'Tekarikunda,Raipur,Chattisgarh', 'lon': '81.9765'},
      '486440': {'lat': '24.6457', 'name': 'Bagadha Pidhihan,Rewa,Madhya Pradesh', 'lon': '81.3515'},
      '486441': {'lat': '24.6448', 'name': 'Kanchanpur,Rewa,Madhya Pradesh', 'lon': '81.3156'},
      '276137': {'lat': '25.9045', 'name': 'Dewara Jadid II,Azamgarh,Uttar Pradesh', 'lon': '83.2852'},
      '276136': {'lat': '26.5088', 'name': 'Tarauka,Azamgarh,Uttar Pradesh', 'lon': '82.9734'},
      '276131': {'lat': '25.9884', 'name': 'Sema,Azamgarh,Uttar Pradesh', 'lon': '83.1493'},
      '486445': {'lat': '24.5228', 'name': 'Kakredi,Rewa,Madhya Pradesh', 'lon': '81.1285'},
      '486446': {'lat': '24.5593', 'name': 'Hardi Saon,Rewa,Madhya Pradesh', 'lon': '81.1955'},
      '486447': {'lat': '24.8565', 'name': 'Konigadehara,Rewa,Madhya Pradesh', 'lon': '81.3734'},
      '735225': {'lat': '26.8803', 'name': 'Champaguri,Jalpaiguri,West Bengal', 'lon': '88.9013'},
      '505502': {'lat': '18.4093', 'name': 'Korkal,Karim Nagar,Telangana', 'lon': '79.3858'},
      '505501': {'lat': '18.6118', 'name': 'Nachupalli,Karim Nagar,Telangana', 'lon': '78.5464'},
      '332311': {'lat': '27.416', 'name': 'Dantru,Sikar,Rajasthan', 'lon': '75.6323'},
      '784168': {'lat': '26.6456', 'name': 'Simuluguri,Sonitpur,Assam', 'lon': '93.4808'},
      '784169': {'lat': '26.782', 'name': 'Lohitmukh,Sonitpur,Assam', 'lon': '93.6636'},
      '505505': {'lat': '18.6305', 'name': 'Vegurupalli,Karim Nagar,Telangana', 'lon': '79.1817'},
      '505504': {'lat': '17.2031', 'name': 'Kaleswaram,Karim Nagar,Telangana', 'lon': '78.9485'},
      '784164': {'lat': '26.346', 'name': 'Dikrong,Lakhimpur,Assam', 'lon': '91.677'},
      '784165': {'lat': '26.919', 'name': 'Puli Naharani,Lakhimpur,Assam', 'lon': '94.2767'},
      '784166': {'lat': '26.8325', 'name': 'Behali,Sonitpur,Assam', 'lon': '93.3452'},
      '784167': {'lat': '26.782', 'name': 'Arragaon No.1,Sonitpur,Assam', 'lon': '93.6636'},
      '784160': {'lat': '26.8478', 'name': 'Lakhanabari,Lakhimpur,Assam', 'lon': '93.7099'},
      '784161': {'lat': '26.9439', 'name': 'Morichapathar,Lakhimpur,Assam', 'lon': '94.4075'},
      '632010': {'lat': '13.0318', 'name': 'Melmonavur,Vellore,Tamil Nadu', 'lon': '79.2586'},
      '784163': {'lat': '26.782', 'name': 'Pithguri,Lakhimpur,Assam', 'lon': '93.6636'},
      '524414': {'lat': '14.3107', 'name': 'Ramakur,Nellore,Andhra Pradesh', 'lon': '79.4973'},
      '524415': {'lat': '13.8823', 'name': 'Muttembaka,Nellore,Andhra Pradesh', 'lon': '80.1134'},
      '736101': {'lat': '26.0188', 'name': 'Cooch Behar New Town,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '457001': {'lat': '23.501', 'name': 'Kaneri,Ratlam,Madhya Pradesh', 'lon': '75.0901'},
      '524410': {'lat': '14.0608', 'name': 'Kesavaram,Nellore,Andhra Pradesh', 'lon': '79.9612'},
      '524411': {'lat': '14.7755', 'name': 'Pedavanjivaka,Nellore,Andhra Pradesh', 'lon': '80.0637'},
      '524412': {'lat': '14.1128', 'name': 'Palicherlavaripalem,Nellore,Andhra Pradesh', 'lon': '79.917'},
      '524413': {'lat': '14.0098', 'name': 'Tinnelapudi,Nellore,Andhra Pradesh', 'lon': '80.0423'},
      '263622': {'lat': '29.7545', 'name': 'Jingoli Toli,Almora,Uttarakhand', 'lon': '79.5385'},
      '486226': {'lat': '24.7829', 'name': 'Raipur Sonauri,Rewa,Madhya Pradesh', 'lon': '81.5313'},
      '628229': {'lat': '8.5592', 'name': 'Sahupuram,Tuticorin,Tamil Nadu', 'lon': '78.0477'},
      '486220': {'lat': '24.9358', 'name': 'Kotrakala,Rewa,Madhya Pradesh', 'lon': '81.6461'},
      '515311': {'lat': '13.8051', 'name': 'Ragalinganahalli,Ananthapur,Andhra Pradesh', 'lon': '77.0439'},
      '486223': {'lat': '24.7552', 'name': 'Negura,Rewa,Madhya Pradesh', 'lon': '81.5896'},
      '203394': {'lat': '28.3734', 'name': 'Dhalana,Bulandshahr,Uttar Pradesh', 'lon': '78.0448'},
      '680620': {'lat': '10.4002', 'name': 'Arimpur,Thrissur,Kerala', 'lon': '76.2065'},
      '680623': {'lat': '10.4002', 'name': 'Mundathicode West,Thrissur,Kerala', 'lon': '76.2065'},
      '585444': {'lat': '18.0422', 'name': 'Nittur,Bidar,Karnataka', 'lon': '77.3525'},
      '686503': {'lat': '9.5566', 'name': 'Anikad East,Kottayam,Kerala', 'lon': '76.7342'},
      '686502': {'lat': '9.5691', 'name': 'Suryanarayana Puram,Kottayam,Kerala', 'lon': '76.6639'},
      '686501': {'lat': '9.5566', 'name': 'Areeparampu,Kottayam,Kerala', 'lon': '76.7342'},
      '686507': {'lat': '9.5566', 'name': 'Kanjirapally H.O,Kottayam,Kerala', 'lon': '76.7342'},
      '686506': {'lat': '9.56', 'name': 'Nariyanani,Kottayam,Kerala', 'lon': '76.75'},
      '686505': {'lat': '9.5566', 'name': 'Theerthapadapuram,Kottayam,Kerala', 'lon': '76.7342'},
      '686504': {'lat': '9.5597', 'name': 'Vazhoor,Kottayam,Kerala', 'lon': '76.7056'},
      '686509': {'lat': '9.4836', 'name': 'Kuruvammoozhy,Kottayam,Kerala', 'lon': '76.8454'},
      '686508': {'lat': '9.5981', 'name': 'Vanchimala,Kottayam,Kerala', 'lon': '76.7762'},
      '203397': {'lat': '28.0664', 'name': 'Biroura,Bulandshahr,Uttar Pradesh', 'lon': '78.441'},
      '283101': {'lat': '27.1713', 'name': 'Arrua Khas,Agra,Uttar Pradesh', 'lon': '77.8047'},
      '518196': {'lat': '15.1428', 'name': 'Chanugondla,Kurnool,Andhra Pradesh', 'lon': '78.165'},
      '244401': {'lat': '29.0479', 'name': 'Dhakia Jut,Moradabad,Uttar Pradesh', 'lon': '78.7599'},
      '244402': {'lat': '28.9052', 'name': 'Laloowala,Moradabad,Uttar Pradesh', 'lon': '78.8683'},
      '754296': {'lat': '21.0859', 'name': 'Sakuntalapur,Jajapur,Odisha', 'lon': '85.9296'},
      '670521': {'lat': '12.0732', 'name': 'Kuniyan,Kannur,Kerala', 'lon': '75.3962'},
      '283103': {'lat': '27.2055', 'name': 'Kurri Kuppa,Firozabad,Uttar Pradesh', 'lon': '78.318'},
      '754295': {'lat': '21.1341', 'name': 'Chakada,Cuttack,Odisha', 'lon': '85.1215'},
      '503186': {'lat': '18.7936', 'name': 'Arsapalli,Nizamabad,Telangana', 'lon': '78.2025'},
      '754294': {'lat': '20.1347', 'name': 'Odisso,Jagatsinghapur,Odisha', 'lon': '85.6268'},
      '362215': {'lat': '22.0921', 'name': 'Khorasa Ahir,Junagadh,Gujarat', 'lon': '69.2751'},
      '754293': {'lat': '20.3149', 'name': 'Lemalo,Cuttack,Odisha', 'lon': '85.696'},
      '515004': {'lat': '13.8968', 'name': 'Motilal Street,Ananthapur,Andhra Pradesh', 'lon': '78.3626'},
      '203396': {'lat': '28.1477', 'name': 'Soi,Bulandshahr,Uttar Pradesh', 'lon': '78.0425'},
      '754292': {'lat': '21.7086', 'name': 'Saudia,Jajapur,Odisha', 'lon': '85.8418'},
      '305415': {'lat': '26.0619', 'name': 'Karonj,Ajmer,Rajasthan', 'lon': '75.3081'},
      '574122': {'lat': '13.2549', 'name': 'Kervashe,Udupi,Karnataka', 'lon': '75.0729'},
      '305412': {'lat': '25.8114', 'name': 'Dabrella,Ajmer,Rajasthan', 'lon': '74.6299'},
      '754290': {'lat': '20.9235', 'name': 'Tilda,Cuttack,Odisha', 'lon': '85.4212'},
      '140702': {'lat': '30.3867', 'name': 'Marru,Patiala,Punjab', 'lon': '76.4902'},
      '140701': {'lat': '30.3867', 'name': 'Narru,Patiala,Punjab', 'lon': '76.4902'},
      '303712': {'lat': '26.8557', 'name': 'Nangal Turkan,Jaipur,Rajasthan', 'lon': '75.948'},
      '761144': {'lat': '21.383', 'name': 'Adapada,Ganjam,Odisha', 'lon': '83.6883'},
      '678551': {'lat': '10.8329', 'name': 'Olasseri,Palakkad,Kerala', 'lon': '76.4992'},
      '678553': {'lat': '10.8329', 'name': 'Nallepilly,Palakkad,Kerala', 'lon': '76.4992'},
      '678552': {'lat': '10.7948', 'name': 'Polpulli,Palakkad,Kerala', 'lon': '76.3143'},
      '678555': {'lat': '10.7378', 'name': 'Vannamada,Palakkad,Kerala', 'lon': '76.8363'},
      '678554': {'lat': '10.9661', 'name': 'Athicode,Palakkad,Kerala', 'lon': '76.347'},
      '678557': {'lat': '10.8329', 'name': 'Velanthavalam,Palakkad,Kerala', 'lon': '76.4992'},
      '678556': {'lat': '10.8329', 'name': 'Eduppukulam,Palakkad,Kerala', 'lon': '76.4992'},
      '723121': {'lat': '23.6244', 'name': 'Kalapathar (PuruliB.O,Purulia,West Bengal', 'lon': '86.8734'},
      '277001': {'lat': '25.7831', 'name': 'Ballia name,Ballia,Uttar Pradesh', 'lon': '84.2017'},
      '422112': {'lat': '20.9671', 'name': 'Khopadi ,Nashik,Maharashtra', 'lon': '79.3816'},
      '422113': {'lat': '20.9671', 'name': 'Paste,Nashik,Maharashtra', 'lon': '79.3816'},
      '387630': {'lat': '22.8982', 'name': 'Bhaner,Kheda,Gujarat', 'lon': '72.9928'},
      '458556': {'lat': '24.1385', 'name': 'Takrawad,Mandsaur,Madhya Pradesh', 'lon': '76.1144'},
      '387635': {'lat': '22.9839', 'name': 'Kakarkhad,Kheda,Gujarat', 'lon': '72.7657'},
      '458553': {'lat': '24.0836', 'name': 'Somia,Mandsaur,Madhya Pradesh', 'lon': '75.2894'},
      '841101': {'lat': '25.7358', 'name': 'Parmanandpur,Saran,Bihar', 'lon': '85.1216'},
      '799210': {'lat': '24.0447', 'name': 'Fatikchhera,West Tripura,Tripura', 'lon': '91.7553'},
      '612605': {'lat': '10.8866', 'name': 'Injikollai,Thanjavur,Tamil Nadu', 'lon': '79.3494'},
      '799212': {'lat': '24.0447', 'name': 'Sundartilla,West Tripura,Tripura', 'lon': '91.7553'},
      '505188': {'lat': '18.5083', 'name': 'Rompikunta,Karim Nagar,Telangana', 'lon': '79.3639'},
      '203393': {'lat': '28.1415', 'name': 'Karanbash,Bulandshahr,Uttar Pradesh', 'lon': '78.3013'},
      '517319': {'lat': '13.4458', 'name': 'Pathapolu,Chittoor,Andhra Pradesh', 'lon': '78.6515'},
      '226013': {'lat': '27.2826', 'name': 'Diguria,Lucknow,Uttar Pradesh', 'lon': '80.5163'},
      '328001': {'lat': '26.8086', 'name': 'Bichhia,Dholpur,Rajasthan', 'lon': '77.5606'},
      '502291': {'lat': '17.6195', 'name': 'Suraram,Medak,Telangana', 'lon': '77.8964'},
      '301413': {'lat': '27.205', 'name': 'Barkokhurd,Alwar,Rajasthan', 'lon': '76.7328'},
      '301412': {'lat': '27.6205', 'name': 'Bhagukawas,Alwar,Rajasthan', 'lon': '76.6262'},
      '301411': {'lat': '27.9491', 'name': 'Hameerka,Alwar,Rajasthan', 'lon': '76.8347'},
      '301410': {'lat': '27.284', 'name': 'Syaluta,Alwar,Rajasthan', 'lon': '76.5703'},
      '152113': {'lat': '30.0346', 'name': 'Mehna,Muktsar,Punjab', 'lon': '74.5073'},
      '301416': {'lat': '27.5177', 'name': 'Rampur (Alwar),Alwar,Rajasthan', 'lon': '76.6788'},
      '301415': {'lat': '27.5177', 'name': 'Bighota,Alwar,Rajasthan', 'lon': '76.6788'},
      '301414': {'lat': '27.3571', 'name': 'Dhigawara,Alwar,Rajasthan', 'lon': '76.5677'},
      '221011': {'lat': '25.3231', 'name': 'Kurahuwan,Varanasi,Uttar Pradesh', 'lon': '82.894'},
      '203392': {'lat': '28.2127', 'name': 'Donka,Bulandshahr,Uttar Pradesh', 'lon': '78.1713'},
      '229001': {'lat': '26.1169', 'name': 'Taqia Kala,Raebareli,Uttar Pradesh', 'lon': '81.5157'},
      '535526': {'lat': '17.3276', 'name': 'Pedamerangi,Vizianagaram,Andhra Pradesh', 'lon': '82.3881'},
      '535527': {'lat': '18.7269', 'name': 'Gochekka,Vizianagaram,Andhra Pradesh', 'lon': '83.335'},
      '535524': {'lat': '18.7355', 'name': 'Sivannapeta,Vizianagaram,Andhra Pradesh', 'lon': '83.7853'},
      '535525': {'lat': '18.7297', 'name': 'Turakanaiduvalasa,Vizianagaram,Andhra Pradesh', 'lon': '83.5112'},
      '535522': {'lat': '18.7354', 'name': 'Mrutyunjayarayanagaram,Vizianagaram,Andhra Pradesh', 'lon': '83.4672'},
      '532203': {'lat': '18.5492', 'name': 'Pedalavunipalli,Srikakulam,Andhra Pradesh', 'lon': '83.9602'},
      '500038': {'lat': '17.3939', 'name': 'Sanjeev Reddy Nagar,Hyderabad,Telangana', 'lon': '78.4529'},
      '532201': {'lat': '18.5492', 'name': 'Tekkali H.O,Srikakulam,Andhra Pradesh', 'lon': '83.9602'},
      '757050': {'lat': '21.3638', 'name': 'Uparbeda,Mayurbhanj,Odisha', 'lon': '84.5833'},
      '757051': {'lat': '22.0094', 'name': 'Palasbani,Mayurbhanj,Odisha', 'lon': '86.0156'},
      '757052': {'lat': '21.1868', 'name': 'Hatijhari,Mayurbhanj,Odisha', 'lon': '86.4047'},
      '757053': {'lat': '22.0775', 'name': 'Sanbhundu,Mayurbhanj,Odisha', 'lon': '85.9019'},
      '757054': {'lat': '21.5197', 'name': 'Soroda Jashipur,Mayurbhanj,Odisha', 'lon': '85.8003'},
      '757055': {'lat': '21.3236', 'name': 'Sarisa,Mayurbhanj,Odisha', 'lon': '85.7969'},
      '534124': {'lat': '16.6434', 'name': 'Juttiga,West Godavari,Andhra Pradesh', 'lon': '81.6516'},
      '534126': {'lat': '16.3927', 'name': 'Podalada,West Godavari,Andhra Pradesh', 'lon': '80.5026'},
      '534123': {'lat': '16.3905', 'name': 'Penumanchili,West Godavari,Andhra Pradesh', 'lon': '81.2707'},
      '534122': {'lat': '16.6989', 'name': 'Neggipudi,West Godavari,Andhra Pradesh', 'lon': '81.0296'},
      '743609': {'lat': '23.2307', 'name': 'Maheshdari,South 24 Parganas,West Bengal', 'lon': '88.1588'},
      '152132': {'lat': '30.0592', 'name': 'Tootwala,Firozpur,Punjab', 'lon': '73.9989'},
      '276303': {'lat': '25.86', 'name': 'Baksh Pur,Azamgarh,Uttar Pradesh', 'lon': '82.9209'},
      '841311': {'lat': '25.9335', 'name': 'Gohpur,Saran,Bihar', 'lon': '84.9431'},
      '841313': {'lat': '25.863', 'name': 'Ghorhat Mathia,Saran,Bihar', 'lon': '84.8105'},
      '841312': {'lat': '25.6797', 'name': 'Khawaspur,Bhojpur,Bihar', 'lon': '84.5653'},
      '844114': {'lat': '25.856', 'name': 'Bishanpur  Bande,Vaishali,Bihar', 'lon': '85.3025'},
      '144631': {'lat': '31.2374', 'name': 'Nangal Lubana,Kapurthala,Punjab', 'lon': '75.502'},
      '302005': {'lat': '26.9196', 'name': 'Vidyut Bhawan,Jaipur,Rajasthan', 'lon': '75.7878'},
      '302004': {'lat': '26.9196', 'name': 'Jhalana Doongri,Jaipur,Rajasthan', 'lon': '75.7878'},
      '781028': {'lat': '26.1593', 'name': 'Beltola,Kamrup,Assam', 'lon': '91.8345'},
      '781029': {'lat': '26.1593', 'name': 'Pillangkata,Kamrup,Assam', 'lon': '91.8345'},
      '302001': {'lat': '26.9196', 'name': 'Purani Basti (Jaipur),Jaipur,Rajasthan', 'lon': '75.7878'},
      '302003': {'lat': '26.9196', 'name': 'Ramganj Bazar,Jaipur,Rajasthan', 'lon': '75.7878'},
      '302002': {'lat': '26.9196', 'name': 'Kishanpole Bazar,Jaipur,Rajasthan', 'lon': '75.7878'},
      '781022': {'lat': '26.118', 'name': 'Khanapara,Kamrup,Assam', 'lon': '91.4061'},
      '781023': {'lat': '26.1593', 'name': 'Jorabat,Kamrup,Assam', 'lon': '91.8345'},
      '781020': {'lat': '26.118', 'name': 'Noonmati,Kamrup,Assam', 'lon': '91.4061'},
      '781021': {'lat': '26.1593', 'name': 'Bamunimaidan,Kamrup,Assam', 'lon': '91.8345'},
      '781026': {'lat': '26.2004', 'name': 'Bonda,Kamrup,Assam', 'lon': '91.8762'},
      '781027': {'lat': '26.1535', 'name': 'Amsinggaon E (S -85),Kamrup,Assam', 'lon': '91.8451'},
      '781024': {'lat': '26.1593', 'name': 'Zoo Road,Kamrup,Assam', 'lon': '91.8345'},
      '781025': {'lat': '26.1593', 'name': 'Ambari Fatasil,Kamrup,Assam', 'lon': '91.8345'},
      '152116': {'lat': '30.0708', 'name': 'Abohar Mandi,Firozpur,Punjab', 'lon': '74.1726'},
      '221401': {'lat': '25.36515', 'name': 'Munsiladpur,Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.53835'},
      '331001': {'lat': '28.3058', 'name': 'Station Road Churu,Churu,Rajasthan', 'lon': '74.7546'},
      '209729': {'lat': '27.1612', 'name': 'Aseha,Kannauj,Uttar Pradesh', 'lon': '79.6213'},
      '625007': {'lat': '10.0042', 'name': 'Denobli Press,Madurai,Tamil Nadu', 'lon': '78.1748'},
      '768210': {'lat': '21.7711', 'name': 'THELKULI,Sambalpur,Odisha', 'lon': '84.0506'},
      '768211': {'lat': '21.7083', 'name': 'Bandhbahal Colony,Jharsuguda,Odisha', 'lon': '83.9233'},
      '768212': {'lat': '21.6508', 'name': 'K. Jhankarpali,Sambalpur,Odisha', 'lon': '84.0735'},
      '768213': {'lat': '21.8144', 'name': 'Malidihi,Jharsuguda,Odisha', 'lon': '84.2738'},
      '768214': {'lat': '21.766', 'name': 'Sadamal,Sambalpur,Odisha', 'lon': '84.1406'},
      '768215': {'lat': '21.9105', 'name': 'Sudang,Jharsuguda,Odisha', 'lon': '84.2501'},
      '768216': {'lat': '21.8866', 'name': 'Lamptibahal,Jharsuguda,Odisha', 'lon': '83.9252'},
      '768217': {'lat': '21.8183', 'name': 'Jorabaga,Jharsuguda,Odisha', 'lon': '83.8179'},
      '768218': {'lat': '21.8218', 'name': 'Belpahar,Jharsuguda,Odisha', 'lon': '83.8458'},
      '768219': {'lat': '21.7181', 'name': 'Bhaurkhol,Jharsuguda,Odisha', 'lon': '83.7633'},
      '793151': {'lat': '25.5689', 'name': 'Khanduli,Jaintia Hills,Meghalaya', 'lon': '92.2088'},
      '793150': {'lat': '25.4496', 'name': 'Thangrain,Jaintia Hills,Meghalaya', 'lon': '92.2655'},
      '249407': {'lat': '29.8573', 'name': 'Arya Nagar (Haridwar),Haridwar,Uttarakhand', 'lon': '77.9933'},
      '272002': {'lat': '26.7933', 'name': 'Jamadashahi,Basti,Uttar Pradesh', 'lon': '82.7685'},
      '496108': {'lat': '21.8385', 'name': 'Urjanagar Telirampur,Raigarh,Chattisgarh', 'lon': '83.3186'},
      '313301': {'lat': '24.859', 'name': 'Salore,Rajsamand,Rajasthan', 'lon': '73.6134'},
      '491338': {'lat': '21.8104', 'name': 'Hatranka,Durg,Chattisgarh', 'lon': '81.3578'},
      '491336': {'lat': '22.010456', 'name': 'Goodha,Kawardha,Chattisgarh', 'lon': '81.238062'},
      '491337': {'lat': '21.9452', 'name': 'Andhiarkhor,Durg,Chattisgarh', 'lon': '81.7122'},
      '625006': {'lat': '10.0042', 'name': 'Thanakkankulam,Madurai,Tamil Nadu', 'lon': '78.1748'},
      '496107': {'lat': '21.92', 'name': 'Hamirpur,Raigarh,Chattisgarh', 'lon': '83.3167'},
      '496100': {'lat': '21.7802', 'name': 'Loharsingha,Raigarh,Chattisgarh', 'lon': '83.3199'},
      '505524': {'lat': '18.5336', 'name': 'Kodurpak,Karim Nagar,Telangana', 'lon': '78.9126'},
      '496450': {'lat': '21.8246', 'name': 'Bhedwan,Raigarh,Chattisgarh', 'lon': '83.0437'},
      '752085': {'lat': '20.4409', 'name': 'Raitadholamara,Nayagarh,Odisha', 'lon': '85.0311'},
      '411028': {'lat': '18.5167', 'name': 'Sasanenagar,Pune,Maharashtra', 'lon': '73.9167'},
      '794109': {'lat': '25.5545', 'name': 'Pedaldoba,West Garo Hills,Meghalaya', 'lon': '90.3369'},
      '794108': {'lat': '25.8544', 'name': 'Daram,East Garo Hills,Meghalaya', 'lon': '90.5926'},
      '411022': {'lat': '18.4769', 'name': 'Srpf,Pune,Maharashtra', 'lon': '74.3505'},
      '411023': {'lat': '18.4769', 'name': 'Kudje,Pune,Maharashtra', 'lon': '74.3505'},
      '788931': {'lat': '26.3929', 'name': 'Diyumbra,North Cachar Hills,Assam', 'lon': '93.8817'},
      '411021': {'lat': '18.5131', 'name': 'Bavdhan,Pune,Maharashtra', 'lon': '73.7825'},
      '411026': {'lat': '18.4769', 'name': 'Indrayaninagar,Pune,Maharashtra', 'lon': '74.3505'},
      '411027': {'lat': '18.4769', 'name': 'Sangavi,Pune,Maharashtra', 'lon': '74.3505'},
      '411024': {'lat': '18.4769', 'name': 'Khadakwasla R.S.,Pune,Maharashtra', 'lon': '74.3505'},
      '411025': {'lat': '18.4009', 'name': 'Osade,Pune,Maharashtra', 'lon': '75.3524'},
      '733125': {'lat': '25.35188', 'name': 'Maliandighi,South Dinajpur,West Bengal', 'lon': '88.24785'},
      '811302': {'lat': '25.3325', 'name': 'Jalal Pur Nauranga,Patna,Bihar', 'lon': '86.186'},
      '811301': {'lat': '24.9409', 'name': 'Markama,Jamui,Bihar', 'lon': '85.9607'},
      '388365': {'lat': '22.9494', 'name': 'Kherda,Anand,Gujarat', 'lon': '73.2175'},
      '782139': {'lat': '26.4537', 'name': 'Anjukpani,Karbi Anglon,Assam', 'lon': '92.9769'},
      '782138': {'lat': '26.4868', 'name': 'Amoni,Nagaon,Assam', 'lon': '92.9697'},
      '388360': {'lat': '22.9494', 'name': 'Ajupura,Anand,Gujarat', 'lon': '73.2175'},
      '301713': {'lat': '26.0912', 'name': 'Guwana,Alwar,Rajasthan', 'lon': '76.5458'},
      '782135': {'lat': '26.3367', 'name': 'Parkup Pahar,Nagaon,Assam', 'lon': '92.8807'},
      '782137': {'lat': '26.4868', 'name': 'Bengenaati,Nagaon,Assam', 'lon': '92.9697'},
      '782136': {'lat': '26.5784', 'name': 'Burapahar,Nagaon,Assam', 'lon': '93.0106'},
      '713405': {'lat': '23.3857', 'name': 'Astogoria,Purba Bardhaman,West Bengal', 'lon': '88.0371'},
      '516268': {'lat': '14.1959', 'name': 'Gurrappagaripalli,Cuddapah,Andhra Pradesh', 'lon': '78.9242'},
      '516269': {'lat': '14.0405', 'name': 'Pemmadapalli,Cuddapah,Andhra Pradesh', 'lon': '78.7468'},
      '505525': {'lat': '18.7309', 'name': 'Eligaid,Karim Nagar,Telangana', 'lon': '79.383'},
      '609810': {'lat': '10.9279', 'name': 'Tiruvalangadu,Nagapattinam,Tamil Nadu', 'lon': '79.6069'},
      '516267': {'lat': '13.9006', 'name': 'Cheemalacheruvupalli,Cuddapah,Andhra Pradesh', 'lon': '78.5121'},
      '361162': {'lat': '22.2754', 'name': 'Vadala Moti Metana,Jamnagar,Gujarat', 'lon': '70.58'},
      '494010': {'lat': '19.8053', 'name': 'Gadhiya,Bastar,Chattisgarh', 'lon': '81.6842'},
      '502248': {'lat': '17.8762', 'name': 'Shankarampet,Medak,Telangana', 'lon': '78.3346'},
      '443204': {'lat': '20.2315', 'name': 'Khallad Gavhan,Buldhana,Maharashtra', 'lon': '76.3799'},
      '443206': {'lat': '20.3232', 'name': 'Saramba,Buldhana,Maharashtra', 'lon': '75.9996'},
      '443201': {'lat': '20.6162', 'name': 'Ambashi,Buldhana,Maharashtra', 'lon': '76.9581'},
      '443203': {'lat': '20.3432', 'name': 'Saokhed Tejan,Buldhana,Maharashtra', 'lon': '76.5906'},
      '443202': {'lat': '19.9221', 'name': 'Malkapur Pangra,Buldhana,Maharashtra', 'lon': '76.5549'},
      '175005': {'lat': '31.4113', 'name': 'Segli,Mandi,Himachal Pradesh', 'lon': '77.0808'},
      '175004': {'lat': '31.5913', 'name': 'Upper Bhambla,Mandi,Himachal Pradesh', 'lon': '76.7833'},
      '175007': {'lat': '31.6481', 'name': 'Gopalpur (Mandi),Mandi,Himachal Pradesh', 'lon': '76.7529'},
      '175006': {'lat': '31.3617', 'name': 'Behna,Mandi,Himachal Pradesh', 'lon': '77.3887'},
      '175001': {'lat': '31.7202', 'name': 'Majhwar,Mandi,Himachal Pradesh', 'lon': '76.9256'},
      '175003': {'lat': '31.6383', 'name': 'Dawahan,Mandi,Himachal Pradesh', 'lon': '76.9929'},
      '175002': {'lat': '31.5363', 'name': 'Bhojpur (Mandi),Mandi,Himachal Pradesh', 'lon': '76.8577'},
      '394235': {'lat': '21.4053', 'name': 'Kafleta,Surat,Gujarat', 'lon': '73.2118'},
      '394541': {'lat': '21.3644', 'name': 'Saras,Surat,Gujarat', 'lon': '72.715'},
      '394540': {'lat': '21.3644', 'name': 'Kudiana,Surat,Gujarat', 'lon': '72.715'},
      '175009': {'lat': '31.4902', 'name': 'Alsindi,Mandi,Himachal Pradesh', 'lon': '77.0216'},
      '175008': {'lat': '31.6605', 'name': 'Khakhariana,Mandi,Himachal Pradesh', 'lon': '76.6565'},
      '572112': {'lat': '13.6763', 'name': 'Badavanahalli,Tumakuru,Karnataka', 'lon': '77.0655'},
      '572113': {'lat': '13.6713', 'name': 'Chikkabanagere,Tumakuru,Karnataka', 'lon': '76.627'},
      '572111': {'lat': '13.5202', 'name': 'Jinnagara,Tumakuru,Karnataka', 'lon': '76.9477'},
      '572116': {'lat': '13.9872', 'name': 'Thimmalanayakanabetta,Tumakuru,Karnataka', 'lon': '77.2349'},
      '572117': {'lat': '13.3427', 'name': 'Irkasandra,Tumakuru,Karnataka', 'lon': '76.6872'},
      '572114': {'lat': '12.9895', 'name': 'Beerasandra,Tumakuru,Karnataka', 'lon': '76.5052'},
      '572115': {'lat': '13.6519', 'name': 'Ganadahunse,Tumakuru,Karnataka', 'lon': '76.7481'},
      '393010': {'lat': '21.7667', 'name': 'Kansia,Bharuch,Gujarat', 'lon': '73.1833'},
      '572118': {'lat': '12.8325', 'name': 'Makanahally,Tumakuru,Karnataka', 'lon': '77.0399'},
      '572119': {'lat': '13.4003', 'name': 'Niruvagal,Tumakuru,Karnataka', 'lon': '76.4069'},
      '393017': {'lat': '21.7667', 'name': 'Avidha,Bharuch,Gujarat', 'lon': '73.1833'},
      '700094': {'lat': '22.4485', 'name': 'Sammilani Mahavidyalaya,South 24 Parganas,West Bengal', 'lon': '88.3883'},
      '505527': {'lat': '17.3267', 'name': 'Thimmapur,Karim Nagar,Telangana', 'lon': '79.7454'},
      '627010': {'lat': '8.726', 'name': 'Kallur,Tirunelveli,Tamil Nadu', 'lon': '77.5962'},
      '453771': {'lat': '22.8218', 'name': 'Lasudia,Indore,Madhya Pradesh', 'lon': '75.956'},
      '713408': {'lat': '23.5086', 'name': 'Jotesriram,Purba Bardhaman,West Bengal', 'lon': '88.1499'},
      '361305': {'lat': '22.4208', 'name': 'Viramdad,Jamnagar,Gujarat', 'lon': '70.06'},
      '488448': {'lat': '24.1616', 'name': 'Nanchanda,Panna,Madhya Pradesh', 'lon': '80.0564'},
      '361306': {'lat': '21.7471', 'name': 'Chhapar,Jamnagar,Gujarat', 'lon': '70.3727'},
      '700097': {'lat': '22.4485', 'name': 'Purbachal,North 24 Parganas,West Bengal', 'lon': '88.3883'},
      '733207': {'lat': '25.577', 'name': 'Khunia,North Dinajpur,West Bengal', 'lon': '88.0713'},
      '311602': {'lat': '25.177', 'name': 'Kasya Kala,Bhilwara,Rajasthan', 'lon': '75.3939'},
      '609811': {'lat': '10.8494', 'name': 'Namasivayapuram,Nagapattinam,Tamil Nadu', 'lon': '79.7389'},
      '700090': {'lat': '22.4485', 'name': 'Noapara,Kolkata,West Bengal', 'lon': '88.3883'},
      '272205': {'lat': '27.1306', 'name': 'Antari Bazar,Siddharthnagar,Uttar Pradesh', 'lon': '83.1336'},
      '272204': {'lat': '26.406', 'name': 'Udairajganj,Siddharthnagar,Uttar Pradesh', 'lon': '83.3423'},
      '272203': {'lat': '27.302', 'name': 'Sekhuiya,Siddharthnagar,Uttar Pradesh', 'lon': '83.0963'},
      '272202': {'lat': '27.1231', 'name': 'Birdpur No.7,Siddharthnagar,Uttar Pradesh', 'lon': '83.1086'},
      '272201': {'lat': '27.0007', 'name': 'Audahi Kalan,Basti,Uttar Pradesh', 'lon': '82.8743'},
      '700091': {'lat': '22.4485', 'name': 'Sech Bhawan,North 24 Parganas,West Bengal', 'lon': '88.3883'},
      '752062': {'lat': '20.7867', 'name': 'Narayanipada,Khorda,Odisha', 'lon': '85.4727'},
      '508377': {'lat': '17.3225', 'name': 'Rangundala,Nalgonda,Telangana', 'lon': '78.7447'},
      '580011': {'lat': '15.5445', 'name': 'Dharwad High Court,Dharwad,Karnataka', 'lon': '74.8713'},
      '505526': {'lat': '18.7775', 'name': 'Endapalli,Karim Nagar,Telangana', 'lon': '79.082'},
      '627011': {'lat': '8.6874', 'name': 'Palayankottai High Grounds,Tirunelveli,Tamil Nadu', 'lon': '77.8008'},
      '241001': {'lat': '27.2599', 'name': 'Eja,Hardoi,Uttar Pradesh', 'lon': '80.243'},
      '140124': {'lat': '31.3897', 'name': 'Nangal Talwara,Rupnagar,Punjab', 'lon': '76.3757'},
      '311606': {'lat': '25.7416', 'name': 'Mangthala,Bhilwara,Rajasthan', 'lon': '75.2283'},
      '508374': {'lat': '17.0576', 'name': 'Amangal,Nalgonda,Telangana', 'lon': '79.5722'},
      '311605': {'lat': '25.3852', 'name': 'Jassu Ji Ka Khera,Bhilwara,Rajasthan', 'lon': '74.8797'},
      '590020': {'lat': '15.8247', 'name': 'Alarwad,Belagavi,Karnataka', 'lon': '74.5987'},
      '465693': {'lat': '24.1101', 'name': 'Ghoghatpur,Sehore,Madhya Pradesh', 'lon': '76.3779'},
      '612201': {'lat': '10.6797', 'name': 'Vadamattam,Tiruvarur,Tamil Nadu', 'lon': '79.536'},
      '244231': {'lat': '28.9586', 'name': 'Kauralla,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.3437'},
      '244236': {'lat': '28.9054', 'name': 'Atrasikalan,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.2956'},
      '752063': {'lat': '20.3025', 'name': 'Gobardhan Prasad,Nayagarh,Odisha', 'lon': '85.2179'},
      '612502': {'lat': '11.15', 'name': 'Lower Anicut,Thanjavur,Tamil Nadu', 'lon': '79.4803'},
      '382650': {'lat': '23.3663', 'name': 'Vasan,Gandhi Nagar,Gujarat', 'lon': '73.5543'},
      '382455': {'lat': '22.1937', 'name': 'Bhangadh,Ahmedabad,Gujarat', 'lon': '72.1484'},
      '581213': {'lat': '14.9747', 'name': 'Yalagach,Haveri,Karnataka', 'lon': '75.7022'},
      '581212': {'lat': '14.6119', 'name': 'Tadas,Haveri,Karnataka', 'lon': '75.4068'},
      '382450': {'lat': '22.6782', 'name': 'Salangpur - Hanuman,Ahmedabad,Gujarat', 'lon': '72.4553'},
      '581210': {'lat': '14.3396', 'name': 'Guddadamadapur,Haveri,Karnataka', 'lon': '75.5094'},
      '712221': {'lat': '23.0455', 'name': 'Angus,Hooghly,West Bengal', 'lon': '88.3215'},
      '712222': {'lat': '22.785', 'name': 'Baidyabati,Hooghly,West Bengal', 'lon': '88.3259'},
      '612202': {'lat': '10.9507', 'name': 'Poundarigapuram,Thanjavur,Tamil Nadu', 'lon': '79.4744'},
      '847115': {'lat': '26.1138', 'name': 'Tarsarai,Darbhanga,Bihar', 'lon': '86.0253'},
      '803214': {'lat': '25.3918', 'name': 'Dhabhma,Patna,Bihar', 'lon': '85.7985'},
      '841402': {'lat': '25.8978', 'name': 'Dholahi Kaithal,Saran,Bihar', 'lon': '84.9669'},
      '627115': {'lat': '8.4365', 'name': 'Nambithalaivanpattaiyam,Tirunelveli,Tamil Nadu', 'lon': '77.5657'},
      '523280': {'lat': '15.5901', 'name': 'Ethamukkala,Prakasam,Andhra Pradesh', 'lon': '79.6183'},
      '523281': {'lat': '15.0461', 'name': 'Potluru,Prakasam,Andhra Pradesh', 'lon': '79.948'},
      '312204': {'lat': '24.8614', 'name': 'Kankarwa,Chittorgarh,Rajasthan', 'lon': '73.9825'},
      '803215': {'lat': '25.3631', 'name': 'NTPC Barh,Nalanda,Bihar', 'lon': '85.651'},
      '627951': {'lat': '8.9619', 'name': 'Chocknatchiapuram,Tirunelveli,Tamil Nadu', 'lon': '77.6356'},
      '523286': {'lat': '15.4651', 'name': 'Padarthi,Prakasam,Andhra Pradesh', 'lon': '80.1537'},
      '483442': {'lat': '23.5455', 'name': 'Kulhua Badkheda,Katni,Madhya Pradesh', 'lon': '79.9151'},
      '803212': {'lat': '25.4569', 'name': 'Satya Bhaiya,Patna,Bihar', 'lon': '85.5434'},
      '803213': {'lat': '25.4564', 'name': 'Mahammadpur,Patna,Bihar', 'lon': '85.2222'},
      '497333': {'lat': '22.5122', 'name': 'Bhuneshwarpur,Surguja,Chattisgarh', 'lon': '82.6202'},
      '209726': {'lat': '27.012', 'name': 'Makrand Nagar,Kannauj,Uttar Pradesh', 'lon': '79.6879'},
      '486333': {'lat': '24.5795', 'name': 'Kulbaheria,Rewa,Madhya Pradesh', 'lon': '81.7628'},
      '785640': {'lat': '26.829', 'name': 'Rongpurnagar,Sibsagar,Assam', 'lon': '94.1138'},
      '803211': {'lat': '25.4553', 'name': 'Dakshnichak,Patna,Bihar', 'lon': '85.5854'},
      '497335': {'lat': '23.0164', 'name': 'Kasra,Koriya,Chattisgarh', 'lon': '82.6156'},
      '851120': {'lat': '25.531', 'name': 'Jaintigram,Begusarai,Bihar', 'lon': '85.8417'},
      '276205': {'lat': '26.0225', 'name': 'Mangrawa,Azamgarh,Uttar Pradesh', 'lon': '82.8341'},
      '515766': {'lat': '14.2804', 'name': 'Thumukunta,Ananthapur,Andhra Pradesh', 'lon': '77.3651'},
      '515767': {'lat': '14.4086', 'name': 'Cheliminahalli,Ananthapur,Andhra Pradesh', 'lon': '76.9656'},
      '752061': {'lat': '21.0275', 'name': 'Simor,Khorda,Odisha', 'lon': '84.7501'},
      '515765': {'lat': '14.1947', 'name': 'Vontareddipalli,Ananthapur,Andhra Pradesh', 'lon': '77.8416'},
      '515763': {'lat': '14.492', 'name': 'Yelagalavanka,Ananthapur,Andhra Pradesh', 'lon': '77.7181'},
      '515812': {'lat': '14.966', 'name': 'Uravakonda (Bus Stand),Ananthapur,Andhra Pradesh', 'lon': '77.2309'},
      '515761': {'lat': '14.5011', 'name': 'Yatakal,Ananthapur,Andhra Pradesh', 'lon': '77.6093'},
      '209727': {'lat': '26.9593', 'name': 'Kushum Khor,Kannauj,Uttar Pradesh', 'lon': '79.9472'},
      '635117': {'lat': '12.7104', 'name': 'Chembarasanapalli,Krishnagiri,Tamil Nadu', 'lon': '78.0252'},
      '502246': {'lat': '16.2881', 'name': 'Kuppanagar,Medak,Telangana', 'lon': '78.1353'},
      '413518': {'lat': '18.1891', 'name': 'Shelgaon,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '413519': {'lat': '18.6896', 'name': 'Chavani Hipperga,Latur,Maharashtra', 'lon': '76.4835'},
      '635115': {'lat': '12.5807', 'name': 'Ramapuram (Dharmapuri),Krishnagiri,Tamil Nadu', 'lon': '78.1221'},
      '483440': {'lat': '23.2502', 'name': 'Bandhi,Jabalpur,Madhya Pradesh', 'lon': '80.0479'},
      '711303': {'lat': '22.7896', 'name': 'Samta,Howrah,West Bengal', 'lon': '87.9004'},
      '413510': {'lat': '18.1891', 'name': 'Naigaon,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '413511': {'lat': '18.3963', 'name': 'Tawarajkheda,Osmanabad,Maharashtra', 'lon': '76.452'},
      '413512': {'lat': '18.5727', 'name': 'Latur H.O,Latur,Maharashtra', 'lon': '76.5908'},
      '413513': {'lat': '18.8192', 'name': 'Hipalner,Latur,Maharashtra', 'lon': '76.4552'},
      '413514': {'lat': '20.0648', 'name': 'Sawargaon Thot,Osmanabad,Maharashtra', 'lon': '75.4844'},
      '413515': {'lat': '18.3737', 'name': 'Hipperga Kajala,Latur,Maharashtra', 'lon': '76.6492'},
      '413516': {'lat': '18.0504', 'name': 'Kini Thot,Osmanabad,Maharashtra', 'lon': '76.4629'},
      '413517': {'lat': '18.1891', 'name': 'Malkapur,Osmanabad,Maharashtra', 'lon': '76.0388'},
      '742165': {'lat': '24.5272', 'name': 'Balarampore Colony,Murshidabad,West Bengal', 'lon': '87.9291'},
      '611111': {'lat': '10.6833', 'name': 'Vailankanni West,Nagapattinam,Tamil Nadu', 'lon': '79.8333'},
      '711306': {'lat': '22.7759', 'name': 'Kulgachia,Howrah,West Bengal', 'lon': '88.0532'},
      '689533': {'lat': '9.3333', 'name': 'Aranmula,Pathanamthitta,Kerala', 'lon': '76.6833'},
      '689532': {'lat': '9.0032', 'name': 'Kurichimuttom,Pathanamthitta,Kerala', 'lon': '76.7674'},
      '689531': {'lat': '9.2841', 'name': 'Koipuram,Pathanamthitta,Kerala', 'lon': '76.929'},
      '613702': {'lat': '10.8656', 'name': 'Attikkadai,Tiruvarur,Tamil Nadu', 'lon': '79.4781'},
      '396570': {'lat': '22.8958', 'name': 'Kharoli,Navsari,Gujarat', 'lon': '73.2954'},
      '423603': {'lat': '20.1318', 'name': 'Karanji Bk,Ahmed Nagar,Maharashtra', 'lon': '76.7784'},
      '335805': {'lat': '28.7846', 'name': 'Thethar,Ganganagar,Rajasthan', 'lon': '74.0075'},
      '335804': {'lat': '28.8981', 'name': 'Suratgarh name,Ganganagar,Rajasthan', 'lon': '74.3155'},
      '335803': {'lat': '28.8501', 'name': 'Ahmedpura,Hanumangarh,Rajasthan', 'lon': '74.0008'},
      '335802': {'lat': '29.6297', 'name': 'Gurusar Modia,Hanumangarh,Rajasthan', 'lon': '74.0534'},
      '335801': {'lat': '29.51', 'name': 'Kamana,Hanumangarh,Rajasthan', 'lon': '74.2438'},
      '423602': {'lat': '20.155', 'name': 'Malegaon Thadi,Ahmed Nagar,Maharashtra', 'lon': '74.1741'},
      '741167': {'lat': '23.4827', 'name': 'Bhimpur,Nadia,West Bengal', 'lon': '88.5215'},
      '741166': {'lat': '23.8101', 'name': 'Seemanagar,Nadia,West Bengal', 'lon': '88.1047'},
      '741165': {'lat': '23.1253', 'name': 'Fajil Nagar,Nadia,West Bengal', 'lon': '88.5469'},
      '515101': {'lat': '14.2819', 'name': 'Polepalli,Ananthapur,Andhra Pradesh', 'lon': '77.917'},
      '671552': {'lat': '12.6332', 'name': 'Vaninagar,Kasargod,Kerala', 'lon': '75.1308'},
      '423601': {'lat': '20.0357', 'name': 'Kopargaon Bet,Ahmed Nagar,Maharashtra', 'lon': '74.8647'},
      '741161': {'lat': '24.3562', 'name': 'Assannagar,Nadia,West Bengal', 'lon': '87.8848'},
      '671551': {'lat': '12.6036', 'name': 'Pallathadka,Kasargod,Kerala', 'lon': '75.0627'},
      '563161': {'lat': '13.5645', 'name': 'Yadaganahalli,Kolar,Karnataka', 'lon': '77.7362'},
      '231306': {'lat': '25.1522', 'name': 'Ramgarh Kiriyat,Mirzapur,Uttar Pradesh', 'lon': '82.8087'},
      '231305': {'lat': '25.2852', 'name': 'Sher Pur,Mirzapur,Uttar Pradesh', 'lon': '82.9166'},
      '563162': {'lat': '13.0276', 'name': 'Deshihalli Bangarpet,Kolar,Karnataka', 'lon': '78.1964'},
      '231303': {'lat': '25.1588', 'name': 'Dadwan Pakseda,Mirzapur,Uttar Pradesh', 'lon': '82.3948'},
      '231302': {'lat': '25.1933', 'name': 'Bhuelikhas,Mirzapur,Uttar Pradesh', 'lon': '82.9287'},
      '231301': {'lat': '25.0716', 'name': 'Ghatampur,Mirzapur,Uttar Pradesh', 'lon': '83.0318'},
      '483334': {'lat': '23.5335', 'name': 'Saroli Majhgawan,Jabalpur,Madhya Pradesh', 'lon': '80.2171'},
      '441113': {'lat': '21.3454', 'name': 'Pipla Dakbanglow,Nagpur,Maharashtra', 'lon': '79.0294'},
      '441112': {'lat': '21.4198', 'name': 'Mangsa,Nagpur,Maharashtra', 'lon': '78.883'},
      '441111': {'lat': '21.2677', 'name': 'Lonkhairi,Nagpur,Maharashtra', 'lon': '79.043'},
      '441110': {'lat': '21.0709', 'name': 'Kawdas,Nagpur,Maharashtra', 'lon': '78.9458'},
      '576122': {'lat': '13.25', 'name': 'Pangala,Udupi,Karnataka', 'lon': '74.75'},
      '576121': {'lat': '13.1396', 'name': 'Mundkur,Udupi,Karnataka', 'lon': '74.842'},
      '576120': {'lat': '13.2962', 'name': 'Manipura,Udupi,Karnataka', 'lon': '74.7926'},
      '383275': {'lat': '23.9841', 'name': 'Tandaliya,Sabarkantha,Gujarat', 'lon': '73.1262'},
      '457882': {'lat': '22.234', 'name': 'Mathna,Alirajpur,Madhya Pradesh', 'lon': '74.5639'},
      '383276': {'lat': '23.5441', 'name': 'Raigadh,Sabarkantha,Gujarat', 'lon': '73.2291'},
      '752064': {'lat': '20.772', 'name': 'Sanapadar,Khorda,Odisha', 'lon': '84.377'},
      '383270': {'lat': '23.599', 'name': 'Mota Baval,Sabarkantha,Gujarat', 'lon': '72.9978'},
      '591248': {'lat': '16.1195', 'name': 'Artal,Belagavi,Karnataka', 'lon': '74.823'},
      '203135': {'lat': '28.1494', 'name': 'Mangrouly,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.5585'},
      '423604': {'lat': '18.9333', 'name': 'Karwadi,Ahmed Nagar,Maharashtra', 'lon': '74.3167'},
      '416502': {'lat': '16.2229', 'name': 'Harali BK,Kolhapur,Maharashtra', 'lon': '74.3501'},
      '483330': {'lat': '23.6285', 'name': 'Gatakheda,Katni,Madhya Pradesh', 'lon': '80.019'},
      '307510': {'lat': '24.6916', 'name': 'Muderla,Sirohi,Rajasthan', 'lon': '72.8039'},
      '307511': {'lat': '24.6469', 'name': 'Udwaria,Sirohi,Rajasthan', 'lon': '72.6898'},
      '307512': {'lat': '24.7051', 'name': 'Dhan,Sirohi,Rajasthan', 'lon': '72.5574'},
      '416503': {'lat': '16.2095', 'name': 'Batkangale,Kolhapur,Maharashtra', 'lon': '74.157'},
      '307514': {'lat': '24.6223', 'name': 'Basan,Sirohi,Rajasthan', 'lon': '72.509'},
      '307515': {'lat': '24.8036', 'name': 'Sundhaparbat,Sirohi,Rajasthan', 'lon': '72.46'},
      '209208': {'lat': '26.7443', 'name': 'Makhauli,Kanpur Dehat,Uttar Pradesh', 'lon': '79.872'},
      '493221': {'lat': '21.498', 'name': 'Khairkhut,Raipur,Chattisgarh', 'lon': '81.643'},
      '416504': {'lat': '16.0562', 'name': 'Shippur T/F Nesari,Kolhapur,Maharashtra', 'lon': '74.3274'},
      '483332': {'lat': '23.7618', 'name': 'Khalari,Katni,Madhya Pradesh', 'lon': '80.345'},
      '493558': {'lat': '21.3636', 'name': 'Bichhiya,Mahasamund,Chattisgarh', 'lon': '83.0111'},
      '493559': {'lat': '21.5567', 'name': 'Gatadih,Raipur,Chattisgarh', 'lon': '82.9126'},
      '493228': {'lat': '21.5977', 'name': 'Konari,Raipur,Chattisgarh', 'lon': '82.228'},
      '788150': {'lat': '25.7351', 'name': 'Saidband PT II,Hailakandi,Assam', 'lon': '92.0907'},
      '495559': {'lat': '22.089', 'name': 'Bachhaud,Janjgir-champa,Chattisgarh', 'lon': '82.5473'},
      '416506': {'lat': '16.167', 'name': 'Bugadikatti,Kolhapur,Maharashtra', 'lon': '74.4682'},
      '125104': {'lat': '29.9038', 'name': 'Maujgarbh,Sirsa,Haryana', 'lon': '74.6887'},
      '283122': {'lat': '27.1276', 'name': 'Jaingara,Agra,Uttar Pradesh', 'lon': '77.9188'},
      '125106': {'lat': '29.6785', 'name': 'Manghera,Fatehabad,Haryana', 'lon': '75.6916'},
      '125101': {'lat': '29.7827', 'name': 'Chautala,Sirsa,Haryana', 'lon': '74.5238'},
      '125103': {'lat': '29.8091', 'name': 'Chakkan,Sirsa,Haryana', 'lon': '74.7261'},
      '125102': {'lat': '29.3814', 'name': 'Poharkan,Sirsa,Haryana', 'lon': '75.0372'},
      '767048': {'lat': '20.7371', 'name': 'Dumerpita,Balangir,Odisha', 'lon': '83.9932'},
      '263159': {'lat': '29.4128', 'name': 'Patkote,Nainital,Uttarakhand', 'lon': '79.3341'},
      '325009': {'lat': '25.5215', 'name': 'Dhipari,Kota,Rajasthan', 'lon': '76.23'},
      '263158': {'lat': '29.4587', 'name': 'Narain Swamy Ashram,Nainital,Uttarakhand', 'lon': '79.5489'},
      '767040': {'lat': '20.7366', 'name': 'Biripali,Balangir,Odisha', 'lon': '83.3283'},
      '767041': {'lat': '21.0136', 'name': 'Kutmunda,Balangir,Odisha', 'lon': '83.166'},
      '767042': {'lat': '20.8948', 'name': 'Bhatipara,Balangir,Odisha', 'lon': '83.5374'},
      '305601': {'lat': '26.2597', 'name': 'Bhatiyani,Ajmer,Rajasthan', 'lon': '74.8156'},
      '761208': {'lat': '18.8893', 'name': 'Devadala,Gajapati,Odisha', 'lon': '83.8939'},
      '767045': {'lat': '20.71275', 'name': 'Lingamani,Sonapur,Odisha', 'lon': '83.59048'},
      '767046': {'lat': '20.4464', 'name': 'Dhanpur,Balangir,Odisha', 'lon': '83.5776'},
      '450051': {'lat': '21.9513', 'name': 'Devalikalan,East Nimar,Madhya Pradesh', 'lon': '76.4787'},
      '400055': {'lat': '18.9292', 'name': 'Vakola,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400054': {'lat': '18.9292', 'name': 'Santacruz Central,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400057': {'lat': '18.9292', 'name': 'Vileparle Railway Station,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400056': {'lat': '18.9292', 'name': 'Vileparle(West),Mumbai,Maharashtra', 'lon': '72.8856'},
      '400051': {'lat': '19.0587', 'name': 'B.N. Bhavan,Mumbai,Maharashtra', 'lon': '72.8452'},
      '400050': {'lat': '18.9292', 'name': 'Bandra West,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400053': {'lat': '18.9292', 'name': 'Andheri H.O,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400052': {'lat': '18.7997', 'name': 'V.P. Road,Mumbai,Maharashtra', 'lon': '72.926'},
      '486335': {'lat': '24.6658', 'name': 'Dhawa  Tiwariyan,Rewa,Madhya Pradesh', 'lon': '82.0007'},
      '400059': {'lat': '18.9292', 'name': 'J.B. Nagar,Mumbai,Maharashtra', 'lon': '72.8856'},
      '400058': {'lat': '18.9292', 'name': 'H.M.P. School,Mumbai,Maharashtra', 'lon': '72.8856'},
      '403201': {'lat': '15.4294', 'name': 'Bambolim Camp,North Goa,Goa', 'lon': '73.9413'},
      '732150': {'lat': '25.426', 'name': 'Kharba,Malda,West Bengal', 'lon': '88.0689'},
      '518508': {'lat': '15.6966', 'name': 'Regadigudur,Kurnool,Andhra Pradesh', 'lon': '78.41'},
      '207302': {'lat': '27.5061', 'name': 'Rejua,Etah,Uttar Pradesh', 'lon': '78.3723'},
      '761209': {'lat': '18.9997', 'name': 'Khariaguda,Ganjam,Odisha', 'lon': '83.6329'},
      '796421': {'lat': '23.8623', 'name': 'Lengpui Airport,Aizawl,Mizoram', 'lon': '92.5797'},
      '518501': {'lat': '15.4758', 'name': 'Sanjeeva Nagar,Kurnool,Andhra Pradesh', 'lon': '78.5424'},
      '403203': {'lat': '15.4294', 'name': 'Pilar (North Goa),North Goa,Goa', 'lon': '73.9413'},
      '518502': {'lat': '15.3532', 'name': 'Deebaguntla,Kurnool,Andhra Pradesh', 'lon': '78.6159'},
      '790114': {'lat': '27.3143', 'name': 'Tippi,West Kameng,Arunachal Pradesh', 'lon': '92.3716'},
      '685503': {'lat': '9.683', 'name': 'Kottamala,Idukki,Kerala', 'lon': '76.9049'},
      '627764': {'lat': '9.3037', 'name': 'Rayagiri,Tirunelveli,Tamil Nadu', 'lon': '77.4475'},
      '685501': {'lat': '9.6351', 'name': 'Pasupara,Idukki,Kerala', 'lon': '76.9786'},
      '685507': {'lat': '9.8843', 'name': 'Kalthotty,Idukki,Kerala', 'lon': '76.8679'},
      '627760': {'lat': '9.2667', 'name': 'Athuvazhi,Tirunelveli,Tamil Nadu', 'lon': '77.4435'},
      '685505': {'lat': '9.8843', 'name': 'Karimkulam Chappath,Idukki,Kerala', 'lon': '76.8679'},
      '685508': {'lat': '9.8843', 'name': 'Kattappana H.O,Idukki,Kerala', 'lon': '76.8679'},
      '685509': {'lat': '9.9969', 'name': 'Periyar Lake,Idukki,Kerala', 'lon': '76.831'},
      '505404': {'lat': '18.4337', 'name': 'Chekode,Karim Nagar,Telangana', 'lon': '79.2586'},
      '403204': {'lat': '15.4294', 'name': 'St. Lourence,North Goa,Goa', 'lon': '73.9413'},
      '723152': {'lat': '23.2075', 'name': 'Pathardih,Purulia,West Bengal', 'lon': '85.98'},
      '306707': {'lat': '25.6085', 'name': 'Padarla,Pali,Rajasthan', 'lon': '73.159'},
      '723151': {'lat': '23.2945', 'name': 'Tatari,Purulia,West Bengal', 'lon': '87.0582'},
      '521457': {'lat': '16.5768', 'name': 'Jayanthipuram,Krishna,Andhra Pradesh', 'lon': '81.0236'},
      '521456': {'lat': '16.5768', 'name': 'Ibrahimpatnam (Krishna),Krishna,Andhra Pradesh', 'lon': '81.0236'},
      '723154': {'lat': '23.2752', 'name': 'Hensla,Purulia,West Bengal', 'lon': '86.1983'},
      '723155': {'lat': '23.5269', 'name': 'Bhawridih,Purulia,West Bengal', 'lon': '86.5137'},
      '742122': {'lat': '24.2273', 'name': 'Singheswari Gouripur,Murshidabad,West Bengal', 'lon': '88.2581'},
      '770044': {'lat': '21.6821', 'name': 'Ruguda,Sundergarh,Odisha', 'lon': '85.3284'},
      '742121': {'lat': '24.2692', 'name': 'Gangadhari,Murshidabad,West Bengal', 'lon': '88.0489'},
      '503218': {'lat': '18.6848', 'name': 'Kissannagar,Nizamabad,Telangana', 'lon': '78.4579'},
      '626141': {'lat': '9.4508', 'name': 'Appayanaickenpatti,Virudhunagar,Tamil Nadu', 'lon': '77.6358'},
      '626140': {'lat': '9.4694', 'name': 'Guhanparai,Virudhunagar,Tamil Nadu', 'lon': '77.9114'},
      '621110': {'lat': '11.4029', 'name': 'Periyammapalayam,Perambalur,Tamil Nadu', 'lon': '78.6831'},
      '626142': {'lat': '9.1518', 'name': 'Terkuvenganllur,Virudhunagar,Tamil Nadu', 'lon': '77.7436'},
      '283121': {'lat': '26.9597', 'name': 'Rasoolpur,Agra,Uttar Pradesh', 'lon': '77.8586'},
      '799022': {'lat': '23.6622', 'name': 'Tripura University,West Tripura,Tripura', 'lon': '91.3881'},
      '524366': {'lat': '14.546', 'name': 'Yellapalem,Nellore,Andhra Pradesh', 'lon': '79.9886'},
      '505415': {'lat': '18.5687', 'name': 'Choppadandi,Karim Nagar,Telangana', 'lon': '79.0611'},
      '263664': {'lat': '29.7664', 'name': 'Deolikhet,Almora,Uttarakhand', 'lon': '79.3381'},
      '799046': {'lat': '23.6622', 'name': 'NIT Agartala,West Tripura,Tripura', 'lon': '91.3881'},
      '306704': {'lat': '25.2483', 'name': 'Dudapura,Pali,Rajasthan', 'lon': '73.5206'},
      '534338': {'lat': '16.6222', 'name': 'Kommuchikkala,West Godavari,Andhra Pradesh', 'lon': '80.8847'},
      '246125': {'lat': '29.9308', 'name': 'Rampur Chatti,Pauri Garhwal,Uttarakhand', 'lon': '78.6816'},
      '208006': {'lat': '26.5167', 'name': 'Govind Nagar (Kanpur Nagar),Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '362530': {'lat': '20.9432', 'name': 'Khapat,Junagadh,Gujarat', 'lon': '71.0823'},
      '534330': {'lat': '16.5501', 'name': 'Khandavalli,West Godavari,Andhra Pradesh', 'lon': '80.4591'},
      '534331': {'lat': '16.7663', 'name': 'Teeparru,West Godavari,Andhra Pradesh', 'lon': '81.7359'},
      '679564': {'lat': '10.6789', 'name': 'Andathode,Thrissur,Kerala', 'lon': '75.9681'},
      '679563': {'lat': '10.6392', 'name': 'Nhamanghat,Thrissur,Kerala', 'lon': '76.0157'},
      '679562': {'lat': '10.6521', 'name': 'Vadakkekad,Thrissur,Kerala', 'lon': '75.9951'},
      '679561': {'lat': '10.6567', 'name': 'Punnayurkulam,Thrissur,Kerala', 'lon': '75.993'},
      '626108': {'lat': '8.3031', 'name': 'Kumarasamy Raja Nagar,Virudhunagar,Tamil Nadu', 'lon': '77.4753'},
      '621117': {'lat': '11.5174', 'name': 'Pillankulam,Perambalur,Tamil Nadu', 'lon': '78.983'},
      '364270': {'lat': '21.9343', 'name': 'Ankolali,Bhavnagar,Gujarat', 'lon': '72.2501'},
      '509320': {'lat': '17.0076', 'name': 'Urkonda,Mahabub Nagar,Telangana', 'lon': '78.2684'},
      '627452': {'lat': '8.6736', 'name': 'Melaseval,Tirunelveli,Tamil Nadu', 'lon': '77.6112'},
      '364275': {'lat': '22.1061', 'name': 'Ganeshgadh,Bhavnagar,Gujarat', 'lon': '72.8802'},
      '628653': {'lat': '8.4205', 'name': 'Polayarpuram,Tuticorin,Tamil Nadu', 'lon': '77.948'},
      '509327': {'lat': '17.2085', 'name': 'Arkepally,Mahabub Nagar,Telangana', 'lon': '78.4762'},
      '627453': {'lat': '8.6726', 'name': 'Pattamadai,Tirunelveli,Tamil Nadu', 'lon': '77.5845'},
      '628656': {'lat': '8.4205', 'name': 'Puthantharuvai,Tuticorin,Tamil Nadu', 'lon': '77.948'},
      '509326': {'lat': '17.2085', 'name': 'Vatvarlapally,Mahabub Nagar,Telangana', 'lon': '78.4762'},
      '700146': {'lat': '22.4126', 'name': 'Kodalia,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '700147': {'lat': '22.4126', 'name': 'Subhas Gram,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '700144': {'lat': '22.4126', 'name': 'Baruipur H.O,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '700145': {'lat': '22.4126', 'name': 'Bamangachi,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '788098': {'lat': '24.8304', 'name': 'Uttar Sonpur,Cachar,Assam', 'lon': '93.0248'},
      '788099': {'lat': '24.6077', 'name': 'Maniarkhal,Cachar,Assam', 'lon': '92.964'},
      '700140': {'lat': '22.4126', 'name': 'Khagramuri,South 24 Parganas,West Bengal', 'lon': '88.3292'},
      '626104': {'lat': '9.6307', 'name': 'Kalkurichi,Virudhunagar,Tamil Nadu', 'lon': '78.0971'},
      '246127': {'lat': '29.8237', 'name': 'Fatehpur,Pauri Garhwal,Uttarakhand', 'lon': '78.6176'},
      '462003': {'lat': '25.0345', 'name': 'M.A.C.T.,Bhopal,Madhya Pradesh', 'lon': '81.703'},
      '246148': {'lat': '29.9403', 'name': 'Dhandkur,Pauri Garhwal,Uttarakhand', 'lon': '78.6724'},
      '242127': {'lat': '27.7097', 'name': 'Pilhua,Shahjahanpur,Uttar Pradesh', 'lon': '79.4213'},
      '621116': {'lat': '11.3879', 'name': 'Vembavur,Perambalur,Tamil Nadu', 'lon': '78.7841'},
      '700149': {'lat': '22.4191', 'name': 'Rajpur (South 24 Parganas),South 24 Parganas,West Bengal', 'lon': '88.4174'},
      '785001': {'lat': '26.7167', 'name': 'Garali,Jorhat,Assam', 'lon': '94.2333'},
      '626106': {'lat': '9.6614', 'name': 'Chatrapuliyankulam,Virudhunagar,Tamil Nadu', 'lon': '78.1599'},
      '785004': {'lat': '26.7167', 'name': 'Pokamura,Jorhat,Assam', 'lon': '94.2333'},
      '205267': {'lat': '27.3177', 'name': 'Jagatpur B,Mainpuri,Uttar Pradesh', 'lon': '79.118'},
      '785006': {'lat': '26.7167', 'name': 'Tilikiaam,Jorhat,Assam', 'lon': '94.2333'},
      '785007': {'lat': '26.7167', 'name': 'Hatigarh,Jorhat,Assam', 'lon': '94.2333'},
      '785008': {'lat': '26.7167', 'name': 'Lichubari,Jorhat,Assam', 'lon': '94.2333'},
      '785009': {'lat': '26.7167', 'name': 'Na Ali Dhekiajuli,Jorhat,Assam', 'lon': '94.2333'},
      '276122': {'lat': '26.2292', 'name': 'Karkhia Rutam Sarai,Azamgarh,Uttar Pradesh', 'lon': '83.1756'},
      '768027': {'lat': '21.3849', 'name': 'Tope,Bargarh,Odisha', 'lon': '83.7757'},
      '768026': {'lat': '21.2854', 'name': 'Chiplima,Sambalpur,Odisha', 'lon': '83.7185'},
      '768025': {'lat': '21.458', 'name': 'C.A. Chiplima,Sambalpur,Odisha', 'lon': '84.3546'},
      '737135': {'lat': '27.289716', 'name': 'Samlick-marchak,East Sikkim,Sikkim', 'lon': '88.588669'},
      '251301': {'lat': '29.4371', 'name': 'Nagala Pithora,Muzaffarnagar,Uttar Pradesh', 'lon': '77.5913'},
      '232340': {'lat': '25.488', 'name': 'Tajpur Majnha,Ghazipur,Uttar Pradesh', 'lon': '83.3931'},
      '768020': {'lat': '21.2854', 'name': 'Jagruti Vihar Burla,Sambalpur,Odisha', 'lon': '83.7185'},
      '737139': {'lat': '27.59275', 'name': 'Ben,South Sikkim,Sikkim', 'lon': '88.48882'},
      '251309': {'lat': '29.2128', 'name': 'Loi Sarai,Muzaffarnagar,Uttar Pradesh', 'lon': '77.5058'},
      '251308': {'lat': '29.4672', 'name': 'Behra Thru,Muzaffarnagar,Uttar Pradesh', 'lon': '77.8658'},
      '768029': {'lat': '21.19', 'name': 'Barpali,Bargarh,Odisha', 'lon': '83.5872'},
      '768028': {'lat': '21.2854', 'name': 'Bargarh Bazar,Bargarh,Odisha', 'lon': '83.7185'},
      '752079': {'lat': '20.2131', 'name': 'Khuntabandha,Nayagarh,Odisha', 'lon': '84.9994'},
      '752078': {'lat': '20.3674', 'name': 'Dhanchangada,Nayagarh,Odisha', 'lon': '85.18'},
      '326034': {'lat': '24.8479', 'name': 'Kharpakalan,Jhalawar,Rajasthan', 'lon': '75.9442'},
      '621112': {'lat': '10.9239', 'name': 'Samayapuram,Tiruchirappalli,Tamil Nadu', 'lon': '78.7435'},
      '752070': {'lat': '20.0665', 'name': 'Gotisahi,Nayagarh,Odisha', 'lon': '85.0897'},
      '147111': {'lat': '30.1736', 'name': 'Dhudan Sadhan,Patiala,Punjab', 'lon': '76.4872'},
      '628615': {'lat': '8.6405', 'name': 'Karungulam,Tuticorin,Tamil Nadu', 'lon': '77.8517'},
      '752077': {'lat': '20.4565', 'name': 'Singhapada,Nayagarh,Odisha', 'lon': '85.22'},
      '517504': {'lat': '14.9764', 'name': 'Tirumala North,Chittoor,Andhra Pradesh', 'lon': '79.5233'},
      '517505': {'lat': '13.8729', 'name': 'C.Gollapalle,Chittoor,Andhra Pradesh', 'lon': '77.9922'},
      '517506': {'lat': '12.9083', 'name': 'Settipalli,Chittoor,Andhra Pradesh', 'lon': '78.3541'},
      '517507': {'lat': '13.3724', 'name': 'A.P.H.B.Colony,Chittoor,Andhra Pradesh', 'lon': '78.5511'},
      '764027': {'lat': '19.0384', 'name': 'Kabirbadi,Koraput,Odisha', 'lon': '83.2746'},
      '517501': {'lat': '16.9362', 'name': 'Tirupati H.O,Chittoor,Andhra Pradesh', 'lon': '81.2577'},
      '517502': {'lat': '14.9764', 'name': 'Thummalagunta,Chittoor,Andhra Pradesh', 'lon': '79.5233'},
      '517503': {'lat': '14.9764', 'name': 'Tiruchanoor,Chittoor,Andhra Pradesh', 'lon': '79.5233'},
      '695525': {'lat': '8.3146', 'name': 'Kanjampazhanji,Thiruvananthapuram,Kerala', 'lon': '77.0686'},
      '695524': {'lat': '9.1681', 'name': 'Kanjiramkulam,Thiruvananthapuram,Kerala', 'lon': '76.6753'},
      '695527': {'lat': '8.3893', 'name': 'Kovalam (Thiruvananthapuram),Thiruvananthapuram,Kerala', 'lon': '76.9788'},
      '695526': {'lat': '9.1681', 'name': 'Kazhavur,Thiruvananthapuram,Kerala', 'lon': '76.6753'},
      '695521': {'lat': '8.6085', 'name': 'Kottapuram,Thiruvananthapuram,Kerala', 'lon': '77.015'},
      '441502': {'lat': '21.2688', 'name': 'Pipla Kinkhede,Nagpur,Maharashtra', 'lon': '78.8076'},
      '695523': {'lat': '9.1681', 'name': 'Venganur,Thiruvananthapuram,Kerala', 'lon': '76.6753'},
      '695522': {'lat': '9.1681', 'name': 'Cgo Complex Poonkulam,Thiruvananthapuram,Kerala', 'lon': '76.6753'},
      '502267': {'lat': '18.1482', 'name': 'Sikindlapur,Medak,Telangana', 'lon': '78.9436'},
      '389350': {'lat': '23.2233', 'name': 'Vintoz,Panch Mahals,Gujarat', 'lon': '73.1127'},
      '326039': {'lat': '24.434', 'name': 'Sarkhandia,Jhalawar,Rajasthan', 'lon': '76.4589'},
      '486889': {'lat': '24.2047', 'name': 'Singrauli Colliery,Singrauli,Madhya Pradesh', 'lon': '82.7041'},
      '855101': {'lat': '26.2217', 'name': 'Bharadah,Kishanganj,Bihar', 'lon': '87.669'},
      '301604': {'lat': '26.7857', 'name': 'Semla Khurd,Alwar,Rajasthan', 'lon': '76.8186'},
      '621114': {'lat': '11.0885', 'name': 'T.Kalathur,Perambalur,Tamil Nadu', 'lon': '78.716'},
      '855105': {'lat': '25.6868', 'name': 'Parveli,Katihar,Bihar', 'lon': '87.6955'},
      '855107': {'lat': '26.0445', 'name': 'Toesa,Kishanganj,Bihar', 'lon': '87.8441'},
      '855106': {'lat': '26.2956', 'name': 'Galgalia,Kishanganj,Bihar', 'lon': '87.9353'},
      '263678': {'lat': '29.5646', 'name': 'Shitalakhet,Almora,Uttarakhand', 'lon': '79.5275'},
      '415001': {'lat': '19.2389', 'name': 'Satara H.O,Satara,Maharashtra', 'lon': '75.3869'},
      '415002': {'lat': '19.2389', 'name': 'Bhatmarli,Satara,Maharashtra', 'lon': '75.3869'},
      '415003': {'lat': '19.2389', 'name': 'Kshatra Mahuli,Satara,Maharashtra', 'lon': '75.3869'},
      '415004': {'lat': '19.2389', 'name': 'Mouje Tasgaon,Satara,Maharashtra', 'lon': '75.3869'},
      '388550': {'lat': '22.6045', 'name': 'Kathana RS,Anand,Gujarat', 'lon': '72.992'},
      '263676': {'lat': '29.685', 'name': 'Amdhar,Almora,Uttarakhand', 'lon': '79.1655'},
      '205264': {'lat': '27.0271', 'name': 'Nagla Dik,Mainpuri,Uttar Pradesh', 'lon': '78.9238'},
      '734104': {'lat': '27.1256', 'name': 'Bannersberg,Darjiling,West Bengal', 'lon': '88.2896'},
      '621118': {'lat': '11.35', 'name': 'Siruvayalur,Perambalur,Tamil Nadu', 'lon': '78.8788'},
      '394680': {'lat': '21.5548', 'name': 'Ukai Dam,Tapi,Gujarat', 'lon': '73.1893'},
      '416209': {'lat': '17.7197', 'name': 'Madilage BK,Kolhapur,Maharashtra', 'lon': '74.6581'},
      '522318': {'lat': '16.079', 'name': 'Modukur,Guntur,Andhra Pradesh', 'lon': '80.568'},
      '416207': {'lat': '20.0535', 'name': 'Mhalunge,Kolhapur,Maharashtra', 'lon': '74.2457'},
      '382732': {'lat': '24.0963', 'name': 'Jornang,Mahesana,Gujarat', 'lon': '72.636'},
      '416205': {'lat': '16.6914', 'name': 'Mhasurli,Kolhapur,Maharashtra', 'lon': '74.124'},
      '382730': {'lat': '23.4387', 'name': 'Meu,Mahesana,Gujarat', 'lon': '72.5126'},
      '416203': {'lat': '17.8906', 'name': 'Randivewadi,Kolhapur,Maharashtra', 'lon': '74.5193'},
      '416202': {'lat': '17.8906', 'name': 'Sangavade,Kolhapur,Maharashtra', 'lon': '74.5193'},
      '382735': {'lat': '23.8579', 'name': 'Amja,Gandhi Nagar,Gujarat', 'lon': '72.4119'},
      '625604': {'lat': '10.1197', 'name': 'Endapuli,Theni,Tamil Nadu', 'lon': '77.6089'},
      '486881': {'lat': '24.2916', 'name': 'Amo,Singrauli,Madhya Pradesh', 'lon': '82.3776'},
      '322029': {'lat': '26.0348', 'name': 'Bhadlav,Sawai Madhopur,Rajasthan', 'lon': '74.3677'},
      '628618': {'lat': '8.545', 'name': 'Pattakarai,Tuticorin,Tamil Nadu', 'lon': '77.8664'},
      '486550': {'lat': '24.4602', 'name': 'Neega,Rewa,Madhya Pradesh', 'lon': '81.3386'},
      '322028': {'lat': '26.3259', 'name': 'Behted,Sawai Madhopur,Rajasthan', 'lon': '76.5215'},
      '174036': {'lat': '31.3855', 'name': 'Harnora,Bilaspur (HP),Himachal Pradesh', 'lon': '76.8750'},
      '174035': {'lat': '31.31525', 'name': 'Malraon,Bilaspur (HP),Himachal Pradesh', 'lon': '76.60165'},
      '174034': {'lat': '31.3476012', 'name': 'Dahad,Bilaspur (HP),Himachal Pradesh', 'lon': '76.6730298'},
      '174033': {'lat': '31.31525', 'name': 'Suin Surhar,Bilaspur (HP),Himachal Pradesh', 'lon': '76.80755'},
      '174032': {'lat': '31.25835', 'name': 'Sikroha,Bilaspur (HP),Himachal Pradesh', 'lon': '76.84277'},
      '174031': {'lat': '31.3904828', 'name': 'Ser,Bilaspur (HP),Himachal Pradesh', 'lon': '76.6771859'},
      '174030': {'lat': '31.4232981', 'name': 'Daslehra,Bilaspur (HP),Himachal Pradesh', 'lon': '76.5531501'},
      '486884': {'lat': '24.2667', 'name': 'Nigahi Project,Sidhi,Madhya Pradesh', 'lon': '82.1937'},
      '761201': {'lat': '19.0267', 'name': 'Ranipeta,Gajapati,Odisha', 'lon': '83.4609'},
      '494332': {'lat': '20.4143', 'name': 'Chawad,Kanker,Chattisgarh', 'lon': '81.3901'},
      '486885': {'lat': '24.2667', 'name': 'Vindhyanagar,Singrauli,Madhya Pradesh', 'lon': '82.1937'},
      '171009': {'lat': '31.0769', 'name': 'Rajhana,Shimla,Himachal Pradesh', 'lon': '77.1856'},
      '171008': {'lat': '31.1', 'name': 'Jutogh,Shimla,Himachal Pradesh', 'lon': '77.1167'},
      '322024': {'lat': '26.2503', 'name': 'Didwadi,Sawai Madhopur,Rajasthan', 'lon': '76.3527'},
      '171001': {'lat': '31.1167', 'name': 'Shimla G.P.O.,Shimla,Himachal Pradesh', 'lon': '77.1684'},
      '171003': {'lat': '31.1192', 'name': 'Bhont,Shimla,Himachal Pradesh', 'lon': '77.1702'},
      '171002': {'lat': '31.1167', 'name': 'Shimla East,Shimla,Himachal Pradesh', 'lon': '77.1684'},
      '171005': {'lat': '31.1152', 'name': 'Distt. Court Chakkar,Shimla,Himachal Pradesh', 'lon': '77.2781'},
      '171004': {'lat': '31.1167', 'name': 'Nabha Estate,Shimla,Himachal Pradesh', 'lon': '77.1684'},
      '171007': {'lat': '31.1669', 'name': 'Dewla,Shimla,Himachal Pradesh', 'lon': '77.1746'},
      '171006': {'lat': '31.0928', 'name': 'Malyana,Shimla,Himachal Pradesh', 'lon': '77.1229'},
      '283207': {'lat': '27.3309', 'name': 'Pilakhatar,Firozabad,Uttar Pradesh', 'lon': '78.4797'},
      '322026': {'lat': '25.1907', 'name': 'Dangarwada,Sawai Madhopur,Rajasthan', 'lon': '74.7742'},
      '382840': {'lat': '23.609', 'name': 'Mandali,Mahesana,Gujarat', 'lon': '72.4619'},
      '208001': {'lat': '26.5442', 'name': 'Philkhana,Kanpur Nagar,Uttar Pradesh', 'lon': '80.1097'},
      '843302': {'lat': '26.0372', 'name': 'B. Piprarhi,Sitamarhi,Bihar', 'lon': '85.6152'},
      '382845': {'lat': '23.555', 'name': 'Mansa Bazar,Gandhi Nagar,Gujarat', 'lon': '72.8118'},
      '624301': {'lat': '10.2848', 'name': 'Chinnalapatti,Dindigul,Tamil Nadu', 'lon': '77.9233'},
      '624303': {'lat': '10.0328', 'name': 'Veerakkal,Dindigul,Tamil Nadu', 'lon': '77.891'},
      '624302': {'lat': '10.2771', 'name': 'A.Nadupatti,Dindigul,Tamil Nadu', 'lon': '77.9195'},
      '184210': {'lat': '32.6516', 'name': 'Nagrota Gujroo,Jammu,Jammu & Kashmir', 'lon': '75.4472'},
      '522317': {'lat': '15.9201', 'name': 'Stuvartpuram,Guntur,Andhra Pradesh', 'lon': '80.4152'},
      '624307': {'lat': '10.3048', 'name': 'Vellodu,Dindigul,Tamil Nadu', 'lon': '77.9552'},
      '624306': {'lat': '10.3806', 'name': 'Sengurichi,Dindigul,Tamil Nadu', 'lon': '78.0537'},
      '624308': {'lat': '10.2505', 'name': 'T.Vadugapatti,Dindigul,Tamil Nadu', 'lon': '78.1202'},
      '522316': {'lat': '16.0928', 'name': 'Munipalli,Guntur,Andhra Pradesh', 'lon': '80.353'},
      '246444': {'lat': '29.8405', 'name': 'Ming Gadhera,Chamoli,Uttarakhand', 'lon': '79.5332'},
      '450221': {'lat': '21.5446', 'name': 'Dabhiyakheda,Burhanpur,Madhya Pradesh', 'lon': '76.395'},
      '676319': {'lat': '11.0698', 'name': 'Chettipadi,Malappuram,Kerala', 'lon': '75.9093'},
      '335051': {'lat': '29.3379', 'name': '11ps,Ganganagar,Rajasthan', 'lon': '73.5209'},
      '786692': {'lat': '27.3147', 'name': 'Anandapara,Dibrugarh,Assam', 'lon': '95.3032'},
      '481998': {'lat': '22.5405', 'name': 'Suktara,Mandla,Madhya Pradesh', 'lon': '80.4457'},
      '481996': {'lat': '22.5085', 'name': 'Mangali,Mandla,Madhya Pradesh', 'lon': '80.7419'},
      '676317': {'lat': '11.0698', 'name': 'Velimukku,Malappuram,Kerala', 'lon': '75.9093'},
      '321202': {'lat': '27.5079', 'name': 'Kasot,Bharatpur,Rajasthan', 'lon': '77.0827'},
      '676311': {'lat': '11.0698', 'name': 'Moonniyur South,Malappuram,Kerala', 'lon': '75.9093'},
      '224151': {'lat': '26.6415', 'name': 'Pratappur Chamurkha,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4855'},
      '224152': {'lat': '26.5009', 'name': 'Dharamganj,Ambedkar Nagar,Uttar Pradesh', 'lon': '82.4052'},
      '676312': {'lat': '11.0698', 'name': 'Ariyallur,Malappuram,Kerala', 'lon': '75.9093'},
      '712501': {'lat': '22.7283', 'name': 'Milangarh,Hooghly,West Bengal', 'lon': '88.435'},
      '712503': {'lat': '22.9867', 'name': 'Btps (Hooghly),Hooghly,West Bengal', 'lon': '88.3987'},
      '712502': {'lat': '22.9539', 'name': 'Shibpur Bansberia,Hooghly,West Bengal', 'lon': '88.401'},
      '496661': {'lat': '21.9499', 'name': 'Mura,Raigarh,Chattisgarh', 'lon': '83.2162'},
      '712504': {'lat': '22.8896', 'name': 'Chandrahati,Hooghly,West Bengal', 'lon': '88.4116'},
      '815301': {'lat': '24.1929', 'name': 'Giridih Bazar,Giridh,Jharkhand', 'lon': '86.3046'},
      '143116': {'lat': '31.6962', 'name': 'Mallowal,Amritsar,Punjab', 'lon': '75.0091'},
      '743166': {'lat': '22.8982', 'name': 'Ramghat (North 24 Parganas),North 24 Parganas,West Bengal', 'lon': '88.4329'},
      '283205': {'lat': '27.3685', 'name': 'Collectorate -firozabad,Firozabad,Uttar Pradesh', 'lon': '78.3544'},
      '813222': {'lat': '25.2886', 'name': 'Ekchari Khawas,Bhagalpur,Bihar', 'lon': '87.0405'},
      '813221': {'lat': '25.1344', 'name': 'Launaparsa,Munger,Bihar', 'lon': '86.4437'},
      '263643': {'lat': '29.6457', 'name': 'Darmar Tikhun,Almora,Uttarakhand', 'lon': '79.5067'},
      '248142': {'lat': '30.4249', 'name': 'Wht-II,Dehradun,Uttarakhand', 'lon': '77.7698'},
      '464570': {'lat': '23.6389', 'name': 'Markhedagulab,Raisen,Madhya Pradesh', 'lon': '78.3207'},
      '508202': {'lat': '16.597', 'name': 'Nellikal,Nalgonda,Telangana', 'lon': '79.3122'},
      '508201': {'lat': '17.4399', 'name': 'Keethavarigudem,Nalgonda,Telangana', 'lon': '80.1766'},
      '508207': {'lat': '17.1563', 'name': 'Miryalguda H.O,Nalgonda,Telangana', 'lon': '79.5603'},
      '143117': {'lat': '31.3964', 'name': 'Bharowal,Tarn Taran,Punjab', 'lon': '75.0725'},
      '508205': {'lat': '17.2101', 'name': 'Chandanapally,Nalgonda,Telangana', 'lon': '79.2979'},
      '734103': {'lat': '26.9135', 'name': 'Rajbari,Darjiling,West Bengal', 'lon': '88.3947'},
      '383460': {'lat': '23.7294', 'name': 'Sarsav,Sabarkantha,Gujarat', 'lon': '73.134'},
      '331802': {'lat': '28.0644', 'name': 'Dhandheru Bhamuwan,Churu,Rajasthan', 'lon': '74.4418'},
      '383462': {'lat': '23.9351', 'name': 'Dantod,Sabarkantha,Gujarat', 'lon': '73.3727'},
      '508208': {'lat': '17.0678', 'name': 'Rudraram,Nalgonda,Telangana', 'lon': '79.2887'},
      '283204': {'lat': '27.27', 'name': 'Ummargarh,Firozabad,Uttar Pradesh', 'lon': '78.4667'},
      '815359': {'lat': '24.2591', 'name': 'Laykapur,Jamtara,Jharkhand', 'lon': '86.9757'},
      '768115': {'lat': '21.399', 'name': 'Mahule,Bargarh,Odisha', 'lon': '84.3305'},
      '522341': {'lat': '16.7645', 'name': 'Kondamudi,Guntur,Andhra Pradesh', 'lon': '81.5637'},
      '462008': {'lat': '25.0345', 'name': 'Jahangirabad (Bhopal),Bhopal,Madhya Pradesh', 'lon': '81.703'},
      '680022': {'lat': '10.4924', 'name': 'Thiruvambady TSR,Thrissur,Kerala', 'lon': '76.2287'},
      '680020': {'lat': '10.4924', 'name': 'Thrissur name,Thrissur,Kerala', 'lon': '76.2287'},
      '680021': {'lat': '10.4924', 'name': 'Thrissur R S,Thrissur,Kerala', 'lon': '76.2287'},
      '680026': {'lat': '10.4924', 'name': 'Chiyyaram,Thrissur,Kerala', 'lon': '76.2287'},
      '680027': {'lat': '10.4924', 'name': 'Palissery,Thrissur,Kerala', 'lon': '76.2287'},
      '521330': {'lat': '16.5285', 'name': 'Siddantham,Krishna,Andhra Pradesh', 'lon': '80.4955'},
      '521331': {'lat': '16.3703', 'name': 'Kongamcherla,Krishna,Andhra Pradesh', 'lon': '80.456'},
      '680028': {'lat': '10.4924', 'name': 'Kattilapoovam,Thrissur,Kerala', 'lon': '76.2287'},
      '805110': {'lat': '25.0168', 'name': 'Nawadha H.O,Nawada,Bihar', 'lon': '85.6328'},
      '733140': {'lat': '26.35872', 'name': 'Deor,South Dinajpur,West Bengal', 'lon': '88.42979'},
      '733141': {'lat': '25.4470266', 'name': 'Safanagar,South Dinajpur,West Bengal', 'lon': '88.7132949'},
      '332304': {'lat': '28.0085', 'name': 'Sekhisar,Sikar,Rajasthan', 'lon': '74.8372'},
      '733143': {'lat': '24.4267', 'name': 'Bhagnail,North Dinajpur,West Bengal', 'lon': '88.0795'},
      '733145': {'lat': '25.2579', 'name': 'Thakurpurahat,South Dinajpur,West Bengal', 'lon': '88.7679'},
      '110066': {'lat': '28.5329', 'name': 'R K Puram (Main),South West Delhi,Delhi', 'lon': '77.2004'},
      '110067': {'lat': '28.5553', 'name': 'JNU New Campus,South West Delhi,Delhi', 'lon': '77.1743'},
      '110064': {'lat': '28.5329', 'name': 'Mayapuri Shopping Centre,South West Delhi,Delhi', 'lon': '77.2004'},
      '110065': {'lat': '28.5619', 'name': 'East Of Kailash Phase-I,South Delhi,Delhi', 'lon': '77.2553'},
      '110062': {'lat': '28.508', 'name': 'Pushpa Bhawan,South Delhi,Delhi', 'lon': '77.2294'},
      '110063': {'lat': '28.5329', 'name': 'Madipur Slum Quarter,West Delhi,Delhi', 'lon': '77.2004'},
      '110060': {'lat': '28.5329', 'name': 'Rajender Nagar,Central Delhi,Delhi', 'lon': '77.2004'},
      '110061': {'lat': '28.5346', 'name': 'Shahbad Mohammadpur,South West Delhi,Delhi', 'lon': '77.0536'},
      '852221': {'lat': '25.8639', 'name': 'Kachra Karahia,Saharsa,Bihar', 'lon': '86.7246'},
      '852220': {'lat': '25.7956', 'name': 'Murli Chandwa,Madhepura,Bihar', 'lon': '86.9205'},
      '272123': {'lat': '26.7038', 'name': 'Deisand,Basti,Uttar Pradesh', 'lon': '82.7797'},
      '477449': {'lat': '25.9575', 'name': 'Gangepura,Bhind,Madhya Pradesh', 'lon': '78.5985'},
      '110068': {'lat': '28.5003', 'name': 'Ignou,South West Delhi,Delhi', 'lon': '77.2056'},
      '110069': {'lat': '28.5329', 'name': 'Union Public Service Commission,Central Delhi,Delhi', 'lon': '77.2004'},
      '688013': {'lat': '9.5046', 'name': 'Thondankulangara,Alappuzha,Kerala', 'lon': '76.3477'},
      '688012': {'lat': '9.5046', 'name': 'Alappuzha Bazar,Alappuzha,Kerala', 'lon': '76.3477'},
      '688011': {'lat': '9.5046', 'name': 'Alappuzha District Hospital,Alappuzha,Kerala', 'lon': '76.3477'},
      '623308': {'lat': '9.8661', 'name': 'Ooranikottai,Ramanathapuram,Tamil Nadu', 'lon': '78.936'},
      '503165': {'lat': '18.0777', 'name': 'Nyavanandi,Nizamabad,Telangana', 'lon': '78.7021'},
      '688014': {'lat': '9.5046', 'name': 'Punnapra North,Alappuzha,Kerala', 'lon': '76.3477'},
      '639004': {'lat': '11.019', 'name': 'Panjamadevi,Karur,Tamil Nadu', 'lon': '78.134'},
      '143112': {'lat': '31.5185', 'name': 'Uppal,Tarn Taran,Punjab', 'lon': '75.3236'},
      '283201': {'lat': '27.3321', 'name': 'Barhan,Agra,Uttar Pradesh', 'lon': '78.1909'},
      '272150': {'lat': '26.9575', 'name': 'Parsa  Damya,Basti,Uttar Pradesh', 'lon': '82.7984'},
      '208007': {'lat': '26.5167', 'name': 'Cambridge Road,Kanpur Nagar,Uttar Pradesh', 'lon': '80.3155'},
      '670014': {'lat': '11.9139', 'name': 'Chalad,Kannur,Kerala', 'lon': '75.3543'},
      '670017': {'lat': '11.9139', 'name': 'Kannur District Hospital,Kannur,Kerala', 'lon': '75.3543'},
      '670010': {'lat': '11.9152', 'name': 'Valapattanam,Kannur,Kerala', 'lon': '75.3476'},
      '670011': {'lat': '11.9126', 'name': 'Chirakk R.S.,Kannur,Kerala', 'lon': '75.361'},
      '670012': {'lat': '11.9139', 'name': 'Kannookara,Kannur,Kerala', 'lon': '75.3543'},
      '670013': {'lat': '11.9139', 'name': 'Burnacherry,Kannur,Kerala', 'lon': '75.3543'},
      '670018': {'lat': '11.9139', 'name': 'Thazhe Chovva,Kannur,Kerala', 'lon': '75.3543'},
      '507003': {'lat': '16.9277', 'name': 'Peddathanda,Khammam,Telangana', 'lon': '79.1687'},
      '683594': {'lat': '10.1039', 'name': 'Puthenvelikkara,Ernakulam,Kerala', 'lon': '76.4103'},
      '431542': {'lat': '19.7461', 'name': 'Warud Chakrapani,Hingoli,Maharashtra', 'lon': '77.085'},
      '431541': {'lat': '19.1443', 'name': 'Kekar Jawala,Parbhani,Maharashtra', 'lon': '76.5315'},
      '431540': {'lat': '19.5179', 'name': 'Sadegaon,Parbhani,Maharashtra', 'lon': '77.0393'},
      '232120': {'lat': '25.2812', 'name': 'Janso Ki Marai,Chandauli,Uttar Pradesh', 'lon': '83.147'},
      '733142': {'lat': '25.2612809', 'name': 'Bhikahar,South Dinajpur,West Bengal', 'lon': '88.4912315'},
      '741103': {'lat': '24.5009', 'name': 'Nadia Bishnupur,Nadia,West Bengal', 'lon': '88.1442'},
      '626612': {'lat': '9.5918', 'name': 'Pervelangudi,Virudhunagar,Tamil Nadu', 'lon': '78.3497'},
      '262725': {'lat': '27.4775', 'name': 'Dhusaru,Kheri,Uttar Pradesh', 'lon': '81.0888'},
      '623601': {'lat': '9.4653', 'name': 'Melakodumalur,Ramanathapuram,Tamil Nadu', 'lon': '78.4357'},
      '262727': {'lat': '27.8132', 'name': 'Khanjan Nagar,Kheri,Uttar Pradesh', 'lon': '80.6622'},
      '262726': {'lat': '26.6076', 'name': 'Badagaon,Kheri,Uttar Pradesh', 'lon': '82.4361'},
      '533577': {'lat': '17.1292', 'name': 'Janupalli,East Godavari,Andhra Pradesh', 'lon': '81.9064'},
      '262723': {'lat': '27.9403', 'name': 'Bhatpurwa Kallan,Kheri,Uttar Pradesh', 'lon': '81.0996'},
      '262722': {'lat': '27.7556', 'name': 'Lakhun,Kheri,Uttar Pradesh', 'lon': '81.3196'},
      '362235': {'lat': '22.8198', 'name': 'Shardagram,Junagadh,Gujarat', 'lon': '72.1464'},
      '231209': {'lat': '24.6418', 'name': 'Gurma Markundi,Sonbhadra,Uttar Pradesh', 'lon': '83.1354'},
      '362230': {'lat': '22.8198', 'name': 'Ghodadar,Junagadh,Gujarat', 'lon': '72.1464'},
      '140116': {'lat': '31.0351', 'name': 'Kotla Power House,Rupnagar,Punjab', 'lon': '76.3631'},
      '799155': {'lat': '23.2518', 'name': 'Barpathari,South Tripura,Tripura', 'lon': '91.4541'},
      '231207': {'lat': '24.4045', 'name': 'Padarachh,Sonbhadra,Uttar Pradesh', 'lon': '83.0971'},
      '494230': {'lat': '20.046', 'name': 'Badagaon,Bastar,Chattisgarh', 'lon': '81.4237'},
      '623605': {'lat': '9.5016', 'name': 'Valayapukulam,Ramanathapuram,Tamil Nadu', 'lon': '78.3629'},
      '473118': {'lat': '24.5546', 'name': 'Chachauda,Guna,Madhya Pradesh', 'lon': '77.2008'},
      '131101': {'lat': '29.1364', 'name': 'Ahulana(Gannur),Sonipat,Haryana', 'lon': '77.0297'},
      '231205': {'lat': '24.9825', 'name': 'Chatarvar,Sonbhadra,Uttar Pradesh', 'lon': '83.0879'},
      '131102': {'lat': '29.1578', 'name': 'Panchijatan,Sonipat,Haryana', 'lon': '76.8968'},
      '131103': {'lat': '28.8865', 'name': 'Mandaura,Sonipat,Haryana', 'lon': '76.9966'},
      '756028': {'lat': '21.8144', 'name': 'Nalabahar,Baleswar,Odisha', 'lon': '86.3745'},
      '577102': {'lat': '13.2568', 'name': 'Kuruvangi,Chikkamagaluru,Karnataka', 'lon': '75.8702'},
      '756029': {'lat': '21.496', 'name': 'Dandika,Baleswar,Odisha', 'lon': '86.1078'},
      '515591': {'lat': '13.9734', 'name': 'K.Brahmanapalli,Ananthapur,Andhra Pradesh', 'lon': '77.6016'},
      '209402': {'lat': '26.3801', 'name': 'Tilsahari,Kanpur Nagar,Uttar Pradesh', 'lon': '80.4141'},
      '562105': {'lat': '13.516', 'name': 'Kundlagurki,Chikkaballapur,Karnataka', 'lon': '77.8292'},
      '562106': {'lat': '12.713', 'name': 'Bestamaranahalli,Bengaluru,Karnataka', 'lon': '77.7247'},
      '585308': {'lat': '17.1746', 'name': 'Naduvinahalli,Kalaburagi,Karnataka', 'lon': '76.7708'},
      '480109': {'lat': '21.5973', 'name': 'Koparwarai,Chhindwara,Madhya Pradesh', 'lon': '78.6873'},
      '577101': {'lat': '13.2568', 'name': 'Avathi,Chikkamagaluru,Karnataka', 'lon': '75.8702'},
      '722148': {'lat': '23.019', 'name': 'Sindri-am,Bankura,West Bengal', 'lon': '86.3508'},
      '722149': {'lat': '23.3392', 'name': 'Peardoba,Bankura,West Bengal', 'lon': '87.1821'},
      '444301': {'lat': '20.1965', 'name': 'Karwand,Buldhana,Maharashtra', 'lon': '76.8221'},
      '722147': {'lat': '23.3392', 'name': 'Patpur,Bankura,West Bengal', 'lon': '87.1821'},
      '444303': {'lat': '20.811', 'name': 'Pimpri Gaoli,Buldhana,Maharashtra', 'lon': '76.8346'},
      '444302': {'lat': '20.1307', 'name': 'Vyala,Akola,Maharashtra', 'lon': '77.0823'},
      '722142': {'lat': '23.4756', 'name': 'Nityanandapur,Bankura,West Bengal', 'lon': '87.2248'},
      '444304': {'lat': '20.8531', 'name': 'Matergaon,Buldhana,Maharashtra', 'lon': '76.5618'},
      '722140': {'lat': '23.0481', 'name': 'Jamda,Bankura,West Bengal', 'lon': '87.049'},
      '444306': {'lat': '20.7236', 'name': 'Pimpalgaon Raja,Buldhana,Maharashtra', 'lon': '76.437'},
      '326515': {'lat': '26.2194', 'name': 'Keetia,Jhalawar,Rajasthan', 'lon': '76.026'},
      '756022': {'lat': '20.9565', 'name': 'Mukulisi,Baleswar,Odisha', 'lon': '86.1689'},
      '326517': {'lat': '24.3289', 'name': 'Morak,Jhalawar,Rajasthan', 'lon': '76.1968'},
      '532195': {'lat': '18.5251', 'name': 'Pattupuram,Srikakulam,Andhra Pradesh', 'lon': '84.0773'},
      '562102': {'lat': '13.3848', 'name': 'Malamachanahalli,Chikkaballapur,Karnataka', 'lon': '77.8768'},
      '532190': {'lat': '18.5147', 'name': 'P.S.Puram,Srikakulam,Andhra Pradesh', 'lon': '83.8175'},
      '585304': {'lat': '16.5051', 'name': 'Sharadahalli,Yadgir,Karnataka', 'lon': '76.9118'},
      '387345': {'lat': '21.1333', 'name': 'Rajnagar,Kheda,Gujarat', 'lon': '72.8179'},
      '602003': {'lat': '13.0553', 'name': 'Kakalur Ind Estate,Tiruvallur,Tamil Nadu', 'lon': '79.906'},
      '622301': {'lat': '10.3816', 'name': 'Sooranviduthi,Pudukkottai,Tamil Nadu', 'lon': '78.8741'},
      '387610': {'lat': '23.0929', 'name': 'Ravdavat,Kheda,Gujarat', 'lon': '72.9689'},
      '326519': {'lat': '24.7797', 'name': 'Satal Khedi,Kota,Rajasthan', 'lon': '75.528'},
      '387340': {'lat': '23.1652', 'name': 'Nizampura,Kheda,Gujarat', 'lon': '73.2799'},
      '485771': {'lat': '23.7225', 'name': 'Sharda Mata Mandir,Satna,Madhya Pradesh', 'lon': '80.4987'},
      '622304': {'lat': '10.6297', 'name': 'Pullanviduthi,Pudukkottai,Tamil Nadu', 'lon': '79.2125'},
      '811214': {'lat': '25.4849', 'name': 'Jamalpur (Munger),Munger,Bihar', 'lon': '86.5402'},
      '756021': {'lat': '21.8832', 'name': 'Bardhanpur,Baleswar,Odisha', 'lon': '85.7216'},
      '416551': {'lat': '15.9179', 'name': 'Nangnoor,Kolhapur,Maharashtra', 'lon': '74.1329'},
      '743446': {'lat': '22.3484', 'name': 'Joygopalpur,North 24 Parganas,West Bengal', 'lon': '88.8997'},
      '756026': {'lat': '21.3642', 'name': 'Paschima Bad,Baleswar,Odisha', 'lon': '86.1894'},
      '621652': {'lat': '11.0216', 'name': 'Melarasur,Tiruchirappalli,Tamil Nadu', 'lon': '78.9534'},
      '621653': {'lat': '11.0425', 'name': 'Poondi,Ariyalur,Tamil Nadu', 'lon': '79.0083'},
      '743442': {'lat': '22.3687', 'name': 'Purba Sankardaha,North 24 Parganas,West Bengal', 'lon': '88.562'},
      '209724': {'lat': '27.2288', 'name': 'Kadhar,Farrukhabad,Uttar Pradesh', 'lon': '79.6531'},
      '396215': {'lat': '20.4095', 'name': 'Dabhel,Daman,Daman & Diu', 'lon': '72.8834'},
      '754132': {'lat': '20.1901', 'name': 'Alla,Jagatsinghapur,Odisha', 'lon': '86.2292'},
      '209725': {'lat': '27.0552', 'name': 'Kannauj Samachar,Kannauj,Uttar Pradesh', 'lon': '79.9188'},
      '756024': {'lat': '21.1336', 'name': 'Nadbani,Baleswar,Odisha', 'lon': '86.1998'},
      '328029': {'lat': '26.7588', 'name': 'Mehandpur,Dholpur,Rajasthan', 'lon': '78.0596'},
      '754133': {'lat': '20.8843', 'name': 'Patenigaon,Jagatsinghapur,Odisha', 'lon': '84.3606'},
      '682312': {'lat': '9.9364', 'name': 'Kuzhiyara,Ernakulam,Kerala', 'lon': '76.3811'},
      '682313': {'lat': '9.4446', 'name': 'Kaipatoor,Ernakulam,Kerala', 'lon': '76.47'},
      '682310': {'lat': '9.984', 'name': 'Pancode,Ernakulam,Kerala', 'lon': '76.4273'},
      '682311': {'lat': '9.9781', 'name': 'Kadayirippu,Ernakulam,Kerala', 'lon': '76.474'},
      '682316': {'lat': '9.9555', 'name': 'Mekkadambu,Ernakulam,Kerala', 'lon': '76.4032'},
      '682317': {'lat': '9.9555', 'name': 'Kulayettikara,Ernakulam,Kerala', 'lon': '76.4032'},
      '682314': {'lat': '9.9555', 'name': 'Paingarapilly,Ernakulam,Kerala', 'lon': '76.4032'},
      '682315': {'lat': '10.4343', 'name': 'Chethicode,Ernakulam,Kerala', 'lon': '76.2634'},
      '757032': {'lat': '22.0808', 'name': 'Gosanipal,Mayurbhanj,Odisha', 'lon': '86.1432'},
      '757033': {'lat': '21.7203', 'name': 'Sargoda Jashipur,Mayurbhanj,Odisha', 'lon': '85.7689'},
      '757030': {'lat': '21.7841', 'name': 'Parulia,Mayurbhanj,Odisha', 'lon': '85.9164'},
      '757031': {'lat': '21.7203', 'name': 'Gandipani,Mayurbhanj,Odisha', 'lon': '85.7689'},
      '757036': {'lat': '21.7368', 'name': 'Tongabilla,Mayurbhanj,Odisha', 'lon': '85.8094'},
      '757037': {'lat': '21.787', 'name': 'Karanjia Bazar,Mayurbhanj,Odisha', 'lon': '85.9314'},
      '757034': {'lat': '21.4888', 'name': 'Baunsnali,Mayurbhanj,Odisha', 'lon': '85.328'},
      '757035': {'lat': '21.955', 'name': 'Baidyanath,Mayurbhanj,Odisha', 'lon': '85.8091'},
      '441306': {'lat': '21.3862', 'name': 'Sindhi Umri,Nagpur,Maharashtra', 'lon': '78.6453'},
      '586111': {'lat': '17.3134', 'name': 'Agarkhed,Vijayapura,Karnataka', 'lon': '76.0606'},
      '441304': {'lat': '21.452', 'name': 'Mohadi Dalvi,Nagpur,Maharashtra', 'lon': '78.5009'},
      '441305': {'lat': '21.3248', 'name': 'Jamgaon BK,Nagpur,Maharashtra', 'lon': '78.4606'},
      '441302': {'lat': '21.259', 'name': 'Metpanjra,Nagpur,Maharashtra', 'lon': '78.6987'},
      '441303': {'lat': '21.4647', 'name': 'Mowad,Nagpur,Maharashtra', 'lon': '78.451'},
      '441301': {'lat': '21.3497', 'name': 'Mendhala,Nagpur,Maharashtra', 'lon': '78.3728'},
      '532220': {'lat': '18.3675', 'name': 'Pedanarayanapuram,Srikakulam,Andhra Pradesh', 'lon': '83.5148'},
      '678572': {'lat': '10.7085', 'name': 'Kottayi,Palakkad,Kerala', 'lon': '76.4722'},
      '678571': {'lat': '10.7085', 'name': 'Thachangad,Palakkad,Kerala', 'lon': '76.4722'},
      '754134': {'lat': '20.2225', 'name': 'Tyandakura,Kendrapara,Odisha', 'lon': '85.1926'},
      '678574': {'lat': '10.7085', 'name': 'Naduvathupara,Palakkad,Kerala', 'lon': '76.4722'},
      '473112': {'lat': '24.3493', 'name': 'Gail Complex,Guna,Madhya Pradesh', 'lon': '77.3036'},
      '473113': {'lat': '24.6231', 'name': 'Myana,Guna,Madhya Pradesh', 'lon': '77.3863'},
      '473110': {'lat': '24.2624', 'name': 'Ruthiyai,Guna,Madhya Pradesh', 'lon': '77.4108'},
      '754135': {'lat': '20.5592', 'name': 'Krishnanandapur,Jagatsinghapur,Odisha', 'lon': '85.176'},
      '341503': {'lat': '26.42', 'name': 'Peelwa,Nagaur,Rajasthan', 'lon': '73.9764'},
      '473115': {'lat': '24.0697', 'name': 'Ramdi,Guna,Madhya Pradesh', 'lon': '77.1708'},
      '123411': {'lat': '28.2955', 'name': 'Nimoth,Rewari,Haryana', 'lon': '76.4283'},
      '587154': {'lat': '15.3399', 'name': 'Moratageri,Bagalkot,Karnataka', 'lon': '75.7695'},
      '123412': {'lat': '28.091', 'name': 'Badhrana,Rewari,Haryana', 'lon': '76.4914'},
      '341505': {'lat': '27.8037', 'name': 'Bharnai,Nagaur,Rajasthan', 'lon': '74.8058'},
      '246121': {'lat': '29.9215', 'name': 'Devrana,Pauri Garhwal,Uttarakhand', 'lon': '78.5414'},
      '743193': {'lat': '22.8827', 'name': 'Kampa,North 24 Parganas,West Bengal', 'lon': '88.4194'},
      '636008': {'lat': '11.3712', 'name': 'Chinnatirupathy,Salem,Tamil Nadu', 'lon': '78.0893'},
      '534102': {'lat': '16.8059', 'name': 'Veerampalem,West Godavari,Andhra Pradesh', 'lon': '81.4021'},
      '534101': {'lat': '16.8059', 'name': 'Sv Peta,West Godavari,Andhra Pradesh', 'lon': '81.4021'},
      '341504': {'lat': '26.8162', 'name': 'Dobariya,Nagaur,Rajasthan', 'lon': '74.4585'},
      '781004': {'lat': '26.2528', 'name': 'Kharguli,Kamrup,Assam', 'lon': '91.5362'},
      '781005': {'lat': '26.1356', 'name': 'Japorigog,Kamrup,Assam', 'lon': '91.8007'},
      '781006': {'lat': '26.2528', 'name': 'Assam Sachivalaya,Kamrup,Assam', 'lon': '91.5362'},
      '781007': {'lat': '26.37', 'name': 'Lachitnagar,Kamrup,Assam', 'lon': '91.2717'},
      '152114': {'lat': '30.1015', 'name': 'Kuttian Wali,Muktsar,Punjab', 'lon': '74.5299'},
      '781001': {'lat': '26.2528', 'name': 'Guwahati G.P.O.,Kamrup,Assam', 'lon': '91.5362'},
      '781002': {'lat': '26.2528', 'name': 'IBC Guwahati,Kamrup,Assam', 'lon': '91.5362'},
      '781003': {'lat': '26.2528', 'name': 'Assam Tribune,Kamrup,Assam', 'lon': '91.5362'},
      '152118': {'lat': '30.109', 'name': 'Sitto,Fazilka,Punjab', 'lon': '74.4341'},
      '341506': {'lat': '26.6949', 'name': 'Begsar,Nagaur,Rajasthan', 'lon': '75.0607'},
      '781008': {'lat': '26.2528', 'name': 'Rehabari,Kamrup,Assam', 'lon': '91.5362'},
      '781009': {'lat': '26.2528', 'name': 'Bharalumukh,Kamrup,Assam', 'lon': '91.5362'},
      '322252': {'lat': '26.9404', 'name': 'Jatwada,Karauli,Rajasthan', 'lon': '77.0887'},
      '334003': {'lat': '28.1862', 'name': 'Pawanpuri (Bikaner),Bikaner,Rajasthan', 'lon': '73.2906'},
      '333516': {'lat': '28.1046', 'name': 'Dumoli Khurd,Jhujhunu,Rajasthan', 'lon': '75.9234'},
      '333515': {'lat': '28.012', 'name': 'Nihaloth,Jhujhunu,Rajasthan', 'lon': '76.0869'},
      '333514': {'lat': '28.0519', 'name': 'Loyal,Jhujhunu,Rajasthan', 'lon': '75.6474'},
      '302027': {'lat': '26.9972', 'name': 'Roop Was,Jaipur,Rajasthan', 'lon': '75.929'},
      '302026': {'lat': '26.7654', 'name': 'Bhankrota,Jaipur,Rajasthan', 'lon': '75.5691'},
      '246733': {'lat': '28.8928', 'name': 'Main Bazar Nehtaur,Bijnor,Uttar Pradesh', 'lon': '79.252'},
      '302022': {'lat': '26.9295', 'name': 'Shri Ram Ki Nangal,Jaipur,Rajasthan', 'lon': '75.7962'},
      '302021': {'lat': '26.9126', 'name': 'Vaishali Nagar,Jaipur,Rajasthan', 'lon': '75.7441'},
      '795134': {'lat': '24.6994', 'name': 'Heikrujam,Bishnupur,Manipur', 'lon': '93.8593'},
      '483880': {'lat': '23.3846', 'name': 'Kymore,Jabalpur,Madhya Pradesh', 'lon': '79.7442'},
      '341306': {'lat': '27.2585', 'name': 'Rahugate Ladnun,Nagaur,Rajasthan', 'lon': '76.7908'},
      '341305': {'lat': '27.9801', 'name': 'Koliya,Nagaur,Rajasthan', 'lon': '74.336'},
      '341304': {'lat': '27.9849', 'name': 'Balsamand,Nagaur,Rajasthan', 'lon': '74.723'},
      '341303': {'lat': '27.3454', 'name': 'Reengan,Nagaur,Rajasthan', 'lon': '74.7795'},
      '341302': {'lat': '26.2575', 'name': 'Bhinchawa,Nagaur,Rajasthan', 'lon': '73.7538'},
      '341301': {'lat': '27.4071', 'name': 'Kasari,Nagaur,Rajasthan', 'lon': '74.7064'},
      '711304': {'lat': '22.7759', 'name': 'Banipur (Howrah),Howrah,West Bengal', 'lon': '88.0532'},
      '604404': {'lat': '12.5612', 'name': 'Echur,Tiruvannamalai,Tamil Nadu', 'lon': '79.5252'},
      '604405': {'lat': '12.3288', 'name': 'Vazhur,Tiruvannamalai,Tamil Nadu', 'lon': '79.6654'},
      '604406': {'lat': '12.3619', 'name': 'Pennattagaram,Tiruvannamalai,Tamil Nadu', 'lon': '79.5109'},
      '604407': {'lat': '12.6956', 'name': 'Pappanthangal,Tiruvannamalai,Tamil Nadu', 'lon': '79.5262'},
      '604152': {'lat': '12.1697', 'name': 'Velanthangal,Villupuram,Tamil Nadu', 'lon': '79.4496'},
      '604401': {'lat': '12.3837', 'name': 'Anapathur,Tiruvannamalai,Tamil Nadu', 'lon': '79.0341'},
      '604402': {'lat': '12.745', 'name': 'Kunnathur,Tiruvannamalai,Tamil Nadu', 'lon': '79.539'},
      '604403': {'lat': '12.5095', 'name': 'Badur,Tiruvannamalai,Tamil Nadu', 'lon': '79.7085'},
      '604408': {'lat': '12.4541', 'name': 'Veerambakkam-pudur,Tiruvannamalai,Tamil Nadu', 'lon': '79.6037'},
      '604409': {'lat': '12.6402', 'name': 'Vadathinallur,Tiruvannamalai,Tamil Nadu', 'lon': '79.465'},
      '412214': {'lat': '19.7629', 'name': 'Nathachi Wadi,Pune,Maharashtra', 'lon': '76.188'},
      '412215': {'lat': '19.1697', 'name': 'Kadethan,Pune,Maharashtra', 'lon': '75.5484'},
      '412216': {'lat': '18.6457', 'name': 'Fulgaon,Pune,Maharashtra', 'lon': '74.0591'},
      '609606': {'lat': '10.9434', 'name': 'Tirumalairayanpattinam,Karaikal,Pondicherry', 'lon': '79.7558'},
      '412210': {'lat': '18.8276', 'name': 'Shirur (Pune),Pune,Maharashtra', 'lon': '74.3747'},
      '412211': {'lat': '18.6194', 'name': 'Alegaon,Pune,Maharashtra', 'lon': '74.3342'},
      '412212': {'lat': '19.6219', 'name': 'Velhe,Pune,Maharashtra', 'lon': '79.007'},
      '412213': {'lat': '19.3846', 'name': 'Wangani,Pune,Maharashtra', 'lon': '77.6251'},
      '412218': {'lat': '18.5709', 'name': 'Amdabad,Pune,Maharashtra', 'lon': '74.0691'},
      '412219': {'lat': '19.2209', 'name': 'Roti,Pune,Maharashtra', 'lon': '74.3'},
      '455118': {'lat': '22.9058', 'name': 'Sonkutch,Dewas,Madhya Pradesh', 'lon': '76.7641'},
      '411004': {'lat': '18.5148', 'name': 'A.R. Shala,Pune,Maharashtra', 'lon': '73.8358'},
      '411005': {'lat': '18.5247', 'name': 'Congress House Road,Pune,Maharashtra', 'lon': '73.8471'},
      '411006': {'lat': '18.5459', 'name': 'Yerwada T.S.,Pune,Maharashtra', 'lon': '73.8855'},
      '690110': {'lat': '9.2297', 'name': 'Kallumala,Alappuzha,Kerala', 'lon': '76.5637'},
      '411001': {'lat': '18.5372', 'name': 'Pune New Bazar,Pune,Maharashtra', 'lon': '73.8551'},
      '411002': {'lat': '18.5372', 'name': 'Shukrawar Peth (Pune),Pune,Maharashtra', 'lon': '73.8551'},
      '411003': {'lat': '18.5635', 'name': 'East Khadki,Pune,Maharashtra', 'lon': '73.852'},
      '160102': {'lat': '30.7465', 'name': 'Mauli Jagran,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '160103': {'lat': '30.7465', 'name': 'Naya Gaon (Mohali),Mohali,Punjab', 'lon': '76.7417'},
      '160101': {'lat': '30.7465', 'name': 'Manimajra,Chandigarh,Chandigarh', 'lon': '76.7417'},
      '411008': {'lat': '18.5372', 'name': 'N.C.L. Pune,Pune,Maharashtra', 'lon': '73.8551'},
      '411009': {'lat': '18.5372', 'name': 'Parvati,Pune,Maharashtra', 'lon': '73.8551'},
      '122098': {'lat': '28.4001', 'name': 'CRPF Kadarpur,Gurgaon,Haryana', 'lon': '76.9661'},
      '495668': {'lat': '21.9868', 'name': 'Naila R.S.,Janjgir-champa,Chattisgarh', 'lon': '82.5787'},
      '517194': {'lat': '13.7221', 'name': 'Dudhevaripalle,Chittoor,Andhra Pradesh', 'lon': '78.9943'},
      '517193': {'lat': '13.6695', 'name': 'Cheruvumundarapalle,Chittoor,Andhra Pradesh', 'lon': '79.071'},
      '517192': {'lat': '13.6132', 'name': 'Peddagottigallu,Chittoor,Andhra Pradesh', 'lon': '78.9165'},
      '322251': {'lat': '26.3418', 'name': 'Rodhai,Karauli,Rajasthan', 'lon': '76.72'},
      '752001': {'lat': '20.4654', 'name': 'Puri H.O,Puri,Odisha', 'lon': '85.0739'},
      '388340': {'lat': '22.5269', 'name': 'Mogar,Anand,Gujarat', 'lon': '72.9314'},
      '854115': {'lat': '25.3569', 'name': 'Bishanpurhariabhir,Katihar,Bihar', 'lon': '87.3467'},
      '516247': {'lat': '14.4311', 'name': 'Nekanapuram,Cuddapah,Andhra Pradesh', 'lon': '78.9735'},
      '361142': {'lat': '22.2286', 'name': 'Reliance Greens,Jamnagar,Gujarat', 'lon': '70.3853'},
      '361141': {'lat': '22.2286', 'name': 'Sikka Tps,Jamnagar,Gujarat', 'lon': '70.3853'},
      '361140': {'lat': '22.2286', 'name': 'Moti Khavdi,Jamnagar,Gujarat', 'lon': '70.3853'},
      '466113': {'lat': '23.1199', 'name': 'Dhamnda,Sehore,Madhya Pradesh', 'lon': '76.9031'},
      '507001': {'lat': '17.1353', 'name': 'Church Road(KHM),Khammam,Telangana', 'lon': '79.5365'},
      '507002': {'lat': '17.3428', 'name': 'Raghunadhapalem,Khammam,Telangana', 'lon': '79.9044'},
      '473111': {'lat': '24.6015', 'name': 'Dongar Vijaypur,Guna,Madhya Pradesh', 'lon': '78.3381'},
      '385110': {'lat': '24.2354', 'name': 'Ambaji,Banaskantha,Gujarat', 'lon': '72.9488'},
      '700021': {'lat': '22.4826', 'name': 'Fort William,Kolkata,West Bengal', 'lon': '88.4744'},
      '230001': {'lat': '25.9732', 'name': 'Pratapgarh H.O,Pratapgarh,Uttar Pradesh', 'lon': '81.3865'},
      '584170': {'lat': '16.3816', 'name': 'Deosugur,Raichur,Karnataka', 'lon': '77.3128'},
      '303601': {'lat': '26.9805', 'name': 'Hanutia,Jaipur,Rajasthan', 'lon': '75.7243'},
      '571111': {'lat': '11.9369', 'name': 'Alathur,Chamrajnagar,Karnataka', 'lon': '76.5934'},
      '828103': {'lat': '24.0728', 'name': 'Bhelatand,Dhanbad,Jharkhand', 'lon': '86.506'},
      '854114': {'lat': '25.6322', 'name': 'Bareta,Katihar,Bihar', 'lon': '87.296'},
      '314406': {'lat': '23.8557', 'name': 'Bandela,Dungarpur,Rajasthan', 'lon': '73.7008'},
      '571110': {'lat': '12.2829', 'name': 'M K Hundi,Chamrajnagar,Karnataka', 'lon': '76.8237'},
      '521109': {'lat': '16.6291', 'name': 'Galigopuram,Krishna,Andhra Pradesh', 'lon': '80.6202'},
      '521108': {'lat': '16.5133', 'name': 'Enikepadu,Krishna,Andhra Pradesh', 'lon': '80.7066'},
      '394210': {'lat': '21.1333', 'name': 'Sania Kande,Surat,Gujarat', 'lon': '72.8179'},
      '521105': {'lat': '17.0268', 'name': 'Medicherla,Krishna,Andhra Pradesh', 'lon': '81.4066'},
      '521104': {'lat': '16.5019', 'name': 'Gudavalli,Krishna,Andhra Pradesh', 'lon': '80.7391'},
      '521107': {'lat': '16.5379', 'name': 'Mustabad (Krishna),Krishna,Andhra Pradesh', 'lon': '80.7327'},
      '521106': {'lat': '17.2226', 'name': 'Puttagunta,Krishna,Andhra Pradesh', 'lon': '81.3678'},
      '521101': {'lat': '16.5409', 'name': 'Budhavaram,Krishna,Andhra Pradesh', 'lon': '80.8021'},
      '521102': {'lat': '16.716', 'name': 'Bacon Factory,Krishna,Andhra Pradesh', 'lon': '80.9114'},
      '614202': {'lat': '10.921', 'name': 'Puthur-PSM,Thanjavur,Tamil Nadu', 'lon': '79.173'},
      '503102': {'lat': '18.2342', 'name': 'Lingupalli,Nizamabad,Telangana', 'lon': '78.3707'},
      '571115': {'lat': '12.0248', 'name': 'Kannagala,Chamrajnagar,Karnataka', 'lon': '76.9783'},
      '175029': {'lat': '31.4891', 'name': 'Kelodhar,Mandi,Himachal Pradesh', 'lon': '77.2301'},
      '175028': {'lat': '31.5401', 'name': 'Shala,Mandi,Himachal Pradesh', 'lon': '77.0422'},
      '175027': {'lat': '31.5592', 'name': 'Chunahan,Mandi,Himachal Pradesh', 'lon': '76.9493'},
      '175026': {'lat': '31.5444', 'name': 'Khoda,Mandi,Himachal Pradesh', 'lon': '76.9627'},
      '175025': {'lat': '31.551', 'name': 'Sajao Pipluso,Mandi,Himachal Pradesh', 'lon': '77.0865'},
      '175024': {'lat': '31.6411', 'name': 'Khalardu,Mandi,Himachal Pradesh', 'lon': '76.6149'},
      '175023': {'lat': '31.6009', 'name': 'Thinagaloo,Mandi,Himachal Pradesh', 'lon': '76.8514'},
      '143113': {'lat': '31.6386', 'name': 'Rasulpur Kalan,Amritsar,Punjab', 'lon': '75.0471'},
      '175021': {'lat': '31.6416', 'name': 'Barsu,Mandi,Himachal Pradesh', 'lon': '76.9357'},
      '143111': {'lat': '31.3373', 'name': 'Allowal,Tarn Taran,Punjab', 'lon': '74.8446'},
      '361320': {'lat': '22.1906', 'name': 'Chachlana,Jamnagar,Gujarat', 'lon': '69.5884'},
      '572138': {'lat': '13.5202', 'name': 'Devalapura,Tumakuru,Karnataka', 'lon': '76.9477'},
      '572139': {'lat': '13.3629', 'name': 'Tavarekere (Tumkur),Tumakuru,Karnataka', 'lon': '76.7083'},
      '361325': {'lat': '22.1906', 'name': 'Khirasara,Jamnagar,Gujarat', 'lon': '69.5884'},
      '572134': {'lat': '13.4687', 'name': 'Turuganur,Tumakuru,Karnataka', 'lon': '76.9437'},
      '572135': {'lat': '13.646', 'name': 'Yenjalagere,Tumakuru,Karnataka', 'lon': '76.5256'},
      '572136': {'lat': '14.0955', 'name': 'Yeguvapalli,Tumakuru,Karnataka', 'lon': '77.0317'},
      '572137': {'lat': '13.5757', 'name': 'Channanakunte,Tumakuru,Karnataka', 'lon': '76.3236'},
      '572130': {'lat': '12.9838', 'name': 'Bidanagere,Tumakuru,Karnataka', 'lon': '77.0029'},
      '577451': {'lat': '14.0861', 'name': 'KVV Shankaraghattta,Shivamogga,Karnataka', 'lon': '74.9959'},
      '577452': {'lat': '13.9374', 'name': 'Mathikai,Shivamogga,Karnataka', 'lon': '74.9076'},
      '577453': {'lat': '14.1852', 'name': 'Hulidevarabana,Shivamogga,Karnataka', 'lon': '75.0548'},
      '132145': {'lat': '29.2884', 'name': 'Didwari,Panipat,Haryana', 'lon': '76.9315'},
      '225405': {'lat': '26.708', 'name': 'Thorthia,Barabanki,Uttar Pradesh', 'lon': '81.4487'},
      '132140': {'lat': '29.4567', 'name': 'Ganjbarh,Panipat,Haryana', 'lon': '76.9347'},
      '248009': {'lat': '30.4085', 'name': 'Bhagwantpur,Dehradun,Uttarakhand', 'lon': '78.0722'},
      '533016': {'lat': '17.3229', 'name': 'Aratlakatta,East Godavari,Andhra Pradesh', 'lon': '82.4308'},
      '631551': {'lat': '12.8696', 'name': 'Krishnapuram,Kanchipuram,Tamil Nadu', 'lon': '79.7195'},
      '127312': {'lat': '28.4222', 'name': 'Badrai,Bhiwani,Haryana', 'lon': '76.0244'},
      '210123': {'lat': '25.6994', 'name': 'Piprahari,Banda,Uttar Pradesh', 'lon': '80.8083'},
      '127310': {'lat': '28.5039', 'name': 'Gudana Kalan,Bhiwani,Haryana', 'lon': '76.0992'},
      '127311': {'lat': '28.702', 'name': 'Haluwas,Bhiwani,Haryana', 'lon': '76.122'},
      '304505': {'lat': '26.0551', 'name': 'Mundia Kalan,Tonk,Rajasthan', 'lon': '75.1281'},
      '485775': {'lat': '24.3883', 'name': 'Asrar,Satna,Madhya Pradesh', 'lon': '80.9864'},
      '322255': {'lat': '26.3843', 'name': 'Bijalpur,Karauli,Rajasthan', 'lon': '76.7006'},
      '591201': {'lat': '15.9528', 'name': 'Chikodi Extension,Belagavi,Karnataka', 'lon': '75.3478'},
      '606808': {'lat': '12.1235', 'name': 'Su. Andapattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.0786'},
      '606806': {'lat': '12.0669', 'name': 'Su Valavetti,Tiruvannamalai,Tamil Nadu', 'lon': '79.1198'},
      '606807': {'lat': '12.6341', 'name': 'Veppambattu,Tiruvannamalai,Tamil Nadu', 'lon': '79.3496'},
      '606804': {'lat': '12.121', 'name': 'Tandarai,Tiruvannamalai,Tamil Nadu', 'lon': '79.1511'},
      '606805': {'lat': '12.3181', 'name': 'Turinjapuram,Tiruvannamalai,Tamil Nadu', 'lon': '79.0962'},
      '606802': {'lat': '12.3812', 'name': 'Northampoondi,Tiruvannamalai,Tamil Nadu', 'lon': '79.0888'},
      '606803': {'lat': '12.4986', 'name': 'Periagaram,Tiruvannamalai,Tamil Nadu', 'lon': '79.1245'},
      '606801': {'lat': '12.4683', 'name': 'Maruthuvambadi,Tiruvannamalai,Tamil Nadu', 'lon': '79.3534'},
      '504207': {'lat': '18.8673', 'name': 'Raipally,Adilabad,Telangana', 'lon': '79.2718'},
      '590001': {'lat': '15.8521', 'name': 'Belgaum Camp,Belagavi,Karnataka', 'lon': '74.5045'},
      '481051': {'lat': '21.8076', 'name': 'Kachnari,Balaghat,Madhya Pradesh', 'lon': '80.8211'},
      '590003': {'lat': '15.8427', 'name': 'Belgaum Shahapur,Belagavi,Karnataka', 'lon': '74.515'},
      '712246': {'lat': '23.5905', 'name': 'Nabagram (Hooghly),Hooghly,West Bengal', 'lon': '87.9457'},
      '590005': {'lat': '15.3188', 'name': 'Sulge ( Yellur),Belagavi,Karnataka', 'lon': '74.8389'},
      '590006': {'lat': '15.8196', 'name': 'Bhagyanagar (Belgaum),Belagavi,Karnataka', 'lon': '74.5037'},
      '712245': {'lat': '24.0455', 'name': 'Rajendra Avenue,Hooghly,West Bengal', 'lon': '87.8402'},
      '590008': {'lat': '15.832', 'name': 'Udyambag,Belagavi,Karnataka', 'lon': '74.4568'},
      '590009': {'lat': '15.6896', 'name': 'Belgaum Records MLI,Belagavi,Karnataka', 'lon': '74.5974'},
      '712248': {'lat': '22.7239', 'name': 'Gandhisarak,Hooghly,West Bengal', 'lon': '88.3456'},
      '712249': {'lat': '22.8847', 'name': 'Nutangram,Hooghly,West Bengal', 'lon': '88.0798'},
      '382775': {'lat': '22.4799', 'name': 'Kadu,Surendra Nagar,Gujarat', 'lon': '72.8969'},
      '504209': {'lat': '18.8727', 'name': 'Mulkala,Adilabad,Telangana', 'lon': '79.4121'},
      '504208': {'lat': '18.928', 'name': 'Mancherial Bazar,Adilabad,Telangana', 'lon': '79.1209'},
      '382470': {'lat': '22.8984', 'name': 'Kali,Ahmedabad,Gujarat', 'lon': '72.7305'},
      '712138': {'lat': '22.5167', 'name': 'Jarura,Hooghly,West Bengal', 'lon': '88.0828'},
      '249202': {'lat': '30.0712', 'name': 'Nirmal Block ,Dehradun,Uttarakhand', 'lon': '78.2819'},
      '493554': {'lat': '21.2533', 'name': 'Sirko,Mahasamund,Chattisgarh', 'lon': '82.779'},
      '382475': {'lat': '22.8984', 'name': 'Sardarnagar,Ahmedabad,Gujarat', 'lon': '72.7305'},
      '262530': {'lat': '29.4165', 'name': 'Shillingatoli,Pithoragarh,Uttarakhand', 'lon': '79.7232'},
      '792123': {'lat': '27.3061', 'name': 'Nampong,Changlang,Arunachal Pradesh', 'lon': '96.106'},
      '262532': {'lat': '29.7305', 'name': 'Saliya,Pithoragarh,Uttarakhand', 'lon': '79.9366'},
      '496554': {'lat': '21.6564', 'name': 'Rissora,Raigarh,Chattisgarh', 'lon': '83.3585'},
      '262534': {'lat': '29.7846', 'name': 'Jarapani,Pithoragarh,Uttarakhand', 'lon': '79.6209'},
      '765024': {'lat': '19.2131', 'name': 'Bichikote,Rayagada,Odisha', 'lon': '83.7794'},
      '792122': {'lat': '27.4545', 'name': 'Namphai,Changlang,Arunachal Pradesh', 'lon': '96.09'},
      '532426': {'lat': '18.6022', 'name': 'Aludu,Srikakulam,Andhra Pradesh', 'lon': '83.9724'},
      '532427': {'lat': '18.5152', 'name': 'Kommanapalli,Srikakulam,Andhra Pradesh', 'lon': '83.976'},
      '785666': {'lat': '27.1513', 'name': 'Jatakia,Jorhat,Assam', 'lon': '94.7565'},
      '532425': {'lat': '18.4644', 'name': 'Paraselli,Srikakulam,Andhra Pradesh', 'lon': '83.9868'},
      '721442': {'lat': '21.7907', 'name': 'Uttarharaschak,East Midnapore,West Bengal', 'lon': '87.8635'},
      '785661': {'lat': '27.1513', 'name': 'Hafaluting,Sibsagar,Assam', 'lon': '94.7565'},
      '785662': {'lat': '27.1998', 'name': 'Konwar Dihingia,Sibsagar,Assam', 'lon': '94.8038'},
      '785663': {'lat': '27.0667', 'name': 'Panbecha,Sibsagar,Assam', 'lon': '94.55'},
      '742408': {'lat': '23.9685', 'name': 'Sargachi Ashram,Murshidabad,West Bengal', 'lon': '87.8257'},
      '785669': {'lat': '27.1874', 'name': 'Mohkhuti,Sibsagar,Assam', 'lon': '94.9156'},
      '532428': {'lat': '18.5811', 'name': 'Karakavalasa,Srikakulam,Andhra Pradesh', 'lon': '83.9213'},
      '532429': {'lat': '18.4767', 'name': 'Amapalam,Srikakulam,Andhra Pradesh', 'lon': '83.7033'},
      '630205': {'lat': '9.982', 'name': 'Avanipatti,Sivaganga,Tamil Nadu', 'lon': '78.6934'},
      '630204': {'lat': '9.8104', 'name': 'Naduvikottai,Sivaganga,Tamil Nadu', 'lon': '78.8384'},
      '630207': {'lat': '10.0783', 'name': 'Pillayarpatti,Sivaganga,Tamil Nadu', 'lon': '78.6688'},
      '630206': {'lat': '9.982', 'name': 'Kunnakudi,Sivaganga,Tamil Nadu', 'lon': '78.6934'},
      '630201': {'lat': '9.982', 'name': 'Vanjinipatti,Sivaganga,Tamil Nadu', 'lon': '78.6934'},
      '630203': {'lat': '10.2067', 'name': 'Kandavarayanpatti,Sivaganga,Tamil Nadu', 'lon': '78.5944'},
      '630202': {'lat': '9.7164', 'name': 'Illuppakudi,Sivaganga,Tamil Nadu', 'lon': '78.7528'},
      '206130': {'lat': '26.7593', 'name': 'Kuiya,Etawah,Uttar Pradesh', 'lon': '79.0966'},
      '206131': {'lat': '26.7354', 'name': 'Jarhauli,Etawah,Uttar Pradesh', 'lon': '78.9044'},
      '630208': {'lat': '10.1356', 'name': 'O.Siruvayal,Sivaganga,Tamil Nadu', 'lon': '78.7239'},
      '843360': {'lat': '26.3048', 'name': 'Ufrauli,Sitamarhi,Bihar', 'lon': '85.6653'},
      '392210': {'lat': '21.7955', 'name': 'Hingalla,Bharuch,Gujarat', 'lon': '73.4541'},
      '631002': {'lat': '13.0393', 'name': 'Palanipet,Vellore,Tamil Nadu', 'lon': '79.6371'},
      '631003': {'lat': '13.0585', 'name': 'Valaikulam,Vellore,Tamil Nadu', 'lon': '79.6189'},
      '392215': {'lat': '21.7955', 'name': 'Urja Nagar,Bharuch,Gujarat', 'lon': '73.4541'},
      '631005': {'lat': '13.0633', 'name': 'Winterpet,Vellore,Tamil Nadu', 'lon': '79.645'},
      '631006': {'lat': '13.0633', 'name': 'Ins Rajali,Vellore,Tamil Nadu', 'lon': '79.645'},
      '403110': {'lat': '15.4841', 'name': 'Corlim IE,North Goa,Goa', 'lon': '73.922'},
      '276302': {'lat': '25.8881', 'name': 'Kalanderpur,Azamgarh,Uttar Pradesh', 'lon': '82.9495'},
      '515741': {'lat': '14.5829', 'name': 'Y Rama Puram,Ananthapur,Andhra Pradesh', 'lon': '77.4081'},
      '515291': {'lat': '13.8781', 'name': 'Dasappapalyam,Ananthapur,Andhra Pradesh', 'lon': '77.3088'},
      '276306': {'lat': '26.8355', 'name': 'Bibi Pur,Mau,Uttar Pradesh', 'lon': '83.4652'},
      '276304': {'lat': '26.059', 'name': 'Mundiyar,Azamgarh,Uttar Pradesh', 'lon': '82.9028'},
      '276305': {'lat': '25.9595', 'name': 'Rangdih,Mau,Uttar Pradesh', 'lon': '82.9004'},
      '721437': {'lat': '24.3029', 'name': 'Tentulmuri,East Midnapore,West Bengal', 'lon': '87.818'},
      '721436': {'lat': '22.8923', 'name': 'Tanua,West Midnapore,West Bengal', 'lon': '87.3704'},
      '721435': {'lat': '23.5034', 'name': 'Chhoto Srikrishnapur,East Midnapore,West Bengal', 'lon': '87.9832'},
      '721434': {'lat': '22.5526', 'name': 'Dhankrabanka,East Midnapore,West Bengal', 'lon': '88.4901'},
      '721433': {'lat': '23.5034', 'name': 'Tagaria,East Midnapore,West Bengal', 'lon': '87.9832'},
      '571448': {'lat': '12.9348', 'name': 'Anchechittanahalli,Mandya,Karnataka', 'lon': '76.6766'},
      '301703': {'lat': '27.908', 'name': 'Majrikanhawas,Alwar,Rajasthan', 'lon': '76.4616'},
      '721430': {'lat': '23.5034', 'name': 'Mohati,East Midnapore,West Bengal', 'lon': '87.9832'},
      '571445': {'lat': '12.8184', 'name': 'Doddachikkanahalli,Mandya,Karnataka', 'lon': '76.8619'},
      '571444': {'lat': '12.7539', 'name': 'Manchapura,Chamrajnagar,Karnataka', 'lon': '77.2996'},
      '243403': {'lat': '28.3173', 'name': 'Malgoan,Bareilly,Uttar Pradesh', 'lon': '79.4194'},
      '571446': {'lat': '12.6627', 'name': 'Chandagalu,Mandya,Karnataka', 'lon': '76.6627'},
      '571441': {'lat': '12.2641', 'name': 'Yelandur Balepete,Chamrajnagar,Karnataka', 'lon': '76.8926'},
      '571440': {'lat': '12.5457', 'name': 'Kollegal H.O,Chamrajnagar,Karnataka', 'lon': '77.1517'},
      '571443': {'lat': '12.1448', 'name': 'Singanallur,Chamrajnagar,Karnataka', 'lon': '77.2198'},
      '571442': {'lat': '12.2184', 'name': 'Hosamalangi,Chamrajnagar,Karnataka', 'lon': '76.7774'},
      '341542': {'lat': '26.9693', 'name': 'I.A.Bidiad,Nagaur,Rajasthan', 'lon': '74.698'},
      '678014': {'lat': '10.7922', 'name': 'Palakkad name,Palakkad,Kerala', 'lon': '76.6419'},
      '623604': {'lat': '9.3755', 'name': 'Pakkuvetti,Ramanathapuram,Tamil Nadu', 'lon': '78.4144'},
      '678010': {'lat': '10.7922', 'name': 'Sekharipuram,Palakkad,Kerala', 'lon': '76.6419'},
      '678011': {'lat': '10.7939', 'name': 'Ambikapuram (Palakkad),Palakkad,Kerala', 'lon': '76.653'},
      '678012': {'lat': '10.7906', 'name': 'Vadakanthara,Palakkad,Kerala', 'lon': '76.6308'},
      '678013': {'lat': '10.7922', 'name': 'Kunnathurmedu,Palakkad,Kerala', 'lon': '76.6419'},
      '605766': {'lat': '12.0647', 'name': 'Deviagaram,Villupuram,Tamil Nadu', 'lon': '79.2489'},
      '301702': {'lat': '27.9893', 'name': 'Kotkassim,Alwar,Rajasthan', 'lon': '76.7177'},
      '689551': {'lat': '9.676', 'name': 'Otherawest,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '689550': {'lat': '9.676', 'name': 'Kuriannoor,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '484001': {'lat': '23.786', 'name': 'Bhuibandh,Shahdol,Madhya Pradesh', 'lon': '80.9682'},
      '233226': {'lat': '25.7929', 'name': 'Kansahari,Ghazipur,Uttar Pradesh', 'lon': '83.5387'},
      '181132': {'lat': '32.6054', 'name': 'Chak Umrah,Jammu,Jammu & Kashmir', 'lon': '74.9329'},
      '181133': {'lat': '32.6617', 'name': 'Tarore,Jammu,Jammu & Kashmir', 'lon': '74.9518'},
      '181131': {'lat': '32.513', 'name': 'Sai Kalan,Jammu,Jammu & Kashmir', 'lon': '74.7793'},
      '741101': {'lat': '24.5009', 'name': 'Krishnanagar R.S.,Nadia,West Bengal', 'lon': '88.1442'},
      '396510': {'lat': '22.8958', 'name': 'Lasanpor,Surat,Gujarat', 'lon': '73.2954'},
      '741102': {'lat': '24.5009', 'name': 'Kalamari,Nadia,West Bengal', 'lon': '88.1442'},
      '301701': {'lat': '27.6069', 'name': 'Tasing,Alwar,Rajasthan', 'lon': '76.3035'},
      '491558': {'lat': '21.0374', 'name': 'Khobha,Rajnandgaon,Chattisgarh', 'lon': '80.5596'},
      '491559': {'lat': '21.2141', 'name': 'Kamthee,Kawardha,Chattisgarh', 'lon': '81.30955'},
      '695608': {'lat': '8.7596', 'name': 'Kodithookiyakunnu,Thiruvananthapuram,Kerala', 'lon': '76.9395'},
      '563147': {'lat': '12.9019', 'name': 'Yelesandra,Kolar,Karnataka', 'lon': '77.5531'},
      '563146': {'lat': '13.4915', 'name': 'Yagavakote,Chikkaballapur,Karnataka', 'lon': '78.1568'},
      '695609': {'lat': '8.7477', 'name': 'Moonnumukku,Thiruvananthapuram,Kerala', 'lon': '76.8535'},
      '802101': {'lat': '25.5367', 'name': 'Sohni Patti,Buxar,Bihar', 'lon': '84.0034'},
      '754239': {'lat': '21.1011', 'name': 'Padanipal,Kendrapara,Odisha', 'lon': '85.0166'},
      '673591': {'lat': '11.6563', 'name': 'Karani,Wayanad,Kerala', 'lon': '76.1696'},
      '673592': {'lat': '11.68', 'name': 'Sulthan Bathery East,Wayanad,Kerala', 'lon': '76.2618'},
      '673593': {'lat': '11.1246', 'name': 'Cheengeri,Wayanad,Kerala', 'lon': '76.3169'},
      '754231': {'lat': '21.1011', 'name': 'Namouza,Kendrapara,Odisha', 'lon': '85.0166'},
      '673595': {'lat': '11.5699', 'name': 'Nenmeni Kunnu,Wayanad,Kerala', 'lon': '76.2538'},
      '383251': {'lat': '23.8415', 'name': 'Kagda Mahuda,Sabarkantha,Gujarat', 'lon': '73.1701'},
      '383250': {'lat': '23.6538', 'name': 'Khiloda,Sabarkantha,Gujarat', 'lon': '73.2908'},
      '275105': {'lat': '26.081', 'name': 'Maur Bojh,Mau,Uttar Pradesh', 'lon': '83.5631'},
      '802102': {'lat': '25.5359', 'name': 'Buxar Jail,Buxar,Bihar', 'lon': '83.9319'},
      '793002': {'lat': '25.5675', 'name': 'Jaiaw,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '734320': {'lat': '26.9319', 'name': 'Kalijhora,Darjiling,West Bengal', 'lon': '88.4504'},
      '767022': {'lat': '20.9489', 'name': 'Budula,Balangir,Odisha', 'lon': '83.7'},
      '767023': {'lat': '20.43575', 'name': 'Karlajuri,Sonapur,Odisha', 'lon': '83.42185'},
      '767020': {'lat': '20.7317092', 'name': 'Ranisarda,Sonapur,Odisha', 'lon': '83.6733959'},
      '767021': {'lat': '20.9227', 'name': 'Bendra,Balangir,Odisha', 'lon': '83.8181'},
      '767026': {'lat': '20.6336', 'name': 'Bagdor,Balangir,Odisha', 'lon': '83.0284'},
      '767027': {'lat': '20.9978', 'name': 'Damaipali,Balangir,Odisha', 'lon': '83.8692'},
      '767024': {'lat': '20.9395', 'name': 'Barpudigia,Balangir,Odisha', 'lon': '83.3847'},
      '767025': {'lat': '20.9387', 'name': 'K. Larambha,Balangir,Odisha', 'lon': '83.2178'},
      '793007': {'lat': '25.5675', 'name': 'Happy Valley,East Khasi Hills,Meghalaya', 'lon': '91.8645'},
      '767028': {'lat': '20.9152', 'name': 'Padiabahal,Balangir,Odisha', 'lon': '82.9769'},
      '767029': {'lat': '20.6155', 'name': 'Sikachhida,Balangir,Odisha', 'lon': '83.6213'},
      '305628': {'lat': '26.2299', 'name': 'Badla,Ajmer,Rajasthan', 'lon': '74.7752'},
      '305629': {'lat': '25.9251', 'name': 'Lamagra,Ajmer,Rajasthan', 'lon': '74.8556'},
      '494001': {'lat': '19.3169', 'name': 'Babusemra,Bastar,Chattisgarh', 'lon': '81.958'},
      '505330': {'lat': '18.7179', 'name': 'Ailapur,Karim Nagar,Telangana', 'lon': '78.353'},
      '333702': {'lat': '27.9022', 'name': 'Dundlod,Jhujhunu,Rajasthan', 'lon': '75.2256'},
      '517429': {'lat': '13.1513', 'name': 'Keeramanda,Chittoor,Andhra Pradesh', 'lon': '78.6921'},
      '632209': {'lat': '12.9648', 'name': 'Ruhsa Campus,Vellore,Tamil Nadu', 'lon': '78.9659'},
      '423702': {'lat': '19.9117', 'name': 'Gajgaon,Aurangabad,Maharashtra', 'lon': '75.7456'},
      '423703': {'lat': '20.1434', 'name': 'Savandgaon,Aurangabad,Maharashtra', 'lon': '75.4195'},
      '734102': {'lat': '27.008', 'name': 'Bloom Field,Darjiling,West Bengal', 'lon': '88.2373'},
      '391745': {'lat': '22.4229', 'name': 'Asoj,Vadodara,Gujarat', 'lon': '73.1675'},
      '632203': {'lat': '12.9351', 'name': 'Melmayil,Vellore,Tamil Nadu', 'lon': '79.1865'},
      '632202': {'lat': '12.9202', 'name': 'Bn Palayam,Vellore,Tamil Nadu', 'lon': '79.2968'},
      '391740': {'lat': '22.3454', 'name': 'Dumad,Vadodara,Gujarat', 'lon': '73.0218'},
      '172105': {'lat': '31.5005', 'name': 'Brua,Kinnaur,Himachal Pradesh', 'lon': '78.1631'},
      '172104': {'lat': '31.5225', 'name': 'Mirro,Kinnaur,Himachal Pradesh', 'lon': '78.0935'},
      '172107': {'lat': '31.5586', 'name': 'Meber,Kinnaur,Himachal Pradesh', 'lon': '78.2755'},
      '172106': {'lat': '31.3913', 'name': 'Kamroo,Kinnaur,Himachal Pradesh', 'lon': '78.3506'},
      '524201': {'lat': '15.1092', 'name': 'Janathapeta,Nellore,Andhra Pradesh', 'lon': '80.0039'},
      '246171': {'lat': '29.8296', 'name': 'Khankara,Rudraprayag,Uttarakhand', 'lon': '79.0114'},
      '172103': {'lat': '31.5276', 'name': 'Nichar,Kinnaur,Himachal Pradesh', 'lon': '78.0946'},
      '172102': {'lat': '31.5276', 'name': 'Dofda,Shimla,Himachal Pradesh', 'lon': '78.0946'},
      '400077': {'lat': '18.9596', 'name': 'Rajawadi,Mumbai,Maharashtra', 'lon': '72.8496'},
      '400076': {'lat': '19.0931', 'name': 'Powai Iit,Mumbai,Maharashtra', 'lon': '72.9049'},
      '400075': {'lat': '19.0931', 'name': 'R.A.Nagar,Mumbai,Maharashtra', 'lon': '72.9049'},
      '400074': {'lat': '19.0931', 'name': 'Chembur Extension,Mumbai,Maharashtra', 'lon': '72.9049'},
      '172109': {'lat': '31.6118', 'name': 'Jangi,Kinnaur,Himachal Pradesh', 'lon': '78.4305'},
      '172108': {'lat': '31.5331', 'name': 'Kalpa,Kinnaur,Himachal Pradesh', 'lon': '78.2688'},
      '400071': {'lat': '19.0931', 'name': 'Sindhi Society,Mumbai,Maharashtra', 'lon': '72.9049'},
      '400070': {'lat': '19.0713', 'name': 'Netajinagar,Mumbai,Maharashtra', 'lon': '72.883'},
      '515124': {'lat': '13.9906', 'name': 'Nagalur,Ananthapur,Andhra Pradesh', 'lon': '77.466'},
      '503185': {'lat': '17.9668', 'name': 'Mandarna,Nizamabad,Telangana', 'lon': '77.865'},
      '816118': {'lat': '24.6567', 'name': 'Shikaripara,Dumka,Jharkhand', 'lon': '86.9551'},
      '826007': {'lat': '24.0858', 'name': 'Centralized Delivery Office,Dhanbad,Jharkhand', 'lon': '86.7287'},
      '515122': {'lat': '13.6688', 'name': 'Chakarlapalli,Ananthapur,Andhra Pradesh', 'lon': '78.7694'},
      '515123': {'lat': '14.066', 'name': 'Thurukalapatnam,Ananthapur,Andhra Pradesh', 'lon': '77.5546'},
      '327031': {'lat': '23.8342', 'name': 'Anandpuri (Banswara),Banswara,Rajasthan', 'lon': '74.0219'},
      '327032': {'lat': '23.6063', 'name': 'Bassi Badi,Banswara,Rajasthan', 'lon': '73.9834'},
      '327034': {'lat': '23.4453', 'name': 'Gopinath Ka Garha,Banswara,Rajasthan', 'lon': '74.2148'},
      '742104': {'lat': '24.1059', 'name': 'Kiriteswari,Murshidabad,West Bengal', 'lon': '88.0168'},
      '246176': {'lat': '29.9218', 'name': 'Chaurakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.7938'},
      '742101': {'lat': '24.1059', 'name': 'Madhupur Colony,Murshidabad,West Bengal', 'lon': '88.0168'},
      '742102': {'lat': '24.1059', 'name': 'Muktinagar,Murshidabad,West Bengal', 'lon': '88.0168'},
      '742103': {'lat': '24.1059', 'name': 'Daihatta Road,Murshidabad,West Bengal', 'lon': '88.0168'},
      '622515': {'lat': '10.6564', 'name': 'Thondamanallur,Pudukkottai,Tamil Nadu', 'lon': '78.6969'},
      '210121': {'lat': '25.5933', 'name': 'Beonja,Banda,Uttar Pradesh', 'lon': '80.6599'},
      '445302': {'lat': '20.0461', 'name': 'Sonbardi,Yavatmal,Maharashtra', 'lon': '78.5128'},
      '445303': {'lat': '20.121', 'name': 'Hiwra Majra,Yavatmal,Maharashtra', 'lon': '78.8759'},
      '445301': {'lat': '19.9674', 'name': 'Patapangra,Yavatmal,Maharashtra', 'lon': '78.2297'},
      '445306': {'lat': '19.9516', 'name': 'Zatala,Yavatmal,Maharashtra', 'lon': '78.3118'},
      '445307': {'lat': '19.8537', 'name': 'Sakhra Kolgaon,Yavatmal,Maharashtra', 'lon': '78.9984'},
      '445304': {'lat': '19.9682', 'name': 'Ghonsa,Yavatmal,Maharashtra', 'lon': '78.819'},
      '445305': {'lat': '19.9353', 'name': 'Chanakha,Yavatmal,Maharashtra', 'lon': '78.5353'},
      '732140': {'lat': '25.2142', 'name': 'Bhatolchandipur,Malda,West Bengal', 'lon': '87.8895'},
      '445308': {'lat': '20.2674', 'name': 'Munzala,Yavatmal,Maharashtra', 'lon': '78.4864'},
      '509207': {'lat': '17.0753', 'name': 'Uttaraspally,Mahabub Nagar,Telangana', 'lon': '78.0839'},
      '609113': {'lat': '11.24', 'name': 'Koolaiyar,Nagapattinam,Tamil Nadu', 'lon': '79.8371'},
      '801109': {'lat': '25.5064', 'name': 'Ch.Dariapur,Patna,Bihar', 'lon': '84.9506'},
      '845306': {'lat': '26.6353', 'name': 'Ghorpakari,West Champaran,Bihar', 'lon': '84.6932'},
      '534350': {'lat': '17.0509', 'name': 'Kovvur H.O,West Godavari,Andhra Pradesh', 'lon': '81.5554'},
      '609112': {'lat': '11.1955', 'name': 'Seithur Srk,Nagapattinam,Tamil Nadu', 'lon': '79.6606'},
      '232328': {'lat': '25.6224', 'name': 'Pakari,Ghazipur,Uttar Pradesh', 'lon': '83.5562'},
      '232329': {'lat': '25.4317', 'name': 'Umarganj Nariaon,Ghazipur,Uttar Pradesh', 'lon': '83.5976'},
      '845307': {'lat': '26.9848', 'name': 'Behra,West Champaran,Bihar', 'lon': '84.6287'},
      '477116': {'lat': '26.2895', 'name': 'Pali Darman,Bhind,Madhya Pradesh', 'lon': '78.3558'},
      '682558': {'lat': '9.1858', 'name': 'Kiltan,Lakshadweep,Lakshadweep', 'lon': '73.3524'},
      '524342': {'lat': '14.3863', 'name': 'Adurupalli,Nellore,Andhra Pradesh', 'lon': '79.5648'},
      '524343': {'lat': '14.4607', 'name': 'Telugurayapuram,Nellore,Andhra Pradesh', 'lon': '79.4027'},
      '524344': {'lat': '14.2946', 'name': 'Damayapalem,Nellore,Andhra Pradesh', 'lon': '80.0937'},
      '524345': {'lat': '14.4772', 'name': 'Kanuparthi,Nellore,Andhra Pradesh', 'lon': '79.7644'},
      '524346': {'lat': '14.3537', 'name': 'Kalvelapalem,Nellore,Andhra Pradesh', 'lon': '80.0636'},
      '682559': {'lat': '8.289', 'name': 'Minicoy,Lakshadweep,Lakshadweep', 'lon': '73.0617'},
      '362002': {'lat': '23.0956', 'name': 'Nandarkhi,Junagadh,Gujarat', 'lon': '72.1775'},
      '362001': {'lat': '23.0956', 'name': 'Junagadh H.O,Junagadh,Gujarat', 'lon': '72.1775'},
      '560116': {'lat': '13.0187', 'name': 'Bolare,Bengaluru,Karnataka', 'lon': '77.1351'},
      '305814': {'lat': '27.0195', 'name': 'Kotdi,Ajmer,Rajasthan', 'lon': '75.0023'},
      '609117': {'lat': '11.712', 'name': 'Vaithiswarankoil West,Nagapattinam,Tamil Nadu', 'lon': '79.5028'},
      '125121': {'lat': '29.3752', 'name': 'Sarhera,Hisar,Haryana', 'lon': '75.828'},
      '125120': {'lat': '29.2151', 'name': 'Gajuwala,Hisar,Haryana', 'lon': '75.8156'},
      '679501': {'lat': '10.825', 'name': 'Taruvakonam,Palakkad,Kerala', 'lon': '76.3637'},
      '753011': {'lat': '20.4333', 'name': 'Uttampur,Cuttack,Odisha', 'lon': '85.9'},
      '679503': {'lat': '10.8368', 'name': 'Panniyamkurissi,Palakkad,Kerala', 'lon': '76.336'},
      '679502': {'lat': '10.8368', 'name': 'Munnurkode,Palakkad,Kerala', 'lon': '76.336'},
      '679505': {'lat': '10.8228', 'name': 'Chalavara,Palakkad,Kerala', 'lon': '76.3002'},
      '679504': {'lat': '10.8368', 'name': 'Karumanamkurissi,Palakkad,Kerala', 'lon': '76.336'},
      '679506': {'lat': '10.8952', 'name': 'Karalmanna,Palakkad,Kerala', 'lon': '76.3119'},
      '686041': {'lat': '9.5713', 'name': 'Malloossery,Kottayam,Kerala', 'lon': '76.5199'},
      '473638': {'lat': '25.4244', 'name': 'Katthamil Shivpuri,Shivpuri,Madhya Pradesh', 'lon': '78.2258'},
      '466116': {'lat': '23.0112', 'name': 'Bhoura,Sehore,Madhya Pradesh', 'lon': '76.7121'},
      '364525': {'lat': '21.447', 'name': 'Virdi,Amreli,Gujarat', 'lon': '71.7423'},
      '364250': {'lat': '21.9838', 'name': 'Kumbhan,Bhavnagar,Gujarat', 'lon': '73.0712'},
      '364521': {'lat': '21.447', 'name': 'Simran,Amreli,Gujarat', 'lon': '71.7423'},
      '303503': {'lat': '27.1421', 'name': 'Kalyanpura,Jaipur,Rajasthan', 'lon': '75.5911'},
      '303502': {'lat': '26.4236', 'name': 'Denda Basedi,Dausa,Rajasthan', 'lon': '76.3897'},
      '303501': {'lat': '26.6476', 'name': 'Jhanpdawas,Dausa,Rajasthan', 'lon': '76.3529'},
      '517297': {'lat': '14.3466', 'name': 'Kanumalopalle,Chittoor,Andhra Pradesh', 'lon': '78.7637'},
      '303507': {'lat': '27.0083', 'name': 'Nabhawala,Jaipur,Rajasthan', 'lon': '75.3311'},
      '517291': {'lat': '13.598', 'name': 'T.Rachapalle,Chittoor,Andhra Pradesh', 'lon': '78.4858'},
      '303505': {'lat': '26.6936', 'name': 'Koliwara,Dausa,Rajasthan', 'lon': '76.336'},
      '303504': {'lat': '26.5595', 'name': 'Mahariya,Dausa,Rajasthan', 'lon': '76.3903'},
      '303509': {'lat': '26.938', 'name': 'Udaipuria,Jaipur,Rajasthan', 'lon': '76.4502'},
      '303508': {'lat': '26.9176', 'name': 'Namner,Dausa,Rajasthan', 'lon': '76.6916'},
      '517299': {'lat': '14.1825', 'name': 'Jarravaripalle,Chittoor,Andhra Pradesh', 'lon': '78.7283'},
      '414006': {'lat': '19.4913', 'name': 'Vahannagar,Ahmed Nagar,Maharashtra', 'lon': '74.7761'},
      '414005': {'lat': '19.2306', 'name': 'Bhoyare Pathar,Ahmed Nagar,Maharashtra', 'lon': '74.6025'},
      '768040': {'lat': '21.3192', 'name': 'Adagaon,Bargarh,Odisha', 'lon': '83.6609'},
      '762027': {'lat': '20.4169', 'name': 'Linepada,Kandhamal,Odisha', 'lon': '84.3034'},
      '768042': {'lat': '21.0771', 'name': 'Gourmal,Bargarh,Odisha', 'lon': '82.7624'},
      '768045': {'lat': '21.5893', 'name': 'Kandalapala,Sambalpur,Odisha', 'lon': '83.4154'},
      '762020': {'lat': '20.6285', 'name': 'Sankulei,Boudh,Odisha', 'lon': '84.6247'},
      '762023': {'lat': '20.5814', 'name': 'Adenigarh,Boudh,Odisha', 'lon': '84.4232'},
      '391350': {'lat': '22.854', 'name': 'Sankarda,Vadodara,Gujarat', 'lon': '73.4677'},
      '768049': {'lat': '21.0318', 'name': 'Phalsapali,Bargarh,Odisha', 'lon': '83.213'},
      '414003': {'lat': '19.0512', 'name': 'Savedi Road,Ahmed Nagar,Maharashtra', 'lon': '75.1407'},
      '762029': {'lat': '20.3726', 'name': 'Pakanagaon,Kandhamal,Odisha', 'lon': '84.3753'},
      '762028': {'lat': '20.238', 'name': 'Beallapadar,Kandhamal,Odisha', 'lon': '84.505'},
      '251320': {'lat': '29.439', 'name': 'Ramraj,Muzaffarnagar,Uttar Pradesh', 'lon': '77.5923'},
      '695507': {'lat': '8.5078', 'name': 'Ooruttambalam,Thiruvananthapuram,Kerala', 'lon': '77.0711'},
      '695506': {'lat': '8.5078', 'name': 'Kakkavila,Thiruvananthapuram,Kerala', 'lon': '77.0711'},
      '695505': {'lat': '8.4601', 'name': 'Kudappanamoodu,Thiruvananthapuram,Kerala', 'lon': '77.1921'},
      '695504': {'lat': '8.4805', 'name': 'Cheriakolla,Thiruvananthapuram,Kerala', 'lon': '77.0691'},
      '695503': {'lat': '8.5078', 'name': 'Manchavilakom,Thiruvananthapuram,Kerala', 'lon': '77.0711'},
      '695502': {'lat': '8.5078', 'name': 'Ayira,Thiruvananthapuram,Kerala', 'lon': '77.0711'},
      '695501': {'lat': '8.5578', 'name': 'Vedivachankoil,Thiruvananthapuram,Kerala', 'lon': '76.9918'},
      '495556': {'lat': '21.9262', 'name': 'Bilari,Janjgir-champa,Chattisgarh', 'lon': '82.7379'},
      '226003': {'lat': '26.9671', 'name': 'Amberganj,Lucknow,Uttar Pradesh', 'lon': '81.0187'},
      '805131': {'lat': '25.5087', 'name': 'S Nawada,Gaya,Bihar', 'lon': '85.294'},
      '695508': {'lat': '8.5078', 'name': 'Parasuvaickal,Thiruvananthapuram,Kerala', 'lon': '77.0711'},
      '805135': {'lat': '25.238', 'name': 'Ayer,Gaya,Bihar', 'lon': '85.458'},
      '638752': {'lat': '11.1689', 'name': 'Uttukuli Rs,Erode,Tamil Nadu', 'lon': '77.4543'},
      '638751': {'lat': '11.1689', 'name': 'Dalavoipalayam,Erode,Tamil Nadu', 'lon': '77.4543'},
      '614208': {'lat': '10.6716', 'name': 'Kiliyur,Thanjavur,Tamil Nadu', 'lon': '79.2413'},
      '743271': {'lat': '23.2312', 'name': 'Sonakenia,North 24 Parganas,West Bengal', 'lon': '88.4785'},
      '797118': {'lat': '25.9288', 'name': 'Thahekhu,Dimapur,Nagaland', 'lon': '94.3486'},
      '743273': {'lat': '23.1406', 'name': 'East Bishnupur,North 24 Parganas,West Bengal', 'lon': '88.5427'},
      '743272': {'lat': '22.8633', 'name': 'Sendanga,North 24 Parganas,West Bengal', 'lon': '88.6131'},
      '797115': {'lat': '25.9288', 'name': 'Sugar Mill Project,Dimapur,Nagaland', 'lon': '94.3486'},
      '797114': {'lat': '25.6705', 'name': 'Hutsu,Phek,Nagaland', 'lon': '94.6408'},
      '797117': {'lat': '25.9288', 'name': 'Pwd Colony,Dimapur,Nagaland', 'lon': '94.3486'},
      '797116': {'lat': '25.9288', 'name': 'Padampukhuri,Dimapur,Nagaland', 'lon': '94.3486'},
      '797111': {'lat': '26.1927', 'name': 'Doyang,Wokha,Nagaland', 'lon': '94.2654'},
      '797110': {'lat': '25.9288', 'name': 'Samjiuram,Peren,Nagaland', 'lon': '94.3486'},
      '797113': {'lat': '25.9288', 'name': 'Lengrijan,Dimapur,Nagaland', 'lon': '94.3486'},
      '797112': {'lat': '25.9117', 'name': 'Singrijan,Dimapur,Nagaland', 'lon': '93.7217'},
      '574235': {'lat': '12.7978', 'name': 'Alike,Dakshina Kannada,Karnataka', 'lon': '75.4875'},
      '574234': {'lat': '12.7978', 'name': 'Sampaje Gate,Kodagu,Karnataka', 'lon': '75.4875'},
      '574237': {'lat': '12.9799', 'name': 'Puchamogru,Dakshina Kannada,Karnataka', 'lon': '75.0178'},
      '574236': {'lat': '12.7978', 'name': 'Marody,Dakshina Kannada,Karnataka', 'lon': '75.4875'},
      '574231': {'lat': '12.8846', 'name': 'Shamboor,Dakshina Kannada,Karnataka', 'lon': '75.0542'},
      '574230': {'lat': '12.7418', 'name': 'Mardala,Dakshina Kannada,Karnataka', 'lon': '75.4991'},
      '574233': {'lat': '12.9024', 'name': 'Tenkakajekar,Dakshina Kannada,Karnataka', 'lon': '75.1828'},
      '388570': {'lat': '22.3607', 'name': 'Ras,Anand,Gujarat', 'lon': '72.8187'},
      '212305': {'lat': '25.2714', 'name': 'Bigahuna,Allahabad,Uttar Pradesh', 'lon': '82.0979'},
      '263619': {'lat': '29.8006', 'name': 'Tachhani,Bageshwar,Uttarakhand', 'lon': '79.704'},
      '574239': {'lat': '12.6263', 'name': 'Sullia Gandhinagar,Dakshina Kannada,Karnataka', 'lon': '75.3727'},
      '574238': {'lat': '13.1529', 'name': 'Yenekal,Dakshina Kannada,Karnataka', 'lon': '76.5854'},
      '495663': {'lat': '21.9298', 'name': 'Gopal Nagar,Bilaspur(CGH),Chattisgarh', 'lon': '82.3462'},
      '272176': {'lat': '26.6869', 'name': 'Kali Jagdishpur,Sant Kabir Nagar,Uttar Pradesh', 'lon': '82.9061'},
      '679357': {'lat': '10.9328', 'name': 'Vazhenkada,Malappuram,Kerala', 'lon': '76.268'},
      '444805': {'lat': '21.1283', 'name': 'Chhota Bazar,Amravati,Maharashtra', 'lon': '77.5561'},
      '212664': {'lat': '25.6618', 'name': 'SARAI SAHJADA,Fatehpur,Uttar Pradesh', 'lon': '81.1881'},
      '743289': {'lat': '23.8794', 'name': 'Simulpur,North 24 Parganas,West Bengal', 'lon': '88.2197'},
      '384325': {'lat': '23.1683', 'name': 'Balad,Mahesana,Gujarat', 'lon': '72.9548'},
      '283152': {'lat': '27.3966', 'name': 'Katana,Firozabad,Uttar Pradesh', 'lon': '78.5575'},
      '384320': {'lat': '23.7808', 'name': 'Umta,Mahesana,Gujarat', 'lon': '72.5553'},
      '394660': {'lat': '21.5548', 'name': 'Centrap Pulp Mill,Surat,Gujarat', 'lon': '73.1893'},
      '360405': {'lat': '22.4603', 'name': 'Sajadiyali,Rajkot,Gujarat', 'lon': '71.1706'},
      '174503': {'lat': '31.4817', 'name': 'Pubowal,Una,Himachal Pradesh', 'lon': '76.5624'},
      '743287': {'lat': '23.3901', 'name': 'Colony Ramchandra Pur,North 24 Parganas,West Bengal', 'lon': '88.4818'},
      '174507': {'lat': '32.0479', 'name': 'Bathri,Una,Himachal Pradesh', 'lon': '76.0077'},
      '174505': {'lat': '31.618644', 'name': 'Changar,Hamirpur(HP),Himachal Pradesh', 'lon': '76.5042308'},
      '847231': {'lat': '25.8906', 'name': 'Bhour,Madhubani,Bihar', 'lon': '86.1899'},
      '574150': {'lat': '12.9859', 'name': 'Punaroor,Dakshina Kannada,Karnataka', 'lon': '75.0862'},
      '574151': {'lat': '12.9558', 'name': 'Malalipanchayath,Dakshina Kannada,Karnataka', 'lon': '74.9175'},
      '574153': {'lat': '12.7967', 'name': 'Kairangala,Dakshina Kannada,Karnataka', 'lon': '74.9469'},
      '574154': {'lat': '13.0419', 'name': 'Mulki East,Dakshina Kannada,Karnataka', 'lon': '74.8841'},
      '847232': {'lat': '26.5906', 'name': 'Sidhhap Parsahi,Madhubani,Bihar', 'lon': '86.2836'},
      '333707': {'lat': '26.1043', 'name': 'Sotwara,Jhujhunu,Rajasthan', 'lon': '75.2989'},
      '333704': {'lat': '28.0771', 'name': 'Maharadasi,Jhujhunu,Rajasthan', 'lon': '75.038'},
      '752050': {'lat': '20.4382', 'name': 'Bhimpur padanpur,Khorda,Odisha', 'lon': '85.2667'},
      '752057': {'lat': '20.3715', 'name': 'Keranga,Khorda,Odisha', 'lon': '85.227'},
      '752056': {'lat': '20.1172', 'name': 'Dadhimachhagadia,Khorda,Odisha', 'lon': '85.3827'},
      '752055': {'lat': '20.2782', 'name': 'Khorda H.O,Khorda,Odisha', 'lon': '85.3261'},
      '752054': {'lat': '20.155', 'name': 'Reta R.S.,Khorda,Odisha', 'lon': '85.4611'},
      '583268': {'lat': '14.1456', 'name': 'Pragathinagar,Gadag,Karnataka', 'lon': '75.3724'},
      '847235': {'lat': '26.3713', 'name': 'Gangdwar,Madhubani,Bihar', 'lon': '86.2385'},
      '226007': {'lat': '26.8507', 'name': 'New Hyderabad,Lucknow,Uttar Pradesh', 'lon': '80.9942'},
      '212308': {'lat': '25.3267', 'name': 'NTPC Kohdar Meja,Allahabad,Uttar Pradesh', 'lon': '82.1474'},
      '476355': {'lat': '25.5542', 'name': 'Sunswada,Sheopur,Madhya Pradesh', 'lon': '77.3573'},
      '500081': {'lat': '17.4411', 'name': 'Cyberabad,Hyderabad,Telangana', 'lon': '78.3885'},
      '523183': {'lat': '16.0305', 'name': 'N.G.Padu,Prakasam,Andhra Pradesh', 'lon': '79.6747'},
      '523182': {'lat': '15.5462', 'name': 'Karavadi,Prakasam,Andhra Pradesh', 'lon': '80.1145'},
      '523181': {'lat': '15.7261', 'name': 'Gonasapudu,Prakasam,Andhra Pradesh', 'lon': '80.2547'},
      '523180': {'lat': '15.5802', 'name': 'Chavatapalem,Prakasam,Andhra Pradesh', 'lon': '80.1488'},
      '523187': {'lat': '15.785', 'name': 'Desaipet,Prakasam,Andhra Pradesh', 'lon': '80.3066'},
      '523186': {'lat': '15.7158', 'name': 'Pedaganjam,Prakasam,Andhra Pradesh', 'lon': '80.1465'},
      '523185': {'lat': '15.7897', 'name': 'Jarbulavaripale,Prakasam,Andhra Pradesh', 'lon': '80.2459'},
      '523184': {'lat': '15.6952', 'name': 'Motupalli,Prakasam,Andhra Pradesh', 'lon': '80.0974'},
      '713381': {'lat': '23.8832', 'name': 'Nutandanga,Purba Bardhaman,West Bengal', 'lon': '87.4873'},
      '713384': {'lat': '23.8832', 'name': 'Parasea,Purba Bardhaman,West Bengal', 'lon': '87.4873'},
      '713385': {'lat': '23.8832', 'name': 'Jhanjhra,Burdwan,West Bengal', 'lon': '87.4873'},
      '713386': {'lat': '23.8832', 'name': 'Rupnarayanpur Bazar,Paschim Bardhaman,West Bengal', 'lon': '87.4873'},
      '847239': {'lat': '26.1169', 'name': 'Dahaura,Darbhanga,Bihar', 'lon': '86.0833'},
      '461775': {'lat': '22.7708', 'name': 'Surelakala,Hoshangabad,Madhya Pradesh', 'lon': '78.3961'},
      '273304': {'lat': '27.0012', 'name': 'Doma Khas,Maharajganj,Uttar Pradesh', 'lon': '83.4521'},
      '273306': {'lat': '26.9322', 'name': 'Chiuthan,Gorakhpur,Uttar Pradesh', 'lon': '82.9367'},
      '461771': {'lat': '22.8645', 'name': 'Niwari,Narsinghpur,Madhya Pradesh', 'lon': '79.1292'},
      '848505': {'lat': '25.7426', 'name': 'Darwa Mathurapur,Samastipur,Bihar', 'lon': '85.6877'},
      '273303': {'lat': '26.9506', 'name': 'Collectrate Compound Maharajganj,Gorakhpur,Uttar Pradesh', 'lon': '83.3823'},
      '273302': {'lat': '26.9515', 'name': 'Purena Khandi Chauri,Maharajganj,Uttar Pradesh', 'lon': '83.504'},
      '754131': {'lat': '21.1001', 'name': 'Khentalo,Cuttack,Odisha', 'lon': '84.9815'},
      '151004': {'lat': '30.4262', 'name': 'Bathinda Cantt,Bathinda,Punjab', 'lon': '74.8657'},
      '151003': {'lat': '30.4262', 'name': 'Nfl Bathinda,Bathinda,Punjab', 'lon': '74.8657'},
      '151002': {'lat': '30.4262', 'name': 'Deon,Bathinda,Punjab', 'lon': '74.8657'},
      '151001': {'lat': '30.4262', 'name': 'Ind. Estate Bathinda,Bathinda,Punjab', 'lon': '74.8657'},
      '572141': {'lat': '14.0514', 'name': 'Y N Hoskote,Tumakuru,Karnataka', 'lon': '77.199'},
      '482005': {'lat': '23.2288', 'name': 'Ranjhi Azad Nagar,Jabalpur,Madhya Pradesh', 'lon': '80.0838'},
      '721601': {'lat': '24.3588', 'name': 'Naba Anantapur,East Midnapore,West Bengal', 'lon': '87.8041'},
      '732125': {'lat': '25.3579', 'name': 'Miahat,Malda,West Bengal', 'lon': '87.8677'},
      '203411': {'lat': '28.6263', 'name': 'Ahemedanagar,Bulandshahr,Uttar Pradesh', 'lon': '77.8278'},
      '848501': {'lat': '25.5152', 'name': 'Sultanpur West,Samastipur,Bihar', 'lon': '85.7078'},
      '572142': {'lat': '12.998', 'name': 'Thippur,Tumakuru,Karnataka', 'lon': '76.8971'},
      '503001': {'lat': '16.6605', 'name': 'Phullon,Nizamabad,Telangana', 'lon': '77.9775'},
      '503002': {'lat': '18.6828', 'name': 'Gurbabadi,Nizamabad,Telangana', 'lon': '78.1153'},
      '503003': {'lat': '18.6283', 'name': 'Yellammagutta,Nizamabad,Telangana', 'lon': '78.1082'},
      '643225': {'lat': '11.5524', 'name': 'Rockwood Estate,Nilgiris,Tamil Nadu', 'lon': '76.4176'},
      '643224': {'lat': '11.4808', 'name': 'T R Bazaar,Nilgiris,Tamil Nadu', 'lon': '76.5437'},
      '643226': {'lat': '11.5338', 'name': 'Glenvans,Nilgiris,Tamil Nadu', 'lon': '76.5361'},
      '643221': {'lat': '11.5338', 'name': 'Manjacombai Bazaar,Nilgiris,Tamil Nadu', 'lon': '76.5361'},
      '341517': {'lat': '26.6374', 'name': 'Ladariya,Nagaur,Rajasthan', 'lon': '74.5327'},
      '643223': {'lat': '11.5682', 'name': 'Mavanahalla,Nilgiris,Tamil Nadu', 'lon': '76.6471'},
      '734004': {'lat': '26.731', 'name': 'Dabgram,Jalpaiguri,West Bengal', 'lon': '88.9486'},
      '813204': {'lat': '25.2822', 'name': 'Trimohan,Bhagalpur,Bihar', 'lon': '87.2831'},
      '813205': {'lat': '24.8537', 'name': 'Pothia,Banka,Bihar', 'lon': '86.8409'},
      '813206': {'lat': '25.2528', 'name': 'Roshanpur,Bhagalpur,Bihar', 'lon': '87.3669'},
      '813207': {'lat': '25.21', 'name': 'Beldiha,Munger,Bihar', 'lon': '86.5274'},
      '764020': {'lat': '18.9973', 'name': 'Koraput Collectorate,Koraput,Odisha', 'lon': '83.0688'},
      '643228': {'lat': '11.5338', 'name': 'Periashola,Nilgiris,Tamil Nadu', 'lon': '76.5361'},
      '732124': {'lat': '24.3409', 'name': 'Hatimari,Malda,West Bengal', 'lon': '87.9885'},
      '604210': {'lat': '12.3162', 'name': 'Sholangunam,Villupuram,Tamil Nadu', 'lon': '79.4075'},
      '620006': {'lat': '10.8711', 'name': 'Renganagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.665'},
      '573128': {'lat': '13.1354', 'name': 'Dundanayakanahalli,Hassan,Karnataka', 'lon': '76.0939'},
      '620004': {'lat': '10.7823', 'name': 'Ambikapuram (Tiruchirappalli),Tiruchirappalli,Tamil Nadu', 'lon': '78.7245'},
      '620005': {'lat': '10.849', 'name': 'Kondayampettai,Tiruchirappalli,Tamil Nadu', 'lon': '78.7428'},
      '620002': {'lat': '10.8566', 'name': 'Rock Fort,Tiruchirappalli,Tamil Nadu', 'lon': '78.5278'},
      '385320': {'lat': '24.0716', 'name': 'Chatra,Banaskantha,Gujarat', 'lon': '71.5974'},
      '620001': {'lat': '10.8566', 'name': 'Ponnagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.5278'},
      '461111': {'lat': '22.5883', 'name': 'Luharia,Hoshangabad,Madhya Pradesh', 'lon': '77.9889'},
      '461110': {'lat': '22.7', 'name': 'Reshalpur,Hoshangabad,Madhya Pradesh', 'lon': '77.7333'},
      '461115': {'lat': '22.8571', 'name': 'NYC Itarsi,Hoshangabad,Madhya Pradesh', 'lon': '77.5111'},
      '673620': {'lat': '11.4828', 'name': 'Urallur,Kozhikode,Kerala', 'lon': '75.7241'},
      '620008': {'lat': '11.324', 'name': 'Clock Tower (Tiruchirappalli),Tiruchirappalli,Tamil Nadu', 'lon': '77.548'},
      '620009': {'lat': '10.7643', 'name': 'Ramjeenagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.6079'},
      '201309': {'lat': '28.6302', 'name': 'Noida Sector 62,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.3918'},
      '208027': {'lat': '26.4302', 'name': 'Barra Vishwa Bank Sector-A,Kanpur Nagar,Uttar Pradesh', 'lon': '80.2963'},
      '442202': {'lat': '21.077', 'name': 'Manikwara,Wardha,Maharashtra', 'lon': '78.1808'},
      '442203': {'lat': '20.8996', 'name': 'Umri,Wardha,Maharashtra', 'lon': '78.3996'},
      '201306': {'lat': '28.5506', 'name': 'I.A. Surajpur,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.4375'},
      '442201': {'lat': '21.056', 'name': 'Talegaon(SP),Wardha,Maharashtra', 'lon': '78.2178'},
      '201301': {'lat': '28.5569', 'name': 'Noida H.O,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.528'},
      '201303': {'lat': '28.5569', 'name': 'Noida Sector 45,Gautam Buddha Nagar,Uttar Pradesh', 'lon': '77.528'},
      '521311': {'lat': '16.4568', 'name': 'Indupalli,Krishna,Andhra Pradesh', 'lon': '80.88'},
      '521312': {'lat': '16.3502', 'name': 'Koyyagurapadu,Krishna,Andhra Pradesh', 'lon': '80.6252'},
      '382860': {'lat': '23.2191', 'name': 'Sundarpur,Mahesana,Gujarat', 'lon': '73.0873'},
      '680008': {'lat': '10.4712', 'name': 'Cherur,Thrissur,Kerala', 'lon': '76.3162'},
      '680009': {'lat': '10.5179', 'name': 'Thrissur Engg College,Thrissur,Kerala', 'lon': '76.2204'},
      '382865': {'lat': '23.1555', 'name': 'Dabhala,Mahesana,Gujarat', 'lon': '73.7794'},
      '624612': {'lat': '10.4208', 'name': 'Chinnamandavadi,Dindigul,Tamil Nadu', 'lon': '77.7952'},
      '680004': {'lat': '10.5179', 'name': 'West Fort,Thrissur,Kerala', 'lon': '76.2204'},
      '680005': {'lat': '10.5179', 'name': 'Thrissur East,Thrissur,Kerala', 'lon': '76.2204'},
      '680006': {'lat': '10.507', 'name': 'Anchery,Thrissur,Kerala', 'lon': '76.2352'},
      '680555': {'lat': '10.5625', 'name': 'Amalanagar,Thrissur,Kerala', 'lon': '76.144'},
      '680552': {'lat': '10.5788', 'name': 'Pathiyarkulangara,Thrissur,Kerala', 'lon': '76.1231'},
      '680001': {'lat': '10.5179', 'name': 'Thrissur H.O,Thrissur,Kerala', 'lon': '76.2204'},
      '680002': {'lat': '10.5179', 'name': 'Punkunnu,Thrissur,Kerala', 'lon': '76.2204'},
      '680551': {'lat': '10.5453', 'name': 'Chittilapilly,Thrissur,Kerala', 'lon': '76.1522'},
      '852202': {'lat': '25.9414', 'name': 'Benghaha,Saharsa,Bihar', 'lon': '86.45'},
      '733128': {'lat': '25.37205', 'name': 'Kalaibari,South Dinajpur,West Bengal', 'lon': '88.17322'},
      '624610': {'lat': '10.419', 'name': 'Palarparundalar Dam,Dindigul,Tamil Nadu', 'lon': '77.4992'},
      '624617': {'lat': '10.5426', 'name': 'Appanuthu,Dindigul,Tamil Nadu', 'lon': '77.5985'},
      '732126': {'lat': '25.1664', 'name': 'Naikanda,Malda,West Bengal', 'lon': '87.9767'},
      '733121': {'lat': '25.5027175', 'name': 'Dikul,South Dinajpur,West Bengal', 'lon': '88.4972842'},
      '733126': {'lat': '25.20094', 'name': 'Hili Aftair,South Dinajpur,West Bengal', 'lon': '88.90829'},
      '733127': {'lat': '25.25694', 'name': 'Chakbaliram,South Dinajpur,West Bengal', 'lon':'88.5899'},
      '733124': {'lat': '25.454726', 'name': 'Sukdebpur,South Dinajpur,West Bengal', 'lon': '88.5087723'},
      '759022': {'lat': '20.6624', 'name': 'Phulapada,Dhenkanal,Odisha', 'lon': '85.1727'},
      '110040': {'lat': '28.8408', 'name': 'Kanya Gurukul,North West Delhi,Delhi', 'lon': '77.0939'},
      '110041': {'lat': '28.6735', 'name': 'Kunwar Singh Nagar,West Delhi,Delhi', 'lon': '77.014'},
      '110042': {'lat': '28.7581', 'name': 'Delhi Engg. College,North West Delhi,Delhi', 'lon': '77.1309'},
      '110043': {'lat': '28.6032', 'name': 'Pandwala Kalan,South West Delhi,Delhi', 'lon': '76.9672'},
      '110044': {'lat': '28.5027', 'name': 'Tugalkabad Railway Colony,South Delhi,Delhi', 'lon': '77.3084'},
      '110045': {'lat': '28.6035', 'name': 'Indira Park,South West Delhi,Delhi', 'lon': '77.0894'},
      '110046': {'lat': '28.6093', 'name': 'Nangal Raya,South West Delhi,Delhi', 'lon': '77.1118'},
      '110047': {'lat': '28.672', 'name': 'Mandi,South West Delhi,Delhi', 'lon': '77.0637'},
      '110048': {'lat': '28.5638', 'name': 'Kailash Colony,South Delhi,Delhi', 'lon': '77.2236'},
      '110049': {'lat': '28.672', 'name': 'Sahpurjat,South Delhi,Delhi', 'lon': '77.0637'},
      '624614': {'lat': '10.488', 'name': 'Manjanaickenpatti,Dindigul,Tamil Nadu', 'lon': '77.8627'},
      '562159': {'lat': '12.9904', 'name': 'GCEC Ramanagara,Ramanagar,Karnataka', 'lon': '77.0654'},
      '403114': {'lat': '15.5219', 'name': 'Reis Magos,North Goa,Goa', 'lon': '73.7881'},
      '734007': {'lat': '26.731', 'name': 'East Bhaktinagar,Jalpaiguri,West Bengal', 'lon': '88.9486'},
      '482001': {'lat': '23.2393', 'name': 'Kasturba Nagar (Jabalpur),Jabalpur,Madhya Pradesh', 'lon': '80.0021'},
      '825329': {'lat': '24.1341', 'name': 'Shikherjee,Giridh,Jharkhand', 'lon': '85.6754'},
      '825322': {'lat': '24.1', 'name': 'Khetko Murmu,Bokaro,Jharkhand', 'lon': '85.8886'},
      '841423': {'lat': '26.2854', 'name': 'Jhanjhawa Mohamadpur,Gopalganj,Bihar', 'lon': '84.5637'},
      '825320': {'lat': '24.1878', 'name': 'Keshwari,Giridh,Jharkhand', 'lon': '85.8411'},
      '825321': {'lat': '23.7429', 'name': 'Bahera,Chatra,Jharkhand', 'lon': '85.044'},
      '825326': {'lat': '24.1341', 'name': 'Tapin,Ramgarh,Jharkhand', 'lon': '85.6754'},
      '825324': {'lat': '24.2745', 'name': 'Jawaharnagar,Giridh,Jharkhand', 'lon': '85.9582'},
      '325601': {'lat': '24.9235', 'name': 'Kamolar,Kota,Rajasthan', 'lon': '76.362'},
      '460551': {'lat': '22.1812', 'name': 'Kutkhedai,Betul,Madhya Pradesh', 'lon': '77.9185'},
      '325602': {'lat': '24.9817', 'name': 'Darrah,Kota,Rajasthan', 'lon': '76.2669'},
      '523169': {'lat': '15.9135', 'name': 'Parchur,Prakasam,Andhra Pradesh', 'lon': '80.2217'},
      '841420': {'lat': '26.5255', 'name': 'Paharpur,Gopalganj,Bihar', 'lon': '84.2292'},
      '581396': {'lat': '14.9053', 'name': 'Kadra Bazar,Uttara Kannada,Karnataka', 'lon': '74.3459'},
      '141416': {'lat': '30.7531', 'name': 'Barmalipur,Ludhiana,Punjab', 'lon': '76.0743'},
      '208022': {'lat': '26.418', 'name': 'Ratan Lal Nagar,Kanpur Nagar,Uttar Pradesh', 'lon': '80.298'},
      '560098': {'lat': '13.1077', 'name': 'Rajarajeshwarinagar,Bengaluru,Karnataka', 'lon': '77.581'},
      '454449': {'lat': '23.0116', 'name': 'Zirvi,Dhar,Madhya Pradesh', 'lon': '75.7321'},
      '560099': {'lat': '13.0427', 'name': 'Bommasandra Industrial Estate,Bengaluru,Karnataka', 'lon': '77.5894'},
      '761100': {'lat': '20.1049', 'name': 'Ankushpur,Ganjam,Odisha', 'lon': '85.0853'},
      '670567': {'lat': '11.9554', 'name': 'Kannur University Campus,Kannur,Kerala', 'lon': '75.352'},
      '670561': {'lat': '11.9554', 'name': 'Pappinisseri West,Kannur,Kerala', 'lon': '75.352'},
      '670562': {'lat': '11.9554', 'name': 'Kanul,Kannur,Kerala', 'lon': '75.352'},
      '670563': {'lat': '11.9554', 'name': 'Nanichery,Kannur,Kerala', 'lon': '75.352'},
      '631553': {'lat': '12.9364', 'name': 'Melmaduramangalam,Kanchipuram,Tamil Nadu', 'lon': '79.7917'},
      '799205': {'lat': '24.007', 'name': 'Uttar Gakulnagar,West Tripura,Tripura', 'lon': '91.6093'},
      '613301': {'lat': '10.7706', 'name': 'Kovilur (Gandarvakottai),Pudukkottai,Tamil Nadu', 'lon': '79.1203'},
      '306103': {'lat': '25.8441', 'name': 'Hariyamali,Pali,Rajasthan', 'lon': '73.8406'},
      '484330': {'lat': '22.9604', 'name': 'Laharpur,Anuppur,Madhya Pradesh', 'lon': '81.3011'},
      '301028': {'lat': '27.5853', 'name': 'Kherajahar,Alwar,Rajasthan', 'lon': '76.682'},
      '731221': {'lat': '24.4823', 'name': 'Harowa,Birbhum,West Bengal', 'lon': '87.9454'},
      '301026': {'lat': '27.5665', 'name': 'KHERI,Alwar,Rajasthan', 'lon': '76.8202'},
      '301027': {'lat': '26.8761', 'name': 'Padakchapli,Alwar,Rajasthan', 'lon': '76.6853'},
      '431215': {'lat': '20.0701', 'name': 'Kedarkheda,Jalna,Maharashtra', 'lon': '75.8312'},
      '431214': {'lat': '19.8562', 'name': 'Seoli,Jalna,Maharashtra', 'lon': '76.0669'},
      '431213': {'lat': '19.8675', 'name': 'Midc Jalna,Jalna,Maharashtra', 'lon': '76.1141'},
      '431212': {'lat': '19.8793', 'name': 'Patharwala,Jalna,Maharashtra', 'lon': '76.0048'},
      '431211': {'lat': '19.4439', 'name': 'Anterwali Tembhi,Jalna,Maharashtra', 'lon': '76.1403'},
      '301021': {'lat': '27.4333', 'name': 'Molia,Alwar,Rajasthan', 'lon': '76.9291'},
      '382315': {'lat': '23.5972', 'name': 'Piplaj,Ahmedabad,Gujarat', 'lon': '72.9513'},
      '410301': {'lat': '18.7589', 'name': 'Khandala,Pune,Maharashtra', 'lon': '73.3694'},
      '410302': {'lat': '18.7589', 'name': 'R P T S Khandala,Pune,Maharashtra', 'lon': '73.3694'},
      '465669': {'lat': '23.6856', 'name': 'Aandalheda,Rajgarh,Madhya Pradesh', 'lon': '77.2403'},
      '509132': {'lat': '16.315', 'name': 'Maddelabanda,Mahabub Nagar,Telangana', 'lon': '77.9491'},
      '509133': {'lat': '16.4204', 'name': 'Kakulavaram,Mahabub Nagar,Telangana', 'lon': '77.6893'},
      '509130': {'lat': '16.4424', 'name': 'Nagal Kadmoor,Mahabub Nagar,Telangana', 'lon': '77.6883'},
      '509131': {'lat': '16.3527', 'name': 'Motlampally,Mahabub Nagar,Telangana', 'lon': '77.7231'},
      '480003': {'lat': '22.0342', 'name': 'Chandangaon,Chhindwara,Madhya Pradesh', 'lon': '78.9278'},
      '262702': {'lat': '28.1225', 'name': 'Gopalapur,Kheri,Uttar Pradesh', 'lon': '80.6671'},
      '262701': {'lat': '27.5683', 'name': 'Udaipur Mahewa,Kheri,Uttar Pradesh', 'lon': '80.7133'},
      '509135': {'lat': '15.9869', 'name': 'Tangal,Mahabub Nagar,Telangana', 'lon': '77.724'},
      '792129': {'lat': '27.3162', 'name': 'Hukanjuri,Tirap,Arunachal Pradesh', 'lon': '95.9869'},
      '560090': {'lat': '13.1077', 'name': 'Chikkabanavara,Bangalore Rural,Karnataka', 'lon': '77.581'},
      '632057': {'lat': '12.8123', 'name': 'Munjurpattu,Vellore,Tamil Nadu', 'lon': '79.0104'},
      '635002': {'lat': '12.5758', 'name': 'Pothinayanapalli,Krishnagiri,Tamil Nadu', 'lon': '78.2512'},
      '560091': {'lat': '13.2257', 'name': 'Herohalli,Bengaluru,Karnataka', 'lon': '77.575'},
      '632058': {'lat': '12.5642', 'name': 'Kattuputhur,Vellore,Tamil Nadu', 'lon': '79.6599'},
      '632059': {'lat': '12.6976', 'name': 'Periyapudhur,Vellore,Tamil Nadu', 'lon': '79.5709'},
      '835103': {'lat': '23.3731', 'name': 'Chatra,Ranchi,Jharkhand', 'lon': '85.4455'},
      '248121': {'lat': '30.6713', 'name': 'Banjarawala,Dehradun,Uttarakhand', 'lon': '77.8947'},
      '835102': {'lat': '23.3268', 'name': 'Bansiya,Ranchi,Jharkhand', 'lon': '85.7538'},
      '306901': {'lat': '25.2275', 'name': 'Bankli,Pali,Rajasthan', 'lon': '73.0295'},
      '306902': {'lat': '25.6373', 'name': 'Koliwara,Pali,Rajasthan', 'lon': '73.648'},
      '422622': {'lat': '19.3483', 'name': 'Hivargaon Pathar,Ahmed Nagar,Maharashtra', 'lon': '74.299'},
      '422620': {'lat': '18.7471', 'name': 'Aklapur,Ahmed Nagar,Maharashtra', 'lon': '74.638'},
      '396185': {'lat': '20.4854', 'name': 'Sarodhi,Valsad,Gujarat', 'lon': '72.8686'},
      '802351': {'lat': '25.5731', 'name': 'Lahang Dumaria,Bhojpur,Bihar', 'lon': '84.5838'},
      '802352': {'lat': '25.498', 'name': 'Akhagaon,Bhojpur,Bihar', 'lon': '84.4386'},
      '392155': {'lat': '22.7189', 'name': 'Jantran,Bharuch,Gujarat', 'lon': '73.1399'},
      '396180': {'lat': '20.4854', 'name': 'Udvada,Valsad,Gujarat', 'lon': '72.8686'},
      '392150': {'lat': '23.1192', 'name': 'Ankhi,Surat,Gujarat', 'lon': '73.0886'},
      '766032': {'lat': '19.8677', 'name': 'Rajpur,Kalahandi,Odisha', 'lon': '83.1092'},
      '763001': {'lat': '20.4682', 'name': 'Sunabeda-1,Koraput,Odisha', 'lon': '83.7161'},
      '763002': {'lat': '20.4682', 'name': 'Sector-VII(Sunabeda),Koraput,Odisha', 'lon': '83.7161'},
      '766031': {'lat': '20.0212', 'name': 'Risida,Kalahandi,Odisha', 'lon': '83.2012'},
      '766036': {'lat': '20.1587', 'name': 'Palna,Kalahandi,Odisha', 'lon': '83.277'},
      '766037': {'lat': '19.6831', 'name': 'Nakrundi,Kalahandi,Odisha', 'lon': '83.1806'},
      '854340': {'lat': '26.5', 'name': 'Banailipatti,Supaul,Bihar', 'lon': '87.0226'},
      '602024': {'lat': '13.0202', 'name': 'Komakkambedu,Tiruvallur,Tamil Nadu', 'lon': '80.0441'},
      '602025': {'lat': '13.0944', 'name': 'Papparambakkam,Tiruvallur,Tamil Nadu', 'lon': '79.9529'},
      '387365': {'lat': '22.9857', 'name': 'Kaniel,Kheda,Gujarat', 'lon': '73.0017'},
      '815352': {'lat': '24.0304', 'name': 'Pathrodih,Jamtara,Jharkhand', 'lon': '85.8211'},
      '602021': {'lat': '13.1877', 'name': 'Thalakancheri,Tiruvallur,Tamil Nadu', 'lon': '79.946'},
      '387360': {'lat': '22.9857', 'name': 'Marida,Kheda,Gujarat', 'lon': '73.0017'},
      '722160': {'lat': '22.9274', 'name': 'Bansarenga,Bankura,West Bengal', 'lon': '87.0137'},
      '722161': {'lat': '22.8117', 'name': 'Sihar,Bankura,West Bengal', 'lon': '88.0649'},
      '722162': {'lat': '22.9061', 'name': 'Barikul,Bankura,West Bengal', 'lon': '87.4788'},
      '431720': {'lat': '19.1553', 'name': 'Shikh Rajura,Parbhani,Maharashtra', 'lon': '76.7616'},
      '722164': {'lat': '22.9793', 'name': 'Jiabandi,Bankura,West Bengal', 'lon': '87.3579'},
      '670622': {'lat': '11.8547', 'name': 'Bavode,Kannur,Kerala', 'lon': '75.483'},
      '480337': {'lat': '21.4993', 'name': 'Seoni Sausar,Chhindwara,Madhya Pradesh', 'lon': '78.6644'},
      '680602': {'lat': '10.8252', 'name': 'Nambazhikad,Thrissur,Kerala', 'lon': '76.0621'},
      '172002': {'lat': '31.5459', 'name': 'Nishani,Kullu,Himachal Pradesh', 'lon': '77.4562'},
      '389240': {'lat': '23.346', 'name': 'Vagadia Ni Andhari,Panch Mahals,Gujarat', 'lon': '73.7832'},
      '625102': {'lat': '10.0826', 'name': 'Jayankondanilai,Madurai,Tamil Nadu', 'lon': '78.4413'},
      '764028': {'lat': '19.3149', 'name': 'Dambaguda,Koraput,Odisha', 'lon': '83.4683'},
      '326530': {'lat': '24.6461', 'name': 'Suket,Kota,Rajasthan', 'lon': '76.0417'},
      '629401': {'lat': '8.1531', 'name': 'Variyoor,Kanyakumari,Tamil Nadu', 'lon': '77.5683'},
      '629403': {'lat': '8.1437', 'name': 'Kulasekharapuram,Kanyakumari,Tamil Nadu', 'lon': '77.4957'},
      '629402': {'lat': '8.1512', 'name': 'Eraviputhur,Kanyakumari,Tamil Nadu', 'lon': '77.5345'},
      '628802': {'lat': '8.6511', 'name': 'Siruthondanallur,Tuticorin,Tamil Nadu', 'lon': '78.0518'},
      '454665': {'lat': '22.8263', 'name': 'Chhokla,Dhar,Madhya Pradesh', 'lon': '75.2482'},
      '313701': {'lat': '24.1764', 'name': 'Upreta,Udaipur,Rajasthan', 'lon': '73.3444'},
      '313702': {'lat': '24.6034', 'name': 'Khakhad,Udaipur,Rajasthan', 'lon': '73.6798'},
      '628809': {'lat': '9.0172', 'name': 'Vittilapuram,Tuticorin,Tamil Nadu', 'lon': '78.0136'},
      '636117': {'lat': '11.6978', 'name': 'A.Komarapalayam,Salem,Tamil Nadu', 'lon': '78.4648'},
      '636116': {'lat': '11.4873', 'name': 'Thedavur,Salem,Tamil Nadu', 'lon': '78.7467'},
      '636115': {'lat': '11.6396', 'name': 'West Rajapalayam,Salem,Tamil Nadu', 'lon': '78.4105'},
      '313703': {'lat': '24.3991', 'name': 'Bemla,Udaipur,Rajasthan', 'lon': '73.8594'},
      '636113': {'lat': '11.4161', 'name': 'Periyakombai,Namakkal,Tamil Nadu', 'lon': '78.4063'},
      '680604': {'lat': '10.6905', 'name': 'Chiramanangad,Thrissur,Kerala', 'lon': '76.1177'},
      '636111': {'lat': '12.2653', 'name': 'Mettupatti Karipatti,Salem,Tamil Nadu', 'lon': '78.1658'},
      '636110': {'lat': '11.3732', 'name': 'Manmalai,Salem,Tamil Nadu', 'lon': '78.5235'},
      '328041': {'lat': '26.7358', 'name': 'Gadhi Sukkha,Dholpur,Rajasthan', 'lon': '77.6684'},
      '535557': {'lat': '18.6096', 'name': 'Iruwada,Vizianagaram,Andhra Pradesh', 'lon': '83.5378'},
      '396235': {'lat': '20.2638', 'name': 'Nagvas,Valsad,Gujarat', 'lon': '72.9591'},
      '636119': {'lat': '11.119', 'name': 'Ariyampalayam,Salem,Tamil Nadu', 'lon': '79.774'},
      '396230': {'lat': '20.194', 'name': 'Khadoli,Valsad,Gujarat', 'lon': '73.0734'},
      '627353': {'lat': '8.738', 'name': 'Manappadaiveedu,Tirunelveli,Tamil Nadu', 'lon': '77.746'},
      '627352': {'lat': '8.7704', 'name': 'Kodiyankulam,Tirunelveli,Tamil Nadu', 'lon': '77.7054'},
      '627351': {'lat': '8.7586', 'name': 'Naduvakrichi,Tirunelveli,Tamil Nadu', 'lon': '77.8126'},
      '757018': {'lat': '21.5417', 'name': 'Dhansole,Mayurbhanj,Odisha', 'lon': '85.5795'},
      '757019': {'lat': '21.4347', 'name': 'Badkaranjia,Mayurbhanj,Odisha', 'lon': '85.6596'},
      '176028': {'lat': '32.0175', 'name': 'Bhatoli Phakorian,Kangra,Himachal Pradesh', 'lon': '76.1496'},
      '176029': {'lat': '32.0348', 'name': 'Thakurdawara,Kangra,Himachal Pradesh', 'lon': '76.2263'},
      '176026': {'lat': '32.0244', 'name': 'Masroor,Kangra,Himachal Pradesh', 'lon': '76.2238'},
      '176027': {'lat': '31.8987', 'name': 'Jarot,Kangra,Himachal Pradesh', 'lon': '76.3593'},
      '757016': {'lat': '20.719', 'name': 'Jharia,Mayurbhanj,Odisha', 'lon': '82.5434'},
      '176025': {'lat': '31.9736', 'name': 'Sathana,Kangra,Himachal Pradesh', 'lon': '75.9202'},
      '176022': {'lat': '32.0076', 'name': 'Rehan,Kangra,Himachal Pradesh', 'lon': '76.3224'},
      '176023': {'lat': '32.0721', 'name': 'Matlahar,Kangra,Himachal Pradesh', 'lon': '76.072'},
      '176021': {'lat': '32.0283', 'name': 'Sidhpur Ghar,Kangra,Himachal Pradesh', 'lon': '75.9573'},
      '500013': {'lat': '17.3172', 'name': 'Zindatelismath,Hyderabad,Telangana', 'lon': '79.408'},
      '535568': {'lat': '18.5553', 'name': 'Pinapenki,Vizianagaram,Andhra Pradesh', 'lon': '83.438'},
      '678598': {'lat': '10.4245', 'name': 'Poonchola,Palakkad,Kerala', 'lon': '76.5276'},
      '331506': {'lat': '27.7918', 'name': 'Norangsar,Churu,Rajasthan', 'lon': '75.1689'},
      '678595': {'lat': '10.9328', 'name': 'Elambulasseri,Palakkad,Kerala', 'lon': '76.44'},
      '678594': {'lat': '10.4245', 'name': 'Vallikkode,Palakkad,Kerala', 'lon': '76.5276'},
      '678597': {'lat': '10.9231', 'name': 'Karimba,Palakkad,Kerala', 'lon': '76.5189'},
      '678596': {'lat': '10.4245', 'name': 'Kalladikode,Palakkad,Kerala', 'lon': '76.5276'},
      '678591': {'lat': '10.4245', 'name': 'Irimbakachola,Palakkad,Kerala', 'lon': '76.5276'},
      '711115': {'lat': '22.6368', 'name': 'Jagadishpurhat,Howrah,West Bengal', 'lon': '88.288'},
      '678593': {'lat': '10.4245', 'name': 'Mudukurissi,Palakkad,Kerala', 'lon': '76.5276'},
      '678592': {'lat': '10.7946', 'name': 'Velikkad,Palakkad,Kerala', 'lon': '76.5586'},
      '711114': {'lat': '22.6344', 'name': 'Lakshmanpur,Howrah,West Bengal', 'lon': '88.2719'},
      '362610': {'lat': '23.2115', 'name': 'Zampodad,Junagadh,Gujarat', 'lon': '70.9135'},
      '403530': {'lat': '15.5238', 'name': 'Pissurlem,North Goa,Goa', 'lon': '74.0637'},
      '363530': {'lat': '22.5742', 'name': 'Tarnetar,Surendra Nagar,Gujarat', 'lon': '71.1994'},
      '462066': {'lat': '23.6859', 'name': 'IISER Bhouri,Bhopal,Madhya Pradesh', 'lon': '78.3942'},
      '721134': {'lat': '22.4325', 'name': 'Paikpari,East Midnapore,West Bengal', 'lon': '87.8594'},
      '534165': {'lat': '16.7708', 'name': 'Kakarlamudi,West Godavari,Andhra Pradesh', 'lon': '81.4694'},
      '534166': {'lat': '16.7582', 'name': 'Parimalla,West Godavari,Andhra Pradesh', 'lon': '81.4711'},
      '741162': {'lat': '24.5163', 'name': 'Dadupur Nadia,Nadia,West Bengal', 'lon': '87.8093'},
      '625105': {'lat': '10.1506', 'name': 'Kesampatti,Madurai,Tamil Nadu', 'lon': '78.2984'},
      '506317': {'lat': '17.5995', 'name': 'Peddavangara,Warangal,Telangana', 'lon': '79.5686'},
      '627120': {'lat': '8.4843', 'name': 'Anuvijay Township,Tirunelveli,Tamil Nadu', 'lon': '77.8968'},
      '506315': {'lat': '17.6824', 'name': 'Purushothamaigudem,Warangal,Telangana', 'lon': '79.8574'},
      '506314': {'lat': '17.6733', 'name': 'Perkaveid,Warangal,Telangana', 'lon': '79.578'},
      '721506': {'lat': '22.5918', 'name': 'Athangi,West Midnapore,West Bengal', 'lon': '88.5238'},
      '461881': {'lat': '22.4679', 'name': 'Pachmarhi,Hoshangabad,Madhya Pradesh', 'lon': '78.4331'},
      '273407': {'lat': '26.5273', 'name': 'Dhuripar,Gorakhpur,Uttar Pradesh', 'lon': '83.2493'},
      '391175': {'lat': '22.1074', 'name': 'Ambadungar,Vadodara,Gujarat', 'lon': '74.0649'},
      '229103': {'lat': '26.4625', 'name': 'Jamurawan,Raebareli,Uttar Pradesh', 'lon': '81.252'},
      '391170': {'lat': '22.1074', 'name': 'Navalja,Vadodara,Gujarat', 'lon': '74.0649'},
      '302041': {'lat': '26.9225', 'name': 'Mundia Rampura,Jaipur,Rajasthan', 'lon': '75.6226'},
      '302040': {'lat': '26.8805', 'name': 'Nangal Jaisabohara,Jaipur,Rajasthan', 'lon': '75.6577'},
      '302043': {'lat': '26.7965', 'name': 'Muhana,Jaipur,Rajasthan', 'lon': '75.728'},
      '302042': {'lat': '26.8805', 'name': 'Bad ke Balaji,Jaipur,Rajasthan', 'lon': '75.6577'},
      '342314': {'lat': '26.8199', 'name': 'Sagrau,Jodhpur,Rajasthan', 'lon': '72.3406'},
      '332402': {'lat': '27.2578', 'name': 'Mandha Madni,Sikar,Rajasthan', 'lon': '75.8405'},
      '484446': {'lat': '23.0567', 'name': 'Semra,Anuppur,Madhya Pradesh', 'lon': '81.684'},
      '143022': {'lat': '31.3373', 'name': 'Daburji,Tarn Taran,Punjab', 'lon': '74.8446'},
      '331504': {'lat': '28.3259', 'name': 'Ranasar Bikan,Churu,Rajasthan', 'lon': '74.5465'},
      '484440': {'lat': '23.2785', 'name': 'Thangaon,Anuppur,Madhya Pradesh', 'lon': '82.0489'},
      '209101': {'lat': '26.448', 'name': 'Gauriyapur,Kanpur Dehat,Uttar Pradesh', 'lon': '79.8604'},
      '273408': {'lat': '26.1559', 'name': 'Gola (Gorakhpur),Gorakhpur,Uttar Pradesh', 'lon': '83.0234'},
      '322236': {'lat': '26.7332', 'name': 'Jagar,Karauli,Rajasthan', 'lon': '76.0189'},
      '516002': {'lat': '14.4823', 'name': 'Central Prison,Cuddapah,Andhra Pradesh', 'lon': '78.8548'},
      '516003': {'lat': '14.3216', 'name': 'Palampalli,Cuddapah,Andhra Pradesh', 'lon': '78.9831'},
      '793113': {'lat': '25.2985', 'name': 'Rynkubazar,East Khasi Hills,Meghalaya', 'lon': '91.5823'},
      '516001': {'lat': '14.4307', 'name': 'Sunnapuralla Palli,Cuddapah,Andhra Pradesh', 'lon': '78.8825'},
      '793115': {'lat': '25.4525', 'name': 'Sohpian,West Khasi Hills,Meghalaya', 'lon': '91.6004'},
      '793114': {'lat': '25.3682', 'name': 'Sngimawlein,West Khasi Hills,Meghalaya', 'lon': '91.4677'},
      '516004': {'lat': '14.4307', 'name': 'Mamillapalli,Cuddapah,Andhra Pradesh', 'lon': '78.8825'},
      '516005': {'lat': '14.4942', 'name': 'Yogi Vemana University,Cuddapah,Andhra Pradesh', 'lon': '78.7643'},
      '793119': {'lat': '25.5634', 'name': 'Myriem,West Khasi Hills,Meghalaya', 'lon': '91.611'},
      '627152': {'lat': '8.5845', 'name': 'Panankulam,Tirunelveli,Tamil Nadu', 'lon': '77.6781'},
      '243722': {'lat': '28.1989', 'name': 'Isampur Danda,Budaun,Uttar Pradesh', 'lon': '78.5202'},
      '140402': {'lat': '30.506', 'name': 'Shamdoo,Patiala,Punjab', 'lon': '76.5659'},
      '741160': {'lat': '23.9232', 'name': 'Chhatina,Nadia,West Bengal', 'lon': '88.2162'},
      '332403': {'lat': '27.1453', 'name': 'Bajore,Sikar,Rajasthan', 'lon': '75.8717'},
      '851129': {'lat': '25.427', 'name': 'Kushmahaut,Begusarai,Bihar', 'lon': '86.0585'},
      '851128': {'lat': '25.7394', 'name': 'Mansurchak,Begusarai,Bihar', 'lon': '85.8407'},
      '331505': {'lat': '28.2395', 'name': 'Dhatri,Churu,Rajasthan', 'lon': '74.4293'},
      '825405': {'lat': '24.2516', 'name': 'Kewal,Hazaribag,Jharkhand', 'lon': '85.4317'},
      '141421': {'lat': '30.7851', 'name': 'Rupalon,Ludhiana,Punjab', 'lon': '76.1114'},
      '743701': {'lat': '22.8451', 'name': 'Sonekpur,North 24 Parganas,West Bengal', 'lon': '88.5958'},
      '144037': {'lat': '31.0792', 'name': 'Nagra,Jalandhar,Punjab', 'lon': '75.6924'},
      '628752': {'lat': '8.6413', 'name': 'Perungulam (Tuticorin),Tuticorin,Tamil Nadu', 'lon': '77.9985'},
      '141422': {'lat': '30.803', 'name': 'Raqba,Ludhiana,Punjab', 'lon': '75.6673'},
      '628751': {'lat': '8.6564', 'name': 'Pandaravilai,Tuticorin,Tamil Nadu', 'lon': '77.9956'},
      '509219': {'lat': '18.0255', 'name': 'Rachala,Mahabub Nagar,Telangana', 'lon': '78.2171'},
      '600099': {'lat': '13.0647', 'name': 'Lakshmipuram,Chennai,Tamil Nadu', 'lon': '80.2523'},
      '731235': {'lat': '23.6823', 'name': 'Paruldanga,Birbhum,West Bengal', 'lon': '87.6864'},
      '262526': {'lat': '29.5882', 'name': 'Jhulaghat,Pithoragarh,Uttarakhand', 'lon': '80.3817'},
      '202124': {'lat': '27.757', 'name': 'Bhaiyan,Aligarh,Uttar Pradesh', 'lon': '77.9765'},
      '600093': {'lat': '13.0647', 'name': 'Saligramam,Chennai,Tamil Nadu', 'lon': '80.2523'},
      '600092': {'lat': '13.0647', 'name': 'Virugambakkam,Chennai,Tamil Nadu', 'lon': '80.2523'},
      '600091': {'lat': '12.9623', 'name': 'Ram Nagar,Kanchipuram,Tamil Nadu', 'lon': '80.1986'},
      '600090': {'lat': '12.8503', 'name': 'Rajaji Bhavan,Chennai,Tamil Nadu', 'lon': '80.0707'},
      '600097': {'lat': '12.8503', 'name': 'Karapakkam,Kanchipuram,Tamil Nadu', 'lon': '80.0707'},
      '600096': {'lat': '12.9609', 'name': 'Perungudi,Kanchipuram,Tamil Nadu', 'lon': '80.2409'},
      '331502': {'lat': '27.819', 'name': 'Dewani,Churu,Rajasthan', 'lon': '74.4394'},
      '600094': {'lat': '12.8503', 'name': 'Choolaimedu,Chennai,Tamil Nadu', 'lon': '80.0707'},
      '470113': {'lat': '23.8481', 'name': 'Bhangarh,Sagar,Madhya Pradesh', 'lon': '78.7589'},
      '470117': {'lat': '24.1153', 'name': 'Teora,Sagar,Madhya Pradesh', 'lon': '78.3389'},
      '321001': {'lat': '27.175', 'name': 'Malah,Bharatpur,Rajasthan', 'lon': '77.5911'},
      '470442': {'lat': '24.0409', 'name': 'Bandri,Sagar,Madhya Pradesh', 'lon': '78.6351'},
      '470119': {'lat': '23.8703', 'name': 'Hirankheda,Sagar,Madhya Pradesh', 'lon': '78.2294'},
      '470118': {'lat': '24.1392', 'name': 'Pathariya Jogan,Sagar,Madhya Pradesh', 'lon': '78.4275'},
      '814119': {'lat': '24.2274', 'name': 'Morbhanga,Dumka,Jharkhand', 'lon': '86.946'},
      '361120': {'lat': '22.2951', 'name': 'Morkanda,Jamnagar,Gujarat', 'lon': '70.5137'},
      '731236': {'lat': '23.1418', 'name': 'Bergram,Birbhum,West Bengal', 'lon': '88.0319'},
      '522113': {'lat': '15.9588', 'name': 'Cheruvu,Guntur,Andhra Pradesh', 'lon': '80.4296'},
      '522112': {'lat': '16.1397', 'name': 'Pedapalem,Guntur,Andhra Pradesh', 'lon': '80.1569'},
      '522111': {'lat': '15.9218', 'name': 'Chintayapalem,Guntur,Andhra Pradesh', 'lon': '80.5547'},
      '385130': {'lat': '24.256', 'name': 'Dabhela,Banaskantha,Gujarat', 'lon': '72.7055'},
      '814110': {'lat': '24.3197', 'name': 'Lilatari,Dumka,Jharkhand', 'lon': '87.2666'},
      '388320': {'lat': '22.9158', 'name': 'Bedva,Anand,Gujarat', 'lon': '72.6289'},
      '202122': {'lat': '27.9655', 'name': 'Chherat,Aligarh,Uttar Pradesh', 'lon': '78.0888'},
      '331503': {'lat': '28.3455', 'name': 'Balera,Churu,Rajasthan', 'lon': '74.6986'},
      '385135': {'lat': '24.256', 'name': 'Jethi,Banaskantha,Gujarat', 'lon': '72.7055'},
      '388325': {'lat': '22.5514', 'name': 'Valasan,Anand,Gujarat', 'lon': '72.8829'},
      '454446': {'lat': '22.2318', 'name': 'Khandlai Jagir,Dhar,Madhya Pradesh', 'lon': '75.278'},
      '521126': {'lat': '16.6497', 'name': 'Nadakuduru,Krishna,Andhra Pradesh', 'lon': '81.2692'},
      '521125': {'lat': '16.3693', 'name': 'Nagayatippa,Krishna,Andhra Pradesh', 'lon': '81.0694'},
      '533350': {'lat': '17.859', 'name': 'Chuchirevulagudem,East Godavari,Andhra Pradesh', 'lon': '82.2105'},
      '403115': {'lat': '15.5093', 'name': 'Cundiam,South Goa,Goa', 'lon': '73.8327'},
      '364750': {'lat': '21.8941', 'name': 'Sakhpar Mota,Bhavnagar,Gujarat', 'lon': '72.5226'},
      '521121': {'lat': '16.3693', 'name': 'Avanigadda H.O,Krishna,Andhra Pradesh', 'lon': '81.0694'},
      '521120': {'lat': '16.0888', 'name': 'Nangegadda,Krishna,Andhra Pradesh', 'lon': '80.8696'},
      '680003': {'lat': '10.5179', 'name': 'Ayyanthole North,Thrissur,Kerala', 'lon': '76.2204'},
      '581337': {'lat': '14.8377', 'name': 'Bare,Uttara Kannada,Karnataka', 'lon': '74.4957'},
      '202155': {'lat': '28.0689', 'name': 'Mehgora,Aligarh,Uttar Pradesh', 'lon': '77.9197'},
      '225122': {'lat': '26.851', 'name': 'Akhtiyarpur  Sharifabad,Barabanki,Uttar Pradesh', 'lon': '81.3155'},
      '394270': {'lat': '21.4053', 'name': 'Nandniketan,Surat,Gujarat', 'lon': '73.2118'},
      '225120': {'lat': '26.1178', 'name': 'Sohawan,Barabanki,Uttar Pradesh', 'lon': '81.1942'},
      '303315': {'lat': '27.1618', 'name': 'Dhigaria Bhim,Dausa,Rajasthan', 'lon': '77.3913'},
      '343042': {'lat': '25.4526', 'name': 'Tikhi,Jalor,Rajasthan', 'lon': '72.4379'},
      '303313': {'lat': '27.2462', 'name': 'Peechupada Khurd,Dausa,Rajasthan', 'lon': '76.3894'},
      '343040': {'lat': '24.4307', 'name': 'Gong,Jalor,Rajasthan', 'lon': '72.5118'},
      '343041': {'lat': '25.4117', 'name': 'Sarwana,Jalor,Rajasthan', 'lon': '73.6186'},
      '343048': {'lat': '25.2291', 'name': 'Vadgaon (Jalor),Jalor,Rajasthan', 'lon': '73.1461'},
      '343049': {'lat': '25.1437', 'name': 'Panseri,Jalor,Rajasthan', 'lon': '72.7316'},
      '175049': {'lat': '31.4971', 'name': 'Khan,Mandi,Himachal Pradesh', 'lon': '76.8119'},
      '175048': {'lat': '31.5082', 'name': 'Sunah Lamba Thach,Mandi,Himachal Pradesh', 'lon': '77.0144'},
      '175040': {'lat': '31.573', 'name': 'Giun,Mandi,Himachal Pradesh', 'lon': '76.7936'},
      '175042': {'lat': '31.2116', 'name': 'Jandhrukalan,Mandi,Himachal Pradesh', 'lon': '77.1759'},
      '175045': {'lat': '30.9745', 'name': 'Sukibain,Mandi,Himachal Pradesh', 'lon': '77.1646'},
      '587103': {'lat': '15.9374', 'name': 'Bagalkot Navanagar,Bagalkot,Karnataka', 'lon': '76.1284'},
      '175047': {'lat': '31.4974', 'name': 'Bihani,Mandi,Himachal Pradesh', 'lon': '77.2162'},
      '175046': {'lat': '30.9642', 'name': 'Katwachi,Mandi,Himachal Pradesh', 'lon': '77.1857'},
      '313324': {'lat': '25.1555', 'name': 'Sayon Ka Khera,Rajsamand,Rajasthan', 'lon': '73.8895'},
      '212652': {'lat': '26.1867', 'name': 'Paigamberpur,Fatehpur,Uttar Pradesh', 'lon': '80.705'},
      '491995': {'lat': '21.96805', 'name': 'Sarangpurkhurd,Kawardha,Chattisgarh', 'lon': '81.2859'},
      '491993': {'lat': '21.652', 'name': 'Bargada,Durg,Chattisgarh', 'lon': '81.368'},
      '533352': {'lat': '16.7712', 'name': 'Cherupalli,Khammam,Telangana', 'lon': '80.9568'},
      '577301': {'lat': '13.84', 'name': 'Bhadravathi New Model Colony,Shivamogga,Karnataka', 'lon': '75.6022'},
      '577302': {'lat': '13.797', 'name': 'Doddagoppenahalli,Shivamogga,Karnataka', 'lon': '75.6787'},
      '614738': {'lat': '10.5682', 'name': 'Jambavanodai,Tiruvarur,Tamil Nadu', 'lon': '79.4143'},
      '212650': {'lat': '25.9501', 'name': 'Karmon,Fatehpur,Uttar Pradesh', 'lon': '80.6969'},
      '221301': {'lat': '25.2666512', 'name': 'Aurai (Sant Ravidas Nagar),Sant Ravidas Nagar,Uttar Pradesh', 'lon': '82.5605074'},
      '212651': {'lat': '25.9723', 'name': 'Farshi,Fatehpur,Uttar Pradesh', 'lon': '80.9718'},
      '322212': {'lat': '26.6278', 'name': 'Riwali,Sawai Madhopur,Rajasthan', 'lon': '76.4572'},
      '465333': {'lat': '23.3975', 'name': 'Rolakhedi,Shajapur,Madhya Pradesh', 'lon': '76.6791'},
      '322213': {'lat': '26.6541', 'name': 'Gudhachandraji,Karauli,Rajasthan', 'lon': '76.6317'},
      '497231': {'lat': '23.1756', 'name': 'Bhaiyathan,Surguja,Chattisgarh', 'lon': '82.9212'},
      '497235': {'lat': '23.1606', 'name': 'Chandramedha,Surguja,Chattisgarh', 'lon': '82.9795'},
      '212654': {'lat': '26.0448', 'name': 'Kishandaspur,Fatehpur,Uttar Pradesh', 'lon': '80.988'},
      '533351': {'lat': '16.6201', 'name': 'Nallakunta,East Godavari,Andhra Pradesh', 'lon': '80.7579'},
      '151209': {'lat': '30.5921', 'name': 'Machaki Mal Singh,Faridkot,Punjab', 'lon': '74.783'},
      '212655': {'lat': '25.6984', 'name': 'Alampur Giriya,Fatehpur,Uttar Pradesh', 'lon': '81.1421'},
      '228125': {'lat': '26.6066', 'name': 'Manipur Patna,Sultanpur,Uttar Pradesh', 'lon': '81.968'},
      '743704': {'lat': '22.8135', 'name': 'Prithiba,North 24 Parganas,West Bengal', 'lon': '88.6066'},
      '456001': {'lat': '23.4977', 'name': 'Ujjain H.O,Ujjain,Madhya Pradesh', 'lon': '75.6802'},
      '263135': {'lat': '29.4816', 'name': 'Talla Bardho,Nainital,Uttarakhand', 'lon': '79.46'},
      '263134': {'lat': '29.5589', 'name': 'Sethi Belgaon,Nainital,Uttarakhand', 'lon': '79.341'},
      '263132': {'lat': '29.402', 'name': 'Nartola,Nainital,Uttarakhand', 'lon': '79.6497'},
      '591229': {'lat': '15.9239', 'name': 'Kognoli,Belagavi,Karnataka', 'lon': '75.3964'},
      '591228': {'lat': '15.9239', 'name': 'Khadaklat,Belagavi,Karnataka', 'lon': '75.3964'},
      '591227': {'lat': '15.9987', 'name': 'Mannikeri,Belagavi,Karnataka', 'lon': '74.8884'},
      '591226': {'lat': '15.6348', 'name': 'Hattarwat,Belagavi,Karnataka', 'lon': '75.6451'},
      '591225': {'lat': '14.9348', 'name': 'Akkiwat,Belagavi,Karnataka', 'lon': '75.2292'},
      '591224': {'lat': '15.9721', 'name': 'Hunshyal PG,Belagavi,Karnataka', 'lon': '75.7415'},
      '591223': {'lat': '15.9239', 'name': 'Kagvad,Belagavi,Karnataka', 'lon': '75.3964'},
      '591222': {'lat': '16.3645', 'name': 'Nipnal,Belagavi,Karnataka', 'lon': '75.0539'},
      '591221': {'lat': '16.1198', 'name': 'Ullagaddi Khanapur,Belagavi,Karnataka', 'lon': '75.5643'},
      '591220': {'lat': '15.9239', 'name': 'Hidkal,Belagavi,Karnataka', 'lon': '75.3964'},
      '262552': {'lat': '29.9787', 'name': 'Bhantarh,Pithoragarh,Uttarakhand', 'lon': '79.3083'},
      '141413': {'lat': '30.6559', 'name': 'Dhamote,Ludhiana,Punjab', 'lon': '76.0162'},
      '262550': {'lat': '29.8752', 'name': 'Narayan Nagar,Pithoragarh,Uttarakhand', 'lon': '79.8034'},
      '212659': {'lat': '26.2756', 'name': 'Kapil Kalan,Fatehpur,Uttar Pradesh', 'lon': '80.922'},
      '425428': {'lat': '21.5456', 'name': 'Wakvad,Dhule,Maharashtra', 'lon': '74.8712'},
      '262554': {'lat': '29.4576', 'name': 'Bauna,Pithoragarh,Uttarakhand', 'lon': '79.7201'},
      '262555': {'lat': '29.9018', 'name': 'Sera,Pithoragarh,Uttarakhand', 'lon': '80.065'},
      '425424': {'lat': '21.5566', 'name': 'Dongargaon,Nandurbar,Maharashtra', 'lon': '74.5253'},
      '425427': {'lat': '21.2785', 'name': 'Arthe,Dhule,Maharashtra', 'lon': '74.7008'},
      '425426': {'lat': '21.1751', 'name': 'Vadfali,Nandurbar,Maharashtra', 'lon': '74.0338'},
      '425421': {'lat': '21.2829', 'name': 'Hisale,Dhule,Maharashtra', 'lon': '74.9714'},
      '425420': {'lat': '21.1718', 'name': 'Kalali,Jalgaon,Maharashtra', 'lon': '75.074'},
      '425423': {'lat': '21.4694', 'name': 'Kukaval,Nandurbar,Maharashtra', 'lon': '74.576'},
      '425422': {'lat': '21.1099', 'name': 'Vaijali,Dhule,Maharashtra', 'lon': '74.6011'},
      '382418': {'lat': '22.9942', 'name': 'Vastral,Ahmedabad,Gujarat', 'lon': '72.6633'},
      '642005': {'lat': '10.6903', 'name': 'Nalluthukuli,Coimbatore,Tamil Nadu', 'lon': '76.9075'},
      '322218': {'lat': '26.3753', 'name': 'Sapotara,Karauli,Rajasthan', 'lon': '76.7932'},
      '312205': {'lat': '24.8823', 'name': 'Patoliya,Chittorgarh,Rajasthan', 'lon': '74.2559'},
      '382415': {'lat': '22.9942', 'name': 'Odhav Industrial Estate,Ahmedabad,Gujarat', 'lon': '72.6633'},
      '532401': {'lat': '18.2907', 'name': 'Mofusbandar,Srikakulam,Andhra Pradesh', 'lon': '83.9367'},
      '532402': {'lat': '18.3317', 'name': 'S.M.Puram,Srikakulam,Andhra Pradesh', 'lon': '83.8267'},
      '177501': {'lat': '31.6429605', 'name': 'Thathwani,Hamirpur(HP),Himachal Pradesh', 'lon': '76.6427062'},
      '532404': {'lat': '18.3641', 'name': 'Lingalavalasa,Srikakulam,Andhra Pradesh', 'lon': '83.9113'},
      '532405': {'lat': '18.3545', 'name': 'Buravilli,Srikakulam,Andhra Pradesh', 'lon': '83.8508'},
      '785688': {'lat': '26.93', 'name': 'Khaloighogra,Sibsagar,Assam', 'lon': '94.3787'},
      '785689': {'lat': '26.93', 'name': 'Maibella,Sibsagar,Assam', 'lon': '94.3787'},
      '785686': {'lat': '26.9185', 'name': 'Dholebagan,Sibsagar,Assam', 'lon': '94.7568'},
      '785687': {'lat': '26.7057', 'name': 'Bihubar,Sibsagar,Assam', 'lon': '92.5878'},
      '785684': {'lat': '26.93', 'name': 'Napam Deopani,Sibsagar,Assam', 'lon': '94.3787'},
      '785685': {'lat': '27.1054', 'name': 'Panibill,Sibsagar,Assam', 'lon': '95.0114'},
      '785682': {'lat': '26.93', 'name': 'Mori Jhanji,Sibsagar,Assam', 'lon': '94.3787'},
      '785683': {'lat': '26.93', 'name': 'Bonai,Sibsagar,Assam', 'lon': '94.3787'},
      '785680': {'lat': '26.8148', 'name': 'Deoraja Maidum,Sibsagar,Assam', 'lon': '94.5261'},
      '785681': {'lat': '26.93', 'name': 'Dopdhar,Sibsagar,Assam', 'lon': '94.3787'},
      '174316': {'lat': '31.7055', 'name': 'Hamboli,Una,Himachal Pradesh', 'lon': '76.542'},
      '174317': {'lat': '31.3387', 'name': 'Bhadsali,Una,Himachal Pradesh', 'lon': '76.7107'},
      '174314': {'lat': '31.3387', 'name': 'Ambehra Dhiraj,Una,Himachal Pradesh', 'lon': '76.7107'},
      '174315': {'lat': '31.3089', 'name': 'Charatghar,Una,Himachal Pradesh', 'lon': '76.8203'},
      '174312': {'lat': '31.56905', 'name': 'Batarli Upperli,Hamirpur(HP),Himachal Pradesh', 'lon': '76.4465'},
      '321201': {'lat': '27.5432', 'name': 'Birheru,Bharatpur,Rajasthan', 'lon': '76.7789'},
      '174310': {'lat': '31.2472791', 'name': 'Janali,Bilaspur (HP),Himachal Pradesh', 'lon': '76.6203258'},
      '174311': {'lat': '31.5421', 'name': 'Kohdra,Hamirpur(HP),Himachal Pradesh', 'lon': '76.44615'},
      '824205': {'lat': '24.4233', 'name': 'Balia,Gaya,Bihar', 'lon': '84.3561'},
      '393050': {'lat': '21.675', 'name': 'Kuilivel,Surat,Gujarat', 'lon': '73.8538'},
      '174319': {'lat': '31.2263', 'name': 'Saloh Beri,Una,Himachal Pradesh', 'lon': '76.6716'},
      '571463': {'lat': '12.3127', 'name': 'Purigali,Mandya,Karnataka', 'lon': '76.8871'},
      '731204': {'lat': '23.5941', 'name': 'Bolpur Bazar,Birbhum,West Bengal', 'lon': '87.4021'},
      '392230': {'lat': '22.5465', 'name': 'Dora,Bharuch,Gujarat', 'lon': '73.2521'},
      '521333': {'lat': '16.5484', 'name': 'Varahapatnam,Krishna,Andhra Pradesh', 'lon': '81.2402'},
      '144306': {'lat': '32.0357', 'name': 'Behbalmanj,Hoshiarpur,Punjab', 'lon': '75.6209'},
      '721450': {'lat': '22.3836', 'name': 'Paschim Bamunia,East Midnapore,West Bengal', 'lon': '87.8747'},
      '721453': {'lat': '21.7344', 'name': 'Manikabasan,East Midnapore,West Bengal', 'lon': '87.5491'},
      '144305': {'lat': '31.779', 'name': 'Berchha,Hoshiarpur,Punjab', 'lon': '75.6751'},
      '144302': {'lat': '30.9987', 'name': 'Dolike Sunderpur,Jalandhar,Punjab', 'lon': '75.1651'},
      '144303': {'lat': '31.6708', 'name': 'Rohjari,Jalandhar,Punjab', 'lon': '75.7745'},
      '721457': {'lat': '22.3836', 'name': 'Gomunda,West Midnapore,West Bengal', 'lon': '87.8747'},
      '144301': {'lat': '31.2126', 'name': 'Sikandarpur,Jalandhar,Punjab', 'lon': '75.5666'},
      '731201': {'lat': '24.1621', 'name': 'Konarpur,Birbhum,West Bengal', 'lon': '87.78'},
      '507136': {'lat': '18.2139', 'name': 'Wazeedu Nagaram,Khammam,Telangana', 'lon': '80.5396'},
      '689573': {'lat': '9.35', 'name': 'Thayamkary,Alappuzha,Kerala', 'lon': '76.5333'},
      '689572': {'lat': '9.35', 'name': 'Anaprambal,Alappuzha,Kerala', 'lon': '76.5333'},
      '689571': {'lat': '9.35', 'name': 'Karikuzhy,Alappuzha,Kerala', 'lon': '76.5333'},
      '227408': {'lat': '26.255', 'name': 'Bikana,Sultanpur,Uttar Pradesh', 'lon': '82.1104'},
      '689574': {'lat': '9.35', 'name': 'Muttar,Alappuzha,Kerala', 'lon': '76.5333'},
      '151503': {'lat': '30.0715', 'name': 'Reond Kalan,Mansa,Punjab', 'lon': '75.3545'},
      '227407': {'lat': '26.1647', 'name': 'Bisesarganj,Sultanpur,Uttar Pradesh', 'lon': '81.9678'},
      '227406': {'lat': '26.1148', 'name': 'Dahiyawan,Sultanpur,Uttar Pradesh', 'lon': '81.9614'},
      '227405': {'lat': '26.1532', 'name': 'Jangal Ramnagar,Sultanpur,Uttar Pradesh', 'lon': '81.8218'},
      '243724': {'lat': '28.2304', 'name': 'Kariamai,Budaun,Uttar Pradesh', 'lon': '78.6993'},
      '321203': {'lat': '27.5434', 'name': 'Khohri,Bharatpur,Rajasthan', 'lon': '77.2492'},
      '221116': {'lat': '25.5195', 'name': 'Tekuri,Varanasi,Uttar Pradesh', 'lon': '83.1092'},
      '221115': {'lat': '25.4976', 'name': 'Targaonajgara,Varanasi,Uttar Pradesh', 'lon': '83.1944'},
      '507133': {'lat': '18.0225', 'name': 'Suraveedu,Khammam,Telangana', 'lon': '80.8157'},
      '505445': {'lat': '18.6259', 'name': 'Uppermallial,Karim Nagar,Telangana', 'lon': '79.0791'},
      '735210': {'lat': '26.6326', 'name': 'Paschim Mallick Para,Jalpaiguri,West Bengal', 'lon': '89.0465'},
      '221110': {'lat': '25.4079', 'name': 'Basant Nagar,Varanasi,Uttar Pradesh', 'lon': '82.9617'},
      '305022': {'lat': '26.5021', 'name': 'Bhagwan Pura,Ajmer,Rajasthan', 'lon': '74.5282'},
      '181111': {'lat': '32.5784', 'name': 'Rathana,Jammu,Jammu & Kashmir', 'lon': '74.7732'},
      '305021': {'lat': '26.5736', 'name': 'Kanwalai,Ajmer,Rajasthan', 'lon': '74.5816'},
      '305026': {'lat': '26.4882', 'name': 'Dhanipura,Nagaur,Rajasthan', 'lon': '74.6673'},
      '305024': {'lat': '26.4695', 'name': 'Rasoolpura,Ajmer,Rajasthan', 'lon': '74.7165'},
      '305025': {'lat': '26.4021', 'name': 'Mundolao,Ajmer,Rajasthan', 'lon': '74.7926'},
      '614211': {'lat': '10.9851', 'name': 'Chakrapalli,Thanjavur,Tamil Nadu', 'lon': '78.9832'},
      '507316': {'lat': '18.3172', 'name': 'Yatalakunta,Khammam,Telangana', 'lon': '79.3498'},
      '221718': {'lat': '25.96', 'name': 'Sisyandkala,Ballia,Uttar Pradesh', 'lon': '83.6933'},
      '754217': {'lat': '20.2526', 'name': 'Alba,Kendrapara,Odisha', 'lon': '85.1039'},
      '754216': {'lat': '19.0804', 'name': 'Sana Jaria,Kendrapara,Odisha', 'lon': '83.8088'},
      '754215': {'lat': '20.5482', 'name': 'Patrapur,Kendrapara,Odisha', 'lon': '85.1053'},
      '754214': {'lat': '20.6239', 'name': 'Ratnagiri,Jajapur,Odisha', 'lon': '86.3456'},
      '754213': {'lat': '20.5217', 'name': 'Garjanga,Kendrapara,Odisha', 'lon': '85.4837'},
      '784028': {'lat': '26.5735', 'name': 'Napaam,Sonitpur,Assam', 'lon': '91.1499'},
      '754211': {'lat': '20.5482', 'name': 'Keshpur Bazar,Kendrapara,Odisha', 'lon': '85.1053'},
      '754210': {'lat': '20.5482', 'name': 'Pakhyat,Kendrapara,Odisha', 'lon': '85.1053'},
      '784025': {'lat': '26.5735', 'name': 'Singitoli,Sonitpur,Assam', 'lon': '91.1499'},
      '241406': {'lat': '27.5163', 'name': 'Burhagaon,Hardoi,Uttar Pradesh', 'lon': '80.4362'},
      '784027': {'lat': '26.5735', 'name': 'Bhojkhowa Chapori,Sonitpur,Assam', 'lon': '91.1499'},
      '784026': {'lat': '26.5735', 'name': 'Khalihamari,Sonitpur,Assam', 'lon': '91.1499'},
      '313902': {'lat': '24.5714', 'name': 'Kejad,Udaipur,Rajasthan', 'lon': '73.8199'},
      '313903': {'lat': '24.6489', 'name': 'Jambuda,Udaipur,Rajasthan', 'lon': '73.6099'},
      '754219': {'lat': '20.5532', 'name': 'Mendhapur,Kendrapara,Odisha', 'lon': '85.8374'},
      '754218': {'lat': '21.2711', 'name': 'Sanamanga,Kendrapara,Odisha', 'lon': '85.1772'},
      '461114': {'lat': '22.673', 'name': 'Cpe Itarsi,Hoshangabad,Madhya Pradesh', 'lon': '77.6181'},
      '500002': {'lat': '17.3862', 'name': 'Moghalpura,Hyderabad,Telangana', 'lon': '78.462'},
      '795138': {'lat': '24.5532', 'name': 'Keibung Mamang,Thoubal,Manipur', 'lon': '93.968'},
      '383230': {'lat': '24.1007', 'name': 'Bolundra (S),Sabarkantha,Gujarat', 'lon': '72.4352'},
      '464551': {'lat': '23.6073', 'name': 'Pamat,Raisen,Madhya Pradesh', 'lon': '77.9347'},
      '383235': {'lat': '23.9362', 'name': 'Hatharva,Sabarkantha,Gujarat', 'lon': '73.048'},
      '631702': {'lat': '12.7078', 'name': 'Vadakalpakkam,Tiruvannamalai,Tamil Nadu', 'lon': '79.7812'},
      '515722': {'lat': '14.4811', 'name': 'Yerragunta,Ananthapur,Andhra Pradesh', 'lon': '77.5768'},
      '802166': {'lat': '25.5977', 'name': 'Barakhrauni,Bhojpur,Bihar', 'lon': '84.4926'},
      '802165': {'lat': '25.469', 'name': 'Horil Chhapra,Bhojpur,Bihar', 'lon': '84.5437'},
      '515721': {'lat': '14.4811', 'name': 'Kandukur,Ananthapur,Andhra Pradesh', 'lon': '77.5768'},
      '802163': {'lat': '25.6543', 'name': 'Naya Mohamad  Pur,Bhojpur,Bihar', 'lon': '84.8517'},
      '631701': {'lat': '12.8198', 'name': 'Akkur (Tiruvannamalai),Tiruvannamalai,Tamil Nadu', 'lon': '79.941'},
      '802161': {'lat': '25.5419', 'name': 'Narhi Chandi,Bhojpur,Bihar', 'lon': '84.7384'},
      '312605': {'lat': '25.3221', 'name': 'Amlawad,Chittorgarh,Rajasthan', 'lon': '74.8175'},
      '587203': {'lat': '16.0166', 'name': 'Parvati,Bagalkot,Karnataka', 'lon': '75.7432'},
      '712402': {'lat': '23.3558', 'name': 'Chenchua,Hooghly,West Bengal', 'lon': '87.9504'},
      '488441': {'lat': '24.5116', 'name': 'Amangang,Panna,Madhya Pradesh', 'lon': '79.9736'},
      '504251': {'lat': '18.7346', 'name': 'Budakalan,Adilabad,Telangana', 'lon': '79.0006'},
      '712403': {'lat': '22.8211', 'name': 'Dwarhatta,Hooghly,West Bengal', 'lon': '88.0802'},
      '854326': {'lat': '25.7807', 'name': 'Dagrua Hat,Purnia,Bihar', 'lon': '87.5778'},
      '854327': {'lat': '25.9763', 'name': 'Sohangaon,Purnia,Bihar', 'lon': '87.5486'},
      '854325': {'lat': '26.0287', 'name': 'Rampur Kodarkatti,Purnia,Bihar', 'lon': '87.5509'},
      '503321': {'lat': '18.6631', 'name': 'Damarancha,Nizamabad,Telangana', 'lon': '77.9191'},
      '854328': {'lat': '26.3991', 'name': 'Maheshwari,Araria,Bihar', 'lon': '87.2652'},
      '854329': {'lat': '26.1803', 'name': 'Bara Ishambara,Araria,Bihar', 'lon': '87.5504'},
      '767001': {'lat': '20.3977', 'name': 'Balangir H.O,Balangir,Odisha', 'lon': '82.7449'},
      '767002': {'lat': '21.1888', 'name': 'Randa,Balangir,Odisha', 'lon': '83.7321'},
      '424119': {'lat': '20.5335', 'name': 'Saygaon,Jalgaon,Maharashtra', 'lon': '75.1001'},
      '686681': {'lat': '10.1503', 'name': 'Malipara,Ernakulam,Kerala', 'lon': '76.7354'},
      '834006': {'lat': '23.4433', 'name': 'Sukurhutu,Ranchi,Jharkhand', 'lon': '85.2917'},
      '416308': {'lat': '17.4168', 'name': 'Dudhondi,Sangli,Maharashtra', 'lon': '75.2904'},
      '391760': {'lat': '22.7334', 'name': 'Ganpatpura,Vadodara,Gujarat', 'lon': '73.3473'},
      '391761': {'lat': '22.3069', 'name': 'Rustampura (Vadodara),Vadodara,Gujarat', 'lon': '73.4928'},
      '712407': {'lat': '22.8465', 'name': 'Haripur Chak,Hooghly,West Bengal', 'lon': '88.1925'},
      '481115': {'lat': '22.0377', 'name': 'Laweri,Balaghat,Madhya Pradesh', 'lon': '80.131'},
      '461116': {'lat': '22.7095', 'name': 'Misrod,Hoshangabad,Madhya Pradesh', 'lon': '77.8125'},
      '484551': {'lat': '23.355', 'name': 'Ghunghuti,Umaria,Madhya Pradesh', 'lon': '81.193'},
      '400019': {'lat': '19.0333', 'name': 'Matunga,Mumbai,Maharashtra', 'lon': '72.85'},
      '400018': {'lat': '19.0167', 'name': 'Worli,Mumbai,Maharashtra', 'lon': '72.8167'},
      '518543': {'lat': '14.9854', 'name': 'Alamur,Kurnool,Andhra Pradesh', 'lon': '78.4599'},
      '470675': {'lat': '23.8683', 'name': 'Mankaura,Damoh,Madhya Pradesh', 'lon': '79.2933'},
      '524228': {'lat': '14.6503', 'name': 'Gangireddypalem,Nellore,Andhra Pradesh', 'lon': '79.5791'},
      '524227': {'lat': '15.0461', 'name': 'Kakolluvaripalli,Nellore,Andhra Pradesh', 'lon': '79.4312'},
      '524226': {'lat': '14.8754', 'name': 'Udayagiri (Nellore),Nellore,Andhra Pradesh', 'lon': '79.3086'},
      '524225': {'lat': '15.004', 'name': 'Peddakondur,Nellore,Andhra Pradesh', 'lon': '79.9691'},
      '524224': {'lat': '14.8695', 'name': 'Veerannakollu,Nellore,Andhra Pradesh', 'lon': '79.6089'},
      '524223': {'lat': '14.9502', 'name': 'Brahmanakraka,Nellore,Andhra Pradesh', 'lon': '79.5818'},
      '524222': {'lat': '14.8277', 'name': 'Rachavaripalli,Nellore,Andhra Pradesh', 'lon': '79.4962'},
      '524221': {'lat': '14.5749', 'name': 'Thurupurompi Dodla,Nellore,Andhra Pradesh', 'lon': '79.4172'},
      '400016': {'lat': '19.0073', 'name': 'Mahim H.O,Mumbai,Maharashtra', 'lon': '72.8421'},
      '533436': {'lat': '17.4458', 'name': 'Amminibada,East Godavari,Andhra Pradesh', 'lon': '81.9607'},
      '533437': {'lat': '17.048', 'name': 'Rayabhupalapatnam,East Godavari,Andhra Pradesh', 'lon': '82.1054'},
      '533434': {'lat': '17.0235', 'name': 'P Vemavaram,East Godavari,Andhra Pradesh', 'lon': '82.1452'},
      '533435': {'lat': '17.2271', 'name': 'Somavaram,East Godavari,Andhra Pradesh', 'lon': '82.1181'},
      '533432': {'lat': '17.535', 'name': 'Chinasankaralapudi,East Godavari,Andhra Pradesh', 'lon': '81.8472'},
      '533433': {'lat': '17.126', 'name': 'Pulimeru,East Godavari,Andhra Pradesh', 'lon': '82.1615'},
      '533430': {'lat': '17.3734', 'name': 'Dharamavaram,East Godavari,Andhra Pradesh', 'lon': '82.1191'},
      '533431': {'lat': '17.19', 'name': 'Mukkullu,East Godavari,Andhra Pradesh', 'lon': '82.1757'},
      '623115': {'lat': '9.2328', 'name': 'Uchinatham,Ramanathapuram,Tamil Nadu', 'lon': '78.3533'},
      '627807': {'lat': '9.0214', 'name': 'Panpuli,Tirunelveli,Tamil Nadu', 'lon': '77.2502'},
      '627806': {'lat': '9.2784', 'name': 'Kulasekarapatti,Tirunelveli,Tamil Nadu', 'lon': '77.5741'},
      '627805': {'lat': '9.9655', 'name': 'Ayyapuram,Tirunelveli,Tamil Nadu', 'lon': '78.2308'},
      '391810': {'lat': '22.1784', 'name': 'Gajera,Bharuch,Gujarat', 'lon': '72.9078'},
      '250601': {'lat': '29.0141', 'name': 'Nerojpur Gujar,Bagpat,Uttar Pradesh', 'lon': '77.3175'},
      '387130': {'lat': '23.2982', 'name': 'Bavra,Kheda,Gujarat', 'lon': '73.1972'},
      '585302': {'lat': '17.5661', 'name': 'Alund Bazar,Kalaburagi,Karnataka', 'lon': '76.4823'},
      '247664': {'lat': '29.8041', 'name': 'Landhaura,Haridwar,Uttarakhand', 'lon': '77.9328'},
      '524322': {'lat': '15.5871', 'name': 'Cherloyadavalli,Nellore,Andhra Pradesh', 'lon': '80.06'},
      '670604': {'lat': '11.9372', 'name': 'Cheleri,Kannur,Kerala', 'lon': '75.4031'},
      '524320': {'lat': '14.3632', 'name': 'Kasumur,Nellore,Andhra Pradesh', 'lon': '79.884'},
      '524321': {'lat': '14.3143', 'name': 'Edagali,Nellore,Andhra Pradesh', 'lon': '79.9757'},
      '172026': {'lat': '31.4632', 'name': 'Kandugad,Kullu,Himachal Pradesh', 'lon': '77.4214'},
      '172027': {'lat': '31.3489', 'name': 'Shiwan,Shimla,Himachal Pradesh', 'lon': '77.3862'},
      '172024': {'lat': '31.3116', 'name': 'Galani,Shimla,Himachal Pradesh', 'lon': '77.3979'},
      '670602': {'lat': '11.9912', 'name': 'Pavanoormotta,Kannur,Kerala', 'lon': '75.4313'},
      '445324': {'lat': '20.0906', 'name': 'Marki,Yavatmal,Maharashtra', 'lon': '78.6791'},
      '172028': {'lat': '31.3401', 'name': 'Delath,Shimla,Himachal Pradesh', 'lon': '77.5429'},
      '172029': {'lat': '31.3059', 'name': 'Sharambal Camp,Shimla,Himachal Pradesh', 'lon': '77.4293'},
      '811213': {'lat': '25.1667', 'name': 'Rathaitha,Munger,Bihar', 'lon': '86.5614'},
      '246453': {'lat': '29.9489', 'name': 'Kafoli Malli,Chamoli,Uttarakhand', 'lon': '79.0793'},
      '445323': {'lat': '20.1698', 'name': 'Wardh,Yavatmal,Maharashtra', 'lon': '78.1477'},
      '679523': {'lat': '10.7531', 'name': 'Kavalappara,Palakkad,Kerala', 'lon': '76.3144'},
      '679522': {'lat': '10.7798', 'name': 'Pathankulam,Palakkad,Kerala', 'lon': '76.3187'},
      '679521': {'lat': '10.7709', 'name': 'Manisseri,Palakkad,Kerala', 'lon': '76.3173'},
      '811212': {'lat': '25.2543', 'name': 'Dharhara (Munger),Munger,Bihar', 'lon': '86.403'},
      '689507': {'lat': '9.9552', 'name': 'Mezhuveli,Pathanamthitta,Kerala', 'lon': '76.3676'},
      '689504': {'lat': '9.4236', 'name': 'Karakkad,Alappuzha,Kerala', 'lon': '76.4496'},
      '620102': {'lat': '10.8202', 'name': 'Thayanur,Tiruchirappalli,Tamil Nadu', 'lon': '78.6282'},
      '612106': {'lat': '11.0706', 'name': 'Kadiramangalam,Thanjavur,Tamil Nadu', 'lon': '79.5318'},
      '741121': {'lat': '23.7032', 'name': 'Arbandi,Nadia,West Bengal', 'lon': '87.8872'},
      '306022': {'lat': '25.1688', 'name': 'Kot- Solankiyan,Pali,Rajasthan', 'lon': '73.6673'},
      '689505': {'lat': '9.2746', 'name': 'Peringala-mulakuzha,Alappuzha,Kerala', 'lon': '76.6444'},
      '612102': {'lat': '9.7609', 'name': 'Suriyanarkoil,Thanjavur,Tamil Nadu', 'lon': '78.8896'},
      '612103': {'lat': '10.9858', 'name': 'Ammachathiram,Thanjavur,Tamil Nadu', 'lon': '79.4102'},
      '764088': {'lat': '20.0256', 'name': 'Hatabharandi,Nabarangapur,Odisha', 'lon': '81.9260'},
      '734224': {'lat': '26.9709', 'name': 'Bagora,Darjiling,West Bengal', 'lon': '88.3462'},
      '362570': {'lat': '20.9432', 'name': 'Wanakbara,Diu,Daman & Diu', 'lon': '71.0823'},
      '402309': {'lat': '18.0996', 'name': 'Kamble Tarfe Mahad,Raigarh(MH),Maharashtra', 'lon': '73.4269'},
      '271882': {'lat': '27.7034', 'name': 'Pandri Tara,Bahraich,Uttar Pradesh', 'lon': '81.573'},
      '689501': {'lat': '9.9552', 'name': 'Kurampala South,Pathanamthitta,Kerala', 'lon': '76.3676'},
      '364505': {'lat': '21.502', 'name': 'Thansa,Bhavnagar,Gujarat', 'lon': '71.9202'},
      '700108': {'lat': '22.966', 'name': 'ISI PO,Kolkata,West Bengal', 'lon': '88.2036'},
      '700109': {'lat': '22.6833', 'name': 'Agarpara,North 24 Parganas,West Bengal', 'lon': '88.3667'},
      '462001': {'lat': '25.0345', 'name': 'Berasia Road,Bhopal,Madhya Pradesh', 'lon': '81.703'},
      '484555': {'lat': '23.313', 'name': 'Nowrrozabad  R. S.,Anuppur,Madhya Pradesh', 'lon': '80.9659'},
      '700102': {'lat': '22.966', 'name': 'Krishnapur (North 24 Parganas),North 24 Parganas,West Bengal', 'lon': '88.2036'},
      '517277': {'lat': '13.633', 'name': 'Chintaparthi,Chittoor,Andhra Pradesh', 'lon': '78.2626'},
      '700100': {'lat': '22.966', 'name': 'Vip Nagar,South 24 Parganas,West Bengal', 'lon': '88.2036'},
      '700101': {'lat': '22.966', 'name': 'Prafulla Kanan,North 24 Parganas,West Bengal', 'lon': '88.2036'},
      '700106': {'lat': '22.966', 'name': 'Bidhan Nagar IB Market,North 24 Parganas,West Bengal', 'lon': '88.2036'},
      '700107': {'lat': '22.966', 'name': 'EAST KOLKATA TOWNSHIP,South 24 Parganas,West Bengal', 'lon': '88.2036'},
      '700104': {'lat': '23.0602', 'name': 'Ramchandrapur Chak Thakurani,South 24 Parganas,West Bengal', 'lon': '88.1492'},
      '700105': {'lat': '22.966', 'name': 'Dhapa,South 24 Parganas,West Bengal', 'lon': '88.2036'},
      '243001': {'lat': '28.3035', 'name': 'Mango Road,Bareilly,Uttar Pradesh', 'lon': '79.3254'},
      '250606': {'lat': '29.0186', 'name': 'Gwali Khera,Bagpat,Uttar Pradesh', 'lon': '77.3814'},
      '243003': {'lat': '28.5418', 'name': 'NEKPUR,Pilibhit,Uttar Pradesh', 'lon': '79.9617'},
      '402304': {'lat': '18.3919965', 'name': 'Talawali Terfe Diwali,Raigarh(MH),Maharashtra', 'lon': '73.2234968'},
      '243005': {'lat': '28.3035', 'name': 'Old name,Bareilly,Uttar Pradesh', 'lon': '79.3254'},
      '243004': {'lat': '28.3011', 'name': 'Simra Boripur,Bareilly,Uttar Pradesh', 'lon': '79.3982'},
      '504206': {'lat': '19.0616', 'name': 'Tapalpur,Adilabad,Telangana', 'lon': '79.2297'},
      '243006': {'lat': '28.2208', 'name': 'R.K.University,Bareilly,Uttar Pradesh', 'lon': '78.8009'},
      '451332': {'lat': '22.32819', 'name': 'Padlya,West Nimar,Madhya Pradesh', 'lon': '75.78025'},
      '451331': {'lat': '21.866058', 'name': 'Sundrel,West Nimar,Madhya Pradesh', 'lon': '75.967202'},
      '741127': {'lat': '24.5408', 'name': 'Byaspur,Nadia,West Bengal', 'lon': '87.8221'},
      '587205': {'lat': '15.9954', 'name': 'Kagalgomba,Bagalkot,Karnataka', 'lon': '75.7285'},
      '402301': {'lat': '18.0789988', 'name': 'Kinjaloli Budruk,Raigarh(MH),Maharashtra', 'lon': '73.4110365'},
      '795159': {'lat': '24.8873', 'name': 'Awangkhul,Imphal West,Manipur', 'lon': '93.6521'},
      '762002': {'lat': '20.4774', 'name': 'Paheraju,Kandhamal,Odisha', 'lon': '84.3568'},
      '762001': {'lat': '20.4666', 'name': 'Ollenbatch Nagar,Kandhamal,Odisha', 'lon': '84.2338'},
      '795150': {'lat': '25.5134', 'name': 'Phunanamei,Senapati,Manipur', 'lon': '94.1413'},
      '695561': {'lat': '8.6873', 'name': 'Poovathur,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '608702': {'lat': '11.4182', 'name': 'Vattathur,Cuddalore,Tamil Nadu', 'lon': '79.5099'},
      '695563': {'lat': '8.6873', 'name': 'Daivapura,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '695562': {'lat': '8.6873', 'name': "Ex-servicemen's Colony,Thiruvananthapuram,Kerala", 'lon': '76.9316'},
      '695564': {'lat': '8.6873', 'name': 'Aruvikara,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '413314': {'lat': '18.1961', 'name': 'Pachegaon (BK),Solapur,Maharashtra', 'lon': '75.4188'},
      '413315': {'lat': '18.1961', 'name': 'Hole,Solapur,Maharashtra', 'lon': '75.4188'},
      '695568': {'lat': '8.6873', 'name': 'Panavoor,Thiruvananthapuram,Kerala', 'lon': '76.9316'},
      '403006': {'lat': '15.4992', 'name': 'Chimbel,North Goa,Goa', 'lon': '73.8682'},
      '413319': {'lat': '18.013', 'name': 'Donaj,Solapur,Maharashtra', 'lon': '75.695'},
      '802226': {'lat': '25.2977', 'name': 'A. Kala,Rohtas,Bihar', 'lon': '84.2245'},
      '263139': {'lat': '29.1726', 'name': 'Kaladhungi Road,Nainital,Uttarakhand', 'lon': '79.5677'},
      '829210': {'lat': '23.7619', 'name': 'Dakra Colliery,Ranchi,Jharkhand', 'lon': '84.6809'},
      '323801': {'lat': '25.7822', 'name': 'Dhanugaon,Bundi,Rajasthan', 'lon': '75.495'},
      '323802': {'lat': '25.2239', 'name': 'Morsan,Bundi,Rajasthan', 'lon': '75.3826'},
      '323803': {'lat': '25.5104', 'name': 'Khyawda,Bundi,Rajasthan', 'lon': '75.994'},
      '805113': {'lat': '25.087', 'name': 'Khakhari,Nawada,Bihar', 'lon': '85.7296'},
      '743252': {'lat': '22.8774', 'name': 'Nakpul Kuchlia,North 24 Parganas,West Bengal', 'lon': '88.7548'},
      '743251': {'lat': '23.5022', 'name': 'Dakshin Panchpota,North 24 Parganas,West Bengal', 'lon': '88.5404'},
      '741153': {'lat': '25.3601', 'name': 'Baliadanga Faridpur,Nadia,West Bengal', 'lon': '87.8882'},
      '171221': {'lat': '31.1885', 'name': 'Jakha,Shimla,Himachal Pradesh', 'lon': '77.3245'},
      '171220': {'lat': '31.1077', 'name': 'Dehabalson,Shimla,Himachal Pradesh', 'lon': '77.3889'},
      '171223': {'lat': '31.2094', 'name': 'Arhal,Shimla,Himachal Pradesh', 'lon': '77.6999'},
      '171222': {'lat': '31.1722', 'name': 'Kathog,Shimla,Himachal Pradesh', 'lon': '77.1784'},
      '171225': {'lat': '31.2027', 'name': 'Kalbog,Shimla,Himachal Pradesh', 'lon': '77.5195'},
      '171224': {'lat': '31.3085', 'name': 'Summer Kot,Shimla,Himachal Pradesh', 'lon': '77.7038'},
      '574219': {'lat': '12.8266', 'name': 'Ammunje,Dakshina Kannada,Karnataka', 'lon': '75.3006'},
      '171226': {'lat': '31.19', 'name': 'Pulbahal,Shimla,Himachal Pradesh', 'lon': '77.2366'},
      '574217': {'lat': '12.9973', 'name': 'Balanja,Dakshina Kannada,Karnataka', 'lon': '75.2377'},
      '574216': {'lat': '12.9411', 'name': 'Kayarthadka,Dakshina Kannada,Karnataka', 'lon': '75.4035'},
      '263636': {'lat': '29.6558', 'name': 'Thapania,Almora,Uttarakhand', 'lon': '79.6264'},
      '574214': {'lat': '13.0488', 'name': 'Navoor(Belt),Dakshina Kannada,Karnataka', 'lon': '75.3259'},
      '574213': {'lat': '13.1293', 'name': 'Padumarnad,Dakshina Kannada,Karnataka', 'lon': '75.0669'},
      '574212': {'lat': '12.6713', 'name': 'Kalmadka,Dakshina Kannada,Karnataka', 'lon': '75.376'},
      '574211': {'lat': '12.9319', 'name': 'Loretto,Dakshina Kannada,Karnataka', 'lon': '75.0292'},
      '574210': {'lat': '12.7248', 'name': 'Madav,Dakshina Kannada,Karnataka', 'lon': '75.2208'},
      '636308': {'lat': '11.5907', 'name': 'Bairoji Agraharam,Salem,Tamil Nadu', 'lon': '78.0832'},
      '577211': {'lat': '14.0103', 'name': 'Mandaghatta,Shivamogga,Karnataka', 'lon': '75.4367'},
      '482020': {'lat': '23.2326', 'name': 'Bilhari,Jabalpur,Madhya Pradesh', 'lon': '79.9765'},
      '636302': {'lat': '11.6614', 'name': 'Ayyamperumalpatti,Salem,Tamil Nadu', 'lon': '78.0834'},
      '636303': {'lat': '11.8787', 'name': 'Palamalai Hills,Salem,Tamil Nadu', 'lon': '77.7654'},
      '636301': {'lat': '11.5006', 'name': 'Thengalpalayam,Namakkal,Tamil Nadu', 'lon': '78.1356'},
      '636306': {'lat': '11.6533', 'name': 'Duddampatti,Salem,Tamil Nadu', 'lon': '77.9079'},
      '636307': {'lat': '11.4877', 'name': 'Naickenpatti,Salem,Tamil Nadu', 'lon': '78.2346'},
      '636304': {'lat': '11.7021', 'name': 'Muthunaickenpatti,Salem,Tamil Nadu', 'lon': '78.057'},
      '636305': {'lat': '11.8593', 'name': 'Kanjanaickenpatti,Salem,Tamil Nadu', 'lon': '78.0217'},
      '231501': {'lat': '24.9854', 'name': 'Bidapur,Mirzapur,Uttar Pradesh', 'lon': '82.6191'},
      '471411': {'lat': '25.0023', 'name': 'Bari,Chhatarpur,Madhya Pradesh', 'lon': '79.6696'},
      '283135': {'lat': '27.1382', 'name': 'Urmura,Firozabad,Uttar Pradesh', 'lon': '78.6075'},
      '384305': {'lat': '23.6029', 'name': 'Lacjjadi,Mahesana,Gujarat', 'lon': '72.839'},
      '360421': {'lat': '22.138', 'name': 'Bhadajalia,Rajkot,Gujarat', 'lon': '70.9749'},
      '741124': {'lat': '23.1328', 'name': 'Chhoto Andulia,Nadia,West Bengal', 'lon': '87.7871'},
      '394640': {'lat': '21.033', 'name': 'Kanjod,Surat,Gujarat', 'lon': '73.2649'},
      '394641': {'lat': '23.0967', 'name': 'Vedchhi,Tapi,Gujarat', 'lon': '73.0979'},
      '732102': {'lat': '24.2919', 'name': 'Kutubsahar,Malda,West Bengal', 'lon': '87.9302'},
      '249194': {'lat': '30.7404', 'name': 'Latasera,Uttarkashi,Uttarakhand', 'lon': '78.5315'},
      '482021': {'lat': '23.0841', 'name': 'Khamaria Neemkheda,Jabalpur,Madhya Pradesh', 'lon': '79.7888'},
      '571187': {'lat': '12.4493', 'name': 'Honnapura,Mysuru,Karnataka', 'lon': '76.0755'},
      '732101': {'lat': '24.2919', 'name': 'Fulbari (Malda),Malda,West Bengal', 'lon': '87.9302'},
      '741159': {'lat': '23.6994', 'name': 'Taherpur,Nadia,West Bengal', 'lon': '88.1823'},
      '223101': {'lat': '26.0571', 'name': 'Suris,Jaunpur,Uttar Pradesh', 'lon': '82.6934'},
      '506104': {'lat': '17.6212', 'name': 'Parvathagiri,Warangal,Telangana', 'lon': '79.7648'},
      '506105': {'lat': '17.0138', 'name': 'Bethole,Warangal,Telangana', 'lon': '79.1797'},
      '506102': {'lat': '17.6023', 'name': 'Laxmipur,Mahabubabad,Telangana', 'lon': '79.5572'},
      '799132': {'lat': '23.3481', 'name': 'Maheshpur,Sepahijala,Tripura', 'lon': '91.3204'},
      '576117': {'lat': '13.2157', 'name': 'Tellar,Udupi,Karnataka', 'lon': '75.0708'},
      '843130': {'lat': '25.8166', 'name': 'Kanti T.P.S.,Muzaffarpur,Bihar', 'lon': '85.4379'},
      '583286': {'lat': '15.6926', 'name': 'Siddapur,Gadag,Karnataka', 'lon': '75.31'},
      '583287': {'lat': '15.6954', 'name': 'Irkalgad,Koppal,Karnataka', 'lon': '75.8841'},
      '583284': {'lat': '15.6558', 'name': 'Hiremyageri,Koppal,Karnataka', 'lon': '75.9229'},
      '583285': {'lat': '15.4311', 'name': 'Katarki,Gadag,Karnataka', 'lon': '75.6663'},
      '583282': {'lat': '14.985', 'name': 'Gundur Camp,Gadag,Karnataka', 'lon': '75.3079'},
      '583283': {'lat': '15.1414', 'name': 'Chickmendinhal,Koppal,Karnataka', 'lon': '75.8404'},
      '583280': {'lat': '15.8561', 'name': 'Habbalkatti,Koppal,Karnataka', 'lon': '75.8981'},
      '583281': {'lat': '15.9116', 'name': 'Yerigonal,Koppal,Karnataka', 'lon': '76.0133'},
      '229308': {'lat': '26.4372', 'name': 'Dahigawan,Raebareli,Uttar Pradesh', 'lon': '81.1471'},
      '229309': {'lat': '26.3182', 'name': 'Khanaipur Karangaon,Raebareli,Uttar Pradesh', 'lon': '81.5511'},
      '284128': {'lat': '25.0242', 'name': 'Mlb Medical College,Jhansi,Uttar Pradesh', 'lon': '78.5946'},
      '229302': {'lat': '26.107', 'name': 'Pure Wade Singh,Raebareli,Uttar Pradesh', 'lon': '81.531'},
      '229303': {'lat': '26.3725', 'name': 'Paharpur Kaso,Raebareli,Uttar Pradesh', 'lon': '81.0021'},
      '143305': {'lat': '31.3549', 'name': 'Wan Tara Singh,Tarn Taran,Punjab', 'lon': '74.6073'},
      '143304': {'lat': '31.4624', 'name': 'Kacha Pacca,Tarn Taran,Punjab', 'lon': '74.7566'},
      '143303': {'lat': '31.3583', 'name': 'Bhikhiwind,Tarn Taran,Punjab', 'lon': '74.6883'},
      '143302': {'lat': '31.4252', 'name': 'Bhucher Kalan,Tarn Taran,Punjab', 'lon': '74.7489'},
      '143301': {'lat': '31.4771', 'name': 'Chabhal,Tarn Taran,Punjab', 'lon': '74.7788'},
      '574274': {'lat': '13.0963', 'name': 'Kallamundkur,Dakshina Kannada,Karnataka', 'lon': '75.15'},
      '465447': {'lat': '23.8534', 'name': 'Amla,Shajapur,Madhya Pradesh', 'lon': '76.0987'},
      '832106': {'lat': '23.2202', 'name': 'Rakha Copper Project,East Singhbhum,Jharkhand', 'lon': '86.0029'},
      '465445': {'lat': '24.0532', 'name': 'Bhesoda,Shajapur,Madhya Pradesh', 'lon': '76.7452'},
      '275205': {'lat': '25.7675', 'name': 'Surhurpur,Ghazipur,Uttar Pradesh', 'lon': '83.3114'},
      '202002': {'lat': '27.7788', 'name': 'Quarsi,Aligarh,Uttar Pradesh', 'lon': '78.2137'},
      '275203': {'lat': '25.7307', 'name': 'Rampur Balbhadra,Ghazipur,Uttar Pradesh', 'lon': '83.1405'},
      '639206': {'lat': '10.8814', 'name': 'Muthanampalayam,Karur,Tamil Nadu', 'lon': '77.849'},
      '202001': {'lat': '27.5766', 'name': 'Sarai Labaria,Aligarh,Uttar Pradesh', 'lon': '78.6516'},
      '832104': {'lat': '23.5392', 'name': 'Palasbani,East Singhbhum,Jharkhand', 'lon': '86.7596'},
      '465449': {'lat': '23.8473', 'name': 'Dehriyakulmi,Shajapur,Madhya Pradesh', 'lon': '76.3631'},
      '412405': {'lat': '19.1847', 'name': 'Gawadewadi,Pune,Maharashtra', 'lon': '73.8803'},
      '412404': {'lat': '18.9217', 'name': 'Kohinde BK,Pune,Maharashtra', 'lon': '73.803'},
      '305811': {'lat': '26.641', 'name': 'Magari,Ajmer,Rajasthan', 'lon': '74.7474'},
      '412406': {'lat': '19.1847', 'name': 'Nirgudsar,Pune,Maharashtra', 'lon': '73.8803'},
      '412401': {'lat': '18.7581', 'name': 'Vadgaon Sahani,Pune,Maharashtra', 'lon': '74.047'},
      '305816': {'lat': '26.6534', 'name': 'Nalu,Ajmer,Rajasthan', 'lon': '74.9948'},
      '412403': {'lat': '18.8307', 'name': 'Kendur,Pune,Maharashtra', 'lon': '74.0537'},
      '412402': {'lat': '19.4948', 'name': 'Tokawade,Pune,Maharashtra', 'lon': '73.4904'},
      '832102': {'lat': '22.9294', 'name': 'Hathibinda,East Singhbhum,Jharkhand', 'lon': '85.9593'},
      '305819': {'lat': '26.7487', 'name': 'Karkeri,Ajmer,Rajasthan', 'lon': '74.7074'},
      '412409': {'lat': '19.2885', 'name': 'Dholwad,Pune,Maharashtra', 'lon': '73.9118'},
      '412408': {'lat': '18.5709', 'name': 'Sal,Pune,Maharashtra', 'lon': '74.0691'},
      '496338': {'lat': '22.7872', 'name': 'Lodam,Jashpur,Chattisgarh', 'lon': '83.834'},
      '416534': {'lat': '16.0889', 'name': 'Gavrai,Sindhudurg,Maharashtra', 'lon': '73.6746'},
      '416531': {'lat': '16.0889', 'name': 'Shirshinge,Sindhudurg,Maharashtra', 'lon': '73.6746'},
      '761026': {'lat': '19.834', 'name': 'Bupilingi,Ganjam,Odisha', 'lon': '83.5893'},
      '852101': {'lat': '25.8093', 'name': 'Laxmipur Bisharia,Madhepura,Bihar', 'lon': '87.0163'},
      '212201': {'lat': '25.575', 'name': 'Muratganj ED,Allahabad,Uttar Pradesh', 'lon': '81.4629'},
      '223104': {'lat': '25.8961', 'name': 'Narauli,Jaunpur,Uttar Pradesh', 'lon': '82.5785'},
      '212203': {'lat': '25.2989', 'name': 'Charwa,Allahabad,Uttar Pradesh', 'lon': '81.9717'},
      '212202': {'lat': '25.4077', 'name': 'Kasenda,Kaushambi,Uttar Pradesh', 'lon': '81.5989'},
      '212205': {'lat': '25.75', 'name': 'Aureni,Kaushambi,Uttar Pradesh', 'lon': '81.3551'},
      '212204': {'lat': '25.8922', 'name': 'Sawrai Buzurg,Allahabad,Uttar Pradesh', 'lon': '81.1611'},
      '212207': {'lat': '25.5297', 'name': 'Naugira,Kaushambi,Uttar Pradesh', 'lon': '81.499'},
      '226020': {'lat': '26.8717', 'name': 'Navinmandi,Lucknow,Uttar Pradesh', 'lon': '81.0729'},
      '643209': {'lat': '11.4695', 'name': 'Mulligoor,Nilgiris,Tamil Nadu', 'lon': '76.5523'},
      '563119': {'lat': '12.9178', 'name': 'Marikuppam,Kolar,Karnataka', 'lon': '78.2633'},
      '643207': {'lat': '11.5437', 'name': 'Mayfield,Nilgiris,Tamil Nadu', 'lon': '76.4404'},
      '643206': {'lat': '11.4585', 'name': 'Ebbanad,Nilgiris,Tamil Nadu', 'lon': '76.7327'},
      '643205': {'lat': '11.5129', 'name': 'Kayyunni,Nilgiris,Tamil Nadu', 'lon': '76.2781'},
      '643204': {'lat': '11.4695', 'name': 'Chamraj Estate,Nilgiris,Tamil Nadu', 'lon': '76.5523'},
      '643203': {'lat': '11.4695', 'name': 'Balacola,Nilgiris,Tamil Nadu', 'lon': '76.5523'},
      '383421': {'lat': '23.7425', 'name': 'Bolundra(R),Sabarkantha,Gujarat', 'lon': '73.0696'},
      '383422': {'lat': '24.3758', 'name': 'Tadhivedi,Sabarkantha,Gujarat', 'lon': '73.0586'},
      '620020': {'lat': '10.6605', 'name': 'Subramaniapuram (Tiruchirappalli),Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620021': {'lat': '10.6605', 'name': 'K.K.Nagar (Tiruchirappalli),Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620022': {'lat': '10.6605', 'name': 'Thuvakudimalai,Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620023': {'lat': '10.6605', 'name': 'Khajanagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620024': {'lat': '10.6605', 'name': 'Bharathidasan University,Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620025': {'lat': '10.6605', 'name': 'Palanganangudi,Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '620026': {'lat': '10.6605', 'name': 'Annanagar,Tiruchirappalli,Tamil Nadu', 'lon': '78.7889'},
      '223105': {'lat': '26.0165', 'name': 'Soithakalan,Jaunpur,Uttar Pradesh', 'lon': '82.4622'},
      '507211': {'lat': '18.1928', 'name': 'Uppalapahad,Khammam,Telangana', 'lon': '79.9161'},
      '507210': {'lat': '17.3225', 'name': 'Mulkanoor,Khammam,Telangana', 'lon': '79.7361'},
      '852107': {'lat': '25.8042', 'name': 'Patarghat,Saharsa,Bihar', 'lon': '86.7799'},
      '403704': {'lat': '15.1541', 'name': 'Molcarnem,South Goa,Goa', 'lon': '74.1838'},
      '148034': {'lat': '30.4297', 'name': 'Punnewal,Sangrur,Punjab', 'lon': '75.9985'},
      '522308': {'lat': '16.3131', 'name': 'Vallabhapuram,Guntur,Andhra Pradesh', 'lon': '80.5147'},
      '522309': {'lat': '16.1397', 'name': 'Ramabhotlapalem,Guntur,Andhra Pradesh', 'lon': '80.1225'},
      '623522': {'lat': '9.3756', 'name': 'Puduvalasai,Ramanathapuram,Tamil Nadu', 'lon': '78.954'},
      '403702': {'lat': '15.0278', 'name': 'Cotigaon,South Goa,Goa', 'lon': '74.0209'},
      '148035': {'lat': '30.1119', 'name': 'Kamalpur,Sangrur,Punjab', 'lon': '76.048'},
      '522302': {'lat': '16.3969', 'name': 'Revendrapadu,Guntur,Andhra Pradesh', 'lon': '80.6263'},
      '522303': {'lat': '16.4159', 'name': 'Nutakki,Guntur,Andhra Pradesh', 'lon': '80.6497'},
      '322034': {'lat': '26.1871', 'name': 'Padana,Sawai Madhopur,Rajasthan', 'lon': '76.5177'},
      '522301': {'lat': '16.2238', 'name': 'Chadalavada,Guntur,Andhra Pradesh', 'lon': '79.8868'},
      '522306': {'lat': '16.2921', 'name': 'Nandivelugu,Guntur,Andhra Pradesh', 'lon': '80.6434'},
      '522307': {'lat': '16.3061', 'name': 'Kolakaluru,Guntur,Andhra Pradesh', 'lon': '80.6181'},
      '522304': {'lat': '16.2876', 'name': 'Kollipara,Guntur,Andhra Pradesh', 'lon': '80.75'},
      '522305': {'lat': '16.3131', 'name': 'Godavarru Agraharam,Guntur,Andhra Pradesh', 'lon': '80.5147'},
      '627755': {'lat': '9.3579', 'name': 'Vadamalapuram,Tirunelveli,Tamil Nadu', 'lon': '78.1387'},
      '332301': {'lat': '28.0375', 'name': 'Fatehpur Bazar,Sikar,Rajasthan', 'lon': '74.8764'},
      '680570': {'lat': '10.4712', 'name': 'Vadakkummuri,Thrissur,Kerala', 'lon': '76.3162'},
      '680571': {'lat': '10.7336', 'name': 'Chazhur,Thrissur,Kerala', 'lon': '76.0025'},
      '731124': {'lat': '23.493', 'name': 'Joydevkenduli,Birbhum,West Bengal', 'lon': '87.8949'},
      '332303': {'lat': '27.5134', 'name': 'Bagroda,Sikar,Rajasthan', 'lon': '75.0854'},
      '636101': {'lat': '11.6768', 'name': 'Veppampoondi,Salem,Tamil Nadu', 'lon': '78.9095'},
      '332302': {'lat': '28.1436', 'name': 'Dhandhan,Sikar,Rajasthan', 'lon': '74.904'},
      '332305': {'lat': '28.0234', 'name': 'Balod Chhoti,Sikar,Rajasthan', 'lon': '75.103'},
      '431502': {'lat': '19.6492', 'name': 'Mapegaon,Jalna,Maharashtra', 'lon': '76.1785'},
      '562131': {'lat': '12.9435', 'name': 'Doddasomanahalli,Bangalore Rural,Karnataka', 'lon': '77.2033'},
      '577520': {'lat': '14.2352', 'name': 'Kurubarahally,Chitradurga,Karnataka', 'lon': '76.2187'},
      '110028': {'lat': '28.5806', 'name': 'Naraina Industrial Estate H.O,South West Delhi,Delhi', 'lon': '77.2201'},
      '110029': {'lat': '28.565', 'name': 'Ansari Nagar,South West Delhi,Delhi', 'lon': '77.1972'},
      '562135': {'lat': '13.0512', 'name': 'Mandibele,Bangalore Rural,Karnataka', 'lon': '76.7591'},
      '332307': {'lat': '28.1283', 'name': 'Thimoli,Sikar,Rajasthan', 'lon': '75.0957'},
      '577179': {'lat': '13.2657', 'name': 'Thotadur,Chikkamagaluru,Karnataka', 'lon': '75.4151'},
      '577521': {'lat': '14.2732', 'name': 'Yelagodu,Chitradurga,Karnataka', 'lon': '76.262'},
      '110022': {'lat': '28.5806', 'name': 'R K Puram Sect-12,South West Delhi,Delhi', 'lon': '77.2201'},
      '110023': {'lat': '28.5806', 'name': 'Laxmi Bai Nagar,South West Delhi,Delhi', 'lon': '77.2201'},
      '110020': {'lat': '28.5345', 'name': 'Tehkhand,South Delhi,Delhi', 'lon': '77.2779'},
      '110021': {'lat': '28.5818', 'name': 'Nanak Pura,South West Delhi,Delhi', 'lon': '77.1793'},
      '110026': {'lat': '28.6709', 'name': 'Punjabi Bagh Sec - III,West Delhi,Delhi', 'lon': '77.141'},
      '110027': {'lat': '28.5806', 'name': 'Tagore Garden,West Delhi,Delhi', 'lon': '77.2201'},
      '110024': {'lat': '28.5724', 'name': 'Amar Colony,South Delhi,Delhi', 'lon': '77.2365'},
      '110025': {'lat': '28.5672', 'name': 'Zakir Nagar,South Delhi,Delhi', 'lon': '77.2725'},
      '680682': {'lat': '10.47', 'name': 'Puthenchira Kizhakkummuri,Thrissur,Kerala', 'lon': '76.2244'},
      '680683': {'lat': '10.5386', 'name': 'Alur-Kallettumkara,Thrissur,Kerala', 'lon': '76.1585'},
      '680681': {'lat': '10.47', 'name': 'Chalingad,Thrissur,Kerala', 'lon': '76.2244'},
      '680686': {'lat': '10.47', 'name': 'Perinjanam,Thrissur,Kerala', 'lon': '76.2244'},
      '680687': {'lat': '10.47', 'name': 'Chentrappinni,Thrissur,Kerala', 'lon': '76.2244'},
      '680684': {'lat': '10.3671', 'name': 'Chembuchira,Thrissur,Kerala', 'lon': '76.3232'},
      '680685': {'lat': '10.47', 'name': 'Mathilakam,Thrissur,Kerala', 'lon': '76.2244'},
      '185121': {'lat': '33.6648', 'name': 'Fazalabad,Rajauri,Jammu & Kashmir', 'lon': '74.2844'},
      '577524': {'lat': '14.1733', 'name': 'Kurumaradikere,Chitradurga,Karnataka', 'lon': '76.476'},
      '680688': {'lat': '10.47', 'name': 'Padiyur,Thrissur,Kerala', 'lon': '76.2244'},
      '680689': {'lat': '10.47', 'name': 'Kanakamala,Thrissur,Kerala', 'lon': '76.2244'},
      '627753': {'lat': '9.3719', 'name': 'Srigomathipuram,Tirunelveli,Tamil Nadu', 'lon': '77.5856'},
      '614625': {'lat': '10.6056', 'name': 'Okkanadumelaiyur,Thanjavur,Tamil Nadu', 'lon': '79.2697'},
      '614624': {'lat': '10.1292', 'name': 'Kasimpudupet,Pudukkottai,Tamil Nadu', 'lon': '78.8231'},
      '614626': {'lat': '10.5299', 'name': 'Ambalapattu North,Thanjavur,Tamil Nadu', 'lon': '79.2728'},
      '614621': {'lat': '10.5694', 'name': 'Talanur,Pudukkottai,Tamil Nadu', 'lon': '79.1339'},
      '614620': {'lat': '12.3262', 'name': 'Edayathimangalam,Pudukkottai,Tamil Nadu', 'lon': '79.2806'},
      '614623': {'lat': '10.3308', 'name': 'Avanam,Thanjavur,Tamil Nadu', 'lon': '79.1386'},
      '614622': {'lat': '10.2561', 'name': 'Arayapatti,Pudukkottai,Tamil Nadu', 'lon': '78.9835'},
      '577527': {'lat': '13.8246', 'name': 'Lakkihally,Chitradurga,Karnataka', 'lon': '76.3004'},
      '614629': {'lat': '10.2241', 'name': 'Padirakudi,Pudukkottai,Tamil Nadu', 'lon': '78.865'},
      '614628': {'lat': '10.4261', 'name': 'Vengarai East,Thanjavur,Tamil Nadu', 'lon': '79.2084'},
      '454116': {'lat': '22.7719', 'name': 'Bimrod,Dhar,Madhya Pradesh', 'lon': '75.4313'},
      '800025': {'lat': '25.5904', 'name': 'Ashiananagar,Patna,Bihar', 'lon': '85.1624'},
      '712105': {'lat': '24.1096', 'name': 'Buroshibtala (Hooghly),Hooghly,West Bengal', 'lon': '87.6599'},
      '712104': {'lat': '22.9275', 'name': 'Keotalatbagan,Hooghly,West Bengal', 'lon': '88.3944'},
      '460663': {'lat': '21.8841', 'name': 'Hirawadi,Chhindwara,Madhya Pradesh', 'lon': '78.4444'},
      '712101': {'lat': '23.5138', 'name': 'Sandeswartala J.C.,Hooghly,West Bengal', 'lon': '88.0606'},
      '460665': {'lat': '21.6407', 'name': 'Magonakalan,Betul,Madhya Pradesh', 'lon': '78.2722'},
      '712103': {'lat': '22.9088', 'name': 'Tamlipara,Hooghly,West Bengal', 'lon': '88.3967'},
      '712102': {'lat': '24.1095', 'name': 'Sogandha,Hooghly,West Bengal', 'lon': '87.7914'},
      '731224': {'lat': '24.1767', 'name': 'Rampurhat H.O,Birbhum,West Bengal', 'lon': '87.7743'},
      '301001': {'lat': '27.5236', 'name': 'Sectt. Alwar,Alwar,Rajasthan', 'lon': '76.661'},
      '301002': {'lat': '27.5236', 'name': 'MOTIDOONGRI,Alwar,Rajasthan', 'lon': '76.661'},
      '508257': {'lat': '17.0638', 'name': 'Shah Ghajipur,Nalgonda,Telangana', 'lon': '79.1658'},
      '811305': {'lat': '24.8528', 'name': 'Kharhawa,Jamui,Bihar', 'lon': '86.1065'},
      '284204': {'lat': '25.5953', 'name': 'Aivani,Jhansi,Uttar Pradesh', 'lon': '79.1582'},
      '271904': {'lat': '27.1584', 'name': 'Arai Umari,Bahraich,Uttar Pradesh', 'lon': '81.5472'},
      '627751': {'lat': '9.8422', 'name': 'Kadayanallur Bazar,Tirunelveli,Tamil Nadu', 'lon': '77.7684'},
      '212635': {'lat': '26.0499', 'name': 'Mahraha,Fatehpur,Uttar Pradesh', 'lon': '80.7725'},
      '382330': {'lat': '23.0704', 'name': 'Medra,Ahmedabad,Gujarat', 'lon': '72.657'},
      '638010': {'lat': '11.3062', 'name': 'SPB Colony,Erode,Tamil Nadu', 'lon': '77.7207'},
      '638011': {'lat': '11.3062', 'name': 'Erode Collectorate,Erode,Tamil Nadu', 'lon': '77.7207'},
      '638012': {'lat': '11.3062', 'name': 'Thindal,Erode,Tamil Nadu', 'lon': '77.7207'},
      '500082': {'lat': '17.4253', 'name': 'I.M.Colony,Hyderabad,Telangana', 'lon': '78.4581'},
      '500085': {'lat': '17.2974', 'name': 'KPHB Colony,Hyderabad,Telangana', 'lon': '78.3132'},
      '733101': {'lat': '25.2296', 'name': 'District School Board (South Dinajpur),South Dinajpur,West Bengal', 'lon': '88.77835'},
      '733102': {'lat': '25.24845', 'name': 'Rajua,South Dinajpur,West Bengal', 'lon': '88.74345'},
      '733103': {'lat': '25.2465563', 'name': 'Amritakhanda Hat,South Dinajpur,West Bengal', 'lon': '88.8286854'},
      '500089': {'lat': '17.0534', 'name': 'Mancherevula,K.V.Rangareddy,Telangana', 'lon': '78.1238'},
      '227815': {'lat': '26.0912', 'name': 'Dobhiara,Sultanpur,Uttar Pradesh', 'lon': '82.166'},
      '277202': {'lat': '25.9993', 'name': 'Mairitar,Ballia,Uttar Pradesh', 'lon': '83.8497'},
      '277203': {'lat': '25.8275', 'name': 'Dawani,Ballia,Uttar Pradesh', 'lon': '83.9701'},
      '741140': {'lat': '23.6685', 'name': 'Rupdaha,Nadia,West Bengal', 'lon': '88.0961'},
      '277205': {'lat': '25.7803', 'name': 'Dubey Chhapra,Ballia,Uttar Pradesh', 'lon': '84.4104'},
      '621003': {'lat': '11.2421', 'name': 'Alathudayanpatti,Tiruchirappalli,Tamil Nadu', 'lon': '78.5007'},
      '422608': {'lat': '19.7568', 'name': 'Maldad,Ahmed Nagar,Maharashtra', 'lon': '74.7289'},
      '766015': {'lat': '19.9486', 'name': 'Chhilipa,Kalahandi,Odisha', 'lon': '82.9978'},
      '766016': {'lat': '20.4712', 'name': 'Khaliakani,Kalahandi,Odisha', 'lon': '83.1271'},
      '766017': {'lat': '20.0441', 'name': 'Dalguma,Kalahandi,Odisha', 'lon': '82.868'},
      '766011': {'lat': '19.9473', 'name': 'Gokuleswar,Kalahandi,Odisha', 'lon': '83.3766'},
      '766012': {'lat': '20.4276', 'name': 'Kandel Road,Kalahandi,Odisha', 'lon': '83.2149'},
      '766013': {'lat': '20.9993', 'name': 'Paruaguda,Kalahandi,Odisha', 'lon': '83.0632'},
      '422601': {'lat': '19.6468', 'name': 'Savargaon Pat,Ahmed Nagar,Maharashtra', 'lon': '75.5847'},
      '422602': {'lat': '19.7568', 'name': 'Bhojdari,Ahmed Nagar,Maharashtra', 'lon': '74.7289'},
      '422603': {'lat': '19.5325', 'name': 'Dhandharphal,Ahmed Nagar,Maharashtra', 'lon': '74.119'},
      '422604': {'lat': '19.7583', 'name': 'Lavhali Otur,Ahmed Nagar,Maharashtra', 'lon': '74.5022'},
      '422605': {'lat': '19.6062', 'name': 'Kuran,Ahmed Nagar,Maharashtra', 'lon': '74.5167'},
      '422606': {'lat': '20.2592', 'name': 'Bhojapur,Nashik,Maharashtra', 'lon': '74.0714'},
      '392170': {'lat': '22.5037', 'name': 'Nahar,Bharuch,Gujarat', 'lon': '72.6815'},
      '722101': {'lat': '23.2593', 'name': 'Lalbazar (Bankura),Bankura,West Bengal', 'lon': '87.3935'},
      '331411': {'lat': '28.5435', 'name': 'Kalwasia,Churu,Rajasthan', 'lon': '74.3804'},
      '461441': {'lat': '22.2958', 'name': 'Kudabamafi,Hoshangabad,Madhya Pradesh', 'lon': '78.0798'},
      '506319': {'lat': '17.8469', 'name': 'Kothagattu Singaram,Warangal,Telangana', 'lon': '79.2023'},
      '627111': {'lat': '8.2704', 'name': 'Radhapuram,Tirunelveli,Tamil Nadu', 'lon': '77.6861'},
      '627110': {'lat': '8.4276', 'name': 'Parappadi,Tirunelveli,Tamil Nadu', 'lon': '77.731'},
      '627113': {'lat': '8.4037', 'name': 'Terkukallikulam,Tirunelveli,Tamil Nadu', 'lon': '77.6871'},
      '627112': {'lat': '8.349', 'name': 'Kottai Karungulam,Tirunelveli,Tamil Nadu', 'lon': '77.6797'},
      '387305': {'lat': '22.8103', 'name': 'Kaparupur,Kheda,Gujarat', 'lon': '73.0468'},
      '313705': {'lat': '24.7527', 'name': 'Bagdunda,Udaipur,Rajasthan', 'lon': '73.572'},
      '627117': {'lat': '8.5882', 'name': 'Terku Vallioor,Tirunelveli,Tamil Nadu', 'lon': '77.6805'},
      '627116': {'lat': '8.2383', 'name': 'Sembikulam,Tirunelveli,Tamil Nadu', 'lon': '77.6412'},
      '627119': {'lat': '8.4037', 'name': 'Vijayanarayanam Naval Base,Tirunelveli,Tamil Nadu', 'lon': '77.6871'},
      '612902': {'lat': '11.1478', 'name': 'Anaikudam,Ariyalur,Tamil Nadu', 'lon': '79.4053'},
      '227304': {'lat': '26.0853', 'name': 'Ranipur,Sultanpur,Uttar Pradesh', 'lon': '82.21'},
      '612903': {'lat': '11.2545', 'name': 'Ilayaperumanallur,Ariyalur,Tamil Nadu', 'lon': '79.4883'},
      '389260': {'lat': '23.335', 'name': 'Kanajra,Panch Mahals,Gujarat', 'lon': '73.7797'},
      '624704': {'lat': '10.6694', 'name': 'Periakuzhipatti,Dindigul,Tamil Nadu', 'lon': '77.8853'},
      '624707': {'lat': '10.4429', 'name': 'Mallayapuram,Dindigul,Tamil Nadu', 'lon': '78.0715'},
      '624706': {'lat': '10.5841', 'name': 'R.Kombai,Dindigul,Tamil Nadu', 'lon': '78.0555'},
      '624701': {'lat': '10.2876', 'name': 'Attur,Dindigul,Tamil Nadu', 'lon': '77.852'},
      '389265': {'lat': '23.2147', 'name': 'Kelamul,Panch Mahals,Gujarat', 'lon': '73.8154'},
      '624703': {'lat': '10.792', 'name': 'Guziliamparai,Dindigul,Tamil Nadu', 'lon': '78.1744'},
      '624702': {'lat': '10.5202', 'name': 'Chittoor,Dindigul,Tamil Nadu', 'lon': '78.0467'},
      '673655': {'lat': '11.5663', 'name': 'Kolathara,Kozhikode,Kerala', 'lon': '75.7011'},
      '678601': {'lat': '10.7946', 'name': 'Uppukulam,Palakkad,Kerala', 'lon': '76.5586'},
      '624708': {'lat': '8.7253', 'name': 'Sithayankottai,Dindigul,Tamil Nadu', 'lon': '77.7374'},
      '676517': {'lat': '11.0716', 'name': 'Melmuri,Malappuram,Kerala', 'lon': '76.0708'},
      '676510': {'lat': '11.1392', 'name': 'Cherushola,Malappuram,Kerala', 'lon': '76.0485'},
      '497339': {'lat': '23.4829', 'name': 'Rajouli,Koriya,Chattisgarh', 'lon': '82.5182'},
      '229406': {'lat': '25.9302', 'name': 'Fgtp Unchahar,Raebareli,Uttar Pradesh', 'lon': '81.3309'},
      '676519': {'lat': '11.1392', 'name': 'Hajiarpalli,Malappuram,Kerala', 'lon': '76.0485'},
      '221001': {'lat': '25.2873', 'name': 'Bunker Market,Varanasi,Uttar Pradesh', 'lon': '82.6763'},
      '221003': {'lat': '25.3881', 'name': 'Sarsawan,Varanasi,Uttar Pradesh', 'lon': '82.9176'},
      '690526': {'lat': '9.1348', 'name': 'Ochira,Kollam,Kerala', 'lon': '76.5117'},
      '690527': {'lat': '9.0964', 'name': 'Govindamuttom,Alappuzha,Kerala', 'lon': '76.581'},
      '690524': {'lat': '9.0034', 'name': 'Padinjattakkara,Kollam,Kerala', 'lon': '76.5756'},
      '690525': {'lat': '9.1062', 'name': 'Clappana,Kollam,Kerala', 'lon': '76.4984'},
      '690522': {'lat': '9.1065', 'name': 'Patharam,Kollam,Kerala', 'lon': '76.6301'},
      '690523': {'lat': '9.0981', 'name': 'Thodiyur North,Kollam,Kerala', 'lon': '76.5545'},
      '690520': {'lat': '9.0912', 'name': 'Ambalathumbhagom,Kollam,Kerala', 'lon': '76.6593'},
      '690521': {'lat': '9.0331', 'name': 'Pallisserickal,Kollam,Kerala', 'lon': '76.5947'},
      '711108': {'lat': '22.5436', 'name': 'Netajigarh,Howrah,West Bengal', 'lon': '88.4146'},
      '176001': {'lat': '32.0623', 'name': 'Kangra Town,Kangra,Himachal Pradesh', 'lon': '76.2523'},
      '176002': {'lat': '32.1094', 'name': 'Tanda,Kangra,Himachal Pradesh', 'lon': '76.3046'},
      '690528': {'lat': '9.0964', 'name': 'Vavvakkavu,Kollam,Kerala', 'lon': '76.581'},
      '690529': {'lat': '9.1981', 'name': 'Pattoor,Alappuzha,Kerala', 'lon': '76.6237'},
      '143149': {'lat': '31.6448', 'name': 'Devidaspura,Amritsar,Punjab', 'lon': '75.1352'},
      '754008': {'lat': '20.334', 'name': 'Ghasiput,Cuttack,Odisha', 'lon': '85.0601'},
      '754009': {'lat': '20.4028', 'name': 'Kantakai,Cuttack,Odisha', 'lon': '85.2855'},
      '141419': {'lat': '30.6894', 'name': 'Ghaloti,Ludhiana,Punjab', 'lon': '76.1544'},
      '141418': {'lat': '30.522', 'name': 'Rampur (Ludhiana),Ludhiana,Punjab', 'lon': '75.8346'},
      '303326': {'lat': '26.7036', 'name': 'Chhokarwada,Dausa,Rajasthan', 'lon': '76.5054'},
      '754002': {'lat': '20.2508', 'name': 'Sundargram,Cuttack,Odisha', 'lon': '85.9828'},
      '754003': {'lat': '20.3253', 'name': 'Bramhanda,Cuttack,Odisha', 'lon': '86.0202'},
      '141417': {'lat': '30.7734', 'name': 'Kotla Bhari,Ludhiana,Punjab', 'lon': '76.3022'},
      '754001': {'lat': '20.09', 'name': 'Kalyanpur,Khorda,Odisha', 'lon': '85.5114'},
      '754006': {'lat': '20.4712', 'name': 'Banra,Cuttack,Odisha', 'lon': '85.7692'},
      '754007': {'lat': '20.3477', 'name': 'Pathapur,Cuttack,Odisha', 'lon': '85.3452'},
      '754004': {'lat': '20.3222', 'name': 'Nariso,Khorda,Odisha', 'lon': '85.3639'},
      '754005': {'lat': '20.3802', 'name': 'Gandarpur,Khorda,Odisha', 'lon': '85.9172'},
      '362630': {'lat': '22.3483', 'name': 'Sanosara,Junagadh,Gujarat', 'lon': '70.5652'},
      '271203': {'lat': '27.2829', 'name': 'Newal Garh,Balrampur,Uttar Pradesh', 'lon': '82.4693'},
      '587118': {'lat': '15.9227', 'name': 'Ramwadgi,Bagalkot,Karnataka', 'lon': '76.1184'},
      '587119': {'lat': '14.9651', 'name': 'Madarkhandi,Bagalkot,Karnataka', 'lon': '75.9744'},
      '271206': {'lat': '27.0149', 'name': 'Visunpur Tantanwa,Balrampur,Uttar Pradesh', 'lon': '82.7426'},
      '741157': {'lat': '23.6994', 'name': 'Bhurulia,Nadia,West Bengal', 'lon': '88.1823'},
      '271204': {'lat': '27.3677', 'name': 'Ashidha,Gonda,Uttar Pradesh', 'lon': '81.9935'},
      '229129': {'lat': '26.0414', 'name': 'Asharasidpur,Raebareli,Uttar Pradesh', 'lon': '81.5951'},
      '587112': {'lat': '15.3859', 'name': 'Chittargi,Bagalkot,Karnataka', 'lon': '75.7007'},
      '587113': {'lat': '15.7062', 'name': 'Mugalkhod,Bagalkot,Karnataka', 'lon': '75.6201'},
      '271208': {'lat': '27.3306', 'name': 'Chauhattar Kalan,Gonda,Uttar Pradesh', 'lon': '82.4903'},
      '587111': {'lat': '15.7933', 'name': 'Bagalkot Alokudyog,Bagalkot,Karnataka', 'lon': '75.4135'},
      '587116': {'lat': '15.9334', 'name': 'Mannikeri,Bagalkot,Karnataka', 'lon': '75.7925'},
      '587117': {'lat': '16.2475', 'name': 'Shirbur,Vijayapura,Karnataka', 'lon': '75.9539'},
      '587114': {'lat': '15.8562', 'name': 'Guddadmallapur,Bagalkot,Karnataka', 'lon': '75.7708'},
      '587115': {'lat': '16.1715', 'name': 'Huvanur,Bagalkot,Karnataka', 'lon': '76.0109'},
      '226101': {'lat': '26.7783', 'name': 'Barawan Kalan,Lucknow,Uttar Pradesh', 'lon': '81.2704'},
      '571249': {'lat': '12.0543', 'name': 'T.Shettigeri,Kodagu,Karnataka', 'lon': '75.9047'},
      '571248': {'lat': '12.1099', 'name': 'Kadagadal,Kodagu,Karnataka', 'lon': '75.8421'},
      '226104': {'lat': '27.0404', 'name': 'Ranipara,Lucknow,Uttar Pradesh', 'lon': '80.9473'},
      '455339': {'lat': '22.5787', 'name': 'Sandalpur,Dewas,Madhya Pradesh', 'lon': '76.9628'},
      '455336': {'lat': '22.6315', 'name': 'Chandwana,Dewas,Madhya Pradesh', 'lon': '76.8693'},
      '465679': {'lat': '23.849', 'name': 'Papdel,Rajgarh,Madhya Pradesh', 'lon': '76.5694'},
      '571247': {'lat': '12.3879', 'name': 'Sannapulikote,Kodagu,Karnataka', 'lon': '75.5295'},
      '243203': {'lat': '28.2573', 'name': 'Rehpura Ganimat,Bareilly,Uttar Pradesh', 'lon': '79.7604'},
      '603001': {'lat': '12.6363', 'name': 'Chengalpattu H.O,Kanchipuram,Tamil Nadu', 'lon': '79.9843'},
      '391156': {'lat': '22.4061', 'name': 'Raisingpura,Vadodara,Gujarat', 'lon': '73.9509'},
      '391155': {'lat': '21.9669', 'name': 'Sukal,Vadodara,Gujarat', 'lon': '73.4227'},
      '391152': {'lat': '22.4083', 'name': 'Vankla,Vadodara,Gujarat', 'lon': '73.2983'},
      '391150': {'lat': '21.4157', 'name': 'Jemalgadh,Vadodara,Gujarat', 'lon': '73.4152'},
      '209121': {'lat': '26.8307', 'name': 'Rasulpur Gogumau(E.U.P-7906),Kanpur Dehat,Uttar Pradesh', 'lon': '80.0882'},
      '481102': {'lat': '21.8411', 'name': 'Boda,Balaghat,Madhya Pradesh', 'lon': '80.2009'},
      '743401': {'lat': '22.4653', 'name': 'Buruj,North 24 Parganas,West Bengal', 'lon': '88.7803'},
      '481105': {'lat': '22.2183', 'name': 'Lood,Balaghat,Madhya Pradesh', 'lon': '80.4437'},
      '192401': {'lat': '34.0417', 'name': 'Wallarhama,Ananthnag,Jammu & Kashmir', 'lon': '74.2404'},
      '313706': {'lat': '24.5314', 'name': 'Idana,Udaipur,Rajasthan', 'lon': '74.0382'},
      '229127': {'lat': '26.0475', 'name': 'Rewhara,Raebareli,Uttar Pradesh', 'lon': '81.44'},
      '229402': {'lat': '26.1071', 'name': 'Ram Garh Tikaria,Raebareli,Uttar Pradesh', 'lon': '81.3124'},
      '502102': {'lat': '18.1725', 'name': 'Bhumpally,Medak,Telangana', 'lon': '78.8726'},
      '327801': {'lat': '23.4976', 'name': 'Chhoti Sarwa,Banswara,Rajasthan', 'lon': '74.2594'},
      '484220': {'lat': '23.0567', 'name': 'Chachai,Anuppur,Madhya Pradesh', 'lon': '81.684'},
      '625601': {'lat': '9.776', 'name': 'Periyakulam H.O,Theni,Tamil Nadu', 'lon': '77.7376'},
      '801112': {'lat': '25.5252', 'name': 'Bindaul,Patna,Bihar', 'lon': '84.7797'},
      '229124': {'lat': '26.0575', 'name': 'Kanhpur,Raebareli,Uttar Pradesh', 'lon': '81.4814'},
      '847423': {'lat': '26.1589', 'name': 'Maun Behat,Darbhanga,Bihar', 'lon': '86.2247'},
      '262405': {'lat': '28.9817', 'name': 'Bijitipattia,Udham Singh Nagar,Uttarakhand', 'lon': '79.727'},
      '249126': {'lat': '30.2705', 'name': 'Hinsriyakhal,Tehri Garhwal,Uttarakhand', 'lon': '78.6059'},
      '249127': {'lat': '30.0308', 'name': 'Madan Negi,Tehri Garhwal,Uttarakhand', 'lon': '78.7765'},
      '534146': {'lat': '16.8199', 'name': 'Allampuram,West Godavari,Andhra Pradesh', 'lon': '81.6173'},
      '534145': {'lat': '16.8385', 'name': 'Moduggunta,West Godavari,Andhra Pradesh', 'lon': '81.3997'},
      '491227': {'lat': '20.713', 'name': 'Jamgaon B,Durg,Chattisgarh', 'lon': '81.3774'},
      '464220': {'lat': '23.8182', 'name': 'Andiakala,Vidisha,Madhya Pradesh', 'lon': '77.8257'},
      '224188': {'lat': '26.7234', 'name': 'Meerpur,Faizabad,Uttar Pradesh', 'lon': '82.0469'},
      '476229': {'lat': '26.4138', 'name': 'Kishorgarh,Morena,Madhya Pradesh', 'lon': '77.8688'},
      '516289': {'lat': '14.0297', 'name': 'Apparaopalle,Cuddapah,Andhra Pradesh', 'lon': '78.6601'},
      '273310': {'lat': '26.998', 'name': 'Paniyara,Maharajganj,Uttar Pradesh', 'lon': '83.4665'},
      '606601': {'lat': '12.2607', 'name': 'Kalnagar,Tiruvannamalai,Tamil Nadu', 'lon': '79.0515'},
      '486882': {'lat': '24.2198', 'name': 'Khatai,Sidhi,Madhya Pradesh', 'lon': '81.8353'},
      '584139': {'lat': '16.2875', 'name': 'Kota,Raichur,Karnataka', 'lon': '76.6305'},
      '584138': {'lat': '15.7331', 'name': 'Diddgi,Raichur,Karnataka', 'lon': '76.8221'},
      '521149': {'lat': '16.7799', 'name': 'Kokanarayanapalem,Krishna,Andhra Pradesh', 'lon': '81.2392'},
      '521148': {'lat': '16.3695', 'name': 'Elamarru,Krishna,Andhra Pradesh', 'lon': '80.9269'},
      '584133': {'lat': '15.7936', 'name': 'Julumgera,Raichur,Karnataka', 'lon': '76.1734'},
      '584132': {'lat': '15.4531', 'name': 'Gunjahalli,Raichur,Karnataka', 'lon': '76.4952'},
      '584135': {'lat': '15.9737', 'name': 'Dongarampur,Raichur,Karnataka', 'lon': '76.7431'},
      '584134': {'lat': '16.2699', 'name': 'Yeganur,Raichur,Karnataka', 'lon': '77.3113'},
      '584136': {'lat': '16.2191', 'name': 'Athnur,Raichur,Karnataka', 'lon': '76.9207'},
      '638656': {'lat': '12.0998', 'name': 'Dharapuram H.O,Erode,Tamil Nadu', 'lon': '78.3383'},
      '638657': {'lat': '10.9241', 'name': 'Nanjiampalayam,Erode,Tamil Nadu', 'lon': '77.6359'},
      '332601': {'lat': '27.3589', 'name': 'Baya,Sikar,Rajasthan', 'lon': '76.0706'},
      '332602': {'lat': '27.5885', 'name': 'Abhawas,Sikar,Rajasthan', 'lon': '75.9523'},
      '332603': {'lat': '27.6366', 'name': 'Gudha,Sikar,Rajasthan', 'lon': '75.2875'},
      '303338': {'lat': '26.586', 'name': 'Hirnoda,Jaipur,Rajasthan', 'lon': '75.8984'},
      '797099': {'lat': '25.662', 'name': 'Bhandari,Wokha,Nagaland', 'lon': '94.1084'},
      '394250': {'lat': '22.012', 'name': 'Dedvasan,Surat,Gujarat', 'lon': '73.4'},
      '272194': {'lat': '26.8277', 'name': 'Dhosar,Basti,Uttar Pradesh', 'lon': '82.7357'},
      '241401': {'lat': '27.0832', 'name': 'Bootamau,Hardoi,Uttar Pradesh', 'lon': '79.8311'},
      '415302': {'lat': '18.2081', 'name': 'Bhavaninagar (Sangli),Sangli,Maharashtra', 'lon': '75.5649'},
      '271129': {'lat': '26.9293', 'name': 'Karnipur Deeh,Gonda,Uttar Pradesh', 'lon': '82.1218'},
      '484224': {'lat': '23.4446', 'name': 'Mediaras,Anuppur,Madhya Pradesh', 'lon': '80.9885'},
      '370020': {'lat': '23.2775', 'name': 'Bhujodi,Kachchh,Gujarat', 'lon': '69.8146'},
      '607109': {'lat': '11.7849', 'name': 'Alaginatham,Cuddalore,Tamil Nadu', 'lon': '79.7058'},
      '311603': {'lat': '25.4713', 'name': 'Gega Ka Khera,Bhilwara,Rajasthan', 'lon': '74.99'},
      '132108': {'lat': '29.3139', 'name': 'Siwaha,Panipat,Haryana', 'lon': '76.9717'},
      '311601': {'lat': '25.4874', 'name': 'Dhakarkhedi,Bhilwara,Rajasthan', 'lon': '74.5871'},
      '695042': {'lat': '8.5818', 'name': 'Kalliyoor,Thiruvananthapuram,Kerala', 'lon': '76.9083'},
      '695043': {'lat': '8.5818', 'name': 'Kallayam,Thiruvananthapuram,Kerala', 'lon': '76.9083'},
      '695040': {'lat': '8.5818', 'name': 'Kaimanam,Thiruvananthapuram,Kerala', 'lon': '76.9083'},
      '311604': {'lat': '25.7595', 'name': 'Ralayata,Bhilwara,Rajasthan', 'lon': '74.7743'},
      '132101': {'lat': '29.2561', 'name': 'Naraina,Panipat,Haryana', 'lon': '77.0088'},
      '132103': {'lat': '29.3882', 'name': 'Panipat GT Road,Panipat,Haryana', 'lon': '76.9586'},
      '132102': {'lat': '29.1967', 'name': 'Mahoti,Panipat,Haryana', 'lon': '77.0158'},
      '132105': {'lat': '29.3636', 'name': 'Bhadar,Panipat,Haryana', 'lon': '76.8598'},
      '132104': {'lat': '29.4345', 'name': 'Rajkheri,Panipat,Haryana', 'lon': '77.0114'},
      '132107': {'lat': '29.3022', 'name': 'Karad,Panipat,Haryana', 'lon': '76.82'},
      '132106': {'lat': '29.8721', 'name': 'Binjhol,Panipat,Haryana', 'lon': '76.7971'},
      '306302': {'lat': '26.1795', 'name': 'Ber - Kalan,Pali,Rajasthan', 'lon': '73.9268'},
      '700080': {'lat': '22.3341', 'name': 'Jessore Road,Kolkata,West Bengal', 'lon': '88.3065'},
      '306303': {'lat': '26.1737', 'name': 'Mohrai,Pali,Rajasthan', 'lon': '74.0013'},
      '611110': {'lat': '10.6265', 'name': 'Karapidagai,Nagapattinam,Tamil Nadu', 'lon': '79.8118'},
      '413120': {'lat': '17.9614', 'name': 'Nimgaon Ketki,Pune,Maharashtra', 'lon': '75.889'},
      '611112': {'lat': '10.597', 'name': 'Koilpathu,Nagapattinam,Tamil Nadu', 'lon': '79.8349'},
      '611105': {'lat': '10.7411', 'name': 'Kurukathi,Nagapattinam,Tamil Nadu', 'lon': '79.7189'},
      '700086': {'lat': '22.3341', 'name': 'Baghajatin,Kolkata,West Bengal', 'lon': '88.3065'},
      '560036': {'lat': '12.9987', 'name': 'Devasandra,Bengaluru,Karnataka', 'lon': '78.2593'},
      '388305': {'lat': '22.4396', 'name': 'Adas,Anand,Gujarat', 'lon': '72.4622'},
      '388306': {'lat': '22.4576', 'name': 'Vasad,Anand,Gujarat', 'lon': '73.0618'},
      '388307': {'lat': '22.4216', 'name': 'Ambali,Anand,Gujarat', 'lon': '71.8626'},
      '560032': {'lat': '13.0108', 'name': 'R T Nagar H.O,Bengaluru,Karnataka', 'lon': '77.7494'},
      '560033': {'lat': '13.0108', 'name': 'Maruthi Sevanagar,Bengaluru,Karnataka', 'lon': '77.7494'},
      '560030': {'lat': '13.2257', 'name': 'Adugodi,Bengaluru,Karnataka', 'lon': '77.575'},
      '457885': {'lat': '22.4877', 'name': 'Dhyana,Alirajpur,Madhya Pradesh', 'lon': '74.638'},
      '591241': {'lat': '15.6926', 'name': 'Appachiwadi,Belagavi,Karnataka', 'lon': '74.7029'},
      '591240': {'lat': '16.1109', 'name': 'Nandeshwar,Belagavi,Karnataka', 'lon': '75.3799'},
      '591243': {'lat': '15.9196', 'name': 'Narasinghpur,Belagavi,Karnataka', 'lon': '75.7371'},
      '591242': {'lat': '15.3595', 'name': 'Mangavati,Belagavi,Karnataka', 'lon': '75.2784'},
      '591244': {'lat': '15.8238', 'name': 'Malikwad,Belagavi,Karnataka', 'lon': '75.2476'},
      '591247': {'lat': '16.1195', 'name': 'Nagral,Belagavi,Karnataka', 'lon': '74.823'},
      '591246': {'lat': '15.8238', 'name': 'Yamakanmardi,Belagavi,Karnataka', 'lon': '75.2476'},
      '770040': {'lat': '21.8702', 'name': 'Chordhara,Sundergarh,Odisha', 'lon': '84.9249'},
      '382435': {'lat': '23.042', 'name': 'Geratpur,Ahmedabad,Gujarat', 'lon': '73.2824'},
      '770042': {'lat': '21.8728', 'name': 'Tensa,Sundergarh,Odisha', 'lon': '85.1713'},
      '770043': {'lat': '21.6821', 'name': 'Mayurdima,Sundergarh,Odisha', 'lon': '84.9135'},
      '382430': {'lat': '22.5635', 'name': 'Zanu,Ahmedabad,Gujarat', 'lon': '72.9091'},
      '306304': {'lat': '25.952', 'name': 'Deepwas,Pali,Rajasthan', 'lon': '74.0486'},
      '770046': {'lat': '22.0798', 'name': 'Hatibari,Sundergarh,Odisha', 'lon': '84.5106'},
      '382433': {'lat': '22.8825', 'name': 'Kaniyel,Ahmedabad,Gujarat', 'lon': '73.158'},
      '770048': {'lat': '21.8894', 'name': 'Dengula Ashram,Sundergarh,Odisha', 'lon': '85.2759'},
      '344032': {'lat': '25.9821', 'name': 'Richholi,Barmer,Rajasthan', 'lon': '72.5232'},
      '262576': {'lat': '29.7715', 'name': 'Fultari,Pithoragarh,Uttarakhand', 'lon': '79.7922'},
      '306305': {'lat': '25.9641', 'name': 'Leelamba,Pali,Rajasthan', 'lon': '74.491'},
      '228145': {'lat': '26.1709', 'name': 'Hamjapur Pathan,Sultanpur,Uttar Pradesh', 'lon': '82.3686'},
      '848160': {'lat': '25.7314', 'name': 'Bajitpur Bombaiya,Samastipur,Bihar', 'lon': '85.8902'},
      '262572': {'lat': '29.8356', 'name': 'Kukrauli,Pithoragarh,Uttarakhand', 'lon': '79.7138'},
      '503212': {'lat': '18.2636', 'name': 'Vannel "k",Nizamabad,Telangana', 'lon': '78.1978'},
      '326033': {'lat': '24.4313', 'name': 'Mishroli,Jhalawar,Rajasthan', 'lon': '76.4904'},
      '585265': {'lat': '17.1507', 'name': 'Hire Heroor,Kalaburagi,Karnataka', 'lon': '76.608'},
      '326036': {'lat': '24.4038', 'name': 'Kali Talai,Jhalawar,Rajasthan', 'lon': '76.3168'},
      '410207': {'lat': '19.0596', 'name': 'Gulsunde,Raigarh(MH),Maharashtra', 'lon': '73.09375'},
      '523228': {'lat': '15.4664', 'name': 'Mangampalli,Prakasam,Andhra Pradesh', 'lon': '79.3176'},
      '326035': {'lat': '25.0649', 'name': 'Akawad Khurd,Jhalawar,Rajasthan', 'lon': '75.9303'},
      '523226': {'lat': '15.5719', 'name': 'Bhusarapalli,Prakasam,Andhra Pradesh', 'lon': '79.867'},
      '523227': {'lat': '15.4312', 'name': 'Pedadasullapalli,Prakasam,Andhra Pradesh', 'lon': '79.3792'},
      '523224': {'lat': '15.1566', 'name': 'Jallapaem,Prakasam,Andhra Pradesh', 'lon': '79.3617'},
      '523225': {'lat': '15.5776', 'name': 'Maddulur,Prakasam,Andhra Pradesh', 'lon': '79.9764'},
      '737131': {'lat': '27.14575', 'name': 'South Regu,East Sikkim,Sikkim', 'lon': '88.4254'},
      '523223': {'lat': '15.0759', 'name': 'Pedagogulapalli,Prakasam,Andhra Pradesh', 'lon': '79.4645'},
      '737133': {'lat': '27.19232', 'name': 'Shivalaya Mandir Lingsey,East Sikkim,Sikkim', 'lon': '88.6796'},
      '737132': {'lat': '27.18648', 'name': 'Upper Pendam,East Sikkim,Sikkim', 'lon': '88.54854'},
      '229205': {'lat': '26.3017', 'name': 'Khanapur Khusti,Raebareli,Uttar Pradesh', 'lon': '80.9003'},
      '507182': {'lat': '17.8583', 'name': 'Chimmapudi,Khammam,Telangana', 'lon': '78.7749'},
      '465687': {'lat': '23.8612', 'name': 'Chosla,Rajgarh,Madhya Pradesh', 'lon': '76.7346'},
      '629168': {'lat': '8.3099', 'name': 'Pacode,Kanyakumari,Tamil Nadu', 'lon': '77.2568'},
      '629169': {'lat': '8.2683', 'name': 'Palliyadi,Kanyakumari,Tamil Nadu', 'lon': '77.2513'},
      '532462': {'lat': '18.6393', 'name': 'Neelanagaram,Srikakulam,Andhra Pradesh', 'lon': '83.6858'},
      '629167': {'lat': '8.3099', 'name': 'Kozhiporvilai,Kanyakumari,Tamil Nadu', 'lon': '77.2568'},
      '532460': {'lat': '18.6853', 'name': 'Killada,Srikakulam,Andhra Pradesh', 'lon': '83.6566'},
      '532461': {'lat': '18.6387', 'name': 'Seetharampuram Inam,Srikakulam,Andhra Pradesh', 'lon': '83.5955'},
      '629162': {'lat': '8.3099', 'name': 'Mulankuzhi,Kanyakumari,Tamil Nadu', 'lon': '77.2568'},
      '629163': {'lat': '8.3179', 'name': 'Thiruthuvapuram,Kanyakumari,Tamil Nadu', 'lon': '77.1919'},
      '629160': {'lat': '8.3099', 'name': 'Fathima Nagar,Kanyakumari,Tamil Nadu', 'lon': '77.2568'},
      '629161': {'lat': '8.3632', 'name': 'Ponmanai,Kanyakumari,Tamil Nadu', 'lon': '77.2978'},
      '768016': {'lat': '21.525', 'name': 'Hirakud,Sambalpur,Odisha', 'lon': '83.8727'},
      '803203': {'lat': '25.4938', 'name': 'Nandlalabad,Patna,Bihar', 'lon': '85.2193'},
      '611108': {'lat': '11.0762', 'name': 'Sikkal (Nagapattinam),Nagapattinam,Tamil Nadu', 'lon': '79.6213'},
      '571401': {'lat': '12.6252', 'name': 'Mandya Azadnagar,Mandya,Karnataka', 'lon': '76.7246'},
      '571403': {'lat': '12.4226', 'name': 'Cheeranahalli,Mandya,Karnataka', 'lon': '76.8887'},
      '571402': {'lat': '12.6339', 'name': 'Kommerahalli,Mandya,Karnataka', 'lon': '76.7212'},
      '571405': {'lat': '12.85', 'name': 'Maranachakanahalli,Mandya,Karnataka', 'lon': '76.3747'},
      '571404': {'lat': '12.5414', 'name': 'Acetate Town,Mandya,Karnataka', 'lon': '76.9557'},
      '680741': {'lat': '10.2213', 'name': 'Alathur(Thrissur),Thrissur,Kerala', 'lon': '76.3196'},
      '608001': {'lat': '11.6327', 'name': 'Chidambaram East,Cuddalore,Tamil Nadu', 'lon': '79.4467'},
      '673002': {'lat': '11.4256', 'name': 'Tali,Kozhikode,Kerala', 'lon': '75.8505'},
      '673003': {'lat': '11.6448', 'name': 'Kundungal,Kozhikode,Kerala', 'lon': '76.0066'},
      '673001': {'lat': '11.4256', 'name': 'Calicut R.S.,Kozhikode,Kerala', 'lon': '75.8505'},
      '673006': {'lat': '11.4256', 'name': 'St.Vincent Colony,Kozhikode,Kerala', 'lon': '75.8505'},
      '673007': {'lat': '11.855', 'name': 'Mankavu,Kozhikode,Kerala', 'lon': '75.7274'},
      '673004': {'lat': '11.248', 'name': 'Puthiyara,Kozhikode,Kerala', 'lon': '75.7804'},
      '673005': {'lat': '11.4256', 'name': 'West Hill,Kozhikode,Kerala', 'lon': '75.8505'},
      '673008': {'lat': '11.2686', 'name': 'Peruvayal,Kozhikode,Kerala', 'lon': '75.8628'},
      '673009': {'lat': '11.4256', 'name': 'Paropadi,Kozhikode,Kerala', 'lon': '75.8505'},
      '244241': {'lat': '28.6852', 'name': 'Pipalidaud,Jyotiba Phule Nagar,Uttar Pradesh', 'lon': '78.2781'},
      '341509': {'lat': '25.6136', 'name': 'Govindi,Nagaur,Rajasthan', 'lon': '74.4945'},
      '341508': {'lat': '26.1066', 'name': 'Rajaliya,Nagaur,Rajasthan', 'lon': '75.0109'},
      '282007': {'lat': '27.1875', 'name': 'Bodla,Agra,Uttar Pradesh', 'lon': '77.9623'},
      '462047': {'lat': '23.8547', 'name': 'Palas Indus Town,Bhopal,Madhya Pradesh', 'lon': '78.2026'},
      '457222': {'lat': '23.2401', 'name': 'Negarda,Ratlam,Madhya Pradesh', 'lon': '75.4522'},
      '209723': {'lat': '26.9433', 'name': 'Agaus,Kannauj,Uttar Pradesh', 'lon': '79.7064'},
      '209720': {'lat': '27.2694', 'name': 'Asaltabad,Kannauj,Uttar Pradesh', 'lon': '79.4333'},
      '711310': {'lat': '22.526', 'name': 'Santoshpur,Howrah,West Bengal', 'lon': '88.0676'},
      '689595': {'lat': '9.676', 'name': 'Mithrakary,Alappuzha,Kerala', 'lon': '76.4887'},
      '689594': {'lat': '9.676', 'name': 'Chengaroor,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '689597': {'lat': '9.676', 'name': 'Thuruthicaud,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '341502': {'lat': '27.0521', 'name': 'Saferkallan,Nagaur,Rajasthan', 'lon': '74.6231'},
      '689591': {'lat': '9.676', 'name': 'Mepral,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '689590': {'lat': '9.676', 'name': 'Veliyanadu North,Alappuzha,Kerala', 'lon': '76.4887'},
      '341507': {'lat': '26.5528', 'name': 'Shyamgarh,Nagaur,Rajasthan', 'lon': '74.6207'},
      '689592': {'lat': '9.676', 'name': 'Perumpatty,Pathanamthitta,Kerala', 'lon': '76.4887'},
      '425407': {'lat': '20.9618', 'name': 'Tamthare,Dhule,Maharashtra', 'lon': '74.6189'},
      '425406': {'lat': '21.2851', 'name': 'Mahalpur,Dhule,Maharashtra', 'lon': '74.9128'},
      '425405': {'lat': '21.3113', 'name': 'Lakdya-hanuman,Dhule,Maharashtra', 'lon': '75.0224'},
      '425404': {'lat': '21.2219', 'name': 'Ajande KH,Dhule,Maharashtra', 'lon': '74.8584'},
      '425403': {'lat': '21.1765', 'name': 'Ajande BK,Dhule,Maharashtra', 'lon': '74.9375'},
      '425402': {'lat': '21.1527', 'name': 'Bohare,Jalgaon,Maharashtra', 'lon': '75.0216'},
      '425401': {'lat': '21.0448', 'name': 'Galwade BK,Jalgaon,Maharashtra', 'lon': '75.1377'},
      '736179': {'lat': '26.0188', 'name': 'Khagrabari.,Cooch Behar,West Bengal', 'lon': '89.0137'},
      '425409': {'lat': '21.3514', 'name': 'Vardhe,Nandurbar,Maharashtra', 'lon': '74.435'},
      '425408': {'lat': '21.1868', 'name': 'Bamhane,Dhule,Maharashtra', 'lon': '74.6666'},
      '462044': {'lat': '23.4682', 'name': 'Sewaniya Gond,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '497773': {'lat': '22.1945', 'name': 'West Chirimiri,Koriya,Chattisgarh', 'lon': '82.4904'},
      '305009': {'lat': '26.4475', 'name': 'Mds University Ajmer,Ajmer,Rajasthan', 'lon': '74.6288'},
      '784001': {'lat': '26.5735', 'name': 'Hazarapar,Sonitpur,Assam', 'lon': '91.1499'},
      '505425': {'lat': '18.591', 'name': 'Thimmapur,Karim Nagar,Telangana', 'lon': '79.0878'},
      '305004': {'lat': '26.5151', 'name': 'Vaishali Nagar Ajmer,Ajmer,Rajasthan', 'lon': '74.6214'},
      '305005': {'lat': '26.4475', 'name': 'Hatikhera,Ajmer,Rajasthan', 'lon': '74.6288'},
      '305007': {'lat': '26.4475', 'name': 'Gc Road Ajmer,Ajmer,Rajasthan', 'lon': '74.6288'},
      '305001': {'lat': '26.4475', 'name': 'Jln Hospital Ajmer,Ajmer,Rajasthan', 'lon': '74.6288'},
      '305002': {'lat': '26.3963', 'name': 'Ie Makhu Pura Ajmer,Ajmer,Rajasthan', 'lon': '74.6646'},
      '305003': {'lat': '26.4055', 'name': 'Ram Ganj Ajmer,Ajmer,Rajasthan', 'lon': '74.6182'},
      '383210': {'lat': '23.5084', 'name': 'Sonasan,Sabarkantha,Gujarat', 'lon': '72.9973'},
      '629251': {'lat': '8.1794', 'name': 'Kurumpanai,Kanyakumari,Tamil Nadu', 'lon': '77.2582'},
      '383215': {'lat': '23.2918', 'name': 'Doltabad,Sabarkantha,Gujarat', 'lon': '73.0734'},
      '455221': {'lat': '22.7152', 'name': 'Double Chowki,Dewas,Madhya Pradesh', 'lon': '76.458'},
      '396370': {'lat': '20.838', 'name': 'Kachholi,Navsari,Gujarat', 'lon': '72.9738'},
      '261001': {'lat': '27.5064', 'name': 'Ailiya,Sitapur,Uttar Pradesh', 'lon': '80.6436'},
      '700066': {'lat': '22.4839', 'name': 'Bidhangarh,Kolkata,West Bengal', 'lon': '88.3038'},
      '822122': {'lat': '24.0488', 'name': 'Udaipru,Palamau,Jharkhand', 'lon': '84.4638'},
      '761125': {'lat': '20.0423', 'name': 'Dumakumpa,Ganjam,Odisha', 'lon': '84.4611'},
      '250501': {'lat': '28.891', 'name': 'Peepla Idrishpur,Meerut,Uttar Pradesh', 'lon': '77.5295'},
      '250502': {'lat': '29.0504', 'name': 'Chandori Khas,Meerut,Uttar Pradesh', 'lon': '77.6349'},
      '208010': {'lat': '26.5934', 'name': 'Shiwans Tanney,Kanpur Nagar,Uttar Pradesh', 'lon': '80.6879'},
      '627355': {'lat': '8.4367', 'name': 'Chinthamani,Tirunelveli,Tamil Nadu', 'lon': '77.736'},
      '788162': {'lat': '26.332', 'name': 'Gharmura Bazar,Hailakandi,Assam', 'lon': '89.8452'},
      '796370': {'lat': '23.4434', 'name': 'Leng,Serchhip,Mizoram', 'lon': '92.8578'},
      '577589': {'lat': '14.5701', 'name': 'Kadlebalu,Davangere,Karnataka', 'lon': '76.0886'},
      '731220': {'lat': '24.2899', 'name': 'Kaitha,Birbhum,West Bengal', 'lon': '87.9125'},
      '802103': {'lat': '25.5369', 'name': 'Buxar Gajadhar Ganj,Buxar,Bihar', 'lon': '84.0273'},
      '560026': {'lat': '12.9996', 'name': 'Nayandahalli,Bengaluru,Karnataka', 'lon': '77.6359'},
      '560021': {'lat': '13.2257', 'name': 'Srirampuram,Bengaluru,Karnataka', 'lon': '77.575'},
      '455227': {'lat': '22.6466', 'name': 'Chapda,Dewas,Madhya Pradesh', 'lon': '76.3864'},
      '282002': {'lat': '27.2046', 'name': 'Billoch Pura,Agra,Uttar Pradesh', 'lon': '79.2674'},
      '515701': {'lat': '14.6254', 'name': 'Danduvaripalli,Ananthapur,Andhra Pradesh', 'lon': '77.9714'},
      '486341': {'lat': '24.5446', 'name': 'Purwa,Rewa,Madhya Pradesh', 'lon': '81.5471'},
      '560020': {'lat': '13.2257', 'name': 'Seshadripuram,Bengaluru,Karnataka', 'lon': '77.575'},
      '632531': {'lat': '12.7595', 'name': 'Ocheri,Vellore,Tamil Nadu', 'lon': '79.2204'},
      '416519': {'lat': '19.2315', 'name': 'Narur,Sindhudurg,Maharashtra', 'lon': '75.6811'},
      '281121': {'lat': '27.581', 'name': 'Brij Accadamy,Mathura,Uttar Pradesh', 'lon': '77.6966'},
      '854304': {'lat': '25.8286', 'name': 'Jageli,Purnia,Bihar', 'lon': '87.3751'},
      '854305': {'lat': '25.7883', 'name': 'Khuskibagh,Purnia,Bihar', 'lon': '87.3915'},
      '854306': {'lat': '25.6508', 'name': 'Burhia Dhankatta,Purnia,Bihar', 'lon': '87.2618'},
      '854301': {'lat': '25.7698', 'name': 'Purnea Court,Purnia,Bihar', 'lon': '87.481'},
      '854302': {'lat': '25.7883', 'name': 'Purnea name,Purnia,Bihar', 'lon': '87.3915'},
      '854303': {'lat': '25.7863', 'name': 'Gangali,Purnia,Bihar', 'lon': '87.4715'},
      '400033': {'lat': '19.0167', 'name': 'Reay Road,Mumbai,Maharashtra', 'lon': '72.85'},
      '400032': {'lat': '19.0167', 'name': 'Mantralaya (Mumbai),Mumbai,Maharashtra', 'lon': '72.85'},
      '400031': {'lat': '19.0167', 'name': 'Wadala Rs,Mumbai,Maharashtra', 'lon': '72.85'},
      '400030': {'lat': '19.0167', 'name': 'Worli Police Camp,Mumbai,Maharashtra', 'lon': '72.85'},
      '400037': {'lat': '19.0167', 'name': 'Antop Hill,Mumbai,Maharashtra', 'lon': '72.85'},
      '414002': {'lat': '18.6966', 'name': 'Nimbodi,Ahmed Nagar,Maharashtra', 'lon': '75.7265'},
      '400035': {'lat': '19.0167', 'name': 'Rajbhavan (Mumbai),Mumbai,Maharashtra', 'lon': '72.85'},
      '400034': {'lat': '19.0167', 'name': 'Haji Ali,Mumbai,Maharashtra', 'lon': '72.85'},
      '281205': {'lat': '27.5114', 'name': 'Sikanderpur,Mathura,Uttar Pradesh', 'lon': '78.027'},
      '281204': {'lat': '27.5652', 'name': 'Siriya Ki Nagaria,Mathura,Uttar Pradesh', 'lon': '77.7947'},
      '282003': {'lat': '27.213', 'name': 'Agra Fort H.O,Agra,Uttar Pradesh', 'lon': '78.1858'},
      '281206': {'lat': '27.5537', 'name': 'Kumhan,Mathura,Uttar Pradesh', 'lon': '77.8761'},
      '281201': {'lat': '28.0171', 'name': 'Ahamadpur,Mathura,Uttar Pradesh', 'lon': '77.9596'},
      '281203': {'lat': '27.9103', 'name': 'Raipur,Mathura,Uttar Pradesh', 'lon': '77.5542'},
      '637201': {'lat': '11.3126', 'name': 'Chittalandur,Namakkal,Tamil Nadu', 'lon': '77.9216'},
      '623135': {'lat': '9.1181', 'name': 'Narippaiyur,Ramanathapuram,Tamil Nadu', 'lon': '78.3683'},
      '502249': {'lat': '17.6206', 'name': 'Yelgoi,Medak,Telangana', 'lon': '78.4013'},
      '641108': {'lat': '11.1162', 'name': 'Pathanjalipuri,Coimbatore,Tamil Nadu', 'lon': '76.7549'},
      '761117': {'lat': '20.0355', 'name': 'Bhatakhali,Ganjam,Odisha', 'lon': '84.7959'},
      '518563': {'lat': '15.3398', 'name': 'Kaminenipalle,Kurnool,Andhra Pradesh', 'lon': '78.4931'},
      '524240': {'lat': '14.7022', 'name': 'Lingalapadu,Nellore,Andhra Pradesh', 'lon': '79.8647'},
      '283203': {'lat': '27.337', 'name': 'Ali Nagar Kenjra,Firozabad,Uttar Pradesh', 'lon': '78.3438'},
      '573120': {'lat': '12.7859', 'name': 'Ankanayakanahalli,Hassan,Karnataka', 'lon': '76.0548'},
      '573121': {'lat': '13.1403', 'name': 'Cheelanayakanahalli,Hassan,Karnataka', 'lon': '76.0354'},
      '573122': {'lat': '13.1214', 'name': 'Handralu,Hassan,Karnataka', 'lon': '75.9699'},
      '573123': {'lat': '12.8275', 'name': 'Goddu,Hassan,Karnataka', 'lon': '75.836'},
      '573124': {'lat': '12.9286', 'name': 'Sabbanahalli,Hassan,Karnataka', 'lon': '76.5763'},
      '573125': {'lat': '13.287', 'name': 'Bandur,Hassan,Karnataka', 'lon': '76.0974'},
      '573126': {'lat': '13.4076', 'name': 'Jaya Chamaraja Pura,Hassan,Karnataka', 'lon': '76.3623'},
      '573127': {'lat': '13.0155', 'name': 'Norway,Hassan,Karnataka', 'lon': '75.8212'},
      '671534': {'lat': '12.153', 'name': 'Punnakkunnu,Kasargod,Kerala', 'lon': '75.2984'},
      '573129': {'lat': '12.8282', 'name': 'Magge,Hassan,Karnataka', 'lon': '75.8814'},
      '671531': {'lat': '11.969', 'name': 'Paraklayi,Kasargod,Kerala', 'lon': '75.3343'},
      '671532': {'lat': '12.153', 'name': 'Rajapuram,Kasargod,Kerala', 'lon': '75.2984'},
      '671533': {'lat': '12.3982', 'name': 'Kanakappally,Kasargod,Kerala', 'lon': '75.2507'},
      '246475': {'lat': '30.0608', 'name': 'Mayali,Rudraprayag,Uttarakhand', 'lon': '79.0061'},
      '641105': {'lat': '10.9057', 'name': 'Palathurai,Coimbatore,Tamil Nadu', 'lon': '76.9634'},
      '246473': {'lat': '29.8661', 'name': 'Raduwa,Chamoli,Uttarakhand', 'lon': '79.2265'},
      '246472': {'lat': '30.3432', 'name': 'Gadora,Chamoli,Uttarakhand', 'lon': '79.0509'},
      '246471': {'lat': '30.4798', 'name': 'Badasu,Rudraprayag,Uttarakhand', 'lon': '79.0188'},
      '246130': {'lat': '29.9271', 'name': 'Banani,Pauri Garhwal,Uttarakhand', 'lon': '78.6794'},
      '327021': {'lat': '24.7789', 'name': 'Buda Bassi,Banswara,Rajasthan', 'lon': '74.1737'},
      '742410': {'lat': '23.9685', 'name': 'Rejinagar,Murshidabad,West Bengal', 'lon': '87.8257'},
      '848129': {'lat': '25.8358', 'name': 'Bikrampur Bandey,Samastipur,Bihar', 'lon': '85.5209'},
      '583126': {'lat': '14.6449', 'name': 'Kadekola,Ballari,Karnataka', 'lon': '76.4555'},
      '524308': {'lat': '14.6548', 'name': 'Vasili,Nellore,Andhra Pradesh', 'lon': '79.7614'},
      '524309': {'lat': '14.872', 'name': 'Mohammadapuram,Nellore,Andhra Pradesh', 'lon': '79.6219'},
      '282001': {'lat': '27.3869', 'name': 'Namner,Agra,Uttar Pradesh', 'lon': '78.4798'},
      '462041': {'lat': '23.4682', 'name': 'Ayodhaya Nagar,Bhopal,Madhya Pradesh', 'lon': '77.8679'},
      '524304': {'lat': '15.3506', 'name': 'Panugodu,Nellore,Andhra Pradesh', 'lon': '78.8318'},
      '524305': {'lat': '14.4968', 'name': 'Penuballi,Nellore,Andhra Pradesh', 'lon': '79.8898'},
      '524306': {'lat': '14.5671', 'name': 'Padamatipalem,Nellore,Andhra Pradesh', 'lon': '79.7896'},
      '524307': {'lat': '14.6738', 'name': 'Kona Samudram,Nellore,Andhra Pradesh', 'lon': '78.7341'},
      '172001': {'lat': '31.3436', 'name': 'Naula,Shimla,Himachal Pradesh', 'lon': '77.5217'},
      '670621': {'lat': '11.8547', 'name': 'Kadachira,Kannur,Kerala', 'lon': '75.483'},
      '524303': {'lat': '13.8773', 'name': 'Revur,Nellore,Andhra Pradesh', 'lon': '79.3874'},
      '845428': {'lat': '26.2642', 'name': 'Majhar Gohiya,East Champaran,Bihar', 'lon': '84.7642'},
      '246131': {'lat': '29.9342', 'name': 'Srikotkhal,Pauri Garhwal,Uttarakhand', 'lon': '78.8115'},
      '621219': {'lat': '11.3327', 'name': 'Yesanai,Perambalur,Tamil Nadu', 'lon': '78.8644'},
      '382424': {'lat': '23.1116', 'name': 'Chandkheda Society Area,Ahmedabad,Gujarat', 'lon': '72.5722'},
      '758002': {'lat': '21.534', 'name': 'Keonjhar Bazar,Kendujhar,Odisha', 'lon': '85.5694'},
      '758001': {'lat': '20.3455', 'name': 'Keonjhargarh H.O,Kendujhar,Odisha', 'lon': '85.2354'},
      '140405': {'lat': '30.615', 'name': 'Badochikalan,Fatehgarh Sahib,Punjab', 'lon': '76.3142'},
      '140407': {'lat': '30.5733', 'name': 'Fatehgarh Sahib,Fatehgarh Sahib,Punjab', 'lon': '76.5092'},
      '140406': {'lat': '30.6147', 'name': 'Mahadian,Patiala,Punjab', 'lon': '76.4568'},
      '140401': {'lat': '30.5733', 'name': 'KSM Rajpura,Patiala,Punjab', 'lon': '76.5092'},
      '416411': {'lat': '18.906', 'name': 'Alkud  (S),Sangli,Maharashtra', 'lon': '76.5271'},
      '787001': {'lat': '27.0636', 'name': 'North Lakhimpur H.O,Lakhimpur,Assam', 'lon': '93.7857'},
      '246155': {'lat': '29.9163', 'name': 'Adhariyakhal,Pauri Garhwal,Uttarakhand', 'lon': '78.8935'},
      '743378': {'lat': '22.2669', 'name': 'Chhoto Mollakhali,South 24 Parganas,West Bengal', 'lon': '88.9111'},
      '271845': {'lat': '27.3069', 'name': 'Khargaura Janoob,Shrawasti,Uttar Pradesh', 'lon': '81.7528'},
      '274604': {'lat': '26.3174', 'name': 'Kasili,Deoria,Uttar Pradesh', 'lon': '83.0733'},
      '140408': {'lat': '30.6562', 'name': 'Makrampur,Fatehgarh Sahib,Punjab', 'lon': '76.528'},
      '700124': {'lat': '22.8848', 'name': 'Sethpukur,North 24 Parganas,West Bengal', 'lon': '88.3469'},
      '700125': {'lat': '22.7572', 'name': 'Mirhati,North 24 Parganas,West Bengal', 'lon': '88.4556'},
      '700126': {'lat': '22.6494', 'name': 'Nabapally,North 24 Parganas,West Bengal', 'lon': '88.2897'},
      '700127': {'lat': '22.7017', 'name': 'Hridaypur,North 24 Parganas,West Bengal', 'lon': '88.4695'},
      '700120': {'lat': '22.8848', 'name': 'Barrackpore Bazar,North 24 Parganas,West Bengal', 'lon': '88.3469'},
      '700121': {'lat': '22.8848', 'name': 'Sewli Telinipara,North 24 Parganas,West Bengal', 'lon': '88.3469'},
      '700122': {'lat': '22.8848', 'name': 'Anandapuri,North 24 Parganas,West Bengal', 'lon': '88.3469'},
      '700123': {'lat': '22.8848', 'name': 'Panpara (North 24 Parganas),North 24 Parganas,West Bengal', 'lon': '88.3469'},
      '421403': {'lat': '20.0771', 'name': 'Thune,Thane,Maharashtra', 'lon': '75.0291'},
      '421402': {'lat': '19.2661', 'name': 'Dhasai,Thane,Maharashtra', 'lon': '73.5937'},
      '421401': {'lat': '18.414', 'name': 'Tulai,Thane,Maharashtra', 'lon': '74.0107'},
      '757079': {'lat': '21.6753', 'name': 'Katuria,Mayurbhanj,Odisha', 'lon': '85.4494'},
      '700128': {'lat': '23.3733', 'name': 'Bhagyamantapur,North 24 Parganas,West Bengal', 'lon': '88.2391'},
      '700129': {'lat': '22.6894', 'name': 'Udayrajpur,North 24 Parganas,West Bengal', 'lon': '88.4459'},
      '480991': {'lat': '22.493', 'name': 'Kudari Maghgaon,Seoni,Madhya Pradesh', 'lon': '79.5589'},
      '743374': {'lat': '22.03', 'name': 'Gazirmahal Chitamari,South 24 Parganas,West Bengal', 'lon': '88.2873'},
      '824208': {'lat': '24.8833', 'name': 'Pirthoo,Aurangabad(BH),Bihar', 'lon': '84.5394'},
      '824209': {'lat': '24.8016', 'name': 'Puna Kala,Gaya,Bihar', 'lon': '84.8559'},
      '272130': {'lat': '26.8772', 'name': 'Deferi,Basti,Uttar Pradesh', 'lon': '82.5779'},
      '757077': {'lat': '21.5062', 'name': 'Badkhaman,Mayurbhanj,Odisha', 'lon': '85.3453'},
      '824202': {'lat': '24.6858', 'name': 'Panchaukhar,Aurangabad(BH),Bihar', 'lon': '84.6674'},
      '824203': {'lat': '25.2143', 'name': 'Sakardiha,Aurangabad(BH),Bihar', 'lon': '84.548'},
      '332031': {'lat': '27.2404', 'name': 'Subhash Nagar,Sikar,Rajasthan', 'lon': '75.8246'},
      '332030': {'lat': '27.4328', 'name': 'Singrawat,Sikar,Rajasthan', 'lon': '74.8788'},
      '824206': {'lat': '24.4545', 'name': 'Imamganj,Gaya,Bihar', 'lon': '84.5869'},
      '743376': {'lat': '22.415', 'name': 'Chhayani,South 24 Parganas,West Bengal', 'lon': '88.307'},
      '517257': {'lat': '13.4421', 'name': 'Kagathi,Chittoor,Andhra Pradesh', 'lon': '78.6933'},
      '415202': {'lat': '18.5574', 'name': 'Tide,Ratnagiri,Maharashtra', 'lon': '74.4986'},
      '415203': {'lat': '19.6184', 'name': 'Tulshi,Ratnagiri,Maharashtra', 'lon': '77.6699'},
      '743377': {'lat': '22.415', 'name': 'Chowl Khola,South 24 Parganas,West Bengal', 'lon': '88.307'},
      '415206': {'lat': '17.3751', 'name': 'Natoshi,Satara,Maharashtra', 'lon': '73.9014'},
      '415207': {'lat': '17.3755', 'name': 'Navaja,Satara,Maharashtra', 'lon': '73.7305'},
      '415205': {'lat': '20.9402', 'name': 'Ambrule,Satara,Maharashtra', 'lon': '77.2104'},
      '743370': {'lat': '22.1655', 'name': 'Jamespur,South 24 Parganas,West Bengal', 'lon': '88.8007'},
      '415208': {'lat': '18.1976', 'name': 'Gothe,Ratnagiri,Maharashtra', 'lon': '72.9927'},
      '415209': {'lat': '18.5574', 'name': 'Bahule,Satara,Maharashtra', 'lon': '74.4986'},
      '835207': {'lat': '23.132', 'name': 'Gumla H.O,Gumla,Jharkhand', 'lon': '84.9558'},
      '326023': {'lat': '24.7479', 'name': 'Parlia Kulmi,Jhalawar,Rajasthan', 'lon': '76.0825'},
      '743371': {'lat': '22.4353', 'name': 'Choto Banashyamnagar,South 24 Parganas,West Bengal', 'lon': '87.8835'},
      '795139': {'lat': '24.0849', 'name': 'Behiang,Churachandpur,Manipur', 'lon': '93.6045'},
      '387115': {'lat': '23.5972', 'name': 'Kanjoda,Kheda,Gujarat', 'lon': '72.9882'},
      '326022': {'lat': '24.3533', 'name': 'Karal Gaon,Jhalawar,Rajasthan', 'lon': '76.3339'},
      '387110': {'lat': '23.5972', 'name': 'Jaravat,Kheda,Gujarat', 'lon': '72.9882'},
      '743372': {'lat': '22.1906', 'name': 'Sarberia,South 24 Parganas,West Bengal', 'lon': '88.4257'},
      '795131': {'lat': '24.3182', 'name': 'Khenjoi,Tengnoupal,Manipur', 'lon': '94.224'},
      '795130': {'lat': '24.5217', 'name': 'Thiyamkonjil,Imphal East,Manipur', 'lon': '93.9404'},
      '795133': {'lat': '24.4975', 'name': 'Nabil (P),Bishnupur,Manipur', 'lon': '93.7779'},
      '795132': {'lat': '24.61', 'name': 'Sekmaijin  Khunou Konuma,Imphal West,Manipur', 'lon': '93.8887'},
      '795135': {'lat': '24.3613', 'name': 'Laiching Tangsang,Chandel,Manipur', 'lon': '94.0327'},
      '413404': {'lat': '19.5631', 'name': 'Shirale,Solapur,Maharashtra', 'lon': '76.9466'},
      '795136': {'lat': '24.9769', 'name': 'Sekmai,Imphal East,Manipur', 'lon': '93.8879'},
      '764081': {'lat': '19.0723', 'name': 'Lamtaput,Koraput,Odisha', 'lon': '82.911'},
      '413405': {'lat': '20.0328', 'name': 'Wagholi (YDS),Osmanabad,Maharashtra', 'lon': '75.9845'},
      '764085': {'lat': '19.5263', 'name': 'Parajadeopalli,Nabarangapur,Odisha', 'lon': '82.21466'},
      '764087': {'lat': '19.3627', 'name': 'Malavaram,Malkangiri,Odisha', 'lon': '82.7237'},
      '764086': {'lat': '18.9352', 'name': 'Pulimetla,Malkangiri,Odisha', 'lon': '82.776'},
      '695543': {'lat': '8.5598', 'name': 'Kuthirakalam-vellanad,Thiruvananthapuram,Kerala', 'lon': '77.0503'},
      '695542': {'lat': '8.6175', 'name': 'Kottakkakom,Thiruvananthapuram,Kerala', 'lon': '77.0163'},
      '695541': {'lat': '8.6367', 'name': 'Karipur,Thiruvananthapuram,Kerala', 'lon': '77.005'},
      '695547': {'lat': '8.6175', 'name': 'Valiamala,Thiruvananthapuram,Kerala', 'lon': '77.0163'},
      '384140': {'lat': '23.7383', 'name': 'Kamli,Mahesana,Gujarat', 'lon': '72.622'},
      '413403': {'lat': '18.7876', 'name': 'Kalamwadi,Solapur,Maharashtra', 'lon': '75.9196'},
      '312404': {'lat': '25.6983', 'name': 'Binayaka,Chittorgarh,Rajasthan', 'lon': '75.2276'},
      '627012': {'lat': '8.7681', 'name': 'Manonmaniam Sundaranar Univers,Tirunelveli,Tamil Nadu', 'lon': '77.6267'},
      '608701': {'lat': '11.3628', 'name': 'Sholathiram,Cuddalore,Tamil Nadu', 'lon': '79.4837'},
      '403102': {'lat': '15.4761', 'name': 'Chorao,North Goa,Goa', 'lon': '73.9417'},
      '743233': {'lat': '23.0361', 'name': 'Jesur Jeoldanga,North 24 Parganas,West Bengal', 'lon': '88.7773'},
      '743232': {'lat': '23.2147', 'name': 'Boyra,North 24 Parganas,West Bengal', 'lon': '88.8863'},
      '743235': {'lat': '23.0762', 'name': 'Nakpul,North 24 Parganas,West Bengal', 'lon': '88.8272'},
      '743234': {'lat': '22.7774', 'name': 'Talsa,North 24 Parganas,West Bengal', 'lon': '88.5686'},
      '574279': {'lat': '12.7364', 'name': 'Kudupallathadka,Dakshina Kannada,Karnataka', 'lon': '75.0505'},
      '171209': {'lat': '31.0532', 'name': 'Fagu (Shimla),Shimla,Himachal Pradesh', 'lon': '77.3166'},
      '171208': {'lat': '31.3968', 'name': 'Pekha,Shimla,Himachal Pradesh', 'lon': '77.5136'},
      '212622': {'lat': '26.075', 'name': 'Dharmpur Saton,Fatehpur,Uttar Pradesh', 'lon': '80.7227'},
      '171203': {'lat': '31.0004', 'name': 'Ghasani,Shimla,Himachal Pradesh', 'lon': '77.3036'},
      '171202': {'lat': '31.1251', 'name': 'Gumma,Shimla,Himachal Pradesh', 'lon': '77.4872'},
      '171201': {'lat': '31.197', 'name': 'Chikar,Shimla,Himachal Pradesh', 'lon': '77.6324'},
      '388530': {'lat': '22.7919', 'name': 'Gajna,Anand,Gujarat', 'lon': '72.7993'},
      '171207': {'lat': '31.3599', 'name': 'Lower Koti,Shimla,Himachal Pradesh', 'lon': '77.4331'},
      '171206': {'lat': '31.1005', 'name': 'Solang,Shimla,Himachal Pradesh', 'lon': '77.7332'},
      '171205': {'lat': '30.9899', 'name': 'Shailapani,Shimla,Himachal Pradesh', 'lon': '77.475'},
      '171204': {'lat': '31.1424', 'name': 'Deori Khaneti,Shimla,Himachal Pradesh', 'lon': '77.5812'},
      '473670': {'lat': '25.2148', 'name': 'Ganeshkheda,Shivpuri,Madhya Pradesh', 'lon': '78.0933'},
      '312402': {'lat': '25.5144', 'name': 'Phalodra,Chittorgarh,Rajasthan', 'lon': '76.8822'},
      '583118': {'lat': '15.3205', 'name': 'D T Ship,Ballari,Karnataka', 'lon': '76.8155'},
      '583119': {'lat': '15.0427', 'name': 'Lakshmipura Sandur,Ballari,Karnataka', 'lon': '76.3979'},
      '583116': {'lat': '15.3237', 'name': 'Chanahal,Ballari,Karnataka', 'lon': '76.8622'},
      '583117': {'lat': '15.5754', 'name': 'Hadligi,Ballari,Karnataka', 'lon': '76.9204'},
      '583114': {'lat': '15.7365', 'name': 'Agasanur,Ballari,Karnataka', 'lon': '76.6914'},
      '583115': {'lat': '15.2696', 'name': 'Veniveerapura,Ballari,Karnataka', 'lon': '76.7872'},
      '583112': {'lat': '14.9715', 'name': 'Yaradammanahalli,Ballari,Karnataka', 'lon': '76.639'},
      '583113': {'lat': '14.1223', 'name': 'Kallukamba,Ballari,Karnataka', 'lon': '76.8517'},
      '583111': {'lat': '15.1871', 'name': 'P D Halli,Ballari,Karnataka', 'lon': '77.094'},
      '360440': {'lat': '21.7623', 'name': 'Zanzmer,Rajkot,Gujarat', 'lon': '70.3772'},
      '283119': {'lat': '26.5141', 'name': 'Cheet,Agra,Uttar Pradesh', 'lon': '78.2984'},
      '506101': {'lat': '17.3166', 'name': 'Mahabubabad H.O,Warangal,Telangana', 'lon': '79.3816'},
      '283113': {'lat': '26.882', 'name': 'Rajora,Agra,Uttar Pradesh', 'lon': '78.5069'},
      '283112': {'lat': '26.9334', 'name': 'Bikapur Tap Kharga,Agra,Uttar Pradesh', 'lon': '78.1598'},
      '384360': {'lat': '23.9513', 'name': 'Dharoi DSC,Mahesana,Gujarat', 'lon': '72.7992'},
      '283110': {'lat': '26.9099', 'name': 'Samara,Agra,Uttar Pradesh', 'lon': '77.8589'},
      '521356': {'lat': '16.35', 'name': 'Mamidikolla,Krishna,Andhra Pradesh', 'lon': '81.05'},
      '283115': {'lat': '26.9684', 'name': 'Singaich,Agra,Uttar Pradesh', 'lon': '77.6969'},
      '283114': {'lat': '26.9182', 'name': 'Shahpur Brahman,Agra,Uttar Pradesh', 'lon': '78.7974'},
      '272165': {'lat': '26.7902', 'name': 'Tanda,Sant Kabir Nagar,Uttar Pradesh', 'lon': '83.0348'},
      '574118': {'lat': '13.1862', 'name': 'Pithrodi,Udupi,Karnataka', 'lon': '74.9141'},
      '574119': {'lat': '13.1622', 'name': 'Paduyermal,Udupi,Karnataka', 'lon': '74.763'},
      '464573': {'lat': '23.3413', 'name': 'Kanth,Raisen,Madhya Pradesh', 'lon': '77.9806'},
      '574114': {'lat': '13.4667', 'name': 'Sanur,Udupi,Karnataka', 'lon': '74.8835'},
      '574115': {'lat': '13.1862', 'name': 'Herur-92,Udupi,Karnataka', 'lon': '74.9141'},
      '574116': {'lat': '13.22', 'name': 'Kuthyar,Udupi,Karnataka', 'lon': '74.8397'},
      '574117': {'lat': '13.6665', 'name': 'Uchila,Udupi,Karnataka', 'lon': '74.9983'},
      '574110': {'lat': '13.1516', 'name': 'Bola,Udupi,Karnataka', 'lon': '74.9087'},
      '574111': {'lat': '13.1395', 'name': 'Padebettu,Udupi,Karnataka', 'lon': '74.7891'},
      '574112': {'lat': '13.1862', 'name': 'Palimar,Udupi,Karnataka', 'lon': '74.9141'},
      '574113': {'lat': '13.1916', 'name': 'Pilar (Udupi),Udupi,Karnataka', 'lon': '74.8361'},
      '207001': {'lat': '27.485', 'name': 'Patiali Gate,Etah,Uttar Pradesh', 'lon': '78.8736'},
      '506122': {'lat': '17.5501', 'name': 'Deekshakunta,Warangal,Telangana', 'lon': '79.4209'},
      '752094': {'lat': '20.1734', 'name': 'Paradhip,Nayagarh,Odisha', 'lon': '84.9653'},
      '752093': {'lat': '20.2131', 'name': 'Nuapada,Nayagarh,Odisha', 'lon': '84.9994'},
      '752092': {'lat': '20.0594', 'name': 'Dimisar,Nayagarh,Odisha', 'lon': '85.1563'},
      '752091': {'lat': '20.2651', 'name': 'Khamarasahi,Nayagarh,Odisha', 'lon': '84.8534'},
      '752090': {'lat': '20.1885', 'name': 'Rohibanka,Nayagarh,Odisha', 'lon': '84.9574'},
      '686010': {'lat': '9.5634', 'name': 'Vadavathoor,Kottayam,Kerala', 'lon': '76.528'},
      '272161': {'lat': '27.0051', 'name': 'Belwaria Jungle,Basti,Uttar Pradesh', 'lon': '82.7865'},
      '788832': {'lat': '25.4351', 'name': 'Mandardisha,North Cachar Hills,Assam', 'lon': '93.1162'},
      '629175': {'lat': '8.3637', 'name': 'Mettukadai,Kanyakumari,Tamil Nadu', 'lon': '77.454'},
      '788830': {'lat': '25.1608', 'name': 'Khognam,North Cachar Hills,Assam', 'lon': '93.2078'},
      '788831': {'lat': '25.3012', 'name': 'Simtuilon,North Cachar Hills,Assam', 'lon': '93.1381'},
      '788781': {'lat': '24.7227', 'name': 'Latu,Karimganj,Assam', 'lon': '92.3761'},
      '600118': {'lat': '13.1281', 'name': 'Rv Nagar,Chennai,Tamil Nadu', 'lon': '80.2557'},
      '600119': {'lat': '12.89', 'name': 'Sholinganallur,Kanchipuram,Tamil Nadu', 'lon': '80.2313'},
      '600116': {'lat': '12.6952', 'name': 'Alapakkam,Kanchipuram,Tamil Nadu', 'lon': '79.9479'},
      '600117': {'lat': '12.9293', 'name': 'Keelakattalai,Kanchipuram,Tamil Nadu', 'lon': '80.034'},
      '600115': {'lat': '12.8959', 'name': 'Injambakkam,Kanchipuram,Tamil Nadu', 'lon': '79.693'},
      '600113': {'lat': '12.9293', 'name': 'TTTI Taramani,Chennai,Tamil Nadu', 'lon': '80.034'},
      '600110': {'lat': '13.13', 'name': 'Ponniammanmedu,Tiruvallur,Tamil Nadu', 'lon': '80.2311'},
      '144409': {'lat': '31.0936', 'name': 'Mathada Kalan,Jalandhar,Punjab', 'lon': '75.7782'},
      '144408': {'lat': '31.2326', 'name': 'Panchhat,Kapurthala,Punjab', 'lon': '75.7895'},
      '629176': {'lat': '8.3403', 'name': 'Poothurai,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '505331': {'lat': '18.5497', 'name': 'Siripuram,Karim Nagar,Telangana', 'lon': '79.2409'},
      '515631': {'lat': '14.4811', 'name': 'Atmakur,Ananthapur,Andhra Pradesh', 'lon': '77.5768'},
      '144401': {'lat': '31.2326', 'name': 'Phagwara H.O,Kapurthala,Punjab', 'lon': '75.7895'},
      '629171': {'lat': '8.3403', 'name': 'Vencode,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '144403': {'lat': '31.2599', 'name': 'Barn,Jalandhar,Punjab', 'lon': '75.7613'},
      '144402': {'lat': '31.1582', 'name': 'Manak,Jalandhar,Punjab', 'lon': '75.1707'},
      '144405': {'lat': '31.2126', 'name': 'Mayopatti,Jalandhar,Punjab', 'lon': '75.5666'},
      '144404': {'lat': '31.3743', 'name': 'Mehtiana,Hoshiarpur,Punjab', 'lon': '75.8197'},
      '144407': {'lat': '31.3313', 'name': 'Rawalpindi Gulab Shah,Jalandhar,Punjab', 'lon': '75.7963'},
      '532474': {'lat': '18.4787', 'name': 'Rana,Srikakulam,Andhra Pradesh', 'lon': '84.0775'},
      '580001': {'lat': '15.3478', 'name': 'Dharwad Market,Dharwad,Karnataka', 'lon': '75.1338'},
      '580003': {'lat': '15.4287', 'name': 'Dharwad K V V,Dharwad,Karnataka', 'lon': '75.0705'},
      '416517': {'lat': '17.237', 'name': 'Redi,Sindhudurg,Maharashtra', 'lon': '74.7197'},
      '580005': {'lat': '15.5091', 'name': 'Dharwad U A S,Dharwad,Karnataka', 'lon': '74.9763'},
      '580004': {'lat': '15.4287', 'name': 'Dharwad Vidyagiri,Dharwad,Karnataka', 'lon': '75.0705'},
      '580007': {'lat': '15.4384', 'name': 'Kyarakoppa,Dharwad,Karnataka', 'lon': '75.0917'},
      '580006': {'lat': '15.4287', 'name': 'Dharwad M J Nagar,Dharwad,Karnataka', 'lon': '75.0705'},
      '580009': {'lat': '15.407', 'name': 'Rayapur,Dharwad,Karnataka', 'lon': '75.069'},
      '580008': {'lat': '15.4287', 'name': 'Dharwad Narayanpur,Dharwad,Karnataka', 'lon': '75.0705'},
      '507183': {'lat': '17.3737', 'name': 'Hydersaipeta,Khammam,Telangana', 'lon': '79.9673'},
      '629172': {'lat': '8.3403', 'name': 'S.T.Mankad,Kanyakumari,Tamil Nadu', 'lon': '77.3452'},
      '516349': {'lat': '14.3811', 'name': 'Bhumayagaripalle,Cuddapah,Andhra Pradesh', 'lon': '78.3185'},
      '506252': {'lat': '17.7243', 'name': 'Kurchapalli,Warangal,Telangana', 'lon': '79.4112'},
      '533256': {'lat': '17.1885', 'name': 'Kothuru,East Godavari,Andhra Pradesh', 'lon': '82.0118'},
      '533254': {'lat': '17.3437', 'name': 'Visweswarayapuram,East Godavari,Andhra Pradesh', 'lon': '82.0769'},
      '533255': {'lat': '16.6744', 'name': 'Ramachandrapuram H.O,East Godavari,Andhra Pradesh', 'lon': '81.7498'},
      '533252': {'lat': '16.3746', 'name': 'Antarvedi Devastanam,East Godavari,Andhra Pradesh', 'lon': '81.7588'},
      '533253': {'lat': '16.4013', 'name': 'Gudimellanka,East Godavari,Andhra Pradesh', 'lon': '81.8052'},
      '533250': {'lat': '15.9985', 'name': 'Srungavarappadu,East Godavari,Andhra Pradesh', 'lon': '80.6911'},
      '533251': {'lat': '16.524', 'name': 'Gudimula,East Godavari,Andhra Pradesh', 'lon': '81.7338'},
      '628908': {'lat': '9.3266', 'name': 'Kilairal,Tuticorin,Tamil Nadu', 'lon': '78.1336'},
      '608704': {'lat': '11.476', 'name': 'B.Kolakudi,Cuddalore,Tamil Nadu', 'lon': '79.5623'},
      '788160': {'lat': '25.0658', 'name': 'Sudarshanpur,Hailakandi,Assam', 'lon': '92.0396'},
      '788161': {'lat': '25.0658', 'name': 'Dholai Malai,Hailakandi,Assam', 'lon': '92.0396'},
      '394620': {'lat': '20.7754', 'name': 'Afva Isroli,Surat,Gujarat', 'lon': '71.0465'},
      '788163': {'lat': '24.5604', 'name': 'Rajyeswarpur,Hailakandi,Assam', 'lon': '92.6163'},
      '788164': {'lat': '25.0658', 'name': 'Lakhinagar T E,Hailakandi,Assam', 'lon': '92.0396'},
      '788165': {'lat': '24.8104', 'name': 'Karicherra Bazar,Hailakandi,Assam', 'lon': '93.0808'},
      '788166': {'lat': '25.0658', 'name': 'Maniknagar,Hailakandi,Assam', 'lon': '92.0396'},
      '416518': {'lat': '19.9464', 'name': 'Shiroda (Sindhudurg),Sindhudurg,Maharashtra', 'lon': '77.2752'},
      '788168': {'lat': '25.0658', 'name': 'Rajyeswarpur,Hailakandi,Assam', 'lon': '92.0396'},
      '416516': {'lat': '16.4538', 'name': 'Wainganiwadi,Sindhudurg,Maharashtra', 'lon': '73.5128'},
      '416515': {'lat': '17.237', 'name': 'Tulas,Sindhudurg,Maharashtra', 'lon': '74.7197'},
      '416514': {'lat': '17.7126', 'name': 'Madura,Sindhudurg,Maharashtra', 'lon': '73.8095'},
      '416513': {'lat': '17.237', 'name': 'Aronda,Sindhudurg,Maharashtra', 'lon': '74.7197'},
      '416512': {'lat': '15.6827', 'name': 'Sateli Traf Bhedshi,Sindhudurg,Maharashtra', 'lon': '74.0377'},
      '416511': {'lat': '15.8118', 'name': 'Tambuli,Sindhudurg,Maharashtra', 'lon': '73.8612'},
      '416510': {'lat': '17.237', 'name': 'Sawantwadi H.O,Sindhudurg,Maharashtra', 'lon': '74.7197'},
      '209722': {'lat': '27.11', 'name': 'Dundwabuzurg,Kannauj,Uttar Pradesh', 'lon': '80.3217'},
      '263157': {'lat': '29.3014', 'name': 'Okhalkanda,Nainital,Uttarakhand', 'lon': '79.6305'},
      '403104': {'lat': '15.4426', 'name': 'Neura,North Goa,Goa', 'lon': '73.9174'},
      '522324': {'lat': '16.6213', 'name': 'Potharlanka,Guntur,Andhra Pradesh', 'lon': '81.024'},
      '522325': {'lat': '16.0341', 'name': 'Pedapudi,Guntur,Andhra Pradesh', 'lon': '80.6047'},
      '603003': {'lat': '12.6383', 'name': 'Nenmeli,Kanchipuram,Tamil Nadu', 'lon': '80.0221'},
      '603002': {'lat': '12.6334', 'name': 'Chengalpattu Bazaar,Kanchipuram,Tamil Nadu', 'lon': '79.9277'},
      '786188': {'lat': '26.221', 'name': 'Jagun,Tinsukia,Assam', 'lon': '91.499'},
      '786189': {'lat': '27.5112', 'name': 'Mulukgaon,Dibrugarh,Assam', 'lon': '95.1816'},
      '486114': {'lat': '24.7552', 'name': 'Padra,Rewa,Madhya Pradesh', 'lon': '81.5896'},
      '486115': {'lat': '24.7786', 'name': 'Kharra,Rewa,Madhya Pradesh', 'lon': '81.6812'},
      '786184': {'lat': '27.4825', 'name': 'Rang Changi,Dibrugarh,Assam', 'lon': '95.1745'},
      '385360': {'lat': '23.8876', 'name': 'Dholakda,Patan,Gujarat', 'lon': '71.7985'},
      '786187': {'lat': '26.9855', 'name': 'Agbandha Bengali Gaon,Tinsukia,Assam', 'lon': '93.7217'},
      '786181': {'lat': '26.9855', 'name': 'Alubari,Tinsukia,Assam', 'lon': '93.7217'},
      '786182': {'lat': '26.9855', 'name': 'Kumchai,Tinsukia,Assam', 'lon': '93.7217'},
      '786183': {'lat': '27.492', 'name': 'Gahoripam,Tinsukia,Assam', 'lon': '95.2541'},
      '680512': {'lat': '10.5701', 'name': 'Madu,Thrissur,Kerala', 'lon': '76.0291'},
      '680513': {'lat': '10.5085', 'name': 'Toyakkavu,Thrissur,Kerala', 'lon': '76.0794'},
      '680510': {'lat': '10.4712', 'name': 'Kannoth,Thrissur,Kerala', 'lon': '76.3162'},
      '680511': {'lat': '10.5732', 'name': 'Elavally,Thrissur,Kerala', 'lon': '76.0745'},
      '680516': {'lat': '10.5933', 'name': 'Tiruvatra,Thrissur,Kerala', 'lon': '76.0028'},
      '680517': {'lat': '10.6195', 'name': 'Chowannur,Thrissur,Kerala', 'lon': '76.075'},
      '680514': {'lat': '10.5475', 'name': 'Kadappuram,Thrissur,Kerala', 'lon': '76.023'},
      '680515': {'lat': '10.7337', 'name': 'Edakkaliyur,Thrissur,Kerala', 'lon': '76.0533'},
      '680518': {'lat': '10.6423', 'name': 'Mannalamkunnu,Thrissur,Kerala', 'lon': '75.9888'},
      '680519': {'lat': '10.6898', 'name': 'Akkikavu,Thrissur,Kerala', 'lon': '76.0904'},
      '734220': {'lat': '26.9051', 'name': "St.Mary's Hill,Darjiling,West Bengal", 'lon': '88.3002'},
      '603106': {'lat': '12.5256', 'name': 'Sathanjeri,Kanchipuram,Tamil Nadu', 'lon': '79.9764'}
    }
}
