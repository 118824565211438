import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ReportGeneratorService } from 'src/app/services/report-generator.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { NavbarComponent } from '../navbar/navbar.component';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-generator',
  templateUrl: './report-generator.component.html',
  styleUrls: ['./report-generator.component.css']
})
export class ReportGeneratorComponent implements OnInit, DoCheck {
  // @ViewChild('tableBUscorecard') tableBUscorecard;
  url = environment.baseURL_FREQ+'get_vmi_wh';
  requestbody = {};
  dropdownValue: "";
  dtOptions: DataTables.Settings = {};
  final = {};
  selected = 1;
  userId_val: any;
  tableDisplay: boolean;
  reportName = "";
  regions: any = [];
  public selectedRegions = [];
  public selectedL1 = [];
  dropdownSettings = {};
  TeamRegionsListObj: any = {};
  filterData: any = {};
  RkeyValue: any;
  isOpen = false;
  userInput = '';
  currentDate: any;
  currentMonth: any;
  dimUserTargetList = [];
  userTargetsArray = [];
  constructor(private navbarTitle: NavbarComponent, private reportGenerateServie: ReportGeneratorService, private commonService: CommonService,
    private userId: AppService,
    private Service: SelfServeService, private appService: AppService) {
    this.multiSelectDropdown();
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }
  ngOnInit() {
    const date = new Date();
    this.currentDate = date.getDate();
    let month = (date.getMonth() + 1).toString();
    const year  = date.getFullYear();
    if(month <= '3') {
      this.userTargetsArray = [`${year}-01`, `${year}-02`, `${year}-03`];
    } else if(month > '3' && month <= '6') {
      this.userTargetsArray = [`${year}-04`, `${year}-05`, `${year}-06`];
    } else if(month > '6' && month <= '9') {
      this.userTargetsArray = [`${year}-07`, `${year}-08`, `${year}-09`];
    } else if(month > '9' && month <= '12') {
      this.userTargetsArray = [`${year}-10`, `${year}-11`, `${year}-12`];
    }
    month = month.length == 1 ? '0' + month : month;
    this.currentMonth = month;
    this.userInput = `${year}-${month}`;
    this.getFiltersData();
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
  }

  updateTargets() {
    const url = 'https://mdm.moglix.com/insertMDMTeamTarget';
    this.reportGenerateServie.updateTargets(url, {mdmTeamTargetList: this.dimUserTargetList}).subscribe((resp) => {
      this.commonService.showToast(resp['message'], 1);
      this.dimUserTargetList = [];
    }, error => {
      this.commonService.showToast(error.error['message'], 3);
    })
  }

  getFiltersData() {
      this.regions = JSON.parse(localStorage.getItem('regions'));
      this.selectedRegions= JSON.parse(localStorage.getItem('regions'));
      this.getDataByFilter();
    }

  getDataByFilter() {
    this.dtOptions = {};
    this.final = {};
    this.dimUserTargetList = [];
    this.tableDisplay = false;
    this.requestbody = {
      'regionList': this.selectedRegions,
      'timeFrame': this.userInput,
      "kpiType":"Targets",
      "pageNumber": "1",
      "pageSize": "9000",
      'username': this.userId_val
    }
    this.reportGenerateServie.saveInfo('https://mdm.moglix.com/getMDMTeamTarget', this.requestbody).subscribe((data) => {
    if(data['result']!=undefined && data['status']==200){
      let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
      this.reportGenerateServie.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
      })
    }
      if (data['result'] != undefined && data['headers'] != undefined) {
        for (let l = 0; l < data['result'].length; l++) {
          for (let h = 0; h < data['headers'].length; h++) {

            if (data['headers'][h] == 'Name') {
              if (data['result'][l][data['headers'][h]].length > 40) {
                data['result'][l][data['headers'][h]] = '<span title="' + data['result'][l][data['headers'][h]] + '">' + data['result'][l][data['headers'][h]].substring(0, 20) + "..." + '</span>'
              }
            }
          }
        }

        this.final = this.reportGenerateServie.post(data)
        if (this.final['table']) {
          this.dtOptions = this.final['table'];
          if (this.dtOptions != null) {
            this.dtOptions.columns.forEach(each => {
              if(each['title'] == 'vertical') {
                each['vertical'] = 'Vertical'
              }
              if(each['title'] == 'region') {
                each['title'] = 'Region'
              }
              if(each['title'] == 'teamName') {
                each['title'] = 'Team Name'
              }
            })
            console.log(this.dtOptions.columns);
            this.tableDisplay = true;
          }
        }
      }
      else {
        this.commonService.showToast(data['message'], 2);
      }
    });
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedRegions = [];
  }

  change(row, column) {
    if (this.dimUserTargetList.length) {
      const i = this.dimUserTargetList.findIndex(each => (each.teamName == row['teamName'] && each['timeFrame'] == column['title']));
      if (i == -1) {
        this.dimUserTargetList.push(this.createObject(row, column));
      } else {
        this.dimUserTargetList[i] = this.createObject(row, column);
      }
    } else {
      this.dimUserTargetList.push(this.createObject(row, column));
    }
    console.log(this.dimUserTargetList);
  }

  createObject(row, column) {
    const yearMonth = column['title'];
    return {
      "region": row['region'] || null,
      "teamName": row['teamName'] || null,
      "timeFrame": yearMonth || null,
      "target": '' + row[yearMonth] || null,
      "teamId": row['teamId'] || null,
      "vertical": row['vertical'] || null,
      "ymType": "Month" || null,
      "kpiType":"Targets"
    }
  }



  generatePayload() {
    const payload = {
      "regions": this.selectedRegions,
  }
  }

  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }

  ngDoCheck() {
  }

}
