import { AppService } from './../../services/app.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { TeamService } from 'src/app/fur/team.service';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { environment } from 'src/environments/environment';

const moment = extendMoment(Moment);

@Component({
  selector: 'app-team-osod',
  templateUrl: './team-osod.component.html',
  styleUrls: ['./team-osod.component.css']
})
export class TeamOSODComponent implements OnInit {
    reportName="";

  
    selectedDate: any;
    datePickerenable: any;
    public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
    public datePickerConfigForSupply: any = {};
    public bsValue: any = new Date();
  
    activeverticalTab = '';
    filterapplied: boolean;
    urlpath: string;
    activeTab: any;
    furType: string;
    finalpostObj: { };
    regions: any;
    dropdownSettings = {};
    dtOptionsenable: boolean;
    dtOptions: DataTables.Settings = {};
    selectedRegions: any;
    dtOptionsenable2: boolean;
    plantclicked: boolean;
    cohortBoolean: boolean;
    smeclicked: boolean;
    supplyclicked: boolean;
    clicked1: boolean;
    cohortclicked: boolean;
    teamclicked: boolean;
    plantCondition = false;
    tableCondition = false;
    finalDataObject = { table: {}, excel: [] };
    userId_val :any;
    @Input('teamWise') teamWise = 0;
  
  constructor(private navbarTitle: NavbarComponent,private service: AppService,private teamService: TeamService,private userId: AppService ){
   
    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email;
  }
    ngOnInit() {
    this.teamWise = 1;
      this.selectedRegions = this.service.getUserDetails(['regions']);
      this.getDataByFilter();
      this.multiSelectDropdown();
      this.finalpostObj = this.service.userDetails.regions;
    }
  
    ngOnChanges(changes: SimpleChanges) {
      this.multiSelectDropdown();
      this.getDataByFilter();
      this.bsValue=new Date();
     this.teamSales(this.teamWise);
    }
  
  
  
    getDataByFilter(date = this.bsValue) {
      this.selectedDate = moment(date).format('YYYY-MM');
  
      console.log(this.selectedRegions);
      if (this.teamWise === 0)
        this.teamSales(this.teamWise);
      if (this.teamWise === 1)
        this.teamSales(this.teamWise);
      if (this.teamWise === 2)
        this.teamSales(this.teamWise);
  
  
    }
  
    // Items(activeTab) {
    //   this.activeverticalTab = activeTab;
    //   this.teamclicked = false;
    //   this.clicked1 = false;
    //   this.cohortclicked = false;
    //   this.supplyclicked = false;
    //   this.smeclicked = false;
    //   this.cohortBoolean = false;
    //   this.plantclicked = true;
    //   this.tableCondition = false;
    //   this.dtOptionsenable = false;
    //   this.dtOptionsenable2 = false;
  
  
    // }
  
    multiSelectDropdown() {
      this.regions = this.service.getUserDetails(['regions']);
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit: 2
      };
    }
    teamSales(paramteam: any) {
      paramteam=this.teamWise;
      switch (paramteam) {
        case 0: {
          this.urlpath = 'team_wise_sales';
          this.datePickerenable=true;
          break;
        }
        // case 1: {
        //   this.urlpath = '/team_wise_collection';
        //   this.datePickerenable=true;
        //   break;
        // }
        case 1: {
          this.urlpath = 'team_wise_osod';
          this.datePickerenable=false;
          break;
        }
      }
  
      this.filterapplied = true;
      //this.activeTab = activeTab;
      this.furType = "region";
      this.plantCondition = true;
      this.dtOptions = {};
      this.finalpostObj = {
        'region': this.regions,
        'date': this.selectedDate,
        'username':this.userId_val
  
  
      }
      this.displayTable(this.urlpath, this.finalpostObj, this.furType);
      //this.bsValue=new Date();
    }
    displayTable(urlpath, finalpostObj, furType) {
      this.furType = furType;
      this.tableCondition = false;
  
  
      this.teamService.saveInfo(urlpath, finalpostObj).subscribe(
        (response) => {
  
          if(response['data']['data1']!=undefined && response['status']=='SUCCESS'){
            let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
            this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
              console.log(reportdata);
            })
          }
          this.finalDataObject = this.teamService.post(response);
  
          if (this.finalDataObject) {
            this.dtOptions = this.finalDataObject.table;
            if (this.dtOptions != null) {
              this.tableCondition = true;
            }
          }
          console.log(this.dtOptions)
        }
      )
  
  
    }
  }
  
