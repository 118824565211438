import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit {
  isCatgeory: any;
  displayedTable: any;

  categoriesPrimaryData: any[] = [];
  categoriesRecommendedData: any[] = [];


  ngOnInit() {
  }
  selectedMode = 'online';
  selectedOption = 'all';
  searchText = '';
  searchResult: any;
  options = [];
  displayTable = false
  suggestionList: any[] = [];
  brandSuggestionList: any[] = [];
  categorySuggestionList: any[] = [];

  constructor(private http: HttpClient, private appservice: AppService) {
    this.updateOptions();
  }

  updateOptions() {


    this.options = (this.selectedMode === 'online')
      ? [
        { value: 'all', label: 'All' },
        { value: 'suggestions/auto-completion', label: 'Suggestions/Auto-Completion' },
        { value: 'words_corrected', label: 'Query Optimisation' },
        { value: 'categories_primary', label: 'Category Identification' },
        { value: 'brands', label: 'Brand Identification' },
        { value: 'attributes', label: 'UOM Identification' },
        { value: 'isCategoryBrand', label: 'IsCategoryBrand' }
      ]
      : [
        { value: 'all', label: 'All' },
        { value: 'words_corrected', label: 'Query Optimisation' },
        { value: 'categories_primary', label: 'Category Identification' },
        { value: 'brands', label: 'Brand Identification' },
        { value: 'attributes', label: 'UOM Identification' }
      ];

  }

  async submitSearch() {
    this.displayTable = false
    if (this.selectedMode == 'online' && this.selectedOption != 'suggestions/auto-completion') {
      const apiUrl = (this.selectedMode == 'online' && this.selectedOption != 'suggestions/auto-completion')
        ? 'https://searchml.moglix.com/spell'
        : 'https://searchml.moglix.com/spell/suggestions';

      const queryParams = `query=${encodeURIComponent(this.searchText)}`;

      this.getAPI_Data(apiUrl, queryParams)

    }
    if (this.selectedMode == 'online' && this.selectedOption == 'suggestions/auto-completion') {
      const apiUrl = 'https://searchml.moglix.com/spell/suggestions'

      const queryParams = `query=${encodeURIComponent(this.searchText)}`;
      this.getAPI_Data(apiUrl, queryParams)

    }
    if (this.selectedMode == 'enterprise') {
      const apiUrl = 'https://analysis.moglix.com/search_ml_enterprise/spell';

      const queryParams = `query=${encodeURIComponent(this.searchText)}`;
      this.appservice.getData(`${apiUrl}?${queryParams}`)
        .subscribe(async (response) => {

          this.displayTable = await this.displayResult(response)
        }, (error) => {
          console.error(error);
        });



    }

  }
  getAPI_Data(apiUrl, queryParams) {
    this.appservice.getData(`${apiUrl}?${queryParams}`)
      .subscribe(async (response) => {

        this.displayTable = await this.displayResult(response)
      }, (error) => {
        console.error(error);
      });
  }
  displayResult(response: any) {


    // Clear the previous result
    this.displayedTable = [];
    this.searchResult = []

    this.categoriesPrimaryData = [];
    this.categoriesRecommendedData = [];
    if (this.selectedOption == 'suggestions/auto-completion') {
      this.suggestionList = response.suggestionList;
      this.brandSuggestionList = response.brandSuggestionList;
      this.categorySuggestionList = response.categorySuggestionList;
      this.displayTable = true;
      return this.displayTable = true
    }
    else {

      // Iterate through the response and format the data
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const value = this.formatValue(key, response[key]);
          const rowData = { key, value };
          this.displayedTable.push(rowData);
          if (key === 'categories_primary') {
            this.categoriesPrimaryData.push(rowData);
          } else if (key === 'categories_recommended') {
            this.categoriesRecommendedData.push(rowData);
          }
        }
      }
      this.switchCaseTableShow();

      return this.displayTable = true
    }
  }

  // Helper method to format the value, handling arrays and objects properly
  private formatValue(key, value: any): string {
    if (Array.isArray(value)) {
      if (key == 'categories_recommended') {
        return value.map(item => this.formatValue(key, item.categoryName)).join(', ');
      }
      else if (key == 'brands') {
        return value.map(item => this.formatValue(key, item.brand_name)).join(', ');
      }
      else if (key == 'categories_primary') {
        return value.map(item => this.formatValue(key, item.categoryName)).join(', ');
      }

    } else if (typeof value === 'object') {
      if (key == 'isCategoryBrand') {
        this.isCatgeory = value
        console.log(value.identifier, value.name)
        return this.isCatgeory
      }
      else {
        return JSON.stringify(value);
      }
      // You can display the properties of the object as needed
    } else {
      return value.toString();
    }
  }
  switchCaseTableShow() {


    if (this.selectedOption != 'all' && this.selectedOption != 'suggestions/auto-completion') {
      if (this.selectedOption === 'categories_primary') {
        this.searchResult = [...this.categoriesPrimaryData, ...this.categoriesRecommendedData];
      } else {
        const result = this.displayedTable.filter((obj) => {
          if (obj.key === this.selectedOption) {
            return obj;
          }
        });
        this.searchResult = result;
      }
    }

    else {
      this.searchResult = this.displayedTable


    }
  }
}
