import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {


  
  finalDataObject = { table: {}, excel: [] };
  finalDataObject2 = { table: {}, excel: [] };
  responseList: any[];
  responseListExcel = [];
  
  dtOptionsenable = false;
  headers: any[];
  headers2: any[];
  finalresult: any[];
  finalresult2: any[];
  dtOptions: {};
  dtOptions2: {};

  responseList2: any[];
  responseList3: any[];
  responseList4: any[];

  responseListExcel2 = [];
  responseListExcel3 = [];
  responseListExcel4 = [];


  dtOptionsenable2 = false;
  dtOptionsenable3 = false;
  dtOptionsenable4 = false;

  constructor(private http: HttpClient) { }

 


  postCohort(headers_phy,headers_team,data_phy,data_team) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;

    this.headers2 = [];
    this.finalresult2 = [];
    this.dtOptions2 = {};
    this.responseList2 = [];
    this.responseListExcel2 = [];
    this.dtOptionsenable2 = false;


    this.responseList3 = [];
    this.responseListExcel3 = [];
    this.dtOptionsenable3 = false;

    this.responseList4 = [];
    this.responseListExcel4 = [];
    this.dtOptionsenable4 = false;

    this.headers = headers_phy
    this.finalresult = data_phy
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    this.headers2 = headers_team
    this.finalresult2 = data_team;
    this.finalDataObject2 = this.sortedResponseList2(this.finalresult2);
console.log(this.finalDataObject,this.finalDataObject2)
    return [this.finalDataObject, this.finalDataObject2];
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }



  sortedResponseList2(finalresult2) {

    for (var i in finalresult2) {
      let obj2 = finalresult2[i];
      let orderObject2 = {};
      let orderobjectwithoutcomma2 = {}
      for (var h2 in this.headers2) {
        for (const key2 of Object.keys(obj2)) {
          if (this.headers2[h2] == key2) {

            orderObject2[key2] = obj2[key2].toLocaleString();
            if (orderObject2[key2].replace(/\s/g, "") == "") {

              orderObject2[key2] = obj2[key2].toLocaleString();
            }
            else {
              if (isNaN(orderObject2[key2]))
                orderObject2[key2] = obj2[key2].toLocaleString();
              else {
                orderObject2[key2] = obj2[key2].toLocaleString() + "%";
              }
            }
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj2[key2]))
                 orderobjectwithoutcomma2[key2] = obj2[key2];
              else
                orderobjectwithoutcomma2[key2] = obj2[key2];
          }
        }
      }
      this.responseList2.push(orderObject2);
      this.responseListExcel2.push(orderobjectwithoutcomma2);

    }
    if (this.responseList2)
      this.dtOptions2 = this.tabledata2(this.responseList2);
    if (this.responseList2 && this.responseListExcel2)
      this.finalDataObject2 = { table: this.dtOptions2, excel: this.responseListExcel2 };
    return this.finalDataObject2;
  }

  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }
    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      "searching": false,
      // scrollY: '100vh',
      paging: false,
      //info: false,
      data: tableData,
      columns: headerData
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
  tabledata2(tableData2) {
    let headerData2 = [];
    for (let item2 of this.headers2) {
      headerData2.push({ title: item2, data: item2 });
    }

    this.dtOptions2 = {
      responsive: true,
      paging: false,
      "searching": false,
      //scrollY: '100vh',
      //info: false,
      data: tableData2,
      columns: headerData2,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      language: {


        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions2) {
      this.dtOptionsenable2 = true;
    }

    return this.dtOptions2;
  }





}
