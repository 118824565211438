import { Component, OnInit,SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { WHReportService } from 'src/app/services/wh-report.service';
import { debug } from 'util';
import { formatDate } from '@angular/common';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-wh-report',
  templateUrl: './wh-report.component.html',
  styleUrls: ['./wh-report.component.css']
})
export class WHReportComponent implements OnInit {
  reportName = "";
  url = environment.baseURL_FREQ+'get_vmi_wh';
  dateFormat = 'y-MM-dd';
  dateLocale = 'en-IN';
  excelData = []; 
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  start_date:any = new Date();
  requestbody = {};
  private toDate = new Date();
  private fromDate = new Date();
  private currentDate = new Date();
  dropdownValue: "";
  WarehouseValue = {};
  dtOptions: DataTables.Settings = {};
  final = {};
  selected = 1;
  userId_val: any;
  tableDisplay: boolean;
  days_to_consider =7;
   constructor(private navbarTitle: NavbarComponent, private whReportService: WHReportService,private commonService:CommonService,
    private userId: AppService,
    private Service: SelfServeService,) {
    
      this.userId.getData(this.url).subscribe((data) => {
        this.WarehouseValue = data['data'];
        let obWH = Object.keys(this.WarehouseValue);
        let l=obWH.length;
      for(let y =0;y<obWH.length;y++){
        if(y==(l-1)){
         
        this.selected = this.WarehouseValue[y];
        }
        
      }

      }); 
      this.userId_val = this.userId.userDetails.email;
    this.initialDate();    
    this.getDataByFilter();
  }
  async getNoDays(numberofDays) {
    this.days_to_consider = parseInt(numberofDays);
    this.requestbody['days_to_consider'] = this.days_to_consider;
  }
  ngOnInit() {
    this.initialDate();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.initialDate();
  }
  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let dateVal: string = [year, month, day].join('-');
    return dateVal;
  }
  initialDate() {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.currentDate = new Date();
    this.currentDate.setDate(this.fromDate.getDate()-1);
    this.fromDate.setDate(this.fromDate.getDate()-1);
    this.toDate.setDate(this.toDate.getDate() - 1);
    // this.start_date = formatDate(this.fromDate.setDate(this.fromDate.getDate()), this.dateFormat, this.dateLocale);
  }
  getDataByFilter() {
    this.initialDate();
    this.dtOptions = {};
    this.final = {};
    this.excelData =[];
    
    this.tableDisplay = false;
    this.requestbody = {

      'username': this.userId_val,
      "date": this.getDateFormat(this.start_date),
      "days_to_consider":this.days_to_consider,
      "input_warehouse_id": this.selected
    }
  

   
    this.whReportService.saveInfo(environment.baseURL_FREQ+'vmi_projection',this.requestbody).subscribe((data) => {
      
     if(data['data']['result']!=undefined && data['status']=='Success'){
      let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
      this.whReportService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
        console.log(reportdata);
      })
    }
      
    if(data["data"]['result']!=undefined && data["data"]['headers']!=undefined){
        for (let l = 0; l < data['data']['result'].length; l++) {
          for(let h=0;h<data['data']['headers'].length;h++){
            
                 if(data['data']['headers'][h]=='Name'){
                 if(data['data']['result'][l][data['data']['headers'][h]].length>40){
                   data['data']['result'][l][data['data']['headers'][h]] = '<span title="'+data['data']['result'][l][data['data']['headers'][h]]+'">'+data['data']['result'][l][data['data']['headers'][h]].substring(0,20)+"..."+'</span>'
                 }
               }
         }
       }
 
       this.final = this.whReportService.post(data)
       if (this.final['table']) {
         this.dtOptions = this.final['table'];
         this.excelData = this.final['excel'];
         
         if (this.dtOptions != null) {
           this.tableDisplay = true;
         }
       }
      }
      else{
        this.commonService.showToast(data['message'],2);
      }
    });
  }
  
  selectWarehouse(evt) {
    this.getNightlyType(evt.target.value);
  }

  getNightlyType(country: any) {
    for (let element in this.WarehouseValue) {
      console.log(this.WarehouseValue[element]);
      element === country
        ? this.selected = parseInt(country)
        : this.WarehouseValue[element];
    }
    console.log(this.WarehouseValue);
    console.log(this.selected);
  }


exportAsXLSX(): void {
  this.Service.exportAsExcelFile(this.excelData, `VMI-${this.WarehouseValue[this.requestbody['input_warehouse_id']]}-${this.requestbody['date']}_${this.requestbody['days_to_consider']} days-ago-sold`,true);
}
}   
