import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private spinner: NgxSpinnerService, private myRoute: Router,private appService:AppService) { }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.spinner.show();
      if (localStorage.getItem('authUser')) {
        this.spinner.hide();
        return true;
      } 
      
      else {
        //this.appService.postS3Link(state.url);
        
        this.myRoute.navigate(['login']);
        this.spinner.hide();
        return false;
      }
   }
}
