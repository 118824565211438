import { UrlConfig } from './../helpers/urlConfig';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DATEPICKER_CONTROL_VALUE_ACCESSOR } from 'ngx-bootstrap/datepicker/datepicker.component';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
  //BASEURL: string = 'https://analytics-web.moglilabs.com/ssde';
  const baseURL_FREQ: string = environment.baseURL_FREQ;
@Injectable({
  providedIn: 'root'
})
export class SelfServeService {


  constructor(private http:HttpClient) { }
  get(url) {
    return this.http.get(url);
  }

  getinputs() {
    return this.http.get(baseURL_FREQ+UrlConfig.pivottablefiltersinput);
  }

  post(resource) {
    return this.http.post(baseURL_FREQ, resource);
  }

  put(url, resource) {
    return this.http.put(url, resource);
  }

  patch(url, resource) {
    return this.http.patch(url, resource);
  }

  delete(url, id, headersJson?: any) {
    return this.http.delete(url + id, headersJson);
  }


  getInfo(){
    
    let userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
    return this.http.post(baseURL_FREQ+UrlConfig.pivottablefiltersinput,{"username":userDetails["email"]});
  }
  
  saveInfo(data){
    return this.http.post(baseURL_FREQ+UrlConfig.datatable,data)
  }
  public exportAsExcelFile(json: any[], excelFileName: string, isFullName = false): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName, isFullName);
  }

  private saveAsExcelFile(buffer: any, fileName: string, isFullName = false): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    
    let dateval = new Date();
    let DD=dateval.getUTCDate();
    let MM=dateval.getMonth();
    let YY=dateval.getFullYear();
    
    if(!isFullName) {
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    } else {
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  }
  
  
  private saveAsExcelFile2(buffer: any, fileName: string): void {
    const data2: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    
    let dateval = new Date();
    let DD=dateval.getUTCDate();
    let MM=dateval.getMonth();
    let YY=dateval.getFullYear();
    
    FileSaver.saveAs(data2, fileName +  EXCEL_EXTENSION)
    
  }
  public exportAsExcelFile3(json: any[], json2:any[], json3: any[], excelFileName: string): void {
    const testsheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const testsheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const testsheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json3);
    const workbook2: XLSX.WorkBook = { Sheets: {'Quoted Value':testsheet1,'Tat Response':testsheet2, 'Win rate %': testsheet3}, SheetNames: ['Quoted Value','Tat Response', 'Win rate %'] };
    const excelBuffer2: any = XLSX.write(workbook2, { bookType: 'xlsx', type:'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile2(excelBuffer2, excelFileName);
  }
  public exportAsExcelFile2(json: any[], json2:any[],excelFileName: string): void {
    console.log(json,json2)
    const testsheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const testsheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    console.log(json,json2)
    console.log('worksheet',testsheet1,'worksheet 2',testsheet2)
    
    const workbook2: XLSX.WorkBook = { Sheets: {'SPO':testsheet1,'Customer':testsheet2}, SheetNames: ['SPO','Customer'] };
    const excelBuffer2: any = XLSX.write(workbook2, { bookType: 'xlsx', type:'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile2(excelBuffer2, excelFileName);
  }
    private saveAsExcelInventory(buffer: any, fileName: string): void {
    const inventorydata2: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    let dateval = new Date();
    let DD = dateval.getUTCDate();
    let MM = dateval.getMonth();
    let YY = dateval.getFullYear();

    FileSaver.saveAs(inventorydata2, fileName +  EXCEL_EXTENSION)

  }
  public exportAsExcelFileInventory(json: any[], json2: any[], excelFileName: string): void {
    console.log(json, json2)
    let inventorytestsheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    let inventorytestsheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    console.log(json, json2)
    console.log('worksheet', inventorytestsheet1, 'worksheet 2', inventorytestsheet2)
    let inventoryworkbook2: XLSX.WorkBook;
    let inventoryexcelBuffer2: any;
    
      inventoryworkbook2 = { Sheets: { 'PHY_Warehouse': inventorytestsheet1, 'custRegions': inventorytestsheet2 }, SheetNames: ['PHY_Warehouse', 'custRegions'] };
      inventoryexcelBuffer2 = XLSX.write(inventoryworkbook2, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelInventory(inventoryexcelBuffer2, excelFileName);
     
  }
}
