import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SMEFinanceService } from 'src/app/services/sme-finance.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sme-finance',
  templateUrl: './sme-finance.component.html',
  styleUrls: ['./sme-finance.component.css']
})
export class SMEFinanceComponent implements OnInit {
  userId_val: any;
data:any;
smeFinanceObj: { table: {}; excel: any[]; };
  tableDisplay: boolean;
  dtOptions: DataTables.Settings = {};
  excelData2 = []; 
  constructor(private smeFinanceService:SMEFinanceService,private userId: AppService,private Service: SelfServeService) {
    this.userId_val = this.userId.userDetails.email;
    this.smeFinanceService.getData(environment.baseURL_FREQ+'sme_finance').subscribe(data => {
        
        console.log(data);  
        this.smeFinanceObj = this.smeFinanceService.post(data);
        this.tableDisplay = false;
      
          if (this.smeFinanceObj['table']) {
            this.dtOptions = this.smeFinanceObj['table'];
            
            this.excelData2 = this.smeFinanceObj['excel'];
            this.tableDisplay=true;
          }
       
    });
  
   }

  ngOnInit() {
  }
  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.excelData2, 'SME-Finance');
  }
}
