import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  headers2: any[];
  searchVal = 1;
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  headerData_PendPODCustom: any;

  constructor(private http: HttpClient) { }

  getData(url) {
    return this.http.get(url);

  }

  postData(url, body) {
    return this.http.post(url, body);

  }

  post(finalpostObj, tab) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers']
    this.finalresult = finalpostObj['data']['data'];
    this.finalDataObject = this.sortedResponseList(this.finalresult, tab);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postDetail(finalpostObj, tab) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers']
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult, tab);
    if (this.finalDataObject)
      return this.finalDataObject
  }



  sortedResponseList(finalresult, tab) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
//             orderobjectwithoutcomma[key] = obj[key]
            let regExp = /^\d*(\.)?(\d{0,10})?$/
            let bigIntExp = /^([\d]+)(?:\.([\d]{1,2}?))?$/
            if (regExp.test(obj[key])) {

              if(key == 'Plant ID' || key == 'Plant_ID' || key == 'Company_ID' || key == 'Team_ID' || key == 'SP' || key == 'Qty' || key == 'Tax' || key == 'GMV' || key == 'Packed Qty' || key == 'Quantity' 
                 || key == 'Final SP' || key == 'Total Value' || key == 'Customer_Order_Number' || key == 'Invoice_No'
                  || key == 'Return_Sales' || key == 'Return_Tax')
                orderobjectwithoutcomma[key] = Number(obj[key]);
//                 orderobjectwithoutcomma[key] = obj[key]

              else if(key == 'Item_ID' || key == 'Item_Ref' || key == 'Moglix_Order_ID')
                orderobjectwithoutcomma[key] = (obj[key])

            }
            else if (key == 'Item_ID' || key == 'Item_Ref' || key == 'Moglix_Order_ID')

              orderobjectwithoutcomma[key] =obj[key].toLocaleString();
            else
              orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList, tab);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }


  tabledata(tableData, tab) {
    let headerData = [];
    for (let item of this.headers) {
      headerData.push({
        title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {



          if (item.includes('Q')) {
            $(td).css({ 'background-color': '#FFF2CC', 'font-weight': 600 })
          }

          if (col == 0) {
            $(td).css({ 'position': 'fixed!important', 'width': '593px!important' })
          }
        }

      });
    }
    if (tab == 'rfq') {
      this.dtOptions = {
        responsive: true,

        scrollX: true,
        scrollCollapse: true,
        // fixedColumns: {
        //   leftColumns: 1
        // },
        scrollY: '40vh',
        paging: false,
        data: tableData,
        columns: headerData,
        searching: true,
        info: false,
        dom: '<"top"i><"bottom"flp>rt<"clear">',

        columnDefs: [
          {

            targets: '_all',

            render: function (data, type, row, meta, full) {
              let textValue = (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>';
              let title = meta.col;
              //let totalRow = (row["Sourcer"] == "Total") ? true : false;




              if (title != 0 && title != 1 && title != 4 && title != 9 && title != 11 && title != 12 && title != 16 && title != 21 && title != 23 && title != 24 && title != 26) {

                if (data == 0 && data == "0") {
                  return data
                }
                else {
                  textValue = '<span><p id="cellvalue" style="cursor:pointer;"><u><b>' + data + '</b></u></p></span>'
                  return textValue;
                }

              }

              else {
                if (title == 0 || title == 1) {


                  textValue = (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>';
                  return textValue

                }

                else {
                  if (data.includes('%')) {

                    textValue = '<span id="cellvalue" style="pointer-events:none;">' + data + '</span>'

                    return textValue

                  }
                  else {
                    return data
                  }
                }
              }




            }

          }

          //{  className: "stickyColumn", targets: 0 }
        ],

        // fixedColumns:   true,
        language: {
          info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
          "lengthMenu": "",
          "infoEmpty": "",
          "infoFiltered": ""
        },
        orderFixed: [0, 1, 'desc']

      };
      this.dtOptions["headerCallback"] = function (thead, data, start, end, display) {
        const timer = sessionStorage.getItem('timer') || '0';
        if (timer == '0') {
          sessionStorage.setItem('timer', '1');
          if (tab == 'rfq') {
            $(thead).before(`
            <tr class="throw0">
            <th colspan="1" style="position: sticky;
            left: 0;
            min-width: 154px;" ></th>
            <th colspan="1" style="position: sticky;
            left: 190px;"></th>
            
            <th colspan="12">MTD</th>
            <th colspan="11">Last Month</th>
            <th colspan="2">Last 6 Months</th>
            <th colspan="1"></th>
            </tr>`)
          }
        }
      }
      if (this.dtOptions) {
        this.dtOptionsenable = true;
      }
      return this.dtOptions;
    }
    if (tab == 'order') {
      this.dtOptions = {
        responsive: true,
        orderFixed: [0, 'asc'],
        scrollX: true,
        scrollCollapse: true,
        fixedColumns: {
          leftColumns: 1
        },
        scrollY: '40vh',
        paging: false,
        data: tableData,
        columns: headerData,
        searching: true,
        info: false,
        dom: '<"top"i><"bottom"flp>rt<"clear">',
        columnDefs: [
          {

            targets: '_all',

            render: function (data, type, row, meta, full, col) {
              let textValue = (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>';
              let title = meta.col;
              debugger
              if (title == 0) {
                textValue = '<b id="cellvalue" style="color:black;">' + textValue + '</b>'
                return textValue;
              }


              if (title != 0) {
                if (data == 0 || data == "0.0%") {
                  return data;
                }
                else {
                  if (title == 1 || title == 3 || title == 4 || title == 5 || title == 6 || title == 8 || title == 9 || title == 10) {
                    textValue = '<b><p id="cellvalue" style="color:black;cursor:pointer;"><u>' + textValue + '</u></p></b>'
                    return textValue;
                  }

                  else {
                    textValue = '<p id="cellvalue" style="color:black;cursor:pointer;">' + textValue + '</p>'
                    return textValue;
                  }
                }
              }
              if (data == 0) {
                return data;
              }
            }

          }

          //{  className: "stickyColumn", targets: 0 }
        ],

        // fixedColumns:   true,
        language: {
          info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
          "lengthMenu": "",
          "infoEmpty": "",
          "infoFiltered": ""
        },

      };

      this.dtOptions["headerCallback"] = function (thead, data, start, end, display) {
        const timer = sessionStorage.getItem('timer') || '0';
        if (timer == '0') {
          sessionStorage.setItem('timer', '1');
          if (tab == 'rfq') {
            $(thead).before(`
      
            <tr class="throw0">
            <th colspan="2" ></th>
            <th colspan="6">MTD</th>
            <th colspan="2">CPO-SPO Pending > 48 hrs</th>
            <th colspan="5">MTD </th>
            </tr>`)
          }
        }
      }
      if (this.dtOptions) {
        this.dtOptionsenable = true;
      }
      return this.dtOptions;

    }
    if (tab == 'business') {
      this.dtOptions = {
        responsive: true,

        scrollX: true,
        scrollCollapse: true,

        scrollY: '40vh',
        paging: false,
        data: tableData,
        columns: headerData,
        searching: true,
        fixedColumns: {
          leftColumns: 1
        },
        info: false,
        dom: '<"top"i><"bottom"flp>rt<"clear">',
        columnDefs: [
          {
            targets: '_all',
            render: function (data, type, row, meta, full) {
              let textValue = (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>';
              let title = meta.col;
              //let totalRow = (row["Sourcer"] == "Total") ? true : false;



              if (title == 2 || title == 5 || title == 6 ||
                title == 9 || title == 10 || title == 11 || title == 13 ||
                title == 14 || title == 16) {
                if (data != "0") {


                  textValue = '<p id="cellvalue" style="color:black;cursor:pointer;"><u><b>' + textValue + '</b></u></p>'
                  return textValue;
                }

                if (data == "0") {
                  return data;
                }
              }
              else {
                return data
              }
            }

          }

          //{  className: "stickyColumn", targets: 0 }
        ],

        // fixedColumns:   true,
        language: {
          info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
          "lengthMenu": "",
          "infoEmpty": "",
          "infoFiltered": ""
        },

      };

      this.dtOptions["headerCallback"] = function (thead, data, start, end, display) {
        const timer = sessionStorage.getItem('timer') || '0';
        if (timer == '0') {
          sessionStorage.setItem('timer', '1');
          if (tab == 'rfq') {
            $(thead).before(`
      
            <tr class="throw0">
            <th colspan="2" ></th>
            <th colspan="6"></th>
            <th colspan="2">CPO-SPO Pending > 48 hrs</th>
            <th colspan="5">MTD</th>
            </tr>`)
          }
        }
      }
      if (this.dtOptions) {
        this.dtOptionsenable = true;
      }
      return this.dtOptions;

    }

    if (tab == 'category') {
      this.dtOptions = {
        responsive: true,

        scrollX: true,
        scrollCollapse: true,

        scrollY: '42vh',
        paging: false,
        data: tableData,
        columns: headerData,
        searching: true,
        info: false,
        dom: '<"top"i><"bottom"flp>rt<"clear">',
        columnDefs: [
          {

            targets: '_all',

            render: function (data, type, row, meta, full) {
              let textValue = (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>';
              let title = meta.col;
              let totalRow = (row["Sourcer"] == "Total") ? true : false;

              if (title == 0 || title == 1) {
                if (!totalRow) {
                  textValue = '<p id="cellvalue" style="color:black;pointer-events: none;">' + (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>' + '</p>';
                  return textValue;
                }
                if (totalRow) {
                  textValue = '<i><p id="cellvalue" style="color:black;pointer-events: none;">' + (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>' + '</p></i>';
                  return textValue;
                }
              }
              if (title == 0 || title == 1) {
                if (!totalRow && data == "0") {
                  textValue = '<p id="cellvalue" style="color:black;pointer-events: none;">' + (data.length > 25) ? '<a  title="' + data + '" >' + data.substring(0, 25) + '...' + '</a>' : '<span class>' + data + '</span>' + '</p>';
                  return textValue;
                }
              }
              if (title != 0 || title != 1) {
                if (data != "0" && !totalRow) {
                  textValue = '<b id="cellvalue" style="color:black;pointer-events: none;">' + textValue + '</b>'
                  return textValue;
                }

              }
              if (title != 0 || title != 1) {
                if (data != "0" && totalRow) {
                  textValue = '<i><b id="cellvalue" style="color:black;pointer-events: none;"><u>' + textValue + '</u></b></i>'
                  return textValue;
                }

              }
              if (data == 0) {
                return data;
              }
            }

          }

          //{  className: "stickyColumn", targets: 0 }
        ],

        // fixedColumns:   true,
        language: {
          info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
          "lengthMenu": "",
          "infoEmpty": "",
          "infoFiltered": ""
        }
      };

      this.dtOptions["headerCallback"] = function (thead, data, start, end, display) {
        const timer = sessionStorage.getItem('timer') || '0';
        if (timer == '0') {
          sessionStorage.setItem('timer', '1');
          if (tab == 'rfq') {
            $(thead).before(`
            <tr class="throw0">
            <th colspan="2" ></th>
            <th colspan="2">MTD</th>
            <th colspan="2">Last Month</th>
            <th colspan="2">Last Quarter</th>
            </tr>`)
          }
        }
      }
      if (this.dtOptions) {
        this.dtOptionsenable = true;
      }
      return this.dtOptions;

    }


  }






  getTableData(userName) {
    const url = environment.baseURL_FREQ + 'freq_report/kam_rfq_tracking';
    return this.http.post(url, { username: userName });
  }
}
