import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toastrService: ToastrService) { }



  showToast(message, status) {
    if (status === 1) {
      this.toastrService.success(message);
    } if (status === 2) {
      this.toastrService.info(message);
    } else {
      this.toastrService.error(message);
    }
  }

}
