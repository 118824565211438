import { Options } from 'highcharts';
import { Injectable, OnChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';

const fur_baseURL: string = environment.baseURL_FREQ;

@Injectable({
  providedIn: 'root'
})
export class OrderTrackingService {


  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  //BASEURL: string = 'https://analytics-web.moglilabs.com/freq_report';

  constructor(private http: HttpClient) { }
  getInfo(urlpath) {
    return this.http.get(fur_baseURL + urlpath)
  }
  saveInfo(urlpath, data) {

    return this.http.post(fur_baseURL + urlpath, data)
  }

  post(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.timestampval = finalpostObj['dateval'];
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['data'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    return this.finalDataObject ? this.finalDataObject : null;
  }

  get(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.timestampval = finalpostObj['dateval'];
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['data1'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    return this.finalDataObject ? this.finalDataObject : null;
  }


  tabledata(tableData) {
    console.log('working');
    let headerData = [];
    for (let item of this.headers) {
      headerData.push({
        title: item, data: item,
      });
    }

    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      scrollY: '100vh',
      pageLength: 10,
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      columnDefs: [
        {
          width: 300,
          targets: '_all',
          render: function (data, type, row) {
          let textValue = (data.length > 30)?'<a  title="' + data + '" >'+data.substring(0, 30)+'...'+'</a>':'<span class>'+data+'</span>';
            
       
            return textValue;
          }
        }
        ],
      
      language: {
        "lengthMenu": "",
        "infoEmpty": "",
        "infoFiltered": ""
      },
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }

  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key]
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject).toLocaleString;
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };

    return this.finalDataObject ? this.finalDataObject : null;
  }








}