//import { UserService } from './../../services/user.service';
//import { FinalTableInterface } from './../../landing-page/landing-page/landing-page.component';
import { Component, ViewChild, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormBuilder, FormArray, ValidatorFn, RequiredValidator } from '@angular/forms';
//import { LandingService } from 'src/app/landing-page/landing.service';
import * as XLSX from 'xlsx'

import { saveAs } from 'file-saver';
//import { MatSort } from '@angular/material/sort';
//import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AUTO_STYLE } from '@angular/animations';
import { template } from '@angular/core/src/render3';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { AppService } from 'src/app/services/app.service';
export interface FinalTableInterface {
  [key: string]: any;
}
@Component({
  selector: 'app-self-serve',
  templateUrl: './self-serve.component.html',
  styleUrls: ['./self-serve.component.css']
})
export class SelfServeComponent implements OnInit {

  displaytrue = false;
  isTableProcess = false;
  panelOpenState: boolean = false;
  allExpandState = true;
  form: FormGroup;
  disable = true;
  selectedColumnValue: any;
  selectedAggValue: any;
  rows = [];
  columns = [];
  filters = [];
  aggregators = [];
  section1 = [];
  section2 = [];
  section3 = [];
  section4 = [];
  mainheading = 'Self Serve';
  display = false;
  finalpostObj = {};
  count = 0;
  dtOptionsenable = false;
  headers: any;
  checks = false;
  finalresult: any;
  dataSource: any;
  numChecked: number = 0;
  aggnumChecked: number = 0;
  responseList = [];
  timestampval = "";
  clusters = [
    "Enterprise Sales",
    "Enterprise Purchase",
    "Enterprise Supply Chain",
    "Online Sales",
    "Online Purchase",
    "Online Supply Chain"
  ]
  responseListExcel = [];
  datalist = [
    { name: "Rows*", isActive: false },
    { name: "Columns*", isActive: false },

    { name: "Values", isActive: false }
  ];
  sec4: any
  sec2: any
  sbmit = true;
  dtOptions: DataTables.Settings = {};
k:any;
  headerData = [];

  ngOnInit(): void {

  this.k=0;
      this.mainheading = this.clusters[0];
      this.display = true;
  }

  //@ViewChild(MatSort) sort: MatSort;

  constructor
    (private formBuilder: FormBuilder,
      private Service: SelfServeService,
      private http: HttpClient,
      private router: Router,
      private userId: AppService
    ) {
    this.formvalues();
  }
  formvalues() {
    this.form = this.formBuilder.group({
      rows: new FormArray([], this.minSelectedCheckboxes(1)),
      columns: new FormArray([]),
      filters: new FormArray([]),
      aggregators: new FormArray([])
    });
    this.rows = this.getRows();
    this.columns = this.getColumns();
    this.filters = this.getFilters();
    this.aggregators = this.getAggrigetors();

    this.displaybutton();
  }
  toggleevent(classList, i) {
    var element = classList.target;
    element.classList.toggle("active");
    console.log(this.datalist[i]);
    if (this.datalist[i].isActive) {
      this.datalist[i].isActive = false;
    } else {
      this.datalist[i].isActive = true;
    }
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  getRows() {
    this.Service.getInfo().subscribe(
      (response) => {
        console.log(response);
        this.rows = response['data'].section1;
        this.rows.map((r, i) => {
          const rowControl = new FormControl(i === -1);
          (this.form.controls.rows as FormArray).push(rowControl);
        });
      }
    )
    console.log(this.rows);
    return this.rows;
  }
  getColumns() {
    this.Service.getInfo().subscribe(
      (response) => {
        this.columns = response['data'].section2;
        this.columns.map((c, j) => {
          const columnControl = new FormControl(j === -1);
          (this.form.controls.columns as FormArray).push(columnControl);

        });
      }
    )
    return this.columns;
  }
  getFilters() {
    this.Service.getInfo().subscribe(
      (response) => {
        this.filters = response['data'].section3;
        this.filters.map((f, k) => {
          const filterControl = new FormControl(k === -1);
          (this.form.controls.filters as FormArray).push(filterControl);
        });
      }
    )
    return this.filters;
  }
  getAggrigetors() {
    this.Service.getInfo().subscribe(
      (response) => {
        this.aggregators = response['data'].section4;
        this.aggregators.map((a, l) => {
          const aggControl = new FormControl(l === -1);
          (this.form.controls.aggregators as FormArray).push(aggControl);
        });
      }
    )
    return this.aggregators;
  }
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(rowControl => rowControl.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      this.sbmit = true;
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
    console.log(this.rows);
    return validator;
  }
  // radio button fuctionality for the columns
  columnSelected(columnselected) {
    const colFormArray = this.form.get('columns') as FormArray;
    let colf = 0;
    colFormArray.controls.forEach((colitemfalse, colf) => {
      this.columns[colf].checked = "false";
      colf++;
    })
    this.section2 = [];
    let col = 0;


    colFormArray.controls.forEach((colitem, col) => {
      if (columnselected == col) {
        this.sec2 = "";
        this.sec2 = this.columns[col].name;

        this.columns[col].checked = "true";
        this.section2.push(this.sec2);
        if (this.count != 0)
          this.sbmit = true;
      }
      else {

        this.columns[col].checked = "false";
      }
      col++;
    })
    console.log(this.columns);
    const colFormArray1 = this.form.get('columns') as FormArray;
  }


  // radio button fuctionality for the valu-aggregators
  aggregatorsCheckbox(aggselected) {
    const aggFormArray = this.form.get('aggregators') as FormArray;
    let aggf = 0;
    aggFormArray.controls.forEach((aggitemf, aggf) => {
      this.aggregators[aggf].checked = "false";
      aggf++;
    })
    let agg = 0;
    let ischecked;
    this.section4 = [];
    aggFormArray.controls.forEach((aggitem, agg) => {
      if (aggselected == agg) {
        this.sec4 = "";
        this.sec4 = this.aggregators[agg].name;
        this.aggregators[agg].checked = "true";
        this.section4.push(this.sec4);
        this.checks = true;
        if (this.count != 0)
          this.sbmit = true;


      }
      else {
        this.aggregators[agg].checked = "false";
      }
      agg++;
    })
    const colFormArray1 = this.form.get('aggregators') as FormArray;
  }

  //Submission funtion 
  submit() {
    if (this.count != 0) {


      this.dtOptions = {};
      this.dtOptionsenable = false;
      this.headers = [];
      this.headerData = [];
      this.finalpostObj = {};
      this.section1 = [];
      this.section2 = [];
      this.section3 = [];
      this.section4 = [];
      this.finalresult = {};
      this.responseList = [];
      this.timestampval = "";
      this.responseListExcel = [];

      for (let i = 0; i <= this.form.value.rows.length; i++) {
        if (this.form.value.rows[i] == true) {
          this.section1.push(this.rows[i].name);
          this.rows[i].checked = true
        }
      }
      if (this.sec2)
        this.section2.push(this.sec2);
      if (this.sec4)
        this.section4.push(this.sec4);
    }
    this.sbmit = false;
    this.isTableProcess = true;
    console.log(this.form.value);
    this.form.value.aggregators;
    this.form.value.rows;
    this.form.value.columns;
    this.form.value.filters;

    //  console.log(this.users.userDetails.email);

    console.log(this.form.value.rows);
    if (this.count == 0) {
      for (let i = 0; i <= this.form.value.rows.length; i++) {
        if (this.form.value.rows[i] == true)
          this.section1.push(this.rows[i].name);

      }
    }

    this.finalpostObj = {
      'username': this.userId.userDetails.email,
      'section1': this.section1,
      'section2': this.section2,
      'section3': this.section3,
      'section4': this.section4
    }
    this.postInfo();
    this.count++;

  }
  displaybutton() {
    this.displaytrue = true;
  }
  // input object to request the data   
  postInfo() {
    this.Service.saveInfo(this.finalpostObj).subscribe(
      (response) => {
        this.isTableProcess = false;
        this.headers = response['data']['headers'];
        this.finalresult = response['data']['data1'];
        const Table_DATA: FinalTableInterface[] = this.finalresult;
        console.log(this.finalresult)
        // this.dataSource = new MatTableDataSource(Table_DATA);
        //this.dataSource.sort = this.sort;
        //this.timestampval = response['data']['timeval'];
        // this.timestampval = "DD/MM/YYYY will be adding dynamic  value";  
        this.responseList = this.sortedResponseList();
        if (this.responseList) {
          this.tabledata(this.responseList);
        }

      }
    )
  }
  @ViewChild('example')
  example: ElementRef;

  tabledata(tableData) {

    for (let item of this.headers) {
      this.headerData.push({ title: item, data: item });
    }

    this.dtOptions = {
      responsive: true,
      autoWidth:false,
      scrollX: true,
      scrollCollapse:true,
      scrollY: '100vh',
      lengthMenu: [[100], [100,"All"]],
      lengthChange: false,
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      data: this.responseList,
      columns: this.headerData,
      columnDefs: [
        {
          // width: 300,
          targets: '_all',
          render: function (data, type, row) {
          let textValue = (data.length > 30)?'<a  title="' + data + '" >'+data.substring(0, 30)+'...'+'</a>':'<span class>'+data+'</span>';
            
       
            return textValue;
          }
        }
        ]
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
  
    // this.example.nativeElement.DataTables({
    //   fixedHeader:true
    // })
  }
  // Export Table format object
  sortedResponseList() {

    for (var i in this.finalresult) {
      var obj = this.finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {};
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);
    }
    return this.responseList;
  }
  //Sort
  // @ViewChild(MatSort, { static: false }) sort: MatSort;

  //Filter
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  //export the result
  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.responseListExcel, 'Self-Serve');
  }
  //reset
  reset() {
    this.form.reset();
    this.section1 = [];
    this.section2 = [];
    this.section4 = [];
    this.count = 0;
    this.finalpostObj = {};
    this.finalresult = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.timestampval = "";
    this.checks = false;
    this.displaytrue = false;
    this.dtOptions = {};
    this.dtOptionsenable = false;
    this.headers = [];
    this.headerData = [];

    console.log(this.responseList);
    console.log("reset---------------------------------------");
    console.log("reset" + this.form.value);
    this.formvalues();
  }
  // button(k) {
  //   if (k == 0) {
  //     this.mainheading = this.clusters[k];
  //     this.display = true;
  //   }
  // }
  back() {
    this.mainheading = 'Self Serve';
    this.display = false;
    this.reset();
  }
}