import { Component, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from 'src/app/services/app.service';
import { RfqService } from 'src/app/services/rfq.service';
import { SCESupplyService } from 'src/app/services/sce-supply.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-supply-l1',
  templateUrl: './supply-l1.component.html',
  styleUrls: ['./supply-l1.component.css']
})
export class SupplyL1Component implements OnInit {
  chart: Chart;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  DateCalender: any;
  regions: any = [];
  public selectedRegions = [];
  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  cat1dropdownSettings = {};
  cat2dropdownSettings = {};
  dateRange: any;
  userId_val: any;
  quotePendancyChart: any;
  RkeyValue: any;
  reportName: any;
  brand = 'all';
  catL1list = [];
  catL2list = [];
  selectedL1 = [];
  selectedL2 = [];
  dropDownSelect = false;
  GMData: any[];

  constructor(private commonService: CommonService, private service: SelfServeService, private rfqService: RfqService, private sceService: SCESupplyService, private appService: AppService, private navbarTitle: NavbarComponent) {
    this.userId_val = this.appService.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
    this.getFiltersData();
    this.multiSelectDropdown();
    this.fetchCat1list();
  }

  ngOnInit() {
  }

  calculateDateRange(value: Date): void {
    if (value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 185) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      }
    }
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.cat1dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      idField: 'id',
      textField: 'category_name'
    };
    this.cat2dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }

  getFiltersData() {
    this.rfqService.getFiltersItemTrackingES(this.userId_val).subscribe(filtersRes => {
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }

  fetchCat1list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l1_cat_list';
    this.rfqService.getCall(url).subscribe(filtersRes => {
      this.catL1list = filtersRes["L1_Category"];
    });
  }

  fetchCat2list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l2_cat_list';
    const ids = [];
    this.selectedL1.forEach(each => {
      ids.push(each.id);
    })
    this.rfqService.postCall(url, {id: ids}).subscribe(filtersRes => {
      this.catL2list = filtersRes["L2_Category"];
    });
  }
  onRegionSelect(item: string) {
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
  }
  onRegionsDeSelectAll(items: any) {
  }
  getDateFormat(date, isLast = false) {
    date = new Date(date);
    if (!date) return;
    if(isLast) {
      date.setDate(date.getDate() + 1);
    }
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }

  generateReports() {
    const payload = this.generatePayload();
    this.generateQuotePendancyGraph(payload);
    let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
    this.appService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{})
  }

  generateQuotePendancyGraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l1/sales/gmv_gm';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      this.generateQuotePendancyChart(resp);
    })
  }
  generatePayload() {
    const payload = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1], true),
      "regions": this.selectedRegions,
      "username": this.userId_val,
      "brand": this.brand
    }
    if(this.selectedL2.length) {
      payload['category2'] = this.selectedL2;
      payload['category1'] = [];
    } else if(this.selectedL1.length) {
      const categoryNames = [];
      this.selectedL1.forEach(each => {
        categoryNames.push(each.category_name);
      });
      payload['category1'] = categoryNames;
      payload['category2'] = [];
    } else {
      payload['category1'] = [];
      payload['category2'] = [];
    }
    return payload;
  }

  generateValuesForGraph(data, x = 'X') {
    const values = [];
    const categories = [];
    if (data && data.length) {
      data.forEach(element => {
        if(element['Y'] > 100) {
          values.push((+element['Y'] / 100000));
        } else {
          values.push(element['Y'] || 0);
        }
        categories.push(element[x]);
      });
    }
    return {
      values: values,
      categories: categories
    };
  }

  generateQuotePendancyChart(data?) {
    const winRate = this.generateValuesForGraph(data['data']['gm_%']);
    const winRateValue = this.generateValuesForGraph(data['data']['gmv_value']);
    const options: any = {
      chart: {
        type: 'line',
        height: 300
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: winRate.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {}
        },
        labels: {
          format: '{value}%',
          style: {}
        },
        min: 0
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value} L',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [
        {
          name: 'GMV (By Value) (In Lakhs)',
          color: 'yellow',
          type: 'column',
          yAxis: 1,
          data: winRateValue.values,
        },
        {
          name: 'GM(%)',
          color: 'blue',
          yAxis: 0,
          data: winRate.values,
        }
      ]
    }
    this.quotePendancyChart = new Chart(options);
    const excelData = this.sceService.post(data && data.data.raw_data);
    this.GMData = excelData['excel'];
  }

  exportAsXLSX1(): void {
    this.service.exportAsExcelFile(this.GMData, 'GMData');
  }

  dropdownclose(value) {
    if(this.dropDownSelect) {
      this.fetchCat2list();
      this.dropDownSelect = false;
    }
  }

}