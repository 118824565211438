import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
HC_map(Highcharts);
require('../../indiamap')(Highcharts);
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { Options } from 'highcharts';

import { Component, OnInit, Input, OnChanges, NgZone, SimpleChanges } from '@angular/core';
import { HttpService } from './../../http.service';
import { keys } from 'highcharts';
//import { ItemTrackingESService } from '../item-tracking-es.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { AppConstants } from './../../shared/app.constants';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
@Component({
  selector: 'app-item-tracking-otif',
  templateUrl: './item-tracking-otif.component.html',
  styleUrls: ['./item-tracking-otif.component.css']
})
export class ItemTrackingOTIFComponent implements OnInit {
  userId_val:any;
  OTIFpreviousQuery = {};
  pageNumber: number = 1;
  total_pages: number;
  config: any = {};
  options: Options;
  chart: Chart;
  public BarLine1Graph: any;
  @Input('itemOTIFWise') itemOTIFWise = false;
  overall_otif: string;
  delivered_items = [];
  otif_precent = [];
  viewDetailsVal: boolean;
  viewDetailsTable: boolean;
  tableHeaders = [];
  tableResponse = [];
  totalrecords: number;
  splineChart: any;
  maxDate: Date = new Date();
  getGraphsData() {
    this.overall_otif = '';
    let data = {};
    if (this.tabledataObj) {
      this.overall_otif = this.tabledataObj['data']['overall_otif'];
      data['delivered_items'] = this.tabledataObj['data']['delivered_items'];
      data['otif'] = this.tabledataObj['data']['otif'];
      data['time_range'] = this.tabledataObj['data']['time_range'];
    }

    this.generateBarLine1Graph(data);
  }

  generateBarLine1Graph(data) {
    const monthsList = [];
    // data['xAxis'].forEach(function (obj) {
    //   monthsList.push(moment(obj.slice(5), 'MM').format('MMM'));
    // });

    const options: any = {
      chart: {
        zoomType: 'xy',
        innerHeight: 1000
      },
      title: {
        text: 'OTIF Summary',
        align: 'center'
      },
      margin: [5, 0, 10, 0],
      subtitle: {
        text: ''
      },
      xAxis: [{
        categories: data['time_range'],
        crosshair: true,
        index: 1,
      }],
      yAxis: [
        { // Primary yAxis
          gridLineWidth: 0,
          labels: {
            format: '{value}%',
            style: {},
            enabled: true
          },
          title: {
            text: 'Percent',
            style: {}
          },
          opposite: true,
          // height: '50%',
          min: 0

        }, { // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: '# Item Count',
            style: {}
          },
          labels: {
            format: '{value}',
            style: {}
          },
          min: 0
          // top: '50%',
          // height: '50%'
        }],
      tooltip: {
        shared: true
      },
      plotOptions: {
        // series: {
        //   cursor: 'pointer',
        //   point: {
        //     events: {
        //       click: function (event) {
        //         if (event.target.localName === 'rect') {
        //           alert('Clicked on Bar \n Booking Month: ' + this.category + ',     Booking Values (INR LAKHS): ' + this.y);
        //         } else {
        //           alert('Clicked on Line \n Booking Month: ' + this.category + ',    Target Achieved: ' + this.y + '%');
        //         }

        //       }
        //     }
        //   }
        // }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: '#Delivered Items',
        type: 'column',
        color: '#4d79ff',
        yAxis: 1,
        data: data['delivered_items'] || [],
        marker: {
          enabled: false
        },
        dashStyle: 'shortdot',
        tooltip: {
          valueSuffix: ''
        }

      }, {
        name: 'OTIF(%)',
        type: 'spline',
        color: '#ffa31a',
        data: data['otif'] || [],
        tooltip: {
          valueSuffix: ''
        }
      }]
    };
    new Highcharts.Chart('BarLine1Graph', options);

    this.viewDetailsVal = true;
  }

  generateValuesForGraph(data) {
    const values = [];
    const categories = [];
    if (data && data.length) {
      data.forEach(element => {
        values.push(element['Y']);
        categories.push(element['X']);
      });
    }
    return {
      values: values,
      categories: categories
    };
  }

  generateSplineChart(data) {
    const otif1Data = this.generateValuesForGraph(data['data']['otif1']);
    const otif2Data = this.generateValuesForGraph(data['data']['otif2']);
    const otifAll = this.generateValuesForGraph(data['data']['otifAll']);
    const options: any = {
      chart: {
        type: 'line',
      },
      title: {
        text: 'Within CDD - National',
      },
      xAxis: {
        categories: otifAll.categories,
        // crosshair: true,
        // height: 100
      },
      yAxis: {
        valueSuffix: '%'
      },
      plotOptions: {
        series: {
          lineWidth: 4,
          // marker: {
          //   enabled: false
          // }
        }
      },
      tooltip: {
        formatter: function () {
          let tooltipMarkup;
          let filteredData;
          let name;
          if(this.point.color == 'red') {
            filteredData = data.data.otif1;
            name = 'CDD1-Strategic';
          } else if(this.point.color == 'yellow') {
            filteredData = data.data.otifAll;
            name = 'CDD-All';
          } else if(this.point.color == 'blue') {
            filteredData = data.data.otif2;
            name = 'CDD2-Strategic';
          }
          const headerObj = filteredData.filter(each => each['X'] == this.point.category)
          tooltipMarkup = `<span style="font-size:10px"><b>${headerObj[0]['Week']}<b/></span><br/><b>${name}: ${headerObj[0].Y} `;
          return tooltipMarkup;
        },
        shared: false,
        useHTML: true
      },
      series: [{	
        name: 'CDD1-Strategic',
        data: otif1Data.values,
        color: 'red'
      }, {
        name: 'CDD2-Strategic',
        color: 'blue',
        data: otif2Data.values,
      }, {
        name: 'CDD-All',
        color: 'yellow',
        data: otifAll.values,
      }]
    }
    this.splineChart = new Chart(options);
    this.userId.saveInfo(environment.user_tracking_api,{'username':this.userId_val,'report_name':'OTIF Item Tracking'}).subscribe(reportdata=>{
          console.log(reportdata);
        })
  }


  // enterprise variables
  stages = [];
  reportsData = [];
  previousQuery = {};
  requestDetails = {};

  @Input('itemTrackerESWise') itemTrackerESWise = false;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  detaileddrillDownData: any = {};
  DateCalender: any;
  detailed = false;
  filterData: any = {};
  requestbody: any;
  columnsHeader = [];
  regions: any = [];
  teams: any = [];
  customers: any = [];
  plants: any = [];
  ddTable = {};
  public selectedL1 = [];
  public selectedL2 = [];
  public selectedL3 = [];
  public selectedL4 = [];
  detailedTable = [];
  tabledata: any = {};
  public selectedRegions = [];
  public selectedTeams = [];
  public selectedCustomers = [];
  public selectedPlants = [];

  TeamRegionsListObj: any = {};
  dropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  otif: string;
  tabledata_rows: any;
  rowheaders: string[];
  lengthTable: number;
  tabledataObj: any;
  sortedtabledata = [];

  sortedtabledataDD = [];
  dateRange: any;
  RkeyValue: any;
  req_start_date: string;
  req_end_date: string;


  constructor(private commonService: CommonService,
               
    private httpService: HttpService,
    public itESservice: ItemTrackingESService,
    private ngZone: NgZone, private userId: AppService,
    private service: SelfServeService,
    private toaster: ToastrService) {

    this.userId_val = this.userId.userDetails.email;
    this.getFiltersData();
    this.multiSelectDropdown();
    //date Range default
    // this.getDefaultDateRange();

    this.getSelectedFilters(this.selectedRegions);

    this.config = {
      currentPage: 1,
      itemsPerPage: 20,
      totalItems: 40
    };
  }
  getDefaultDateRange() {
    this.itESservice.get_otif_date_limit().subscribe(dateRes => {
      this.DateCalender = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
    });
  }
  getFiltersData() {
    this.itESservice.getFiltersItemTrackingES(this.userId_val).subscribe(filtersRes => {
      this.filterData = filtersRes["data"];
      this.TeamRegionsListObj = filtersRes["data"];
      this.regions = Object.keys(filtersRes["data"]);
    });
  }

  calculateDateRange(value: Date): void {
    if(value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 92) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      } 
    }
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  refresh() {
    this.columnsHeader = [];
    this.requestbody = {};
    this.otif = null;
    this.selectedRegions = [];
    this.selectedTeams = [];
    this.selectedCustomers = [];
    this.selectedPlants = [];
    this.regions = [];
    this.customers = [];
    this.plants = [];
    this.teams = [];
    this.getDefaultDateRange();
    this.dateRange = null;
    this.getFiltersData();

  }
  getTableDataFromApi() {
    this.sortedtabledata = [];
    this.tabledata = [];
    this.tabledataObj = [];

    this.requestbody = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "region": this.selectedRegions,
      "team": this.selectedTeams,
      "customer_name": this.selectedCustomers,
      "plant_name": this.selectedPlants,
      "username": this.userId_val
    }

    this.itESservice.sendPostRequest('https://analysis.moglix.com/' + 'item_tracking/get_otif_trendline', this.requestbody).subscribe((data) => {
      this.tabledataObj = data;
      console.log(this.tabledataObj)
      this.getGraphsData()
    })
  }
  sortedTable(tabledata) {
    for (var i in this.tabledata) {
      var obj = this.tabledata[i];
      let orderObject = {};
      for (var j in this.columnsHeader) {
        for (const key of Object.keys(obj)) {
          if (this.columnsHeader[j] == key) {
            orderObject[key] = obj[key];
          }
        }
      }
      this.sortedtabledata.push(orderObject);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getDateRange(this.dateRange);
    this.getSelectedFilters(this.selectedRegions);
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);

    this.getGraphsData()
  }
  displayItemTracking() {
    this.getStages();
  }
  async getStages() {
    const url = 'assets/stages.json';
    try {
      this.stages = await this.httpService.sendGetRequest(url);
    } catch (e) {
      console.log(e);
    }
  }
  getDateRange(date = this.dateRange) {
    this.itESservice.getDateItemTrackingES().subscribe(dateRes => {
      // this.dateRanges = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
      this.req_start_date = this.getDateFormat(this.dateRanges.start_date);
      this.req_end_date = this.getDateFormat(this.dateRanges.end_date);

    });
  }

  generateReports() {
    const payload = this.generatePayload();
    const url = environment.baseURL_FREQ+'otif_graph';
    this.itESservice.getotifTrendingLine(url, payload).subscribe(resp => {
      this.generateSplineChart(resp);
    })
  }

  generatePayload() {
    const payload = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "regions": this.selectedRegions,
      "teams": this.selectedTeams,
      "customers": this.selectedCustomers,
      "plants": this.selectedPlants,
      "username": this.userId_val
    }
    return payload;
  }

  // generateReports() {
  //   if (!this.viewDetailsTable) {
  //     if (this.dateRange === null) {
  //       this.commonService.showToast("Please Select Date Range", 2);
  //     }
  //     if (this.dateRange != null && this.selectedRegions != null && this.selectedPlants != null && this.selectedCustomers
  //       != null && this.selectedPlants != null)
  //       this.getTableDataFromApi();
  //   }
  //   if (this.viewDetailsTable) {
  //     this.requestbody = {
  //       "start_date": this.getDateFormat(this.dateRange[0]),
  //       "end_date": this.getDateFormat(this.dateRange[1]),
  //       "region": this.selectedRegions,
  //       "team": this.selectedTeams,
  //       "customer_name": this.selectedCustomers,
  //       "plant_name": this.selectedPlants,
  //       "username": this.userId_val,
  //       "page_number": this.pageNumber
  //     }
  //     this.viewDetails();
  //   }
  // }
  getSelectedFilters(selectedRegionsList) {
    this.teams = [];
    this.selectedTeams = [];
    this.selectedL3 = [];
    this.customers = [];
    this.selectedCustomers = [];
    this.selectedL4 = [];
    this.plants = [];
    this.selectedPlants = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getTeamsKey(selectedRegionsList[j]);
    }
  }
  getTeamsKey(Rkey) {

    this.RkeyValue = Rkey;
    this.TeamRegionsListObj[Rkey].forEach((team, index) => {

      let currentTeamName = Object.keys(this.TeamRegionsListObj[Rkey][index])[0];
      let Tkey = team[currentTeamName];
      for (let t = 0; t < Tkey.length; t++) {
        let Ckey = Object.keys(Tkey[t]);
        this.getCustomersKey(Ckey, Tkey[t]);
      }
      this.selectedTeams.push(currentTeamName);
      this.teams.push(currentTeamName);
    });
  }
  getCustomersKey(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.selectedL3.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getPlantsKey(Ckey, Tkey);
    }
    this.selectedCustomers = this.selectedL3;
    this.customers = this.selectedL3;
  }
  getPlantsKey(Ckey, Tkey) {
    for (let p = 0; p < Tkey[Ckey].length; p++) {
      this.selectedL4.push(Tkey[Ckey][p]);
    }
    this.selectedPlants = this.selectedL4;
    this.plants = this.selectedL4;
  }
  ngOnInit() {
    this.displayItemTracking();
  }
  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }

  pageChange(currentpageNumber: number) {
    console.log(currentpageNumber);
    this.pageNumber = currentpageNumber;

    this.config.currentPage = currentpageNumber;
    this.viewDetails();
  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedTeams = [];
    this.teams = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL3 = [];
    this.selectedPlants = [];
    this.plants = [];
    this.selectedL4 = [];

  }
  //teams
  onTeamSelect(item: string) {
    this.TeamUpdatedOptions(this.selectedRegions, item, this.selectedCustomers, this.selectedPlants);

  }
  onTeamsAll(items: any) {
    this.selectedTeams = items
    this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  onTeamDeSelect(deselectedTID: any): void {

    if (this.selectedTeams != null) {

      this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
    }
    else {
      this.selectedL3 = [];
      this.selectedCustomers = [];
      this.customers = [];
      this.selectedL4 = [];
      this.selectedPlants = [];
      this.plants = [];
    }
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = items;
    this.selectedL3 = [];
    this.selectedCustomers = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Customers
  oncustomerSelect(item: string) { this.TeamUpdatedOptions(this.selectedRegions, this.selectedPlants, this.selectedCustomers, this.selectedPlants); }
  oncustomersAll(items: any) {
    this.TeamUpdatedOptions(this.selectedRegions, items, this.selectedCustomers, this.selectedPlants);
  }
  oncustomerDeSelect(deselectedTCID: any): void { this.TeamUpdatedOptions(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants); }
  oncustomersDeSelectAll(items: any) {
    this.selectedCustomers = items;
    this.selectedL3 = [];
    this.customers = [];
    this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }
  //Plants
  onplantSelect(item: string) { }
  onplantsAll(items: any) { }
  onplantDeSelect(deselectedTPID: any): void { }
  onplantsDeSelectAll(items: any) {
  this.selectedL4 = [];
    this.selectedPlants = [];
    this.plants = [];
  }



  TeamUpdatedOptions(Rkeys, Tkeys, CKeys, Pkeys) {
    let teamsList = [];
    let s2 = [];
    let s3 = [];

    let s4 = [];
    this.selectedCustomers = [];
    this.customers = [];
    for (let r = 0; r < Rkeys.length; r++) {

      let y: any = [];
      let regionName = Rkeys[r]
      this.TeamRegionsListObj[regionName].forEach((team, index) => {
        teamsList.push(Object.keys(team));
        for (let st = 0; st < this.selectedTeams.length; st++) {
          if (this.selectedTeams[st] == teamsList[index]) {
            s2.push(teamsList[index]);
            for (let t = 0; t < team[teamsList[index]].length; t++) {
              for (let c = 0; c < Object.keys(team[teamsList[index]][t]).length; c++) {
                s3.push(Object.keys(team[teamsList[index]][t])[c]);
                this.customers = s3;
                this.selectedCustomers = s3;
                y = Object.values(team[teamsList[index]][t])[c];
                for (let u = 0; u < y.length; u++) {
                  s4.push(y[u]);
                }
                this.selectedPlants = s4;
                this.plants = s4;
              }
            }
          }
        }
      });
    }
    // this.PlantsUpdate(this.selectedRegions, this.selectedTeams, this.selectedCustomers, this.selectedPlants);
  }
  PlantsUpdate(Rkeys, Tkeys, CKeys, Pkeys) {
    let s5 = [];
    for (let r = 0; r < Rkeys.length; r++) {
      for (let t = 0; t < Tkeys.length; t++) {
        for (let c = 0; c < CKeys.length; c++) {
          for (let p = 0; p < this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]].length; p++) {

            s5.push(this.TeamRegionsListObj[Rkeys[r]][t][Tkeys[t]][c][CKeys[c]][p]);
            this.selectedPlants = s5
            this.plants = s5
            this.selectedL4 = s5
            console.log(this.plants);
          }
        }
      }
    }

  }


  backButton() {
    this.viewDetailsVal = false;
    this.tableHeaders = [];
    this.tableResponse = [];
    this.config = {
      currentPage: 1,
      itemsPerPage: 20,
      totalItems: 20
    };
    this.viewDetailsTable = false;

    this.generateReports();
  }
  async onExport() {
    const url = 'https://analysis.moglix.com/' + 'item_tracking/export_otif_report';





    const body = this.requestbody;
    delete body.page_number;
    const headers = {
      responseType: "blob",
      headers: new HttpHeaders().append("responseType", "text/csv; charset=utf-8")
    }
    try {
      const res: any = await this.httpService.sendPostRequest(url, body, headers);
      const xlData: any[] = [];
      res['result']['otif_data'].forEach((element, i) => {
        xlData[i] = element;
        // this.tableHeaders.forEach((column: any) => {
        //   xlData[i][column] = element[column];
        // });
      });
      console.log(xlData)
      this.exportAsExcelFile(xlData, 'test');
    } catch (e) {
      console.log('ERROR from API', e);
    }
    // window.saveAs
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    this.ngZone.runOutsideAngular(() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '_';
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const name = 'item_tracking_OTIF' + date + time + '.xls'
      XLSX.writeFile(workbook, name, { bookType: 'xls', type: 'buffer' });
    });
  }

  downloadExcel() {
    const payload = this.generatePayload();
    const url = environment.baseURL_FREQ+'otif_graph_detailed';
     this.itESservice.getotifTrendingLine(url, payload).subscribe(res => {
      let excelData1: any = {};
      let excelData2: any = {};
      if (res['data']['Customer_Packet-side'].hasOwnProperty('headers')) {
        const data = this.itESservice.post(res['data']['Customer_Packet-side']);
        excelData1 = data.excel;
      }
      if (res['data']['SPO-side'].hasOwnProperty('headers')) {
        const data = this.itESservice.post(res['data']['SPO-side']);
        excelData2 = data.excel;
      }
      return this.service.exportAsExcelFile2(excelData2, excelData1, 'Detailed report')
    })
  }

  async viewDetails(isPageChange = false) {
    this.viewDetailsTable = true;

    let url = 'item_tracking/get_otif_report';
    let body = {};
    this.OTIFpreviousQuery = this.requestbody;
    if (isPageChange) {
      body = this.OTIFpreviousQuery;
    } else {
      body = this.requestbody;
    }
    body['page_number'] = this.config.currentPage;
    try {
      let data = await this.itESservice.getOTIFViewDetailsTable(url, body).subscribe(res => {
        this.tableHeaders = res['result']['headers'];
        this.tableResponse = res['result']['otif_data'];
        console.log(res['result']['total_pages'] * 20);
        this.config.total_pages = res['result']['total_pages'];
        this.totalrecords = res['result']['total_pages'] * 20;
        this.config.currentPage = this.pageNumber;
        this.config.itemsPerPage = 20;
        this.config.totalItems = this.totalrecords;

      });
    } catch (err) {
      console.log('ERROR from API', err);
      this.reportsData = [];
    }

  }

}
