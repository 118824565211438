import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';

const fur_baseURL: string = environment.baseURL_FREQ;


@Injectable({
  providedIn: 'root'
})
export class CohortService {
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  timestampval2 = "";
  timestampval3 = "";
  timestampval4 = "";
  headers2: any[];
  headers3: any[];
  headers4: any[];
  finalresult2: any[];
  finalresult3: any[];
  finalresult4: any[];

  dtOptions2: {};
  dtOptions3: {};
  dtOptions4: {};

  responseList2: any[];
  responseList3: any[];
  responseList4: any[];

  responseListExcel2 = [];
  responseListExcel3 = [];
  responseListExcel4 = [];

  public finalDataObject2 = { table: {}, excel: [] };
  public finalDataObject3 = { table: {}, excel: [] };
  public finalDataObject4 = { table: {}, excel: [] };

  dtOptionsenable2 = false;
  dtOptionsenable3 = false;
  dtOptionsenable4 = false;

  constructor(private http: HttpClient) { }
  saveInfo(urlpath, data) {
    if (urlpath == 'monthly_cohort_plantwise') {

      return this.http.post(fur_baseURL + urlpath, data)
    }
    if (urlpath != 'monthly_cohort_plantwise') {

      return this.http.post(fur_baseURL + urlpath, data)
    }


  }
  getTeamRegion(teamRegionURL, TeamRegionList) {
    return this.http.post(environment.baseURL_FREQ+UrlConfig.accessMapping, TeamRegionList);
  }


  postCohort(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;

    this.headers2 = [];
    this.finalresult2 = [];
    this.dtOptions2 = {};
    this.responseList2 = [];
    this.responseListExcel2 = [];
    this.dtOptionsenable2 = false;


    this.headers3 = [];
    this.finalresult3 = [];
    this.dtOptions3 = {};
    this.responseList3 = [];
    this.responseListExcel3 = [];
    this.dtOptionsenable3 = false;




    this.headers4 = [];
    this.finalresult4 = [];
    this.dtOptions4 = {};
    this.responseList4 = [];
    this.responseListExcel4 = [];
    this.dtOptionsenable4 = false;



    this.timestampval = finalpostObj['dateval'];
    this.headers = finalpostObj['data1']['headers'];
    this.finalresult = finalpostObj['data1']['data'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    this.timestampval2 = finalpostObj['dateval'];
    this.timestampval3 = finalpostObj['dateval'];
    this.timestampval4 = finalpostObj['dateval'];

    this.headers2 = finalpostObj['data2']['headers'];
    this.finalresult2 = finalpostObj['data2']['data'];
    this.finalDataObject2 = this.sortedResponseList2(this.finalresult2);

    return [this.finalDataObject, this.finalDataObject2, this.finalDataObject3, this.finalDataObject4];
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList)
      this.dtOptions = this.tabledata(this.responseList);
    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }



  sortedResponseList2(finalresult2) {

    for (var i in finalresult2) {
      let obj2 = finalresult2[i];
      let orderObject2 = {};
      let orderobjectwithoutcomma2 = {}
      for (var h2 in this.headers2) {
        for (const key2 of Object.keys(obj2)) {
          if (this.headers2[h2] == key2) {

            orderObject2[key2] = obj2[key2].toLocaleString();
            if (orderObject2[key2].replace(/\s/g, "") == "") {

              orderObject2[key2] = obj2[key2].toLocaleString();
            }
            else {
              if (isNaN(orderObject2[key2]))
                orderObject2[key2] = obj2[key2].toLocaleString();
              else {
                orderObject2[key2] = obj2[key2].toLocaleString() + "%";
              }
            }
            orderobjectwithoutcomma2[key2] = obj2[key2];
          }
        }
      }
      this.responseList2.push(orderObject2);
      this.responseListExcel2.push(orderobjectwithoutcomma2);

    }
    if (this.responseList2)
      this.dtOptions2 = this.tabledata2(this.responseList2);
    if (this.responseList2 && this.responseListExcel2)
      this.finalDataObject2 = { table: this.dtOptions2, excel: this.responseListExcel2 };
    return this.finalDataObject2;
  }
  sortedResponseList3(finalresult3) {

    for (var i in finalresult3) {
      let obj3 = finalresult3[i];
      let orderObject3 = {};
      let orderobjectwithoutcomma3 = {}
      for (var h3 in this.headers3) {
        for (const key3 of Object.keys(obj3)) {
          if (this.headers3[h3] == key3) {

            orderObject3[key3] = obj3[key3].toLocaleString();
            if (orderObject3[key3].replace(/\s/g, "") == "") {

              orderObject3[key3] = obj3[key3].toLocaleString();
            }
            else {
              if (isNaN(orderObject3[key3]))
                orderObject3[key3] = obj3[key3].toLocaleString();
              else {
                orderObject3[key3] = obj3[key3].toLocaleString() + "%";
              }
            }
            orderobjectwithoutcomma3[key3] = obj3[key3];
          }
        }
      }
      this.responseList3.push(orderObject3);
      this.responseListExcel3.push(orderobjectwithoutcomma3);

    }
    if (this.responseList3)
      this.dtOptions3 = this.tabledata3(this.responseList3);
    if (this.responseList3 && this.responseListExcel3)
      this.finalDataObject3 = { table: this.dtOptions3, excel: this.responseListExcel3 };
    return this.finalDataObject3;
  }



  sortedResponseList4(finalresult4) {

    for (var four in finalresult4) {
      let obj4 = finalresult4[four];
      let orderObject4 = {};
      let orderobjectwithoutcomma4 = {}
      for (var h4 in this.headers4) {
        for (const key4 of Object.keys(obj4)) {
          if (this.headers4[h4] == key4) {

            orderObject4[key4] = obj4[key4].toLocaleString();
            if (orderObject4[key4].replace(/\s/g, "") == "") {

              orderObject4[key4] = obj4[key4].toLocaleString();
            }
            else {
              if (isNaN(orderObject4[key4]))
                orderObject4[key4] = obj4[key4].toLocaleString();
              else {
                orderObject4[key4] = obj4[key4].toLocaleString() + "%";
              }
            }
            orderobjectwithoutcomma4[key4] = obj4[key4];
          }
        }
      }
      this.responseList4.push(orderObject4);
      this.responseListExcel4.push(orderobjectwithoutcomma4);

    }
    if (this.responseList4)
      this.dtOptions4 = this.tabledata4(this.responseList4);
    if (this.responseList4 && this.responseListExcel4)
      this.finalDataObject4 = { table: this.dtOptions4, excel: this.responseListExcel4 };
    return this.finalDataObject4;
  }

  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }
    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      "searching": false,
      // scrollY: '100vh',
      paging: false,
      //info: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      language: {
        info: this.timestampval,

        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        // "infoFiltered": ""
      }
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
  tabledata2(tableData2) {
    let headerData2 = [];
    for (let item2 of this.headers2) {
      headerData2.push({ title: item2, data: item2 });
    }

    this.dtOptions2 = {
      responsive: true,
      paging: false,
      "searching": false,
      //scrollY: '100vh',
      //info: false,
      data: tableData2,
      columns: headerData2,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      language: {
        info: this.timestampval,


        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions2) {
      this.dtOptionsenable2 = true;
    }

    return this.dtOptions2;
  }



  tabledata3(tableData3) {
    let headerData3 = [];
    for (let item3 of this.headers3) {
      headerData3.push({ title: item3, data: item3 });
    }

    this.dtOptions3 = {
      responsive: true,
      paging: false,
      "searching": false,
     // scrollY:"40vh",
      //info: false,
      data: tableData3,
      columns: headerData3,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      language: {
        info: this.timestampval,


        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions3) {
      this.dtOptionsenable3 = true;
    }

    return this.dtOptions3;
  }


  tabledata4(tableData4) {
    let headerData4 = [];
    for (let item4 of this.headers4) {
      headerData4.push({ title: item4, data: item4 });
    }

    this.dtOptions4 = {
      responsive: true,
      paging: false,
      "searching": false,
      //scrollY:"40vh",
      //info: false,
      data: tableData4,
      columns: headerData4,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      language: {
        info: this.timestampval,


        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions4) {
      this.dtOptionsenable4 = true;
    }

    return this.dtOptions4;
  }



}
