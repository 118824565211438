import { Router } from '@angular/router';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from '../../services/app.service';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import * as Highcharts from 'highcharts';
import { CommonService } from '../../shared/common.service';
import { MgmtScoreCardService } from 'src/app/services/mgmt-score-cardservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { NavbarComponent } from '../navbar/navbar.component';


import { MgmtService } from '../../services/mgmt.service';
const analytics_baseURL = environment.analytics_baseURL;
const moment = extendMoment(Moment);
@Component({
  selector: 'app-enterprise-region-wise',
  templateUrl: './enterprise-region-wise.component.html',
  styleUrls: ['./enterprise-region-wise.component.css']
})
export class EnterpriseRegionWiseComponent implements OnInit {
  reportName ='';
  userId_val :any; 
  dtOptionspickup: DataTables.Settings = {};
  detailedReportStatus: boolean;
  dateval_mgmt_scorecard: string;
  dateval_mgmt_osod: string;
  sccorecard_mgmt_month: any;
  osod_mgmt_month: any;
  dtOptionsenable0: boolean;
  dtOptionsenable_1: boolean;
  finalDataObjectpickup = { table: {}, excel: [] };
  detailedfinalDataObjectpickup = { table: {}, excel: [] };
  finalDataObject = { table: {}, excel: [] };  
  finalDataObject2 = { table: {}, excel: [] };  
  excelData2 = [];  
  public selectedRowValue_TypeVal: string;
  public selectedRowValue_Team_TypeVal: string;
  public selectedColumnValue: string;
  public visualTable: any;
  public bsValue: any = new Date();
  public BarLine1Graph: any;
  public BarGraph1: any;
  public BarLine2Graph: any;
  public BarGraph2: any;
  public StackedGraph: any;
  public LineGraph: any;
  public bulletgraph: any;
  public discountData: any = [];
  public sparkLineList: any = {};
  public mapData: any = {};
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public minDate: any = new Date('2019-04-01');
  public maxDate: any = new Date();
  public deliveryList: any = [];
  public mapChartOptions: any = {};
  public baseURL_freq: any;
  tableCondition: boolean;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  regions = [];
  mngmt_data: any;
  selectedRegions = [];
  dropdownSettings = {};
  popUp :boolean;
  selectedRowValue:string;
  selectedColumnValues:any;
  userComment:string;
  commentForm: FormGroup;
  commentFormGroup: FormGroup;
  modalValue = 'inactive';
  userCommentList =[];
  enterpriseMenuList=[
      { 'title':"Enterprise Dashboard", id:0},
      { 'title':"Summary  - Regions", id:5},
      { 'title':"Region-wise Enterprise", id:1},
      { 'title':"Team-wise Enterprise", id:2}
  ]
  
// Tabular Region,Team,Plant
regionDropdownSettings = {};
teamDropdownSettings = {};
plantDropdownSettings = {};
// Tabular Selected Items
public selectedRegionsList= [];
public selectedTeamsList= [];
public selectedPlantsList= [];
// default List of Items
regionsList:any;
teamsList: any;
plantsList:any;
// obj list
TeamRegionsListObj: any;
PlantRegionsListObj: any;
//Final object to get the data
  regionfinalpostObj: {};
  teamfinalpostObj: {};
  plantfinalpostObj: {};
  requestBody ={};
  url: string;
  urlpath: string;
  filterType: number;
  detailedTable: boolean;
  selected:any;
    datamessageDate =""
  
  constructor(private navbarTitle: NavbarComponent,private mgmtService: MgmtService,private router: Router,private Service: SelfServeService,private userId: AppService,private _fb: FormBuilder,private appService: AppService, private commonService: CommonService,
     private mgmtScoreCardservice: MgmtScoreCardService) {
      this.userId_val =  this.userId.userDetails.email;
      this.reportName = this.navbarTitle.reportlabel;
  }
  ngOnInit() {
    this.selected=1;
    this.selectedRegions = JSON.parse(localStorage.getItem('regions'));
    this.regions = this.selectedRegions;
    this.selectedRegionsList = this.selectedRegions;
    this.selectedTeamsList= this.teamsList;
    this.getDataByFilter(new Date());
    this.multiSelectDropdown();
  
    this.userId_val =  this.userId.userDetails.email;
    this.getdefaultRegionsList();
    this.getVisual(this.selected);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getdefaultRegionsList();
    this.getCurrentRegionsTeams(this.selectedRegionsList);
    this.getVisual(this.selected);
  }
  getdefaultRegionsList(){
    this.appService.getTeamRegion(environment.baseURL_FREQ+UrlConfig.accessMapping,{username:this.userId_val}).subscribe(
        (response) => {

     

          this.TeamRegionsListObj = response["data"][0];
          this.selectedRegionsList=Object.keys(response['data'][0]);
          this.regionsList=Object.keys(response['data'][0]);  
          this.getCurrentRegionsTeams(this.selectedRegionsList); 
        });
        return this.selectedRegionsList;
    }
    getCurrentRegionsTeams(selectedRegionsList){
      this.teamsList = [];
      this.selectedTeamsList = [];
      for(let j=0;j<selectedRegionsList.length;j++){
        this.getKey(selectedRegionsList[j]);
    }
  } 
  getKey(key) {
    this.TeamRegionsListObj[key].forEach((team,index)=>{
      this.selectedTeamsList.push(team);
      this.teamsList.push(team);
    });
  }
  multiSelectDropdown() {
    this.regions = this.appService.getUserDetails(['regions']);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.regionDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.teamDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.plantDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  onSubmit({ value, valid }: { value: UserComment, valid: boolean }) {
    console.log(value.userComment, valid);
    this.userCommentList.push(value.userComment);
  }
  getCommentForm(){
    this.commentForm = this._fb.group({
      userComment: new FormControl('')
    });
  }
  saveComments(){
    this.commentForm.value.userComment;
    console.log(this.userComment);
  }
  close(){
    this.modalValue ='';
    this.modalValue = 'inactive';
    this.popUp = false;
  }
 
  currentactiveTab = 1;
  select(e_menu_id) {
    this.selected = e_menu_id; 
};
isActive(e_menu_id) {
    return this.selected === e_menu_id;
};
  getVisual(e_menu_id) {
    this.selected = e_menu_id; 
    this.currentactiveTab = e_menu_id;
    this.filterType =1;
    switch (e_menu_id) {
      case 0: {
        break;
      }
      case 1: {
        this.selected = 1;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }
      case 2: {
        this.selected=2;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }
      case 3: {
        this.selected=3;
        this.getdefaultRegionsList();
        this.getvisualTable();
        break;
      }
      case 5: {
        this.selected=5;
        this.PendencyTables(0);
        break;
      }
    }
  }
  getDataByFilter(date = this.bsValue) {
    this.visualTable = false;
    if (this.selectedRegions && this.selectedRegions.length) {
      const selectedDate = moment(date).format('YYYY-MM');
      
    } else {
      this.commonService.showToast('Please select regions', 3)
    }
  }
  
  getvisualTable() {
    this.detailedTable =false;
    this.filterType = 1;
    this.visualTable = false;
    this.tableCondition = false;
    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};
    this.regionfinalpostObj = { "region": this.selectedRegionsList,"username":this.userId_val};
    this.teamfinalpostObj = {'team':this.selectedTeamsList};   
    this.plantfinalpostObj = {'region': this.selectedRegionsList,'team':this.selectedTeamsList,'plant':this.selectedPlantsList};
    this.baseURL_freq = environment.baseURL_FREQ;
    switch (this.selected) {
      case 1: {
        this.url = 'sale_summary';
        this.finalData(this.url,this.regionfinalpostObj);
        break;
      }
      case 2: {
        this.url = 'sale_summary_teamwise';
        this.finalData(this.url,this.teamfinalpostObj);
        break;
      }
      case 3: {
        this.url = 'sale_summary_plantwise';
        this.finalData(this.url,this.plantfinalpostObj);
        break;
      }
    }
  }
  finalData(urlpath,finalObj){
    this.requestBody =finalObj;
    this.appService.sendPostRequest(environment.baseURL_FREQ+urlpath, this.requestBody).subscribe(res => {
              
      this.datamessageDate = res['message'];
      if(res['data']['data1']!=undefined && res['status']=='SUCCESS'){
        let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
        this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
          console.log(reportdata);
        })
      }
      
      this.mngmt_data = this.mgmtScoreCardservice.post(res);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
         
        }
      }
    });
  }
  commentMgmt(){
    //console.log("form sun=bmit");
    console.log(this.commentForm)
  }
  
  setRowColumnvalues = (cell:string,r: string, c: string) => {  
   
    let requestObj = {region:r,YM:c}
    let teamObj = {team:r,YM:c}
    if(this.selected == 1 && cell =="Sales with Tax (Lakhs)"){
      this.urlpath = "revenue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Return Sales (Lakhs)"){
      this.urlpath = "returns_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="COGS excluding Tax (Lakhs)"){
      this.urlpath = "cogs_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Overdue (Lakhs)"){
      this.urlpath = "overdue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Overdue > 30 Days (Lakhs)"){
      this.urlpath = "overdue_gr30days_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 2 && cell =="Sales with Tax (Lakhs)"){
      this.urlpath = "revenue_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Return Sales (Lakhs)"){
      this.urlpath = "returns_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="COGS excluding Tax (Lakhs)"){
      this.urlpath = "cogs_detailed_team";
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Overdue (Lakhs)"){
      this.urlpath = "overdue_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Overdue > 30 Days (Lakhs)"){
      this.urlpath = "overdue_gr30days_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
  }
  backButton() {
    this.tableCondition = !this.tableCondition;
    this.dtOptionsenable = !this.dtOptionsenable;
    //this.detailedTable = !this.detailedTable;
    this.detailedTable = false;
    this.filterType =1;
  }
  displayTable(path:String,requestObj:any){
    this.tableCondition = false;
    
    this.dtOptionsenable = false;
   this.detailedTable = false;
   
      this.mgmtScoreCardservice.saveInfo(path, requestObj).subscribe(
        (response) => {
          
          
          this.filterType = 2;       
          this.finalDataObject2 = this.mgmtScoreCardservice.post(response);
          this.dtOptions2 = this.finalDataObject2.table;
          this.excelData2 = this.finalDataObject2.excel;
          this.detailedTable = true;
          //this.exportAsXLSX();
        }
      )
   
  }
  calldetailsData(event) {
    var self = this;
    $('#example tbody').on('click', 'td', function () {
      let table = $('#example').DataTable();
      let idx = table.cell(this).index().column;
      let rowIdx = table.cell(this).index().row;
      let title = table.column(idx).header();
      this.selectedRowValue = table.cell(rowIdx, 0).data();
      this.selectedRowValue_TypeVal = table.cell(rowIdx, 1).data();
      this.selectedRowValue_Team_TypeVal = table.cell(rowIdx, 2).data();
      this.selectedRowValue_Team = table.cell(rowIdx, 1).data();
      this.selectedColumnValue = $(title).html();
      console.log(this.selectedRowValue);
      console.log(this.selectedRowValue_TypeVal);
      console.log(this.selectedColumnValue);
      let cell = table.cell( this );
      if(this.selectedRowValue_TypeVal == "Sales with Tax (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }
      if(this.selectedRowValue_TypeVal == "Return Sales (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }  
       if(this.selectedRowValue_TypeVal == "COGS excluding Tax (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      } 
       if(this.selectedRowValue_TypeVal == "Overdue (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }
      if(this.selectedRowValue_TypeVal == "Overdue > 30 Days (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "Sales with Tax (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "Return Sales (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "COGS excluding Tax (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
            if(this.selectedRowValue_Team_TypeVal == "Overdue (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "Overdue > 30 Days (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
    });
  }
  onRegionSelect(item: string) {
    this.getCurrentRegionsTeams(this.selectedRegionsList);
   }
   onRegionsAll(items: any) {
     this.selectedRegionsList = this.regionsList;
     this.getCurrentRegionsTeams(this.selectedRegionsList);
   }
   onRegionDeSelect(deselectedSID: any): void {
       this.selectedRegionsList = this.selectedRegionsList.filter(s => s != deselectedSID);
       this.selectedRegionsList.forEach(sid => {this.onRegionSelect(sid);});
   }
   onRegionsDeSelectAll(items: any){
     this.selectedTeamsList= [];
     this.teamsList = [];
   }
 //teams
   onTeamSelect(item: string) {}
   onTeamsAll(items: any) {}
   onTeamDeSelect(deselectedTID: any): void {}
   onTeamsDeSelectAll(items: any){
       this.selectedTeamsList = items;
   }
  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.excelData2, 'Enterprise');
    this.backButton();
  }
    
  PendencyTables(val) {
    this.dtOptionsenable0=   false;
    this.dtOptionsenable_1= false;
    switch (val){
      case 0: {
        this.getPendencyTables(environment.baseURL_FREQ+"overall_mgm_score/get_mgm_score", -1);
        this.getPendencyTables(environment.baseURL_FREQ+"overall_mgm_score/get_os_od_overall", 0);
        break;
      }
      
    }
  }
  
  getPendencyTables(urlpath,val:any) {
    if (val == -1) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
        this.dateval_mgmt_scorecard = response["dateval"];
        this.sccorecard_mgmt_month = response["scorecard_date"];
        
        this.mgmtService.setmgmt_scorecard_Month(this.sccorecard_mgmt_month);
          this.finalDataObjectpickup = this.mgmtService.postpickup(response, val);
          console.log(this.finalDataObjectpickup);
          this.dtOptionsenable0= true;
          if (this.finalDataObjectpickup.table) {
            this.dtOptionspickup = this.finalDataObjectpickup.table;
          }
        }
      )
    }
    
    if (val == 0) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
          this.dateval_mgmt_osod = response["dateval"];
          this.osod_mgmt_month = response["scorecard_date"];
          this.mgmtService.setmgmt_osod_Month(this.osod_mgmt_month);
          this.finalDataObject = this.mgmtService.post(response, val);
          console.log(this.finalDataObject);
          this.dtOptionsenable_1=   true;
          if (this.finalDataObject.table) {
            this.dtOptions = this.finalDataObject.table;
          }
        }
      )
    }
   
  }
}
export interface UserComment {
  userComment: string;
}
