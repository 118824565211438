import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Report101Service {

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  readonly item_es_apiBaseURL = environment.baseURL_FREQ;
  constructor(private http: HttpClient) { }

  getFiltersItemTrackingES(userId_val) {
    return this.http.post(environment.baseURL_FREQ + "broad_mapping", { "username": userId_val });
  }

  getDateItemTrackingES() {
    return this.http.get(this.item_es_apiBaseURL + "item_tracking/get_date_limit");
  }
  saveInfo(urlpath, data) {

    return this.http.post(urlpath, data)
  }
  post(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['report101Cols'];
    this.finalresult = finalpostObj['report101Rows'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject != null)
      return this.finalDataObject
    else
      return this.finalDataObject
  }
  postData(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj["data"]['headers'];
    this.finalresult = finalpostObj["data"]['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject != null)
      return this.finalDataObject
    else
      return this.finalDataObject
  }
  
  postCustomers(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = ['Customer_ID','Customer_Name'];
    this.finalresult = finalpostObj['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postplantMapping(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = ["Plant_ID","Plant_Name","Customer_Name","Team_Name","Region","Vertical"];
    this.finalresult = finalpostObj['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();

            let regExp = /^\d*(\.)?(\d{0,10})?$/
            if (regExp.test(obj[key]))
              orderobjectwithoutcomma[key] = Number(obj[key]);
            else
              orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderobjectwithoutcomma);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({
        title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
          if (col == 0) {
            $(td).css({ 'background-color': 'lightgray', 'z-index': 500 })
          }

          if (col == 1) {
            $(td).css({ 'background-color': 'lightgray', 'z-index': 500 })
          }

          if (col == 2) {
            $(td).css({ 'background-color': 'lightgray', 'z-index': 500 })
          }
        }
      });
    }
    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {
        leftColumns: 2
      },
      scrollY: '75vh',
      //pageLength: 10,
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',

      columnDefs: [
        {
          width: "300",
          targets: '_all',
          //render: function (data, type, row) {
          //             return   data ;

          //}
          render: function (data, type, row) {
            return data.length > 50 ? '<span title="' + data + '">' + data.substr(0, 20) + "…" + '</span>' : data;


          }
        }
      ],



      language: {
        info: this.timestampval,

        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }
    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
}

