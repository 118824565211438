import { Component, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from 'src/app/services/app.service';
import { RfqService } from 'src/app/services/rfq.service';
import { SCESupplyService } from 'src/app/services/sce-supply.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'app-rfq-dashboards-l3',
  templateUrl: './rfq-dashboards-l3.component.html',
  styleUrls: ['./rfq-dashboards-l3.component.css']
})
export class RfqDashboardsL3Component implements OnInit, OnChanges {
  chart: Chart;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  DateCalender: any;
  sourcers: any = [];
  public selectedSourcers = [];
  dropdownSettings = {};
  sourcerdropdownSettings = {};
  dateRange: any;
  userId_val: any;
  quotePendancyChart: any;
  doubleColumnChart: any;
  splineChart1: any;
  splineChart: any;
  RkeyValue: any;
  filterData: any = {};
  highValue = 'all';
  dtOptions: DataTables.Settings = {};
  tableDisplay: boolean;
  reportName: any;
  bsValue: Date;
  status: any = 0;
  isSourcer = false;
  dropDownSelect = false;
  catL1list = [];
  catL2list = [];
  selectedL1 = [];
  selectedL2 = [];
  cat1dropdownSettings={}
  cat2dropdownSettings={}

  constructor(private commonService: CommonService,private service: SelfServeService, private rfqService: RfqService, private appService: AppService, private sceService: SCESupplyService, private navbarTitle: NavbarComponent) {
    this.userId_val = this.appService.userDetails.email;
    this.reportName = this.navbarTitle.reportlabel;
    const currentDate = new Date();
    this.bsValue = new Date(currentDate.setDate(currentDate.getDate() - 30));
    this.dateRange = [this.bsValue, new Date()]
    this.multiSelectDropdown();
    this.fetchSourcersList();
    this.fetchCat1list();
   }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  fetchCat1list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l1_cat_list';
    this.rfqService.getCall(url).subscribe(filtersRes => {
      this.catL1list = filtersRes["L1_Category"];
    });
  }

  fetchCat2list() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sales/l2_cat_list';
    const ids = [];
    this.selectedL1.forEach(each => {
      ids.push(each.id);
    })
    this.rfqService.postCall(url, {id: ids}).subscribe(filtersRes => {
      this.catL2list = filtersRes["L2_Category"];
    });
  }

  fetchSourcersList() {
    const url = 'https://analysis.moglix.com/supp_dashboard/sourcer_list';
    this.rfqService.getCall(url).subscribe(filtersRes => {
      this.sourcers = filtersRes["sourcer_list"];
      if(this.sourcers.includes(this.userId_val)) {
        this.isSourcer = true;
        this.selectedSourcers = [this.userId_val];
      } else {
        this.isSourcer = false;
        this.selectedSourcers = this.sourcers;
      }
      this.generateReports();
    });
  }

  fetchData1() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/pendency_sourcer';
    const payload = this.generatePayload();
    this.rfqService.postCall(url, payload).subscribe((resp: any) => {
      if(resp && resp.data && resp.data.data == 'null') {
        resp.data.data = {};
        }
        const smeFinanceObj = this.sceService.post(resp);
        if (smeFinanceObj['table']) {
            let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
            this.appService.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
              console.log(reportdata);
            })
            this.dtOptions = smeFinanceObj['table'];
            this.tableDisplay = true;
        }
    });
}

  calculateDateRange(value: Date): void {
    if(value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if(diff + 1 > 185) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      } 
    }
  }

  multiSelectDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };

    this.sourcerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.cat1dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1,
      idField: 'id',
      textField: 'category_name'
    };
    this.cat2dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  //sourcers
  onSourcerSelect(item: string) { }
  onSourcerAll(items: any) {
    this.selectedSourcers = this.sourcers;
   }
  onSourcerDeSelect(deselectedTPID: any): void { }
  onSourcerDeSelectAll(items: any) {
    this.selectedSourcers = [];
  }
  getDateFormat(date, isLast = false) {
    date = new Date(date);
    if (!date) return;
    if(isLast) {
      date.setDate(date.getDate() + 1);
    }
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }

  generateReports() {
    const payload = this.generatePayload();
    if(this.dateRange && this.dateRange.length) {
      this.generateQuotePendancyGraph(payload);
      this.generateGMgraph(payload);
      this.getAdherenceData(payload);
      this.generateRfqPendencyGraph(payload);
      this.fetchData1();
    } else {
      this.commonService.showToast('Please select a date range', 3);
    }
  }

  generateQuotePendancyGraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/conversion_pendency_edr_plus_30';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      // this.drawRfqPendencyGraph(resp);
      this.generateQuotePendancyChart(resp);
      // this.generateDoubleColumnGraphs(resp);
    })
  }

  generatePayload() {
    const payload = {
      "start_date": this.dateRange && this.dateRange.length ? this.getDateFormat(this.dateRange[0]) : '',
      "end_date": this.dateRange && this.dateRange.length ? this.getDateFormat(this.dateRange[1], true) : '',
      "username": this.userId_val,
      "high_value": this.highValue == 'all' ? 2 : this.highValue == '10l' ? 0 : 1,
      "sourcer": this.selectedSourcers,
      pendency_status_code: Number(this.status) || 0,

    }
    if(this.selectedL2.length) {
      payload['category2'] = this.selectedL2;
      payload['category1'] = [];
    } else if(this.selectedL1.length) {
      const categoryNames = [];
      this.selectedL1.forEach(each => {
        categoryNames.push(each.category_name);
      });
      payload['category1'] = categoryNames;
      payload['category2'] = [];
    } else {
      payload['category1'] = [];
      payload['category2'] = [];
    }
    return payload;
  }

  generateValuesForGraph(data, x = 'X', y = 'Y') {
    const values = [];
    const categories = [];
    if (data && data.length) {
      data.forEach(element => {
        values.push(element[y] || 0);
        categories.push(element[x]);
      });
    }
    return {
      values: values,
      categories: categories
    };
  }

  downloadExcel() {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/rfq/data_download';
    const payload = this.generatePayload();
    if(payload['start_date'] == '' || payload['end_date'] == '') {
      this.commonService.showToast('Please select a date range', 3);
      return;
    }
    this.rfqService.postCall(url, payload).subscribe(resp => {
      const excelData = this.sceService.post(resp['data']['raw_data'])
      return this.service.exportAsExcelFile(excelData['excel'], 'RFQ-l3-Data');
    })
  }

  getAdherenceData(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/tat_adherence_by_value';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      this.generateAdherenceChart(resp);
    })
  }

  generateGMgraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/gm';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      // this.drawRfqPendencyGraph(resp);
      // this.generateQuotePendancyChart(resp);
      this.generateGMGraphs(resp);
    })
  }

  generateRfqPendencyGraph(payload) {
    const url = 'https://analysis.moglix.com/supp_dashboard/l3/rfq_pendency_with_ageing';
    this.rfqService.postCall(url, payload).subscribe(resp => {
      this.drawRfqPendencyGraph(resp);
    })
  }

  drawRfqPendencyGraph(data) {
    const pendency = this.generateValuesForGraph(data['data']['Quote pendency %']);
    const regret = this.generateValuesForGraph(data['data']['Quote pendency regret %']);
    const total = this.generateValuesForGraph(data['data']['total_count']);
    const options: any = {
      chart: {
        type: 'column',
        height: 300,
      },
      title: {
        text: 'Quote Pendency With Ageing'
      },
      xAxis: {
        categories: total.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Pendency %',
          style: {}
        },
        labels: {
          format: '{value}',
          style: {}
        },
        min: 0
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {},
          enabled: true
        },
        title: {
          text: 'Total RFQ (Count)',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{	
        name: 'Pendency %',
        data: pendency.values,
        yAxis: 1,
        color: 'yellow'
      },
      {	
        name: 'Regret %',
        data: regret.values,
        yAxis: 1,
        color: 'red'
      },
      {	
        name: 'Total RFQ',
        type:'line',
        data: total.values,
        color: 'blue'
      }]
    }
    this.splineChart1 = new Chart(options);
  }

  generateGMGraphs(data) {
    const gm_percent = this.generateValuesForGraph(data['data']['gm_percent']);
    const options: any = {
      chart: {
        type: 'line',
        height: 400,
      },
      title: {
        text: 'GM-Chart'
      },
      xAxis: {
        categories: gm_percent.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        // height: '50%',
        min: 0

      }, 
    ],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [
      {
        name: 'Total',
        color: 'blue',
        type: 'column',
        data: gm_percent.values,
      }
    ]
    }
    this.doubleColumnChart = new Chart(options);
  }

  generateQuotePendancyChart(data?) {
    const total = this.generateValuesForGraph(data['data']['rfq_total_quoted_value']);
    const conversion = this.generateValuesForGraph(data['data']['conversion_percentage']);
    const options: any = {
      chart: {
        type: 'column',
        height: 400
      },
      title: {
        text: 'EDR + 30 Days Conversion Pendency'
      },
      xAxis: {
        categories: conversion.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Total Quoted Value (Cr)',
          style: {}
        },
        labels: {
          format: '{value}',
          style: {}
        },
        min: 0
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {},
          enabled: true
        },
        title: {
          text: 'Conversion Pendency %',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{
        name: 'Conversion Pendency',
        data: conversion.values,
        yAxis: 1,
        color: 'red'
      },
      {
        name: 'Total Quoted Value (Cr)',
        color: 'blue',
        data: total.values,
        type: 'line'
      }
    ]
    }
    this.quotePendancyChart = new Chart(options);
  }

  generateAdherenceChart(data?) {
    const response = this.generateValuesForGraph(data['data']['total_quoted_value']);
    const winRate = this.generateValuesForGraph(data['data']['win_rate_percent']);
    const tat = this.generateValuesForGraph(data['data']['rfq_tat_%']);
    const perRegion = this.generateValuesForGraph(data['data']['response_%']);
    const options: any = {
      chart: {
        type: 'line',
        height: 300
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: winRate.categories,
      },
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        title: {
          text: 'TAT Adherence',
          style: {}
        },
        labels: {
          format: '{value}%',
          style: {}
        },
        min: 0
      },
      { // Secondary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }],
      plotOptions: {
        series: {
          lineWidth: 4,
        }
      },
      tooltip: {
        shared: false,
        useHTML: true
      },
      series: [{
        name: 'Sum of Quoted Value',
        data: response.values,
        yAxis: 1,
        type: 'column',
        color: 'yellow'
      },
      {
        name: 'Sum of Win Rate(%)',
        color: 'blue',
        yAxis: 0,
        data: winRate.values,
      },
      {
        name: 'Sum of TAT Adherence(%)',
        color: 'orange',
        yAxis: 0,
        data: tat.values,
      },
      {
        name: 'Sum of Response(%)',
        color: 'grey',
        yAxis: 0,
        data: perRegion.values,
      }
    ]
    }
    this.splineChart = new Chart(options);
  }

  dropdownclose(value) {
    if(this.dropDownSelect) {
      this.fetchCat2list();
      this.dropDownSelect = false;
    }
  }
}

