import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NamService {

  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  constructor(private http: HttpClient) { }
  saveInfo(urlpath, data) {
    return this.http.post(urlpath, data)
  }
  post(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['headers']
    this.finalresult = finalpostObj['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  postMgmt(finalpostObj) {
    console.log(finalpostObj)
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['headers'];
    this.finalresult = finalpostObj['result'];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }
  sum() {
    console.log('Anil1')
  }
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            //orderObject[key] = obj[key].toLocaleString();

            orderObject[key] = obj[key];
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }
  tabledata(tableData) {
    let headerData = [];
    //alert(this.headers);
    for (let item of this.headers) {
      headerData.push({ title: item, data: item });
    }
    this.dtOptions = {
      ordering: false,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      searching: false,
      scrollCollapse: true,
      fixedHeader: {
        header: true
      },

      fixedColumns: {
        heightMatch: 'none'
      },
      scrollY: '62vh',
      paging: false,
      data: tableData,
      columns: headerData,
      dom: '<"top"i><"bottom"flp>rt<"clear">',

      columnDefs: [
        {
          width: "300",
          targets: '_all',
          render: function (data, type, row, meta) {
            // if (meta.col < 2) {
            //   return data;
            // } else {
            //   return '<input type="number" (input)="sum()"/>';
            // }
            return data;
          }
        }
      ],
      language: {
        info: this.timestampval,
          search: 'Search',
          searchPlaceholder: 'Search',
      
        "lengthMenu": "",
        //"zeroRecords": "",

        "infoEmpty": "",
        "infoFiltered": ""
      }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }
  updateTargets(url, payload) {
    return this.http.post(url, payload);
  }
}
