import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CpnMsnService {
  headers2:any[];
  searchVal = 1;
  headers: any[];
  finalresult: any[];
  dtOptions: {};
  responseList: any[];
  responseListExcel = [];
  public finalDataObject = { table: {}, excel: [] };
  dtOptionsenable = false;
  timestampval = "";
  LOGINURL: string = '/request';
  readonly pivottablefiltersinput = '/request';
  headerData_PendPODCustom: any;

  constructor(private http: HttpClient) { }

  getData(url){
    return this.http.get(url);

  }

  postData(url,body){
    return this.http.post(url,body);

  }

  post(finalpostObj, dataObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers']
    this.finalresult = finalpostObj['data'][dataObj];
    this.finalDataObject = this.sortedResponseList(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }

  postMoglixSummary(finalpostObj) {
    this.headers = [];
    this.finalresult = [];
    this.dtOptions = {};
    this.responseList = [];
    this.responseListExcel = [];
    this.dtOptionsenable = false;
    this.headers = finalpostObj['data']['headers'];
    this.finalresult = finalpostObj['data']['result'];
    this.finalDataObject = this.sortedResponseListMS(this.finalresult);
    if (this.finalDataObject)
      return this.finalDataObject
  }

  
  sortedResponseList(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            
            let regExp = /^\d*(\.)?(\d{0,10})?$/ 
            if(regExp.test(obj[key]))
                 orderobjectwithoutcomma[key] = obj[key];
              else
                orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledata(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel: this.responseListExcel };
    return this.finalDataObject;
  }

  sortedResponseListMS(finalresult) {
    for (var i in finalresult) {
      var obj = finalresult[i];
      let orderObject = {};
      let orderobjectwithoutcomma = {}
      for (var j in this.headers) {
        for (const key of Object.keys(obj)) {
          if (this.headers[j] == key) {
            orderObject[key] = obj[key].toLocaleString();
            orderobjectwithoutcomma[key] = obj[key];
          }
        }
      }
      this.responseList.push(orderObject);
      this.responseListExcel.push(orderobjectwithoutcomma);

    }
    if (this.responseList) {
      this.dtOptions = this.tabledataMoglixSummary(this.responseList);
    }

    if (this.responseList && this.responseListExcel)
      this.finalDataObject = { table: this.dtOptions, excel:this.responseListExcel };
    return this.finalDataObject;
  }

  // , "createdCell": function (th, cellData, rowData, row, col) {
            
  //   if ( col == 0 ) {
  //     $(th).css({'position':'fixed'})
  //   }
  // }

  tabledata(tableData) {
    let headerData = [];
    for (let item of this.headers) {
      headerData.push({ title: item, data: item, "createdCell": function (td, cellData, rowData, row, col) {
   
           
        // if ( item.includes('4') ) {
        //   $(td).css({'background-color':'#FFF2CC'})
        // }
        if ( item.includes('Down') ) {
          $(td).css({'background-color':'#FFF2CC','font-weight':600})
        }
           
         if(col == 0){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }
      }

      });
    }

    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,
      ordering:false,
      fixedColumns: {
        leftColumns:1
      },
      scrollY: '50vh',
      paging: true,
      data: tableData,
      columns: headerData,
      searching: false,
      info: false,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      columnDefs: [
        {
          width: "300",          
          render: function (data, type, row, meta) {
            let isDownload = row['Download'] ? true : false;
            if(isDownload) {
              return '<b id="cellvalue" style="color:blue;pointer-events: none;">' + data + '</b>';
            } else {
              return '<b id="cellvalue" style="color:blue;pointer-events: none;">' + data + '</b>';
            }
          }
         
        }

        //{  className: "stickyColumn", targets: 0 }
      ],

      // fixedColumns:   true,
      // language: {
      //   info: this.timestampval,
      //     // search: '<i style="color:blue;font-size:12px;"></i>Search',
      //     // searchPlaceholder: 'Search',
      
      //   "lengthMenu": "",
      //   //"zeroRecords": "",

      //   "infoEmpty": "",
      //   "infoFiltered": ""
      // }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }



  tabledataMoglixSummary(tableData) {
    let headertopLevel_custom = [];


    for (let hc = 0; hc < this.headers.length; hc++) {
      if (hc > 3)
      {
        if(this.headers[hc].includes("GMV"))
        headertopLevel_custom.push(this.headers[hc].slice(11));

        else
        headertopLevel_custom.push(this.headers[hc].slice(10));
      }
        else
        headertopLevel_custom.push(this.headers[hc]);
    }
    let headerData2 = [];
    let h = 0;
    for (let itemToplevel of this.headers) {
      headerData2.push({ title: headertopLevel_custom[h], data: itemToplevel, "createdCell": function (td, cellData, rowData, row, col) {
        if ( itemToplevel.includes('Q') ) {
          $(td).css({'background-color':'#FFF2CC','font-weight':600})
        }
         if(col == 0){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }

        if(col == 1){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }

        if(col == 2){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }

        if(col == 3){
          $(td).css({'background-color': 'lightgray','z-index': 500})
        }



         if (rowData['Business'].includes('Total')) {
          $(td).css({'background-color': '#28abb1','font-size':'14px!important'})
         }
         
         if (!(rowData['Business'].includes('Total')) && rowData['BU'].includes('Total')) {
          $(td).css({'background-color': '#3bd6da'})
         }

         if (!(rowData['Business'].includes('Total')) && !(rowData['BU'].includes('Total')) && (rowData['Zone'].includes('Total'))) {
          $(td).css({'background-color': '#94fcff','font-family':"Garamond!important"})
         }
      }
        
      });
      h++;
    }





    
    this.dtOptions = {
      destroy: true,
      responsive: true,
      autoWidth: false,
      scrollX: true,
      scrollCollapse: true,      
      ordering:false,
      fixedHeader: true,
      fixedColumns: {
        leftColumns: 4
            
      },
      scrollY: '50vh',
      searching: false,
      info: false,
      paging: false,
      data: tableData,
      columns: headerData2,
      dom: '<"top"i><"bottom"flp>rt<"clear">',
      columnDefs: [
        {
          width: "300",
          targets: '_all',
          render: function (data, type, row, meta,full) {
              if(row['Download'] != '') {
                return '<b id="cellvalue" style="color:blue;pointer-events: none;">' + data + '</b>';
              } else {
                return data;
              }
          }
        }
      ],
      // language: {
      //   info: this.timestampval,
      //   search: '<i style="font-size:12px;">Achieved/Target is <b style="color:red">0-85%</b>, <b style="color:#e59a0f">86%-95%</b>, <b style="color:green">>95%</b></i>',
      //   searchPlaceholder: 'Search',
      
      //   "lengthMenu": "",
      //   "infoEmpty": "",
      //   "infoFiltered": ""
      // }

    };
    if (this.dtOptions) {
      this.dtOptionsenable = true;
    }
    return this.dtOptions;
  }

  getTableData(url,payload) {
    return this.http.post(url, payload);
  }
}

