import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { SelfServeService } from 'src/app/services/self-serve.service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
// import { FormGroup, FormControl, FormBuilder, FormArray, ValidatorFn, RequiredValidator } from '@angular/forms';
import { NavbarComponent } from '../navbar/navbar.component';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})

export class InventoryComponent implements OnInit {
  

  filterShow=true;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  

reportName:any;
  finalTableResult: any;
  tableDisplay :boolean;
  userId_val: any;
  mouseStatus: boolean = false;
  typeList = [];
  Category= [];
  PPE: string[];
  Customer_Group_Name = [];
  Team: any;
  Sales_Region: string[];
  OrderType: string[];
  Biz_team: string[];
  Payment = [];
  Inventory = [];
  Team_Name = [];
  Payment_terms: any[];
  Inventory_type: string[];
  MSN_tagging: string[];
  SupplierPaymentTerms = [];
  filterList = [];
  selectedtypeList = [];
  selectedCategory = [];
  selectedPPE = [];
  selectedCustomer_Group_Name = [];
  selectedTeam_Name = [];
  selectedSales_Region = [];
  selectedOrderType = [];
  selectedMSN_tagging = [];
  selectedBiz_team = [];
  selectedPayment_terms = [];
  selectedInventory_type = [];

  dropdownSettingstypeList = {};
  dropdownSettingsCategory = {};
  dropdownSettingsPPE = {};
  dropdownSettingsCustomer_Group_Name = {};
  dropdownSettingsTeam_Name = {};
  dropdownSettingsSales_Region = {};
  dropdownSettingsOrderType = {};
  dropdownSettingsMSN_tagging = {};
  dropdownSettingsBiz_team = {};
  dropdownSettingsPayment_terms = {};
  dropdownSettingsInventory_type = {};



  checkboxestypeList = [];
  checkboxesCategory = [];
  checkboxesPPE = [];
  checkboxesCustomer_Group_Name = [];
  checkboxesTeam_Name = [];
  checkboxesSales_Region = [];
  checkboxesOrderType = [];
  checkboxesMSN_tagging = [];
  checkboxesBiz_team = [];
  checkboxesPayment_terms = [];
  checkboxesInventory_type = [];

  masterSelected: boolean;

  headers_phy = [];
  headers_team = [];

  data_phy = [];
  data_team = [];
  
  step = 1;
  accordionVal = 0;
  requestedObject = {};
  tableDisplay2: boolean;
  constructor(private navbarTitle: NavbarComponent,private selfServe:SelfServeService,private commonService:CommonService, private userId: AppService,private inventoryService:InventoryService) {
    this.multiSelectDropdown();
    this.userId_val = this.userId.userDetails.email;
this.reportName = this.navbarTitle.reportlabel;
    this.typeList = ["NEW","CUSTOMER_RETURN","SUPPLIER_RETURN_CN","SUPPLIER_RETURN","Allocated"];

    this.Category = ["Electricals",
    "Fasteners",
    "Abrasives",
    "Automotive",
    "Office Stationery & Supplies",
    "Safety",
    "Electronic Components",
    "Paints & Coatings",
    "Tapes, Adhesives & Sealants",
    "Hand Tools",
    "Material Handling & Packagings",
    "LED & Lighting",
    "Hydraulics",
    "Cleaning & Housekeeping",
    "Bearings & Power Transmission",
    "Welding & Soldering",
    "Lab Supplies",
    "Cutting Tools & Machining",
    "Power Tools",
    "Plumbing & Bathroom Fittings",
    "Lubricants,Greases & Industrial Oils",
    "Raw Materials",
    "Kitchen and Pantry Supplies",
    "Gardening & Landscaping",
    "Hardware",
    "Measurement & Testing",
    "Pumps & Motors",
    "Medical Supplies",
    "Personal Hygiene",
    "Pneumatics",
    "Corporate Gifts"];


    this.PPE = ["Yes", "No"];
    this.Customer_Group_Name = ["Hero MotoCorp Ltd",
    "Mogli Labs (India) Private Limited",
    "Hindustan Zinc Limited",
    "Lumax Industries Limited-North",
    "Rockman Industries Ltd",
    "Navin Fluorine International Limited",
    "Indian Farmers Fertilisers Co-Operative Limited (IFFCO)",
    "Secure Meters Limited",
    "Uno Minda",
    "PPAP Automotive Ltd.",
    "JBM Group",
    "Kansai Nerolac Paints",
    "SRF Limited",
    "Elantas Beck India Limited",
    "Kirloskar Brother Limited",
    "Sanko Gosei JRG Automotive India Pvt. Ltd.",
    "Adani Ports And Special Economic Zone Limited",
    "GHCL Limited",
    "Badve Autotech Pvt.Ltd. 4P",
    "Rane",
    "Grundfos Pumps India Private Ltd",
    "Ultratech Cement Limited",
    "Tata Chemicals Limited",
    "Tata Projects Limited",
    "INEOS Styrolution India Limited",
    "Sterlite Technologies Limited",
    "VARROC ENGINEERING LIMITED",
    "STERLITE TECH CABLES SOLUTIONS LIMITED",
    "CEAT Limited",
    "NRB Bearings Limited",
    "Manjushree Technopack Limited",
    "ABB INDIA LIMITED",
    "KATERRA INDIA PRIVATE LIMITED",
    "GABRIEL INDIA LIMITED",
    "Ashirvad Pipes Private Limited",
    "Timken",
    "Tata Hitachi Construction Machinery Co Pvt Ltd",
    "ABB Power Products and Systems India Limited",
    "Procter & Gamble Home Products Private Limited",
    "Saint-Gobain India",
    "Eaton Industrial System Private Limited",
    "UNITED SPIRITS LIMITED",
    "Shivalik Creations",
    "Hindustan Coca-Cola Beverages Pvt. Ltd.",
    "Diageo Group",
    "TVS Group",
    "JK Fenner India Limited",
    "TRACTORS AND FARM EQUIPMENT LIMITED",
    "India Yamaha Motor Private Limited",
    "Perfetti Van Melle India Pvt. Ltd.",
    "Brakes India Private limited",
    "Kals Breweries ",
    "GlaxoSmithKline Consumer Healthcare Limited",
    "JK Group",
    "EICHER MOTORS LIMITED",
    "Moglix Online Assist",
    "A. G. INDUSTRIES PRIVATE LIMITED",
    "Tata Projects Limited QS",
    "Maiestas Technologies Private Limited",
    "Odisha State Medical Corporation",
    "Samsung India Electronics Private Limited",
    "Shahi Exports Pvt. Ltd.",
    "G B Springs Private Limited",
    "Shriram Pistons & Rings Limited",
    "JSL LIFESTYLE LIMITED",
    "Vedanta Limited-Cairn Oil & Gas",
    "Havells India Limited",
    "Dabur India Ltd",
    "Spark Minda",
    "SM Cylinders Pvt Ltd",
    "ACC Limited",
    "JPM Group",
    "Cummins India Limited",
    "Orient Electric Limited",
    "India Forge-North",
    "Godrej Consumer Products Limited",
    "Relaxo Footwears Limited",
    "Tirupati Containers Pvt. Ltd.",
    "Graziano Trasmissioni India Private Limited",
    "JCB India Limited",
    "Federal-Mogul Ignition Products India Limited",
    "GlaxoSmithKline Consumer Healthcare Ltd ", 
    "Ambuja Cements Limited",
    "Hindustan Unilever Limited",
    "GKN Driveline(India) Ltd",
    "Stork Rubber Products Pvt Ltd",
    "Petrofac International UAE LLC",
    "Alicon Castalloy Limited",
    "E-Pack Polymers Pvt Ltd",
    "Century Metal Recycling Limited",
    "Micromatic Grinding Technologies Limited",
    "DS Spiceco Private Limited",
    "Anand Automotive Private Limited",
    "Minda Corporation Ltd",
    "Uttar Pradesh Medical Supplies Coporation Limited ",
    "Busybees Logistics Solutions Private Limited",
    "MARUTI SUZUKI INDIA LIMITED",
    "Vedanta Aluminium",
    "TATA HOUSING DEVELOPMENT COMPANY LIMITED",
    "MIKADO REALTORS PRIVATE LIMITED",
    "Princeton Infrastructure Private Limited - Kasauli",
    "Land Kart Builders Pvt Ltd",
    "Gatevida Developers Pvt Ltd",
    "SNEH JITENDRAKUMAR BAROT",
    "JINDAL STAINLESS (HISAR) LIMITED",
    "TATA UNISTORE LIMITED",
    "HFCL Limited",
    "ITC Limited",
    "Bridgestone India Private Limited",
    "SUN PHARMACEUTICAL INDUSTRIES LIMITED",
    "Trident Group",
    "GRASIM INDUSTRIES LIMITED",
    "Indian Metals And Ferro Alloys Limited",
    "Emami Agrotech Limited",
    "TIL Limited",
    "Genpact India",
    "Electrosteel Steels Limited",
    "Carborundum Universal Limited",
    "Alstom Transport India Limited - ",
    "Tata Steel BSL Limited",
    "Max Speciality Films Limited",
    "Sunbeam Auto Private Limited",
    "AUTO IGNITION LIMITED",
    "Amazon Services Private Limited",
    "1MG TECHNOLOGIES PRIVATE LIMITED",
    "Talbros Automotive Components Limited",
    "Avery Dennison India Pvt Ltd",
    "Rico Auto Industries Limited  ",
    "Hella International Automotive Private Limited",
    "COLDEX LOGISTICS PRIVATE LIMITED",
    "Bennett Coleman & Co Ltd",
    "Grofers India Private Limited",
    "Thea Group",
    "SBL Private Limited",
    "Alcott Town Planners Pvt Ltd. ",
    "KK Spinners Pvt. Ltd.",
    "KSS ABHISHEK SAFETY SYSTEMS PRIVATE LIMITED",
    "Takata India Private Limited",
    "EVEREST INDUSTRIES LIMITED",
    "BRIGHT LIFECARE PRIVATE LIMITED",
    "Claro Agro Solutions Private Limited",
    "Mahindra & Mahindra",
    "Vedanta Limited",
    " Hengtong Optic Electric India",
    "Kaya Limited",
    "U. P. Twiga Fiberglass Ltd.",
    "Cipla Limited",
    "GATEWAY TERMINALS INDIA PRIVATE LIMITED",
    "Siemens Limited",
    "Lumax Industries Limited-West",
    "Eternis Fine Chemicals Limited",
    "Badve Autocomps Pvt Ltd. 1K (Silencer Division)",
    "Glaxosmithkline consumer pvt ltd - Gurgaon",
    "Reydel Automotive India Pvt Ltd",
    "Sudarshan Chemical Industries Limited",
    "Uno Minda-West",
    "PARANJAPE AUTOCAST PRIVATE LIMITED",
    "Spaco Technologies(India) Pvt. Ltd.",
    "L&L Products",
    "Accenture Solutions Private Limited",
    "Godrej & Boyce Mfg. Co. Ltd",
    "THERMAX LIMITED",
    "SKF India Limited",
    "Cikautxo India Private Limited",
    "Sujan Cooperstandard AVS Pvt. Ltd.",
    "RAYCHEM RPG PRIVATE LIMITED",
    "General Motors",
    "DIGITAL AGE RETAIL PRIVATE LIMITED (FIRST CRY)",
    "GUJARAT PIPAVAV PORT LIMITED",
    "Arvind Limited",
    "Volvo CE India Private Limited",
    "Britannia Industries Limited",
    "Safran Electrical & Power ",
    "Big Basket",
    "BBQ Nation",
    "Faiveley Transport Rail Technologies",
    "Weld and Grind Engineers",
    "Kotak Mahindra",
    "ATRIOS",
    "SKL Agro Impex",
    "KRISHNAPATNAM PORT COMPANY LTD",
    "Arjas Steel Private Limited",
    "Mudhra Fine Blanc Private Limited",
    "Cooper Standard Automotive India Private Limited",
    "Ashok Leyland Limited",
    "Buyer Testing ",
    "RSB Transmissions (I) Ltd.",
    "Merino Industries Limited",
    " Mars International India Pvt Ltd",
    "Tirupati LPG Industries Pvt. Ltd.",
    "Technico Industries Limited",
    "Denso India Private Limited",
    "Hope Foundation",
    "GMR Group",
    "MILKY FASHION CLOTHES PRIVATE LIMITED",
    "TAFE MOTORS AND TRACTORS LIMITED",
    "Tata Steel Limited",
    "Amazon Services Pvt Ltd",
    "B.S.W. TOOLS CORPORATION",
    "Mahle Filter Systems (India) Private Limited",
    "Toyoda Gosei Minda India Private Limited",
    "Chaayos Group",
    "PURPLE PANDA FASHIONS PRIVATE LIMITED",
    "Ecom Express Private Limited",
    "Reliance Industries Limited",
    "SHAPOORJI PALLONJI AND COMPANY PRIVATE LIMITED",
    "Enkei Wheels (India) Ltd",
    "C/O Seco Tools India (P) Ltd",
    "Eaton India Foundation",
    "Flipspaces Technology Labs Pvt. Ltd",
    "Apollo Group"];
    this.Team_Name = ["Team Emerald",
    "Internal (Moglix)",
    "Team Kohinoor",
    "Team Jasper",
    "Team Garnet",
    "Team Sapphire",
    "Team Yellow",
    "Team White",
    "Team Hulk",
    "Team Thanos",
    "Team Sparrow",
    "Team Blue",
    "Team Winterfell",
    "Team Corleone",
    "Team Mumbai",
    "Team Heisenberg",
    "Team Hyderabad",
    "Team Beta",
    "Team Impeccables",
    "Team Cauvery",
    "Team Narmada",
    "Team Madurai",
    "Team Godavari",
    "Team Saraswati",
    "Team Krishna",
    "South Packaging",
    "Team Yamuna",
    "North Packaging",
    "Team Incredibles",
    "Team Green",
    "Team Violet",
    "Team Black",
    "Team Grey",
    "Team Indigo",
    "Team Haridwar",
    "Team Spider",
    "Team Red",
    "Team Cyan",
    "Team Pantnagar",
    "Team Magenta",
    "Team Purple",
    "Team Orange",
    "Team Hero",
    "Team Ludhiana",
    "West Packaging",
    "Team Vedanta",
    "dummy team",
    "Team Brown",
    "Team Wonder",
    "Team Juggernaut",
    "Team Infinity",
    "Team Maverick",
    "Team Aspirants",
    "Team Innovators",
    "VAL East India",
    "Team Lucknow",
    "Team Delta",
    "Team Sterlite Copper",
    "Team Alpha",
    "Team Super",
    "Team Deadpool",
    "Team Logan",
    "Team Ganga",
    "Online Assist"];
    this.Sales_Region = ["Ahmedabad",
    "Internal",
    "North Team 1",
    "North Team 2",
    "Pune",
    "Bangalore",
    "Mumbai",
    "Hyderabad",
    "East",
    "Chennai",
    "Packaging",
    "North Team 3",
    "Online"];
    this.OrderType = ["CustomerSpecific", "Bulk"];
    this.MSN_tagging = ["1", "2"];
    this.Biz_team = ["Enterprise",
    "Bulk",
    "No Team Mapping",
    "Packaging"];
    this.Payment_terms = ["Advance",
    "Credit 45 days",
    "Credit  15 days",
    "Credit 7 days",
    "Credit 40 days",
    "Credit  30 days",
    "Credit 60 days",
    "Credit 120 days",
    "Credit 20 days",
    "Advance first txn - Credit 30 day"]
    this.Inventory_type = ["JIT",
    "VMI25",
    "VMI15",
    "INV_SA",
    "STOCK_TRANSFER"];



    for (let i = 0; i < this.typeList.length; i++) {
      this.checkboxestypeList.push({
        name: this.typeList[i],
        value: this.typeList[i],
        checked: false
      });
    }



    for (let i = 0; i < this.Category.length; i++) {
      this.checkboxesCategory.push({
        name: this.Category[i],
        value: this.Category[i],
        checked: false
      });
    }



    for (let i = 0; i < this.PPE.length; i++) {
      this.checkboxesPPE.push({
        name: this.PPE[i],
        value: this.PPE[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Customer_Group_Name.length; i++) {
      this.checkboxesCustomer_Group_Name.push({
        name: this.Customer_Group_Name[i],
        value: this.Customer_Group_Name[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Team_Name.length; i++) {
      this.checkboxesTeam_Name.push({
        name: this.Team_Name[i],
        value: this.Team_Name[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Sales_Region.length; i++) {
      this.checkboxesSales_Region.push({
        name: this.Sales_Region[i],
        value: this.Sales_Region[i],
        checked: false
      });
    }


    for (let i = 0; i < this.OrderType.length; i++) {
      this.checkboxesOrderType.push({
        name: this.OrderType[i],
        value: this.OrderType[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Customer_Group_Name.length; i++) {
      this.checkboxesCustomer_Group_Name.push({
        name: this.Customer_Group_Name[i],
        value: this.Customer_Group_Name[i],
        checked: false
      });
    }


    for (let i = 0; i < this.MSN_tagging.length; i++) {
      this.checkboxesMSN_tagging.push({
        name: this.MSN_tagging[i],
        value: this.MSN_tagging[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Biz_team.length; i++) {
      this.checkboxesBiz_team.push({
        name: this.Biz_team[i],
        value: this.Biz_team[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Payment_terms.length; i++) {
      this.checkboxesPayment_terms.push({
        name: this.Payment_terms[i],
        value: this.Payment_terms[i],
        checked: false
      });
    }


    for (let i = 0; i < this.Inventory_type.length; i++) {
      this.checkboxesInventory_type.push({
        name: this.Inventory_type[i],
        value: this.Inventory_type[i],
        checked: false
      });
    }
  }



  ngOnInit() {
    this.filterList = ["Type", "Category", "PPE", "Customer Group Name", "Team Name", "Sales Region", "Order Type", "MSN Tagging", "Biz Team", "Payment Terms", "Inventory Type"];


  }
  multiSelectDropdown(){
    this.dropdownSettingstypeList = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsCategory = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsPPE = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsCustomer_Group_Name = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsTeam_Name = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsSales_Region = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsBiz_team = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsPayment_terms = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsOrderType = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsInventory_type = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.dropdownSettingsMSN_tagging = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }


  //type
  toggleALLTypeList(event) {
    this.selectedtypeList = [];
    if (event.target.checked)
      this.checkboxestypeList.forEach((val, index) => {
        val.checked = true;
        this.selectedtypeList.push(val.name);

      });
    else
      this.checkboxestypeList.forEach((val, index) => { 
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedtypeList);
  }
  activeStatusTypeList(event) {

    if (event.target.checked) {
      this.selectedtypeList.push(event.target.value);
      console.log(this.selectedtypeList);
      if (this.selectedtypeList.length == this.typeList.length) {
        $(document.getElementById('select-all-Type')).prop('checked', true);
      }
    } 
    
    else {
      this.selectedtypeList.forEach((element, index) => {
        if (element == event.target.value) this.selectedtypeList.splice(index, 1);
        $(document.getElementById('select-all-Type')).prop('checked', false);
      });
      console.log(this.selectedtypeList);
    }
  
  }

  toggleALLCategory(event) {
    this.selectedCategory = [];
    if (event.target.checked)
      this.checkboxesCategory.forEach((val, index) => {
        val.checked = true;
        this.selectedCategory.push(val.name);

      });
  
      else
      this.checkboxesCategory.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedCategory);
  }
  activeStatusCategory(event) {
    if (event.target.checked) {
      this.selectedCategory.push(event.target.value);
      console.log(this.selectedCategory);
      if (this.selectedtypeList.length == this.Category.length) {
        $(document.getElementById('select-all-Category')).prop('checked', true);
      }
    } 
    else {
      this.selectedCategory.forEach((element, index) => {
        if (element == event.target.value) this.selectedCategory.splice(index, 1);
        $(document.getElementById('select-all-Category')).prop('checked',false);
      });
      console.log(this.selectedCategory);
    }
  }



  //PPE
  toggleALLPPE(event) {
    this.selectedPPE = [];
    if (event.target.checked)
      this.checkboxesPPE.forEach((val, index) => {
        val.checked = true;
        this.selectedPPE.push(val.name);


      });
    else
      this.checkboxesPPE.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedPPE);
  }
  activeStatusPPE(event) {
    if (event.target.checked) {
      this.selectedPPE.push(event.target.value);
      console.log(this.selectedPPE);
      if (this.selectedPPE.length == this.PPE.length) {
        $(document.getElementById('select-all-PPE')).prop('checked', true);
      }
    }
     else {
      this.selectedPPE.forEach((element, index) => {
        if (element == event.target.value) this.selectedPPE.splice(index, 1);
        $(document.getElementById('select-all-PPE')).prop('checked', false);
      });
      console.log(this.selectedPPE);
    }
  }

  //select-all-CustomerGN
  toggleALLCustomer_Group_Name(event) {
    this.selectedCustomer_Group_Name = [];
    if (event.target.checked)
      this.checkboxesCustomer_Group_Name.forEach((val, index) => {
        val.checked = true;
        this.selectedCustomer_Group_Name.push(val.name);
      });
    else
      this.checkboxesCustomer_Group_Name.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedCustomer_Group_Name);
  }
  activeStatusCustomer_Group_Name(event) {
    if (event.target.checked) {
      this.selectedCustomer_Group_Name.push(event.target.value);
      console.log(this.selectedCustomer_Group_Name);
      if (this.selectedCustomer_Group_Name.length == this.Customer_Group_Name.length) {
        $(document.getElementById('select-all-CustomerGN')).prop('checked', true);
      }
    } else {
      this.selectedCustomer_Group_Name.forEach((element, index) => {
        if (element == event.target.value) this.selectedCustomer_Group_Name.splice(index, 1);
        $(document.getElementById('select-all-CustomerGN')).prop('checked', false);
      });
      console.log(this.selectedCustomer_Group_Name);
    }
  }

  //Team Name 
  toggleALLTeam_Name(event) {
    this.selectedTeam_Name = [];
    if (event.target.checked)
      this.checkboxesTeam_Name.forEach((val, index) => {
        val.checked = true;
        this.selectedTeam_Name.push(val.name);
      });
    else
      this.checkboxesTeam_Name.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedTeam_Name);
  }
  activeStatusTeam_Name(event) {
    if (event.target.checked) {
      this.selectedTeam_Name.push(event.target.value);
      console.log(this.selectedTeam_Name);

      if (this.selectedTeam_Name.length == this.Team_Name.length) {
        $(document.getElementById('select-all-TeamName')).prop('checked', true);
      }
    } else {
      this.selectedTeam_Name.forEach((element, index) => {
        if (element == event.target.value) this.selectedTeam_Name.splice(index, 1);
        $(document.getElementById('select-all-TeamName')).prop('checked', false);
      });
      console.log(this.selectedTeam_Name);
    }
  }


  //Sales Region 
  toggleALLSales_Region(event) {
    this.selectedSales_Region = [];
    if (event.target.checked)
      this.checkboxesSales_Region.forEach((val, index) => {
        val.checked = true;
        this.selectedSales_Region.push(val.name);
      });
    else
      this.checkboxesSales_Region.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedSales_Region);
  }
  activeStatusSales_Region(event) {
    if (event.target.checked) {
      this.selectedSales_Region.push(event.target.value);
      console.log(this.selectedSales_Region);


      if (this.selectedSales_Region.length == this.Sales_Region.length) {
        $(document.getElementById('select-all-SalesRegion')).prop('checked', true);
      }
    } else {
      this.selectedSales_Region.forEach((element, index) => {
        if (element == event.target.value) this.selectedSales_Region.splice(index, 1);
        $(document.getElementById('select-all-SalesRegion')).prop('checked', false);
      });
      console.log(this.selectedSales_Region);
    }
  }



  // select-all-OrderType
  // select-all-MSN
  // select-all-Biz
  // select-all-Payment
  // select-all-Inventory

  //Team Name 
  toggleALLMSN_tagging(event) {
    this.selectedMSN_tagging = [];
    if (event.target.checked)
      this.checkboxesMSN_tagging.forEach((val, index) => {
        val.checked = true;
        this.selectedMSN_tagging.push(val.name);
        // select-all-OrderType
        
      });
    else
      this.checkboxesMSN_tagging.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedMSN_tagging);
  }
  activeStatusMSN_tagging(event) {
    if (event.target.checked) {
      this.selectedMSN_tagging.push(event.target.value);
      console.log(this.selectedMSN_tagging);
      if (this.selectedMSN_tagging.length == this.MSN_tagging.length) {
        $(document.getElementById('select-all-MSN')).prop('checked', true);
      }
    } else {
      this.selectedMSN_tagging.forEach((element, index) => {
        if (element == event.target.value) this.selectedMSN_tagging.splice(index, 1);
        $(document.getElementById('select-all-MSN')).prop('checked', false);
      
      });
      console.log(this.selectedMSN_tagging);
    }
  }



  //Team Name 
  toggleALLBiz_team(event) {
    this.selectedBiz_team = [];
    if (event.target.checked)
      this.checkboxesBiz_team.forEach((val, index) => {
        val.checked = true;
        this.selectedBiz_team.push(val.name);
      });
    else
      this.checkboxesBiz_team.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedBiz_team);
  }
  activeStatusBiz_team(event) {
    if (event.target.checked) {
      this.selectedBiz_team.push(event.target.value);
      console.log(this.selectedBiz_team);
      if (this.selectedBiz_team.length == this.Biz_team.length) {
        $(document.getElementById('select-all-Biz')).prop('checked', true);
      }
    } else {
      this.selectedBiz_team.forEach((element, index) => {
        if (element == event.target.value) this.selectedBiz_team.splice(index, 1);
        $(document.getElementById('select-all-Biz')).prop('checked', false);
      });
      console.log(this.selectedBiz_team);
    }
  }


  //Team Name 
  toggleALLPayment_terms(event) {
    this.selectedPayment_terms = [];
    if (event.target.checked)
      this.checkboxesPayment_terms.forEach((val, index) => {
        val.checked = true;
        this.selectedPayment_terms.push(val.name);
      });
    else
      this.checkboxesPayment_terms.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedPayment_terms);
  }
  activeStatusPayment_terms(event) {
    if (event.target.checked) {
      this.selectedPayment_terms.push(event.target.value);
      console.log(this.selectedPayment_terms);
      if (this.selectedPayment_terms.length == this.Payment_terms.length) {
        $(document.getElementById('select-all-Payment')).prop('checked', true);
      }
    } else {
      this.selectedPayment_terms.forEach((element, index) => {
        if (element == event.target.value) this.selectedPayment_terms.splice(index, 1);
        $(document.getElementById('select-all-Payment')).prop('checked', false);
      });
      console.log(this.selectedPayment_terms);
    }
  }


  //Team Name 
  toggleALLInventory_type(event) {
    this.selectedInventory_type = [];
    if (event.target.checked)
      this.checkboxesInventory_type.forEach((val, index) => {
        val.checked = true;
        this.selectedInventory_type.push(val.name);
      });
    else
      this.checkboxesInventory_type.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedInventory_type);
  }

  activeStatusInventory_type(event) {
    if (event.target.checked) {
      this.selectedInventory_type.push(event.target.value);
      console.log(this.selectedInventory_type);
      if (this.selectedInventory_type.length == this.Inventory_type.length) {
        $(document.getElementById('select-all-Inventory')).prop('checked', true);
      }
    } else {
      this.selectedInventory_type.forEach((element, index) => {
        if (element == event.target.value) this.selectedInventory_type.splice(index, 1);
        $(document.getElementById('select-all-Inventory')).prop('checked', false);
      });
      console.log(this.selectedInventory_type);
    }
  }


  //type
  toggleALLOrderType(event) {
    this.selectedOrderType = [];
    if (event.target.checked)
      this.checkboxesOrderType.forEach((val, index) => {
        val.checked = true;
        this.selectedOrderType.push(val.name);
      });
    else
      this.checkboxesOrderType.forEach((val, index) => {
        val.checked = false;
      });
    console.log("CheckAllOptions", this.selectedOrderType);
  }
  activeStatusOrderType(event) {
    if (event.target.checked) {
      this.selectedOrderType.push(event.target.value);
      console.log(this.selectedOrderType); 
      if (this.selectedOrderType.length == this.OrderType.length) {
        $(document.getElementById('select-all-OrderType')).prop('checked', true);
      }
    } else {
      this.selectedOrderType.forEach((element, index) => {
        if (element == event.target.value) this.selectedOrderType.splice(index, 1);
        $(document.getElementById('select-all-OrderType')).prop('checked', false);
      });
      console.log(this.selectedOrderType);
    }
  }



  nextStep() {
    if (this.step == 1) {
      this.step = 2;
      console.log("2" + this.step);

    }
    if (this.step == 2) {
      this.step = 3;
      console.log("3" + this.step);
    }
  }
  addFilter(event) {
    this.requestedObject = {};

    this.requestedObject = {
      "type": this.selectedtypeList,
      "Category": this.selectedCategory,
      "PPE": this.selectedPPE,
      "Customer_Group_Name": this.selectedCustomer_Group_Name,
      "Team": this.selectedTeam_Name,
      "CustRegion": this.selectedSales_Region,
      "OrderType": this.selectedOrderType,
      "team_business_map": this.selectedBiz_team,
      "SupplierPaymentTerms": this.selectedPayment_terms,
      "inventory_type": this.selectedInventory_type,
      "username": this.userId_val

    };
    this.headers_phy =  [];
    this.headers_team = [];
    this.data_phy =  [];
    this.data_team = [];
    this.finalTableResult = [];


    this.dtOptions = {};
    this.dtOptions2 = {};
    this.tableDisplay = false;
    this.tableDisplay2 = false;

    this.userId.saveInfo(environment.baseURL_FREQ+'wms_dashboard', this.requestedObject).subscribe(data => {
      

      if(data['status']=='SUCCESS'){
        let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
        this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
          console.log(reportdata);
        })
      }




      if(data['status'] != 'ERROR'){

        this.headers_phy =  data['headers_phy'];
        this.headers_team = data['headers_team'];
        this.data_phy =  data['data_phy'];
        this.data_team = data['data_team'];
        this.finalTableResult = this.inventoryService.postCohort(this.headers_phy,this.headers_team,this.data_phy,this.data_team)
  
  
        this.dtOptions = this.finalTableResult[0].table;
        this.dtOptions2 = this.finalTableResult[1].table;
  
      this.filterShow = false;
        this.tableDisplay = true;
        this.tableDisplay2 = true;
  
      }

      else{
        this.commonService.showToast('No Data for the selected  Filters',2);
          
  
      }
    })

  }

  over() {
    
    this.mouseStatus = true;
  }
  out() {
    
    
  }
  close() {
    this.mouseStatus = false;
    this.tableDisplay = true;
    this.tableDisplay2 = true;
  }
  download(status) {
    //console.log(status);
    this.finalTableResult[0].excel;
    this.finalTableResult[1].excel;
    console.log(this.finalTableResult[0].excel);
    console.log(this.finalTableResult[1].excel);
    return this.selfServe.exportAsExcelFileInventory(this.finalTableResult[0].excel,this.finalTableResult[1].excel, "Inventory");
  }
  reset(){
  this.selectedtypeList = [];
this.selectedCategory = [];
this.selectedPPE = [];
this.selectedCustomer_Group_Name = [];
this.selectedTeam_Name = [];
this.selectedSales_Region = [];
this.selectedOrderType = [];
this.selectedMSN_tagging = [];
this.selectedBiz_team = [];
this.selectedPayment_terms = [];
this.selectedInventory_type = [];
  }

  onTypeSelect(item:any){
   this.selectedtypeList.push(item);
  }

  onTypeAll(items:any){
   this.selectedtypeList = this.typeList; 
  }

  onTypeDeSelect(deselectedSID:any){
    this.selectedtypeList = this.selectedtypeList.filter(s => s != deselectedSID);
  }

  onTypeDeSelectAll(){
    this.selectedtypeList =[];
  }

  onCategorySelect(item:any){
    this.selectedCategory.push(item);
   }
 
   onCategoryAll(items:any){
    this.selectedCategory = this.typeList; 
   }
 
   onCategoryDeSelect(deselectedSID:any){
     this.selectedCategory = this.selectedCategory.filter(s => s != deselectedSID);
   }
 
   onCategoryDeSelectAll(){
     this.selectedCategory =[];
   }
   onPPESelect(item:any){
    this.selectedPPE.push(item);
   }
 
   onPPEAll(items:any){
    this.selectedPPE = this.typeList; 
   }
 
   onPPEDeSelect(deselectedSID:any){
     this.selectedPPE = this.selectedPPE.filter(s => s != deselectedSID);
   }
 
   onPPEDeSelectAll(){
     this.selectedPPE =[];
   }
   onCustomer_Group_NameSelect(item:any){
    this.selectedCustomer_Group_Name.push(item);
   }
 
   onCustomer_Group_NameAll(items:any){
    this.selectedCustomer_Group_Name = this.typeList; 
   }
 
   onCustomer_Group_NameDeSelect(deselectedSID:any){
     this.selectedCustomer_Group_Name = this.selectedCustomer_Group_Name.filter(s => s != deselectedSID);
   }
 
   onCustomer_Group_NameDeSelectAll(){
     this.selectedCustomer_Group_Name =[];
   }

   onTeam_NameSelect(item:any){
    this.selectedTeam_Name.push(item);
   }
 
   onTeam_NameAll(items:any){
    this.selectedTeam_Name = this.typeList; 
   }
 
   onTeam_NameDeSelect(deselectedSID:any){
     this.selectedTeam_Name = this.selectedTeam_Name.filter(s => s != deselectedSID);
   }
 
   onTeam_NameDeSelectAll(){
     this.selectedTeam_Name =[];
   }

   onSales_RegionSelect(item:any){
    this.selectedSales_Region.push(item);
   }
 
   onSales_RegionAll(items:any){
    this.selectedSales_Region = this.typeList; 
   }
 
   onSales_RegionDeSelect(deselectedSID:any){
     this.selectedSales_Region = this.selectedSales_Region.filter(s => s != deselectedSID);
   }
 
   onSales_RegionDeSelectAll(){
     this.selectedSales_Region =[];
   }

   onOrderTypeSelect(item:any){
    this.selectedOrderType.push(item);
   }
 
   onOrderTypeAll(items:any){
    this.selectedOrderType = this.typeList; 
   }
 
   onOrderTypeDeSelect(deselectedSID:any){
     this.selectedOrderType = this.selectedOrderType.filter(s => s != deselectedSID);
   }
 
   onOrderTypeDeSelectAll(){
     this.selectedOrderType =[];
   }
   onBiz_teamSelect(item:any){
    this.selectedBiz_team.push(item);
   }
 
   onBiz_teamAll(items:any){
    this.selectedBiz_team = this.typeList; 
   }
 
   onBiz_teamDeSelect(deselectedSID:any){
     this.selectedBiz_team = this.selectedBiz_team.filter(s => s != deselectedSID);
   }
 
   onBiz_teamDeSelectAll(){
     this.selectedBiz_team =[];
   }


   onPayment_termsSelect(item:any){
    this.selectedPayment_terms.push(item);
   }
 
   onPayment_termsAll(items:any){
    this.selectedPayment_terms = this.typeList; 
   }
 
   onPayment_termsDeSelect(deselectedSID:any){
     this.selectedPayment_terms = this.selectedPayment_terms.filter(s => s != deselectedSID);
   }
 
   onPayment_termsDeSelectAll(){
     this.selectedPayment_terms =[];
   }
   onInventory_typeSelect(item:any){
    this.selectedInventory_type.push(item);
   }
 
   onInventory_typeAll(items:any){
    this.selectedInventory_type = this.typeList; 
   }
 
   onInventory_typeDeSelect(deselectedSID:any){
     this.selectedInventory_type = this.selectedInventory_type.filter(s => s != deselectedSID);
   }
 
   onInventory_typeDeSelectAll(){
     this.selectedInventory_type =[];
   }
    openFilter(){
     this.filterShow = true;
    //  this.tableDisplay = false;
    //  this.tableDisplay2 = false;

   }
}
