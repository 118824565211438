import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from './../../shared/app.constants';
import { environment } from './../../../environments/environment';
import { HttpService } from './../../http.service';
import { Component, OnInit, NgZone, Input, SimpleChanges, OnChanges } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
//import { ItemTrackingESService } from '../item-tracking-es.service';
import { AppService } from 'src/app/services/app.service';
import { ItemTrackingESService } from 'src/app/fur/item-tracking-es.service';

@Component({
  selector: 'app-item-tracking-day-wise',
  templateUrl: './item-tracking-day-wise.component.html',
  styleUrls: ['./item-tracking-day-wise.component.css']
})
export class ItemTrackingDayWiseComponent implements OnInit {
  submitted: boolean;
  checked:boolean;
  analyzeLabel:String = 'Analyzable Format';
  analyze_format:boolean =true;
  itemTracker = 1;


  
  reportDropdown = [
    { name: 'Customer PO No.', value: 'CUSTOMERPONO' },
    { name: 'Item Ref', value: 'ITEMREF' },
    { name: 'Order Id', value: 'ORDERID' },
    { name: 'Plant Id', value: 'PLANTID' },
    { name: 'Region Name', value: 'REGION' },
    { name: 'Team Name', value: 'TEAMNAME' },
    { name: 'Customer Name', value: 'CUSTOMER' },
    { name: 'Plant Name', value: 'PLANT' }

  ];
  reportDropdownMappingRegion = [];
  reportDropdownMappingTeam = [];
  reportDropdownMappingCustomer = [];
  reportDropdownMappingPlant = [];

  selectedRegions = [];
  selectedTeams = [];
  selectedCustomers = [];
  selectedPlants = [];

  itemTrak: any = {
    dropdownValue: "",
    ref: []
  };
  stages = [];
  reportsData = [];
  config: any = {};
  previousQuery = {};
  regiondropdownSettings = {};
  teamdropdownSettings = {};
  customerdropdownSettings = {};
  plantdropdownSettings = {};
  dateRanges = { start_date: new Date(), end_date: new Date() };
  ites: boolean;
  itesotif: boolean;
  mapValue: number = null;
 
  userId_val:any;
  constructor(private httpService: HttpService,
    private userId: AppService,
    public itESservice: ItemTrackingESService,
    private ngZone: NgZone, private toaster: ToastrService) {
      this.userId_val = this.userId.userDetails.email;

    this.itemTrak.dropdownValue = '';
    this.itemTrak.ref = null;
    this.multiSelectDropdown();
    this.getMappingList();

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 10
    };
  }
  ngOnInit() {
    this.getMappingList();
    this.displayItemTracking();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getSelectedRegion(this.selectedRegions);
    this.getSelectedTeam(this.selectedTeams);
    this.getSelectedCustomer(this.selectedCustomers);
    this.getSelectedPlant(this.selectedPlants);
    this.displayItemTracking();
  }
  multiSelectDropdown() {
    this.regiondropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.teamdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.customerdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.plantdropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  getMappingList() {
    this.itESservice.getFiltersItemTrackingES(this.userId_val).subscribe(filtersRes => {
      let filterdata = filtersRes["data"];
      this.getFilterRegion(filterdata);
      this.getFilterTeam(filterdata);
    });
  }
  getFilterRegion(setRegion) {
    this.reportDropdownMappingRegion = Object.keys(setRegion);
  }
  getFilterTeam(setTeam) {
    this.reportDropdownMappingRegion.forEach(element => {
      setTeam[element].forEach((team, index) => {
        let currentTeamName = Object.keys(setTeam[element][index])[0];
        let Tkey = team[currentTeamName]
        this.reportDropdownMappingTeam.push(currentTeamName);
        for (let t = 0; t < Tkey.length; t++) {
          let Ckey = Object.keys(Tkey[t]);
          this.getFilterCustomer(Ckey, Tkey[t]);
        }
      });
    });
  }

  getFilterCustomer(Ckey, Tkey) {
    Ckey.forEach((customer, cindex) => {
      this.reportDropdownMappingCustomer.push(customer);
    });
    for (let t = 0; t < Ckey.length; t++) {
      this.getFilterPlant(Ckey, Tkey);
    }
  }
  getFilterPlant(Ckey, teamkey) {
    for (let p = 0; p < teamkey[Ckey].length; p++) {
      this.reportDropdownMappingPlant.push(teamkey[Ckey][p]);
    }
  }




  displayItemTracking() {
 
    if (this.itemTracker === 1) {
      this.ites = true;
    }
  }
  async getStages() {
    const url = 'assets/stages.json';
    try {
      this.stages = await this.httpService.sendGetRequest(url);
    } catch (e) {
      console.log(e);
    }
  }
  async generateReports(isPageChange = false, isGenerateReport = false) {
    const url = 'https://analysis.moglix.com/' + AppConstants.reportsURL;
    let body: any = {};
    const sendDates = this.doSendDates();
    if (isPageChange) {
      body = this.previousQuery;
    } else {
      if (isGenerateReport && this.validateInputs()) {
        return;
      }
      body = {
        analyze_format:this.analyze_format,
        username:this.userId_val,
        start_date: sendDates ? this.getDateFormat(this.itemTrak.dateRange[0]) : null,
        end_date: sendDates ? this.getDateFormat(this.itemTrak.dateRange[1]) : null,
        input_data: {
          item_ref: this.itemTrak.dropdownValue === 'ITEMREF' ? [this.itemTrak.ref] : null,
          order_id: this.itemTrak.dropdownValue === 'ORDERID' ? [this.itemTrak.ref] : null,
          plant_id: this.itemTrak.dropdownValue === 'PLANTID' ? [this.itemTrak.ref] : null,
          customer_po_no: this.itemTrak.dropdownValue === 'CUSTOMERPONO' ? [this.itemTrak.ref] : null,
          region: this.itemTrak.dropdownValue === 'REGION' ? this.selectedRegions : null,
          team_name: this.itemTrak.dropdownValue === 'TEAMNAME' ? this.selectedTeams : null,
          customer_name: this.itemTrak.dropdownValue === 'CUSTOMER' ? this.selectedCustomers : null,
          plant_name: this.itemTrak.dropdownValue === 'PLANT' ? this.selectedPlants : null
        }
      };
      this.previousQuery = body;
    }
    body.page_number = this.config.currentPage;

    if(this.analyze_format){

      this.submitted =false;
    }
    else{
      this.submitted = true;
    }
    if(body!=null){
      try {
        const { data, total_pages, status, message } = await this.httpService.sendPostRequest(url, body);
        if (status && status.toLowerCase() === 'success') {
          this.formJsonForReports(data);
          this.config.totalItems = Number(total_pages) * 10;
        } else {
          this.toaster.error(message, 'Error');
          this.reportsData = [];
        }
      } catch (err) {
        console.log('ERROR from API', err);
        this.reportsData = [];
      }
    }

  }
  formJsonForReports(data) {
    const tableData = {};
    this.ngZone.runOutsideAngular(() => {
      Object.values(data).forEach((object: any) => {
        object.stage_data.forEach((items: any) => {
          items.forEach(item => {
            tableData[item.item_ref] = tableData[item.item_ref] || { itemRef: item.item_ref };
            tableData[item.item_ref].stageDetails = object.item_ref_property || {};
            tableData[item.item_ref][item.sub_stage_code] = tableData[item.item_ref][item.sub_stage_code] || [];
           
           if(this.analyze_format){
            tableData[item.item_ref][item.sub_stage_code].push({ stageTime: item.stage_time,stageCode: item.sub_stage_code });
            }
           else{  
             
             tableData[item.item_ref][item.sub_stage_code].push({ stageTime: item.stage_time, quantity: item.quantity, stageCode: item.sub_stage_code });
           
          }
            





            
          });
        });
      });
    });
    this.reportsData = Object.values(tableData);
  }
  pageChange(pageNumber: number) {
    this.config.currentPage = pageNumber;
    this.generateReports(true);
  }
  async onExport() {
    const url = 'https://analysis.moglix.com/' + AppConstants.exportURL;
    const sendDates = this.doSendDates();
    let body = {
      analyze_format:this.analyze_format,
      username:this.userId_val,
      start_date: sendDates ? this.getDateFormat(this.itemTrak.dateRange[0]) : null,
      end_date: sendDates ? this.getDateFormat(this.itemTrak.dateRange[1]) : null,
      input_data: {
        item_ref: this.itemTrak.dropdownValue === 'ITEMREF' ? [this.itemTrak.ref] : null,
        order_id: this.itemTrak.dropdownValue === 'ORDERID' ? [this.itemTrak.ref] : null,
        plant_id: this.itemTrak.dropdownValue === 'PLANTID' ? [this.itemTrak.ref] : null,
        customer_po_no: this.itemTrak.dropdownValue === 'CUSTOMERPONO' ? [this.itemTrak.ref] : null,
        region: this.itemTrak.dropdownValue === 'REGION' ? this.selectedRegions : null,
        team_name: this.itemTrak.dropdownValue === 'TEAMNAME' ? this.selectedTeams : null,
        customer_name: this.itemTrak.dropdownValue === 'CUSTOMER' ? this.selectedCustomers : null,
        plant_name: this.itemTrak.dropdownValue === 'PLANT' ? this.selectedPlants : null,
      }
    };

    const headers = {
      responseType: "blob",
      headers: new HttpHeaders().append("responseType", "text/csv; charset=utf-8")
    }
    try {
      const res: any = await this.httpService.sendPostRequest(url, body, headers);
      const xlData: any[] = [];
      res.data.forEach((element, i) => {
        xlData[i] = {};
        xlData[i]['Item'] = element['Item'];
        xlData[i]['Delivery Due Date'] = element['Delivery Due Date'];
        if(this.analyze_format){
          xlData[i]['Ordered Quantity'] = element['Ordered Quantity'];
          xlData[i]['Processed Quantity'] = element['Processed Quantity'];
        }
        xlData[i]['CPN'] = element['CPN'];
        xlData[i]['Region'] = element['Region'];
        xlData[i]['Team Name'] = element['Team Name'];
        xlData[i]['Plant Name'] = element['Plant Name'];
        xlData[i]['Customer Name'] = element['Customer Name'];
        xlData[i]['Current Stage'] = element['Current Stage'];
        this.stages.forEach((stage: any) => {
          xlData[i][stage.value] = element[stage.value];
        });
      });
      this.exportAsExcelFile(xlData, 'test');
    } catch (e) {
      console.log('ERROR from API', e);
    }
    // window.saveAs
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    this.ngZone.runOutsideAngular(() => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '_';
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const name = 'item_tracking_' + date + time + '.xls'
      XLSX.writeFile(workbook, name, { bookType: 'xls', type: 'buffer' });
    });
  }

  showDatePicker() {
    return this.itemTrak.dropdownValue === 'PLANTID' || this.itemTrak.dropdownValue === 'TEAMNAME' ||
      this.itemTrak.dropdownValue === 'REGION' || this.itemTrak.dropdownValue === 'CUSTOMER' || this.itemTrak.dropdownValue === 'PLANT';
  }
  showItemRef() {
    return this.itemTrak.dropdownValue == 'CUSTOMERPONO' ||
      this.itemTrak.dropdownValue == 'ORDERID' ||
      this.itemTrak.dropdownValue == 'ITEMREF';
  }
  showItemRefPlantID() {
    return this.itemTrak.dropdownValue == 'PLANTID';
  }

  doSendDates() {
    return (this.itemTrak.dropdownValue === 'PLANTID' || this.itemTrak.dropdownValue === 'TEAMNAME' ||
      this.itemTrak.dropdownValue === 'REGION' || this.itemTrak.dropdownValue === 'CUSTOMER' || this.itemTrak.dropdownValue === 'PLANT');
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    console.log(date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate()));
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  validateInputs() {

    if ((!this.itemTrak.dropdownValue || !this.selectedRegions || !this.selectedTeams || !this.selectedCustomers || !this.selectedPlants)) {
      const status = !this.itemTrak.dropdownValue || !this.selectedRegions || !this.selectedTeams || !this.selectedCustomers || !this.selectedPlants;
    }
    if ((!this.itemTrak.dropdownValue && !this.itemTrak.ref)) {
      const status = !this.itemTrak.dropdownValue || !this.itemTrak.ref;
    }

    if (this.doSendDates()) {
      return !this.itemTrak.dateRange || this.itemTrak.dateRange.length < 2 || status
    }
    return status;
  }
  async onChange() {
    if (this.itemTrak.dropdownValue === 'PLANTID' || this.itemTrak.dropdownValue === 'REGION' || this.itemTrak.dropdownValue === 'TEAMNAME'
      || this.itemTrak.dropdownValue === 'CUSTOMER' || this.itemTrak.dropdownValue === 'PLANT') {
      const url = 'https://analysis.moglix.com/' + AppConstants.dateLimitURL;
      try {
        const res: any = await this.httpService.sendGetRequest(url);
        this.dateRanges.start_date = new Date(res.data.start_date);
        this.dateRanges.end_date = new Date(res.data.end_date);
      } catch (e) {
        console.log('ERROR from API', e);
      }
    }
  }









  getSelectedRegion(selectedRegions) {
    this.selectedRegions = selectedRegions;
    console.log(this.selectedRegions);
  }
  onRegionSelect(item: string) {
    this.getSelectedRegion(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.reportDropdownMappingRegion;
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getFilterRegion(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedRegions = [];
  }




  getSelectedTeam(selectedTeams) {
    this.selectedTeams = selectedTeams;
    console.log(this.getSelectedTeam);

  }
  onTeamSelect(item: string) {

    this.getSelectedTeam(this.selectedTeams);
  }
  onTeamsAll(items: any) {
    this.selectedTeams = this.reportDropdownMappingTeam;
  }
  onTeamDeSelect(deselectedSID: any): void {
    this.selectedTeams = this.selectedTeams.filter(s => s != deselectedSID);
    this.selectedTeams.forEach(sid => { this.onTeamSelect(sid); });
  }
  onTeamsDeSelectAll(items: any) {
    this.selectedTeams = [];
  }





  getSelectedCustomer(selectedCustomers) {

  }
  onCustomerSelect(item: string) {
    this.getSelectedCustomer(this.selectedCustomers)
  }
  onCustomersAll(items: any) {
    this.selectedCustomers = this.reportDropdownMappingCustomer;
  }
  onCustomerDeSelect(deselectedSID: any): void {
    this.selectedCustomers = this.selectedCustomers.filter(s => s != deselectedSID);
    this.selectedCustomers.forEach(sid => { this.onCustomerSelect(sid); });

  }
  onCustomersDeSelectAll(items: any) {
    this.selectedCustomers = [];
  }


  getSelectedPlant(selectedPlants) {

  }
  onPlantSelect(item: string) {
    this.getSelectedCustomer(this.selectedPlants)
  }
  onPlantsAll(items: any) {
    this.selectedPlants = this.reportDropdownMappingPlant;
  }
  onPlantDeSelect(deselectedSID: any): void {
    this.selectedPlants = this.selectedPlants.filter(s => s != deselectedSID);
    this.selectedPlants.forEach(sid => { this.onPlantSelect(sid); });

  }
  onPlantsDeSelectAll(items: any) {
    this.selectedPlants = [];
  }
  analyseStatus(){
    this.analyze_format = !this.analyze_format;
    if(this.analyze_format){

      
      this.generateReports(false,false);
    }
    else{
      this.generateReports(false,false);
      
    }
  }
  
}
