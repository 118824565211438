import { CommonService } from './../../shared/common.service';
import { formatDate } from 'ngx-bootstrap/chronos/format';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/services/app.service';
import { BuUploadService } from 'src/app/services/bu-upload.service';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';

import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { CpnMsnService } from 'src/app/services/cpn-msn.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @ViewChild('completedFiles') completedFiles: ElementRef<HTMLElement>;
  @ViewChild('pendingFiles') pendingFiles: ElementRef<HTMLElement>;
  reportName = '';
  customer_Id: any;

  arrayBuffer: any;
  email_id: any;
  file: any;
  userId_val: any;
  filename: any;

  previewDataListSrcArr = [];
  previewDataList: unknown[];
  isValidHeader: any;
  isValidUsers: any;
  isUploadProcess: boolean;
  message: any;
  headers: string[] = ["CPN", "CPNDesc"]

  dateStatus: boolean;
  visualTable: boolean;
  dtOptions:any = {};
  finalDataObjectpickup: any;
  url: any;
  formData = new FormData();
  tableDisplay = false;
  smeFinanceObj: any;
  buttons = ['Pending', 'In Progress', 'Completed'];
  selectedBtn = 'Pending Files';
  tableData: any;
  dtOptions2: any;
  selected_suggestType: any;
  suggestType: any;
  constructor(private navbarTitle: NavbarComponent,
    private appService: AppService,
    private buUploadService: BuUploadService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private userId: AppService,
    private cpnService: CpnMsnService,
    private cdr: ChangeDetectorRef
  ) {

    this.reportName = this.navbarTitle.reportlabel;
    this.userId_val = this.userId.userDetails.email
    this.url = 'https://analysis.moglix.com/product_suggestion/customer/file/upload';
    this.formData = new FormData();
  }

  ngOnInit() {
    let selected = sessionStorage.getItem('selected') || 'Pending File';
    this.fetchData(selected);
    // this.calldetailsData(null);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('selected');
  }

  getSuggetsionType(event) {

    this.selected_suggestType = event.value


}
  refresh() {
    sessionStorage.setItem('selected', this.selectedBtn);
    if(this.selectedBtn == 'Pening File') {
      let el: HTMLElement = this.completedFiles.nativeElement;
      el.click();
    } else {
      let el: HTMLElement = this.pendingFiles.nativeElement;
      el.click();
    }
    this.selectedBtn = sessionStorage.getItem('selected');
    setTimeout(() => {
      if(this.selectedBtn == 'Pening File') {
        let el: HTMLElement = this.pendingFiles.nativeElement;
        el.click();
      } else {
        let el: HTMLElement = this.completedFiles.nativeElement;
        el.click();
      }
    }, 100)
    sessionStorage.setItem('selected', this.selectedBtn);
    this.ngOnInit();
  }

  fetchData(dataObj) {
    const url = "https://analysis.moglix.com/product_suggestion/consumer/dashboard/view";
    const payload = {
      email_id: this.appService.userDetails.email,
      page_number: "1",
      page_size: "1000"
    }
    this.cpnService.getTableData(url, payload).subscribe((resp: any) => {
      this.tableData = JSON.parse(JSON.stringify(resp));
      this.updateData(resp, dataObj);
    });
  }

  updateData(resp, dataObj) {
    this.dtOptions = {};
    this.tableDisplay = false;
    this.selectedBtn = dataObj;
    this.smeFinanceObj = {};
    const data = JSON.parse(JSON.stringify(this.cpnService.post(resp, 'Pending File')));
    const data2 = JSON.parse(JSON.stringify(this.cpnService.post(resp, 'Completed File')));
    if (data['table']) {
      this.dtOptions = JSON.parse(JSON.stringify(data['table']));
      if(this.dtOptions && this.dtOptions.data && this.dtOptions.data.length && this.selectedBtn == 'Pending File') {
        const sortedArray = [];
        this.dtOptions.data.forEach(each => {
          if(each['File Status'] == 'PRE-PROCESSING') {
            sortedArray.unshift(each);
          } else {
            sortedArray.push(each)
          }
        this.dtOptions.data = JSON.parse(JSON.stringify(sortedArray));
        });
      }
      this.dtOptions2 = JSON.parse(JSON.stringify(data2['table']));
      if(this.dtOptions2 && this.dtOptions2.data && this.dtOptions2.data.length && this.selectedBtn == 'Completed File' && Object.keys(this.dtOptions2.data[0]).length == 8) {
        this.dtOptions2.columns.forEach(each => {
          if(each['title'] == 'Download') {
            each['title'] = 'Download File';
            each['data'] = 'Download File';
          }
        });
        this.dtOptions2.data.forEach(each => {
          each['Download File'] = `<a href=${each['Download']}><img style="cursor: pointer; height: 30px; width: 30px; position: relative; left: 50px" src="../../../assets/icons/1_excelDownload.png"></a>`;
        });
      }
      console.log(this.dtOptions2)
      this.tableDisplay = true;
      this.cdr.detectChanges();
    }
    this.tableDisplay = true;
  }

  // calldetailsData(event) {
  //   var self = this;
  //   $('#filesData tbody').on('click', 'td', function () {
  //     let table = $('#filesData').DataTable();
  //     let idx = table.cell(this).index().column;
  //     let rowIdx = table.cell(this).index().row;
  //     let title = table.column(idx).header();
  //     this.selectedRowValue = table.cell(rowIdx, 0).data();
  //     this.selectedRowValue_TypeVal = table.cell(rowIdx, 1).data();
  //     this.selectedRowValue_Team_TypeVal = table.cell(rowIdx, 2).data();
  //     this.selectedRowValue_Team = table.cell(rowIdx, 1).data();
  //     this.selectedColumnValue = $(title).html();
  //     let cell: any = table.cell(this);
  //     if(this.selectedColumnValue == 'Download File'){
  //       const Downloadlink = this.dtOptions2.filter(each => each['Request Id'] == this.selectedRowValue);
  //       console.log(this.Downloadlink);
  //       var link = document.createElement("a");
  //       link.href = Downloadlink['Download'];
  //       link.setAttribute('visibility', 'hidden');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   });
  // }


  onSubmit = function (user) {
    let el: HTMLElement = this.completedFiles.nativeElement;
    el.click();
    console.log(user.customerName,user.suggestType)
    if(user.customerName && this.suggestType){
      this.formData.append("customer_name", user.customerName);
      this.formData.append("email_id", this.userId_val);
      this.formData.append("suggestType", user.suggestType);
      console.log(this.filename);
      console.log(this.formData)
      this.appService.getReport(this.url, this.formData).subscribe(res => {
        console.log(res);
        if (res['status'] == "SUCCESS") {
          this.commonService.showToast(res['message'], 1);
          this.ngOnInit();
          let reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };
          this.userId.saveInfo(environment.user_tracking_api, reportRequest).subscribe(reportdata => {
          })
          this.formData = new FormData();
        }
      });
    }
    else{
      this.commonService.showToast("Customer Name and Suggest Type is mandatory", 3);
    }
    
  }
  onFileChange(ev: File) {
    this.formData = new FormData();
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    this.filename = ev[0];

    this.formData.append('file', ev[0], this.filename['name']);
    if (this.filename != null) {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.filename);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for (let i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet: XLSX.WorkSheet = workbook.Sheets[first_sheet_name];
        if (worksheet["!ref"] == 'A1:K1') {
          alert('Enter the data')
        }
        else {
          this.validateUploadedExcel(worksheet, ev);
        }
      }
    }
  }
  validateUploadedExcel(worksheet, ev) {
    this.previewDataListSrcArr = XLSX.utils.sheet_to_json(worksheet, { defval: '', raw: false });
    this.previewDataList = XLSX.utils.sheet_to_json(worksheet, { defval: 0, raw: false });

    let srcArr: string[] = Object.keys(this.previewDataListSrcArr[0]);

    this.isValidHeader = this.validateHeaders(srcArr);
    if (this.isValidHeader) {
      this.formatUsersData(ev);
      if (this.isValidUsers) {
        this.isUploadProcess = true;
        this.previewData(this.previewDataList);
        this.spinner.hide();
      }
      else {
        this.deleteFile("#fileInput");
        this.spinner.hide();
      }
    }
    else {
      this.spinner.hide();
    }
  }


  setError(message) {
    this.isValidUsers = false;
    this.message = message;
  }
  formatUsersData(ev) {
    this.isValidUsers = true;
    for (let headIndex = 0; headIndex < this.headers.length; headIndex++) {
      for (let userIndex = 0; userIndex < this.previewDataListSrcArr.length; userIndex++) {
        let value: number | string | any = this.previewDataListSrcArr[userIndex][this.headers[headIndex]];
        let row = userIndex + 2;

      }

    }
    return this.isValidUsers;
  }

  dateValidations(value) {
    if (value.indexOf('/') > -1) {
      this.dateStatus = true;
      let res = value.split("/");
      let day = res[0];
      let month = res[1];
      let year = res[2];
      month = Number(month);
      day = Number(day);
      year = Number(year);
      if (month < 1 || month > 12) { // check month range
        this.dateStatus = false;
      }
      if (day < 1 || day > 31) {
        this.dateStatus = false;
      }
      if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
        this.dateStatus = false;
      }
      if (month == 2) { // check for february 29th
        let isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
        if (day > 29 || (day === 29 && !isleap)) {
          this.dateStatus = false;
        }
      }
    }
    else {
      this.dateStatus = false;
    }
    return this.dateStatus;
  }
  validateHeaders(srcArr: string[]): boolean {
    this.isValidHeader = true;
    let count = 0;

    let allFounded = this.headers.every(ai => srcArr.includes(ai));
    if (allFounded) {
      this.isValidHeader = true;
    }
    else {
      this.commonService.showToast("Not matching with the template", 3)
      this.isValidHeader = false;
    }

    return this.isValidHeader;
  }
  previewData(data) {
    this.visualTable = false;
    this.dtOptions = {};
    // this.finalDataObjectpickup = this.buUploadService.post(data);
    // this.dtOptions = this.finalDataObjectpickup.table;
    this.visualTable = true;
    // this.uploadData();
  }
  uploadData() {
    if (this.visualTable) {

      let requestBody = { "email": this.userId_val, data: this.previewDataList };
      this.appService.getReport(this.url, requestBody).subscribe(res => {
        if (res['status_code'] == "201") {
          this.commonService.showToast("Successfully Data Uploaded", 1);
          this.spinner.hide();
        }
        else {
          this.commonService.showToast(res['message'], 3);
          this.spinner.hide();
        }

      })

    }
  }
  deleteFile(element) {
    this.previewDataList = [];
    this.previewDataListSrcArr = [];
    this.visualTable = false;
    element.value = "";
  }
}
export interface Person {
  id: number;
  firstName: string;
  lastName: string;
}
