import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { AppService } from '../../services/app.service';
import * as Moment from 'moment';

import { extendMoment } from 'moment-range';
import { environment } from '../../../environments/environment';
import { UrlConfig } from '../../helpers/urlConfig';
import * as Highcharts from 'highcharts';
import { CommonService } from '../../shared/common.service';
import { MgmtScoreCardService } from 'src/app/services/mgmt-score-cardservice.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelfServeService } from 'src/app/services/self-serve.service';
//import { MgmtComponent } from 'src/app/mgmt-score-card/mgmt/mgmt.component';
import { take } from 'rxjs/operators';

import { NavbarComponent } from 'src/app/components/navbar/navbar.component';

import { MgmtService } from '../../services/mgmt.service';

const analytics_baseURL = environment.analytics_baseURL;
const moment = extendMoment(Moment);

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  reportName='';
  dtOptionspickup: DataTables.Settings = {};
  detailedReportStatus: boolean;
  dateval_mgmt_scorecard: string;
  dateval_mgmt_osod: string;
  sccorecard_mgmt_month: any;
  osod_mgmt_month: any;
  dtOptionsenable0: boolean;
  dtOptionsenable_1: boolean;
  finalDataObjectpickup = { table: {}, excel: [] };
  detailedfinalDataObjectpickup = { table: {}, excel: [] };

  finalDataObject = { table: {}, excel: [] };  
  finalDataObject2 = { table: {}, excel: [] };  
  excelData2 = [];  
  public selectedRowValue_TypeVal: string;
  public selectedRowValue_Team_TypeVal: string;
  public selectedColumnValue: string;
  public visualTable: any;
  public bsValue: any = new Date();
  public BarLine1Graph: any;
  public BarGraph1: any;
  public BarLine2Graph: any;
  public BarGraph2: any;
  public StackedGraph: any;
  public LineGraph: any;
  public bulletgraph: any;
  public discountData: any = [];
  public sparkLineList: any = {};
  public mapData: any = {};
  public datePickerConfig: any = { minMode: 'month', dateInputFormat: 'MMMM YYYY' };
  public minDate: any = new Date('2019-04-01');
  public maxDate: any = new Date();
  public deliveryList: any = [];
  public mapChartOptions: any = {};
  public baseURL_freq: any;
  tableCondition: boolean;
  dtOptionsenable: boolean;
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  regions:any = [];
  mngmt_data: any;
  selectedRegions = [];
  dropdownSettings = {};
  popUp :boolean;
  selectedRowValue:string;
  selectedColumnValues:any;
  userComment:string;
  commentForm: FormGroup;
  commentFormGroup: FormGroup;
  modalValue = 'inactive';
  userCommentList =[];
  selected=0;
  enterpriseMenuList=[
      { 'title':"Enterprise Dashboard", id:0},
      { 'title':"Summary  - Regions", id:5},
      { 'title':"Region-wise Enterprise", id:1},
      { 'title':"Team-wise Enterprise", id:2}
  ]

  userId_val: any;
  

// Tabular Region,Team,Plant
regionDropdownSettings = {};
teamDropdownSettings = {};
plantDropdownSettings = {};
// Tabular Selected Items
public selectedRegionsList= [];
public selectedTeamsList= [];
public selectedPlantsList= [];
// default List of Items
regionsList:any;
teamsList: any;
plantsList:any;
// obj list
TeamRegionsListObj: any;
PlantRegionsListObj: any;
//Final object to get the data
  regionfinalpostObj: {};
  teamfinalpostObj: {};
  plantfinalpostObj: {};

  requestBody ={};
  url: string;
  urlpath: string;
  filterType: number;
  detailedTable: boolean;
  section1Data: any;


  constructor(private navbarTitle: NavbarComponent,private mgmtService: MgmtService,private router: Router,private Service: SelfServeService,
    private userId: AppService,private _fb: FormBuilder,private appService: AppService, private commonService: CommonService, private mgmtScoreCardservice: MgmtScoreCardService,) {
    
      this.reportName = this.navbarTitle.reportlabel;
      this.userId_val = this.userId.userDetails.email;
   
  }

  async ngOnInit(): Promise<any>   {
    this.regions =JSON.parse(localStorage.getItem('regions'));
   
    this.selectedRegions = this.regions;
    this.selectedRegionsList=this.appService.selectedRegionsList;
    this.regionsList=this.appService.regionsList
    if(this.regions){
      this.multiSelectDropdown();
    
      this.getDataByFilter(new Date());
     
      this.commentFormGroup = new FormGroup({
        userComment: new FormControl('')
      });
    }
 
  }
 
  multiSelectDropdown() {
    console.log("multiple")
    this.regions = JSON.parse(localStorage.getItem('regions'));
    this.selectedRegions = this.regions;
   
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.regionDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.teamDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
    this.plantDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2
    };
  }
  onSubmit({ value, valid }: { value: UserComment, valid: boolean }) {
  
    this.userCommentList.push(value.userComment);
  }
  getCommentForm(){
    this.commentForm = this._fb.group({
      userComment: new FormControl('')
    });
  }
  saveComments(){
    this.commentForm.value.userComment;
  }
  close(){
    this.modalValue ='';
    this.modalValue = 'inactive';
    this.popUp = false;

  }
 
  currentactiveTab = 1;
  select(e_menu_id) {
    this.selected = e_menu_id; 
};
isActive(e_menu_id) {
    return this.selected === e_menu_id;
};


  getDataByFilter(date = this.bsValue) {
    this.visualTable = false;
    if (this.selectedRegions && this.selectedRegions.length) {
      const selectedDate = moment(date).format('YYYY-MM');
      this.getGraphsData(selectedDate);
      // this.getMapData(selectedDate);

      let reportRequest = {'username':this.userId_val,'report_name':this.reportName};
      this.userId.saveInfo(environment.user_tracking_api,reportRequest).subscribe(reportdata=>{
        console.log(reportdata);
      })

    } else {
      this.commonService.showToast('Please select regions', 3)
    }
  }

  getGraphsData(date) {
    this.sparkLineList = {};
    //const url = environment.analyticsapi_baseURL_highcharts + UrlConfig.getGraphAPI;
    const urlSalesCards = environment.baseURL_FREQ+'sales_cards';
    const urlInvoice  = environment.baseURL_FREQ+'invoice_and_returns';
    const urllogistics_and_cm = environment.baseURL_FREQ+'logistics_and_cm';
    const urlteam_gmv_and_gm = environment.baseURL_FREQ + 'team_gmv_and_gm';
    const urlteam_wise_sales_data =  environment.baseURL_FREQ + 'team_wise_sales_data';

    const body: any = { filterDate: date, selectedRegions: this.selectedRegions };
    this.appService.sendPostRequest(urlSalesCards, body).subscribe(res => {
      this.discountData = res.data || [];
      this.section1Data =  this.discountData.section1;
      this.generatebulletgraph(this.discountData.section1[0]);
      console.log(res)
     
    });
    this.appService.sendPostRequest(urlInvoice, body).subscribe(res => {
      this.discountData = res.data || [];
     
      this.generateBarLine1Graph(this.discountData.section2);
      this.generateBarGraph1(this.discountData.section2);

    });

    this.appService.sendPostRequest(urllogistics_and_cm, body).subscribe(res => {
      this.discountData = res.data || [];
      console.log("After section2 - 3",this.discountData.section1)
      this.generateBarLine2Graph(this.discountData.section3);
      this.generateBarGraph2(this.discountData.section3);



    });

    this.appService.sendPostRequest(urlteam_wise_sales_data, body).subscribe(res => {
      this.discountData = res.data || [];

      this.generateStackedGraph(this.discountData.section4);
      this.generateLineGraph(this.discountData.section4);


    });
    
   

    this.appService.sendPostRequest(urlteam_gmv_and_gm, body).subscribe(res => {
      this.discountData = res.data || [];
      if (res.data.section6) {
        this.sparkLineList = res.data.section6 || {};
      }

    });
  }
  
  
  getvisualTable() {
    this.detailedTable =false;
    this.filterType = 1;
    this.visualTable = false;
    this.tableCondition = false;
    this.dtOptions = {};
    this.mngmt_data = {};
    this.requestBody = {};
    this.regionfinalpostObj = { "region": this.selectedRegionsList };
    this.teamfinalpostObj = {'team':this.selectedTeamsList};   
    this.plantfinalpostObj = {'region': this.selectedRegionsList,'team':this.selectedTeamsList,'plant':this.selectedPlantsList};

    this.baseURL_freq = environment.baseURL_FREQ;
    switch (this.selected) {

      case 1: {
        this.url = 'sale_summary';
        this.finalData(this.url,this.regionfinalpostObj);
        break;
      }
      case 2: {
        this.url = 'sale_summary_teamwise';
        this.finalData(this.url,this.teamfinalpostObj);
        break;
      }
      case 3: {
        this.url = 'sale_summary_plantwise';
        this.finalData(this.url,this.plantfinalpostObj);
        break;
      }
    }

  }
  finalData(urlpath,finalObj){
    this.requestBody =finalObj;
    this.appService.sendPostRequest(this.baseURL_freq+urlpath, this.requestBody).subscribe(res => {
      this.mngmt_data = this.mgmtScoreCardservice.post(res);
      if (this.mngmt_data) {
        this.dtOptions = this.mngmt_data.table;
        this.dtOptionsenable = true;
        if (this.dtOptions != null) {
          this.tableCondition = true;
          this.visualTable = true;
        }
      }
    });
  }

  commentMgmt(){
    console.log(this.commentForm)
  }
  
  getMapData(selectedDate) {
    this.mapData = {};
    const url = environment.analyticsapi_baseURL_highcharts + UrlConfig.getMapDataAPI;
    const body: any = { filterDate: selectedDate, selectedRegions: this.selectedRegions };
    this.appService.sendPostRequest(url, body).subscribe(res => {
      this.mapData = res.data;
    });
  }
  generateBarLine1Graph(data) {
    const monthsList = [];
    data.InvoiceValue.xAxis.forEach(function (obj) {
      monthsList.push(moment(obj.slice(5), 'MM').format('MMM'));
    });
    if (monthsList && monthsList.length > 0) {
      const options: any = {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: 'Invoice value',
          align: 'center'
        },
        margin: [5, 0, 10, 0],
        subtitle: {
          text: ''
        },
        xAxis: [{
          categories: monthsList,
          crosshair: true,
          index: 1,
        }],
        yAxis: [{ // Primary yAxis
          gridLineWidth: 0,
          labels: {
            format: '{value}%',
            style: {},
            enabled: true
          },
          title: {
            text: '',
            style: {}
          },
          opposite: true,
          // height: '50%',
          min: 0

        }, { // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: '',
            style: {}
          },
          labels: {
            format: '{value}',
            style: {}
          },
          min: 0
          // top: '50%',
          // height: '50%'
        }],
        tooltip: {
          shared: true
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: function (event) {
                  if (event.target.localName === 'rect') {
                    alert('Clicked on Bar \n Booking Month: ' + this.category + ',     Booking Values (INR LAKHS): ' + this.y);
                  } else {
                    alert('Clicked on Line \n Booking Month: ' + this.category + ',    Target Achieved: ' + this.y + '%');
                  }

                }
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'Invoice value',
          type: 'column',
          color: '#4d79ff',
          yAxis: 1,
          data: data.InvoiceValue.yAxis1 || [],
          marker: {
            enabled: false
          },
          dashStyle: 'shortdot',
          tooltip: {
            valueSuffix: ' Lakhs (INR)'
          }

        }, {
          name: 'Target Achieved(%)',
          type: 'spline',
          color: '#ffa31a',
          data: data.InvoiceValue.yAxis2,
          tooltip: {
            valueSuffix: ' '
          }
        }]
      };
      this.BarLine1Graph = new Chart(options);
    }
  }

  generateBarGraph1(data) {
    const monthsList1 = [];
    data.ReturnedValue.xAxis.forEach(function (obj) {
      monthsList1.push(moment(obj.slice(5), 'MM').format('MMM'));
    });

    const chartOptions: any = {
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: 'Return value',
        align: 'center'

      },
      subtitle: {
        text: ''
      },
      xAxis: [{
        categories: monthsList1,
        crosshair: true,
        index: 1,
      }],
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {}
        },
        labels: {
          format: '{value}',
          style: {}
        },
        // linkedTo: 0,
        // top: '50%',
        // height: '50%',
        min: 0

      }],
      tooltip: {
        shared: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function (event) {

                if (event.target.localName === 'rect') {
                  alert('Clicked on Bar \n Booking Month: ' + this.category + ',     Returns (INR): ' + this.y);
                } else {
                  alert('Clicked on Line \n Booking Month: ' + this.category + ',    Return (%): ' + this.y + '%');
                }

              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Return Value',
        type: 'column',
        yAxis: 1,
        data: data.ReturnedValue.yAxis1,
        marker: {
          enabled: false
        },
        dashStyle: 'shortdot',
        tooltip: {
          valueSuffix: ' Lakhs (INR)'
        }

      }, {
        name: 'Return (%)',
        type: 'spline',
        color: '#6771DB',
        data: data.ReturnedValue.yAxis2,
        tooltip: {
          valueSuffix: '  '
        }
      }]
    };
    this.BarGraph1 = new Chart(chartOptions);
  }


  generateBarLine2Graph(data) {
    const monthsList2 = [];
    data.GM_CMValue.xAxis.forEach(function (obj) {
      monthsList2.push(moment(obj.slice(5), 'MM').format('MMM'));
    });
    const options: any = {
      chart: {
        zoomType: 'xy'
      },
      title: {
        text: 'Margins',
        align: 'center'
      },
      subtitle: {
        text: ''
      },
      xAxis: [{
        categories: monthsList2,
        crosshair: true,
        index: 1,
      }],
      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {}
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%'

      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {}
        },
        labels: {
          format: '{value}',
          style: {}
        },
        // top: '50%',
        // height: '50%',
      }],
      tooltip: {
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function (event) {
                if (event.target.localName === 'rect') {
                  alert('Clicked on Bar \n Booking Month: ' + this.category + ',     Booking Values (INR): ' + this.y);
                } else {
                  alert('Clicked on Line \n Booking Month: ' + this.category + ',    Spline (%): ' + this.y + '%');
                }

              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'GM Value',
          type: 'column',
          color: '#c44dff',
          yAxis: 1,
          data: data.GM_CMValue.yAxis1.GM || [],
          marker: {
            enabled: false
          },
          dashStyle: 'shortdot',
          tooltip: {
            valueSuffix: ' Lakhs (INR)'
          }

        }, {
          name: 'CM1 Value',
          type: 'column',
          color: '',
          yAxis: 1,
          data: data.GM_CMValue.yAxis1.CM1,
          marker: {
            enabled: false
          },
          dashStyle: 'shortdot',
          tooltip: {
            valueSuffix: ' Lakhs (INR)'
          }

        }, {
          name: 'GM(%)',
          type: 'spline',
          color: '#ff471a',
          data: data.GM_CMValue.yAxis1.GMPercentage || [],
          tooltip: {
            valueSuffix: ' '
          }
        }, {
          name: 'CM1(%)',
          type: 'spline',
          data: data.GM_CMValue.yAxis1.CM1Percentage,
          tooltip: {
            valueSuffix: ' '
          }
        }]
    };
    this.BarLine2Graph = new Chart(options);
  }

  generateBarGraph2(data) {
    const monthsListb2 = [];
    data.LogisticValue.xAxis.forEach((obj) => {
      monthsListb2.push(moment(obj.slice(5), 'MM').format('MMM'));
    });

    const chartOptions: any = {
      title: {
        text: 'Logistic Cost',
        align: 'center'

      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: monthsListb2,
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: ''
        },
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                alert('X-Axis: ' + this.category + ', Y-Axis: ' + this.y);
              }
            }
          }
        }
      },
      series: [
        {
          type: 'column',
          name: 'Logistic Cost',
          color: 'rgb(22,153,221)',
          data: data.LogisticValue.yAxis1,
          tooltip: {
            valueSuffix: ' Lakhs (INR)'
          }
        }
      ]
    };
    this.BarGraph2 = new Chart(chartOptions);
  }

  /* Invoiced Graphs start here */

  generateStackedGraph(data) {
    console.log(data)
  
    const invoice = data.Invoice_Stacked;
    const options: any = {
      chart: {
        type: 'column',

      },
      title: {
        text: 'Invoice Value',
        align: 'center'
      },
      xAxis: {
        categories: invoice.xAxis,
        title: {
          text: ''
        }
      },
      yAxis: {
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          enabled: false
        },
        title: {
          text: ''
        },
        min: 0
      },
      credits: {
        enabled: false
      },
      tooltip: {
        //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        pointFormatter: function () {
          const name = this.series.name;
          let suffix = ' Lakhs (INR) ';
          if(this.category == 'UAE') {
            suffix = ' AED (UAE) '
          }
          let percentage = 0;
          if (name === 'Target') {
            percentage = invoice.yAxis.gmv[this.series.data.indexOf(this)];
          } else if (name === 'Trending') {
            percentage = invoice.yAxis.returns[this.series.data.indexOf(this)];
          } else if (name === 'Actual') {
            percentage = invoice.yAxis.net_revenue[this.series.data.indexOf(this)];
          }
          return '<span style="color: ' + this.series.color + '">' + name + '</span>: <b>' + this.y + suffix +'</b> (' + percentage + '%)<br/>';
        },
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'percent'
        },
        series: {
          cursor: 'pointer',
          point: {}
        }
      },
      series: [
        {
          name: 'GMV',
          color: '#E94033',
          data: invoice.yAxis.gmv,
        },
        {
          name: 'Returns',
          color: '#64cc84',
          data: invoice.yAxis.returns,
        }, {
          name: 'Net Revenue',
          color: '#359EFB',
          data: invoice.yAxis.net_revenue,
        }]
    };

    this.StackedGraph = new Chart(options);
  }

  generateLineGraph(data) {
    
    const lineGraphData = data.GM_CMValue || {};
    const options: any = {
      chart: {
        type: 'areaspline'
      },
      title: {
        text: ' Margins',
        align: 'center'
      },

      subtitle: {
        text: ''
      },
      xAxis: {
        categories: lineGraphData.xAxis || [],

        title: {
          text: ''
        }
      },

      yAxis: {
        gridLineWidth: 0,
        labels: {
          format: '{value}%',

        },
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.2
        },
        series: {
          label: {
            connectorAllowed: false
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                alert('X-Axis: ' + this.category + ', Y-Axis: ' + this.y + '%');
              }
            }
          }

        }
      },

      series: [{
        name: 'GM (%)',
        data: lineGraphData.yAxis1 || []
      },
      {
        name: 'CM1 (%)',
        data: lineGraphData.yAxis2 || []
      }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    };
    this.LineGraph = new Chart(options);
  }

  /* Invoiced Graphs end here */

  /* Bullet Graph  */
  generatebulletgraph(data) {
    const options: any = {
      chart: {
        inverted: true,
        type: 'bullet'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: ['<span style="font-size: 13px;font-weight: bold;">Invoice Value']
      },
      yAxis: {
        plotBands: [{
          from: 0,
          to: data.value,
          color: '#edf0f2'
        }],
        title: null,
        gridLineWidth: 0,
        labels: {
          formatter: function () {
            // if ( this.value > 1000 ) return Highcharts.numberFormat( this.value/1000, 1) + "K";  // maybe only switch if > 1000
            return Highcharts.numberFormat(this.value, 0);
          }
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0.25,
          borderWidth: 0,
          color: '#4A708B',
          targetOptions: {
            width: data.target
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
        data: [{
          name: 'Invoice Value (INR IN LAKHS)',
          y: data.value,
          target: data.target
        }]
      }],
      tooltip: {
        pointFormat: '<b>{point.y}</b> (with target at {point.target})'
      }
    };
    this.bulletgraph = new Chart(options);
  }

  setRowColumnvalues = (cell:string,r: string, c: string) => {  
   
    let requestObj = {region:r,YM:c}
    let teamObj = {team:r,YM:c}

    if(this.selected == 1 && cell =="Achieved (Lakhs)"){
      this.urlpath = "revenue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Return Sales (Lakhs)"){
      this.urlpath = "returns_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }

    if(this.selected == 1 && cell =="COGS excluding Tax (Lakhs)"){
      this.urlpath = "cogs_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Overdue (Lakhs)"){
      this.urlpath = "overdue_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }
    if(this.selected == 1 && cell =="Overdue > 30 Days (Lakhs)"){
      this.urlpath = "overdue_gr30days_detailed_region"
      this.displayTable(this.urlpath, requestObj);
    }


    if(this.selected == 2 && cell =="Achieved (Lakhs)"){
      this.urlpath = "revenue_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Return Sales (Lakhs)"){
      this.urlpath = "returns_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }

    if(this.selected == 2 && cell =="COGS excluding Tax (Lakhs)"){
      this.urlpath = "cogs_detailed_team";
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Overdue (Lakhs)"){
      this.urlpath = "overdue_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
    if(this.selected == 2 && cell =="Overdue > 30 Days (Lakhs)"){
      this.urlpath = "overdue_gr30days_detailed_team"
      this.displayTable(this.urlpath, teamObj);
    }
  }
  backButton() {
    this.tableCondition = !this.tableCondition;
    this.dtOptionsenable = !this.dtOptionsenable;
    this.detailedTable = !this.detailedTable;
    this.filterType =1;
  }
  displayTable(path:String,requestObj:any){
    this.tableCondition = false;
    

    this.dtOptionsenable = false;
   this.detailedTable = false;
   
      this.mgmtScoreCardservice.saveInfo(path, requestObj).subscribe(
        (response) => {
          this.filterType = 2;       
          this.finalDataObject2 = this.mgmtScoreCardservice.post(response);
          this.dtOptions2 = this.finalDataObject2.table;
          this.excelData2 = this.finalDataObject2.excel;
          this.detailedTable = true;
        }
      )
   
  }

  calldetailsData(event) {
    var self = this;
    $('#example tbody').on('click', 'td', function () {
      let table = $('#example').DataTable();
      let idx = table.cell(this).index().column;
      let rowIdx = table.cell(this).index().row;
      let title = table.column(idx).header();
      this.selectedRowValue = table.cell(rowIdx, 0).data();
      this.selectedRowValue_TypeVal = table.cell(rowIdx, 1).data();
      this.selectedRowValue_Team_TypeVal = table.cell(rowIdx, 2).data();
      this.selectedRowValue_Team = table.cell(rowIdx, 1).data();
      this.selectedColumnValue = $(title).html();
   
      let cell = table.cell( this );

      if(this.selectedRowValue_TypeVal == "Achieved (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }

      if(this.selectedRowValue_TypeVal == "Return Sales (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }  
       if(this.selectedRowValue_TypeVal == "COGS excluding Tax (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      } 
       if(this.selectedRowValue_TypeVal == "Overdue (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }
      if(this.selectedRowValue_TypeVal == "Overdue > 30 Days (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_TypeVal,this.selectedRowValue, this.selectedColumnValue);
      }




      if(this.selectedRowValue_Team_TypeVal == "Achieved (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "Return Sales (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }

      if(this.selectedRowValue_Team_TypeVal == "COGS excluding Tax (Lakhs)"){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
            if(this.selectedRowValue_Team_TypeVal == "Overdue (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }
      if(this.selectedRowValue_Team_TypeVal == "Overdue > 30 Days (Lakhs)" ){
        self.setRowColumnvalues(this.selectedRowValue_Team_TypeVal,this.selectedRowValue_Team, this.selectedColumnValue);
      }


    });
  }

  onRegionSelect(item: string) {
 
   }
   onRegionsAll(items: any) {
     this.selectedRegionsList = this.regionsList;
     
   }
   onRegionDeSelect(deselectedSID: any): void {
       this.selectedRegionsList = this.selectedRegionsList.filter(s => s != deselectedSID);
       this.selectedRegionsList.forEach(sid => {this.onRegionSelect(sid);});
   }
   onRegionsDeSelectAll(items: any){
     this.selectedTeamsList= [];
     this.teamsList = [];
   }
  exportAsXLSX(): void {
    this.Service.exportAsExcelFile(this.excelData2, 'Enterprise');
  }
  PendencyTables(val) {
    this.dtOptionsenable0=   false;
    this.dtOptionsenable_1= false;
    switch (val){
      case 0: {
        this.getPendencyTables(environment.baseURL_FREQ+"overall_mgm_score/get_mgm_score", -1);
        this.getPendencyTables(environment.baseURL_FREQ+"overall_mgm_score/get_os_od_overall", 0);
        break;
      }
    }
  }
  



  getCurrentRegionsTeams(selectedRegionsList){

} 
  getPendencyTables(urlpath,val:any) {

    if (val == -1) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
        this.dateval_mgmt_scorecard = response["dateval"];
        this.sccorecard_mgmt_month = response["scorecard_date"];
        

        this.mgmtService.setmgmt_scorecard_Month(this.sccorecard_mgmt_month);
          this.finalDataObjectpickup = this.mgmtService.postpickup(response, val);
         this.dtOptionsenable0= true;
          if (this.finalDataObjectpickup.table) {
            this.dtOptionspickup = this.finalDataObjectpickup.table;
          }
        }
      )
    }
    
    if (val == 0) {
      this.mgmtService.saveInfo(urlpath).subscribe(
        (response) => {
          this.dateval_mgmt_osod = response["dateval"];
          this.osod_mgmt_month = response["scorecard_date"];
          this.mgmtService.setmgmt_osod_Month(this.osod_mgmt_month);
          this.finalDataObject = this.mgmtService.post(response, val);
     

          this.dtOptionsenable_1=   true;
          if (this.finalDataObject.table) {
            this.dtOptions = this.finalDataObject.table;
          }
        }
      )
    }
  }
}
export interface UserComment {
  userComment: string;
}
