import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'angularx-social-login';
import { CommonService } from '../shared/common.service';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { UrlConfig } from '../helpers/urlConfig';

import { SelfServeService } from './self-serve.service';
import { S3ReportService } from './s3-report.service';
@Injectable({
  providedIn: 'root'
})
export class AppService {
    s3DataObj: { table: {}; excel: any[]; }; 
  tableDisplay:boolean;
  dtOptions: DataTables.Settings = {};
  excelData2 = []; 
  
  public selections: any;
  public userDetails: any;
  L1Title: any;
  L2Title: any;
  L3Title: any;
  teamsList: any[];
  selectedTeamsList: any[];
  TeamRegionsListObj: any;
  selectedRegionsList: string[];
  regionsList: string[];
  menuList =[];
  s3Link: any;
  constructor(private http: HttpClient,
    private router: Router,
    public exportexcelservice:SelfServeService,
    public s3ReportService:S3ReportService,
    private socialAuthService: AuthService,
    private commonService: CommonService) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
  }


  getS3Link(){
    return this.s3Link;
  }
  postS3Link(url){
    this.s3Link = url;
  }



  sendGetRequest(url): any {
    if (localStorage.getItem('authUser')) {
      const userToken = localStorage.getItem('authUser').replace(/"/g, '');

      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
      return this.http.get(url, { withCredentials: true, headers });
    }
  }
  getData(url) {
    return this.http.get(url);
  }

  sendPostRequest(url, body): any {
    this.selections = body;

    if (localStorage.getItem('authUser')) {
      const userToken = localStorage.getItem('authUser').replace(/"/g, '');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
      return this.http.post(url, body, { withCredentials: true, headers });
    }
  }
  saveInfo(urlpath, data) {
    return this.http.post(urlpath, data);
  }

    login(url, data)  {
    return this.http.post(url, data).pipe(map((res: any) => {
     
      localStorage.setItem('userDetails', JSON.stringify(res.data || {}));
      const token: any = res.data.accessToken.replace(/"/g, '');
      localStorage.setItem('authUser', JSON.stringify(token));
     // this.getdefaultRegionsList();
      
      this.userDetails = res.data || {};
      const userName = localStorage.getItem('authUser');
         if ((this.userDetails.email !== undefined )) {
        this.router.navigate(['/graphs']);
      }
      // if (this.userDetails.email !== undefined) {
        

      //   var splitUrl = this.getS3Link().split('/');
      //   console.log(splitUrl)
      //   let reportFolder: any
      //   let reportSubFolder: any
      //   let fileName: any
      //   if (splitUrl.length == 4) {
      //     reportFolder = splitUrl[2]
      //     fileName = splitUrl[3]
      //   }
      //   if (splitUrl.length == 5) {
      //     reportFolder = splitUrl[2]
      //     reportSubFolder = splitUrl[3]

      //     fileName = splitUrl[4]

      //   }
      //   debugger
      //   let payload = {
      //     "username": this.userDetails.email ? this.userDetails.email : null,
      //     "reportFolder": reportFolder ? reportFolder : null,
      //     "reportSubFolder": reportSubFolder ? reportSubFolder : null,
      //     "fileName": fileName || ''
      //   }
      //   this.sendPostRequest(environment.baseURL_FREQ+'s3Link', payload).subscribe(res => {
      //     this.s3DataObj = this.s3ReportService.post(res);
      //     this.tableDisplay = false;
        
      //       if (this.s3DataObj['table']) {
      //         this.dtOptions = this.s3DataObj['table'];
      //       this.excelData2 = this.s3DataObj['excel'];
      //         this.tableDisplay=true;
      //         this.exportexcelservice.exportAsExcelFile(this.excelData2, (reportFolder ? reportFolder : null + reportSubFolder ? reportSubFolder : - +fileName));
      //         this.logout();
      //       }

         
          
      //     //   const blob = new Blob([res.blob()], { type : 'application/vnd.ms.excel' });
      //     //   const file = new File([blob], fileName + '.xlsx', { type: 'application/vnd.ms.excel' });
      //     //   saveAs(file);
      
      //     // this.router.navigate([this.s3Link]);
      //     //this.downloadFile(res);
          
          

      //   })
      // }
      if(this.userDetails.email === undefined) {
        this.router.navigate(['/login']);
      }
      return res;
    }));

  }

  logout() {
    this.socialAuthService.signOut();
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  getUserDetails(field) {
    return this.userDetails[field];
  }


  getTeamRegion(teamRegionURL, TeamRegionList) {
    sessionStorage.clear();
    if(this.userDetails['email']){
      return this.http.post(environment.baseURL_FREQ+UrlConfig.accessMapping, {username:  this.userDetails['email']});
    }
    
  }
  setMenuList(menuListArray){
    this.menuList = menuListArray;
  }
  getMenuList(){
    return this.menuList;
  }
  getSessionData(url, username) {
    
    return this.http.post(url, { "username": username });
  }
 async getdefaultRegionsList() {
   if(this.userDetails['email']){

    this.getTeamRegion(environment.baseURL_FREQ+UrlConfig.accessMapping,{username:  this.userDetails['email']}).subscribe(
      (response) => {
        this.TeamRegionsListObj = response["data"][0];
        this.selectedRegionsList=Object.keys(response['data'][0]);
        this.regionsList=Object.keys(response['data'][0]);  
        this.getCurrentRegionsTeams(this.selectedRegionsList);
          localStorage.setItem('regions',JSON.stringify(this.regionsList));
        console.log(this.regionsList)
      });
     return this.regionsList
   }
  }
  getCurrentRegionsTeams(selectedRegionsList) {
    this.teamsList = [];
    this.selectedTeamsList = [];
    for (let j = 0; j < selectedRegionsList.length; j++) {
      this.getKey(selectedRegionsList[j]);
    }
  }
  getKey(key) {
    this.TeamRegionsListObj[key].forEach((team, index) => {
      this.selectedTeamsList.push(team);
      this.teamsList.push(team);
    });
  }

  setL1L2(L1Title, L2Title, L1, L2) {

    this.L1Title = L1Title;
    this.L2Title = L2Title;
    localStorage.setItem('L1Title', this.L1Title);
    localStorage.setItem('L2Title', ">" + this.L2Title);
    localStorage.setItem('L1Idx', L1);
    localStorage.setItem('L2Idx', L2);

    localStorage.removeItem('L3Title');
    localStorage.removeItem('L3Idx');


  }
  setL1L2L3(L1Title, L2Title, L3Title, L1, L2, L3) {
   
    this.L1Title = L1Title;
    this.L2Title = L2Title;
    this.L3Title = L2Title;
    localStorage.setItem('L1Title', this.L1Title);
    localStorage.setItem('L2Title', ">" + this.L2Title);
    localStorage.setItem('L3Title', ">" + this.L3Title);
    localStorage.setItem('L1Idx', L1);
    localStorage.setItem('L2Idx', L2);
    localStorage.setItem('L3Idx', L3);
  }

  getMenuDataAPI(url,userName){
    return this.http.post(url, { "username": userName });
  }
  getReport(url,requestBody){
    return this.http.post(url, requestBody);
  }


  getDownloadLink(id) {
    // Specify 'text' as the responseType
    return this.http.get(`https://analytics-web.moglix.com/freq_report/download_desc?meta_data_id=${id}`, { responseType: 'text' });
  }
}
