import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advance-po-finance',
  templateUrl: './advance-po-finance.component.html',
  styleUrls: ['./advance-po-finance.component.css']
})
export class AdvancePOFinanceComponent implements OnInit {
  url = "";

  dateRanges = { start_date: new Date(), end_date: new Date() };
  usernameVal: string;
  requestBody = {};
  dateRange: any; uploadForm: FormGroup;
  download_pin: any;
  download: boolean
  constructor(public appService: AppService, public fb: FormBuilder, private formBuilder: FormBuilder, private httpClient: HttpClient) {
    this.usernameVal = this.appService.userDetails.email;
    this.uploadForm = this.formBuilder.group({
      start_date: "",
      end_date: ""
    });
  }

  ngOnInit() {
  }
  async generateReports(isPageChange = false, isGenerateReport = false) {
    this.download = false;
    this.download_pin = "";
    this.requestBody = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1])
    };
    this.url = environment.baseURL_FREQ+"generate";
    const formData = new FormData()
    let start = this.getDateFormat(this.dateRange[0]);
    let end = this.getDateFormat(this.dateRange[1]);
    console.log(start, end)
    formData.append('start_date', start);
    formData.append('end_date', end);
    this.httpClient.post<any>(this.url, formData).subscribe(res => {
      if (res['status_code'] == 200) {
        this.download_pin = environment.baseURL_FREQ + res['path'];
        this.download = true;
      }
    })
  }
  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    console.log(date);
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let dateVal: string = [year, month, day].join('-');
    return dateVal;
  }
}

