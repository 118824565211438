import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core'

import { AppService } from 'src/app/services/app.service';
import { PodTrackerService } from 'src/app/services/pod-tracker.service';

import { SelfServeService } from 'src/app/services/self-serve.service';

import * as Highcharts from 'highcharts';

import { Chart } from 'angular-highcharts';
import { CommonService } from 'src/app/shared/common.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pod-tracker',
  templateUrl: './pod-tracker.component.html',
  styleUrls: ['./pod-tracker.component.css']
})
export class PodTrackerComponent implements OnInit {
  selectedTab = 'Summary';
  BarLine1Graph: any;
  BarLine1Graph30: any;
  splineChart: any;
  userId_val: any;
  data: any;
  smeFinanceObj: { table: {}; excel: any[]; };
  tableDisplay: boolean;
  reportRequest: any;

  dtOptionsday: DataTables.Settings = {};
  excelData2 = [];
  regionsList: any;
  regions: any;
  regionDropdownSettings = {};
  shipTypeDropdownSettings = {};
  DateCalender: any;
  selectedRegions = [];
  selectedShipType: any;
  dateRanges = { start_date: new Date(), end_date: new Date() };
  dateRange: any;
  viewDetailsTable = false;
  viewDetailsTable1: boolean;
  final: { table: {}; excel: any[]; };
  excelfinal: { table: {}; excel: any[]; };
  DayWiseTablefinal = {};
  excelData: any;
  pod_bar_line: Chart;
  viewBarlineGraph: boolean;
  shipType: any;
  shipTypeoptions = ['DP', 'NDP', 'LSP'];
  optionSelected: any;
  viewTableDaywise: boolean;
  viewTableDaywise_data: boolean;
  enableAll: boolean;
  reportName: any;
  constructor(private commonService: CommonService, public exportexcelservice: SelfServeService, private service: AppService, private userId: AppService,
    private podtrackerService: PodTrackerService, private Service: SelfServeService,
    private navbarTitle: NavbarComponent,) {
    this.userId_val = this.userId.userDetails.email;
    this.regionsList = this.service.regionsList

    this.userId_val = this.userId.userDetails.email;
    this.multiSelectDropdown();
    this.reportName = this.navbarTitle.reportlabel;
    this.reportRequest = { 'username': this.userId_val, 'report_name': this.reportName };


  }
  onOptionsSelected(event) {

    this.shipType = event
  }
  fetchData(reportTabName) {
    if (reportTabName == 'Summary') {
      this.viewDetailsTable = false;
      this.viewDetailsTable1 = false;
      this.viewBarlineGraph = false;
      this.viewTableDaywise_data = true;

      
    } else if (reportTabName == 'Days Wise') {
      this.viewTableDaywise_data = false;
      this.viewDetailsTable = true;
      this.viewDetailsTable1 = true;
      this.viewBarlineGraph = false;
      
    } else if (reportTabName == 'Total') {
      this.viewTableDaywise_data = false;
      this.viewDetailsTable = false;
      this.viewDetailsTable1 = false;
      this.viewBarlineGraph = true;
      
    }
  }
  generatePayload() {
    const payload = {
      "start_date": this.getDateFormat(this.dateRange[0]),
      "end_date": this.getDateFormat(this.dateRange[1]),
      "regions": this.selectedRegions,
      "username": this.userId_val,
      "shipType": this.selectedShipType
    }
    console.log(payload)
    return payload;
  }
  generateValuesForGraph(data) {
    const values = [];
    const categories = [];
    if (data && data.length) {
      data.forEach(element => {
        values.push(element['Y']);
        categories.push(element['X']);
      });
    }
    return {
      values: values,
      categories: categories
    };
  }
  generateSplineChart(res) {
    let arraylist = []
    let yaxisdata = []
    let Xaxisdata = []
    let arraylist30 = [];
    let yaxisdata30 = []
    let Xaxisdata30 = [];
    let color = ['#5D8AA8', '#E32636', '#A4C639', '#8DB600', '#7FFFD4', '#4B5320', '#3B444B', '#E9D66B', '#B2BEB5', '#87A96B', '#FF9966', '#6D351A', '#007FFF', '#89CFF0', '#A1CAF1', '#F4C2C2', '#FFD12A', '#848482', '#98777B', '#0000FF', '#DE5D83', '#79443B']








    for (var i = 0; i < this.selectedRegions.length; i++) {
      yaxisdata = [];

      for (let y = 0; y < res['data15days']['result'].length; y++) {
        if (res['data15days']['result'][y]['Region'] == this.selectedRegions[i]) {
          yaxisdata.push(res['data15days']['result'][y]['per'])
          Xaxisdata.push("W" + res['data15days']['result'][y]['Week_Number'])

        }


      }
      console.log(yaxisdata)
      arraylist.push({ "name": this.selectedRegions[i], "data": yaxisdata, "color": color[i] })



    }
    console.log(arraylist)
    for (var i = 0; i < this.selectedRegions.length; i++) {
      yaxisdata30 = [];

      for (let y = 0; y < res['data30days']['result'].length; y++) {
        if (res['data30days']['result'][y]['Region'] == this.selectedRegions[i]) {
          yaxisdata30.push(res['data30days']['result'][y]['per'])
          Xaxisdata30.push("W" + res['data30days']['result'][y]['Week_Number'])
        }


      }
      arraylist30.push({ "name": this.selectedRegions[i], "data": yaxisdata30, "color": color[i] })



    }
    console.log(arraylist30)
    let uniqueXaxis = Xaxisdata.filter((item, i, ar) => ar.indexOf(item) === i);
    let uniqueXaxis30 = Xaxisdata30.filter((item, t, art) => art.indexOf(item) === t);

    const options15days: any = {
      title: {
        text: '15 days Target',
        align: 'center',
        'background-color': '#9a9898',
        style: {
          color: '#D9232D ',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: uniqueXaxis
      },
      plotOptions: {
        series: {
          allowPointSelect: true
        }
      },
      series: arraylist
    }
    const options30days: any = {
      title: {
        text: '30 days Target',
        align: 'center',
        'background-color': '#9a9898',
        style: {
          color: '#D9232D ',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: uniqueXaxis30
      },
      plotOptions: {
        series: {
          allowPointSelect: true
        }
      },
      series: arraylist30
    }
    const optionslinebar: any = {
      chart: {
        type: 'areaspline',
        'background-color': '#9a9898',
      },
      title: {
        text: ' POD Total',
        align: 'center',
        style: {
          color: '#D9232D ',
          fontWeight: 'bold'
        }
      },

      subtitle: {
        text: ''
      },
      xAxis: {
        categories: res.sum_pod.result.name || [],

        title: {
          text: ''
        }
      },

      yAxis: [{ // Primary yAxis
        gridLineWidth: 0,
        labels: {
          format: '{value}%',
          style: {},
          enabled: true
        },
        title: {
          text: '',
          style: {}
        },
        opposite: true,
        // height: '50%',
        min: 0

      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {}
        },
        labels: {
          format: '{value}',
          style: {}
        },
        // linkedTo: 0,
        // top: '50%',
        // height: '50%',
        min: 0

      }],
      tooltip: {
        shared: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.2
        },
        series: {
          label: {
            connectorAllowed: false
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                alert('X-Axis: ' + this.category + ', Y-Axis: ' + this.y + '%');
              }
            }
          }

        }
      },

      series: [{
        name: 'Invoice Total',
        data: res.sum_pod.result.invoice_total || [],
        yAxis: 1,
        type: 'column'
      },
      {
        name: 'Delivered POD',
        data: res.sum_pod.result.Delivered_POD || [],
        yAxis: 1,
        type: 'column'
      },
      {
        name: 'Percent',
        data: res.sum_pod.result.percent || [],
        type: 'line'
      }
      ],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }

    };


    this.DayWiseTablefinal = this.podtrackerService.post(res)
    if (this.DayWiseTablefinal['table']) {

      this.dtOptionsday = this.DayWiseTablefinal['table'];
      this.excelData = this.DayWiseTablefinal['excel'];

      if (this.dtOptionsday != null) {
        this.viewTableDaywise_data = true;
      }
    }

    this.pod_bar_line = new Chart(optionslinebar);
    this.BarLine1Graph = new Chart(options15days);
    this.BarLine1Graph30 = new Chart(options30days);
    this.viewDetailsTable = true;
    this.viewDetailsTable1 = true;
    this.viewBarlineGraph = true;
    this.viewTableDaywise = true;

    this.enableAll = true;
  }

  generateReports() {

    this.dtOptionsday = {};
    this.DayWiseTablefinal = {};
    this.excelData = [];
    this.excelData2 = [];




    this.viewTableDaywise = false;
    this.viewBarlineGraph = false;
    this.viewDetailsTable = false;
    this.viewDetailsTable1 = false;
    this.viewTableDaywise_data = false;

    let payload = this.generatePayload();
    const url = environment.baseURL_FREQ+'pod_graph';

    this.podtrackerService.postData(url, payload).subscribe(resp => {
      this.generateSplineChart(resp);

      this.userId.saveInfo(environment.user_tracking_api, this.reportRequest).subscribe(reportdata => {
        console.log(reportdata);
      })

    })



  }
  ngOnInit() {
    if (this.regions) {
      this.multiSelectDropdown();

      //this.getDefaultDateRange();

    }
  }
  calculateDateRange(value: Date): void {
    if (value) {
      var diff = Math.floor((Date.parse(value[1]) - Date.parse(value[0])) / 86400000);
      if (diff + 1 > 92) {
        this.commonService.showToast('max allowable date range is 3 months', 3);
        setTimeout(() => {
          this.dateRange = null;
        }, 100);
      }
    }
  }
  getDefaultDateRange() {
    this.podtrackerService.get_otif_date_limit().subscribe(dateRes => {
      this.DateCalender = dateRes;
      this.dateRanges.start_date = new Date(this.DateCalender.data.start_date);
      this.dateRanges.end_date = new Date(this.DateCalender.data.end_date);
    });

  }

  multiSelectDropdown() {

    this.regions = JSON.parse(localStorage.getItem('regions'));

    this.regionDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
    this.shipTypeDropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      itemsShowLimit: 1
    };
  }
  getSelectedFilters(selectedRegionsList) {
    for (let j = 0; j < selectedRegionsList.length; j++) {

    }
  }
  onRegionSelect(item: string) {
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsAll(items: any) {
    this.selectedRegions = this.regions;
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionDeSelect(deselectedSID: any): void {
    this.selectedRegions = this.selectedRegions.filter(s => s != deselectedSID);
    this.selectedRegions.forEach(sid => { this.onRegionSelect(sid); });
    this.getSelectedFilters(this.selectedRegions);
  }
  onRegionsDeSelectAll(items: any) {
    this.selectedRegions = [];

  }



  onShipTypeSelect(item: string) {
    this.selectedShipType = item;
  }

  getDateFormat(date) {
    if (!date) return;
    date = new Date(date);
    return date.getFullYear() + '-' + this.appendZero((date.getMonth() + 1)) + '-' + this.appendZero(date.getDate());
  }
  appendZero(number) {
    if (number > 9) {
      return number;
    } else {
      return '0' + number;
    }
  }
  exportAsXLSX(): void {
    let payload = this.generatePayload();
    this.podtrackerService.postData(environment.baseURL_FREQ+'pod_tracker_detailed', payload).subscribe(resp => {
      this.excelfinal = this.podtrackerService.post(resp)
      if (this.excelfinal['table']) {
        this.excelData2 = this.excelfinal['excel'];

        this.Service.exportAsExcelFile(this.excelData2, 'POD-Tracker')
      }
    })

  }

}

